import { useEffect, useRef, useState } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
// import "../../../styles/PageViewer/Transcription.scss";
import PageNumber from "./PageNumber";
import * as Styled from './styled';

const Transcription = () => {
  const page = useParams().page || "1";
  const { hash } = useLocation();
  const scrollToAnchor = hash ? hash.replace("#", "") : null;

  // pageNum is stored in state and updated when any necessary scrolling has finished
  const [pageNum, setPageNum] = useState(0);
  // this is set to true if the element if scrollIntoView is necessary in the effect below. It's passed to PageNumber components to prevent them from navigating to a new page until the scroll here is complete.
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  //when the page number changes, scroll to the page on the transcription
  useEffect(() => {
    if (parseInt(page) !== pageNum && !scrollToAnchor) {
      const element = document.getElementById(`page-${page}`);
      const transcriptionContainer = ref.current;
      if (element && transcriptionContainer) {
        element.scrollIntoView();
        let scrollTimeout: any;
        setIsAutoScrolling(true);
        const onScroll = (e: any) => {
          clearTimeout(scrollTimeout);
          scrollTimeout = setTimeout(function () {
            setPageNum(parseInt(page));
            setIsAutoScrolling(false);
          }, 100);
        };
        transcriptionContainer.addEventListener("scroll", onScroll);
        return () => {
          transcriptionContainer.removeEventListener("scroll", onScroll);
        };
      }
    }
  }, [page, pageNum, scrollToAnchor]);

  // scroll to a meeting anchor
  useEffect(() => {
    if (scrollToAnchor) {
      const element = document.getElementById(scrollToAnchor);
      const transcriptionContainer = ref.current;
      if (element && transcriptionContainer) {
        element.scrollIntoView();
        let scrollTimeout: any;
        setIsAutoScrolling(true);
        const onScroll = (e: any) => {
          clearTimeout(scrollTimeout);
          scrollTimeout = setTimeout(function () {
            setPageNum(parseInt(page));
            setIsAutoScrolling(false);
          }, 100);
        };
        transcriptionContainer.addEventListener("scroll", onScroll);
        return () => {
          transcriptionContainer.removeEventListener("scroll", onScroll);
        };
      }
    }
  }, [page, pageNum, scrollToAnchor]);

  return (
    <Styled.Volume>
      <div className="tei_front" id="v2front">
        <PageNumber num={1} />
        <div className="titlePage">
          <div className="docTitle">
            <div className="titlePart">
              Proceedings<br />
              OF THE<br />
              Virginia State Convention<br />
              of 1861
            </div>
            <div className="titlePart">FEBRUARY 13 — MAY 1<br />In Four Volumes</div>
          </div>
          <div className="byline">GEORGE H. REESE<br />Editor</div>
          <div className="docImprint"><span className="date">1965</span><span className="publisher">VIRGINIA STATE LIBRARY</span><span className="pubPlace">RICHMOND, VIRGINIA</span>:</div>
        </div>
        <section className="front" id="vsc1965.v2.1">
          <h1>Library of Congress …</h1>
          <PageNumber num={2} />
          <p>Library of Congress Catalog Card No. A65-7459</p>
          <p>PRINTED IN U.S.A.</p>
          <PageNumber num={3} />
          <div className="contents" id="vsc1965.v2.1.1">
            <PageNumber num={5} />
            <h2><span className="head">CONTENTS</span></h2>
            <p>Synopsis vii</p>
            <p>Proceedings 3</p>
            <p>Notes 755</p>
            <PageNumber num={6} />
          </div>
          <div className="synopsis" id="vsc1965.v2.1.2">
            <PageNumber num={7} />
            <h2><span className="head">SYNOPSIS</span></h2>
            <h3>TWENTY-EIGHTH DAY</h3>
            <div className="synopsisDateLine"><div>Monday, March 18</div><Link to={`../${3 + 10}`} relative="path">3</Link></div>
            <p>
              Mr. Willey's resolutions are the subject of full discussion by Mr. Brown, Mr. Fisher, Mr. Turner, Mr. Early, Mr. Wilson, Mr. Branch, Mr. Caperton, and Mr. Johnson, who elaborate upon the attitude of the northwest section of Virginia, and taxation of slaves. Mr. Woods begins a speech on the same topics. The President lays before the Convention a communication from the Maryland
              Convention regarding a proposed conference of border states. Mr. Randolph continues his speech. Mr. Dorman offers resolutions for amendments to the Federal Constitution on Presidential electors and citizenship of Negroes.
            </p>
            <h3>TWENTY-NINTH DAY</h3>
            <div className="synopsisDateLine"><div>Tuesday, March 19</div><Link to={`../${29 + 10}`} relative="path">29</Link></div>
            <p>
              Mr. Fisher explains his remarks on taxation. Mr. Hall recommends the Confederate Constitution be made the basis of Virginia's ultimatum to the Federal Government. Mr. Conrad presents, as the remainder of the report of the Committee on Federal Relations, proposed amendments to the Federal Constitution. Mr. Branch comments on resolutions of citizens of Petersburg insisting that Negroes
              are property, and that Virginia should secede. Mr. Woods continues his remarks, at length, and concludes. Mr. Randolph next concludes his speech, and Mr. Haymond begins his remarks on taxation.
            </p>
            <h3>THIRTIETH DAY</h3>
            <div className="synopsisDateLine"><div>Wednesday, March 20</div><Link to={`../${73 + 10}`} relative="path">73</Link></div>
            <p>
              Mr. Speed offers a resolution calling for two ordinances, one providing for secession, and the other for amendment of the Federal Constitution. Mr. Holcombe begins a speech on slavery and the advantages of a Southern Confederacy. Mr. Wickham presents resolutions of citizens of Henrico County, in favor of secession by Virginia and the border states. Mr. Osburn offers resolutions of the
              citizens of Harper's Ferry and Bolivar, opposing secession. Mr. Haymond concludes his remarks on taxation.
            </p>
            <PageNumber num={8} />
            <h3>THIRTY-FIRST DAY</h3>
            <div className="synopsisDateLine"><div>Thursday, March 21</div><Link to={`../${129 + 10}`} relative="path">129</Link></div>
            <p>
              Mr. Wilson suggests a conference of states. Mr. Boyd offers a substitute for the report on amendments to the Federal Constitution. Mr. Marye reads resolutions of citizens of Spotsylvania County, in favor of secession. Mr. Holcombe concludes his remarks. Mr. Baldwin speaks at length on slavery, and says that Virginia holds the destiny of the Republic in her hands. Mr. Carlile suggests
              that the Peace Conference proposals be substituted for the report of the Committee on Federal Relations.
            </p>
            <h3>THIRTY-SECOND DAY</h3>
            <div className="synopsisDateLine"><div>Friday, March 22</div><Link to={`../${160 + 10}`} relative="path">160</Link></div>
            <p>Mr. Hall begins a speech on taxation. Mr. Baldwin continues his speech in defense of the Union, with frequent interruptions.</p>
            <h3>THIRTY-THIRD DAY</h3>
            <div className="synopsisDateLine"><div>Saturday, March 23</div><Link to={`../${202 + 10}`} relative="path">202</Link></div>
            <p>
              Mr. Hall makes further comments on taxation. Mr. Boisseau, Mr. Morton and Mr. Marye lay before the Convention the resolutions of citizens of Dinwiddie, Greene and Spotsylvania counties, all favoring secession. Mr. Baldwin concludes his address. Mr. Bruce starts a speech questioning the benefits of the Federal Union. Mr. Barbour presents the resolutions of a meeting in Culpeper County,
              calling for secession. Mr. Dorman requests a tax on Northern products.
            </p>
            <h3>THIRTY-FOURTH DAY</h3>
            <div className="synopsisDateLine"><div>Monday, March 25</div><Link to={`../${249 + 10}`} relative="path">249</Link></div>
            <p>Mr. Turner repeats his resolutions on taxation of slaves. Mr. Bruce continues his criticism of the Federal system, and concludes with an approving reference to the Southern Confederacy. A number of delegates, notably Messrs. Carlile, Leake, Wise, Harvie and Summers, discuss Carlile's substitute for the report of the Committee on Federal Relations.</p>
            <h3>THIRTY-FIFTH DAY</h3>
            <div className="synopsisDateLine"><div>Tuesday, March 26</div><Link to={`../${338 + 10}`} relative="path">338</Link></div>
            <p>Mr. Miller presents resolutions by the people of Botetourt County, in</p>
            <p>favor of secession. Mr. Preston speaks against secession, and for</p>
            <PageNumber num={9} />
            <p>
              negotiation. Mr. Nelson reviews the causes of the present crisis, and argues that Virginia is better off in the Federal Union. Mr. Wilson follows, with an address on the formation and present condition of the national government, Northern hostility, and the need for conservative and deliberate action by Virginia. Mr. Hall proposes to substitute the Confederate Constitution for the
              majority report of the Committee on Federal Relations. Mr. Turner proposes, as a substitute for the report, a set of amendments to the Federal Constitution.
            </p>
            <h3>THIRTY-SIXTH DAY</h3>
            <div className="synopsisDateLine"><div>Wednesday, March 27</div><Link to={`../${420 + 10}`} relative="path">420</Link></div>
            <p>
              Mr. Cox presents resolutions by citizens of Chesterfield County, recommending immediate secession. Mr. Turner resumes his remarks with an appeal for equality in taxation, and once more proposes his substitute of amendments to the United States Constitution. A long discussion follows on the first resolution in the report of the Committee on Federal Relations, concerning the relation of
              the states to the central government.
            </p>
            <h3>THIRTY-SEVENTH DAY</h3>
            <div className="synopsisDateLine"><div>Thursday, March 28</div><Link to={`../${503 + 10}`} relative="path">503</Link></div>
            <p>
              Mr. Cabe11 presents resolutions of a public meeting in Nelson County, calling for immediate secession. Mr. Turner concludes his remarks on taxation, and Mr. Willey speaks on the same subject. Mr. Conrad and Mr. Scott talk about independence and sovereignty. Mr. Goode makes a long address on the great question of African slavery. Mr. Branch and Mr. Blakey return to the question of state
              sovereignty, and after the recess Mr. Fisher, Mr. Scott and Mr. Wise take up the subject. The first resolution in the report of the Committee on Federal Relations is adopted. The second resolution, on African slavery, is considered and adopted.
            </p>
            <h3>THIRTY-EIGHTH DAY</h3>
            <div className="synopsisDateLine"><div>Friday, March 29</div><Link to={`../${569 + 10}`} relative="path">569</Link></div>
            <p>Mr. Parks presents resolutions of a meeting held in Grayson County, calling for immediate secession. The third resolution of the Committee on Federal Relations, decrying control of the Federal government by a sectional party, is taken up. Mr. Rives makes a long speech against secession, for negotiation, and in defense of slavery.</p>
            <PageNumber num={10} />
            <h3>THIRTY-NINTH DAY</h3>
            <div className="synopsisDateLine"><div>Saturday, March 30</div><Link to={`../${582 + 10}`} relative="path">582</Link></div>
            <p>
              Mr. Mallory presents resolutions by citizens of Brunswick County, in favor of immediate secession. Mr. Flournoy gives his ideas on proper guarantees and recognition of Southern rights, in particular that of slavery. Mr. Barbour follows to speak of his previous devotion to the Federal Union, and his present approval of the Confederacy. Mr. Goggin acknowledges that the Union is
              dissolved, but hopes for the return of the seceded states. He closes with a qualified approval of secession. Delegates conclude the day with a protracted discussion on the limiting of debate.
            </p>
            <h3>FORTIETH DAY</h3>
            <div className="synopsisDateLine"><div>Monday, April 1</div><Link to={`../${664 + 10}`} relative="path">664</Link></div>
            <p>
              Mr. Southall presents resolutions by the citizens of Scottsville and vicinity, in favor of immediate secession. He justifies his own conduct. Mr. Barbour concludes his speech, proposes recognition of the Confederacy, and discusses the economic capabilities of the South. Mr. Tredway expresses agreement with Mr. Barbour; he argues against Virginia's secession from the Union, but says
              that he prefers the Southern Confederacy to a Union based on false principles. Mr. Montague begins a speech on the slavery controversy. Mr. Goode, Mr. Harvie, and Mr. Blakey present resolutions from Bedford, Amelia and Madison counties, favoring secession.
            </p>
          </div>
        </section>
      </div>
      <div className="tei_body" id="v2body">
        <section className="body" id="vsc1965.v2.2">
          <PageNumber num={11} />
          <header>
            <h1><span className="headingNumber">1. </span><span className="head">VOLUME TWO</span></h1>
          </header>
          <p>Proceedings: March 18-April 1, 1861</p>
          <PageNumber num={12} />
          <div className="day" id="vsc1965.v2.2.1">
            <PageNumber num={13} />
            <h2><span className="headingNumber">1.1. </span><span className="head">TWENTY-EIGHTH DAY</span></h2>
            <div className="dateline">Monday, <span className="date">March 18</span></div>
            <p>The Convention met at half-past 10 o'clock.</p>
            <p>Prayer by the Rev. Mr. Brown, of the Presbyterian Church.</p>
            <div className="section" id="vsc1965.v2.2.1.1">
              <h3><span className="headingNumber">1.1.1. </span><span className="head">THE QUESTION OF TAXATION</span></h3>
              <div className="speaker" id="sp752"><span className="persName">Mr. BROWN</span>, of Preston—</div>
              <p className="p-in-sp">I believe, sir, when the Convention adjourned on Saturday, the pending question was to lay the resolutions of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>]
                , on the table. As the Convention is very thinly attended this morning, I presume the gentleman who moved to lay the resolutions upon the table would withdraw it for the present. The present time is unpropitious for a discussion upon these resolutions, and it would be important, moreover, when we take a vote upon them, that the Convention should be tolerably full. If the gentleman
                will withdraw his motion I will renew it again if the gentleman desires.
              </p>
              <div className="speaker" id="sp753"><span className="persName">Mr. SLAUGHTER</span>—</div>
              <p className="p-in-sp">
                I cannot withdraw the motion. My object in making it was to prevent any discussion which was calculated to create division and to throw a fire-brand in our midst. I think it would be very unwise and indiscreet to discuss this question at a time when we have other matters of great importance to consider. I am apprehensive that a discussion of this question now would lead to sectional
                strife and division, which would impair the influence of our action upon the great questions which more immediately led to our assembling here. I cannot withdraw my motion for the reasons which I have just stated.
              </p>
              <p>The resolutions of <span className="persName">Mr. WILLEY</span> not being published in full on yesterday—only two of them being read—we give them to-day. They read as follows:</p>
              <p>Resolved, That taxation should be equal and uniform throughout the Commonwealth, and that all property should be taxed in proportion to its value.</p>
              <p>Resolved, That a committee of thirteen members be appointed to prepare and report to the Convention such alterations of sections 22 and 23 of Article IV. of the Constitution of the Commonwealth, as shall conform said sections to the principle of taxation enunciated in the foregoing resolution.</p>
              <p>Resolved, That a committee of thirteen members be appointed to take into consideration so much of Article VI. of the Constitution of this Commonwealth, as relates to the Supreme Court of Appeals, the</p>
              <PageNumber num={14} />
              <p>District Courts, Circuit Courts, and County Courts; and that they report such amendments and modifications thereof as they may deem necessary and proper.</p>
              <p>Resolved, That the basis of representation in the two Houses of the General Assembly should be the same; therefore be it further</p>
              <p>Resolved, That a committee of twelve members, to be selected in equal numbers from the four great divisions of the State, be appointed to apportion representation in the Senate according to the number of the qualified voters in the Commonwealth, and that they report amendments of the IV. Article of the Constitution accordingly.</p>
              <div className="speaker" id="sp754"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">I rise to a question of order.</p>
              <p className="p-in-sp">
                Under the rules adopted by this Convention for its government, it is not in order to transact any business until a quorum is present. With a view to ascertain whether a quorum is present, I move a call of the House. It is a matter of importance to the people of Virginia that they should be informed who are present and prepared to transact the business of the Convention, and who are
                not.
              </p>
              <div className="speaker" id="sp755"><span className="persName">Mr. SLAUGHTER</span>—</div>
              <p className="p-in-sp">Will the gentleman withdraw his motion for a moment?</p>
              <div className="speaker" id="sp756"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">I cannot withdraw it. It is not competent for the Convention to discharge any business until a quorum is present.</p>
              <p>A call of the House was then made, when the following gentlemen failed to answer to their names :</p>
              <p>
                Messrs. Baldwin, James Barbour, Baylor, Blakey, Blow, Boyd, Branch, Brent, Bruce, Cabell, Carlile, Cecil, Chapman, Coffman, C. B. Conrad, Robert Y. Conrad, James Cox, Richard H. Cox, Critcher, Custis, Dent, Dorman, Dulany, Echols, Fisher, Forbes, Graham, Gray, John Goode, Jr., Hale, L. S. Hall, Harvie, Hoge, Peter C. Johnston, Kindred, Lawson, Lewis, McComas, Macfarland, Chas. K.
                Mallory, Marshall, Marr, Marye, Sr., Miller, Montague, Morton, McD. MOORE, Nelson, Pendleton, Preston, Price, Randolph, Rives, Robert E. Scott, Sheffey, Sitlington, Southall, Staples, Alexander H. H. Stuart, Summers, Thornton, Tredway, Tyler, Waller, White, Willey, Williams, Wise.
              </p>
            </div>
            <div className="section" id="vsc1965.v2.2.1.2">
              <h3><span className="headingNumber">1.1.2. </span><span className="head">EXPLANATION OF CAUSES OF ABSENCE</span></h3>
              <p><span className="persName">Mr. GOGGIN</span>, when the name of <span className="persName">Mr. GOODE</span>, of Bedford, was called, said:</p>
              <div className="speaker" id="sp757">[<span className="persName">Mr. GOGGIN</span>:]</div>
              <p className="p-in-sp">Mr. President, I desire to say that my colleague is necessarily absent because of illness.</p>
              <p><span className="persName">Mr. PARKS</span>, of Grayson, when the name of <span className="persName">Mr. WALLER</span> was called, said:</p>
              <div className="speaker" id="sp758">[<span className="persName">Mr. PARKS</span>:]</div>
              <p className="p-in-sp">Mr. President, <span className="persName">Mr. WALLER</span> is necessarily absent on account of illness.</p>
              <p><span className="persName">Mr. HOLLADAY</span>, when the name of <span className="persName">Mr. WHITE</span> was called, said: That his colleague was unavoidably absent from the city.</p>
              <PageNumber num={15} />
              <div className="speaker" id="sp759"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">Mr. President, I am requested by <span className="persName">Mr. CARLILE</span> to say that he was very anxious to attend the Convention this morning with a view to record his vote upon these resolutions. He is, however, prevented from being present by severe indisposition.</p>
              <p><span className="persName">Mr. A. M. BARBOUR</span>, when the name of <span className="persName">Mr. JAS. BARBOUR</span> was called, said :</p>
              <div className="speaker" id="sp760">[<span className="persName">Mr. A. M. BARBOUR</span>:]</div>
              <p className="p-in-sp">Mr. President, <span className="persName">Mr. BARBOUR</span> is absent from the city. Even if here, I do not think the feeble condition of his health would justify him in sitting here during this long session.</p>
              <p><span className="persName">Mr. PARKS</span>, when the name of <span className="persName">Mr. CECIL</span> was called, said that he was absent on account of indisposition.</p>
              <p><span className="persName">Mr. PARKS</span> also presented, as an excuse for the absence of <span className="persName">Mr. GRAHAM</span>, that he was waiting on his sick colleague, <span className="persName">Mr. CECIL</span>.</p>
              <p><span className="persName">Mr. SEAWELL</span>, when the name of <span className="persName">Mr. MONTAGUE</span> was called, said :</p>
              <div className="speaker" id="sp761">[<span className="persName">Mr. SEAWELL</span>:]</div>
              <p className="p-in-sp">Mr. President, <span className="persName">Mr. MONTAGUE</span> has been confined to his room from severe indisposition for several days. I have not seen him to-day, but I am satisfied his absence is caused by a continuance of that indisposition.</p>
              <p><span className="persName">Mr. SEAWELL</span> also represented that <span className="persName">Mr. CHARLES K. MALLORY</span> was necessarily absent.</p>
              <p>The result of the call of the roll was announced as follows: Number present 85.</p>
              <div className="speaker" id="sp762"><span className="persName">Mr. CAPERTON</span>—</div>
              <p className="p-in-sp">The mover of these resolutions is a member of the Committee on Federal Relations and necessarily absent.</p>
              <p className="p-in-sp">It would not be altogether fair to him that we should proceed to consider them. To relieve the difficulty I move to pass by the unfinished business.</p>
              <div className="speaker" id="sp763"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">Before any motion is in order it is necessary to dispose of the question of the call of the roll. I move that further proceedings be dispensed with on that subject.</p>
              <p>The motion was agreed to.</p>
              <div className="speaker" id="sp764"><span className="persName">Mr. CAPERTON</span>—</div>
              <p className="p-in-sp">I now move that the matter under consideration be postponed for the present, to enable the mover of the resolutions to be present.</p>
              <div className="speaker" id="sp765"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The pending motion is to lay on the table. That motion is not debatable.</p>
              <div className="speaker" id="sp766"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">I would again make an appeal upon personal grounds, to the gentleman from Campbell [<span className="persName">Mr. SLAUGHTER</span>] , to withdraw his motion to lay on the table.</p>
              <div className="speaker" id="sp767"><span className="persName">Mr. SLAUGHTER</span>—</div>
              <p className="p-in-sp">Mr. President, I will yield to this appeal and withdraw my motion.</p>
              <PageNumber num={16} />
              <div className="speaker" id="sp768"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">I desire to say a few words in relation to these resolutions.</p>
              <p className="p-in-sp">
                It has been charged, sir, upon the North-western portion of the people, that I in part have the honor to represent, that they are Abolitionists, that they are unkind in their feelings towards the institution of slavery. Sir, if that were true I should not have a seat upon this floor. I am not only interested directly in the institution of slavery, but have vindicated it as one highly
                conservative; one that is ordained of God himself for the purpose of redeeming the African race from barbarism. I have said before my people again and again, that but for the opinions of the Christian world, and we must in some degree give way, I would be willing to see the last African picked up and brought under the influence of Christianity and civilization; and yet, sir, no
                opinions of this character that I have expressed before my people have militated against me or been objected to by them, whenever I sought their suffrages. It is unkind, therefore, to charge upon that people hostility to slavery. Let me tell you, sir, that your institution—that that particular property, and not only that, but every kind of property that you have is much safer in the
                care and keeping of that people that have been denounced as Abolitionists here, than in the care and keeping of that mixed crowd that you see on your streets here shouting in a disorderly manner and at unusual hours. Gentlemen may stir up an element here, sir, much more dangerous to the institution of slavery and to all their interests, than they possibly can stir up in the West. We
                are a calm, dispassionate people. We regard the rights of all, and if that institution is never injured until the people of North-western Virginia disturbs it, it will never be injured or disturbed.
              </p>
              <p className="p-in-sp">
                It is true, sir, that they believe, honestly believe, that that peculiar property—slave property—ought to be taxed in common with all the other property of the Commonwealth. They believe in the doctrine that property that seeks the security of the laws of the land, should bear its equal proportion of the burdens of Government. It is a doctrine that they honestly believe, and one,
                sir, that they will never cease to insist upon until it is carried out. But, sir, I am not prepared to say that they will seek to get it by secession or revolution. They are a law-abiding people, and I doubt, sir, if ever they attempt to intimidate your representatives—I mean the representatives of Eastern Virginia—by threats of violence for expressing their honest opinion in any
                assemblage that may be called for the purpose of consulting upon the public weal. Sir, they are not disposed to threaten nor to revolutionize. They have been charged with being submissionists. Sir, in one sense of the <PageNumber num={17} /> word, if it means submission to the laws of the land, they are submissionists. Sir, I have had occasion to look
                back a little to the history of the anti-submissionists, of the secessionists, in other words. They are not a recent party. I believe, sir, the first anti-submissionist was found in the original government given to our first parents. Eve, sir, would not submit to one single restraint; she rebelled against the government given to her by God himself. We in Northwestern Virginia are not
                prepared for revolution or secession. We view secession and revolution as dangerous expedients, while they may be resorted to in extreme cases. We acknowledge the right of revolution when all efforts to obtain our rights shall be exhausted, but not before. Sir, we look upon the robber and murderer as anti-submissionists, for they will never submit to the laws, and we regard them as
                the most precipitate secessionists of all others in the world, for the moment they commit their terrible crimes they fly precipitately away. We do not believe in any such system of secession as that. We desire to be a law-abiding people and are opposed to any course of action in conflict with that principle.
              </p>
              <p className="p-in-sp">
                With regard to the resolutions now under consideration, I will say, that I am anxious that they shall be acted upon, and that this committee shall be raised, fairly raised, to consider the subject referred to in those resolutions. I know, sir, if the appointment of that committee is left to you it will be a fair and impartial committee. I trust, sir, that we shall have a committee to
                consider this delicate subject, and that measures will be inaugurated for the purpose of reconciling the East and West upon that delicate question, and that equal and full justice will be done to all the Western people.
              </p>
              <p className="p-in-sp">
                Sir, they are unwilling to see partial legislation in reference to this institution, especially at a time when our interests have been so much disturbed in consequence of the rights of that peculiar property being disturbed or assailed. Let me say, sir, that we will stand by you to the last; we will stand by the principle of justice to the last; we will defend you against aggression
                from your enemies; but let me beg of you to remember that we have rights, that we are peculiarly sensitive upon this subject of equality. We may not have as much property as you, we may not have as much of that peculiar kind of property as you have, but we are unwilling to see one man pay $500 taxes and his neighbor pay $5 upon property of probably equal value. It is all right when
                assessed upon the ad valorem principle. Would you have your neighbor pay as much taxes as the man whose property, under the same assessment, would be far above his in value? It is unfair to require it. Sir, we are not all equal in property; we are not, in fact, equal in anything.
              </p>
              <PageNumber num={18} />
              <p className="p-in-sp">
                If we are a homogeneous community that ought to live in peace together, we ought to have a rule by which the burthens should fall in equal proportions upon all. I desired to say simply what I have said, to put myself right, and I trust it will be the pleasure of the Convention to raise a committee to take this subject into consideration. It may be that the committee could not act
                upon it during this session, but I wish the people of the West to know that this Convention has indicated a willingness to consider it. I offered a resolution a few days ago to raise a committee to which all resolutions relative to taxation should be referred. I have no choice, however, as to the form in which an enquiry shall be made, but I want it to be made; and if it shall be the
                opinion of a committee, fairly and impartially raised, that no action should be taken in the matter, why, I will submit.
              </p>
              <div className="speaker" id="sp769"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I desire to say in relation to the remarks of my friend from Preston [<span className="persName">Mr. BROWN</span>] , that some of them seem to me to have been inappropriate to the present occasion. His remarks in relation to submission, would have been more appropriate, it seems to me, in discussing the question upon the resolutions reported by the Committee on Federal Relations.</p>
              <p className="p-in-sp">
                But will my friend from Preston, while insisting that the North-West will not consent to occupy a position unequal to other portions of the State, maintain with equal firmness, that they will submit to no inequality in representation or taxation in the General Government? From what has taken place here, we are to infer that at least some of the gentlemen from the North-West are
                willing to submit to inequality in the Federal Union.
              </p>
              <p className="p-in-sp">
                Now, sir, I am indisposed to submit to inequality either in the State or Federal Union. If my friend will carry out, in good faith, the plighted faith of North-Western, Eastern and Western Virginia—the plighted faith, pledged in the Convention of 1850 to leave this question undisturbed until 1865, I will meet my friend; and then if he shall satisfy me at that year it is necessary to
                consider whether or not any inequality exists as to taxation or representation; whether we may want a re-assessment of the value of lands that are rising so rapidly as that the best calculator cannot estimate their value, especially those lands in which the oil springs are situated, less than $1,000 or $2,000 an acre. And, perhaps, when we come to an adjustment of this question, we
                shall ascertain that we gain as much by a re-assessment of the lands in the West as we lose in Eastern Virginia by taxing slaves under twelve years of age.
              </p>
              <p className="p-in-sp">
                I am sure that my friend does not mean to inflict any injustice on <PageNumber num={19} /> Eastern Virginia. I am sure that he has taken the same view of this matter that has presented itself to the minds of many other Western men on this floor; but they, like him, will find their estimates of the advantages now looked for from the proposed change to turn
                out very differently from what they now imagine.
              </p>
              <p className="p-in-sp">But, sir, it seems to me it would be a breach of faith on the part of Western Virginia to disturb this question before the year 1865.</p>
              <div className="speaker" id="sp770"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">Will my friend permit me to make an inquiry?</p>
              <div className="speaker" id="sp771"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">Certainly, sir.</p>
              <div className="speaker" id="sp772"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">Do I understand the gentleman as conveying the idea that the taxation question was to remain undisturbed until 1865?</p>
              <div className="speaker" id="sp773"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I was under the impression that such was the understanding.</p>
              <div className="speaker" id="sp774"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">It was not, sir. The only question that was to be left undisturbed until 1865, was the basis of representation, and you know that, Mr. President?</p>
              <div className="speaker" id="sp775"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">It follows as a necessary consequence that taxation is not to be disturbed, because representation in the Senate is based upon taxation and population, while in the House of Delegates it is based upon population alone. When, therefore, they consider the question of representation, they will have to consider the question of taxation.</p>
              <p className="p-in-sp">
                I understand, too, sir, from the "Examiner" of this morning, that the man who ought to be the representative of the whole Commonwealth—the man who ought to be the embodiment and representative of all the great interests and prosperity of every portion of the Commonwealth—has said that this Convention was convened with no view to the consideration of domestic matters. I suppose, sir,
                that when this Convention was called, no one dreamed that it was to consider any other matters than those that specially pertained to what I will call our international relations. And it would be grossly unjust, as it seems to me, when convened for a special purpose, for us to go into the consideration of other matters that are altogether foreign to the intentions of those who cast
                their votes for us at the polls, on the 4th of February last. But I repeat, if my friend and the gentlemen from the Northwest—I do not mean to impute to these gentlemen any want of patriotism, I have no reason to doubt that they are as patriotic as any other portion of the people of this Commonwealth—but, I repeat again, if the gentlemen who represent that portion of our State will
                give us what is suggested in one of the morning papers—although, according to my understanding, we are not authorized to enter into any such understanding—yet, I will shake hands with my friend, and say to him <PageNumber num={20} /> that if he will give us an ordinance of secession, and go before his people pledging himself in good faith to make the
                effort to induce them to confirm that ordinance, I will go into a consideration of this question of taxation and representation. I will say for myself, sir, whether my constituents will endorse it or not, that if these gentlemen give me an ordinance of secession that shall be adopted by their people and the Commonwealth, they shall have taxation upon slaves under twelve years of age,
                and all slaves over that age shall be taxed according to their value.
              </p>
              <p className="p-in-sp">
                I am unwilling, sir, to remain under a sense of inferiority, and am now in favor of this Commonwealth's resuming all the power that she delegated to a Government from which she can obtain no justice; nothing, in fact, but insults and injury. Shall we continue to allow the stamp of inferiority for ever to be put upon us, or shall we not rather now postpone the consideration of mere
                domestic questions and do something to relieve us from the degraded and degrading position of inferiority in which we are placed in this Federal Union? Sir, do you suppose that when we are not recognized as equals with these men at the North; when we see that these people that ought to be the same with us in feeling and interest, are, before the civilized world, declaring us their
                inferiors and unworthy of their association, do you suppose, sir, that the people of this Commonwealth ought to continue in association with that people under the same Government? For myself, I can hardly realize the possibility of their submitting to such a degradation. And yet I cannot doubt but that all here are convinced that they are occupying an inferior position in this
                confederacy. If they were not so convinced they could not consistently give countenance to a single resolution or proposition made by this Committee on Federal Relations. I say there is no gentleman in this Convention so stultified as not to recognize the fact that our rights have been invaded, our interests paralyzed, our honor infringed, by the Northern States of this confederacy.
                Let them come up and relieve us from this degraded condition that we occupy; and I say to the gentleman from the Northwest, that whatever is right and just shall be cheerfully conceded to his section. We ask our friends from the North-west to aid us in securing for the Commonwealth the position that she ought to occupy and we will yield all that is reasonable and just. Relieve all
                the great interests of the Commonwealth from the paralysis which has seized them; relieve the stagnation which has been brought upon the business of the people of Richmond, against whom my friend warns us as being more to be dreaded than the people of the North-West; I say, relieve these <PageNumber num={21} /> great interests, and then we will enter
                into a consideration of the State question which seems to occasion peculiar trouble to the gentleman from the North-West. I fear, sir, that the inaction of this body will bring starvation upon the mechanics, operatives and laborers and their families, and the result will be that we shall breed riots in Richmond as there will be in Baltimore, Philadelphia and New York, before 60 days,
                as is now very well known.
              </p>
              <p className="p-in-sp">
                That may come, sir, but it will only come from those people in the city of Richmond whose families are in a state of starvation, and who will rise in their strength to make a demand which may be respected. I hope, sir, my friend from Preston has had an opportunity of making the explanation he desired; that now, as two or three speeches have been made on the other side, and views of
                the gentlemen from the Northwest have gone and will now go before the country, that I may be allowed to renew the motion that this question be laid upon the table.
              </p>
              <div className="speaker" id="sp776"><span className="persName">Mr. TURNER</span>, of Jackson and Roane—</div>
              <p className="p-in-sp">I trust that the gentleman will withdraw his motion for a moment.</p>
              <p><span className="persName">Mr. FISHER</span> withdrew his motion.</p>
              <div className="speaker" id="sp777"><span className="persName">Mr. TURNER</span>, of Jackson and Roane—</div>
              <p className="p-in-sp">I trust that the gentleman mark of my friend from Northampton [<span className="persName">Mr. FISHER</span>]
                , which casts some imputation upon the fair dealing of gentlemen upon this floor from the Northwest, in relation to the question of taxation. I do not understand —and if I had the Code of Virginia here containing the new Constitution of the State, I could show—that the present Constitution in any way attempts to interfere with the question of taxation in the future; that it only
                provided for the time and the manner in which the taxation was to be distributed. It only provides for a re-apportionment of representation in the year 1865,. and does not contemplate in its language, in any way whatever, that a Convention was to be held at that time. I do not presume that any gentleman for one moment proposes that any Convention, by its action, can bind the people
                of a State so as to prevent them, through their Legislature, from calling another Convention, whenever they desire to do so. In reply to the imputation against the gentlemen on this floor from the Northwestern portion of the State, I desire to call attention to the act of the Legislature calling this Convention together, the very language of which is susceptible of only one
                construction. The law says:
              </p>
              <p className="p-in-sp">
                "At the same time the said Commissioners and officers shall open a separate poll to take the sense of the qualified voters as to whether any action of said Convention dissolving our connection with the Federal <PageNumber num={22} /> Union, or changing the organic law of the State, shall be submitted to the people for ratification or rejection."
              </p>
              <p className="p-in-sp">
                It is true the Legislature could not have control over the subjects which would be brought before the Convention when it was assembled; and a fair interpretation of this matter is that the Legislature intended —at least this was the view conveyed to the people of the West, and I think it is a fair interpretation of the act itself—that the Legislature contemplated when it concluded to
                call this Convention, that the organic law should be changed. I know nothing about any arrangement or understanding with the last Convention, and upon the face of the Constitution then adopted there is nothing to show that there was any understanding as to the future action of the North-West upon this question of taxation. And it is known by the gentlemen from the East that the
                people of the West have been agitating this question before the Legislature in order to obtain a new Convention for the purpose of discussing this question.
              </p>
              <p className="p-in-sp">
                One more word, and I have done. The bid that the gentleman throws out, I, for one of the gentlemen from the North-West, cannot accept. I came here without reference to the question of taxation particularly, but to do what is my duty to promote the interests and welfare, and to preserve the honor of the State. That duty I will perform, under all circumstances, so far as I know how,
                but at the same time I should press, with whatever ability I was possessed of, other questions of more local interest which I deem of vital importance to the people I represent. Upon this question of Federal Relations I think that, in considering that question, we should look at it as common citizens of the whole State, with a view to the welfare and the honor of the entire
                Commonwealth; but when we come to consider other questions of local interest they should receive that degree of interest which their local importance demands. I would suggest to the gentleman that, before he tries to induce North-Western men to get sounder, he should find some inducement to hold out to his Eastern friends to make them come up to the mark. I think that if some
                gentlemen are a little low down on the question of States Rights, they are shaking hands with Eastern men who are equally as low down upon the subject as themselves.
              </p>
              <p className="p-in-sp">
                But there is another suggestion which I wish to make in this connection. I rise to a point of order. It was submitted on Saturday. I do not know whether the question was decided or not. It was submitted that resolutions are now upon the table directly looking to this question, which resolutions I will call the attention of the Chair to. Those resolutions look precisely—only clothed
                in different language— <PageNumber num={23} /> to the same result. They are upon the table, and before any resolutions, looking to the same object can be submitted, those resolutions on the table, of the same nature, must be taken up and amendments or substitutes offered to them. That is the point of order which I wish to make.
              </p>
              <div className="speaker" id="sp778"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">Mr. President, if the faith of the State was pledged by the action of the Convention in 1850 not to disturb this question of taxation until 1865 I want to know if the proposition that I understand the gentleman from Northampton [<span className="persName">Mr. FISHER</span>] to make to the northwestern representatives in regard to this question, would not equally violate that faith? Why, sir, if we should succeed in making that bargain with the gentlemen from the northwest what would become of my friends from Pendleton and Halifax, Union men, representing the largest slaveholding counties in the State?</p>
              <div className="note" id="Note5">
                <span className="noteLabel">1</span>A correction (March 21) of Mr. Early's sentence reads thus:
                <p>"Why, sir, if he [the gentleman from Northampton] can succeed in making that bargain with the gentlemen from the North-west, what would become of my friends from Pittsylvania and Halifax, who came here Union men, representing the largest slaveholding counties in the State?"</p>
              </div>
              <p className="p-in-sp">
                I have seen an intimation in the newspapers that a bargain was on foot between the Northwestern men and the Union men in Eastern Virginia; that if the Eastern men would go against Disunion the North-Western men would yield this question of taxation. I have never heard, except by that information through the newspapers, that any such bargain was on foot. But we hear it now submitted
                to the gentlemen of the Northwest, that if they will support an ordinance of secession, notwithstanding this plighted faith of the State in 1850 not to touch the subject, the gentleman from Northampton, and I presume those who agree with him on that question, are ready to disturb this question of taxation.
              </p>
              <div className="speaker" id="sp779"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I wish to say that I have had no consultation with any man upon the subject. I speak for myself.</p>
              <div className="speaker" id="sp780"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">That is all I have to say upon this question. How can the gentleman from Northampton [<span className="persName">Mr. FISHER</span>]
                , insist that the gentlemen from the North-West shall not require a change, if he is willing to yield it himself, upon certain conditions. If the faith is pledged it equally binds us in the case of secession as in any other case. As the gentleman said, the bill provided for a change in the organic law. A great many gentlemen from the East voted against a reference of that question to
                the people. But there was a very large majority of the popular vote in favor of referring any action of the Convention to the people for ratification, whether an ordinance of secession be adopted, or any change be made in the Constitution. In my section of the State this question of Union and dis-union over-rode every other question. I am not now prepared to vote for any change in
                the organic law of the State. I am not committed, except so far as a representative is bound to remember the views of the majority of his fellow-citizens; further than that, I am in no way committed to the present Constitution. <PageNumber num={24} /> I fought it in every way from its inception to its adoption. There are a great many provisions in it
                which I would like to see changed; but I am not prepared to interfere with the organic law at this time, unless some changes in the relation of your State are brought about rendering it necessary to interfere with it. I am willing to vote for a proposition for the appointment of a Committee to which all these resolutions shall be submitted, for the purpose of having them enquire into
                and report upon the subject. I suppose it is pretty well ascertained that we will have an adjourned session of this Convention. This Committee could be organized, and during the recess of this Convention they could be preparing a report, and in the meantime we would have an opportunity of consulting our constituents. And if the resolutions are so modified as to have them submitted to
                a Committee of enquiry, I would be very willing to vote for such a proposition as that.
              </p>
              <div className="speaker" id="sp781"><span className="persName">Mr. WILSON</span>, of Harrison—</div>
              <p className="p-in-sp">Mr. President, I regret very much to have heard from gentlemen on this floor so much distrust manifested of the disloyalty of the people of that portion of the State which I have the honor to represent in this Convention.</p>
              <p className="p-in-sp">In the course of the debate much has been said to the disparagement of the North-western portion of the State. I think it is time that the Western men in this body should defend their section of the Commonwealth. Sir, we have a record also as old as Virginia herself. That record has been one of steady and unceasing loyalty and devotion to the State.</p>
              <div className="speaker" id="sp782"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I hope the gentleman did not understand me as wishing to cast any reflection on the Western section of the State.</p>
              <div className="speaker" id="sp783"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I refer more particularly to the remarks of the gentleman from Northampton [<span className="persName">Mr. FISHER</span>]
                . It is said that we are Abolitionists. It is true there are some Abolitionists in North-western Virginia, but I venture to assert that you have more Abolitionists in the city of Richmond than there are in the whole of my Congressional District. It is said we are a free-negro-loving people. This is unjust. We have a law upon the statute book providing that free negroes shall not be
                allowed to remain within the limits of the Commonwealth. The statistics show that in the tide-water, in the valley, and in the Piedmont districts, the free negroes have increased from three to twenty-two per cent. since 1850, while in the North-west they have decreased twenty-seven per cent. The number of free negroes has been decreasing in the North-western part of the State because
                we enforce the law requiring their removal, Which has been a dead letter so far as the Eastern part of Virginia is concerned.
              </p>
              <PageNumber num={25} />
              <p className="p-in-sp">
                Concerning the progress of improvements I will remind those gentlemen, that, for a quarter of a century we knocked at the door of your capitol, asking permission to build roads over our soil, with our own money, and for many years it was refused. At the same time the General Assembly was pouring out countless millions of money to make improvements in the Eastern portion of the State.
                If there is no community of interest between the two sections, it is because Eastern Virginia has refused us the means which were necessary to build the improvements that would bring it about.
              </p>
              <p className="p-in-sp">
                We are told that it is a breach of faith to bring up this question of taxation at this time. Now I am willing to carry out in good faith, under all circumstances, and against all opposition, any plighted faith on the part of the people of the State of Virginia. I say, sir, this question of equal taxation ought to be calmly reflected upon. We should not let our passions over-rule our
                judgment. We ought to look at the facts as they exist. We have an extensive and valuable property in Eastern Virginia, a large portion of which is now exempt from taxation. Our whole country is jeopardized and threatened. Threatened by what? By the negro interest. It was the African slave that brought on the present dangerous condition of our affairs; and, my word for it, Mr.
                President, and I will make this prediction here to-day, that if something is not done to make taxation more equal we will soon have a conflict, a dire and awful conflict in Virginia. Not a conflict between the people of the sea-coast and of the mountains, but an irrepressible conflict, I fear, between the laboring man and the slave owner.
              </p>
              <p className="p-in-sp">
                Look at our tax laws. A man who toils day by day for his daily bread, and if his wages amount in the year to five hundred dollars (I believe) he is heavily taxed; while opulent slaveholders, resting upon pampered wealth and fortune own a valuable property in young negroes that is not taxed. Is that just and equitable? The slaveholder is exempt, while the laboring man is burdened with
                taxes. Shall the laboring man continue to groan and groan under taxation, and the slaveholder enjoy his valuable property, without being subject to at least equal taxation. The people of Virginia will say no. I think that this matter should be looked to; it is a subject of vast importance to the laboring men of the Commonwealth. If our existing difficulties shall yet assume the worst
                aspect, and this peculiar kind of property, slaves I mean, shall be the means of bringing war upon us, that which has been the means of producing the difficulty, ought to be taxed to the same extent as other property. I understand from the gentleman from Franklin
                [<span className="persName">Mr. EARLY</span>] , that there is some bargaining between the <PageNumber num={26} /> Eastern and Western portion of Virginia, involving the questions of secession and taxation.</p>
              <div className="speaker" id="sp784"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">Will the gentleman allow me to explain. I said I saw some intimation in the newspapers that there was a bargain, but that I had heard of no such thing among the members of this body. I utterly repudiated the idea that there was any such bargain in existence as that to which reference has been made among members of this Convention.</p>
              <div className="speaker" id="sp785"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">
                I will shake hands with the gentleman from the county of Franklin upon that subject. I have come here with the determination that so far as I am concerned, whatever will preserve the honor and interest of Virginia shall be done. I have come here prepared to use all my efforts to sustain the interests equally of all parts of Virginia. More true and more loyal friends of the negro
                interest are no where to be found than in the Western part of the State. But if we are to fight the battles of that interest, we contend that they should be subjected to the same rule of taxation as other property. We have no bargain to make, no measure to propose, but such as we believe will best promote the interest of Virginia. If she is to remain in the Union, we remain; but if
                we can get the proper assurances that our rights shall be respected and protected, we see no reason why we should sever. We do not wish to be hasty. We believe that with deliberation we can guide our Ship of State and steer her safely to the shore. We are disposed to deliberate; we are disposed to investigate the whole subject thoroughly and perfectly; we are inclined to exhaust all
                possible, all legal, all constitutional and honorable means to correct the evils and adjust the existing differences. If, after all our efforts, that cannot be done, then the time will arrive for us to assume a different position.
              </p>
              <p className="p-in-sp">
                Mr. President, I merely wished to make a few remarks on the subject of taxation, and to do what I thought was necessary in order to place in a proper position the North-western portion of Virginia. I will say no more at present, but at some future time, when I am relieved from the cold which I have to-day, I expect to give my views upon the question of our federal relations.
              </p>
              <div className="speaker" id="sp786"><span className="persName">Mr. BRANCH</span>, of Petersburg—</div>
              <p className="p-in-sp">
                Mr. President, I have just arrived in the Convention from my home, and I perceive that a very exciting subject is now before this body. I am quite surprised to see so much excitement manifested upon such an important question at this period of the Convention. It seems to me that the question of secession now agitating the people of the State of Virginia and the whole country is
                sufficient to occupy the whole of our time and attention at this moment. I have not had an opportunity of reading the exact terms of <PageNumber num={27} /> the resolution now under discussion, but I understand it is a resolution involving simply the tax on slave property, appointing a committee to investigate that question, for the purpose of raising
                the tax on slave property. I think, sir, that at the proper time, that is say, when this Convention determines that the State of Virginia shall disconnect herself from the other States of the Confederacy, if it shall so determine, the organic law of the State should be revised and re-modeled. I regard the maxim as true, that every man should pay taxes according to his ability to pay
                them. If that rule is carried out, the principle involves every species of property which a man possesses, everything that a man is worth. And I maintain that the property in slaves should be valued according to their worth to the planter in working them in Virginia, and not according to their worth in Texas or Mississippi.
              </p>
              <p className="p-in-sp">
                If you value a negro in Virginia to be worked on a plantation here, according to the value of a negro in Mississippi, you must at once see that you lose all the negroes. They will be taken into those States where they can be worked more profitably; and when the exodus becomes more rapid, you will see that there will be but a small proportion of slave property to be taxed, and the
                agricultural, the mineral and manufacturing interests will have to pay away a very large proportion of the forty millions of dollars now due by the State. We cannot afford to lose our slave labor by over taxation.
              </p>
              <p className="p-in-sp">
                The time has not yet arrived to agitate this question, but when the Convention shall have determined that the State shall leave the Union, then there should be a re-organization of the organic law upon the basis above stated, which I say cannot be controverted. I am surprised to hear the gentleman speak in the manner in which he has in reference only to negro property. Has he
                forgotten that the mercantile interest pays about one quarter of the whole tax of the State of Virginia? And does he ask us to increase the tax on negroes, and not to reduce it on the mercantile interest—to which class I belong, and of which I am an humble member. We pay more taxes than any other class of men in Virginia. Does the gentleman not know, if he is a lawyer—and I presume
                he is judging from the able manner in which he has presented his views to this Convention
                [laughter]
                —that if he has an income, say, of $1800 per annum, by his profession, that he only pays $95 tax—a half of one cent interest on his income, making $90,<span className="note" id="Note6"><span className="noteLabel">2</span>Mr. Branch's calculations at this point appear incomprehensible.</span> and $5 for a lawyer making $95; while I, if I make $1800, have to pay $360 tax? Now is it not
                perfectly plain that the tax should be equalized; and if the matter is attempted we should scan the whole subject of taxation and put it on a basis of equality? I trust the gentleman from Harrison, <PageNumber num={28} /> will take a more comprehensive view of the subject of taxation. For myself, I have but few acres of land and few slaves. I have but
                little money; and if it can bear the same proportion of tax as other property, I am willing to pay it. But, sir, it is unnecessary to proceed further on that subject. I am not now called on to elaborate that point, I ask the gentleman to let the resolution be laid on the table, and if the State determines to go out of the Union, the subject can then be considered before our final
                adjournment.
              </p>
              <div className="speaker" id="sp787"><span className="persName">Mr. WILSON</span>, of Harrison—</div>
              <p className="p-in-sp">
                I propose to let a Committee be appointed for the purpose of ascertaining and reporting if it be expedient to take action upon the subject of taxation. I wish to make a further remark, namely, that according to the present rate of taxation, the Northwestern portion of the State defrays its own expenses and returns to the State every dollar received out of the public treasury for
                public improvements in that section. So that we are in advance of appropriations.
              </p>
              <div className="speaker" id="sp788"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">
                I do not mean to go into a detailed argument upon this question, but I wish to suggest the impropriety of taking up a matter which we cannot properly consider at this time, and which involves a question concerning the organic law. I say we have no right according to sound morals before God or man to take up this question unless we first go out of the Union. Why? In 1850 we had a
                Convention, and adopted a constitution upon a compromise. The people have accepted it, and have agreed to live under it until 1865. Having entered into that compact, we ought never to touch the Constitution of our State unless some extraordinary cause requires us to do it. Of course such an event as Virginia withdrawing herself from the Confederacy, would be such an occasion and
                would justify it. That contingency not having been anticipated in 1850, but having arisen since, why, sir, that extraordinary cause would bring about the extraordinary necessity of remodeling the Constitution and equalizing the taxes. But until we do determine to go out of the Union, we should not touch it.
              </p>
              <p className="p-in-sp">
                Does the gentleman mean to say that he means to lay an additional tax upon negroes? Sir, I cannot believe that the gentleman apprehends the subject in all its bearings. I am in principle for equality in taxation, and I believe that the man who has got property should pay taxes upon that property. The poor man should not be obliged to pay upon his labor. It may take all he can get by
                his labor to support himself and family, and he should not be compelled to pay taxes upon hard-earned wages. Under the present organic law, that man has to pay taxes upon his income. I do not mean to charge any improper motive upon the <PageNumber num={29} /> Legislature that enacted such a law, but I contend that it is unjust and oppressive. I am a
                laborer myself, and I feel it myself, in my own pocket. I contribute more than I can afford to pay to this Government.
              </p>
              <div className="speaker" id="sp789"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">
                I want to call the attention of my friend from Petersburg to a resolution which was offered a few mornings since, to raise a committee on Finance, to which should be referred all resolutions relating to State and county taxation. That resolution was intended to protect all classes, and to equalize taxation; to remove the very hardship of which the gentleman complains.
              </p>
              <div className="speaker" id="sp790"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">I merely rose to reply to the argument of the gentleman over the way [<span className="persName">Mr. WILSON</span>]
                . By way of conclusion, I say that I will never vote for touching the present Constitution unless we determine to go out of the Confederacy. It would not be just to the people— they are not prepared for it, and the Convention would never have been called for any such purpose. It was called together in view of the extreme position of the South and the state of our Federal relations. I
                do not think that we ought, in advance of the separation from the Union, to change the organic law.
              </p>
              <div className="speaker" id="sp791"><span className="persName">Mr. CAPERTON</span>—</div>
              <p className="p-in-sp">
                I desire to say something in answer to the suggestion made by gentlemen as to the propriety of laying this resolution upon the table, and granting the committee asked for, leaving the subject open to be disposed of at a subsequent meeting of this Convention. I think that that course would have an unhappy effect upon this subject. We are here endeavoring to obtain from the Northern
                people such concessions and such guarantees as will enable us to continue in the Union with them. We know well that those people will grant those guarantees very much in proportion to their apprehensions as to what may be the ultimate action of this State. We present these demands to them with the implied declaration that if the demands are not accorded, we will separate from them.
                If they have any ground for disturbing the earnestness of this declaration, they will, of course, be slower in according to us those demands, and if we exhibit to them the fact that there is a domestic trouble in reference to that very property, which has caused all these national difficulties, it will have the effect to retard them in their efforts—the conservative portion of
                them—to bring about an adjustment of the demands which we make. That will be the inference which they would draw from the fact that there was now pending before this body a proposition with respect to this very subject of slavery which in all probability might so divide the State as to prevent any unity of action in regard to the question of separation. I say then, for that
                consideration, that I am opposed to acting upon the <PageNumber num={30} /> resolution at this time. I am in favor of saying to the Northern people that we are for the time determined to sink all questions of domestic strife, and to dispose of the great national questions which we were called here to consider, before we act upon anything touching merely
                our State interests.
              </p>
              <p className="p-in-sp">But, Mr. President, the question is not now whether the principle of taxation embodied in our State Constitution is right or not. Upon that proposition, I find myself with the mover of the resolutions; with the gentleman from Preston [<span className="persName">Mr. BROWN</span>]
                , and all who advocate the principle contained in them. The gentleman from Preston well knows that our views were the same in the Convention which framed this law. But I am opposed to action now, because it is not the proper time for such action, on account of the peculiar circumstances under which we assembled. Although there is no express provision to that effect in the
                Constitution, yet there has been an implied understanding that this system of taxation would be continued until the end of the year 1865. Why do I say implied understanding? This subject was very much connected with the great subject of the basis of Representation. And although it was not a matter of compromise, although you may say it was forced upon the Western people, still the
                Northern people having required so much in the arrangement of that great and paramount question, the basis of Representation, were contented to take the constitution with this provision upon the subject of taxation as that indicated by their vote ratifying that instrument.
              </p>
              <p className="p-in-sp">From that time up to the present, I am not aware that there has been presented to the General Assembly of Virginia, one solitary petition asking for a call of the Convention, with the view of changing the organic law in this particular. One gentleman who addressed the Convention this morning, I think the gentleman from Jackson [<span className="persName">Mr. TURNER</span>] , referred to some action that had been taken upon the part of his people with reference to this particular subject. It may have been that that question was agitated there, but so far as I am informed, that question has never been presented by the people of Jackson or by the people of Virginia to the General Assembly of the State.</p>
              <div className="speaker" id="sp792"><span className="persName">Mr. TURNER</span> of Jackson—</div>
              <p className="p-in-sp">The gentleman misunderstood me. The question was agitated in Jackson, and it was agitated in some other sections of the West.</p>
              <div className="speaker" id="sp793"><span className="persName">Mr. CAPERTON</span>—</div>
              <p className="p-in-sp">
                It matters not. I have no doubt it has been the subject of complaint, and I have no doubt it may have been the subject of agitation on the part of some people in some portions of the State. But what I affirm is, so far as my knowledge extends, that there has <PageNumber num={31} /> not been one solitary petition presented to the General Assembly of the
                State asking that a Convention might be assembled for the purpose of affording that redress. Why then, should we press this matter now upon the consideration of this Convention? This Convention was called with reference to no such object. If a Convention had been asked for any such purpose, I presume it could not have been obtained. This Convention was called under peculiar
                circumstances and exigencies which may never, perhaps, occur again in the history of this Commonwealth. And having been obtained under these circumstances, do these gentlemen think it right, proper and becoming to bring, forward this proposition now?
              </p>
              <div className="speaker" id="sp794"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">I wish to ask the gentleman one question. If I understood my friend, he says, that this Convention could not have been had but for the question of Federal Relations.</p>
              <div className="speaker" id="sp795"><span className="persName">Mr. CAPERTON</span>—</div>
              <p className="p-in-sp">Yes, sir.</p>
              <div className="speaker" id="sp796"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">Then I want to make this inquiry of him. Can you have any hope that we ever can have a Convention that will change the organic law? If we could not have one now, could we have one two or three years hence?</p>
              <div className="speaker" id="sp797"><span className="persName">Mr. CAPERTON</span>—</div>
              <p className="p-in-sp">
                I will answer the gentleman. I apprehend it could be had at that time which seems to have been looked to as the proper time for reforming and changing the Constitution with respect to this and other subjects. To be sure, the Constitution does not provide that in 1865 there will be a Convention. The calling of the Convention may be superseded by the Legislature, but still it was
                contemplated at the arrangement of this great question of the basis of representation, that the Constitution should be changed in 1865, and the people of this Commonwealth have seemingly acquiesced in this arrangement.
              </p>
              <div className="speaker" id="sp798"><span className="persName">Mr. WYSOR</span>—</div>
              <p className="p-in-sp">May I ask the gentleman one question. I have heard it said and repeated here that there was some understanding in the Convention of 1850-51, some plighted faith, by which we are precluded now from considering this question of taxation. The gentleman from Monroe [<span className="persName">Mr. CAPERTON</span>] as well as myself, were members of that Convention. I should like to know in what that understanding or plighted faith consisted.</p>
              <div className="speaker" id="sp799"><span className="persName">Mr. CAPERTON</span>—</div>
              <p className="p-in-sp">
                The gentleman did not hear it from me. I made no such declaration. I did not affirm that there was any such understanding. I mentioned that it was an inference from the action of that body, from the acquiescence of the people in the postponement of that subject until the year 1865. The great subject which called that Convention together, was the subject connected with the basis of
                representation. <PageNumber num={32} /> That was a subject upon which the West were more concerned than upon all other subjects, and the basis of representation was arranged so as to satisfy the demands of the Western people, and it would only have been so arranged by their having granted certain guarantees and concessions to the East. I do not admit
                that this was one; I do not admit that this arrangement of taxation was one which the members of that Convention did assent to, a consideration upon which they should get the white basis; but that was an arrangement made by the Convention; and that arrangement was sanctioned by the people in their adoption and ratification of the Constitution.
              </p>
              <p className="p-in-sp">
                Sir, the people having accepted the Constitution under these circumstances, a Constitution that looked to a change of the instrument itself in 1865, the inference was, that the organic law was to remain unchanged until the year 1865. It is a matter for the judgment and determination of the people. They are under no constraint. They could have called at any time a convention for the
                purpose of reforming the organic law in this particular. But, sir, now is of all others the most improper and inopportune time for any such call.
              </p>
              <p className="p-in-sp">
                Why, sir, the gentleman says that this whole difficulty has originated from this particular species of property. It is known that this particular species of property abounds in this portion of the State and but very little is found in the Western portion, and it is also known that the people in this portion of the State are more alive, more apprehensive, more filled with dread
                consequences of the great trouble now upon us, than the people inhabiting the Western portion of the State; and I submit to gentlemen whether it would be right to extort from their fears and apprehensions that which they would not obtain under other circumstances. If the people of Eastern Virginia are so apprehensive upon this subject, and their dread is worked up to such a pitch
                that they are willing to do now what they would not do under other circumstances, in order to have united action in resisting encroachments on the part of the North, I submit whether it would be right and proper for us and for them, situated as we are, to avail ourselves of this occasion. Would we value concessions obtained under duress—for that is the effect?
              </p>
              <p className="p-in-sp">
                Now, sir, when the proper time comes, we will unite with these gentlemen in endeavoring to obtain this reform in respect to the question of taxation. This I do not regard as the proper time. I regard the effort now, as having an injurious effect upon our interests in respect to the great questions touching our national difficulties. I regard it as not fair towards our neighbors of
                the East, to avail ourselves of the <PageNumber num={33} /> peculiar emergency in which we find them placed, to obtain from them that which they would not be willing to accord under different circumstances. In view of these considerations, I shall vote for laying this proposition upon the table, and I shall at every conceivable stage, resist the
                consideration of this subject during the sessions of this Convention.
              </p>
              <div className="speaker" id="sp800"><span className="persName">Mr. JOHNSON</span>—</div>
              <p className="p-in-sp">I had not intended, Mr. President, to participate at all in the discussion of this question. I would not do so now, but for the very singular charge which emanated from my friend from the county of Harrison [<span className="persName">Mr. WILSON</span>] , whom I understood here to-day, as saying that there are now more abolitionists in the city of Richmond than there are in North-Western Virginia. May I ask my friend by what authority he makes this assertion?</p>
              <div className="speaker" id="sp801"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">
                The gentleman misunderstood me. I did not say that there were more abolitionists in the city of Richmond than in North-western Virginia. I understand that we have some abolitionists there. I understand that there are abolitionists here. Your public prints have informed me of the fact, and from what I had seen and heard, I stated it as my belief that there were as many abolitionists
                in the city of Richmond as in my Congressional District; but I desire my friend to know that we have very few out there.
              </p>
              <div className="speaker" id="sp802"><span className="persName">Mr. JOHNSON</span>—</div>
              <p className="p-in-sp">I trust that there are none there, as I am confident that we have none here. If I may be permitted to take that liberty with my friend, I would say to him that he should seek authenticity for his information, beyond the calumnies which are daily circulating in the newspapers of the city of Richmond.</p>
              <p className="p-in-sp">
                Sir, as one of the representatives of the city of Richmond, and the humblest, I repeat the assertion, that there are no Abolitionists here. I am not prepared to say that there are any in the District from which the gentleman comes. One thing is certain, that there were votes recorded in his District for Abraham Lincoln, and that the poll book will show none in the city of Richmond.
              </p>
              <p className="p-in-sp">
                I trust that I may not be understood as making any assault upon any section of the State. I, for one, as a representative of the city of Richmond, am also a representative of this whole State; and I think we are dwindling down and condescending very much in this discussion, when we permit ourselves to criminate and recriminate one another upon local prejudices. We have a higher duty
                to perform here—we have a holier and more sacred function to discharge. Our country is in danger. The cause of republican liberty is at stake. The most magnificent temple of republican freedom that ever glittered in the sun, and that was ever vouchsafed by God or ordained by Him, is now tottering; <PageNumber num={34} /> and, for one, I protest against
                complicating the consideration of that great, comprehensive question with the question of taxation, or with any other local questions calculated to excite local prejudices. What good can be produced? What harm will not be produced, if we should allow ourselves to be drawn off into the consideration of other subjects than the great question? How can the rights and the dignity of the
                occasion be maintained, and how shall we do justice to the great and solitary question which we were called here to consider? I, therefore, protest against any attempts on the part of gentlemen from any part of the State to excite local prejudices, and to complicate and embarrass the consideration of the first great question which we have to consider.
              </p>
              <p className="p-in-sp">We have now from the lips of the gentleman from Northampton [<span className="persName">Mr. FISHER</span>]
                a proposition made upon this floor, to the West, that, if they will disrupt this Government, they can have their own terms of taxation. I had heard rumors to this effect before, that such a proposition was entertained, but I thought it unfounded; but I hear the proposition from the gentleman himself made to the gentleman from Preston, that, if they will come over and disrupt the ties
                which bind us to our Union and the Constitution, then they can have their own terms upon the subject of taxation.
              </p>
              <div className="speaker" id="sp803"><span className="persName">Mr. NEBLETT</span>—</div>
              <p className="p-in-sp">The gentleman from Northampton made the statement he did upon his own responsibility. I make this statement because the gentleman is absent.</p>
              <div className="speaker" id="sp804"><span className="persName">Mr. JOHNSON</span>—</div>
              <p className="p-in-sp">
                But I was correct in saying that he made the proposition this morning. And he had the temerity, at the same time, to say that he did it without consultation with his own constituents. I trust in God that he will not be sustained by his constituents upon that reckless proposition, but that he will stand solitary and alone. It does seem to me, therefore, that we are not meeting this
                question as we should do. Gentlemen from the East and from the West, whatever may be their local interest or their opinions upon the question of taxation, should lay aside all petty considerations, for I regard the subject of taxation in the present crisis as no more than the smallest particle of attenuated matter that floats in space, of universal creation. I shall with pleasure
                vote for laying the resolution on the table, hoping that the gentlemen from the West will see that it is an inopportune occasion to press the consideration of the taxation question and that the effect of it can only be to embarrass the consideration of the great question which we are here to consider.
              </p>
              <p><span className="persName">Mr. WOODS</span> addressed the Convention at some length upon the same <PageNumber num={35} /> subject, but the hour of half past twelve having arrived before he had concluded his remarks, [The speech will be given entire, when finished.]
                <span className="note" id="Note7"><span className="noteLabel">3</span>This speech by Mr. Woods was printed in the Enquirer as if entirely contained in the Proceedings of March 19. However, the Daily Dispatch published, on March 19, an abstract of the first three paragraphs of the speech, and related proceedings, as part of the Proceedings of March 18.</span>
              </p>
              <p><span className="persName">Mr. DORMAN</span> moved that the farther execution of the order be suspended.</p>
              <div className="speaker" id="sp805"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I trust that my friend will not insist upon his motion now. This subject is to be ventilated for a longer time than the Convention would now be willing to accord, and it would therefore be more agreeable, I have no doubt, to the friends of the resolutions, that their consideration would no longer be proceeded with for the present.</p>
              <div className="speaker" id="sp806"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">I withdraw my motion.</p>
              <div className="speaker" id="sp807"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Before vacating the chair I beg leave to lay before the Convention a communication that I have received from the Hon. Wm. Mitchell, E. F. Chambers, Wm. Henry Norris, Isaac D. Jones and J. Hanson Thomas, citizens of the State of Maryland, a Committee lately appointed by a Convention held in that Commonwealth, with a request that it be laid before this body.</p>
              <p>The communication was then read as follows:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>To the Honorable, the President of the Convention of the People of</p>
                  <p>Virginia:</p>
                  <p>
                    The undersigned, citizens of Maryland, have been appointed a Committee on behalf of a Conference Convention, representing a portion of the citizens of the city of Baltimore, and of nearly all the counties of Maryland, to communicate with the Convention over which you preside in reference to a matter in which they deem themselves deeply concerned, and which is now occupying the
                    attention of your body. It is upon the subject of the proposed conference with the Southern border States.
                  </p>
                  <p>
                    It is especially to be understood, that in anything we may say, there must be no inference that this particular measure, or any other is, in our judgment, or in the opinion of those persons whom we represent, to be preferred. We entirely disclaim every purpose even to suggest what would be the judgment of our constituents, did the occasion make it proper to express it. Our design
                    is solely to invite the attention of the Convention to the importance of one particular consideration, if and when the proposed conference shall be found acceptable to your Convention. In that event, believing (as those whom we represent have instructed us to say, they do believe) , that the people of Maryland will accept such an invitation, it becomes, as we respectfully
                    suggest, a matter of equal interest to each and all of the Southern border States <PageNumber num={36} /> to secure, as far as it may be done, a full, fair and accurate expression of the popular will, in such form as to leave no doubt either of its character, or of the authority of those who may be selected as its agents and representatives.
                  </p>
                  <p>The proposition before your body requires the delegates to the Conference to be selected by the "proper authorities of those States."</p>
                  <p>If any existing organized department of the government of Maryland should attempt the selection of delegates to such a Convention as is proposed, it would undoubtedly be resisted by the people, and in that event, conflicting claimants would present themselves as rightful exponents of the popular sentiments, duly entitled to be receivable as such.</p>
                  <p>Our sole object is to avoid, by the terms of the invitation, all occasion for such collision; and with this view, we respectfully suggest such an alteration in the language of your resolution, as will express its object to be, that delegates to the proposed Convention shall be elected either directly by the people, or through the agency of a sovereign State Convention.</p>
                  <p>In the hope and expectation that this request will be favorably considered by your Convention,</p>
                  <p>We have the honor to be,</p>
                  <p>Very respectfully,</p>
                  <p>Your obed't serv'ts,</p>
                  <p>W. MITCHELL,</p>
                  <p>E. F. CHAMBERS,</p>
                  <p>WM. HENRY NORRIS, ISAAC D. JONES,</p>
                  <p>J. HANSON THOMAS.</p>
                </div>
              </div>
              <div className="speaker" id="sp808"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I move that the communication be laid upon the table and printed.</p>
              <div className="speaker" id="sp809"><span className="persName">Mr. MOORE</span>, of Rockbridge—</div>
              <p className="p-in-sp">
                I hope that it will not be printed. I think we have been annoyed enough already with county meetings and speeches in our own State; but when gentlemen come here from another State, authorized by, I don't know whom, not by the authority of the State, at least, to suggest what we ought to do or not do, I think we ought not to give so much respect to their communication as to print it.
              </p>
              <div className="speaker" id="sp810"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">I am sorry I differ with my friend from Rock-bridge [Mr. MOORE] . I know something of the character of the gentlemen <PageNumber num={37} /> whose names are signed to that communication, and I think that their character alone, if nothing else, entitles them to respect.</p>
              <p className="p-in-sp">I move, if it be in order, that the communication be referred to the Committee on Federal Relations, to see whether any plan can be adopted to promote the object indicated in that communication.</p>
              <div className="speaker" id="sp811"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I hope the gentleman will include in his motion, "and that they be printed."</p>
              <div className="speaker" id="sp812"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">I also move that the communication be printed. Both motions were agreed to.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.1.3">
              <h3><span className="headingNumber">1.1.3. </span><span className="head">COMMITTEE OF THE WHOLE</span></h3>
              <p>The Convention then went into a Committee of the Whole on the reports of the Committee on Federal Relations, <span className="persName">Mr. SOUTHALL</span> of Albemarle in the Chair.</p>
              <p>
                <span className="persName">Mr. GEO. W. RANDOLPH</span> being entitled to the floor resumed his address. He spoke for nearly two hours, but had not concluded when the Committee rose. He will resume again to-morrow. His speech will appear in full hereafter.<span className="note" id="Note8"
                ><span className="noteLabel">4</span>Mr. Randolph's entire speech is given in the Proceedings of March 16. See note 3 on those Proceedings.</span
                >
              </p>
              <div className="speaker" id="sp813"><span className="persName">Mr. CHAMBLISS</span>—</div>
              <p className="p-in-sp">It is perfectly obvious to the Convention that the gentleman who has the floor is physically unable to pursue his argument to its close to-day. As it is a very interesting and important one, I now move that the Committee rise, in order that the gentleman may finish to-morrow.</p>
              <div className="speaker" id="sp814"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">I feel unwilling to throw myself again upon the indulgence of this body. They have indulged me so often, that I should be unwilling to trespass again upon their time.</p>
              <div className="speaker" id="sp815"><span className="persName">Mr. JOHNSON</span>—</div>
              <p className="p-in-sp">I would appeal to my friend to entertain no such feelings of delicacy. He has spoken a long time, and I trust that the motion that the committee rise will prevail.</p>
              <p>The motion was agreed to. The committee then rose and the chairman reported progress.</p>
              <p><span className="persName">Mr. DORMAN</span> offered the following resolutions, which were referred to the Committee on Federal Relations:</p>
              <p>
                "Resolved, That the Committee on Federal Relations inquire into the expediency of amendments to the Constitution of the United States, being submitted by this State to other States of the Union, providing and declaring (1st) that electors of President and Vice-President shall be chosen on the District system; and (2nd) that persons of African blood, in whole or in part, are not and
                shall not be citizens of the United States, or citizens within the meaning of the 2nd section of the 4th article of the Federal Constitution.
              </p>
              <PageNumber num={38} />
              <p>"And, further, whether such amendments should form part of any ultimatum laid down by Virginia or the border States of the South, or should be submitted separately and distinct from such ultimatum."</p>
              <p>On motion of <span className="persName">Mr. HAYMOND</span>, the Convention then adjourned.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v2.2.2">
            <PageNumber num={39} />
            <h2><span className="headingNumber">1.2. </span><span className="head">TWENTY-NINTH DAY</span></h2>
            <div className="dateline">Tuesday, <span className="date">March 19</span></div>
            <p>The Convention met at half-past 10 o'clock.</p>
            <p>Prayer by the Rev. Mr. Woodbridge, of the Episcopal Church.</p>
            <div className="section" id="vsc1965.v2.2.2.1">
              <h3><span className="headingNumber">1.2.1. </span><span className="head">PERSONAL EXPLANATION</span></h3>
              <div className="speaker" id="sp816"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">Before the debate commences upon the business regularly before the Convention, I propose to set myself right, not only before this body, but before the people of the Commonwealth, in respect to the resolutions of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] .</p>
              <p className="p-in-sp">
                I observe in the Richmond "Whig" of this morning, that my remarks upon those resolutions have been very much misunderstood. It seems to have been supposed by the editors of that paper, that I made a proposition in my representative, or some other capacity, to the Convention. Mr. President, I made no proposition. On the contrary, I stated distinctly that I was not authorized by my
                constituents to act upon this question of taxation in this Convention. I stated distinctly that it was a question which had not been considered by them as coming legitimately within the scope of the action of this body at the time I was elected.
              </p>
              <p className="p-in-sp">I am very sure that if the editor of the "Whig" had known my position as I stated it—because I know that he had no motive to misrepresent me—he would not have indulged in the comments which appear in the columns of that paper of this morning.</p>
              <p className="p-in-sp">I was unfortunate in being misunderstood, even by some gentlemen upon this floor. My friend from Richmond [<span className="persName">Mr. JOHNSON</span>] who took part in the debate while I was warming my feet in the Secretary's office, and who I, therefore, did not hear, I find by the report of his remarks, this morning, alluded to a remark from me as a proposition coming from me. He seems to suppose that I had offered a carte blanche to the West, if they would accede to another proposition.</p>
              <p className="p-in-sp">
                Now, sir, as I have remarked, I made no proposition, and I deem it due to myself to re-state the position I assumed in regard to the resolutions of the gentleman from Monongalia. I referred, without quoting it, to an article which appeared in the "Examiner" of yesterday morning, and I remarked, as I say now, upon the same principle which controlled our ancestors in forming the
                present Federal Constitution, as an individual, while I could not act for my constituents, I say, as an individual, I would agree that a tax should be placed upon slaves under <PageNumber num={40} /> twelve years of age, and upon all slaves according to their value, as upon any other species of property, if they would give me an ordinance of secession.
                My friend, however, supposed that I made the proposition in my representative character, which I not only did not attempt to do, but which I expressly disclaimed. Sir, I repeat, that, as an individual, I am ready to say to the North-West and to every portion of Virginia, that if they will give me an ordinance of secession which will place the people of this Commonwealth, as the
                brightest star in the Southern Confederacy, they may have an adjustment of this question of taxation. I made that remark as an individual; I reiterate it now, and, in so doing, I am acting upon the same principle on which our ancestors acted in the Convention which framed the Federal Constitution, when they consented that three-fifths of our slaves should enter into the basis of
                representation and taxation. That is my position as an individual; and if such an expression of opinion by me, is of sufficient importance to meet the consideration of gentlemen from the North-West, they can have it as often as they please. The portion of the article in the "Examiner," to which I made allusion, is in these words:
              </p>
              <p className="p-in-sp">
                "The introduction of these resolutions are not, however, to be much regretted. Since the first day of this Convention, the proposition of Willey and his Republican friends has been held over the head of the State as the last and dire resort of the Submissionists to reduce her to subjection. If Virginia will not rest quiet under Lincoln, and leave off talking of secession, resistance
                and the like, then Monongalia and the Pan Handle shall be let loose on them, and high taxation put on the negroes. This has been the sheet-anchor of the Submissionist party. Now we have their threat before us; this is their coercion. We know the worst that they can do; and so far from being frightened at it, we are sure that every slaveholder in the State is ready, if necessary, to
                make this bargain with Willey and his pedlars, that the East will surrender the basis in the Senate, and vote for the taxation of negroes without a protest or a delay, if they, on their side, will pass the ordinance of secession. There is no Virginian slaveholder who is not ready to encounter higher taxes on his negroes to gain this security, that they will still be his without the
                necessity of an exodus. But to make this Convention, called for the State's deliverance, the mere instrument of a new robbery and oppression without that equivalent, is more than honest men, of any section or any political persuasion, will support."
              </p>
              <div className="speaker" id="sp817"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">Will the gentleman from Northampton permit me to ask what paper he is reading from.</p>
              <div className="speaker" id="sp818"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">From the "Examiner "</p>
              <PageNumber num={41} />
              <div className="speaker" id="sp819"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">At what point is that paper published?</p>
              <div className="speaker" id="sp820"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">In the city of Richmond.</p>
              <div className="speaker" id="sp821"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">Is it?</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp822"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">It is published in the city of Richmond, as the gentleman from Preston has very good reason to be well aware of. I referred to this paragraph, Mr. President, and expressed my concurrence in it as an individual.</p>
              <p className="p-in-sp">
                In regard to another matter. I find myself unfortunately reported in the "Enquirer," in the remarks I submitted yesterday in regard to the action of one of the highest officers of our government, in bringing before this body this question of taxation. I referred to another portion of this same article in the "Examiner," and in that connexion, as reported, I am made to praise the man
                whose conduct I intended to condemn. The paragraph to which I referred is this:
              </p>
              <p className="p-in-sp">
                "When a signer of the Ruffner pamphlet, who had through much apparent repentance gotten to be a high officer of the State, and whose violent propositions had done more than anything else to produce that re-actionary panic and confusion through which that thoroughly bad body of Black and Brown Republicans, Federalists and Abolitionists, who make the majority of the Convention, crept
                and climbed and leaped into their places; when this son of Ruffner heard the result of the election he declared with a joy too triumphant for concealment, that the Convention, called to defend the South against Abolition, would 'just put up ad valorem taxes on all slave property, change the basis of representation and then go home.'"
              </p>
              <p className="p-in-sp">
                That sentiment, thus expressed by one of the chief officers, was what I meant to level my anathemas at. I intended to denounce the action of a high officer of this Commonwealth which had for its object the bringing on of a domestic as well as an international quarrel. I need say nothing in regard to the editors of the "Examiner" who made the allegation. They are able to vindicate and
                defend themselves.
              </p>
              <p className="p-in-sp">It is my purpose not to be offensive to any gentleman upon this floor, and it is for that reason I have deemed this explanation necessary to show that my remarks have been misunderstood.</p>
              <p className="p-in-sp">I beg now sir, to say in conclusion, that I have offered no proposition except as an individual. I stated distinctly that my people have never been called upon to consider the subject, but that I as an individual, will agree to make a contract for maintaining the integrity of the Commonwealth and to promote her interests and vindicate her honor.</p>
              <div className="speaker" id="sp823"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The pending question is upon the adoption of the resolutions offered by the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , <PageNumber num={42} /> and upon that question the gentleman from Barbour [<span className="persName">Mr. WOODS</span>] , is entitled to the floor.</p>
              <div className="speaker" id="sp824"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">By the kind permission of the gentleman from Barbour [<span className="persName">Mr. WOODS</span>] , I propose this morning to offer a resolution to be referred to the Committee on Federal Relations, and to accompany that resolution, sir, with a remark or two, which will occupy not more than five minutes. I will read the resolution before sending it to the Secretary's desk:</p>
              <p>
                Resolved, That the Committee on Federal Relations are hereby requested to report the Constitution of the Confederate States of the South, as Virginia's ultimatum, and that they recommend the same to the Northern States of this Confederacy for their adoption or rejection, and in order to give them time to act on the same, this Convention will adjourn to meet again on the first Monday
                in October, 1861.
              </p>
              <div className="speaker" id="sp825"><span className="persName">Mr. HALL</span>—</div>
              <p className="p-in-sp">
                Now, sir, we have heard a great deal of talk about the Union, the love of the Union, the reconstruction of the Union, and all of this sort of thing. If gentlemen who are extra Union men, desire and want to perpetuate or reconstruct this Union, they cannot object to this proposition. I take it for granted, sir, that they are all in love with the present Constitution of the United
                States; that they have no objection to many of its provisions and that they will take them as the basis for their action. I imagine, sir, that there is hardly a man on this floor who does not concede the propriety and the necessity of amendments to the present Constitution. I take it for granted, sir, from the report of the Committee on Federal Relations, also from the various
                resolutions referred to that Committee, that this body is unanimous in demanding additional guarantees or, at least, amendments to the present Federal Constitution. But there is a great variety of opinions about this matter—what these amendments should consist of, and especially as to the manner of getting at them. I imagine also, sir, that it is the desire of this Convention, of the
                representatives of the people of Virginia, to bring back, if possible, our Southern sisters to this Confederacy; and it has been circulated that Mr. Seward, the high Priest of the present order of things in this Confederacy as well as the papers of the North, have said, repeatedly, that they were ready to give us all we might ask for. They are represented as having said to us: state
                your demands, let us know what you want, what are your grievances—state them in plain terms so that we may know exactly what you desire, and we will satisfy you—we will give you what you demand.
              </p>
              <p className="p-in-sp">
                We find that the people of the Confederate States went to work, taking the present Constitution of the United States as their basis, and <PageNumber num={43} /> have made some twelve important amendments to that great instrument. The Constitution which they have adopted is nothing more nor less than the old house dressed up, repainted, and fixed to suit
                the present order of things.
              </p>
              <p className="p-in-sp">The first of these twelve important amendments provides that the President's term shall be limited to six years, and makes him ineligible for a second term. That sir, will strike every man as proper and wise.</p>
              <p className="p-in-sp">The third amendment provides that representatives shall be of a certain age; but there is no limit as to the length of residence or the number of years that he shall live in the State after becoming a citizen.</p>
              <p className="p-in-sp">The fourth amendment imposes a restriction upon persons of foreign birth, not citizens.</p>
              <p className="p-in-sp">
                Now, sir, that provision will strike every man as a matter of prime necessity. We all know that in the States of Iowa, Minnesota, Illinois, Indiana, and other Western States, that men, not more than ten days in the State, nor probably more than ten days from Europe, have often controlled the elections in those States. I have been myself for a few years a resident in the West, and am
                somewhat familiar with the facts which I state. I think, sir, that no man will question the propriety of this restriction.
              </p>
              <p className="p-in-sp">The fifth amendment provides that no person shall be removed from office without a specification in writing to the Senate, by the President, setting forth the reasons of dismissal.</p>
              <p className="p-in-sp">The seventh amendment provides that all bills for appropriation shall specify the amount of each appropriation and the purpose for which it is appropriated; and also provides that no extra compensation shall be allowed to contractors.</p>
              <p className="p-in-sp">The eighth amendment provides that every law shall relate to but one subject. Under this, you are prevented from passing an omnibus bill embracing various objects.</p>
              <p className="p-in-sp">The ninth amendment provides that the principal officers of the Departments, or at least their Cabinet officers, shall be entitled to seats on the floor of Congress. It does not, however, give them the privilege of voting, but merely the privilege of explanation, such as is possessed by the Cabinet officers of the Queen of England.</p>
              <p className="p-in-sp">The tenth amendment provides that the President cannot pardon in case of impeachment; and it is also provided that the General Government may impeach, and that a State may impeach. And finally it is provided in the 12th amendment that the word "slave" shall be substituted in the Constitution for the word "servant."</p>
              <p className="p-in-sp">Now, I appeal to the Union gentlemen on this floor, and I appeal <PageNumber num={44} /> to the members of the Committee on Federal Relations, and especially to its distinguished Chairman [<span className="persName">Mr. R. Y. CONRAD</span>] —I appeal to him, as he has indicated the purpose of the Committee to report amendments to the Constitution—to take this Constitution adopted by the Confederate States and recommend it to the Convention for its adoption.</p>
              <p>The resolution was then read as follows :</p>
              <p>
                Resolved, That the Committee on Federal Relations are hereby instructed to report the Constitution of the Confederated States as Virginia's ultimatum, and that they recommend the same to the Northern States of this Confederacy for their adoption or rejection, and in order to give them time to act on the same, this Committee will adjourn to meet again on the first Monday in October,
                1861.<span className="note" id="Note9"><span className="noteLabel">1</span>This version of the resolution differs from what Mr. Hall had read out when he proposed the resolution. Probably the version on page 32, which agrees with the version given in the Journal, is correct.</span>
              </p>
              <div className="speaker" id="sp826"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">I move to lay the resolution on the table.</p>
              <div className="speaker" id="sp827"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I rise to a question of order. We have now a question pending before the Convention upon the resolution of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>]
                . I submit whether it is competent to offer any resolution at this time, requiring the vote of the Convention. The gentleman who offered these resolutions, as I understand, got leave from the gentleman from Barbour to introduce them, as I suppose, for the purpose of having them read and referred to the Committee on Federal Relations. The point of order I make is, whether these
                resolutions can be offered in the form in which they are now presented, while this other question is pending.
              </p>
              <div className="speaker" id="sp828"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">
                The practice of the Convention has been by general consent early in the morning for gentlemen entitled to the floor to give way for various motions. This resolution under the statement of the gentleman from Wetzel, the Chair supposed would, as a matter of course, go to the Committee on Federal Relations. The Chair finds that it is one of such a character that it cannot go to that
                Committee without the consent of that body. The motion to lay on the table would, therefore, be in order.
              </p>
              <p>The question was taken, on the motion of <span className="persName">Mr. CONRAD</span>, and it was agreed to.</p>
              <p>So the resolutions were laid on the table.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.2.2">
              <h3><span className="headingNumber">1.2.2. </span><span className="head">REPORT OF THE COMMITTEE ON FEDERAL RELATIONS</span></h3>
              <div className="speaker" id="sp829"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">May I ask the consent of the Convention to make a report from the Committee on Federal Relations?</p>
              <div className="speaker" id="sp830"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">It will be in order if the pending question is laid upon the table for the purpose of receiving the report.</p>
              <PageNumber num={45} />
              <div className="speaker" id="sp831"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">It might be done by general consent.</p>
              <div className="speaker" id="sp832"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair hears no objection, and the report that the gentleman desires to offer will be in order.</p>
              <div className="speaker" id="sp833"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">
                The Committee on Federal Relations have had under further consideration the reports referred to them, and have directed me to make a report upon the subject of the proposed amendments to the Constitution of the United States, which I will send to the Clerk's desk. These amendments, I may state, in general, are neither of the schemes precisely which have been submitted for the
                consideration of the Convention, to the extent of being properly termed either the one or the other. They are Virginia's propositions of amendments to the Constitution, combining, in view of the Committee, all the advantageous features of both of Virginia's schemes that have hitherto been before the public mind.
              </p>
              <p className="p-in-sp">I move that the report be printed and referred to the Committee of the Whole.</p>
              <p>The report was then read by the Secretary as follows:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>The Committee on Federal Relations have, according to order, had under consideration sundry resolutions to them referred, and amendments proposed to the Federal Constitution, and beg leave to report the following amendments to be proposed to the Constitution of the United States, to be appended to their former report:</p>
                  <p>ARTICLE XIII</p>
                  <p>
                    SECTION 1. In all the present territory of the United States, North of the parallel of thirty-six degrees and thirty minutes of North latitude, involuntary servitude, except in punishment of crime, is prohibited. In all the present territory South of that line, involuntary servitude, as it now exists, shall remain and shall not be changed; nor shall any law be passed by Congress
                    or the Territorial Legislature to hinder or prevent the taking of persons held to service or labor from any of the States of this Union to said territory, nor to impair the rights arising from said relation; nor shall said rights be in any manner affected by any preexisting law of Mexico; but the same shall be subject to judicial cognizance in the federal courts, according to the
                    remedies and the practice of the common law. When any territory North or South of said line, within such boundary as Congress may prescribe, shall contain a population equal to that required for a member of Congress, it shall, if its form of government be republican, be admitted into the Union on an equal footing with the original States, with or without involuntary servitude, as
                    such Constitution of the State may provide. In all territory <PageNumber num={46} /> which may hereafter be acquired by the United States involuntary servitude is prohibited, except for crime, North of the latitude of 36 deg. and 30 min.; but shall not be prohibited by Congress or any Territorial Legislature South of said line.
                  </p>
                  <p>
                    SECTION 2. No territory shall be acquired by the United States, except by discovery and for naval and commercial stations, depots and transit routes, without the concurrence of a majority of all the Senators from States which allow involuntary servitude, and a majority of all the Senators of States which prohibit that relation ; nor shall territory be acquired by treaty, unless
                    the votes of a majority of the Senators from each class of States herein before mentioned be cast as a part of the two-thirds majority necessary to the ratification of such treaty.
                  </p>
                  <p>
                    SECTION 3. Neither the Constitution, nor any amendment thereof, shall be construed to give Congress power to legislate concerning involuntary servitude in any State or Territory wherein the same is acknowledged or may exist by the laws thereof, nor to interfere with or abolish the same in the District of Columbia without the consent of Maryland and Virginia, and without the
                    consent of the owners, or making the owners who do not consent just compensation; nor the power to interfere with or prohibit representatives and others from bringing with them to the District of Columbia, retaining and taking away, persons so held to labor or service, nor the power to interfere with or abolish involuntary service in places under the exclusive jurisdiction of the
                    United States within those States and Territories where the same is established or recognized; nor the power to prohibit the removal or transportation by land or water of persons held to labor, or involuntary service in any State or Territory of the United States to any other State or Territory thereof where it is established or recognized by law or usage; and the right during
                    transportation, by sea or river, of touching at ports, shores and landings, and landing in case of need, shall exist, but not the right of sojourn or sale in any State or Territory, against the laws thereof. Nor shall Congress have power to authorize any higher rate of taxation on persons held to labor or service than on land.
                  </p>
                  <p>The bringing into the District of Columbia persons held to labor or service for sale, or placing them in depots to be afterwards transferred to other places for sale as merchandise, is prohibited.</p>
                  <p>
                    SECTION 4. The third paragraph of the second section of the fourth article of the Constitution shall not be construed to prevent any of the States, by appropriate legislation, and through the action of their <PageNumber num={47} /> judicial and ministerial officers, from enforcing the delivery of fugitives from labor to the person to whom such
                    service or labor is due.
                  </p>
                  <p>SECTION 5. The importation of slaves, coolies or persons held to service or labor in the United States and the Territories, from places beyond the limits thereof, is hereby forever prohibited.</p>
                  <p>
                    SECTION 6. Congress shall provide by law that the United States shall pay to the owner the full value of his fugitive from labor, in all cases where the marshal, or other officer, whose duty it was to arrest such fugitive, was prevented from so doing by violence or intimidation from mobs, or riotous assemblages, or by violence, or when, after arrest, such fugitive was rescued by
                    like intimidation or violence, and the owner thereby deprived of the same.
                  </p>
                  <p>SEC. 7. The elective franchise and the right to hold office, whether Federal or Territorial, shall not be exercised by persons who are of the African race.</p>
                  <p>SEC. 8. No one of these amendments nor the third paragraph of the second section of the first article of the Constitution, nor the third paragraph of the second section of the fourth article thereof, shall be amended or abolished without the consent of all the States.</p>
                </div>
              </div>
              <p>The report was laid on the table and ordered to be printed.</p>
              <div className="speaker" id="sp834"><span className="persName">Mr. HALL</span>—</div>
              <p className="p-in-sp">With the permission of the gentleman from Barbour [Mr. Woons] , I desire to offer the following resolution:</p>
              <p>
                Resolved, That in future, until otherwise ordered, this Convention shall meet as at present at half-past 10 o'clock, A.M., remain in the session until half-past two o'clock, P.M., then take a recess, and again assemble at quarter to 8 o'clock, P.M.<span className="note" id="Note10"
                ><span className="noteLabel">2</span>A correction (March 21) notes that the resolution regarding a night session of the Convention was offered by Mr. George W. Hull, of Highland, and not by Mr. Hall.</span
                >
              </p>
              <div className="speaker" id="sp835"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I move that the resolution lie on the table.</p>
              <p>The motion was agreed to, so the resolution was laid on the table.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.2.3">
              <h3><span className="headingNumber">1.2.3. </span><span className="head">PROCEEDINGS OF A MEETING AT PETERSBURG</span></h3>
              <div className="speaker" id="sp836"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">
                I beg to intrude upon the time of the gentleman from Barbour for a few moments. I hold in my hands a letter from Wm. T. Joynes, of Petersburg, chairman of one of the largest meetings ever held in my city, covering resolutions passed by that meeting, addressed to this Convention, and requesting them to take cognizance of them. I will ask the Secretary to read them, and then I would
                like to make a few remarks upon them.
              </p>
              <p>The resolutions were then read by the Secretary as follows:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>MEETING OF THE CITIZENS OF PETERSBURG</p>
                  <p>In pursuance of the annexed call, published in the daily papers on <PageNumber num={48} /> Monday, the 11th inst., a large concourse of the citizens of Peters-</p>
                  <p>burg met at Phoenix Hall, on the evening of that day, at eight o'clock,</p>
                  <p>P. M.:</p>
                  <p>"To THE VOTERS OF PETERSBURG-All true lovers of Constitutional Liberty, and of the Union, as our fathers formed it, are requested to meet at Phoenix Hall, on Monday night, at eight o'clock, in order to instruct our Delegate in the State Convention, now assembled, to go for immediate secession. Let there be a grand rally of the people. MANY CITIZENS."</p>
                  <p>The meeting was organized by the election of Wm. T. Joynes as Chairman, and A. F. Crutchfield and John Lyon, Secretaries.</p>
                  <p><span className="persName">Mr. C. F</span>. Collier offered the following resolutions :</p>
                  <p>
                    1. Resolved, as the opinion of this meeting, which is one of the largest ever assembled in this city, That the point of substantial dispute, from which all minor disputes radiate as from a common centre, between the slaveholding and the non-slaveholding States, is whether negroes are property; and that the observance, in all legislation by the General Government and by the States
                    of the Constitutional fact, in our complex system, as we understand it, that negro slaves are property, is the orbit in which the Federal Union, now in the darkness of disaffection and disloyalty, must henceforth move, in order to make that Union again illustrious with the beams of the old-time brotherhood; and that that orbit alone, and no other, is competent to make the wasting
                    powers of that Union and the drooping prospects of the whole country revive and rejoice.
                  </p>
                  <p>
                    2. Resolved, That in the opinion of this meeting, the Convention now deliberating in Richmond, ought to propose to be ratified by the people, an ordinance to the following effect: That, whereas, the non-slaveholding States have refused to accord much less in the way of Compromise than the slaveholding States are entitled to, as is evidenced by the refusal of Congress to accept
                    the proposal of the Peace Conference; now, therefore, we, the people of Virginia, will resist the authority of the Union over us; and do ordain that the powers thereto formerly delegated, be resumed; and do declare this State absolved from any allegiance to that Union; and, furthermore, that we, the people of Virginia, never will re-unite in any Confederacy with those States, or
                    any of them, except on the basis that negroes held to service are property, as well as persons, and that the element of property in them must be created as property in the territories, and especially in the matter of the delivery of fugitives from labor when escaped into any of those States or into any territory.
                  </p>
                  <PageNumber num={49} />
                  <p>
                    3. Resolved, That such ordinance might appropriately prescribe some period, with short interval, but ample, within which this, our demand must be acceded to, in the way of amendment to the Constitution of the United States, by the non-slaveholding States; so that the attention of THE PEOPLE of those States shall be drawn to the point on which alone future fraternity is possible;
                    but be it understood that this leaving of the door open for overtures from the people of those States, is only intended as a condition of re-union subsequent to secession or revolution, and not as a condition precedent.
                  </p>
                  <p>
                    4. Resolved, That whilst we do not deem it indispensable, as the action of the Convention must come back to us, that we shall send up instructions, in mandatory form, to our delegate, Mr. Branch, yet we hereby request him to present our proceedings to that body; and, therefore, it is ordered that a copy be certified to him for that purpose, and that he be requested to take heed
                    thereto.
                  </p>
                  <p>Mr. George W. Bolling offered the following as a substitute for Mr. Collier's resolutions:</p>
                  <p>Resolved, That having full confidence in the intelligence, patriotism and firmness of our delegate in the Convention, for the protection of Southern rights and honor, it is inexpedient to instruct him on any question now pending before the Convention.</p>
                  <p>Mr. Collier's resolutions were advocated by himself, <span className="persName">Mr. R. R</span>. Collier and Hon. R. A. Pryor.</p>
                  <p>Mr. Bolling's substitute was advocated by himself and Mr. John Lyon.</p>
                  <p>During Mr. Lyon's speech he gave way to Mr. Pryor, who moved that the resolutions and substitute be laid on the table and that the meeting adjourn.</p>
                  <p><span className="persName">Mr. C. F</span>. Collier and R. R. Collier each, with the consent of the meeting, made a few remarks by way of explanation.</p>
                  <p>Mr. Pryor subsequently withdrew his motion.</p>
                  <p><span className="persName">Mr. R. R</span>. Collier then offered the following resolution:</p>
                  <p>
                    Resolved, That the resolutions offered to this meeting by Charles F. Collier, Esq., and the substitute therefor offered by Col. George W. Bolling, be published in the newspapers of this city to-morrow (Tuesday), and the next day (Wednesday) , and the next day (Thursday) , and that a poll be opened at the Court House, on each of those days, at which the legally qualified voters of
                    the city may vote aye or no on the said resolutions of Mr. Collier, and aye or no on the substitute of Col. Bolling.
                  </p>
                  <PageNumber num={50} />
                  <p>Which was adopted.</p>
                  <p>On motion, the meeting adjourned.</p>
                  <p>WM. T. JOYNES, Chairman.</p>
                  <p>JOHN LYON, A. F. CRUTCHFIELD, Secretaries.</p>
                </div>
              </div>
              <div className="speaker" id="sp837"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">
                Mr. President, the resolutions having been read to the Convention, I shall make no allusion to them, except that portion which makes reference to myself. The members of this Convention well know my position here, they know upon what platform I have stood, and stand now in my own individual opinion ; and that is, to demand all the just rights of Virginia in the Union, and if they are
                not granted, to leave the Union. I was elected upon that platform. Previous to the election, during the most interesting, pleasant, and excited canvass I ever witnessed, I even stated that I believed in the right of instructions ; that my people had only to make known their wishes to me and I would carry them out to the best of my ability, or I would vacate my seat and they might
                fill it with some other person more worthy to represent them. Of course you readily see I acknowledge mandatory instructions, instructions by request, or by the known will of my people coming from my quarter; I consider them all synonymous, and I believe it to be my imperative duty to carry out the will of the people whom I represent.
              </p>
              <p className="p-in-sp">
                Having made those few remarks, I am bound to say further, sir, that I now bow with submission to the will of my constituents. I am satisfied, from the vote which was taken most excitedly and anxiously by both parties, that it is the will of my people that this Convention should pass an ordinance of secession. And I have that assurance not only from the vote upon the resolutions, but
                I have it upon the authority of gentlemen who took an active part in opposition to them. My people have changed very suddenly
                [laughter] , and they have a right to change again so soon as the daylight breaks upon our hopes; and when they do, I shall be ready to carry out their views then. [Renewed laughter.] I do not know that it is necessary for me to say anything more than that I make these remarks simply that my position might be fully understood in this Convention. I will ask that the resolutions be printed and referred to the Committee on Federal Relations.</p>
              <p>They were so referred.</p>
              <div className="speaker" id="sp838"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">Mr. President, if the gentleman from Barbour will allow me, I desire to make a motion which will not detain the Convention a moment.</p>
              <PageNumber num={51} />
              <div className="speaker" id="sp839"><span className="persName">Mr. WOODS</span>, of Barbour—</div>
              <p className="p-in-sp">
                I am compelled, very much against my will, to decline yielding the floor any longer at this time.<span className="note" id="Note11"><span className="noteLabel">3</span>It appears that the first three paragraphs of Mr. Woods' speech were delivered on March 18. See note 3 on Proceedings of that day.</span>
              </p>
              <p className="p-in-sp">
                Mr. President, I trust in the few remarks I may have occasion to submit to the Convention this morning, that I shall not suffer myself to be carried away by the whirlwind of excitement which seems to be passing over this body, the moment this great question of State interest is broached here. I am sorry to see it. I would much rather have preferred—my people would much rather have
                preferred—if the gentlemen from Eastern Virginia, who are the peculiar champions of this interest, would have manifested the magnanimity that our people expected they would have done, and upon their responsibility, to present this question in such a shape, that the members from Western Virginia would have had nothing but to endorse it. I am free to confess that I am somewhat
                surprised at the latitude which this debate has taken. I understand that the proposition is merely for the purpose of raising a Committee to whom all these questions may be referred, that they may take such action upon it as they deem proper.
              </p>
              <p className="p-in-sp">
                I regret, then, to see so much tenderness manifested in regard to this question. I regret to see the members of this body exhibiting so much feeling in regard to this matter, and intimating that we have sought a most inopportune time in which to consider the single question of taxation of negro property, when there are other interests, in which some of these gentlemen who participate
                in the deliberations of this body feel themselves personally concerned, that should also be considered. I, for one, feel myself at liberty to say to every gentleman, that every question of grievance of which any portion of the citizens of Virginia justly complain, ought to be considered by this Convention, and such action taken in the premises as will remove this just cause of
                complaint.
              </p>
              <p className="p-in-sp">
                Sir, it has been said upon this floor, by various gentlemen this morning, that the effort to raise this question now in this body will tend to strengthen the hands of the enemies of Virginia in the Northern States, and will tend to prevent them from according to us our demand. Unless the indications that we see in this Convention are very much misunderstood, we will not ask any thing
                that any State, even Massachusetts, may not be willing to give us. I do not believe that this Convention will rise to the dignity of asserting its demands, and I feel humiliated and disgraced by it. I come from that much despised portion of Virginia known as North-western Virginia, and I hear it repeated flippantly every where that North-western Virginia is not sound, and not to be
                trusted. Why, sir, my people despise this spirit that is willing to go <PageNumber num={52} /> with hat in hand, and bended knee to Abraham Lincoln—asking that they can have just enough of right left to them to allow them to remain in the Union. I love the Union of these States. I love it as I love the memory of my mother, but I do not love this Union
                well enough to be willing to lay down all my rights as a freeman to remain in these United States. I do not love it so well, that when my old mother Virginia calls her sons in council in the hour of her peril and calamity, and asks them for counsel and advice, that I will say to her—"my proud old mother, lay down your robes of purity and power in the dust of humiliation and disgrace;
                come down from your exalted position, and with ashes upon your head, and sack cloth upon your loins, bend your suppliant knee, and ask the Federal Government at Washington to be permitted to remain in this glorious temple."
              </p>
              <p className="p-in-sp">
                At the time I was tempted to engage in this discussion, I had no expectation of trespassing for any considerable length of time upon the attention of this Convention; not, sir, that I did not feel the subject which is under discussion to be one of transcendent importance to my people; not that I am not fully committed to an examination of this question before this Convention by my
                people; but because I hoped and because I expected—and as I supposed, with a reasonable expectation—that this discussion would be conducted by other gentlemen from other sections of the State, much better qualified than I am, and much more accustomed to participate in discussions of this character.
              </p>
              <p className="p-in-sp">
                I have, to a considerable degree, been disappointed in this regard; and I find myself, therefore, compelled to undertake the performance of a part of that duty, as I best am able; and consequently, sir, I address myself to the consideration of these questions with a good deal of diffidence. I have undertaken the discharge of this duty with a great deal of reluctance; but feeling it
                to be a duty, and once having the assurance that the line of duty lies in a certain direction, I desire to walk in that way, and, according to the best of my judgment, to discharge that duty. And in attempting it, sir, I trust that it will be scarcely necessary for me to assure any person who knows me, that I am not to be intimidated by any mode of procedure that may be adopted in or
                out of the Convention, and prevented from expressing my opinions whatever they may be. If it affords amusement to any body to ridicule the plain and just proposition now pending before this Convention, I tell you, Mr. President, there is no person enjoys a hearty laugh more than I do, even when it is at my own expense. But, sir, when I understand, as I do understand, in the
                discussion of these questions, that great questions of public policy, of constitutional <PageNumber num={53} /> law, are involved, I apprehend, sir, that however much amusement they may afford to facetious gentlemen here and elsewhere, I and my constituents regard these as matters of substantial and real importance. I was tempted yesterday to suffer
                myself to become somewhat excited over and above what I ordinarily do in the discussion of any question. I found excitement in this hall, and I found that that excitement was as infectious as the small pox; and if I suffered myself to manifest any of it, I trust the Convention will pardon me.
              </p>
              <p className="p-in-sp">
                Before I proceed to present the views that I entertain in reference to these questions, I must be permitted respectfully and most kindly, Mr. President, to pay my respects to the various gentlemen who have preceded me in this discussion. And, I shall not be compelled to confine my remarks to those who oppose me, for I find myself very unpleasantly situated this morning, and obliged
                to protest somewhat against the proposition presented by the honorable gentleman from Preston county
                [<span className="persName">Mr. BROWN</span>]
                . Having known him, and known him favorably for many years, and having always known that he possessed a record without spot or blemish, as a staunch, conservative, State Rights Democrat; I say it gives me some pain to be compelled to recur to the singular position that he was pleased to assume in the opening of his remarks yesterday. He was pleased to draw into the discussion of this
                domestic question, a great question which now pervades the minds of all classes of people in this State. I am aware, Mr. President, that while we have been sitting here patiently, and laboring for the last six weeks, and I must confess, thus far to very little purpose, that the nation has been standing on tip-toe, that every eye has been directed towards us to see what we would do,
                and every ear has been strained to hear what we would say.
              </p>
              <p className="p-in-sp">But my friend from Preston [<span className="persName">Mr. BROWN</span>]
                , saw fit to refer to the party which he thinks proper to designate as secessionists in this body and in the State, as being an ancient party; as having derived our lineage so far back that the "memory of man runneth not to the contrary." He has been pleased to refer us back to the garden of Eden. Well, sir, I know that we are, all of us, descendants of mother Eve, and that she
                committed an act which brought all our ills upon us, but it never occurred to me before that we were her descendants as a political party. It never occurred to me before that Eve was a secessionist, and I am the less disposed to credit the lineage, when I recollect that history records her unfortunate act to have been very closely connected with the Devil.
                [Laughter.]
                I say then, that I was sorry the honorable gentleman found it necessary <PageNumber num={54} /> to refer the rise of this party to so ancient a source; and I was sorry, moreover, Mr. President, to find that he deemed it necessary to class us, not only with the lineal descendants of mother Eve, but to regard us as being guilty of something even worse than
                that of being her descendants.
              </p>
              <p className="p-in-sp">He says: "Sir, we look upon the robber and murderer as antisubmissionists, for they will never submit to the laws, and we regard them as the most precipitate secessionists in the world, for the moment they commit their terrible crimes they fly precipitately away."</p>
              <p className="p-in-sp">As a rhetorical flourish, I have no objection to this historical allusion, but I protest against it as argument against the views held by gentlemen upon this floor in that regard.</p>
              <p className="p-in-sp">The gentleman from Northampton [<span className="persName">Mr. FISHER</span>]
                , too, it seemed to me, was a little unkind. It seemed to me that he lacked that kind of care and caution, which he ought to have possessed when speaking respectfully of a section of the State or Union. When speaking of the people of North-western Virginia as submissionists, I regret that he did not except the county which I have the honor to represent upon this floor. I must insist
                that when that gentleman, or any other gentleman, speaks of the North-west in connection with submission, he shall except Barbour as a county, and myself as an individual. We are not submissionists, and we despise those who are. But I apprehend that when this matter comes to be fairly understood, it will be found that there is not nearly so much difference of opinion between us as is
                now imagined. I apprehend that those gentlemen who are always singing psalms in praise of the Union of the United States, and not of the Union of the Constitution, are not the peculiar friends of the Union; and I apprehend that those who may be said to travel a little slowly in their march towards the ultimate result, and who, therefore, have been termed "submissionists," are not, as
                the event will show, liable to the application of that term in the offensive manner in which it has been used. I think that a little charity, and a little more charity than has been exercised towards one another, would enable us in a very short period of time—and perhaps in a much more brief period of time than many of us anticipate—to stand side by side, and shoulder to shoulder,
                upon the same platform.
              </p>
              <p className="p-in-sp">
                I have not yet seen any man who was willing to commit himself to unconditional submission, but I have seen a great many who were willing to commit themselves to submit to a great deal more than I am willing, and more than my people are willing to submit to or to tolerate. But <PageNumber num={55} /> I cannot consent when gentlemen even hold such views,
                to denounce them as it has been the practice of some gentlemen in this body.
              </p>
              <p className="p-in-sp">I trust, then, that when my friend from Northampton [<span className="persName">Mr. FISHER</span>]
                comes to look over the fair fields and rugged declivities of Northwestern Virginia, he will remember that those mountain fastnesses are not inhabited by slaves, and that they are not inhabited by submissionists, for I believe that history affords no instance in which mountains have ever been infested by such a disreputable race of inhabitants. No, sir, the people of North-western
                Virginia, though they may not enjoy all the luxuries which those in other parts of the State possess, have honest hearts, brave hearts, and strong arms. They are a brave people, ready to do, to dare and to suffer in defence of the right, and who will never submit to wrong.
              </p>
              <p className="p-in-sp">
                But, Mr. President, I find from the gentleman from Northampton, and I believe from every other gentleman who has opened his mouth in this body in opposition to these resolutions, that we are reminded that this attempt, at this time, to disturb this question of domestic taxation, is a breach of plighted faith; and, therefore, notwithstanding the knowledge, and I believe no one of them
                has failed to acknowledge it—that great injustice in some regard, is done to Western Virginia in respect to this question of taxation, still they insist that it is a breach of plighted faith to disturb that question now. They have most eloquently reiterated that proposition again and again.
              </p>
              <p className="p-in-sp">
                Now, sir, in the first place, if that were a fact, it might be worthy and deserving of some consideration. In the next place, if it is an argument, I am unable, I confess, to appreciate its force or its logic. But is it a fact? Is there any breach of plighted faith in attempting to disturb this question now, that these gentlemen should feel themselves so wronged, so utterly disgusted
                with our want of patriotism in seeking to bring this question before the Convention at this time, and in the shape in which it is now presented? Why, sir, my recollection of the history of this question in Virginia, does not teach me that it is a breach of plighted faith. I recollect that in 1850 when the last Convention was called, or rather when the bill calling the Convention was
                under consideration, it was resisted in my section of the State, because it failed to recognize the great principle of equal representation all over the State. I recollect, if my memory serves me correctly, that we had at that time in Western Virginia a majority of one hundred and thirty thousand of the white population of the State, while the terms of the Convention bill placed us
                in a minority of seventeen in that body.
              </p>
              <p className="p-in-sp">
                We elected our delegates; we went into the Convention, and we felt <PageNumber num={56} /> what everybody knew that we were bound hand and foot; and, like men in that condition, we were thankful for small favors, and we took all that we could get, trusting to the returning sense of justice of our Eastern brethren to give us our just rights in the future.
              </p>
              <p className="p-in-sp">
                It has been said upon this floor, that because the present Constitution postponed the consideration of this question of representation until 1865, it is in exceeding bad taste for any of us to attempt to raise the question now. That question, sir, we were obliged to postpone until that time, not because we desired it, but because we found ourselves in a position in which we could not
                avoid it. And why was it made a part and parcel of our present Constitution, that in 1865 this question was to be referred to the people of Virginia in the cumbrous, unwieldy and uncertain manner, proposed in our present Constitution?
              </p>
              <p className="p-in-sp">
                Why, sir, it was to avoid, if possible, the disturbance of this question. It was intended to place us in a position giving us the power, it may be, if we could secure it at the polls, to occupy a place in both Houses of the General Assembly on the terms which now regulate representation in the House of Delegates, but at the same time to withdraw from the action of that Legislature a
                very large proportion of the subjects of taxation. Then, Mr. President, we did not deserve much consideration at the hands of our Eastern brethren for submitting to this. We could not avoid it; but they did deserve at our hands—and we never failed to accord it to them—some degree of consideration for having acknowledged a principle which, in the end, must eventually work out our
                equality. We were willing to wait until 1865 ; but one gentleman, I think the gentleman from Monroe
                [<span className="persName">Mr. CAPERTON</span>]
                , pressed it upon the attention of the Convention yesterday, that because no petition had come up to the Legislature of Virginia from the various counties, asking for a Convention, that therefore it was to be taken for granted they did not desire it. I tell the gentleman, that in my section and in all sections West of the Alleghany mountains, we find it a very troublesome thing to
                keep our people quiet until 1865.
              </p>
              <p className="p-in-sp">
                But, sir, you have asked your representatives to call a Convention; you have obtained a Convention; you gave us the white basis in the election of our delegates, or the basis of the House of Delegates; and it has been said by gentlemen that if anybody had ever dreamed that this Constitution was to have been disturbed in this Convention, it would not have been called.
              </p>
              <p className="p-in-sp">
                I tell you, sir, that the Legislature would not have dared to call a Convention upon any other basis. We were willing to wait further; but when the representatives in that body undertook to call a Convention <PageNumber num={57} /> we did not expect that they would commit political suicide by issuing a call for a Convention upon any other basis; and I
                tell you if the members of this body could but realize the feelings of gratitude which that act of liberality and justice had aroused in the minds of the people of Western Virginia, they would, I am sure, appreciate them.
              </p>
              <p className="p-in-sp">
                Now, sir, it has been said by various gentlemen upon this floor, again and again, that this question was never considered among the people; that the delegates elected to this body were not elected upon that issue —that the people never dreamed of it, and that, therefore, this Convention ought not to touch it: that it would be bad faith for us to touch it. What does that imply? I
                don't know how it strikes the minds of other gentlemen, but it strikes me that if the people who are represented here had supposed that this question was to be disturbed they would have filled the seats of these gentlemen with other representatives. That is a fair deduction; that is the conclusion to which this argument leads me; I have no idea, however, that their seats could have
                been filled more worthily than they are filled.
              </p>
              <p className="p-in-sp">
                But if they think differently from me in this respect, let me make a suggestion in the kindest spirit, that every one of them resign their seats, and suffer their places to be filled by men whom the people would be willing to trust. But, sir, I have no idea that the good people of the State of Virginia would desire to change the seat of any gentleman here, because I am well satisfied
                that whatever may be said, that for ability and zeal in the cause of their respective constituencies, they never could be surpassed by the people of Virginia. But, sir, why not disturb this question now? When will there be a more convenient season than the present? When will the people of Virginia be more convinced of the necessity of this change? Why, my friend, the gentleman from
                Petersburg
                [<span className="persName">Mr. BRANCH</span>]
                , tells us that he will never consent to disturb this question, until Virginia shall go out of the Union, and until this Convention takes her out of the Union. How does that interpret itself to me, sir? Why, that she will never go out. I don't think that this Convention would drag her out if it remained here until 1965. But, sir, I may be mistaken. Significant indications are coming
                up all over the country; and I am very glad to find that my worthy friend, the gentleman from Petersburg
                [<span className="persName">Mr. BRANCH</span>] , has had his pace considerably accelerated in that direction, and it does my heart good to find that this acceleration has had the desired effect, and that he stands ready to advance or recede, just as the circumstances of the case may require.</p>
              <p className="p-in-sp">Before I forget it, and lest my silence may be misunderstood, I desire to refer to the question, which has disturbed the equanimity of the <PageNumber num={58} /> gentleman from Northampton [<span className="persName">Mr. FISHER</span>] . I refer to his invitation to Western gentlemen to come up and give him an ordinance of secession by way of offset for an agreement to yield this demand for equalizing taxation.</p>
              <p className="p-in-sp">
                Mr. President: I understood his proposition just as he explained it to us this morning. I was not misled by the playful manner in which he made this proposition. I came here not to bargain, or beseech for my rights, but to ask, demand them, and insist upon them, because I hold in regard to the rights of citizens of Virginia in Virginia, that a right that is not worth asking for ought
                to be abandoned. I am not willing to consent to occupy an inferior position in the Confederacy of the United States, if I can help it, nor to consent that my people shall occupy an inferior position in the councils of their native State. Whilst I recognize the inequality of Virginia, in the Confederacy of the United States, and shall insist, so far as my abilities will permit, that
                every thing shall be done, suffered and borne, necessary to vindicate our rights; whilst I shall be willing, as an humble individual member of the Convention to do, and suffer every thing that may be necessary, to vindicate the rights of my people, I have no idea that the offer of my friend from Northampton
                [<span className="persName">Mr. FISHER</span>] , will be misunderstood, or that it will operate to the prejudice of any body. It might possibly be for the interest of some to prefer it, but I am well convinced that it will not have that effect in the State.</p>
              <p className="p-in-sp">My course in regard to this great question shall be dictated by my sense of duty and right with reference to that question, and that alone, and I am ready to meet that and every other question when it rises, and to give to it such consideration as it may deserve.</p>
              <p className="p-in-sp">But I desire to recur again to the pregnant admission made by the gentleman from Monroe [<span className="persName">Mr. CAPERTON</span>] , the gentleman from the city of Richmond [<span className="persName">Mr. JoHNsoN</span>] , and I think by other gentlemen, certainly by the gentleman from Petersburg [<span className="persName">Mr. BRANCH</span>] , that the principle of this resolution is right and just in itself.</p>
              <div className="speaker" id="sp840"><span className="persName">Mr. JOHNSON</span>, of Richmond—</div>
              <p className="p-in-sp">I beg leave to say that I was not aware that I uttered one word susceptible of the construction which he has given it.</p>
              <div className="speaker" id="sp841"><span className="persName">Mr. WOODS</span>—</div>
              <p className="p-in-sp">
                I do not desire to misrepresent the gentleman. But the gentleman from Richmond, Mr. President, whom I cannot successfully undertake to imitate; whose beautiful flights of fancy I could never aspire to follow, has, in the richness of his imagination and in the beauty of his imagery, painted the Union of the United States as the most magnificent temple of liberty upon which the sun
                ever shone.
              </p>
              <PageNumber num={59} />
              <p className="p-in-sp">
                But, sir, he has fallen most unwittingly into an error that is pervading the country—an error that ought to be corrected here, that ought to be corrected in every man's mind; and it is this, that we were indebted to the late Union of the United States for liberty; for all the laws which secure life, liberty, and the pursuit of happiness. Sir, I do not so understand the constitution
                of my country. I do not understand that any right is secured to me by the government of the United States as a citizen at home, in the enjoyment of all those things which render life desirable and happy.
              </p>
              <p className="p-in-sp">
                Sir, if I be wronged in my person, or good name, or in my property, I do not resort to the federal laws for redress. I resort to my State laws. If I desire a public improvement for my county, I do not resort to Congress and petition that body for this privilege. I resort to my State Legislature. I think it is high time, Mr. President, when we are asked to condescend to the General
                Government, to remember that we have a most beneficent government at home, which challenges our admiration, to which we owe our allegiance, which gives us protection, which ought to be preserved, and which must be preserved, if we desire to preserve our liberties.
              </p>
              <p className="p-in-sp">
                It is said that the eagle when warned by his instinct that a storm is approaching, instead of preparing himself to breast it, plumes his flight and soars above it, and then looks down with complacency upon the raging elements below, and so the gentleman from the city of Richmond, when this question is presented, like the eagle soars above it all and looks down upon the turmoil and
                strife that we are engaged in and never condescends to meet these questions with a single argument. I would much prefer, Mr. President, that that gentleman and every other gentleman in this Convention should meet these questions by argument; and I desire to say most respectfully to one and all, that the song of the Union has been sung out, and like the harp of a thousand strings it
                ought to be hung upon the willows.
              </p>
              <p className="p-in-sp">But, Mr. President, the gentleman from Monroe [<span className="persName">Mr. CAPERTON</span>]
                , made, in my opinion, a most unfortunate and unjust reference to our friends of Western Virginia. He said to us upon this floor yesterday, that we ought not to extort from Eastern Virginia, in this hour of her peril and her calamity, those rights of ours which he, almost in the same breath, entertaining, as he did, the same sentiments as the honorable gentleman from Preston
                [<span className="persName">Mr. BROWN</span>]
                , asserted to be eminently just and proper. Sir, I am inclined to think—and I hope that I am correct in this apprehension—that no such necessity could ever arise. It never occurred to my mind before, that our friends of Virginia, in any portion <PageNumber num={60} /> of the State, while admitting the existence of a right, and at the same time admitting
                that they withheld it from those entitled to it, could be induced to relinquish it, unless extorted by fear. I think it is unjust to our friends in Eastern Virginia, and I expect them to come up manfully to this subject, and indicate, by a liberal and generous support of this proposition, the just appreciation that they have of the affection of Western Virginia.
              </p>
              <p className="p-in-sp">
                Now, sir, having disposed of the remarks of these gentlemen, I propose to submit a very few plain common sense questions for the consideration of this body. First, then, in what does the present Constitution of Virginia require any amendment in any regard whatever? Is it so perfect, that we would hand it down to our posterity and expect them to be content with it? Does it meet all
                the questions of State interest in such a manner as to secure to the citizens their respective rights, and, at the same time, guard them against the abuse of the authority conferred upon various agencies that they have established to carry out their government?
              </p>
              <p className="p-in-sp">
                If it does, then no change is necessary now, or will be necessary hereafter. But, sir, I apprehend that a very casual, a very careless review of the statutes of Virginia, will point out very many things to the enquiring mind—especially, if that mind be educated through the experience which we get from day to day from all parts of the State—that need to be changed; a great many
                reforms that ought to be introduced, some very material and essential, and others of less materiality.
              </p>
              <p className="p-in-sp">
                We find that representation of the State of Virginia in both Houses of the General Assembly, has not been properly apportioned. This was a compromise given to us in 1850. They had the power in both Houses; but seeing that it was so palpably unjust to retain it altogether any longer, they gave us the compromise by which we obtained our rights in one House, with the possibility that in
                1865 we might obtain the same in the other. We find biennial sessions of the Legislature instead of annual sessions; and I contend that there is no man who hears me, with the experience of ten years past before his eyes, and with the portentous experience of ten years in the future before his mental vision, but feels that in this regard it would be well that the Legislature should
                assemble from year to year, leaving to other representatives the correction of such abuses as may occur, and leaving to the same persons, in like manner, to judge of the interests of the State.
              </p>
              <p className="p-in-sp">
                Another question suggests itself to the minds of every intelligent gentleman here, and especially every lawyer. Why, sir, we have an <PageNumber num={61} /> intermediate Court of Appeals, a District Court, established by the Constitution of Virginia. Who does not feel that it is a most miserable abortion? Who does not feel that that Court has utterly
                failed in every reasonable and just expectation that was entertained in regard to it? Our County Courts, sir, are a peculiar institution in Virginia, if indeed, she has a peculiar institution. Why, sir, in earlier times it had the prestige of great names. It sustained vast expectations. It was confided to a body of men who, unfortunately, do not possess power in these later days. It
                was not then an expensive Court in the positive sense. But I have learned, and I am satisfied that the people of Virginia have learned, that within the last ten years, while it has been the most expensive Court it has been the most inefficient; that it has been a most perfect caricature on a court of justice that has ever been devised by man. And it is made a Constitutional Court,
                and whatever may be its inherent defects, its practical imperfections, yet it is beyond the ordinary power of the Legislature to abolish it.
              </p>
              <p className="p-in-sp">
                I must confess that I have never been able to appreciate the justice of that system of taxation which, if my property consists in lands, and is taxed to the full extent of its value, enables me to relieve myself of all taxation by merely changing the character of my property. The common masses of the people throughout the country are at a loss to understand that kind of logic which
                renders it eminently just and proper that my property, when consisting of lands, shall be taxed to its full value, but when that land is exchanged for negroes under twelve years of age, that property is exempt from taxation. And I have no idea that they will ever be able to understand such a proposition as that; I think they will never try to understand it, and most assuredly, sir, I
                never will endeavor to instruct them in any such logic.
              </p>
              <p className="p-in-sp">
                One gentleman has said that this taxing of slaves is a tax upon labor. What is labor? what is capital? what is money? It is but the result of the accumulation of labor; and you cannot place your finger upon a single article of property in the Commonwealth, or in the world that does not represent labor, and labor almost exclusively. And why should the 239,000 slaves in Virginia, under
                12 years of age, worth $119,500,000, be exempt from taxation? Gentlemen say that it may be possible that we will not obtain as many benefits from it as we expect; and more, that it is possible our burdens will be increased. But if the principle be correct, you have no right to resist the application of it. Leave the consequences to us: if it increases our burdens we are willing to
                bear the additional load. But, I apprehend that if a well founded apprehension existed that it would increase our burdens, a proposition <PageNumber num={62} /> of this kind would not have been resisted. It will diminish and not increase our burdens.
              </p>
              <p className="p-in-sp">But, it has been stated on this floor, by the gentleman from Petersburg [<span className="persName">Mr. BRANCH</span>]
                , that if any extraordinary occasion were to arise in the history of this State, then it would be proper, and he would consent to disturb this question of taxation. Why, let me ask the gentleman, or any other gentleman on this floor, if extraordinary circumstances have not arisen? Have no extraordinary circumstances arisen—circumstances that were never thought of, never dreamed of?
                Who ten years ago supposed that we would now be sitting, convened in another convention assembled by the order of the people, discussing the probability and the possibility of remaining under the Government of the United States? Who at that time did not think that that question was one which their descendants to the latest generation would never be called upon to determine? Who ever
                thought that the State of Virginia, by the practical operation of the aggressive doctrine of Northern abolitionism, would be invaded by an incendiary and murderous force, and that the citizens of Virginia would be murdered in cold blood in their own streets? Who ever dreamed that the State of Virginia, when she undertook to execute the law and to impose the highest penalty on her
                malefactors, would have to surround the gallows with an army of two or three thousand soldiers? Who expected that, on account of this Northern interference, we would find it necessary to expend half a million dollars on the Armory in Richmond, to supply arms to the State? Who thought that an extra session of the Legislature, and this Convention called by the people, would be found
                necessary to be assembled at the same time, to provide against this extraordinary contingency? Who ever expected that the militia system would have to be re-organized, and an immense expense for these undertakings have to be borne by the people of Virginia? And will we be asked to believe that, notwithstanding all these evils, and innumerable others of greater magnitude which
                threaten us, that we should sit still and bear all the burdens, while we permit that vast property in negroes to remain exempt from taxation? Upon looking over the statement of the public debt, I find that it has increased, in round numbers, from eleven millions of dollars in 1852, to forty millions in 1860, requiring $2,556,687 to pay the annual interest upon it. The present session
                of the Legislature in order to supply the extraordinary demands of the country for protection is required to appropriate $1,000,000, and has done so since we have been in session here. The present session will require, I am told, some $150,000 to defray its expenses, and this Convention <PageNumber num={63} /> will require as much more, for I see it
                indicated by the gentleman from Franklin
                [<span className="persName">Mr. EARLY</span>] , that there is to be held an adjourned session of this body for the purpose of determining these questions further. It seems to me that nothing will arrest this current of extravagance until the people come to realize that, while their representatives spend their money, they will have it to pay.</p>
              <p className="p-in-sp">
                I know, Mr. President, and just here, I may be permitted to allude to the argument, that it is represented that the portion of the State where slave property exists to the greatest extent, already pays a large portion of the taxes. I admit it; but it is also true that with equal generosity they have appropriated to themselves the lion's share of the expenditures, of which I complain
                nothing. I have before me a statement showing that there have been expended in Virginia for internal improvements in the various districts of the State, the following sums, viz:
              </p>
              <p className="p-in-sp">In Tide-Water District $15,000,000</p>
              <p className="p-in-sp">In Piedmont District 13,000,000</p>
              <p className="p-in-sp">In Valley District 8,000,000</p>
              <p className="p-in-sp">In South-West District 8,000,000</p>
              <p className="p-in-sp">In North-West District 2,000,000</p>
              <p className="p-in-sp">The estimated receipts from the several Districts, for the year 1861, are as follows:</p>
              <p className="p-in-sp">From Tide-Water District $1,132,342.68</p>
              <p className="p-in-sp">From Piedmont District 972,044.15</p>
              <p className="p-in-sp">From Valley District 523,086.82</p>
              <p className="p-in-sp">From South-West District 289,616.53</p>
              <p className="p-in-sp">From North-West District 404,195.22</p>
              <p className="p-in-sp">These Districts, according to the present rates of taxation, will return said sums to the Treasury in the following periods :</p>
              <p className="p-in-sp">Tide-Water District in about 8 years</p>
              <p className="p-in-sp">Piedmont District in about 13 years</p>
              <p className="p-in-sp">Valley District in about 16 years</p>
              <p className="p-in-sp">South-West District in about 27 years</p>
              <p className="p-in-sp">North-West District in about 5 years</p>
              <p className="p-in-sp">
                Then, Mr. President, it appears, as I have stated, that that portion of the State which pays the largest portion of the taxes, has judiciously appropriated to itself an equally large proportion of the expenditures. I am not here to complain of that course, but I am here to protest against the mortgaging of the inheritance of my children for all time
                <PageNumber num={64} /> to come by increasing the public debt of the State; for I know that every dollar of increase of that public debt is a mortgage upon the inheritance of my children, and that they will have it to pay. I know that the property of the people of my section of the State, consists, to a greater extent in landed property, which, from its
                nature, is permanent and will go on increasing in value, while the species of property which it is now proposed to bring within the power of taxation is variable in its value, and may, in a few years, disappear entirely from Virginia.
              </p>
              <p className="p-in-sp">
                I ask you then, Mr. President, have we not an interest—have we not a substantial interest that ought to excuse us if we make these demands upon this body? Have we not such an interest as should entitle us to ask and to demand at the hands of this Convention that all the property of the State shall be taxed for the purpose of meeting these enormous expenditures? It seems so to me, and
                I trust that our friends will therefore excuse the zeal with which we approach this question. As a proof to my friends from Eastern Virginia that every description of property in the State, including slave property, should be taxed alike, I call their attention to the fact, that with the comparative exemption from taxation they have enjoyed, the public debt has increased in the last
                9 or 10 years from $11,000,000 to $40,000,000—and I insist that equality of taxation is necessary if we would make ours an economical Government. I know that these enormous appropriations ought to be restrained; and, although I do not feel myself competent to indicate what would be the best method of accomplishing that result, it is perfectly evident that unless these extravagant
                expenditures are curtailed, the State of Virginia, however she may be degraded by it, is marching forward, steadily and rapidly forward, towards repudiation. No State, more than an individual, can go on from year to year increasing its indebtedness, without realizing its ultimate ruin. Gentlemen admonish us that there may be a re-valuation of the lands of Western Virginia. Sir, we
                have already had one or two re-valuations of our lands since 1850. We have paid for it and we are ready to do it again. All we ask is that you shall give us the principle of equality, that we shall all be placed upon the same platform, and whether our lands be valued high or low, we will be satisfied.
              </p>
              <p className="p-in-sp">
                It has been intimated to us that we will gain nothing by this adjustment of the question of taxation; that the coal fields and the oil fields of Western Virginia will increase the value of our lands, and that we will get no more from you than you will from us. Be it so. Give us the principle and we will submit to the result, whatever it may be. If the result is for the benefit of
                Eastern Virginia, rather than for that <PageNumber num={65} /> of the West, be it so; let justice be done and we will not complain of the consequences.
              </p>
              <p className="p-in-sp">
                I ask again, is it not sound policy for Virginia to adopt a system of equal taxation in view of the condition of things that is upon us? We, in Western Virginia have found it a formidable undertaking to keep the people of that section of the State quiet upon this question. It has been falsely thrown in our teeth, continually, that the Democratic party, which has ruled the State, has
                ruined it. I say, to the gentlemen upon this floor, that the people of the West will have justice done them. I will not say, as other gentlemen have intimated, that if the particular measure we ask for is not granted, we will inaugurate revolution or violence. We make no threats; we appeal to your native magnanimity; we appeal to your sense of justice and right, and we still believe
                that that appeal will be met with that fraternal spirit in which it ought to be met. We deem it of the utmost importance to Virginia, at this crisis in her history, that there shall be no division of sentiment among her people, no cause of division, whether ostensible or real, but that she shall be united as one people, from the Ohio to the Chesapeake. We are one in interest, and I
                trust the day will never arrive when we shall be two in fact. I cannot look to the possibility of a rupture of the good old Commonwealth of Virginia without feeling that her glory will have departed forever. Everything which makes a Virginian raise his head with pride, and claim his birth right, will have passed away, and he must hang his head with shame when he acknowledges himself
                to be a citizen of a State which, by wrong and injustice, accomplished her own destruction. I cannot, for a moment, entertain the possibility of such a thing. I drive away the thought from my mind; but I must be permitted to say that the way to attach the people to the Government is to do equal justice to every section.
              </p>
              <p className="p-in-sp">
                Mr. President, I did not intend when I was drawn into this discussion to have trespassed so long upon the patience of this body, but I cannot resist the temptation to refer to a few figures and facts in regard to this question of the public debt so that the people of Virginia may see how steadily and rapidly the State is marching forward towards repudiation. I find upon examination
                the progress and condition of the public debt within the last nine years, to be as follows:
              </p>
              <p className="p-in-sp">On the 1st of January, 1852, the debt was $11,971,838.30. Increased in-</p>
              <p className="p-in-sp">1852 $2,979,087.00</p>
              <p className="p-in-sp">1853 4,605,916.87</p>
              <PageNumber num={66} />
              <p className="p-in-sp">1854 4,112,184.37</p>
              <p className="p-in-sp">1855 1,504,403.09</p>
              <p className="p-in-sp">1856 2.653,570.00</p>
              <p className="p-in-sp">1857 630,260.00</p>
              <p className="p-in-sp">1858 1,866,800.00</p>
              <p className="p-in-sp">1859 1,454,600.00</p>
              <p className="p-in-sp">1860 3,573,125.00</p>
              <p className="p-in-sp">23,379,946.33</p>
              <p className="p-in-sp">From this deduct amount already paid 1,462,993.00</p>
              <p className="p-in-sp">21,916,953.33</p>
              <p className="p-in-sp">Debt existing Jan. 1, 1852, now unpaid 10,271,113.99</p>
              <p className="p-in-sp">32,188,067.32</p>
              <p className="p-in-sp">Amount debts due on account of internal improvements 7,563,993.24</p>
              <p className="p-in-sp">39,752,060.56</p>
              <p className="p-in-sp">Deduct debt existing Jan. 1, 1852 10,271,113.99</p>
              <p className="p-in-sp">Total increase since 1852 is $29,481,946.57</p>
              <p className="p-in-sp">
                What may be the increase which the necessary expenditures for the session of the Legislature now progressing or of this Convention, or what additional appropriations it may be necessary to make, to secure our rights in the Union or out of it, I cannot state; but it must be evident to every gentleman. I can understand distinctly, and the people of Virginia will understand distinctly
                that the best means of restraining these extravagant appropriations is to make all the property of the State taxable, so that every man will feel that he is interested in restraining the extravagant expenditures of the Government.
              </p>
              <p className="p-in-sp">
                I shall not longer trespass upon this Convention, but will merely express a hope that it will be the pleasure of the Convention to consider this question; to raise the appropriate commmittee and then it will be for us to present propositions. If we cannot agree, then let us disagree; but do not stifle enquiry—do not say to the people of the West that you will not even entertain the
                proposition. I tell you, sir, that eternal political perdition awaits the man who will stifle, by his voice, free enquiry by the people of Virginia in relation to this question.
              </p>
              <p className="p-in-sp">
                I desire to add a single word. On yesterday, sir, a question of order was made by the gentleman from Jackson, which, I supposed it would be <PageNumber num={67} /> the province of the Chair to decide before debate proceeded. The question of order was to the effect that a proposition somewhat similar in its character to that submitted by the gentleman
                from Monongalia providing for the appointment of a committee, had been on the table, and that it was proper that the question should be first taken upon that.
              </p>
              <div className="speaker" id="sp842"><span className="persName">Mr. HAYMOND</span>—</div>
              <p className="p-in-sp">The hour has arrived for the Convention to go into committee. I desire to secure the floor with a view to offer some remarks upon the pending question when the proper time arrives.</p>
              <p><span className="persName">Mr. R. Y. CONRAD</span> offered a resolution changing the time for the Convention to go into a committee of the whole from half past 12 o'clock to 11, A. M., which was adopted.</p>
              <p>The Convention then resolved itself into Committee of the Whole, <span className="persName">Mr. SOUTHALL</span> in the Chair.</p>
              <div className="speaker" id="sp843"><span className="persName">Mr. SOUTHALL</span> having taken the Chair said—</div>
              <p className="p-in-sp">When the Committee rose yesterday, it had under consideration the various reports submitted by the Committee on Federal Relations. This morning another report from the same Committee in relation to additional constitutional guarantees has been referred to the Committee of the Whole for its consideration.</p>
              <p className="p-in-sp">The gentleman from Richmond is entitled to the floor.</p>
              <p>
                <span className="persName">Mr. GEO. W. RANDOLPH</span> resumed his remarks, and concluded about half past one o'clock, having occupied the floor nearly an hour and a half. His speech will appear in full in a day or two.<span className="note" id="Note12"><span className="noteLabel">4</span>Mr. Randolph's speech is given in the Proceedings of March 16. See note 3 on those Proceedings.</span>
              </p>
              <p>No member claiming the floor,</p>
              <p>The CHAIRMAN stated that the question before the committee was upon the first clause of the report of the Committee on Federal Relations.</p>
              <div className="speaker" id="sp844"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I hope no vote will be taken to-day upon that report. I think there are other gentlemen who desire to address the Convention upon it. I understood that a gentleman whom I do not see present desired to follow the gentleman from Albemarle.</p>
              <div className="speaker" id="sp845"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">To which gentleman from Albemarle does the gentleman allude?</p>
              <div className="speaker" id="sp846"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I intended to say that I understood that a gentleman who is not present desired to follow the gentleman from Richmond [<span className="persName">Mr. RANDOLPH</span>] . For that reason I move that the Committee rise.</p>
              <div className="speaker" id="sp847"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">
                I hope that the Committee will not rise. If there is no gentleman ready to take the floor to proceed with further discussion, I think we are prepared to vote, and I think it is exceedingly important <PageNumber num={68} /> that we shall vote. Of course if any gentleman is prepared to go on with the discussion, I am ready to hear him; but if not I hope
                that we shall vote, and vote quickly.
              </p>
              <p>The question was put on <span className="persName">Mr. FISHER</span>'S motion, and the Committee refused to rise.</p>
              <p>No gentleman claiming the floor,</p>
              <p>The CHAIRMAN again stated the question to the Committee.</p>
              <div className="speaker" id="sp848"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">
                I desire to discuss the general subjects of the various reports of the several Committees. I had supposed that some gentleman, entertaining different views from my friend from Richmond, would address the Convention to-day. An intimation was given yesterday, by a gentleman, that he desired to speak in the same general line of argument pursued by the gentleman from Richmond. Having
                made no preparation, therefore, it would be altogether out of my power, with any satisfaction to myself, to enter upon an argument to day, without the references before me which I propose to use. I would, with the utmost pleasure, yield the floor to any gentleman who is ready to go on. Indeed, I would much prefer hearing some gentleman entertaining different views from those
                expressed by the gentleman from Richmond; but if it is not the purpose of any gentleman upon the other side to discuss the general question before the Committee any further, I would ask the indulgence of gentlemen to renew the motion that the Committee rise, and I promise to be ready by to-morrow morning.
              </p>
              <div className="speaker" id="sp849"><span className="persName">Mr. E. B. HALL</span>, of Marion—</div>
              <p className="p-in-sp">
                I hope the Committee will not rise. If gentlemen desire to continue this debate they certainly ought to be prepared to go on. I desire to extend to the gentleman from Albemarle the courtesy he desires, but the Committee has been occupied for several days by the gentleman who last addressed it, and if every gentleman here is to occupy as much time, I ask when this Convention is to
                adjourn? Three times 152 days is a longer time than the most of us desire to remain here.
              </p>
              <div className="speaker" id="sp850"><span className="persName">Mr. STAPLES</span>—</div>
              <p className="p-in-sp">It seems to me that the Committee might rise and report progress and the proposition of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , be taken up. By that course we should lose no time, and allow the gentleman from Albemarle to go on with his speech in the Committee to-morrow.</p>
              <div className="speaker" id="sp851"><span className="persName">Mr. E. B. HALL</span>, of Marion—</div>
              <p className="p-in-sp">If it is proposed that the Committee shall rise with that view, I have no objection.</p>
              <div className="speaker" id="sp852"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I rise simply to make an inquiry upon the question of order. If we go on now and dispose of some of the propositions reported by the Committee on Federal Relations, may not the gentleman <PageNumber num={69} /> from Albemarle then proceed to-morrow to discuss the report, and still have the general subject open for debate?</p>
              <div className="speaker" id="sp853"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">If the Committee proceeds to act upon the propositions before them seriatim as they arise, such as shall have been acted upon will have passed from before it, and will not be open for discussion.</p>
              <div className="speaker" id="sp854"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">Still the report made this morning from the Committee on Federal Relations would be under consideration, and I desire to ask if that would not open up all those general questions which we have discussed? If we were to proceed and act upon some of the resolutions now before us upon which there is no controversy, it would expedite our business.</p>
              <div className="speaker" id="sp855"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">I hope, sir, it will be the pleasure of the Committee to extend what seems to me is the usual courtesy, to the gentleman from Albemarle.</p>
              <p className="p-in-sp">
                It is manifest that there has been a little hitch in the programme, that another gentleman was expected to follow the gentleman from Richmond, in the debate, and that the gentleman from Albemarle whom, I am sure, all of us are exceedingly desirous to hear, did not come this morning prepared to enter into the discussion. Even if we should lose the remainder of this day, I do not think
                it ought to be weighed in the balance against the courtesy that is due to that gentleman; but in point of fact, I do not see how we will lose time at all. We have a subject under debate in Convention, and we have a subject under debate in Committee of the whole. Now, sir, if the Committee rises, the balance of the day can be appropriated to the discussion of the subject which was
                under debate when the Convention resolved itself into a Committee of the whole. By doing this we shall lose no time, while at the same time we would be extending to the gentleman from Albemarle
                [<span className="persName">Mr. HOLCOMBE</span>] , what is but a common courtesy.</p>
              <div className="speaker" id="sp856"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">
                I shall certainly vote for granting the courtesy to the gentleman from Albemarle which is asked for. It is with great pleasure I do it, sir. He has not been troublesome at all during the session of this Convention; but whilst I shall vote to have this courtesy extended, I think there ought to be a stopping place. I rise now to say, that after this I shall expect those who intend to
                make speeches will be here prepared at half past twelve o'clock, when the Convention goes into Committee of the whole.
              </p>
              <div className="speaker" id="sp857"><span className="persName">A voice</span>—</div>
              <p className="p-in-sp">11 o'clock.</p>
              <div className="speaker" id="sp858"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">
                11 o'clock. I hope, sir, that gentlemen intending to <PageNumber num={70} /> speak will be here at that hour to take their position to reach the goal. If you recollect, sir, when we first met here the honorable gentleman from Princess Anne remarked that if a man had not made up his mind what he would do upon this question at that time, he would not do it
                until doomsday. It appears, however, that nobody is ready but my young friend over the way. I saw on last Saturday—I was not here, sir, but I saw from the record—that when a vote was taken upon the question of meeting at half-past 10 o'clock that those who were going to go out of the Union before the 4th of March voted against the resolution proposing to meet at that hour. Now, sir,
                if those gentlemen who are entitled to the floor are not ready to proceed, and there is nobody else prepared to take it, we will go on and take the vote. I think that is the proper course; and then let us adjourn and go home.
              </p>
              <p>The question, "Shall the committee now rise?" was then put, and decided in the affirmative.</p>
              <p>The committee then rose, and reported progress.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.2.4">
              <h3><span className="headingNumber">1.2.4. </span><span className="head">THE TAXATION QUESTION</span></h3>
              <div className="speaker" id="sp859"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The pending question is on the resolutions of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , and upon that question the gentleman from Marion [<span className="persName">Mr. HAYMOND</span>] , is entitled to the floor.</p>
              <div className="speaker" id="sp860"><span className="persName">Mr. HAYMOND</span>—</div>
              <p className="p-in-sp">
                Mr. President: I had not expected to address the Convention to day upon this subject. The Convention, however, having adopted a resolution by which it is provided that after to day it will go into Committee of the Whole at 11 o'clock in the morning, I fear if I shall postpone the remarks which I have to submit to the consideration of this Convention I shall have no opportunity to
                submit them to-morrow, or perhaps at any other time, unless I should ask the Committee to suspend its deliberation on the subject of Federal Relations. I prefer, however, not to do so, and shall therefore avail myself of this opportunity of presenting to the Convention the views I entertain upon the subjects embraced in the resolutions offered by the gentleman from Monongalia
                [<span className="persName">Mr. WILLEY</span>] .</p>
              <p className="p-in-sp">
                Mr. President, the questions involved in these resolutions, especially the first resolution, are of the highest importance to what I conceive to be the best interests of the Commonwealth of Virginia. I shall attempt to show, sir, that a proper consideration and proper action upon the part of the people of Virginia, upon this subject, is essential to the best interests of the State,
                and essential ultimately to the maintenance of the honor and glory of Virginia.
              </p>
              <PageNumber num={71} />
              <p className="p-in-sp">But, sir, before I proceed to the discussion of these questions, which I propose to do to some extent, I beg leave very briefly to call the attention of the Convention to some objections urged by the gentleman from Monroe [<span className="persName">Mr. CAPERTON</span>] against the consideration of this subject by this body.</p>
              <p className="p-in-sp">
                The first objection urged by him was, that we are now considering the propriety of perpetuating our Union with the Northern States—that is, sir, the Union of Virginia with the Northern States, and, if possible, the reconstruction of the Union composing our confederacy as it stood some few months since. In the estimation of the gentleman from Monroe, to discuss our family differences,
                in view of the other questions to be settled, would have the effect of showing to people abroad that the citizens of Virginia were divided upon their own internal policy. The effect of this would be, in the gentleman's opinion, to impair the force and efficacy of any proposition of compromise and adjustment that we may present to the Northern States.
              </p>
              <p className="p-in-sp">Mr. President, it seems to me, sir, that the very reason which the gentleman urges against a consideration of this question now, is the strongest argument that could be presented in favor of its consideration.</p>
              <p className="p-in-sp">
                Is it not known in the Northern States of this Confederacy that the chief portion of the slave property of this Commonwealth is East of the Blue Ridge, and that we have two systems of labor in Virginia to some extent? And is not our Constitution known to the Northern people? Don't we see in the newspapers published in the free States, almost daily that in Virginia inequality exists
                in representation and taxation, and that it is an existing cause of contention and complaint among her people? They are perfectly conversant with these difficulties. They are perfectly familiar with all our distractions and internal heart-burnings, and in order to make ourselves powerful and efficient in the eyes of those Northern people, in my judgment, our first duty is to become
                reconciled to each other. We should harmonize this Commonwealth from one end to the other, and make Virginia one and indivisible in sentiment and feeling. I know, sir, that in the Western part of Virginia there is no want of loyalty to any of the institutions of Virginia. I believe that in my portion of the State the people are as sound and as emphatically Southern as the people of
                Richmond or the people of any other portion of the Commonwealth.
              </p>
              <p className="p-in-sp">
                Another objection which the gentleman from Monroe made to the consideration of this question is, that at the time of the adoption of the present Constitution by the Convention of 1850, there was an agreement, <PageNumber num={72} /> if not an express, a tacit or implied agreement, that the provisions of that instrument should not be interfered with until
                1865.
              </p>
              <p className="p-in-sp">
                I can only reply to that by saying that if there was any such agreement, it is not contained in the bond, and I apprehend that when the people of Virginia voted upon the question of the adoption or rejection of that instrument, they voted for it as it was, and not according to any outside understanding of any kind among the members of the Convention before they left the city of
                Richmond.
              </p>
              <p className="p-in-sp">
                What is that instrument? Does it provide on its face the remedy for the evil of which we complain? Does it provide any kind of remedy for the removal of the injustice and inequality in taxation of which we complain? No, sir. The only provision in that instrument, looking to change, is one in regard to representation; and that provides not that in 1865 there shall be a Convention to
                reform and amend the Constitution, but that the General Assembly, if it cannot agree upon some terms of representation, shall submit the question to the voters of the Commonwealth as to whether they will have the two Houses organized upon the suffrage or mixed basis, and if a majority of the people then vote favorably to the mixed basis, then each House shall be organized upon that
                basis. If a majority vote for the suffrage basis, then each House will be organized upon that basis.
              </p>
              <p className="p-in-sp">
                But how is it with regard to the subject of the courts, because our courts are constitutional courts—the Circuit Court and the County Court—and the Legislature has no power to abolish either of them? How is it with regard to the subject of taxation, and other provisions touching the finances, &amp;c., &amp;c.? I understand from high authority that more than one fourth of the Sheriffs
                are defaulters for the past year. And, sir, there are many reforms needed in our Constitution which are necessary to our welfare and prosperity. And none of them except that relating to representation can be changed except by a Convention.
              </p>
              <p className="p-in-sp">But, gentlemen say, let it remain until 1865. Why let it remain until then? If any of the provisions of the Constitution are wrong, is it not the duty of the people, through their representatives in this Convention assembled, now to correct them, and not put it off to another time, in order to incur the expense of another Convention?</p>
              <p className="p-in-sp">
                Suppose that it had been the belief of the members who composed the Convention of 1850, that no Convention would assemble again until 1865. Does that alter the case? I presume the members of that Convention never contemplated, for a moment, that before 1865 Virginia would be invaded by a John Brown, and she compelled, in
                <PageNumber num={73} /> consequence, to expend a large amount of money for the maintenance of the honor and dignity of the Commonwealth, and punish him and his associates in crime. I presume that no member of the Convention of 1850 anticipated that any necessity would arise, as the gentleman from Barbour
                [Mr. Woons]
                remarked, for the expenditure of $500,000 for the construction of an armory in the city of Richmond. It is not to be presumed either that they apprehended a necessity for the appropriation of one million of dollars for the defence of the State, and the protection of the institution of slavery. None of the members of that Convention, I presume, then supposed, that at this time this
                Convention would be assembled here for the great purposes which brought it together. Sir, circumstances have changed since that time. The position of Virginia has changed since then. She is now considering her relations with the Federal Union, and whether she shall sever her connection from the Federal Government; whether that temple of human liberty in which it has been hitherto our
                pride to dwell, shall be destroyed, because of its insufficiency to promote the object of its construction.
              </p>
              <p className="p-in-sp">
                Yet, sir, this Convention is assembled. I admit that it was not anticipated in 1850; but what has occurred that has led to the expenditure of so large an amount of the public money for the purpose of procuring arms and instruments of war and death, and placing them in the hands of our people? What, sir, has involved Virginia in the position to make it necessary for her to consider
                whether she shall longer remain within the Union? Sir, it is not with a view of prejudicing the institution of slavery—for I am a slaveholder myself, to a limited extent—that I answer, that all has grown out of the institution of slavery. I do so because I cannot otherwise account for it, and because, in fact, that is the true cause of all this difficulty. I say that the institution
                ought to be maintained and protected at all hazards. It ought to be protected as property to the fullest extent; but while this is so, it ought to be subjected to a fair proportion of the burthens incurred for the support of the government and for its protection. It is alone the dangers which threaten that institution that has made it necessary for Virginia's citizens to come
                together and consider solemnly whether the protection and safety of her interests, as connected with that peculiar institution, does not make it necessary for her to destroy the Union.
              </p>
              <p className="p-in-sp">
                This brings before us, sir, a very different question from any that arose before the Convention of 1850. We held, however, in 1850, as we now hold, that it is a sound principle of political economy and of the <PageNumber num={74} /> philosophy of government that each citizen shall contribute towards the support of government in proportion to the amount
                of protection he receives; or, in other words, that each citizen of the community should contribute to the support of government in proportion to his ability. Every true and wise man holds this to be a sound principle in political economy. A citizen who has nothing but himself, has, of course, not so much to be protected by government, as he who has his farm, his cattle, his slaves
                and various other species of property. To protect the individual does not require as much expenditure as is necessary for the protection of the person and property of the property holder. According to the principle I have announced each citizen would contribute to the support of government in the degree that he requires protection. Now, let us see how this principle has been carried
                out, and we should see that it is carried out now, because I contend, with all due respect, of course, to gentlemen who may differ in opinion with me, if there never was a time before when slaves ought to be taxed as other property is taxed, that now is the time when the owners of slaves should be taxed as the owners of other property in the State are taxed. Should there be a
                distinction in property? Does it not cost as much, and even more, effort on the part of the government to protect slave property as any other species of property. Why, then, if it costs as much to protect slave property as it does any other species of property, should it be exempt from taxation, in whole or part, while all other property is taxed upon the ad valorem principle? Is
                there any reason for it? Can there be any sound, substantial reason urged against taxing that property as other property is taxed? Is it not as valuable? Will it not command high cash prices? and is it not of ready sale? Does it not sell as readily for cash as any other species of property which we have?
              </p>
              <p className="p-in-sp">
                Now, sir, in discussing this subject farther, I do not mean to discuss it as purely a sectional question, because I maintain that it is not altogether a sectional question, but a question, as I consider it, that affects different classes of persons and property holders, and in all sections of the State. Let us see : A large majority of the people of this Commonwealth are not
                slaveholders; they are not confined to North-western or South-western Virginia, or to the valley of Virginia. I apprehend that the majority of the people in tide-water and in Piedmont are also non-slaveholders. Now, sir, I put the question to you and to all these people in every portion of the State, not only to nonslaveholders, but the slaveholders, why it is that every other
                species of property in this Commonwealth should be taxed and this peculiar property be exempt from taxation to the extent it is at present? What <PageNumber num={75} /> is the result? The result is that the species of property which costs the government the least to protect pays the largest amount of the tax, and contributes the money which goes to the
                protection of the institution which does not contribute to its own protection in the proportion of other property.
              </p>
              <p className="p-in-sp">
                Mr. President, my object in urging this question upon this body is not to tax harshly or unreasonably the slaveholder. I do not desire to oppress him, nor do I desire that any portion of the citizens of this Commonwealth should be oppressed. But, sir, as the blessings of this Government, like the dews of Heaven, fall upon the heads of all alike, I do think that the burthens to be
                borne in support of this Government, should be distributed and borne by the people in proportion to their ability.
              </p>
              <p className="p-in-sp">It has been intimated in this Convention that the section of the State from which I hail is not altogether sound or true with regard to the institution of slavery. I recollect observing, in a Richmond paper, not very long ago—a year or so since—just after the Gubernatorial election, in which my friend from Bedford [Mr. Goconsr]
                , was one of the contending parties, and the present incumbent of the Gubernatorial chair the other; that my friend from Bedford was defeated by the votes of North-western Virginia, and that North-western Virginia was not sound upon the slavery question. I know that it did not come from the gentleman himself, because I have no idea that he entertains any such views, but it was in a
                journal of the day; and I find, since I have been in the city of Richmond, the same sentiment and feeling prevailing in this city and Convention, to some extent, in relation to that people, whose sentiments, I, in part, represent upon this floor. This impression I indignantly repudiate and denounce as false. But to proceed with my subjects. I wish to satisfy you, Mr. President, and
                the members of this Convention, if I can, that we are not asking for any injustice to be done to the slaveholders; but, in fact, we are advocating that which is most truly and greatly to the interest of the owner of that species of property.
              </p>
              <p className="p-in-sp">
                We all recollect, Mr. President, that after the labors of the Convention of 1850-'51 had ended, we were told in the country that Virginia then had an immense debt upon her; that she had engaged largely in internal improvements, but that by provisions in the Constitution, the log-rolling system, which had existed in the General Assembly for years, would be effectually broken up by
                that provision in the Constitution which required a majority of the votes of all the members elected, composing each House of the General Assembly, to pass any bill into a law which appropriated money out of the public <PageNumber num={76} /> treasury. Sir, the people of my section of the country were very much pleased with that provision in the
                Constitution, under the idea that the debt of Virginia, by its operation, would not be increased, or that the taxes imposed upon the people by the government to pay the public debt, or the interest thereon, would not be increased.
              </p>
              <p className="p-in-sp">
                They voted for the adoption of the Constitution by an overwhelming majority. But instead of this provision preventing an increase of the public debt, the very first Legislature that met under it, according to my present recollection, appropriated to works of internal improvements in different portions of this State an amount of money equal to the whole debt of Virginia, which existed
                at the time of the adoption of that instrument.
              </p>
              <p className="p-in-sp">The various bills passed by the first Legislature convened under that Constitution, amounted to about eleven millions of dollars, and since that time, as remarked by the gentleman from Barbour county [<span className="persName">Mr. WOODS</span>]
                , our public debt has increased from eleven millions to forty millions and upwards. How is this debt to be paid—how is it to be kept down—how is the honor and credit of Virginia to be maintained? Is this debt to be increased? Are there to be no restrictions thrown around the public treasury by which the credit of Virginia is to be reinstated; by which her credit is to be restored and
                the people protected from being ground to the dust by unjust and oppressive taxation? Yes, sir, in my judgment there is a remedy and I believe that remedy is in the very proposition contained in the first resolution offered by the gentleman from Monongalia
                [<span className="persName">Mr. WILLEY</span>] . I wish to make all the property holders of this Commonwealth interested in her legislation. I wish each one of them to become interested as the guardian of her treasury. What is the fact in relation to the slaveholders of this Commonwealth?</p>
              <p className="p-in-sp">
                I know that generally they are the most wealthy and the most influential persons in the various counties in which they live; in fact, it may be said with truth that they control the Legislation of the Commonwealth. If, therefore, their peculiar property, their slave property, is taxed as other property is taxed, what must be the result? Why, sir, when this large and respectable class
                in Virginia—because I apprehend there is no more respectable and patriotic class in any land than the Virginia slave owners—become more deeply interested in watching the votes and action of their delegates upon those various schemes of internal improvements, which eat out the vitals of this Commonwealth and consume the substance of the people, they will require and demand a different
                course and a different policy of legislation in regard <PageNumber num={77} /> to taxation and the public debt. Why was it that before the adoption of the Constitution, our debt was not so much increased? We then had various schemes of internal improvements projected in various sections of the Commonwealth. Why was not the debt increased before? Why were
                not these works of internal improvements progressed in then at State expense? If you will look at the tax bills previous to the adoption of that Constitution, you will find that the proportion of taxes which the slaveholders then paid upon their negroes, was a very different proportion to that which it now bears to that fixed upon their property. It is true that the tax then was per
                capita and not according to value. But then it will be remembered, that the tax upon other property—although the tax upon slaves previous to that time was small—was insignificant and almost nothing to what it now is. If we adopt the ad valorem principle, we will bring to the relief of the property of the Commonwealth, other than slaves, which is now taxed according to its actual
                value, under the Constitution, that immense amount of slave property which, as I will show you before I take my seat, will yield necessarily to the treasure of the Commonwealth, if it is taxed as other property is taxed, over $600,000, and it will enable the Legislature, unless they still increase the debt, to reduce our taxes upon all other species of property about one fourth.
              </p>
              <p className="p-in-sp">
                Now, Mr. President, is this unjust to the owners of slave property? I am willing that they shall be protected to the fullest extent. The sons of my native hills are willing to protect them, not only with their money and their property, but with their strong arms and their blood, if it shall be necessary. I ask again, if those who happen to have land or personal property, other than
                slaves, and who are compelled to pay taxes upon the full value of that property—whether it is not right and proper that those who own slaves, and who need so much protection and who get so much from the Government of Virginia, should contribute to the support of that Government and to defray the expenses of the Government for its protection, as the owners of the property? Let us see
                for a moment, what will be the result.
              </p>
              <p className="p-in-sp">
                I have made a memorandum, with a view of laying before the Convention, and the country, through the Convention, the value of this property which is exempt from taxation, with the view of showing the propriety of the owners of that species of property coming to the rescue and relief of the owners of other species of property. I find from the Report of the Auditor of Public Accounts,
                that in Virginia—I believe it was in 1859—there were 239,000 and a fraction, of slaves under twelve years of age; 272,000 and a fraction, of slaves over <PageNumber num={78} /> twelve years of age. Now, sir, let us see the operation of this Constitution. It provides that all slaves twelve years of age or over, shall not be taxed at a higher rate than
                $300 worth of land ; and that all slaves under twelve years of age shall be totally exempt from taxation. Thus you see that the Constitution values all slaves at twelve years of age and over, at $300. That is the average value. If they are only worth $50 they are taxed as though they are worth $300. If they are worth 1500 or 2000 dollars, they are still taxed only as though they were
                worth $300. But, Mr. President, is $300 the average value of slaves 12 years old and upwards. Previous to 1850 this species of property did not sell so high as now—slave property has doubled, yes, thribbled in value, within my recollection. I see that the Auditor of Public Accounts after considering this subject, estimates the slaves at 12 years and over as being worth in Georgia
                over $600. I do not expect to arrive at their exact worth here, but my object is, however, to approximate it, and to show by a system of calculation what would be the result, or at least to approximate it, if this property was taxed as in my judgment it should be taxed. I have estimated the average for the sake of the calculation of all slaves twelve years old and upwards, as being
                $600, just double what the Constitution fixes the value at. If that be correct, by the operation of the Constitution, there is just one-half of the slaves over twelve years of age exempt from taxation. The number of that class of slaves thus exempted from taxation, is 136,000. Take that number (136,000) of twelve years and upwards at $600, and you have the sum of $81,600,000 invested
                in that species of property which pays no taxes whatever to the Government. The tax on that property will yield, at the same rate that other property is taxed, $326,000 annually.
              </p>
              <p className="p-in-sp">Let us look for a moment to those under twelve years of age. I remarked, a few moments since, that there were 239,000 and over of that description. I take the average value fixed by the gentleman from Monongalia [<span className="persName">Mr. WrLLE</span>]
                —and I believe it is about correct—if slaves are as valuable in Eastern Virginia as they are in my country, and I apprehend they are. Now, then, you have 239,000 of this species of property, worth $300 each—which, to a great extent, has been the cause of convening us together—which has convulsed the country from one end to the other, from the Atlantic to the Pacific, from Maine to
                Georgia, and from its centre to its circumference—I say you have that amount of property totally exempt from taxation; but it is worth, at $300 per head, $71,700,000—which would yield annually, if taxed as other property in the Commonwealth, $286,000.
              </p>
              <PageNumber num={79} />
              <p className="p-in-sp">
                Now, sir, putting together the amount of taxes which those under twelve years of age would yield if taxed, and those above twelve years of age, which are undervalued by the present Constitution, and you have a revenue denied to the Commonwealth of over $600,000 annually to be applied to the extinguishment of the present public debt of the Commonwealth, and to the relief of the other
                tax-payers, who are already greatly oppressed throughout the country.
              </p>
              <p className="p-in-sp">
                Now, sir, before I conclude this branch of the subject, I desire to call the attention of this Convention to the amount of taxes which the slave property of the Commonwealth does pay. I know that it is said by gentlemen, that we who live in the North-western part of the State, and those who live in the South-western part, do not pay much taxes; that we make a great clamor but do not
                bring much of our money into the coffers of the State. In North-western Virginia, we pay between four hundred and five hundred thousand dollars of taxes annually to the Treasurer of the Commonwealth. In South-western Virginia, they pay two hundred and eighty-nine thousand dollars annually, according to the last estimate of the Auditor of Public Accounts. Taking the whole together,
                Trans-Alleghany pays annually upwards of six hundred and ninety-three thousand dollars. Very little of that amount, I apprehend, sir, is paid upon slave property. We have some slaves in my section of the country, but I do not know that we could call it involuntary servitude. We live so near the free States on the border that our section of the country has been almost entirely
                depopulated of its slaves, by the influence of Abolitionism; and I say to you, my brethren, members of this Convention, that if, upon Virginia's broad domain, any of her sons have a right to be opposed to Abolitionism, it is the citizens of North-western Virginia. They have suffered more from Northern aggression than all the rest of the State put together.
              </p>
              <p className="p-in-sp">
                We have not so large a number of slaves as you of the Eastern section have. It is not because we don't desire to have them; it is not because our soil will not support that kind of population—for I undertake to say that we have as valuable soil as can be found in any portion of the State, not only for its agricultural production, but also for its minerals, and fluids recently
                discovered; but it is because we cannot keep them. Take this estimate to which I have referred, and you will find that this vast amount of slave property, worth two hundred and thirty-four millions four hundred thousand dollars, pays into the treasury of this Commonwealth only about three hundred and twenty-six thousand dollars. And, sir, the portion of slave property which is exempt
                from taxation by the Constitution, which does not contribute <PageNumber num={80} /> one dollar to the support of the Government which we are all willing to protect, and which we say shall be protected to the extent of every legal and constitutional right, even if it is to be done with our blood—this species of property amounts to one hundred and
                fifty-three millions three hundred thousand dollars, and exists in portions of the State which to that extent do not pay one cent towards defraying the expense of the Government or the expense of protecting this property.
              </p>
              <p className="p-in-sp">
                But it is said, Mr. President, that we are not sound. Well, sir, if Trans-Alleghany Virginia is not sound upon Southern rights and upon the political philosophy of the South, what part of Virginia is sound? I should like to know upon what portion of Virginia several of the gentlemen who now ask an immediate dissolution of the bond of the Union—many of them whom I have heard speak,
                and heard with pleasure—rely most confidently in political conflicts? Is it not upon the portion which I have the honor in part to represent; which is always right side up; always in favor of the interests of the South; always in favor of Southern principles, even to standing upon the principle upon which the gallant and glorious Breckinridge stood during the last Presidential
                canvass? My own county
                [Marion]
                within twenty miles of Pennsylvania on the one side and forty miles of Ohio on the other, stood upon that platform by a majority of 769, when Eastern Virginia stood upon the platform of "the Constitution, the Union and the enforcement of the laws." Sir, we unsound! If we of Trans-Alleghany are unsound concerning Southern institutions and Southern people, I should like to know where
                you can find your friends? But, Mr. President, why is it that in all assemblages in which I have ever served in Virginia, men of the West are spoken of as being unsound? Sir, I observed it to my sorrow and mortification, in the Hall of the House of Delegates of your General Assembly, in which I have had the honor to serve, whenever Western Virginia is spoken of by many Eastern
                gentlemen, it is as being unsound; and her sons, if possessed of any ability or distinction, no matter what their politics, nor how ardently devoted to the interests of the State, are held up as unsound and disloyal to the institutions of the State, especially if they were before the people as candidates for their suffrages; and for no reason save that they manfully and rightfully
                dare assert and contend for the rights of their people. If you think we are unsound, what will you have us do to make us sound? Do you require us to get down on our knees? Do you require us to make confessions? This we will never do. I ask you, Mr. President, and I ask the members of this Convention, to judge of our soundness by our political action, by our principles, which we never
                <PageNumber num={81} /> evade, to which we have ever stood, and to which, I trust in God, we will ever stand.
              </p>
              <p className="p-in-sp">
                But, sir, I acknowledge the equality of the States and of the people of the States under the Federal Constitution, and the right of the people to emigrate to the territories with their slave property, and to be protected there in the use and enjoyment thereof as other property is protected, and I claim that the same principles of common justice and right teach and require equality of
                representation and taxation in Virginia. I have been glad to hear my friends from Eastern Virginia, who have been addressing you and the other members of this Convention, talk about the equality of rights; to hear them say that that was the great object in the formation of our Government; that under the wholesome and wise provisions of our Federal Constitution, not only the States
                were placed upon exact equality, but the citizens of each and all the States were placed upon exact equality, and are entitled to equal privileges. Sir, that is our position. We have submitted to the injustice which has been done by the Eastern portion to the Western portion of the Commonwealth as long as we feel able or willing to submit. And why have we submitted to it? Because we
                are all sons of the same old mother, glorious as she is. We are members of the same political family as you of the East, and we regard Virginia as a sovereign State, as a consolidated Government in which the majority of the people have the indubitable right, to govern and rule; her people are one people in a political sense, and in this respect her Government differs materially from
                that of the Federal Government. And, sir, we have submitted to this thing a long time. We have submitted because you are our brethren, and we supposed, in the course of human events, it would be your interest as well as your pleasure freely and generously to tender to us that which is our right. But it has not been done. And now, sir, I say to the members of this Convention that I
                hope they will not consider that I am unnecessarily consuming one moment of their time; I am sure I have not thrust myself on the deliberations of this body, and I would not claim your attention now but for the fact that I would be ashamed to go back to my people thus wronged and thus outraged, as I conceive them to be, and as they themselves feel that they are, by their own mother,
                although they are true and loyal to her, without having raised my voice in their behalf and without demanding at the hands of the Convention their just rights.
              </p>
              <p className="p-in-sp">
                But, sir, I have shown that it was just, that it was proper that slaves, which we regard as property—for we acknowledge slaves to be property <PageNumber num={82} /> in the fullest sense of that term—I say it is just to us, and just to your neighbors, that you should pay as much tax upon that kind of property as they pay upon theirs. Is it not your
                interest to do so? I have shown you that, in all human probability, if you get that species of property interested in legislation more deeply than it is now, it would perhaps produce a reform in financial expenditures. But, sir, have you not a direct interest in it otherwise? I have shown you that this property has contributed and is contributing to the increase of our State debt,
                and our taxes. I have shown you its immense value; and if you tax it in the same proportion as other property is taxed, it will yield over $600,000 revenue. Suppose you apply that $600,000 annually to the liquidation of the State debt, and to the payment of the interest on that debt—you relieve your neighbors who are not interested in the same species of property, of at least
                one-fourth of the amount of taxes they have now to pay, and to that extent you interest them in your slave property. They become interested in the protection and in the security of that property, and thereby make it more valuable to its owners. And why so? Because it comes to their relief by contributing towards the payment of the public debt, and adding its fair and just proportion
                to the amount annually paid into the Treasury. It is therefore the interest of the owners of that species of property, not only so far as they themselves are concerned, but so far as the honor of Virginia is concerned—for I fully concur in the remark of the gentleman from the county of Barbour
                [Mr. Woons]
                , that unless this system of extravagant expenditure, which has been indulged in by our legislative assemblies for the last ten years, is speedily stopped, Virginia will fail to pay the interest of her public debt, and I fear will be driven to the verge of repudiation, if not to actual repudiation. Shall that disgrace ever rest upon Virginia? Shall it be said in the financial marts
                of the world that Virginia has failed to meet her contracts, has failed to meet her pledges, and failed to sustain that honor about which we have heard so much, and, in consequence, be forever disgraced and degraded in the eyes of the sovereign nations of the earth? I trust not, sir. And I hope, in order to avoid this inevitable result, that the slaveowning population of this
                Commonwealth will come forward to the relief of the other class of our property owners, and contribute their just proportion to preserve the honor of Virginia, and to pay their mite of the taxes which are necessary to defray the current expenses of the Commonwealth.
              </p>
              <p>On motion of <span className="persName">Mr. TURNER</span>, <span className="persName">Mr. HAYMOND</span> not having concluded his remarks, the Convention adjourned.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v2.2.3">
            <PageNumber num={83} />
            <h2><span className="headingNumber">1.3. </span><span className="head">THIRTIETH DAY</span></h2>
            <div className="dateline">Wednesday, <span className="date">March 20</span></div>
            <p>The Convention met at half-past 10 o'clock.</p>
            <p>Prayer by the Rev. <span className="persName">Mr. WOODBRIDGE</span>, of the Episcopal church.</p>
            <div className="section" id="vsc1965.v2.2.3.1">
              <h3><span className="headingNumber">1.3.1. </span><span className="head">CALLING THE ROLL IN COMMITTEE</span></h3>
              <div className="speaker" id="sp861"><span className="persName">Mr. BROWN</span>, of Preston—</div>
              <p className="p-in-sp">I desire, sir, with the leave of the gentleman from Marion [<span className="persName">Mr. HAYMOND</span>, who was entitled to the floor] , to offer a resolution. It is one similar to a resolution offered in the Convention of 1850.</p>
              <p>The resolution was read as follows :</p>
              <p>Resolved, That the ayes and noes may be taken in Committee of the Whole in the same manner as in the Convention.</p>
              <p>The resolution was adopted.</p>
              <div className="speaker" id="sp862"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">With the permission of the gentleman from Marion [<span className="persName">Mr. HAYMOND</span>] , I will ask leave to offer a resolution.</p>
              <p className="p-in-sp">I will make no word of comment upon it; but merely ask that it be referred to the Committee on Federal Relations.</p>
              <p>The resolution was read as follows:</p>
              <p>
                Resolved, That the Committee on Federal Relations be instructed to inquire into the expediency of reporting to the Convention two ordinances, to be submitted to a vote of the people for their approval or rejection on the Thursday in May next—one providing for a resumption, by the State, of the powers heretofore delegated to the General Government; and the other as an alternative
                proposition, the series of amendments to the Constitution of the United States, which may be agreed on by the Convention to be submitted to the Northern States as an ultimatum, on the acceptance of which this State will continue in the Federal Union; with the proviso that if not accepted by the day of , the Convention shall have the authority to pass an ordinance of secession without
                referring it back to the people for their ratification.
              </p>
              <div className="speaker" id="sp863"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">If the Chair understands the resolution correctly, it contains an instruction to the committee.</p>
              <div className="speaker" id="sp864"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">It is simply a resolution instructing the committee to enquire into the expediency of carrying out what is there indicated. <span className="persName">Mr. EARLY</span>—I move to lay the resolution on the table.</p>
              <div className="speaker" id="sp865"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
              <p className="p-in-sp">I ask for the ayes and noes upon that motion.</p>
              <PageNumber num={84} />
              <p>The call was sustained, and the roll being called, resulted as follows:</p>
              <p>YEAS—Messrs. Janney [President]
                , Aston, Baylor, Berlin, Boggess, Brent, Brown, Burley, Campbell, Carlile, Carter, C. B. Conrad, Couch, Custis, Deskins, Dorman, Early, Fugate, Gillespie, Gravely, Addison Hall, Ephraim B. Hall, Hammond, Hoge, Hubbard, Hughes, Hull, Jackson, Peter C. Johnston, Lewis, McGrew, McNeil, Masters, Moffett, Orrick, Osburn, Patrick, Porter, Pugh, Sharp, Sitlington, Staples, A. H. H. Stuart,
                C. J. Stuart, White, Willey-46.
              </p>
              <p>
                NAYS—Messrs. Ambler, Armstrong, Blakey, Blow, Jr., Bouldin, Boyd, Branch, Bruce, Caperton, Chambliss, Conn, R. Y. Conrad, James H. Cox, Richard H. Cox, Fisher, Flournoy, Garland, Graham, Grant, Gregory, Jr., Cyrus Hall, Haymond, Holcombe, Hunton, Isbell, Marmaduke Johnson, Kent, Kilby, Leake, McComas, James B. Mallory, Marshall, Marye, Sr., Miller, Montague, Morris. Morton, Neblett,
                Nelson, Parks, Preston, Price, Randolph, Richardson, R. E. Scott, Wm. C. Scott, Seawell, Sheffey, Slaughter, Southall, Speed, Spurlock, Strange, Sutherlin, Tredway, R. H. Turner, Franklin P. Turner, Whitfield, Wilson, Wysor-60.
              </p>
              <p><span className="persName">Mr. OSBURN</span>, when the name of <span className="persName">Mr. A. M. BARBOUR</span> was called, said that that gentleman was necessarily absent.</p>
              <p><span className="persName">Mr. HUNTON</span>, when the name of <span className="persName">Mr. GOGGIN</span> was called, stated that he was requested by <span className="persName">Mr. G</span>., if the roll should be called, to state that he was unavoidably absent.</p>
              <p>When the name of <span className="persName">Mr. GOODE</span>, of Mecklenburg, was called, he stated that he had paired off with a friend who was absent. Under other circumstances, he would have voted "no."</p>
              <p><span className="persName">Mr. EARLY</span>, when the name of <span className="persName">Mr. SAUNDERS</span> was called, stated that he was absent on account of illness.</p>
              <p><span className="persName">Mr. PORTER</span> stated when the name of <span className="persName">Mr. TARR</span> was called, that that gentleman was absent because of illness in his family.</p>
              <p><span className="persName">Mr. TAYLOE</span>, when called, remarked that he paired off with the gentleman from Dinwiddie [Mr. BORSSEAU] . He said he would otherwise have voted no.</p>
              <div className="speaker" id="sp866"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I would like to hear the resolution read. I was not in when it was offered and cannot therefore vote intelligibly. If it is not in order to report the resolution while the roll is being called, I should like to hear from the Chair the substance of the resolution.</p>
              <p>The resolution was read, whereupon <span className="persName">Mr. WILSON</span> voted no.</p>
              <p>The result of the vote was announced as follows—yeas 46, nays 60. So the motion to lay on the table was lost.</p>
              <p>The question was then taken on referring the resolution to the Committee on Federal Relations, and decided in the affirmative.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.3.2">
              <h3><span className="headingNumber">1.3.2. </span><span className="head">COMMITTEE OF THE WHOLE</span></h3>
              <div className="speaker" id="sp867"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The hour of 11 has arrived.</p>
              <div className="speaker" id="sp868"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I move to suspend the order for the purpose of <PageNumber num={85} /> enabling the gentleman from Marion [<span className="persName">Mr. HAYMOND</span>] , to finish his speech. I understand the gentleman will conclude in a few minutes.</p>
              <p>The question was taken on the motion to suspend, and decided in the negative.</p>
              <p>The Convention then went into committee of the whole, <span className="persName">Mr. SOUTHALL</span>, of Albemarle, in the chair.</p>
              <div className="speaker" id="sp869"><span className="persName">Mr. SOUTHALL</span>—</div>
              <p className="p-in-sp">When the committee rose yesterday it had under consideration the reports of the Committee on Federal Relations to it referred. <span className="persName">Mr. HOLCOMBE</span>, of Albemarle, is entitled to the floor.</p>
              <div className="speaker" id="sp870">
                <span className="persName">Mr. HOLCOMBE</span><span className="note" id="Note13"><span className="noteLabel">1</span>The delivery of Mr. Holcombe's speech was begun on March 20 and concluded on March 21, but the speech was printed as a continuous text, with no indication of the point at which the break was made. The whole speech is printed here as part of the Proceedings of March 20.</span>—
              </div>
              <p className="p-in-sp">
                There are, no doubt, Mr. Chairman, many members of the Committee who can recall the feelings of a landsman, unfamiliar with the terrors of the sea, overtaken by a night of storm and danger in the midst of the Atlantic, the ship pitching upon the waves, the fog too dense to be pierced by any human eye, the shrill tones of the alarm pipe sounding every moment in his ears, and more
                fearful by far, than wave, or mist, or blast, the rapid fall of the barometer indicating the close proximity of that most appalling because most irresistible enemy of the sailor, the tremendous iceberg, by whose fatal contact the most powerful vessel may be dashed to pieces in an instant of time. I only recollect an overwhelming, pervading sense of utter helplessness, a feeling that
                the weakness of infancy was strength itself, when compared with the ability of man, aided by all the agencies of the highest art, to contend face to face with the great elemental powers of nature.
              </p>
              <p className="p-in-sp">We, sir, are tossing upon the surges of revolution; clouds and darkness cover our future track; an unerring moral instinct warns us of approaching collision between repulsive masses, in whose awful shock all that we love and value may perish.</p>
              <p className="p-in-sp">
                Under such circumstances the highest statesmanship might well bow in mute despair of its own resources, and in humble submission to the direction of an invisible but all-controlling power. For myself, sir, profoundly convinced that upon this assembly depends the decision of an issue as momentous as ever devolved upon any deliberative body in the history of the world ; almost sinking
                under a sense of public responsibility, the prayer which the oldest of poets has put in the mouth of a patriot hero on the eve of battle, recurs to my memory, the simple and sublime prayer for light. Feeble as is my arm, I would raise it in behalf of what I believe to be the cause of liberty, of peace, and of civilization, and, from the depths of my heart, reverently and fervently.
                the aspiration ascends to the Father of all Lights, that illumination. broad and steady, may be poured along our path of duty.
              </p>
              <PageNumber num={86} />
              <p className="p-in-sp">
                In the argument which I shall submit to the Convention, there are some general propositions underlying the whole, that I shall assume without discussion. The institution of slavery is so indissolubly interwoven with the whole framework of society in a large portion of our State, and constitutes so immense an element of material wealth and political power to the whole Commonwealth,
                that its subversion through the operation of any unfriendly policy on the part of the Federal Government, whether that operation is extended over a long or short period of time, would, of necessity, dry up the very fountains of the public strength, change the whole frame of our civilization, and inflict a mortal wound upon our liberties.
              </p>
              <p className="p-in-sp">
                "The spirit of great events strides on before the events themselves." And if there is danger to an interest of such importance, serious danger, although remote, there can be no higher duty which any Christian or any patriot owes to his country, than to resist it in its first approaches. Mr. Calhoun once declared in the Senate of the United States, "that wrongs were most sensibly felt
                and most easily resisted at the extreme limit of right." He had learned this lesson, he declared, from our patriot fathers, who met wrong promptly, and repelled it in the beginning. I believe that this danger is impending; that it is of overshadowing magnitude; and that there is no rational hope of escaping from it, but in the prompt severance of the relations of this Commonwealth
                through the Federal Government, with the free States of the North.
              </p>
              <p className="p-in-sp">
                In maintaining that such is the policy, the interest and the duty of the Commonwealth, I rejoice to feel that I am fortified by the approbation and stimulated by the direction and voice of my immediate constituents. The largest and most enthusiastic meeting of voters held in the county of Albemarle within the memory of living man, took place upon the very day on which at the Capitol,
                in Washington, a revolution in the whole principles and policy of the government was inaugurated. That meeting with most unparalleled unanimity, at the very hour when the present President of the United States was proclaiming doctrines utterly subversive to the principles of that immortal instrument dear to all Americans, but if possible, dear by an especial emphasis of affection to
                the people of Albemarle, in whose bosom lived and died its illustrious author; at that very moment these people were sending forth their voice in unmistakable tones for another Declaration of Independence.
              </p>
              <p className="p-in-sp">
                I also rejoice that in the consideration of this question, we shall be so little embarrassed by Constitutional difficulties; that so large a number <PageNumber num={87} /> of the gentlemen of the Committee, as we may fairly infer, are represented by our Committee on Federal Relations, place the rights of the States and the rights of the people upon such
                high ground, that I feel there is no obligation upon me to vindicate either the right of secession; or, what approaches closely to an equivalent to it, the right of revolution, not in the English sense, of a right to resist a long course of oppression by forcible subversion-of the public authority, but in the American sense—the right of the people to change their government,
                peacefully, when ever they become dissatisfied with it; in other words, the principle embodied in our Bill of Rights, that all governments rest for a legitimate foundation upon the consent of the governed.
              </p>
              <p className="p-in-sp">
                I propose, sir, very briefly, to review the history of this slavery question so far as it is connected with the present condition of the country. I desire to ascertain, in the first instance, what course a proper regard for her own honor and duty would have pointed out to Virginia, if her decision, had been in no wise embarrassed by the action of other communities, and then to
                inquire how far her policy should be influenced by her relations to them.
              </p>
              <p className="p-in-sp">
                That prophetic apprehension of "a geographical line coincident with a moral principle, once held up to the angry passions of men, never to be obliterated," which filled the last days of Mr. Jefferson, and I may add Mr. Madison, with anxiety—that apprehension has become melancholy history. That line, rendered deeper and deeper by successive irritations, has at length severed the unity
                of the nation, and divided us into two distinct and unfriendly people. Prior to the creation of that line in 1820, slavery in theory was condemned universally, at home and abroad ; but the condemnation was a mere passive sentiment of indifference. It was a sleeping abstraction, as inoperative for any practical purpose of mischief, as if it had been a dream, instead of an opinion and
                sentiment. It hardly embarrassed the reclamation of the fugitive; it scarcely offered an obstruction to the transit, when it was convenient, of slaves through free territory; it suggested no offensive discrimination in private or public life; it hardly left upon any of the forms in which the thought of the age found expression, a perceptible impression of its existence. We lived
                together in peace and in friendship, as members of a great family of freedom. On this subject, at least, no angry discord marred what the poet calls, "the unity and married calm of the States." Our attachment to the Union was universal, was manly, and was rational, because the blessings of the Union were solid and real. In every section an equal pride was felt in its glory, an equal
                participation in its benefits, an equal zeal and <PageNumber num={88} /> interest in its perpetuity. It was a common centre of patriotic attachment and hope. This Republic of free States at that time, bade fair to surpass in power and in fame, even imperial Rome itself; not only in the extent of its territory, in the beneficence of its government, but in
                the duration of its empire, and the fruitful immortality of its institutions. Well may the heart of her humblest citizen have kindled with a glow of noble pride at the reflection, not extravagant, that from the great light which filled his own rejoicing sky of freedom, every morning star which should herald the dawn of liberty to other lands, would gild its horns.
              </p>
              <p className="p-in-sp">
                By the operation of a long train of causes which it is unnecessary for me here to describe, that passive sentiment of indifference, that sleeping, harmless abstraction, has been awakened and converted into a principle of active and dangerous aggression. The public conscience of the Northern people has been aroused, and pervaded with a conviction that it is within their power to
                suppress this institution, and that it is their duty to assail such an embodiment of moral, social and political evil, by the exercise of the public authority in every Constitutional form, and by the rebuking pressure of public opinion at every accessible point. Antagonistic forces have been working during the same period upon the hearts and minds of the Southern people, producing a
                revolution as complete but in another direction. It has become their universal conviction, that African slavery constitutes the wisest and most beneficent adjustment possible of the relations between the two races, and that it is to be cherished and defended to the last extremity.
              </p>
              <p className="p-in-sp">Now, whatever other elements of dissatisfaction may have contributed to bring upon the country this fearful strife, there can be no doubt that the convulsive throes which are now rending its bosom, spring mainly from an irrepressible conflict of opposing opinions and sympathies on the subject of slavery.</p>
              <p className="p-in-sp">
                Look, sir, at the expression of this conflict, not only in the action of legislative bodies in the free States, because we might hope that such action—the action of the agents—would be corrected and rebuked by the principal; but, in popular demonstrations, in innumerable places, and in every variety of form. The old association of the people has been reduced to a mere formal
                intercourse on the lines of travel, and in the great centres of commerce and manufactures; the old friendship has departed; the decaying heart of the body politic scarcely throbs with one living pulsation of a comprehensive and unselfish American patriotism; the great fellowships of Church and State, which formerly embraced in one broad and unbroken communion every portion
                <PageNumber num={89} /> of the country, have been rent in twain; and between the people of the North and the people of the South there lies to day a moral gulf of angry and jealous passions, wider and more impassable than that by which the rivalship and feuds of a thousand years have divided the people of France from the people of England. The Northern
                people have covered themselves, by the repeated obstructions they have offered to the execution of the fugitive slave law, with the reproach of a standing infidelity to the most sacred compact of the Constitution. They have cherished in the bosom of their society, associations extensive in number and wealth, openly avowing their purpose to incite and aid the escape of our slaves, and
                not infrequently expressing sympathy with insurrection, rapine and murder. If a similar state of facts existed between any two great powers on the continent of Europe, it would instantly break the peace of Christendom. If France, for example, permitted a society to exist in that country from which money and emissaries were sent to India, to disturb the relations between the English
                residents and the native race, precious as is the cause of peace to both of those great nations, it would not endure for an hour. This same people, forgetting that the only bond of unity which can or ought to keep together an empire of freemen, is an equal participation of rights and privileges, have avowed the fixed and deliberate policy of excluding us from our fair and legitimate
                portion of the public domain, acquired by the common blood and treasure, and of localizing and discrediting an institution, which lies at the very basis of our prosperity and our civilization. Finally, sir, disregarding the solemn lessons of the Fathers of the Republic, the earnest appeal, of a patriotism not yet wholly extinguished, the warning premonitions of an outraged sentiment
                of liberty, they have, by a purely geographical combination, taken possession of that Federal agency which was established for the equal maintenance of the rights and honor of all, to execute this systematic injustice; thus converting the very forms of our Constitution into an instrument, for the destruction of the great ends it was ordained to accomplish. It is as complete a
                revolution, as that by which Augustus, uniting in his own person offices which, under the theory of the Roman Republic, were to be held by independent and distinct functionaries, acquired absolute authority. And it is a revolution as fatal to the independence and honor of the Southern people, as if their liberties had been lost in a disastrous battle with a foreign enemy.
              </p>
              <p className="p-in-sp">
                I speak these words, Mr. Chairman, in sorrow, and not in anger. I cannot discover any ground of rational hope, upon which to expect a change in the relative feeling between the two sections. The great <PageNumber num={90} /> masses of the Northern people are not accessible to any argument or any appeal which it is within our power to make. We are not
                brought into communication with them, except upon the thoroughfares of travel, and in the commercial and manufacturing cities of the seaboard; and the population of this limited section cannot control the opinions or action of the great agricultural region in the interior.
              </p>
              <p className="p-in-sp">This revolution which has taken place within the last forty years in the theories, the opinions and the views of public policy, respectively entertained at the North and in the South, renders it, as I honestly believe, impossible for us any longer to live together, in peace and in prosperity, under a common government.</p>
              <p className="p-in-sp">
                It is no doubt, the first wish of the people of Virginia to preserve this Union, if it can be done upon terms of equality, of honor, of friendship, and of justice to all; but not upon terms of inequality, injustice or degradation to any section. It is also true, that the public voice has declared in unmistakable terms, that the period has arrived, when the whole question must be
                settled forever, either by a permanent dissolution of the Union, or a solid and enduring pacification, upon which we can rely for the enjoyment of our property, for the security of our rights, and for the quiet of our society, through ages to come.
              </p>
              <p className="p-in-sp">
                Now, what elements should enter into such a scheme of adjustment? I shall not, at this time, undertake to compare either of those competing propositions which have been submitted to the country—the Crittenden propositions as amended by the Virginia Legislature, or the Resolutions of the Peace Conference—because there is wanting in each of them, what I regard as an indispensable
                element of any honorable or safe arrangement. One of these schemes does recognize our right of property, does recognize our right to equality; but yet provides no securities by which that recognition is to be rendered available. It has been the habit of our race, of our English forefathers, of our American ancestors, whenever questions of liberty came up for consideration and
                decision, to demand appropriate guaranties of power; power in their own hands, to protect their own rights. The English Barons, when they obtained from King John the great charter at Runnymede, demanded power to secure the execution of its provisions. Twenty-five of the King's castles were placed in their possession, with the privilege of making war upon him for a violation of the
                rights declared in that memorable charter of freedom. It was power in another form, power over the supplies, which has gradually given emancipation and liberty, to the commons of England. It was for power—power to direct the disposition of their own property, that our forefathers <PageNumber num={91} /> went into the struggle of the revolution. Power to
                protect themselves, was claimed and given in the Constitution to the small States, against the large States. And power was accorded, power that was deemed adequate at the time, to the slave States, in the apportionment of representation, to protect themselves against the free States. With all its securities, our fathers accepted that Constitution in fear and trembling, under a
                species of moral duress, to escape the anarchy of the old Confederation. Had they anticipated the facts which exist to-day, the disparity in strength of the two sections, the antagonism of principle and feeling between their respective people, they never would have formed the Union or established the Constitution, without provision, introduced into the organic law, securing an
                equilibrium and balance of power between the sections.
              </p>
              <p className="p-in-sp">
                Now, I take it, sir, that the question for us to decide, is not what the North is willing to give us, but what our rights, what our honor, and the security of our property entitle us to expect and require. The necessity for this adjustment has been created by our Northern brethren; it is, in consequence of their breach of Constitutional obligations; it is because they have trampled
                upon the letter and spirit of the written covenant of the Union, that we are obliged to call for a revision of the fundamental law. The political equilibrium of the sections that in substance existed when the Constitution was formed has been destroyed; every year adds to the relative strength and predominance of the North; the manifestations of hostile and unfriendly feeling cannot
                be mistaken. Under these circumstances, we are entitled not simply to request, but to demand from their justice, the amplest security against every form of injury, which can reasonably be apprehended from their power.
              </p>
              <p className="p-in-sp">
                I shall not, at present, undertake to discuss the details of any form of guarantee, but merely to indicate the great purposes and objects for which guarantees of power are wanted—viz: the power of expansion, and the power of protection from the danger of anti-slavery agitation, and from the humiliation and injustice of sectional ascendency. And, first, unless we have positive power
                in some form placed in our own hands, for the expansion of this institution of slavery beyond its present limits, and its protection as it goes, its extinction, and that, in a comparatively short period of time, will be rendered inevitable. Can we rely upon any of the provisions suggested in either of the competing schemes of adjustment for this power of expansion? I am glad to see
                in one of the propositions—and it is incorporated, I believe, in the report of the Committee on Federal Relations to this body—a recognition <PageNumber num={92} /> of the great principle of a guarantee of power, in the concurrent vote of Southern and Northern Senators, which is made necessary for the acquisition of territory. But I do not see in that
                proposition, any adequate security. The hostility to slavery and to its expansion in the North, will lay an interdict upon future acquisitions of slave territory—except in the barely possible case of a chance, to obtain Canada and Cuba at the same time. I can see no contingency in which we could, through this provision, make a bargain with the North. The acquisition of free territory
                is of slight importance to the North, when compared with the acquisition of slave territory by the South; with the former, it is a question of political strength, and extended empire—no expansion is necessary to its safety. For hundreds of years, with the largest emigration from Europe, there will be ample room in the bosom of our present free territory for its accommodation.
                Expansion with us, is a question of existence. The races cannot be amalgamated; they cannot be severed; their diffusion, under such circumstances, over an area which will prevent a dangerous superiority, in any section, or at any point, of the black race, is a necessity of our position, and essential to the preservation of our civilization itself.
              </p>
              <p className="p-in-sp">Now, if there is any obstruction by which the natural and legitimate channels, for the overflowing of a redundant black population, are closed, the emigration of the white, or the emancipation or destruction of the black race, at some future period, are rendered certain.</p>
              <p className="p-in-sp">
                This policy of ultimate extinction, by contraction, "compelling slavery, like a scorpion girdled with fire, to sting itself to death," was proclaimed by Lord Brougham and other English abolitionists, in a memorable debate in the House of Lords—a debate which led to the commencement of negotiations by the United States, resulting in the acquisition of Texas. The same views have been
                uniformly avowed by the leaders of the Republican party in this country, and unless we can reverse the great laws of political economy, and change principles of human nature hitherto considered as immutable, its truth and justice must be acknowledged. There are now four millions of slaves in the United States. According to the ordinary tables, indicating their ratio of increase, they
                will, in the short space of fifty years, amount to sixteen millions—one-third more than the entire population, black and white, of all of the Southern States, at this time.
              </p>
              <p className="p-in-sp">
                There are laws now in operation, and which will not fail, impelling the black race in mighty currents, towards the warm latitudes of the South—the pressure of the free States upon the North, and the greater productive value of black labor in the South. Whilst the slave population <PageNumber num={93} /> is thus constantly gravitating towards the tropics,
                their presence discourages white emigration to that region, and the character of the climate and soil render exposure to the sun in the culture of cotton and rice, unendurable by the white man. I might add that the statistics of the census illustrate and confirm these statements. They exhibit the fact that the white is gaining upon the black population in Delaware, Maryland,
                Kentucky, Missouri, and, to a slight extent, in Virginia, whilst the black is gaining on the white in all of the Gulf States and in some of them very rapidly.
              </p>
              <p className="p-in-sp">
                What is to be the ultimate solution of this immense problem, it would be idle to inquire; for, with our limited understanding, it would be impossible to discover. We know what lies before us; we know that with the diffusion of our slaves over a large surface of country, the proportional density of the white population constantly increases; and that this diffusion is therefore an
                element of safety. Notwithstanding the immense superiority of the white over the black man, there is a numerical equilibrium which is essential to preserve in peace, the requisite subordination and control. To adopt any policy by which slavery would be hemmed in, within its present limits, does appear to me, when we look at the growing disparity of numbers between the races, the
                perpetual stimulus to dissatisfaction which will be held out to the negro, and his enlarging capacity and increasing facilities for mischief, to be providing for a renewal upon our own soil of the scenes of St. Domingo, and the destruction of the race or the relation, amid national and social convulsion.
              </p>
              <p className="p-in-sp">
                The Southern States, especially those to the South of Virginia, are deeply interested in this policy of expansion. To them it is of more vital importance even than to us. The destruction of slavery would bring upon Virginia a blight and ruin, from which she would not emerge for centuries; yet it is possible that the lands of Virginia may be cultivated by the white man. But the
                question of cultivating cotton and rice with white labor does not admit of debate; in the cotton and rice States the existence of African slavery becomes a question of civilization. They could not acquiesce in any principle or policy in the administration of this government, by which the expansion of their institutions, as the exigencies and opportunities of the future might require,
                should be prevented, without betraying that sacred trust, the guardianship of an inferior race, which has been committed to their keeping by God himself, and purchasing an ignominious lease of security to slavery during their own lives, at the price of its ultimate extinction within the lives of their children, amid the probable horrors of
                <PageNumber num={94} /> servile war. No Southern statesman under such circumstances, could, with reason, anticipate any other result, as time unrolled the curtain of the future, than one of two alternatives—either that his countrymen would be prepared in a debasing school of political degradation for civil and social equality with their emancipated
                slaves, or that from its darkness would burst
              </p>
              <p className="p-in-sp">"Some dread Nemesis, crowned with fire, To tread them out forever."</p>
              <p className="p-in-sp">
                If the Southern people, in an exigency by which the whole course of their history and the future fortunes of this vast continent were to be determined, had not taken counsel of that wise and provident fear, which Burke describes as "the mother of safety," and resolved, whilst their destiny under Providence was in their own hands, to meet the enemy in the gate, their irresolution
                would have covered with undying reproach the very name of free institutions. In this great necessity of power to diffuse, and to protect slavery as it goes, we shall, as I believe, find a rock upon which all schemes of reconstruction will be wrecked.
              </p>
              <p className="p-in-sp">But there are other reasons why we should require guarantees of power. We want power to put an end to this irritating strife, because it is fatal to our peace, dangerous to the security of our property, and through the sectional ascendancy which it has established, irreconcilable with every principle of liberty.</p>
              <p className="p-in-sp">
                How are we to control the further agitation of this subject? If the concurrence of a majority of the Southern members of the Electoral College was rendered necessary to the appointment of the Executive (some new provision being made to meet the contingency of a failure to elect by the College) , and the assent of a majority of the Southern as well as Northern members of the Senate,
                required whenever either of such majorities should call for a sectional vote, to the performance of any act, which under the Constitution is submitted to the Senate, whether the enactment of a law, the negotiation of a treaty, or a confirmation to office, you would go far towards rendering it impossible, to use the agency of the Federal Government in any form whatever to the
                prejudice of slavery, and thus strike at the very root of this political agitation.
              </p>
              <p className="p-in-sp">
                I shall not now inquire how far these guaranties would be consistent with the practical administration of the government. In my opinion they would secure its just and would not impair its efficient action. We have in the Constitution of our own State this day a concurrent majority, <PageNumber num={95} /> requisite to the action of the Legislature, the
                East controlling the Senate, the West the House; and the principle of concurrent majorities for the protection of a minority runs through all the great provisions of our Federal Constitution. An amendment, such as I have indicated, to that Constitution, would take away all power of mischief from extreme men, North or South, and would place the government of the country in the hands
                of its moderate men. There would be a strong motive offered to ambitious men in all sections, to calm, and not to inflame sectional irritation and prejudice. No man could be elevated to any high office who rendered himself peculiarly obnoxious to the North, or to the South. If there is any adjustment under which it is possible for our people to be brought together, and their
                differences to be so completely removed, as to enable them to live in peace, and like the Patricians and Plebeians of Rome, when they returned from Mount Sacer, to build another temple to Concord, it must be upon the broad and enduring basis of guaranties of power.
              </p>
              <p className="p-in-sp">
                Machiavelli, in one of his discourses, tells us that—"Liberty needs new securities every day; but as every day does not afford an opportunity for obtaining them, that the statesman is most culpable who fails to improve any occasion, upon which they may be procured." If the Northern people are not disabled from making concessions in consequence of the moral principles involved; if
                they will yield theoretical acknowledgments of our right of property and of our right of equality, such as are contained in some of these amendments, they will be ready or they ought to be ready to make such concessions of political power, as will render the declarations of right of practical value to us. If their objections to re-adjustment, do not arise from conscientious
                conviction, but considerations merely of expediency, the value of the Union to them, upon any standard of calculation, industrial or political, would repay much larger sacrifices.
              </p>
              <p className="p-in-sp">
                Without security against the continuance of this agitation, not only will all useful legislation be obstructed ; not only will the irritated passions, that now inflame the sections, be perpetuated, but a danger, already formidable to the very security of our fire-sides, will continue to increase in magnitude. Sir, the centre of anti-slavery sentiment has been brought from Chicago and
                Boston to the borders of our Commonwealth, and endowed with a hundred fold power by securing the express or implied countenance and sanction of a public authority—possessed of mighty and pervading energy in every State of the Union.
              </p>
              <p className="p-in-sp">
                Have we not the best of reasons to apprehend danger? Look at the experience of the past, and take a lesson from it. The struggle between <PageNumber num={96} /> the races in St. Domingo commenced long before the enactment of the French Assembly, decreeing liberty to the blacks. It began with the public discussion of the question, out of, as well as in
                that body. The tragedy at Southampton, in our own State, has been traced to the influence of agitation. The John Brown raid was its offspring. Recollect that we enjoy our slave property by a different tenure, from that which secures to us our land and chattels. Unless these are taken by the arm of the law, or by the strong hand of violence, we are safe; but the slave is a man as well
                as his master. He is an ignorant man, and liable, therefore, like all ignorant men, to be misled by extravagant expectations—by false hopes held out to him; and with the noise of a mighty movement for his emancipation sounding always in his ears, can you expect aught else than that, from time to time, there should be outbursts of violence in your society, which will fill the master
                with continual apprehensions, and render it necessary for him to provide safeguards so costly and so burdensome, as that the institution itself will become intolerable? Have you any reason to think that, with a settlement of the question as to the territories, and with amendments to the Constitution providing that the subject shall never be touched in the States or in the District of
                Columbia; that the agitation would cease? Upon what grounds do you form this expectation? Is it that there will be no opportunity to connect the abolition of slavery with party strife? May it not still remain a test of qualification for the highest office in the Republic—a test of qualification for members to either branch of the Legislature in the free States or to either House of
                Congress? Will there not be left means of assault under the forms of the Constitution in the hands of the Northern people, and which they will be stimulated to use by the same motives, that have prompted them to resist the extension of slavery into the territories? Putting the government perpetually on the side of freedom, and against the slave power; waging an uninterrupted war upon
                it; concentrating the public indignation of the world; stimulating discussion and hostility in your own community; adapting the governmental policy to the advantage of the free States over the slave States—are not these, objects, that will be ever present to the Northern mind; objects, as legitimate as the contraction of the area of slave territory, and which will associate the
                Northern people in party combinations, unless you can strike at the root of the mischief, either by taking away from them all ability to use the powers of the government to the prejudice of slavery, or, by changing the opinion and sentiment of duty, which now unites them in party association, to discredit and destroy it. Can you do the latter? Do you expect by any
                <PageNumber num={97} /> force of logic, or power of eloquence to reach the conscience of the North, and to alter its sentiment and opinion in relation to slavery? As I have said before, it is impossible for the Northern people to become acquainted with the facts on which an intelligent judgment can be framed. They do not read our newspapers; they do not
                hear our public speakers; they do not meet us in personal intercourse except upon the great highways of travel. The existing anti-slavery sentiment is encouraged and strengthened every year by an immense emigration from Europe, of men imbued with still profounder ignorance of the nature of slavery, and still more radically opposed to it. This Republican party has not been founded
                upon a question of expediency, which may change with time and circumstances; nor upon a local issue which may be absorbed in one of paramount importance; but upon convictions of right and wrong, convictions of duty, which whenever they constitute an element of political association must commit men to its support, until they have accomplished their purpose. The sentiment of hostility
                to slavery is more generally diffused, and is as strong in the minds of the Northern people, as any passion with which a nation has ever been animated. It is stronger than that love of dominion, which carried the eagles of Rome on the wings of conquest over the globe. It is stronger than that passion for military glory, which has precipitated France into every great war that has
                taken place upon the continent of Europe, for a thousand years. It is the irrepressible out-birth of the conscience of the North, expressing its highest intelligence and purest virtue. Bring the case home to ourselves. The Northern people have put on record their belief, that slavery is a twin relic of barbarism, with polygamy. Now if Mormonism existed in a number of the Free States,
                if this new and worse form of Mohammedanism was rapidly spreading into those territories, to which we have looked as the future seats of Christian civilization, and a great party was once formed in our midst to prevent its wider diffusion, and to discourage and suppress it as far and as fast as this object could be accomplished by the agency of the Federal Government, would not such
                a party, thoroughly organized, be invincible until its mission was performed?
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I shall not read the platform of the Republican party, because that platform, bad as it is, has been moderated so as to embrace the most conservative portion of the anti-slavery element of the North. The temper of a party is best seen in the spirit and opinions of its representative men. I could quote from the most eminent leaders of its conservative as well as radical
                wing, such as Hale, Banks, Burlingame, Wilson and others, acknowledgments that it was not the purpose or the <PageNumber num={98} /> wish of the party to legislate in reference to this institution in the States, but proclaiming that agitation for its ultimate removal by the action of the States themselves, was its purpose and policy.
              </p>
              <p className="p-in-sp">I will not, however, fatigue myself and the Convention, by reading the extracts I had marked for this purpose. You will bear in mind the letter read yesterday, by my friend from Richmond [<span className="persName">Mr. RANDOLPH</span>]
                , containing the sentiment of Mr. Lincoln, that no man who owned slaves ought to be free, and, under a just God would not long remain free. We all remember, no doubt, also that ferocious exhibition of fanaticism on the part of Mr. Chase, which has been recently made public in one of the papers of our city—a fanaticism displayed, too, in view of the present condition of the country,
                and authenticated by the testimony of a distinguished gentleman of our own State.
              </p>
              <p className="p-in-sp">
                As regards Mr. Seward, there is nothing, Mr. Chairman, that seems to me to exhibit a more striking evidence of the infatuation which may overtake a people than that any declaration, on the part of Mr. Seward, at this time, should be accepted as an earnest or reliable pledge of safety to Southern institutions. Why, sir, he is the very apostle, not only of the irrepressible conflict,
                but of a law higher than the Constitution. His whole public life has been devoted to the service of this fanaticism, and there is nothing in the past which he has scrupled to perform for its advancement. There is one single exhibition to which I cannot recur without the strongest feelings of resentment, not only as a lover of my country, but as a member of that noble profession
                against whose honor his attack was particularly levelled. When the Dred Scott decision was rendered, Mr. Seward, to impair the authority of the opinion of the Supreme Court, charged in his place in the Senate, not only without the shadow of evidence to sustain him, but in the face of facts, which have been adduced by Mr. Reverdy Johnson and Senator Benjamin, to show the impossibility
                of its truth ; insinuated again ; and scattered the charge and insinuation broad-cast over the Northern States, that this decision was rendered in a sham cause, made up for party purposes, by means of a corrupt coalition between the highest magistrate of the country, and the venerable Judges of the Supreme Court. Mr. Chairman, I remember the words of a great English writer, in
                reference to Aristophanes, the common libeller of antiquity. He says, that he attacked all conditions of men, calumniated the magistrates, reviled the public assemblies, blasphemed the priests and even the established gods; but that he did not venture to cast one licentious imputation upon that venerable judicature, the awful Court of the Areopagus. The infamy which was spared the
                Athenian satirist, has been reserved to the American <PageNumber num={99} /> Senator. By insinuations falser than ever emanated from the polluted pen of Aristophanes, against a Court exceeding in its purity, its wisdom and dignity, all the Courts of antiquity, in the base service of party, the Senator from New York struck a vital blow at the integrity of
                a free Constitution, and the unity of a great people. Mr. Chairman, it may be, that now he would retrace, in a measure, his steps; that he would not have the country plunged into that civil war, upon the verge of which it seems to stand ; and he may find that, like the evil being of whom the great poet has told us as unbarring the gates,
              </p>
              <div className="lg">
                <div className="l">"Which but herself, not all the Stygian powers</div>
                <div className="l">Could once have moved. * * *</div>
                <div className="l">* * * She opened; but to shut,</div>
                <div className="l">Excelled her power;"</div>
              </div>
              <p className="p-in-sp">
                it is too late to undo the mischief, and redeem the wrong. If this controversy is ever closed in an issue of blood, if the God-given strength of freedom should ever be profaned in fraternal strife, impartial history will lay upon his head, whose life has been devoted to kindling and blowing the fires of sectional hate, all the horror and all responsibility. If such consummation
                should occur, well may he, as he approaches the hour of death, envy that barbaric chieftain who, in a similar moment, re-calling the provinces he had desolated and the cities he had sacked, desired that a mighty river should be turned from its channel, his remains deposited in its bosom, and then the waters turned back upon their ancient bed, and the place of his burial forever
                concealed from the eyes of men; and prefer the dying petition, that some overflowing tide of oblivion, might forever hide his name and memory, from the execrations of after ages.
                [Applause.]
                But, sir, this agitation is not only attended with imminent and increasing danger to our peace, but impossible as it is, to terminate it, until the moral sentiment of the North has been changed, it will perpetuate a sectional ascendency that is destructive of all constitutional freedom by depriving us of the great rights and privileges of self-government. That man, Mr. Chairman, has
                not gone beyond the horn book of liberty, who will confound parties which represent different opinions of the people, intermingled through the whole extent of a country, as to the policy which would promote common interests, with parties existing only in sections, and struggling for the triumph of sectional opinions, passions and interests. The one, into whatever excesses of faction
                the insolence of prosperity may lead it, although an evil, is an admitted necessity of all free governments; the other, as truly pointed out by that great French statesman, De Tocqueville, ought not <PageNumber num={100} /> to be called a struggle of parties; it expresses a struggle between distinct nations, a struggle by one people for the government of
                another. Whenever parties become geographical under a free Constitution, government should also become geographical.
              </p>
              <p className="p-in-sp">There is an interesting discussion by Mr. Madison in one of the most luminous papers of the Federalist, in which he points out the securities to which the framers of the Constitution looked against the dangers of a sectional ascendency, through sectional majorities. The extract is as follows:</p>
              <p className="p-in-sp">
                "If a faction consists of less than a majority, relief is supplied by the republican principle, which enables the majority to defeat its sinister views by a regular vote. When a majority is included in a faction, the form of popular government on the other hand enables it to sacrifice to its ruling passion or interest both the public good and the rights of other citizens. To secure
                the public good and private rights against the danger of such a faction, and, at the same time, to preserve the spirit and form of popular government, is then the great object to which our inquiries are directed. * * * By what means is this object attainable. Evidently by one of two only. Either the existence of the same passion or interest in a majority at the same time must be
                prevented ; or the majority having such co-existent passions or interests, must be rendered by their number and local situation unable to concert and carry into effect schemes of oppression. If the impulse and the opportunity be suffered to coincide we well know that neither moral nor religious motives can be relied on as an adequate control. * * * A pure democracy, by which I mean,
                a society consisting of a small number of citizens, who assemble and administer the government in person, can admit of no cure for the mischiefs of faction. A common passion or interest will, in almost every instance, be felt by a majority of the whole; a communication and concert results from the form of government itself, and there is nothing to check the inducements to sacrifice
                the weaker party. One of the great points of difference between a democracy and a republic, is the greater number of citizens and extent of territory which may be brought within the compass of republican than of Democratic government; and it is this circumstance principally which renders factious combinations less to be dreaded in the former than in the latter. * * * Extend the
                sphere, and you take in a greater variety of parties and interests; you make it less probable that a majority of the whole will have a common motive to invade the rights of other citizens, or, if such common motive exists, it will be more difficult for all who feel it, to discover their own strength, and to act in unison with each other. * * *
                <PageNumber num={101} /> The influence of factious leaders may kindle a flame within their particular States, but will be unable to spread a general conflagration through other States; a religious sect may degenerate into a political faction in a part of the Confederacy, but the variety of sects dispersed over the entire face of it, must secure the
                national councils against any danger from that source; a rage for paper money, for an abolition of debts, for an equal division of property, or for any other improper or wicked project, will be less apt to pervade the whole body of the Union, than a particular member of it. * * * In the extent and proper structure of the Union, therefore we behold a Republican remedy for the diseases
                most incident to Republican Government." (No. 10 of the Federalist.)
              </p>
              <p className="p-in-sp">The same views are presented again in the 51st No., by Mr. Madison.</p>
              <p className="p-in-sp">
                It will be observed, Mr. Chairman, that Mr. Madison, in this most interesting and philosophical paper, anticipates that a security against factious combinations of a majority will be found in the extent of our Federal Territory; and the difficulty which would thence arise of uniting a sufficient number of the States, in schemes of oppression, and of obtaining the necessary concert at
                the precise opportunity. For a long time the experience of the country attested the justice and sagacity of these anticipations. With widely different interests—manufacturing, commercial, planting and agricultural, in different sections, their free play and collision furnished us security, against any serious abuse by sectional majorities, of the public power. But a moral principle
                has subordinated to itself all industrial and material considerations; a principle embracing in its influence, the entire North, and binding together parties, discordant in every other respect, by the powerful cohesion of hostility to slavery. The introduction of this disturbing element into its machinery, has rendered worthless all the checks and balances of the Federal
                Constitution.
              </p>
              <p className="p-in-sp">
                You will observe, that a party having only one idea, and that idea not necessarily antagonistic to any industrial policy of the section in which it prevails, holds a balance of power between parties divided upon other questions, which will enable it, in time, to incorporate its own dogma into one or the other of their platforms. Sir, I recollect, fifteen years ago, hearing Mr. Chase
                enunciate this principle, and I remember being struck, at the time, with its truth, and anticipating the certainty of its triumph. He said, in substance, that the anti-slavery party, in the State of Ohio, contained some twenty or thirty thousand members; men who hated slavery so much that they would vote for Whig or Democrat, would stand upon a Whig or a Democratic platform, as the
                one or the other would take anti-slavery into its creed.
              </p>
              <PageNumber num={102} />
              <p className="p-in-sp">That policy has achieved its first great triumph—and that policy is invincible, unless you can bring about some re-adjustment of the Constitution, which will repress and control this fanaticism, by rendering it utterly impossible, to employ the Federal Government in any way in its service.</p>
              <p className="p-in-sp">
                Shall we rely upon the good faith, the good feeling or justice of the Northern people for security against the wrong and the degradation of a sectional ascendancy? Jealousy, not confidence, has been the tutelary genius of English and American liberty. Shall we believe that men are better than their principles, or shall we bind them in the chains of the Constitution? Will not the
                Executive patronage be prostituted in the North, to reward the active enemies of slavery ; in the South, to reward those who, at least, are willing to sprinkle incense on the altars of fanaticism? In regard to the Legislative department, is there any security against the most partial and unjust laws? Prudence or fear may withhold, for a time, the heavy hand of oppression, but where
                the impulse, the power and the opportunity coincide, sooner or later wrong must come.
              </p>
              <p className="p-in-sp">
                There is one result to be apprehended from this sectional domination, which has made a deep impression upon my own mind. It renders utterly worthless to a Southern freeman, the great franchise of liberty. It transfers the decision of all questions connected with the public policy of the country, from the South to the forum of the North alone. Look at the last election! What issue was
                decided by it? It was the exclusion of slavery from the territories, the placing of the government on the side of freedom. This policy was discussed in the North, but in the North only. It will be the same hereafter in reference to all other great questions, so long as that ascendency continues. Unless some division should occur in the ranks of this Republican party, before the next
                Presidential election takes place, the North, being able to control the election, and that party having the ascendency in the North, would it not be the merest mockery to open a poll for an electoral vote in the South?
              </p>
              <p className="p-in-sp">
                Now, sir, the great difference between the peasantry of England and the laboring men of the United States, arises from the exercise and enjoyment by the latter of the elective franchise. Look at the English peasant, and you will find him blank, stolid, uninquisitive. The English law protects his rights of property and his rights of person, as sacredly, as our Constitution guards
                those of an American citizen. The laboring man of our country is inquisitive, aspiring and intelligent. There is no single cause more operative in producing this difference, than the <PageNumber num={103} /> fact that the laboring man of England has no vote, and the laboring man of the United States has a vote. But this sectional ascendency would render
                the vote of the Southern laboring man worthless in his hands. Sir, it has been, through the discussions addressed to the people, that the laboring men of our country have been elevated. They have learned to respect themselves as men, and as freemen; to enlarge their sympathies beyond the narrow circle of their own homes and business; and to embrace in their thoughts and affections
                the great interests that involve the destiny of their country and their race.
              </p>
              <p className="p-in-sp">
                Now, I do not consider that the material blessings which Liberty brings in its train, constitute its highest claim upon the gratitude of mankind. I value it not only because it awakens all the dormant springs of peaceful industry in a country, because it embellishes life with prodigal creations of taste and art, because it covers our property and our persons with an invincible
                shield, but because it performs a yet nobler function. It establishes a great school for the education of the people in the sentiments and principles of patriotism, justice and honor; and I would not exchange the moral discipline imparted by the exercise of the high trusts and responsibilities of a freeman, for all the material advantages it secures; and whenever we surrender it, the
                battles of the Revolution will have been fought in vain, the cause of the Revolution will be lost, and that power of self-government acquired by our fathers at the expense of so many sacrifices, and under so many dangers, will have perished. No future foreigner, like De Tocqueville, when he visits our Southern land, will gaze with admiration upon the grand Olympic spectacles of a
                free people, the enthusiasm of the immense mass meetings, the magnificent displays of the highest genius in popular oratory, the Press teeming with wit, and eloquence and logic addressed to the reason and passions of the people, on the great questions of public policy. Instead of this life, animation and enthusiasm of liberty, we should have the apathy, the torpor, the brooding,
                waveless calm, of a despotism.
              </p>
              <p className="p-in-sp">
                There is yet another feature in this sectional ascendency. It is that element which rendered so bitter the oppression of the Jew by the Christian, of the Moor by the Spaniard, of the Saxon by the Norman, the element of humiliation ; not only the iron hand of oppression, but the insolent spurn of contempt. Other despotisms have frequently made compensation for the loss of liberty in
                the protection of property, but it is the peculiar feature of this despotism not only to rule but to ruin, not only to ruin but to degrade.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, it was the boast of Demosthenes, in reference to <PageNumber num={104} /> Athens, that she had expended more for the common cause of Greece, than any other city for its own advantage. I take up that memorable declaration, and, looking to American history, I renew it, in all its proud significance, in behalf of our own Virginia. Heroic
                struggles for independence, miraculous refinements in the construction of governments and constitutions, just and liberal principles, inaugurating new eras in public law, wise, honest and beneficent administration of public affairs—miracles of liberty—upon whatever page they are recorded, is impressed the noble image of this Commonwealth. Are we so recreant to the exalted strain of
                our great fathers, as to be willing to endure the humiliation and ruin of a sectional ascendency, worse than colonial vassalage; to permit ourselves to be stript, on pretexts as insulting as they are injurious, of our fair proportion of the public domain; to allow that common authority established for the protection of our rights and our honor, to accumulate in the bosom of our own
                society, elements of danger and destruction, which at a future day must close our long line of glory in the deepest tragedy. Contemptu famae, contemptu virtutis,<span className="note" id="Note14"><span className="noteLabel">2</span>Perhaps the tag should be Contemptus famce, contemptus virtutis.</span> is a great moral law. When any people lose that self-respect which is the spring of public
                virtue and private honor, the public spirit and public conscience, which constitute the most valuable possession of a nation, must become extinct. And, taking up the words of the distinguished gentleman from Kanawha, I say that when this takes place, all will have been lost that is worth defence. It is wise and rational to pause on the threshold of revolution, because while man knows
                when and where it may begin, God only knows when and where it will end. But there is another lesson which we should also take to heart, a lesson exemplified in all history, and expressed in that great retributive law of Providence, by which, sooner or later, ruin overtakes every people who surrender their rights to their fears, and prefer their ease to their duty. Can gentlemen
                anticipate that living under a Union which is already the instrument of inflicting upon us such degradation—a Union which threatens our future destruction—can they expect that any strain of eulogy on this Union, however eloquent, will awaken responsive echoes from the hearts of a free people? Tell me not, sir, what our ancestors have said in their day; they delivered to us sentiments
                and maxims that were just and appropriate in their own time. In my opinion, true veneration is shown for our fathers, not by applying their precepts under circumstances entirely different from those which they contemplated in giving them utterance, but by acting in our day and generation, as we believe that they would have acted, had they
                <PageNumber num={105} /> been standing in our places. No doubt they desired this Union to endure forever. It was their wish that its links might remain as fast, and firm, and bright, as the links of that sacred chain which holds the jarring elements of nature in peace. No doubt, it was their fervent aspiration that Liberty and Union might ever be one and
                inseparable. But there is an inspiration in their "lives sublime," which teaches us another and a yet higher sentiment—a sentiment which breathes in those fragments of the eloquence of Otis and Henry, which have been borne to us on the winds of tradition—a sentiment which is embodied in that glorious scene commemorated by the genius of painting, now so fitly gracing this Hall,<span
                  className="note"
                  id="Note15"
                ><span className="noteLabel">*</span>Referring to a Painting of Washington crossing the Delaware.</span
                >
                in which issues of liberty are to be decided—which is revealed in the majesty of that form, in the serenity of that brow, in the lustre of that eye, as amid the wintry torrents of the Delaware, the Father of his Country pours into a dismayed and desponding patriotism, in its hour of darkest trial, his own unfailing heart of hope—a sentiment which, from every memorial tomb of the
                mighty dead, through all our borders, bursts into speech—"Whenever this Union and your liberties cannot exist together, throw the Union to the winds, and clasp the Liberty of your country to your heart."
              </p>
              <p className="p-in-sp">
                I wish, Mr. Chairman, to express the earnest hope, that if any word has escaped or shall escape my lips which may sound harshly in the ears of any gentleman in this body, he will believe that I have no stronger desire than to treat with most respectful consideration those who may differ from me in opinion on this great question, and that he will pardon much to one who believes, most
                sincerely, that all in life of value to him as a man, a freeman or a patriot, is depending in a large measure upon the decision of this Convention. Momentous as is the material interest of the Commonwealth at stake, the material value sinks into insignificance, in the presence of other considerations. Appalling as would be the calamities of war between the two sections of our
                country, there is a profounder depth of misery and degradation towards which we are approaching, and in which we may be lost. I regard the issue as ,one between the enjoyment of Constitutional liberty, and the inauguration under the forms of the Constitution and the law of the most odious despotism on which the sun ever shone. I regard it as an issue between the uninterrupted reign
                of the arts of civilization and social order, and a frightful chaos of anarchy and of crime.
              </p>
              <PageNumber num={106} />
              <p className="p-in-sp">I have endeavored to prove that there is now an irrepressible antagonism of feeling and opinion between the two sections of the Union; that although error ceases to be dangerous when truth is free to combat it, yet error is dangerous so long as it is inaccessible. I do not despair of the final triumph of Truth :</p>
              <div className="lg"><div className="l">"The eternal years of God are hers."</div></div>
              <p className="p-in-sp">
                Passion and ignorance and weakness may banish or discredit her for a season, but God himself will throw around Truth the shield of His Providence, and finally establish her throne upon the ages of Time. It is thus that I confidently anticipate the final vindication of the purity, and the wisdom of our institutions, and not from any power, on our part, for a century to come, to reach
                the hearts and minds of the Northern people. Sir, their prejudices and their passions would not only be advocates against us, but they would be witnesses against us. They would give credence to no faithful description of slavery. Here and there in their great cities, along the seaboard, may be found clergymen and statesmen who are now ready to do justice to the South. But, in the
                heart of the country, in the vast Northwest, in the interior of New York, and of New England, the regions where lies the seat of empire and power, it is impossible for any man to lay his finger upon any encouraging indication of an important change in public opinion, upon this subject.
              </p>
              <p className="p-in-sp">
                I have endeavored to prove the absolute necessity to our peace and security of various forms of power—power of expansion, power of protection against constant political agitation, and the infinite humiliation and wrong of a sectional ascendency resulting from it. I have also maintained, that, with friendship gone, equality gone, liberty gone, this Union, if it was commended to us by
                the ignoble dictates of prudence, would be spurned by the truer and more generous instincts of manhood. Unless it can be planted upon the everlasting rock of justice, the sooner it is overthrown the wiser, and manlier, and better. Whether the Northern people would be willing to give us the guarantees of power which I have suggested, is not, it seems to me, a question fit for
                discussion in this assembly. Are they necessary and just? We are entitled to claim a full measure of security against their own wrong. Our constituents expect us to exercise moderation in any demand we may make, but the only moderation they will appreciate or commend is an heroic moderation—that moderation which, whilst asking nothing more than a free people ought to expect, under a
                <PageNumber num={107} /> government established for the protection of their rights and their honor, will accept nothing less.
              </p>
              <p className="p-in-sp">
                Mr Chairman, if we stood here to-day surrounded by no complication from the action or attitude of other States, it would have been appropriate for this Commonwealth, it would have been in keeping with all her past, to have inaugurated a movement demanding full security in the Union, and declaring her fixed determination, unless it was given, at once and forever to leave the Union.
                That same spirit which committed our fathers, in advance of any other colony in 1765, to the principles of the revolution; that spirit which committed them, in advance of any others in 1776, to the Declaration of Independence; that same spirit which, after the formation of the Constitution, impelled them in '98-'99 to lead in a struggle for the restoration of its primitive integrity;
                that same spirit would have pointed us to our accustomed place, in the van-guard of a battle for Constitutional liberty. I utter these words not in the spirit of reproach, but in the spirit of regret—regret that the State has lost a great opportunity of fame—but I do not acknowledge that any reproach can be justly cast upon the principles, or the temper of her people. Her situation
                might well have induced her to linger longer in the Union; her own peculiar relations to it; her part in its formation; her central position; her variety of interests and population; the burthen and the misfortune of a violent party feud—all these circumstances would naturally produce greater deliberation on the part of the Commonwealth of Virginia, than of the States to the South,
                without justifying any reflection upon the spirit of her people—their love of liberty or their devotion to principle. Sir, the reproach will be deserved if, when the time for action comes, she does not then move with the power, and with the unity, befitting her strength and her fame.
              </p>
              <p className="p-in-sp">We do not, however, sir, find ourselves in that attitude. Neither States nor individuals can isolate themselves from the consequences of events that transpire around them. It is not for Virginia to say that the Union shall be preserved upon certain terms, or dissolved, if these terms cannot be obtained.</p>
              <p className="p-in-sp">
                We are standing amidst the ruins of the Union; the Union has been shattered, and there is no human architect that can rear the temple again. A number of our sister States—seven, soon in all human probability, to become eight—have not only left the Union, but have actually organized a new Union, and have established a permanent Constitution. They are frequently accused of hot haste,
                in not having shown proper deference to your opinions, and your feelings in changing <PageNumber num={108} /> their Federal Relations, without appeal to you? It is more natural for man, Lord Shaftesbury remarks, to commit two errors than to retract one. Let us have the magnanimity to do justice, although it may involve our own condemnation. Let us bear
                in mind that it is hardly twelve months since Mississippi and South Carolina sent a mission to Virginia, and that Alabama would have taken the same course, had there been any indication that it would be acceptably received.
              </p>
              <p className="p-in-sp">
                The object of this mission was to obtain a conference of all the slave States; a conference, by whose decision it was expressly declared that South Carolina, as well as Mississippi, would be bound, however opposite it might be to their own views of the policy to be pursued. They had previously, by Legislative resolutions, expressed their own conviction that, in the precise
                contingency which has since arisen, and which was then imminent, the Southern States should withdraw from the Union. That the responsibility of acting upon their own judgment in this grave crisis might not be forced upon them, they appealed to Virginia to meet them in general council.
              </p>
              <p className="p-in-sp">
                Taking no part by reason of my official position, in the ordinary contests of party, I was invited, a short time before this mission, to address a public meeting in the county of Albemarle, composed of gentlemen of all parties. Upon that occasion I expressed the opinion that it would be wise and proper for Virginia, in anticipation of the probable election of a Black Republican
                President, to call together her sister slave States, that they might make a common demand upon the North for security and indemnity, or withdraw in a body from the Union. That declaration not only exposed me to personal censure, but the noble institution with which it was my fortune to be associated, to unmerited hostility. In vindicating myself, as I felt called upon to do, from the
                charge of unseemly and improper interference with the politics of the day, and proclaiming my desire to restore harmony and friendship to our divided people, I also expressed the apprehension that in consequence of the division and irritation which would result from our policy, I might outlive the Union and Constitution of my country. For the prudence and patriotism of those
                counsels, and the reasonableness of that apprehension, I this day, claim the melancholy vindication of time. But again, Mr. Chairman, anterior to the election of Lincoln, there was not the slighest indication of public sentiment in the border States from which the people of the Gulf States could have anticipated that another proposition for conference would have been received with
                more favor. No alternative was left to them, but <PageNumber num={109} /> to acquiesce in our decision, or to take such course as, in their own judgment, might be requisite for the protection of their rights.
              </p>
              <p className="p-in-sp">
                I believe it is stated in the preamble of the majority report that Virginia has more interest in this controversy, than any other State. I take issue with the report upon this point. I maintain that the cotton States are more deeply interested than Virginia. So far as the number of fugitive slaves is concerned, the statistics published recently, and said to be from official sources,
                show that the facilities of escape by sea, from the Atlantic coast, and by the Mississippi and its tributaries in the South-west, are such that the actual number of slaves lost this way, is about as great in those States, in proportion to white population, as in Virginia or Kentucky. But this is as dust in the balance, compared with other considerations.
              </p>
              <p className="p-in-sp">
                The degradation of enduring a sectional ascendency, will as deeply wound their honor and liberty, as it would wound our honor and our liberty. On the great issue of expansion and future protection to slavery, they are more vitally concerned than we are. They want future acquisition of Territory, that the normal relation of the races may be preserved for all time, and that there may
                be no great struggle for existence or supremacy between them at any remote day. We are in no danger of such a contest in Virginia. The Southern States will receive all our slaves, but the Southern Territory with the slaves of Virginia emptied into it, will not be adequate to the peaceful accommodation of the black race, which will be ultimately concentrated in its bosom.
              </p>
              <p className="p-in-sp">
                Agriculture may revive from the depression consequent upon a loss of your slave labor, but if you confine the slaves to the cotton States within their present limits, you will compel the white population of that region either to abandon it to the black, or to endure the debasing consequences of an admixture of races. Thus they have to decide a question both of existence and
                civilization, as well as of liberty; with us it is equally a question of liberty, so long as we remain a slaveholding community, but it is not a question of existence or civilization. From the position of the border States they may have more to fear from the dissolution of this Union ; but the cotton States, from their position on the Gulf, have infinitely more to fear, from its
                duration. Delaware is nominally only, a slave State. Maryland will soon be a free State; and so it is with Missouri, and Kentucky. Is it to be expected that States, which by natural and political laws are compelled to be slave States forever, will surrender the keeping of their destiny to States, which may at no distant day, give up this institution?
              </p>
              <PageNumber num={110} />
              <p className="p-in-sp">
                If there has been any ungenerous expression of sentiment towards Virginia, from any quarter in the South, from any newspaper, from any public man, can we not recall sentiments as offensive, from sources as distinguished and presses as influential, and widely circulated in Virginia, towards the people of South Carolina? Does not every sentiment of justice and patriotism command us to
                cease this crimination and re-crimination? It was a solemn duty resting upon the people of the cotton States, to take an attitude which should place their vast interests beyond the reach of peril. Their Confederation has become a fixed and irreversible fact of history, and that fact is one which the sooner we realize the better.
              </p>
              <p className="p-in-sp">
                Upon what do you ground the hope of a reconstruction of the Union? Do you think it will be voluntary on the part of the Southern people? To bring about such a reconstruction, you must not only repeal their fears but their resentments. They have been living for the last three months, in anticipation of being called upon to defend their homes and their property, from desolating
                invasion by a Northern army. During this time all the deadly and all the noble passions of human nature, have been aroused and inflamed. Do you think that the men who have been sleeping upon the sand banks of Charleston, expecting nightly and hourly to be brought into deadly collision with the Northern people, are ready to come back and mingle with them on terms of friendship in the
                administration of a common government? No, sir; the image of the poet truly describes the relative condition of these once fraternal, but now divided States; like cliffs that have been rent asunder by the thunder bolt,
              </p>
              <p className="p-in-sp">"A dreary sea now rolls between."</p>
              <p className="p-in-sp">
                Do you believe that the men who have organized this government, and the great popular body that has sustained it so far, can be brought back into this Union, and induced to submit to Black Republican rule, but by *bloody revolution at home or subjugation from abroad? Are they not able to maintain themselves, in their independent condition? We frequently see in the public prints
                wholesale falsehoods, like the letter that has. just appeared, purporting to be from Judge Lyons, of South Carolina; with details of enormous taxes, interference with private property, mob laws, &amp;c., in the seceded States. I trust that the only weapons we shall use in this controversy will be those of truth and fair argument, and that we will lend no countenance, in any form, to
                a shameless policy which finds its appropriate counterpart in the course of that ancient Deity, who, unable to secure the aid of the <PageNumber num={111} /> Heavenly powers to wreak her vengeance upon its object, turned, in her wrath, and invoked help from the furies of hell:
              </p>
              <div className="lg"><div className="l">"Si flectere superos nequeo, Acheronta movebo."</div></div>
              <p className="p-in-sp">
                These States contain a white population larger than that of the thirteen colonies when our independence was achieved, a territory more compact and defensible, vastly superior resources in money, trained soldiers and all the muniments of war, and a great staple, which makes it almost a necessity, for every civilized nation to preserve with them relations of peace and commerce.
              </p>
              <p className="p-in-sp">
                From the most reliable accounts, the prosperity of these States has never been greater than at this day. Their ability to maintain their government, the fact that it will be a more economical one when it has been once permanently organized, and that the taxes necessary for its support, will be actually less than the proportion which the Southern people now pay towards the maintenance
                of this Federal Government, can be established by indisputable evidence. Will the Government at Washington, be able to bring them back by force? How will it be attempted? It has at length discovered, that to blockade the Southern ports, and attempt to collect the revenue outside of them by armed vessels, would not only cost more than the duties would pay, but would, in all
                probability, involve the Government in war with foreign powers. Neither France nor England would submit for a moment to the exaction of one duty out of the port, and another in the port. To close the Southern ports as ports of entry, by an act of Congress, when the people are repudiating the Federal jurisdiction—and the Government, by its own conduct, confessing inability to maintain
                such jurisdiction—would be regarded by every Government in Europe, as equivalent to a paper blockade, and be resisted as a violation of the rules of public law. Can an army be collected and supported, sufficiently numerous to establish the Federal authority? I have heard a friend of mine relate a conversation which occurred in his presence, at the dinner table of a distinguished
                gentleman of Boston, between men who were regarded as conservative in that city, in which conversation the opinion was generally expressed, that if an attempt should be made to dissolve the Union by the Southern States, Northern capital could and would employ a force of foreign mercenaries sufficient, to subjugate them, without shedding a drop of Yankee blood. We learn from history,
                Mr. Chairman, that when the Persians invaded Greece, they carried with them the marble with which to build a column to Victory, and that upon the plains of Marathon, a temple was raised from that marble to avenging Nemesis; and, if necessary, <PageNumber num={112} /> the long sleeping echoes of those warning lessons which Greece gave to Persia, which
                Switzerland gave to Austria, which the Netherlands gave to Spain, and which our forefathers gave to England, will be revived on many a battle field of freedom, upon our Southern soil.
              </p>
              <p className="p-in-sp">
                No, sir, the Federal Government can neither tempt these States to return, nor can it force them to return. Without some change in its own policy, according to the reliable statement of the New York "Evening Post"—the most conservative and the ablest Republican journal in the country—without some change in its policy, in consequence of the unequal operation of the Southern and the
                Northern tariff, the Government will be bankrupt, before the next crop of corn is ripe. In this condition, let us enquire, what policy is suggested by the report of the Committee on Federal Relations, to Virginia? The policy recommended, is to initiate a proceeding, which cannot be consummated in less than from six to twelve and eighteen months, with the view of obtaining amendments
                to the Constitution, which may possibly result in a re-construction of the Union.
              </p>
              <p className="p-in-sp">
                Now, Mr. President, every material interest of this Commonwealth is going to wreck under the uncertainty as to what is to be her future policy. Whilst we ought to exercise all the deliberation requisite for an intelligent and well considered decision of this momentous issue—yet deliberation protracted one moment beyond that period, would not only be a weakness, but a crime.
              </p>
              <p className="p-in-sp">
                Whilst we are making a movement, hopeless in all human appearance from indications North, as well as South, as to its main object, what precious interests are we not putting in peril? We are exposing the cause of peace. If blood is shed, it is conceded by all that an inextinguishable feud, not to be healed in the lapse of generations, will be opened between the sections. The most
                violent and ultra organs of the Black Republican party have acknowledged, that if Virginia acceded to this Southern Confederacy; if her voice of strength and influence, called for negotiation, and recognition of independence, this fact alone, would make peace a necessity. But we have no assurance from either party, no certainty in the attitude in which the State would be placed by
                the action contemplated in this majority report, that hostilities might not begin in a month. And what would be our condition, if war, under these circumstances, should break out? Our own territory would in all likelihood, become the battleground. The federal authority having been employed in the meantime in concentrating troops upon our borders, if not within the State itself; every
                Virginian in the army and navy that could be sent to a distant <PageNumber num={113} /> territory or a foreign port, withdrawn from the call of his native Commonwealth; no organization or connection with our Confederate States, so as to secure that unity in the defence which will exist in the attack, the war would be prosecuted by us, under every
                disadvantage.
              </p>
              <p className="p-in-sp">
                Why should we defer our decision, until we have more fully ascertained the opinion of the border States? The eloquent gentleman from Kanawha reminded us the other day, that we should think of Maryland lying, as it were, in the lap of Virginia, and Kentucky, leaning upon her shoulder. Will this policy of procrastination, preserve the peace of Maryland, and Kentucky? If that is the
                object at which you are aiming, is it not most likely to be accomplished, by a decisive expression of your purpose to associate Virginia with the States to the South of her? It is uncertainty as to our course which alone puts the peace of the country in jeopardy, and which may, and probably will, precipitate upon Virginia, Maryland, Kentucky and all the border States, the horrors of
                civil war.
              </p>
              <p className="p-in-sp">
                If this Border conference will not contribute to this end, for what do you ask it? Is it that you may commit the destiny of Virginia to the decision of a majority of the Border States? It must be borne in mind that Delaware is really a free State. Maryland has little upwards of eighty thousand slaves, and, according to Mr. Chase's declaration in the letter previously referred to, the
                Federal Government would pay for these, rather than permit her to withdraw from connection with the North. Missouri has only one hundred thousand slaves, and in five or ten years, is likely to become a free State. Kentucky lies separated by the river only from Ohio, with an almost virgin soil, equally adapted to the growth of the staples of Ohio, with a large proportion of white
                population; and with a strong anti-slavery feeling in existence, is destined at a later day to lose her slaves. Are we prepared to commit the fortunes of Virginia into the keeping of States so unequally interested, in the preservation of this institution? Unless we intend to be bound by the action of the conference, it is more likely to result in division, than in union.
              </p>
              <p className="p-in-sp">
                But, sir, the only real issue for this Convention to determine, is as to the permanent attitude of Virginia. Shall she remain in a Confederacy with the North, or associate with the States of the South? Our efforts to reconstruct the Union, will prove abortive. They may, in their consequences, involve the Commonwealth of Virginia and her sister Border States, in a war which could have
                been averted. But, sooner or later, you must decide with which of these Confederacies, you will link your destiny. One gentleman has said that Virginia <PageNumber num={114} /> will go nowhere. In other words that she will stay where she is, in connection with the North. Suppose the government pursues a pacific policy towards the Southern States,
                resulting in the recognition of their independence, is that the opinion of this Convention, as to our future position?
              </p>
              <p className="p-in-sp">I shall not attempt to reproduce in any form the argument so luminously and so exhaustively stated, by my friend from Richmond [<span className="persName">Mr. RANDOLPH</span>]
                , as to the necessity of a union by Virginia with the Southern States, for the advancement of all her manufacturing, mining, agricultural, and commercial interests. Our customers are in the South—our rivals in the North. The manufactures and commerce that now enrich them, might enrich us. But there is one point in connection with our industrial interests not sufficiently developed,
                to which I will allude for a moment. If Virginia remains with the North, the uncertainty as to the security of slave property, the repugnance, moral and political, in the minds and hearts of a large portion of her people to such a connection, must be speedily followed by an immense emigration of slaves and their masters, from the Commonwealth. What effect will be produced upon all
                our great interests, by this loss of capital, and population? The price of land will be depreciated ; the towns which depend for their prosperity upon the prosperity of the country, will languish and decay; the custom of the merchants will be reduced ; the employment of the artisan and mechanic will be diminished; the pressure of the public debt will fall more heavily upon both the
                East and the West; private ruin and public bankruptcy, social anarchy and civil convulsion will overwhelm the Commonwealth.
              </p>
              <p className="p-in-sp">
                Look at it, in its political aspect. It is association, without power or influence, with an unfriendly Confederacy. One of seven slave States, in a Union with twenty free States. Virginia has been accustomed to lay down the rule for the construction of the Constitution, and to fix the principles of public policy, which should guide the administration of the Government. It may not be
                her proud fortune to lead the procession of the States, in triumph up to the Capitol, but I wholly mistake the temper of her people, if she is ever willing to follow, a spiritless and degraded captive, in the train of the victor.
              </p>
              <p className="p-in-sp">
                There are moral, as well as industrial and political considerations, which unmistakably point us to the South. There is to be found our own form of society; there are our nearest kindred ; there are the habits and institutions of our own people; there we may wield the noblest form of power—a moral and intellectual dominion; there we
                <PageNumber num={115} /> may improve and perpetuate our own peculiar type of civilization; there we may build up a splendid Confederacy, homogeneous in its feelings and its interests—a Confederacy that will change the moral sentiment of the world in reference to slavery, because Europe will then cease to look at it through the discolored media of
                Northern prejudice—a Confederacy that will march in unity, in power, in glory and in liberty, upon that great and ample territory, which will be its own, of nearly half a continent.
              </p>
              <p className="p-in-sp">
                The course which has been pursued by the Confederate States, so far from inducing any despondency as to the capacity of man for self-government, furnishes the most signal proof of the power and immortality of the principles of liberty. As the degree of our apprehension, in the presence of any danger, is generally measured by the value which we place upon the object that is exposed,
                many of our best and most patriotic citizens are filled with fear, lest all the lights of freedom and civilization should expire with the Union. My reading of history, leads me, to no such unfilial trust in Providence. I find that no great revolution has ever occurred which did not leave the world in a better condition than it found it; and I confidently expect that the great
                luminary of constitutional liberty, which now seems to many to be setting in darkness, will, on the morrow—
              </p>
              <p className="p-in-sp">"Repair his golden ray,</p>
              <p className="p-in-sp">And warm the nation with redoubled flood."</p>
              <p className="p-in-sp">
                Why should we not form this association? The apprehension of becoming responsible for the re-opening of the slave trade, must have vanished. The permanent Constitution of the Confederate States, settles it forever. The fear of a revenue, to be derived from direct taxes, has, in the same way, been put to rest. The most serious difficulty that disturbs gentlemen, seems to be uneasiness
                as to the condition of the border, which Virginia, Maryland and Kentucky would then constitute. Now let us calmly inquire, what are the dangers of the border? As to the escape of fugitives, it is said the Canada line will be brought down to the Ohio. My own impression is, that the Canada line, for a long period, has been very near the Ohio, and that the security of our slaves is
                rather the result of our ability to keep them at home than our ability to recover them, when they reach the free States upon the border. But if the Southern Confederacy is established on a permanent footing, the Northern people will soon recognize the necessity and advantage of favorable commercial treaties with it. They will then learn that one of the terms of good neighborhood
                between contiguous <PageNumber num={116} /> nations is, that neither should permit its citizens to disturb their neighbors, in the enjoyment of their property; and a refusal on the part of the Southern Confederacy to receive Northern manufactures and produce upon terms equally favorable with other foreign nations, would lead to a treaty, in which they
                would stipulate to pay for fugitive slaves, not delivered, their entire value.
              </p>
              <p className="p-in-sp">
                The most serious danger arises from the exposed frontier, which the Border States would present in the event of war. Apprehension has been expressed, lest we should have the European system of standing armies, introduced upon our continent. This seems to me a phantom, which will disappear in the presence of reason and examination. Why do such armies exist on the Continent of Europe?
                It is simply because the people have not the control over their governments. There are standing armies in Germany, because the ambition of the Emperor of France, or of the Emperor of Austria, may disturb the peace of Europe, at any moment. If the people upon the Continent of Europe, were able to direct to the same extent, as in England, the policy of the State, not only standing
                armies, but war itself would soon become a barbarous anomaly. The history of the last fifty years—certainly up to the accession of Louis Napoleon—establishes the fact, that peace is being recognised more and more, as the standing interest and policy of all civilized nations. Mr. Legare states, in a report which he made to Congress many years since, upon the propriety of establishing
                a tribunal to arbitrate national difficulties, that between 1815 and 1838 innumerable causes for war between the neighboring States of Europe, which in the previous century would have led to the most desolating conflict, had been closed by peaceful negotiation. This great re-action is to be attributed to the spread of humane and Christian principles, the more intelligent appreciation
                by the mass of men of their true interest, the multiplied relations of commerce, and, above all, the increasing influence of the people upon the policy of their government.
              </p>
              <p className="p-in-sp">
                England within the last fifteen years would, on more than one occasion, have declared war against the United States, but for the strength and political power of the manufacturing and commercial classes which it would have prostrated. Will not these considerations apply with equal force, between the Northern and the Southern Confederacies? What will induce the Northern people to
                maintain large armies on their side of the border, from whence the necessity is anticipated of keeping up a similar force upon our side? They must be supported by a fund, raised from direct taxation. With the <PageNumber num={117} /> abstraction from their revenue, of an immense amount of duty now collected in Southern ports; with the injury to their
                manufacturing industry which must result from the permanent withdrawal of a portion, at least, of our patronage, with the expenses of the Government by no means reduced in proportion to the loss of revenue; their territory stretching from ocean, to ocean, will prove a source of weakness, rather than strength. Be assured, sir, the agricultural, manufacturing and commercial people of
                the North will never court the burthen of sustaining upon their frontier, an immense army, to repel an invasion they have no reason to anticipate or fear, or to prosecute a war of aggression, they have no motive to undertake, and no means of rendering successful.
              </p>
              <p className="p-in-sp">
                We have, at this moment, a standing and conclusive answer to this apprehension. We have an immense border on the North—the Canada border—between our country, and a great military power. Yet there are no standing armies collected upon either side of that border. On neither side do the people live in constant apprehension of war. They rely for security, not only upon their courage and
                their arms, but upon those great economical and commercial reasons, which make peace the interest, and therefore the policy of their governments.
              </p>
              <p className="p-in-sp">
                Yet, sir, our Canada border is more exposed, or would be in the event of war, than the border of the Southern Confederacy; because the country upon that border is, to a large extent, a champaign country, through which large armies may more easily advance into the interior. Our border is, to a considerable extent, a broken and mountainous region, in which small bodies of men would be
                able to check the advance of an immensely superior force.
              </p>
              <p className="p-in-sp">
                Is it expected that the spirit and scenes of the feudal age would be revived; repetitions, across the Ohio, of the plundering forays of the English and Scotch outlaws of the fourteenth and fifteenth centuries? Even in old border song, it runs that "there are beeves on both sides of the border." Probably, in this instance, the fattest beeves would be found on the North side, and this,
                I imagine, in itself, would be a sufficient security that the peace would not be broken in this form, from that quarter. Again, sir, the Southern people along that border are eminently military in their spirit and habits. They are the descendants of men who reclaimed that land from the ruthless savage; men who went serenely to their couches at night, when there was constant danger
                that they might be roused from their slumbers by an Indian war whoop or a blazing roof; the descendants of the men who fought, at Point Pleasant, and of the men of West Augusta, to whom Washington <PageNumber num={118} /> looked in the darkest hours of the revolution. If their border was ever invaded, these proud historic memories would lend an
                inspiration of courage to its defence which, like the miraculous light that blazed on the crest of Diomed, would strike terror into the heart of every foe.
              </p>
              <p className="p-in-sp">
                Mr Chairman, a great change has taken place, not yet sufficiently appreciated in the conditions of the world, that must render aggressive wars upon any country containing a large and brave population, almost impossible, unless by immense and well disciplined armies. I refer to the greater facilities afforded by railroads and telegraphs, for purposes of defence, than for purposes of
                offence. They can only be extensively employed in a friendly country. An invading army may be brought, by railroad, to the verge of the hostile border, but, when it crosses into the enemy's country, every step of its progress must be made as it was a hundred years ago. An obstruction placed upon the track by a single hand would cause the destruction of hundreds of lives. They must
                travel slowly, dragging a weary length of waggons, and camp equipage, and ammunition in their train. They must leave garrisons along the whole line of their route, to keep open a communication with their base of operations. But how is it with railroads and telegraphs, for defensive warfare? As soon as it could be known, that an enemy was approaching the line of Virginia, Maryland, or
                Kentucky, the busy lightning, in an instant of time, would proclaim the fact to the remotest frontiers of Texas or Florida, and throughout the whole South, every railroad facility would be used to concentrate an overwhelming force upon the point of attack. Under ordinary circumstances, the railroad car is a magnificent and impressive symbol of power, but our hearts would kindle with
                the rapture of patriot enthusiasm, when we beheld this most powerful agency of nature and art, pressed into the noble service of liberty, crowding with untiring speed by night and by day, towards the post of danger, and pouring all along the line of the border, thousands upon thousands of freemen, ready and able to cover their country with a living wall of impassable fire.
              </p>
              <p className="p-in-sp">
                In the event of hostilities, I have always thought that the most exposed point to the enemy would be our seaboard, and not our Western border. If we ever separate, we must have a border line upon our frontier, instead of that which now separates us from Canada. Sir, I ask our friends from the West to consider this whole question, and to place themselves in imagination in our
                condition; look at it for a moment in the light in which we view it. The North-western district of Trans-Alleghany has, according to the statistics of the last census, <PageNumber num={119} /> about 8,000 slaves, while there are more than 400,000 in Piedmont and Tide-Water. The rest are distributed through the Valley, and the South-West. Of these 8,000
                slaves in the North-West, 3,300 are in the county of Kanawha. Your people can never, therefore, be disturbed by any apprehension of present or future servile wars. The destruction of slave property, would only affect you by the re-action of our ruin. We, as a great slaveholding people, whose whole civilization is interwoven with this institution, may naturally be more sensitive to
                all which threatens its disgrace or its destruction. By all the hallowed associations of our common ancestry and common glory, I invoke you, gentlemen of the West, to let us march, keeping step together, through all the future, as our fathers have done in all the past. Let us ever be as ready, as they were in their generation, to cover with interlocked arms, the rights, the
                institutions, and the honor of the whole State. The poet tells us that liberty has two voices, one of the mountains, and one of the sea. The mingling and blended echoes of both those voices, sounded in the ears of our glorious dead. Let the West stand by the East in this hour of the greatest peril, in all its history; and with a people whose unity no trial or temptation could sever,
                with a territory never to be dismembered, a splendid heritage of power and fame never to be divided, the lofty strains of liberty will ascend from all our borders, in grand and unbroken chorus, so long as mountain floods shall thunder in the West, or ocean surges bellow in the East.
              </p>
              <p className="p-in-sp">
                But, sir, suppose the policy of the Federal Government is not pacific, but coercion is attempted, where will Virginia go then? I am glad to know that the voice which says Virginia will then go nowhere, is very faint and low. If a coercive policy should be adopted, as is now, at least, probable, the question which will be presented to us, is one that must be decided by the heart, and
                not by the head. It is one that does not admit of debate. True, sir, every material interest we possess, would be destroyed by the subjugation of the Gulf States; all the principles of Constitutional liberty we have cherished, would be trampled in the dust; but there are stronger considerations yet, which would never permit us to stand by, and witness such a
                consummation—irrepressible sympathies and instincts of nature. We could never sit around the council board of the nation, with States which presented themselves in garments dripping with fraternal blood. No, sir, if this administration should be so deaf to the commanding voice of justice ; so deaf to the mournful appeal of the wounded, but yet living sympathies of our ancient
                brotherhood ; so insensible to the public opinion of the civilized world, to the common sentiments of humanity, and to the <PageNumber num={120} /> principles of liberty, as to inaugurate this policy of coercion, upon whatever other point there may be uncertainty, there is no doubt, then, that this Commonwealth
              </p>
              <div className="lg">
                <div className="l">"Like a re-appearing star,</div>
                <div className="l">Like a glory from afar,</div>
                <div className="l">Would head the flock of war."</div>
              </div>
              <p className="p-in-sp">
                History informs us that Louis XIV, in the latter part of his life, when stripped of the territory acquired by a long career of conquest, broken in spirit, humbled in his fortunes, ready to purchase peace from his adversaries by almost any concession, broke up the conference of Gertrudenburg rather than acquiesce in the suggestion, to turn his arms against his grandchild on the
                Spanish throne; and the native magnanimity of his hereditary enemies, subdued the insolence of triumph, and applauded his noble pride. This gleam of generous spirit shed around his closing hours a brighter lustre, than was imparted to them by all his earlier conquests.
              </p>
              <p className="p-in-sp">
                Sir, will Virginia be found lending the sinews of war and the prestige of her name, to enable this government to execute a policy of coercion? Sir, for nations as well as individuals, there is something worse than death. Sooner than behold this glorious Commonwealth stoop to the abasement and degradation of this course, I would see her perish in some convulsion of nature, whilst a
                fading lustre from the parting footsteps of liberty and honor, yet lingered upon her monuments of glory. Rather let her, like that famous ship of war, the "Royal George," in the presence of assembled multitudes, in the full blaze of day, with every man at his post, and every gallant streamer flying from the mast-head, upon an anniversary of fame, go down to the depths of the ocean,
                and only leave a floating signal to mark the place of her disappearance. Infinitely would I rather see her fall in a glorious struggle for her own rights, and the rights of her sister States, and leave to future history the memorable response in her behalf, "dead upon the field of honor."
              </p>
              <p className="p-in-sp">Sir, the gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>]
                , alluded most touchingly, most beautifully to his devotion to this old Commonwealth. The bones of my forefathers are buried, like his, in its soil. There, too, repose the more precious ashes of my own household. I cannot say, like him, that I have not been often beyond its borders, but I can say, that wherever I have wandered, I have dragged at each remove, a lengthening chain; and
                that my heart untraveled, has ever fondly turned to her. I can say, that whether upon the Gulf or the Lakes of our <PageNumber num={121} /> own continent, or in the cities of the old world, that sentiment has filled my bosom, which the great master of fiction puts into the mouth of his Highland hero, as he is led to execution in the dying prayer, that
                his head, when severed from the body, might be planted upon the North gate of the capital, and the eyes swimming in death, yet look towards the blue hills of his nativity.
              </p>
              <p className="p-in-sp">
                But, much as I love this Commonwealth, if in this great hour of trial and temptation, she should forsake that noble course of glory which has given to her an immortal history, I, and many another worthier and better son, who could not have been induced to leave her in any extremity of adversity or misfortune, will rather abandon the ashes than the principles of our fathers, and will
                say, with the old Roman, that "where Liberty is, there is my country."
              </p>
              <p className="p-in-sp">
                The President of the Convention, in his opening address, most felicitously and beautifully directed our thoughts to the flag of our country. There was a time, Mr. Chairman, when my heart leaped with enthusiasm, whenever that flag was unrolled. I saw in its glorious fellowship, of stars and stripes, a pledge of mutual friendship; a symbol of equal strength and liberty, and a type of
                common fame forever. It was followed by the benedictions of patriotism wherever it floated, whether in holidays of peace, or nights of tempest, or days of battle. But, when I think of that flag lending its lustre and sanction, to the mockery of free government, which now dishonors the land ; when I reflect that it may soon wave over floating batteries of destruction, seeking with
                shot and shell to reduce to ashes Southern cities, or be borne in advance of Northern armies, carrying the desolation of fire and blood through Southern homes; the stars which beamed together in light and glory, fade from my vision; I see only the emblematic stripe in the red field of blood; and, turning, pained and indignant from the desecrated banner, the native sentiment of
                liberty and patriotism comes bursting from every fountain of feeling in my bosom; "the true colors of your country are the spirit and the principles of your fathers; live under them in freedom, or perish with them in honor."
              </p>
              <div className="speaker" id="sp871"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">As the gentleman from Albemarle is not in very good health, and is exhausted, I will submit the motion that the committee rise.</p>
              <div className="speaker" id="sp872"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                I take it for granted that the Convention will accommodate the distinguished gentleman from Albemarle, and not require him to go on to-day; but I ask the gentleman who has made the motion that the committee rise, to withdraw the motion for the <PageNumber num={122} /> purpose of enabling me to occupy the attention of the committee for a few minutes, not
                in reply to the gentleman from Albemarle at all, but upon a different subject.
              </p>
              <div className="speaker" id="sp873"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">I have no objection to withdrawing the motion for that purpose, and will withdraw it.</p>
              <div className="speaker" id="sp874"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I desire, sir, to submit an amendment to the report of the Committee on Federal Relations, which is now under consideration, in the nature of a substitute. If it be in order, I will send to the Chair and have read my amendment, and will then ask the attention of the Convention upon it for a short period.</p>
              <div className="speaker" id="sp875"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">I did not understand that to be the object of the gentleman when I withdrew my motion, and I will now renew the motion that the committee rise.</p>
              <p>The motion was agreed to.</p>
              <p>The Committee accordingly rose, and the President having resumed the Chair, the Chairman [<span className="persName">Mr. SOUTHALL</span>] , reported progress.</p>
              <p><span className="persName">The PRESIDENT</span> stated the business before the Convention to be the resolutions submitted by the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , upon which the gentleman from Marion [<span className="persName">Mr. HAYMOND</span>] , was entitled to the floor.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.3.3">
              <h3><span className="headingNumber">1.3.3. </span><span className="head">PUBLIC MEETING IN HENRICO</span></h3>
              <div className="speaker" id="sp876"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">with the permission of the gentleman from Marion, I desire to present to the Convention the proceedings of a meeting of a portion of my constituents, recently held in the county of Henrico.</p>
              <p>There being no objection, the proceedings were read as follows:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>MEETING OF CITIZENS OF HENRICO</p>
                  <p>In accordance with notice, a portion of the citizens of Henrico, regardless of party, met at their Court House on Saturday, the 16th, to take into consideration the condition of the country. The Hon. Alex. R. Holladay was called to the Chair, and O. S. Taylor elected Secretary. The Chairman in a very able and appropriate speech explained the object of the meeting.</p>
                  <p>
                    On motion of D. E. Gardner, Esq., a committee of seven was appointed by the Chair to draft suitable resolutions for the action of the meeting. The Chair appointed the following gentlemen, viz: Nathaniel F. Bowe, John A. Hutcheson, Daniel E. Gardner, James T. Redd, N. L. Pelaski, Wm. F. G. Garnett, Thomas G. Bell. Mr. Gardner also moved that the Chairman and the Delegate from
                    Henrico (Dr. Magruder) be added to the committee.
                  </p>
                  <PageNumber num={123} />
                  <p>Dr. Magruder in a few appropriate remarks, asked to be excused from serving upon the committee.</p>
                  <p>
                    During the sitting of the committee, Dr. Magruder was called upon to define his position. The Doctor came forward, and excused himself upon the grounds of a private character, why he did not desire to participate in the proceedings of the meeting, and hoped the meeting would excuse him, and promised to address them at the earliest opportunity.
                    <span className="persName">Mr. W. F</span>. Bowe replied, and stated that it was due to the citizens of Henrico that Dr. Magruder should state to them his views upon the condition of the country. The Doctor then addressed the meeting. He stated that he was ready to lay down his life to preserve the Union as handed down to us by the patriots of the revolution: that he would exhaust
                    all honorable efforts to preserve the Union, and that he voted for the Peace Conference as a final effort; and so far as he was able to judge from the information before him, it had, to his deep regret, signally failed, and that if he were a member of the Convention, with all the lights now before him, he would vote for Virginia resuming all power delegated by her to the Federal
                    Government, and that he was (if need be) in favor of Virginia's assuming an independent position, supplicating to no section, North, South, East or West, and thereby have a voice in the formation of any new Confederacy.
                  </p>
                  <p>Ed. E. Orvis then addressed the meeting, and was followed by John N. Davis, Esq., during which time the committee made their report. The committee submitted a majority report to the meeting, as follows:</p>
                  <p>
                    Whereas, A Black Republican administration is now fully inaugurated, which not only gives us every reason to apprehend all the evils which must naturally flow from the application of their principles to the affairs of the Government and the people, but threatens to exercise compulsive force against sovereign States, and thus to inaugurate war with all its horrors: whereas the
                    efforts of the State of Virginia to obtain satisfactory assurances and guarantees for the rights, the peace and the safety of the slaveholding States have signally failed, and whereas, in our judgment, the time has come when Virginia shall determine whether she will unite with her friends or her enemies; therefore,
                  </p>
                  <p>Resolved, That the State of Virginia should now resume the powers which she delegated to the General Government, and unite her fortunes with those of her sister Southern States, who have withdrawn from the Federal Union.</p>
                  <PageNumber num={124} />
                  <p>Resolved, That to this end we earnestly desire that the State Convention, now in session, should, without further delay, adopt an ordinance of secession, and call upon the other border States to do likewise.</p>
                  <p>Resolved, That the delegate of Henrico county in the State Convention be "most respectfully" requested to carry into effect our wishes as herein expressed.</p>
                  <p>Jas. T. Redd, Esq., submitted a minority report, as a substitute for the resolutions embraced in the majority report, as follows:</p>
                  <p>Resolved, As the sense of this meeting, that the present interests and future peace and welfare of this State require that we dissolve our connection with the present Government of the United States, and that this State resume her full and entire sovereignty.</p>
                  <p>Resolved, That while we do not formally instruct our delegate in the State Convention to vote for an ordinance of immediate secession, we would suggest to him that it is the earnest desire of this meeting, and we believe of a majority of the citizens of the county, that he take such action as will tend to re-invest our State with her full sovereignty.</p>
                  <p>Garland Hanes, Sr., of Henrico, offered the following resolution as a substitute for the minority report, viz:</p>
                  <p>Resolved, That we, the citizens of Henrico, are perfectly willing to abide the decision of the State Convention, believing that the Old Dominion has rights in the Union, and that they will protect and defend them.</p>
                  <p>The vote was then taken upon Mr. Hanes' resolution, which was defeated by a large majority, and Mr. Redd's minority report was also lost, and the majority report of the committee passed by a large majority, several persons giving their votes against the resolution.</p>
                  <p>It was moved and carried that the secretary publish the proceedings of the meeting, and also furnish Mr. Wickham, the delegate from Henrico, with a copy thereof.</p>
                  <p>On motion of Rob. Courtney, Esq., one of the oldest citizens of the county, three cheers were given for secession.</p>
                  <p>There were about one hundred persons present, including a number of citizens of Richmond.</p>
                  <p>A motion was made to adjourn, which was unanimously carried.</p>
                  <p>ALEX. R. HOLLADAY, Chair'n.</p>
                  <p>O. S. TAYLOR, Secretary.</p>
                </div>
              </div>
              <div className="speaker" id="sp877"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">
                It is with some reluctance, Mr. President, that I obtrude upon this Convention a matter which is peculiarly personal <PageNumber num={125} /> to my constituents and to myself. But, sir, desiring always, so far as I can consistently with my views of propriety, to comply with every request made by any portion of my constituency, it has afforded me
                pleasure to comply with that part of the request which requires me to lay the proceedings of this meeting before the convention.
              </p>
              <p className="p-in-sp">Having done that, I have done all that I can do in compliance with the requests of that meeting. The resolution of the majority of that meeting, declare that in their opinion the State of Virginia should resume its sovereignty, and request their delegate upon this floor to give his vote for such resumption.</p>
              <p className="p-in-sp">
                That meeting, Mr. President, as stated in the official account which has been read, was composed of about 100 persons, many of them citizens of the city of Richmond. I have the honor to represent, upon this floor, a constituency numbering more than 2200 voters. In the account of this meeting, which I see in the local columns of the Richmond "Dispatch," I see it stated that the Union
                men there withheld their votes upon these resolutions, declaring that they had sufficiently instructed me at the polls. That instruction I recognize, and I intend fully to carry out upon this floor. But, sir, I have to say to those who composed this meeting of less than 100 persons, out of the 2200 voters of the county of Henrico, that, under the circumstances, I have no hesitation
                in respectfully declining to comply with their request.
              </p>
              <p className="p-in-sp">
                Having settled that, I perhaps have said as much as it is necessary that I should say; and were I a politician, I unquestionably would pause here, and leave the course of future events to take care of themselves, and to be met as they arise; but, sir, I conceive that the use of frankness and candor is one of the golden rules of life, which should always guide every man in his course
                of action; and I propose to go one step further, and state what will be my course under all circumstances. In order to do so, I will have to trespass more at length upon the indulgence of the Convention than I desire to do, and go into a brief history of the manner in which I have been called to represent the people of the county of Henrico.
              </p>
              <p className="p-in-sp">I fear that I may bring upon myself somewhat the charge of egotism, in what I shall say. I certainly should not do what might bring that charge upon me, did I not consider that it was proper thus to justify myself before my constituency and this Convention, for the course I have determined to pursue.</p>
              <p className="p-in-sp">
                Two years ago, I was engaged quietly and peacefully in the, to me, more pleasant pursuits of agricultural life, than the busy turmoil of <PageNumber num={126} /> politics. Against my earnest protestation, I was called upon to become a candidate in the Senatorial District, in which I reside. Against my urgent remonstrance, I was forced to accept the
                position of that candidacy. I was elected, and it has ever since been my earnest effort faithfully to perform the duties of that office and advance the interests of my constituency, and I have had the good fortune to acquire the confidence of that constituency.
              </p>
              <p className="p-in-sp">
                Having taken the first step, it was easy to fall into additional steps in the progress of political life. When the presidential canvass came on I took an active part in it—and whenever I addressed any assemblage of my constituency, I told them that the issue of that canvass was Union or dis-union. I advocated the side of Union and opposed that of disunion. I went further and declared
                before the result of the Pennsylvania State election was known, that Lincoln's election was certain, and that it was therefore more important that the voters of the State of Virginia should cast their votes for Bell or for Douglas and against Breckinridge, than it would be even if they could secure the election of Bell; that if the vote of the State was cast for Breckinridge it would
                at once be asserted that it was in indication that the people of Virginia desired to carry out the policy of the South Carolina school of politicians, and to carry the old State of Virginia out of this Union into the vortex of disunion.
              </p>
              <p className="p-in-sp">These opinions, sir, were fully and freely expressed by me before the public in every section of my district.</p>
              <p className="p-in-sp">The election came on, sir, and Lincoln was elected ; and at the December Henrico Court, I attended a meeting of the people, and there expressed again my sentiments of attachment to the Union and devotion to its interests.</p>
              <p className="p-in-sp">
                The Legislature met, sir, and a bill for the call of a Convention was passed. I published an address to the constituency which I have the honor to represent in the State Senate, pointing out to them the dangers which were about to environ them, warning them to guard against the wiles of the disunionists, and the influence of the secession cry which was raised to entice them from the
                path of conservatism and moderation. I told them that disunion was no remedy for the ills upon us, and urged them to make every effort to maintain the rights of Virginia and yet to preserve the Union.
              </p>
              <p className="p-in-sp">
                Shortly after, sir, nominations were made for the Convention through the papers, of several prominent gentlemen of the county, men of the highest standing and respectability and of the greatest influence, holding, however, opinions at variance with those entertained <PageNumber num={127} /> by the Union party of the State. A considerable number of
              </p>
              <p className="p-in-sp">my constituents sent to me a letter of which the following is an extract :</p>
              <p className="p-in-sp">"Feeling the great importance of having a conservative man to represent us in the approaching Convention, and feeling assured that we cannot get a man who will more truly represent the sentiments of a majority of the people of Henrico county, we call upon you to allow us the use of your name as a candidate for said county."</p>
              <p className="p-in-sp">
                Sir, I used my best efforts to procure some friend, in the county of Henrico, who entertained the same opinions with myself, to undertake the canvass. Failing in that, I at last consented to become a candidate, and in that way come before the people of the county, my opinions having been fully expressed and generally known. They chose to elect me, and I became their representative. I
                now stand here entertaining precisely the same opinions that I did when elected. I stand here ready to defend Virginia's every right; but, at the same time, determined to use every effort to preserve the Union. I stand here acknowledging allegiance to Virginia first of all; but believing that that allegiance will be best discharged and the interests of the Commonwealth best promoted
                by the preservation of the Union. I desire to say that I have no sympathy with those gentlemen, who, while they profess to be the peculiar guardians of Virginia, avow their purpose, if this question is not settled as they desire, to cast off their allegiance and leave Virginia. I, for one, am determined to stand by Virginia; to stand by the allegiance which I owe to Virginia, and,
                come weal or come woe, to remain upon her soil, and, with every breath, to maintain and advance her interests. Should it be my misfortune, that the constituency whom I represent should dissent from my opinions; should it be my misfortune that they should change their opinion, of which I have no fear, I shall still be guided by the programme I proclaimed before the election, and be
                governed by the principles which I advocated at that time. I shall carry out those principles in view of which I have been elected, and should I have the misfortune to incur the displeasure of that constituency, I will trust to time, sir, that curer of ills, to see that justice is done to my motives and actions; and if I do not recover the confidence of that constituency—should I be
                so unfortunate as to lose it—I am satisfied that their children will say to my children, "your father has boldly maintained our rights, and consistently vindicated our honor and our interests."
              </p>
              <p className="p-in-sp">
                I have said so much, sir, because in the first place, I felt it right that I should justify myself to my constituents; and, secondly, knowing that an effort has been made, by some gentlemen, to embarrass <PageNumber num={128} /> me in my position, I deem it proper to show them that no attempt that they might make, and no pressure that they might seek to
                bring to bear upon me, will have the effect of causing me to change the views which I have entertained, and do now entertain, upon the subjects to which the proceedings submitted by me refer.
              </p>
              <p className="p-in-sp">And lastly to assure that Union-loving constituency that sent me here, that they have not mistaken their man, but that I will fully carry out those principles which they elected me to advocate.</p>
              <p className="p-in-sp">I now move that the proceedings be referred to the committee on Federal Relations.</p>
              <p>The motion was agreed to.</p>
              <div className="speaker" id="sp878"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The pending question is upon the resolution of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] .</p>
              <div className="speaker" id="sp879"><span className="persName">Mr. OSBURN</span>, of Jefferson—</div>
              <p className="p-in-sp">With the permission of the gentleman from Marion [<span className="persName">Mr. HAYMOND</span>] , I would ask leave to submit the proceedings of a meeting of my constituents.</p>
              <div className="speaker" id="sp880"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Does the gentleman from Marion yield the floor.</p>
              <div className="speaker" id="sp881"><span className="persName">Mr. HAYMOND</span>—</div>
              <p className="p-in-sp">I do, sir.</p>
              <div className="speaker" id="sp882"><span className="persName">Mr. OSBURN</span>—</div>
              <p className="p-in-sp">I beg leave to present the following proceedings of a meeting of my constituents, which I would ask to be referred to the committee on Federal Relations.</p>
              <p>The proceedings are as follows:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>
                    At a meeting of the citizens of Harper's Ferry and Bolivar, held at Campbell<span className="note" id="Note16"><span className="noteLabel">3</span>The Journal has "Camp-Hill School House."</span> School House, on Friday the 15th March, 1861.
                  </p>
                  <p>On motion, E. H. Chambers was called to the Chair, and A. McCliesh appointed Secretary.</p>
                  <p>On motion, it was</p>
                  <p>Resolved, That there is no change of public sentiment upon the subject of secession, so far as the people of Harper's Ferry and Bolivar are concerned, and that we fully endorse the resolutions adopted by the Convention at Charlestown, on the 21st January, 1861, which nominated the Union candidates, Messrs. Osburn and Barbour.</p>
                  <p>On motion, it was</p>
                  <p>Resolved, That the position of our Senators in Congress and in the State Senate, does not reflect the true sentiments of their constituency; and that we regard their conduct as being in open rebellion to our interests as a people and at war with the Constitution of our beloved country.</p>
                  <p>On motion, it was</p>
                  <PageNumber num={129} />
                  <p>Resolved, That a copy of the proceedings of this meeting be sent to Messrs. Osburn and Barbour, delegates to the Virginia Convention. The meeting then adjourned.</p>
                  <p>E. H. CHAMBERS, Chairman</p>
                  <p>A. McCliesh, Secretary.</p>
                </div>
              </div>
              <p>The proceedings were ordered to be referred.</p>
              <p>THE TAXATION QUESTION</p>
              <div className="speaker" id="sp883"><span className="persName">Mr. HAYMOND</span> [resuming] said :</div>
              <p className="p-in-sp">
                Mr. President, I feel somewhat embarrassed in rising to address the Convention at this time, by way of concluding the remarks commenced by me on yesterday. I am aware, sir, that the Convention to-day has already been detained for a considerable time by a gentleman much more able and eloquent than myself, and upon a subject in which a large portion, perhaps the largest portion of this
                body, on this particular occasion, feel a deeper interest than the subject I have under discussion. I should not now proceed to the discussion were it not for the fact that I feel it to be my duty to the constituency whom in part I have the honor to represent, to conclude this discussion and ask a vote, as soon as possible, upon the proposition now before the Convention.
              </p>
              <p className="p-in-sp">Without recapitulating or referring particularly to the remarks that I offered to the consideration of the Convention on yesterday, I shall proceed at once with the subject.</p>
              <p className="p-in-sp">
                I was endeavoring to show, by an assumed mode of calculation, which I believe to be right in the abstract, the amount of taxes collected by the officers of the Commonwealth from the slave property therein according to the provisions of our Constitution as they now exist, with a view of showing to the Convention and to the country, that that Constitution, as it at present exists, is
                erroneous and ought to be changed. It will be perceived that in the remarks I have submitted, I have assumed that slaves over twelve years of age were worth, upon an average, $600 each, and that slaves under twelve years of age were worth $300 a head. Now, sir, according to that assumption, as I stated on yesterday, the value of the slave property of the Commonwealth is $234,430,000,
                but according to the provisions of the Constitution as it now exists, $81,600,000 worth of that property only is taxed. And, sir, we also find by carrying out that calculation, that instead of receiving $326,000, as we now do under the provisions of this Constitution, we should receive from that species of property, if taxed as other property, according to value, $629,600.
              </p>
              <p className="p-in-sp">
                It will be seen, sir, by a reference to the calculation which I have <PageNumber num={130} /> submitted, that I suppose the average value of all the slaves of the Commonwealth, young, old and middle age, to be about $450 each, and, in my judgment, that is a reasonable estimate of the value of that property, and if that estimate be reasonable, if it be
                proper, as I remarked a minute since, we should receive into the treasury of the Commonwealth, from that property, $629,600, instead of $326,000 which it now pays under the provisions of the present Constitution.
              </p>
              <p className="p-in-sp">
                But suppose I should be mistaken in estimating the average value of that species of property at $450, suppose that it be not worth more than $300 a head, I mean averaging the whole, what would be the state of affairs in this regard? Taking the 511,000 estimated by the auditor as being the number of slaves in the Commonwealth, and estimating them at at $300 each, the amount of money
                we would receive from that property in taxes would be $613,200 instead of $326,000 which we now receive, being a difference of $287,200.
              </p>
              <p className="p-in-sp">
                But suppose that each of the estimates that I have made should be too high, why, sir, it does not change the principle which I am endeavoring to force upon the Convention and community, namely, the justice, the propriety and the equality of the ad valorem system of taxation. That species of property should contribute for the support of the government, and for the protection of itself
                as well as the protection of other property in the Commonwealth.
              </p>
              <p className="p-in-sp">But, Mr. President, I was endeavoring to show on yesterday, that the best interests of the Commonwealth required that the Constitution should be amended in this particular—that the harmony of the Commonwealth required it, and that the honor of the Commonwealth in future, so far as her protection was concerned, absolutely demanded it.</p>
              <p className="p-in-sp">I remarked yesterday that the debt of the Commonwealth of Virginia was over $40,000,000. I shall now proceed to state to the Convention what is the amount of that debt, and what, in my judgment, is just and proper for this Convention to do in order to prevent the increase of that debt and bring about a decrease of the now oppressive taxation.</p>
              <p className="p-in-sp">
                By reference to the Auditor's last financial report, we ascertain exactly the amount of the present debt; I mean the funded debt and the debt which is not funided—the debt for which taxes are now required to be levied, and the debt authorized by law, for which taxes will be required to be levied, whenever the bonds of the Commonwealth can be disposed of under the acts making the
                appropriations. I find in the Auditor's financial report, under the head of the amount <PageNumber num={131} /> of State debt for taxes, that the aggregate amount of it, including $537,875 of the bonds of the James River and Kanawha Canal Company, amounts to $35,889,659.63. That I understand to be the amount of the actual registered debt of the
                Commonwealth of Virginia, for which our Legislature is required to provide by taxing the people, or rather property.
              </p>
              <p className="p-in-sp">
                But, sir, that is not the whole amount. We not only have this enormous amount of debt hanging over Virginia and resting upon us a blighting incubus, but we have, in addition to this, another large sum which has been appropriated by the Legislature—a debt directed to be contracted, and which the tax-payers of Virginia will be required to pay in time to come. Under the head of
                appropriations not funded, in the same report, it will be found, without specifying or enumerating particularly the several items, that the aggregate amount of the authorized debt which has not yet been funded, is $7,563,993.24.
              </p>
              <p className="p-in-sp">But, sir, these are not the only liabilities of Virginia. In addition to these liabilities already accrued and authorized by law, Virginia has guaranteed the bonds of Internal improvement companies and cities for a large amount of money, amounting in the aggregate, as stated in the Auditor's report, to $1,638,500.</p>
              <p className="p-in-sp">
                Now, sir, adding these items together, and what do you find the present debt of the Commonwealth of Virginia to be, provided for by taxation upon the property of the people. It is $45,092,152.87. Then how do you find the financial condition of Virginia to be? How is her credit at present in the great money marts of the world. We find that this debt, by what I consider to be
                extravagant and improper appropriations, has been increased to such an enormous amount, and such an amount of our bonds has been thrown into the market, that absolutely, instead of being honored as she was years ago by her credit being at par, her bonds are now 25 per cent below par. Yes, sir, in all parts of the United States, the bonds of Virginia are sold at 25 per cent below par.
                Is this right and proper? Should such a state of things as this exist in this Commonwealth, in this day of our trouble, when it may become necessary to raise her arm in battle in defense of our own honor and the rights of our own people? Is this debt to go on increasing and increasing, not only by appropriations to works of Internal Improvements, but for matters of defence and for
                arming the people in defence of the honor and the institutions of the Commonwealth?
              </p>
              <p className="p-in-sp">
                Mr. President, I endeavored to show, yesterday, to the owners of <PageNumber num={132} /> slave property, that it was for their interest to have it taxed as other property is taxed. I proceeded to assign various reasons why the Convention should proceed to amend the Constitution touching taxation, &amp;c. There are now two other reasons which I beg
                leave to state to this body; one of which is in answer to objections made by gentlemen who have already preceded me. It is said that this Convention should not consider this subject, because it was not called for that purpose, and the people had not this subject in view at the polls, and, therefore, the Convention should not spring it upon them. Let us look at the act of the General
                Assembly. We will find by examining this act that this Convention was called as much for an amendment of the organic law of the Commonwealth as it was for the consideration of our Federal Relations. Does it not provide upon its very face that the action of this Convention, dissolving our relations with the Federal Government or amending our organic law, shall be submitted to the
                people for their ratification, if they so voted. Yes, sir, it is explicitly enumerated and stated, and there can be no mistake or misunderstanding about it.
              </p>
              <p className="p-in-sp">
                I maintain, therefore, that the objection to the Convention considering and making amendments to the Constitution on this score is unalterable.<span className="note" id="Note17"><span className="noteLabel">4</span>Perhaps this last word ought to be "unacceptable" or "unallowable."</span>
              </p>
              <p className="p-in-sp">My reply to it, as before stated, is that the act of the Assembly itself shows that it was in contemplation to amend the organic law, or that it might be done. I would remark to the members upon this floor, that in the region of country from which I came, this subject was considered and agitated as much as that of Federal Relations.</p>
              <p className="p-in-sp">But there is another remark I wish to make. The gentleman from the county of Bedford [<span className="persName">Mr. GOGGIN</span>]
                and other gentlemen, have offered for the consideration of this Convention ordinances of secession. Aye, sir, an ordinance has already been offered and laid on the table, to abide the order of the Convention, by which Virginia is declared to be out of the Union. Resolutions have been offered here by gentlemen providing, in view of the action of this Convention in relation to an
                ordinance of secession, that the military of the Commonwealth shall be called out and instructed in military tactics at such convenient points and places as in the judgment of the Governor may be proper. If that ordinance of secession be granted, what is to be the state of things? Virginia must at once place herself in a condition for war. She must place herself upon the defensive,
                if she does not assume an aggressive position. How many men is she to call into the field? How many is she to arm? How many muniments of war is she to distribute?
              </p>
              <PageNumber num={133} />
              <p className="p-in-sp">
                How much will it cost before the Revolutionary war, which is to be inaugurated, ends? Can any man inform us? What is the expense of the standing army of the United States? I believe that it comprises some 17,000 men. It costs the government of the United States, as I understand it, from seventeen to eighteen millions of dollars annually. Sir, if that small and insignificant army
                costs the government of the United States that immense sum per annum, how much will the army of Virginia cost, which is to be called out if she dissevers her relations with this Union, in order to defend and vindicate her dignity? It will be millions on top of millions. Am I to sit here as a member of this Convention, representing a population who are not slaveholders, but who are
                compelled to pay taxes on every cent that they are worth, keep my mouth shut and be prevented from bringing to the consideration of this body the question of taxing slave property according to its worth, upon the ad valorem principle.
              </p>
              <p className="p-in-sp">But, the gentleman from Northampton [<span className="persName">Mr. FISHER</span>]
                , tells me—I do not now see him in his seat—and he tells my colleagues from the section of country from which I come, that if we will unite with him in having an ordinance of secession passed, then, although he does not speak by the authority of his constituents, yet, upon his own responsibility, he will grant to us that equality of taxation which we demand as a right.
              </p>
              <p className="p-in-sp">
                The only reply I have to make is this. We maintain that the great principle of ad valorem taxation is a just and proper principle, and if it be right, just and proper to adopt the principle out of the Union, it is right and just to adopt it in the Union. And I say, moreover, to that gentleman and his constituents, if he represents them, that we decline any such bargain. My
                constituents may not have as much of this world's goods as the constituents of some other gentlemen, but there is one virtue which my constituents have, and I thank God for it, and that is that they can neither be bought or sold, and I trust in God that representatives upon this floor will be true to the will and virtue of their constituents. I demand here for my constituents what I
                believe to be their right, and I am willing to do what is right for others. I am not here representing the principle of ad valorem taxation as applying exclusively to my section of the State. I maintain that it applies to the slaveholding as well as to the non-slaveholding sections of this entire State, and those two classes should be harmonized from the Atlantic upon your East, to
                the Ohio on your West.
              </p>
              <p className="p-in-sp">But, Mr. President, another resolution has been offered here which I do not wish to fail noticing. It is a resolution offered by the <PageNumber num={134} /> gentleman from Northampton [<span className="persName">Mr. FISHER</span>]
                , a few days since. It seemed to be offered in view of the fact that the General Assembly of Virginia might fail to increase the debt of the State beyond what it now is. This resolution provided, by direct ordinance, for the appropriation of a million of dollars from our Treasury. It seems, although the ordinance was not entertained by this body, that it had the effect to awaken into
                life in the mind of the General Assembly, the necessity of the passage of such a bill, and they accordingly passed it a few days since. What expedient is resorted to, in order to carry it into effect? They have legislated away, appropriated away and destroyed the credit of their own Government—Virginia now stands in the moneyed markets of the world with her bonds below par, with
                depreciated credit—a credit not equal to that of many of her solvent citizens.
              </p>
              <p className="p-in-sp">
                The Legislature decline to authorise the banks of the Commonwealth to issue small notes for fear of corrupting the currency. I believe that this is right. I agree with them in principle in this respect; but how do they propose to carry into effect this bill appropriating a million of dollars? Why, Virginia, this proud old mother of ours—about whom we have heard, so many thrillingly
                eloquent remarks—has gone into the shin-plaster business, under the name and title of treasury note bills. Yes, sir, we are to have that species of currency in the Commonwealth of Virginia; and what are we to lose by it? If the bonds of the Commonwealth are 25 per cent below par, will not her treasury notes, which are but simple promises to pay, be dishonored to the same extent, and
                will not Virginia lose 25 per cent exactly upon every dollar that she expends by virtue of the law authorizing the issuing of these treasury notes?
              </p>
              <p className="p-in-sp">
                To illustrate : Suppose the Governor of the Commonwealth undertakes to make a contract with any person, to provide for the defence of Virginia at any point where he may deem it necessary, and he proposes to pay the contractor in treasury notes instead of gold and silver. Will he not charge the Government of Virginia 25 per cent more than he would if he were paid in gold and silver?
              </p>
              <p className="p-in-sp">
                I mention these facts in order to show you the true condition in which Virginia is placed. Look at her currency, at her finances, from one end of the country to the other. What do you find to be the fact? There is in circulation but a very small amount of specie, and I presume there is but a small amount of actual specie in the vaults of our Banks. Not one dollar of the issue of the
                Bank notes in <PageNumber num={135} /> circulation, which you are bound to receive in payment of your taxes, is convertible currency.
              </p>
              <p className="p-in-sp">
                Thus you have before you the true financial condition of Virginia—her credit twenty-five per cent below par—her people furnished with a currency utterly worthless, even in the adjoining States, or in your own midst. We who are here representing the people of the Commonwealth, as best we can, are paid in this unconvertible currency, and if we want a small amount of change, we must go
                to a broker and submit to a shave of from three to five per cent. And yet gentlemen speak of the honor of our old mother, and refuse to lay their hand upon her organic law, unjust, odious, and oppressive as it is. I appeal to the members of the Convention from the East and the West, if it is not high time, and imperatively necessary, that this reckless and extravagant expenditure
                should cease, and that the debt of Virginia, instead of being increased, should be decreased.
              </p>
              <p className="p-in-sp">
                Mr. President, the grand criterion of sovereignty is to command and to be obeyed, and to require obedience by protection—protection from imposition, from fraud, from partial taxation, from all odious inequalities, wrongs and injuries. Virginia should deal with all her children alike and not favor any exemptions from burthen, as to one class of her sons, which she does not favor and
                commend as to all. Then let this exemption of slave property from the principle of ad valorem taxation be stricken from the Constitution. Do this and you will have accomplished a great end.
              </p>
              <p className="p-in-sp">But, Mr. President, a word or two more and I am done. The course of proceeding in relation to our demands here has appeared to me to be singular in the extreme. We represent upon this floor, I undertake to say, the wishes of the majority of the voters of this Commonwealth. The grievances of which we complain are the grievances of those voters.</p>
              <p className="p-in-sp">
                We have simply asked this Convention to inaugurate a Committee of Enquiry to investigate these complaints and these grievances in regard to our present Constitution. And, sir, every time a proposition of that kind has been offered, it has been met by a motion to lay on the table, so as to cut off debate; and we, who represent that class of people, are compelled, upon the mere motion
                for a committee, to discuss the merits of every proposition that we may contemplate bringing before the committee, or of which the committee could take cognizance.
              </p>
              <p className="p-in-sp">
                Is there any precedent for such a course of procedure? Where has such an example been set? Not in the General Assembly. No section <PageNumber num={136} /> of the Commonwealth that has ever come before that body, complaining of injuries and asking for redress, has been refused a committee, or been strangled in their very first efforts at vindication and
                redress. Will this Convention set such an example? Are dear rights thus summarily to be treated?
              </p>
              <p className="p-in-sp">
                Mr. President, I have endeavored to show to the slaveholders, by actual and dispassionate argument, as I trust, taking in connection with what I have said to-day what I said yesterday, that it was their interest and their duty to come to the relief of the Commonwealth of Virginia and to the tax payers, with their species of property, and that it was just, and that it was eminently
                proper that they should do so at this juncture of time.
              </p>
              <p className="p-in-sp">
                Now, I have a word or two to say to my brethren of the West, delegates with me on this floor. I ask them how long it has been since their constituents and their constituents' fathers have prayed as Virginians, arid for the sake of Virginia, and for Virginia's honor, that the free white men of this Commonwealth not contaminated by crime, should be permitted to convene in a body like
                this, upon terms of perfect equality? And for what purpose? For the purpose of breaking those manacles which have oppressed them so harshly during the entire life-time of the Commonwealth of Virginia. We have here a majority of fourteen I believe. While we are here, there are many of those who represent patriotic constituencies in the East, who would be willing to join with us in
                correcting this error in the Constitution, as I believe, if we are but united. Are we now to desert our constituents? When will we have another opportunity to redress their wrongs by correcting its existing evils? Some say in 1865. How would we have an opportunity in 1865? Our Constitution does not provide for the calling of a Convention like this. Then it does not provide for an
                equalization of the taxes, or for relieving them from any of the burthens and inequalities of which they have complained so long and so justly. Can we place our hands on our hearts and before our God and our country plead that it is not right now to amend the Constitution? If we fail to amend the Constitution now, will we, can we justly expect to retain the respect and confidence of
                our respective constituencies? These are matters about which we should consider, and consider wisely, and particularly in full view of our responsibility. I trust each one of us will discharge our duty and our whole duty in this particular. If we prove unequal to our duty and fail to reform our organic laws, I fear the consequences will not be favorable to the peace of Virginia. And
                that our constituents will justly conclude that <PageNumber num={137} /> we, for the want of proper courage and wisdom, have riveted upon their necks the yoke of inequality and oppression.
              </p>
              <p className="p-in-sp">
                Mr. President, I deeply regret the necessity which required me to trespass on so much of the time of the Convention. I have done so in the discharge of a simple duty, the highest duty I owe to my constituents. I feel that their grievances are grievances in fact, that they ought to be removed, and there is no excuse upon the face of the earth for the failure of this Convention to
                remove them. And if there ever has been a time in the history of Virginia when it was improper to retain in her Constitution odious inequalities such as I have named, it is at this very period when that species of property to which Virginians are so much attached, and which we are all willing to defend with blood if need be, is the cause, the sole cause of all this extraordinary
                expense now being incurred, and of the universal mourning which is spread all through our beloved country.
              </p>
              <div className="speaker" id="sp884"><span className="persName">Mr. BRANCH</span>, of Petersburg—</div>
              <p className="p-in-sp">I would like to ask the gentleman if he is willing to take up the organic law of the State and make the necessary amendments to go into effect in 1865, provided this Convention deem it proper to make such amendments?</p>
              <div className="speaker" id="sp885"><span className="persName">Mr. HAYMOND</span>—</div>
              <p className="p-in-sp">
                The gentleman will understand me to say, that I contend that the ad valorem principle is now and has always been right, and that the Constitution ought to be so amended as to put that principle in operation now. That is what we contend for. If we cannot get anything better at the hands of this Convention, than the proposition of the gentleman to adopt the ad valorem system to go into
                effect in 1865, of course we would be bound to receive<span className="note" id="Note18"><span className="noteLabel">5</span>The pronoun "it," or some noun, must have been left out here.</span>; but I contend that it would be unjust and wrong to compel us to wait until the expiration of that time when so much peril and additional expense is rendered necessary by reason of this property.
              </p>
              <p className="p-in-sp">Again I thank the Convention for the kind attention with which they have listened to my remarks.</p>
              <div className="speaker" id="sp886"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
              <p className="p-in-sp">I move to lay the resolutions of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , on the table.</p>
              <div className="speaker" id="sp887"><span className="persName">Mr. HALL</span>, of Marion—</div>
              <p className="p-in-sp">I hope the gentleman will not make that motion at this time.</p>
              <div className="speaker" id="sp888"><span className="persName">Mr. GOODE</span>—</div>
              <p className="p-in-sp">I must decline to withdraw the motion.</p>
              <div className="speaker" id="sp889"><span className="persName">Mr. HALL</span>—</div>
              <p className="p-in-sp">I ask for the ayes and noes on that question.</p>
              <div className="speaker" id="sp890"><span className="persName">Mr. WILLEY</span>, of Monongalia—</div>
              <p className="p-in-sp">I think if the gentleman from Mecklenburg, intends to press his motion, that the ayes and noes should be called on the question.</p>
              <PageNumber num={138} />
              <div className="speaker" id="sp891"><span className="persName">Mr. WILLEY</span>—</div>
              <p className="p-in-sp">I do not wish to make any amy remarks upon the subject.</p>
              <div className="speaker" id="sp892"><span className="persName">Mr. GOODE</span>—</div>
              <p className="p-in-sp">I will yield the floor to the gentleman from Monongalia, with the understanding that he will renew the motion.</p>
              <div className="speaker" id="sp893"><span className="persName">Mr. GOODE</span>—</div>
              <p className="p-in-sp">Then I decline to yield the floor.</p>
              <div className="speaker" id="sp894"><span className="persName">Mr. BOGGESS</span>, of Lewis—</div>
              <p className="p-in-sp">I desire to inquire whether the question should not be taken on the first of the resolutions?</p>
              <div className="speaker" id="sp895"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The motion is to lay the whole on the table.</p>
              <div className="speaker" id="sp896"><span className="persName">Mr. BURDETT</span>—</div>
              <p className="p-in-sp">I move that the Convention do now adjourn.</p>
              <p>On this motion a division was called for, and the motion to adjourn carried by a vote of 51 to 37.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v2.2.4">
            <PageNumber num={139} />
            <h2><span className="headingNumber">1.4. </span><span className="head">THIRTY-FIRST DAY</span></h2>
            <div className="dateline">Thursday, <span className="date">March 21</span></div>
            <p>The Convention met pursuant to adjournment and was called to order by the President. Prayer by Rev. Mr. Seeley.</p>
            <div className="section" id="vsc1965.v2.2.4.1">
              <h3><span className="headingNumber">1.4.1. </span><span className="head">FEDERAL RELATIONS</span></h3>
              <div className="speaker" id="sp897"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">
                I desire to offer the following resolution that it may be laid upon the table and printed. Its object is to direct the Committee on Federal Relations to inquire into the expediency of appointing a Commissioner to the Confederated States, and the border State Convention, if such Convention should be called, and also to appoint a Commissioner to each non-slaveholding State, and invite
                their participation and concurrence in some adjustment of the difficulties now impending over the country.
              </p>
              <p>The resolution was then read by the Secretary as follows:</p>
              <p>
                Resolved, That the Committee on Federal Relations be instructed to inquire into the expediency of providing for a Border State Conference as recommended by the report of the majority of that Committee, and a Conference with the authorities of the Confederated States as recommended by one of the minority reports, and a commission to each of the non-slaveholding States, and invite the
                authorities thereof to initiate such proceedings as will be acceptable to the slaveholding States now in the Union, and to the Confederated States.
              </p>
              <p>The resolution was laid on the table and ordered to be printed.</p>
              <div className="speaker" id="sp898"><span className="persName">Mr. BOYD</span>—</div>
              <p className="p-in-sp">I desire to offer the following substitute for the first section of the amendments to the Constitution reported by the Committee on Federal Relations, which I ask to be printed and referred to the Committee of the Whole.</p>
              <p>
                "In all the present territory of the United States, North of the parallel of thirty-six degrees and thirty minutes of North latitude, involuntary servitude, except in punishment of crime, is prohibited. In all the present territory of the United States South of said line of latitude, involuntary servitude, or slavery of the African race, is hereby recognized as existing, any law or
                usage to the contrary notwithstanding; and no law shall be passed by Congress or by the Territorial Legislature to hinder or prevent the taking of persons held in slavery or involuntary servitude from any of the States of this Union to said territory, nor to impair the rights arising from said relation; but the
              </p>
              <PageNumber num={140} />
              <p>
                same shall be subject to judicial cognizance in the Federal Courts, according to the remedies and practice of the common law, and said relation shall be protected by all the departments of the territorial government. When any territory North or South of said line, within such boundary as Congress may prescribe, shall contain a population equal to that required for a member of
                Congress, it shall, if its form of government be republican, be admitted into the Union on an equal footing with the original States, with or without involuntary servitude, as such Constitution of the State may provide. In all territory which may hereafter be acquired by the United States, involuntary servitude is prohibited, except for crime, North of the latitude of thirty-six
                degrees thirty minutes; but shall not be prohibited by Congress or any Territorial Legislature South of said line."
              </p>
              <p>The substitute was ordered to be printed and referred to the Committee of the Whole.</p>
              <div className="speaker" id="sp899"><span className="persName">Mr. MARYE</span>—</div>
              <p className="p-in-sp">I desire to offer the following resolutions which passed at a meeting of some 75 or 100 of my constituents, in the county of Spotsylvania, and which have been sent to me with the request that they shall be read and referred to the Committee on Federal Relations.</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>PUBLIC MEETING IN SPOTSYLVANIA</p>
                  <p>At a large and respectable meeting of the South-side citizens of Spotsylvania county, including the Mount Pleasant Rifle Corps, held at Partlow's, pursuant to notice, on Saturday, March 16, 1861.</p>
                  <p>On motion Dr. Nelson S. Waller was called to the Chair, and E. M. Crutchfield appointed Secretary.</p>
                  <p>On motion, the Chair appointed Col. John M. Waller, Thos. D. Smith, Esq., Dr. F. J. Herncock, Wm. P. Richardson, Esq., and Captain S. B. Goodloe, a committee to draft resolutions expressive of the sense of the meeting.</p>
                  <p>On motion, the Chairman was added to the committee, who, after a short absence, reported the following preamble and resolutions, which were adopted with only one dissenting voice:</p>
                  <p>
                    Whereas, Abraham Lincoln, in his Inaugural Address has denied that the slaveholding States have any national cause of dissatisfaction with the North or Black Republican party; has announced that a majority, though it overrides the Courts and Constitution, is entitled to rule; and has declared his decided purpose to coerce the seceding States of the South into subjection;
                    therefore, be it resolved,
                  </p>
                  <p>North gate of the capitol, and the eyes swimming in death, yet look</p>
                  <p>1st. That we can but consider such a policy as eminently endangering <PageNumber num={141} /> our liberties, and an open declaration of war against our sister States bordering on the Gulf.</p>
                  <p>2nd. That every tie of interest and affection calls on us to unite our destiny with our brethren of the South, and make common cause with them in a struggle for the independence, equality and liberty bequeathed us by our revolutionary ancestors.</p>
                  <p>3d. That the remedies proposed by the Peace Conference as an adjustment of our national difficulties are insufficient and unsatisfactory; and that Virginia ought not to accede to any plan of settlement that would not be acceptable to the seceding States.</p>
                  <p>4th. That the cry of "Wait," with the hope that something may turn up, so often uttered by some members of the Virginia Convention has been, and still is, detrimental to Virginia's interest, as well as the seceding States.</p>
                  <p>5th. That it is the opinion of this meeting that no good can result from a border State Convention, and that, instead of delaying, Virginia ought, by immediate ordinance of secession, sever her connection with the Federal Union, and form an alliance with the Southern Confederacy.</p>
                  <p>6th. That our representative in the Convention be furnished a copy of these resolutions, with the request to present them before that body. On motion,</p>
                  <p>Resolved, That this meeting approve the course of their Senators in Congress, State Senator, and county representatives in the Legislature.</p>
                  <p>On motion,</p>
                  <p>Resolved, That a copy of the proceedings of this meeting be furnished the Richmond and Fredericksburg papers, with a request to publish the same.</p>
                  <p>After addresses from several gentlemen,</p>
                  <p>On motion, the meeting adjourned.</p>
                  <p>NELSON S. WALLER, Chairman.</p>
                  <p>E. M. CRUTCHFIELD, Sec'y.</p>
                </div>
              </div>
              <p>TAXATION</p>
              <div className="speaker" id="sp900"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The pending question is the motion of the gentleman from Mecklenburg [<span className="persName">Mr. GOODE</span>] , to lay upon the table the resolutions offered by the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , upon which the yeas and nays have been ordered.</p>
              <div className="speaker" id="sp901"><span className="persName">Mr. SLAUGHTER</span>—</div>
              <p className="p-in-sp">I hope the gentleman from Mecklenburg will <PageNumber num={142} /> withdraw his motion, in order to enable me to make an appeal to Western members to postpone the question until fall.</p>
              <div className="speaker" id="sp902"><span className="persName">Mr. GOODE</span>—</div>
              <p className="p-in-sp">I will cheerfully yield to the request of the gentleman from Lynchburg [<span className="persName">Mr. SLAUGHTER</span>] , to withdraw the motion for a moment to give him an opportunity to address this body. I desire to say a single word in reference to the motives which have prompted me to make the motion to lay on the table the resolutions of the gentleman from Monongalia [<span className="persName">WILLEY</span>] . In making that motion, sir, I designed no discourtesy to the mover or to gentlemen from Western Virginia; nor do I desire to cut off these gentlemen from a full and thorough discussion of this question.</p>
              <p className="p-in-sp">
                I regard it, however, as a most unfortunate time for the introduction of such a question into this body. We were assembled here at a time of great public exigency, when Governments were dissolving around us. We were commissioned here to take counsel together for the interest and the honor of the Commonwealth of Virginia. Sir, standing in the presence of a mighty foe, before we have
                accomplished one single thing looking to the accomplishment of the great object for which we are here assembled, a fire-brand is thrown into our midst, and a subject was introduced which is to give rise to internal strife—at a time, too, when we should stand here, side by side, shoulder to shoulder, as men and brethren to confront a common peril. I do hope that it will be the
                pleasure of the Convention, and I appeal to my brethren of the West, to forbear pressing this agitating question upon us at this time.
              </p>
              <div className="speaker" id="sp903"><span className="persName">Mr. SLAUGHTER</span>—</div>
              <p className="p-in-sp">
                My sole object in rising at this time is to make an appeal to the gentlemen from the West to postpone the discussion of this question until we meet in the Fall. It is perfectly obvious that we are to have an adjourned meeting of this body. At present we have matters of vast importance before us, and the agitation and discussion of this question is calculated to promote discord in our
                midst, and arraign<span className="note" id="Note19"><span className="noteLabel">1</span>This word should perhaps be "array."</span> Western Virginia and Eastern Virginia against each other. The very agitation of the question is calculated to produce excitement out of doors, which is not promotive of the object for which we are met together. If you have a test question upon the subject of
                raising a committee, and it is carried against the West, you will find Western members feeling aggrieved by the action of the majority of the members; if it is carried against the East, you will find them also excited and feeling aggrieved, and jealousies will exist in the minds of the Convention, one against another. Demagogues from both sections are seeking to array one section of
                the country <PageNumber num={143} /> against another. Many persons are seeking now to say that this Convention is wasting time in the discussion of matters which are not pertinent to the objects for which we were called together. Let us do nothing to add to this excitement; let us go on and consider only the question for which we were called together,
                and when we have discussed and settled that, then we can go on, and properly turn our attention to other matters which may be deemed proper for us to consider.
              </p>
              <p className="p-in-sp">
                I appeal to those gentlemen that they will not now introduce any discord by the agitation of this question. The Union is dismembered; it is bleeding at every pore; I call upon them to do that which will restore the Union, and when that is done we may call up this subject of taxation. I call upon them, as loyal Virginians, at a time like this, not to be disputing in regard to the
                burdens which we have to bear among ourselves; but let us all come up to our high duties and save Virginia and this institution to which we all profess to be so much attached; and when that is done we can talk about the subject of taxation. Let us not be engaged in a controversy among ourselves, a controversy between the East and the West; but let us stand up boldly as one man, and
                present a united front against our common aggressors. Let it not appear to the North, let it not go abroad, that Virginia is divided herself—that she would suffer her great united power to be taken away by any such paltry consideration when more important issues are involved. What would you think of a crew which, when a ship was in the midst of a tempest, tossed by the breakers, and
                the effort of every hand was necessary to save the ship, would be disputing among themselves in regard to the inequality of their labors. Let us save the ship, let us bring her safely to the shore, and then you can see how this inequality ought to be adjusted. I appeal to my friends of the West to withdraw this agitating subject from our midst. In the Fall they will stand a better
                chance to secure their purpose. Then they can strongly appeal to their friends of the East, and press their point, that that institution of slavery ought to be taxed as they propose. If, on the other hand, we succeed in obtaining a favorable adjustment of our more serious grievances, we will be in such good spirits, we will all be so over-joyed at the result, that I think they will
                have no cause to agitate that subject. At all events, let us postpone the matter; let us not be agitating that question at this time; but let us all unite now for the purpose for which we have convened. When this is done we can proceed to adjust other matters. I desire that the gentleman will withdraw his motion for the ayes and
                <PageNumber num={144} /> noes. This question of taxation can be taken up, if it is thought proper, after we have done with the subject of Federal Relations. We can then discuss that question in a spirit of calmness and patience, in a spirit which will enable us to do justice to the gentlemen on the other side, when something can be effected which will
                be agreeable to all parties. But this is not the time and this Convention is not in the proper mood to discuss it. I renew the motion that the resolution be laid on the table, with no view of making it a test question, but simply to pass the subject over until we have settled upon some plan of general adjustment.
              </p>
              <div className="speaker" id="sp904"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
              <p className="p-in-sp">The motion to lay the resolution on the table is withdrawn.</p>
              <div className="speaker" id="sp905"><span className="persName">Mr. BROWN</span>, of Preston—</div>
              <p className="p-in-sp">
                I never hear a proposition or an appeal, sir, designed to promote the interests of this Union without responding to it. I will ask of my friends if silence should be preserved for the present, they are willing to pass a resolution which I hold in my hand and which I will send to be read and laid on the table; and if so I presume my Western friends will agitate the question no more at
                the present.
              </p>
              <div className="speaker" id="sp906"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The resolution can be read to the Convention, but not to be acted upon.</p>
              <div className="speaker" id="sp907"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">I design it as a compromise, and I want it to be done by unanimous consent; and then we will go to work in earnest for the Union.</p>
              <p>The resolution was read as follows:</p>
              <p>Resolved, That the President appoint a committee of fifteen members whose duty it shall be to enquire if it be expedient to change or amend the organic law of the State, and if so in what particular or particulars, and report thereon to an adjourned meeting of this body, should the Convention adjourn to meet at some future day.</p>
              <div className="speaker" id="sp908"><span className="persName">Mr. SLAUGHTER</span>—</div>
              <p className="p-in-sp">That raises the whole question, sir.</p>
              <div className="speaker" id="sp909"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">There is no question before the Convention except that on the adoption of the resolutions presented by the gentleman from Monongalia.</p>
              <div className="speaker" id="sp910"><span className="persName">Mr. WILLEY</span>, of Monongalia—</div>
              <p className="p-in-sp">
                Mr. President, I assure you, sir, that I had no disposition, no intention, no expectation, .in offering the resolutions which I had the honor to present to the Convention a few days ago, of producing any strife. On the contrary, sir, my object was to produce peace and good will; my object was to let it go out to the constituents whom I represent, and to a considerable portion of this
                Commonwealth, that there was a disposition here and now to <PageNumber num={145} /> remove those abiding causes of dissension which are disturbing the deep sentiments of the hearts of a large portion of the Commonwealth. Sir, I deprecate all strife. I deplore a disposition to beget strife, especially at this hour; and to the sentiment of my friend from
                Lynchburg in that behalf, I respond most cordially. I have listened, with pain, Mr. President, to every manifestation of a desire to sow dissension in this body or this Commonwealth; to every threat and menace. I offer this measure as a measure of peace. I want no war, I want no strife, I want no dissension. So help me God, sir, rather than to stir up the spirit of a Brutus that
                would rid our country of agitation by striking down her oppressor, in this age of Christian civilization and refinement, I would rather be a Curtius, to throw myself into the chasm now opening between the North and the South, as a peace-offering to the gods of strife, if thereby goodwill and harmony and unity can be restored to our disturbed land. My object in introducing those
                resolutions was peace; and I verily believe that the principle involved in those resolutions, if adopted in the organic law, instead of producing dissension, would produce peace; instead of dividing, would consolidate the Commonwealth; instead of producing dissension, would beget a spirit of harmony all over this land.
              </p>
              <p className="p-in-sp">I, sir, do not present this as a measure of Section, and I deny the assertion that it is sectional in its character.</p>
              <p className="p-in-sp">
                Mr. President, I am not here, at this moment, for the purpose of discussing the merits of this measure, but merely for the purpose of explaining to gentlemen upon the other side, what is its character. I repeat, sir, that this is not a question of section. It is applicable to every portion of the Commonwealth; and if it is not settled before long, my opinion is that it will become a
                question between classes and not between sections. But, sir, in urging this measure I have no desire that it shall embarrass any other matter before the Convention. If we can have any assurance that this question can be better settled at an adjourned session of this body, within any reasonable time, I am the last person to stand in the way of such a course. All my people want is,
                that they may have the assurance of this Convention that this question shall be adjusted within some reasonable time. Give them that assurance, and if it is the opinion of this body that it can be better adjusted at an adjourned session, they will be content that it shall go over for the present.
              </p>
              <p className="p-in-sp">
                I believe the hour has arrived for going into Committee of the Whole, and I will not, therefore, detain the Convention longer at this time. Before the final question is taken upon the proposition, I would <PageNumber num={146} /> be obliged to the Convention if they would afford me an opportunity for a brief discussion of its merits. The time for
                closing the consideration of the morning business, however, having now expired, I will yield to the will of the majority.
              </p>
            </div>
            <div className="section" id="vsc1965.v2.2.4.2">
              <h3><span className="headingNumber">1.4.2. </span><span className="head">FEDERAL RELATIONS</span></h3>
              <p>The hour of 11 having arrived, the Convention resolved itself into Committee of the Whole [<span className="persName">Mr. SOUTHALL</span> in the Chair] .</p>
              <p>The CHAIRMAN stated the question before the Committee to be the report of the Committee on Federal Relations.</p>
              <p>
                <span className="persName">Mr. HOLCOMBE</span> resumed and concluded his remarks, which will be published entire hereafter.<span className="note" id="Note20"><span className="noteLabel">2</span>Mr. Holcombe's entire speech is given in the Proceedings of March 20. See note 1 on those Proceedings.</span>
              </p>
              <p>At the conclusion of <span className="persName">Mr. HOLCOMBE</span>'S speech, there was considerable applause, which was promptly checked by the Chair, who called upon the Sergeant-at-Arms to clear the galleries.</p>
              <div className="speaker" id="sp911"><span className="persName">Mr. AMBLER</span>, of Louisa—</div>
              <p className="p-in-sp">I hope the Chair will withdraw the order. It is an extreme measure.</p>
              <div className="speaker" id="sp912"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair indicated yesterday, and has repeated the warning some two or three times to-day, that if there was any further manifestation of disorder either in the gallery or the lobby—</p>
              <div className="speaker" id="sp913"><span className="persName">Mr. BALDWIN</span> of Staunton—</div>
              <p className="p-in-sp">If the Chair will allow me—</p>
              <div className="speaker" id="sp914"><span className="persName">CHAIRMAN</span>—</div>
              <p className="p-in-sp">
                The Chair will conclude what he has to say before recognizing any gentleman. There were demonstrations of disorder yesterday in the gallery and lobby, and the Chair then indicated his purpose to order both places to be cleared upon its renewal. The same manifestations have been repeated to-day and the same warning given; and, as these warnings have not been regarded, the Chair must
                issue a peremptory order to have the gallery cleared. The Chair is perfectly satisfied, at the same time, that some of the disorder is within the bar of the Hall. If it be among members, the Chair has no right, nor is it his disposition to rebuke them. They have adopted rules of order for their government, and it is for them to say whether or not they shall observe them ; but it is
                the duty of the Chair to see that no disorder shall come from outsiders.
              </p>
              <div className="speaker" id="sp915"><span className="persName">Mr. FLOURNOY</span>, of Halifax—</div>
              <p className="p-in-sp">I am satisfied, Mr. Chairman, from my observations—for, at the moment of the applause, my eyes were directed towards the gallery—that there was no applause in that part of the building, and that the disturbance was mainly upon the floor of this Hall. [Cries of "good," from the gallery.]
                I think that the warning of the Chairman was strictly and rigidly observed in the gallery and in the lobby, but was not observed, as I believe, upon the floor of this <PageNumber num={147} /> Hall. I think, sir, when the gallery has observed the warning of the Chair strictly and properly, it ought not to be made to suffer for disorder upon the floor.
              </p>
              <div className="speaker" id="sp916"><span className="persName">Mr. MORTON</span>, of Orange—</div>
              <p className="p-in-sp">
                Permit me to say, sir, that upon no occasion on which the public heart has been moved to the extent that it has been upon the present occasion, have I ever observed greater order than has been observed here. It is impossible to suppose that upon an occasion like this, under appeals of eloquence that all must feel, the public can be cold and indifferent. Whatever applause might have
                been manifested upon the floor, in the galleries or in the lobby, was entirely in response to noble sentiments, honorable to human nature; and, for one, I am willing to say, whilst I wish order could be preserved, I could not help sympathising and participating, under the emotions of my heart, with the sentiments which have been expressed. And if it be the pleasure of the Chair to
                execute this heartless order, for a mere harmless manifestation in response to a noble feeling of human nature, I am satisfied it will be done in opposition to the sentiment of the House.
              </p>
              <div className="speaker" id="sp917"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I rise to a point of order. The gentleman, in referring to the order issued by the Chair, has used the word "heartless."</p>
              <div className="speaker" id="sp918"><span className="persName">The CHAIRMAN</span> [interrupting]—</div>
              <p className="p-in-sp">The Chair is aware of that.</p>
              <div className="speaker" id="sp919"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">Permit me to say, Mr. Chairman, that in the warmth of debate it is impossible for one so little gifted in language as I am, to use precisely the terms that may be parliamentary. If there is anything that I have said which has been disrespectful to the Chairman, or disrespectful to any member of this body, I cheerfully retract it.</p>
              <p className="p-in-sp">I will conclude simply with a request that the execution of the order of the Chair will not be enforced, since the feelings which led to the applause, were sympathized with by members upon this floor, as has been truthfully stated.</p>
              <div className="speaker" id="sp920"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                I hope it will be the pleasure of the Chair not to enforce the order which it has made. I take it for granted that the order for clearing the galleries was not intended so much for punishment of past offences as a preventive for the future; and as it is my fortune to follow the distinguished and eloquent gentleman who has just finished his address, I think I can offer a guarantee to
                the House that there will be no manner of occasion for disorder.
              </p>
              <div className="stage it">[Loud laughter.]</div>
              <div className="speaker" id="sp921"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I am sure sir, that with the concurrence of the entire Convention, I may make an appeal to you to withdraw the order.</p>
              <div className="speaker" id="sp922"><span className="persName">The CHAIR</span>—</div>
              <p className="p-in-sp">
                Whilst the present incumbent of the chair occupies the <PageNumber num={148} /> position which he now fills, he will see that the order of decorum is observed, and as he feels that this Convention, as well as this committee, requires him to enforce order, he would have no discretion but to see that every rule is strictly carried out. Under existing
                circumstances, whilst candor requires the CHAIR to say that the disorder was as much upon the floor, and within the bar, as in the lobby or gallery, yet it is for the CHAIR principally to see that outsiders do not disturb the deliberations of the committee. But, as various members of the committee have appealed to withdraw the order to clear the galleries, and as no one seems
                inclined to dissent from these appeals, the CHAIR takes great pleasure in doing so under the hope that there will be again no necessity to repeat that order.
              </p>
              <div className="speaker" id="sp923"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I concur, Mr. Chairman, I assure you most heartily in the sympathy which has been expressed by the gentleman from Orange [<span className="persName">Mr. MORTON</span>] , for the sentiments of deep devotion to the Commonwealth of Virginia, and to everything that tends to promote her best interests, which have been so eloquently expressed by the distinguished gentleman from Albemarle [<span className="persName">Mr. HOLCOMBE</span>] .</p>
              <p className="p-in-sp">
                Sir, I participate, as I am sure this Committee does, most deeply and earnestly, in the feelings of responsibility which have been recognized by that gentleman as growing out of the importance, the difficulty and the delicacy of the high duties which we are here to discharge. Sir, this is emphatically a Virginia assembly. It is not an assemblage of Virginians in the ordinary
                administration of our State government, but it is a Convention called together to consider the fundamental rights of this Commonwealth, to make one of those recurrences to first principles which we have been told are of so much importance to the preservation of civil liberty. I regard it, sir, as a most august assemblage, and as one of its members, I join most earnestly in the prayer
                for "light" which was offered by the distinguished gentleman who has just preceded me
                [<span className="persName">Mr. HOLCOMBE</span>]
                . Sir, this is, as I have said, an assemblage peculiarly Virginian, and I claim for the county I have the honor to represent upon this floor, a right to be heard in any representative body of Virginians. I put forward no claim because of the historic associations to which the gentleman has referred, but I claim for the county of Augusta a position now as a living, acting community—I
                claim for her the proud position of being the Queen county of this great Commonwealth, in point of population, and of wealth; in point of varied production and pursuit, in every particular that goes to constitute a great people. She is represented in the great popular branch of the General Assembly by three delegates, and by an
                <PageNumber num={149} /> equal number on this floor speaking the voice of the largest constituent body in the State. In the Senate of Virginia she speaks by her own Senator, representing there a county which stands first, aye, sir, first, upon the list in point of wealth and contribution to the common government. Situated at the centre of the State,
                connected with both East and West by the great lines of travel and improvement, and with the great Valley, midway of which she stands, she is connected with each and all by every tie, social and industrial, that can bind communities together. She occupies a position in the great heart of the Old Dominion, and I claim that he who represents her people is entitled to speak for a
                representative county of this Commonwealth.
              </p>
              <p className="p-in-sp">Sir, in behalf of such a county as this, I desire to be heard; not merely for the reasons that I have stated, but because she is identified with every interest and every institution that is recognized as of value in Virginia; and in regard to them all occupies what I might call a great central, conservative position.</p>
              <p className="p-in-sp">
                If anywhere in Virginia there are extremes of opinion on the one hand or on the other, if there are extremes of prejudice in one quarter or another, Augusta county and her people know nothing of them. They stand central in point of geographical position; central in point of sympathy, interest and association; ready to engage in a conservative yet earnest consultation, about whatever
                concerns the rights, the interests, or the honor of the Commonwealth.
              </p>
              <p className="p-in-sp">
                Well, sir, what is the great cause that has assembled this august body here to-day? What is it that has already divided and distracted our people and that threatens to overthrow completely the great fabric of this government of ours? What is the moving cause so far as Virginia is concerned? Understand me, I wish to consider these questions from a Virginia standpoint. I wish to
                confine myself in their consideration to their effect upon Virginia's rights, Virginia's interests and Virginia's honor.
              </p>
              <p className="p-in-sp">
                I say, then, that viewed from that standpoint, there is but one single subject of complaint which Virginia has to make against the government under which we live; a complaint made by the whole South, and that is on the subject of African slavery. Why, sir, what is the position of Virginia on other subjects? What is the position of this Commonwealth in regard to all the great
                principles of government, in regard to all the great measures of administration which have been in progress since our government was first inaugurated? It is the pride and boast of our people that on all the great questions of administrative policy, on the great doctrines of constitutional construction, Virginia <PageNumber num={150} /> has from the
                beginning given tone and direction to the opinion and the policy of this nation; that from its earliest foundation, with very rare and brief exceptions, the administration of this great government of ours has been, if not in the hands of Virginia's own sons, in the hands of men of Virginia's own selection whose principles, whose opinions, and whose policy have been time and again
                approved and ratified by the people of Virginia. Not only in the Executive department has this been true, but over and over again, year after year, we have been informed that the legislative policy has been shaped by the principles which have been taught by the sages of Virginia, and that judicial department of the Government has had its principles impressed upon it by Virginia; and
                thus, as to all the departments of this Government, in all its administration—in all its dealings with the great affairs of State, we have Virginia standing this day estopped from denying that the Government has been administered to her satisfaction.
              </p>
              <p className="p-in-sp">
                But, sir, the great cause of complaint now is the slavery agitation, and the questions growing out of it. If there is any other cause of complaint which has been influential in any quarter, to bring about the crisis which is now upon us; if any State or any people have made the troubles growing out of this question, a pretext for agitation instead of a cause of honest complaint,
                Virginia can have no sympathy whatever, in any such feeling, in any such policy, in any such attempt. It is the slavery question. Is it not so? We have heard, for days past, the great issues before the country discussed by able and eloquent gentlemen upon this floor—and I ask this Committee if any man has yet been heard, in all the recounted injuries that we are alleged to have
                suffered from the administration of the Government—if he has heard, in the long list of apprehended wrongs in the future, the slighest reference to anything else, to any other ground of complaint, except this subject of slavery? Thus we have it confessed, upon all hands, that in the main—with this one exception—this great government of ours, from its origin down to the present time,
                in its administration, in all the departments that concern the industry, the energy, the business of thirty millions of people, has been conducted upon the principles and in a manner satisfactory to the people of Virginia.
                [Here <span className="persName">Mr. HOLCOMBE</span> nodded assent.] Put down that admission before we proceed to consider the grievances alleged to grow out of the slavery agitation.</p>
              <p className="p-in-sp">
                Sir, when I come to consider this question of slavery and the agitations growing out of it, I cannot ignore the fact that here in Virginia <PageNumber num={151} /> there seems to be an impression entertained in some quarters, and industriously disseminated, that upon this great question Virginia is divided—that some of the people of Virginia, in some
                portions of the State, and some of their representatives upon this floor in this great Convention of her sovereignty, are, for some reason or other, not to be trusted upon this great paramount interest of Virginia. Sir, this idea is not new to me, or to the members of this body. I have heard it, with pain and sorrow, for years; not coming from abroad, not alleged against us by our
                enemies. No, it has come up from the midst of our own State family. It has come from Virginia's own sons—the insinuation, the suspicion, the charge, that the people of particular localities —that the people of particular political associations in Virginia, are not to be trusted to deal with this great leading, and most important institution of the State.
              </p>
              <p className="p-in-sp">
                Sir, for years past I have seen this thing industriously resorted to for the baser ends of party, and not of one party alone; I have seen it in turns resorted to for the baser ends of all parties in this Commonwealth. What has been the effect of this sort of thing upon the standing and influence of Virginia, in the councils of the nation, and before the nation itself? It has had the
                effect of leading on fanaticism against us by representing that we are a divided people. It has had the effect of increasing, in boldness, the attacks made upon us by the fanatical enemies of our institutions, under the impression, disseminated by our own citizens, that the people of Virginia were not true to Virginia; but that we were a divided and distracted people in regard to our
                own highest interests. Sir, this, as I say, has been resorted to in times past for the baser ends of party. This insinuation seems to be now resorted to as part of a species of terrorism, that is to be brought to bear upon the people and the people's representatives in Virginia, in the vain hope that it will repress, if not freedom of thought, at least, freedom of speech. Sir, this
                terrorism, thus attempted will be hideous, if it were not that it is exceedingly ridiculous and contemptible. I hope, when I proceed to define my position in regard to this great interest, that I shall not in any quarter be deemed to defer, in any respect, to a clamor, which I despise.
              </p>
              <p className="p-in-sp">As my opinions upon this subject, Mr. Chairman, may influence the course which I intended to pursue in this Convention. I deem it to be due to that candor of debate which, I trust, will characterize us all in this deliberative assembly, to declare from what stand-point in regard to this great institution I look upon this question of Union.</p>
              <p className="p-in-sp">
                Sir, in regard to the question of slavery, I hold views which were <PageNumber num={152} /> regarded at one time as peculiar, but I am gratified to know that they are becoming, day by day, the opinions of all kinds and conditions of men in this Commonwealth. I have always held the same opinions, from my earliest recollection, on this subject; I have had
                to undergo no change, such as was described in his own case, by the gentleman from Orange
                [<span className="persName">Mr. MORTON</span>]
                , and it may be owing to the fact that I have none of the fierce zeal of the new convert, that I am unable to follow him in his violent measures for summary redress. I have always entertained the opinion that African slavery, as it exists in Virginia, is a right and a good thing—on every ground, moral, social, religious, political and economical—a blessing alike to the master and the
                slave—a blessing to the non-slaveholder and the slaveholder. I entertain these views and opinions now. Sir, I am not one of those who look forward with expectation or desire, to its extinction at any time or in any place. I am not one of those who look with any sort of sympathy upon attempts to restrict it to any particular locality, but, so far as I am concerned, if it can be done
                by fair, legitimate and honest expansion and extension, I have no objection that this mild, beneficent and patriarchal institution may cover the whole earth as the waters cover the great deep.
              </p>
              <p className="p-in-sp">These are my own opinions upon this subject—opinions, not now expressed for the first time, but entertained and expressed all my life, everywhere, and perfectly understood by the constituent body that sent me here. My people who have sent me here with these avowals upon my lips might be regarded, I suppose as sound even a little farther South.</p>
              <p className="p-in-sp">
                Sir, I wish, in the consideration of all these questions, that it shall be remembered, however my views of what concerns the interest and the honor of Virginia, may differ from the views of other gentlemen in other parts of the State, that I take them from the stand-point of a pro-slavery man, representing a slaveholding constituency; aye, sir, representing a constituency who have in
                men and in money, more resources than any other people in this Old Dominion, and who hold all their resources at the call of the Commonwealth, to protect and defend this great interest as earnestly and devotedly as any other interest whatever.
              </p>
              <p className="p-in-sp">
                Let us come then to the consideration of the grievances of Virginia growing out of the institution of slavery. Having by consent narrowed the inquiry down to the question of slavery, let us now treat it more closely and more exactly. I undertake then to say, that upon the slavery question the mouth of Virginia is stopped as thoroughly
                <PageNumber num={153} /> and as completely to charge misconduct on the part of the Federal Government, as upon any of the other great subjects of administration in this country. Aye, sir, I go further, and undertake to say that Virginia is estopped more completely, more thoroughly to charge anything against the Federal Government in relation to slavery,
                than she is upon any other subject; because if there is any matter in the whole round of administration in regard to which it has been the pride and boast of the politicians and statesmen of Virginia to claim the ascendancy, aye, the undivided ascendancy of Virginia ideas and Virginia principles in the administration of the General Government, it is this question of African slavery
                and its accompaniments.
              </p>
              <p className="p-in-sp">
                Now I challenge the entire body of the Committee to put a finger upon a single act of the General Government of the United States on this subject in any one of its departments, from the foundation of the government down to the election of Abraham Lincoln, that did not either at the time or afterwards, receive the sanction and approval of Virginia. I know, sir, that this may take some
                gentlemen a little by surprise, this broad sweeping announcement; but it is true, and cannot be controverted. It is an assertion made in full view of the history of all the administration upon this subject. And I challenge a contradiction in regard to any one single measure of the General Government, from its foundation down to the late Presidential election. Mark me. I do not say
                that Virginia approved each one at the time it was done, but I do say that if in any case she failed to do it then, she gave it her approval afterward, distinctly, fully, unequivocally. Aye, sir, if there has been an irrepressible conflict waging in this government, or among the people on the subject of pro-slavery, Virginia is bound to admit, Virginia is bound to declare, that in
                that irrepressible conflict, the government in all its departments—the General Federal Government of the United States—has been on her side; and that in that conflict, call it irrepressible or what you will, the people of the United States, in large majorities, have always stood on the side of Virginia. I challenge contradiction upon this point; and I would suggest, as to the future
                progress of debate here, that if we are ever to be indulged in anything like an exact discussion of the merits of the crisis in which we are engaged, it is time for gentlemen who take up the language of lamentation for oppressed people, gentlemen who speak of the past oppression of Virginia and the South, to do something more than indulge in generalities upon the subject; and point
                out by specification when, where, how, and by whom the rights, <PageNumber num={154} /> interests and honor of the people of the South have been assailed so far as the General Government is concerned.
              </p>
              <p className="p-in-sp">
                Sir, it is true of the slavery question, as of every other question of administration in this government of ours, whatever some of us at home here may think of the policy which has been adopted and directed by Virginia; yet it cannot be controverted that she has been upon all subjects of administration, including this great question of slavery, the directing spirit of this
                government, from its foundation down to the election of Abraham Lincoln. Sir, we may not have agreed with her in all—I have not, I confess, agreed with her in all the policy that she has impressed upon the nation ; but, as a Virginian, I cannot help feeling an honest pride when I think that such has been her influence in the councils of the nation, such the respect and consideration
                which she has commanded among her sister States and their people, that, even when I thought she was wrong, she has commanded, and directed and shaped the policy of the nation. Sir, who has ever had a better right than Virginia to the celebrated exclamation "L'etat, c'est moi"?
              </p>
              <p className="p-in-sp">
                I know, sir, that much is said of late about the degradation of Virginia. Ah, the degradation of Virginia! Who calls Virginia degraded? Who dares to call Virginia degraded in any presence? Alas! alas! it is reserved for her own sons, in this her council of State sovereignty, where all her mighty interests and her great destiny are at stake, it is reserved for her own sons to point at
                her the finger of scorn and to dare assert the degradation of Virginia. Is she degraded? Does this sentiment find an answering echo in the hearts of Virginians here? Is Virginia degraded? No, sir! no, sir! I answer never! As one of her humblest sons I fling back the imputation with scorn, contempt and defiance! Virginia degraded? Why, look to her past. It has been pointed out to you
                in words of glowing eloquence by the distinguished gentleman.
              </p>
              <div className="speaker" id="sp924"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">If the gentleman refers to me I would say that I did not speak of the degradation of Virginia in the past.</p>
              <div className="speaker" id="sp925"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I refer, sir, not especially to you in that particular, but with others who have spoken.</p>
              <div className="speaker" id="sp926"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I understand you to make a declaration that some imputation has been made of degradation upon Virginia, which you repel with scorn and contempt?</p>
              <div className="speaker" id="sp927"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I certainly understood you to say so; your remarks gave me that impression.</p>
              <div className="speaker" id="sp928"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I say, sir, that if Virginia should persist in the <PageNumber num={155} /> policy proposed, bearing in mind the declarations upon which I base my remarks in reference to the sentiments and the feeling with which I would treat every gentleman.</p>
              <div className="speaker" id="sp929"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I understand you now to have spoken about the future.</p>
              <div className="speaker" id="sp930"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I have spoken of it in the future. Do you refer to those remarks?</p>
              <div className="speaker" id="sp931"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                No, sir. You have a right, of course, to picture the future. But I repel the imputation that she has been degraded; I repel that everywhere. Gentlemen have a right, unquestionably, to argue the effect of any policy upon Virginia in the future; no one can gainsay that; but the complaint I am uttering, the protest I am making, is against any inference that Virginia has been degraded by
                anything in her history, that she is degraded by anything in her present.
              </p>
              <div className="speaker" id="sp932"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">The gentleman will allow me another single remark? He will bear in mind that I deplore, without reference to the past, the condition of Virginia. I spoke in no tone of reproach; it was in reference to the possible future that I spoke; and all that I then said with all conceivable emphasis, I now renew.</p>
              <div className="speaker" id="sp933"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">Certainly, sir. I accept what the gentleman says as the just and fair construction of his remarks. I have no desire whatever to place any imputation of that sort upon any man. Certainly I did not understand the position of the gentleman as he now states it. But I cordially yield his perfect right to construe his remarks.</p>
              <div className="speaker" id="sp934"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">It was my declaration, and not my construction.</p>
              <div className="speaker" id="sp935"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">Certainly, sir, I admit and yield the point with perfect frankness.</p>
              <p className="p-in-sp">But, sir, it is nevertheless true, not in regard to that gentleman [<span className="persName">Mr. HOLCOMBE</span>] but in regard to many persons. Everywhere you go you hear talk about the degradation of Virginia. You hear it in this hall; you hear it everywhere. Sir, look at her past, as it has been eloquently and justly depicted by the distinguished gentleman from Albemarle [<span className="persName">Mr. HOLCOMBE</span>]
                . It is needless to recount it. It is the history of mankind. It is the history of free principles and free Government all over the world. I ask you not to delay with me in going over the great and mighty history of Virginia's past. I ask you not to go with me to the Revolutionary period in which her contributions to the common cause have been so well compared by the gentleman to
                those of Athens for a common Greece. I ask you not to dwell upon, but simply to glance at the history of her contributions to the formation of our Constitution. I ask you to look, as I have looked in <PageNumber num={156} /> passing, at her influence in shaping, directing and controlling the policy and administration of the Government, and tell me, is
                Virginia degraded?
              </p>
              <p className="p-in-sp">
                Sir, her past has been glorious; her past has been one march of glory. But it was reserved for her present to crown her with immortal honor. Sir, look at her now! Does any man doubt for a moment that the course which Virginia has pursued and is pursuing in regard to the great questions which now agitate and threaten to overthrow this great Government of ours, has placed her in the
                position this day that beyond all dispute, beyond all denial she holds the destiny of this Republic in her own hands? Can any man deny that that is her present position? That this day the peace of this nation depends entirely upon the judgment which Virginia shall pronounce? Does any man question that? Sir, look at this assembly, this constituent assembly of the people of Virginia
                this day justly attracting the attention, and the interest, and the prayers of the friends of free government over the world. Look at this assembly this day, having committed to it more of all that concerns the weal or woe of man, more of all that concerns the high destiny of a free people, more that concerns the great interests of civil and religious liberty throughout the world,
                and for all time, than was ever before committed to the keeping of any one assembly of mortal men—look at this assembly, and tell me if the position of Virginia to-day does not crown her with immortal and undying honor? Friends, shall we meet this high occasion? Shall we rise to the high duties which this position devolves upon us, or shall we shrink back incompetent to deal with the
                great issues that are now committed to our hands? Friends, let us, in this great council of Virginia, to which is entrusted so much that concerns the welfare of mankind—let us join hands with one another here and swear, before high Heaven, that as the Ark of the Covenant of Constitutional Liberty has been committed to us we will be true to the high trust and will, with God's help,
                lift up our bleeding country from the dust and set her free.
              </p>
              <p>While <span className="persName">Mr. BALDWIN</span> was speaking,</p>
              <div className="speaker" id="sp936"><span className="persName">Mr. RANDOLPH</span> said—</div>
              <p className="p-in-sp">It is apparent, sir, that it would be more agreeable to the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] to suspend his remarks; and I move that the committee rise.</p>
              <div className="speaker" id="sp937"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">Before that motion is taken, as the hour for adjournment has not arrived, and as I propose to occupy a few moments of the time of the Committee, if the gentleman will withdraw the motion, the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] can suspend his <PageNumber num={157} /> remarks, and when I have addressed the committee I will renew the motion then. Of course I am aware that the gentleman from Augusta retains the floor for to-morrow.</p>
              <p>The motion that the committee rise was withdrawn.</p>
              <div className="speaker" id="sp938"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I propose to the consideration of the committee an amendment to the report under consideration, and after it is read, I will, with the indulgence of the committee, detain them for a short time with a few remarks assigning my reasons for proposing that amendment.</p>
              <p><span className="persName">Mr. CARLILE</span>'S amendment was read then as follows:</p>
              <p>
                Whereas, the Peace Conference which was called by the Legislature of this State, and in which twenty-one States (fourteen of them nonslaveholding) of this Union, were represented, after much anxious deliberation and careful investigation, has recommended for the adjustment of our present national difficulties the adoption, by the people of the several States in the manner provided
                for amendments to the Constitution of the United States by the fifth article thereof, the following propositions :
              </p>
            </div>
            <div className="section" id="vsc1965.v2.2.4.3">
              <h3><span className="headingNumber">1.4.3. </span><span className="head">"ARTICLE XIII</span></h3>
              <p>
                "SECTION 1. In all the present territory of the United States north of the parallel of thirty-six degrees and thirty minutes of north latitude, involuntary servitude, expected in punishment of crime, is prohibited. In all the present territory south of that line, the status of persons held to service or labor, as it now exists, shall not be changed; nor shall any law be passed by
                Congress or the Territorial Legislature to hinder or prevent the taking of such persons from any of the States of this Union to said territory, nor to impair the rights arising from said relation; but the same shall be subject to judicial cognizance in the federal courts, according to the course of the common law. When any territory north or south of said line, within such boundary
                as Congress may prescribe, shall contain a population equal to that required for a member of Congress, it shall, if its form of government be republican, be admitted into the Union on an equal footing with the original States, with or without involuntary servitude, as such constitution of the State may provide.
              </p>
              <p>"SECTION 2. No territory shall be acquired by the United States, except by discovery and for naval and commercial stations, depots and transit routes, without the concurrence of a majority of all the Senators from States which allow involuntary servitude, and a majority of all the Senators from the States which prohibit that relation; nor</p>
              <PageNumber num={158} />
              <p>shall territory be acquired by treaty, unless the votes of a majority of the Senators from each class of States herein before mentioned be cast as a part of the two-thirds majority necessary to the ratification of such treaty.</p>
              <p>
                "SECTION 3. Neither the Constitution, nor any amendment thereof, shall be construed to give Congress power to regulate, abolish or control, within any State of the United States, the relation established or recognized by the laws thereof touching persons held to labor or involuntary service therein, nor to interfere with or abolish involuntary service in the District of Columbia
                without the consent of Maryland and without the consent of the owners, or making the owners who do not consent just compensation; nor the power to interfere with or prohibit representatives and others from bringing with them to the District of Columbia, retaining and taking away, persons so held to labor, or service, nor the power to interfere with or abolish involuntary service in
                places under the exclusive jurisdiction of the United States within those States and Territories where the same is established or recognized ; nor the power to prohibit the removal or transportation of persons held to labor, or involuntary service in any State or Territory of the United States to any other State or Territory thereof where it is established or recognized by law or
                usage; and the right during transportation, by sea or river, of touching at ports, shores and landings, and of landing in case of distress, shall exist, but not the right of transit in or through any State or Territory, or of sale or traffic against the laws thereof. Nor shall Congress have power to authorize any higher rate of taxation on persons held to labor or service than on
                land.
              </p>
              <p>"The bringing into the District of Columbia persons held to labor or service for sale, or placing them in depots to be afterwards transferred to other places for sale as merchandise, is prohibited.</p>
              <p>"SECTION 4. The third paragraph of the second section of the fourth article of the Constitution shall not be construed to prevent any of the States, by appropriate legislation, and through the action of their judicial and ministerial officers, from enforcing the delivery of fugitives from labor to the person to whom such service or labor is due.</p>
              <p>"SECTION 5. The foreign slave trade is hereby forever prohibited; and it shall be the duty of Congress to pass laws to prevent the importation of slaves, coolies, or persons held to service or labor in the United States and the Territories, from places beyond the limits thereof.</p>
              <p>"SECTION 6. The first, third and fifth sections, together with this</p>
              <PageNumber num={159} />
              <p>section of these amendments, and third paragraph of the second section of the first article of the Constitution, and the third paragraph of the second section of the fourth article thereof, shall not be amended or abolished without the consent of all the States.</p>
              <p>
                "SECTION 7. Congress shall provide by law that the United States shall pay to the owner the full value of his fugitive from labor, in all cases where the marshal, or other officer, whose duty it was to arrest such fugitive, was prevented from so doing by violence or intimidation from mobs or riotous assemblages, or when, after arrest, such fugitive was rescued by like violence or
                intimidation, and the owner thereby deprived of the same; and Congress shall provide by law for securing to the citizens of each State the privileges and immunities of the several States."<span className="note" id="Note21"
                ><span className="noteLabel">3</span>In the Proceedings of March 25, page 299, Mr. Carlile asked to have the words "citizens of" inserted before "the several states."</span
                >
              </p>
              <p>Therefore, be it resolved, by the representatives of the people of Virginia, in Convention assembled,</p>
              <p>
                1. That it be, and is hereby recommended to the people of the several States comprising the United States, to hold in their respective States, Conventions to consider the said measures of adjustment and express their approval of the same, and request their Senators and Representatives in Congress assembled, either in extra or regular session, at its first meeting, to adopt the same
                by the constitutional majority of two thirds of each house, so that the same may be laid before the several States of this Union, in the mode pointed out by the said fifth article of the Constitution aforesaid, for ratification or rejection.
              </p>
              <p>
                2. That this Convention, for and in the name of the good people of this Commonwealth, do declare their approval of the said propositions, and will, if adopted as an amendment to the Constitution of the United States, accept the same as an adjustment of all our national difficulties, and that we do hereby request our Senators and Representatives in Congress, at its next session,
                whether convened in extra or regular session, to use their best efforts to have the same adopted in their respective houses by the constitutional vote required, to the end that the same may be laid before the people of the several States of the Union, to be, by them, ratified or rejected in the manner provided in the Constitution, through the action of State Conventions or the
                Legislatures of the several States.
              </p>
              <p>3. That it shall be the duty of the officers conducting the election for members of the next General Assembly of this State, to see that a poll is opened at the several places of voting in this Commonwealth, to take the sense of the voters of this State upon the said measures of adjustment recommended by the Peace Conference. And the better to secure</p>
              <PageNumber num={160} />
              <p>
                a correct poll-book, the Governor of this Commonwealth is requested to have prepared and transmitted to every county in this Commonwealth a sufficient number of poll-books to supply each county with as many books as there are places of voting in the same, said books to be headed "Poll book for taking the sense of the voters upon the adoption of the measures of adjustment recommended
                by the Peace Conference," and to have two columns, the one headed, "For the adjustment," the other headed "Against the adjustment." And it shall be the duty of the said conducting officers, each and all of them, to see that the names of all persons qualified to vote for members of the General Assembly, and who present themselves to vote at the respective places of voting, are allowed
                to vote for or against the said measures of adjustment, and that the names of all those who vote for adjustment are recorded in the column headed "for the adjustment," and all those who vote "against adjustment" are recorded in the column head "against the adjustment," said poll books to be returned and their correctness certified to under oath by the officers conducting the election
                within two days after the said election to the clerk of the respective county or corporation court, as the case may be, in which the said conducting officers reside; and it shall be the duty of the respective county and corporation clerks of this Commonwealth to certify to the Governor of this Commonwealth, attested by their seal of office, the result of the vote upon the question
                aforesaid, in their respective counties and corporations aforesaid, within two days after the said poll books shall be returned by the conducting officers aforesaid; and the Governor of this Commonwealth, upon receipt thereof, is hereby requested to ascertain the result of the said vote in the State, and to make the same known by proclamation; and if a majority of the votes cast
                shall be in favor of the said Peace Conference adjustment; he is hereby requested to communicate to the Governor of each of the States of this Union the foregoing resolutions, with the result of the vote aforesaid, with a request to each to take such steps as the Constitution and laws of his State may require to be taken, in order that a Convention of delegates may be elected by the
                voters in each State, and convened, to whom shall be referred the foregoing resolutions.
              </p>
              <div className="speaker" id="sp939"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The proposition of the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>]
                , is an amendment, by way of substitute, to strike out the report of the Committee on Federal Relations and insert what has just been read as a substitute therefor. The Chair would avail himself of the present occasion to repeat, to-day, the opinion he indicated the other <PageNumber num={161} /> day, as to the course of action upon the part of the
                Committee of the Whole touching the report of the Committee on Federal Relations now pending before it. He is induced to do so, because of the report of what the Chair may have said on a former occasion, as seen in the public prints, in which his opinions and views did not seem to have been properly published, if they were properly understood by the members present.<span
                  className="note"
                  id="Note22"
                ><span className="noteLabel">4</span>The Chair referred to his remarks in the Proceedings of March 15, pages 700-701. As there seems to be no substantial difference between the two statements, the Chair may have been finding fault with a report in some other paper than the Enquirer.</span
                >
              </p>
              <p className="p-in-sp">The opinion which the Chair then expressed he now desires to repeat, so that no member may be taken by surprise, and that every member, especially the minority upon every question, may have their views properly and distinctly presented for consideration.</p>
              <p className="p-in-sp">
                As remarked the other day, the CHAIR, under the peculiar circumstances attending the various propositions which have been referred by the Convention to the Committee of the Whole, deems it proper, and but reasonable, to relax, to some extent, the strict parliamentary rule. That rule would require the Committee of the Whole to take the report of the Committee on Federal Relations,
                containing, as it does, distinct and independent propositions, and act upon those propositions seriatim, rejecting or adopting each as it arose; but after it should have been adopted or rejected, the judgment thus pronounced could not be re-considered or reversed by the Committee; and, therefore, those gentlemen who might desire to offer a substitute for the entire report, would be,
                to say the least, embarrassed in presenting their competing propositions, a part of the report of the Committee on Federal Relations, or the whole having been adopted.
              </p>
              <p className="p-in-sp">
                The CHAIR, therefore, in order to secure to any gentleman dissatisfied with the report of the Committee on Federal Relations, and who prefers some substitute for it, whether that substitute in the form of a minority report, as now before the committee, or any other proposition which may hereafter be offered by any member of the Convention, indicated that he would pursue this course,
                that he would receive a motion—which has now been made by the gentleman from Harrison—to strike out the entire report of the committee and to insert in lieu thereof a substitute for the whole. This, as the CHAIR supposes, would protect, as well as secure to any gentleman not concurring with the Committee on Federal Relations, the opportunity of bringing directly in competition any
                report or any substitute which he may desire to offer. But the substitute, when offered, as is now the case, as a substitute for the whole report of the Committee on Federal Relations, cannot of itself receive any amendment, by way of substitute, that is to strike out the pending amendment and to insert something as a substitute for the whole. It is
                <PageNumber num={162} /> open to amendment as is the report of the Committee on Federal Relations, both being now open for amendment as they stand before the committee. Any gentleman who may be dissatisfied with both the report of the committee on Federal Relations, as well as with the substitute offered by the gentleman from Harrison, but prefers some
                other substitute for both, can only suggest his intention to the committee to offer an amendment in the nature of a substitute, when the pending amendment shall have been disposed of, and state the character of his proposed amendment, and his purpose in proposing to offer it. The committee then being in possession of the contents and character of such second substitute, all those who
                would agree with the gentleman thus desiring to offer such substitute, would unite in voting down the substitute now pending, and in giving the opportunity of presenting the second one; and so on until a motion to strike out and insert some pending substitute, shall be decided in the affirmative.
              </p>
              <p className="p-in-sp">The CHAIR hopes that he is now understood. He will further remark that the various minority reports which have been referred to the Committee of the Whole, do not come up as a matter of course, but any gentleman desiring to have action upon any one of them, may move it as an amendment, by way of substitute.</p>
              <div className="speaker" id="sp940"></div>
              <p className="p-in-sp"><span className="persName">Mr. HARVIE</span>—I understand the Chair to decide that we are to take separate votes upon each branch of the majority report when we come to act upon it.</p>
              <div className="speaker" id="sp941"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">But not now.</p>
              <div className="speaker" id="sp942"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I mean to say when we come to act upon it finally.</p>
              <div className="speaker" id="sp943"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">
                When the Committee comes to act upon it finally a separate vote will be taken upon each individual proposition. The Chair should have added that if the report of the Committee on Federal Relations overcomes all opposition, and the substitutes are all voted down, it will then be open, as it is now open, to amendment, but the action on each resolution being a distinct proposition,
                adopting or rejecting it will be final.
              </p>
              <div className="speaker" id="sp944"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I wish to be informed whether after we have voted on the various propositions of the majority report the vote will be taken upon adopting it as a whole?</p>
              <div className="speaker" id="sp945"><span className="persName">THE CHAIRMAN</span>—</div>
              <p className="p-in-sp">It will not.</p>
              <div className="speaker" id="sp946"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">The vote upon the adoption of each separate proposition then is to be final?</p>
              <div className="speaker" id="sp947"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">
                After all the propositions in the nature of a substitute have been voted on, if they are all rejected, as the Chair has already stated, the propositions contained in the report of the Committee <PageNumber num={163} /> on Federal Relations will be taken up seriatim and disposed of, and there will be no subsequent vote taken upon them in committee as an
                entirety.
              </p>
              <div className="speaker" id="sp948"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">
                The reason of the enquiry I make is this: I understand that in the report of the Peace Conference, there was no vote taken upon the award of that Conference as a whole. After separate votes had been taken upon the separate parts of it, the aggregate of those parts was taken to be the sense of the Conference. Now, sir, it may turn out, and I think very probably will turn out, that,
                while each proposition taken separately, may get a majority of this Committee, the whole could not get a majority; that the opinions of gentlemen might be changed by adding on something to the segregate parts which had been acted upon. I wish, therefore, the Chair to consider whether he will entail upon this Committee as its judgment, by the adoption of the rule he has suggested,
                that which, if voted upon as a whole, would not be the judgment of the Committee.
              </p>
              <div className="speaker" id="sp949"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">
                The Chair will, as he has stated, only relax the strict parliamentary rule so far as to enable gentlemen who oppose the report of the Committee on Federal Relations to offer competing propositions. But he repeats that if that report overcomes the opposition of all competing propositions in Committee of the Whole, when it is finally acted upon, each proposition will be acted upon
                seriatim and if adopted, there will be no vote taken upon the report as an entirety.
              </p>
              <p className="p-in-sp">The gentleman from Amelia, will recollect that the gentleman from Charles City [<span className="persName">Mr. TYLER</span>] , in giving a history of the action of the Peace Conference stated that he made the same decision, and therefore one of the representatives from Virginia, who desired a vote upon the report as a whole, was denied the privilege of such a vote. The Chair thinks the gentleman from Charles City, then the presiding officer of that Peace Conference, decided correctly.</p>
              <div className="speaker" id="sp950"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I ask further for information, although I suppose there is no doubt about the fact, whether it will be competent for me, whenever the question arises, at any stage of the proceedings, to take an appeal from the decision of the Chair.</p>
              <div className="speaker" id="sp951"><span className="persName">The CHAIR</span>—</div>
              <p className="p-in-sp">Certainly.</p>
              <div className="speaker" id="sp952"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">It is not necessary then that I should controvert the position of the Chair at this time.</p>
              <div className="speaker" id="sp953"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair has only thrown out these suggestions in order that if the Committee acquiesce, the matter may be understood. The gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] , is entitled to the floor.</p>
              <PageNumber num={164} />
              <div className="speaker" id="sp954"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                In support of the resolutions which I have had the honor to submit to the Committee, and which I propose shall be adopted as a substitute for the report made to the Convention by the Committee on Federal Relations, now under consideration in this Committee, it is not my purpose to detain this body with any comments upon the several propositions, or upon the many abstractions
                contained in that report. It is sufficient for my present purpose to say, that the proposition for a border slave State Conference does not command the approval of my judgment.<span className="note" id="Note23"
                ><span className="noteLabel">5</span>In the Proceedings of March 22, page 160, Mr. Carlile complained that his remarks of the day before were badly reported in the Enquirer. He added that he had written out a correction, which would be published in the Whig for March 23. In the Richmond Daily Whig for March 23, Mr. Carlile published a version of his remarks which combined what he said
                  in this and subsequent paragraphs into an apparently continuous speech. The two versions show a considerable number of minor differences in wording and arrangement of sentences, but no substantial difference in meaning between the two versions can be discerned.</span
                >
              </p>
              <div className="speaker" id="sp955"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">I rise to a question of order. Without intending to do anything to interfere with gentlemen, I desire to know whether it is in order to have two discussions progressing at the same time upon the same subject? That is the effect, if I understand aright, of the mode of proceeding now being carried out.</p>
              <div className="speaker" id="sp956"><span className="persName">The CHAIR</span>—</div>
              <p className="p-in-sp">In what respect?</p>
              <div className="speaker" id="sp957"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">The gentleman from Augusta is entitled to the floor upon the discussion of the subject before the committee. He has yielded the floor to enable the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] to introduce a proposition bearing upon the same subject, which that gentleman now proposes to discuss. This, I take it, will interfere with the order of discussion as it now exists.</p>
              <div className="speaker" id="sp958"><span className="persName">The CHAIR</span>—</div>
              <p className="p-in-sp">It may interfere, for, in the opinion of the Chair, the floor cannot be transferred. The gentleman from Harrison having obtained the floor, it is competent for him to offer any proposition to the Committee he may think proper, in connection with the subject before it. The gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] yielded the floor with the understanding that he would have it to-morrow.</p>
              <div className="speaker" id="sp959"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">As I understand, the gentleman from Augusta will not be entitled to the floor to-morrow, unless he chances to catch the Chairman's eye.</p>
              <div className="speaker" id="sp960"><span className="persName">The CHAIR</span>—</div>
              <p className="p-in-sp">The Chair will consider the circumstances under which the gentleman from Augusta yielded the floor, and see that he does not lose the opportunity to proceed with his address.</p>
              <div className="speaker" id="sp961"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                It is a fact, sir, that is pressing upon the people not alone of this Commonwealth, but of the people of the whole Union, that the great business interests of the country cannot much longer bear the present agitation. And, sir, I believe it is a fact that this is the only agitating body now in session in the Union. The ship of State has been tossed for months past by a boisterous
                sea; the crew now want repose. We have enough of extra Constitutional assemblies. The border slave States have not asked us for a Conference, and it may be well inferred <PageNumber num={165} /> that they don't desire it. The effort with them has been to suppress agitation, and to save their people as much as possible from the ruins brought upon our
                common country by heartless agitation, reckless precipitators and disappointed hungry placeseekers—men who see their only hope in the destruction of the best government the sun ever shone upon—and who would march over their bleeding and prostrate country to gratify an ambition prompted by selfish considerations alone. What care these men for the ruin of the people whom they can no
                longer use for their base purposes? It has been well said, Mr. Chairman, that the love of power, with a desire to display it, when it can be done with impunity, is inherent in the human heart. Turn it out at the door, and it would come in again at the window. Add to this the fact that they are no longer in a position to deceive the people whom they had used while they could, and we
                may well imagine, that for such men death itself would be preferable to a return to the masses to earn, with them, by honest toil and industry, the means of support.
              </p>
              <p className="p-in-sp">
                With these facts before the people, they can have no difficulty in discovering now whence comes sensation articles, sensation appeals and those other various efforts to arouse their prejudices and produce revolution, bloodshed and civil war. Look into it, poor people of Virginia, and see who it is that will not let you rest; who is disturbing your peace; who were they that refused to
                yield to you an opportunity to give an expression to your will at the ballot box—who were they that hushed every demonstration in favor of the Union—that Union which our revolutionary fathers won for us by their valor and cemented with their blood? Who were they that worry you in the town meetings and cross road meetings that they may send forth the ravings of their madness and of
                their hatred to the government secured to us by Washington and his compatriots at a cost of a seven years war—bequeathed to us by the great statesmen who formed it as the richest inheritance ever bestowed upon man? There are many who spit upon and trample under foot the glorious flag, bearing upon its ample folds the stars and stripes—the emblems of our Union, of our greatness and
                our power.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, the border slave States with fourteen of the nonslaveholding States responded to the call of our Legislature for a Peace Conference. That Conference after weeks of laborious investigation and anxious deliberation agreed upon measures that ought to be accepted, and will be, I have no doubt, by the people, if you will but allow them an opportunity to give expression to
                their wishes, as a just and fair and honorable adjustment of all our national difficulties. If we desire peace and the restoration of fraternal feelings, we should <PageNumber num={166} /> accept the recommendations of that Conference. I feel confident, sir, and in this I am strengthened by a letter which was read before this body from one of the
                Commissioners of that "Peace Conference" who does not sympathise with me, and as I believe, with the great body of the people of this State. I allude, sir, to the one presented to this body from Judge Brockenbrough, in which he says:
              </p>
              <p className="p-in-sp">
                "It was supposed that, as a matter of course, the vote would be taken upon the scheme as an entirety, and, then announced to one of you that after the most anxious deliberation I had come to the conclusion that, distasteful as the scheme was to me, I felt it to be my duty to cast a representative vote and sustain the measure as a whole. I was convinced that Western Virginia, which I
                in part represented, would so have voted by an immense majority, if her voice could be heard within that hall; and, acting under that strong conviction, I would have done homage to that great principle of representative government which demands that the representative yield his individual sentiments and give utterance to those of his constituents. But no vote was taken on the plan as
                a whole, the Chair having ruled that each section being successively adopted, the entire plan was adopted, and no further vote was necessary or admissible under the parliamentary rule."
              </p>
              <p className="p-in-sp">
                Sir, the proposition which I have offered as a substitute provides for bringing before the people of this Commonwealth these propositions, and taking their sense upon them at the polls. And, sir, I have confidence that our constituents from the sea shore to the banks of the Ohio, from the Maryland and Pennsylvania line to the North Carolina and Tennessee border, will, if you only
                allow them an opportunity in a constitutional way to pass upon them, accept these measures as an adjustment of our national difficulties. If we desire the perpetuity of the Union, why not accept them? We should take that settlement which the public mind is prepared to accept, and to which it is, to a certain extent, pledged by the action of the "Peace Conference." Why refuse to the
                people the privilege of giving expression to their will upon these measures, at the polls? Their voice should be heard—their will, not ours, should be done. Why ask to substitute for them the Crittenden resolutions? These have been abandoned for the resolutions reported from your Committee on Federal Relations. The Crittenden resolutions were before that Conference, and, for one, I
                greatly preferred, as a citizen of a slaveholding State, the measures of adjustment as recommended by that Conference, to the resolutions of Mr. Crittenden; and I understand the old patriot has declared his preference for them over his own resolutions. We cannot shut our eyes to the fact that all the <PageNumber num={167} /> guns of the disunionists are
                leveled against the work of that Conference, and if they express a preference for the Crittenden, or any other plan of adjustment, it is for the purpose of concealing their real design, which is a determination to oppose any and every settlement that may be proposed; and it is because they fear that the measures of the "Peace Conference" will be accepted by the people that they
                oppose this measure with so much perseverance and zeal. Their opposition should be to us the highest evidence and the best reason why we should accept these measures. To all men who place party success and personal advancement above the interests of their country, of course the "Peace Conference" recommendations are distasteful. The effort with them is to destroy the Government that
                has, for the greater part of a century, protected us in the full, free and absolute enjoyment of all our rights, civil, political and religious, as no Government ever protected a people before.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, there is away down in the bottom of every man's heart whose nature has not been perverted, a deep seated attachment for his country. The patriotic throbbings of that heart are felt, and its beatings are heard every where to-day among the honest masses of this land. This is evidenced by the fact that wherever the voice of the people has been heard, it has spoken in favor
                of the Union, and they have manifested a strong determination to protect it from an internal, as they would defend it against an external enemy. Virginia, if the opportunity had been allowed her, would, as North Carolina, Tennessee and Kentucky have done, have voted down the proposition for a State Convention. There are higher and weightier considerations of public policy and of
                individual interest that call aloud upon Virginia to pronounce, and pronounce now in favor of peace. State credit, corporation credit, individual credit is prostrate; every industrial interest is paralyzed, and bankruptcy is staring us in the face. Confidence in the stability of our Government is fast giving way, and the melancholy truth is pressing the patriot's mind and weighing
                down the patriot's heart, that this Government is fast drifting towards destruction, and that another Government, challenging, as this has done, the admiration of the world, will never be reared upon its ruins. Let Virginia's clarion voice go forth this day from this Hall in favor of the work of that "Peace Conference;" let the people be assured that the opportunity will be afforded
                them of passing upon the work emanating from the representatives of twenty-one of the States of the Union; and let them not be kept in further doubt, by looking forward to the assembling of other bodies whose recommendations will probably be regarded with <PageNumber num={168} /> as little favor and treated with a little respect as seems to be shown on
                their part to some of the recommendations of that Peace Conference. Let us do this this day. It is cruel, aye, is it not criminal, to continue the present excitement, to keep the country in doubt as to what shall be its ultimate fate, and prolong that policy which threatens to beggar thousands, and has brought thousands already to bankruptcy?
              </p>
              <p className="p-in-sp">
                Who does not want to hear again the hum of industry? What eye that would not brighten up to see the sweet smile again upon countenances now bowed down with anxiety and care for home, country, and for all that makes life desirable? Let us declare our approval today of the measures recommended by the Peace Conference, and let us place them before the people, in order that they may have
                an opportunity to pronounce their will upon them. If the telegraphic wires could but bear intelligence of the fact that this Convention had spoken in favor of the measures of the Peace Convention, and had taken steps to place them before the people, it would at once restore confidence to this land from one end to the other. If we do but utter that voice, our land will smile again, as
                has been its wont in days gone by. Again will the people of this country bask in the sunshine of a renewed and awakened patriotism, and again will the ship of State float proudly upon the bosom of a calm and placid sea; and, sir, when we shall have done this, may we not congratulate ourselves that we have not lived in vain, and may we not reasonably hope that the monument of a more
                perfect Union, erected by our own Washington, Madison, and their revolutionary associates, will last until time shall be no more, and that it will only disappear with all things earthly?
              </p>
              <div className="lg">
                <div className="l">"When rapt in flames the realms of ether glow,</div>
                <div className="l">And Heaven's last thunder shakes the world below."</div>
              </div>
              <p className="p-in-sp">I will detain the Convention but for one moment longer, while I read to them an extract from a speech said to have been delivered by one [Ex-President TYLER] , who has occupied the highest office in the gift of the people of this whole country, and who now adds dignity and character to our body by being a member of it:</p>
              <p className="p-in-sp">
                "Sir, I have looked into the distant future, and have there beheld the greatness of free America. I have beheld her walking upon the face of the mighty deep, and carrying glad tidings of great joy into distant nations. I have seen her stretching her arms over the whole earth, and overturning the strongholds of despotisms in the old world. Wo, wo betide the man who shall endeavor to
                sow the seeds of disunion and anarchy among us. Better for him he had never been born. If he call <PageNumber num={169} /> upon mountains to hide him—nay, if he bury himself in the very centre of the earth—the indignation of man shall find him out, and blast him with its lightnings."
              </p>
              <p className="p-in-sp">In accordance with the promise which I made upon obtaining the floor, I now move that the Committee rise.</p>
              <p>The motion was agreed to.</p>
              <p>The Committee accordingly rose, and the President having resumed the Chair, the Chairman [<span className="persName">Mr. SOUTHALL</span>] , reported progress.</p>
              <p><span className="persName">The PRESIDENT</span> stated the business before the Convention to be the</p>
              <p>resolution submitted by the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] .</p>
              <p>On motion of <span className="persName">Mr. E. B. HALL</span>, the Convention then adjourned.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v2.2.5">
            <PageNumber num={170} />
            <h2><span className="headingNumber">1.5. </span><span className="head">THIRTY-SECOND DAY</span></h2>
            <div className="dateline">Friday, <span className="date">March 22</span></div>
            <p>
              The Convention met at 12 o'clock.<span className="note" id="Note24"><span className="noteLabel">1</span>The Journal gives the hour as 10:30 A.M.</span>
            </p>
            <p>Prayer by Rev. Dr. Jeter, of the Baptist Church.</p>
            <div className="speaker" id="sp962"><span className="persName">Mr. CARLILE</span>—</div>
            <p className="p-in-sp">
              Mr. President, I use, sir, for the purpose of disclaiming the jumble in the "Enquirer" of this morning purporting to give some remarks which I had the honor to submit to the committee of the whole on yesterday.<span className="note" id="Note25"
              ><span className="noteLabel">2</span>Mr. Carlile refers to his remarks in the Proceedings of March 21, pages 154-159. See note 5 on those Proceedings.</span
              >
              I am made to say, sir, what I did not say, and language that I did employ is so mixed up as to be almost impossible for me to recognize it. I have taken the trouble to write out this morning what I did say, and it will appear in the "Whig" of to-morrow morning.
            </p>
            <div className="speaker" id="sp963"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The gentleman from Marion [<span className="persName">Mr. HALL</span>] , is entitled to the floor under the resolutions of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] .</p>
            <div className="section" id="vsc1965.v2.2.5.1">
              <h3><span className="headingNumber">1.5.1. </span><span className="head">REPORT FROM THE AUDITING COMMITTEE</span></h3>
              <div className="speaker" id="sp964"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">With the permission of the gentleman, I will present a report to the Convention. The Committee charged with auditing certain accounts, have prepared a report which I am directed to submit, containing three resolutions. I beg that it may at once be acted upon.</p>
              <p>The report read as follows:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>The Committee appointed to audit claims against the Convention for services before its organization have, according to order, had under consideration such claims as were brought to their notice, and beg leave to report the following resolutions for adoption by the Convention:</p>
                  <p>Resolved, That Robert B. Craddock be allowed the sum of sixteen dollars, for four days service as door-keeper to the Convention.</p>
                  <p>Resolved, That Valentine Brown be allowed the sum of eight dollars, for two days service as door-keeper to the Convention.</p>
                  <p>Resolved, That Ritchie &amp; Dunnavant be allowed the sum of sixteen dollars and fifty cents, for printing five hundred rolls of members of the Convention.</p>
                </div>
              </div>
              <p>The report was adopted.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.5.2">
              <h3><span className="headingNumber">1.5.2. </span><span className="head">THE QUESTION OF TAXATION</span></h3>
              <div className="speaker" id="sp965"><span className="persName">Mr. E. B. HALL</span> of Marion—</div>
              <p className="p-in-sp">Mr. President, from the organization of the Convention until now, I have not opened my mouth upon any <PageNumber num={171} /> question, save to vote and to offer one or two resolutions. I have preferred to listen rather than to speak; but upon the question arising on the resolutions of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , I feel that those whom I represent and by whom we are surrounded, have certain interests, which, when I see a disposition manifested here upon the part of some to prevent us from having a fair hearing on this question, I feel constrained, in behalf of those I represent, to ask that my voice shall be heard upon our interests which are involved in this question.</p>
              <p className="p-in-sp">
                We have but half an hour to employ in the discussion of any question previous to the time at which the Convention has decided it will resolve itself into a Committee of the Whole. I cannot, therefore, fully enter into a discussion of the merits of the question, and give all my reasons for demanding a proper consideration of the subject now before the Convention. But there are some
                suggestions in addition to the reasons mentioned in the able argument of the gentleman who preceded me, which I should like to make in reference to this matter.
              </p>
              <p className="p-in-sp">
                We are told, sir, by gentlemen who urge that this question shall not be considered by the Convention now, that it would be a breach of faith, and that this Convention was called for no such purpose. This objection has been answered by those who preceded me, but I beg to add further on that point, that there never was a Convention in this Commonwealth which had the power to
                circumscribe the action of this Convention, or to dictate its recommendations; and those who are familiar with the manner and the circumstances connected with the calling of this Convention by the Legislature, know that it was understood distinctly at the time, that this question of taxation would be before this Convention. I beg that gentlemen will remember that, when this question
                was before the Legislature, a member of the Senate proposed that they should recite in the act calling this Convention, that they deemed it proper that this body should take into consideration the question of taxation. Knowing that they had no power to circumscribe this Convention, or to dictate what it should or should not do, they proposed to suggest in this Act that it would be
                improper to enter into the consideration of this question. It was the general impression in the Senate that that would be a fatal proposition, and an immediate adjournment was moved and carried. Next morning a motion was made to withdraw the proposition, and it was withdrawn. And withdrawn why? Because it was found that the feeling in relation to considering
                <PageNumber num={172} /> this question was such that it was necessary to waive that objection.
              </p>
              <p className="p-in-sp">
                I know, sir, that the members from the West, never voted for calling this Convention with any other expectation—never would have voted for it if it had been restricted, or required not to enter upon the discussion of this matter. I assure you, sir, that our people have felt the weight of this burden for years. And it is said that we have ratified the Constitution by which it is
                agreed that we would wait until 1865, before we sought a further agitation of the matter. I beg gentlemen to look at the article and they will find that there is no such provision whatever.
              </p>
              <p className="p-in-sp">But suppose there was; suppose that question of taxation had been connected with that of representation, has that any obligation upon us, with reference to this matter? What did we get in 1850? Because of a helpless minority we got only what we could get, in agreeing that we should suffer this inequality no longer than necessity compelled us to submit to it.</p>
              <p className="p-in-sp">
                But we are told this is an improper time to consider it because we have other questions before us, and that it evinces to the world that we are divided among ourselves and destroys our power and influence with reference to other questions. Now I ask you if you can conceal any longer that there is a dissatisfaction among us? I tell you you cannot do it. I come from that section of the
                State whose people are denounced upon this floor and outside the hall, as Submissionists, Abolitionists, and all these things. But I tell you, sir, that we have been submissionists because we must; and now we are disposed to be so no longer. I care not, sir, for names; the difference of names is played out; I care not by what name I am called—submissionist or what not. I would not
                wish to be understood as believing that gentlemen who urge that this subject shall not be agitated, do so with any other than a proper motive; but let me ask how would it embarrass this matter by considering it now? We have resolutions proposing what? The raising of a committee to enquire into the propriety of these amendments. Now, sir, if we raise a committee it can sit without
                occupying any of the time of this body. It is important that we hasten to some action upon all the questions before this Convention, in order that we may adjourn as soon as possible and go home to our people. If this committee be appointed it would expedite our business.
              </p>
              <p className="p-in-sp">
                And why is it eminently proper that it should be done now? Why, sir, it has been admitted upon this floor, I believe, by all those who gave an expression of opinion on this question, that what we ask, that the <PageNumber num={173} /> principle for which we contend, is right and just. Who, I ask, has denied that it is our right? And when that is already
                admitted, how long would it detain this Convention to say that it is right? I want to see perfect harmony throughout this State; I want to see perfect equality of rights; I want to see the burdens equally borne in each portion of the State; I want to see harmony prevail throughout all its parts. But why postpone this matter? It has been said outside—and those matters have been
                brought in here and read—it has been said outside, that the West who have an interest in this matter—although, as was remarked by my colleague and others who preceded me, that this was not a question of East and West, but that it was really a question between those who did and those who did not hold slave property—and that is the true position—but it has been said outside that the
                East and the West were engaged in a bargain in reference to this question. It is suggested that the gentlemen from the East were willing to forego their objections, if the conservative element from the West, who are anxious to have this matter settled now, were willing to co-operate with the Eastern members in passing an ordinance of secession.
              </p>
              <p className="p-in-sp">
                Now, sir, if there has been any such understanding upon the part of any gentleman representing this interest, I have been unable to find it. But what do we find? We find that after the suggestion has gone forth in the public prints, the proposition is deliberately made upon this floor, that if we will join in voting for an ordinance of secession and then go home and labor with our
                people to procure the ratification of that ordinance, we can have an adjustment of this taxation question.
              </p>
              <div className="speaker" id="sp966"><span className="persName">Mr. GOODE</span>—</div>
              <p className="p-in-sp">Will the gentleman allow me to ask him if he desires to be understood as making that charge upon the whole secession party?</p>
              <div className="speaker" id="sp967"><span className="persName">Mr. HALL</span>, of Marion—</div>
              <p className="p-in-sp">
                No, sir. It has been stated upon this floor, however—I am not able to state precisely who did make the assertion,<span className="note" id="Note26"><span className="noteLabel">3</span>Mr. Hall corrected this statement in the Proceedings of March 25, page 249.</span> but that proposition was made upon this floor, and made not as the gentleman from Barbour
                [<span className="persName">Mr. WOODS</span>] , would say "jocularly," because, in the disclaimer afterwards made that, in presenting the proposition, the gentleman did it only upon his own individual responsibility, he reiterated the proposition seriously and soberly.</p>
              <p className="p-in-sp">
                Now, Mr. President, I beg to say that I hope there is no man representing this or any other interest who is ready to enter into any bargain. We came here not to purchase but to ask what we conceive to be our right. We ask it not as a condition on which we will agree to any other proposition, but we ask it as a right, and we are entitled to receive it unconditionally. I scorn a
                proposition to sell anything in order to obtain our rights. I believe there is no such sentiment upon the part <PageNumber num={174} /> of any man representing or holding the views that I do and desiring the result which I desire in this particular, and I believe there is no such gentleman who has had any participation whatever in any such proposition
                as this. I cannot believe it. But what are we to infer from the circumstances? I can come to but one conclusion. When I see that there is a disposition, by moving to lay the resolutions on the table, to cut off all debate, to defeat the resolution, and not even accord to us the raising of a committee to enquire into the propriety of making any further provision in this regard, which
                is usually granted as an act of common courtesy; when I see a disposition to preclude, in advance, any consideration of the subject, I am bound to come to the conclusion —I hope I may be wrong, but I can come to no other result, and I can see no other legitimate conclusion, than that this matter is to be held over us, and to be granted only on the condition that we will accord to
                other gentlemen the course they desire the Convention to pursue in regard to the questions pertaining to Federal Relations.
              </p>
              <p className="p-in-sp">
                I say I hope there is no man in this body representing any portion of the freemen of the State of Virginia who would permit himself to be influenced in his action upon any question with the view of obtaining the concession thereby of some other right pertaining to some other matter. I hope gentlemen are not to be sold in the market here. I will never yield to such a proposition
                though it cost me my life, and I will never believe that any man who co-operates with me in this idea or policy will agree to it.
              </p>
              <p className="p-in-sp">
                Now, Mr. President, with the position in which this question is presented to us, I think it is due, I think it is eminently right and proper that it should be considered, and considered now. What will be the effect, what construction will be placed upon the refusal of the Convention to consider it? Simply this, that it is refused, that it is held back for the purpose of ascertaining
                if it could be used as a lever, as I before indicated, for the accomplishment of another purpose.
              </p>
              <p className="p-in-sp">
                There are many questions requiring our consideration, but it seems that in order to avoid this one question in reference to taxation, gentlemen here are unwilling to entertain any question in relation to any amendment of the organic law of the Commonwealth. I do not know how it may be with other sections of the State, but I know that in my section it is believed that our organic law
                should be changed in other respects. We have an inefficient incumbrance upon our judiciary in the shape of a county court, which weighs upon us as a burthen under which our people are literally groaning. In these times, they are anxious to be relieved from all unnecessary expense and to have efficiency <PageNumber num={175} /> in all the departments of
                Government by which they may maintain their rights as was contemplated in the organization of these institutions.
              </p>
              <p className="p-in-sp">
                That there are features of our Constitution which require amendment, defects which the people since the adoption of the Constitution have seen really to operate against their interest and require amendment and reform at once, no one can doubt. I hope therefore that it will be the pleasure, and I appeal to men from all parts of the State to allow us to consider this matter; to let us
                be heard upon this matter; to appoint a committee to determine and report upon these various questions to this body. Such a committee would consider these matters, mature whatever action they should see proper to recommend, submit it to us and it would require but little time for the Convention to act upon it. If it is right that we should at any time have an adjustment of this
                question of taxation, it is right that we should have it now. We ask that you will grant it now. And what is the reason given—what is urged as the reason why we shall not act upon it now? I have heard no reason except the simple declaration that there was an important question outside of this for which this Convention was called. I admit the importance of the other question, and I
                would not trammel it by any other that shall embarrass its consideration. But I appeal to gentlemen to place themselves in our position, asking for what it is universally admitted is their right to have as a matter of common justice; and suppose we refuse to accord them that right, what would be the effect of it?
              </p>
              <p className="p-in-sp">Why, sir, the effect of this defect in our organic law is not confined to any one section of the State; it operates upon all; but we in the West feel its injurious effects more than they are felt in the East, because we have fewer slaves there. But it is urged that we are unsound, unsound upon the slavery question.</p>
              <p>Before <span className="persName">Mr. HALL</span> had concluded his remarks, the hour of eleven arrived, and he was interrupted by the President.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.5.3">
              <h3><span className="headingNumber">1.5.3. </span><span className="head">FEDERAL RELATIONS</span></h3>
              <p>The committee, in pursuance of its standing order, resolved itself into Committee of the Whole [<span className="persName">Mr. SOUTHALL</span> in the Chair] , and resumed the consideration of the report of the Committee on Federal relations, the pending question being the amendment in the nature of a substitute offered by <span className="persName">Mr. CARLILE</span>, upon which <span className="persName">Mr. BALDWIN</span>, of Augusta, was entitled to the floor.</p>
              <div className="speaker" id="sp968"><span className="persName">Mr. BALDWIN</span> resumed as follows:</div>
              <p className="p-in-sp">
                I was endeavoring, yesterday, Mr. Chairman, as far as I could, <PageNumber num={176} /> during the time that I occupied the floor, to narrow the issues presented by the question before this body. I think I have discovered a tendency —I will not say a disposition—on the part of members participating in this discussion, to introduce into it matters that
                have nothing to do with the merits of the subject about which we are engaged, and to avoid what seemed to me to be questions of real, vital and pressing interest before us. In the effort that I was making yesterday to call back the Committee to what I regard as the true issues, I undertook to assert—and I understood the assertion to be distinctly admitted to be correct—that the State
                of Virginia, in regard to all the great questions of administration which have occupied the attention of this country from the foundation of the Government down to the election of Abraham Lincoln, had no cause of complaint to urge against the General Government, unless it might be in regard to the question of slavery. I undertook to re-call to the mind of the Committee, that, with
                but short intervals, the high offices of the Government of the United States have been in the hands mainly of the sons of Virginia—that the great principles upon which the Government has been administered, and which were, until recently, thought to be the established principles of our country, were impressed upon the administration by the minds of Virginia's sons, and that, in all
                the departments of the General Government, Virginia influences have been predominant, and hence that she was bound to say that, substantially, in regard to all the great matters of concern to our people—now thirty millions in number—the Government had been in the main administered satisfactorily, wisely and well. This proposition I understood to be distinctly admitted.
              </p>
              <div className="speaker" id="sp969"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">May I inquire of the gentleman by whom that admission was made?</p>
              <div className="speaker" id="sp970"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I understood it to be made distinctly by the gentleman from Albemarle [<span className="persName">Mr. HOLCOMBE</span>] , who preceded me immediately in the discussion.</p>
              <div className="speaker" id="sp971"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">
                In answer to the remarks of the gentleman, yesterday, I intended to do nothing more than to acknowledge, so far as I was concerned, my individual opinion, that no action had ever been taken by the General Government up to this time, upon any subject unconnected with slavery, that would, for a moment, have justified the people of Virginia in raising an issue of Union or disunion. But
                I intended, by no means, to make the admission that the policy of the General Government had been always such as the people of Virginia would approve; on the contrary that there were instances—that there were many instances—in the policy of that Government, that they <PageNumber num={177} /> could not approve. But I said that I was ready to admit, that,
                with the exception of the slavery question, Virginia had no grievances upon which to make an issue of disunion.
              </p>
              <div className="speaker" id="sp972"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                I did not understand the gentleman as intending to speak for any one but himself, but understood him to assent distinctly to the general proposition that in the main, in the administration of the General Government, substantially, the Virginia policy had governed. I do not expect to find that gentleman or any other gentleman here, undertaking to endorse item by item all the matters
                of administrative detail that have occupied the attention of our Government from its foundation. I did not expect, nor do I desire, that the admission of any member on this floor would be tortured so as to extend to any such sweeping acknowledgment as that; but the direct proposition remains, that, whatever might have been her opinion now or then, as to this or that particular
                measure of administrative policy, that in the main, upon the whole taken together in the series of years, the administration of the Government of the United States has been satisfactory to Virginia.
              </p>
              <p className="p-in-sp">
                This, sir, has the effect, then, of narrowing down the subject of inquiry to the issues growing directly out of the slavery question, and upon that question I undertake to assert and to challenge a contradiction from any quarter, that so far as the administrative action of the government of the United States has been concerned, it has never, from its foundation down to the election
                of Abraham Lincoln, done anything in regard to the subject of slavery or taken any measure in regard to the institutions of the South, that was not either approved at the time, or concurred in by Virginia, or at some time subsequently received, approved and distinctly acquiesced in by this Commonwealth.
              </p>
              <p className="p-in-sp">
                Sir, I might rest here if I could rely upon the recollection of a body like this—I might rest here, upon the challenge which I have given, until some one of the distinguished gentlemen who represent the grievances of Virginia shall come forward and put his finger upon the answer to that challenge. But I will not rest content with that. I propose, now, in confirmation of what I have
                stated, to glance briefly at some of the most prominent measures which at different times have been made the subject of complaint—at the measures of restriction in regard to the institution of slavery. Why, sir, it astonished me, and I am sure it must astonish any man who will make the investigation, to find how thoroughly Virginia has been committed all the while to every one of the
                restrictive measures in regard to slavery, that have at any time been undertaken or carried out by the General Government.
              </p>
              <PageNumber num={178} />
              <p className="p-in-sp">
                The first great slavery restriction in this country was the ordinance of 1787, by which Virginia gave this great North-west territory for the common good, and at the same time affixed to the grant, the condition that in all that territory, out of which were formed five great States of the Union, involuntary servitude, except for crime, should thereaftei be forever prohibited. I
                presume it will not be denied here or anywhere else that that measure received the distinct sanction and approbation of Virginia, of her government and her people. I know it is fashionable now to refer to that as one of the errors of a dark age; but, sir, it is but a moment in the history of a nation; and when we are seeking causes of complaint, we are bound in common honesty, as it
                seems to me, to withhold our censure from those measures in regard to which our approval was sought and gained at the time.
              </p>
              <p className="p-in-sp">
                The next step of slavery restriction undertaken by the government of the United States, of which I have any recollection, was in the act of Congress dividing the territory of Orleans, after its acquisition by the government of the United States, and imposing a restriction upon the importation of slaves of a certain description into that territory. That act constituted a part of the
                administration of Thomas Jefferson ; and, sir, so far as I remember, I have never yet heard of its being complained of in any quarter; and yet it was an act of slavery restriction.
              </p>
              <p className="p-in-sp">What was the next? The next was the Missouri Compromise, by which the line which has been so eloquently denounced by the distinguished gentleman from Albemarle [<span className="persName">Mr. HOLCOMBE</span>]
                , was established; the celebrated line of 36 deg. 30 min.; a compromise by which slavery north of that line was forever prohibited, while South of that line it was left to be determined by the people according to their desire. What is the history of that measure? I suppose it will hardly be controverted here in a Virginia assembly, that that measure received the support of Virginia's
                Senators in the Congress of the United States, the approval of Mr. Monroe, the President of the United States, a Virginian, and of his Cabinet, composed of a majority of Southern men, Calhoun, Wirt and Crawford. That was the Missouri Compromise; and, sir, it not only received that sanction at the time; was not only sustained by the voice of Virginia, at that day, but it has since
                distinctly, over and over again received the approval of Virginia, as we shall see.
              </p>
              <p className="p-in-sp">The next instance of slavery restriction was during the administration of the distinguished gentleman from Charles City [Ex-President TYLER] , now a member of this body, a measure which received his distinct approval and support.</p>
              <PageNumber num={179} />
              <div className="speaker" id="sp973"><span className="persName">Ex-President TYLER</span>—</div>
              <p className="p-in-sp">
                Will the honorable gentleman from Augusta give way for a moment. In regard to the Missouri Compromise I was an actor upon the stage at that time—a member of the Congress of the United States. The gentleman has very correctly stated the proposition. He has given you the history, but he has forgotten one portion of it, of which I beg leave to remind him, and that is, that Virginia
                being represented by 22 persons upon the floor of the House of Representatives, the negative to that proposition was sustained throughout, and would have been sustained, as I verily believe, to the present moment, by 18 members out of the 22 represented. The gentleman is aware of that fact.
              </p>
              <p className="p-in-sp">
                In regard to my own agency in that connection, my humble vote has been recorded throughout against it, and I would have stood there until I perished, before I would have recognized that line. I believed it to be unconstitutional. I believed it to be, moreover, if the gentleman will pardon me for interrupting him thus longer, to be the opening of Pandora's box, which would let out
                upon us all the present evils which have gathered over the land. In regard to my own action in the administration of the government, I am entirely persuaded—
              </p>
              <div className="speaker" id="sp974"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">The distinguished gentleman might have waited for the attack to be made before offering his defence.</p>
              <div className="speaker" id="sp975"><span className="persName">Ex-President TYLER</span>—</div>
              <p className="p-in-sp">I did not understand that I was attacked. I merely designed to make an explanation of my course.</p>
              <div className="speaker" id="sp976"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I hope the gentleman before he replies, will allow me to state the case. I believe the prosecutor is entitled to the opening and closing of the argument.</p>
              <div className="speaker" id="sp977"><span className="persName">Ex-President TYLER</span>—</div>
              <p className="p-in-sp">I was merely entering into an explanation of this matter of the Missouri Compromise line in connection with the annexation of Texas, which was spoken of by the distinguished gentleman [<span className="persName">Mr. BALDWIN</span>]
                . Mr. Chairman, it is essentially a different matter between a question of original jurisdiction and right on the part of Congress where you have a Territory like this in question, to draw discriminations unfavorable to any portion of the country, and to stipulate for terms in the negotiation of treaties for the acquisition of a new Territory. Why, I should no more question the right
                of the government in the event of our feeling a desire hereafter for the annexation of Canada to the United States, to stipulate with Great Britain that slavery should never exist within the Canadas. And so in negotiating with Texas, we had a right not only to present our terms, but we had also a right to consider the terms to present to it; and when therefore it occurred to us
              </p>
              <PageNumber num={180} />
              <p className="p-in-sp">
                that it would be wise to throw off the 36 30 line, we had an unquestionable right to do it in that treaty. I remember to have seen this statement upon a previous occasion, and it was answered by me at the time, for I wanted to stand right in the forum of history. Whatever there is of me, is altogether of an historical character. I want above all things to preserve the little space I
                may occupy upon the page of history, legibly and correctly written. I never would have yielded to that Missouri Compromise, I would have died in my shoes, suffered any sort of punishment you could have inflicted upon me, before I would have done it. In regard to Texas it was altogether the acquisition of new Territory, and we were authorized to stipulate our terms. I beg pardon for
                having occupied so much of your time in making this explanation.
              </p>
              <div className="speaker" id="sp978"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">You will observe, Mr. Chairman, that it was not a question of Constitutional power, that I was considering. I was not enquiring whether the gentleman had ever conceded the question of Constitutional power; but I was speaking of the recognition of the slavery restriction. I claim that under the administration of the distinguished gentleman from Charles City [Ex-President TYLER]
                , and in a measure which he and his friends have ever claimed as the crowning act of his administration, entitling it to consideration before the world, that in that very act he recognized the extension of this odious Missouri Compromise line, as to which, he says, he would have died before he would have allowed it to be established; he recognized and approved the extension of that
                very line through this newly acquired Territory of Texas. It is that, sir, I speak of, and that is, as the gentleman says, in reference to the course of his life, a matter historical.
              </p>
              <p className="p-in-sp">
                But, sir, I referred to this matter as showing but one of the series of recognitions of this Missouri Compromise line by Virginia. In 1846 comes the next slavery restriction; in which we have first a recognition by Virginia of the power of Congress over the subject of slavery restriction, and then a recognition again of the Missouri Compromise line. How is that, sir? Upon the
                establishment of the Territorial Government of Oregon during the administration of Mr. Polk—to which administration Virginia is always proud to point as one exemplifying sound Virginia principle—during the administration of Mr. Polk, I repeat, the Territory of Oregon was established, and in the bill organizing it, was inserted the proviso that had been used in the ordinance of 1787,
                prohibiting slavery or involuntary servitude forever in the Territory of Oregon. That measure passed both Houses of Congress, and was approved by Mr. Polk. And why? On what ground did he place his approval? He certainly must be taken to have conceded the Constitutional <PageNumber num={181} /> power, because, I presume he would hardly have felt
                justified in signing a bill which he regarded as unconstitutional; but, sir, he placed his approval of it upon the ground that the Territory of Oregon lay North of the line of 36 30; and he declared it to be his policy to recognize the Missouri Compromise as applying to all the Territories of the United States whether embraced by it at the time or not.
              </p>
              <p className="p-in-sp">That was the position assumed by Mr. Polk; and in taking it, he has been over and over again justified, endorsed and sustained by the people of Virginia.</p>
              <p className="p-in-sp">
                In the year 1847, this subject came up before the Virginia Legislature, who, undertaking to denounce the Wilmot Proviso as applied to the Territories of the United States, declared it to be unconstitutional, and a measure that ought to be resisted; but feeling that they were upon tender ground in regard to the act of a Southern Administration which had approved the restrictive
                Proviso in the Oregon bill, they adopted the curious expedient of denouncing the Wilmot Proviso as contrary to the spirit and principle of the Missouri Compromise. They recognize it thereby to be a proper thing to apply the Wilmot Proviso wherever it would be consistent with the principles of the Missouri Compromise ; by which I understand, Virginia to mean that she recognized 36
                deg. 30 min., as the true division line, and that she would not complain of the restriction North of that line.
              </p>
              <p className="p-in-sp">
                Where then is the next matter of complaint in regard to the institution of slavery? Why sir, the admission of California, and the enactment of the law in regard to the slave trade in the District of Columbia. Those measures were resisted by Virginia as part and parcel of the Compromise measures of 1850. I remember that upon those measures the State of Virginia was very much divided
                at the time of their adoption; but since that time the State of Virginia has given to that adjustment and that Compromise, in all its parts, the sanction of her unanimous approval.
              </p>
              <p className="p-in-sp">
                In 1852, there was a Presidential election, in which two political parties came before the people of the country, and before the people of Virginia for support. Both placed their candidates distinctly upon what were called platforms, in each of which was a distinct ratification and endorsement of the Compromise measures of 1850 as a final settlement of all the questions to which they
                relate; and, sir, all gentlemen here present, whatever may have been their political status at that time, I presume, were found in one or other of the two political parties <PageNumber num={182} /> which, in solemn form, gave the stamp of their approval and ratification to those measures.
              </p>
              <p className="p-in-sp">
                Then, sir, where, is the measure in regard to the institution of slavery in the history of the administration of this Government, in reference to which Virginia has not either at the time participated actually in the adoption, or in the subsequent progress of the country and of the government distinctly at some time or other expressed her approval? I think, then, sir, that I am
                warranted in saying, as I said yesterday, that I challenge and defy the production of a single instance in the history of this country in which the action of the Federal Government in regard to the institution of slavery, has not at some time or other received the direct approval of the people of Virginia. I repeat the challenge, and I wish it borne in mind in the future progress of
                the discussion, if any gentleman shall do me the honor to notice anything that has fallen from my lips in the course of this debate, I trust he will do me the pleasure to give his special attention to this challenge which I now here throw down in the presence of this assembly.
              </p>
              <div className="speaker" id="sp979"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">I take up the glove, sir.</p>
              <div className="speaker" id="sp980"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">When the glove is taken up, I wish the gentleman to recollect that the battle is about to begin.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp981"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">And it will be a long time before it is ended.</p>
              <div className="stage it">[Renewed laughter.]</div>
              <div className="speaker" id="sp982"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                I undertook yesterday, sir, to speak of the talk that we hear of the degradation of this Commonwealth—of the degraded condition of Virginia. It is a subject in regard to which I admit I have some warmth of feeling; and I find upon reflection, that in my own estimation, and I fear in the estimation of others, I perhaps used a harshness of expression that is not consonant with my own
                feelings or with correct taste or good usage. I regret that I have done it, and I wish to disclaim any idea of saying anything offensive to any gentleman on this floor or elsewhere. I have no desire to indulge in any asperity of feeling—
              </p>
              <div className="speaker" id="sp983"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">I hope my friend will not understand me concerning what he said that I took it up as a challenge; but with a sweet smile I took up that glove.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp984"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                I had no reference to that. Sir, I have no feeling of asperity whatever. I admit that I have a deep and earnest feeling and conviction in regard to the propriety of the course in which I am now engaged, that it moves every feeling of my heart, every energy of my mind; but I thank God that I am so constituted that I can tolerate the most enlarged freedom of opinion, and that I have no
                <PageNumber num={183} /> disposition whatever to carry into the discussion any excitement whatever that the subject might give rise to. But, sir, I can hardly regret having used this language, when I remember that it has given an opportunity to the distinguished gentleman from Albemarle
                [<span className="persName">Mr. HOLCOMBE</span>] , to come forward as he did promptly, eagerly, gracefully, to disclaim here in the presence of this assembly, any desire or intention to impute to our proud and peerless old Commonwealth anything of degradation.</p>
              <p className="p-in-sp">So much for that, Mr. Chairman. And now allow me to proceed to consider—</p>
              <div className="speaker" id="sp985"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">
                I hope the gentleman will permit me to interrupt him in order that I may put myself right. I did not yield that I needed any opportunity of disclamation. I feel that in the most explicit language which it is possible for, man to employ, I have directly and pointedly declared that what I had said in reference to the Commonwealth of Virginia was not said in a tone of reproach, but
                merely of regret that she had lost an opportunity to exert her influence, and in no way involving any reflection upon the principles or the spirit of her people; and when I interrupted the gentleman it was not for the purpose of putting any construction upon the remarks that I made, but to bring to his attention, what evidently had escaped it, my clear, unmistakable declaration.
              </p>
              <div className="speaker" id="sp986"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I certainly have no desire to hold out the idea that any disclaimer was necessary to the gentleman, but he cannot deprive me of the satisfaction that I derive from his more specific declaration.</p>
              <div className="speaker" id="sp987"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I have no desire to deprive the gentleman of that satisfaction.</p>
              <div className="speaker" id="sp988"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">Mr. Chairman, I think I have brought the history of the question which this Convention is engaged in considering, fairly down to the time of the election of Lincoln. Let us see what there is in that. That election has been spoken of by both the distinguished gentlemen who have preceded me [Messrs. <span className="persName">RANDOLPH</span> and <span className="persName">HOLCOMBE</span>] , as an overthrow, or a subversion of the Constitution, by the use of its own forms. I do not understand them to say that the mere election of Lincoln of itself, would have been a sufficient justification of disunion; but I understand them to say that it was an expressive fact which justified dissolution, because of the existing feeling which it represented.</p>
              <p className="p-in-sp">Sir, I take issue with the gentlemen here. I deny that the election of Lincoln, or of any other man, could, in any just sense, be regarded as justifying disunion or secession in any quarter.</p>
              <p className="p-in-sp">
                On the contrary, I undertake to establish here that that very election <PageNumber num={184} /> devolved upon each State in the South a new -duty and a new obligation of the highest and most imperative character, to stand by the defences of the Constitution and to defend and uphold the rights of her sister States under it. Sir, I regard this assumption,
                that the election of any man to the Presidency can justify disunion, as a direct assault upon the fundamental principles of American liberty.
              </p>
              <p className="p-in-sp">
                What is American liberty? Is it like any other liberty? Or is it not distinguished from all by some striking peculiarities, as American liberty, American Constitutional liberty, American representative liberty, American Confederated liberty? Sir, I regard any attempt to dissolve this Union because of the election of any President, or at the time of the election of any unfriendly
                President, to be a direct assault upon every one of those peculiar features which distinguish American liberty from all other liberty. American liberty is not the license of one man or of many; it is not the power of one or the power of the few or of the many in any unrestricted sense. American liberty is founded, upon confidence in the virtue and intelligence of the people. That is
                its foundation and chief corner-stone. But, sir, it is built up in that wise distrust which has been well characterized here as the parent of safety. It is built, in the first place, with that distinguishing peculiarity. The Constitutional compact, limiting the power of the Government, in all its departments, within certain circumscribed boundaries which are regarded as insuring
                safety against tyranny and oppression.
              </p>
              <p className="p-in-sp">
                It has not only this restriction, but the contract, although resting upon the integrity and the intelligence of the people, does not allow the majority of mere numbers, the opportunity to disregard, if they would, or thoughtlessly to violate the solemn obligation to maintain limitations established for the defence of Constitutional liberty. The powers conferred upon our government
                which the Constitution limits, defines and restricts, are all distributed according to a discreet precaution in our system among the different departments of government, for wise and prudent purposes; as a restraint and check upon outbreaks of popular will, or popular excitement. They are divided among coordinate departments of the government to operate as checks upon one another, in
                order to secure that the constitutional powers of the government shall be exercised with calmness, deliberation and discretion; and with due regard to the rights of the people and to all the great interests of the country. Sir, this is our idea of American constitutional representative republican liberty.
              </p>
              <p className="p-in-sp">
                Well, sir, why is it that all these checks and balances have been resorted to? Why did not our fathers rest the liberty of the country, the <PageNumber num={185} /> rights of the States and the rights of the people upon one single guaranty? Why was it that they did not allow them to stand the chances of the overthrow of one single defence against
                encroachment? Sir, they have built them up; one, two, three, four, five distinct barriers. One may fail, two, three, four may fail, and yet the fifth may remain and be sufficient to protect the Constitution from overthrow. Why is this? Our forefathers, with that wise forethought which distinguished them in regard to all those matters, anticipated that the time might come, aye, in all
                probability would come, when the excitement, turbulence, violence if you choose, incident to all free government, might overwhelm the popular assembly, when the House of Representatives, fresh from the people by short tenures, might yield to the popular clamor of the moment, to the excitement of freemen roused in regard to any great question, overstep the bounds of constitutional
                limitation and encroach upon the liberties of the minority of the people or upon the rights of the States.
              </p>
              <p className="p-in-sp">
                And, sir, to avoid that, they erected another barrier, the United States Senate, removed farther from the turmoils and excitements and passions of the multitude. As another barrier against this encroachment they armed the President of the United States with the great conservative veto. And yet another in the Supreme Court of the United States. Then they reserved to the people
                themselves, at the polls, the power which after all is the great safeguard, and one which the people, if they are wise, will never let go—a check by which the administrators of this entire government must come back at last to the people for judgment in regard to their public conduct.
              </p>
              <p className="p-in-sp">
                Why was all this? Was it the anticipation of our forefathers that the time would come when any portion of the American people would desert the Constitution upon the first assault? Was it in the contemplation of those gallant men, educated in the principles of Anglo-Saxon representative liberty, that their descendants would ever come to that time when on the first assault upon the
                Constitution they would give it up, surrender it? throw it away as a worthless and a useless thing? Now, sir, these barriers were erected as an injunction upon the part of our fathers which it will be well for us to heed. It was an admonition to us if we are true friends of free principles, of popular right, of Constitutional liberty, if we are beaten in the popular branch of the
                National Legislature, to appeal to the Senate. If beaten in the Senate, to appeal to the Constitutional Executive veto. If that fail, to appeal to the Supreme Court to declare the just construction of the charter by which all power is held and withheld. And, if all fail, when all these <PageNumber num={186} /> means of protection have failed, not to
                give up the ship, but to appeal from the false agents of the people, from the faithless administrators of broken trusts to the people who conferred the trusts, from the servants of the people to their masters at the polls; and only upon the consent of those who framed the government—only upon the consent of those for whose benefit its powers are wielded, if wielded justly, only upon
                their consent agree to give up the great experiment of American Constitutional representative republican liberty.
              </p>
              <p className="p-in-sp">
                Well, sir, at the time of the election of Lincoln, what had been the condition of things in regard to all these great departments of government? It will not be pretended here that the enemies of the South had ever been in a majority in either branch of the Legislative department of the government. It will not be pretended that the Supreme Court had ever failed, justly and fairly, and
                with a sufficiently Southern aspect, to say the least of it, to expound the Constitutional rights of the South. Then in the Senate of the United States, on the day of Lincoln's election, the south and the friends of the South were in a majority. In the House of Representatives on that day, the South and the friends of the South had control. In the Supreme Court the South and the
                friends of the South were in the ascendant. In this condition of things, the Presidential office was gained by the Republicans as the result of a party contest before the American people. What then? Immediately the cry was that the government had been seized. The government, the government, Mr. Chairman. I fear very much that we have gotten into the habit of exaggerating, grossly
                exaggerating the importance of the Executive office. We have come to attribute to the Presidential office more power, more influence, and more consideration, than justly belongs to it according to a fair estimate of our system. And I think I see, resulting from this, the exaggerated importance attached to the position of Abraham Lincoln in the Presidential office. Why, sir, my
                opinion is, and has been for years, that the President is the weakest department of the government. It has the duty of disbursing patronage to hungry office seekers, and while there are more who want office than there are offices to be bestowed, this will always be a source of weakness rather than of power. Then I undertake to say that the Republican party, in the election of Abraham
                Lincoln, obtained possession of the weakest department of the government, and one which, if anything could bring them to an untimely end, will have that effect beyond a doubt, beyond a controversy.
              </p>
              <p className="p-in-sp">
                Well, sir, what was the duty of the South under these circumstances? She had not votes enough it is true, but she had friends enough in the <PageNumber num={187} /> Congress then sitting and in the Congress next elected, to insure, if every man stood firm to his constitutional duty in defence of the rights of the South, that no successful assault could
                be made upon them. And, sir, who is responsible before God and man for the fact that the Republican party is this day in the ascendant in the different departments of the government? At the time of the election of Lincoln,
              </p>
              <p className="p-in-sp">I have shown, the bulwarks of the Constitution, except the Presidential office, stood unbroken.</p>
              <p className="p-in-sp">
                If our adversaries, our enemies, our oppressors, or whatever you choose to call them, are in the ascendant now, who is responsible? Who has withdrawn fourteen Senators from the Senate of the United States, and left us in the lamentable condition of being in a hopeless and helpless minority in that body? Who, but the seceding States of the South, that have also withdrawn more than
                thirty members of Congress from the House of Representatives, and left us in a minority not only in the Congress just closed, but in the Congress that is to assemble next fall?
              </p>
              <p className="p-in-sp">
                Understand me, Mr. Chairman; I have no intention and no disposition to undertake any denunciation of the seceded States or of any other States. This is not the time for denunciation. I would rather say what might promote peace and harmony, than to add anything whatever to the distractions of our unfortunate country. But it is due to the truth of history, it is due to justice and
                right and fair dealing that this matter should be placed in its just and fair light. What was the duty of these States to us? Had we ever done them wrong? Had we failed to do them right? Were we not to a large extent identified in interest, and ought we not to have been identified in feeling and in action? Why is it, then, that they have left us—left us helpless in the hands of those
                that they tell us are our enemies? Sir, there are circumstances connected with this matter that fill me, when I think of them, with the deepest pain and sorrow. When I heard, sir, of the fact that the election of Abraham Lincoln, which all of us must deplore as a great disaster, as a great wrong done to the entire South, was received in the city of Charleston, South Carolina, with
                the firing of one hundred guns, I must confess that I was compelled to inquire whether we have had an earnest seconding of our efforts to defeat him from people who thus rejoice over his election. I must confess that I did doubt whether we have been in heart and soul a united South, in earnest, honest opposition to the ascendancy of the Republican party.
              </p>
              <p className="p-in-sp">
                Sir, it seems to me that the plain duty and policy of the whole South was that we should all confer together and take our action, <PageNumber num={188} /> whatever it might be, together. Was there any necessity for our leaving the Union? None, sir; none whatever. The Constitution itself provided sufficient guarantees and sufficient protection. While we
                had the Legislative department we had the guarantees of power spoken of by the gentleman from Albemarle
                [<span className="persName">Mr. HOLCOMBE</span>]
                , as being possessed by the Barons of Runnymede. We had the power of withholding the supplies of Government until we compelled them to yield justice, and protection and safety to the South. Sir, the reference to the Barons of Runnymede, brings to mind the principles and practices which have prevailed among our English ancestry. I cherish the maxims and usages of Anglo-Saxon and
                Norman liberty which we have inherited, and which I fondly believe we have improved upon. I cherish that practice of English liberty that forces from power the concessions due to right by withholding the supplies of Government; we had that power, we had that right, and we had, moreover, a long line of illustrious and brilliant precedents to guide us.
              </p>
              <p className="p-in-sp">
                Were we justified, sir—will we be justified in impartial history—in the judgment of mankind—for thus deserting the great experiment of American Constitutional liberty—for abandoning our strongholds of defence and fortifications, upon the mere taking of an outpost? Is this in accordance with the practices and principles which have always distinguished English liberty, which I had
                supposed would ever distinguish American liberty? So far as I am concerned, I confess that my instincts, that my impulses were not to fly from the Constitution and seek refuge in other resorts; but I felt impelled to throw up defences around the Constitution and, with the friends of the Union, with the friends of the South and with the friends of Constitutional right and
                Constitutional liberty, to gather, within solid phalanx, around the Constitution of the United States—broken if you will, threatened if you will—but, because it was broken, because it was threatened, because it was in danger, I felt disposed the more to rally, with all my might and heart and soul, to the defence of this last hope—as I verily believe, of the permanent success of civil
                and religious liberty in the world. That was my disposition; and that, I think, should have been the disposition of all the South. But, sir, it was not done. A new idea—a new practice has been introduced into the Constitutional history of America. Sir, since the Constitution of the United States was formed—since its foundations were laid deep down, as it was supposed, in the very
                hearts of the people of America, new ideas, new inventions have come to pass; and we have now the railroad, the steamboat, and, and greatest invention of all, we have <PageNumber num={189} /> the magnetic telegraph, as the great precipitation, by whose magic influence government is overthrown, civil liberty prostrated and the reign of violence, of
                confusion, and of anarchy, inaugurated. Sir, it seems to be regarded now as degrading to a man, as something derogating from his manly spirit, to take counsel upon the great question now distracting the country, and to await mature consideration of what affects our most vital interest. In the good old times, just in proportion to the magnitude and importance of the interest at stake,
                it was regarded as a higher duty to think, to pause, to weigh well every step, to consult together. How is it now? We are urged every where to take counsel of our passions, instead of our judgment—to be guided by the instincts of resentment, rather than by the suggestions of reason. Upon these our Southern brethren have acted. They have refused counsel. They say that they are
                justified in not conferring with Virginia, because Virginia refused to go into conference with the Southern States a year ago. That, in my opinion, has no just bearing upon the question. A proposition is made to Virginia to go into conference avowedly for purposes of disunion. She is already satisfied, without conference, that the time has not come for disunion, but that each State
                has, within the Union, sufficient power and sufficient means of self-defence and self-vindication, and therefore she declines to go into a conference, called for the avowed purpose of disunion. Does that, at all, justify the idea that if Virginia had wanted disunion she would have been at liberty to disregard, altogether, the interests and safety of South Carolina, and to go into
                action without consultation? It is a very different thing to decline a proposition for action which, when declined, disturbs nothing, breaks up no interest, alarms no commerce, destroys no safe-guards. It is a Very different thing to decline a conference then, and to decline it now, and follow up the refusal by an act of violent disruption as these seceded States have done. Look at
                the position they have placed us in. We have been connected together by ties, social and commercial, of a sort such as have never bound together the people of States before. These ties were of such a character that the people of South Carolina were bound to regard them in any action that they might take for their own safety. But immediately upon the election of Lincoln, without
                waiting for conference, but, on the contrary she, disclaiming it, undertakes to raise the standard of secession, to break up all intercourse, social and commercial, all business relations of every kind and to shake the credit and confidence of the financial world by inaugurating a revolution. She undertook it all without consulting anybody but South Carolina,
                <PageNumber num={190} /> and looked to no interest but her own. She disregarded every other consideration, and pursued a policy which has brought upon the country the embarrassments and disasters which now oppress it.
              </p>
              <p className="p-in-sp">
                And here let me remark, that much is said upon the subject of these financial embarrassments, and they seem to be presented as arguments to induce men to go for secession, upon the ground that if this state of things continues we will be ruined. Who brought about this state of things? If the causes of the disruption of this government were so deep-seated as to render it impracticable
                and unadvisable for the people of the North and the people of the South to live together, would it not have been in accordance with the spirit of the age that they should confer together in a manner due to their past associations of common interest, and make a severance of peace, thereby securing all the commercial and social advantages, and avoiding as far as possible all the evils
                that would follow upon such a separation. Was it in accordance with the spirit of the age that a violent disruption should take place which would effectually destroy all hopes of peace in the future, and all the advantages which would result from social and commercial intercourse. All these things seem to have been disregarded, and we are plunged now into that financial embarrassment
                which is ever the result of uncertainty in regard to the future. How disunion and further disruption is likely to restore confidence or give assurance of peace, I am at a loss to understand.
              </p>
              <p className="p-in-sp">
                But, sir, this disruption has taken place, and seven States have withdrawn, de facto, whether de jure or not. It is too late now to draw the precise distinction between Constitutional and revolutionary, or peaceful and violent remedies. Seven States, de facto, have undertaken to withdraw frorri the Union, and have undertaken to establish a government for themselves. And it becomes
                necessary for us to consider the new relations which devolve upon us. In the first place let me consider our relations to these cotton States. I deny the assertion that we are in any sense dependent for our policy upon them. I deny that it is either a physical, moral, or commercial necessity, that we shall follow their fortunes. I deny that we are so "hitched on" to them as to be
                "dragged after" their destiny. I hope, and I will not abandon the hope, that some day or other, sooner or later, the people of these States will see, what I think I see now, the utter hopelessness in any business, in any commercial, financial, or international sense, of their obtaining a Government, such as will satisfy any people who have once lived under the stars and stripes. I do
                not pretend to deny that they may establish a Government which may satisfy the fancy of some <PageNumber num={191} /> people, but, sir, with all the talk about the glory of a Southern Confederacy, with all the talk about the splendors of a Confederacy, which, as the gentleman from Albemarle has said, is to have for its theatre half a continent— it is
                after all but a Southern Confederacy, which is to have but half a continent; and those of us who have lived under the glorious Confederation of these United States, embracing a whole continent, may well refuse to go into ecstasies of admiration over the proposition to become members of a dismembered half, aye, less than half, of a continent. It seems to me that this argument, this
                idea of the brilliant future which is in store for the people of the South, if it proves any thing, proves too much for that side. It only proves the magnificence, the splendor, the great advantages of the glorious Union which we had, and which I trust in God we may have again.
              </p>
              <p className="p-in-sp">
                Sir, it may suit the fancy of some to wander amid ruined cities and admire the proportions of broken columns and crumbling arches, but I prefer the cities of living men. I prefer the haunts of busy industry and of thrifty enterprise. It may be reserved for the philosophic antiquarian hereafter, to trace out, in the future history of this continent, how this great and mighty republic
                underwent, time and again, disruption and disintegration, and how at each step downward, it still retained a large share of the brilliancy and magnificence of its original proportions.
              </p>
              <p className="p-in-sp">
                But, sir, after all it must be admitted everywhere and under all circumstances that each rupture, each division, will have a ruinous effect upon this glorious Confederation of ours. The broken fragments of the brightest diamond into the market to be valued ;<span className="note" id="Note27"
                ><span className="noteLabel">4</span>Something seems to have been lost from the beginning of the sentence, such as "Send" or "Take"; or perhaps "go" was omitted after "diamond."</span
                >
                compare their aggregate value with that of the unbroken jewel, and you will have a faint illustration of the condition, in the estimation of mankind, of this Union of ours unbroken and its condition with ever so many confederacies erected out of its ruins.
              </p>
              <p className="p-in-sp">
                But, sir, some of the States have left us, and their action has brought us into new relations with the other States. What are our relations to the border States—our physical relations? Look, Mr. Chairman, at our relations to North Carolina. She has acted by her Legislature and by her people—solemnly consulted at the polls. What has been her voice? She has refused even to call a
                Convention to consider the question of secession or disunion. How is it in regard to Tennessee? She has done the same thing. And so in regard to Kentucky. In Maryland no meeting of the Legislature or Convention has been had. How, then, are we situated? Why, sir, I asked a boy in the street, the other day, how Virginia would go out of the Union? and the reply
                <PageNumber num={192} /> was: "I suppose she will have to go by water, in a dugout."
                [Laughter.]
                How are we to act now? It must be by separate State action, I suppose. How will we join the Southern Confederacy? Ah, when we ask any of our earnest and rapid friends how this thing is to be done, they tell us that the voice of Virginia will be potential with North Carolina, and that North Carolina, in deference to the voice of Virginia, will reverse her matured and deliberate
                opinion, and will go South with us. Do you not think, to say the least, that it would be a little respectful in us, to ask her whether she will? Does it not strike you that it would be more consistent with a proper self-respect, on our part, and a proper respect to a sister State, who has acted as she has done, and declared her purpose so recently—to ask her whether her voice is to
                be controlled by our action, and whether she is prepared to reverse her conduct at our bidding. The border States have all the claims upon us that the Cotton States have, and some additional. They have the claim, that the Cotton States have, of past association, and they have in addition the claim of present association. The Cotton States have the claim of similar institutions; so
                have the border States, and, in addition to that, they have the claim of similar dangers, and similar wrongs. Then they have the higher, the stronger and the better claim upon us. I think we would be derelict in duty to ourselves, would act in disregard of common prudence of the rights, feelings and interests of those States, if we were to undertake to move one step without a
                conference with them. The proposition of the Committee of Twenty-one before this Committee now for its consideration, provides for such a conference.
              </p>
              <p className="p-in-sp">
                Our relations towards the free States are changed. We not only have the same complaints against them, but much more serious complaints than ever the cotton States had. We not only have causes of complaint against them, common to the Border Slave States, but we are exposed to new dangers which we must guard against by new precautions and new guarantees. This is one of the occasions,
                as it seems to me, when distrust becomes the parent of safety. It is an occasion when it becomes the Border Slave States to stand firm together, and to demand from the people of the North guarantees and securities, ample and complete, and overflowing in their abundance, against the new dangers to which we are to be exposed, and against the recurrence of this miserable, abominable
                agitation which has brought us into this serious difficulty. It is the duty of the North to give them to us promptly and earnestly. They ought to hasten to give to us, who are remaining under a common government and who are <PageNumber num={193} /> appealing to our Southern brethren to return to the ancient household, such guarantees as will afford us
                safety for the future and as will, or ought to, bring back our sister States of the South. If they refuse under circumstances thus appealing to their sense of justice, thus appealing to their kind feeling, I for one will not submit to it. I do not know whether I belong to the class referred to by the gentleman over the way, as being submissionists; but that is a thing I will not
                submit to, and that Virginia will not submit to, and that I believe the Border Slave States now remaining in the Union will not submit to.
              </p>
              <p className="p-in-sp">
                So far as I know the opinions or feelings of those who act with me upon this floor, it is no part of their purpose to advise or countenance any submission to the present condition of things. We will demand, and we must have the most thorough and complete guarantees for all our rights, without exception, or we must separate—one or the other. I hope the conference of the border States
                will be called, and I hope when it meets, it will agree upon some proposition of guaranty that will satisfy the South. I hope they will lay it down distinctly as their ultimatum, and that they will present to the people of the free States, in one hand this ultimatum of protection and guaranty, and in the other, the alternative of a peaceful dissolution of this government. That is the
                plan which I advocate, and upon which I propose to act.
              </p>
              <p className="p-in-sp">
                But, sir, we are told, there is no use of making a bargain with these people; that there is such a hatred between the people of the two sections; that there has grown up, such an anti-slavery fanaticism among the people of the North as to render it unsafe to live with them any longer; that we have got to be two nations in feeling, and we shall have to be two in government. We have
                lived together a long time. It is true we have had our jarrings, our quarreling and our complaints; but, it seems, even in the opinion of the distinguished gentlemen upon this side, we have not in anything else, suffered very materially, except that we have been abused in regard to our institutions, and threatened with interference in respect to our slave property.
              </p>
              <p className="p-in-sp">
                But they say there is an irrepressible conflict that has grown up between the people of the North and the South, and there is no living together; that either the North must succeed in overturning the institution of slavery, or the South must take the institution of slavery into its own keeping. I listened, as all of us listened, I have no doubt, with great satisfaction as well as
                admiration to the magnificent denunciation that was so justly hurled by the gentleman from Albemarle
                [<span className="persName">Mr. HOLCOMBE</span>] , at the distinguished Senator from New York [Mr. <PageNumber num={194} /> Seward]
                , in regard to his course upon this subject. He evidently had the adversary down, and was likely to keep him down, so far as the argument and the denunciation were concerned. In the days of ancient chivalry the victor knight was entitled to the horse and the armor of his vanquished adversary as lawful spoil of the conflict. Were you not, Mr. Chairman, reminded of that ancient usage,
                when you saw the distinguished gentleman from Albemarle brandishing aloft the sword of Seward over the dead body of his antagonist, proclaiming the irrepressible conflict—in a Southern aspect?
                [Applause.]
                Is this irrepressible conflict a living thing, or is it as dead as the Senator from New York was laid out the other day? Its author—no, not the author, but its great promoter—in his alarm at the awful condition of public affairs, has expressed his willingness, aye, his determination to sacrifice and abandon it; and his party, in the last Congress of the United States, have expressed
                the same willingness and the same purpose in an unmistakable manner.
              </p>
              <p className="p-in-sp">
                We have in the last Congress this remarkable fact, that will not be controverted, that by a vote of two thirds of both Houses, they have, with a Republican majority, in each passed and propounded for ratification by the States of the Union, a constitutional amendment which might well be considered as the epitaph to be inscribed upon the tombstone of the irrepressible conflict—a
                constitutional amendment by which it is provided that the Constitution never shall be amended so as to give the right to the General Government to interfere with slavery in the States, in any respect whatever.
              </p>
              <p className="p-in-sp">
                But, sir, what about this irrepressible conflict, and this great increase of danger to the South from the increase of the abolition sentiment? I deny the fact at the onset. Sir, the gentlemen who have addressed this body, I believe all from that side of the house, have spoken of slavery as if it were on the retreat, as if it were cowering before the advancing assault, as if it were
                losing the argument, and were giving way gradually before the rising storm of Northern fanaticism. I deny it. On the contrary, I assert with pride, with pleasure, and with satisfaction, that the institution of slavery this day stands upon a footing higher, stronger, firmer, than ever it stood since the foundation of the world; that it stands this day higher above successful assault,
                higher in the estimation of the religious, and moral, and intellectual, and philosophical, and business world than ever it stood at any day in its history. Sir, I can recollect—and I am not a very old man—I can remember myself when slavery, and the evil and the sin of slavery rested upon the minds and conscience of the South like an incubus. I can
                <PageNumber num={195} /> recollect, sir, when it was only spoken of by Northern men to denounce it, and by Southern men to offer up a feeble apology for its existence. That was the condition of the public opinion of the South upon this question when I first recollect it. What is it now? Sir, since the day when the Reformation in the Church was
                proclaimed by the Reformers, there never has occurred so wonderful, so thorough a revolution in public opinion in any country on any subject as has occurred in the opinion of the people of the South on the subject of slavery. Instead of its being looked upon now as a curse or a crime it is not only defended, but justified, aye, and approved by the South as a system of servitude
                between an inferior and a superior race, conferring benefits and blessings upon them both. Sir, the conscience of the South is easy. The South stands self-acquitted on this subject of our greatest institution and our greatest blessing.
              </p>
              <p className="p-in-sp">
                What is the condition of the Northern mind? Sir, there are more—and I say it without fear of contradiction—there are more pro-slavery men in the North than there were in the whole earth when I can first recollect. We have not only revolutionized the mind of the South and presented her redeemed, regenerated and disenthralled from this oppressive weight that bore her down in times
                past, but we have invaded the mind of the North. We have gone with them into the school of philosophy and theology, and we have grappled with them there, with success in defence of this great institution of the South. Not only that, sir, but we have gone into the world at large—into the world of letters and of science and have carried the defence of our principles every where, to say
                the least, with equal fortune.
              </p>
              <p className="p-in-sp">And, sir, under circumstances like these, at a time like this, are we to be told that the institution of slavery is threatened with overthrow and destruction? No, no! It never stood so firm as it stands this very day.</p>
              <p className="p-in-sp">
                But we are told that while all this is true in regard to the condition of slavery some twenty or thirty years ago, while it is true that all were opposed to it then, and thought it a sin, yet that it was merely a passive feeling, that it did not amount to anything, that they believed it to be a sin, but that that belief injured nobody. Sir, it is the opinion of almost every man I
                ever heard speak upon the subject, that if Virginia had been let alone by the fanatics of the North thirty years ago, we would not have had a slave to day, in the broad limits of the Commonwealth. I believe this is admitted by every body. I know that opinion has been expressed by the distinguished gentleman from Albemarle
                [<span className="persName">Mr. HOLCOMBE</span>]
                , in an address which has given infinite satisfaction to <PageNumber num={196} /> his friends and the public, delivered in the city of Petersburg, several years ago. Sir, we were in danger of this feeling which the gentleman seems now to think was a mere passive, theoretical feeling, having nothing of active principle about it. We were in danger at that
                day. That danger, thank God, has gone.
              </p>
              <p className="p-in-sp">Let us now look to the feeling in the North. It is true that there has been developed in the North an active principle of abolitionism. It began there; it first took possession of the harebrained men and the old maids of the North—the materials of fanaticism everywhere. [Laughter.] There it stopped, with an occasional lift from some weak member of the clergy, until after a while the clergy found favor among the fair sex; and abolition, putting on the garb of religion, came through the country in a white cravat, singing "psalms and hymns, and spiritual songs." [Laughter.]
                Its race was short, and was soon run; the institution of slavery, against which it was directed, existed at that time only in the States and the District of Columbia, and all the efforts of fanaticism to make an anti-slavery party had to come directly over the ramparts and bulwarks of the Constitution. Mr. Lincoln has said one good thing if he never said another, and that is that all
                our agitations and excitements and quarrels have occurred over points of doubtful constitutional construction. But, sir, the institution of slavery is so entrenched within the inner citadel of the Constitution that no man but a madman has ever yet undertaken to hazard the opinion that Congress had anything to do with it, and while it was confined to the States the party of
                abolitionists were such as I have described, fanatical, ridiculous, harmless.
              </p>
              <p className="p-in-sp">
                But, sir, when we began to acquire territory, when we began to settle new territory, as to which the question had not been adjusted, when we got beyond the healing influence of that beneficent measure, the Missouri Compromise, and the great doubtful territorial question began to come up, then the dogs of politicians got to fighting for the scraps that were to be gathered even at the
                humble tables of abolition leaders, and then wielding abolition as a balance of power, operating here and there, they have succeeded in getting advantages which to a superficial observer might give a false impression as to the condition of the North upon that subject.
              </p>
              <p className="p-in-sp">
                But, sir, it stands out that while the attempt was to be made to interfere with slavery where it existed, Abolitionism was a miserable, contemptible failure. And now Black Republicanism has only gotten a respectable number of supporters by backing down from all the fierce claims of the Abolition party. In old times the political Abolitionists
                <PageNumber num={197} /> always claimed to repeal the fugitive slave law—to abolish slavery in the District of Columbia, and in the United States dock yards, &amp;c—to interfere with the slave trade between the States, and ultimately to extinguish slavery in the States by amendments to the Constitution of the United States. Black Republicanism had to
                give up all of them and to take its stand upon the naked isolated question of restricting slavery from going into the Territories of the United States, and until it took that comparatively abstract position it never could succeed in getting the support of the Northern people. I refer to this to show that it is at least doubtful whether we have a right to attribute to the Northern
                people as a mass a purpose, or desire or disposition, in any way whatever to interfere with slavery where it exists. I know, sir, that by even stating facts as they exist before us, I render myself liable to the imputation of taking sides with Black Republicans, of defending Black Republicanism; but, sir, I tell you it is the part of wisdom to know where to find your adversary, and
                he is not a true friend who conceals from you the position of the enemy from whom you are expecting an assault. I feel bound to express my opinion on this subject, and I don't believe there is that feeling among the people of the North, that fanatical feeling, that is spoken of on this floor. Why, we have been told that this feeling is so violent that if it had existed between
                neighboring nations of different governments, it would have long since brought on a war. Well, it might be said, in passing, that it is one small recommendation of the united Government under which we have lived, that we have been saved from war which we would have had if we had been two nations. I think that may be fairly claimed as one of the small and incidental benefits of the
                Union.
              </p>
              <p className="p-in-sp">
                But we are told that this fanatical feeling is so strong and that it is so blind that it will sacrifice all other interests to accomplish its designs. It is admitted that the interests of the Northern people are bound up in this Union, and that its destruction will be a heavy blow to all their industrial and material interests; they are said to be keen sighted and intelligent people
                in regard to matters affecting their interest, but we are told we have no means of access to them; that they don't read our newspapers, don't hear our speakers, and that there is no way to reach them.
              </p>
              <p className="p-in-sp">
                But shift the scene. Dissolve the Union, take us into the Southern Confederacy, and when we come to discuss the question of the peace of the border, then, very unexpectedly, the people on the other side of the border suddenly become far-sighted, active observers, and we are assured, that, for the sake of the interest of commerce, they will consent
                <PageNumber num={198} /> to do us justice. Forthwith they are expected to change their course, to abandon fanaticism, and to overwhelm us with concessions. If I thought that a dissolution of the Union—that the disruption of this Government, would restore all the people of the North and South to a just perception and consciousness of their true
                interests, I don't know but I would consent to it upon an experiment. But, sir, what right have we to suppose that the peace of the border will be maintained between fanatical people on one side, and the objects of their fanatical hate on the other, where there is no obligation of common Government, and no community, whatever, of rights, duties or responsibilities?
              </p>
              <p className="p-in-sp">
                But it is said the reason they are assailing us is, that they feel somewhat responsible for the institution of slavery. Ah? Why, sir, it is a recognized fact that they don't feel responsible for it in the States. They might feel responsible for it, if they should get the power to amend the Constitution. But if we get them to agree, that, under no circumstances shall they have power
                over it, it would deprive them of the sense of responsibility as completely as if we were two countries, like England and France.
              </p>
              <div className="speaker" id="sp989"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">
                I do not wish to interrupt the course of the gentleman's argument, but I wish to say, in reference to a remark of mine which he seems to have misunderstood, that it was not intended to apply to the Northern people. In my description of the English peasant, I referred to him as being blank and stolid and indifferent, because he didn't have a vote, and I did not have reference to the
                Northern free laborer. I made the allusion in reference to no similar class of people in our own country; and, as I cannot have a chance, for some time, to have a reply to the gentleman—which I hope I will have—I wish to correct that misapprehension now.
              </p>
              <div className="speaker" id="sp990"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                I did not misapprehend the gentleman's remark. I had no reference to that. I understand his remarks about the English peasantry, about the English laboring people. I used, I believe inadvertently, a word which he applied to that subject as my interpretation of what he had said in regard to the difficulty of getting at the Northern mind. He certainly represented that it was impossible
                to get at the Northern people, because they do not read our newspapers, and do not hear our speeches. I was undertaking to make no assault upon the gentleman, because he expressed that opinion, but was merely remarking with what facility the Northern people in his estimation will change their character by our simply separating from them and going South. In the one case, they are
                inaccessible to argument, to the dictates of justice, reason or common sense, and in the other, at once <PageNumber num={199} /> illuminated as with a flood of light, they come to a precipitation of our just relations. It was in that point of view that I was referring to the gentleman.
              </p>
              <div className="speaker" id="sp991"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">
                I must be allowed a moment's explanation, for the gentleman evidently misunderstood my argument, and it is but justice to him as well as myself, that I should correct him. My statement was, that the prejudices of the Northern people upon the subject of slavery, prejudices which I believe existed in the minds of the great masses of the Northern people, made them both advocates and
                witnesses in reference to the facts of slavery; that there were no journals and no orators whom they now heard or whom they would hear, that would correct their opinions and views upon the subject of slavery. But that in reference to their own interest, whether there should be war upon the border, or whether there should be peace between the North and South, they would certainly have
                information from their own journals; and my position was, that even their journals would take conservative ground towards the Southern people, although not pro-slavery ground.
              </p>
              <div className="speaker" id="sp992"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                Well, sir, in the present condition of things, what constitutes the difficulty about their perceiving what is justice to us ; and how is that difficulty to be removed by the fact of our going South? I am at a loss to understand how that will have any influence upon the question, yet the gentleman evidently thinks that in some mysterious way, if we go South we shall get justice from
                those who are now inaccessible to all appeals for justice.
              </p>
              <div className="speaker" id="sp993"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">We shall then have our own Government.</p>
              <div className="speaker" id="sp994"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                Yes, sir, we will have our own Government, but that will not govern fanatics, who, if I understand what fanaticism is, act upon the impulses of blind rage without regard to any considerations of prudence or government either. That is the history of Exeter Hall. It does not stop to inquire whether its conscience ought to be troubled about slavery or not. It does not stop to inquire
                about any thing, but rushes on with reckless violence, to its fanatical ends. Sir, Exeter Hall has been the fountain head from which have come all the streams of fanaticism in this country. So far as anti-slavery, so far as Black Republicanism is a fanatical element at all, it is a direct flow from Exeter Hall.
              </p>
              <p className="p-in-sp">
                But, sir, in looking at the condition of the public mind in regard to the probability of our making any bargain, no observer can fail to take notice of the fact that the question of slavery has been made the tool of politicians North and South. No one can fail to take cognizance of the fact that it has been used by gambling politicians, of both sections,
                <PageNumber num={200} /> as a counter in the miserable game of politics. No one can doubt it; it is a fact that stands out in the history of the country, and when the responsibility comes to be measured out in the last day of great accounting there will be a fearful reckoning for the politicians of both the North and the South, for the manner in which
                they have stirred up the worst passions of our people, North and South, for the purpose of gratifying their insane desires for office and for plunder.
              </p>
              <p className="p-in-sp">
                It is true; we cannot deny it. Aye, sir, and another thing is true as has been avowed by some of the politicians of our sister States, which have seceded. It has been avowed in their Conventions that the slavery question had no interest for them, that their quarrel was of thirty years standing, and upon another ground; and that as far as they had engaged in the slavery agitation it
                was a mere pretext of assault, with a view of overturning, by our sympathy and our assistance, institutions which were distasteful to them upon wholly independent grounds. Sir, it suggests disagreeable reflections in looking back to the history of the positions the South has taken in regard to the slavery question when we are compelled to ask whether those who seemed to be
                co-operating with us to secure the peace and harmony of the country and the rights of the South, have not rather been engaged in exaggerating difficulties and inflaming passions than fairly endeavoring to settle and heal the strife? It is a most melancholy subject for reflection when we come to think of their co-operation with us in the past.
              </p>
              <p className="p-in-sp">But, sir, although it may be the opinion of the gentleman from Albemarle [<span className="persName">Mr. HOLCOMBE</span>]
                , and those who belong to the particular school of politics that claims him as among its brightest ornaments, that there can be no contract between us and the people of the North, such has not been the opinion of Virginia, such is not now her opinion, for her General Assembly last winter, by a large and overwhelming vote declared in favor of inaugurating a new contract of
                constitutional amendments between the people of Virginia and the people of the North. Aye, sir, and not only that, but they laid down a chart of Constitutional amendments, as would in their judgment, be satisfactory to the people of Virginia. And when the election was held for members of this body, if I am correctly informed, a very large majority of the members, now upon this floor,
                declared to their constituents when candidates for election, that they would be satisfied with the adoption of the plan suggested. Then, sir, it is not true now, that it is the voice of Virginia that no bargain ought to be made and that no bargain can be made with the North. Sir, I cannot and I will not believe that we cannot bargain with these people. On the contrary, I am strongly
                convinced <PageNumber num={201} /> that in both the North and the South, the great masses of the people, leaving out the politicians and fanatics of both sections, have this day an earnest yearning for each other, and for peace and union with each other. I verily believe that to be the sentiment of the people of this country, both North and South; and,
                for one, I will never be satisfied to see this government, which has shielded and protected us so long, overthrown. I will never consent to any plan of dismemberment of it, which does not involve, as a necessary condition, that there shall be a direct appeal to the people, North and South, to know of each other, face to face, in the exercise of the highest franchise of a free people,
                at the polls, whether it has come to this, that a people united by so many historic associations, by so many ties of substantial interest, by so many bright and glorious hopes, must part? whether it has come to be understood by the people of the North and the South that the hour of necessary and angry separation has in fact come? Until it has been so declared by the people themselves
                at the polls, I will never, no never consent to give up this blessed government of ours.
              </p>
              <p className="p-in-sp">
                But we are told that if we make any bargain with them, it must be a kind of contract new to this country; it must be a bargain in which, by some mysterious process, we are to correct a great mistake that has been made. In old times, we used to be in a majority, and as the majority, we had certain substantial benefits, certain very comfortable enjoyments. But, in the process of time,
                in the progress of affairs; in a course of business of which I do not complain, in the course of the tide of emigration which I do not object to, the people of the North, of the free States, have secured a majority. I do not envy them the means, I do not envy them the employments, or the prosperity by which they have come to be the majority. I prefer the position of the South,
                industrially and socially, both, to the position of the North. But it has so happened that the South has come to be in a minority; and now we are to undergo some curious transformation under what is called a contract or guaranty of power by which this minority is to be converted into a working majority of this Government. "Let us have a guaranty of power." I have heard of guaranties
                of power. There is no doubt that it is admissible as a principle in government that as a means of protection and defence there should be, within certain limits, guaranties of power in the hands of minorities, or of interests, likely to be injuriously affected. There is no doubt of that. We have an instance of it in the propositions of the Peace Conference, and we have an instance of
                it in the propositions reported to this Convention by the Committee on Federal Relations. I recognize it as a proper principle in that case.
              </p>
              <PageNumber num={202} />
              <p className="p-in-sp">
                But I protest that it never can be a true, that it never can be a wise policy to undertake to place the government of this country or of any State in this country, or of any community in any State, in the administrative hands of a minority. It cannot be right, sir; we are as yet a popular government. I have never been suspected, I believe, of any overweening admiration of what are
                called the popular features of our government. I have never been a Democrat in any party sense, but, I am a republican, thorough-going; and, I believe, that our system of government has but one foundation on which it can rest safely, and that is the virtue and intelligence of the people; that it has but one administrative body that it can trust with safety, and that is the body of
                the majority of the people. To what extent, or in what form the people shall participate in the administration of their affairs is a question of wise expediency, depending upon circumstances that admit of no general discussion. But, sir, the principle underlies all our institutions, that it is a government of the majority of the people, and, whenever it is understood that it is to
                rest upon any other foundation, it will topple and fall to pieces from its own weight. Sir, we build upon that foundation. Other foundation has not been laid in this country. It is founded upon confidence that our people have sense enough to make a bargain, and integrity enough to keep a bargain. The Constitution recognizes the propriety of a distribution of power among various
                departments of administration, for the purpose of securing the matured and deliberate judgment of the people. But, sir, it never has been, and never will be, admitted to be sound, that the governmental administration of this country should be confided to the hands of a minority of the people.
              </p>
              <p className="p-in-sp">I do not know that I understand exactly the principle which is sought to be established by the gentleman from Albemarle [<span className="persName">Mr. Ho</span>LcomBE]
                . I understood that the advocates of this principle of the guarantee of power relied upon it entirely as a defensive measure, and that it was never proposed by them that it should be any thing more than a veto for the protection of the minority section; but the gentleman from Albemarle, as I understood him, developed the idea in a new form; he declared that the South must have
                guaranteed and placed in her own hands the power of expansion—that the minority must, by some means as yet unexplained, have the power to acquire territory, and to expand the institutions of the South into it. I do not understand how that is to be accomplished. It seems to be a departure from what I always understood to be the principles of the guarantee of power.
              </p>
              <p className="p-in-sp">
                The true principle, as it seems to me, is that while the working of the <PageNumber num={203} /> Government shall be entrusted to the majority in all of its ordinary administration, there should be located at proper points in the system checks or vetoes by which the minority should be enabled to secure itself against organic changes in prejudice of its
                rights.
              </p>
              <p className="p-in-sp">
                This principle is recognized in the plan of the Peace Conference and in the report of the Committee, in the section which provides for the acquisition of new territory. There is a guarantee of power which secures to the South that when we make a new bargain, when we bring in a new subject of Government, each party will have a veto upon the other, the minority section upon the
                majority section, and thus each be able to secure for itself fair and equal terms. To that extent I favor it. Beyond that I think it mischievous.
              </p>
              <p className="p-in-sp">The opinion of Virginia has been declared upon this subject very recently and very distinctly. By her General Assembly and by the votes of her people in the recent election it has been declared beyond dispute that the Crittenden propositions, as amended by the General Assembly, would be satisfactory to the people of Virginia.</p>
              <p className="p-in-sp">Here we have a declaration, not only that Virginia is willing to bargain with the people of the North, but that she does not regard as essential to the contract that there shall be any resort to the extraordinary expedients of guaranties of power, and as have been suggested by the gentleman from Albemarle [<span className="persName">Mr. HOLCOMBE</span>] .</p>
              <p className="p-in-sp">It being the sentiment of Virginia then that we shall bargain for amendments of the Constitution upon the old principle of trusting to the plighted faith of the American people, let us inquire where we now stand in the effort made by Virginia for the settlement of these difficulties.</p>
              <p className="p-in-sp">
                Sir, I referred, the other day, to the proud attitude occupied by Virginia at this time, in her effort to settle these distracting and disturbing questions; in her effort to rise above the passions and the excitement of the moment, and to address herself, in a spirit of calm, patriotic statesmanship, to the work of pacification, and the re-construction of this government. It is a
                position, sir, in which I am proud to think of her, proud to speak of her, proud to serve her. She undertook to settle this matter, and in her effort for that purpose, made through her Legislature, she declared that, believing that unless this controvers), could be settled in the spirit in which the Constitution was formed, the dissolution of this Union was inevitable, and wishing
                earnestly to bring about such a settlement, Virginia desired a conference of States by commissioners, to meet in the city of Washington. I do not know in what spirit they came there, but I know that they were invited to come in the spirit in <PageNumber num={204} /> which the Constitution was formed. I take it that they supposed, in accepting the
                invitation, that they were coming in that spirit.
              </p>
              <p className="p-in-sp">
                That Conference assembled. I don't know much about its composition, but I undertake to say, that, looking to the character of the delegation sent from Virginia, and what I have heard of the delegations from other States, I am satisfied that this was one of the most august assemblages ever convened in this country. It represented States North and States South, two Northern to one
                Southern; and, what is worthy of note about it, is that all the Northern Representatives were appointed in States under the dominion of the Republican party. They were representative men of the Republican party, brought together in that Conference; and I take it that they were as true a representative body as could be gotten from the politicians of the party. I believe, sir, that if
                we were to go down to the depths below the upper crust of the political leaders, and appeal to the people to send true representatives of their will and sentiments, we should get a better and a more favorable body. But, sir, these representatives came, such as they were; they were in session for a fortnight or three weeks; they gave us the result of their labors and suggested a plan
                of constitutional amendments, to which I propose to ask the attention of the Committee, not because it is likely to be presented here—yes, I am reminded that it has been presented by the gentleman from Harrison and is now under consideration—but, sir, I propose to ask the consideration of the Committee to that plan in another point of view.
              </p>
              <p className="p-in-sp">
                It has been assailed in several ways. In the first place it is said that it contains nothing valuable. In the next place it is assailed by saying that what it contains of value has been fraudulently inserted under double meaning language for the purpose of deceiving us. It is also assailed on the ground that whatever may be its merits, it has failed; and I have heard of persons who
                have thought it necessary to change their politics because they thought the Peace Conference another failure.
              </p>
              <p className="p-in-sp">
                Sir, has it failed? In what has it failed? It has been said to have failed because the Congress of the United States refused to refer it to the action of the States. Ah, is that the failure? Why, who expected them to do any thing else? With a Congress in which, by the defection that I have already mentioned on the part of the Southern States, the republican party is largely in the
                ascendant, and with but three days in which to consider the subject and act upon it, what could you expect? Sir, I never expected them to give a two-thirds vote in favor of any plan of pacification, especially in so short a time, and <PageNumber num={205} /> if I had expected it, I would not have been much disappointed when I looked at the circumstances
                which surrounded them.
              </p>
              <p className="p-in-sp">
                Sir, it has been frequently said, and said with great truth, that there is a kinship between extremes of opinion and of party. I have already referred to the rejoicing in Charleston over the election of Lincoln—answering back to the rejoicing in the North over the same result. We all remember the fact that in the South Carolina Convention the fugitive slave law was denounced as being
                unconstitutional, as it has been denounced in the North for years past. But when pacification becomes the order of the day, when the plan of pacification, inaugurated by Virginia, is, by her authority, communicated to the Congress of the United States, we see there the kinship of extremes; we see, when it was propounded in the Senate of the United States, that it was resisted by the
                combined efforts of William H. Seward and R. M. T. Hunter—both of them co-operating in their resistance and refusing to submit it to the States. And, sir, one other Senator, Mr. Mason, co-operated in this resistance; and then we had brought on the wings of the wind—faster than the wind—we had it brought on the precipitating telegraph, that the Senators from Virginia and the Black
                Republican Senators from Illinois and New York had refused to let us have a chance at the "Peace Conference" settlement. The denunciations in regard to the conduct of Mr. Seward and Mr. Trumbull would have been more impressive if they had been dealt out with a little more impartiality to Mr. Hunter and Mr. Mason, who concurred in the vote. I think we will hardly make the refusal to
                submit the plea to us, a cause of fresh complaint against the North until we have settled with our own Senators. I think, as a necessary preliminary, we should settle our little matters at home before we make the action of the Senate upon this proposition the foundation of assault upon Black Republican Senators.
              </p>
              <p className="p-in-sp">
                But I was considering whether this "Peace Conference" proposition failed. I say it has not failed. It has never yet had a fair chance; and I verily believe, and I expressed that belief in a resolution which I have offered for the adoption of the Committee—that if this Convention of Virginia had accepted the recommendation of the "Peace Conference," and sent it to the country with our
                endorsement upon it as a satisfactory adjustment—before this time you would have had the Union men of the country, North and South, East and West, rallying with one voice in favor of it as the great measure of pacification, which was to restore harmony and concord throughout the land. I believe this, sir; but in that opinion I am overruled by men in whose judgment
                <PageNumber num={206} /> I have high confidence. I desire to carry on the work of pacification, and, so far as any action of mine is concerned, I would promote any policy looking to a union of all Union men for that great work. I have concurred in the report of the majority of the Committee which I thought unnecessary, but which I think, as it is made,
                is a decided improvement upon the Peace Conference proposition in this, that it stops the mouths of objectors who have been cavilling about this "Peace Conference" proposition, and who have been exhibiting an ingenuity of criticism which seemed to me wholly unnecessary, and which, I think, does not tend towards the great end either of substantial right or of harmony.
              </p>
              <p className="p-in-sp">
                Let us look at that proposition. I maintain that it is an offer of justice to the South, and that having been obtained from a representative body, such as I have described, we might fairly expect and confidently hope that before any fair representative body of the Republican party of the country, we might be able to obtain its equivalent. There is one circumstance which I ought to
                mention here in confirmation of what I have already said in regard to the dismemberment of the Government by the withdrawal of the Southern States. We will feel the effect of that withdrawal very deeply in such an event as I have alluded to, because, in a proposition to submit this matter to the ratification of the States, we would be materially helped by the voice of these sister
                States. In any constitutional amendment hereafter to be made, and in the count of three-fourths necessary to the ratification of any amendment we shall sorely miss the co-operative aid of these seven seceding States. Let us now look at this Peace Conference proposition.
              </p>
              <p className="p-in-sp">I undertake to say in regard to it, that there is no grievance which has been suffered already by the South in the past, and none which she is to apprehend on the subject of slavery, and which is susceptible of being corrected by a constitutional provision that is not covered by this Peace Conference proposition.</p>
              <p className="p-in-sp">Sir, I repeat the assertion, and I know it is a broad one, that there is no injury, no grievance, no assault which the South has incurred in the past on the subject of slavery, or which she apprehends in the future, that is not completely covered and provided against by this proposition of the Peace Conference.</p>
              <p className="p-in-sp">
                Let us look at the provision of this proposition. In the first place, I maintain that it is not only true, that this proposition answers all our grievances and all our apprehensions, but that it answers them better, more thoroughly and conclusively than the Crittenden plan, which <PageNumber num={207} /> was propounded by the Legislature of Virginia,
                and which has been approved by the people of the State. I undertake to say, therefore, that although we do not get the Crittenden proposition from the Peace Conference, we get that which is better and stronger, and more efficacious for the South, ten times over, and I think I shall be able to demonstrate that not only is it better than the Crittenden proposition, but that it is
                sufficient for all the purposes of our complete protection. Let us see. The first section of the Crittenden proposition and the first section of the Peace Conference plan refer altogether to the Territorial question. In regard to this Territorial question, I must be allowed to say that I have never been able to understand upon what principle of the relative value of things it has
                loomed up into the importance which seems to be attached to it in all parts of the country. I have not been able to see upon what principle it is that the people of this mighty nation, this mighty State of States, have been wrought up to the extreme of passion, sectional strife, bitterness and hate about this Territorial question. Why? Is it not a matter perfectly familiar to every
                member of this Convention, that in regard to the present Territories of the United States, we have not one foot of Territory that is fit for the employment of slave labor? Is it not a matter perfectly notorious and known to every beginner in Geography and politics, that we have not any Territory of the United States this day into which any sane man, as an investment of his money, as
                an employment of his labor, would carry slaves for private advantage? It is true that in the Territory of New Mexico the officers of the army stationed there have carried along with them as members of their families their body servants and their house servants; and owing to this fact there are, perhaps, fifteen to twenty, and perhaps twenty-five slaves in that Territory. Although
                that Territory has been opened for settlement without any prohibition for the last ten years under a Territorial Government, yet there have never been found more than twenty-five slaves there in all that time. I believe it is conceded by men of all shades of political opinion that that territory is wholly unfit for slave labor. I understand it is thought by some that there will be
                silver and quicksilver mining carried on in some portions of that territory in which slaves might possibly be employed to advantage; but, I suppose, in a country where Mexican peons are to be had almost for the asking, it will hardly pay to transport negroes worth a thousand or fifteen hundred dollars for the purpose of working their silver or quicksilver mines. That is not the sort
                of labor for which slaves are adapted ; and I believe it is the unanimous judgment of the country, so far as New <PageNumber num={208} /> Mexico is concerned, slavery can never go there. They have had for two or three years past in that territory a code protecting slavery, and authorizing it to be brought there, yet no one has thought of carrying it
                there. That is partly owing to the fact, there is an unsuitableness in the Territory for that sort of labor; but it is also owing to the other important fact, that we have no surplus of slaves to spare to settle a new territory, even if we have the territory to settle. What is all this clamor in the South about the re-opening of the African slave trade? What means the fact that
                African slavers have landed cargoes of slaves in Southern ports and sold them in open market? Has the Government been able to bring to justice any offenders against the laws? What means it that mobs in cities have broken down every barrier of law and have wrested from the officers persons in custody charged with being concerned in the African slave trade? What means all this? It
                means neither more nor less than this—and the fact is admitted and apparent in the South—that there is a deficiency of slave labor in the South; that their great, pressing necessity at this day, is the want of slaves enough to cultivate their great staple crops. We feel this in the drain made upon us; and the great demand for slaves is constantly striking off the list of
                slaveholders, one after another, the small slave-holder, whose necessity or cupidity is stronger than his appreciation of the institution of slavery. You cannot feel in Eastern Virginia, in the heart of the slaveholding region, that drain as much as we do in the county from whence I came, which is a slave-holding, slave-buying community, and where they are anxious to purchase and
                obtain slaves, and to extend the institution in their midst. We feel sensibly this check upon our wishes, growing out of the fact that the staple crops of the South demand more labor. This drain from us is constantly going on; and it is owing to that fact, more than to any other, I presume, that the distinguished gentleman from Albemarle
                [<span className="persName">Mr. HOLCOMBE</span>] anticipates that Missouri, Maryland and Kentucky will eventually become free States.</p>
              <p className="p-in-sp">
                I take it that these two propositions are incontrovertible, that we have not the territory to take the slaves into; and, if we had, that we have not the negroes to take there. If that is true, what are we to think of shaking to the foundations this Government of ours, with all its great proprietary and industrial interests? What are we to think of the infatuation, madness and
                fanaticism that are seeking to overthrow such a Government as this, and to imperil and destroy the rights and interests of millions of freemen, North and South, upon an issue like this? I suppose if the question had never been raised, if the South had <PageNumber num={209} /> never made the point, there is hardly a man in the South now who would not
                say, that it is a point not worth making. If we had never asserted the right; if we never had locked arms, as it were, upon the argument of the right, I suppose everybody would say it was more for peace, more for good sense, more for correct statesmanship, more for the interests of all concerned, that this miserable, poor territory should be settled by whoever choose to go and live
                in that remote region.
              </p>
              <p className="p-in-sp">
                But the point has been made; and what is it? It is that the South has a right to go into all the territories with her property, and have protection for that property. What is the other side? It is that the North has a right to prohibit them from going there with their property; that she has the right, and henceforth having the power, she intends to exercise both the right and the
                power to exclude the South from the territories. The South claims them all for her civilization and her institutions; the North claims them all for her civilization and her institutions, and thus we have, what seems to me, to be a very absurd quarrel between fifteen slave States and eighteen free States, the extreme pretensions being set up on the part of each to exclude the other
                altogether from any participation in the common territories. It seems to me that that is about the state of the case. How was this question settled by our fathers years ago? It was done upon principles, I maintain, of common sense. Here is a man, for instance, in the county of Augusta, who owns, perhaps, half a dozen negroes and a tract of land worth $50 per acre. He thinks his land
                is too high priced, and that it would be better for him to sell out there and go to some of the territories in the West, where he could enter land and get a large domain, upon which he could raise his negroes and provide for his family. He accordingly removes to a new territory. A citizen of Pennsylvania, who has a large family of sons, but no slaves, and who is the owner of a
                valuable and expensive farm, worth $100 per acre, also desires to emigrate to some new territory of the West, where he can buy with the proceeds arising from the sale of his farm, a large tract of cheap land, upon which he can raise his family and accustom them to habits of industry. He has no fanatical feeling on the subject of slavery, but having, at one time, been among
                slaveholders, he entertains the idea, with no disposition, however, to interfere with any one else, that the interests of his sons would be promoted by going with them into a territory where there were no slaves. He thinks that the war waged against slavery is wrong, but he has the idea that the prevalence of slave labor in a community renders manual labor less respectable, and,
                therefore, if he should raise his sons in a slaveholding community <PageNumber num={210} /> that he will do them an injury. He starts for the West then, about the same time with the man from Augusta, but having less property to carry along, he gets out there first and enters his land. By and by, the man from Augusta, with his whole brood of young
                negroes, comes along, and, perhaps, to settle side by side with this Pennsylvania farmer.
              </p>
              <p className="p-in-sp">
                Well, now will any one deny, will any slaveholder deny, that it is as much a right of the Pennsylvania man to object as a matter of interest for his children to raise them in the company and in the immediate observation of that brood of young negroes from Virginia, as it is the right of this Virginian to move his negroes there and enjoy the benefits of a new country? And yet both
                cannot be done. The only practical and feasible way is for them to divide the territory and say to one kind of civilization, "Here, this is suitable for you and yours, settle here," and to another kind of civilization, "This is suitable for you and yours, settle here, and let there be no strife." Is not that the suggestion of common sense? Is not that the suggestion of common
                prudence? of common right?
              </p>
              <p className="p-in-sp">
                Our fathers in 1820, when this question was adjusted, settled it upon principles; they undertook to divide the Territory by the line of 36 deg. 30 min., and I think they did right. But it is said it was unconstitutional, and that the Supreme Court have decided it to be so. I am not so sure about that, but I am disposed to acquiesce in whatever they did decide. I have no disposition
                to quarrel with them, especially upon a subject of that sort. But I should like to know if the signs of the times are not now a little propitious for returning to the ancient principles of our fathers upon that subject? The first plan of adjustment which has been suggested, has as its first principle, as its first term of reconciliation, the restoration of the ancient Missouri
                Compromise line of peace. The other plan of adjustment includes the same provision—and I take it as a happy omen, that all the plans which have been proposed on this subject, look to the restoration of this same great line of peace, 36 deg. 30 min. If we go back to it, we go back forty years. Does it not give us reason to be encouraged? does it not give us reason to hope that we may
                be able to get back to the spirit that animated its authors? that we may yet be able to retrace the forty years that we have been wandering—the forty years of strife and bickering, the forty years of sectional jarring and discord, and that we may go back to the days of Monroe, to the era of good feeling, and take up anew, not merely the language of compromise and adjustment, but take
                in the ancient Virginia school, the spirit of compromise, the <PageNumber num={211} /> spirit of settlement, the spirit characterized by our General Assembly as that in which the Constitution was formed?
                [Here <span className="persName">Mr. BALDWIN</span> gave way to a motion that the Committee rise.] Without concluding, the committee rose, and</p>
              <p>The Convention adjourned.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v2.2.6">
            <PageNumber num={212} />
            <h2><span className="headingNumber">1.6. </span><span className="head">THIRTY-THIRD DAY</span></h2>
            <div className="dateline">Saturday, <span className="date">March 23</span></div>
            <p>The Convention met at half past 10 o'clock, A.M. Prayer by the Rev. <span className="persName">Mr. VAN DYKE</span>, of Brooklyn, N.Y.</p>
            <div className="section" id="vsc1965.v2.2.6.1">
              <h3><span className="headingNumber">1.6.1. </span><span className="head">HOUR OF MEETING</span></h3>
              <div className="speaker" id="sp995"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">With the permission of the gentleman from Marion, who is entitled to the floor, on the suggestion of several gentlemen I desire to offer a resolution which has for its object the acceleration somewhat of the movement of the business of this Convention. I ask for the adoption of the following resolution, changing the standing hour of meeting.</p>
              <p>Resolved, That on and after Monday next, until further ordered, this Convention shall be called to order at 10 o'clock, A. M., that at half past ten it shall resolve itself into Committee of the Whole upon the reports of the Committee on Federal Relations, and at 2 o'clock the said Committee of the Whole shall take a recess until four, when it shall resume its session.</p>
              <div className="speaker" id="sp996"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">That is a resolution which ought not to be debated. I call the previous question upon it.</p>
              <p>The previous question was ordered, and the main question ordered to be put.</p>
              <p>The resolution was then adopted.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.6.2">
              <h3><span className="headingNumber">1.6.2. </span><span className="head">THE QUESTION OF TAXATION</span></h3>
              <p>The Convention then resumed the consideration of the resolution offered by <span className="persName">Mr. WILLEY</span> of Monongalia.</p>
              <div className="speaker" id="sp997"><span className="persName">Mr. E. B. HALL</span>, of Marion—</div>
              <p className="p-in-sp">On yesterday morning I attempted to offer some considerations to the Convention upon the question presented in the resolutions submitted by the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>]
                . I remarked that I had been pained to see what I could not fail to consider an attempt made to hold this question in suspense over us; that no action was to be taken upon this question, but that it was to be left open with a view, as suggested upon this floor and elsewhere, to be used as a lever to influence our action upon this floor in reference to other matters before this body.
                I wish, in response to the remarks of the gentleman from Lynchburg
                [<span className="persName">Mr. SLAUGHTER</span>]
                , made in a spirit to which I cordially respond, to notice one or two of the suggestions presented by him as a reason why we <PageNumber num={213} /> should not now agitate this question before this body. He tells us it is important that we should stand shoulder to shoulder, that we should be united, that it shall not go to the world that there is
                dissension in this body or in the State of Virginia.
              </p>
              <p className="p-in-sp">
                Sir, I recognize and realize the force of that suggestion, but I ask the gentleman now, how that is to be done? I ask him when one portion of the people of this Commonwealth are borne down by a system of taxation which they deem to be unjust and improper, if he can expect merely by stilling the voice of remonstrance in this body, he can thus succeed in stilling that voice throughout
                this land? No sir. You may control that voice here, but you cannot control it outside, unless you give the people outside some reason or some foundation for a reason to believe that they should remain silent.
              </p>
              <p className="p-in-sp">
                The gentleman suggested that when once we had disposed of the more important questions before us, the questions growing out of our federal relations—if we are successful in settling these matters, we will be so jubilant that there will be no necessity for agitation, but we shall have cheerfully yielded to us all we want upon the subject of taxation. Sir, if gentlemen intend to give
                us that, why not do it now? They certainly will not give it to us unless they recognize that it is our right to have it. I venture to say that they will not be so jubilant as to accord to us privileges it is not our right to ask. But if they propose to give it to us at all, why not now?
              </p>
              <p className="p-in-sp">
                Another gentleman suggests that if we dissolve our connexion with the Federal Government they will be ready to give us all we want upon the question of taxation. In either event the promise only applies to some future time. Now, sir, I ask and repeat the question, if it is true, that if a large portion of the people of this Commonwealth are laboring under an unjust and oppressive
                system of taxation, when there is not a man in this body whom I have seen that does not admit the justice and propriety of the change we propose, what reason is there why the change should not be made now? But, it is urged again that the consideration of this question should be postponed to an adjourned session of the Convention.
              </p>
              <p className="p-in-sp">Well, sir, right upon that, the gentleman from Preston [<span className="persName">Mr. BROWN</span>] , proposed a resolution to appoint a Committee upon the various questions affecting our State, to report to an adjourned meeting; and the party who makes the suggestion that we could take up the question at that time, says that the resolution of the gentleman from Preston would raise the whole question, and, therefore, ought not to be considered.</p>
              <p className="p-in-sp">I understand, however, that the gentleman who made that suggestion, <PageNumber num={214} /> is not now in his seat. But I was merely referring to the matter as indicative of the feeling which prevails on that side of the House.</p>
              <div className="speaker" id="sp998"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The resolution of the gentleman from Preston [<span className="persName">Mr. BROWN</span>] , is not before the House.</p>
              <div className="speaker" id="sp999"><span className="persName">Mr. HALL</span>—</div>
              <p className="p-in-sp">I am aware of that, sir, and I do not intend to discuss it.</p>
              <p className="p-in-sp">Sir, I deem it very important that a Committee should be appointed now, upon the various questions relating to our State interests. This course does not contemplate any final action upon the subject which may be considered by that committee. We know that there is a strong desire in every part of the State, as remarked by my colleague [<span className="persName">Mr. HAYMOND</span>]
                , that this question of taxation should be settled, because it is one that is weighing heavily upon our people. There are other questions to which my colleague referred, in reference to which it is desirable that action should be taken. If that be so, why not appoint a Committee now, to whom these questions should be referred ; and if we are not ready to pass upon them now, the
                Committee can, during the recess, consider them, and 'come back with their minds made up as to the policy they may suggest. True the members of that Committee, if it should be raised, may not have an opportunity to consider the questions together; but they can do so each for himself, and when they come to act in union, they will have little difficulty in coming to a decision inasmuch
                as they have fully considered the subjects upon which they are to report.
              </p>
              <p className="p-in-sp">
                There seems, sir, to be an indisposition to consider these questions at all. I do not think it will be denied that this indisposition proceeds solely from an objection to change the present system of taxation. Suppose this committee is appointed, what will be the effect of it? It will merely go to show that a disposition exists to consider these questions in the future. That is
                asking as little as a people could ask. What objection can there be to take this course? There can be none, sir, and we shall regard a refusal to appoint this committee as equivalent to discarding our claims to be heard upon a matter of vital interest to us. I agree, sir, that we ought to dispose of the other matter now under consideration first. I came here instructed to go in for a
                settlement of all federal questions before proceeding to act upon other subjects.<span className="note" id="Note28"><span className="noteLabel">1</span>Mr. Hall corrected this statement in the Proceedings of March 25, page 249, and again in the Proceedings of March 26, pages 341-342.</span> I told my people that I should first see that these subjects were settled before any merely State
                affairs should be taken up, and they desired me to express that purpose. They maintain their rights to equality within as well as without the State, with everybody in this Commonwealth. If this Convention will but indicate a disposition to consider the subjects referred to in the resolutions <PageNumber num={215} /> of the gentleman from Monongalia,
                harmony will be promoted. Our people will be prepared to stand by the interests of all sections, and to maintain their rights. But if this question is left unconsidered, at least to the extent of appointing a committee, you will engender ill feeling towards the institution, which will be held as the source of all our difficulties, and of the unjust and unequal burthens now complained
                of. In this connection let me remark, that it seems to be supposed—in fact, it is said—that there are too many freesoilers in the Western part of this State. I deny it, sir. The people of that section are as loyal to the institution of slavery as any in the Commonwealth, and they are true, sir, without any motive save that of principle. I maintain, sir, that we are the best
                pro-slavery men in the State of Virginia. I believe that, and I want that this state of things shall not be changed. I trust, sir, that there will be some speedy and satisfactory settlement of our present difficulties, so that we may have the benefits of this institution of slavery. We desire an opportunity to own slaves, and that can be afforded us by giving it due protection
                amongst us. We have suffered much more than this part of the State for want of proper protection for slavery, and we are looking forward to the time when we shall be protected in the enjoyment of that institution. I want this Convention to do nothing that will alienate this people from that institution, and, I fear, if this question is wholly discarded, as seems to be the disposition
                here, that ill-feeling in regard to it will grow up. It is a source of considerable expense to the people of all sections, and it should certainly be made subject to an equal proportion of these expenses. When any institution is placed upon a people to oppress them, the result must be to excite hostility to it.
              </p>
              <p className="p-in-sp">I don't desire, sir, to enter into any argument upon this question now. The gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>]
                , who submitted the resolutions now under consideration, was anxious to be afforded an opportunity to address the Convention upon them. Since that opportunity has not been afforded, it is not for me to undertake to speak at any length upon them. I have deemed it necessary to say thus much, and I will now conclude with the expression of a hope that it will be the pleasure of the
                Convention to appoint the committee, that they may consider this question fully, and report to the adjourned meeting of this body.<span className="note" id="Note29"><span className="noteLabel">2</span>See note 1, above.</span>
              </p>
              <div className="speaker" id="sp1000"><span className="persName">Mr. BOISSEAU</span>, of Dinwiddie—</div>
              <p className="p-in-sp">I ask leave, sir, in obedience to the request of the people of my county, to lay before the Convention the proceedings of a meeting held in my county.</p>
              <PageNumber num={216} />
              <div className="speaker" id="sp1001"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">It can only be done by general consent. The time for the Convention to go into Committee of the Whole has arrived.</p>
              <p>Leave was given, whereupon <span className="persName">Mr. BOISSEAU</span> submitted the proceedings referred to as follows :</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>PUBLIC MEETING IN BEHALF OF SOUTHERN RIGHTS</p>
                  <p>At a meeting of the people, held at Dinwiddie Courthouse on Monday, 18th day of March, 1861, Dr. Jno. E. Harris was called to the Chair, and Wm. Doyle, and Captain John C. Griffin were appointed Secretaries.</p>
                  <p>Capt. John C. Griffin being loudly called for, appeared in the meeting amid enthusiastic cheers, and made an eloquent and patriotic speech. He said he had always been an old-line Whig, but he called upon the people to bury the tomahawk of parties, and rally like true, chivalrous sons of Virginia, under the banner of the Southern Confederacy.</p>
                  <p>Capt. William A. Adams then offered the following preamble and resolutions which were greeted with cheers beyond description :</p>
                  <p>Whereas, Our neighboring counties and cities have held meetings declarative of their feelings in the present dangerous affairs of the country, we, the people of Dinwiddie, deem it our duty to express, in no uncertain manner, our sentiments on the momentous issue,</p>
                  <p>Resolved, 1st. The old Union being irreparably dissolved, there is no option left us save to unite our destinies with our sister Southern States, or to remain a helpless appendage to the Northern Confederacy —the latter alternative being utterly repugnant to all true Southern men.</p>
                  <p>2d. That all honorable means of adjustment having been exhausted, we, the people of Dinwiddie, are in favor of immediate secession.</p>
                  <p>3d. We consider Lincoln's inaugural as an open declaration of war upon the South, and no mitigation or credit shall be given for the evacuation of Fort Sumter, that being a military necessity.</p>
                  <p>
                    4th. We regret that one so conspicuous among the disturbers of our peace and quiet as the Lieutenant General, should ever have been born within the limits of our county; but we will never be reduced to the dumb submission of the oxen and sheep that daily trample upon the site of the house in which he was born, although Gen. Winfield Scott himself shall command the armies of our
                    enemies.
                  </p>
                  <p>5th. That the thanks of this county are due to James Boisseau for the honorable and faithful manner in which he has represented us <PageNumber num={217} /> in the Convention, and that we request him to present the foregoing resolutions to the said Convention.</p>
                  <p>Captain Adams delivered an address in defence of Southern Rights that would have done honor to the greatest of statesmen.</p>
                  <p>The preamble and resolutions were seconded by Dr. R. E. Lewis, and his remarks were chaste, beautiful and eloquent.</p>
                  <p>Mr. Wm. B. Hamlin, of the city of Petersburg, was then called upon, who made an eloquent speech. He brought tidings of great joy to the meeting, that the Cockade of Virginia was redeemed.</p>
                  <p>Captain Wm. Trumbull was then called for, who made a few statesmanlike remarks, endorsing the resolutions.</p>
                  <p>Loud calls were then made for W. T. Scott, who appeared in the meeting and made a strong Southern speech.</p>
                  <p>The preamble and resolutions were unanimously passed, amid cheers and great enthusiasm.</p>
                  <p>On motion, the Richmond Enquirer, Examiner and Whig, Petersburg Intelligencer and Express be requested to publish the proceedings of this meeting.</p>
                  <p>On motion the meeting adjourned.</p>
                  <p>JOHN E. HARRIS, Chairman.</p>
                  <p>W. F. DOYLE, J. C. GRIFFIN,</p>
                  <p>Secretaries</p>
                </div>
              </div>
              <div className="speaker" id="sp1002"><span className="persName">Mr. BOISSEAU</span>—</div>
              <p className="p-in-sp">
                I will state, sir, that at the time of the election, there was a very large majority in the county of Dinwiddie against secession.<span className="note" id="Note30"><span className="noteLabel">3</span>Mr. Boisseau corrected this statement in the Proceedings of March 25, pages 249-250.</span> I may say now that that county is now almost unanimous in favor of secession. The people of my county
                have sons, brothers, relations and friends in the Confederated States South—persons who have left us in the pursuit of their fortunes, and who have been taken by the hand by the people of the South. They have amassed wealth, and achieved honor and distinction among the people with whom their lot has been cast; and the people of Dinwiddie will never consent to be placed in any
                attitude of hostility to them. Their sympathies are with those whom they regard as their friends, and they have no sympathies with those whom they regard as their enemies. I move that the proceedings be referred to the Committee on Federal Relations.
              </p>
              <div className="stage it">[Agreed to.]</div>
            </div>
            <div className="section" id="vsc1965.v2.2.6.3">
              <h3><span className="headingNumber">1.6.3. </span><span className="head">PROCEEDINGS OF A PUBLIC MEETING IN GREENE COUNTY</span></h3>
              <div className="speaker" id="sp1003"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">At the risk of being troublesome to some of the members of this Convention, I wish to submit the proceedings of a <PageNumber num={218} /> meeting of the people of the county of Greene, at their last Court day, at which they adopted the following resolutions :</p>
              <p className="p-in-sp">1. Resolved, That when the people complain their rulers should listen.</p>
              <p className="p-in-sp">2. Resolved, That this crisis to us is no "artificial one." "We are all hurt."</p>
              <p className="p-in-sp">3. Resolved, That the action of the late Peace Conference offers us no relief.</p>
              <p className="p-in-sp">4. Resolved, That we, disdaining to accept the partial terms of the Franklin substitute as a satisfactory remedy to our wrongs, are still in favor of immediate secession as the best means to secure the honor and safety, not only of Virginia, but of the whole South.</p>
              <p className="p-in-sp">5. Resolved, That the threats of coercion in the recent inaugural strike no terror into the hearts of freemen, and that we are unalterably opposed to the exaction of tribute from an unwilling people.</p>
              <p className="p-in-sp">In a communication accompanying these resolutions, I am informed that in the noble county of Greene there are but four men who are at this time in favor of a union with the Northern States, or the Union as it now exists; that they are almost unanimously in favor of immediate secession.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.6.4">
              <h3><span className="headingNumber">1.6.4. </span><span className="head">TAXATION QUESTION</span></h3>
              <div className="speaker" id="sp1004"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">I desire to submit a few remarks upon the question which is now before the Convention, but as the hour has now arrived for going into Committee of the Whole, I now move that the Convention resolve itself into Committee of the Whole.</p>
              <div className="speaker" id="sp1005"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">With the consent of the gentleman from Jackson, I will send up the following resolution which I desire to offer as a substitute for the resolutions of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] .</p>
              <p>The resolution was then read by the Secretary, as follows:</p>
              <p>"Resolved, That the consideration of any reforms in the State Constitution, including the subject of taxation, should be postponed until the subject of our Federal Relations shall have been acted upon by the Convention, and then should be taken up and disposed of so far as reforms may then be deemed proper."</p>
              <p>The resolution was laid on the table.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.6.5">
              <h3><span className="headingNumber">1.6.5. </span><span className="head">PROCEEDINGS OF A PUBLIC MEETING IN SPOTSYLVANIA COUNTY</span></h3>
              <div className="speaker" id="sp1006"><span className="persName">Mr. MARYE</span>—</div>
              <p className="p-in-sp">I beg leave to submit to the Convention, with the view of having them referred to the Committee on Federal Relations, the following resolutions and proceedings of a meeting of a portion of <PageNumber num={219} /> the citizens of Spotsylvania, which I ask to have read and then referred.</p>
              <p>The proceedings were then read by the Secretary, as follows, and then referred to the Committee on Federal Relations:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>
                    At a meeting of all parties, held at Mt. Hermon Church, in the upper part of Spotsylvania, on Saturday, the 16th of March, 1861, on motion, T. T. Dillard, Esq., was called to the Chair, and F. W. Conner appointed Secretary.<span className="note" id="Note31"><span className="noteLabel">4</span>Below, the chairman's initials are T. L., and the secretary's are T. W.</span>
                  </p>
                  <p>The object of the meeting having been explained, a committee of three was appointed to present business for its action, and, after a short retirement, reported the following preamble and resolutions, which were adopted with one dissenting voice.</p>
                  <p>
                    Whereas, Being satisfied that a great revolution in public sentiment, in regard to secession, has taken place since the election of delegates to the Virginia Convention; that the Peace Congress has proved an abortion; that its recommendations are an insult and a mockery to the South ; that Republicans will not acknowledge property in slaves, or protect it, or agree to its
                    extension; that they deny the South equality in the Territories; that Lincoln's inaugural address announces his determination to coerce the seceded States; that Republican rule will become permanent, and that, if Virginia remains attached to the remnant of the dilapidated old Union, she must submit to insult and degradation; therefore, Resolved,
                  </p>
                  <p>1. We desire the immediate secession of Virginia.</p>
                  <p>2. We hereby request, and instruct (so far as we have the right), our representative in the Convention, JNo. L. MARYE, ESQ., to urge and vote for an act submitting, at the earliest possible time, to the people of the State, a direct vote on the question of secession.</p>
                  <p>3d. That we are opposed to an adjournment of the Convention to next fall, and, if there be an interregnum at all, it may only occupy such time as may be necessary to give the people time to vote on the question of secession, and refer their action back to the Convention for adoption.</p>
                  <p>4th. That these proceedings be forwarded to <span className="persName">Mr. MARYE</span>, our representative in the Convention, with the request that he ask the privilege of reading them to that body, and that a copy be furnished the Richmond and Fredericksburg papers for publication.</p>
                  <p>5th. That Dr. Dillard and T. W. Conner be a Committee to carry the 4th resolution into effect.</p>
                  <p>On motion the meeting adjourned.</p>
                  <p>T. L. DILLARD, Chairman.</p>
                  <p>T. W. CONNER, Secretary.</p>
                </div>
              </div>
              <PageNumber num={220} />
              <p>ADDRESSES OF COMMISSIONERS</p>
              <div className="speaker" id="sp1007"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">With the consent of the gentleman from Jackson [<span className="persName">Mr. TURNER</span>] , I desire to offer the following resolution. It is a mere resolution of inquiry, and will give rise to no debate:</p>
              <p>Resolved, That the Clerk of the Convention inquire into and report why the speeches of the Commissioners from South Carolina, Georgia and Mississippi, have not been printed, as ordered by the Convention.</p>
              <p>The resolution was agreed to.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.6.6">
              <h3><span className="headingNumber">1.6.6. </span><span className="head">FEDERAL RELATIONS</span></h3>
              <p>The Committee, in pursuance of its standing order, resolved itself into Committee of the Whole [<span className="persName">Mr. SOUTHALL</span> in the Chair] , and resumed the consideration of the report of the Committee on Federal Relations, the pending question being the amendment in the nature of a substitute offered by <span className="persName">Mr. CARLILE</span>, upon which <span className="persName">Mr. BALDWIN</span>, of Augusta, was entitled to the floor.</p>
              <div className="speaker" id="sp1008"><span className="persName">Mr. BALDWIN</span> resumed as follows:</div>
              <p className="p-in-sp">
                Mr. Chairman, I feel, I assure you, most profoundly impressed by the marked kindness which has been shown me thus far by the members of this Convention, and by those who have been attending upon its deliberations, in the attention which they have bestowed upon what I have had to say. I feel, sir, that I am a trespasser upon this kindness and this patience; but, having undertaken to
                represent upon this floor, the earnest convictions which I entertain, and in which I believe I represent the wishes and the will of my people, I feel bound to proceed, according to my best judgment, to the conclusion of this discussion. I cannot, sir, under these circumstances, undertake any elaborate recapitulation. I think once listening to my views is enough, in all conscience, to
                ask of any audience. I shall proceed, therefore, to consider the Territorial question in which I was engaged at the time of the adjournment yesterday.
              </p>
              <p className="p-in-sp">
                We have already seen, sir, I think, in regard to the Territories of the United States, that the question of extending slavery into them is one purely abstract, that they are not adapted to slave labor, and that no sane man, having a regard to the prudent investment of his money and the discreet conduct of his business, would ever think of carrying slave property into these
                Territories. We have also seen, sir, I think, that the present condition of slave labor is such that we have no surplus to send anywhere; but, that, on the contrary, the great trying, present need of the South, is an increase of slave labor, in the production of its great staple crops; this want is so great as to press upon <PageNumber num={221} /> the
                minds of many to whom, a short time ago, such an idea would have been repugnant, the necessity for entertaining a discussion concerning the re-opening of the African slave trade.
              </p>
              <p className="p-in-sp">
                Sir, in this connection I wish to glance at the view taken upon this floor in regard to the probable Africanization of the South. It seems to weigh upon the minds of members here, and the minds of others discussing this subject elsewhere, that we are in danger of having the South converted into a St. Domingo; that we are in danger of being overrun by the increase in the number of
                slaves, driving the white man from the South, and converting it into one vast community of slaves, or, what is more, of free negroes. That seems to be the idea. Now, sir, it strikes me as somewhat singular that this apprehension should prevail; because the tendency now, as is admitted by every body, is not to slavery extension, indicating a need of more room; but the tendency is, as
                stated here, to contract the area of slave labor, thus indicating a want of sufficient labor in even the most thickly settled portions of the slave-holding country.
              </p>
              <p className="p-in-sp">Sir, the gentleman from Albemarle [<span className="persName">Mr. HOLCOMBE</span>]
                , the other day went into a statistical estimate as to the probable future upon this subject. He told us he apprehended that in fifty years, according to the best received tables of increase of the population of the South, we might apprehend that there would be sixteen millions of slaves in the slave-holding country now owned by the United States; and he seemed to look forward to
                that fact as conclusive evidence of the utter Africanization of the South. He seemed to look upon a mass of sixteen millions of slaves, if I correctly interpreted him, as in itself a realization complete that in fifty years we would have this expulsion of the white man, and a necessary surrendering of the fair fields of the South to the African race. Sir, I have not been under any
                such impression in regard to the danger to be apprehended in that direction. I have been of opinion that there was no danger, within hundreds of years, of any such number of slaves as to render it at all impracticable to hold them all in slavery, and to apply their labor, as slaves, to the cultivation of the great staple crops of the South. And, sir, it so happens that I am sustained
                upon this point by perhaps the most distinguished man who has ever brought his mind to the consideration of this great slavery question, in all its bearings. I allude to Governor Hammond, of South Carolina. I suppose if there is a man who has contributed more than all others to the spread of sound opinions in regard to this vital interest of the South, it is Senator Hammond. If there
                is a man who has distinguished himself above all others for his far-reaching, sagacious, <PageNumber num={222} /> philosophical and statesmanlike views of that subject, he is the man. What is his opinion upon this subject? Sir, I have his remarks as made on this very point, in the Senate of the United States, within the last year. In answer to this very
                anticipation, which was indulged in by a Republican Senator upon the floor of the United States Senate, who was pointing out, not exactly with triumph, but certainly with no dissatisfaction, the probability or the apprehension that the South would be Africanized for the want of an outlet for the surplus of her slave population, Mr. Hammond utterly repudiated the idea that the South
                had any such apprehension, or any cause for such apprehension, and declared that within the present slaveholding limits of the United States, there could be sustained, with safety and with advantage, a slave population of twenty millions. Let us see what he states upon this subject, in his own language.
              </p>
              <p className="p-in-sp">I read from the Congressional Globe of April 12, 1860. I will first read the remarks of Senator Doolittle as introductory to what was said by Senator Hammond in explanation and correction :</p>
              <p className="p-in-sp">Mr. Doolittle said:</p>
              <p className="p-in-sp">
                "I would say that this whole question of slavery is discussed in the Congress of the United States simply on the ground of the territorial question. We have no right to discuss the question of slavery as a question of practical legislation as it exists in the States, and we refer to it only because we are discussing our policy in the territories. You propose to send the institution
                of slavery into the territories, we think it ought not to go there; and discussing the question whether it should or should not go into the territories necessarily involves, on our part, the consideration of the system of slavery in its bearing in the States where it exists. We mean not to discuss the question in any sense offensive to the States of the South, but when we insist that
                it ought not to go into the territories, what answer do you give?"
              </p>
              <p className="p-in-sp">Here he imputes to the South the very position taken upon this floor.</p>
              <p className="p-in-sp">
                "You tell us that if we do not allow it to go into the Territories the necessary consequence will be that, under the laws of population, the colored race will become so numerous in the slave States that it will force on emancipation. That is what you say. You say if a limit be once put to the extension of slavery the laws of population will force it out in the end."
              </p>
              <p className="p-in-sp">That is the position assigned to the South by Mr. Doolittle, and that is the position I understand to be assumed here.</p>
              <p className="p-in-sp">Mr. Hammond said in reply:</p>
              <p className="p-in-sp">"The Senator will excuse me, I have said no such thing. He answers <PageNumber num={223} /> me and says 'you say it.' I think the South is capable, within its present limits, of sustaining a slave population of two hundred millions."</p>
              <p className="p-in-sp">"Mr. Doolittle—I am most happy to hear the honorable Senator from South Carolina avow that he does not apprehend any such fears as were expressed by his colleague yesterday and which were expressed the other day, in the House of Representatives, in a very able speech, by Mr. Curry of Alabama."</p>
              <p className="p-in-sp">"Mr. Hammond—Allow me one word ; I do not wish to be misunderstood. While I believe we can support two hundred million of slaves within our present limits, and have never urged that as an argument for expansion, I do not wish to be understood as surrendering any of our rights to territory and expansion, at all."</p>
              <p className="p-in-sp">
                Now, sir, if this be true, or if it approximates to the truth, what becomes of this apprehension of the Africanization of the South, within fifty years? Sir, if the South is capable of any such population, or any population at all approaching it, might we not say that, if we can manage, in the mean time, to sustain the great vital interest of the country, only having to apprehend
                danger upon this question of slavery in that far distant future, if we can do this without dishonor, without any sacrifice of vital right and interest, might we not well say that it would be wise for us to leave that question when it arises in the distant future, to the disposal of an Allwise Ruler, who doubtless has preparation to meet that as he has to meet all other great issues
                and questions of human life.
              </p>
              <p className="p-in-sp">
                Sir, it is well to observe again, in passing, the kinship of extremes. It is apprehended that the Black Republicans intend to make a war of desolation upon us by building, as they call it, a wall of fire all around the institution of slavery, and letting it die out within that circle. That is the apprehension from the Black Republicans. Sir, look, I say, at the kinship of extremes.
                Already thus early in the history of the Cotton Confederacy they have engrafted into their constitution the power given to their government to build this wall of fire along our Southern and their Northern border. They have conferred this power upon their Congress, sir, and distinguished men among them have declared it to be their policy of self-defence that this wall shall be built
                along the border, and that the slaves of the border States shall be pent up within their present limits, and forever prevented from going into the Cotton States. For myself, I have no apprehension upon this subject. I agree with Senator Hammond in thinking the South will be able to take care of herself in regard to this question against any such threat of the Black Republicans. And I
                have as little apprehension of it as presented <PageNumber num={224} /> by the cotton States; because, in the first place, I believe that it would be a great blessing to us, if there should, to a very large extent, be a stoppage of the Southern drain upon our slave population. We in Central and Western Virginia, who have no prejudice to permit our
                enjoying the benefits of slave labor, we are not prevented by any adverse system of laws from enjoying it, when we shall be free from this immense drain and competition from the South, which prevents us from availing ourselves fully of the benefits of a system which we approve and desire.
              </p>
              <p className="p-in-sp">
                I think, then, it would be well to look into the probabilities of the spread of slavery into Western Virginia, which is capable of receiving and employing hundreds and thousands, and tens of thousands of slave laborers. We had better look at the probable emancipation which we are told is likely to take place in five years in the State of Missouri, and which we can only prevent by
                stopping the drain from that State to the South. We had better prevent the emancipation which threatens Kentucky, by stopping the drain from there. In that way we in the border States may become a people having the recommendation which seems to possess so great a charm in regard to the cotton States, we may become a homogeneous people upon this subject.
              </p>
              <p className="p-in-sp">
                But, sir, when the time comes to test this question with our Southern brethren of the Cotton States, I think it will be found that their self-interest will be stronger upon this subject—aye sir, and I hope it will be stronger upon many other subjects than their present excitement. I believe that their self interest will soon show them that they are at last, as dependent upon us for
                their supply of labor as we are dependent upon them for any market for our surplus. I do not apprehend that they will have any advantage of us upon that subject, because thus far, unquestionably, we have had the advantage in the market. Virginians do not go South to sell their slaves, but the people of the South come to Virginia seeking their supplies of slave labor.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I stated yesterday that, in the view I presented, no practical question existed in reference to the territory now in possession of the Federal Government; that it would, in my opinion, and, I believed, in the opinion of most Southern men, have been better for the country, better for the North and the South, better for all concerned if the question never had been raised
                in regard to the kind of labor with which the present territory belonging to the government should be settled. But, sir, the question has been raised, and, as I said, I believe now that the duty is devolved upon our brethren of the North to speak out now and forever upon this subject. I think that the duty is now <PageNumber num={225} /> upon them to
                meet the question fairly, frankly and in a spirit of brotherhood. I say that I am prepared to go upon this as upon all other subjects affecting the question of slavery into a full, fair, complete and final settlement of it. I would avoid nothing. I would leave out nothing that may hereafter vex the people of this country with strife, or bickerings, or sectional jealousies of any
                sort. I think the time for a settlement has come, and I think that common prudence requires that it shall be a comprehensive settlement, covering all questions in any manner likely to disturb our peace hereafter. And I am in favor of settling this territorial question now correctly.
              </p>
              <p className="p-in-sp">
                I indicated yesterday that there were extreme views, and erroneous views upon this subject entertained North and South. Why, sir, if the extreme Southern view upon this subject is to be pressed—that all the territories of the United States are to be slaveholding territories—the Dred Scott decision gives us so strong a construction of the Constitution in its favor, that we have no
                cause to make any great disturbance about it. But, sir, there is apprehension in the minds of Southern people that that decision cannot prevail permanently as the doctrine of this country, and I think that the true doctrine and the true result is expressed in one of the resolutions reported by the majority of our Committee. I ask the indulgence of the Convention while I read that
                resolution :
              </p>
              <p className="p-in-sp">
                "4. The Territories of the United States constitute a trust to be administered by the General Government, for the common benefit of the people of the United States, and any policy in respect to such Territories, calculated to confer greater benefits on the people of one part of the United States than on the people of another part, is contrary to equality and prejudicial to the rights
                of some for whose equal benefit the trust was created. If the equal admission of slave labor and free labor into any Territory, excites unfriendly conflict between the systems, a fair partition of the Territories ought to be made between them, and each system ought to be protected within the limits assigned to it, by the laws necessary for its proper development."
              </p>
              <p className="p-in-sp">
                That, I take to be the true doctrine. A fair division of the territories between the two systems, if they cannot work well together—a fair division according to the capacities and interests of each one of these two great systems of civilization; and then the protection of each system and of all the rights growing out of it within its proper share and division of the territories.
              </p>
              <p className="p-in-sp">
                Sir, on this subject of slavery, as on all subjects affecting the rights of the States or of the people of the States, I hold to the doctrine of <PageNumber num={226} /> perfect equality. I never have consented, I never will consent to any doctrine that gives to any section higher or better rights under the common government than are enjoyed by my own
                section. I never will consent that any State of this Union shall enjoy higher or better rights than the State to which I belong. I never have consented and I never will consent that any other institution or property in this country shall have higher or better or more efficient protection than the institution and property which constitutes the great paramount interest of Virginia.
              </p>
              <p className="p-in-sp">
                Sir, I stand here to demand, under all circumstances, to demand against all opposers full equality—aye, full protection, for all the institutions of the South. As a Southern man, as a slaveholder in Virginia, I never can consent that this great interest, this great institution of the South shall be placed under the ban of government. I never will consent that it shall be treated in
                the operations of government as an inferior property to be subjected to any sort of restriction or limitation that does not become necessary in order to the enjoyment of the equal rights of other sections and other interests. As was remarked by a distinguished friend of mine the other day, I never will consent that they shall put a wolf's head upon the institution of slavery to
                attract to it the opposition—nay, the enmity of mankind. Never!
              </p>
              <p className="p-in-sp">
                These will, I know, be regarded as strange opinions coming from an unexpected quarter. They are the opinions that I have always held, and which I am ready at all times to act upon and carry out. In the plan of constitutional amendment, which has been submitted here by the Committee of Twenty-one, these principles are declared. The first section of this report recognizes a division of
                the Territory by the line of 36 degrees 30 minutes. I believe, upon that subject, there hardly remains at this time any dispute. I believe there are very few now who are prepared to insist upon the extreme demand of a slave code for all the territories of the United States. On this subject, allow me, in passing, to remark as a singular indication of the tendency of things—not to the
                oppression of the South, not to the assertion of the extreme doctrines of the Republican party; but in the other direction—that in the last Congress of the United States, in both branches of which the Republican party was in the ascendant—three territorial governments were organized, and in regard to each one of them, the fact stands forth that there is no prohibition of slavery; and
                that, the territorial legislature is expressly directed to protect, equally, all property of the people of the territories.
              </p>
              <p className="p-in-sp">
                They talk about the Chicago platform, and what it looks to. Sir, if there is any feature in the Chicago platform more distinct than another, <PageNumber num={227} /> it is the obligation of the Republican party to assert the restrictive policy in regard to all the territories of the United States. Yet here they have organized three territorial
                governments within the last three months, without applying it to any one of them.
              </p>
              <p className="p-in-sp">Then, sir, this first section recognizes the division line of 36 30, and prohibits involuntary servitude North of that line. It then proceeds to recognize and to establish and confirm in all the territories South of that line, involuntary servitude as it now exists, and declares that it shall remain and shall not be changed. I will read that section:</p>
              <p className="p-in-sp">
                "ART. 13, Sec. 1. In all the present territory of the United States, North of the parallel of thirty six degrees and thirty minutes of North latitude, involuntary servitude, except in punishment of crime, is prohibited. In all the present territory South of that line, involuntary servitude as it now exists, shall remain, and shall not be changed; nor shall any law be passed by
                Congress or the Territorial Legislature to hinder or prevent the taking of persons held to service or labor from any of the States of this Union to said Territory; nor to impair the rights arising from said relation; nor shall said rights be in any manner affected by any pre-existing law of Mexico; but the same shall be subject to judicial cognizance in the Federal Courts, according
                to the remedies and the practice of the common law."
              </p>
              <p className="p-in-sp">Now, sir, observe, that in the first place, it removes the difficulties which have been referred to, on this floor, by the distinguished gentleman from Charles City [Ex-President TYLER]
                , as suggested by Mr. Chase, or by some other person, in that "Peace Conference." He informed us that in that Conference a distinguished Black Republican had declared and given notice in some spirit of candor, that the status of persons held to service or labor in the territory, was the status of freedom, and that it would be contended that such should be the construction of the
                "Peace Conference" proposition. I call the attention of the distinguished gentleman to the fact that this report of the committee cuts off that objection, fair and square, by declaring "nor shall said rights be in any manner affected by any pre-existing law of Mexico," so that, here I take it, is a negative upon the declaration of Mr. Chase, and in that respect, the report of the
                Committee, is a decided improvement upon all others—even upon the Crittenden proposition, as we will see.
              </p>
              <p className="p-in-sp">The Crittenden proposition says that slavery of the African race is recognized as an existing institution, and shall not be interfered with by Congress. The report of the Committee declares that involuntary servitude as it now exists, shall remain, and shall not be changed.</p>
              <PageNumber num={228} />
              <p className="p-in-sp">
                Now observe. The objection to the Crittenden proposition is, that whilst it says that involuntary servitude shall not be interfered with by Congress, it allows it to be under the jurisdiction of the territorial Legislature, with the single precaution of directing them to protect it. Sir, I am not satisfied that the rights of the South, if we are to go into a settlement, shall depend
                upon a mere directory clause in the Constitution, for protection at the hands of the territorial Legislature. Suppose the territorial Legislature—suppose the territorial Government, in all its departments, refuses to protect it, where is the remedy? Gentlemen in their objections to the plan of the Peace Congress, have departed from all rules of construction—even from their own
                construction—and wander into the field of conjecture as to what an evil disposed Black Republican Judge might do in regard to the rights of the South, in the face of a fair construction. I ask them to take warning from their own advice on that subject. Sir, anticipating what an adverse Black Republican Legislature, an adverse Black Republican Governor, and an adverse Black Republican
                Judiciary all combined would do with a Constitutional injunction to protect slavery; Sir, for one I am not willing to rest upon the probability of their discharging their duty. Sir, there is another point to be observed. We must take notice of the fact that in the Territory of New Mexico, including all the Territory south of the line, there is a slave code now in existence. The plan
                of the Committee lays hold of that slave code, and says it shall remain and shall not be changed. What does the Crittenden Proposition do with it? It recognizes slavery as existing there and prohibits Congress from interfering with it. But I ask of gentlemen whether under the Crittenden Proposition, the Territorial Legislature could not repeal that code, and thus by failing to pass
                another evade and avoid the Constitutional injunction of the Crittenden amendment? The report of the Committee not only prohibits Congress from changing it, but the Territorial Legislature also. It says it shall not be changed by any authority; and this, as will be admitted, makes a very marked difference in favor of the report of the Committee over the Crittenden plans.
              </p>
              <p className="p-in-sp">
                But, sir, there is another advantage. If the territorial Legislature should even undertake to interfere—if it should turn out that this slave code was in violation of some law or of the constitution and that the territorial Legislature had no right to pass it, this amendment, presuming that it is to be embodied in the constitution, would lay hold of the slave code now in existence
                and would make it part of the Constitution itself.
              </p>
              <PageNumber num={229} />
              <p className="p-in-sp">What says the Committee's plan? "Involuntary servitude, as it now exists, shall remain and shall not be changed."</p>
              <p className="p-in-sp">
                There is no doubt of the fact that it exists there; whether de jure or not it exists there de facto. I suppose there can be no principle of constitutional construction better established than that it must be read by the light of existing facts. Then, sir, it is recognized de facto as existing there. Burn up the slave code of New Mexico, and we have yet the constitution of the United
                States—if this amendment is incorporated in it, recognizing slavery as an existing institution de facto. But it does more than that, it says it shall not be changed. It goes further than that. It says, "nor shall any law be passed by Congress or the territorial Legislature to hinder or prevent the taking of persons held to service or labor, from any of the States of this Union, to
                said territory; nor to impair the rights arising from said relation."
              </p>
              <p className="p-in-sp">
                One of the most striking provisions that ever I have seen in any law or in any Constitution, is that which follows, declaring that these rights—that is the rights arising upon the relation of master and slave —"shall be subject to judicial cognizance in the Federal Courts." What is judicial cognizance? What is "cognizance" but "recognition"? —shall be subject to judicial
                "recognition" in the Federal Courts "according to the remedies and the practice of the common law."
              </p>
              <p className="p-in-sp">
                Well, what are the remedies and the practice of the common law? Why, all our remedies in Virginia are remedies of the common law. All the remedies for the recovery of property between man and man, all our system of practice, and pleading, and proceeding in Court is in the main according to the remedies and the practice of the common law, not depending upon any statute enacted by our
                Legislature, except in the way that this amendment proposes to establish it in New Mexico. In Virginia, by special statute, we adopt the common law of England as the basis and foundation of all our rights, remedies, and of the practice in the Courts. And here it is proposed to place in the Constitution of the United States, for the benefit of New Mexico, a recognition of the same
                rights, remedies, and practices in regard to the institution of slavery in that territory.
              </p>
              <p className="p-in-sp">
                We have heard it said, in regard to the insufficiency of these remedies, that the common law knows no such institution as slavery. That is a new doctrine coming from Southern quarters. I had supposed that the modern doctrine of the South, so ably maintained by Senator Benjamin, in his celebrated speech in the Senate of the United States, was, that the old idea of the Somerset case
                has exploded, and that slavery did exist under the common law. I thought that was the modern <PageNumber num={230} /> idea of, at least, the extreme Southern men. But, suppose that slavery does not exist under the common law, we do not propose to go to the common law to establish the right. The plan of the Committee, says : "Nor to impair the rights
                arising from said relation, nor shall said rights be in any manner affected by any pre-existing law of Mexico, but the same shall be subject to judicial cognizance in the Federal Courts according to the remedies and the practice of the common law."
              </p>
              <p className="p-in-sp">
                Sir, I need not argue to any man familiar with the remedies and practice of the common law, that its fundamental principle, the maxim which guides its remedial hand—is that for every right there shall be an action, for every wrong a remedy. Ubi jus ibi remedium, is the maxim of the common law, which has excited the admiration of students of jurisprudence in all ages and all countries
                for its adaptability to all the changes and circumstances of cases, and for its power of moulding and adjusting itself in the most complete and admirable manner to the furtherance of right and the administration of justice between man and man. Sir, I need not promise to any lawyer any thing more to inspire his confidence than to say you shall have this right, and you shall have all
                the redress that the common law affords to enforce it. There is no lawyer who would hesitate to say that if you establish the right and give him the common law remedies to enforce it, he stands upon a rock.
              </p>
              <p className="p-in-sp">
                This is the plan reported by the committee, and in that respect it carries out the true doctrines of the South. It does not leave to Congress, subject to the whims, caprices, passions, prejudices or dishonesty of party, or to their sense of duty and of moral propriety the application of the remedy to our rights. It does not leave our rights to the tender mercies of any party in
                Congress, or of any Territorial Legislature; but it plants them in the Constitution itself, and gives the enforcement of them to the courts.
              </p>
              <p className="p-in-sp">
                We hear a great deal about the corruption of the Black Republican party. We hear a great deal said about the danger of trusting a corrupt Judiciary in the territories. Sir, as a lawyer, I am proud to declare that in my humble judgment, when the legal profession of the United States become unsafe depositories of private right or public liberty, the days of this republican government
                and of free government every where are numbered. As an humble member of that profession I claim for them that they are the true conservative class, that they constitute the true conservative element in this great republic of ours. Sir, let the storms of fanaticism and of popular fury howl where they will—let them beat and surge as they may over all other barriers—the safeguards
                <PageNumber num={231} /> of private right and of public liberty, thank God, are in the halls of justice, and are entrusted to a band in whose hands they will be safe, until everything ceases to be worth preserving.
              </p>
              <p className="p-in-sp">Thus I think I have shown in regard to this first section, so far as it applies to present territory, that the report of the Committee is far preferable, far stronger and far better for the South than the Crittenden proposition, either as a recognition of the true principle or as a plan for carrying out practical rights.</p>
              <p className="p-in-sp">
                Sir, how is it in regard to future territory? What is the protection afforded in the Crittenden compromise as to future territory? None whatever. In the Crittenden plan with the Powell amendment it is provided that in all future territory South of the line slavery shall be protected by all the departments of the territorial government. In regard to present territory, I have shown the
                insecurity of entrusting our rights in the hands of the squatter legislatures in the new territories. Here is another difficulty to which I will allude. It happens that we have settled our last territory in the wilderness. Our hardy pioneers have gone out into the Western wilds and carved out homes for themselves and their children, and have made laws such as they desired, and in
                accordance with the spirit of their institutions. But, sir, now we have exhausted all the wilderness around us, and from this time forward, if the United States acquire territory, they must acquire countries already settled. Look at the exterior boundaries of the United States. Why, until recently the United States could be hardly said to have any exterior boundaries. Look at the
                countries that infringe upon our borders; at the North, the British possessions; at the South, Mexico—old settled countries with large populations; Cuba, just over the way, settled already with a crowded population. In all these countries they have systems of laws recognizing and establishing their domestic, social and proprietary relations, and in all of them, except Cuba, slavery
                is prohibited. Now take the Crittenden proposition, and suppose under it you acquire Mexico and cut out of it a slice large enough to make a State of sixty or seventy thousand square miles, and you would have in it already a population large enough for a member of Congress. The very moment you acquire it, under the Crittenden plan, it has the right to come in as a State. What good
                does your protection in a territorial condition do? You say that the territorial judiciary, territorial Legislature, and territorial Government, are all bound to give it protection during the territorial condition; but it would have no territorial condition. It would come in like Texas, full grown, and as such entitled to admission as a State. Not only this, but the distinguished
                <PageNumber num={232} /> gentleman from Charles City
                [<span className="persName">Ex-President TYLER</span>]
                , said yesterday in regard to a striking case which occurred in his own Administration, that the Constitution of the United States does not apply to territory until you get it. Suppose it was contrary to the Constitution of the United States to impose any restriction upon territory after you get it, all that would be necessary would be to insert in the joint resolution of annexation
                a prohibition of slavery, and it would be as valid and binding as the restriction to which the distinguished gentleman affixed his signature in regard to Texas.
              </p>
              <p className="p-in-sp">
                So you see that in the acquisition of new territory, constitutional amendments are of no value. The distinguished example we have had recounted here shows that they would all be of no avail, because, has not the distinguished gentleman from Charles City said that he would have died before he would have consented to the application of the Missouri line within the territory of the
                United States? And yet he did not refuse to apply it, in making a bargain to get more territory. Suppose we had the Crittenden proposition, and that all territory hereafter acquired is to have slavery recognized; what is to prevent any President, hereafter, from imitating the example and quoting the authority of the distinguished gentleman from Charles City, and saying, "I can make
                any bargain I please, in regard to territory which is outside of constitutional protection; your Crittenden amendments are but as dust in the balance, which I utterly disregard"? What becomes then of the protection in regard to future acquisitions of territory? It seems to me that the Crittenden proposition, so far as it affords protection in regard to them, is utterly worthless.
              </p>
              <p className="p-in-sp">
                But, sir, what is the case in regard to the report of the Committee? in that, there has been adopted the principle of the guarantee of power. I suppose the gentlemen of what may be called the "rabid" school, and who contend for the application of that principle throughout, in the workings of the government, will hardly complain of us for having applied it in regard to future
                acquisitions of territory. If we could not go all the way, I presume they will at least congratulate us for having gone that far. The principle is, that hereafter, no new territory shall be acquired, either by joint resolution or by treaty, except by the concurrent vote of a majority of the Senators from the slave States and the non slaveholding States. There is a complete protection
                in regard to future acquisitions. No new territory, either slave or free, can be introduced without our consent, without the consent of the South, without the consent of a majority of Southern Senators. If it should ever happen in the progress of future ages, that we should <PageNumber num={233} /> dwindle down to but one single State, it would require
                the consent of that State to acquire any territory whatever. The old maxim, cujus dare, ejus disponere, would apply here.
              </p>
              <p className="p-in-sp">
                He who has the right to give without restriction, has a right to impose what limitations he will upon the gift. We who have the right to consent or to refuse, have the right to consent upon what conditions we please. Unless the South is less keen in perception of her interests, or less watchful in regard to her rights than she has ever been supposed to be, I, for one, am willing to
                trust the future acquisition of territory to the watchful guardianship of the Senators from the Southern States. I do not entertain any of the apprehensions which were expressed by my friend from Richmond, as to the possibility, in the future, of corruption among the slave States or the purchase of Senators from the South. I do not apprehend any such difficulties. If it ever comes
                true that the apprehension is well founded, and that Southern Senators in the United States Senate cannot be trusted upon a question of Southern rights, I would ask what are Southern Rights, worth? What plan will you adopt to protect them, if you cannot trust them in the hands of our own people, our own representatives? Something has been said about this section being a check and a
                clog upon the acquisition of territory hereafter. So far as I am concerned, I deem it due to honest debate to say that that constitutes no objection in my estimation. It is no objection with me that it will operate as a restriction upon the fierce spirit of territorial aggrandisement, upon the wild lust for the lands of our neighbors. If ever a case arises commanding a concurrent
                vote. it will be apt to be a case of public necessity. Unless some case arises of sufficient public necessity and public benefit to command a concurrent vote, I take it that the true interests of the nation require that we shall have no more Territory. What dci we hear upon this subject now, from that class of politicians who have been heretofore in favor of the expansion of Southern
                institutions, who have told us that our mild and gentle confederated Republican system might be safely extended over this entire continent, and that so far from diversified interests being causes of antagonism, they would be elements of mutual attraction and strength? They have all suddenly become convinced that the Territory of the United States is too large, and that the only thing
                for us to do is to divide it up; and we hear most splendid descriptions of the magnificence of a republic which is to have for its theatre of action the half of a continent.
              </p>
              <p className="p-in-sp">But to proceed with this report of the Committee. The third section of the report covers the irrepressible conflict all over like a blanket.</p>
              <PageNumber num={234} />
              <p className="p-in-sp">
                What is the third section? We have been told that the Abolitionists and the Black Republicans intended to abolish slavery in the District of Columbia This report prohibits them from doing it except by the consent of Virginia, Maryland and the people of the District. Will you trust that? Will you trust Virginia, Maryland and the people of the District with the guardianship of slavery
                in the District?
              </p>
              <p className="p-in-sp">We have been told too that this Black Republican party intends to interfere with the slave trade between the States and to abolish slavery at all the dock-yards, &amp;c., belonging to the United States, within the slave States.</p>
              <p className="p-in-sp">
                Now, this third section provides distinctly that they shall never have any such power. But we have been told that these Black Republicans will keep on adding free State after free State, and after a while when they get strong enough they are going to amend the constitution so as to authorize them to legislate upon the subject of slavery and abolish it in the States; and this is to be
                the outcome of the irrepressible conflict in its legal and constitutional aspects. This section provides that they never shall have a right to abolish, or even to legislate upon slavery in the States under any circumstances; and then there is another section which provides that they shall not only not have a right to interfere with slavery in the District of Columbia, or with the
                interstate slave trade, or with slavery in the United States dock-yards, &amp;c., or to touch slavery in the States; but that they shall not even have a chance to grow strong enough to do it: because they are prohibited in the last section from ever amending the constitution of the United States so as to do any one of these things, or to affect the Fugitive Slave Law, or to affect
                any provision touching the institution of slavery except by the unanimous consent of all the States.
              </p>
              <p className="p-in-sp">
                What becomes of the Irrepressible Conflict? Where can slavery be touched? I said yesterday that slavery where it exists now, according to our present Constitution, is entrenched within the inner citadel of the Constitution, and that none but a madman had ever pretended that the General Government had a right to touch it except by amendment to the Constitution. Now, here is an article
                proposing to incorporate in the Constitution a perpetual prohibition to make such amendments, except by the unanimous consent of all the States. It seems to me that I may well declare that if that amendment to the Constitution becomes incorporated so as to form a part of the fundamental laws of the United States, it may be regarded as driving the last nail into the coffin of the
                Irrepressible Conflict.
              </p>
              <PageNumber num={235} />
              <p className="p-in-sp">There is another section which says, "Nor shall Congress have the power to lay any greater tax on slaves than on land."</p>
              <p className="p-in-sp">
                Well, if that is not reducing slavery to a dollar and cent measure, I do not understand the meaning of the language. How will you compare slaves with lands? What is the common standard of measure? If they are to be taxed together, they must be compared together for the purpose of taxation. The only common measure is the dollar and cent standard, and that I take to be, a distinct
                recognition of the right of property in slaves. As to the right of taxation that may or may not hereafter be a valuable provision for us. Even if the law is so now, as was intimated here the other day by the distinguished gentleman from Princess Anne
                [Ex-Gov. WISE] , this provision will do no harm.</p>
              <div className="speaker" id="sp1009"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">
                I hope I may be allowed to interrupt the gentleman for one moment. He has gotten the idea exactly contrary to the idea that I had expressed. My position was, and is, and I can demonstrate upon the authority of the Supreme Court of the United States, that this report of the Peace Conference expressly contradicts, and is contrary to the present Constitution of the United States, and
                reverses the interpretation of the Supreme Court from the year '96 down to the present hour; that the idea of the Supreme Court is that, instead of slaves being regarded, in respect to taxes and representation, as property, as chattels, as "dollars and cents," that they are regarded by the present Constitution of the United States as persons—persons to be taxed per capita by direct
                taxation, which by the rule of apportionment, must be equal throughout the United States; that the Supreme Court have decided that the Constitution has established two rules of taxation, first in respect to the indirect taxes of duties, imposts and excises, the rule of uniformity—that all imposts and excises must be uniform; and second that the direct taxes of the United States must
                be apportioned and are obliged necessarily to be equal. That was the idea—the idea, sir, which accounts for the present Constitution of the United States, using the words "other persons," endowing these slaves with a personality instead of giving them a proprietary or property character; and that they were called persons instead of property because so far as the powers of the General
                Government were concerned, they were to be treated as persons with heads on their shoulders to bear capitation tax, and, if taxed at all, were to be taxed per capita, and a capitation tax is a direct tax, and a direct tax is obliged to be apportioned among the "persons" designated by the Constitution throughout the United States. Therefore, you have either an ignorant or intentional
                blunder on the part of the Peace Conference.
              </p>
              <div className="stage it">[Applause.]</div>
              <PageNumber num={236} />
              <div className="speaker" id="sp1010"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair is satisfied that the disorder which has just been displayed, came from either the gallery or the lobby; and it again repeats and earnestly entreats that such a demonstration will not again be displayed, with the assurance that if it is, the galleries and lobby will be cleared.</p>
              <div className="speaker" id="sp1011"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                It seems, sir, on this subject, to be a little difficult to tell exactly where to find gentlemen of a particular school of politics. The other day we had a most able and learned argument, to show that the use of the term "persons held to service or labor," in the first section of the Peace Conference plan, was an art and device of Black Republicanism, under which was concealed a
                denial of property in slaves. Now, sir, we have a change here that is either an ignorant or an intentional blunder, by which the great benefits secured, by the Constitution, of regarding slaves as persons, are to be taken away. Which horn of the dilemma do the gentlemen intend to take? If they desire that slaves shall be regarded as persons held to service or labor—shall be regarded
                as persons "with heads upon their shoulders," then why assail the Peace Conference for using that very term, with all its benefits to the South—all that we have a right to ask or ever have desired? If, on the other hand it is desired that they shall be regarded as property, as dollars and cents, or, in the language of Scripture, as "our money"—if that is intended to be the idea to be
                inculcated by Constitutional language and Constitutional provision, why complain of that protection, in regard to taxes, that is given to land? It seems to me there is a conflict upon this subject, between the positions assumed by gentlemen of that particular school of politics.
              </p>
              <p className="p-in-sp">
                But, sir, the gentleman undertakes to say that the capitation tax must be imposed upon slaves, and that it must be apportioned. Why, sir, the rule of direct taxation is apportionment unquestionably; but apportionment how? Man to man? No; it is an apportionment among the States, according to the ratio of their representation in the House of Representatives. If the Government of the
                United States wants to lay a tax of a million, it divides that tax among the States, according to the ratio of representation in the House of Representatives of the United States, and then it proceeds, without Constitutional limitation, to impose that tax within each State, for the purpose of raising the amount of revenue apportioned to each; I know of no limit, whatever, upon the
                power of Congress, in laying a direct tax in any State—as to the subjects on which taxes are to be laid.
              </p>
              <div className="speaker" id="sp1012"><span className="persName">Ex-Governor WISE</span>—</div>
              <p className="p-in-sp">
                Will the gentleman permit me to interrupt him again for a moment. There is a limitation, and there is a limitation <PageNumber num={237} /> by the very fact of apportionment throughout the United States among the several States. By the decision of Judge Marshall in the case of Loughborough against Blake, a question arose upon the language of the
                Constitution of the United States, providing that capitation and other direct taxes, should be apportioned among the several States, according to the census. The question arose—because the several States, eo nomine, were the words used—whether a territory was embraced in those terms or not. Judge Marshall decided that in respect to the territories, Congress might lay a tax upon the
                territory or not, that in laying taxes on the States the territories might be omitted; but if the tax was laid upon the territories, it must be laid, according to the character of the tax, under the one rule or the other—the rule of uniformity or the rule of apportionment; that there were but two classes of Federal taxes, indirect and direct. The indirect taxes, embracing duties,
                imposts and excises, which were obliged to be uniform throughout the United States; and the direct, supposed to be capitation and land taxes alone, which were obliged to be apportioned among the several States according to the census thereof. Well now, what limitation is there in that? That the Federal power, whether it be primary and original, or derivative, or in other words,
                whether it be Congressional or territorial, cannot lay taxes without uniformity, or apportionment, throughout the United States—territories being part of the United States—the name United States, in the language of Judge Marshall, being but the name of the "great Republic." By these words "throughout the United States," he decided territories were embraced.
              </p>
              <p className="p-in-sp">
                You cannot lay an indirect class of taxes without making it uniform, nor the "direct" without being apportioned and both equal to all the several States. If one is taxed, so all must be taxed either uniformly or apportioned. If the property touched by the tax laid by federal power is touched in the Territories, so it must be touched in the States; and if persons are touched by the
                direct tax in Territories so they must be proportionately touched throughout the United States; if touched in one State, so property and persons must be touched in all the States; and the result of the whole is that the federal power of taxation can be exerted by Congress alone. They must be laid by the primary and original power—by Congress. Why? Because the Territorial power,
                merely derivative, is a local power, and cannot apportion throughout the United States; because the Territorial power is a local power, and cannot control uniformity throughout the United States, Congress alone having the power to control uniformity and to apportion throughout the United States. Congress, and Congress only, <PageNumber num={238} /> can
                lay a tax direct or indirect. The consequence is that when the Northern States would impose a capitation tax upon negroes in a Territory, they would be obliged to lay it upon only three-fifths of the slaves, and upon the heads of every one of the whites of all the several States. That is the protection under the present Constitution, sir; that is the limitation that they cannot tax
                the heads of negroes in the territories without taxing the white heads per capita throughout the United States. Now tell me whether there is no limitation in that? and better protection in that than in this Peace Conference proposal, which must have been made either in gross ignorance or with the design to pervert the Constitution, and deprive us of the perfect protection it now
                affords.
              </p>
              <div className="speaker" id="sp1013"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">Mr. Chairman, I feel that I have come into collision, upon exceedingly unequal terms, on a subject like this, with a gentleman who has written a book upon it.</p>
              <div className="speaker" id="sp1014"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">Yes, sir; and I should like to have a pupil to instruct him in his A B C.</p>
              <div className="speaker" id="sp1015"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I have no doubt he would, and such a pupil as that I should suppose would be about the one he would like to teach. [Great laughter.]
                Mr. Chairman, I do not propose to detain the committee by the discussion of this law question. I will simply state my view in regard to it. There are two kinds of taxes that can be laid by Congress—indirect taxation, such as a tariff was required to be uniform everywhere, as you can see very obviously it should be. Then there is internal or direct taxation, either a capitation tax,
                or a tax upon property. To that tax the Constitution does not attach the condition of uniformity, but only says that the amount shall be limited as to each State by the proportion of its representation in the Congress of the United States. That is the only limit. The law of uniformity applies in terms only to propositions for indirect taxation. The law of apportionment is only
                applicable to the States, and the question cannot produce any decision going further than that. The case of Loughborough, to which he refers, is a case where only one point is decided, and that is that Congress is not bound to lay direct taxes within a territory, but if they do so they must apportion them as if it were a State, and the correlative point that if Congress lays a tariff
                that tariff must be uniform in its application to States and Territories.
              </p>
              <div className="speaker" id="sp1016"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I see I shall have to give my pupil another lesson.</p>
              <div className="speaker" id="sp1017"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">Very well. I hope I shall be able to go through it with becoming diligence and humility. [Laughter.]
                But here is another dilemma. Some gentlemen regard the use of the term "persons held to <PageNumber num={239} /> service or labor," by the Peace Conference, as in bad faith, as delusive, as a Black Republican trick, designed to give that party the power in the future to assail us. Mr. Chairman, what is the history of the use of the term in the
                Constitution, "persons held to service or labor?" It was introduced into the Convention that framed the Federal Constitution by the South Carolina delegation, who applied the term incorporated into the Constitution to slaves. Instead of calling them slaves, Mr. Butler, of South Carolina, moved in that Convention to call them "persons held to service or labor." And, sir, it is only a
                modern doctrine that slaves are regarded in the Constitution as property. Now, it is charged as an evidence of great unsoundness to the South to call slaves in their constitutional sense anything but property. We hear men denounced as abolitionists who dare to speak of what are designated in the Constitution as "persons held to service or labor," as "men with heads on their
                shoulders." I was glad to hear the gentleman from Princess Anne
                [<span className="persName">Mr. WISE</span>] , bear his testimony to the ancient construction of the Constitution, that slaves are persons—persons to be represented, persons to be taxed, persons in all respects, subject only to regulation as persons by the Government of the United States.</p>
              <p className="p-in-sp">
                Why, sir, when it was proposed to stop the inter-state slave trade under the authority of Congress to regulate commerce what was the refuge of the South? The South said "that slaves are not property under the constitution of the United States. They are property among ourselves, but in a constitutional sense, in the sense in which they are treated in the constitution of the United
                States, they are persons; the commercial power does not extend to persons, and we, therefore, claim exemption from the exercise of such a power." Yet, here, upon this floor, the Black Republican party is assailed, and the action of the Peace Conference was assailed by one of its members, in giving a history of it to his people, simply because they used the constitutional language of
                "persons held to service or labor." Well, sir, I do not wish to detain the Convention by going into any protracted discussion of this matter. I have been led into it farther than I would have gone by the interruption of the thread of my discourse.
              </p>
              <p className="p-in-sp">But, sir, there is another feature to which I must draw the attention of the Convention. The 7th section of the report of the Committee on Federal Relations, proposing amendments to the constitution of the United States, declares that</p>
              <p className="p-in-sp">"The elective franchise and the right to hold office, whether federal <PageNumber num={240} /> or territorial, shall not be exercised by persons who are of the African race."</p>
              <p className="p-in-sp">
                Now, sir, in regard to that, I must confess I do not like it. What is the ground upon which we hold our slaves in subjection? What is the justification, in the eyes of God and man, for holding these Africans in a state of bondage? The justification is complete, is unanswerable, when we say that they are an inferior race, incapable of equality with the Caucasians; that their
                inferiority and subordination is stamped upon their nature, by God himself, in their creation. That is our complete, triumphant justification, when we are called upon to declare why we keep them in slavery. Now, sir, I do not want it to be put into the Constitution of the United States. I do not want anybody to imagine that we fear the elevation of that race to be our equals. I do
                not want put into the Constitution any provision that may look like it.
              </p>
              <p className="p-in-sp">
                I believe, however, in the Committee which reported that provision, there were but few dissenting voices, and I suppose therefore that my view is somewhat peculiar; but I must acknowledge, as I have already said, that I have a strong repugnance to having that inserted as an article in the Constitution. I do not want any man to suppose that there is apprehension of the slave becoming
                equal to his master or a free negro becoming equal to the white man.
              </p>
              <p className="p-in-sp">
                But it is said in this Convention and elsewhere, that there is a party in the United States, a pre-dominant party, in favor of the equality of the races. Sir, I do not believe it. I have no idea that there is any such party, except, perhaps, some poor, miserable, crazy fanatics, a handful here and there. That there is any large party of white people in the United States, or in any
                part of it, who believe in, who assert, or recognize the equality of the races, I do not believe. Why, sir, it is known to us all, that if you go North, among the free States, you find the distinction drawn with a sterner hand than it is here. Here there is generally a mutual sympathy, a mutual respect and affection between the master and the slave; and even the poor free negro, who,
                for want of a master, is treated by a great many people as if he had no friends, who hangs, in his defenceless and friendless condition, around our kitchens, to gather the crumbs that fall from the table of the slave —even the free negro in this country has the sympathy of somebody. But in the North he has the sympathy of no one. He is an oppressed, down trodden, over-ridden
                creature. With these facts staring us in the face, when gentlemen come here and tell us that the great majority of <PageNumber num={241} /> the people of the North intend to assert the doctrine of the equality of the races, it amuses me. We know it cannot be so.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, these are the main provisions about which I shall comment, in this plan of amendment. It will be seen, as I said in respect to the Peace Conference proposition, that this plan of the Committee covers the entire scope of the subjects about which it is supposed that amendments to the Constitution are necessary. It embraces every feature of the Crittenden amendment, and
                substantially every feature of the propositions of the Peace Conference. We had, from the distinguished gentleman from Charles City
                [<span className="persName">Mr. TYLER</span>] , criticisms upon the Peace Conference propositions, upon their uncertainty and their vagueness of meaning. And we have had since, that from my distinguished friend from the city of Richmond [<span className="persName">Mr. RANDOLPH</span>] , a most elaborate criticism in regard to their construction.</p>
              <p className="p-in-sp">
                Now, the Committee on Federal Relations, without admitting the correctness of the criticism upon the Peace Conference propositions, urged mainly on the ground of doubt as to its true construction, yet, in deference to the criticisms of enlightened gentlemen, such as have examined it upon this floor, have undertaken to correct the phraseology in the points to which objections have
                been made. I have no hope of securing the support of my friend from the city of Richmond
                [<span className="persName">Mr. RANDOLPH</span>] to this proposition, because he distinctly informed us that he thought it objectionable for want of Constitutional guarantees of power. I, therefore, give up the hope of his support. But may I not, with confidence, call upon the distinguished gentleman from Charles City [<span className="persName">Mr. TYLER</span>]
                , who made no objection to it upon that ground, but who, if I understood him, voted against the propositions of the Peace Conference because of the vagueness of expression in certain particulars, which have now been corrected. May I not ask him, now that every exception taken by him has been removed, now that every doubtful point has been made plain, to come and give us the benefit
                of his approval of amendments which are due in no small degree to his enlightened criticism upon the plan of the Peace Conference? It seems to me we are entitled to it. He makes his objections and we remove them; we remove them in a spirit of deference, in a spirit of harmony, in a spirit of conciliation, for the purpose of uniting all who criticise for the purpose of improving; and
                I think we have the right now to claim the support of that distinguished gentleman.
              </p>
              <p className="p-in-sp">
                I will not detain the committee longer upon this subject. That is the plan which we propose of amendments to the Constitution. We propose to refer that plan to a conference of the border States, to be held at the <PageNumber num={242} /> city of Frankfort. We do not propose to declare an ultimatum bill, because we do not conceive that it would be
                respectful to our sister States whom we invite into the conference, to declare here in advance what is our ultimatum. It would be going, as I remarked the other day, into a consultation with our written opinion in our pocket. And, therefore, I believe there is no plan for propounding these matters as an ultimatum.
              </p>
              <p className="p-in-sp">
                But we are told that we are to go into this proposed conference and give up our opinion to the will of the majority. No body has proposed that. For one I say, that while Virginia ought not to act finally in this matter without regarding the wishes, the feelings and interests of her sister States, yet I will never consent to commit her honor to any conference whatever. I would retain
                it in the hands of this constituent assemblage of sovereigns, in the hands of our constituents themselves at the polls. I will never consent that any body else shall determine for Virginia what is to make for her best interests, her highest honor and her greatest safety. I would retain for ourselves the decision of that question. But if we get this plan, if it answers the purpose I
                have suggested, if it secures us against what we have apprehended in the past, against what we apprehend in the future, if it covers up completely the whole subject of this unfortunate controversy to the satisfaction of the slave States now remaining in the Union, will it not give satisfaction to those which have gone out? Will it not bring them back? Sir, I do not know the condition
                of affairs, or of public sentiment in those States, but I do know that up to the time of their secession there was in all of them, except South Carolina, a Union party so strong that it was doubtful down to the last moment, what course would be taken; and I think I have a hint, at least, from the careful manner in which the Conventions of those States refrain from the submission of
                their action to the popular approval, that there is an apprehension that their action may not meet the sanction of the constituent body at their homes and at their firesides. But, however that may be I shall be exceedingly slow to believe that when we 'have settled to our own satisfaction and to the satisfaction of the border States, when we have declared that our honor is satisfied,
                that our rights are safe, that our interests are protected, when the seceded States come to look the future sternly in the face, when they come to consider their relative positions toward us and toward the outer world, when they thinke of the glorious past in connexion with this mighty Union, when they think of the flag, in which the gentleman who last preceded me
                [<span className="persName">Mr. HOLCOMBE</span>] , can see nothing but stripes, when they think of the Stars and Stripes, when <PageNumber num={243} /> they think of the old associations of interest and affection connected with this Union, I will not believe that they will consent to remain outside of it.</p>
              <p className="p-in-sp">
                But, suppose they will not return. What then? Are we to submit our judgment to their passion—our interests to their caprice—our conduct to their direction—our honor to their keeping? I think not. I think the duty devolves upon us to take care of our own rights, our own interests and our own honor. It seems to be thought here, that it is a clear proposition that our interests are all
                with the Cotton States—that our interests, commercial, manufacturing, industrial—of all sorts carry us South. Sir, I do not know what are the interests of the manufacturing portion of the community; I know very little about the peculiar industrial pursuits that are followed in the cities; but I do know something about the pursuits of my constituents, who are an agricultural people.
                Now let us consider their interests in connection with this matter of going with the Southern Confederacy, or of staying with the present Union. What do my constituents, and in fact a large majority of the people of Virginia, raise for sale? What is it that they send to market as a means of livelihood? Why, sir, breadstuffs, cattle, horses, mules, and all sorts of live stock are the
                products of my people and of a large majority of the people represented on this floor. Now, the Cotton States do not raise any of the products. They want to buy them all.
              </p>
              <p className="p-in-sp">
                The gentleman from Richmond city presented that fact as an argument to show that it is our interest to go with the Cotton States, because we want to sell to them and they want to buy from us. That is not a legitimate argument. They want to buy these products for they are necessaries of life to them, so much so, as to have led them in laying down their tariff, to admit them all duty
                free; and they are bound to do so always. Do you suppose that the Cotton States that left this Union mainly upon the tariff question—mainly to get rid of what they regarded as oppressive taxation upon articles of prime necessity—do you suppose they are going to establish at the outset of their government a system of heavy duties and imposts upon importation of the very necessaries of
                life—that they are going to tax their own bread and meat? I hardly think it will be expected. On the contrary, if free trade is to exist any where it must be in the Confederate States on all these articles which are of prime necessity to them.
              </p>
              <p className="p-in-sp">
                If that is the case and we have free access to their market to sell all that we raise, why should we go with them to get the benefit of the market? Suppose we go with them, and a line of custom houses is <PageNumber num={244} /> established between us and the people of the North. They are, many of them, a grain-growing, cattle raising people, coming
                directly into competition with us, and able to raise supplies sufficient for themselves. They must, at once, adopt a protective policy, and cut us off from the Northern market for all these articles in order to protect large interests in their own community. So that by going with the Southern Confederacy, we interpose a tariff between us and our Northern customers. By staying as we
                are, we maintain free trade with our customers North and South. I don't know what is the state of trade between Eastern Virginia and the Cotton States; but I know that the great trade of our valley and of trans-Alleghany is with the North in regard to all these articles of prime supplies. I know that our graziers and feeders send their cattle to Maryland and Pennsylvania, from whence
                they are carried on to the New York market. I do not know the other places they may supply after they get there ; but I know that this is the route which most, if not all, of the products of the valley and of Western Virginia take.
              </p>
              <p className="p-in-sp">
                Then, sir, we are asked to cut ourselves off from our best customers and interpose a protective tariff between us and them by going with the Cotton States. This, it seems to me, would occasion peculiar disadvantage in respect to almost every article raised in Virginia. I am not sufficiently acquainted with the tobacco trade to say how that great staple of Virginia would be affected
                by a matter of this sort.
              </p>
              <p className="p-in-sp">It was said the other day, by the gentleman from Richmond city [<span className="persName">Mr. RANDOLPH</span>]
                , that the tariff of the Southern Confederacy admitted leaf tobacco free of duty, and imposed twenty per cent. duty upon manufactured tobacco. I don't know how that fact is—I doubt not he regards it as authentic—but this I will say, that the constitution of the Confederate States expressly prohibits the laying of a duty for protection and requires all duties to be laid for revenue.
                If this new Confederacy has started out thus early with laying a most striking tariff for the protection of the tobacco manufacturers they give thereby but a poor earnest of that exact adherence to strict constitutional construction which we are told is so high a recommendation in favor of our joining them.
              </p>
              <p className="p-in-sp">In regard to manufactures, I have no statistics, no acquaintance with the subject sufficient to enable me to discuss it in its details. But there is one general view which I shall present to the Committee in this Convention.</p>
              <p className="p-in-sp">Why, sir, the people of this country have been for years fencing high against the ruinous competition of European manufacturers.</p>
              <PageNumber num={245} />
              <p className="p-in-sp">
                Every time the tariff was brought down, ruin overtook our manufacturers and we saw utter destruction attending every step in the line of free trade. But now, sir, we are told that there is nothing to apprehend from foreign manufacturers—that the ruinous competition comes from the Yankees. I thought it was an admitted fact that if the United States were now to embark in free trade,
                the Yankee would soon be compelled, by the competition of European manufacturers, to give up their business. There is some dispute as to how high the duty ought to be. I thought it was universally conceded that a duty of 25 or 30 or 40 per cent. upon the leading articles of the manufactures of the United States, was essential to the protection of those engaged in manufacturing
                against the ruinous competition of the pauper labor of Europe. If that be true—if it be true that the Yankees cannot stand against the foreigner, and that we cannot stand against the Yankees. what chance will there be for us at the South, if we shut out the North and let in the foreigner. If Virginia has not been able to compete with the North under an equal, high, protective 30 per
                cent. tariff, how do you expect our manufacturers to thrive upon a duty of only 10 per cent, against Europe? It seems to me that it is an attempt to combine protection with free trade. They cannot be combined. You cannot protect against the North while you have free trade with Europe, because the very failure to protect against Europe, will render all your protection against the
                North utterly futile and worthless.
              </p>
              <p className="p-in-sp">
                Sir, it will not do to attempt either. Suppose we join the South; then we come under the influence of this new competition. We get rid, it is true, of the Yankee competition—a mere drop in the bucket—and we receive the whole ocean of European competition flowing in upon us. But it is said that when we go into this new Confederacy, we will have the power to control matters; that
                Virginia will be the great directing spirit, and that we can introduce a protective system, under which we are to become the Yankees of the South. Sir, I do not concur at all, in this idea. I have no sympathy for the indiscriminate denunciation that is heaped upon our Yankee brethren ; none, whatever. I look upon them as a noble race of men, with all their faults and foibles. I am
                not one of those who want to cut them off from this Union. I am not one of those who want to get rid of the Yankee States. or of even the least one of them. I want to hold them all as valuable, extremely valuable members of this glorious Confederacy of ours, by their energy, and their intelligence, in all the departments of industry and art, of science and literature, doing honor to
                our name, whenever it is pronounced. I don't want to get rid of them; but I don't want to <PageNumber num={246} /> introduce the Yankee system of industry into Virginia. I prefer ow system of industry. I prefer our agricultural pursuits—our cattle feeding and our stock raising. I don't want our people to be a manufacturing people. I don't want to be
                overrun here with these immense corporations, which my friend from Richmond city
                [<span className="persName">Mr. RANDOLPH</span>]
                , referred to as being a curse to the country and an injury to our trade. I don't want our people to be the Yankees of the South. But suppose we were disposed to be the Yankees of the South, do we expect South Carolina, who quit us before because of a protective tariff, to establish a protective tariff for us? Do we expect that South Carolina will change her views on the subject of
                tariffs with the same facility that it is supposed Virginia will change hers? I humbly apprehend that South Carolina, having found secession a remedy for high tariffs, will resort to it again the very moment that it is necessary for her economy. Would it not be the wildest and most unreasonable course for Virginia to follow after such a State as that? She would lead us, step by step,
                like an ignis fatuus while in search of some stable and secure form of government, into the quicksands and quagmires of secession, disunion and anarchy.
              </p>
              <p className="p-in-sp">
                Sir, I used to be a believer in the doctrine that is agitated here by the gentleman from the city of Richmond. I understood that everything depended upon protection. I used to have an idea that government was a great protecting machine, that was to be put in operation and grease the wheels of all industry. That was my idea of government many years ago; but I had soon occasion to
                change them very materially. I have come to believe that while the true province of government is protection, it is protection of right, protection of the rights of the individual against encroachments from all quarters, and as far as is consistent with the protection of all to secure to the individual the right to seek his fortune in the way that his individual interest and his
                intelligence may suggest to him. That is the school of philosophy to which I profess to belong. I recognize the imposition of a protective tariff as a necessity, growing out of the fact that there are different nations; that it is necessary for the purpose of asserting the individuality of the nations ; of giving each a separate existence among the nations of the earth, and securing
                to each such elements of self-defence and self-protection as are necessary to her as a member of the family of nations. But I rejoice whenever I hear that one of these obstructions to free trade is broken down; I rejoice, whenever I hear that two people have come together and extended the area of free trade. Sir, it has been one of the great delights of my heart, to look
                <PageNumber num={247} /> over this broad land of ours and to think that here was the finest theatre that ever was presented for developing the principles of free trade, individual enterprise and individual rights, as it is for everything that concerns the interest and the happiness of mankind. But now it seems that this great principle is to be
                thwarted; that a line is to be drawn across this magnificent theatre, and that we are to have tariff and custom house lines, to change the intercourse between man and man, and section and section. Sir, I protest against breaking up this great experiment of free trade in process of splendid developement. I don't want to see it broken up by petty rivalries and jealousies, by custom
                house restrictions and tariff regulations.
              </p>
              <p className="p-in-sp">
                I would rejoice to see our country, in all respects, socially, industrially, politically united. Can this ever be accomplished? They say it cannot. I must confess that when I look along the path which my sense of duty calls me to walk in, and see the number of steps that are required for the accomplishment of the great object of my hopes, my spirit oftentimes quails within me and I
                tremble with apprehension, lest I may fall short of the great result. I can only move on in humble confidence—that if I do my duty, if I perform well at every step the patriotic service which under a sense of the most solemn responsibility I have undertaken ; if I act well my part, and take each step with a just regard to the rights of all and with a just sense of responsibility to
                God and man, I may safely leave the result in the hands of the Great Disposer of events. Sir, I intend to march in that path of duty. I intend to look neither to the right nor to the left for smiles of approbation or words of encouragement. I intend not to be driven back by frowns of opposition or threats of intimidation; but sustained by my own sense of right and my own conviction
                of patriotic duty, I shall march forward and leave the result to God.
              </p>
              <div className="stage it">[Applause.]</div>
              <div className="speaker" id="sp1018"><span className="persName">Mr. BRUCE</span>, of Halifax, said :</div>
              <p className="p-in-sp">
                Mr. Chairman, Who can shape his destiny or command his fate? If I had been told three months ago that I should have been in this presence, and addressing you, sir, upon these overwhelming subjects which are before you, I should have listened to the statement with incredulity and surprise. Buried for twenty-five years, a curious antiquary in the county where I reside disinterred me,
                and presenting me before my constituents, they thought they saw in the fossil before them something that might enter into the construction of a break-water which might arrest the wave of agitation that was sweeping so disastrously over every part of our land. My constituents, Mr. Chairman, are lovers of peace and quiet, and what they considered the blessings of this Union. I
                concurred with them ; <PageNumber num={248} /> but, at the same time they gave me no instructions even for those inestimable jewels, to barter away their honor or their independence. I believe if my constituents could stand here as I do, that their faith in this Union would be much disturbed. I told them that I would exhaust all probable means of
                redress before I would carry them out of this Union. I proposed to them that I would wait for the Peace Conference; that I would wait for the inauguration of Abraham Lincoln and the policy which he might pursue. I have waited. Does any one in the sound of my voice see any hope for the future? I confess that my last hope is gone, and I can see nothing before me to kindle hope or drive
                away despair.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I am a States Rights' man. I was a States Rights' man in 1833. Do you remember that period? South Carolina had nullified a law of Congress—a law which she believed was oppressive to her. Sir, I stood at that day, with forty other gentlemen, in the Virginia Assembly, and did what I could to protect her. I held up my shield before her, but it was a frail thing of
                gossamer, and merely served to show my good will. I did what I could. I see but two of that band of forty before me. There is my honored friend from Montgomery
                [<span className="persName">Mr. PRESTON</span>] . He stood, at that time, shoulder to shoulder with me in vindication of South Carolina; he was not in favor of trampling her in the dust. There is another friend, the gentleman from Henry [<span className="persName">Mr. GRAVELY</span>]
                , who stood up at that time as a gallant defender of States Rights—and I know where those two gentlemen will be found now. They will be on the side of State Rights. They will be on the side of the Constitution; under all circumstances they will be on the side of liberty. In 1833 General Jackson was in the Chair of State. He rolled, like another Juggernaut, his car throughout the
                Union, crushing out every thing like States Rights in Virginia, and the whole country; and following this car there was such a shout from the Passamaquoddy to the Gulf of Mexico as has never been heard since that which rose around the walls of Jerusalem when the Savior of mankind was expiating the sins of the world by his death on the cross. The cry went forth—"crucify her, crucify
                her;" and I am pleased to find many gentlemen in our midst who then joined in this cry, now at the top of their voices crying hallelujah.
                [Laughter.] There is my friend from Greene and Orange [<span className="persName">Mr. MORTON</span>] he has become a convert to the doctrine of States Rights. I am glad to see him abjuring his errors and professing the true faith, and under the frost which time has showered upon his head, if you will examine closely, you will find penitential ashes. [Laughter.] I will tell you, Mr. Chairman, in confidence, <PageNumber num={249} /> that next to his skin he wears a shirt of the coarsest sack-cloth. [Laughter.] And, sir, there is my friend from Princess Anne [<span className="persName">Mr. WISE</span>] , whom I hold in such high esteem and whom I admire so much for his manliness and talents, that I would have been glad at that day to have shaken him by the hand and called him friend.</p>
              <div className="speaker" id="sp1019"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Will you allow me but one word, and that is, that I never did at that day, cry out "crucify South Carolina," and if ever an army had invaded South Carolina, she would have found me by her side.</p>
              <div className="speaker" id="sp1020"><span className="persName">Mr. BRUCE</span>—</div>
              <p className="p-in-sp">I know it, but still he has been a persecutor of the saints. I know he has undergone a godly, thorough reformation, and that the scales have fallen from his eyes, and whereas he was once Saul of Tarsus, the persecutor, he now stands before us, Paul, the Apostle, and at his feet I lay my homage and my reverence, and on bended knees pray for his apostolic blessing.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp1021"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">And I would give it with ointment.</p>
              <div className="stage it">[Renewed laughter.]</div>
              <div className="speaker" id="sp1022"><span className="persName">Mr. BRUCE</span>—</div>
              <p className="p-in-sp">Then, Mr. Chairman, here is another friend of mine, the gentleman from Rockbridge [Mr. MOORE]
                —a gentleman whom I have always known where to find. But he is so encrusted with political sins, that I have seen an hundred arrows shot at him, and yet they seemed to glance from him like thistledown. If I can see one crack in his harness, one broken link in his coat of mail, I would send an arrow at him, hoping to reach some vulnerable point. I know the gentleman is rather
                belligerent, and will never be a member of a peaceful church, but ours may soon be a church militant, and then he will come in armed from head to foot, to the very teeth, ready to do or die in the cause of Virginia. He does not like South Carolina, and I believe South Carolina does not like him; but, sir, I intend to have them welded together like another Benedict and Beatrice, and I
                am sure they will live together in peace and happiness.
              </p>
              <p className="p-in-sp">Mr. Chairman, is my friend from Amelia [<span className="persName">Mr. HARVIE</span>] . here? He has always been a good soldier and fought well. He did so in the year 1833. I had not the honor of his company in the Legislature, but I knew him as a man, and though he may have fallen occasionally into bad company since, I think he has come out untouched. I give him the right hand of fellowship.</p>
              <p className="p-in-sp">
                And, sir, there is another gentleman, the honored gentleman by my side, who has just closed his most eloquent address. I wish I could say I had some hope of him; but I am afraid I shall have to say to him, "Ephraim is joined to his idols; let him alone." But I trust that Ephraim will not vex Judah and that Judah will not vex Ephraim ;
                <PageNumber num={250} /> and that he may go hand in hand in this great cause which Virginia is calling upon us to defend. I listened to the gentleman day before yesterday in the few remarks he made. Yesterday, desirous as I was to hear him, for knowing his power and his manliness, it would have afforded me the highest satisfaction, I was taken sick and
                could not be in the Convention. I was glad to hear, however, that the gentleman said he was in favor of slavery, and that with rapt vision he seemed to see it in the distance increasing until it should cover the earth as the waters cover the great deep. I would like to know whether he confined himself to Africa.
                [Laughter.]
                Well, sir, Africa would be drained and there would not be a black face left there, if his desire should be consummated. The gentleman's catholicity is as unquestionable as it is comprehensive. We should have no white men, and the whole world would be Africanized. Every vessel upon the face of the earth would wear a black flag and would be engaged in the slave trade. Is he in favor of
                opening the slave trade to all the ships of the world, for to realize his vision this must be necessary.
              </p>
              <p className="p-in-sp">
                There was another position which the gentleman took. He does not cross the line of my argument, which lies in a different direction, but I beg leave merely to remark that the gentleman took the ground here that Virginia belonged to a majority, which always controlled in Congress, the subject of slavery. Is that your position? If this be true, and the gentleman is not disposed to join
                the States' Rights party, the position that he took, is a most dangerous one, for the cause which he advocates. I recollect that the infidels of the last century, urged that the Old Testament did not teach the reality of a future state, that Bishop Warburton admitted the whole of it, and upon that admission built one of the most luminous and powerful arguments in favor of the
                Divinity of the Scriptures. So on the position, so elaborately argued and so ably sustained by the gentleman, may be drawn the inevitable inference, that Virginia has no other security but in her own inalienable sovereignty.
              </p>
              <p className="p-in-sp">
                The gentleman's argument was a very strong one for the position I assume, and I was about to take the same line of argument. I was going to prove that Virginia and all the States of the South had used every effort to succeed in their legislation for the purpose of protecting slavery; but they had not succeeded. The argument is irresistible. I want nothing stronger. Virginia had the
                power of legislation and Virginia has failed to arrest the progress of fanaticism. The abolition feeling has been gathering strength in the country and if Virginia and all the Southern States, with the power of legislation in their hands <PageNumber num={251} /> could not restrain it, what will it be when the power has gone from them to the Republican
                party? The wrongs of Virginia are immedicable under any process of Legislation, even when this Legislation is in the hands of the South. In what nook of the Federal Constitution can Virginia secure herself from the aggression of a full grown abolitionism? I know of none—there is none.
              </p>
              <p className="p-in-sp">Mr. Chairman, I have no disposition to follow the argument of the gentleman any further on this branch of the subject for fear of marring it. He has amplified and illustrated it with so much ability that I shelter myself under it with a sense of security which I could not feel under any argument of my own.</p>
              <p className="p-in-sp">
                Mr. Chairman, I wish to say to this Committee that this subject of slavery is not a question of dollars and cents. After this Colony was settled on the banks of the river at Jamestown, a Dutch ship came in from the coast of Africa with a cargo of slaves. They were bought by the early colonists. They all labored together, they grew together and they increased together; they sat by
                their hearthstones, were the nurses of their infancy, the companions of their childhood and the consolation of their declining years, and they are now so inter-twined with our social habits and interests, and laws, that to sever the connection must be political death even if done with the tenderest hand.
              </p>
              <p className="p-in-sp">
                But, sir, the North have not dealt with this subject with a tender hand. Far from it. They have dealt with it with a rude hand. We find that this institution is distinctly recognized in the Constitution of the United States, and the surrender of fugitive slaves as guaranteed was honestly, and fairly and justly carried out by the first Congress in 1789. They enacted a law sir, to the
                effect that upon the application of a master, he could get a warrant from a Judge or Justice of the Peace, and the fugitives were to be surrendered to their masters. And fugitives were surrendered to their masters, until, in an evil hour, a case came before the judiciary of the United States, in which a decision was given which says, it is not the duty of State officers to execute
                the law of Congress. From that time, sir, it was made a felony on the part of a State officer to arrest a fugitive slave; and we lost all chance of recovering the fugitive for years; and when a slave escaped, his master looked upon him as much beyond his reach as if he were dead. That, sir, was a distinct and solemn violation of the Constitution.
              </p>
              <p className="p-in-sp">
                But this is not the point. It is that the hatred of our Southern institutions and our system of slavery, is deeply, irradicably ingrafted into the minds of the Northern people. It is an opinion, sir, which has been deeply fastened there, and an opinion which, I fear, can never <PageNumber num={252} /> be eradicated. Sir, the children in their primers
                see a print of the slaveholder with scourge in hand, and the suffering negro in chains at his feet. Every kind of effort is made to indoctrinate into the Northern mind the sentiments of abolitionism. The press, the pulpit, the school house—all are made subservient to its purpose, until their whole country, in spite of this favorable legislation, which is alluded to by the gentleman
                from Augusta
                [<span className="persName">Mr. BALDWIN</span>] , has become overspread with those abolition sentiments. And, Mr. Chairman, whenever there occurs a war of opinion, that war will never stop; it will go on from time to time ; it will increase in volume and either one or the other of the parties must submit or must be conquered. A war of opinion is sure, in the long run, to be a war of the sword.</p>
              <p className="p-in-sp">
                And what is the remedy sir? "If thy brother trespass against thee, tell him of his faults between thee and him alone. If he hear thee thou hast gained thy brother. If he will not hear thee"—what next? "Take two witnesses before him, and if he will not hear them, tell it to the church; and if he will not hear the church"—what then? "Let him be unto thee as a heathen man and a
                publican." We have whispered our complaint into the ears of our Northern brethren; we have told them of their fault in the presence of the world for witnesses; we have brought them before the church and they have divided that church in twain and told us to begone. What is the remedy? God speaks it by the mouth of His Incarnate Son: "Let them be unto thee as heathen men and
                publicans." I know of no other conclusion to come to; yet, I for one, in order to give my brethren another opportunity, am willing to make one other effort, and it shall be in the nature of an ultimatum put forth by Virginia, in her sovereign capacity. This is the ground I would occupy. I would give them an opportunity of repenting of their trespasses, but further than that I will
                never go.
              </p>
              <p className="p-in-sp">Mr. Chairman, I propose now to enter upon a very delicate task. I propose now, sir—I hope no gentleman will be startled—to enquire what really is the value of the Union.</p>
              <p className="p-in-sp">
                Mr. Chairman, ever since the day when Constantine, at the commencement of the fourth century, made an alliance with the church there seemed to be an impression that there was almost a divinity not only at the head of Virginia but at the head of the Government whether Republican or Democratic. Sir, the Roman Empire in the person of Constantine shielded the church, protected it; and
                the church poured the sacred oil upon the head of the Roman Emperor, and declared to the subjects that he stood in the place of God, and that passive obedience was due him; and, sir, although we have no heathen ruler, <PageNumber num={253} /> yet there is divinity among us in the shape of the Union, and it is considered almost irreverent for us to enter
                into a calculation of its advantages to our people. But, sir, I hope that this Convention, and every one in it, will bear with me while I enter into this investigation. And, sir, I know that the North has calculated the value of this Union. She knows how many eggs the Southern goose has laid into her lap. She has estimated it, she has weighed it, not in the balances of the sanctuary,
                but in the scales of her own interest, and more unerring scales were never established by the High Priest in the temple of Jerusalem.
              </p>
              <p className="p-in-sp">Mr. Chairman, I ask what has this Confederacy done for us? I ask it, sir, with simplicity; I ask it in truth; and I want to know what good thing this Confederacy has ever done for us? I don't mean to say that good things have not been done, and that we are not deriving blessings from it, but I want to know what these good things are?</p>
              <p className="p-in-sp">
                Sir, we are told that we have protection abroad, and that when an individual goes abroad, he has only to pronounce the magic word, "I am an American citizen," and his honor and his rights are protected. We know the influence of the magic word in ancient times, "I am a Roman citizen." It secured protection and honor in every satrapy of Persia, in the boundaries of Carthage, and in the
                most distant regions of Africa. But, sir, when the slaveholder says "I am an American citizen," I know that his goods are protected, I know that his person is saved from incarceration, I know that he is protected from robbery, but, sir, when he says "I am an American citizen," the European turns upon him and says, "but you are a slaveholder," and the sneer, the shrug of the
                shoulders, the uplifting of the brow, and the pushing out of the lip, are all marks of contempt; and, so help me God, I would rather be scourged and imprisoned than have such marks of contempt showered upon me. The one touches the body, the other touches the soul.
              </p>
              <p className="p-in-sp">
                And, sir, why? It is the teaching of the Northern people, the teaching of the Northern press, the teaching of the Northern traveller. They all profess to be ashamed of us when they go abroad ; and they look upon slavery as a scab, as a running sore, and they throw contempt upon us in every way. Is this the protection that a great people require under the flag of a great Union? No,
                sir; no!
              </p>
              <p className="p-in-sp">
                And how shall we get rid of this contempt? There is but one way, and it does appear to me that it must be in the assumption of our own nationality. Why, sir, when we have our own nationality, when we have our own flag, when we show that by our own wise government, our firm <PageNumber num={254} /> bearing, our virtue, our religion, and the bravery with
                which we uphold our liberty, then, sir, and then alone, will we have that security to our persons and our honor, and our feelings which we are so eminently entitled to; and any Government that fails to protect any part of its people from this encroachment, fails in the great purpose for which a Government was designed. I want to have our honor protected, which is far more precious
                than that of person or property. And what is the effect? Why, sir, the contempt showered upon us abroad re-acts upon us at home. We have become degraded in our own estimation, and I do believe, unless this thing can be arrested, we will become the patient subjects of arbitrary rule.
              </p>
              <p className="p-in-sp">But, sir, we are told again that we are protected in our property, that our commerce is protected by the United States flag. Alas! alas! Mr. Chairman, we have no commerce. The commerce of the country is in the hands of the North; the shipping of the country is in the hands of the North; and we have no commerce to protect.</p>
              <p className="p-in-sp">
                O, but we are told, our agriculture is protected. How? where? when? Does our cotton need protection? Does our wheat need protection? Not a bit of it. Does our rice need protection? We have a monopoly of the rice trade. Our tobacco needs no protection. But we are told that Southern sugar needs protection. Mr. Chairman, I do honestly believe that if there never had been any protection
                to Southern sugar the South would have been better off than she is to-day. The cane is a forced plant in Louisiana; and she would, in the absence of protection, have engaged in the more profitable culture of cotton. The Tariff gives to the South three-quarters of one per cent per pound on Muscovado sugar, and the Muscovado or brown sugar is the only sugar made in Louisiana. Let us
                follow this raw material until it comes to the Northern manufactories; and then, sir, we see that white sugar, which is almost entirely in the hands of the North, receives a protection of two cents, and when they, by a little more manipulation, convert it into candy, it receives four cents; and thus we find upon this article of sugar the North gets four times the protection of the
                South.
              </p>
              <p className="p-in-sp">
                Well, sir, I do not propose to go into the discussion of this question of a tariff, it would lead me into an endless debate; but I do propose to mention one other article—that of wool. It is for the interest of the Northern manufacturer to secure the importation of the raw material used in his manufacture, free of duty, unless it comes into competition with the producer of the North.
                Let me call your attention, then, to the duties laid upon this article of wool. The duty laid upon wool worth eighteen cents per pound is ninety-one hundredths of a cent per <PageNumber num={255} /> pound. There is no such wool grown in the North. It comes from Australia, from Smyrna, and from various other countries. It does not interfere, to any
                extent, with the Northern wool-grower. But when wool is worth from 18 to 24 cents per pound, there is a duty of three cents. This limit just grazes the products of the North. But when the article ranges from 24 to 30 cents, which embraces the great body of Northern wool, there is a tax of nine cents per pound. The Northern wool-growers use Saxony sheep and Merino sheep, producing the
                finest quality of wool; and they protect themselves to the extent of nine cents, while for wool grown to a considerable extent in the South, but not at all in the North, they levy a duty of ninety-one hundredths of a cent per pound. Thus, every interest at the North is protected, while not one solitary one at the South is protected, unless it so happens that they are obliged to
                protect us incidentally, in order to protect themselves.
              </p>
              <p className="p-in-sp">
                But we are told that this Union will protect us against encroachments upon our borders; that if the Union is dissolved there will be constant marauding across our borders. Sir, we are a civilized people and such marauding expeditions would not be tolerated for a moment. I have no doubt there would be perfect quiet upon our borders as there is now upon the Canada borders. Civilized
                people could not tolerate such raids and would not tolerate them. But my friend from Albemarle
                [<span className="persName">Mr. HOLCOMBE</span>]
                , has treated this subject so fully, so clearly, so beautifully, that I am not disposed to mar his argument by any effort to encroach upon it by an argument of my own. I confess that this idea of being protected from the North grates harshly upon my feelings! The liberties of the South to seek protection under the flag of the Union for fear that they may be invaded by our Northern
                brethren. Liberty! that child of storm, rocked on earthquakes, the inhabitant of mountain top, where
              </p>
              <p className="p-in-sp">"From crag to crag leaps the live thunder,"</p>
              <p className="p-in-sp">
                that this liberty should crouch and creep under the folds of a Northern robe for protection. Such liberty as this I scorn; I trample it under foot; I spit upon it. This is not the liberty of our fathers. No, sir; "the price of liberty is eternal vigilance!" If it sleeps, it sleeps a sleep that knows no waking. I hope that no gentleman will overrate the advantages the border States
                will derive from protection from the North.
              </p>
              <p>Before <span className="persName">Mr. BRUCE</span> had concluded his remarks, he gave way to <span className="persName">Mr. MORTON</span> who moved that the Committee rise.</p>
              <div className="speaker" id="sp1023"><span className="persName">Mr. BRUCE</span>—</div>
              <p className="p-in-sp">It is very unpleasant for me to speak upon a second <PageNumber num={256} /> day. My constituents, I am afraid, would never forgive me if I were to speak two days.</p>
              <div className="speaker" id="sp1024"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I must insist upon my motion. The gentleman has evidently not the strength to go on longer.</p>
              <p>The motion was agreed to. The Committee accordingly rose and the PRESIDENT, having resumed the Chair, the Chairman [<span className="persName">Mr. SOUTHALL</span>] , reported, the Committee of the Whole, according to order, had the report of the Committee on Federal Relations under consideration, and had come to no resolution thereon.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.6.7">
              <h3><span className="headingNumber">1.6.7. </span><span className="head">PUBLIC MEETING IN CULPEPER</span></h3>
              <div className="speaker" id="sp1025"><span className="persName">Mr. BARBOUR</span>, of Culpeper—</div>
              <p className="p-in-sp">I am requested by the resolutions of a public meeting in my county, to present these resolutions. I have lost the official copy of them, and I offer them as I find them in the newspapers. I ask that they may be read, laid on the table, and ordered to be printed.</p>
              <p>The resolutions were read as follows:</p>
              <p>"H. Shackleford, Esq., rose, and after a brief but stirring and eloquent speech, offered the following resolutions, which were adopted—the first with one dissenting voice, and the second with three or four:</p>
              <p>"1st. Resolved, That we do not approve the propositions of the Peace Conference.</p>
              <p>"2d. Resolved, That the Convention of Virginia ought to adopt an ordinance of secession.</p>
              <p>"3d. Resolved, That a copy of these resolutions be sent to our delegate, to be presented to the Convention, and that they be published in the Culpeper and Richmond newspapers."</p>
              <div className="speaker" id="sp1026"><span className="persName">Mr. BARBOUR</span>, of Culpeper—</div>
              <p className="p-in-sp">The first of these resolutions, declaring that my constituents were not satisfied with the resolutions of the Peace Conference, was adopted with but one dissenting voice, in the largest gathering of the people, I presume, ever held in the county.</p>
              <p className="p-in-sp">The second resolution, instructing me to vote for an ordinance of secession, was adopted with but three or four dissenting voices. Recognizing, sir, as I do fully, in my present capacity, the right of my constituents to direct my vote here, I shall, with pleasure, conform to the instructions given to me.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.6.8">
              <h3><span className="headingNumber">1.6.8. </span><span className="head">PRESENTATION OF A WREATH</span></h3>
              <div className="speaker" id="sp1027"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">
                I desire to make the motion that the Convention adjourn, but before submitting that motion I desire to announce to the Convention that some of the ladies of the city of Richmond desire to <PageNumber num={257} /> pay a compliment to a member of the Convention, and that as the ceremonies connected with that compliment will take place immediately, it is
                requested that gentlemen will remain in their seats. I move that the Convention adjourn.
              </p>
              <div className="note" id="Note32">
                <span className="noteLabel">5</span>On March 26, the Enquirer published the following account of the compliment paid to the member of the Convention:
                <p>More Northern Influence.</p>
                <p>Mr. Baldwin, of Augusta, concluded, on Saturday last, his three days' anti-Southern speech.</p>
                <p>Before the adjournment of the Convention, Mr. Price, of Greenbrier, announced that so soon as the Convention should adjourn, some of the "ladies of Richmond" desired the use of the hall for the purpose of "paying a compliment to a member of the Convention" and that members were requested to keep their seats, to witness the ceremony.</p>
                <p>
                  Accordingly, so soon as the President declared the adjournment, Mr. Critcher, of Westmoreland, called upon Mr. Baldwin to stand forth. Mr. Baldwin stood forth. Mr. Critcher produced a large wreath of flowers, made a short speech complimentary to Mr. Baldwin, and read (or sang) some verses written by a lady. Mr. Critcher finally presented the wreath and the verses to Mr. Baldwin. A
                  small portion of the audience feebly applauded. Mr. Baldwin replied with a short, and certainly a better speech than he had made in Committee of the Whole. A small portion of the audience cheered vociferously. After all which, the audience dispersed.
                </p>
                <p>So much for so much. The moral of the fable requires explanation. We are credibly informed that the contribution for the purchase of the wreath and the arrangement for its presentation were instigated and mainly achieved by Northern ladies, and the verses were written by a lady from New England.</p>
                <p>Mr. Baldwin's anti-Southern prose complimented by versification of New England manufacture! Decidedly appropriate!</p>
              </div>
            </div>
            <div className="section" id="vsc1965.v2.2.6.9">
              <h3><span className="headingNumber">1.6.9. </span><span className="head">TAX UPON NORTHERN GOODS</span></h3>
              <div className="speaker" id="sp1028"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">I ask the gentleman to withdraw the motion for a moment, to enable me to submit a resolution.</p>
              <div className="speaker" id="sp1029"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">I will withdraw it.</p>
              <div className="speaker" id="sp1030"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">I beg leave to offer the following resolutions, and ask that they may be adopted.</p>
              <p>
                Resolved, That this Convention recommends to the General Assembly, the passage of a law imposing a license tax upon the sale, by retail, within Virginia, of the products or manufactures of such of the Northern States as continue to retain offensive acts of the character known as Personal Liberty Bills, amongst their statutes, guarding, however, in such law, against any infringement
                of the provisions of the Federal Constitution.
              </p>
              <p>Resolved, That the President of this Convention cause copies of the foregoing resolution to be communicated forthwith to the two houses of the General Assembly.</p>
              <div className="speaker" id="sp1031"><span className="persName">Mr. DORMAN</span> said :</div>
              <p className="p-in-sp">
                I have no voice nor disposition, Mr. President, to detain the Convention with any remarks upon those resolutions. I have merely to say that I hope it will be the pleasure of the Convention to pass them without debate and without delay. My object in offering these resolutions is this: I believe that in the course of the discussion which preceded the election of members of this
                Convention there was no difference of opinion at all amongst the constituency of the delegates upon this floor in regard to the propriety of action concerning the Personal Liberty Bills in the Northern States. No person has learned with greater pleasure than myself of the action in some of the Northern States, just prior to our assembling and since we have been in session, in
                removing the obnoxious laws from their statute books. But it seems to me, in view of the fact, that other States still retain them, and of the fact of the general expression as I have said of our constituencies upon the subject, it is eminently proper that there should be an expression of opinion upon the part of this Assembly in regard to the States which have failed to comply with
                the requisitions of justice and the comity of the States in this regard. I do not think it would be proper for this Convention, called as it was, to consider questions of great State policy and of organic law, to assume to itself the exercise <PageNumber num={258} /> of purely legislative powers, and I have, therefore, proposed merely that the
                Convention recommend the Legislature to take action upon the subject. I have waited thus long before making the proposition, in the hope that some gentleman of more influence than myself would initiate the measure, but it seems to me that the time has arrived when, if anything is to be done, it should be done at once, and I, therefore, submit the proposition to the Convention.
              </p>
              <p className="p-in-sp">It will be seen that I have guarded the resolution against the only objection which can be urged to it, that of an infringement upon the Federal Constitution. It is required that the Legislature in framing the law shall carefully guard against any infringement of the provisions of the Constitution.</p>
              <p className="p-in-sp">Now, sir without detaining the Convention longer, as I suppose there can be no reasonable objection to the resolutions, I move their adoption.</p>
              <div className="speaker" id="sp1032"><span className="persName">Mr. BROWN</span>, of Preston—</div>
              <p className="p-in-sp">
                I understand, Mr. President, that the subject of those resolutions is already fully within the jurisdiction of the Legislature, and that it is a subject which has been frequently adverted to in that body. I am sure my constituents never thought of the Convention interfering with the jurisdiction of the Legislature in relation to that subject. If my friend will give it to the
                direction of the Committee on Federal Relations, and let it be examined by them and come up through them, I will not object to it. Otherwise, I must move to lay it on the table.
              </p>
              <div className="speaker" id="sp1033"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">I will say to the gentleman from Preston, that the reason why I do not offer it in that form is that it does not come within the meaning of the term "Federal Relations." It is a matter pertaining entirely to inter-State Relations.</p>
              <div className="speaker" id="sp1034"><span className="persName">Mr. BROWN</span>, of Preston—</div>
              <p className="p-in-sp">I prefer that, for the present, the resolutions shall lie on the table. I desire an opportunity to look into the subject and see whether or not it is one on which we ought to act. I move to lay the resolutions on the table.</p>
              <p>The motion was agreed to.</p>
              <p>Then, on motion of <span className="persName">Mr. PRICE</span>, the Convention adjourned until Monday next at 10 o'clock, A.M.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v2.2.7">
            <PageNumber num={259} />
            <h2><span className="headingNumber">1.7. </span><span className="head">THIRTY-FOURTH DAY</span></h2>
            <div className="dateline">Monday, <span className="date">March 25</span></div>
            <p>The Convention met at 10 o'clock, A.M.</p>
            <p>Prayer by Rev. Dr. Solomon, of the Disciples Church.</p>
            <div className="section" id="vsc1965.v2.2.7.1">
              <h3><span className="headingNumber">1.7.1. </span><span className="head">CORRECTION</span></h3>
              <div className="speaker" id="sp1035"><span className="persName">Mr. E. B. HALL</span>, of Marion—</div>
              <p className="p-in-sp">
                I rise to a privileged question. By the report in the "Enquirer" of the remarks I made in this body on Friday, I have been misrepresented in a manner which I desire to have corrected. I am represented there as saying that I did not know by whom a proposition was made to the Western members upon this floor in reference to withholding action upon questions arising out of our State
                Constitution until action should have been had upon the question of our Federal Relations. I certainly did not say, or, at least, did not intend to say, that I was ignorant of the source from whence that proposition came.
              </p>
              <p className="p-in-sp">
                I desire, also, to say that in my remarks of Saturday, I am represented as saying that I came here directed by my people to see first that the question of our Federal Relations should be settled before entering upon the consideration of the taxation question, and other matters of interest connected with the amendment of the organic law of the State. The reverse is what I said and
                what I was instructed. My people insisted, as I believe and as I feel myself, that we should first settle these questions of difference amongst ourselves, so that when we should enter upon the other matters, that may be presented to us, we should be regarded as equals upon this floor. That was the position I attempted to express to the Convention.
              </p>
              <p className="p-in-sp">
                I am also represented as proposing that the Committee to be appointed upon the question of taxation should report at an adjourned meeting of this body. I did not make that proposition. I was and am in favor of settling that question now, before coming to a decision upon any other matter, and to that extent I was opposed to the proposition of the gentleman from Preston
                [<span className="persName">Mr. BROWN</span>] .</p>
              <p className="p-in-sp">There are other inaccuracies in the report of my remarks, but I will not, at this time, trouble the Convention or the reporters with them.</p>
              <div className="speaker" id="sp1036"><span className="persName">Mr. BOISSEAU</span>—</div>
              <p className="p-in-sp">
                I am made to say in the report in the "Enquirer" "that at the time of the election, there was a very large majority in the county of Dinwiddie against secession." That is not the language which I used. What I said was: "that at the time of the election, there was <PageNumber num={260} /> a very small majority in the county in favor of secession, but
                that now the county is nearly unanimous in favor of secession."
              </p>
            </div>
            <div className="section" id="vsc1965.v2.2.7.2">
              <h3><span className="headingNumber">1.7.2. </span><span className="head">THE TAXATION QUESTION</span></h3>
              <p>The Convention then resumed as the regular order of business, the consideration of the resolutions of <span className="persName">Mr. WILLEY</span>, of Monongalia.</p>
              <div className="speaker" id="sp1037"><span className="persName">Mr. TURNER</span>, of Jackson, addressed the Convention as follows :</div>
              <p className="p-in-sp">
                Having had the honor to submit to this Convention, the first resolutions looking to the investigation of this question of taxation, I deem it due to myself partly from that fact, as well as from the fact that certain remarks have dropped from gentlemen in the course of this discussion, which require some response from me, to call the attention of the members of this Convention to the
                propositions which I had the honor to submit, and to the difference between that proposition and those presented by the gentleman from Monongalia
                [<span className="persName">Mr. WILLEY</span>] .</p>
              <p className="p-in-sp">
                In doing so I shall endeavor to trespass upon the time of the Convention as briefly as possible, and I must ask the indulgence of gentlemen for any inaccuracies of detail into which I may be led in consequence of the brief time to which I shall necessarily be confined. In order that I may be understood in the course of the argument which I may pursue, I will call the attention of the
                Convention for a moment to the resolutions which I offered, and to those offered by the gentleman from the county of Monongalia. The resolutions which I had the honor to submit, are as follows:
              </p>
              <p className="p-in-sp">
                "1. Resolved, That it is expedient and proper that the 23d section of the 4th article of the Constitution of this State shall be so modified, that slaves, like other property, shall be taxed without exemption, and according to value, and that no exemption of any property from taxation shall be had without the vote of a majority of all the members elected to each House of the General
                Assembly.
              </p>
              <p className="p-in-sp">"2. Resolved, That a Committee, to consist of thirteen members, to be selected from the different sections of the State, be appointed, who shall report to this Convention such amendments to the Constitution of the State as will effect the object indicated in the foregoing resolution."</p>
              <p className="p-in-sp">
                Now, sir, I desire to call your attention to the extent to which this resolution goes, and, in connexion therewith, to state some of the objects which induced me to offer the proposition. The resolutions of the gentleman from Monongalia, embrace three separate and distinct propositions. The resolutions I have offered embrace but one question for the consideration of the
                Convention—the question of taxation alone.
              </p>
              <PageNumber num={261} />
              <p className="p-in-sp">
                Gentlemen, upon the floor of the Convention, have deemed it proper and necessary to state, that in their understanding, there was a tacit agreement in the last Convention between the Eastern and Western members of that body, that the question of taxation should not be re-opened before 1865. In reference to that understanding, I believe there is some mistake; members of that
                Convention say there was no such understanding. But, whether such was the fact or not, the very terms of the act calling this Convention, by implication, propose to make alterations in the organic law of the State.
              </p>
              <p className="p-in-sp">
                But it may be said by gentlemen from the Eastern portion of the State, that there was an agreement or understanding, that the question of the basis of representation should be postponed until 1865. I do not propose to consider this question in connection with that which this Convention is more immediately deliberating upon. I propose that this Convention shall treat each subject
                separately, and in its order, and that this question of taxation shall be dis-embarrassed of all questions save that which more immediately affects the interest of the whole State; and I trust that my friend from Monongalia
                [<span className="persName">Mr. WILLEY</span>] , will withdraw his resolutions, and allow the resolution which I offered to be acted upon. The object with which I offered that resolution, I will refer to very briefly.</p>
              <p className="p-in-sp">Sir, it has been the pleasure of gentlemen upon this floor, and especially the gentleman from Lynchburg [<span className="persName">Mr. SLAUGHTER</span>] , and the gentleman from Mecklenburg [Mr. GooDE] , to allege that these resolutions were offered for the purpose of throwing a firebrand into the Convention and disturbing its equanimity.</p>
              <p className="p-in-sp">
                I desire for myself, sir, and I trust I may do the same for the gentleman who offered the competing proposition, to disclaim any such intention. I came here, sir, with a determination to demand for the State of Virginia all the rights to which she is entitled, and to be content with nothing less; while at the same time I came determined with equal persistency to demand for my section
                all the rights to which it is entitled. These two subjects are essentially distinct in character. I believe we can discharge our duties to both the State and peculiar section we represent, without producing discord or disturbing the harmony of this Convention.
              </p>
              <p className="p-in-sp">
                Sir, the question of equalizing tax upon all the property of this State is a just measure, and any discussion of such a question cannot lead to distraction nor operate as a firebrand in this Convention. Inasmuch, sir, as gentlemen seem to have misunderstood my position on the question which this Convention is now mainly considering, I desire
                <PageNumber num={262} /> to read some propositions the adoption of which I think is necessary to an adjustment of our difficulties. They embody the attitude which, in my opinion, Virginia should assume with regard to the other States of this Union. They are such propositions as I believe Virginia should adopt without regard to the action of other
                States. She should demand for herself the rights which belong to her, taking nothing less, and demanding nothing more. I have been waiting here for weeks and weeks anticipating that this question of Federal Relations would be settled. I was told to wait until the conclusion of the deliberations of the "Peace Conference." We waited ; but the Committee on Federal Relations took no
                action even after the report of the Commissioners to the Conference was received. We were then told to wait until the inaugural of Mr. Lincoln would appear, in order to ascertain what position he would take. We did wait, and yet this Convention has taken no action. How far is this apathy to extend to? How long are we to remain here awaiting action on the part of this body? If they do
                not act with reference to these questions which affect the interest of Virginia so vitally, I desire that they shall act upon those questions which are of vital importance to the people of my section.
              </p>
              <p className="p-in-sp">In order, sir, that my position may be fully understood on the subject of Federal matters, so far as Virginia is concerned, I will read some propositions which I intend to offer for the consideration of the Committee of the Whole as a substitute for the report of the Committee on Federal Relations so soon as it shall be in order so to do.</p>
              <p className="p-in-sp">
                These are my views upon the subject of Federal Relations as they affect Virginia, and I am responsible to my constituents for them. I believe that they will sustain me in presenting an ultimatum which will decide finally and forever the questions now agitating the country. These propositions, I believe, embrace the true States Rights doctrine in which I have been educated. And it was
                in order to harmonize the State of Virginia; it was in order that there should be no sectional divisions, and the people should act in perfect harmony upon these questions, that I desired this subject of taxation should be settled. Sir, it is impossible that a people such as I represent, as well as those of the whole section from which I come, who have been true to the interest of
                the South, true to the interests of the institution of slavery, in which they have had comparatively no interest ; I say it is impossible that a people of that kind who have battled for ten years to my knowledge faithfully for that interest should act now in bad faith in reference to that question. But, sir, I would state that, if they are made to feel this inequality which is
                implied in the unjust discrimination made <PageNumber num={263} /> against them upon the subject of taxation, it would be impossible that they could maintain this attitude long. I believe, morever, that if this Convention shall refuse to raise a committee to consider this subject, it will have the effect of relaxing this zeal which they have always
                manifested upon the subject of slavery.
              </p>
              <p className="p-in-sp">
                Sir, we have told them, so soon as an opportunity was offered on the part of the Eastern portion of this State, to relieve them from the inequality under which they were laboring, that our Eastern brethren would take advantage of that opportunity to extend with the right hand of brotherhood, the rights to which they were entitled; and we have been able to induce a people who are not
                pecuniarily interested in this question, to come up and stand shoulder to shoulder with, and even to take higher ground than their Eastern brethren upon the question of protection of the rights of property in the territories of the United States. It is utterly impossible that we can ever again resist the torrent which would overwhelm any man who should attempt to defend the action of
                this Convention, if it adjourned without taking this question into consideration. I for one, desire to say here now, that whilst I am perfectly willing to go as far as I believe we ought to go or can go, or that the people of this State will bear us out in going; yet sir, I will never vote for this Convention's adjourning, unless before it adjourns, a Committee shall be raised for
                the purpose of investigating this question of taxation, except to vote to adjourn forever. I have no compromises to make of any of the rights to which the State is entitled in the Union, or to which the citizens whom I represent are entitled. It is only for the East to come up, and meet us in a true fraternal spirit; and whilst this subject of the relations of this State to the
                Union, is under consideration to settle at the same time, questions which are involving the interests and rights of citizens of every portion of the State. As the hour has now arrived for going into the Committee of the Whole, I beg to postpone the farther consideration of the subject until to-morrow morning.
              </p>
            </div>
            <div className="section" id="vsc1965.v2.2.7.3">
              <h3><span className="headingNumber">1.7.3. </span><span className="head">ADDRESSES OF THE COMMISSIONERS</span></h3>
              <div className="speaker" id="sp1038"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair has received a communication from the Secretary, in response to the resolution offered by the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>] , on Saturday last.</p>
              <p>The communication was then read as follows:</p>
              <PageNumber num={264} />
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>RICHMOND, March 25, 1861</p>
                  <p>Hon. JOHN JANNEY,</p>
                  <p>President of the Convention,</p>
                  <p>
                    DEAR SIR: In accordance with a resolution adopted by the Convention on Saturday last, directing "the clerk to inquire into and report why the speeches of the Commissioners from South Carolina, Georgia and Mississippi have not been printed as ordered by the Convention," I addressed a note to Wyatt M. Elliott, Esq., printer to the Convention, transmitting a copy of said resolution,
                    and in response thereto received from him the enclosed communication.
                  </p>
                  <p>Respectfully,</p>
                  <p>JOHN L. EUBANK,</p>
                  <p>Secretary of Convention</p>
                  <p>RICHMOND, March 25, 1861</p>
                  <p>DEAR SIR: I have your note enclosing resolution of enquiry adopted by the Convention, in regard to delay in printing the speeches of the Southern Commissioners ordered some time since.</p>
                  <p>
                    In reply, I beg to say, that the small number of these speeches ordered to be printed, coupled with the fact that they had already been published in the newspapers, induced me to believe that the purpose of the Convention in printing was rather to preserve than to circulate, and hence the delay. Upon the first intimation that a different purpose influenced the Convention, and
                    that they desired the prompt execution of the order the work was put in hand and will be delivered to-morrow and next day complete.
                  </p>
                  <p>Respectfully,</p>
                  <p>W. M. ELLIOTT</p>
                  <p>To JOHN L. EUBANK, Secretary of the Convention.</p>
                </div>
              </div>
              <p>The communication was laid on the table and ordered to be printed.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.7.4">
              <h3><span className="headingNumber">1.7.4. </span><span className="head">FEDERAL RELATIONS</span></h3>
              <p>The Convention, in pursuance of its standing order, resolved itself into Committee of the Whole, [<span className="persName">Mr. SOUTHALL</span> in the Chair] , and resumed the consideration of the report of the Committee on Federal Relations, the pending question being the amendment in the nature of a substitute offered by <span className="persName">Mr. CARLILE</span>, upon which <span className="persName">Mr. BRUCE</span>, of Halifax, was entitled to the floor.</p>
              <div className="speaker" id="sp1039"><span className="persName">Mr. BRUCE</span> resumed his remarks, as follows :</div>
              <PageNumber num={265} />
              <p className="p-in-sp">
                Mr. Chairman, I feel that an apology is due to the Committee for troubling them again this morning. Some days ago I met with an eminent legal friend. He saw that I was laboring under disease. He told me that he knew of a remedy, and advised me to come up to this Hall and make a speech. He said that when he was troubled with any kind of ailment, dyspepsia or a disorder of the liver,
                he always made it a point to make a two or three hours' speech, and he found in it a complete remedy. I believe, Mr. Chairman, that this remedy is known to the legal profession generally, and I think that they never hesitate to use it for every class and degree of disease; for a common ailment, a speech of one hour; when the case becomes worse, a speech of a day ; when the symptoms
                become uncontrollable, a speech of two days; but, sir, when they venture upon a speech of three days, they are found to be very nearly in articulo mortis.
                [Laughter] .</p>
              <p className="p-in-sp">I do think, Mr. Chairman, that a great deal of abuse has been unnecessarily expended upon this Convention, not only by the people of the State, but by the press of the State. The cause of our delay has been, I think, owing solely to the epidemic which has been prevalent in Richmond for the last sixty days.</p>
              <p className="p-in-sp">But, sir, I will offer no more apology, and will proceed at once to resume the thread of my argument which I addressed to you on Saturday.</p>
              <p className="p-in-sp">
                My object this morning will be to prove that the present oppressed condition of the State of Virginia is owing to the operation and the working of our Federal system. Well, sir, one has but to open his eyes to see—it requires no argument to show—that Virginia has not kept pace with her sisters of the North in all the elements of progress and material wealth. I know, we console
                ourselves with the idea that we have great moral qualities, that ours is peculiarly a soil which grows gentlemen, men of high moral, intellectual and social qualities. But, Mr. Chairman, in this world, as at present constituted, the poverty of a nation does not peculiarly recommend it; and the estimate which is placed upon States and Nations, is owing in a great measure to the
                progress which they are making in wealth, in improvement, and in accumulation of capital; and in this respect I must acknowledge, and all must acknowledge, that Virginia is a laggard in the race.
              </p>
              <p className="p-in-sp">Now this must be owing to one of three causes. First, the natural inferiority of the people of Virginia and the inferiority of her soil; second, to the peculiarity of their institutions; or, at last, it is owing to the peculiar working of our Federal System.</p>
              <p className="p-in-sp">
                I need hardly stop to say that Virginia is not peopled by a naturally <PageNumber num={266} /> inferior people; nor need I stop to defend the people of Virginia against such a charge of inferiority as this. Virginia has descended from the same ancestry as our Northern brethren, Anglo-Saxon blood courses in our veins; and it cannot, therefore, be owing
                to this cause. Nor is it owing to the inferiority of the soil and the natural advantages of Virginia. Her position, her climate, her noble rivers, her splendid waterfalls—all invite enterprise, all invite to energy and industry. But Virginia is a laggard in the race.
              </p>
              <p className="p-in-sp">
                Now, sir, I wish to show that this institution of slavery has not been the depressing cause which has kept back the progress of the State. See here, sir. I beg to read a few figures now and I will not trouble the Convention again with one of them. In the year 1774, one hundred and sixty years after slavery had been introduced into the colony of Virginia, we find, sir, that our
                population was greater than the population of any State in this Confederacy; and, remember that at that time the area, the habitable area and the cultivatable area of Virginia, was but little more than that of Massachusetts. At that time the population of Virginia was 560,000; the population of Massachusetts was 360,000; of New York 180,000; and of Pennsylvania, 300,000. Nine years
                afterwards—in order to show to you that this ascendancy of Virginia has not been the ascendancy of population alone, but of wealth—we find when the General Government made a levy on the States for a million and a half of dollars, Virginia had to pay $256,000, Massachusetts $224,000, New York $128,000, and Pennsylvania $205,000. Thus you see that Virginia has taken the lead in
                population and in computed wealth. Now it seems to me that after one hundred and seventeen years of slavery in Virginia, I am not entitled to say that slavery has had any depressing effect on the material progress of the State.
              </p>
              <p className="p-in-sp">
                Now, sir, what was the condition of the Northern States? They had but few slaves—not enough to make them an element in the calculation of wealth or of population. From the poverty of their soil and the rigor of their climate, they necessarily embarked in commerce and in manufactures. But you may remember that under the navigation laws of England, passed by Cromwell in 1651, there
                were great restrictions placed upon Colonial trade, and especially upon Colonial manufactures. The exigency of the position of the Northern States compelled them to adopt this double system of manufactures and commerce. They were near the sea, and had every inducement to invite them to that course. And I may add, Mr. Chairman, that the slave trade was also a source of great profit to
                New England—not as great as it was in the mother <PageNumber num={267} /> country; but the traffic of slaves with that great depot of slaves—Kingston, Jamaica—was extensive and very profitable.
              </p>
              <p className="p-in-sp">
                Now, sir, came the declaration of war—a war which resulted in the emancipation of the Colonies. This necessarily restricted the commercial intercourse between England and the Colonies, and operated to give a stimulus to the manufactures of the North. Their commerce, of course, was cut up and unfavorably affected, but the manufactures were stimulated by the war of the Revolution.
                Well, sir, when peace was declared, it was found impossible that Northern manufactures and Northern commerce could stand in competition with the manufactures and the commerce of England. And, sir, the very first Congress that assembled after the adoption of the Constitution of the United States inaugurated a system of imposts and tonnage duties which were professedly intended to
                protect the commerce and the manufactures of the North.
              </p>
              <p className="p-in-sp">
                Suppose they had not had that protection, what would have been their condition. England would have monopolized the coasting trade, she would have monopolized the foreign trade, she would have carried all the surplus produce of America to Europe. This is perfectly certain. They were skilled manufacturers, and had been for hundreds of years. Thus, sir, if all the colonies had been
                permitted to bear the same relations to England that they before had borne, it is perfectly obvious that Virginia would have gone abroad with her agriculture; and it is perfectly obvious, too, that the North never would have obtained a footing at all. But, sir, her poverty drove her to more profitable branches of industry, and our climate and soil invited us to continue the pursuits
                of agriculture.
              </p>
              <p className="p-in-sp">
                Now, sir, in 1789, as I have informed you the Tariff policy was inaugurated, and Mr. Madison, in that Congress, deliberately said, that although he was a friend of free trade, yet he would relax his iron rule in favor of the infant manufactories of the North. Well, sir, thus it continued, and thus the Northern States continued to prosper under the protection of the General
                Government. And I think I have shown that without this protection they would never have made a start even in commerce and manufactures.
              </p>
              <p className="p-in-sp">
                Then followed the war of 1812. This war itself was a protection to the manufacturers of the North. It cut up their commerce I confess, but their manufactories greatly flourished, and became an important interest in the Northern States. But when peace came the manufactures of the North were prostrate in the dust. Goods were bought, under this moderate tariff, from England and
                inundated the whole <PageNumber num={268} /> country. And what was the effect? There was a clamor from New England and from the Middle States. They said they were ruined without the interposition of Congress. Congress did interpose, and the tariff of 1816 was passed—a tariff, not very high, but sufficiently high, as was then thought, to revive the
                fallen fortunes of the manufacturers of the North.
              </p>
              <p className="p-in-sp">So far, all the progress that had been made by the North was under the protecting care of the General Government. That is perfectly certain; and they never would have progressed, they never would have made a commencement without it. It was generally believed throughout the country, that we of the South must go far beyond the North in wealth and in population.</p>
              <p className="p-in-sp">
                Here let me pause. A nation that engages in commerce and manufactures, must necessarily, if it can get a footing, be more wealthy, must make greater progress, than a nation confined to agriculture. That is perfectly clear; but what I contend for is, that without the fostering care of this government, they never would have made any progress, they never would have even obtained a
                footing or have made a beginning. Then, so far, the industry of the North has been protected by the legislation of Congress. And here let me observe, as a remarkable coincidence, that the recedence of the South and the rise of the North begins exactly with the inauguration of the Constitution of the United States. The year 1789, which established our Federal Government and our
                Federal Constitution, is a year that seems fatal to the South, because from it grew all these protections, all this prosperity, which has so perfectly characterized the North. Their commerce and their fisheries are protected; aye, more, the fisheries of the North are protected, not only by a duty which excludes the fishermen of all other nations from our markets, but they have
                absolutely bounties given them in order to encourage these fisheries under the plea that they are necessary to raise seamen. And what are these seamen necessary for? They are necessary for the protection of commerce? What commerce? Northern commerce, and Northern trade. And the whole operation looks not only to the protection of Northern interests, but to the exclusion of Southern
                interests.
              </p>
              <p className="p-in-sp">
                But, sir, in a very short time the North said that the tariff of 1816 was not sufficient. And then the tariff of 1824 was enacted. That tariff almost excluded English manufactures from America. It was as much as to say: "No goods shall be shipped to the South, but such goods as shall be carried there by the North." Is not this so? What was the effect of the tariff of 1824? Had the
                wand of Prospero been waved <PageNumber num={269} /> over New England it would not have brought to view such a scene of peace, happiness and industry. Wealth flourished and flourished in a manner that was truly magical. Towns and villages rose almost instantly at every waterfall in New England. The accounts which were received from Massachusetts of the
                rise and progress of the town of Lowell, situated at the falls of the Merrimack in that State, were romantic and almost incredible. An English traveller, who visited America at this time, gave a graphic account of what he saw of this town of Lowell. Sir, the town of Lowell, became the El Dorado of men and the paradise of women. Men embarked in manufactures under this tariff of 1824,
                and grew rich on a sudden. The merchant princes of Tyre found their counterpart in the manufacturers of Lowell.
              </p>
              <p className="p-in-sp">
                And, sir, young women came down to this town from New Hampshire and from Vermont, to seek money and to seek education. We are told that in a very short time they became accomplished in the profess'on in which they embarked. Their business was almost a sinecure: their labor was almost nothing. It was at last a labor of the eyes. Are you too old, Mr. Chairman—I hope not—to know the
                powers of woman's eyes when directed by woman's skill?
                [Laughter.]
                Yes, sir, it was the labor, it was the labor of the eye. They sat down by their delicate machines and watched over them, and the roughest employment that they were subject to, was with finger and thumb, to tie the broken thread or unravel the tangled skein; and, sir, they soon became, not only learned in their peculiar arts, but accomplished. In their intervals of leisure, their
                intervals were devoted to books, and the hardest usages that their delicate hands were exposed to, were in touching the keys of the piano, and turning over the gilded leaves of some Abolition author, and in a short time they became indoctrinated in abolitionism. They went to their native homes and, sir, they became the belles of their native mountains—the cynosures of admiring eves:
                but alas for the fate of woman, in a short time they married—olive plants grew up thick around them; and the whole business of their future lives is made up of efforts to inculcate in these tender plants hatred to the South and its inhabitants. Sir, this is not to be considered a fancy sketch; for I have been to this town of Lowell, and it seemed to me that the labors there engaged
                in were rather luxuries than toil. Well, sir, what was true of Lowell was true of other towns in Massachusetts and Connecticut. The whole country absolutely blossomed like a rose. What was it, sir, that kindled up this manufacturing enterprize? What sun was it that warmed it into such active life? Sir, <PageNumber num={270} /> the sun which ripened
                fruits for these Northern manufactures was no other than a Southern sun. The labor which ministered to their prosperity was Southern labor; and so nicely was the system of protection adjusted that there was no escape from it. It is perfectly apparent that the North is indebted for all its success and its progress to the action of this general government; and it so happened that
                whatever tariff was passed has been increased and increased from year to year until there has been no satisfying them. And there seems to be also a determination on the part of the North to make us their Colonies.
              </p>
              <p className="p-in-sp">
                Now, sir, the only possible way in which one nation can injure any portion of its inhabitants, is through the revenue laws. Talk to me about the surrender of fugitive slaves—talk to me about the attacks which have been made upon our institutions by the North ; but the great attack which has been made, and continually made, and which has never been remitted or relaxed, is the attack
                upon all our institutions through the instrumentality of the tariff of the North. I don't blame the North; I would as soon blame the shepherd for shearing his flock, or the herdsman for milking his cows, as I would blame the North for its policy to the South. We have borne it patiently, and we entered into this contract with our eyes open, and it was but just that we should suffer.
              </p>
              <p className="p-in-sp">
                But, the question is, shall we now pause? shall we tolerate it any further? And even while we are attempting to adjust our difficulties with the North, instead of giving us an olive branch they give us the Morrill tariff—the worst tariff that ever was inflicted upon any people who call themselves free. There is one point here that I would have you to observe. A tariff in a
                Confederacy of States like ours is a very different thing from a tariff in a consolidated, small Government; for a tariff in that case, while it may injure the people does also increase the consumption of agricultural products, and if these manufacturers which have made an El Dorado and a Paradise of these Northern towns, had been located here, we could, probably, have stood it. But
                the tariff has been levied for the benefit of the North; the free trade between the North and the South has given to them all the benefits of this tariff not only by benefiting the manufacturers, but also the agriculturalists. The man who lives near a factory has a market. He can sell every vegetable to advantage. I saw a Northern man who was at my house, and while there, I walked
                with him through a large field of turnips. He observed, if I had these turnips in the North they would be a fortune to me, for I could find a market for them at my door at the factories. How am I to sell my turnips? I might send them to <PageNumber num={271} /> Massachusetts, but they would not bear the cost of transportation. But the agriculturists of
                the North can sell to the manufacturers in their neighborhood at remunerative prices, from which but little is deducted, if any, for transportation. But far different with us. We are ground for the benefit of the North, not for the benefit of the manufacturers alone, but also of the Northern agriculturalist. And, sir, this enables them to monopolize the trade of the whole country. I
                am informed that when Mr. Byrd ran the line between Virginia and North Carolina, before the adoption of our present Constitution, he saw in the harbor of Norfolk, twenty-nine large vessels. Does the gentleman who represents the City of Norfolk, recollect seeing at one time in his port, twenty nine large vessels? No sir, that time has passed ; the commerce of Virginia has gone, and
                all that is left to us, is, that we shall employ the ships of the North, and use its manufactures.
              </p>
              <p className="p-in-sp">
                Now, sir, is it not a perfectly clear case that if there was a law passed that Virginia shall not be permitted to buy any goods from any nation except from the North, we should not be in a greater state of vassalage. The North tells us, by an enactment of Congress, that we Southerners shall not buy from any other people but them. Turn it over as you please, and it amounts to this and
                no more, that the South is compelled, by statute, to buy from the North, and the North alone.
              </p>
              <p className="p-in-sp">Mr. Chairman, I will for a moment call the attention of the Committee to a single practical view of the effects of this vassalage of the South to the North by a few facts within my own cognizance. I risk the charge of egotism that I may establish my point the better.</p>
              <p className="p-in-sp">
                I was once, sir, a Southern planter. In the year 1844 I went to New Orleans. My imagination was kindled by the quantity of sugar, and molasses, and cotton that planters raised in that sunny region. I came home, and was invited to address an agricultural society in a neighboring county. I advised my friends who were large planters to carry a portion of their negroes South, where their
                profits were great, and not keep them here where our profits were small. To show my sincerity in this belief, I went to Louisiana, and became a cotton and sugar planter. The first year I made nothing, as might be expected, because I had heavy expenses; but the next year afterwards, there was a genial climate, there was a fine season, there were fine showers, and I thought that our
                crop was very great. Well, sir, it was sent to a commission merchant in New Orleans, and I went there in the spring, and told him that I wanted my money. Ah, said he, I am sorry to tell you that your expenses have been very great, and I have no money of yours in my hands. What, sir! Where are all the hundreds <PageNumber num={272} /> of hogsheads of
                sugar, and bales of cotton? Is there no money coming in from them? He opened his portfolio, and drew out a paper, which he presented to me—I think he called it an account current. The thousands of dollars for the proceeds of my crop was on one side, and then on the other side I found the account more than balanced. How is it?
              </p>
              <p className="p-in-sp">
                I took the account, carried it to the plantation and examined it carefully. I found it all right. I had no money. I found my pockets empty—not a dime in them—my purse turned wrong-side outwards did not reveal a picayune—I began to reflect upon the matter. I found that though I had no money, I had been a munificent patron of all the arts, which gave me some comforts. I had bought
                scientific instruments in Philadelphia, chronometers, barometers, saccharometers and spy-glasses. I bought from Lynn all my shoes—I bought from the northwest all my stock—I had bought my pork from Indiana—I bought my corn from the Wabash—hoes, ploughs, cane-knives, wagons and carts from Cincinnati—cotton goods from Lowell, and hats, blankets, and other woolens from New England. I
                found that all went into the pockets, not of slaveholders, but of the non-slaveholder. I say now, sir, that if I had given these plantations to the North and let them manage them—I held them seven years ago, but I do not now—I believe I would have been the gainer. No doubt about it. If we had had free-trade instead of a protective tariff, the profits derived from the plantations
                would have been handsome. All the profits went North, and all I got was the support of my negroes, and money enough to pay the overseer.
              </p>
              <p className="p-in-sp">
                Why is this? Why do you encourage the manufacturers of the North? They had derived all the profit, and I had received nothing. Well, sir, I don't mean to say—I would not be understood as saying —that all the planters in the South were as bad planters as I am; but this I know, that they raise nothing but their main staples, and buy everything else in the North. Well, we are asked,
                why, under this high tariff, has not the South the benefit as well as the North? I have just shown you, I hope, that the reasons were obvious—that the situation of the North was such as to enable them to get the benefits of the tariff, and that the South did not apply herself to the pursuits which might have given her those benefits. We are to blame, and not the North. With the
                advantages which they possess over us, it would be impossible for us to compete with them. It would be just as difficult for us to compete with the North in manufactures, as it is for the North to compete with Louisiana in the production of sugar.
              </p>
              <PageNumber num={273} />
              <p className="p-in-sp">
                And there is a point I hope the Committee will dwell upon and never forget, that the North can never compete with England and France in commerce and manufactures, but by the protecting care of the Government. I believe another thing, that if we had had free trade instead of this protective tariff, from the commencement of our system of government in 1788 until this time, we should
                have been a rich people, and I think it can be demonstrated that what we have paid for our manufactures during those years would be the difference between wealth and poverty.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, here is the important question—what is Virginia to do? This tariff is upon us. The North has got the start of us, and we cannot possibly overtake her under this system of free trade. She must monopolise our manufactures. She is doing it more and more every year, and the question is what shall the South do? What can it do? Why, what I feel it ought to do, it ought to
                exact all those compromises and all those concessions which rightfully belong to us, and if the South were true to itself, it would require the North to give up its tariff policy and give us free trade; but instead of that they give us the Morrill tariff. I ask you, Mr. Chairman, I ask any one, if there is any hope of Virginia rising under this state of things? Is there any good
                ground of promise that Virginia can be recovered from the lethargy in which she is fallen? What shall we do? Why, sir, the inclination of my mind is, that separate nationality is necessary; but I do not wish to thrust my views upon other portions of our State. I love the West as I do the East. I love Monongalia and I love Pittsylvania. I love Augusta as I do Charlotte, and I, for
                one, will never, willingly, spare from Virginia an acre of Virginia Territory; not a rood, not a foot, not an inch, myself, in the cause of peace. I say to my friends from the West, I will humble myself in the dust rather than that Virginia should be divided. There is nothing short of absolute degradation that I would not submit to in order to get the whole of Virginia united; but
                what is our remedy? I do not know what is the temper of the people of Western Virginia; but from their representatives—their honorable and faithful representatives I take it that Western Virginia is not up to the standard that I would lay down. This question is to be settled by compromise. I know that the word compromise is hateful to the extremes in this hall on both sides. But
                compromise is a very dear word to me. You cannot do any thing without compromise. The existence of the Constitution, of laws, of everything, depends upon compromise. Compromise—it is the soul of the world ; the harmony of nature, the music of the spheres. Even our salvation <PageNumber num={274} /> was probably won by a celestial compromise in the
                councils of Heaven. And shall gentlemen reject compromise? Will they make none? I am sure that this Committee is not of this mind. But what compromise would I make? I would propose this compromise : There are ultra secessionists, who take the ground that Virginia should go right out of the Union. There is another part of this Convention who say that they will not, under any
                circumstances, go out; but I know they will. But it will be rather hard to make a case that they think would be justification to Virginia for going out. Now, Mr. Chairman, I think that there may be a middle ground, and that middle ground in my estimation is this. Let Virginia put forth an ultimatum and send it to the North, and say to the North, unless this ultimatum is granted that
                I will refuse any longer to remain in the Union. That is the language of the 1 1 th resolution, in the report of the Committee—a good resolution—and I will stand to it; but, for one, I am not willing to stand by the last resolution in this report, as understood by this Committee, or rather the Committee of Twenty-one. I think, though, that that report will bear fairly the
                interpretation that Virginia pledges herself to go out, if this compromise or amendments to the Constitution, intended as a compromise, are not adopted. I am afraid that some of my friends in the Committee of Twenty-one and in this Convention, do not take this broad and catholic view. I would have it more intelligible. I am instructed by my constituents, and it has been the voice of
                the State repeated time after time, and reflected in the Legislature of Virginia—that this question must be settled quickly, for we are in an agony of suspense which is now fatal to our trade, fatal to our social relations with the North and our social relations with the South. I think we might adopt it as a fair compromise, that we will send before the people to-morrow, if we can
                get at our ultimatum, authorising the people to send back to us the power, if this ultimatum is not accepted, to go out of the Union. I think that those who are in favor of immediate secession would agree to it, and that the conservative party may also come into this compromise. We can harmonize here, I believe, but no where else. I would say to the conservatives, that if they will
                take the ground, they will at last have such checks as the people may be disposed to put upon it. It is no final action, and the people have to pass upon it; and I am glad that the people have to pass upon it. I would have voted, if I had voted at all, for a reference of this whole matter back to the people. If I were an immediate secessionist, I would never deny the sovereignty of
                the people by refusing the right to them of voting upon any act. I therefore hope that all sides may be united, and that <PageNumber num={275} /> we may send forth our resolutions with an unanimous voice and with an unanimous purpose to stand up and to sustain the rights of Virginia.
              </p>
              <p className="p-in-sp">
                But gentlemen say we must have a conference of the Border States. You had a conference the other day. What was the result? They were in session for weeks, but they could not unite. Even the Border States could not unite. Suppose you had a conference of the Border States for consultation, for argument, debate and disputation—this very circumstance will kindle animosities and hatreds
                among the Border States that will render union impossible. What did Virginia send us here for? To consult the Border States? Not at all. We were sent here for Virginia. Virginia is to act for Virginia's self. Do you propose to send an ultimatum to the Border States? I thank the gentleman from Augusta
                [<span className="persName">Mr. BALDWIN</span>]
                for proving in his very candid, luminous and able argument on Saturday—the absurdity of sending an ultimatum to this Border Convention. To send an ultimatum to the Border States would be an insult to them. What else can you do? We may go without an ultimatum, or in other words, without our resolutions. Is this Committee of Twenty-one, which has been laboring and sweating in a room
                which is almost like the Black Hole of Calcutta, to send forth a resolution which is not an ultimatum? This thing is absurd upon the very face of it. I will not put the liberties, the rights and honor of Virginia into other hands than Virginia hands. I will never consent to it—never never.
              </p>
              <p className="p-in-sp">
                But this is good ground, that which I advocate, and I am sure that no one who occupies, as I do, the middle position, can refuse to take this ground—to go before the people at once, and say to them whether they are willing to stand upon it and authorize this Convention, if they do not, to go out of the Union. And the people will vote for it quickly, and it will be final. That is what
                I go for. I confess I have strong sympathies for my friends who are the ultra secessionists. If I had my own way exactly, I do not know but I would go with them. Candor requires me to say this, but prudence requires me to take a different course. I want Virginia to be united. This is the dearest thing to my heart, and I will seek this union in every way I can. The middle party holds
                the power between the extremes and will settle this question; and I ask them, one and all, to unite at once upon this compromise ; send it before the people, get the decision of the people, and then we can act and act as a unit. Where are we to go? Suppose the North will not grant us this ultimatum. I do not believe it will. I am afraid it will not. Suppose they do not act. What are
                we to do? Some say, give us <PageNumber num={276} /> a border State Confederacy, and try and get in some of the Northern States. That does not meet my views. The slave interest is too weak for this. A house divided against itself—we have it upon high authority —cannot stand. And, sir, with Europe against you, with the North against you, without a
                solitary friend except one South American State, the empire of Brazil, not another State could give you any countenance, any right hand of fellowship; and yet we are talking about dividing the slave States of this Union. No, never! Our interests, are embarked in the same bottom, and I am for holding our slaveholding States together and acting together.
              </p>
              <p className="p-in-sp">Mr. Chairman, I am not prepared yet to say that I will take the ultimatum as presented by the Committee. It is good as far as it goes. I think, with the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>]
                , that it does not go quite far enough. I am not disposed to be unreasonable, I am disposed to act an honest, open and an upright part. I mean not to take any advantage of gentlemen who do not agree with me upon this subject for the purpose of opposing them. I will go for such a compromise as will last as long as time, and if such compromise is adopted, I for one, will submit to it.
                I say submit—hoping, devoutly hoping, that the Cotton States will find it for their interest to come back to us. I will not be satisfied with a compromise that I think the Cotton States ought not to be satisfied with. I will give them and require a compromise that I think ought to satisfy, and if it does not satisfy them, I at least have done my duty and can do no more.
              </p>
              <p className="p-in-sp">Mr Chairman, I felt it my duty on Saturday, to open a question that I know grated harshly upon the ears of the Committee. But, sir, thinking that the North would not accept our ultimatum, it is necessary and proper that we should look forward to the course we will pursue in the future. The gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>]
                , on Saturday, properly argued this question to show that Virginia would lose by a dissolution of the Union. Sir, permit me to tell him that he took his position like a man and sustained it with great ability. I feel it, and I take pleasure in saying that to him. But, I wish to draw the attention of the Committee to one position which was well taken, and strongly taken by him, and
                which, I am sure, made a great impression upon this Committee. In answer to the question whether a dissolution of the Union would encourage the manufactures of Virginia, he said that Virginia could not compete with the North, and the North could not compete with England. With heavy duties what would. Virginia do in case we were out of the United States and had to compete with
                England? Sir, the view was very striking. It made its impression on me, <PageNumber num={277} /> and made its impression on this Committee; and I am sure I am fair, when I state the argument in its whole length and breadth. Sir, it would be perfectly true that Virginia could not compete with England in carrying its manufactures abroad; but one immediate
                effect of this competition between the North and England would be to reduce the prices of manufactures and the duties upon them. The profit of this competition to the agriculturalist would be enormous, would unquestionably be great, and greater than any profit that could be derived from a tariff. But, then, the gentleman's position was not that. He stated that he was confining
                himself to the question of manufactures.
              </p>
              <p className="p-in-sp">
                Sir, it would not be the interest of Virginia to become a great manufacturing State at once. Manufactures here, as elsewhere must have their infancy, their growth and their manhood. But here is the point : That if there was a dissolution of the Union it is perfectly evident that with the duty which we now have or would have—a duty of 15 or 20 per cent—that all the manufactures which
                Virginia now has, and which hang by a feeble thread, would be encouraged.
              </p>
              <p className="p-in-sp">
                Look at the article of carriages and wagons. Does any one believe that England could compete with us with a duty of twenty per cent. in our favor? Of course not. Look at furniture! Why, sir, I remember seeing it stated, that Dr. Franklin mentioned that, when he went to England, he carried his writing desk with him, and when he was there, it became so tight from the dampness of the
                atmosphere that he could not use it, and when he brought it back to America, such was the dryness of the climate, that it almost fell to pieces. So, England could not compete with our own manufacturers of furniture. They must be made at home. And besides, there is a strong tendency in individuals to buy things near at home, rather than at a distance. There is, also, a nationality of
                feeling to encourage home industry, and national industry. And I have no doubt, sir, that every manufactory which is now barely able to sustain itself, when it has a protection of ten, fifteen, or twenty per cent., would flourish, and Virginia would take a forward step in manufactures.
              </p>
              <p className="p-in-sp">You cannot tell, no man can tell what effect it would have, for us to invite the manufacturers of Europe, and the manufacturers of the North, to the South; for a protection even of five per cent., would be a mighty protection to a large business. And there is the point, and the weak point of the argument of the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>]
                . I am perfectly certain that, upon the manufactures from abroad, you could save ten per cent, and ten per cent is an immense profit on a large business. In the wholesale dry goods business, five <PageNumber num={278} /> per cent. is a large profit; and there is no question that the only chance of the Northern manufacturer to compete with the English,
                would be to come South and enjoy this small protection. There would be no question about that I think.
              </p>
              <p className="p-in-sp">But we are told by the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>]
                that Western Virginia, with her cattle and her provisions, would not be benefited by it. At least, Mr. Chairman, it must be admitted by the gentleman from Augusta she would not be, and could not be, injured by it. And I will tell him another thing: that the Western part of Virginia would be benefited, because there is a natural disposition in man to purchase from his own people
                rather than from foreign people; and, ultimately, under the present tariff, the provisions from the county of Augusta would go to the South. It will be protected, if the principle upon which that Southern government is founded is the principle of horizontal duty, which will protect every class.
              </p>
              <p className="p-in-sp">
                But let me tell the gentleman another thing—and I hope will have the attention of this body while I urge it before this Convention. Look at the tobacco interest. We have for seventy years been striving to get the duties taken off our tobacco, and we have failed. That will not be always the case. But in a country like the United States is now constituted, with high duties, it is
                impossible that they can get the duties on tobacco reduced. England has a protective duty, or rather a tax—a revenue tax—upon tobacco. I don't know whether it is a direct or an indirect tax, probably it is both—that amounts to three shillings sterling, which makes a duty of seventy-five cents a pound; and this duty or this tax, instead of being diminished, is continually going up, in
                spite of the protection of the General Government. If you have a low tariff in the hands of the Southern Confederacy, I tell you, sir, that they would force them to relax the duty upon tobacco. What would they do? They would say, "If you don't reduce the duty upon tobacco, we will put a duty upon your manufactures ;" and this Confederacy would not be in existence twelve mcnths before
                the tariff upon tobacco would be lowered in every part of Europe, and we would have them in our own hands—we would have them under our control.
              </p>
              <p className="p-in-sp">
                I need not say to this Committee that Virginia is vitally interested in this matter of tobacco; and it is a tremendous oppression that we have not the ability to let tobacco in free or with reasonable duties, to all the nations of Europe. When in Virginia we grow beyond seventy thousand hogsheads, it goes down, on account of the obstruction of the foreign tariff; and the profits of
                Virginia would be proportionately increased by the removal of this obstruction. I would like to know how any <PageNumber num={279} /> gentleman can expect, and I appeal to commercial men, and ask them how it is possible that this Government, as now constituted, with this extravagant tariff, can bring any European nation to reason upon the subject.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I have to say that no country, unless it be an extremely tropical one, ever grew rich by agriculture alone. Look at Poland, which absolutely led Holland for a century—the first during all this time the poorest nation in Europe, the latter the richest. Ireland has no manufactures; it has no commerce, and its population is starving in the midst of heavy exports of grain,
                potatoes, beef and pork. Sir, look at all the nations of the world, and you will find that commercial and manufacturing industry are absolutely necessary for their prosperity. And who can expect that Virginia will ever be anything but an agricultural State so long as she has any connection with this General Government? The tariff forbids it; it cannot be done; and her case is a
                hopeless one, unless you can have the industry of Virginia protected. I see no brilliant future for Virginia. She has no future. All she has is in the past. She is rich in memories, but has no present, no future.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, when we are divided, and become a great commercial and manufacturing people, then we shall fulfill the destinies of Virginia. Why, sir, look at Western Virginia. Her minerals are buried in the ground, with no hand to bring them up, doing no good to herself or to others, and will yet sleep there for ages under the wing of the protecting care of our General Government. 0!
                that we could be that Virginia that we ought to be. 0! that we could enjoy the blessings that Providence has so bountifully showered upon us. God has done everything for us; man has done everything against us. We have the finest country upon the face of the earth; the finest climate in the world ; every advantage that heart could wish for or mind conceive of, but the disadvantages
                which we are laboring under are thrown in the way by the General Government. I wonder at it, and admire the hopes of those who can look forward to any greatness in the future under the protecting wing of this Constitution. What source of hope have gentlemen now which they have not always had? What is the prospect of the future? Show it to me and I will embrace it. But I have never
                seen any one yet who could give me any rational hope that Virginia can be lifted from the dust.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, to the West I would say, that they have a town there now largely engaged in manufactures—the town of Wheeling. I say to them that when Virginia resumes her sovereignty, and when she goes as she will go in that event, with the Southern Confederacy, Wheeling is to become the Manchester of Virginia. She already supplies the
                <PageNumber num={280} /> South with some of her manufactures. I know that she has now a large trade with Louisiana, and with others of the Southern States; and if there be a disruption of this Union, I have no hesitation in saying that she would command the whole trade, or nearly the whole trade, of the lower Mississippi. And, sir, I know of no other
                part of Virginia that might not be compensated for the disruption of this government.
              </p>
              <p className="p-in-sp">
                But, Mr. Chairman, I am exhausted, and I will draw my remarks to a close. I may say to you that I look with no pleasure to the disintegration of this Government. It is a serious subject, a serious thing to break, or even to untie the bonds which connect a kindred people together. I know there is a risk. I appreciate it. And my affections have always been with the Union. I have always
                loved it. But, sir, these are not times when men's affections should rule them in their action entirely. They ought to have their rights, and we owe something to the future, something to our posterity. It does sadden my heart, Mr. Chairman, does it not yours, when you reflect that the great name of Washington, clustered around with blessings, and intended to carry those blessings
                down to the remotest posterity, may become but the miserable representative of an impracticable idea? It does concern me to reflect that monuments which have been erected to his memory, which pierce the skies, and bear on their fronts the story of his great name and his greater deeds up to the chancery of Heaven—that they should become warning beacons to tell adventurers upon the sea
                of liberty not to approach too near our dangerous coasts. This saddens my memory; but this will I bear and more I will bear. I would see Virginia, as dear as she is to me, sunk fathoms deep in earth and
              </p>
              <p className="p-in-sp">"To dumb forgetfulness a prey,"</p>
              <p className="p-in-sp">
                rather than to see her live forever a degraded thing, to be pointed at in all time by the slow unmoving finger of scorn; nay, rather than that, welcome destruction, the treason and the grave.<span className="note" id="Note33"
                ><span className="noteLabel">1</span>The words "the treason" are in the Enquirer. The sense seems unclear, but the speaker may have been quoting something unidentified.</span
                >
              </p>
              <p className="p-in-sp">
                But, sir, I think that I can discern, beyond Alpine heights which frown with ice, glacier and snow, the fair plains of smiling Italy. I think I see unfolded a flag upon Southern ground which will protect Virginia's interests, which will protect Virginians in their persons and their property, and, more than that, which will do what our present flag does not do, protect the honor of
                Virginia in every land and in every clime.
              </p>
              <div className="speaker" id="sp1040"><span className="persName">Mr. MOORE</span>, of Rockbridge—</div>
              <p className="p-in-sp">I do not rise, Mr. Chairman, to trouble Committee with a speech. My health would not permit it, if I desired. I rise simply to put myself right in reference to a matter alluded to by my friend from Halifax [<span className="persName">Mr. BRUCE</span>] , who has just taken his seat.</p>
              <PageNumber num={281} />
              <p className="p-in-sp">
                The gentleman, in his opening remarks, tells the Convention of the stand taken by himself and some forty others in 1832, in reference to the course of South Carolina then. But I think he takes more credit to himself and to the other forty than they are entitled to for any benefit which was conferred upon South Carolina by them; for that forty could have done nothing. But I would
                remind him that there were others whose efforts on that occasion were better calculated to promote the interests of South Carolina than even the course taken by himself. According to my recollection, although I condemned the course of South Carolina, I was for the preservation of peace, as I am for preserving it now; and I drew and offered in the House of Delegates a resolution that
                was afterwards amended, and the result of which was the sending of Mr. Leigh to South Carolina for the purpose of preventing a collision between the forces of South Carolina and those of the Federal Government. I think, then, I may claim credit for having done for the benefit of South Carolina as much as my friend from Halifax, or any other man.
              </p>
              <p className="p-in-sp">But the worthy gentleman has thought proper to hold me up as a belligerent character, as excessively bellicose. Now, sir, I really cannot understand the ground upon which that can be done. I have assailed no individual here, and it is injustice to impute to me such a character.</p>
              <div className="speaker" id="sp1041"><span className="persName">Mr. BRUCE</span>—</div>
              <p className="p-in-sp">I hope my friend will really not take in earnest the jest I took occasion to indulge in.</p>
              <div className="speaker" id="sp1042"><span className="persName">Mr. MOORE</span>, of Rockbridge—</div>
              <p className="p-in-sp">Well, sir, I hope the gentleman's explanation will go outside of this body and that the public will understand it as a joke. That is all. [Laughter.]
                Now, sir, I have manifested no disposition to assail any man here, nor have I manifested a disposition to make war upon South Carolina. On the contrary, as the worthy gentleman knows, in the Committee on Federal Relations and elsewhere, I voted for resolutions strongly condemnatory of any attempt upon the part of the Federal Government to coerce any of the seceding States. All that I
                have done here has been to defend myself when I have been assailed, about opinions expressed 30 years ago, and in reference to opinions which I am supposed to entertain now, but which I do not entertain.
              </p>
              <p className="p-in-sp">
                But, sir, my worthy friend has announced to the Committee that I am a great sinner. I do not suppose that he can have discovered that I am such in a political sense because of two things. In the first place, the gentleman and myself I believe have uniformly acted together upon all political questions when we have been together. And, in addition to that fact, when I had the good
                fortune to meet him here
              </p>
              <PageNumber num={282} />
              <p className="p-in-sp">at the beginning of the session of this body, he was exceedingly glad to meet me, and had always looked upon me as his file leader. [Laughter.]
                Well, sir, I do not know how it is that he has since found out that I am guilty of any great political sins, unless it be from other file leaders whom he has since adopted. He cannot, therefore, allude to me as a political sinner, but must make the allegation in another sense. Well, sir, I confess that I am a sinner; I cannot stand up here and quote Scripture by the whole page, and
                take the attitude which would seem to say "I thank God that I am not as other men are."
                [Laughter.] No, Mr. Chairman, I must stand back, like the poor publican, and ask God to forgive me as a poor sinner.</p>
              <p className="p-in-sp">Well, sir, the gentleman denies that I shall ever be admitted into the church. One thing is certain, if ever I do get there, you may count me there for good. [Laughter.]
                When I do get there, I will feel it a duty I owe to my God to act strongly, and to act in such a way that I may always be known where to be found. My friend here did me the favor to say that he knew where to count me. Mr. Chairman, I have thought for some time that I knew where to count him, but since the remarks he has been pleased to submit to this Committee, there is no longer any
                doubt about his position. Yes, sir, I think I may be counted on with some certainty, and I think the gentleman may be counted on with very great certainty, after the speech he has favored us with.
              </p>
              <p className="p-in-sp">
                But, sir, I am sorry my friend has found the Government of the United States so exceedingly bad, that it has never done anything for us. I do not like to refer to anything occurring out of this house, but my friend has the credit of having made a speech here, three or four years ago, in which he expressed the opinion that it was better that the whole meeting with which he was
                surrounded, numbering twelve or fifteen hundred persons, should perish in an instant, than that the Union should be dissolved; because, if these should perish, their place would be filled by others, but if the Union should once be destroyed nothing could ever restore it, or supply its place. I am sorry that my friend has made these discoveries.
              </p>
              <p className="p-in-sp">
                But the gentleman talks of taking me to South Carolina. Well, sir, I do not know whether I will ever get there or not. It may be that if I should become master of some of those cotton and sugar plantations, it might have a little effect upon my vote if ever I did get there. One thing I very much regret in the case of my friend: I am sorry that he did not have some of the principles
                which he has announced here with his cotton and sugar plantations when he gave them up.
              </p>
              <div className="stage it">[Laughter.]</div>
              <PageNumber num={283} />
              <p>The Chairman stated the question to be upon the amendment in the nature of a substitute offered by <span className="persName">Mr. CARLILE</span>.</p>
              <div className="speaker" id="sp1043"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I only rise to inquire about the effect of that amendment. Are you going to put the question on the motion to strike out?</p>
              <div className="speaker" id="sp1044"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">On the motion to strike out and insert unless a division of the question be called.</p>
              <div className="speaker" id="sp1045"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I was not enquiring whether the question was divisible or not. The question I desire an answer to from the Chair is, whether, if the motion to strike out and insert be decided in the negative, will it be in order subsequently to move to strike out and insert another substitute.</p>
              <div className="speaker" id="sp1046"><span className="persName">The CHAIR</span>—</div>
              <p className="p-in-sp">
                The Chair understands the inquiry of the gentleman from Princess Anne to be, whether, if the substitute of the gentleman from Harrison, which is pending, be decided in the negative, it will then be in order again to move to strike out the entire report of the Committee on Federal Relations, and substitute something in lieu of it. Does the CHAIR understand the gentleman correctly?
              </p>
              <div className="speaker" id="sp1047"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Yes, sir. The motion pending is to strike out and insert. The negative of that proposition is to refuse to strike out and to refuse to insert. What I inquire of the Chair is whether, if the motion put in this form be decided in the negative, it will preclude a motion from being made in future to strike out and insert?</p>
              <div className="speaker" id="sp1048"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Certainly not, if this motion is decided in the negative.</p>
              <div className="speaker" id="sp1049"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">
                I desire to offer, for the consideration of the Committee, some remarks upon the subjects now under consideration; but I had not anticipated an opportunity would have been presented to me to do so during this session of the Committee. I have at my room some preparations to which I desire to refer, and if no other gentleman desires to address the Committee at this time, I will move
                that the Committee rise.
              </p>
              <div className="speaker" id="sp1050"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">That motion is not in order, the Convention having directed that the Committee should remain in session until 2 o'clock, when a recess is to be taken until 4 o'clock.</p>
              <div className="speaker" id="sp1051"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">
                I had desired to avail myself of some opportunity to address some remarks to the Committee before this question should be finally disposed of, but I am not inclined to do so on the present occasion. I understand the question now before us is upon the substitute offered by the gentleman from Harrison, that substitute recognizing the propositions known as those which emanated from the
                Peace Conference. I hardly suppose it is necessary at this time to offer <PageNumber num={284} /> any reasons why that substitute should not be adopted. The Committee knows that the propositions which came to us from the Peace Conference were referred to the Committee on Federal Relations, where they received the deliberate consideration of that
                Committee; and the result of those deliberations was the report of certain amendments which that committee proposed as proper to be adopted to the present Constitution. It is a proposition to consider a competing scheme, without saying in how many particulars, in how many important particulars, the amendments proposed by the Committee differ from the propositions of the Peace
                Conference. Whilst, as it seemed to me, the propositions of the Peace Conference, rightly understood, properly interpreted, secure to the South these territorial rights which the gentleman from Kanawha represented to us it was the purpose of these propositions to secure, yet the language employed admitted of such criticism that, in the opinion of the Committee on Federal Relations,
                it was deemed proper to amend that language in certain particulars, and to add to the propositions other language beyond what these propositions contained when they were reported to us as the work of the Peace Conference.
              </p>
              <p className="p-in-sp">
                Now, sir, I don't propose to detain the Committee by entering upon any detailed comparison of the two schemes, but I rise merely for the purpose of saying that it seems to me there is at present no ground of comparison between the two. In the proposition submitted by the Committee, every ground of just criticism has been removed; every ambiguity has been stricken from it, and in
                addition to these recommendations other propositions designed for distinct purposes have been added to it that make it preferable in my opinion to the proposition of the Peace Conference.
              </p>
              <p className="p-in-sp">I understand, sir, that by a resolution which the Convention some days ago adopted, it is allowable in the committee of the whole to take a question by ayes or noes. I rise now, sir, for the purpose of demanding that the vote upon the substitute of the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] , shall be submitted to that test.</p>
              <p>The call for the ayes and noes was sustained.</p>
              <div className="speaker" id="sp1052"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I wanted to say, sir, to the committee here, that the President of this Convention having done me the honor even when absent I believe, to place me upon the Committee on Federal Relations, I have faithfully, as far as ill-health would permit me, endeavored to discharge my duty in that Committee. I have, against great disadvantage of labor, made a report placing this Convention—hoping
                to place this State upon a fair, conservative, middle-ground of action— <PageNumber num={285} /> safe, honorable, just and hopeful; hopeful to save not to destroy; standing upon the grounds of the old Union and trying to reconcile the two hostile Unions that have sprung up out of the old; fighting in the old Union and for the old Union, and aiming, if
                possible, to restore it.
              </p>
              <p className="p-in-sp">Now, sir, standing in that position, standing until to-day, I say today, because of the remarks made by the gentleman from Halifax [<span className="persName">Mr. BRUCE</span>]
                , who has just concluded his speech; standing until to-day alone almost, in that position in this House, hoping from the example which he has given me that I don't stand alone now; not despairing of my aims, ends and objects, I do desire before the debate closes to be heard fully. I care not how long it takes me, sir—in times of revolution like these, and when all that I have and all
                that my children after me will have to enjoy is at stake, I mean to be heard, and heard before a decision is made. I claim it as a right, as a member of this Convention, and as a member of that Committee standing in a peculiar position. I am not able to perform my duty now. My lungs are lacerated. Ever since you have been in session I have been troubled by an influenza that I fear is
                worse than common cold. If I die in the effort, they shall be risked, life itself shall be risked upon this question. I am not ready now—my physician cannot tell me when I will be ready. With inflammation from my head to my heels I have not a voice for my constituents this day or this week. I only ask that an opportunity shall be given me that their voice shall be heard on this
                floor. I stand to them in a peculiar position. I don't agree with them in mere party politics, and they have trusted me although differing from them, and if I betray them "may my tongue cleave to the roof of my mouth and may my right hand forget its cunning." This debate has not been opened yet. The points that press upon me and upon my constituents have not been touched in my
                hearing to my satisfaction ; and before I am done with this crisis I mean to be satisfied; and those who are satisfied without satisfying me shan't have peace either here or elsewhere for ever as long as I live.
                [Laughter.] I am obliged, after giving this notice, to ask for this courtesy—to ask this Convention to wait a little while, if necessary, before the debate is concluded, for a sick man—a sick committee man, who stands solitary and alone upon the other side of that assumed by the Committee.</p>
              <p className="p-in-sp">I cannot help now, sir, saying one thing in reply to the remarks of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
                . If I heard the gentleman aright, just now, in what he said—if what he said meant anything pointed and particular—he said and meant to say that there is no <PageNumber num={286} /> difference between the report of the majority of the Committee and the report of the "Peace Conference," except that the report of the majority of the Committee explains and
                makes unambiguous the meaning of the report of the "Peace Conference."
              </p>
              <div className="speaker" id="sp1053"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">
                I said that there were important features added which were not to be found in the propositions that came from the "Peace Conference," and expressed the opinion that in respect to that section of the "Peace Conference" proposition which related to the rights of the slaveholder in the territories; that according to a just construction and proper understanding of its meaning, these
                rights have been properly secured; but the language employed was of doubtful import, and this language had been amended by the Committee.
              </p>
              <div className="speaker" id="sp1054"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Sir, give me the time and an opportunity, and I will show to those who have approved of that Peace Conference proposition, that this Committee of Twenty-one have not approved of the Peace Conference proposition in any one particular; that they have disapproved of it, and in fact torn it into tatters, and there it lies in miserable tatters; that there is no agreement between the two.
                I will propose to show you—I will show you a material, an essential difference to the poor minority that is now asking for protection; I will show you that the Peace Conference did not "bell the cat;" I will show you that the Peace Conference saw the cat in the meal; and I will show you that the Committee of Twenty-one have "belled the cat" and turned him loose. No man, after
                comparing the report of the Committee of twenty-one with the result of the "Peace Conference," will ever touch the "Peace Conference" with a forty-foot pole hereafter—never. The committee of twenty-one "belled the cat." They have shown you by their own report that there was a "cat in the meal." It was showing itself so strongly in everything in this "Peace Conference" proposition
                that it was necessary to change the language of the report of that Conference. I do mean to take up these reports and to compare them. You are shown where the one infringes upon the other, not only in language, but in idea, in thought, in application, in construction—in all the wide differences between tyranny and protection. I will show you that the Committee of Twenty-one have gone
                further than the Peace Conference have; that they were not content with the ultimatum that was proposed by the "Peace Conference;" that they had to go beyond what was demanded and admit the necessity of demanding far more than was demanded by the "Peace Conference." I honor them for it, and shall aid them, as a minority man, in demanding more. They have not only added to but they
                have excised that "Peace Conference" <PageNumber num={287} /> proposition. It will not bear the examination of any man that understands the rights of the South or of the slaveholder, and, understanding, means to maintain them. Give me time, sir, and I will prove that I am right in all that I say in reference to these reports. I would say that I would
                take the majority report in preference to the Peace Conference Proposition; but God save me from those who cry peace, peace, coming to me in the form of Bellona!
              </p>
              <div className="speaker" id="sp1055"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I have only a word or two to say before the vote is taken.</p>
              <p className="p-in-sp">I too was a member of the Committee of twenty-one, and I continued on that Committee, discharging the duty that was imposed upon me, until I was called away from here, and then I paired off with one of the members on the other side.</p>
              <p className="p-in-sp">
                I differed from the majority of that Committee, and from the minority of that Committee that did not agree with me radically. I differ from them now, radically. Unlike the members of that Committee, whether the majority or the minority, I, in common with the constituency that I represent, have utterly despaired of continuing this Union and protecting their rights and the rights of
                the South within its limits I have made up my mind, as my constituents have made up theirs, to make no further propositions for adjustment—to ask no further surrender from the North of the powers that they claim—I am unwilling to put myself and my constituents in the attitude of proposing an ultimatum to the North which might be adopted by the North and which might be rejected by the
                South. I will not make a proposition to the North, believing as I do that the South has claimed no more than it is entitled to, and this it ought to have. I do not want now to present an ultimatum to the North, the acceptance of which would estrange me from the people of the South, and attach me to the North. I want to know the position that Virginia will place herself in if she
                presents a proposition to the North which the North will accept, but which the South will reject. I don't believe that we can remain or that we ought to remain in this Confederacy, no matter what parchment guarantees are granted to us by the Northern States, in the absence of the support that we are entitled to, and will have if we are connected with the Southern States.
              </p>
              <p className="p-in-sp">
                So far as I am concerned, and so far as my constituents are concerned, without one solitary dissenting voice, so far as I know, we have determined so far as we are able, to make a common destiny with the cotton States and to break up this Union, unless those States come back. We will take nothing unless with the condition that these States
                <PageNumber num={288} /> of the South are brought back. Now, sir, I came here to make demands for Virginia. I was sent here to make demands from Virginia upon the North. I never will consent to occupy the position of placing Virginia in a mediatorial position, as was proclaimed by that committee, to settle this question between two competing parties,
                the party at the South, South of us, and the party at the North, North of us. I am here contending for what these cotton States have broken up this Union for. I do not acknowledge that there is any Union now existing. I believe that we are as much a part of the United States at the South as of the Union of States here at the North. I think that the only question for us to decide here
                is to which Confederacy will the people of Virginia go, that to the North of us, or that to the South of us? I will not recognize the fact that has been acted upon in the committee throughout, from the day we went into committee, if I may be allowed to speak of it, up to the moment they made their report, that we are to make another tender to the North, in order to get our rights
                from them. I will never make another demand upon the North, so far as I am concerned, except with the sword in my hand. When I go to the North again, it must be in the form of defiance and demand. I will never place Virginia in the attitude of supplication hereafter, as long as I continue to represent a portion of her sovereignty.
              </p>
              <p className="p-in-sp">
                I have made these remarks to make it clear why it is that I cannot support either or any of the reports emanating from any member of this Committee, except the one signed by myself and colleagues; but, at the same time, while I make that declaration, I will vote as between any two competing propositions for that which demands the most, for that which exacts the most for the South
                against the encroachment of the North; and upon that principle, if the vote be taken, as I suppose it is now to be taken, I shall vote against the award of the Peace Conference and in favor of the award of the Committee of Twenty-one.
              </p>
              <p className="p-in-sp">
                I do not know that I shall trouble this Committee with any extended remarks in regard to the various propositions that will be submitted. I want to ask the Chairman, before I take my seat, whether I understood him last week when he gave his opinion as to the rules of order, as saying that no vote should be taken in this Committee upon the result of the proceedings of this Committee
                as a whole. I wish to know whether he confined his remarks to the action of this Committee, or whether, if reported to the Convention, that then the Convention should vote upon the report of the Committee as a whole.
              </p>
              <div className="speaker" id="sp1056"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">
                The gentleman from Amelia has asked for information, and the Chair will take great pleasure in giving the gentleman <PageNumber num={289} /> the information he desires. The Chair announced the other day that he would relax, to some extent, the strict parliamentary rule, and would receive, as he has just received, a motion to strike out the entire
                committee's report, and insert therein, as a whole, any substitute that might be offered by way of amendment. During the pendency of such amendment, any gentleman who desired to offer a substitute that could not then be entertained as an amendment for the pending substitute, might indicate to the committee the contents and character of his amendment, and those who agreed with him,
                and opposed the pending amendment, might unite in voting it down, and so on from time to time, through the various stages, until the report of the Committee on Federal Relations overcame all opposition to that extent. After the committee's report should overcome all the substitutes that were offered, then the Convention would take up the committee's report, and proceed to consider
                its distinct, independent propositions seriatim; and after that, then any distinct proposition which might be adopted by this Convention, would not be open to amendment or reversal.
              </p>
              <div className="speaker" id="sp1057"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">Do I understand the Chair as construing that in the Convention we should have to go over the work of the Committee again?</p>
              <div className="speaker" id="sp1058"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Convention may do precisely whatever they please with this Committee's report, amend, modify or any thing else they think proper.</p>
              <div className="speaker" id="sp1059"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">Of course I shall not make any appeal from the decision of the Chair, but I do protest—</p>
              <div className="speaker" id="sp1060"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">If the gentleman is going to argue against the opinion of the Chair he must make an appeal.</p>
              <div className="speaker" id="sp1061"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I do not want to make an appeal at this time. I want to call the attention of the Chair, if the Chair will permit me to make a suggestion.</p>
              <div className="speaker" id="sp1062"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair cannot permit the gentleman from Amelia to argue against any decision of the Chair, unless an appeal is taken.</p>
              <div className="speaker" id="sp1063"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">Can I appeal from the decision of the Chair?</p>
              <div className="speaker" id="sp1064"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Certainly.</p>
              <div className="speaker" id="sp1065"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I want to appeal from the decision of the Chair that makes final and conclusive the action of the Committee upon the whole proposition presented, because there has been a vote upon each of the branches of the proposition.</p>
              <div className="speaker" id="sp1066"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman will have the full opportunity when that time comes, to take his appeal upon the distinct proposition.</p>
              <PageNumber num={290} />
              <div className="speaker" id="sp1067"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">I do not propose to make a speech of any length upon the propositions now pending. My object is merely to indicate to the Committee a sensible course of action that we might take and also to indicate my vote upon the amendments offered by the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>]
                . My impression is that the report of the majority of the Committee is wrong. The amendment is wrong, and all the proceedings so far as we have acted in Committee or in the Convention have been wrong; provided, we want to attain a certain object, that of the restoration of the Union. If this Committee is really desirous of re-constructing or re-organizing this broken Union again, in
                my candid opinion, they have but one course of policy to pursue. We all admit the necessity of amendments to the Federal Constitution and that a re-construction of it is necessary. We have such a re-construction done to our hands by the people of the Confederated States South.
              </p>
              <p className="p-in-sp">
                Let us at once, if their recommendations are reasonable—if their suggestions are proper—if their constitution is such a constitution as commends itself to the judgment of mankind—accept the Constitution as it has come from the hands of the confederated States South. I defy the distinguished gentlemen of the committee of twenty-one to sit down and prepare a better constitution than
                that which is now presented to the world by the confederated States of the South. Let us discard all this stuff, all this balderdash we have been hearing about this and the other thing, and come to some practical result. Let us go to work and reconstruct the Union. I feel encouraged by the interest taken in reference to this matter by the whole conservative press of the North, which,
                with hardly an exception, appeals to Virginia to adopt the constitution of the confederated States of the South.
              </p>
              <p className="p-in-sp">I hope it will be the pleasure of the Committee to vote down the amendment of the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] , because of all the subterfuges, cheats or attempted frauds, that takes the lead.</p>
              <div className="speaker" id="sp1068"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">I understand that a division of the question may be called for. Has that division been called for?</p>
              <div className="speaker" id="sp1069"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">No, sir.</p>
              <div className="speaker" id="sp1070"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">Then I call for a division of the question.</p>
              <div className="speaker" id="sp1071"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">
                If I can get the attention of the gentleman for a moment, I would appeal to him to withdraw it. It merely involves a waste of time. The proposition now is to strike out and insert a certain substitute. We have four or five other substitutes upon the table which will be offered in succession. The proposition here, to strike out, is a proposition to strike out in order to insert a
                particular one of these <PageNumber num={291} /> substitutes. It will be in order, although the Committee may refuse now to strike out, to offer any other substitute; and it will be equally in order, if the Committee determine to strike out to offer any other substitute; so that a division of the question would be merely an unnecessary consumption of
                time. But, in addition to that, it will involve some confusion in the minds of members in voting upon these various propositions. I would ask, therefore, the gentleman from Preston
                [<span className="persName">Mr. BROWN</span>] , to withdraw the motion he has made.</p>
              <div className="speaker" id="sp1072"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">In compliance with the suggestion of the gentleman, I will withdraw the motion I made.</p>
              <div className="speaker" id="sp1073"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">The gentleman from Harrison [<span className="persName">Mr. CARLILE</span>]
                , who has proposed this substitute is not in his place. It seems to me to be rather a legislative anomaly for a Committee to consider a proposition offered by a gentleman in his absence. I have thought, perhaps, it would be well, under these circumstances, that this pending substitute should, by general consent, be withdrawn. It would be competent at this stage of the proceedings, I
                presume, to adopt that course.
              </p>
              <div className="speaker" id="sp1074"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">That can be done, if the proposition is made and the Committee, by general consent, agree to it ; but the proposition is now the property of the Committee.</p>
              <div className="speaker" id="sp1075"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">I had no opportunity of ascertaining the wishes of the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>]
                , or of desiring him on some occasion hereafter to present his proposition as a whole. I take the responsibility of suggesting to the Committee its withdrawal by general consent. It will expedite the consideration of other pending propositions between which and the report of the Committee of 21 there are more marked differences. In a parliamentary point of view the report of the
                Committee of 21 ought to be as perfect as possible before a substitute, such as the one now pending, is adopted in its place.
              </p>
              <div className="speaker" id="sp1076"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Does the Chair hear any objection made?</p>
              <div className="speaker" id="sp1077"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">I object.</p>
              <div className="speaker" id="sp1078"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">Mr. Chairman, I rise—as you went into committee whilst I was absent—to enquire what is the original proposition to which a substitute is now proposed. Will the Clerk read it?</p>
              <div className="speaker" id="sp1079"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The entire report of the committee of twenty-one.</p>
              <div className="speaker" id="sp1080"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">The entire of which report?</p>
              <div className="speaker" id="sp1081"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Of the committee of twenty-one, sir, the only two reports the Chair is aware of.</p>
              <div className="speaker" id="sp1082"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">Which two?</p>
              <div className="speaker" id="sp1083"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The partial report and the proposed amendments appended to that report.</p>
              <PageNumber num={292} />
              <div className="speaker" id="sp1084"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">Well, does the Chair consider both these reports as being before the committee?</p>
              <div className="speaker" id="sp1085"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">They are but one report, and that is now under consideration.</p>
              <div className="speaker" id="sp1086"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">Mr. Chairman, I ask again, as there were two reports made by the Committee of twenty-one, which report is now before this committee?</p>
              <div className="speaker" id="sp1087"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The two reports constitute a whole; the chair does not consider that they are two distinct reports.</p>
              <div className="speaker" id="sp1088"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">The Chair thinks then that two is one. Am I right in that?</p>
              <div className="speaker" id="sp1089"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Sir?</p>
              <div className="speaker" id="sp1090"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">Does the Chair think that two is one?</p>
              <div className="speaker" id="sp1091"><span className="persName">The CHAIR</span>—</div>
              <p className="p-in-sp">Under these circumstances, it does, sir.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp1092"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">"Under these circumstances it does." [Renewed laughter.] Well, what part—if the report is a whole—what part of the two reports is now under consideration?</p>
              <div className="speaker" id="sp1093"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The entire report, both of which being partial reports, constitute one report, and that whole report is now pending before the Committee, to which there is an amendment offered by the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] .</p>
              <div className="speaker" id="sp1094"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">That requires the most curious grammar I ever heard of—"the entire report, both of which."</p>
              <div className="speaker" id="sp1095"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair does not wish to discuss with the gentleman from Princess Anne the question of grammar.</p>
              <div className="speaker" id="sp1096"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">The Chair has made this decision itself. I submit that it is a question for the Committee. I submit that the Chair is not sole arbiter of this question. This statement of the Chair places us in a very false position.</p>
              <div className="speaker" id="sp1097"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman mistakes the Chair if he supposes for one solitary moment that it thinks it has arbitrary power to decide any question.</p>
              <div className="speaker" id="sp1098"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">I might be willing to substitute a motion made by the gentleman from—</p>
              <div className="speaker" id="sp1099"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">Will the gentleman from Princess Anne allow me one moment?</p>
              <div className="speaker" id="sp1100"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Does the gentleman yield the floor.</p>
              <div className="speaker" id="sp1101"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">Certainly.</p>
              <div className="speaker" id="sp1102"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">The Chair has stated the proposition of the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>]
                , fairly. I know it, because I heard the proposition offered, and conversed with the gentleman afterwards <PageNumber num={293} /> as to the scope of his proposed amendment. It was to strike out the whole report of the committee of twenty-one, embracing the resolutions reported as a partial report, and the proposed constitutional amendments afterwards
                added to that report, to strike out the whole and insert simply the propositions of the Peace Conference amended, as a substitute for the whole.
              </p>
              <div className="speaker" id="sp1103"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">If this is so, the journal will decide the question. Mr. Clerk, read the motion. I call for the reading of the motion. That will determine it.</p>
              <p>The CLERK read the motion from the journal as follows :</p>
              <p>"<span className="persName">Mr. CARLILE</span> moved to amend the report of the Committee on Federal Relations by striking out the whole, and inserting in lieu thereof a proposition which is included among documents hereto appended."</p>
              <div className="speaker" id="sp1104"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">
                That motion on the record says no such thing. It is an indefinite motion. The Committee of twenty-one have made two reports—one that they call the partial report of the Committee on Federal Relations, and the other the report of the Committee on Federal Relations, with the proposed amendments to the Constitution of the United States. Now, I say that they are independent of each other
                in matter; that they have no unity or community of purpose or design, and that they were made at different times. There is no unity in them; and I want to know which report they would substitute this Peace Conference for. Non constat, sir, that I might vote for the substitute and against the other.
              </p>
              <div className="speaker" id="sp1105"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Does the gentleman appeal from the decision of the Chair? If he does not, there is no question before the Committee.</p>
              <div className="speaker" id="sp1106"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">I want to reason with the Chair.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp1107"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair would have great pleasure in hearing the gentleman, but perhaps the Committee might desire to proceed to the business before it.</p>
              <div className="speaker" id="sp1108"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">Well, it certainly ought to be the pleasure of the Chair to answer an enquiry.</p>
              <div className="speaker" id="sp1109"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Certainly.</p>
              <div className="speaker" id="sp1110"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">Because, when the Chair makes a decision, and I don't understand the decision, I cannot say whether I am going to appeal or not. [Laughter.] Now, will the Chair tell me which of these two reports is meant? It is "the report." There is the definite article. "Two reports" would be in the plural. And there are two reports. The gentleman—the Chairman of the Committee of twenty-one—told us <PageNumber num={294} /> what the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>]
                is not here to tell us, whether he meant his proposition as a substitute for the whole, or for one. I say, arithmetically, in this case, and in all cases, that two are not one. And you cannot talk about two as one without making bad grammar. It is suggested to me that there was one in many, once. Yes, sir, there was one in many, once. "E Pluribus" Unum in this country, and now the
                Union is turned into two. Out of the "E Pluribus Unum" have come two Confederacies, and now out of two reports come one. I don't understand this e converso. Now, I wish it understood, that the Committee of twenty-one have one report that is called a partial report, from the Committee on Federal Relations, presented March 9th, 1861. Then they have a report not called a partial report,
                but called "a report" of the Committee on Federal Relations, proposing amendments to the Constitution of the United States. Mr. Clerk, will you tell me when this was made—this last report of the Committee of twenty-one?
              </p>
              <div className="speaker" id="sp1111"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">
                I hope my friend from Princess Anne will allow me to interrupt him for a moment, in order to relieve him, for I am really afraid, sir, that he is likely to injure himself; the condition of his voice is dangerous, and I fear that he is exerting himself entirely unnecessarily. If the gentleman will look at those two, which he calls two reports, he will find that in point of truth, upon
                the face of this report, it appears they are but one. The partial report of the Committee in the eleventh resolution speaks of certain proposed amendments to the Constitution, which are "hereunto appended ;" and when that partial report was made by the Chairman, under the direction of the Committee, he stated that the Committee had not entirely prepared those proposed amendments, but
                would afterwards bring them in, and ask to have them appended to this report. Accordingly, when the second part of this report was made embracing these constitutional amendments, the gentleman will find that that again professes to be only a part of the report originally made; for he
                [the Chairman] , asked these amendments to be appended to their former report, so that the Committee considered them as partial reports, both to constitute one report. I apprehend, however, sir, that this is not a point of any real practical moment. I know that the proposition of the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>]
                , was a substitute for the whole, embracing both the portions of this report—a simple series of proposed constitutional amendments, emanating from this Peace Conference. And such it appears upon the journal; for there is but one report referred to. It does not say the first, or the last <PageNumber num={295} /> report of the Committee; but it speaks of
                both reports truly and correctly as one report of the Committee on Federal Relations.
              </p>
              <div className="speaker" id="sp1112"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">
                This report of the Committee on Federal Relations, sir, is a very curious article. It reminds me of some of the vermiculi which, when broken, the parts become distinct animals in themselves; they unite with each other, and they are indestructible in their vitality. I suppose this Committee on Federal Relations is like a snake dragging its slow length along. That Committee has not
                done reporting yet. This is the first of it, but it is not the last of it. I suppose we are to have a new vertebra every day.
              </p>
              <p className="p-in-sp">Now, sir, I want to know what I am to prefer. The whole report, I am told. I must prefer or reject the whole of this Peace Conference proposition. Is that what I must do? I only want to know, in order that I may understand the question.</p>
              <p className="p-in-sp">My friend [<span className="persName">Mr. CONRAD</span>] alarms me when he tells me he is alarmed about my health. If he is inclined to treat me as a quack, I would assure you that I have been taking medicine of quacks so often that I feel much the worse for it already.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp1113"><span className="persName">Mr. SUMMERS</span>, of Kanawha—</div>
              <p className="p-in-sp">Mr. Chairman, the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] , who moved the amendment, I understand, is not now present. He is absent I presume because of no expectation on his part that we should be called on to vote to-day. I do not know what his purposes or preferences might be if he were present, whether he would desire that this question should be pushed to a vote now, or whether he would withdraw it.</p>
              <div className="speaker" id="sp1114"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">He can't withdraw it.</p>
              <div className="speaker" id="sp1115"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">
                I know nothing of his wishes upon that subject. I should prefer, if there be any legitimate mode to reach that end, that an opportunity be afforded to the gentleman who offered this substitute to indicate his own wishes in regard to it. At the same time I don't feel at liberty to have the Committee now rise. I am anxious that we should proceed with the business that remains to be
                transacted. I rise at this time mainly to say that if we are now to be brought to a vote upon the proposition to strike out the report and insert the substitute, I shall feel myself compelled to vote against that proposition. The Convention will bear me witness that when I had the honor to submit some remarks in regard to the proposition of the Peace Conference, some days since, and
                when I spoke of the first section—which was mainly the subject under discussion—that while I regarded it as plainly, and distinctly and fully covering the rights claimed by the Southern people growing out of the Territorial difficulty, I remarked <PageNumber num={296} /> that in deference to the criticisms of others—the cavils, if you please, of
                others—I saw no objection to verbal changes in order to remove all objections to it. I also indicated a preference on my part that a certain portion of that adjustment should be entirely excised. These were matters which were under consideration by the Committee on Federal Relations. A reference of this matter was ordered by the Convention. Our Committee of Twenty-one have considered
                the matter referred to them and have reported the measure back to us somewhat modified and amended. I find myself entirely at liberty to accept and adopt the proposition which has been submitted to us by the Committee. While I maintain the opinion that alterations, in some particular, have been made, not necessary at all; yet, willing to meet the opinions of others, willing to meet
                even the prejudices of others, I accept, with entire satisfaction, the report in all these particulars as it has been furnished to us by the Committee on Federal Relations. Now, sir, I am called upon to vote upon a proposition to strike out the Committee's report and insert in its stead the proposition offered by the gentleman from Harrison
                [<span className="persName">Mr. CARLILE</span>]
                . The proposition offered by that gentleman is not only a substitution for the Committee's amended—and I think in those particulars improved report—it is not only to strike out the report, and insert in lieu of it a proposition in regard to which I would say that there was a portion of it that I would have desired myself to have seen curtailed—it is not only that, but to insert a
                proposition for a submission of it to a vote of the people, ignoring all that part which looks to a Conference of the Border States. I understand that if the motion of the gentleman from Harrison to strike out and insert shall prevail, we adopt virtually a proposition for a submission to the people of the proposition of the Peace Conference for ratification or rejection, for approval
                or disapproval by them, and the rejection also of the proposition to confer with the other States remaining in the Union. So understanding the question, I am not prepared to vote for that proposition. On the other hand—and I regret somewhat that the motion to divide the vote has been withdrawn—if the vote is taken in the first place on the motion to strike out, I was willing to give
                the gentleman from Harrison an opportunity to test the question again, as I understood from the Chair that a simple motion to strike out if overruled would have reserved the right.
              </p>
              <div className="speaker" id="sp1116"><span className="persName">Ex-Governor WISE</span>—</div>
              <p className="p-in-sp">Not the same proposition.</p>
              <div className="speaker" id="sp1117"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">
                Well, it is not of much consequence. I was only in courtesy indicating that I was willing to give him an opportunity <PageNumber num={297} /> to test it again; but as the proposition is now before us, I must vote against it. And for a further reason that I am willing to indicate now my purpose, so far as I have any, to take the report of the Committee
                on Federal Relations as against other propositions, so far as any others have been submitted, while I shall hold myself in readiness to hear argument on any other propositions that shall be offered in substitution of it; yet as I am at present advised, I am willing to stand by the Committee's report against all others. Then I understand from the Chair, that the Committee's report
                will be open to amendment and modification. I have no modification or supposed improvement to submit at present—perhaps would have none at all—but, as I said before, I was only indicating that, for myself, I was disposed to look to the Committee's report as acceptable to me.
              </p>
              <p className="p-in-sp">This is very briefly what I have to say. I thought it necessary to say this much, to show why I shall feel constrained to vote against the proposition to strike out and insert. I prefer, however, that the gentleman from Harrison, who moved this substitute, should have the opportunity of being present when this proposition is to be acted on by the committee.</p>
              <div className="speaker" id="sp1118"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">
                I beg to say that I differ from my friend from Kanawha, about this being a question of courtesy. If the gentleman from Harrison were here, and desired to withdraw his proposition to amend, and the withholding of my assent could prevent its withdrawal, I would withhold that assent. I am exceedingly anxious that a direct vote shall be taken upon this proposition, and of determining
                whether or not our Representatives in the Congress of the United States, in refusing to accept the propositions of the Peace Conference as acceptable to the people of Virginia, truly represented this old Commonwealth. I am exceedingly anxious to see whether a majority of this Convention prefer the propositions of the Peace Conference to the report of our own committee, and if so,
                that they shall have an opportunity of recording their votes in favor of it. And inasmuch as if the gentleman who made this proposition were present, as he was early in the session this morning, I could, by withholding my assent, prevent its withdrawal, I hope the vote will be taken upon it, and that the Convention will proceed to vote now, unless some gentleman desires to speak upon
                the question now before this committee. And if, on the contrary, gentlemen agree that the Peace Conference propositions shall be voted down, I want to see them vote them down.
              </p>
              <div className="speaker" id="sp1119"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">I only want to say to the gentleman from Northampton [<span className="persName">Mr. FISHER</span>]
                , in connexion with the remarks I submitted <PageNumber num={298} /> a few minutes ago, that the vote upon this substitute certainly will not be regarded as a test vote so far as I am concerned, and I imagine it will not be in regard to a very large portion of this body; because, as I remarked before, it is not merely the presentation of the
                propositions of the Peace Conference, inasmuch as it has connected with it, propositions for its submission to a vote of the people and not for its submission to a Border State Convention.
              </p>
              <p className="p-in-sp">
                I said expressly that in my opinion, the report presented by the Committee on Federal Relations was in some respects rendered more acceptable by change of phraseology and by the omission of a portion of the report upon which I spoke at large the other day. But, sir, I will not detain the Committee. I only desired to say that the vote upon the adoption of this substitute cannot in any
                regard be considered as a test vote upon the propositions of the Peace Conference.
              </p>
              <div className="speaker" id="sp1120"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">I call for a division of the question upon striking out and inserting.</p>
              <div className="speaker" id="sp1121"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">If there be no objection, the question will be divided, and the question will be first upon striking out the entire report of the Committee on Federal Relations.</p>
              <div className="speaker" id="sp1122"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I desire a vote directly upon these Peace Conference propositions, and I therefore object to a division of the question.</p>
              <p>The question was put, and the Committee refused to order a division of the question.</p>
              <div className="speaker" id="sp1123"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">
                I merely rise to give a reason for the vote I shall cast upon the motion to strike out and insert, for I do not want that vote to be construed as a condemnation on my part of the propositions of the Peace Conference. I am perfectly willing to accept these propositions as they came from the Peace Conference. But, sir, the substitute of the gentleman from Harrison, embodies certain
                resolutions which, in their present form, I cannot vote for. I understand from the Chair, that if the motion to strike out and insert shall be decided in the negative, the proposition of the gentleman from Harrison, may be amended in Committee. For that reason I shall vote against the motion; and I, in so voting, do not want to be considered as passing condemnation upon the Peace
                Conference propositions.
              </p>
              <div className="speaker" id="sp1124"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                I think it is proper to say, in explanation of the vote I shall give, that I do not consider it properly made or as capable of being made a test question upon the propositions of the Peace Conference standing by itself. I have already intimated, by the substitute I have offered for the report of the Committee on Federal Relations, my distinct willingness to accept the propositions of
                the Peace Conference <PageNumber num={299} /> as a fair settlement for the question. But, sir, I would not now vote for the adoption of my own proposition as a competing proposition with the report of the Committee. I urged upon them, and I urged upon my friends in this body as a matter of policy and of propriety that they should accept the propositions
                of the Peace Conference as they were presented to us. That view, however, did not prevail in the Committee, and we went to work to remove by amendment the objections and the criticisms that had been made by members of the Convention and others. The result is that while I believe the report of the Committee, in the main, substantially nothing more than what would result from a fair
                construction of the Peace Conference propositions, yet certainly the language has been improved; it has been made more clear and definite, and, therefore, while I was willing to take the propositions of the Peace Conference as a whole, I will not now vote for it in any way or shape against the report of the majority of the Committee as a competing proposition; but gentlemen cannot
                get any test vote upon the Peace Conference propositions by any such action.
              </p>
              <div className="speaker" id="sp1125"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">After the remarks of the gentleman from Northampton [<span className="persName">Mr. FISHER</span>] , I consider it due to myself and to my constituents to claim the privilege now accorded to me to render some of the reasons which will govern me in the vote I am about to give.</p>
              <p className="p-in-sp">
                I protest in the outset against the discrimination the gentleman from Northampton has endeavored to make. So far as I am concerned I certainly shall indulge in the privilege of remonstrance against any vote I may see proper to give upon the amendment now before us being construed as an intention, upon my part, to condemn the action of any portion of the Commissioners selected by
                Virginia, and acting in the Peace Conference, or upon any proposition submitted by the Peace Conference. And I protest against the division upon the part of the Committee of the propositions now pending before us in the absence of the gentleman from Harrison. I thought it was due to him, as a member of this body, that he should be present here and proclaim the reasons which induced
                him to offer this proposition as a substitute for the report of the Committee of twenty-one. The Committee, in the exercise of its judgment and discretion, refused that privilege.
              </p>
              <p className="p-in-sp">
                In the first place, so far as the gentleman from Harrison is concerned, by acting in his absence, we not only deprive him of the privilege of controlling his own proposition, but we are acting under circumstances which every gentleman knows were perfectly unexpected by all of us, for I presume no gentleman in this Convention entertained
                <PageNumber num={300} /> the idea for a single moment that this discussion would close by half past one or two o'clock. We know perfectly well that it was expected a gentleman would be ready to take the floor when the gentleman from Halifax
                [Mr. Bruce] had concluded his remarks. It was supposed that there would be full and ample discussion before any vote should be taken. The gentleman from Princess Anne [<span className="persName">Mr. WISE</span>]
                has explained why he cannot now proceed to assign the reasons which have induced him to present the propositions which he proposes to offer as a substitute for the report of the Committee. So far as I am concerned, I am perfectly willing to extend a reasonable time to the gentleman from Princess Anne to give his views and to give them in full in this Committee, and I am unable to
                give perhaps an intelligent vote upon his propositions without the light of his reasons before me—reasons which he says he holds in reserve and which I presume will be presented with his usual power and acumen.
              </p>
              <p className="p-in-sp">I propose, sir, to vote against the proposition to strike out the report of the Committee of twenty-one, and to insert the proposition of the gentleman from Harrison. I shall cast that vote for reasons which are satisfactory to myself, but in so doing I protest against any construction of that vote such as is suggested by the gentleman from Northampton [<span className="persName">Mr. FISHER</span>] .</p>
              <div className="speaker" id="sp1126"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I would say that if the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] desires to speak upon his resolutions I would vote for a motion that the Committee rise with a great deal of pleasure; and if the gentleman from Wheeling [<span className="persName">Mr. CLEMENS</span>] will say he believes the gentleman from Harrison desires to speak I will vote for that motion now.</p>
              <div className="speaker" id="sp1127"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">I would suggest as a matter of accommodation, and in order to expedite business, as it now wants but twenty or twenty-five minutes of the usual hour of adjournment, that the Committee now rise.</p>
              <div className="speaker" id="sp1128"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Committee must take a recess until 4 o'clock, when the hour of two arrives.</p>
              <div className="speaker" id="sp1129"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">
                One word in reply to my friend from Augusta who protests against this being regarded as a test vote for gentlemen. Now, sir, I do not know what the gentleman from Augusta may regard as a test vote, or upon what principle he intends to vote upon the proposition, but I intend to vote at this time against that proposition, as I shall at all times. I shall vote upon it now or whenever I
                get an opportunity, as a competing proposition to the report of the Committee on Federal Relations. I know how that vote will result now <PageNumber num={301} /> just as well as if I had heard the roll called. It will result in voting down the propositions of the Peace Conference, as they will be voted down when coming in competition with any
                proposition giving any sort of protection to the South, and that is the reason why I want a vote upon the Peace Conference propositions, and will not consent that they shall be withdrawn. I repeat, sir, that I want a vote upon the award of the Peace Conference, and I repeat, that, whenever the vote is taken upon it, in whatever shape it comes up, it will be voted down. That is the
                reason why I want a vote now.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, while I am up, I desire to make another declaration. It is said that I and those who act with me are in a minority upon this floor. Just let those gentlemen who so think bring forward any distinct proposition, and let the friends of that proposition vote for it, conflict with the friends of secession on this floor, and I hazard nothing in saying that the friends of
                secession will be stronger than the friends of any other proposition. I do not know how, after having gone through the sieve, after having riddled scheme after scheme, after having stricken down one proposition and put up another, going on at that rate of amalgam, I do not know whether any proposition may be brought forward, by combining all together, that will beat secession, but
                let them take any one distinct proposition now and I tell them that secession will certainly beat it.
              </p>
              <p className="p-in-sp">
                But we have to take these things in detail, one by one, and when we have disposed of them all, I will give you a chance to vote upon secession; and I tell this Convention that, if they will sit here long enough to hear from the people, they will pass an ordinance of secession, or if they will let such an ordinance go to the people, they will find the people ready for it; and if they
                reflect the will of their constituents, they will afford them the opportunity of a vote upon it. Gentlemen may refuse to meet the question, they may delay it by one mode and another, but I tell them that, if they will allow us to take a vote upon it in this Convention, it will receive more votes than any single competing proposition they can bring. And I tell gentlemen another thing:
                When they called this Convention together, they appealed to the people. They told us that they must have the popular sanction for what we did. I tell them to go before the people upon what they do. I defy them to go before the people upon what we do. I ask them to give us an opportunity of showing where the people stand. Let them meet the question fairly, and the people will meet it.
              </p>
              <p className="p-in-sp">
                But they propose, as I believe, and I have reason to believe from the propositions that they have submitted here, that they have got the <PageNumber num={302} /> machinery at work to keep the people from deciding this question—to keep it from them for six, eight, or twelve months, or longer if possible. They mean to decide the question for themselves,
                for I understand that they don't mean to adjourn but to take a recess. They were not elected to take a recess, but to come here and decide what they are to do. If a recess is taken I want to have someting for the people to put their condemnation upon, for such I believe will be their verdict upon the action which this body is likely to take. And when that is done, I take it that the
                people will spare these gentlemen here who, I am satisfied, do not represent their sentiments, the necessity of returning here, but will elect others in their stead, who will carry out their will and reflect their sentiments. I do not like this rump parliament proceeding; I don't like it for the reason that it resembles the late Congress in the very feature which led gentlemen here
                to excuse the failure of the presentation of the Peace Conference proposition to the States through Congress, namely that it did not reflect the existing sentiments and wishes of the people. This Convention is precisely in that attitude, and it is well that they should do now what they intend to do, so that the people may take action upon whatever decision they make. This they must
                submit to. I care not how long they may defer this action. The people will ultimately proclaim their will in reference to whatever action this body may take, and that proclamation will assuredly be one of condemnation upon that action. I want, when this Convention shall go out of this Hall, that it will go never to return, and the sooner they act and give the people an opportunity of
                expressing their sentiments the better.
              </p>
              <div className="speaker" id="sp1130"><span className="persName">Mr. BAYLOR</span>, of Augusta—</div>
              <p className="p-in-sp">I don't understand the position of the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] . I think he said this morning that he did not recognize that the old Commonwealth of Virginia belonged to any Union, but regarded her without the limits of the Union. I don't know what the gentleman is contending about if that is the fact. I would have thought that when the gentleman regards Virginia as no longer in the Union that he has got what he wants.</p>
              <p className="p-in-sp">I did not get up to answer the gentleman's remarks, but merely the reasons why I intend to vote against striking out the report of the Committee and inserting the proposition of the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] I think, sir, I was the first man who endorsed the "Peace Conference" proposition upon this floor.</p>
              <p className="p-in-sp">
                I do not want to be understood now, sir, when I vote against striking out the report of the Committee of twenty-one and putting in its place the "Peace Conference" propositions, as therefore condemning <PageNumber num={303} /> the "Peace Conference" proposition. I do not want this vote to be regarded as a test vote—in other words, that I condemn the
                action of the Peace Conference. I said some days ago that I was willing to accept that proposition. I would be willing now to accept it, but as a report is made by the Committee, removing some of the objections which have been urged against it, I acquiesce in that report. I want it distinctly understood, however, that I never have changed my opinions upon this "Peace Conference"
                proposition. I believe it of itself sufficient, and I would be willing to accept it yet. But, as I have said, since the Committee has made a report removing some of the objections urged to this measure, I accept it.
              </p>
              <div className="speaker" id="sp1131"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I am getting information every moment. I rise now for further enquiry. I thought the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] , had offered the Peace Conference proposition. I learn since that he has not. The gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>] , says that they are not the same—that there is something added to it. Will you please report to me what has been offered as a substitute?</p>
              <div className="speaker" id="sp1132"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">All I did say was that the Peace Conference propositions were embodied in this substitute of the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] and that other matter had been added to them.</p>
              <div className="speaker" id="sp1133"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I want to hear what is added. Will the Secretary read the propositions added by the gentleman from Harrison?</p>
              <p>The Secretary then read the following:</p>
              <p>"Therefore, be it resolved, by the representatives of the people of Virginia, in Convention assembled.</p>
              <p>
                "1. That it be, and is hereby recommended to the people of the several States comprising the United States, to hold in their respective States, Conventions to consider the said measures of adjustment, and express their approval of the same, and request their Senators and Representatives in Congress assembled, either in extra or regular session, at its first meeting, to adopt the same
                by the constitutional majority of two-thirds of each House, so that the same may be laid before the several States of this Union, in the mode pointed out by the said fifth article of the Constitution aforesaid, for ratification or rejection.
              </p>
              <p>
                "2. That this Convention, for and in the name of the good people of this Commonwealth, do declare their approval of the said propositions, and will, if adopted as an amendment to the Constitution of the United States, accept the same as an adjustment of all our national difficulties, and that we do hereby request our Senators and Representatives in Congress, at its next session,
                whether convened in <PageNumber num={304} /> extra or regular session, to use their best efforts to have the same adopted in their respective houses by the constitutional vote required, to the end that the same may be laid before the people of the several States of the Union, to be, by them, ratified or rejected in the manner provided in the
                Constitution, through the action of State Conventions, or the Legislatures of the several States.
              </p>
              <p>
                "3. That it shall be the duty of the officers conducting the election for members of the next General Assembly of this State, to see that a poll is opened at the several places of voting in this Commonwealth, to take the sense of the voters of this State upon the said measures of adjustment recommended by the Peace Conference. And the better to secure a correct poll-book, the
                Governor of this Commonwealth is requested to have prepared and transmitted to every county in this Commonwealth a sufficient number of poll-books to supply each county with as many books as there are places of voting in the same, said books to be headed "Poll-books for taking the sense of the voters upon the adoption of the measures of adjustment recommended by the Peace
                Conference," and to have two columns, the one headed "For the adjustment," the other headed "Against the adjustment." And it shall be the duty of the said conducting officers, each and all of them, to see that the names of all persons qualified to vote for members of the General Assembly, and who present themselves to vote at the respective places of voting, are allowed to vote for
                or against the said measures of adjustment, and that the names of all those who vote for adjustment are recorded in the coluinn headed "for the adjustment," and all those who vote "against adjustment" are recorded in the column head "against the adjustment," said poll books to be returned and their correctness certified to under oath by the officers conducting the election within two
                days after the said election, to the clerk of the respective county or corporation court, as the case may be, in which the said conducting officers reside; and it shall be the duty of the respective county and corporation clerks of this Commonwealth to certify to the Governor of this Commonwealth, attested by their seal of office, the result of the vote upon the question aforesaid,
                in their respective counties and corporations aforesaid, within two days after the said poll books shall be returned by the conducting officers aforesaid; and the Governor of this Commonwealth, upon receipt thereof, is hereby requested to ascertain the result of the said vote in the State, and to make the same known by proclamation; and if a majority of the votes cast shall be in
                favor of the said Peace Conference adjustment; he is hereby requested to communicate to the Governor of <PageNumber num={305} /> each of the States of this Union the foregoing resolutions, with the result of the vote aforesaid, with a request to each to take such steps as the Constitution and laws of his State may require to be taken, in order that a
                Convention of delegates may be elected by the voters in each State, and convened, to whom shall be referred the foregoing resolutions."
              </p>
              <div className="speaker" id="sp1134"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">If I have heard the proposition of the gentleman aright, it adds nothing to, and takes nothing from the proposition of the Peace Conference—not a particle of alteration in the crossing of a t, or in the dotting of an i. The gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>] , said that there was a different proposition added to it. Show me if you can [addressing himself to <span className="persName">Mr. SUMMERS</span>] , what this addition is.</p>
              <div className="speaker" id="sp1135"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">I regret that I have not been able to make myself understood to the gentleman from Princess Anne.</p>
              <p className="p-in-sp">I did not maintain, at all, that the proposition submitted by the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>]
                , propounded any other or a different scheme of guarantees than that submitted by the Peace Conference. What I meant to say was this: that the proposition of the gentleman from Harrison, to strike out the Committee's report and insert this in lieu thereof, made a different scheme of adjustment and constituted a different proposition in this—that it submitted this matter to a vote of
                the people of Virginia, and if I understand the latter clause of the proposition correctly, submits it to the vote of the people of the other States for their action.
              </p>
              <p className="p-in-sp">These are the particulars, in which I remarked it would be making a different scheme of adjustment, and would constitute in this Conference compromise a different proposition. I did not mean that the gentleman from Harrison had added anything to the "Peace Conference" proposition.</p>
              <div className="speaker" id="sp1136"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Sir, this vampire of influenza is making me deaf. [Laughter.] But, sir, I have pricked my ears, and I think now I have heard correctly everything that was necessary for me to know. The gentleman from Harrison has made no alteration. I speak to him, and ask him whether he intended to make any change in the "Peace Conference" proposition.</p>
              <p className="p-in-sp">He shakes his head, meaning that he did not. We have so much palaver and so much circumlocution about this matter as to render some scrutiny necessary in order to understand its true meaning.</p>
              <div className="speaker" id="sp1137"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">I ask the gentleman if the proposition of the gentleman from Harrison contemplates the proposition at all embraced <PageNumber num={306} /> in the partial report of the Committee of Twenty-one, that the border slave States should meet at Frankfort?</p>
              <div className="speaker" id="sp1138"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                It does not—it goes directly to consolidate the people of the other States, and wants to consolidate the voice and the judgment of the sovereign people of our State. He takes up the proposition of the Peace Conference, introducing it in totidem verbis, word for word, letter for letter, with the "whereas" of preamble—"whereas" it is the act of the Peace Conference—that it comes here
                with the sanction of a high commission; that it comes here with all the sanction of nationality that can be given to it; that it comes here brought back by the gentleman from Kanawha
                [<span className="persName">Mr. SUMMERS</span>]
                himself, who has made a two days' speech—a five hours' speech—to show that it was the best thing that could be got, and was, with the exception of two clauses, altogether acceptable; whereas all this, they proposed that you shall adopt it—nothing more, nothing less. The only thing he adds is, that you shall get the other States now in the Confederacy to adopt it and make it their
                own, and he asks you to make it your own; and thus the question comes up directly. The gentleman from Augusta
                [<span className="persName">Mr. BALDWIN</span>] told us, not only that it was not a direct test, for some reason or other that he did not give, and speaking not only for himself, but for the whole body authoritatively, he tells us that we cannot get a direct vote.</p>
              <div className="speaker" id="sp1139"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                I have not undertaken at all to speak for anybody but myself. I understand the desire is to have a test upon the merits of the proposition of the Peace Conference, as if that was the only proposition acceptable to us. I said that I was not willing to vote upon any such test, and that no such test could be got. How can you get a test question when members refuse to accept your test? I
                don't intend to speak for anybody but myself, and I trust the gentleman will so regard my remarks in this connection.
              </p>
              <div className="speaker" id="sp1140"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I understood him expressly to say that no test vote on the Peace Conference proposition could be got in this body. I beg his pardon if I misunderstood him. I apprehended at the time that the gentleman meant to speak for himself and others. I have got his assurance, however, to the contrary, and I accept it, for I know he would assure of nothing that was wrong.</p>
              <p className="p-in-sp">
                But, sir, it is curious that the very gentleman who have been advocating this proposition of the "Peace Conference," now get up and wring and twist under the screw, and finally say, I am going to vote against it; but, mark you, that is not a test question. Oh! that the spear of Ithuriel could bring us to the test—to the trial of this crisis, even if the
                <PageNumber num={307} /> trial was blood and fire. What, sir! the gentleman from Augusta
                [Mr. BAYLOR] , some weeks ago, rose and advocated nothing but the Peace Conference, and he rises to-day and tells us that this is no test question.</p>
              <div className="speaker" id="sp1141"><span className="persName">Mr. BAYLOR</span>—</div>
              <p className="p-in-sp">I did not say so. I told this Convention that I was willing to accept it; but I did not say, if the Committee reported anything else that I regarded better, I would not accept it.</p>
              <div className="speaker" id="sp1142"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">But some weeks ago there was nothing seen better in all the vista of thought. Well, sir, who is going to vote against it? He tells us none. I accept that—that he finds something better. I think that is good.</p>
              <p className="p-in-sp">But the other gentleman from Augusta differed from the report of the Committee; differed in limine; differed toto ccelo; and making a different report brought it directly into competition with the other, and he now tells us that he would not vote for his own report, even against the report of the Committee.</p>
              <div className="speaker" id="sp1143"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                My report was made long before the amendments of the Committee were entered upon. At the time that the partial report was made from the Committee of 21, withholding the constitutional amendments which they intended to propose, I submitted the report in which I propounded for the adoption of the Committee the Peace Conference propositions without amendment. At a subsequent day the
                Committee of 21 took up for consideration the Peace Conference Propositions, and proceeded to amend them, and, as I think, they have improved them, in some respects, so much so, as to have silenced a great deal of the clamor about doubtful construction that we heard before in regard to the Peace Conference.
              </p>
              <div className="speaker" id="sp1144"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                If there was anything on which I thought I could compliment my friend from Augusta, it was for my differing from him in character. I have had the character of being an impulsive man. I regarded him as one of the most deliberate, cautious gentlemen of my acquaintance in this body. But now the excuse is that he went off halfcocked. Oh, these deliberate gentlemen will find these
                questions to confuse all of them. They bother the slow gentlemen very much. It is a pity that this deliberate friend of mine should have gone off half-cocked before the Committee had digested better matter; otherwise now, when they come in direct competition, he would not have had to vote against his own report. Above all, you could not have a stronger, better, more Atlas-like
                shoulder to put your cause upon, than of that Kanawha Commissioner of ours, who was sent to Washington to see that the work was done there, not in a single Conference, of a single State, but in a congregation of States; that this Conference was done, well done, and so <PageNumber num={308} /> well done that nothing could be done better. Although it
                seems he voted against the several propositions, yet here, with the exception of two little lines, he advocated for five mortal hours—his speech filling 24 columns of the "Enquirer"—that report of the Conference Committee. I had intended to take the floor to answer that speech, but there is no necessity, I am told, for it now. He is going to vote against the work which he advocated.
                Oh Lord, is it not time that we should give up conference after that?
              </p>
              <div className="speaker" id="sp1145"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">Will the gentleman yield me the floor for a moment?</p>
              <div className="speaker" id="sp1146"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Certainly.</p>
              <div className="speaker" id="sp1147"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">I know that the gentleman from Princess Anne is an able lawyer and debater.</p>
              <div className="speaker" id="sp1148"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I am not a lawyer—do not call me so—nor a politician.</p>
              <div className="speaker" id="sp1149"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">I have often heard the gentleman spoken of in that capacity. The gentleman represents me as standing in the position of repudiating—</p>
              <div className="speaker" id="sp1150"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Oh, no.</p>
              <div className="speaker" id="sp1151"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">Of repudiating the peace adjustment, and what I have said about it. Do I misunderstand him?</p>
              <div className="speaker" id="sp1152"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Yes, sir. That is a very strong word—that "don't pay" word.</p>
              <div className="speaker" id="sp1153"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">
                I will not stand with the gentleman upon words. What I meant to call his attention to, was this : I felt myself called upon at one stage of the discussion, as having been a member of that Peace Conference, to explain my views. In the remarks which I addressed to the Convention, I gave, as I thought, candidly, my impressions as to the nature and true construction of each of those
                sections. I endeavored to maintain that they covered all the grounds of difficulty, and if incorporated into the Constitution, would, upon every fair construction everywhere, close all our difficulties. I say so now. I have moved from no position I then took, and I believe now that these measures, proposed as amendments by that Conference, would be an entire safeguard for the South.
              </p>
              <p>The hour of 2 o'clock having arrived, the committee, in pursuance of the order of the Convention, took a recess until 4 o'clock P.M.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.7.5">
              <h3><span className="headingNumber">1.7.5. </span><span className="head">EVENING SESSION</span></h3>
              <p>The Committee of the Whole re-assembled at four o'clock, <span className="persName">Mr. PRICE</span> occupying the Chair at the request of <span className="persName">Mr. SOUTHALL</span>, who was indisposed.</p>
              <PageNumber num={309} />
              <div className="speaker" id="sp1154"><span className="persName">Mr. GOODE</span> of Mecklenburg—</div>
              <p className="p-in-sp">I move a call of the Committee. There is evidently not a quorum present.</p>
              <div className="speaker" id="sp1155"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It is not competent to make a call in Committee of the Whole. That is the motion made by the gentleman from Mecklenburg [Mr. GOODE] , and it is pronounced by the Chair to be a motion out of order.</p>
              <div className="speaker" id="sp1156"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">
                If I understand the opinion of the Chair, it is in direct opposition to what I consider to be the duty of the Chair. I hold that it is the duty of the Chair, when the attention of the Chair is called to the fact there is no quorum present to count the House, and after having counted the House and finding that there is no quorum present, then to report the fact to the House.
              </p>
              <p>The CHAIR decides that it is not in order in Committee of the Whole to move a call of the House.</p>
              <div className="speaker" id="sp1157"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">That is not the question before the Chair. The question before the Chair is, will the Chair count the House and report the fact whether there is a quorum present or not?</p>
              <div className="speaker" id="sp1158"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">With a view to relieve the gentleman, the Chair will proceed to count and see whether there is a quorum present or not.</p>
              <p>A count having been made by him, The CHAIRMAN then announced that a quorum was present, eighty members being in their seats.</p>
              <div className="speaker" id="sp1159"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I find, Mr. Chairman, upon examination, that there are two words left out of the amendment which I ask the unanimous consent of the Committee may be inserted. The last line of the report of the Peace Conference, as printed, reads:</p>
              <p className="p-in-sp">"And Congress shall provide by law for securing to the citizens of each State the privileges and immunities of the several States."</p>
              <p className="p-in-sp">I desire to insert before "several States" the words "citizens of," so that it will read:</p>
              <p className="p-in-sp">"And Congress shall provide by law for securing to the citizens of each State the privileges and immunities of the citizens of the several States."</p>
              <div className="speaker" id="sp1160"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I had a few words more to say before the Convention took a recess. I am sorry I do not see the gentleman from Staunton [<span className="persName">Mr. BALDwIN</span>]
                in his seat. I was alluding to the curious and singular exhibition which was made by the gentlemen who have come here, with great force and effect, advocating peace propositions, now not recanting, yet recanting, and the only excuse which I hear from the gentleman from Kanawha is, first that it is a different proposition, inasmuch as it is a proposition to submit the Peace Conference
                proposition to <PageNumber num={310} /> the people; second, because it comes into competition with something that has improved and made it better. Why was not that Sanhedrim surpassing this domestic body of ours? Did they not do their best upon the proposition, and is it possible that men like you and I, Mr. Chairman, humble individuals here in this
                Mechanics' Institute, could show them at every line, and every phrase, in every section, in every part and parcel, in particular and in the whole, where it was entirely wrong. Where does it come from? Not from the gentleman from Harrison. It is not from the gentleman from Harrison
                [<span className="persName">Mr. CARLILE</span>] . He does not put it as his proposition; he presents it with the whereas, that it is the veritable work of the Peace Conference.</p>
              <p className="p-in-sp">It is the proposition coming with the sanction of the highest authority, the Convention for peace, inaugurated by Virginia herself. He presents it to you as the proposition which you [addressing himself to <span className="persName">Mr. SUMMERS</span>] brought here, which you were commissioned to go for, which you advocated, and yet it could not hold water before the Committee of twenty-one. Now it seems it will not hold water with the Commissioner himself. He gives it up; but the motion for it was made by the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>]
                . Never since I was born did I see a gentleman start upon a two days speech, make one day's speech, and give the floor to another gentleman to change the whole proposition before the House, and then make another day's speech without touching the new proposition at all. I would enquire of the gentleman from Harrison and the gentleman from Augusta, if there was any understanding about
                this matter?
              </p>
              <div className="speaker" id="sp1161"><span className="persName">Mr. BAYLOR</span>, of Augusta—</div>
              <p className="p-in-sp">I am disposed to fight my own battles, and whilst I don't wish to see my colleague used badly, I hope the gentleman will indicate to what gentleman from Augusta he refers.</p>
              <div className="speaker" id="sp1162"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I mean the gentleman who was on the Committee of twenty-one.</p>
              <div className="speaker" id="sp1163"><span className="persName">Mr. CARLILE</span>, of Harrison—</div>
              <p className="p-in-sp">Mr. Chairman—there was certainly no understanding between the gentleman from Augusta and myself. So far as I know, sir, he had no idea of the proposition I intended to submit, and the floor was yielded by the gentleman from the city of Richmond [<span className="persName">Mr. RANDOLPH</span>] , who moved, out of courtesy to the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] , that the Committee rise.</p>
              <div className="speaker" id="sp1164"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">But without the consent of the gentleman from Augusta, the floor could not be yielded, and a new proposition be debated, pending the proposition before the house. At one moment the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>]
                , was speaking upon the report of the Committee of Twenty-one, and the next day he was speaking upon <PageNumber num={311} /> the proposition of the Peace Conference which was entirely different, and in many respects contradictory to the report of the committee. It was curious to see it. I could not but observe it. And so anxious was I that the
                gentleman from Augusta
                [<span className="persName">Mr. BALDWIN</span>] , should not be put in a false position that I went to him at the time and warned him. "Do you see," I said to him, "what the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] , is doing? He is going to change the proposition before the Committee." But he did not heed the warning. No objection was made; and the gentleman from Harrison is made the step-father of this proposition. The gentleman from Staunton [<span className="persName">Mr. BALDWIN</span>] , who had recommended the report of the Peace Conference, stopped advocating it, to let the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] father it. What I want to say to the gentleman from Harrison is, that I feel it is a hard question to decide whether he will make the best father, or the gentleman from Augusta. [Laughter.] Both of these gentlemen profess to be most unconditional pro-slavery men. Does not the gentleman from Harrison?</p>
              <p><span className="persName">Mr. CARLILE</span> nodded assent.</p>
              <div className="speaker" id="sp1165"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">The gentleman claims, I believe, to have been prognostic in the opinion that slavery is a blessing, ahead of everybody else. You have anticipated all the large slaveholders themselves [addressing <span className="persName">Mr. CARLILE</span>] , in discovering in advance that slavery was the best and divinest institution among us. [Laughter.] The gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>]
                professes so too. I have no doubt that these professions are even fanatically sincere. And I will pay the gentleman from Harrison the compliment to say that there is no mistake whatever about his position. Whatever his opinions and abstract faith may be, there is no mistake as to what will be the effect of this final action. Whatever of pro-slavery there may be in his opinions, I
                think there is no pro-slavery in his action.
                [Laughter.]
                He is very bold; he is very decided. My constituents, sir, would never mistake him in the world. I, as a slaveholder of twenty-seven years' experience on this question of slavery, in Congress and out of it, abroad and at home, I have no idea that I can mistake as to where that gentleman will go. The mover suits the Peace Conference proposition, and the proposition suits the mover.
                The motion is to do nothing. The advocacy of the motion tells us that the South is all wrong, that the North has not been the aggressor. And the record is appealed to by both gentlemen; but some way or other, one of the gentlemen has slipped out of the fathership of the proposition, and the other gentleman has slipped in. I don't know how that is, sir. One reported it from the
                Committee as his, and another takes it <PageNumber num={312} /> up as setting forth his opinion. Now, sir, a great deal of friendly fellowship in auld lang syne with the gentleman from Harrison
                [<span className="persName">Mr. CARLILE</span>]
                would lead me to speak kindly of him. I could not justify myself if I offered any unkindness to him, because I have every reason to believe that he is personally kind to me; but let me tell him in all candor, and with all respect, that if it shall come to civil war, he and I will certainly differ, and he and I will be on opposite sides, and will have to fight each other; and I would
                expect to find him fighting, if not for the Black Republicans, at all events, to stay in a Northern Confederacy, even if the Peace Conference proposition should be rejected.
                [Laughter.]
                This Peace Conference proposition is zero. It is as low as he could propose. It is nothing. And the only reason, perhaps, that the gentleman from Augusta prefers the report of the Committee, after all, is, that he has discovered that the report of the committee, although it is a grand improvement upon the Peace Conference, may be made even more effectual to do nothing, and to leave
                us where the trap of the dead-fall of this crisis has caught us, in a Northern Confederacy.
              </p>
              <p className="p-in-sp">But I am enlarging too much. I don't hesitate to say that the bold, decided, and unequivocal action of the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>]
                entitles him to father this measure of the Peace Conference. And some of these gentlemen may well say like John Randolph once said about the administration of Thomas Jefferson. He said: "Mr. Speaker, I found William B. Giles riding in the Royal George; and I saw John Quincy Adams and Barnabas Bidwell step into the same coach; and when they got in, sir, I thought it was time for me to
                get out;" and I say the gentleman from Kanawha
                [<span className="persName">Mr. SUMMERS</span>] , and the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] , left this Peace Conference proposition just about the time that the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] came into it. [Laughter.]
                Again: gentlemen, now warm advocates of the report of the committee, told us at the opening of the session of this Convention that the people were our masters, and that the people were to be bowed down to, and that the people were to pass judgment upon everything that we should do in this Convention. The gentleman from Harrison has done justice to himself, has done justice to the
                proposition, has done nothing less than homage to the people by proposing in his amendment that the naked question of the Peace Conference proposition shall be submitted to the people. Is that the grievance? Does that alter it so as to make other gentlemen vote against it? If it does, let me hear no more about extreme deference to the people.
              </p>
              <PageNumber num={313} />
              <p className="p-in-sp">
                Sir, I never was afraid of the people. If I have any strength before the people and with the people, I don't owe it to any ability, perhaps to no great merit moral or intellectual of any sort—no great merit but one, namely, that I never in my life was afraid to differ with my constituents, and I never was afraid to tell them so. I don't want their advice at this time. I am ready to
                act. And if anybody else wants their advice, or if there is a doubt as to what is their counsel, I am for referring everything you do to the people.
              </p>
              <p className="p-in-sp">Now what is the effect and intent of the propositions of the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>]
                ? They do precisely what the act which organized you a Convention—not a sovereign but an advisatory Convention—says you shall do: refer any result you arrive at to the people for their ratification or rejection. What is the sort of action that must be referred? Secession? Union? or disunion? Nothing of the sort in that act, sir—nothing of the sort. It says you shall refer to the
                people any propositions which you make to change the organic relations between this State and the Federal Government.
              </p>
              <p className="p-in-sp">The gentleman from Harrison [<span className="persName">Mr. CARLILE</span>]
                , is right. He is obeying a law of his representative being—the law that organized him a member of this Convention and organized the Convention for him to be a member. Why? Because there is not a section in the majority report or in the report of the Peace Conference but proposes to change the organic law of the relations existing between this State and the General Government.
              </p>
              <p className="p-in-sp">Both propose nothing less than a change, a material change—I am afraid a destructive change, of the old Constitution.</p>
              <p className="p-in-sp">
                Again, you may tell me by and by, when my propositions come before the House, that they are too strong. Mine are too strong? Well these I tell you, Mr. Chairman, are too weak. They are not only too weak to satisfy me, to satisfy my constituents, or to satisfy the complaints of the Southern people, which are innumerable, intangible to legislation, undefinable—above all they are too
                weak to preserve peace.
              </p>
              <p className="p-in-sp">
                Come down to zero, gentlemen. Come down to the last peg of demand. Be so humble that you yourselves would be surprised if your propositions should be rejected. Come down lower, lower still, and after you have got to the profoundest depth of low, lower, lowest—suppose then that proposition is rejected—suppose that proposition is rejected—where is peace? Sir, like freedom, peace will
                shriek and die, as when Kosciusko fell. What have you then? War. You must immediately secede, and immediately fight.
              </p>
              <p className="p-in-sp">
                Answer aggression with terms of submission cloaked under proposals <PageNumber num={314} /> for compromise—and you may be sure that the aggressor will spurn even your terms of submission—and will push you to the wall. Make your demands high enough. Plant your foot firmly, not only on your rights, but when once assailed step over the half-way mark. You
                will find many friends to check you and go to pull you back, and say you are extravagant, and then you may step back a little and bring your adversary to some point of honorable agreement.
              </p>
              <p className="p-in-sp">
                Where, gentlemen, are you going? I see pretty clearly. You are going into a Border State Conference, and from that into a National Convention. And where is the matter then to terminate? We are to be bound up in a Northern Confederacy. We are to be dwarfed by the sloughing off of eight Southern States, and will be compelled to treat on terms dictated by the Northern States themselves.
                Time! time! time! step by step, by dilatory action, by postponement, by propositions which will end in nothing. Time! time! is the great arbiter that is to decide where Virginia is to go. What nonsense to ask the question, where we are to go. Why, where are you? Is Virginia a boat, that you can put on the water, and row up the coast and down the coast. Is Virginia a waggon that you
                can put upon wheels, and cart North and cart South? No, sir, she is, blessed be God, terra firma—as immovable as earth, rock, mountain, river and lake can be made. She is here in the latitude, of 37 deg., South of Mason &amp; Dixon's line, with 500,000 slaves. Yet slaveholding State as she is, and Southern State as she is, she has been caught by the dead fall of this crisis in the
                trap of a Northern Confederacy, and you will keep her there if you can. And now all I have got to say is that you have got to do one of two things; you have got to accomplish one of two purposes before you can satisfy me. Restore the blessed old Union if you can. Sir, you cannot out-union me. There is not a man among you who is readier to die to restore the old Union than I am.
                Restore it, restore it if you can. You cannot restore it without restoring those wronged and offended sisters that have gone out. You cannot restore it without restoring hearts, without reclaiming the hearts and dispositions of the offending sisters of the North who have driven those of the South out of the family.
              </p>
              <p className="p-in-sp">
                I will agree to no compromise, so help me God, so long as I live, that comes short of that end. I will fight to the end, aye, to the bitter end, against any and all propositions that will not restore the old Union as it was, that will not be acceptable to the South as well as the North. Is there a hope of that? I have none. None upon the face of the earth. But you have, and if you
                have, hope on; and hope ever. So long as there is hope, I will give you fair time and opportunity. Not an <PageNumber num={315} /> unlimited time, not a resort to all resources, because they are infinite—infinite. To say that you will exhaust all means is to say you will never leave the Northern Confederacy.
              </p>
              <p className="p-in-sp">
                And when gentlemen talk about the Union! the Union! the Union! I beg them to remember that there are now three Unions, two existing, and one destroyed. You are now in a Union of a Northern Confederacy; that is not the old Union; that has been sloughed off, and we are left a hopeless minority in a Northern Confederacy. While in the old Union, with a united South, we could to some
                extent, restrain the majority, and beat back our aggressors; but you are now in a Union where you are dwarfed, not only upon the slave question, but upon other questions, in my humble judgment, of ten times more potency with some gentlemen here than slavery; and we are in a Confederacy where they delight to be, in my opinion. As for me, if you will not, or if you cannot restore the
                old Union, I will not—I never will consent that my State shall remain a permanent part and parcel of this new Northern Confederacy. But I do not propose to pursue the subject further at this time. I do not intend now to make a speech. I only intended to do the gentleman from Harrison the justice to say that he belongs to this Peace Conference proposition, and that this proposition
                belongs to him, and any gentleman who wants to take action against slavery and the South, may follow him. I shall not.
              </p>
              <div className="speaker" id="sp1166"><span className="persName">Mr. LEAKE</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, the Legislature of Virginia, acting for the people of Virginia, determined to make another effort to settle this great controversy with the North. It was understood every where to be a final effort. The interest and the honor of Virginia, it was thought, required that another effort should be made; and that interest and that honor demanded equally that that effort
                should be a final one. Sir, it was so understood in Virginia. The representatives from this State met those from other States in a Peace Conference. Fourteen non-slaveholding States, and seven slaveholding States; and, sir, the propositions, submitted to us by the representatives from this State, constitute the result of the deliberations of that body. And as it was to be expected,
                they are just such propositions as were likely to emanate from a body in which there was so overwhelming a majority of representatives opposed to the rights and interests of Virginia, as fourteen non-slaveholding States to seven slaveholding States—such a result as could be expected from a body in which there was an overwhelming anti-slavery sentiment, and that anti-slavery sentiment
                not only opposed to your property, but opposed to the sentiments upon which that property rests for its justification—persons who are not willing to acknowledge <PageNumber num={316} /> your rights of property in the common territory, but who denounce the sentiments of your heart by which your title to your property is justified. You have, sir, three
                notable propositions as the result of this Conference on the part of the people of Virginia. They had recommended to them propositions which came from a body, the majority of which was opposed to the rights of the South, and opposed to the rights of Virginia. And, sir, these propositions were brought back to us as the best basis that could be obtained, and not only as the best that
                could be obtained, but as the very propositions which the rights and interest of Virginia demanded should have been brought back.
              </p>
              <p className="p-in-sp">
                Now gentlemen are telling us that we cannot make any test for them upon these Peace Conference propositions; that no vote given upon the pending propositions can be considered as in any way indicating what are their opinions upon the Peace Conference propositions. Well, sir, we are making no test for them, they have made the test for themselves. The propositions of the Peace
                Conference are before this Committee, and I take it that no gentleman will be so forgetful of the duty which he owes to himself as to vote against these propositions if he thinks them the best that can be obtained from the Northern States, and the best for the interests of Virginia; the best the honor and interest of Virginia entitle her to demand.
              </p>
              <p className="p-in-sp">
                Gentlemen, during the progress of these discussions, have denounced the Representatives of Virginia in the Senate of the United States, because they did not concur in the proposal to submit those propositions to the people for their ratification; and there now lies upon the table of this Convention a resolution denouncing the Senators from Virginia in Congress, because they did not
                vote to submit those propositions to the people for their approbation. I understand that that resolution only slumbers there in order that gentlemen may have a full opportunity to come up and vote for it. Suppose your Senators had voted for that proposition—is it not very evident from the sentiments you have proclaimed here to-day that they would have misrepresented Virginia upon a
                matter involving her interests and her rights? Sir, would you have had your Senators, representing the sovereignty of Virginia cast their votes for a proposition that gentlemen here are unwilling to give their votes in favor of. Suppose your Senators had voted for these propositions, why, gentlemen you would have found, as you have found in this Committee to-day, that a majority are
                not willing to sanction these Peace Conference propositions. Yet gentlemen came here recommending them. Before the discussion took place they were <PageNumber num={317} /> satisfied that they were the best propositions that could be offered. And no wonder; they had just come from a conference with Black Republican politicians. No wonder; when they had
                just come from Washington from attending a Peace Conference, where there was so large a majority opposed to slavery—opposed to the interests of Virginia and opposed to the Southern demands upon this great question. But since this discussion has taken place, since criticism has been brought to bear upon them, since they began to be understood, gentlemen say they must be modified.
                Those gentlemen who have stood up here to say to Virginia that she ought to take those propositions, now come to this convention and say that she ought not to take them, while the resolution is still lying upon your table to censure your Senators in Congress for not voting that Virginia should take them; in other words, that they have not been faithful to your rights and your honor,
                because they have not voted away your honor, because they have not been willing to be guided by the chart laid down by CHASE and other Black Republicans of that school of politices. Gentlemen have, at an early stage of this discussion told us that these propositions demanded all that the rights and interests of Virginia required, but now they tell you that there are other
                propositions which are better, and that those propositions will command their votes when placed in competition with the Peace Conference propositions. Then by that vote you say that your representatives in Congress were right in refusing to vote for these propositions. Sir, I trust the gentleman who introduced that resolution of censure, will either have it taken up and voted upon or
                have the magnanimity to withdraw it from the files upon the clerk's table, and instead, pass a resolution complimenting them for being faithful to the interests and honor of Virginia; because they were so far-sighted and so watchful; because they were more careful of your rights and your honor than you at first supposed them to be.
              </p>
              <p className="p-in-sp">
                But, Mr. Chairman, I should suppose the friends of these Peace Conference propositions would not hesitate for a moment in voting for them if they did not believe that there were other propositions which were better in themselves. But I hear gentlemen say that the changes made in the Peace Conference propositions, by the report of the Committee of Twenty-one, are only changes in
                phraseology. Gentlemen these propositions are better. The changes are material and naturally for the better. Now what is the condition in which these propositions stand? I beg gentlemen to bear it in mind; because when taken in connexion with a part of this report of the Committee of Twenty-one, <PageNumber num={318} /> it is a very important inquiry.
                Because, if it turns out that this report of the Committee is only changed as to some phraseology, and much the same thing as the "Peace Conference" proposition, we ought to know it; Virginia ought to know it. Why, sir, that Report of the Committee of twenty-one proposes that the amendments which have been proposed by the Committee in lieu of the propositions of the "Peace
                Conference," shall be submitted to the other States for their approval; and what then does it say? If the responses from the nonslaveholding States are not satisfactory, then some other step is in contemplation upon the part of Virginia. Are gentlemen who have taken the position which they have taken to-day in reference to the propositions emanating from your Committee, in a
                condition to say, that if the non-slaveholding States shall report back the "Peace Conference" propositions, that these propositions are satisfactory to Virginia? I don't mean to use any expressions that can be construed into any disrespect; but if there be any dodging about these "Peace Conference" propositions, ought we not to know exactly what it is that gentlemen contemplate, and
                whether a majority of this Convention shall say that the propositions emanating from your Committee are substantially different from these propositions of the "Peace Conference"? But if they are hereafter to be embodied in the "Peace Conference" propositions, and should the non-slaveholding States in response to the demands of Virginia, send back here the Peace Conference
                propositions, is it to be said that these propositions are satisfactory responses to Virginia? Sir, I trust that the action of this Convention is to be a finality—that it is to lead to some final result. It was supposed that the Peace Conference proposition was to be a finality. But is it now so regarded? and is it not time that it should? Are we to be left in the position hereafter,
                if the non-slaveholding States shall refuse to endorse the Peace Conference propositions and send them back to us, to receive them as satisfactory responses to Virginia's demands? I trust not, sir. I trust that this Committee will not be willing to take these propositions as any response to such demands as Virginia has a right to make.
              </p>
              <p className="p-in-sp">
                Sir, the very fact that propositions have been submitted to us by gentlemen chosen to represent the honor and the rights of Virginia in this Convention, called at Washington to settle those great questions, which are so vague and indefinite, and which do not come up to that point of directness as to our rights, as to meet with the approbation even of a majority upon this floor; I
                say, the very fact that these propositions are now repudiated by the very gentlemen who advocated <PageNumber num={319} /> them in the beginning leaves no probability that any propositions, any measures of adjustment will ever be given by the North that can be acceptable to Virginia. These "Peace Conference" propositions were the result of long and
                laborious deliberations on the part of delegates from twenty one States, in which our Southern delegates used their best efforts to procure just measures of protection and future guarantee; and yet we find here to-day that they are unacceptable to the very men who advocated them in the beginning. What hope is there then of obtaining any satisfactory adjustment of our difficulties
                when this "Peace Conference" failed? None whatever. Sir, none of the Northern States, so far as I understand their proceedings, have condescended to initiate any proceedings towards an adjustment of our difficulties. Here, Virginia, time and again has been making efforts for an honorable adjustment of these difficulties—she has put forth what was considered a final effort to
                accomplish that purpose, but the propositions which resulted from that effort have been repudiated by this Northern majority in the Congress of the United States, while you find efforts being made everywhere in the North for the purpose of adjusting these difficulties. And still we find Virginia occupying the position of a suppliant to the North to grant her some guarantee of her
                rights. As long as she keeps that position, the propositions which shall be made to her, if any are offered, will be of a character which she cannot accept.
              </p>
              <p className="p-in-sp">
                Sir, gentlemen have said, in the progress of this discussion, that the action of the Federal Government in the past, upon this question of slavery, has been such as Virginia ultimately acquiesced in, if she did not approve of in the outset. Sir, she acquiesced in these settlements because they were considered finalities on that question—because they laid down lines which it was
                supposed Northern aggression would not dare violate or infringe. Inasmuch as Virginia has acquiesced in the past from motives of peace, gentlemen argue that she should now acquiesce. Indeed, we have the plea of precedent put forth to justify this acquiesence on the part of Virginia—a plea which has been set up before to justify encroachments upon popular rights. I was astonished to
                hear the gentleman from Augusta
                [<span className="persName">Mr. BALDWIN</span>]
                refer to the vote which was given upon the Oregon question, and the approval of Mr. Polk of the passage of that bill. The gentleman referred to the discussion in connection with that matter, in an air of triumph. It seems to me that there were but two aspects in which the question could be viewed—either as a party triumph, because Polk had signed the bill which contained the Wilmot
                proviso, while the Democratic party <PageNumber num={320} /> denounced it and disclaimed any agreement in that course. And then, sir, what was the other aspect? The majority of the Northern men in that Congress, when the Black Republicans of the North affixed the Wilmot proviso to the Oregon bill, remonstrated against it, because Oregon was north of
                thirty-six degrees thirty minutes, and the Missouri Compromise principle applied to it, and hence there was no necessity that the Wilmot Proviso should be annexed. But the Black Republican majority annexed the proviso notwithstanding to show that they had the power over this question. And now the gentleman from Augusta
                [<span className="persName">Mr. BALDWIN</span>] , refers to the greatest outrage that the South has ever had to submit to, to show that she should submit further.</p>
              <p className="p-in-sp">
                It is probable that at the meeting of the next Congress for the purpose of settling these difficulties between the North and the South, the very language of this Peace Conference proposition will be used by the Northern men—aye, sir, and by some Southern men—to show that Virginia having acquiesced ought still to acquiesce in any other outrage of her rights that may be perpetrated
                upon her.
              </p>
              <p className="p-in-sp">
                But, sir, gentlemen may say that they don't mean in any vote they give here, to cast any reflection upon the proceedings of the Peace Conference. Sir, the fact still stares them in the face that they are unwilling to vote for the proposition of the Peace Conference, and that these propositions have been exposed to the scathing criticism to which they have been subjected in this body,
                and it is consoling to remember, sir, to use the language of the gentleman from Augusta, that by the time we have taken a vote upon this question, all that is left of the Peace Conference proposition can be carried North in a "dug-out" even if the gentleman from Augusta
                [<span className="persName">Mr. BALDWIN</span>]
                , acts as the pilot in carrying it through the capes of Virginia. It was understood, sir, that the propositions which emanated from this "Peace Conference" were to come back here to this Convention, and that if they were such as Virginia ought to adopt, that this Convention would adopt them and say to the North here is our ultimatum. What do we find now? That this committee of
                twenty-one is engaged in making another ultimatum entirely different and distinct from that which has emanated from this "Peace Conference." The proposition of this committee is to emanate from this body and is to go before a conference of the different slave States, and at the very time that these amendments shall go before the States, you propose to have a border slave State
                conference. What is the meaning of that, sir? Is it not satisfied that this "Peace Conference" proposition cannot meet either <PageNumber num={321} /> with the approbation of Virginia or that such responses will not come from the North as would be satisfactory to us? Why are they proposing that there shall be a third conference for the purpose of
                considering what Virginia and the other border slave States ought to do in this emergency, when it is apparent to every gentleman here, that there is nothing that can be proposed to the North that they will sanction. Sir, Virginia and the South have occupied for the last twelve months at least, an improper position in this Confederacy. We are the assailed party, and still we are the
                party suing for peace. The South is the outraged party, and the South is the party that is proposing terms of peace which are not consistent with her rights or her honor; and, sir, so long as Virginia and the South occupies that position in a Confederacy of this sort, so long will the North turn a deaf ear to your demands.
              </p>
              <p className="p-in-sp">
                Sir, the attitude of Virginia has been and is now one of humiliation. She ought to be placed in a position in which the North should come and ask to be allowed to remain in the Union. Virginia ought not longer be making propositions to a party that turns a deaf ear even to her entreaties. The other party, if it desires a continuation of this Union, ought to make offers of
                re-conciliation and re-construction. So long as the South occupies the position of attempting to bring about re-construction, the North, having the majority, will never re-construct upon terms satisfactory to the South. Let Virginia go with the South; let her take the direction where her interests lie, and join that section with which she is identified in feeling and interest, and
                cease any longer being a suppliant for terms from the North.
              </p>
              <p className="p-in-sp">While gentlemen representing the rights and honor of Virginia, have been denounced for not supporting these Peace propositions, let it go forth that its advocates upon this floor are afraid to vote for them themselves.</p>
              <div className="speaker" id="sp1167"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                Years ago, in the hall of the nation's representatives, I had the temerity to say that those who passed and those who advocated the Kansas Nebraska bill would live to regret it. And, sir, I may be allowed to say before this Committee, that those representatives in this hall who record their votes against the substitute which I have offered will live to regret it, if they represent
                Union constituencies. For the remark I made years ago, I endured years of abuse, denunciation and vilification. I relied upon time for my vindication, and so far as my neighbors and friends are concerned, the people of my county have vindicated me. For making the declaration which I have made here with regard to the conduct of representatives who shall vote against
                <PageNumber num={322} /> the Peace Conference proposition being submitted to the test of the ballot-box, I may undergo again years of traduction and abuse. I trust in God I have the nerve to stand it, and I shall trust to time for my vindication. I do in all kindness appeal to my friend from Princess Anne. I know he will allow me to call him my friend,
                for I have been and am still his friend, though I differ from him; an honest difference of opinion I trust—that hereafter he will not by such remarks as he made at the conclusion of his speech cast, I know they were not meant to be intentional, imputations upon the motives of members.
              </p>
              <div className="speaker" id="sp1168"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I did not.</p>
              <div className="speaker" id="sp1169"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">The language he employed was this, "Any gentleman who wants to take action against slavery will go with him."</p>
              <div className="speaker" id="sp1170"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">That refers to action not motive.</p>
              <div className="speaker" id="sp1171"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                The motive for the action is referred to, "Who wants to take action?" Must there not be a motive to want to take the action? I shall make no further profession of loyalty to my State or to her institutions. I would scorn myself if I could do so. Those who know my past, and with whom I have spent my life from early manhood to my present age, will vindicate me from any aspersions that
                gentlemen may be disposed to cast upon me. I was unfortunately prevented by sickness from hearing the speech of the gentleman from the city of Richmond
                [<span className="persName">Mr. RANDOLPH</span>] , which, I understood, was an argument against the Peace Conference proposition; and from a like cause I was prevented from hearing the speech of the distinguished gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>] , which, I understood, was an argument in favor of the Peace Conference proposition. Therefore I can say, so far as I have been able to hear the discussions in this hall, I have yet to learn the objections that gentlemen have to this proposition. I did not hear the gentleman from Goochland [<span className="persName">Mr. LEAKE</span>] , who has just taken his seat. Will gentlemen favor us with a specification of their objections to the Peace Conference propositions, if they please, or will they content themselves with general denunciations, such as they have indulged in against the Government which we are called upon to destroy.</p>
              <div className="speaker" id="sp1172"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I refer the gentleman to the report of the committee of twenty-one, containing twenty-one specifications.</p>
              <div className="speaker" id="sp1173"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">While I did not read that report as containing objections to the Peace Conference proposition, I certainly did see many things in that report that I, with all due deference to the committee of twenty-one, would have left out.</p>
              <p className="p-in-sp">
                The reason why we should provide against apprehended danger, <PageNumber num={323} /> we are told by those gentlemen who have excited our fears, is because of the elevation to power of a Black Republican party. I will not admit that they are in power or ever will be. But for the sake of argument I state it as gentlemen upon the other side state it. We
                were told that it was worse than folly for a prudent people to expect that that party, based upon one idea, and that idea hostility to the institution of slavery would stop where their present platform stops.
              </p>
              <div className="speaker" id="sp1174"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">May I ask the gentleman a question? Did I understand him to say, that he did not consider the Black Republicans were in power or ever would be? Is that so?</p>
              <div className="speaker" id="sp1175"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                I will explain. No, sir; they are not in power, thank God, and I think it is very evident that they never will be. We were told that they would not stop with the plank in the platform declaring their opposition to the extension of slavery into the territories of the United States, but that they would advance still further and prohibit the slave trade between the States, abolish
                slavery in the District of Columbia, and finally interfere with slavery in the States. I ask gentlemen, if all these apprehended dangers were not provided against by the Committee at the late Peace Conference at Washington—each and every one? Now tell me, if you please, what more do you ask? Do you want remedies for the recovery of your fugitive slaves? What better remedies could you
                have than the remedies which the experience of centuries has perfected? What territory have we now, to which any act going to amend the Constitution of the United States, in any particular could apply? Did the Black Republican party, which had the majority in Congress at the last session, after the withdrawal of the Representatives and Senators of the seceding States, apply the
                Wilmot proviso to the territories, or exclude you from those territories? They did no such thing. They have by their action negatived the charge which you made against them, that they would exercise the power, if it were ever in their possession, for the purpose of excluding you, by Congressional enactment, from the common territories of the land. They have organized the territories
                and have left to the people within them to settle and determine this question of slavery for themselves, when they should come to form their State Constitution—a power I have yet to hear a Southern man deny to the people.
              </p>
              <p className="p-in-sp">
                What do you want as Union men? I mean as men who are not prepared to drag this State, or rather to allow it to be dragged, at the heel of the cotton States by their precipitate action, and who are determined, so far as their representative conduct can go, not to consent that the people against their will shall be telegraphed out of
                <PageNumber num={324} /> a Union which is dear to them. I ask these gentlemen what do they expect from a border slave State conference to be assembled within 60 days, to lay down, it may be an ultimatum, and to require that that ultimatum shall be accepted within a period of time within which it would be impossible to submit it to the people of the
                several States of the Union? Sir, have we not had a border slave State conference within the last thirty days? Was not each and all the border slave States represented in this Conference at Washington? You are to have, by the report of this committee, a border slave State conference which is to meet the last Monday in May, and who will appoint commissioners to that conference. I
                confess I am afraid of the men in power.
              </p>
              <div className="speaker" id="sp1176"><span className="persName">A VOICE</span>—</div>
              <p className="p-in-sp">The people.</p>
              <div className="speaker" id="sp1177"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                No sir, they would be appointed either by the present Governors or the present Legislatures of the several States. I confess I am afraid of the men in power. I would ask where are the sympathies of the Governor of North Carolina, and where are the sympathies of the present Legislature of North Carolina? I know where the people are, because they have spoken. I know where the people of
                Tennessee are, but I know not where the sympathies of the Governor or the present Legislature of Tennessee are. I know where the heart of Kentucky is. No man doubts that; but I know not where the sympathies of her Governor or her Legislature may be. But, sir, I know that while I adhere to the Peace Conference propositions, I am anchored, and the moment I let go of them, I am again at
                sea.
              </p>
              <div className="speaker" id="sp1178"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Will the gentleman tell us about the other border States, where their sympathies are—Maryland and Virginia?</p>
              <div className="speaker" id="sp1179"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                Judging from the fact that my friend from Princess Anne and myself are here without the people having the privilege of saying whether this body should assemble or not, I think I can tell where the sympathies of her present Legislature are. Judging from the appointment which they made of the distinguished gentlemen who represented us in the Peace Conference, I am very much inclined to
                think that it would not require a prophet to tell who they would appoint again. I repeat again, that standing upon the Peace Congress propositions I am anchored. I do not wish to put again to sea.
              </p>
              <div className="speaker" id="sp1180"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">The gentleman did not answer me fully.</p>
              <div className="speaker" id="sp1181"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">Does the gentleman want me to tell him about Maryland and Delaware?</p>
              <div className="speaker" id="sp1182"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">The gentleman has referred only to one branch—the Legislature. Will he tell us where the executive officers of these States stand?</p>
              <PageNumber num={325} />
              <div className="speaker" id="sp1183"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I trust that the Executive of Virginia and the Executive of little Maryland, are where I think all good Union men ought to be.</p>
              <div className="speaker" id="sp1184"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">They suit you, then?</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp1185"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                Now, sir, I grant you that in one department of the General Government the Black Republican party is in power. But it is known to all gentlemen here, as well as to myself, that there are three departments in the Government. In neither the Legislative or Judicial is the Black Republican party in power, and the department of which they have possession is a pure Executive department,
                and the Executive officer is powerless for harm. He can do no harm, unless we suspect ourselves. Do we? If we do, I fear that seceding from the Union would not increase your confidence. Are we, the representatives of the people of Virginia, to distrust our constituents? Are we to refuse to those who sent us here the right to judge for themselves, and to protect their own rights?
                Shall not their vote be heard? Are we to follow the example set us by these States of the Cotton Confederacy?
              </p>
              <p className="p-in-sp">
                Sir, we are told that you can never restore the Union again. I do not admit that it is broken. But, for the sake of the argument, I will assume that it is in a condition to be reconstructed. I say to gentlemen who believe that it is in a condition to be reconstructed, that the sooner they disabuse their minds of the idea that we can reconstruct it, the better it will be for them. We
                cannot do it.
              </p>
              <p className="p-in-sp">
                But there is no necessity why we should attempt it. The sovereign people, upon whose virtue and intelligence rests all free government, in each and every one of these States, will re-construct it for themselves. And that the men in power know it, is evidenced by the fact that they refuse to allow the people the opportunity to pass upon this new Government, which they have determined
                to impose upon them. Whenever you point me to an act on the part of that new Government, that assumed Government, showing a confidence that the people they claim to represent are truly represented by them, then I grant you that there will be some evidence that it may have permanency attached to it.
              </p>
              <p className="p-in-sp">
                But, sir, even in Louisiana, they refused to submit an ordinance to the people, and refused to declare the vote of the people. At Montgomery a Congress is constituted, composed of men claiming to be representatives of the people. Are these men the representatives of the people? No, sir. No such thing. That Congress is composed of gentlemen selected by the several Conventions that met
                in the several <PageNumber num={326} /> States; and they, in the name of the people, presume to construct for them a Government, and have resolved to force it upon the people, and to refuse to the people the right and the privilege of saying whether they desire the Government or not. Sir, there is no necessity—nor if there was a necessity, have we the
                ability—so to shape our action here as to re-construct—to use the language of the gentleman—the old Union.
              </p>
              <p className="p-in-sp">
                But, sir, there is another fact to which I would respectfully call the attention of gentlemen who are so enamored of this report from the Committee on Federal Relations, and with its various propositions. After occupying nearly eight pages with what I conceive to be pretty much generalities and abstractions, it begins to talk a little practically. I mean nothing at all disrespectful
                when I speak of this report. It is a very able report, and one, sir, that I regret exceedingly I cannot approve by my action here. The report says :
              </p>
              <p className="p-in-sp">
                "Virginia, therefore, requests the people of the several States, either by popular vote, or in Conventions similar to her own, to respond, at their earliest convenience, to the positions assumed in the foregoing resolutions, and the proposed amendments to the Constitution of the United States hereunto appended. And in the event that this Commonwealth fails to obtain satisfactory
                responses to her requests, from the non-slaveholding States, she will feel compelled to resume the powers granted by her under the Constitution of the United States, and to throw herself upon her reserved rights."
              </p>
              <p className="p-in-sp">By what authority, gentlemen, do we, the advisatory representatives of Virginia, make this declaration?</p>
              <p className="p-in-sp">The report goes on to say:</p>
              <p className="p-in-sp">
                "The people of Virginia will await any reasonable time to obtain answers from the other States, to these propositions, aware of the embarrassments that may produce delay, but they will expect, as an indispensable condition, that a pacific policy shall be adopted towards the seceded States, and that no attempt be made to subject them to the Federal authority, nor to reinforce the
                forts now in possession of the military forces of the United States, nor recapture the forts, arsenals or other property of the United States within their limits, nor to exact the payment of imposts upon their commerce; nor any measure resorted to, justly calculated to provoke hostile collision.
              </p>
              <p className="p-in-sp">
                "In the opinion of this Convention, the people of Virginia would regard any action of the Federal Government, tending to produce a collision of forces, pending negotiations for the adjustment of existing difficulties, as aggressive and injurious to the interests and offensive to <PageNumber num={327} /> the honor of this Commonwealth; and they would
                regard any such action on the part of the seceded or confederated States as hurtful and unfriendly, and as leaving them free to determine their future policy."
              </p>
              <p className="p-in-sp">
                Mr. Chairman, will gentlemen be good enough to inform us how long they propose to wait? Or rather, how long will they have to wait for this report of the Conference which they desire to inaugurate, and then for the action of the people of the several States, in the event that Conference should agree upon the propositions that it would submit? It certainly will take months, if not
                years, to get a final settlement of the matter.
              </p>
              <p className="p-in-sp">
                But let us say it takes but a twelve month—I want gentlemen to tell me how they are going to prevent this collision in the mean time? How are you going to prevent this collision? And if it takes place, you commit me and mine, the destinies of my children and of the people I represent, to the destinies of these seceded States—aye, rather, to the destiny that may be provided for them
                by the seceded States. You link my fortunes with theirs, and you bring upon me and Virginia, so far unoffending, all the evil consequences that may possibly come upon those who have offended.
              </p>
              <p className="p-in-sp">
                Sir, we have no representatives in Congress now. Many of the States have not elected representatives. The last Congress expired upon the 4th of March. The President of the United States has sworn to see that the laws are faithfully executed. The United States have a tariff, and the Southern Confederacy, down South, pretends to have a tariff. Tell me, gentlemen, how long are you going
                to wait and how long do you expect it will be before those two tariffs come into collision? And when they do come into collision, how long do you expect that it will be before something very near akin to civil war will come? And then Virginia is to take part and parcel in that civil war—not part and parcel, sir, but here upon the border, she is to be the victim.
              </p>
              <p className="p-in-sp">
                There are a few other considerations that weigh upon my mind why we should have action, and prompt action, if we would save the Union, if we would save our people from all the horrors of civil war, if we would save the institutions of our State, of our section of the Union; and if we would afford an opportunity to the people in the seceded States, to declare their wishes as to the
                maintenance of what gentlemen term the old Union—why we should take that proposition which will the soonest bring quiet and peace to the country. How long, sir, will it be?
              </p>
              <p className="p-in-sp">
                We know by this time, Mr. Chairman, that it is not the intention, nor is it the desire of the Executive at Washington to do anything by which <PageNumber num={328} /> a collision will be provoked. We know, sir, what has been given out as to the policy of that Administration. However much we may have differed about the Inaugural Address, we know that the
                policy of the Executive is peace.
              </p>
              <div className="speaker" id="sp1186"><span className="persName">Ex-Governor WISE</span>—</div>
              <p className="p-in-sp">
                Will the gentleman allow me a moment? He keeps me alternating between a fever and an ague. One moment he tells us that there is imminent danger of a violent collision between the Federal Government and the seceded States. In the next breath almost, he announces that the policy of the Federal Executive is peace. Finally he assures us that we need entertain no apprehension. Now,
                leaving out of view the apparent incongruity of these positions, I would ask him, how he knows that the policy of the administration is peace?
              </p>
              <div className="speaker" id="sp1187"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I will tell you how I know.</p>
              <div className="speaker" id="sp1188"><span className="persName">Ex-Governor WISE</span>—</div>
              <p className="p-in-sp">There is no apprehension about a collision if the President does mean peace.</p>
              <div className="speaker" id="sp1189"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                I will explain, I will tell you. Mr. Chairman, I can say perhaps what even my friend from Princess Anne cannot say—during a term in Congress I did not speak to them, and while I have seen gentlemen who now hold high positions in this new Government, walking side by side in Pennsylvania Avenue, with Joshua R. Giddings, I never spoke to him; and I have never spoken to Mr. Lincoln, or
                any one belonging to his Administration.
              </p>
              <div className="speaker" id="sp1190"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I hope the gentleman will pardon me for interrupting him. I wish to tell him that I was eleven years in Congress, and I learned there not to call a spade a spade—we learn curious things you know in Congress? [Laughter.] There were sometimes terms of four years when I did not speak to anybody in the Administration. Very often, however, I knew what they were going to do. What is this administration going to do? The gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] , knows some how or other, what they are going to do. Are they going to slough off the Southern States? Are they going to be disunionists? Are they going intentionally to slough off those Southern States, and form a new Union? Is that their acknowledged position—the position of disunion? I put that question.</p>
              <div className="speaker" id="sp1191"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, when I spoke of what we knew as to the policy of the Administration, I spoke from facts before the public, from information that is before not only the representatives of the people in this hall, but before the whole people of the United States. I felt authorised so to speak from the fact that has been announced that Fort Sumter was to be evacuated; from the fact that
                we have <PageNumber num={329} /> it repeated day after day that it is to be the effort on the part of the Executive to preserve peace if he can do so, as long as he can ; and I believe that he may be able to do so for a short period of time, but I do not believe that he can very long discharge the duties imposed upon him under and by virtue of the
                solemn oath that he has taken, without a collision being brought about between these two tariffs. And hence, sir, I advocate that proposition which can be acted upon within the shortest period of time. If we adopt the substitute and the people of Virginia shall, at the polls in May next, ratify our action; these Peace Conference propositions will go before the several States of this
                Union and will be acted upon by the first day of August next, and if accepted by the States as a fair adjustment of our difficulties, peace will be restored to the country; and upon the meeting of Congress the adjustment will be placed before the States in a constitutional mode, to be acted upon and adopted by them as an amendment to the Constitution.
              </p>
              <div className="speaker" id="sp1192"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">
                Will you allow me to interrupt you again? I want information. Whilst they are evacuating Fort Sumter—perhaps at this time; I hope they are—does the gentleman know that an order came this day to Junius Archer, of the Bellona Arsenal, from the Ordnance Department at Washington to have all the new guns at that arsenal sent to Fortress Monroe? Does the gentleman know that?
              </p>
              <div className="speaker" id="sp1193"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">No, sir.</p>
              <div className="speaker" id="sp1194"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">And whilst they are sloughing off the Cotton States of the South, they are making every preparation now to force Virginia to remain with the Northern Confederacy.</p>
              <div className="speaker" id="sp1195"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I ask the gentleman if he apprehends danger?</p>
              <div className="speaker" id="sp1196"><span className="persName">Ex-Gov. WISE</span>—</div>
              <p className="p-in-sp">Apprehend it, sir? It is fearful. So much, that I would be this moment demanding that that armament at Fortress Monroe should be reduced.</p>
              <div className="speaker" id="sp1197"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                Well, sir, if the Federal Government were to order all its arms and all its men to Fortress Monroe to-day or to-morrow, I would feel as secure in all my rights as I feel at this hour. Sir, how is it possible that gentlemen can suppose for one moment that there can be, by any man or any set of men, the slightest design to make war upon or otherwise injure or invade the right of a
                single citizen in this State? These sensation articles, these alarming telegraphic orders, never have disturbed my peace for a single moment, for I know, that up to this hour, Virginia has done nothing to bring down upon her the power of the Federal Government to be used against her; and I would be more willing to believe that these arms are ordered there to protect and
                <PageNumber num={330} /> defend Virginia. But, sir, I will say, although I disclaim any extra bravery, that the people of Virginia never could, standing alone, be subjected or subdued, by any power on this continent.
              </p>
              <p className="p-in-sp">But, I trust in God there is self reliance enough to be found throughout every portion of this good Old Commonwealth not to be alarmed and frightened from this inheritance of ours which we should—to use the language which I have seen quoted from the gentleman from Princess Anne [Mr. WISE] —"never run away or flee from."</p>
              <p className="p-in-sp">
                This Union is mine and it shall, so far as my conduct is concerned, go to my children to be handed down to them and enjoyed by them as it has been enjoyed by me, to protect them as it has protected me, to be their shield against every danger and their protection for every interest. No right of mine, no right of the people of this Commonwealth has ever been invaded by it. All—all have
                been protected and secured as the rights of a free people were never protected before, and never, I am afraid, will be again if we let go this hope.
              </p>
              <p className="p-in-sp">
                Sir, there is another reason, one at which I hinted the other day, why we should hold fast to this anchor—the Peace Conference propositions. They can be acted on, as I said before, within a very short period of time. The public mind is prepared to accept them. They come with an endorsement given them by twenty-one of the States of this Union and fourteen of them non-slaveholding
                States. The country in all its business relations has suffered as it never has suffered before. War with any other power—aye, sir, war with all the powers of the earth would have brought less disaster and less ruin upon the country than this agitation, this unwise—not to give it a harsher expression—attempt upon the part of designing men to destroy this Union, which, I venture to
                say, the people of no State in this Union desire to destroy. Sir, the country cannot stand it much longer. It cannot wait for your Border State Conference, it cannot wait for a year or two, as it will have to do if you inaugurate a new Conference, before any action can be put into such a shape as to come before the people of the United States in a constitutional mode. A few months
                more and you will find your business men will not be able to stand longer before the storm, they will bend and wilt before it as they are doing daily now. Within a short time, without any Providential calamity visiting us, the bonds of Virginia have come to be hawked in the market at 25 per cent discount, at a time when under ordinary circumstances they would be sought by monied men
                for investment at par. And your Legislature, in order to raise the means for war, war upon an extended scale, resorts to the issue of shin-plasters of $10 and upwards, in the shape of Treasury <PageNumber num={331} /> notes; which they refuse the privilege of issuing to the banks because I suppose they want to issue them themelves.
              </p>
              <p className="p-in-sp">
                Sir, I am a Virginian. I am proud of my State. I love every inch of her soil, and yet I do confess that I am mortified at the position in which she has been placed—not by any act of her people, however, I am free to admit; and because I believe it has not been by the action of her people I am anxious to get before them these propositions. I am of the opinion of Judge Brockenbrough,
                who I believe sympathizes with the gentleman from Princess Anne, not only that the propositions of the Peace Conference would be adopted by an immense majority of the people of Virginia in the west and northwest, but my word for it they would be adopted by an immense majority in Eastern Virginia.
                [Cries of "no! no!"] If the gentlemen have no fears of it, let the people have the opportunity to speak.</p>
              <p className="p-in-sp">
                Give them, gentlemen, this opportunity, and go home and exert your influence among those whose fears have been unnecessarily aroused, and bring peace and comfort to their pillows. But, gentlemen, protract unnecessarily the session of this body, and you protract the ruin that is upon the country. Who is not aware of the impression that has been made upon the minds of the people of
                this State, that the Union men of this body are responsible for its non-action, and for the time that has been consumed here? Who upon this floor does not know that it is an unjust charge affixed to us?
              </p>
              <p className="p-in-sp">Sir, let us have a vote, and if the Peace Conference propositions are what I understand the gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>]
                , in the morning in reply to the gentleman from Princess Anne to say they were, if they are safe, and will secure to Virginia all her rights, why, oh why deny to the people the right to pronounce upon them? I warn gentlemen, in their desire to harmonize here, in their desires to gain the support of men whose support they will never gain, not to stir up new agitation everywhere else.
                I would like to see, how many men in this Convention would rather sacrifice that which I believe in my heart would be adopted by unparalleled majorities, in order to harmonize here and gain the support of men who were elected to the Convention as Union men with "ifs" and "buts." I say let them go. Let us get these propositions before the people, and let their voice be given for peace
                and harmony. Let that voice once be heard, and my word for it, it will be such a voice as was never before heard in this glorious old Commonwealth; a voice resounding from sea-shore to mountain; a voice that will make gentlemen willing to respect those <PageNumber num={332} /> whom they were once willing to call their masters, but whom they now dignify
                with the title of "my people."
              </p>
              <div className="speaker" id="sp1198"><span className="persName">Mr. NELSON</span>—</div>
              <p className="p-in-sp">I move that the Committee rise. [Cries of "No, no!"] The question was put and the Committee refused to rise.</p>
              <div className="speaker" id="sp1199"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">I am willing that the Committee should rise if they are disposed to do so, but supposing from the indications I have just witnessed, that such is not their will, I propose to detain them for a few moments.</p>
              <p className="p-in-sp">I need not tell you how much I love Virginia. I have heard that so often by the gentlemen who have addressed us that I hardly think it necessary to repeat it. If I understand the question before the Committee, it is the conflicting propositions of the Peace Conference and that of the Committee of twenty-one.</p>
              <div className="speaker" id="sp1200"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">
                I must renew the motion that the Committee rise. I think there was a misapprehension when the vote was taken upon a similar motion just now. I understand that various gentlemen voted in opposition to the motion not understanding the circumstances under which it was put. I am sure when they understand that a gentleman desires to speak, and that he does not desire to go on to-night,
                they will not refuse him the courtesy of continuing his remarks in the morning hour. I submit the motion that the Committee rise.
              </p>
              <p>The question was put and the motion disagreed to.</p>
              <div className="speaker" id="sp1201"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">
                I only gave way for the motion that the Committee rise, and will resume the floor. I was proceeding to say that I believe the question to be voted upon was between the propositions of the Peace Conference, and that of the Committee of Twenty-one. Now, sir, it has been stated, distinctly, by at least two members of the Committee, that the report of the Committee of Twenty-one, so far
                as it proposes amendments to the Constitution, is identically in substance the Peace Conference propositions. If that be true—and I believe it is true, for I have listened, during the whole of this debate, to hear some gentleman point out the difference—I say if that be true, I see no reason why we should take the Peace Conference propositions, in preference to those of the Committee
                on Federal Relations, when they are in substance the same.
              </p>
              <p className="p-in-sp">
                I hear gentlemen talking about war, but, sir, I am much afraid of gentlemen who talk so loudly upon that subject. They are generally the very last men to come to the post of war. I have listened in vain to hear why it was that the labors of the Committee of Twenty-one should be superseded by the Peace Conference propositions. But I have heard nothing on the subject. You recollect, as
                well as I, that it <PageNumber num={333} /> was said here that the Peace Conference propositions were not entirely plain and explicit, and that, for the purpose of avoiding the criticism which they received, they were amended by the Committee. If that be the fact, why not take the propositions as they come from our Committee so amended as to remove all
                ambiguity and difficulty? Besides that, these propositions have attached to them what some gentlemen choose to call a platform. Why not move the propositions of the Peace Conference, as a substitute for the amendments only? Why do gentlemen want to strike out the platform, as it is called?
              </p>
              <div className="speaker" id="sp1202"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I will answer my friend in the language of a letter which I received the other day from a gentleman from Northwestern Virginia, in which he said:</p>
              <p className="p-in-sp">"I have just read Mr. Conrad's report. I presume it is the majority report. If adopted, it is unnecessary to make any further efforts at compromise. If the secessionists had written it—and I do not know but what they did—they could not have written a report that would have answered their purposes better."</p>
              <div className="speaker" id="sp1203"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">I should a great deal rather have heard the argument of my friend, than to have heard a letter written by some gentleman whom I know nothing about. Yet the gentleman has not yet made any very good argument in favor of adopting his report over the report of the committee of twenty-one.</p>
              <p className="p-in-sp">Now, sir, so far as I am concerned, I am ready to vote upon all these propositions. I desire to vote, and not to debate. But I have a word to say to my friend from Goochland [<span className="persName">Mr. LEAKE</span>]
                , who, if I recollect aright, said the Legislature of Virginia had recommended the Crittenden propositions, as they were termed, "as a basis." I am using their own language, "as a basis of compromise." Now, when the Franklin proposition, as it was called, or the Peace Conference propositions, were reported in the Senate of the United States, Mr. Crittenden himself, you will
                recollect, moved them as a substitute for his own propositions; and you will remember that this body, by an overwhelming majority, returned our thanks to Mr. Crittenden for his zealous efforts in endeavoring to bring about a settlement of our national difficulties.
              </p>
              <div className="speaker" id="sp1204"><span className="persName">Mr. STAPLES</span>—</div>
              <p className="p-in-sp">With the permission of the gentleman, I will renew the motion that the Committee rise.</p>
              <p>The motion was disagreed to, ayes 44, noes 53.</p>
              <div className="speaker" id="sp1205"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">I am satisfied, Mr. Chairman, that you cannot hear me and that I can't hear you.</p>
              <p className="p-in-sp">
                I was proceeding to say, sir, in regard to the resolution that I offered the other day to the effect that our Senators in Congress did not reflect <PageNumber num={334} /> the sentiments and wishes of the people of Virginia, in relation to that "Peace Conference" proposition, that I had in my possession a number of petitions asking me to call up that
                resolution, and my friend from Monongalia
                [<span className="persName">Mr. WILLEY</span>] , told me he had also some petitions on the same subject from another portion of the North-west. But I don't know that I would call it up after going over the heads of the Senators and passing a resolution of thanks to Mr. Crittenden who offered the "Peace Conference" proposition in preference to his own.</p>
              <div className="speaker" id="sp1206"><span className="persName">Mr. LEAKE</span>—</div>
              <p className="p-in-sp">If the gentleman from Preston [<span className="persName">Mr. BROWN</span>] votes for other propositions as being better than the Peace Conference propositions, would he then censure our representatives who would not vote for a proposition that they considered not as good as the Crittenden proposition for instance?</p>
              <div className="speaker" id="sp1207"><span className="persName">Mr. McGREW</span> of Preston—</div>
              <p className="p-in-sp">I think it very evident, sir, that we will not have that, degree of order here during the evening that will enable my colleague from Preston or any other gentleman who might desire to address this committee, to be heard with satisfaction to himself or the committee. I therefore move that the committee rise.</p>
              <p>The vote was taken by a count, and resulted ayes 43. The noes were not counted, it being apparent that there was a large majority in the negative.</p>
              <div className="speaker" id="sp1208"><span className="persName">A MEMBER</span> [whom the reporter could not recognize, it being too dark],</div>
              <p className="p-in-sp">, I would ask that the door-keepers be called upon to aid in keeping order. Order cannot be restored unless they do their duty.</p>
              <div className="speaker" id="sp1209"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">I endeavored to raise my voice so that I could be heard. I am satisfied that I could not be heard even when I hallooed at the top of my voice, and I now don't care whether I am heard or not. [Laughter.] I desire, sir, when I get a little water, to speak, if I can, so that you can hear me. I don't know whether I can do that.</p>
              <p className="p-in-sp">My friend from Goochland [<span className="persName">Mr. LEAKE</span>]
                made the enquiry of me, if I should vote for a proposition better than that of the "Peace Conference;" whether I still would insist upon my resolution that is now upon the table? I don't expect to vote for a proposition any better, and that is an answer to my friend. I intend to vote for the proposition, and will endeavor to reconcile the criticisms of my friend from Goochland. He
                told us that he was a good Union man.
              </p>
              <div className="speaker" id="sp1210"><span className="persName">Mr. LEAKE</span>, in his seat—</div>
              <p className="p-in-sp">No, sir.</p>
              <div className="speaker" id="sp1211"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">
                Then I am mistaken. I thought the gentleman was a Union man. I am in error, however; he is against the Union, and I <PageNumber num={335} /> have nothing to say to him on the subject. He and I are against each other on that point. I don't feel like calling up the resolution that I intended to call up, because of the vote of thanks to Mr. Crittenden. My
                notions of chivalry would forbid that I would call it up and pass it, if I could. Now, sir, I will assign a few reasons why I shall vote for the report of the Committee of twenty-one, and shall refuse to substitute the "Peace Conference" resolutions in lieu thereof.
              </p>
              <p className="p-in-sp">You will bear me witness, sir, that in all the argument made during the early part of the Convention, the arguments as to these "Peace Conference" propositions were directed chiefly against their ambiguity.</p>
              <div className="speaker" id="sp1212"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">There is too much confusion in the Hall; gentlemen will refrain from conversation.</p>
              <div className="speaker" id="sp1213"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">It is always said that night sessions are noisy. Sometimes they assign one reason and sometimes another. I don't know what reason there is for the confusion this evening.</p>
              <div className="speaker" id="sp1214"><span className="persName">A VOICE</span>—</div>
              <p className="p-in-sp">All sober.</p>
              <div className="speaker" id="sp1215"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">
                Let us behave ourselves, then. The whole argument was directed in the beginning against the ambiguity of the Peace Conference proposition. I shall vote for the reports of the committee of twenty-one, because they have cleared up the ambiguity. I understand them precisely as amounting to the same—as being the same in substance with the propositions of the Peace Conference. I shall
                vote for them because they remove that ambiguity, and because—
              </p>
              <div className="stage it">[There was some confusion at this stage, which caused the speaker to suspend his remarks for a short time.]</div>
              <div className="speaker" id="sp1216"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">
                It is said that there ought to be a sober Indian always in the crowd. I am aware, sir, that I raise my voice and endeavor to speak so that I could be heard; but I am afraid I articulate badly. If the object of my friends here is not to hear me; if that be the object of the noise, I can only say that I extended to them a very different courtesy. I do not intend to argue to-night. I
                cannot argue. I would not argue before an unwilling assemblage—before my peers who manifest a disposition not to hear or listen. Sir, I am as ready to vote as they are; and I will vote for the propositions of the committee of twenty-one; and sir, I will adhere to them. I believe the Franklin substitute, as adopted by the "Peace Conference" at Washington, is good, and so believing, I
                was ready to vote for it. My people are ready to sustain that substitute and to accept it as an adjustment of our difficulties. And so of the resolutions of the Committee of twenty-one. I shall adhere to them, unless, during the progress of the argument, I shall be convinced that there could be an amendment made either in
                <PageNumber num={336} /> phraseology or in substance, that would commend itself to me. I will not pledge myself to vote for all the preamble. I have not examined it with sufficient care.
              </p>
              <p className="p-in-sp">Sir, I have said all I will say at this time. I should have been very glad, if it was the pleasure of the Committee to rise, to enable me to make a few remarks to-morrow morning. After the manifestations on this floor, I cannot ask the Committee for any further indulgence now or to-morrow.</p>
              <div className="speaker" id="sp1217"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">It seems to me, Mr. Chairman, from the indications of the number present, given in a late count, that it would be well to postpone a vote upon the substitute. According to the last count there are only 97 members present, and I think it would be proper to wait until there was a full attendance before taking a vote. I move that the committee rise.</p>
              <div className="stage it">[Lost.]</div>
              <div className="speaker" id="sp1218"><span className="persName">Mr. CHAPMAN J. STUART</span>—</div>
              <p className="p-in-sp">I don't propose to make a speech, but I am willing to say to this Convention that I am willing to stay here all night and vote. I desire to explain my vote. I shall vote against the proposition offered by my friend from Harrison [Mr CARLILE]
                , as a substitute for the report of the committee of 21. I want it distinctly understood that I do this not because I take exception to the Peace Conference resolutions. I have friendly feelings towards these resolutions, and am now willing to vote for them. But I understand in voting for the substitute offered by the gentleman from Harrison, that I am precluded thereby from voting
                for certain other propositions raised by the committee of 21. Sir, I am in favor of a Border Slave State Conference, and I understand that my vote for the Peace Conference resolutions would preclude me from voting for that proposition also, inasmuch as this substitute if adopted would supersede all the other propositions reported by the committee.
              </p>
              <p className="p-in-sp">
                I am opposed to coercion, sir. I desire to enter my protest against any act of coercion by the General Government. The report of the Committee of Twenty-one contains many things that I approve, and I am, therefore, to cast a vote which would preclude me from voting upon them. There are, however, many things in that report that I cannot endorse, but as the vote will be taken upon the
                propositions seriatim, there will be an opportunity of voting upon all and rejecting such as are not acceptable and moving substitutes for them if necessary.
              </p>
              <p className="p-in-sp">For these reasons I shall vote against the substitute.</p>
              <p className="p-in-sp">But at present I shall vote against them, not as a test matter as to the Peace Congress at all. I do not want to state it in that way.</p>
              <PageNumber num={337} />
              <div className="speaker" id="sp1219"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">
                I did not anticipate so late a session or that the vote would be taken this evening. I desire only to occupy a few minutes time, in which to state distinctly the grounds upon which my vote now to be cast will be given. The proposition to amend the Constitution of the United States agreed upon in the Conference at Washington had become the subject of some criticism and cavil connected
                mainly with the terms and phraseology in which portions of that instrument had been couched. I did not myself think that the adjustment was liable justly and fairly to those objections. I attempted some days since to make a vindication of these propositions. I wish it distinctly understood that then and now I candidly and conscientiously think, so far as I am enabled to form a
                judgment at all, that they are without objection in the particulars to which objection has been directed, and that if incorporated into the Constitution of the United States they would completely meet the difficulties which have heretofore surrounded us. I believe, furthermore, that these propositions not only are acceptable to a very large majority of the Convention, but I believe
                acceptable to a large majority of the people of Virginia. I am called upon now to act not as a member of the Peace Conference, not as a Commissioner from Virginia to meet Commissioners from non-slaveholding and slaveholding States. That duty has passed. I am now called upon as a member of this Virginia Convention to aid in constructing a Virginia proposition. In doing so, and in
                performing that labor and that duty, I have been willing to meet gentlemen on common ground, who have thought that the phraseology of these Peace Conference propositions was liable to the charge of ambiguity. It will be remembered that one of my colleagues
                [JUDGE BROCKENBROUGH]
                in his letter states in regard to the territorial question that there might be some ambiguity; that it was a matter of construction. I have been willing to meet this objection. These propositions have been submitted to your Committee on Federal Relations. They have examined them; they have reported them back modified and amended in several particulars. In regard to that first
                proposition, covering the territorial question, I am free to say that while the phraseology has been changed, I do not regard it as a change in substance at all; that is, holding the opinions I entertain, the language of the proposition as originally employed is precisely the same I care not whether you say the status of the person held to service or whether you say involuntary
                servitude. They are to me the same. I say I care not whether you say according to the practice of the common law or a remedy at common law. They are precisely the same.
              </p>
              <PageNumber num={338} />
              <p className="p-in-sp">
                But I am not here to make objections to grounds of resistance made by other gentlemen, but to remove criticism and cavil. I interpose no such difficulty. Now, while I believe the Peace Conference propositions would be quite acceptable to a large majority of the people of this Commonwealth, I speak my own opinions; I may be mistaken; yet I verily believe that upon a vote they would be
                acceptable to a large majority of the people—yet, at the same time, I am satisfied, upon conference with other gentlemen, and interchange of opinion with them, that the modifications and changes which the propositions have now undergone, as presented by the committee of twenty-one, would challenge the approbation of a still larger majority of the people of Virginia. I said, in the
                remarks I made the other day, that I had been opposed in the Conference to the addition made to the 7th section. That alone would justify me in voting for the committee's proposition rather than the original proposition, because we get rid of that which is and always has been to me an objection. In constructing a proposition, as we are, in behalf of Virginia, having it in our power,
                if we think best, and not cramped about with the difficulties of a General Conference, I see no objection to putting it into proper language which will suit us. There is another preference which I have. The Peace Conference propositions in one section rendered irrepealable certain other sections of the propositions and certain sections of the Constitution; not all of the sections,
                however, of the adjustment made by that Conference. But this committee has so modified the section as to make it apply to render irrepealable all those sections. Then, when you bring up these propositions here as a matter of comparison, although the Peace Conference propositions were acceptable to me, is not my judgment here to act and determine for itself whether upon this
                comparison, the one or the other is likely to be most acceptable to the people of Virginia, and most likely to harmonize the feeling of this Convention and of the people? Then it seems to me there is no ground for any imputation of inconsistency. I do not repudiate in any form or to any extent the proceedings of the Peace Conference at Washington. You bring me back from the committee
                those same propositions having passed through the crucible of examination here among ourselves. We are forming a proposition now to submit on behalf of Virginia to other States. We are free to submit it in our own terms. I then am at liberty to compare and adopt now, as an independent question, whatever proposition I prefer. And it is for this reason I reject the Peace Conference
                proposition; not because I think less of it than I did originally; not because I concur in opinion with gentlemen around <PageNumber num={339} /> me who say that it is an abortion and a sham. Not at all. I have already spoken upon it and have given my views of it. In the present attitude of our action, a motion being made here to strike out the
                committee's report and insert the proposition of the gentleman from Harrison
                [<span className="persName">Mr. CARLILE</span>]
                , I shall vote against striking out. The proposition of the gentleman from Harrison passes by altogether, and makes no provision for a border State Conference, and it makes no provision for the ratification of his own proposition by the people. It provides that it should be submitted to the people of Virginia, and that the county and corporation clerks shall certify the result, and
                that the Governor shall make proclamation of the fact; but it makes no provision that it shall finally be passed by Congress and submitted by that body to the people. Then, when you ask me how I shall vote upon this question of striking out, I am constrained to vote against striking out, for the reason that the Committee having reported this proposition in an improved and amended
                form, so far as the objections of others are concerned, I am willing to hold on to it, looking to see if other improvements are needed. It is a mere matter of comparison between the two propositions, and I have only to say, if there be inconsistency in my position, I am entirely incapable of perceiving it.
              </p>
              <div className="speaker" id="sp1220"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">The gentleman from Kanawha has collected himself and comes back with an additional excuse to that which he gave this morning.</p>
              <div className="speaker" id="sp1221"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">I took then precisely the same ground that I do now.</p>
              <div className="speaker" id="sp1222"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I did not hear any thing about the Virginia proposition this morning.</p>
              <div className="speaker" id="sp1223"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">It is an illustration and enlargement of the view I took then.</p>
              <div className="speaker" id="sp1224"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Let us examine: I tell the gentleman his last explanation is more unsatisfactory than the first. By whom was the gentleman sent to Washington? By Virginia. What was he sent there for? If to get any thing, it was to get a Virginia proposition. This is not a Virginia proposition, is it, that he brought back ?</p>
              <div className="speaker" id="sp1225"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">The gentleman certainly understands what I say of the Virginia proposition. I mean a proposition framed in a Virginia assembly as contra-distinguished from a proposition obtained from a General Convention.</p>
              <div className="speaker" id="sp1226"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I repeat, he was on a Virginia commission to obtain a Virginia end. He was not only a Commissioner to attend at Washington, <PageNumber num={340} /> but he had the double character of being a member of this body, at the time he was a member of that Commission.</p>
              <div className="speaker" id="sp1227"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">I was elected while there.</p>
              <div className="speaker" id="sp1228"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                He was elected while he was there, but he was acting upon the commission while he was a member of this body. He was a Virginian there, and a Virginian here, identified with Virginia, acting for her as her agent with the double commission in his charge. And now we are told that this proposition is to be regarded as something coming from foreigners and outsiders, and it is not to be
                treated with the same respect as one emanating from a mere advisory Committee of this body. That is all that the Committee of twenty-one is. But if it be true, as he states, that the one proposition is a domestic, and the other a foreign one—one a Virginia Convention proposition, and the other a Washington City proposition—the question arises, is it true that they are the same? I
                take up the gauntlet from the gentleman when he asserts that they are the same, in respect to the very words he uses, in the very illustration he has given in regard to the first proposition. What does the Peace Conference proposition say in reference to Mexican laws? You may call it ambiguity only that you have relieved your proposition from. No such thing. You have put in that very
                clause substantial, essential alterations; but it was done after a hard struggle. I believe I may claim that it was done on my motion in the Committee, if I may be allowed to speak of what occurred in Committee. The very question was then—is it the same? But, sir, argument will convince any man that will listen to the reading of the propositions, that they are not the same. The
                gentleman hinges upon the words "status of persons held to labor." Throw them away. I have got nothing to do with them. The words that I fix my eye upon in this report are the words "as now existing." The status of persons held to service or labor as now existing in that territory, shall be determined by the cognizance of the common law. I do not use the precise words in the latter
                phrase, but in the first phrase I do use the words. Sir, eleven years of service in the Congress of the United States and perfect familiarity with the debates in Congress upon it, in which I was a participant over the tables, under the tables and around the tables, with the best teacher that man ever had—that old man eloquent, John Quincy Adams told me too well the meaning of those
                words. It is not a new phrase it is an old one. What is the doctrine of these words?
              </p>
              <p className="p-in-sp">
                If you adopt the unamended proposition of the Peace Conference, that the status of persons as it now exists in territories South of 36 deg., 30 min., shall not be changed, the Northern men who agree with you <PageNumber num={341} /> to accept such a term of adjustment, will tell you that the legal status as it now exists, in territories acquired from
                Mexico, is fixed by and derived from the Mexican law—a free soil law. And thus, under the very terms of the Peace Conference proposition, they would establish irrevocably the domain of free-soilism South, as well as North of 36 deg 30 min.
              </p>
              <p className="p-in-sp">Take on the other hand, the express provision now embodied in the report of the Committee of twenty-one, that the status shall not be regarded as fixed by the Mexican law, and you are relieved of that free-soil argument which applies so directly to the proposition of the Peace Conference.</p>
              <p className="p-in-sp">
                Take the two propositions and ask Joshua R. Giddings, Horace Greeley, Wm. H. Seward and the anti-slavery, Black Republican advocates of the doctrine of free-soil and non-extension of slavery, which of the two propositions they would take? Ask Salmon P. Chase, who was in the Peace Convention, which of the two propositions he would take? I tell you that if you adopt the Peace
                Conference proposition, they will hold you to the construction, that you fix the status according to the Mexican law which was free-soil law, and that free-soil law is not to be changed. But if you will say, as the Committee have said, that it shall exclude the operation of all pre-existing Mexican law and that the status as now existing, independent of that law, shall not be
                changed, why, it exactly reverses the proposition. I would like time to enter into a discussion of this question.
              </p>
              <div className="speaker" id="sp1229"><span className="persName">Mr. SPEED</span>, of Campbell—</div>
              <p className="p-in-sp">Will the gentleman give way for one moment?</p>
              <div className="speaker" id="sp1230"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Certainly.</p>
              <div className="speaker" id="sp1231"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">
                I propose to test the sense of the Convention upon the programme adopted last Saturday—that this Convention hold sessions in the morning and in the afternoon. It is now past seven o'clock, and if it is the purpose of the Convention to hold night sessions also, I will quite cheerfully agree to it. If that be not the purpose, and it now being past 7 o'clock, I would move that—
              </p>
              <div className="speaker" id="sp1232"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I did not give way for any such purpose as that. I will conclude in a few minutes.</p>
              <div className="speaker" id="sp1233"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman having given way, the gentleman from Campbell [<span className="persName">Mr. SPEED</span>] , is entitled to the floor.</p>
              <div className="speaker" id="sp1234"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">But I would not have yielded if I had known what his purpose was. I do not wish to place the gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>]
                in any inconsistent position. It may be that he differs <PageNumber num={342} /> from me in this construction; I don't know. I suppose he does. But, I say he came to the conclusion that there is no difference in the propositions, which are essentially contradictory to each other. I differ from him toto ccelo. I have had too much indoctrination—too much
                experience of what the Northern men contend for, to doubt at all; and I am informed that Mr. Chase made himself perfectly understood in that Peace Conference. When the word legal was proposed to be affixed to the word status, it was a matter of indifference to him. That gentleman, now a member of the Cabinet, as I am informed distinctly stated that he would take that proposition
                because of the interpretation that was put upon it by him and his party.
              </p>
              <p className="p-in-sp">
                But it is not only in that instance, sir, but in almost every section of the report of the Peace Conference, that the Committee have condemned it. I, for one, have fixed my gaze upon it with intensity, and I tell you that if you think there is no difference between the two, you are grossly mistaken. There is a vital, essential, practical, constructive difference between the two. The
                provision as to the privileges of the citizens of the several States has been thrown out. And there is one other vital change—that in respect to the right of transit with slaves—the power to pass through the non-slaveholding States. In that particular the one proposition is wholly different from the other: and there can be no difference of opinion as to the right of the question
                itself. But, besides that, the other parts of the report of the Committee of twenty-one, the preamble of the report of the Committee of twenty-one, the resolutions and principles that they have adopted, the addendum to the Peace Conference which they have adopted—all change it substantially and utterly, and add to the complication of the thing.
              </p>
              <div className="speaker" id="sp1235"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">I restricted myself to the guarantees—to the proposed amendments to the Constitution.</p>
              <div className="speaker" id="sp1236"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I know that, sir; but the question now subjected to your decision is not so restricted. The motion is to strike out the whole of the report, and adopt in lieu of it the amendment, You are called upon either to adopt the whole or reject the whole of the report of the Committee of Twenty-one, or substitute in its place, or to reject, the propositions of the gentleman from Harrison
                [<span className="persName">Mr. CARLILE</span>] .</p>
              <p className="p-in-sp">
                Let us look at this question narrowly, with a Virginia eye—for there is a difference between the eye of Washington City and the eye of Virginia. I am very glad that the gentleman has thrown away the spectacles that he looked through at Washington, and has, at last, taken the Old Virginia spectacles. I tell you, sir, there is an immense
                <PageNumber num={343} /> difference between the two; when you get in the atmosphere of Washington there is a haze; you see things double there; there is a mirage, there is a great deal of malaria, too, at Washington, that does not exist here; the best eye and the purest heart that goes there is obliged to see through a medium different from Virginia
                atmosphere. I am glad, too glad to know that whilst the gentleman endorses the Peace Conference proposition, he seems to have a double vision; one vision is telescopic and the other microscopic; and when he looks at the two with the Washington eye he can make a five hours' speech to persuade this Convention that the Peace Conference proposition is the best one that Virginia ought to
                adopt. He moves to have it referred to the Committee of twenty-one, recommending it by every endorsement, by every argument, as if Virginia should adopt it. Then he was acting I suppose in the capacity of his representative membership in this body. Well, Old Virginia got hold of it, and we saw it in this atmosphere through the Virginia glasses and it would not do.
                [Laughter.] It was originally wrong and ambiguous.</p>
              <p className="p-in-sp">And it was not ambiguous merely. The whole thing was originally defective. It was handing us over body and soul—and give me the time and I will demonstrate it—taking from us all that was left after we had compromised and compromised in vain.</p>
              <p className="p-in-sp">
                Oh my God, sir, I could weep! I cannot look at you without seeing near you those two sculptured figures in contrast. I wish I could speak of Henry Clay as I want to speak of him without being misunderstood. I knew him and knew him well. He has gone, and I would say nothing of him but what is good. And there is one good thing I can say of him, and when I look at that marble I am
                reminded of it. He was game, he was indomitable, he was uplifted, and his steppings were stately step-pings. He, that mighty man, tried compromise, and compromise and compromise until he won exalted fame, and the name of The Great Pacificator. Henry Clay, I invoke your shade! If you had life now and were standing in this hall, on your native soil,—Henry Clay I invoke your shade to
                know what would be your exclamation, your apostrophe of compromise! Alas! Alas! the word has crumbled into ruins like the Ship of State for which he labored in vain.
              </p>
              <p className="p-in-sp">
                And, in contrast with that of Henry Clay, another image rises before my mind, distinct and indelible as marble—the image of John C. Calhoun, of that man who was ambitious above other men—who sought the Presidency as Newton sought an observatory—he did not want it without his telescope; that man of whom a Georgian has said that he was like Michael Angelo's dome in the Heavens without
                the scaffolding <PageNumber num={344} /> of thought. The other day, when a South Carolinian, who was pleading at this bar, referred to that marble image, I looked at the stern, severe, unbending, unyielding countenance, and my manhood gave way, and I wept to think of him. He was a prophet, and more than a prophet. He foretold the events in the midst of
                which we are now to live. He knew the use of words; he knew that words were not shadows, but things. The giant contest, the more than gladiatorial struggles which took place between these two men, between whose marble images you and I, sir, sit here to-day, furnish in one sense a type of the national crisis in which you and I are this day involved. And now the pen of the present is
                to record an answer to the question—an indelible answer. Whose efforts have told on the side of error, and whose have told on the side of truth? From one came a hopeful effort to save by compromise; the other sternly told you to stand by your rights as the only staunch, stern immovable position that would save you.
              </p>
              <p className="p-in-sp">
                And, sir, I have had twenty-seven years experience in this matter .out of fifty-four of my life, and I aver the fact, and I defy any man to contradict it, I will prove it to be true in every instance by the record—that slavery, the South, the country, never have lost one jot or tittle of right or interest by any extreme Abolitionist. The rabid Abolitionists have always been so
                extravagant that no bargains were made with them, no compromise could be concerted with them; they have been out of the contract always—always opposing a contract; but all the loss, all the cost to slavery, to the South—now to the whole country, has come out of compromise—iterated and reiterated compromise with what you call conservative men of the North. Yes, sir, you had first
                anti-slavery beginning with the brethren of the Quaker order—peace party men, who will pay tribute rather than fight. They first commenced this war upon slavery. It was a religious element at that time. Shortly afterwards a hypocritical, canting, ranting pseudo philanthropy of politics seized upon the religious element, and you had a double demon to contend with. At this day you have
                a still more vindictive, more destructive element combined with the religious element and the political element. You have that which I hate worse than all, that which I would trample upon to utter annihilation, that which is worse than Lucifer's own infernal Gods—you have agrarianism, falsehood and chicanery.
              </p>
              <p className="p-in-sp">
                Let me tell you, sir, that, in my opinion, that last, worst, most-to-bedreaded enemy is found in Virginia as well as in the North. Agrarianism at this day calls itself Republicanism par excellence. But it lays no claim to that proud Republicanism which raises mankind upon the <PageNumber num={345} /> highest platform, and would make us equal to kings
                and princes. It would drag all down together to the lowest level of the very filth and mire of the earth. This is the struggle now going on.
              </p>
              <p className="p-in-sp">To return more immediately to the matter under consideration. The gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>]
                blames the Peace Conference only as ambiguous. If this proposition that comes from the Peace Conference is ambiguous, why did the gentleman bring it here? Why did he try so hard to enforce it? It was unambiguous to me. I tell the gentleman from Kanawha, if he is not with the gentleman from Harrison, that this proposition suits the gentleman from Harrison but it does not suit him. He
                has made a mistake; the proposition of the Committee is substantially different from that of the Peace Conference, and let us have it so understood. I do not want the gentleman to condemn himself, but I will give him one piece of advice, as the best means of avoiding all perplexity. Let him say that he sees a better proposition than the one he brought here from Washington, and he
                therefore takes it. That is what he does substantially say.
              </p>
              <p>The question was then taken upon the adoption of the substitute proposed by <span className="persName">Mr. CARLILE</span>, and it was decided in the negative—yeas 4, nays 119, as follows:</p>
              <p>YEAS—Messrs. Burley, Carlile, Hubbard, Porter-4.</p>
              <p>
                NAYS—Messrs. Ambler, Armstrong, Aston, Baldwin, Baylor, Berlin, Blakey, Blow, Boggess, Boisseau, Borst, Boyd, Branch, Brent, Brown, Bruce, Burdett, Byrne, Cabell, Campbell, Caperton, Carter, Chambliss, Chapman, Clemens, Coffman, Conn, C. B. Conrad, R. Y. Conrad, J. H. Cox, R. H. Cox, Custis, Deskins, Dulany, Early, Echols, Fisher, Forbes, French, Fugate, Garland, Gillespie, Graham,
                Gravely, Gray, Goggin, J. Goode, T. F. Goode, A. Hall, C. Hall, E. B. Hall, L. S. Hall, Hammond, Harvie, Haymond, Hoge, Holcombe, Holladay, Hull, Isbell, Jackson, M. Johnson, P. C. Johnston, Kilby, Kindred, Lawson, Leake, Lewis, McComas, McGrew, McNeil, C. K. Mallory, J. B. Mallory, Marshall, Marye, Maslin, Masters, Miller, Moffett, Morris, Morton, MOORE, Neblett, Nelson, Orrick,
                Osburn, Parks, Pendleton, Preston, Price, Pugh, Richardson, R. E. Scott, Seawell, Sharp, Sheffey, Sitlington, Southall, Speed, Spurlock, Staples, A. H. H. Stuart, C. J. Stuart, Strange, Summers, Sutherlin, Tayloe, Thornton, F. P. Turner, Tyler, Waller, White, Whitfield, Wickham, Willey, Williams, Wilson, Wise, Woods-119.
              </p>
              <p>So the amendment was rejected. During the call of the roll</p>
              <p><span className="persName">Mr. BALDWIN</span> stated that he had paired off with the gentleman from Richmond city [<span className="persName">Mr. RANDOLPH</span>] ; but he had been informed that, were <span className="persName">Mr. RANDOLPH</span> present, he would vote in the negative, as he [<span className="persName">Mr. BALDWIN</span>] should vote.</p>
              <PageNumber num={346} />
              <p><span className="persName">Mr. HARVIE</span> said he would take the responsibility of saying that <span className="persName">Mr. RANDOLPH</span>, if present, would vote in the negative.</p>
              <p><span className="persName">Mr. OSBURN</span> stated that his colleague, <span className="persName">Mr. BARBOUR</span>, of Jefferson, was necessarily absent.</p>
              <p><span className="persName">Mr. BROWN</span> stated that he had paired off with the gentleman from Cumberland, <span className="persName">Mr. SCOTT</span>; but understanding they would both vote the same way, if here he should vote in the negative.</p>
              <p><span className="persName">Mr. CAMPBELL</span> stated that his colleague, <span className="persName">Mr. GRANT</span>, had been called home suddenly by severe family affliction.</p>
              <p><span className="persName">Mr. HUGHES</span> said he had paired off with the gentleman from Pulaski [<span className="persName">Mr. WYSOR</span>] .</p>
              <p>A member stated that <span className="persName">Mr. TREDWAY</span> was absent from the city. If he were present he would vote "no."</p>
              <div className="speaker" id="sp1237"><span className="persName">Mr. WOODS</span> said:</div>
              <p className="p-in-sp">
                Before voting, I want to say a word. When the propositions emanating from the Peace Conference were presented to the people of the United States, I gave them a careful examination—the best examination I was able to give them. That examination led my mind to the conclusion that they were a delusion and a snare, and I determined to vote against them. After I had the pleasure of
                listening to the able, eloquent and vigorous argument of the gentleman from Kanawha
                [<span className="persName">Mr. SUMMERS</span>]
                , in their defence, I reconsidered my judgment, and my mind came to the conclusion still that they were a snare. To-day, I have had occasion again to review the grounds over which my friend traveled, and I have been relieved from all doubt upon the subject by finding the enlightened gentleman from Kanawha turning his back upon these propositions he so ably advocated before, and
                announcing his vote "no" upon their adoption. I am convinced now, beyond a doubt, that my first conclusion was correct, and that they are a delusion and a snare. I take pleasure, therefore, in voting "no."
              </p>
              <div className="speaker" id="sp1238"><span className="persName">Mr. E. B. HALL</span>, of Marion—</div>
              <p className="p-in-sp">
                I desire to give a reason for my vote. I shall vote "no" in this case, not as opposing the Peace Conference propositions in themselves. I, myself, and so far as I know, my people were content with the Crittenden propositions. I know, or believe, that that people, notwithstanding the hard things that have been applied to the Peace Conference propositions, regard those propositions as
                infinitely superior to the Crittenden propositions. And the very fact that they have received such hard names from certain quarters, eminently commends itself to me and to my people, who are a conservative people. With this explanation, I cast my vote in the negative.
              </p>
              <div className="speaker" id="sp1239"><span className="persName">Mr. J. A. EARLY</span>, of Franklin, on giving his vote, said:</div>
              <p className="p-in-sp">
                I merely rise to give the reason for the vote I shall cast upon the <PageNumber num={347} /> motion to strike out and insert the substitute under consideration, and I do not wish that vote to be construed as a condemnation on my part of the propositions of the Peace Conference. But the substitute of the gentleman from Harrison embraces certain
                resolutions for which, in their present form, I cannot vote; as I understand the Chair to decide that if the motion to strike out and insert the substitute of the gentleman from Harrison prevails, that substitute cannot be amended in the Committee. For that reason I shall vote against the pending motion; and, in so doing, I do not want to be considered as condemning the propositions
                of the Peace Conference.
              </p>
              <p>The vote was then announced as above recorded.</p>
              <p><span className="persName">Mr. NELSON</span> moved that the committee rise.</p>
              <p>The motion was agreed to.</p>
              <p>The committee accordingly rose and <span className="persName">Mr. SHEFFEY</span> having taken the chair as President pro tern pore, the chairman [<span className="persName">Mr. PAICE</span>] reported that the Committee of the Whole had according to order, had under consideration the report of the Committee on Federal Relations, and had come to no resolution thereon.</p>
              <div className="speaker" id="sp1240"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I move the Convention adjourn sine die.</p>
              <p><span className="persName">Mr. GOODE</span> of Mecklenburg demanded the yeas and nays.</p>
              <div className="speaker" id="sp1241"><span className="persName">Mr. NELSON</span>—</div>
              <p className="p-in-sp">I rise to make a motion which I understand will take precedence of the motion of the gentleman from Harrison. I move simply that the Convention adjourn.</p>
              <p>The motion was agreed to, and thereupon the Convention adjourned until to-morrow at ten o'clock A. M.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v2.2.8">
            <PageNumber num={348} />
            <h2><span className="headingNumber">1.8. </span><span className="head">THIRTY-FIFTH DAY</span></h2>
            <div className="dateline">Tuesday, <span className="date">March 26</span></div>
            <p>The Convention met pursuant to adjournment at 10 o'clock, A. M. Prayer by Reverend Dr. Jeter.</p>
            <div className="speaker" id="sp1242"><span className="persName">Mr. HULL</span>—</div>
            <p className="p-in-sp">
              Mr. President, it is apparent from the thin attendance of members this morning, that the animals so graphically described in the columns of the Examiner a few days since, don't pull well on a cold collar; and that we may see which of them wants tickling, and that their constituents may see who is chargeable with delay in the business of this Convention, I move a call of the House.
            </p>
            <p>The motion was agreed to and a call of the House proceeded with, but before it was finished</p>
            <p><span className="persName">The PRESIDENT</span> announced that there was evidently a quorum present.</p>
            <p>On motion, all further proceedings under the call were then dispensed with.</p>
            <div className="speaker" id="sp1243"><span className="persName">Mr. ECHOLS</span>—</div>
            <p className="p-in-sp">I am requested by a very respectable number of gentlemen from the county of Monroe to present a petition in their name, asking this Convention to concur in the suggestions contained in the substitute offered by the senior Delegate from Bedford county [<span className="persName">Mr. GOGGIN</span>] for the report of the majority committee. I move that it be received and laid on the table.</p>
            <p>The petition was laid on the table.</p>
            <div className="section" id="vsc1965.v2.2.8.1">
              <h3><span className="headingNumber">1.8.1. </span><span className="head">PROCEEDINGS OF A PUBLIC MEETING IN BOTETOURT COUNTY</span></h3>
              <p><span className="persName">Mr. MILLER</span> presented the following proceedings of a public meeting in Botetourt county:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>At an unusually large meeting of the people of Botetourt county, on Monday, the 11th of March, 1861, it being Court day,</p>
                  <p>On motion of Col. George W. Wilson, Henry M. Bowyer, Esq., was called to the Chair, and Wm. Robinson appointed Secretary.</p>
                  <p>The Chairman said he understood the object of the meeting was, to give expression to the views of the people of this county in the present condition of public affairs.</p>
                  <p>On motion of Robert R. Douthat, a Committee of seven, consisting of the following gentlemen, viz: T. Henry Johnston, Major John T. Wilson, Joseph W. Anderson, Fletcher H. Mayo, Robert R. Douthat, John G. Rudesell and David G. Houston, Jr., were appointed by the Chair, to prepare business for the meeting.</p>
                  <PageNumber num={349} />
                  <p>Major Wilson asked to be excused from serving on the Committee, only on the ground that he had but recently returned to the county after a long absence; whereupon Rufus Pitzer was appointed in his place.</p>
                  <p>
                    The Committee then retired, when loud and repeated calls were made upon Wm. Watts, Esq., of Roanoke county, who was present, to address the meeting. After some hesitation, Mr. Watts responded to the unexpected call, and in an able and lucid speech of about half an hour rapidly sketched the past and present condition of the country—said he had, until recently, hoped that matters
                    could have been amicably arranged beween the opposing sections, but now he had given up all hopes, and concluded a strong secession speech by declaring that others might do as they pleased—people might enlist under the Black Republican banner who chose to do so—for himself, he would go with no party that holds their principles. If Virginia went North he would go South.
                    [Cheers.] He was for a Southern banner, upon whose folds was emblazoned "equal rights." [Continued cheering.] The committee having now returned, presented to the meeting, through T. Henry Johnston, Esq., its Chairman, the following preamble and resolutions, which he read, and said had been unanimously agreed upon by the committee.</p>
                  <p>Whereas, All means of preserving the Union, consistent with the equal rights and equal honor of all the States, have been exhausted; therefore, be it</p>
                  <p>Resolved, That we, the people of Botetourt, in general meeting assembled, are in favor of immediate secession of the State of Virginia from the Confederacy of the United States.</p>
                  <p>Resolved, That our delegates in the State Convention be, and they are hereby instructed to vote for an ordinance of secession.</p>
                  <p>The Chairman then read the preamble and resolutions, and put the question, and they were adopted by a large majority.</p>
                  <p>Capt. Jacob G. Sperry here came forward, and said, that he believed a large majority of those present were opposed to the adoption of that preamble and resolutions, and charged that there was artifice, cunning, and unfairness, used in voting, &amp;c.</p>
                  <p>Capt. Andrew L. Pitzer desired the gentleman to state in what particular way the charges made by him had been resorted to.</p>
                  <p>Capt. Sperry said he made no particular allusion to any one, but he thought, by the sound, that many of those who voted for the resolution had repeated their vote several times.</p>
                  <PageNumber num={350} />
                  <p>T. Henry Johnson, Esq., wished to know if so many were opposed to their adoption, why they did not vote against them.</p>
                  <p>
                    Dr. S. Meredith called for a division, in order to test the matter; whereupon Capt. Jacob G. Sperry and Rufus Pitzer were appointed tellers, and took their stations at the door, and as each person passed out, he voted for or against the preamble and resolutions. A considerable number remained in the Court House, and did not vote at all. Of the number who went out and voted, the
                    tellers reported—for the resolutions 220; against them 76.
                  </p>
                  <p>On motion that the Chairman and Secretary are entitled to vote, they both voted for the preamble and resolutions.</p>
                  <p>On motion of Col. Wilson,</p>
                  <p>Resolved, That the Secretary furnish copies of these proceedings to the Fincastle and Richmond papers for publication, and that he also forward copies thereof to each of our delegates in the Convention and in the General Assembly.</p>
                  <p>The meeting then adjourned.</p>
                  <p>HENRY M. BOWYER, Chairman.</p>
                  <p>WM. ROBINSON, Sec'y.</p>
                </div>
              </div>
              <p><span className="persName">The PRESIDENT</span> announced the pending question to be the resolution offered by the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , upon which the gentleman from Jackson [ <span className="persName">Mr. TURNER</span>] , was entitled to the floor.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.8.2">
              <h3><span className="headingNumber">1.8.2. </span><span className="head">CHANGE OF HOUR OF MEETING</span></h3>
              <div className="speaker" id="sp1244"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">
                As the call of the House and other matters have taken up seven minutes of time, I shall not now have time to present the views I desire to submit during the few minutes left before we go into Committee of the Whole. In order that gentlemen may have time in the morning to discuss this and other questions before going into Committee of the Whole, I submit the following resolution for
                the adoption of the Convention. It is perfectly apparent that this early hour of assembling does no good. I am perfectly willing to meet in this Convention at any hour gentlemen may please, or the Convention may select, and to attend to the business that may come before us. It is impossible to get a full attendance at 10 o'clock.
              </p>
              <p>The resolution was then read as follows by the Clerk:</p>
              <p>"Resolved, That after to-day and until otherwise ordered, the Convention will assemble at 11 o'clock A.M.; at 12 M., to go into Committee of the Whole; to continue in session until 3 o'clock P.M., at which time a recess shall be taken until 8 P.M., when the Convention will again meet in Committee of the Whole."</p>
              <PageNumber num={351} />
              <div className="speaker" id="sp1245"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">
                There is no loss of time involved by the proposition which I submit. If gentlemen do not desire night sessions, as suggested, the question may be divided. I am seeking to get as much time as possible for the Convention that can be actually used profitably; and that is the object of the motion. If it be deemed advisable to take a recess until four o'clock, I have no objection to such
                amendment. My object is to get one hour in the morning in which business can be done.
              </p>
              <div className="speaker" id="sp1246"><span className="persName">Mr. STAPLES</span>—</div>
              <p className="p-in-sp">I would suggest to the gentleman that in consequence of the paucity of numbers, it would be an improper time to act upon the resolution now.</p>
              <div className="speaker" id="sp1247"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">I will make the motion to lay it on the table for the present, giving notice that I will call it up to-morrow morning.</p>
              <p>The resolution was laid on the table.</p>
              <div className="speaker" id="sp1248"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">The gentleman from Marion [<span className="persName">Mr. E. B. HALL</span>] , desires to make an explanation, and I will give way for that purpose, as the morning hour has almost expired, with the understanding that I still retain the floor.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.8.3">
              <h3><span className="headingNumber">1.8.3. </span><span className="head">PERSONAL EXPLANATION</span></h3>
              <div className="speaker" id="sp1249"><span className="persName">Mr. E. B. HALL</span>, of Marion, said :</div>
              <p className="p-in-sp">
                Mr. President—It was my privilege to occupy the attention of the Convention a few moments on Friday morning last and again on Saturday morning, with some remarks on the resolutions of the gentleman from Monongalia, in the report of which in the "Enquirer" are some palpable errors which I desire to correct. I am represented as saying that the questions growing out of our Federal
                relations should be settled by the Convention before entering upon the question of taxation or other questions pertaining to the organic laws of the State, and that I and those whom I in part represent so desired, and that they had so instructed me; and I am also represented as proposing that a committee should now be raised to report to an adjourned meeting of this Convention upon
                all these matters—taxation included. Now, Mr. President, in both of these points I have been sadly misapprehended. I told our people when I was before them for a seat in this Convention, and they told me, that before we took action upon any Federal question, action should be had, and justice, common justice, done us in reference to taxation and representation, and this was the
                position I took here in my remarks, and take now and ever. Remove this odious distinction, this inequality; this unjust burthen which we have long borne, that we may stand on this floor as your equals, with equal rights, <PageNumber num={352} /> equal protection and bearing equally with you the common burthens of the State; then we will talk with you of
                Federal questions.
              </p>
              <p className="p-in-sp">
                On the second point I will speak of a report to a proposed adjourned session of the Convention (if we are to have one, but which I much desire, if we can, to avoid) . I spoke of it, first, to show that those who tell us to wait till an adjourned session on these questions, refuse to entertain the proposed resolution of the honorable gentleman from Preston, which asked only the
                appointment of committees now to report to an adjourned session—showing how little was asked by that resolution, and yet that little was refused or declined. I also suggested then as to the questions of State reform, other than taxation, that Committees might be now appointed to report then; but the question of taxation is a simple proposition—requires no Committee. And I, in the
                name and on behalf of our people, demand action on that question now. Our people await your action and answer; the burthens are upon us, and we want relief now—not when we secede, or when we do not secede—not when our property shall have paid the millions of our State debt—but now. Treat us as equals, and then you may expect us to co-operate with you in other matters, willing and
                ready to bear our part, and to do what we think should be done. Our people will be ready for your answer, be that what it may—but they will regard postponement, as equivalent to a denial of our rights. I hope I am understood—I know my distance from the reporters, and the confusion incident upon the assemblings of the house, rendered it difficult for them to hear and understand what I
                said—but I hope I am heard this morning.
              </p>
              <div className="speaker" id="sp1250"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The hour has arrived for the execution of the order of the Convention to go into Committee of the Whole.</p>
              <p className="p-in-sp">I have received a note from <span className="persName">Mr. SOUTHALL</span>, Chairman of the Committee of the Whole, stating that he is prevented by indisposition from attending to-day. Yesterday he requested the gentleman from Greenbrier [<span className="persName">Mr. PRICE</span>] to act in his place. Will the gentleman please to take the chair.</p>
              <div className="speaker" id="sp1251"><span className="persName">Mr. PRICE</span> then took the chair. He said—</div>
              <p className="p-in-sp">
                Yesterday when the Committee rose, it had under consideration the report of the Committee on Federal Relations, the amendments proposed, and the substitute by the gentleman from Harrison. The report is now before the Committee. If the present occupant of the Chair understands the question, there is no substitute now pending, and there will not be any, unless some shall be offered;
                the report will be taken up and read, section by section, and passed upon.
              </p>
              <PageNumber num={353} />
              <div className="speaker" id="sp1252"><span className="persName">Mr. AMBLER</span>, of Louisa—</div>
              <p className="p-in-sp">Mr. President, <span className="persName">Mr. HARVIE</span> of Amelia has given notice of his intention to offer an amendment. He is not present, and there is no person here authorized to act for him. I merely wish to call attention to the fact that he intended to offer an amendment.</p>
              <div className="speaker" id="sp1253"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair will remark to the gentleman from Louisa and to the Convention, that if the sections are adopted, they become the action of the Committee and are not subject to any amendment by adopting a substitute for them.</p>
              <div className="speaker" id="sp1254"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">Do I understand the Chair that, if a section of the report is adopted, it precludes the presentation of a substitute for the whole report?</p>
              <div className="speaker" id="sp1255"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It will be the action of the Committee upon that much of the report.</p>
              <div className="speaker" id="sp1256"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">I desire the Chair to give me a distinct answer. I understand the Chair to announce that the adoption of any portion of the report is an exclusion of a substitute for the whole report?</p>
              <div className="speaker" id="sp1257"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The exclusion of a substitute for that part.</p>
              <div className="speaker" id="sp1258"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">The exclusion of a substitute for the whole report, if I understand the Chair correctly.</p>
              <div className="speaker" id="sp1259"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question does not now arise.</p>
              <div className="speaker" id="sp1260"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">No, sir. It was only because the Chair gave notice on its own account, to the Committee, that my attention was called to the point; and the Chair having made that announcement, it was important, as we were to be governed by the action of the Chair, to know what its intention was.</p>
              <div className="speaker" id="sp1261"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Mr. Southall desired the Chair to announce that that was his intention; and I adhere to it—that so much of the report as might be sanctioned by the Committee, could not be substituted afterwards for the whole report.</p>
              <div className="speaker" id="sp1262"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">I do not think the Chair has yet met the question propounded, which is this—</p>
              <div className="speaker" id="sp1263"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman from Clarke [<span className="persName">Mr. NELSON</span>] , is entitled to the floor.</p>
              <div className="speaker" id="sp1264"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">The gentleman from Clarke is not entitled to the floor. I am entitled to the floor on a question of order.</p>
              <div className="speaker" id="sp1265"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] will take his seat. The Chair will state the position of the question. On yesterday evening the gentleman from Clarke [<span className="persName">Mr. NELSON</span>] expressed a wish to address the Committee. At that time the motion before the Committee was not agreed to. He moved that the Committee rise. The question was afterwards taken upon the substitute of the gentleman <PageNumber num={354} /> from Harrison [<span className="persName">Mr. CARLILE</span>] . He finally moved that the Committee rise, with a view, as I understood it, of addressing the Committee this morning. The present occupant of the Chair is of opinion that the gentleman from Clarke is entitled to the floor, and it is accorded to him. The gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] takes an appeal from that opinion, and the question is, shall the opinion of the Chair stand?</p>
              <div className="speaker" id="sp1266"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">
                I would ask, permission to state the question as I view it. The Chair volunteered to give its construction to a rule upon a question not now before the Convention. Being one of those who are to be governed by the construction which the Chair has volunteered to give, I ask for a full understanding of the opinion of the Chair upon that question. Before the Chair gives an answer to the
                question propounded, the Chair rules me out of order. Now I appeal from that decision of the Chair, and I maintain my right to have a full, explicit answer from the Chair, of the course which the Chair proposes to pursue upon a question which may arise, and which has been brought before the Committee by the action of the Chair, and not by my action.
              </p>
              <div className="speaker" id="sp1267"><span className="persName">Mr. CHAMBLISS</span>—</div>
              <p className="p-in-sp">
                I would like to understand this question before I vote. I don't understand that because a gentleman moves an adjournment, that gives him a title to the floor as a right. In parliamentary bodies it is a matter of courtesy, and a matter of courtesy alone, that the gentleman who moves an adjournment has the privilege of being heard when the body assembles again. If I am called upon by
                my vote to decide that this is a question of right, and not of courtesy, I cannot say that the gentleman from Clarke would be entitled to the floor as a matter of right, however much he might be entitled to it as a matter of courtesy.
              </p>
              <div className="speaker" id="sp1268"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question is, shall the opinion of the Chair stand?</p>
              <p>The question was taken, when the decision of the chair was sustained.</p>
              <p><span className="persName">Mr. NELSON</span> of Clarke, then took the floor, but gave way to <span className="persName">Mr. WM. B. PRESTON</span>, who addressed the Convention for over an hour. His speech will appear hereafter.</p>
              <div className="note" id="Note34">
                <span className="noteLabel">1</span>Mr. Preston's speech has not been found in the Enquirer. On March 27, the Daily Dispatch published this abstract of the proceedings covering Mr. Preston's speech:
                <p><span className="persName">Mr. NELSON</span> then yielded the floor to <span className="persName">Mr. PRESTON</span>, of Montgomery, who proceeded to address the Committee.</p>
                <p>
                  After alluding to the great responsibility resting upon him as a member of the Convention he went on to consider the practical issue now before the country. For years there had been a regular course of sectional hostility, which excited the apprehensions of all of us. The election of last November resulted in a manner that shook the Commonwealth to its very centre, and the people
                  took the necessary measures for meeting the crisis, and had sent a Convention here clothed with powers to make and unmake. The aggressions upon the South were no longer to be borne, and he for one was ready to repel them. Virginia, on the 4th of February, was not ready for disunion, but she took a prompt and decided stand against any measures of coercion or force. When we met here,
                  the choice and purpose of Virginia was to have proper amendments to the Constitution, with a view to restore the Union upon a basis to secure permanent protection to her rights. It was further her purpose, in case no sufficient guarantees can be secured, to withdraw from the Union, and protect herself out of it. What was the proposition of the committee? If these permanent
                  guarantees cannot be secured, then, thank God, we have a power to secure our own rights by withdrawing from the Union. He then alluded to the determination of the Commonwealth to stay all impediments by way of coercion on the one side or precipitation on the other. While the representatives of the people could deliberate with the calmness of peace, and if possible restore the
                  Union. The Convention interposed its moral power—saying to the Federal Government, you must not use measures to coerce those who have seceded from the Union; you must not possess yourself of the forts or the commerce. She said to her sister, South Carolina, let there be no war; it would be hurtful to those who are deeply interested in your institutions, but are not so impulsive,
                  and want time to reflect. They had paused. The Government had acknowledged its inefficiency, and we were not now in the danger that we had previously been. He could not say how long it would be so, though he hoped it might be permanent. But we were here to-day, with no external cause for alarm, and enabled to deliberate calmly upon all subjects of agitation.
                </p>
                <p>He could not agree with those who said this was the moment to secede. It was the very time when we ought to go to work to carry out the wishes of the people, as expressed at the polls on the 4th of February last.</p>
                <p>Proceeding to discuss the propositions of the committee, he said there must be thorough and efficient guarantees of equality in the Government, and of protection in regard to the institution of slavery. He asked his friend from Albemarle [<span className="persName">Mr. HOLCOMBE</span>]
                  if, when those guarantees were secured, we should not have done all our constituents required? Guarantees of principle, and guarantees of power were the covenants to be considered. He believed that physical guarantees were not demanded. The guarantee of Magna Charta was at this moment stronger than ever before. Where was the guarantee, except by amendments of the Constitution, that
                  had been for a moment thought of? He alluded to the exceptions of the dual Executive suggested by Mr. Calhoun, and to the dual Judiciary foreshadowed by Mr. Hunter, as checks to be interposed.
                </p>
                <p>He asked how such checks were to be enforced on a party that recognized no influence of moral power? The false key of the higher law would still be used to unlock the casket and steal from it its treasures. Whenever any gentleman—the gentleman from Princess Anne or the gentleman from Albemarle—could show anything tangible, he would be ready to accept it.</p>
                <p><span className="persName">Mr. WISE</span> protested against being mingled with these dualities. He would say to the gentleman that a human head had been thinking of these guarantees of power, and at a proper time would show how they could be made practicable.</p>
                <p><span className="persName">Mr. PRESTON</span> said the gentleman from Princess Anne stood alone on his own platform. He was too slow in the enforcement of his guarantees of power.</p>
                <p><span className="persName">Mr. WISE</span> said he was obliged to be slow, for he had very heavy weights to drag after him. [Laughter.] <span className="persName">Mr. PRESTON</span> thought it would be better to press forward with his own burden, and leave others to take care of themselves. He [<span className="persName">Mr. PRESTON</span>]
                  could see no efficiency in any guarantees, except by suitable amendments to the Constitution. The first section of the committee's report, he conceived, covered the question of exact equality, and the North must respond aye or no to it. If the response is "aye," you have a guarantee of faith for the full and ample protection of the institution in which we are vitally interested.
                  The speaker continued to discuss in a general manner, and somewhat in detail, the propositions of the committee, taking ground similar to those who had preceded him in the advocacy of the report.
                </p>
                <p>
                  After pointing out the efficiency of the remedies proposed, he said the cry of "inadequacy" still came up; we still heard of the deep-seated hatred and sectional hostility existing at the North. He asked if we were to get rid of this hostility by flying from it. Due weight, he thought, should be given to the conflicting passions in Congress, which had given an impulse to sectional
                  hostility. The slave question, he argued, was at the bottom of it all, but if this cause of agitation could be cut off, much would be done towards restoring the friendly feelings of the two sections. Everything was favorable now to a submission to the demands we were to make of the North. It was not to be made in a crouching or an humble attitude; but we were to make, for the first
                  time, an appeal to the Northern people—not to the politicians; and if they were not granted within a reasonable period, we separate from our brothers of the past, and separate forever. This was his idea of true chivalry—to make of them a firm demand, not in the language of bravado; but to say to them, if you accept, well; if you do not accept, well. Recurring again to the report,
                  he read the demands therein contained. The test is thus proposed to the North to be voted upon. If they say "aye," said <span className="persName">Mr. PRESTON</span>, I will accept them as a repentant people. Everything, he thought, was auspicious for a full, clear, and unequivocal settlement. They have now an empire, but sit with a barren sceptre in their hand. Reason will regain its
                  sway, and Virginia's demands will meet a favorable response. Dishonor, disgrace, and overthrow is now upon them. Their counsels are distracted
                </p>
                <p>and their party divided. The Government is hesitating and tottering in its own weakness, under the certain doom which this Convention pronounces, unless those demands are submitted to which will restore the prosperity of the whole country.</p>
                <p>
                  The propositions are to be sent to the North immediately upon the adjournment. The Conference at Frankfort was not to be entrusted with the settlement of the matter. This power belonged to the sovereign people of Virginia. He had come here for the purpose of carrying out their will. If it became necessary to divide, after a full and fair consultation with the border slave States,
                  then we could go out peacefully and quietly. Although the propositions presented might not be so full as he could desire—if anything better came up, he would be ready to accept it—he still thought they were the best we could do. He came from the West, and in the name of his loyal people was prepared to make a demand for full security of their rights. He did not conceive it to be
                  his duty to consult the wishes of the North in making demands, but the wishes of the people of Virginia. He urged gentlemen not to say, where Liberty is, there is my country; but say where my home is, there shall Liberty be.
                  [Applause.]
                  He closed with an appeal to all to stand by the Commonwealth, and to vindicate every right that belongs to her. His ancestors had fought and died in her defence, and with the help of God this should be his destiny. If, said he, I fall elsewhere, I ask to be brought back to the consecrated earth of his mother Virginia—to be buried in his own meadow. Then it may be said of Ballard
                  Preston, "after life's fitful fever he sleeps well." He would go to the North or to the South, or to the judgment seat of God, in vindication of the rights of Virginia.
                  [Applause.] <span className="persName">Mr. WISE</span>, of Princess Anne, next addressed the Convention. After an allusion to the eloquence of the gentleman who had preceded him, he said he did not feel as if he was standing alone. The gentleman stood near enough to call him his own, with the gentleman from Halifax [<span className="persName">Mr. BRUCE</span>] . He then went on to discuss the propositions, which he contended not only provided for dual, but triple guarantees of power.</p>
                <p><span className="persName">Mr. PRESTON</span> conceded this, but said the gentleman had shown the justness of his argument by pointing out the guarantees demanded by amendments to the Constitution. <span className="persName">Mr. PRESTON</span> reiterated the argument which he had made.</p>
                <p>
                  <span className="persName">Mr. WISE</span> proposed to show that there were other guarantees of power, which the committee had not made, but which were perfectly practicable. He would say to the gentleman, in the friendliness of a brother, that if he could see no other tangible guarantee, he had reached an immedicable condition. Was the privilege of catching a runaway negro nothing?
                  Was the privilege of going to the Territory of Kansas, with your property, nothing? They proposed to pay for fugitive slaves; but money was no compensation for the surrender of a principle. The Northern people were in league with the fanatics of Exeter Hall, in endeavoring to destroy our institutions. Was there no remedy for that?
                </p>
                <span className="persName">Mr. PRESTON</span> re-stated his position. He had said that no guarantees had been proposed on the freedom of the press and freedom of speech; if they were proposed, he would be ready to accept them. He did not stand with the gentleman, but if he had such guarantees he would like to have them.
                <p><span className="persName">Mr. WISE</span> had none to propose.</p>
                <p><span className="persName">Mr. PRESTON</span> said he thought he had them in his breeches pocket, and that he was about to be called on to accept them. He [<span className="persName">Mr. P</span>.] proposed to quiet agitation by removing it entirely from the halls of Congress.</p>
                <p>
                  <span className="persName">Mr. WISE</span> said that the whole effort was then to be given up; that acknowledging they could not still the agitation, they could only stand by and cry "hush." He had, in his report, made fifteen specifications, eleven of which he believed were entirely practicable. He would make this appeal to the North, and if they did nothing, he would stand with the
                  gentleman and fight and die upon our own soil. No host of Yankees could ever drive him from his hearthstone.
                </p>
                <p><span className="persName">Mr. PRESTON</span> made a brief rejoinder. The difference between the gentleman from Princess Anne and himself was that he [<span className="persName">Mr. W</span>.] had no hope, while he had hope from both sections, and would never give up until time and blood shall set a seal upon the efforts in behalf of the Union.</p>
              </div>
              <p>
                <span className="persName">Mr. NELSON</span>, of Clarke, then obtained the floor, and spoke until the hour arrived for the Convention to take a recess.<span className="note" id="Note35"
                ><span className="noteLabel">2</span>Mr. Nelson's speech was printed as a continuous whole, with no indication of the point at which it was interrupted by the recess. The entire speech has been put in the Proceedings of the morning session.</span
                >
              </p>
              <div className="speaker" id="sp1269"><span className="persName">Mr. NELSON</span> said:</div>
              <p className="p-in-sp">
                Mr. Chairman, under any circumstances, it would require all the moral courage which I possess to rise and address this body; for my pursuits and avocations for the last twenty years, have led me far from <PageNumber num={355} /> the cultivation of the arts of oratory; during that period my only cares have been to cultivate and adorn that small portion
                of this good Old Commonwealth which has fallen to my lot. But, sir, at this time, after listening to the lofty and impassioned eloquence of my friend from Montgomery, I am more than ever embarrassed. "For what can the man do who comes after the king?"—and nothing but a sense of the duty which I owe to the people whom I have the honor to represent, could nerve me to make the attempt.
              </p>
              <p className="p-in-sp">
                Sir, I have the honor to represent a county which pays a larger tax in proportion to her aggregate population, than any county in the State, with the exception of the county of Jefferson and those counties which have large towns or cities—and which is also the largest slave-holding county in proportion to her white population, west of the Blue Ridge. I have the honor to represent
                that county, sir, which was the birth-place of the "Hero of the Cowpens." I represent a people, sir, who, whilst they are loyal to the State of Virginia and to this Union, "know their rights, and knowing, dare maintain them." Sir, there are swords there "ready to leap from their scabbards"—there are still unerring rifles there ready to be directed with deadly aim, against any and all
                enemies; but, sir, we remember the order of the old hero, and we mean "to wait till we see the whites of the enemy's eyes." And, sir, in their behalf I claim the right to present and to advocate before this body the sentiments which I, and I believe a majority of those whom I represent, hold on the great questions which now agitate the country. Sir, I may be behind this fast age; but
                I do not belong to that school of modern political philosophers who believe "that the Constitution is effete and gone to seed." I do not believe that any ten men in any parlor, or in all the parlors of Virginia and the United States together can make a better. I do not hesitate to say, that I am one of those who still cherish a fond attachment to this Union, and who still believe
                that the Constitution of the United States, if administered according to the spirit and intention of its framers, is the best government, for our people, that the world has ever seen. Sir, even those who are most dissatisfied with it—the Southern Confederacy—have adopted it in its essential features as their form of government, at least for the present. Nor, sir, have I heard any
                complaint against the administration of the government.
              </p>
              <p className="p-in-sp">
                In all the great crises which, up to the present time, have occurred at regular alternate periods, of ten and twenty years, since the foundation of the government, some of which have shaken it to its foundation —either the passing by Congress of laws considered by some as unconstitutional, <PageNumber num={356} /> or the want of law to carry out some
                constitutional provision, has been the cause of complaint. In 1798-9, ten years after the formation of our government, what were called the Alien and Sedition Laws were complained of, and were the cause of the passage by the Legislature of Virginia of the famous resolutions of '98 and '99.
              </p>
              <p className="p-in-sp">
                In 1819-'20, the refusal to admit Missouri as a slave State, gave rise to a serious disturbance, which was finally settled by the admission of Missouri, and the passing by Congress of what is called the Missouri Compromise—from 1830 to 1833, what was called the Black Tariff of 1828, was near proving a cause of a disruption of this Union, similar to the present. You all know, Mr.
                Chairman, how that difficulty was settled. In 1850 the want of a law to carry out the fugitive slave provision of the Constitution gave rise to a struggle which had well nigh proved fatal to our government. This was settled, as you all know, by the Compromise of 1850.
              </p>
              <p className="p-in-sp">
                In the present crisis then, as I have said, there is really no complaint against the Constitution itself, which cannot be remedied by amendments declaratory of its intent and meaning, which I believe we can obtain with regard to the administration of the Government. The Executive Department is charged with no usurpation. The Legislative Department—the Congress—has passed no
                unconstitutional laws interfering with any man's rights or liberties, nor has the Judiciary Department of the Government, by any decision, interfered with our rights in the territory or elsewhere; on the contrary they have decided that the territories are a trust fund for the benefit of all the States. And if I understand that decision, the slaveholder is entitled to protection in
                every part of the common territory during its territorial condition.
              </p>
              <p className="p-in-sp">
                What then are the true causes of the present crisis in our affairs, and for which we are asked to break up this Government purchased at the price of so much treasure and blood of our patriotic ancestors, and bequeathed to us with the solemn injunction that we should cherish it as the "palladium of our political safety and prosperity; the main pillar in the edifice of our real
                independence; the support of our tranquility at home, our peace abroad; of our safety, our prosperity, and of that very liberty which we so highly prize"? They are, as I consider,
              </p>
              <p className="p-in-sp">1st. The passage of certain laws by some of the non-slaveholding States, called the Personal Liberty Bills.</p>
              <p className="p-in-sp">2d. The alleged intention of the majority in power, to exclude our peculiar institution from the common territory; and</p>
              <p className="p-in-sp">3d. The fanatical spirit which prevails in the non-slaveholding States, which threatens ultimately to destroy that institution where it now <PageNumber num={357} /> exists—and this spirit as exhibited, in the election of a President, on a sectional platform, with the avowed purpose of its ultimate extinction.</p>
              <p className="p-in-sp">
                Is there no remedy, Mr. Chairman, for these evils? "is there no balm in Gilead?" is there no physician there? That is the question which we have to consider and to decide in this Convention. If there is no means of adjusting these difficulties, short of breaking up this Union, I, for one, will have no conscientious scruples in disrupting all the bonds which unite us. But, sir, I am
                for adopting that means, which, whilst it will secure our rights, will best comport with the interest and the honor of Virginia.
              </p>
              <p className="p-in-sp">
                The only remedy proposed by what we call the ultra party is immediate secession. Sir, what will be the effect of secession on the first of these evils, the escape of our slaves? Sir, we who live on the border of the slaveholding States are accustomed to take a practical view of this question, and are much less excited on this subject than those who live at a distance from the border,
                and who reason abstractly upon it. We know that, notwithstanding the Personal Liberty Bills, we lose comparatively few of our slaves since the passage of the fugitive slave law. Before that law was passed, they were leaving my county by tens and twenties at a time. Since 1850, notwithstanding the Personal Liberty Bills, we lose, as I stated, comparatively few. Sir, when I consider
                that secession will not move Virginia one foot further from Pennsylvania and Ohio, but will, in effect, repeal the fugitive slave law and place a foreign country close upon us, I cannot but conclude that secession, so far from remedying this evil, will increase it. I am aware, sir, that that the honorable Commissioner from Georgia proposed to surround us by a "cordon militaire" to
                prevent our slaves from escaping across the border. But, sir, I think there are many serious objections to that system. I think that, like the Indian's gun, "it will cost more than it will come to." Sir, it is as much as I can do to pay one overseer to attend to my farm; but if I have to pay soldiers to watch around it to keep the negroes from running off, I should soon have to sell
                both farm and negroes to pay the costs. And, in the second place, I think it will be ineffective. Now we can pursue our slaves across the border and recover them. When the border States become a foreign territory, neither you nor your soldiers can go one foot across the line in pursuit of your fugitives. And, if the interest of those States in the preservation of this Union will not
                induce them to give proper guarantees, think you that you can induce them to restore your slaves, who escape, by treaty stipulations? Sir, I think not.
              </p>
              <p className="p-in-sp">
                Will secession secure us our rights in the territories? According to the <PageNumber num={358} /> theory of secession as I understand it, so far from securing our rights there, we abandon, for a time at least, the very territory we are contending for, and leave it all in the possession of those States which remain in the Union. I do not contend, sir,
                that we would abandon our equitable claim, yet the only remedy left to us would be to obtain it, either by war or by treaty, and I, for one, am free to confess that I think we can treat better in than out of the Union. For the present at least.
              </p>
              <p className="p-in-sp">
                Now, sir, as to the third cause of complaint. Whilst I do not consider that the election of any man to the Presidency of the United States, according to the forms of the Constitution, is a sufficient cause, of itself, for breaking up this Union, yet if Mr. Lincoln unyieldingly persists in carrying out that spirit of fanaticism and that policy which will not only deny us our rights in
                the common territory, but must result in the ultimate extinction of slavery in all the States, much as I love this Union, I, for one, am prepared to say "away with it." I, sir, am prepared, at any cost, to sever every bond which binds us together. And in view of this, I say, sir, that the time has now come for us to settle definitely and forever this vital question. Yet, sir, when I
                remember the large number of our fellow-citizens of the non-slaveholding States who have nobly stood up for us and gallantly fought on our side; when I remember that of the 3,400,000 votes nearly 1,600,000 were cast against the Republican candidate, and even of the 1,800,000 who voted for Mr. Lincoln many voted on other issues than those of Black Republicanism, I am not disposed to
                embrace them all, both friends and foes, in one common anathema. I will not call those enemies whom I know to be our warm friends; I will even dare in this presence to call them friends. Nay, sir, I cannot but hope that we shall not appeal in vain to the great conservative element of the North, to award us our just rights without an appeal to the ultima ratio regum. I would have this
                Convention to present to the non-slaveholding States clearly and distinctly our ultimatum of adherence to the Union. I have already indicated those conditions in the resolutions which I offered to this body on the 20th of February.
              </p>
              <p className="p-in-sp">These resolutions I think the report of the majority of the Committee on Federal Relations, substantially contains.</p>
              <p className="p-in-sp">
                This ultimatum I would present in the calm and dignified language of settled purpose. If it be heeded, the tide of fanaticism rolled back, peace and harmony restored, this great republic, returning to its ancient usages, acting within the scope of its constitutional limitations, will go on to illustrate the grand theory of popular sovereignty, and to
                <PageNumber num={359} /> perpetuate the great blessings of liberty, prosperity and happiness to us and to our posterity. If, unfortunately, it should be unheeded, then, conscious of having done all that forbearance can do, wisdom suggest, or patriotism demand, to save from destruction this glorious Union, we will—nay, of necessity we then must—withdraw
                from a Confederacy no longer compatible with our interest or our honor.
              </p>
              <p className="p-in-sp">
                But, Mr. President, the question is asked on the other side, in case the Gulf States will not come back into this Confederacy will you go with the South or with the North? Do gentlemen forget or mean to ignore the border Slave States? Sir, I will not ignore them. They are a mighty empire, embracing within themselves all the elements of greatness and power—they contain at this time a
                population double that of the Gulf States, and I, for one, Mr. Chairman, am prepared to say that if I can get such constitutional guarantees as will be satisfactory to Virginia and the border Slave States, and which ought to be satisfactory to the Gulf States, much as I will deplore a separation from them, though they will have my strongest sympathies and my best wishes for their
                prosperity, I will not consent, as far as my humble influence can effect it, to take Virginia out of this Union, and in this my action I shall be guided solely by what I think will be for the interest of Virginia. For, sir, of all the stars upon our national flag, the star of Virginia "is the bright particular star" which fills my vision. To her I owe my first allegiance; to her I am
                bound by the strongest ties. Sir, if any man can have a birth-right in Virginia, I have one. All my ancestors, for near two hundred years, have lived and died in Virginia. Sir, I cannot say, with the eloquent gentleman from Kanawha, that I never left Virginia. Stern necessity, sir, once compelled me to leave her border—I felt like an exile from my native land —I thought of her by
                day, I dreamed of her by night. When laid upon the bed of sickness, in the delirium of fever, I was singing "carry me back to Old Virginia." I never breathed freely till I got back within her bounds. Yes sir, the memories of the dead enter into my love of Virginia; the potent associations of my childhood bind me to her—all the joys and all the griefs of my manhood have daguerreotyped
                her on my heart—and I can say, as Mary of England said of Calais, when I am dead, take out my heart and you will find Virginia engraved upon it. May she be my home through life, and when I am dead, may my ashes repose within her soil.
              </p>
              <p className="p-in-sp">Mr. President, whilst I would not say one word to wound the feelings of our Southern friends, I must say that I think that neither <PageNumber num={360} /> one nor all the grievances which I have enumerated, are the real causes of their seceding at this time.</p>
              <p className="p-in-sp">
                For years past the Southern States have been laboring under a chronic excitement, produced by what I consider a mistaken view of a question of political economy, and that is, that the South is burdened with the larger portion of the duties paid to support the General Government. Sir, it has been a long time since I have read Adam Smith's essay,<span className="note" id="Note36"
                ><span className="noteLabel">3</span>Mr. Nelson corrected this allusion in the Proceedings of April 1, page 751.</span
                >
                and other works on political economy, but if I remember well, the theory used to be that the consumer paid the duties on imports. Now, sir, I would like to know who consumes the larger portion of the articles imported into the United States? The 19,000,000, who inhabit the non-slaveholding States, or the 9,000,000 who live in the slaveholding States? Sir, I had almost said there are
                more of them consumed in the city of New York, than in all the Gulf States together.
              </p>
              <p className="p-in-sp">
                Sir, the fact is, that although the South is the great producer of cotton, yet, by the time they pay for the bacon, corn and mules of the West, the clothing for their negroes, which is mostly manufactured at the North, and the other Yankee notions which they purchase, they have comparatively little left. Like Triptolemus Yellowlegs, in one of Scott's novels, "The carts and the cart
                horses make it all, but the carts and the cart horses eat it all."<span className="note" id="Note37"><span className="noteLabel">4</span>Mr. Nelson corrected this reference in the Proceedings of April 1, page 751, and again in the Proceedings of April 3, page 56.</span> And the statement which my friend, the honorable gentleman from Halifax, made yesterday, of the result of his farming or
                rather planting in the South, confirms this view which I have presented. Sir, it always will happen that those who sell the raw material, and purchase the manufactured article, will never make as much profit as those who, by their labor and skill, convert the raw material into the manufactured article. Sir, in proof of this, I beg leave to refer to a speech made in the House of
                Representatives, March 30th, 1858, by the Hon. J. M. Sandidge, of Louisiana. In that speech Mr. Sandidge undertakes to show how much richer the South is than the North; and he commences his speech by saying figures cannot lie. I have combined his figures in a different manner, and bring out, as you will see, a very different result. In that speech he shows, from the census tables of
                1850, that the North had a deficiency in 1850 of six millions of agricultural products (I give the round numbers) , whereas the South had a surplus of $125,000,000. The South had to purchase from the North that year $103,000,000 worth of manufactures, to make up her deficiencies, which left the South only $22,000,000, whereas, the North that year, after paying the South $6,000,000,
                had $97,000,000 in her favor, which added to eighteen million dollars worth exported, <PageNumber num={361} /> gave the North that year a surplus of $115,000,000 on her manufactures alone. And, sir, when you add to that what she made by her commerce, you can very readily see how the North is really richer than the South, or at least has more money to
                expend in the luxuries of life, and therefore pays a large proportion of her duties in imports. Sir, it seems to have been ordained by Providence that ever since man was driven from the Garden of Eden, and the decree went forth, "by the sweat of thy brow, shalt thou eat bread," that purely agricultural people of all countries, though I believe them to be the happiest portion of the
                human family, are as compared to the commercial and manufacturing people, poor—nay, sir, are the "hewers of wood and the drawers of water for the rest of the world." Sir, the history of the world from the time whereof the memory of man runneth not to the contrary, would bear me out in this assertion. What made Tyre the "crowning city," whose "merchants were princes," whose
                "traffickers were the honorable of earth;" "the merchants of Sidon replenished her treasures," "the harvest of the river was her revenue, and she was the mart of nations."
              </p>
              <p className="p-in-sp">
                What made Babylon "abundant in treasures"? She is represented as "dwelling on many waters." In later times, what made Athens and Corinth so rich, that in the times of Pericles, the former was built of marble—and the latter so expensive that none but the rich could visit her—whilst the other portions of Greece, much more productive, were comparatively poor? Even Rome, herself, with
                all her conquests, was comparatively poor, till she engaged in commerce and manufacturing.
              </p>
              <p className="p-in-sp">
                Coming down to later times I might point you to the Italian cities in the middle ages, magnificent and wealthy, whilst the rest of the world was steeped in poverty. What made the cities of Belgium and Holland to attain a height of almost fabulous wealth in the time of Charles the Fifth, whilst Spain, the seat of monarchy, was comparatively poor; and England had scarcely emerged from
                barbarism? And, sir, at this day what makes England so great and powerful? I answer, sir, her commerce and her manufactures, combined with her agriculture.
              </p>
              <p className="p-in-sp">
                Sir, Agriculture is said to be the nursing-mother of the arts and sciences, but, like some other nursing-mothers, she is often poor while the children are the reverse. And, hence, sir, I assert, without fear of contradiction, that it is an universal law of nature, that any country which depends upon agriculture alone, however rich and productive its soil, will, as compared to
                countries which combine manufactures and commerce with agriculture, be poor—and that wealth, monied wealth <PageNumber num={362} /> I mean, will accumulate at the great commercial centres in spite of all efforts to prevent it. From these facts, sir, I argue that the Northern or manufacturing States, are really more interested in the institution of
                slavery than we of the South, because they receive a greater portion of the profits than we do, and that in fact, we really manage our estates for their benefit; and when they awake up as I think they are fast doing, to see this (for no people have a keener sense of their interest, than the people of the North), I think their interest, if not their sense of justice, will force them
                to award us all our just rights.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, my friend from Halifax undertook, yesterday, the stupendous task of estimating the value of the Union. Sir, if any gentleman can do that, I am sure that gentleman can, and I listened to him with great interest. But, sir, to my great disappointment, the gentleman only presented the dark side of the picture. Sir, I could not help thinking, that if he had spoken for one
                hour longer in presenting the bright side, as he could have done so well, it would, according to his own prescription, have been a sovereign antidote to those dyspeptic symptoms with which he seems to be afflicted.
              </p>
              <p className="p-in-sp">
                Sir, would you break up this Union because there are acknowledged evils in our system of government? Is there any human institution which is unattended with evils? Is it, sir, an easy matter to make everything in the actual world conform to the ideal pattern which we have conceived in our minds, of absolute right? Sir, I would ask the gentleman if he is really of the opinion that
                this government has been a source of evil, and only of evil, to Virginia? Sir, the gentleman tells us that in the Southern Confederacy Virginia will gradually become a manufacturing State, with a duty of 5 to 10 per cent in her favor. Sir, if the Northern States, with all their capital, with their manufactories built and in operation, and with their dense population, can scarcely
                keep them going, with a duty of 20 per cent in their favor and only England to contend against, do you think that Virginia, with her sparse population, and without capital, can build up and sustain manufactures with a duty of only 5 per cent in her favor, with both England and the Northern States her competitors? Sir, any man who knows anything of manufactures, knows she could not.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I listened with great pleasure to the beautiful and eloquent philippic of the gentleman from Albemarle on Mr. Seward. I agree with him in the sentiment that posterity will hold Mr. Seward to a strict accountability for the hatred he has so persistently excited at the North against us and our institutions; but, sir, is there not danger that the gentleman himself and
                those who are acting with him, will <PageNumber num={363} /> contribute largely, by the course they are pursuing, to produce a reciprocal feeling of hatred on the part of the South towards the North, by their indiscriminate anathemas against the whole people of the North, the innocent as well as the guilty?
              </p>
              <p className="p-in-sp">
                The gentleman quotes largely from the famous Frenchman De Tocqueville. Sir, I can tell the gentleman that no Frenchman can by any possibility appreciate our system of Governments, and that every French writer on Governments, from Montesquieu to De Tocqueville, confounds Democracy with Agrarianism; for with the French, Democracy and Agrarianism are synonymous terms.
              </p>
              <p className="p-in-sp">Napoleon the First, who understood the French character perfectly, said long years ago "Les Francais aiment mais ils ne se soucient pas de la liberty." "The French love equality but they don't care for liberty."</p>
              <p className="p-in-sp">
                Mr. Chairman, I hope we have not yet passed our golden age; but, judging by the warlike sentiments I have heard on this floor, I think we certainly have arrived at the heroic period of our history. There is the gentleman from Bedford, whose voice, like that of Sempronius, is still for war. There are the gentlemen from Prince Edward and Mecklenburg like Horatius and Spurius Lartius at
                the bridge, ready to risk their lives in their country's defence. There is the gentleman from Princess Anne, ready to leap, like another Curtius, horse and all, into the yawning gulf ; and last, though not least, even my venerable friend, my Christian brother from Orange and Greene, was willing, like the Horatii and the Curatii to engage in a patriotic duello with Mr. Seward to
                decide the question. Sir, if that mode of deciding contests is ever justifiable, it surely would be in such a case. Sir, gentlemen on this floor have intimated that even the ladies of Virginia are far ahead of the Union party of this body in their zeal for war; and I have heard it stated, that in one county the ladies had held a meeting and resolved, that they would come here and
                teach "our hands to war and our fingers to fight." Sir, when I heard this I could not help exclaiming in the words of the Poet, "Tantæne iræ animis cælistib."<span className="note" id="Note38"><span className="noteLabel">5</span>Mr. Nelson corrected this quotation in the Proceedings of April 1, page 751, and again in the Proceedings of April 3, page 56.</span>
              </p>
              <p className="p-in-sp">
                Sir, I have read in history of females who have distinguished themselves on the battlefield, but they were individual cases. The only community of female warriors of which we have any account, is far back in the world's history, and I believe that is a myth. They are represented though as "strong minded women," called Amazons, and they were brave and independent of men. But, sir,
                from the very nature of things there was only one generation of them.
              </p>
              <PageNumber num={364} />
              <p className="p-in-sp">
                Mothers, wives, sisters of Virginia! I doubt not that when your sons, your husbands, and your brothers are called to the battle, like the Spartan mother you will tell them "to return with their shields or to return on them." But when they are brought back to you in the cold embrace of death, will it assuage your grief to reflect that you have urged them on to an unnecessary contest
                in a deadly civil war? Will you call me coward when I tell you, that like Lucius, "my thoughts are turned on peace"—because, when the war does come, those who are dearer to me than my own life, my wife and children, will be much nearer to the seat of war, much more exposed to its dangers than you will be.
              </p>
              <p className="p-in-sp">
                I come from the banks of the sparkling Shenandoah, "Daughter of the stars," as its name imports. I live within a day's march of the Thermopylae of Virginia. That valley, now beautiful and peaceful "as the Vale of Tempe, may be a very Bochim—a place of weeping." Those green fields, where now "lowing herds wind slowly o'er the lea," may become fields of blood. Can you blame me, then,
                if I wish to try all peaceful means, consistent with Virginia honor, of obtaining our rights, before I try the last resort? I promise you, when the contest does come, if come it must, the people whom I have the honor to represent on this floor, will meet it like men. I hope in that event, I shall not be wanting in my duty. If I know myself, I will try and not disgrace that commission
                which I hold, which was presented to me by the gentleman from Princess Anne, when Governor of the State. When Virginia spreads her broad banner to the breeze and gives me my orders, no Mahomedan ever followed the sacred Banner of the Prophet with greater zeal than I will follow her standard. I hope, if need be, "I'll follow it to the death."
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I have said, that my sympathies are with the South—and I, for one, will never consent for the Government of the United States to attempt to coerce them, either directly or indirectly. Sir, if we can get such Constitutional guarantees as will be satisfactory to Virginia, and ought to be so to them—as I think we can—I would entreat them to return to this Union. Sir, I
                hope, nay, I have an abiding faith, that they will return. I pray to God that the time may not be far distant when all our difficulties will be adjusted, and we shall again be a united, prosperous and happy people. I think, Mr. Chairman, that Virginia, by the noble sacrifices and successful efforts she has from time to time made, for the formation and preservation of this Union, has
                well earned for herself, the proud position of a great <PageNumber num={365} /> pacificator. I trust her voice will again be potent to still the troubled waves, and that the North and the South will listen to that voice.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, philosophers say that every star which spangles the Heavens is a sun around which systems of worlds revolve—that there is a point in the universe which is a common centre of gravity to all these systems, and that point is one of the stars in that beautiful constellation, called the Pleiades or the seven stars. Sir, I wish to stand under my country's flag with all its
                stars and all its stripes. I want the Pole star there—I want the Southern Cross to shine out brightly there. But should the Pole star retreat far into Northern regions, and veil its face in icy clouds—should the Southern Cross shoot madly from its sphere—I want Virginia to be that great central point in our political system, by her unfettered influences, to draw them all back, to
                revolve once more in harmony in their accustomed orbits.
              </p>
              <div className="lg">
                <div className="l">"God bless my native land!</div>
                <div className="l">Firm may she stand</div>
                <div className="l">Through storm and night;</div>
                <div className="l">When the wild tempests rave,</div>
                <div className="l">Ruler of wind and wave,</div>
                <div className="l">Do thou my country save!"</div>
              </div>
            </div>
            <div className="section" id="vsc1965.v2.2.8.4">
              <h3><span className="headingNumber">1.8.4. </span></h3>
              <p>EVENING SESSION</p>
              <p>The Committee assembled at four o'clock.</p>
              <p><span className="persName">Mr. NELSON</span> concluded his remarks, when</p>
              <p><span className="persName">Mr. WILSON</span> of Harrison, obtained the floor and addressed the Committee.</p>
              <div className="speaker" id="sp1270"><span className="persName">Mr. WILSON</span> said :</div>
              <p className="p-in-sp">
                Nothing, Mr. Chairman, would induce me to occupy the time of the Committee but the sense of duty which I owe to my constituents. In these times of peril and danger, it becomes us before we act to look well to the circumstances by which we are surrounded. It may be well to consider the consequences which may result from our action. We are, in the language of the distinguished
                gentleman from Kanawha
                [<span className="persName">Mr. SUMMERS</span>]
                , in a new era. We are now passing through another and fiery ordeal. Another blow has been struck at American institutions in their onward march to greatness and power. Our country is jeopardized, imperiled—it is reeling and staggering like a drunken man; and growing out of its endangered condition this Convention, clothed with extraordinary powers, has been called. Our duty is to
                administer to the relief of the Government, to heal, if possible, the breach and prevent the country from being precipitated into the deep abyss which lies before us, to save it, if possible, from downfall.
              </p>
              <p className="p-in-sp">
                In order, Mr. Chairman, that we may correctly understand the <PageNumber num={366} /> power with which this Convention is clothed, it may not be improper to consider, for a moment the formation and character of the Government, and then look to its present condition. Man is a social being. and in a state of nature is possessed of no higher or superior
                rights than the brute creation. In that condition might is his right and the strength of his arm the measure of his redress. But, being endowed by his Creator with reason and a capacity for social enjoyment and self-government, from necessity and in accordance with his nature, he !ntered into a compact for the formation of society; by that compact each individual citizen surrendered
                to the aggregate of society, some of his natural rights and privileges, and in return therefor received the guarantee, that he should be protected in the enjoyment of his life, liberty and the pursuit of happiness.
              </p>
              <p className="p-in-sp">
                Looking back to the early history of this continent, we find the original thirteen Colonies were chartered by England as separate Colonies, in which condition they remained until the establishment of American independence. The resolution of the Virginia Convention of the 6th of May, 1776, instructed their delegates in the General Congress to declare those Colonies free and
                independent States.
              </p>
              <p className="p-in-sp">On the memorable fourth of July, in that year Congress did so declare them. I read the following extract from that sacred instrument, the Declaration of Independence, to give the very language of the patriot fathers of the country in their declaration of freedom and independence :</p>
              <p className="p-in-sp">
                "We, therefore, the representatives of the United States of America, in general Congress assembled, appealing to the Supreme Judge of the World for the rectitude of our intentions, do, in the name, and by authority of the good people of these colonies, solemnly publish and declare, That these United Colonies are, and of right ought to be, Free and Independent States; and they are
                absolved from all allegiance to the British Crown, and that all political connexion between them and the state of Great Britain, is, and ought to be, totally dissolved; and that as Free and Independent States, they have full power to levy war, conclude peace, contract alliances, establish commerce, and to do all other acts and things which Independent States may of right do. And for
                the support of this declaration, with a firm reliance on the protection of Divine Providence, we mutually pledge to each other, our lives, our fortunes, and our sacred honor."
              </p>
              <p className="p-in-sp">Out of that declaration the revolutionary war grew, the history of which war, with its horrors and consequences, is familiar to us all. Upon the termination of that war and the declaration of peace, each <PageNumber num={367} /> State was recognized by England as free and independent, and not as consolidation of States.</p>
              <p className="p-in-sp">Liberty being thus achieved, and the independence of the States recognized, those States for the purposes of mutual defence and better security, ultimately adopted the present Constitution.</p>
              <p className="p-in-sp">The powers delegated to the General Government were limited, and all powers not so delegated were expressly reserved by the States. By the fourth section of the fourth article of that Constitution, a Republican form of Government is guaranteed to each State.</p>
              <p className="p-in-sp">The part of that section to which I refer, is as follows:</p>
              <p className="p-in-sp">"The United States shall guarantee to every State in this Union a Republican Form of Government, and shall protect each of them against invasion."</p>
              <p className="p-in-sp">After the Constitution had been framed and revised, it was submitted for ratification or rejection to the Convention of each State, and not to the people in their aggregate number.</p>
              <p className="p-in-sp">I have pursued this line of argument to meet the argument of gentlemen that each State divested itself of all the attributes of independence and sovereignty by the adoption of the Federal Constitution. How far the States have retained, and now possess, sovereign powers, upon the great question which is agitating the nation, I shall presently discuss.</p>
              <p className="p-in-sp">It will be recollected, Mr. Chairman, that the declaration of independence not only recognized the freedom and independence of the States, but recognized the right of the people to alter or abolish their form of Government whenever it becomes destructive of the ends for which it was established.</p>
              <p className="p-in-sp">
                As the Government has a Constitutional existence, it was contemplated that its abolishment or alteration should be accomplished, not at the point of the sword or bayonet, but in some legal and Constitutional manner. It can not be done by a Legislative body, for its powers are limited—I take it then it can only be done by the same power that created or adopted it—which was by
                Conventions of the people possessing sovereign power, subject only to the will of the people. In this connection I refer to the following extract from the Declaration of Independence:
              </p>
              <p className="p-in-sp">
                "We hold these truths to be self evident; that all men are created equal; that they are endowed, by their Creator, with certain unalienable rights; that among these are life, liberty, and the pursuit of happiness. That to secure these rights, governments are instituted among men, deriving their just powers from the consent of the governed; that
                <PageNumber num={368} /> whenever any form of government becomes destructive of these ends, it is the right of the people to alter or to abolish it, and to institute a new government, laying its foundation on such principles, and organizing its powers in such form, as to them shall seem most likely to effect their safety and happiness."
              </p>
              <p className="p-in-sp">
                It will also be recollected that the Convention of Virginia, which ratified the Federal Constitution, declared "that the powers granted under the Constitution being derived from the people of the United States, may be resumed by them whensoever the same shall be perverted to their injury or oppression, and that every power not granted thereby remains with them at their will."
              </p>
              <p className="p-in-sp">The government being thus established, our national history commenced. Our free institutions, a generous climate and fertile soil at once gave impulse to our developement. Energy and industry, and enterprise have accomplished it; and to-day we rank among the first nations of the earth.</p>
              <p className="p-in-sp">
                The rich fields of cotton, and sugar, and hemp, and rice of the sunny South; the vast grain producing region of the great West; the manufactures of the North, and the stock raising and producing section of the centre, together with the production of tobacco and supplies of choicest timber, added to the rich mines, and minerals, and oils that underlay our mountains, have truly made
                our country an asylum for the oppressed of every land, and rendered it the Eden and pride of the world.
              </p>
              <p className="p-in-sp">Then why, Mr. Chairman, is our country jeopardized? What cause threatens its overthrow? What makes it reel and stagger, like a drunken man? In the consideration of this subject, it will be necessary to raise the curtain, and take a view of our domestic affairs.</p>
              <p className="p-in-sp">
                In the fifteen Southern States, the institution of African slavery exists; in the remaining nineteen States it does not exist. That institution has become an element of political power, which operates beneficially to the section where it exists. The non-slaveholding States becoming jealous of that power, and, with a view to overthrow it, have sought to destroy the institution from
                which it derives its existence.
              </p>
              <p className="p-in-sp">
                When I say the non-slaveholding States have sought to destroy the institution of slavery, I do not wish to be understood as referring to or embracing in my argument all of the citizens of the non-slaveholding States; for I am sure there are within those States, many—very many —who are as loyal to the institutions of the country as are to be found in the slaveholding States. I refer
                to the abolition party—that party <PageNumber num={369} /> who teach and preach agitation and propagandism, and instil fanaticism.
              </p>
              <p className="p-in-sp">
                The party which has ever hindered and obstructed the execution of the fugitive slave law, and in many instances caused its execution to be attended with riots, mobs and bloodshed, as it was done at Boston some years ago, where it became necessary to execute it at the point of the bayonet ; the party which contrived and put into operation the Emigrant Aid Societies of the New England
                States, who were armed and equipped, and sent to Kansas to prosecute a civil war upon the unoffending people of Missouri—a war which caused the blush of shame to mantle the cheek of this nation—a war which put to death the helpless and the innocent—a war which, for a time, bid defiance to the authorities of the government, and found its sympathisers only in the ranks of the abolition
                fanatics of the North. A party that has for forty years, in church and State, in public councils and private walks inculcated in the Northern mind a deadly hostility to the institution of slavery; and, as was correctly remarked on yesterday by the gentleman from Halifax
                [<span className="persName">Mr. BRUCE</span>]
                , their infants are taught to detest the institution; pictures are held up before them representing the suffering slave in chains at the feet of a cruel master with scourge in hand. They are taught to believe the institution is a moral, social and political evil—an offence against religion and a crime against God. Growing out of this sentiment, we see that in some of the
                non-slaveholding States their legislative enactments tend to the obstruction of the Fugitive Slave Law. But a few months ago, in the stillness of the night, when a State and nation were wrapped in slumber, enjoying the supposed security guaranteed to them by the social compact to which I have referred, a band of murderers and marauders, headed by John Brown, seized the Government
                armory at Harper's Ferry, stopped the mail upon one of the highways of the country, took some of our citizens prisoners and shot others down like dogs. After this infamous man had been tried and convicted and suffered the penalties of the law, his remains were carried to the North, where they were worshiped by whole communities. I mention this fact, sir, to show the sentiment that
                prevails in portions of the North—a sentiment of utter and bitter hostility to the institutions of the South.
              </p>
              <p className="p-in-sp">
                Nor is this all, Mr. Chairman. We have the published letters and speeches of such men as Millard Fillmore, Franklin Pierce, Nathaniel P. Banks, Edward Everett, and others, showing that the North occupies an unfriendly if not a hostile position to the South. Nor does this hostility seem to have diminished, for we see that recently the very
                <PageNumber num={370} /> party that has created and kept up this agitation and excitement has elected the President and now has the power of the Government in their own hands. That President when a candidate for high honor and office has told the American people, that he hated slavery as much as any abolitionist; that between free labor and slave labor
                there is an irrepressible conflict, and that that conflict will continue to exist until all the States become free, or all become slave. The Chicago platform upon which he was elected, breathes the same degree of hostility to the South, and I regret to find that the inaugural of that President gives but little or no hope to the Southern man for peace and harmony.
              </p>
              <p className="p-in-sp">In this connection, the question may well be propounded—should the institution of slavery be protected in Virginia?</p>
              <p className="p-in-sp">
                There are now in this State 490,887 slaves, which, valued at the average market price, would make them worth $300,422,844. As now taxed, they yield to the State an annual revenue of $337,804.00. And if taxed as other property is, they would yield a revenue of $1,201,691.37. Most of the agriculture in the Eastern portion of the State, and much in the Valley and South-West, is the
                result of their labor. If they are liberated, over three hundred millions of dollars worth of property will be lost to the State. Her agriculture must diminish, Eastern lands will depreciate, and in addition, the large amount of tax now paid, and the much larger amount that will be paid upon them when properly taxed, will be lost to the State.
              </p>
              <p className="p-in-sp">
                This is not all. In order to keep down the interest on our State debt and ultimately extinguish that debt, our land taxes must be increased, perhaps ten fold greater than they now are. The eastern lands, after the abolition of slavery, declining, and the western lands increasing in value, would make that tax much more oppressive on the western man than the eastern. Already are we
                groaning under excessive taxation, and I am sure it is the wish of my people to avert an increase of their burthens. If slaves should be liberated, society would be infested with a half-caste race who have not the capacity for self-government, and instead of yielding to the State a large annual revenue as they now do, they would fill our poor-houses, jails and penitentiary. The time
                of our courts would be occupied in trying them for crime, and our treasury heavily taxed to defray their criminal expenses. Whilst we have but few free negroes in the State, as compared with the number of slaves, is it not strange that as many of the former class have been convicted for crime in the last twelve months as have been of the latter class? The statistics of the country
                show, that in slave States and non-slaveholding <PageNumber num={371} /> States there is a much larger per cent of free negroes convicted for crime than any other class.
              </p>
              <p className="p-in-sp">
                If liberated, they must remain with us. I see it stated that all the ships of the world could not remove the slaves with their probable increase from the Southern States to Africa in one hundred years. The only proper answer that can be made to the enquiry as to what shall be done with them, is to keep them where they are and as they are. This done, and their labor will yield largely
                to the commerce and pay much of the taxes of the country.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, it is urged upon this floor that, in consequence of the insecurity of this valuable property, the South ought to sever her connection with the Federal Government. And the question is asked, what shall Virginia do? She has done no wrong to the General Government or to any State. She has ever been first and foremost in her loyalty to the Union. Her sons inaugurated the
                government, and her best blood and talent were sacrificed to acquire it. When our independence was achieved and the country left poor in purse but rich in liberty, Virginia gave an empire for its relief. She contemplates with sad and melancholy reflection the condition of the country—she mourns over it; and when she calls to mind the long years of war that were waged and the blood
                and treasure that were spent to acquire it; when she recollects that it is the last rich legacy of our patriot fathers; when she recollects they entailed it upon us as tenants in common, with the injunction to perpetuate it, she seeks to keep the "articles of the covenant," and enjoy this, the fairest and proudest and happiest government of earth, in peace and love forever.
              </p>
              <p className="p-in-sp">
                But, if she failed in this, and her brothers of any section "pervert it to her injury or oppression," she will earnestly and affectionately ask them to part in peace. But to part implies a division of territory. How will it be divided? Contemplate, for a moment, our almost boundless coasts of oceans, gulfs and lakes; our navigable rivers, reaching out their mighty arms from ocean to
                mountain, as if to water and fertilize and gladden the earth everywhere. Run your eye over the map of the country—behold our mountain ranges, with their hidden treasures, from North to South—their water power and inexhaustible supplies of timber. Look at the network of improvements that give life and vitality to the country as the arteries of the human body give life and vitality to
                that body. All these force the conviction on the mind that God designed this should be one country, and we should be one people.
              </p>
              <p className="p-in-sp">But, Mr. Chairman, suppose all efforts at an adjustment fail; suppose <PageNumber num={372} /> reconciliation and compromise cannot be had; suppose the fanaticism of the North shall render the Government intolerable and oppressive to us—what shall Virginia do?</p>
              <p className="p-in-sp">I do not understand that she has the right to secede at will and pleasure without cause, and that her action shall be final; for if this were so, it would be competent for any State, at any time, to avoid its responsibility to the Government in the payment of debt, sustaining war, or the performance of any duty that might legitimately devolve upon it.</p>
              <p className="p-in-sp">
                But I do insist that, when the Government shall be "perverted to the injury or oppression" of the people, they have the right to resume the powers delegated to the Government. The difficulty presented is as to the manner in which this right may be exercised. It is unquestionably true that the people have the revolutionary right; but it is not fair to infer that the framers of our
                State papers intended to confer upon the people another and additional right. One of the grand objects of the Government was to vest the people with rights they did not possess in a natural state; hence it would have been idle to have stopped with the guarantee of natural rights alone. The revolutionary right is natural, unalienable, inherent. As well might they have guaranteed to us
                any other natural, unalienable and inherent right; such as the right to breathe the air of Heaven or drink the waters of the earth.
              </p>
              <p className="p-in-sp">
                To my mind it is clear that the right to resume rests in each State to be exercised by its Convention possessing sovereign powers, subject only to the action of the people. That right being thus exercised, and the State thereby placed in an abnormal condition, the remaining States have the right to demand an adjustment or settlement of any obligation that may rest upon the withdrawn
                State growing out of national obligation, public property. And for this, among other purposes, the right of treaty is interposed. If this mode prove insufficient the ultimo ratio regum will have been attained, and war is the last resort.
              </p>
              <p className="p-in-sp">
                If this were not so, and the right to resume depended upon the will of a majority of all the people, as argued upon this floor, it would place it in the power of a majority to "pervert the Government to the injury and oppression" of a minority, and give to that minority no other right but that of revolution, which, from the balance of power against it, would reduce it to a state of
                vassalage to the majority. The right of a State to judge of its grievances and act for itself is manifest from the fact that at the formation of the Government each State <PageNumber num={373} /> was independent and sovereign, and only delegated to that Government limited powers that did not divest it of its sovereignty.
              </p>
              <p className="p-in-sp">
                No arbiter was chosen to act between a State and the government. If the State complained of oppression and a perversion of her rights there is no form of tribunal to which she can appeal to institute the enquiry and adjudicate the difficulty. Each State entered into the government upon terms of exact equality. Hence the gallant little State of Delaware, with her eighty thousand
                people, is entitled to her two Senators in the Congress of the United States, the same as the mighty State of New York with her three millions. It is conceded on all hands, that Virginia has the right to demand some guarantees for her future safety and the enjoyment of equality in this sisterhood of States. The General Assembly declared it, by the call of this Convention, and the
                appointment of Commissioners to the Peace Conference at Washington. The Commissioners to that Conference from the North and South, by their action, conceded it. Almost every northern conservative journal and orator conceded it. The State of Massachusetts by the modification of her personal liberty bill conceded it. Each member of this Convention by his action on this floor has
                conceded it. Then what guarantees should she demand? They should be guarantees of principle and guarantees of power. Such guarantees as are in accordance with the spirit of our institutions and will enable the South to protect her institutions and have a controlling influence in the acquisition of future Territory. She should demand such guarantees as will secure her rights and such
                as the North can give without abridging their Constitutional rights, and such as will justify the seceded States to resume their former positions in the Union.
              </p>
              <p className="p-in-sp">
                I am opposed to Virginia's being forced by the action of South Carolina to join the Southern Confederacy, if she can be indemnified where she is. The action of South Carolina was too hasty; she did not exhibit a proper spirit when she refused to have a consultation with Virginia. I deprecate her persistent determination, expressed by her Commissioners on this floor, never to return
                to the Union of these States, even if we can remove the causes for which she seceded.
              </p>
              <p className="p-in-sp">
                And I deprecate the speech of one of her distinguished sons, who expressed the hope that she would throw her arms around the pillars of the Constitution, and tear it down from turret to foundation. Mr. Chairman, occupying the proud and potential position that Virginia does, and at a time when the eyes of a nation are turned to her, and when the friends of equality and constitutional
                liberty are imploring her to save this great government, she should take a high, bold and <PageNumber num={374} /> conservative position—not defiantly but firmly, not in an anger, but with forbearance. Mr. Chairman, some days ago I had the honor to offer the following resolution :
              </p>
              <p className="p-in-sp">
                "Resolved, That the Committee on Federal Relations be instructed to inquire into the expediency of providing for the Border State Conference, as recommended by the report of the majority of that Committee, and a Conference with the authorities of the Confederated States as recommended by one of the minority reports; and a commission to each of the non-slaveholding States, to invite
                the authorities thereof, to initiate such proceedings as will be acceptable to the slaveholding States now in the Union, and of the Confederated States."
              </p>
              <p className="p-in-sp">
                If an adjustment of our national difficulties is had, it must be by consultation with all the States. It is now generally understood among the members of this body that, after we shall have agreed upon a proposition to be submitted as the basis of that adjustment, we will adjourn for several months. I therefore propose, during that time, to send a commissioner to each of the
                non-slaveholding States with our plan of adjustment. I think it is due to them. I am also in favor of the border slave State Conference, as proposed by the majority report of the Committee on Federal Relations. I also favor the proposition of one of the minority reports, to send a commission to the Confederated States. We should ascertain from them, authoritatively, whether, upon any
                conditions, they will return to the Union, and, if so, upon what conditions.
              </p>
              <p className="p-in-sp">
                I regard it eminently proper that Virginia should act in concert with the slave States now in the Union. For if they shall be forced to resume their powers, I am opposed to her relinquishing her right to the eleven hundred million acres of public land and her rights to other public property, and the archives of the country. If we cannot remain in the Union in peace, let us depart in
                peace. And in the event we do part, let us take all the Government property South of the line of 36 deg. 30 min., and yield to the North all the property North of that line—and the difference be paid by one to the other, according to its relative value. But avoid, if possible, a disruption of the Government. Test the question fairly and fully, and I may be permitted to say, with
                deference to every gentleman on this floor, that I have seen no proposition, that, in my opinion, would so fully accomplish it as the one submitted by myself.
              </p>
              <p className="p-in-sp">
                It is the duty of each delegate to look to his own locality and the interest of his constituency in estimating what is best for the general <PageNumber num={375} /> good. Acting upon this principle, my heart has turned to my people, and my prayer has been that I may aid in accomplishing that result which will best accord with their views and most
                effectually protect their interest.
              </p>
              <p className="p-in-sp">The markets for my section of country are principally in the States of Maryland, Pennsylvania and Ohio.</p>
              <p className="p-in-sp">
                If the Government shall be divided, and a tariff of duties shall be imposed upon all articles of trade that cross the border, it will readily be seen that our trade, as it now exists, will be materially obstructed. Take, for instance, the farming and grazing interest. My county is peculiarly a grazing county. Many of our cattle are bought in the Statt. of Ohio, and find a market in
                Pennsylvania. If a tariff is imposed it must be paid by the producer. or consumer.
              </p>
              <p className="p-in-sp">If it is imposed on the consumer, he will, of course, seek the Western beef, that goes to him duty free. If it is imposed on the producer, it renders his business unprofitable, and shuts him out from the market of the free States.</p>
              <p className="p-in-sp">
                It will not be contended that we can compete with the rich districts of the West in grain raising, hence we must limit ourselves to the markets of Maryland, or change our pursuit, unless the South will open up to us a trade, and I have not yet heard how this is proposed to be done. It is true, as has been urged upon this floor, that the North-West, in climate, soil, water-power,
                timber minerals and oil, is not surpassed, if equaled, by any other section of the State; and I would suggest to the gentleman from Kanawha
                [<span className="persName">Mr. SUMMERS</span>] , and the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , that when they speak of their fertile counties, to bear in mind that the rich valleys, the gurgling, streams and sloping hills of Harrison make it fully their equal.</p>
              <p className="p-in-sp">
                Mr. Chairman, if the day of our country's discontent and disruption shall be forced upon us, and an honorable adjustment cannot be had, you will find the gallant men of the mountains in the front rank, to vindicate her honor and to assert our rights; and if war follows, their stout hearts and strong arms will wage and prosecute it as did their fathers wage and prosecute the wars of
                our early history.
              </p>
              <p className="p-in-sp">
                Sir, there is no part of Virginia that can boast of its record as can the Northwest; from the time when the ring of the woodman's axe broke upon the stillness of the forest, its devotion to the Union and loyalty to Virginia have been unquestionable. In the days of the revolution my county was embraced in West Augusta; and when, during that war, the dark and lowering clouds of despair
                hung over the country, <PageNumber num={376} /> when the Continental Congress failed to furnish men and money to prosecute that war, when hope had fled and despondency had seized the nation, it was the bravery and chivalry and courage of the men of those mountains that called forth from the father of his country the daring declaration of : "Strip me of
                the dejected and suffering remnant of my army; take from me all that I have left; leave me but a banner; give me but the means to plant it upon the mountains of West Augusta; and I will yet draw around me the men who will lift their bleeding country from the dust and set her free." And in the war of 1812 it was my county that sent her soldiers to fight the battles of Richmond and
                Norfolk.
              </p>
              <p className="p-in-sp">Not only this, sir, but ever since that country has been populated, whenever and however, and by whomsoever, the country has been assailed, the men of that section have been ready and willing to respond to her call and rush to her rescue.</p>
              <p className="p-in-sp">Mr. Chairman, I thought it not improper to step aside from the line of my argument, to say this much of my section of the State, as other gentlemen had manifested some distrust of it.</p>
              <p className="p-in-sp">
                Whilst the hope of an adjustment of our national troubles is entertained by many, and the plans for its accomplishment seem feasible, it is actually and indispensably necessary that the peace of the country should be preserved. And yet we are told that the administration will enforce the collection of the revenue in the seceded States, and establish a system of coercion.
              </p>
              <p className="p-in-sp">
                The word coercion is very comprehensive and repulsive to every American patriot. It implies war. Aye, sir, it will inaugurate war, and if resorted to there will be a different issue propounded to Virginia. The question will then be propounded directly to her whether she will tamely submit or whether she will rise up in the power of strength, and throw off the oppression, come from
                whence it may. That coercion was contemplated and intended by the Administration I have no doubt. It was indicated by the inaugural, and demanded by the most prominent of the Republican journals and leaders, and indeed by one or more of the Cabinet. Certain it is we have had no disclaimer from the President of his intentions to coerce. In these times of excitement on the subject,
                when the whole nation is aroused and looking to him for repose, if he did not intend to coerce it is natural to suppose he would say so, and thereby put the country to rest. Let the policy of the Administration be what it may, it is certain the people of Virginia never will be coerced. Upon the subject of resistance to oppression and coercion, Mr. Chairman, our forefathers left us a
                glorious record.
              </p>
              <PageNumber num={377} />
              <p className="p-in-sp">
                In the year 1635, Sir John Harvey, the King's Governor of the colony of Virginia was driven from place and power, was thrust out of the colony and sent to England for trial, by the people, for betraying their interest and pursuing a policy of oppression towards them. After Charles the First had been executed and the commonwealth of England established, it sought to impose upon the
                people of the colony, restrictions to which they were not willing to submit. Other colonies had submitted, and Massachusetts went so far by her own enactment, as to prohibit all intercourse with Virginia until the supremacy of the commonwealth of England should be established. Notwithstanding the demand by England, and the course pursued by Massachusetts, Virginia, jealous of the
                rights of her people, repudiated the one and disregarded the other, until she agreed with that Commonwealth upon terms acceptable to herself. I read the following extracts from the articles of adjustment to which I refer, and I am sure they will be read with pleasure by every Virginian:
              </p>
              <p className="p-in-sp">
                "First, It is agreed and cons'ted that the plantation of Virginia, and all the inhabitants thereof, shall be and remain in due obedience and subjection to the commonwealth of England, according to the laws there established. And that this submission and subscription be acknowledged a voluntary act not forced or constrained by a conquest upon the country. And that they shall have and
                enjoy such freedoms and privileges as belong to the free born people of England, and that the former government by the commissions and instructions be void and null."
              </p>
              <p className="p-in-sp">"8thly. That Virginia shall be free from all taxes, customs and impositions whatsoever, and none to be imposed on them without consent of the Grand Assembly, and so that neither forts nor castles be erected or garrisons maintained without their consent."</p>
              <p className="p-in-sp">
                Thus it will be seen that at that early day a spirit of independence and chivalry characterized the people of Virginia. That patriot band had the courage to repudiate force and constraint from haughty and powerful England. Not only this, sir, but their submission to the laws of England was made dependent upon their right to enjoy such "freedoms" and privileges as belonged to the free
                born people of England, and dependent upon the further condition, that neither forts nor castles should be erected, nor garrisons maintained within her limits without her consent.
              </p>
              <p className="p-in-sp">Will the sons of Virginia at this day ask less or submit to more than did their fathers? Have they so far degenerated as to be branded with <PageNumber num={378} /> inferiority, and become the subjects of inequality? I trust not. I can never believe it.</p>
              <p className="p-in-sp">There is another incident in the history of the colony, that speaks volumes for the chivalry of that day. In 1658, after the House of Burgesses had elected their Governor, he sought to usurp powers he did not possess, and to control the action of the Grand Assembly. Let history speak :</p>
              <p className="p-in-sp">
                "The Governor and Council had ordered the dissolution of the Assembly; the Burgesses now decreed the former election of Governor and Council to be void. Having thus exercised, not merely the right of election, but the more extraordinary right of removal, they reelected Matthews, 'who by us,' they added, 'shall be invested with all the just rights and privileges belonging to the
                Governor and Captain-General of Virginia.' The Governor submitted, and acknowledged the validity of his election by taking the new oath, which had just been prescribed. The Council was organized anew; and the spirit of popular liberty established all its claims."
              </p>
              <p className="p-in-sp">When the necessity arises, if it should ever arise, I hope, we all hope, we know, that the brave sons of Virginia will dare to do as their fathers did—denounce treachery and resist oppression.</p>
              <p className="p-in-sp">If coercion shall be commenced, let the sons of Virginia, as one man, resist, resist to the death. They will</p>
              <p className="p-in-sp">"Strike till the last armed foe expires! Strike for their altars and their fires! Strike for the green graves of their sires—God, and their native land."</p>
              <p className="p-in-sp">Rather than be coerced, let them fight, fight on, fight ever; fight till Virginia shall be a continuous grave yard from ocean to river. And when Virginia shall be conquered, the sun of liberty will set in gloom and blood forever.</p>
              <p className="p-in-sp">
                But, Mr. Chairman, let us rise above this awful necessity, let us shut our eyes to the horrors of this extreme necessity. Let us, if possible, avert the dangers that now threaten us. Let Virginia erect a platform broad enough for all the States to stand upon; let her crush out the schemes of wire-pullers and political tricksters, and appeal to the patriotism of the American people.
                Let her, with the olive branch in one hand and the Constitution in the other, mount that platform, and bid her sisters come; let us have a feast of reason and flow of soul, and re-unite in bonds of confidence and love forever the distracted spirits of our land.
              </p>
              <p className="p-in-sp">It will be glad tidings of great joy throughout the land, and loud will <PageNumber num={379} /> be the hosannas that will ascend to the bar of God, to gladden the heart of the Father of his Country.</p>
              <p>At the close of <span className="persName">Mr. WILSON</span>'S speech, calls were made for "question, question," when</p>
              <p><span className="persName">The CHAIRMAN</span> ordered the Secretary to read the first section of the majority report of the Committee on Federal Relations.</p>
              <p>The Secretary read that section as follows:</p>
              <div className="floatingText">
                <div className="floatingText_body"><p>"The Committee on Federal Relations have, according to order, had under consideration the several propositions referred to them, and beg leave to report for consideration and adoption by the Convention, the following preamble and resolutions :"—</p></div>
              </div>
              <div className="speaker" id="sp1271"><span className="persName">Mr. STAPLES</span> of Patrick—</div>
              <p className="p-in-sp">I hardly expect that the Committee is prepared to take a vote on these propositions. It is probable that some other gentlemen are going to address the Committee before a vote is taken upon this proposition. I move that the committee now rise.</p>
              <p>The motion was lost.</p>
              <div className="speaker" id="sp1272"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">I have a substitute to offer. I supposed I had it in my pocket, but I find on searching that I have not. I will state, however, that it is the Constitution of the Confederate States, and I propose to offer it as a substitute for the Committee's report, to come in after the preamble.</p>
              <p>The SECRETARY intimated to <span className="persName">Mr. HALL</span> that he had a copy of the Constitution to which he referred.</p>
              <div className="speaker" id="sp1273"><span className="persName">Mr. HALL</span>—</div>
              <p className="p-in-sp">The Secretary has accommodated me with a copy of the Constitution of the Confederate States, and not having an opportunity to get from my room the Constitution as I intended to offer it, I will offer this.</p>
              <div className="speaker" id="sp1274"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I ask that it may be read. If we are to adopt the Constitution of any other people, we should know what it is. This, I understand, is the Constitution of the Confederate States. Which Confederate States—this, or that? [pointing alternately North and South] . I claim the right to have it read, sir, that I may understand what this Constitution is, and what I am going to vote on.</p>
              <div className="speaker" id="sp1275"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">
                I understand that this is the Constitution of the United States with the amendments adopted by the Confederated States. The reading of the paper, so far as it is identical with the Constitution of the United States might be dispensed with, and those portions only might be read which differ from that instrument. I do not think it necessary to consume the time of this Convention in
                reading a paper which will occupy half an hour, and which every body is familiar with and under which we are here in session—for we are in <PageNumber num={380} /> session under the Constitution of the United States. I move then to dispense with the reading of the paper so far as it is identical with the Constitution of the United States.
              </p>
              <div className="speaker" id="sp1276"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I claim the right not to be called upon to vote upon any thing which I do not hear read at that table.</p>
              <div className="speaker" id="sp1277"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">I acknowledge the right of the gentleman to call for the reading of the paper, but I wish it to go abroad that it is done for the purpose of delay.</p>
              <div className="speaker" id="sp1278"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I say that the gentleman has no authority for making the statement; it is utterly unfounded. [Cries of "order."] I am not out of order, when I say it is unfounded. I have no such purpose as delay.</p>
              <div className="speaker" id="sp1279"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I presume that there is no more incontestable right than that of requiring that a proposition shall be read.</p>
              <div className="speaker" id="sp1280"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">As a matter of course the document will be read.</p>
              <div className="speaker" id="sp1281"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">An imputation has been cast upon my motives. I characterize that imputation as utterly unfounded. I wish it distinctly understood that I use the lightest word in reply to that imputation.</p>
              <p>The paper was then read as follows:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <h1>CONSTITUTION OF THE CONFEDERATE STATES OF AMERICA</h1>
                  <p>
                    We, the people of the Confederate States, each State acting in its sovereign and independent character, in order to form a permanent federal government, establish justice, insure domestic tranquility, and secure the blessings of liberty to ourselves and our posterity—invoking the favor and guidance of Almighty God—do ordain and establish this constitution for the Confederate
                    States of America.
                  </p>
                  <p>ARTICLE I</p>
                  <p>SECTION 1. All legislative powers herein delegated shall be vested in a Congress of the Confederate States, which shall consist of a Senate and House of Representatives.</p>
                  <p>
                    SECTION 2.-1. The House of Representatives shall be composed of members chosen every second year by the people of the several States; and the electors in each State shall be citizens of the Confederate States, and have the qualifications requisite for electors of the most numerous branch of the State Legislature; but no person of foreign birth not a citizen of the Confederate
                    States shall be allowed to vote for any officer, civil or political, State or federal.
                  </p>
                  <p>2. No person shall be a representative who shall not have attained the age of twenty-five years, and be a citizen of the Confederate States, <PageNumber num={381} /> and who shall not, when elected, be an inhabitant of that State in which he shall be chosen.</p>
                  <p>
                    3. Representatives and direct taxes shall be apportioned among the several States, which may be included within this Confederacy, according to their respective numbers, which shall be determined by adding to the whole number of free persons, including those bound to service for a term of years, and excluding Indians not taxed, three-fifths of all slaves. The actual enumeration
                    shall be made within three years after the meeting of the first Congress of the Confederate States, and within every subsequent term of ten years, in such manner, as they shall by law, direct. The number of Representatives shall not exceed one for every fifty thousand, but each State shall have at least one Representative; and until such enumeration shall be made the State of
                    South Carolina shall be entitled to choose six—the State of Georgia ten—the State of Alabama nine—the State of Florida two—the State of Mississippi seven—the State of Louisiana six, and the State of Texas six.
                  </p>
                  <p>4. When vacancies happen in the representation from any State, the Executive authority thereof shall issue writs of election to fill such vacancies.</p>
                  <p>5. The House of Representatives shall choose their Speaker and other officers; and shall have the sole power of impeachment; except that any judicial or other federal officer, resident and acting solely within the limits of any State, may be impeached by a vote of two-thirds of both branches of the Legislature thereof.</p>
                  <p>SECTION 3-1. The Senate of the Confederate States shall be composed of two Senators from each State, chosen for six years by the Legislature thereof, at the regular session next immediately preceding the commencement of the term of service; and each Senator shall have one vote.</p>
                  <p>
                    2. Immediately after they shall be assembled, in consequence of the first election, they shall be divided as equally as may be into three classes. The seats of the Senators of the first class shall be vacated at the expiration of the second year; of the second class at the expiration of the fourth year; and of the third class at the expiration of the sixth year; so that one-third
                    may be chosen every second year; and if vacancies happen by resignation, or otherwise, during the recess of the Legislature of any State, the Executive thereof may make temporary appointments until the next meeting of the Legislature, which shall then fill such vacancies.
                  </p>
                  <p>3. No person shall be a Senator who shall not have attained the age of thirty years, and be a citizen of the Confederate States; and <PageNumber num={382} /> who shall not, when elected, be an inhabitant of the State for which he shall be chosen.</p>
                  <p>4. The Vice President of the Confederate States shall be President of the Senate, but shall have no vote, unless they be equally divided.</p>
                  <p>5. The Senate shall choose their other officers, and also a President pro tempore in the absence of the Vice President, or when he shall exercise the office of President of the Confederate States.</p>
                  <p>6. The Senate shall have the sole power to try all impeachments. When sitting for that purpose they shall be on oath or affirmation. When the President of the Confederate States is tried the Chief Justice shall preside, and no person shall be convicted without the concurrence of two-thirds of the members present.</p>
                  <p>7. Judgment in cases of impeachment shall not extend further than to removal from office, and disqualification to hold and enjoy any office of honor, trust, or profit, under the Confederate States; but the party convicted shall nevertheless, be liable and subject to indictment, trial, judgment, and punishment, according to law.</p>
                  <p>SECTION 4.-1. The times, places, and manner of holding elections for Senators and Representatives shall be prescribed in each State by the legislature thereof, subject to the provisions of this Constitution; but the Congress may, at any time, by law make or alter such regulations, except as to the times and places of choosing Senators.</p>
                  <p>2. The Congress shall assemble at least once in every year, and such meeting shall be on the first Monday in December, unless they shall, by law, appoint a different day.</p>
                  <p>SECTION 5.-1. Each House shall be the judge of the elections, returns, and qualifications of its own members, and a majority of each shall constitute a quorum to do business; but a smaller number may adjourn from day to day, and may be authorized to compel the attendance of absent members, in such manner and under such penalties as each House may provide.</p>
                  <p>2. Each House may determine the rules of its proceedings, punish its members for disorderly behavior, and, with the concurrence of two-thirds of the whole number, expel a member.</p>
                  <p>3. Each House shall keep a journal of its proceedings, and from time to time publish the same, excepting such parts as may in their judgment require secresy; and the yeas and nays of the members of either House, on any question, shall, at the desire of one-fifth of those present, be entered on the journal.</p>
                  <p>4. Neither House, during the session of Congress, shall without the <PageNumber num={383} /> consent of the other, adjourn for more than three days, nor to any other place than that in which the two Houses shall be sitting.</p>
                  <p>
                    SECTION 6.-1. The Senators and Representatives shall receive a compensation for their services, to be ascertained by law, and paid out of the treasury of the Confederate States. They shall, in all cases, except treason and breach of the peace, be privileged from arrest during their attendance at the session of their respective Houses, and in going to and returning from the same;
                    and for any speech or debate in either House they shall not be questioned in any other place.
                  </p>
                  <p>
                    2. No Senator or Representative shall during the time for which he was elected, be appointed to any civil office under the authority of the Confederate States, which shall have been created, or the emoluments whereof shall have been increased, during such time; and no person holding any office under the Confederate States shall be a member of either House during his continuance
                    in office. But Congress may, by law, grant to the principal officer in each of the Executive Departments a seat upon the floor of either House, with the privilege of discussing any measure appertaining to his department.
                  </p>
                  <p>SECTION 7.-1. All bills for raising revenue shall originate in the House of Representatives; but the Senate may propose or concur with amendments as on other bills.</p>
                  <p>
                    2. Every bill which shall have passed both Houses shall, before it becomes a law, be presented to the President of the Confederate States; if he approve he shall sign it; but if not, he shall return it with his objections to that house in which it shall have originated, who shall enter the objections at large upon their journal, and proceed to reconsider it. If, after such
                    reconsideration, two-thirds of the House shall agree to pass the bill, it shall be sent, together with the objections, to the other House, by which it shall likewise be reconsidered, and if approved by two-thirds of that House, it shall become a law. But in all such cases, the votes of both Houses shall be determined by yeas and nays, and the names of the persons voting for and
                    against the bill shall be entered on the journal of each House respectively. If any bill shall not be returned by the President within ten days (Sundays excepted) after it shall have been presented to him, the same shall be a law, in like manner as if he had signed it, unless the Congress, by their adjournment, prevent its return; in which case it shall not be a law. The
                    President may approve any appropriation and disapprove any other appropriation in the same bill. In such cases he shall, in signing the bill, designate the appropriations disapproved; and shall return a copy of such appropriations, with his objections, to the House in which <PageNumber num={384} /> the bill shall have originated; and the same
                    proceedings shall then be had as in case of other bills disapproved by the President.
                  </p>
                  <p>
                    3. Every order, resolution or vote, to which the concurrence of both Houses may be necessary (except on a question of adjournment), shall be presented to the President of the Confederate States; and before the same shall take effect, shall be approved by him; or being disapproved by him, may be repassed by two-thirds of both Houses, according to the rules and limitations
                    prescribed in case of a bill.
                  </p>
                  <p>SECTION 8. The Congress shall have power-</p>
                  <p>
                    1. To lay and collect taxes, duties, imposts and excises, for revenue necessary to pay the debts, provide for the common defence, and carry on the government of the Confederate States; but no bounties shall be granted from the treasury; nor shall any duties or taxes on importations from foreign nations, be laid to promote or foster any branch of industry; and all duties, imposts
                    and excises shall be uniform throughout the Confederate States :
                  </p>
                  <p>2. To borrow money on the credit of the Confederate States:</p>
                  <p>
                    3. To regulate commerce with foreign nations, and among the several States, and with the Indian tribes; but neither this, nor any other clause contained in the Constitution, shall ever be construed to delegate the power to Congress to appropriate money for any internal improvement intended to facilitate commerce ; except for the purpose of furnishing lights, beacons and buoys,
                    and other aids to navigation upon the coasts, and the improvement of harbors, and the removing of obstructions in river navigation, in all which cases such duties shall be laid on the navigation facilitated thereby, as may be necessary to pay the costs and expenses thereof :
                  </p>
                  <p>4. To establish uniform laws of naturalization, and uniform laws on the subject of bankruptcies, throughout the Confederate States; but no law of Congress shall discharge any debts contracted before the passage of the same:</p>
                  <p>5. To coin money, regulate the value thereof and of foreign coin, and fix the standard of weights and measures :</p>
                  <p>6. To provide for the punishment of counterfeiting the securities and current coin of the Confederate States:</p>
                  <p>7. To establish post offices and post routes; but the expenses of the Post Office Department, after the first day of March, in the year of our Lord eighteen hundred and sixty-three, shall be paid out of its own revenues:</p>
                  <p>8. To promote the progress of science and useful arts, by securing for <PageNumber num={385} /> limited times to authors and inventors the exclusive right to their respective writings and discoveries:</p>
                  <p>9. To constitute tribunals inferior to the Supreme Court:</p>
                  <p>10. To define and punish piracies and felonies committed on the high seas, and offences against the law of nations:</p>
                  <p>11. To declare war, grant letters of marque and reprisal, and make rules concerning captures on land and water:</p>
                  <p>12. To raise and support armies; but no appropriation of money to that use shall be for a longer term than two years:</p>
                  <p>13. To provide and maintain a navy:</p>
                  <p>14. To make rules for government and regulation of the land and naval forces:</p>
                  <p>15. To provide for calling forth the militia to execute the laws of the Confederate States, suppress insurrections, and repel invasions:</p>
                  <p>16. To provide for organizing, arming, and disciplining the militia, and for governing such part of them as may be employed in the service of the Confederate States; reserving to the States, respectively the appointment of the officers, and the authority of training the militia according to the discipline prescribed by Congress:</p>
                  <p>
                    17. To exercise exclusive legislation, in all cases whatsoever, over such district (not exceeding ten miles square) as may, by cession of one or more States and the acceptance of Congress, become the seat of the Government of the Confederate States; and to exercise like authority over all places purchased by the consent of the Legislature of the State in which the same shall be,
                    for the erection of forts, magazines, arsenals, dockyards, and other needful buildings, and
                  </p>
                  <p>18. To make all laws which shall be necessary and proper for carrying into execution the foregoing powers, and all other powers vested by this Constitution in the government of the Confederate States, or in any department or officer thereof.</p>
                  <p>SECTION 9.-1. The importation of negroes of the African race, from any foreign country, other than the slaveholding States or Territories of the United States of America, is hereby forbidden; and Congress is required to pass such laws as shall effectually prevent the same.</p>
                  <p>2. Congress shall also have power to prohibit the introduction of slaves from any State not a member of, or Territory not belonging to, this Confederacy.</p>
                  <p>3. The privilege of the writ of habeas corpus shall not be suspended, unless when in case of rebellion or invasion the public safety may require it.</p>
                  <PageNumber num={386} />
                  <p>4. No bill of attainder, or ex post facto law, or law denying or impairing the right of property in negro slaves, shall be passed.</p>
                  <p>5. No capitation or other direct tax shall be laid unless in proportion to the census or enumeration hereinbefore directed to be taken.</p>
                  <p>6. No tax or duty shall be laid on articles exported from any State, except by a vote of two-thirds of both Houses.</p>
                  <p>7. No preference shall be given by any regulation of commerce or revenue to the ports of one State over those of another.</p>
                  <p>8. No money shall be drawn from the treasury but in consequence of appropriations made by law; and a regular statement and account of the receipts and expenditures of all public money shall be published from time to time.</p>
                  <p>
                    9. Congress shall appropriate no money from the treasury except by a vote of two-thirds of both Houses, taken by yeas and nays, unless it be asked and estimated for by some one of the heads of Department, and submitted to Congress by the President; or for the purpose of paying its own expenses and contingencies; or for the payment of claims against the Confederate States, the
                    justice of which shall have been judicially declared by a tribunal for the investigation of claims against the government, which it is hereby made the duty of Congress to establish.
                  </p>
                  <p>10. All bills appropriating money shall specify in federal currency the exact amount of each appropriation and the purposes for which it is made; and Congress shall grant no extra compensation to any public contractor, officer, agent or servant, after such contract shall have been made or such service rendered.</p>
                  <p>11. No title of nobility shall be granted by the Confederate States; and no person holding any office of profit or trust under them shall, without the consent of the Congress, accept of any present, emoluments, office or title of any kind whatever from any king, prince or foreign State.</p>
                  <p>12. Congress shall make no law respecting an establishment of religion, or prohibiting the free exercise thereof; or abridging the freedom of speech, or of the press; or the right of the people peaceably to assemble and petition the government for a redress of grievances.</p>
                  <p>13. A well-regulated militia being necessary to the security of a free State, the right of the people to keep and bear arms shall not be infringed.</p>
                  <p>14. No soldier shall, in time of peace, be quartered in any house without the consent of the owner; nor in time of war, but in a manner to be prescribed by law.</p>
                  <PageNumber num={387} />
                  <p>15. The right of the people to be secure in their persons, houses, papers, and effects, against unreasonable searches and seizures, shall not be violated; and no warrants shall issue but upon probable cause, supported by oath or affirmation, and particularly describing the place to be searched, and the persons or things to be seized.</p>
                  <p>
                    16. No person shall be held to answer for a capital or otherwise infamous crime, unless on presentment or indictment of a grand jury, except in cases arising in the land or naval forces, or in the militia, when in actual service, in time of war or public danger; nor shall any person be subject for the same offence to be twice put in jeopardy of life or limb; nor be compelled, in
                    any criminal case, to be a witness against himself; nor be deprived of life, liberty or property, without due process of law; nor shall private property be taken for public use without just compensation.
                  </p>
                  <p>
                    17. In all criminal prosecutions the accused shall enjoy the right to a speedy and public trial, by an impartial jury of the State and district wherein the crime shall have been committed, which district shall have been previously ascertained by law, and to be informed of the nature and cause of the accusation; to be confronted with the witnesses against him; to have compulsory
                    process for obtaining witnesses in his favor; and to have the assistance of counsel for his defence.
                  </p>
                  <p>18. In suits at common law, where the value in controversy shall exceed twenty dollars, the right of trial by jury shall be preserved ; and no fact so tried by a jury shall be otherwise re-examined in any court of the Confederacy than according to the rules of the common law.</p>
                  <p>19. Excessive bail shall not be required, nor excessive fines imposed, nor cruel and unusual punishments inflicted.</p>
                  <p>20. Every law, or resolution having the force of law, shall relate to but one subject, and that shall be expressed in the title.</p>
                  <p>SECTION 10.-1. No State shall enter into any treaty, alliance or confederation; grant letters of marque and reprisal; coin money; make anything but gold and silver coin a tender in payment of debts; pass any bill of attainder, or ex post facto law, or law impairing the obligation of contracts; or grant any title of nobility.</p>
                  <p>
                    2. No State shall, without the consent of the Congress, lay any imposts or duties on imports or exports, except what may be absolutely necessary for executing its inspection laws; and the net produce of all duties and imposts, laid by any State on imports or exports, shall be for the use of the treasury of the Confederate States; and all such laws shall be subject to the revision
                    and control of Congress.
                  </p>
                  <p>
                    3. No State shall, without the consent of Congress, lay any duty of <PageNumber num={388} /> tonnage, except on sea-going vessels, for the improvement of its rivers and harbors navigated by said vessels; but such duties shall not conflict with any treaties of the Confederate States with foreign nations; and any surplus of revenue, thus derived,
                    shall, after making such improvement, be paid into the common treasury; nor shall any State keep troops or ships of war in time of peace, enter into any agreement or compact with another State, or with a foreign power, or engage in war, unless actually invaded, or in such imminent danger as will not admit of delay. But when any river divides or flows through two or more States,
                    they may enter into compacts with each other to improve the navigation thereof.
                  </p>
                  <p>ARTICLE H</p>
                  <p>SECTION 1.-1. The executive power shall be vested in a President of the Confederate States of America. He and the Vice President shall hold their offices for the term of six years; but the President shall not be re-eligible. The President and Vice President shall be elected as follows:</p>
                  <p>2. Each State shall appoint, in such manner as the Legislature thereof may direct, a number of electors equal to the whole number of Senators and Representatives to which the State may be entitled in the Congress; but no Senator or Representative, or person holding an office of trust or profit under the Confederate States, shall be appointed an elector.</p>
                  <p>
                    3. The electors shall meet in their respective States, and vote by ballot for President and Vice President, one of whom, at least, shall not be an inhabitant of the same State with themselves; they shall name in their ballots the person voted for as President, and in distinct ballots the person voted for as Vice President, and they shall make distinct lists of all persons voted
                    for as President, and of all persons voted for as Vice President, and of the number of votes for each, which list they shall sign and certify, and transmit, sealed, to the government of the Confederate States, directed to the President of the Senate; the President of the Senate shall, in the presence of the Senate and House of Representatives, open all the certificates, and the
                    votes shall then be counted; the person having the greatest number of votes for President shall be President, if such number be a majority of the whole number of electors appointed; and if no person have such majority, then, from the persons having the highest numbers, not exceeding three, on the list of those voted for as President, the House of Representatives shall choose
                    immediately, by ballot, the President. But in choosing the President, <PageNumber num={389} /> the votes shall be taken by States, the representation from each State having one vote; a quorum for this purpose shall consist of a member or members from two-thirds of the States, and a majority of all the States shall be necessary to a choice. And if
                    the House of Representatives shall not choose a President, whenever the right of choice shall devolve upon them, before the fourth day of March next following, then the Vice President shall act as President, as in case of the death, or other constitutional disability of the President.
                  </p>
                  <p>
                    4. The person having the greatest number of votes as Vice President shall be the Vice President, if such number be a majority of the whole number of electors appointed; and if no person have a majority, then from the two highest numbers on the list the Senate shall choose the Vice-President; a quorum for the purpose shall consist of two-thirds of the whole number of Senators, and
                    a majority of the whole number shall be necessary to a choice.
                  </p>
                  <p>5. But no person constitutionally ineligible to the office of President shall be eligible to that of Vice-President of the Confederate States.</p>
                  <p>6. The Congress may determine the time of choosing the electors, and the day on which they shall give their votes; which day shall be the same throughout the Confederate States.</p>
                  <p>
                    7. No person except a natural born citizen of the Confederate States, or a citizen thereof at the time of the adoption of the Constitution, or a citizen thereof born in the United States prior to the 20th of December, 1860, shall be eligible to the office of President; neither shall any person be eligible to that office who shall not have attained the age of thirty-five years,
                    and been fourteen years a resident within the limits of the Confederate States, as they may exist at the time of his election.
                  </p>
                  <p>
                    8. In case of the removal of the President from office, or of his death, resignation, or inability to discharge the powers and duties of the said office, the same shall devolve on the Vice President; and the Congress may, by law, provide for the case of removal, death, resignation, or inability both of the President and Vice-President, declaring what officer shall then act as
                    President, and such officer shall act accordingly until the disability be removed or a President shall be elected.
                  </p>
                  <p>9. The President shall, at stated times, receive for his services a compensation, which shall neither be increased nor diminished during the period for which he shall have been elected; and he shall not receive within that period any other emolument from the Confederate States, or any of them.</p>
                  <p>10. Before he enters on the execution of his office, he shall take the following oath or affirmation:</p>
                  <PageNumber num={390} />
                  <p>"I do solemnly swear (or affirm) that I will faithfully execute the office of President of the Confederate States, and will, to the best of my ability, preserve, protect and defend the Constitution thereof."</p>
                  <p>
                    SECTION 2.-1. The President shall be commander-in-chief of the army and navy of the Confederate States, and of the militia of the several States, when called into actual service of the Confederate States; he may require the opinion, in writing, of the principal officer in each of the Executive Departments, upon any subject relative to the duties of their respective offices; and
                    he shall have power to grant reprieves and pardons for offences against the Confederate States, except in cases of impeachment.
                  </p>
                  <p>
                    2. He shall have power, by and with the advice and consent of the Senate, to make treaties provided two-thirds of the Senators present concur; and he shall nominate, and by and with the advice and consent of the Senate, shall appoint, ambassadors, other public ministers and consuls, Judges of the Supreme Court and all other officers of the Confederate States whose appointments
                    are not herein otherwise provided for, and which shall be established by law; but the Congress may, by law, vest the appointment of such inferior officers, as they think proper, in the President alone, in the courts of law or in the heads of Departments.
                  </p>
                  <p>
                    3. The principal officer in each of the Executive Departments, and all persons connected with the diplomatic service, may be removed from office at the pleasure of the President. All other civil officers of the Executive Department may be removed at any time by the President or other appointing power, when their services are unnecessary, or for dishonesty, incapacity,
                    inefficiency, misconduct, or neglect of duty; and when so removed, the removal shall be reported to the Senate, together with the reasons therefor.
                  </p>
                  <p>4. The President shall have power to fill all vacancies that may happen during the recess of the Senate, by granting commissions which shall expire at the end of their next session; but no person rejected by the Senate shall be re-appointed to the same office during their ensuing recess.</p>
                  <p>
                    SECTION 3. The President shall, from time to time, give to the Congress information of the state of the Confederacy, and recommend to their consideration such measures as he shall judge necessary and expedient; he may, on extraordinary occasions, convene both Houses, or either of them; and in case of disagreement between them, with respect to the time of adjournment, he may
                    adjourn them to such time as he shall think proper; he shall receive ambassadors and other public ministers; <PageNumber num={391} /> he shall take care that the laws be faithfully executed; and shall commission all the officers of the Confederate States.
                  </p>
                  <p>SECTION 4. The President, Vice-President, and all civil officers of the Confederate States, shall be removed from office on impeachment for, and conviction of, treason, bribery, or other high crimes and misdemeanors.</p>
                  <p>ARTICLE III</p>
                  <p>
                    SECTION 1. The judicial power of the Confederate States shall be vested in one Superior Court, and in such Inferior Courts as the Congress may from time to time ordain and establish. The Judges, both of the Supreme and Inferior Courts, shall hold their offices during good behavior, and shall, at stated times, receive for their services a compensation, which shall not be
                    diminished during their continuance in office.
                  </p>
                  <p>
                    SECTION 2.-1. The judicial power shall extend to all cases arising under this Constitution, the laws of the Confederate States, and treaties made or which shall be made, under their authority; to all cases affecting ambassadors, other public ministers, or consuls; to all cases of admiralty and maritime jurisdiction; to controversies to which the Confederate States shall be a
                    party; to controversies between two or more States; between a State and citizens of another State, where the State is plaintiff; between citizens claiming lands under grants of different States, and between a State, or the citizens thereof, and foreign States, citizens or subjects; but no State shall be sued by a citizen or subject of any foreign State.
                  </p>
                  <p>2. In all cases affecting ambassadors, other public ministers, or consuls, and those in which a State shall be a party, the Supreme Court shall have original jurisdiction. In all other cases before mentioned, the Supreme Court shall have appellate jurisdiction, both as to law and fact, with such exceptions, and under such regulations, as the Congress shall make.</p>
                  <p>3. The trial of all crimes, except in cases of impeachment, shall be by jury, and such trial shall be held in the State where the said crimes shall have been committed; but when not committed within any State, the trial shall be at such place or places as the Congress may by law have directed.</p>
                  <p>SECTION 3.-1. Treason against the Confederate States shall consist only in levying war against them, or in adhering to their enemies, giving them aid and comfort. No person shall be convicted of treason <PageNumber num={392} /> unless on the testimony of two witnesses to the same overt act, or on confession in open court.</p>
                  <p>2. The Congress shall have power to declare the punishment of treason, but no attainder of treason shall work corruption of blood, or forfeiture, except during the life of the person attainted.</p>
                  <p>ARTICLE IV</p>
                  <p>SECTION 1.-1. Full faith and credit shall be given in each State to the public acts, records and judicial proceedings of every other State. And the Congress may, by general laws, prescribe the manner in which such acts, records, and proceedings shall be proved, and the effect thereof.</p>
                  <p>SECTION 2.-1. The citizens of each State shall be entitled to all the privileges and immunities of citizens in the several States, and shall have the right of transit and sojourn in any State of this Confederacy, with their slaves and other property; and the right of property in said slaves shall not be thereby impaired.</p>
                  <p>2. A person, charged in any State with treason, felony, or other crime against the laws of such State, who shall flee from justice, and be found in another State, shall, on demand of the Executive authority of the State from which he fled, be delivered up, to be removed to the State having jurisdiction of the crime.</p>
                  <p>
                    3. No slave or other person held to service or labor in any State or territory of the Confederate States, under the laws thereof, escaping or lawfully carried into another, shall, in consequence of any law or regulation therein, be discharged from such service or labor; but shall be delivered up on claim of the party to whom such slave belongs, or to whom such service or labor
                    may be due.
                  </p>
                  <p>
                    SECTION 3.-1. Other States may be admitted into'this Confederacy by a vote of two thirds of the whole House of Representatives, and two-thirds of the Senate, the Senate voting by States; but no new States shall be formed or erected within the jurisdiction of any other State; nor any State be formed by the junction of two or more States, or parts of States, without the consent of
                    the Legislatures of the States concerned as well as of the Congress.
                  </p>
                  <p>2. The Congress shall have power to dispose of and make all needful rules and regulations concerning the property of the Confederate States, including the lands thereof.</p>
                  <p>
                    3. The Confederate States may acquire new territory; and Congress shall have power to legislate and provide governments for the inhabitants of all territory belonging to the Confederate States, lying <PageNumber num={393} /> without the limits of the several States; and may permit them, at such times, and in such manner as it may by law provide, to
                    form States to be admitted into the Confederacy. In all such territory, the institution of negro slavery, as it now exists in the Confederate States, shall be recognized and protected by Congress, and by the territorial government; and the inhabitants of the several Confederate States and territories shall have the right to take to such territory any slaves, lawfully held by them
                    in any of the States or territories of the Confederate States.
                  </p>
                  <p>4. The Confederate States shall guaranty to every State that now is or hereafter may become a member of this Confederacy a republican form of Government, and shall protect each of them against invasion, and on application of the Legislature (or of the Executive when the Legislature is not in session) against domestic violence.</p>
                  <p>ARTICLE V</p>
                  <p>
                    SECTION 1.-1. Upon the demand of any three States, legally assembled in their several conventions, the Congress shall summon a convention of all the States, to take into consideration such amendments to the Constitution as the said States shall concur in suggesting at the time when the said demand is made; and should any of the proposed amendments to the Constitution be agreed on
                    by the said convention—voting by States—and the same be ratified by the Legislatures of two-thirds of the several States, or by conventions in two-thirds thereof—as the one or the other mode of ratification may be proposed by the general convention—they shall thenceforward form a part of this Constitution. But no State shall, without its consent, be deprived of its equal
                    representation in the Senate.
                  </p>
                  <p>ARTICLE VI</p>
                  <p>
                    1. The Government established by this Constitution is the successor of the Provisional Government of the Confederate States of America, and all the laws passed by the latter shall continue in force until the same shall be repealed or modified; and all the officers appointed by the same shall remain in office until their successors are appointed and qualified, or the office
                    abolished.
                  </p>
                  <p>2. All debts contracted and engagements entered into before the adoption of the Constitution shall be as valid against the Confederate States under this Constitution as under the Provisional Government.</p>
                  <p>
                    3. This Constitution, and the laws of the Confederate States made in pursuance thereof, and all treaties made, or which shall be made <PageNumber num={394} /> under the authority of the Confederate States, shall be the supreme law of the land; and the judges in every State shall be bound thereby, anything in the constitutions or laws of any State to
                    the contrary notwithstanding.
                  </p>
                  <p>
                    4. The Senators and Representatives before mentioned and the members of the several State Legislatures, and all executive and judicial officers, both of the Confederate States and of the several States, shall be bound by oath or affirmation to support this Constitution; but no religious test shall ever be required as a qualification to any office or public trust under the
                    Confederate States.
                  </p>
                  <p>5. The enumeration, in the Constitution, of certain rights, shall not be constructed to deny or disparage others retained by the people of the several States.</p>
                  <p>6. The powers not delegated to the Confederate States by the constitution, nor prohibited by it to the States, are reserved to the States, respectively, or to the people thereof.</p>
                  <p>ARTICLE VII</p>
                  <p>1. The ratification of the conventions of five States shall be sufficient for the establishment of this Constitution between the States so ratifying the same.</p>
                  <p>
                    2. When five States shall have ratified this Constitution, in the manner before specified, the Congress under the Provisional Constitution shall prescribe the time for holding the election of President and Vice President; and for the meeting of the Electoral College, and for counting the votes, and inaugurating the President. They shall also prescribe the time for holding the
                    first election of members of Congress under this Constitution, and the time for assembling the same. Until the assembling of such Congress, the Congress under the Provisional Constitution shall continue to exercise the legislative powers granted them; not extending beyond the time limited by the Constitution of the Provisional Government.
                  </p>
                  <p>Adopted unanimously, March 11, 1861.</p>
                </div>
              </div>
              <div className="speaker" id="sp1282">
                <span className="persName">Mr. FISHER</span>
                <div className="note" id="Note39">
                  <span className="noteLabel">6</span>In this letter (March 30) Mr. Fisher offered several corrections to his remarks at this point:
                  <p>CONVENTION, March 28, 1861</p>
                  <p>To the Editors of the Enquirer:</p>
                  <p>
                    I regret to be obliged to say that in the publication of the remarks which I made on Tuesday the 25th instant, in your paper of this morning, injustice, unintentionally doubtless, is done by the reporter of the sentiments I expressed. I stated, in commencing my remarks, that I had a great admiration for the constitution which has been adopted by the Confederated States, not
                    "veneration." But this is a matter of small moment. I remarked that the commission which I bear did not authorise me to look now to a reconstruction of the Union, because I do not believe there is the remotest hope of that; yet, as I believe a majority of the Convention did look to that, we should demand such guaranties as will be satisfactory to the Cotton States, and as would
                    restore them to the Union, if granted. In another connection I affirmed that the States are as sovereign and independent, to-day, as they were on the 1st day of September, 1787, not 1777, meaning thereby to declare that they are as sovereign and independent now as they were under the old Articles of Confederation. And I never spoke of seventeen slave States, but I did of seven,
                    as being associated now with nineteen free soil States. Nor did I ever speak of offering any proposition of any sort for a reconstruction of the Union. I believe that any man who entertains the opinion now that the Union can be reconstructed, and the Cotton States brought back into it, possesses a faith that can remove mountains.
                  </p>
                  <p>I have not attempted, nor shall I, to correct other errors, some of which are apparent, to be found in the report of my remarks.</p>
                  <p>Very respectfully, MIERS W. FISHER</p>
                </div>
                —
              </div>
              <p className="p-in-sp">
                I have great veneration for the Constitution which has been adopted by the Confederate States, South. I regard it as a considerable improvement upon the present Federal Constitution; but placed as this Committee now is, there would be an incongruity by voting for that as a whole, which I think it is possible for us to avoid. I would be very glad to have an opportunity to vote for
                these interpolations in the present Federal constitution, by the Confederate States, if they were proposed to the Northern States as amendments. But I am <PageNumber num={395} /> so circumstanced now by the action of my friend from Wetzel
                [<span className="persName">Mr. HALL</span>]
                , that I am called upon to vote for the preamble presented by the Committee on Federal Relations, which—they will pardon me for saying so—I think contains heresies that can never command my vote, and that is coupled with a Constitution that is entirely confined to seven States, so declared on its face. It could not, therefore, be presented by the Convention of Virginia, as an
                amendment or amendments, to be adopted by the Northern States. I could not, I beg to say, endorse the first resolution of the Committee on Federal Relations, which is in these words, and which my friend has tacked on to this Constitution of the Confederated States. The first resolution is
              </p>
              <p className="p-in-sp">"But the States which composed the United States of America, when the Federal Constituion was formed, were independent sovereignties; and in adopting that instrument, the people of each State agreed to associate with the people of the other States, upon a footing of exact equality."</p>
              <div className="speaker" id="sp1283"><span className="persName">THE CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair would state to the gentleman that the motion is to strike out all except the preamble, and substitute this Constitution in its place. The Committee will still have to pass upon the preamble.</p>
              <div className="speaker" id="sp1284"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I am very much obliged to the Chair, for I shall war against the sentiments expressed in that as a heresy, if my friends on the Committee on Federal Relations will take no offence at the expression. I would be very glad if the Committee will allow the gentleman from Wetzel [<span className="persName">Mr. HALL</span>]
                , until to-morrow morning to select out from the document those provisions which are not to be found in the present Federal Constitution, so that he may offer them in the morning as a substitute for the report of the committee of twenty-one. I think this is just to that gentleman, and certainly just to those who appreciate as I do the amendments which have been made by the
                Confederate States to the present Constitution of the United States. I take it that this may be allowed, because I believe, and believe sincerely, that if Virginia is to do anything which is to bring about a re-construction of the dis rupted Union, it can only be by asking such amendments to the present Federal Constitution as experience has taught the gentlemen of the South are
                absolutely essential for their own government. And, sir, whilst I am regarded as extreme and perhaps ultra, if I am to vote for any proposition in this body, it is with the sincere desire on my part—and this is the great object of the commission that I bear—that it will bring about a reconstruction of the old Federal Union. I should regard myself as false to my constituents, false to
                those purposes which ought <PageNumber num={396} /> to animate and control our conduct on the present occasion, if I were not to wish for those amendments to the Constitution which shall bring back those Confederated States into union with ourselves, at least—if I cannot get them into union with the Northern States. Our purpose and object being to
                restore the Union as it was—that is the purpose I have no doubt of a majority of gentlemen who are entitled to the honor of seats upon this floor—it becomes my duty to vote for a demand of those guaranties which shall accomplish the object, not in part, but in the whole; not that we can get guaranties which may satisfy Virginia alone, but such as will satisfy Kentucky, or Missouri,
                or Maryland, or North Carolina, or Tennessee, that State upon which so high an eulogy has been pronounced upon this floor.
              </p>
              <p className="p-in-sp">I, sir, want, if we are to demand guaranties, that they shall bring back in connection with Virginia the Cotton States of the South; and, sir, for one, I will never be satisfied with any guaranties that will not bring us in union together.</p>
              <p className="p-in-sp">
                We cannot afford, Mr. Chairman, to have a Southern Confederacy in the South, and a Northern Confederacy in the North. We cannot afford to be separated from the Confederated States of the South. We cannot, if we would, consent to connect ourselves permanently, or to adhere permanently to nineteen freesoil States when there will be seventeen slaveholding States. The cost would be too
                great for Virginia to bear; it is more than her people could bear. I hope, animated by the principles and sentiments enunciated to-day by the distinguished gentleman from Montgomery
                [Mr PRESTON]
                , we shall command such guaranties as ought to be sufficient to bring into the Union again the Confederated States of the South. Do not let us be guilty of the mockery of asking what will not restore the Union. The object of that gentleman, and most of the gentlemen who have spoken upon this floor, has been to restore the Union—to restore the Southern States. It may be that there are
                some on this floor like Horace Greeley, Wendell Phillips, who rejoice that the cotton States are sloughed off from the other slaveholding States of the South; but I know no such gentleman upon this floor, and believing and hoping that there are none such here, I ask that every gentleman in this body will vote for making such demands as will restore these States to us, as brothers and
                sisters, homogeneous in feeling, in interest, and whose honor, like our own, is to be protected and defended against the encroachments of Northern power.
              </p>
              <p className="p-in-sp">
                I owe no allegiance to the Federal Government. I hear my friends talk about allegiance to the Federal Government. Who ever heard <PageNumber num={397} /> before that a sovereign owes allegiance to a subject? I owe no allegiance to the Federal Government. I owe obedience to the Federal authority, which is consonant with the Constitution of the United
                States, because my own mother, Virginia, has taught me to yield obedience to the constitutional authority in the land. I never have yielded to the idea, and I hope gentlemen don't mean what they say when they talk about our owing allegiance to any government. We owe no allegiance to any government, especially the Federal Government, which is but the common agent of the States, and is
                not the master of the sovereign States of this Confederacy. My idea is that, in the language of this report which I intend to present, the States, under the articles of confederation, were sovereign and independent and I maintain the doctrine that, whilst they delegate'd the exercise of certain powers to the Federal Government as the common agent of all the States, they never
                surrendered one iota of sovereignty in doing so; that to-day, as on the first day of September, 1777, each State is sovereign and independent, but that every one of its citizens owes obedience to the Constitution of the United States, to the laws, and to the treaties made in pursuance of it, because his own sovereignty requires him to render and yield that obedience.
              </p>
              <p className="p-in-sp">But my object is to ask—and as I am not at all familiar with parliamentary law or parliamentary proceedings, I hope that either the Chair or some parliamentarian on the floor will instruct me how I may accomplish the object which I have in view—my object is to allow my friend from Wetzel [<span className="persName">Mr. HALL</span>]
                , to have until to-morrow morning to select such portions of the document that has been read to the house as will be new in its character, as will embody such amendments to the present Constitution as will accomplish the end which we have in view, if there is to be a re-construction of the Union—the bringing back of our cotton sister States of the South. Can the privilege be granted
                by having the committee rise?
              </p>
              <div className="speaker" id="sp1285"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Yes, sir.</p>
              <div className="speaker" id="sp1286"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">Then I ask that the committee rise.</p>
              <p>A division was called for on the question and the committee, by a vote of 63 to 43, refused to rise.</p>
              <div className="speaker" id="sp1287"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">I desire to obtain information from the Chair. In case the substitute presented by the gentleman from Wetzel is adopted, will it then be in order to move a substitute for it?</p>
              <div className="speaker" id="sp1288"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It will not, sir, in the committee.</p>
              <p><span className="persName">Mr. TURNER</span>, of Jackson, then addressed the Committee.</p>
              <div className="speaker" id="sp1289"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">I have prepared—with the exception of one or two <PageNumber num={398} /> sections, which are not now in the exact phraseology which I desire to put them in—a substitute which I desire to propose for the report of the Committee on Federal Relations, and which, I trust, will satisfy one gentleman—the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>]
                , who addressed the Committee to-day, because they, if adopted, in my opinion, will furnish some guarantees of some practical benefit and importance. I have compared this substitute with the report of the Committee on Federal Relations, and, with the exception of the phraseology I desire to have it in, I think it is a more valuable proposition. I cannot now vote for the substitute
                offered by the gentleman from Wetzel
                [<span className="persName">Mr. HALL</span>]
                . I look to the action of this Convention, and I desire to see it adopt such amendments to the Constitution of the United States, as I trust will not only protect the rights of Virginia, if she remains in this Union in case every other Southern slave State will withdraw, but that will secure you the return of your sister States of the South. I do not understand, sir, that the means
                proposed by the gentleman from Wetzel
                [<span className="persName">Mr. HALL</span>]
                , will bring about that result. Nor do I understand that it proposes any means which will suit me, for its submission to the States for ratification in case those amendments to the Constitution shall be adopted as a substitute for the report of the Committee on Federal Relations. Nor does the Committee's report propose the means for those steps that I think are necessary to that end.
              </p>
              <p className="p-in-sp">
                I am opposed to a Border State Conference; but I desire that amendments of the Constitution, which I trust will demand in language that cannot be susceptible of any two constructions, all the rights that Virginia and the other Southern States are entitled to in this Union, will be adopted by this Convention. I believe it would come with very bad grace for Virginia, after thrusting
                from her doors ambassadors from other States, asking counsel and concert with Virginia, to apply to other States herself for counsel. I demand that the honor of Virginia shall remain within her own keeping, and not be confided to the keeping of any other State. I desire that she shall speak in language which cannot be misunderstood, demanding the rights to which she is entitled, and
                knowing the means and the measure of her redress if she is refused. I propose that Virginia shall lay down her ultimatum and send it by a commissioner, to be appointed by this Convention, to each of the States now within the Union, who shall ask that on or before a given day, these amendments shall be approved or rejected by all the States within the Union; and in case of failure, on
                some certain day succeeding their rejection, Virginia shall stand out of this Union, to take that course which it is her right and her duty to take, making <PageNumber num={399} /> no alliance beforehand, but ready then to make such alliances in the future as shall promote her interest and preserve her honor. I have indicated before what will be her
                position if the alternative was presented, where will Virginia go—with a Northern or a Southern Confederacy—that her destiny is with the South. I have no change of sentiment upon that subject. But as I represent on this floor a people who are not practically or pecuniarily interested in this question, and as I desire that all the people of the State of Virginia shall be a unit upon
                it, I desire to have an opportunity of submitting to this Committee a proposition which I think will bring about that result. In order that I may not be misunderstood, I propose to detain this Committee for a few moments, while I call attention to the propositions which I wish to submit, providing for amendments to the Constitution.
              </p>
              <p className="p-in-sp">Sir, I do not desire, that in enunciating guarantees which Virginia is entitled to, or the causes which necessitate her to demand those guarantees, that any long preamble shall be offered to the people. I believe, as the gentleman from Princess Anne said yesterday, that words are things—</p>
              <div className="speaker" id="sp1290"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">They are everything.</p>
              <div className="speaker" id="sp1291"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">Yes, they are everything; and I desire that the fewest words that can express the meaning I wish to convey, should be used to express it; that it shall be so plain that a wayfaring man, though he runs, may read it. The preamble I desire, shall be as follows :</p>
              <p className="p-in-sp">"Seven States having withdrawn from the Federal Union and formed a new and distinct Government, it has become necessary for Virginia, if she remains in the present Federal Union, to obtain guarantees by way of amendments to the Constitution of the United States, upon the following points."</p>
              <p className="p-in-sp">
                The fact of the withdrawal, I desire to be stated as the cause of the necessity for the guarantees. That is all I wish to be stated in the preamble; that the position of Virginia, in the Northern Confederacy or the Union as it now exists, compels her to demand guarantees for her future safety. In arriving at those guarantees, I wish that they shall be such that she can remain in this
                Union alone; that if every other sister slave State shall withdraw, she may retain self-protecting power. I propose to show the manner in which that self-protecting power may be secured. The first point I propose is:
              </p>
              <p className="p-in-sp">"1st. A recognition, that by virtue of the Constitution, African slavery does exist in all the territory of the United States, and must be protected by the Federal Government."</p>
              <p className="p-in-sp">That by virtue of the Constitution of the United States, African <PageNumber num={400} /> slavery, sir, not "involuntary servitude," not "persons held to labor," or anything else, but that "African slavery does exist in all the territory of the United States, and must be protected by the Federal Government."</p>
              <p className="p-in-sp">The second proposition is:</p>
              <p className="p-in-sp">2d. Upon all questions relating to the acquisition of territory a concurrent majority of the Senators in Congress from the slaveholding and non-slaveholding States shall be required.</p>
              <p className="p-in-sp">
                Sir, I am opposed to compromising away our rights. We may corn-- promise the question of the division of the territory—the mere division of the land—but a right within the Territory is an entirely different thing. I am opposed to compromising our rights as citizens of a confederacy or citizens of a State. But, sir, I am in favor of a com promise in regard to the settlement of the
                Territory itself, as far as the possession of the Territory is concerned.
              </p>
              <p className="p-in-sp">The third section I propose is as follows:</p>
              <p className="p-in-sp">
                3d. With a view to settle the vexed question of the territories it is agreed that in all the territory of the United States, now held or hereafter acquired, situate North of 36 degrees 30 minutes north latitude, slavery is prohibited, and in all the territory of the United States now held or hereafter acquired South of said line, African slavery is recognized as existing, and shall
                receive its necessary protection as property from the various departments of the Government.
              </p>
              <p className="p-in-sp">
                But, sir, there is one other proposition which I deem of more importance to Virginia if she remains in this Union—a proposition which I believe, if any guarantee can bring back the seceded States, will produce that result. It was not the mere question of slavery that has carried off the seceded States of the South. It was a question of taxation, a question of tariff. They knew well
                what they were doing. They knew, the very best means to prosecute this irrepressible conflict against slavery was by means of taxation, to make that species of property valueless; and they knew that the Black Republican party would inaugurate a system of taxation which would deprive them of the benefit of that property. And they were not mistaken, for, as soon as that party has come
                into power, we find them inaugurating a tariff imposing the most oppressive taxation that was ever inflicted on any people. In order to prevent that oppression, I offer this as the fourth proposition:
              </p>
              <p className="p-in-sp">4th. On all questions relating to laying taxes, duties, imposts and excises, or any other means necessary to raise revenue for the support of the General Government, a concurrent vote of a majority of the <PageNumber num={401} /> Senators in Congress, from the slaveholding and non-slaveholding States, shall be required.</p>
              <p className="p-in-sp">I think the gentleman from Montgomery, [<span className="persName">Mr. PRESTON</span>]
                , will find a tangible guarantee in this: that upon all questions relating to laying duties, imposts and excises, or any other means necessary to raise revenue, for the support of the General Government, a concurrent vote of a majority of the Senators in Congress from the slaveholding and non-slaveholding States, shall be required, and if that is done Virginia may remain satisfied
                that she cannot be imposed upon.
              </p>
              <p className="p-in-sp">The other points are as follows:</p>
              <p className="p-in-sp">5th. The right of transit by the citizens of the several States with their property, slaves included, through the States and Territories.</p>
              <p className="p-in-sp">6th. The rendition of fugitive slaves, and in case of their loss by violence or intimidation, remuneration by the General Government to the owner; and Congress shall provide for its reimbursement by laying and collecting a tax upon the State, city, or county in which said loss occurred.</p>
              <p className="p-in-sp">7th. That Congress shall not abolish or interfere with slavery, as it now exists, in the District of Columbia; nor shall it abolish or interfere with slavery in any of the States, by whose laws it is or may be allowed or permitted.</p>
              <p className="p-in-sp">8th. The withholding from persons who are in whole or in part of the African race, the elective franchise and the right to hold office whether Federal, State, Territorial or Municipal.</p>
              <p className="p-in-sp">9th. Congress shall have no power to abolish slavery in places under the exclusive jurisdiction of the Federal Government, and situate within the limits of States that permit the holding of slaves.</p>
              <p className="p-in-sp">10th. That the importation of slaves from foreign countries into the United States shall be forever prohibited.</p>
              <p className="p-in-sp">11th. That Congress shall have no power to interfere with the slave trade between the States.</p>
              <p className="p-in-sp">12th. That the foregoing amendments shall not be subject to repeal or modification except with the consent of all the States of the Union.</p>
              <p className="p-in-sp">And in order that this proposition may be fairly submitted to the people of this country in a manner becoming the dignity of the people of Virginia, I propose the following course of proceeding:</p>
              <p className="p-in-sp">"And in order that amendments to the Constitution of the United States, carrying into effect the foregoing suggestions shall be submitted to the people of the several States now in the Federal Union for their approval or rejection, therefore,</p>
              <p className="p-in-sp">
                "Resolved, That this Convention will appoint one Commissioner to <PageNumber num={402} /> each of the States of the Union, who shall be instructed to submit to the Legislature of said States, if in session, and if not, to the Governors of said States, the foregoing amendments, with the request that the same may be acted on either by the Legislatures of
                said States, or by Conventions called by the Legislatures of said States, on or before the first Monday in October next.
              </p>
              <p className="p-in-sp">"Resolved, That in case said amendments shall not be approved by the Legislatures or Conventions of said States on or before the first Monday in October next, that then an ordinance to the effect indicated in the following resolutions, shall go into operation on the third Monday in October next.</p>
              <p className="p-in-sp">
                "Resolved, That this Convention will adopt an ordinance resuming to the State of Virginia the powers heretofore delegated by her to the Federal Government, to take effect on the 3d Monday in October next, in case the amendments indicated in the foregoing suggestions are not approved by 'the several States of the Union on or before the first Monday in October next.
              </p>
              <p className="p-in-sp">"Resolved, That the said amendments and ordinance be submitted to the people of this State at the next general election for their approval or rejection."</p>
              <p className="p-in-sp">
                Sir, I desire that whatever is determined upon by this Convention, shall be submitted for the adoption or rejection of the people of Virginia, and I believe they will ratify by their action whatever this Convention shall, upon due deliberation, determine upon, if our action shall be such as the honor and safety of Virginia demands. I believe this is a time when Virginia ought to take
                high grounds. I believe the condition of the Northern mind is such that if Virginia does not get all her rights and her interests demand, it will be because she does not demand it. I believe the people of the North are ready, because they cannot help themselves, to grant to Virginia every thing she is entitled to ask at their hands. I believe that Virginia will accept from us no
                propositions that do not contain in themselves what, if adopted, will settle, and settle forever, all the questions that divide us.
              </p>
              <p className="p-in-sp">
                But, sir, we are told that the country is going to destruction—that every species of business is depressed; that all the avenues to commerce are being closed. Sir, I can tell you the only way in which these avenues to commerce can be re-opened. It is by restoring some degree of confidence to the minds of the people. What will restore that confidence more speedily than for us to
                announce to the world exactly the position we intend to assume? The people will then be prepared for <PageNumber num={403} /> the result of that action, whatever it may be; they will be prepared to meet the issue, whatever it may be.
              </p>
              <p className="p-in-sp">
                It is true, as I said before, that I come here from a portion of the State which has, comparatively speaking, no interest in this question of slavery; but, sir, I come from a portion of the State which I deem to be as loyal to the honor and interests of the South as any people within her broad limits. Aspersions have been cast by gentlemen—unintentionally, I know—upon the integrity
                and loyalty of the people of North-western Virginia, to this institution of slavery. I stand here to repel the insinuation. I appeal to history for the vindication of the fact, that they are as loyal to this State, and to her institutions, as any people within her limits. I appeal to history to show, that from time immemorial there has been one scene of sacrifice upon the part of
                Western Virginia to the interests of Eastern Virginia. She has been always true to slavery, and she has been so under circumstances, when probably no other portion of the State, so situated, would have been. This battle has been fought in Western Virginia, to my certain knowledge, during the last ten years, upon the question of protection to property within the territories of the
                United States, and of all the property of all the citizens of all the States. In the last canvass, gentlemen as distinguished for their ability and eloquence as any who have the honor of seats upon this floor, canvassed the entire region, urging and insisting that those who supported Breckinridge and Lane for the Presidency, were aiding and assisting a party having for its object the
                dissolution of the Union, and that the success of that party in this State would bring about a disruption of this government. I have heard them again and again urge that argument, with an eloquence almost irresistible; and yet you know, gentlemen, how Western Virginia came up to the support of these candidates. If their brethren of the East had done as well, the electoral vote of the
                State would have been cast for these gallant men. I do not make these allusions for the purpose of stirring up party feeling, for I desire that upon all questions touching the honor and interest of this Commonwealth, every party issue should be laid aside. I merely allude to the matter as a part of the history of our people, and in refutation of the insinuations which have been made
                against their loyalty.
              </p>
              <p className="p-in-sp">
                Sir, I claim as much fealty to this proud old State as any of her sons, although she is only my mother by adoption; but while that is true she holds my mother State cradled in her arms, and she stands linked with my native State together, and their destinies are one. And, sir, if Virginia is not the land of my birth she has as dear a claim upon
                <PageNumber num={404} /> my affection. She is the mother of my children, and I am determined, for them, at least, to demand, that all the glories that cluster around the bright past, shall be a part of their heritage. With that determination I desire that this Convention shall insist on one thing, and in a manner that cannot be misunderstood. I desire
                guaranties to protect the liberty of her people.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, it has been urged upon this floor that Virginia was standing in such a position on account of her border that she could not leave this Union; with a territory so exposed that she would have to bear the brunt of any conflict with the North that may come. I believe none will come, but if it must come we will try to meet it as men should meet it. It is not the first time
                in the history of this country or the history of revolutions that men have been appealed to, to forego their rights on account of the dangers and difficulties that stood in the way of their demanding and maintaining them. Sir, if our fathers had yielded to such an appeal the great battles of liberty which resulted in the formation of this republic, at the cost of all the blood of the
                revolution would not have been fought, and we could not have been here this day demanding from our Northern aggressors the rights we are entitled to. Sir, these are appeals which will have no influence with me. They are appeals addressed not to the manliness and courage of a people, but to their cowardice and fears. Then I trust there will be found within the limits of Virginia some
                at least catching the inspiration from the apostle of liberty whose name is now immortal, who, when told that we would be overwhelmed by our mother England, stood up in the halls of Virginia and said that instead of being weak, we are strong; that- a free people armed in a holy cause, with a just God fighting their battles, were not weak but strong. So I say to-day, that this people
                should not for a moment take into consideration the consequences that would ensue from the assertion of their rights. Let us ascertain whether we are right; let us know what our rights are, and then demand them as our rights ought to be demanded; not as braggarts demand them, but calmly as brave men always demand their rights, and with all the dignity of a free people.
              </p>
              <p className="p-in-sp">
                Sir, the song of the Union is about sung out—the old Union no longer exists, and all that remains is the Northern Confederacy, a fraction, a part of the old Union. As the gentleman from Princess Anne said the other day, there are three Unions, two existing and one destroyed. The only question for us to decide is, what shall Virginia do.. I think I have proposed a plan by which she
                will be safe in her honor and her interests even if she has to stand alone. God knows she has <PageNumber num={405} /> sons enough to protect her under all circumstances. But if she cannot remain with the North on proper terms her destiny, in my opinion, is with the Southern States.
              </p>
              <p className="p-in-sp">Now, sir, I trust at this late hour it will be the pleasure of the Committee to rise, and I make that motion.</p>
              <p>The motion was disagreed to.</p>
              <p><span className="persName">Mr. HALL</span>, of Wetzel, obtained the floor.</p>
              <div className="speaker" id="sp1292"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I was about to ask my friend from Wetzel to withdraw his proposition. There are incongruities in the adoption of such an amendment which will prevent me from voting for it.</p>
              <div className="speaker" id="sp1293"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">
                I will state to the gentleman that I had no purpose of offering the amendment to-day; certainly not in this form. I had an amendment prepared, which I supposed I had with me, but find I have not, which it was my intention to offer to-morrow morning. Under the circumstances which existed, however, I was compelled to offer that whole paper because the Committee was not disposed to
                extend to me the courtesy which they have extended to all gentlemen. I now see proper to avail myself of my rights upon this floor, and I can talk here all night if necessary.
                [Cries of "go on" and laughter.] I understand that the Committee have entire control of this matter, and if there be no objection, I am willing to withdraw my proposition.</p>
              <p>Several members objected.</p>
              <p>The CHAIRMAN then put the question, and permission to withdraw was refused—ayes 40, noes 65.</p>
              <p><span className="persName">Mr. FISHER</span> moved that the Committee rise, and asked for the yeas and nays upon the motion.</p>
              <p>
                The yeas and nays were ordered. The question was taken and it was decided in the negative—yeas 37, nays 76—as follows:<span className="note" id="Note40"><span className="noteLabel">7</span>Both Proceedings and Journal state that there were 76 negative votes. However, the Proceedings list only 73, while the Journal lists 74, adding Hammond to negative votes listed in the Proceedings.</span>
              </p>
              <p>YEAS—Messrs. Ambler, Blakey, Boisseau, Borst, Boyd, Chambliss, Coffman, Conn, R. H. Cox, Fisher, Goggin, John Goode, Jr., L. S. Hall, Harvie, Holcombe, Hunton, Isbell, Kilby, Kindred, Lawson, Leake, Macfarland, C. K. Mallory, Marye, Sr., Montague, Morris, Neblett, Randolph, Richardson, Sea-well, Strange, Thornton, Franklin P. Turner, Tyler, Whitfield, Wise, Woods-37.</p>
              <p>
                NAYS—Messrs. Armstrong, Aston, Baldwin, A. M. Barbour, Berlin, Blow, Jr., Boggess, Brent, Brown, Burdett, Burley, Byrne, Cabell, Campbell, Caperton, Carter, Chapman, Clemens, R. Y. Conrad, C. B. Conrad, J. H. Cox, Deskins, Dorman, Dulany, Early, Echols, Forbes, French, Fugate, Garland, Gillespie, Gravely, Gray, Addison Hall, Ephraim B. Hall, Haymond, Holladay, Hubbard, Hughes, Hull,
                Jackson, Marmaduke Johnson, Peter C. Johnston, Lewis, McComas, McGrew, McNeil, J. B. Mallory, Marshall, Maslin, Masters, Moffett, Nelson, Orrick, Osburn, Parks, Pendleton, Porter, Preston, Price, Pugh, Robt. E. Scott, Sharp, Sheffey, Sitlington, Slaughter, Staples, Chapman J. Stuart, Sutherlin, Tayloe, Waller, White, Willey-76.
              </p>
              <PageNumber num={406} />
              <div className="speaker" id="sp1294"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">I offered that substitute, sir, for the very best of purposes and the purest of motives, and I make this remark, sir, for the purpose of relieving myself from the imputation that was attempted to be cast upon this movement by the gentleman from Ohio [<span className="persName">Mr. CLEMENS</span>]
                , that this substitute was offered for the purpose of delay. I disclaim any such purpose in offering it. I offered that substitute as a Union man, for I claim to be a better Union man than any gentleman who has taken a different position on this floor—better than any Union-shrieker in this Hall. And, sir, I want this Union reconstructed. I want it placed back in the same position in
                which it was. I want the old United States reconstructed in all their parts. And, sir, for the life of me, I cannot see how you are going to do that upon any hypothesis such as you seem to be proceeding upon—that these Southern States were absolutely dependent upon you. Why, sir, they care as little about you as you do about them; and the truth is, I believe that they would rather,
                for the present, at least, that we would not attach ourselves to them. They are independent of us and the world. There are no people, there is no nation, that can make war upon them successfully, without injuring themselves in such a manner as to admonish them against such a policy. Is it not a fact, sir, that this whole body politic is sick, and does it not want some remedy? Does it
                not demand a remedy at the hands of some person or persons, or from some source? Now, sir, I cannot better express my idea upon this subject than by referring to those immortal words penned by Thomas Jefferson in the Declaration of Independence—that all men are entitled to life, liberty, and the pursuit of happiness. Life there is used in a governmental sense, in contra-distinction
                from private or individual life. Governmental life is that life the Declaration of Independence says cannot be alienated. No government, no people in a governmental sense can alienate that right, or transfer it, or forfeit it in any way. An individual can forfeit his life, but a community—a government—never can forfeit this right to life. Then, sir, talk to me about the
                sovereignties—talk to me about the State sovereignties alienating their rights away, when the Declaration of Independence expressly says that they are inalienable—that you cannot destroy them—that no compact, no bargain that is possible to be made can alienate the rights of a government.
              </p>
              <p className="p-in-sp">
                How do you perpetuate this governmental life? It requires as much care, as much attention, as much food and as many prescriptions, sir, as the individual life does, if not more. The whole body politic has become corrupt; every vein, every artery, every duct that leads to the <PageNumber num={407} /> great heart of this body politic, is corrupt to-day;
                and if the source from whence all the supplies are derived, is impure 'and corrupt, a remedy should certainly be applied and the fountain head purified.
              </p>
              <p className="p-in-sp">Well, sir, if the function of Government is to preserve to all life, liberty, and happiness—and all the people which constitute the source of Government, shall have their rights—then I ask if the State of Virginia, or rather her people, enjoys that sovereignty to-day which can enable her to guarantee these rights?</p>
              <p className="p-in-sp">
                Has the State of Virginia that liberty which would enable her to maintain these rights for her people? Has she liberty under the stars and stripes? She has delegated certain powers for specific purposes severally and specifically set forth in the contract. She is still complying with the terms of that contract, though in the opinion of every gentleman on this floor, that contract has
                been broken by the Northern section of this Confederacy; and according to the authority of Mr. Webster himself, it is not binding upon Virginia now.
              </p>
              <div className="speaker" id="sp1295"><span className="persName">Mr. J. B. MALLORY</span>—</div>
              <p className="p-in-sp">Will the gentleman give way for a moment.</p>
              <div className="speaker" id="sp1296"><span className="persName">Mr. HALL</span>—</div>
              <p className="p-in-sp">Certainly, sir.</p>
              <div className="speaker" id="sp1297"><span className="persName">Mr. MALLORY</span>—</div>
              <p className="p-in-sp">I move that the committee now rise.</p>
              <p>The motion was lost.</p>
              <div className="speaker" id="sp1298"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">
                I was just stating that Virginia was not free in this government, that she has not liberty and equality in this government. That government, sir, ever since it has been formed, has been an injury rather than a blessing to Virginia. There has been a persistent effort on the part of the Northern Confederacy, from the foundation of the government to this day, to plunder and to rob
                Virginia and the South. The whole patronage and expenditure of the government, or nearly all, has been confined to the Northern section of this Confederacy. They have by a system of legislation in the General Government, appropriated all the benefits of the government to themselves. They have all this system of legislation so arranged that they secure all the benefits and we get
                nothing. Does any man deny that proposition? I make this declaration here, that as long as the Yankees could make money out of the slave trade, they were in love with the South; but as soon as they were prevented from doing this, they turned upon the Southern people and warred upon them and their institutions relentlessly. This is well known. No man will deny that the chief part, if
                not all of that trade was carried on by the North. And yet we find the people of that section as soon as that infamous traffic is arrested, denouncing the institution of slavery, patriarchal and benign as it is.
              </p>
              <div className="speaker" id="sp1299"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">
                I beg leave to say that the course of the Committee <PageNumber num={408} /> has been heretofore altogether indulgent to every gentleman who has in any way claimed its kindness. It is apparent that the gentleman who is now addressing the Committee is laboring and must be sensible of exhaustion. I would suggest, if the Committee would now rise, that
                probably in the morning it would be the disposition of the Committee to allow the gentleman to withdraw his proposition. If that course is adopted, in all probability, the question may be viewed in a different aspect, and the difficulty now existing be removed. I, therefore, ask that in consideration of the lateness of the hour, in consideration of the respect that is due to the
                gentleman from Wetzel, as a member on this floor, it will be the pleasure of the Committee now to rise. I accordingly submit a motion to that effect.
              </p>
              <p>The question was put by yeas and nays, upon that motion, and decided in the negative yeas 40, nays 59.</p>
              <div className="speaker" id="sp1300"><span className="persName">Mr. HALL</span>—</div>
              <p className="p-in-sp">I think that the Committee will get tired quite as soon of me as I will of them. [Laughter.] Mr. Chairman, I was remarking when I gave way to the gentleman from Richmond [<span className="persName">Mr. MACFARLAND</span>]
                , to submit a motion that the Committee rise, that the slavery question was not the main or principal question that produced the difficulty and troubles in the country at this time. I stated that I differed from gentlemen upon this Committee as to the cause of our troubles. For what purpose does the Yankee want power and to what expedients does he resort to obtain power, and to what
                extent will he go to obtain power for its own sake? Go to Massachusetts or any of the New England States, and you will find that the largest operators, those who employ a thousand or fifteen hundred operatives, derive their support and the material upon which they all operate from the cotton producing States of the South; and that they are intimately connected with and dependent upon
                them in this regard. A person at first thought would think that they would be so intimately connected and so dependent upon this system of labor at the South that they would foster and protect it; but you find this anomaly, that these manufacturers and operatives are the most violent and bitter enemies of the institutions of the South, and denounce them unsparingly at all times and
                on all occasions. The press, the pulpit, the school-house and the Sabbath school, all exert their influence in hostility to the institutions of the South, and the whole country is taught to abuse us, and for what purpose? For the purpose of power—the people being the source of power. What do they do with power? The very first thing they do is to pass a Morrill tariff bill for the
                purpose of robbing and plundering <PageNumber num={409} /> the South. You take the constitution of the Confederated States of the South, and you at once cut off any inducements of that sort to obtain power in this way. You strike the axe at the root of the evil at once. The Constitution of the Confederated States of the South, I might say, has an
                express prohibition that these privileged laws shall not be passed. If you had such a provision in the Federal Constitution as is contained in the Constitution of the Confederated States, my word for it, they would have no motive to obtain power. After the American system was broken down; after it was repudiated by the people of the country, William H. Seward having advocated that
                system as long as he could, went to work to obtain the power which he had lost, through the religious element and prejudices of the people of the North, and he succeeded in it. And the very moment he obtained it, you see the result. It was what the Yankee operator has been working for all the time. He has had no other object in view. The people of the North, and especially of New
                England, look upon the people of the South as dependent provinces; and you permit this system of plunder to go on; you permit it to be perpetuated in this Northern Confederacy, and my word for it, if you allow this state of things to continue, you will be to the North what Ireland is to England, what Hungary is to Austria, what Poland is to Russia. Sir, I do not blame the Yankees. I
                am not traducing them for the spirit they manifest, because I imagine that we should be like them if we got the power and could make money by it. I do not believe that New England to-day would abolish slavery, if she could. I do not believe that they would interfere with it in the States in which it exists, if they could. It is very true that there are some fanatics, Wendell
                Phillips, Wm. Lloyd Garrison, Gerrit Smith, and a few others of that stripe, who would interfere with domestic slavery in the States where it exists; but they are better men this day, before high Heaven and before all just and true men, than Wm. H. Seward and that class of politicians at the North. They act out honestly, fairly and boldly their sentiments, and they tell you what
                their purposes are and what they will do. They tell you honestly their motive, that it is for philanthropy and to conserve and carry out the great principle of the Declaration of Independence and the principle of equality amongst the races.
              </p>
              <div className="speaker" id="sp1301"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Will my friend give way for a moment, to allow me to make an appeal to the House? What is the object of holding on here to-night, upon this proposition? Nobody seriously expects a vote upon it. Do gentlemen expect to get through those propositions, if they retain them upon the table? It is impossible, if any one man in this
                <PageNumber num={410} /> house chooses to make a contest, to get through with them in a month. It is really wasting our time, wearing out our physique, to obtain nothing. I assure you, gentlemen, I regret that these propositions have been offered. The house has refused to let the gentleman from Wetzel withdraw them. The gentleman from Wetzel has a fine
                physique, and I am sure that, even speaking, he can wear the most of you out, while in your seats. Suppose, when he is done, he calls for a division of the question, which is almost a book in itself. He can debate every proposition; he can cut it up in detail, so that you would have to stay here, not a night, but a month. All this is absurd. Will the conscript fathers of the State,
                sitting at night, waste away their strength over propositions of this sort, while really the whole country is in sackcloth and ashes over the volcano of revolution? I beg the house either to let the gentleman withdraw his propositions, or his object being merely to get until to-morrow morning in which to amend them, for God's sake let him have until to-morrow morning. I ask the
                house, then, if it be in order, for leave to let the gentleman from Wetzel withdraw his propositions, and for the committee to rise.
              </p>
              <div className="speaker" id="sp1302"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">That motion has already been made, and voted down.</p>
              <div className="speaker" id="sp1303"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I ask if there is no objection.</p>
              <div className="speaker" id="sp1304"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I object.</p>
              <div className="speaker" id="sp1305"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I move that the committee rise.</p>
              <div className="speaker" id="sp1306"><span className="persName">Mr. COX</span>—</div>
              <p className="p-in-sp">Upon this I ask the yeas and nays.</p>
              <p>The yeas and nays were ordered.</p>
              <p>
                The question was taken, and the motion was not agreed to—yeas 32, nays 63, as follows:<span className="note" id="Note41"><span className="noteLabel">8</span>Both Proceedings and Journal state that there were 63 negative votes. However, the Proceedings list only 61, while the Journal lists 62, adding Carter to negative votes listed in the Proceedings.</span>
              </p>
              <p>AYES—Messrs. Ambler, Boyd, Chapman, Coffman, Richard H. Cox, Fisher, Garland, Goggin, John Goode, Jr., Thomas F. Goode, L. S. Hall, Harvie, Kilby, Lawson, Leake, Macfarland, Charles K. Mallory, Jas. B. Mallory, Marye, Sr., Morris, MOORE, Randolph, Richardson, Robt. E. Scott, Sheffey, Speed, Strange, Thornton, F. P. Turner, Whitfield, Wise, Woods-32.</p>
              <p>
                NOES—Messrs. Armstrong, Aston, Baldwin, A. M. Barbour, Berlin, Boggess, Brown, Burdett, Burley, Byrne, Cabell, Campbell, Clemens, C. B. Conrad, R. Y. Conrad, James H. Cox, Custis, Deskins, Dorman, Early, Echols, French, Fugate, Gillespie, Gravely, Addison Hall, Ephraim B. Hall, Hammond, Haymond, Hoge, Holladay, Hubbard, Hughes, Hull, Jackson, Marmaduke Johnson, Peter C. Johnston,
                Lewis, McComas, McGrew, McNeil, Marshall, Maslin, Masters, Moffett, Nelson, Orrick, Osburn, Parks, Pendleton, Porter, Preston, Price, Pugh, Rives, Sharp, Slaughter, C. J. Stuart, Sutherlin, White, Willey-63.
              </p>
              <p>So the Committee refused to rise. Pending the call of the roll, <PageNumber num={411} />.</p>
              <div className="speaker" id="sp1307"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I rise to make a personal request of the Chair. A friend has been kind enough to pair off with me. I shall retire from the body to night, and I would ask you, Mr. Chairman, as a matter of favor, if a call of the House is made, to report my excuse as sick, sick in every sense.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp1308"><span className="persName">Mr. HALL</span>, of Wetzel [resuming]—</div>
              <p className="p-in-sp">There is no man in this Convention who regrets this state of affairs more than I do. I had no idea or expectation that the proposition I offered would elicit any debate whatever. I merely offered a paper without asking that it be read. I was not disposed to detain the Committee a moment. I supposed I had the substantial amendments I desired to offer with me.</p>
              <p className="p-in-sp">
                Mr. Chairman, I stated to the committee when I presented the paper that I had no intention of producing delay, but my intention was seriously to press upon the committee the consideration of the question of the amendments to the constitution as adopted by the Confederated States of America, as the only true principle upon which we could act and operate to reconstruct the old Union.
                When I found that the committee was about to rise I asked leave to withdraw that proposition. That courtesy was refused me. Then I asked the privilege of letting the question go over till to-morrow morning. That courtesy was also refused me—a courtesy which has been extended to other gentlemen on this floor. I hope, sir, I have not offended the committee in any manner that would
                justify the treatment that I have received at their hands. I would remind gentlemen that sometimes there is an opportunity to give tit for tat; and as I have my rights upon this floor as well as anybody else, I will demand them, and no power on earth shall take them from me. I am not going to indulge in abuse of this body or of this committee, but I will now make this declaration,
                that we are getting a great deal of distinction and notoriety. I have no aspirations to distinguish myself here by originating any proposition. I am not so vain as to believe that I could improve upon the handiwork of those who framed those amendments to the Federal Constitution which are embraced in the Constitution of the Confederate States of the South; I am disposed to give the
                credit to whom it belongs. Therefore it was in no spirit of vanity or vain glory that I presented it; but I say that, if the gentlemen want to have their names handed down as second James Madisons and Thomas Jeffersons, I think they will find themselves very much mistaken; and when the future historian shall record the acts of this Convention and of this Committee, he
                <PageNumber num={412} /> will record them in the terms of the Hartford Convention.
              </p>
              <div className="stage it">[Cries of "Order, order!"]</div>
              <div className="speaker" id="sp1309"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman will come to order and confine himself to the question before the Committee.</p>
              <div className="speaker" id="sp1310"><span className="persName">Mr. HALL</span>—</div>
              <p className="p-in-sp">I am speaking upon this question of notoriety. I have always been willing to extend a reasonable courtesy to any gentleman in the Convention, and I demand the same for myself.</p>
              <div className="speaker" id="sp1311"><span className="persName">Mr. SHEFFEY</span>, of Smyth—</div>
              <p className="p-in-sp">
                Will the gentleman from Wetzel allow me to make a personal appeal? I do not see that any earthly good can be accomplished by this protracted struggle. It is presenting a deliberative body before the country in an attitude in which a body like this ought not to be presented. This is a struggle that can accomplish no good whatever. I am free to say, that whilst I have voted to extend
                this courtesy asked for by the gentleman from Wetzel, on every occasion except one, I am perfectly satisfied, that when the vote is taken, an overwhelming majority will vote down this proposition of the gentleman from Wetzel. Now, if there was something like an equal division of the body on this question, some purpose might be accomplished by this protracted debate. I suggest that
                the gentleman would relieve himself and the Committee by permitting a vote to be taken now upon his proposition. At the same time, I am constrained to assure the gentleman that, not having had an opportunity of examining fully this Constitution of the Confederate States, I must vote against that proposition; I also feel certain that the relations which I hope I sustain to that
                gentleman will justify me in making this appeal. I should be glad that this question would be taken, so that we may be permitted to retire to our respective abodes, instead of being here at this unseasonable hour.
              </p>
              <div className="speaker" id="sp1312"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">
                It would afford me infinite pleasure to comply with the request of the gentleman; and, so far as the gentlemen who have voted with me are concerned, I must say that I sincerely regret the position in which they are placed. It is a source of great pain to me that my friends find themselves in the position in which they are placed. But, sir, I have asked nothing of this committee but
                the usual courtesy that has been asked by gentlemen upon this floor, and that has been extended to every man upon this floor who requested it. I tell you I do feel upon the subject. I have just as good a heart and as brave an arm as any man who lives within the limits of the State of Virginia. This Committee can relieve themselves in a moment, by extending the usual courtesy to me
                that they have extended to other gentlemen. I simply asked to have the consideration of this proposition <PageNumber num={413} /> postponed till to-morrow morning, or that I might be permitted to withdraw it. Every courtesy that I have asked has been refused me, and I want the fact to go to the country, sir.
              </p>
              <div className="speaker" id="sp1313"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">
                I have no doubt the committee will feel at this time, that some courtesy is due to the gentleman from Wetzel as well as to other gentlemen on this floor. He desires to present his views upon the question of the proposition which he has submitted. There can be no harm in letting the subject remain over till to-morrow morning, and in order to have that courtesy extended to the
                gentleman, I move that the committee do now rise, and on that I demand the ayes and noes.
              </p>
              <p>The ayes and noes were called, and the committee refused to rise, by a vote of 32 to 59, as follows:</p>
              <p>YEAS—Messrs. Ambler, Boyd, Chapman, Conn, R. H. Cox, Fisher, Garland, Goggin, John Goode, Jr., Thos. F. Goode, L. S. Hall, Harvie, Haymond, Kilby, Lawson, Leake, Macfarland, C. K. Mallory, J. B. Mallory, Marye, Sr., Morris, Neblett, Randolph, Richardson, R. E. Scott, Sheffey, Speed, Thornton, F. P. Turner, Whitfield, Williams, Woods-32.</p>
              <p>
                NAYS—Messrs. Armstrong, Aston, Baldwin, A. M. Barbour, Berlin, Boggess, Brown, Burdett, Burley, Byrne, Campbell, Carter, Clemens, C. B. Conrad, R. Y. Conrad, J. H. Cox, Custis, Deskins, Dorman, Early, Echols, Forbes, French, Fugate, Gillespie, Gravely, A. Hall, E. B. Hall, Hammond, Hoge, Hubbard, Hughes, Hull, Jackson, Marmaduke Johnson, Peter C. Johnston, Lewis, McGrew, McNeil,
                Marshall, Maslin, Masters, Moffett, MOORE, Nelson, Orrick, Osburn, Parks, Pendleton, Porter, Preston, Pugh, Rives, Sharp, Slaughter, C. J. Stuart, Sutherlin, White, Willey-59.
              </p>
              <p>EXPLANATION OF VOTES</p>
              <div className="speaker" id="sp1314"><span className="persName">Mr. SUTHERLIN</span>, when his name was called, said,</div>
              <p className="p-in-sp">I have no disposition to offer any discourtesy to the gentleman from Wetzel [<span className="persName">Mr. HALL</span>] . If he was discussing a question of the success of which there was any probability, I would cheerfully accord to the gentleman the courtesy usual in such cases; but inasmuch as it is manifest that there is no chance of the gentleman's substitute being carried, and it is unreasonable to allow a minority to control a majority, I must vote No.</p>
              <p><span className="persName">Mr. STAPLES</span> stated, when his name was called, that he paired off with <span className="persName">Mr. BOISSEAU</span>.</p>
              <p><span className="persName">Mr. HOLLADAY</span>, when his name was called, said he had paired off with an absent member.</p>
              <p><span className="persName">Mr. BRENT</span>, when his name was called, said he paired off with <span className="persName">Mr. MILLER</span>, of Botetourt.</p>
              <PageNumber num={414} />
              <p><span className="persName">Mr. CHAPMAN J. STUART</span> said he paired off with <span className="persName">Mr. MORRIS</span>, who was sick.</p>
              <div className="speaker" id="sp1315"><span className="persName">Mr. WOODS</span>, of Barbour, when his name was called, said :</div>
              <p className="p-in-sp">
                I desire to say a word or two in explanation of my vote. I have, in every instance, since this discussion commenced, voted for the Committee to rise as often as a motion of that character was submitted. I am aware that this system of operations is costing the State a good deal of money, and I suppose the majority of this Committee is also aware where the responsibility for this
                expense rests; and I suppose the people of the State will be slightly interested in knowing who is responsible.
              </p>
              <div className="speaker" id="sp1316"><span className="persName">The CHAIR</span>—</div>
              <p className="p-in-sp">The gentleman from Barbour will remember that the courtesy of the House has been extended to him for the purpose of explaining his vote.</p>
              <div className="speaker" id="sp1317"><span className="persName">Mr. WOODS</span>—</div>
              <p className="p-in-sp">
                That is a part of my explanation. Another reason why I shall vote, as I will do, is that I desire to extend to the gentleman from Wetzel, the same courtesy that I would like to have extended to myself, the same courtesy that has been extended to every other gentleman, except on one occasion. I think the members of the Convention owe it to themselves to accord that courtesy.
                Therefore, I shall vote aye.
              </p>
              <p>FEDERAL RELATIONS</p>
              <div className="speaker" id="sp1318"><span className="persName">Mr. HALL</span> [After the vote.]—</div>
              <p className="p-in-sp">Is there a quorum present?</p>
              <div className="speaker" id="sp1319"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Yes, sir.</p>
              <div className="speaker" id="sp1320"><span className="persName">Mr. HALL</span>—</div>
              <p className="p-in-sp">Mr. Chairman: When I yielded the floor for the purpose of allowing a motion to be made for the Committee to rise, I was endeavoring to show what the Northern people had done or were doing with the power that they have acquired, and I was explaining, also, the manner in which they had acquired this power.</p>
              <p className="p-in-sp">I will now proceed to speak at length [laughter] upon the effect and the policy of this tariff. It may be necessary for me to go into details to a considerable extent before I close. I am satisfied that this Committee will be vastly interested. [Laughter.] And, before I proceed to the investigation of this elaborate subject of tariff and tariffs, I will make a remark in reply to a gentleman who spoke in explanation of his vote.</p>
              <div className="speaker" id="sp1321"><span className="persName">Mr. GOODE</span> of Mecklenburg—</div>
              <p className="p-in-sp">I ask the gentleman to allow me to make a motion that the Committee now rise.</p>
              <div className="speaker" id="sp1322"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I rise to a question of order. I ask if it is competent for a gentleman to yield the floor for a motion and then resume his remarks again?</p>
              <PageNumber num={415} />
              <div className="speaker" id="sp1323"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair is of opinion that, in Committee of the Whole, a gentleman has a right to do so.</p>
              <div className="speaker" id="sp1324"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I understand the Chair to decide that the gentleman can yield the floor and resume it again?</p>
              <div className="speaker" id="sp1325"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">If he got the floor in advance of any other gentleman.</p>
              <div className="speaker" id="sp1326"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">Ah! then the Chair has not decided in the manner I supposed he did.</p>
              <div className="speaker" id="sp1327"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I understand the rule to be this: That when a gentleman is entitled to the floor, and he yields it merely for the purpose of allowing a motion to adjourn to be put, and the House refuses to adjourn, he is entitled to it again. That is the principle we have acted on throughout the session.</p>
              <div className="speaker" id="sp1328"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The decision of the Chair is, that the gentleman from Wetzel has a right to proceed.</p>
              <div className="speaker" id="sp1329"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
              <p className="p-in-sp">I move that the Committee rise, and on that I call the ayes and noes.</p>
              <p>The ayes and noes were called, and the Committee refused to rise by a vote of 12 to 56, as follows:</p>
              <p>YEAS—Messrs. Boyd, Cabell, Chapman, Goggin, L. S. Hall, Haymond, Kilby, Macfarland, C. K. Mallory, Marye, R. E. Scott, Whitfield-12.</p>
              <p>
                NAYS—Messrs. Armstrong, Aston, Baldwin, Berlin, Boggess, Brown, Burdett, Burley, Byrne, Campbell, Carter, Clemens, C. B. Conrad, R. Y. Conrad, J. H. Cox, Custis, Deskins, Dorman, Early, Forbes, Fugate, Gillespie, Gravely, A. Hall, E. B. Hall, Hammond, Hoge, Hubbard, Hughes, Hull, Jackson, M. Johnson, P. C. Johnston, Lewis, McGrew, McNeil, Marshall, Maslin, Masters, Moffett, MOORE,
                Nelson, Orrick, Osburn, Parks, Pendleton, Porter, Price, Pugh, Rives, Sharp, Sitlington, Slaughter, Sutherlin, White, Willey-56.
              </p>
              <p>EXPLANATION OF VOTE</p>
              <div className="speaker" id="sp1330"><span className="persName">Mr. BROWN</span>, of Preston, when his name was called, said:</div>
              <p className="p-in-sp">I have generally voted, late in the day, for an adjournment. I have understood this evening, from gentlemen who are good judges of the probable time it will take us to dispose of the various amendments involved, in the proposition presented by the gentleman from Wetzel [<span className="persName">Mr. HALL</span>] , that it will take us a month to vote upon them. I understood that from the gentleman from Princess Anne, who, I know, is a good judge of parliamentary matters. Believing that we will be detained here too long unless we are faithful and vigilant in obtaining votes upon all the propositions, I vote no.</p>
              <p>It being ascertained that there was no quorum voting,</p>
              <div className="speaker" id="sp1331"><span className="persName">Mr. JACKSON</span>, of Wood said :</div>
              <p className="p-in-sp">Mr. Chairman, I would suggest that the course now <PageNumber num={416} /> to be pursued is, for the Chair to report to the Convention that there is no quorum. When the Committee rises, I shall move a call of the House.</p>
              <p>The committee then rose and reported to the Convention [<span className="persName">Mr. SHEFFEY</span>, of Smyth, in the Chair] , that the business of the Committee was arrested for want of a quorum.</p>
              <div className="speaker" id="sp1332"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I move a call of the House.</p>
              <div className="speaker" id="sp1333"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I believe a motion to adjourn is a privileged motion. I make that motion.</p>
              <div className="speaker" id="sp1334"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I call the yeas and nays upon that motion.</p>
              <div className="speaker" id="sp1335"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">A motion to adjourn does not take precedence of my motion for a call of the House.</p>
              <div className="speaker" id="sp1336"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I don't know what is the exact rule; but I imagine that a motion to adjourn has precedence over a motion for a call of the House. It has precedence over all motions, and must necessarily over that.</p>
              <div className="speaker" id="sp1337"><span className="persName">The PRESIDENT</span> [<span className="persName">Mr. SHEFFEY</span> in the Chair]—</div>
              <p className="p-in-sp">The Chair does not profess to be familiar with parliamentary law, and would be glad to have the opinion of some gentleman who is, as to the matter now in dispute.</p>
              <div className="speaker" id="sp1338"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">A motion to adjourn takes precedence over any other motion in all parliamentary proceedings.</p>
              <div className="speaker" id="sp1339"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair decides that the motion to adjourn has precedence over the motion made for a call of the House, by the gentleman from Wood [<span className="persName">Mr. JACKSON</span>] .</p>
              <div className="speaker" id="sp1340"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I appeal from the decision of the Chair.</p>
              <div className="speaker" id="sp1341"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
              <p className="p-in-sp">I call the yeas and nays upon that motion.</p>
              <div className="speaker" id="sp1342"><span className="persName">Mr. STAPLES</span>, of Patrick—</div>
              <p className="p-in-sp">Suppose it is ascertained there is no quorum in Committee of the Whole, as it has been ascertained, is not the proper course to be pursued when the Committee rises, to make a call of the House?</p>
              <div className="speaker" id="sp1343"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">It has been suggested to me, by a friend, that I withdraw my appeal from the decision of the Chair, and ask for the yeas and nays upon the motion to adjourn. I shall adopt that plan, and accordingly withdraw my appeal. I call for the yeas and nays upon the motion to adjourn, made by the gentleman from Richmond city [<span className="persName">Mr. MACFARLAND</span>] .</p>
              <p>
                The roll was then called and resulted, yeas 26, nays 54, as follows:<span className="note" id="Note42"><span className="noteLabel">9</span>Both Proceedings and Journal state that there were 54 negative votes. The Proceedings list only 52, while the Journal lists 53, omitting Armstrong from, and adding Baldwin and Barbour to, the negative votes as listed in the Proceedings.</span>
              </p>
              <p>YEAS—Messrs. Ambler, Conn, R. H. Cox, Fisher, Goggin, T. F. Goode, L. S. Hall, Haymond, Kilby, Leake, Macfarland, C. K. Mallory, Marye, Montague, <PageNumber num={417} /> Morton, Neblett, Price, Randolph, Richardson, R. E. Scott, Slaughter, Speed, C. J. Stuart, F. P. Turner, Whitfield, Woods-26.</p>
              <p>
                NAYS—Messrs. Armstrong, Aston, Berlin, Boggess, Brown, Burdett, Burley, Byrne, Cabell, Campbell, Carter, Clemens, C. B. Conrad, Robert Y. Conrad, James H. Cox, Custis, Deskins, Dorman, Early, Echols, Forbes, Fugate, Gillespie, Gravely, Addison Hall, E. B. Hall, Hammond, Hoge, Hubbard, Hughes, Hull, Jackson, Marmaduke Johnson, P. C. Johnston, Lewis, McGrew, Marshall, Masters, Moffett,
                MOORE, Orrick, Osburn, Parks, Pendleton, Porter, Pugh, Rives, Sharp, Sitlington, Sutherlin, White, Willey-54.
              </p>
              <p>So the motion to adjourn was decided in the negative.</p>
              <div className="speaker" id="sp1344">
                <span className="persName">Mr. EARLY</span><span className="note" id="Note43"><span className="noteLabel">10</span>Mr. Early corrected the account of this passage with Mr. Harvie in the Proceedings of March 28, page 504.</span>—
              </div>
              <p className="p-in-sp">Mr. President—</p>
              <div className="speaker" id="sp1345"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I rise to a point of order, and it is this: that the gentleman is bound to rise from his seat before he can be recognized by the President.</p>
              <div className="speaker" id="sp1346"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I did rise from my seat.</p>
              <div className="speaker" id="sp1347"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">The gentleman was standing before he addressed the Chair and did not rise for that purpose.</p>
              <div className="speaker" id="sp1348"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">Mr. President, I beg leave to offer the following resolution:</p>
              <p>Resolved, That all discussion shall cease upon the pending proposition, in fifteen minutes after the Convention shall be resolved into a Committee of the Whole.</p>
              <div className="speaker" id="sp1349"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I would like to know whether by "the pending proposition," all the propositions now pending are meant?</p>
              <div className="speaker" id="sp1350"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair understands the resolution as referring only to the substitute offered by the gentleman from Wetzel [<span className="persName">Mr. HALL</span>] .</p>
              <div className="speaker" id="sp1351"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">That is all, sir.</p>
              <div className="speaker" id="sp1352"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">I rise to a point of order. It is this: that the pending question before the Convention is the resolution of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , and upon that question, sir, I am entitled to the floor.</p>
              <div className="speaker" id="sp1353"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">I am not aware that the gentleman from Jackson [<span className="persName">Mr. TURNER</span>]
                , or any other gentleman, can claim the floor upon any such ground as he represents. It is true that the courtesy of the floor has been extended to gentlemen from day to day, to discuss questions introduced before the Convention went into committee of the whole; but when a member gets the floor for the purpose of offering a proposition, surely that position cannot be taken from him
                merely upon the claim of courtesy.
              </p>
              <div className="speaker" id="sp1354"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">I will state that that very question was decided this <PageNumber num={418} /> very day. The Chair decided in committee of the whole that a member having the floor could be deprived of it by putting another in his place.</p>
              <div className="speaker" id="sp1355"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">I apprehend the question was very different from that now before the Convention. The proposition of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>]
                , has been under consideration here from day to day, but only during the morning hours. We find that other propositions have been interposed almost every morning by different gentlemen, and yet it cannot be claimed that these can be introduced here to the exclusion of the important question now under consideration. The state of the question as it now stands makes it entirely
                incompetent for the gentleman, as it seems, to claim the floor upon this taxation question. The Convention has just come out of Committee of the whole for the purpose of making a call of the House to ascertain if a quorum be present. That fact having been ascertained, it is now proceeding to determine what is to be the course of proceeding in Committee upon this question.
              </p>
              <p className="p-in-sp">
                But, sir, this is a question of vital importance and ought not to be set aside for the consideration of matters of comparatively little importance. It is moreover, unreasonable that the minority should be permitted to control the majority, and that too, upon a question the most important that a Convention ever assembled to deliberate upon. The question now is whether a majority or a
                minority shall control the Convention. It is not a matter of jest, as I regard it, and I offer that resolution to test that question. The gentleman says it is out of order because he may be entitled to the floor upon the resolution offered some days ago by the gentleman from Monongalia
                [<span className="persName">Mr. WILLEY</span>]
                . He may be entitled to the floor to-morrow morning, before the consideration of this question is entered upon; but, really, it seems to me, that to make such a claim now, is trifling with this momentous subject, and I say so with great respect, because if we are prevented from controlling the proceedings of the Committee of the whole tonight, the same obstacle may be interposed for
                months.
              </p>
              <p className="p-in-sp">I submit that I am not out of order.</p>
              <div className="speaker" id="sp1356"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">I move to amend the resolution of the gentleman from Frederick.</p>
              <div className="speaker" id="sp1357"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">I rise to a question of order. That is a privileged question.</p>
              <div className="speaker" id="sp1358"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">The gentleman must state his question of order, and I demand that it be reduced to writing. He is required to do so under the rule.</p>
              <PageNumber num={419} />
              <div className="speaker" id="sp1359"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">Show the rule.</p>
              <div className="speaker" id="sp1360"><span className="persName">A VOICE</span>—</div>
              <p className="p-in-sp">There is no doubt about it.</p>
              <div className="speaker" id="sp1361"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">If you refer to rule 31 you will find that every motion is required to be reduced to writing.</p>
              <div className="speaker" id="sp1362"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">It is not a motion; it is a point of order.</p>
              <div className="speaker" id="sp1363"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">There can be no point of order without a motion. I ask the decision of the Chair upon the question.</p>
              <div className="speaker" id="sp1364"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair is of opinion that every point of order requires a motion; and, under the rule, every motion is required to be reduced to writing.</p>
              <div className="speaker" id="sp1365"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">I appeal from the decision of the Chair.</p>
              <div className="speaker" id="sp1366"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">Mr. President—</p>
              <div className="speaker" id="sp1367"><span className="persName">Mr. TURNER</span> [Interrupting]—</div>
              <p className="p-in-sp">I desire to get time to reduce my motion to writing.</p>
              <div className="speaker" id="sp1368"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">The gentleman ought to have had his motion in writing before he submitted it.</p>
              <div className="speaker" id="sp1369"><span className="persName">Mr. COX</span>, of Chesterfield—</div>
              <p className="p-in-sp">I call the previous question.</p>
              <div className="speaker" id="sp1370"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">Upon that I demand the yeas and nays.</p>
              <div className="speaker" id="sp1371"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The gentleman from Jackson has appealed from the decision of the chair, and the question is, will the decision of the chair stand as the judgment of the Convention.</p>
              <div className="speaker" id="sp1372"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">In order to sustain a call for the previous question, there must be 20 members.</p>
              <div className="speaker" id="sp1373"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">Mr. President, will you be so good as to let us know what is before the Convention?</p>
              <div className="speaker" id="sp1374"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">Mr. President, is there anything before the Convention.</p>
              <div className="speaker" id="sp1375"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I move that we adjourn—I believe that motion is in order.</p>
              <div className="speaker" id="sp1376"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">I had the floor when the gentleman from Orange [<span className="persName">Mr. MORTON</span>] rose—I hold in my hand a privileged resolution, and I would ask an opportunity to allow me to offer it. I am desirous to expedite the proceedings of the Convention.</p>
              <div className="speaker" id="sp1377"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I rise to a point of order.</p>
              <div className="speaker" id="sp1378"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">The gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , is not in his seat, and the Chair can entertain no motion from him. Mr. President, I beg leave to offer the following resolution.</p>
              <div className="speaker" id="sp1379"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I rise to a question of order.</p>
              <div className="speaker" id="sp1380"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I move to adjourn.</p>
              <div className="speaker" id="sp1381"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">The Chair has no right to recognize the gentleman, as he is not in his seat.</p>
              <PageNumber num={420} />
              <div className="speaker" id="sp1382"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I move the previous question. The gentleman has a right to make a motion to adjourn.</p>
              <p>The call for the question was not sustained.</p>
              <div className="speaker" id="sp1383"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">I offer the following resolution :</p>
              <p>Resolved, That all discussions shall cease upon the pending proposition in Committee, in fifteen minutes after the Convention shall resolve itself into a Committee of the Whole.</p>
              <div className="speaker" id="sp1384"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I move to adjourn.</p>
              <div className="speaker" id="sp1385"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">It is not in order.</p>
              <div className="speaker" id="sp1386"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair decides that the motion is in order.</p>
              <div className="speaker" id="sp1387"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">I appeal from the decision of the Chair.</p>
              <div className="speaker" id="sp1388"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I call for the ayes and noes upon that appeal.</p>
              <p>The call for the ayes and noes was sustained, and the Secretary was proceeding with the call, when—</p>
              <div className="speaker" id="sp1389"><span className="persName">Mr. CLEMENS</span> said:</div>
              <p className="p-in-sp">I will state that while I was on the floor I had this resolution in my hand, and was about to send it to the Secretary, intending, at the same time, to offer some explanation in reference to it. The gentleman from Orange made his motion to adjourn.</p>
              <p className="p-in-sp">My point of order is, that while I was on the floor and offering the resolution it was not competent for the gentleman to make a motion to adjourn.</p>
              <div className="speaker" id="sp1390"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The impression of the Chair is that the gentleman from Ohio [<span className="persName">Mr. CLEMENS</span>] had submitted his resolution.</p>
              <div className="speaker" id="sp1391"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">No sir; I had not.</p>
              <div className="speaker" id="sp1392"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I rise to a point of order. The Chair has ordered the roll to be called, and it is not in order for the gentleman to interrupt the call.</p>
              <div className="speaker" id="sp1393"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I voted against sustaining the decision of the Chair upon the understanding that the gentleman from Orange [<span className="persName">Mr. MORTON</span>] interposed his motion while the gentleman from Ohio was on the floor.</p>
              <p>The call of the roll was further proceeded with, when it was found that there was no quorum voting.</p>
              <div className="speaker" id="sp1394"><span className="persName">Mr. PRICE</span>, of Greenbrier—</div>
              <p className="p-in-sp">I think the question is wrongly decided by the Chair. I am of opinion that the gentleman from Ohio [<span className="persName">Mr. CLEMENS</span>] , had the floor when the gentleman from Orange [<span className="persName">Mr. MORTON</span>] , made his motion to adjourn.</p>
              <div className="speaker" id="sp1395"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair will take leave to explain to the Convention its own understanding of the question. A resolution was offered, not by the gentleman from Ohio [<span className="persName">Mr. CLEMENS</span>] , but the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>] . The gentleman from Ohio was not <PageNumber num={421} /> understood by the Chair to have had the floor at the time the motion to adjourn was made; and he was further satisfied that the motion to adjourn was in order, from the fact that the resolution was not offered by the gentleman from Ohio, but by the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>] .</p>
              <div className="speaker" id="sp1396"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">
                The Chair will permit me to say that I took the resolution of the gentleman from Frederick and amended it by inserting the words "in Committee;" and before I had an opportunity of stating what my object was in making the amendment, the gentleman from Orange rose and made a motion to adjourn. The resolution I offered was not the same resolution offered by the gentleman from Frederick.
                This being the state of the case, I appealed from the decision of the Chair.
              </p>
              <div className="speaker" id="sp1397"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I rise to a question of order. I find, sir, that there are some members present who have not voted, and the rule requires that all members present when a vote is taken shall record their votes, unless excused by the House. I see a number of members around me who have not voted. There is the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , and the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>] , neither of whom have voted.</p>
              <div className="speaker" id="sp1398"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I am responsible to my God and my country for my vote, and to no one else. It is for my constituents, and not for the gentleman, to take me to task for my course here. I do not hold myself subject to that rule.</p>
              <div className="speaker" id="sp1399"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">The gentleman has taken his seat in this Convention, and I think he voted for these rules. I understood the gentleman to say that notwithstanding the rules have been adopted, he does not hold himself subject to them.</p>
              <div className="speaker" id="sp1400"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">
                I will say to the gentleman that I mean to observe the rules strictly; but in this instance I choose to withhold my vote. I am not ambitious to have my name recorded upon every petty question of this sort that may arise. I shall vote or not as I please, and it is for the House to say what course they will pursue in reference to me. I do not see why I should be singled out as a mark
                to be shot at here. I have taken no part in this excited scene, here to-night, and I do not understand why I have been thus made the object of the gentleman's peculiar concern.
              </p>
              <div className="speaker" id="sp1401"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I desire to ask the gentleman a question. I desire to know whether this question of ayes and noes has not arisen at the instance of the very gentlemen who are now refusing to vote?</p>
              <div className="speaker" id="sp1402"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">The yeas and nays have been called upon different <PageNumber num={422} /> questions here, and I do not know who called them. But I know that I did not call them nor second a call for them.</p>
              <div className="speaker" id="sp1403"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I call the attention of the House to the fact, that it may be known.</p>
              <div className="speaker" id="sp1404"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I alluded to the fact of the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , and the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>] , not having voted, from no object of singling them out for special notice, as the gentleman from Middlesex intimated, particularly as regarded himself, but because they happened to meet my eye first.</p>
              <p className="p-in-sp">The gentleman speaks of his having no ambition to record his vote upon every petty question that may arise here. I would say that I am governed in recording my vote by no motive of ambition to be recorded, but purely from motives looking to the performance of the duties which I came here to perform.</p>
              <p>The absentees were called, when the following result was announced—ayes 9, noes 72.</p>
              <p>So the decision of the Chair was not sustained.</p>
              <div className="speaker" id="sp1405"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">I understand that the chair was overruled in his decision in favor of the motion of the gentleman from Orange. I now move the amendment which I indicated a while ago, to the resolution of the gentleman from Frederick, and upon that I call the previous question.</p>
              <div className="speaker" id="sp1406"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">I accept the amendment.</p>
              <p>The call for the previous question was sustained, and the main question ordered to be put.</p>
              <div className="speaker" id="sp1407"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">I rise to a question of order. The point of order that I make is this: When I rose a while ago and made a point of order, the gentleman from Ohio [<span className="persName">Mr. CLEMENS</span>] , objected on the ground that I should reduce my point of order to writing. I have accordingly done so, and I now present it. It reads as follows: "The pending question before the House is the resolution offered by the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , and upon that <span className="persName">Mr. TURNER</span>, of Jackson, has the floor, and during the pendency of that question no other proposition can be taken up."</p>
              <div className="speaker" id="sp1408"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair decides that the point of order ought to be made in writing when the original resolution was offered. It is now out of order.</p>
              <div className="speaker" id="sp1409"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">I appeal from the decision of the Chair.</p>
              <div className="speaker" id="sp1410"><span className="persName">Mr. COX</span>, of Chesterfield—</div>
              <p className="p-in-sp">The previous question has been ordered, and this proceeding is out of order.</p>
              <div className="speaker" id="sp1411"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">I demand the ayes and noes upon the main question.</p>
              <PageNumber num={423} />
              <div className="speaker" id="sp1412"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">I understand that it is not in order for the Chair to entertain any proposition now except a motion to adjourn. I make that motion.</p>
              <div className="speaker" id="sp1413"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">I call the ayes and noes upon the main question.</p>
              <div className="speaker" id="sp1414"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">A motion has been made by the gentleman from Lynchburg [<span className="persName">Mr. SPEED</span>] to adjourn—that has precedence.</p>
              <div className="speaker" id="sp1415"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">It is not in order to make a motion to adjourn pending a question which was ordered by the House to be put.</p>
              <p>The call for the ayes and noes was sustained.</p>
              <div className="speaker" id="sp1416"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">I would ask the Chair if the motion to adjourn, which I made, was not in order.</p>
              <div className="speaker" id="sp1417"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair decides that no motion is in order until the question is taken.</p>
              <div className="speaker" id="sp1418"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
              <p className="p-in-sp">I appeal from the decision of the Chair.</p>
              <div className="speaker" id="sp1419"><span className="persName">Mr. CONN</span>—</div>
              <p className="p-in-sp">I desire to know what the question is.</p>
              <div className="speaker" id="sp1420"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">It is upon the adoption of the resolution as amended by the gentleman from Ohio [<span className="persName">Mr. CLEMENS</span>] .</p>
              <div className="speaker" id="sp1421"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">I ask that the resolution be reported.</p>
              <p>The Secretary read the resolution as amended.</p>
              <p>The roll was then called but there was no quorum voting; the vote being ayes 71; noes 5.</p>
              <div className="speaker" id="sp1422"><span className="persName">Mr. JACKSON</span>, of Wood—</div>
              <p className="p-in-sp">No person here has a right to pair off with another and thus cause a suspension of business. The rule requires that every member within the Hall shall vote. No member has a right to arrest the progress of business by pairing off with absent members.</p>
              <div className="speaker" id="sp1423"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">I move a call of the House.</p>
              <div className="speaker" id="sp1424"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I will state, sir, that there are members present who have not voted.</p>
              <div className="speaker" id="sp1425"><span className="persName">Mr. BRENT</span>—</div>
              <p className="p-in-sp">I paired off with the gentleman from Botetourt [<span className="persName">Mr. MILLER</span>] , but I desire inasmuch as there is no quorum, to record my vote, and, as far as I can, cause the business to go on.</p>
              <div className="speaker" id="sp1426"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">The gentleman cannot vote, the result of the vote having been announced.</p>
              <div className="speaker" id="sp1427"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">I move that the gentleman be permitted to vote.</p>
              <div className="speaker" id="sp1428"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">As long as this House has rules I will submit to them and remain here. The vote has been announced in this instance, and it was ascertained that there was no quorum.</p>
              <p className="p-in-sp">
                Now if this house means to abide by its rules, I will stay here until to-morrow morning in order to enable gentlemen to carry out their policy if they can. But if this House decides that after a vote is taken <PageNumber num={424} /> and the result announced, it is competent for a gentleman to vote when he refused to do so before, I for one will leave.
              </p>
              <div className="speaker" id="sp1429"><span className="persName">Mr. PARKS</span>—</div>
              <p className="p-in-sp">I believe one of the rules of the Convention is that all who are present shall vote unless excused. I would ask the gentleman who has just preceded me whether he was present while the vote was being taken, and whether he voted.</p>
              <div className="speaker" id="sp1430"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I was present, and would have voted if this House required me to vote.</p>
              <div className="speaker" id="sp1431"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I rise to make a suggestion. There is now a quorum present, and if the vote has been announced and a quorum is not found voting, we can call the roll over again, and give those who did not vote an opportunity of doing so now.</p>
              <div className="speaker" id="sp1432"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I deny that the gentleman or the House has a right to take the vote again, after that vote has been taken and the result announced. If the gentleman wants another vote he may make another motion. That vote is beyond the power of this House, and it has no right to take it over again.</p>
              <div className="speaker" id="sp1433"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">
                There is no principle better established in parliamentary practice than this. When the presiding officer of a deliberative body can see by an inspection of the members that there is not a quorum present, it is competent for him to direct the clerk to call the absentees. The House cannot tell whether there is a quorum present or not until the roll is called, and the very fact that it
                is ascertained there is no quorum present, devolves the duty on the Chair of ascertaining, by inspection, whether a quorum is present, and being satisfied that there is, it is competent for him on the call of any member to order the absentees to be called.
              </p>
              <div className="speaker" id="sp1434"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">
                There is no manner of doubt that before that vote had been announced, if the Chair had seen that there was a quorum present and withheld the announcement of the vote until he ascertained whether there was or was not, it would be competent for any gentleman to make a motion to have the absentees called. But the Chair did not so ascertain, the Chair did not so announce, and the result
                of the vote being given, it is not now in the power of the Chair or of this House to have the vote taken over again.
              </p>
              <div className="speaker" id="sp1435"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I move to adjourn.</p>
              <div className="speaker" id="sp1436"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">Here is a question pending which must be settled. The gentleman from Greenbrier, moved that the gentleman from Alexandria have leave to vote.</p>
              <div className="speaker" id="sp1437"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">The Chair has not decided that it was not in order to make a motion to adjourn.</p>
              <PageNumber num={425} />
              <div className="speaker" id="sp1438"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">
                I rise to make an explanation. I am a member of the minority here—I had the floor in the early part of this day and was deprived of it by a vote of the majority of this House. Now, if the rules of this House are to be interpreted to the disadvantage of the minority, we shall insist that the same interpretation shall apply when it is made in a similar matter with reference to the
                majority.
              </p>
              <div className="speaker" id="sp1439"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I don't agree with the gentleman as to his recollection of this matter. I got up to move that the roll be again called, to ascertain whether we had a quorum or not, and the gentleman from Orange [<span className="persName">Mr. MORTON</span>] just as I had made the motion, submitted his motion to adjourn.</p>
              <div className="speaker" id="sp1440"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I rise to a question of order. The gentleman from Wheeling [<span className="persName">Mr. CLEMENS</span>] moved a call of the House since the vote was taken, and that question is now pending.</p>
              <div className="speaker" id="sp1441"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">No, sir.</p>
              <div className="speaker" id="sp1442"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair will state that the question now pending is the motion of the gentleman from Greenbrier [<span className="persName">Mr. PRICE</span>] , to permit the gentleman from Alexandria [<span className="persName">Mr. BRENT</span>] , to vote.</p>
              <div className="speaker" id="sp1443"><span className="persName">Mr. COX</span>, of Chesterfield—</div>
              <p className="p-in-sp">I call the previous question.</p>
              <p>There was no vote taken upon sustaining the call, and the question being put, on granting leave to <span className="persName">Mr. BRENT</span> to cast his vote, was decided in the affirmative.</p>
              <p>The other gentlemen who paired off and whose names are given in an early part of the proceedings, voted, giving as their reasons those stated in connection with the vote where their names are given.</p>
              <p>
                The vote was then announced as follows: ayes 71, noes 6:<span className="note" id="Note44"><span className="noteLabel">11</span>The Journal states that there were 5 negative votes; it does not include Brent, but does include Wilson.</span>
              </p>
              <p>
                AYES—Messrs. Armstrong, Aston, Baldwin, A. M. Barbour, Berlin, Boggess, Brown, Burdett, Burley, Byrne, Cabell, Campbell, Carter, Chapman, Clemens, C. B. Conrad, R. Y. Conrad, J. H. Cox, Custis, Deskins, Dorman, Early, Forbes, French, Fugate, Garland, Gillespie, Gravely, Goggin, Addison Hall, E. B. Hall, Hammond, Haymond, Hoge, Hubbard, Hughes, Hull, Jackson, Marmaduke Johnson, P. C.
                Johnston, Kilby, Lewis, McGrew, McNeil, Macfarland, C. K. Mallory, J. B. Mallory, Marshall, Marye, Sr., Maslin, Masters, Moffett, MOORE, Nelson, Orrick, Osburn, Parks, Pendleton, Porter, Price, Pugh, Rives, Robt. E. Scott, Sharp, Sheffey, Sitlington, Slaughter, Sutherlin, White, Whitfield, Willey-71.
              </p>
              <p>Noes—Messrs. Brent, R. H. Cox, Dulany, Randolph, C. Stuart, Tayloe-6.</p>
              <div className="speaker" id="sp1444"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">I move to reconsider that vote, and lay the motion to reconsider on the table.</p>
              <div className="speaker" id="sp1445"><span className="persName">Mr. BRENT</span>—</div>
              <p className="p-in-sp">I would be glad to hear the gentleman's reason for making that motion.</p>
              <div className="speaker" id="sp1446"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">I withdraw it, and move instead that the Convention <PageNumber num={426} /> go into Committee of the whole, and upon that motion I call the previous question.</p>
              <p>The call was sustained.</p>
              <div className="speaker" id="sp1447"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I move to adjourn.</p>
              <div className="speaker" id="sp1448"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The motion is not in order.</p>
              <div className="speaker" id="sp1449"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I desire to call the attention of the House to another fact, and it is this, that the gentleman from Orange [<span className="persName">Mr. MORTON</span>] , would not vote to make a quorum, while he is making motions to adjourn, which rather cause delay than otherwise. I hope the reporter will take a note of that.</p>
              <div className="speaker" id="sp1450"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I am responsible to my constituents and nobody else for my course here, and I would say to the gentleman that his admonitions will avail nothing in diverting me from the course which I think proper to pursue.</p>
              <div className="speaker" id="sp1451"><span className="persName">Mr. WOODS</span> of Barbour—</div>
              <p className="p-in-sp">What is the question before the House?</p>
              <div className="speaker" id="sp1452"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The question before the House is the motion of the gentleman from Ohio [<span className="persName">Mr. CLEMENS</span>] , to go into Committee of the Whole.</p>
              <div className="speaker" id="sp1453"><span className="persName">Mr. WOODS</span>—</div>
              <p className="p-in-sp">I call for the ayes and noes upon that motion.</p>
              <p>
                The call was sustained, and the vote being taken by ayes and nays, resulted, ayes 74, noes 8, as follows:<span className="note" id="Note45"
                ><span className="noteLabel">12</span>A correction (March 30) notes that Dr. R. H. Cox voted with the nays, and not with the ayes. The Journal states that there were 74 affirmative votes, although it lists but 72: it omits R. H. Cox, and Critcher, but adds Forbes, Johnson and White to names given in the Proceedings.</span
                >
              </p>
              <p>
                YEAS—Messrs. Armstrong, Aston, Baldwin, Alfred M. Barbour, Berlin, Bogges, Brown, Burdett, Burley, Byrne, Cabell, Campbell, Carter, Chapman, Clemens, C. B. Conrad, Robert Y. Conrad, James H. Cox, Richard H. Cox, Critcher, Custis, Deskins, Dorman, Early, Echols, French, Fugate, Garland, Gillespie, Gravely, Goggin, Addison Hall, Ephraim B. Hall, Hammond, Hoge, Hubbard, Hughes, Hull,
                Jackson, Peter C. Johnston, Kilby, Lewis, McGrew, McNeil, Macfarland, Charles K. Mallory, James B. Mallory, Marshall, Marye, Maslin, Masters, Moffett, McD. MOORE, Nelson, Orrick, Osburn, Parks, Pendleton, Porter, Preston, Price, Pugh, Rives, Robert E. Scott, Sharp, Sheffey, Sitlington, Slaughter, Sutherlin, Whitfield, Willey-74.
              </p>
              <p>NAYS—Messrs. Brent, Dulany, L. S. Hall, Haymond, Montague, Chapman J. Stuart, Tayloe, Woods-8.</p>
              <p>ABSENT MEMBERS</p>
              <p><span className="persName">Mr. BALDWIN</span>, of Staunton, when <span className="persName">Mr. BAYLOR</span>'S name was called, stated that he was absent on account of indisposition.</p>
              <p><span className="persName">Mr. EARLY</span> made a similar excuse for <span className="persName">Mr. SAUNDERS</span>.</p>
              <p>COMMITTEE OF THE WHOLE</p>
              <p>The Convention then resolved itself into Committee of the Whole, <span className="persName">Mr. PRICE</span>, of Greenbrier, in the Chair.</p>
              <div className="speaker" id="sp1454"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">
                It is due to myself and to other gentlemen on <PageNumber num={427} /> this floor, who voted in a certain way to-night, to say, that so far as the offering of the motion that I submitted was concerned, I consulted with no mortal man about it. Whatever blame is attached to it falls upon me. I take the responsibility of the whole matter.
              </p>
              <div className="speaker" id="sp1455"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">What is the pending question.</p>
              <div className="speaker" id="sp1456"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The substitute offered by the gentleman from Wetzel [<span className="persName">Mr. HALL</span>] , to the report of the Committee on Federal Relations.</p>
              <div className="speaker" id="sp1457"><span className="persName">Mr. HALL</span>—</div>
              <p className="p-in-sp">I say, that whatever blame may be attached to the introduction of the proposition submitted by me, I alone am responsible for, and, in answer to the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>] , that he regarded this as a very important matter, that it was no more nor less than a question whether a factious minority should control a responsible majority, I—</p>
              <div className="speaker" id="sp1458"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">I beg to correct the gentleman. I did not characterize either the majority or minority as factious.</p>
              <div className="speaker" id="sp1459"><span className="persName">Mr. HALL</span>—</div>
              <p className="p-in-sp">
                Now, sir, I will refer briefly to the history of this matter. I was seated at a remote end of the Hall this evening, when I heard the Secretary read the first section of the committee's report. I came over, with a view of getting the floor, believing that the usual courtesy would be granted to me that was granted to other gentlemen. I submitted my proposition; I was at the time
                unprepared, not having the proposition with me, but the Secretary stated that he had the proposition to which I referred. I immediately afterwards determined, should the House afford me an opportunity, to present it in better form to-morrow. The gentleman from Princess Anne
                [<span className="persName">Mr. WIsE</span>]
                , called for the reading of the document, and, after it was read, I asked for the courtesy of withdrawing it. That courtesy was refused, and hence the scene which followed. I would ask if there was anything in my course that was calculated to provoke the discourtesy manifested to me. I shall not retaliate in language befitting such a course of conduct towards me as has been
                exhibited. I shall be respectful, and I will state, that for the course I have pursued here to-night I am perfectly responsible here or elsewhere or under any circumstances. And gentlemen may understand that, too. I merely want to place myself right. So far as responsibility is concerned, I tell the majority here that if the gag is to be put on men in the manner in which it has been
                to-night, the sooner the people know it the better for their interests and their rights. There is a power, sir, behind, that will be respected ere long, so far as the privilege of discussing the propositions seriously and honestly, is concerned. I had no sinister purpose in view in offering that substitute; <PageNumber num={428} /> and in order to show
                you that I am not factious in this matter, I will state that the whole of the conservative journals of the North—the "Journal of Commerce," the New York "Herald," the Cincinnati "Enquirer," Cleveland "Plaindealer," and other conservative papers, have agreed on the plan that I proposed here to-night. I will read an extract from a letter signed Robert Tyler, which also takes the same
                ground.
              </p>
              <div className="stage it">[<span className="persName">Mr. HALL</span> read an extract from this letter favoring the adoption of the amendments embodied in the Constitution of the Confederate States, as the terms of the adjustment of existing difficulties.]</div>
              <div className="speaker" id="sp1460"><span className="persName">Mr. HALL</span>, continuing—</div>
              <p className="p-in-sp">
                I am not alone on this question. Gentlemen may arrogate to themselves all wisdom, power and authority, yet, sir, they will find a greater power to whom they will have to render a strict account. I disclaim arrogating to myself anything. I am only carrying out the opinions of other distinguished men and of the conservative papers of the North which appealed to the South to adopt these
                amendments. And here, sir, I am to be gagged and cut off from the exercise of my just rights as a representative, because I choose to adopt the opinions of men as good as any in this Convention.
              </p>
              <div className="speaker" id="sp1461"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman must not be disrespectful to the committee.</p>
              <div className="speaker" id="sp1462"><span className="persName">Mr. HALL</span>—</div>
              <p className="p-in-sp">I am not disrespectful. It is no manifestation of disrespect to say that there are other men as good as any in this Convention. I will remark that many gentlemen who proclaim opinions in keeping with those which I hold, are as good as any in this body, however this body may affect to disregard those opinions.</p>
              <div className="speaker" id="sp1463"><span className="persName">Mr. MOORE</span>, of Rockingham—</div>
              <p className="p-in-sp">Order, order, order.</p>
              <div className="speaker" id="sp1464"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman is out of order. He will please take his seat.</p>
              <p>The pending question is upon the substitute offered by the gentleman from Wetzel.</p>
              <div className="speaker" id="sp1465"><span className="persName">Mr. PORTER</span>—</div>
              <p className="p-in-sp">I call for the ayes and noes on that substitute.</p>
              <p>The call was not sustained at first, but soon after a sufficient number arose to sustain the call.</p>
              <div className="speaker" id="sp1466"><span className="persName">Mr. GOODE</span>—</div>
              <p className="p-in-sp">I ask if it is not now too late. The call was not sustained at first, and the Chair so announced.</p>
              <div className="speaker" id="sp1467"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It has been announced since that the call was sustained. The Chair thinks the call was duly sustained.</p>
              <div className="speaker" id="sp1468"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
              <p className="p-in-sp">I appeal from the decision of the chair, and upon that I ask for the ayes and noes.</p>
              <p>The call for the ayes and noes was not sustained.</p>
              <PageNumber num={429} />
              <p>The question on the appeal was then put, and decided in favor of the judgment of the chair.</p>
              <p>
                The question was then taken by yeas and nays upon the substitute offered by <span className="persName">Mr. HALL</span>, and resulted—Yeas none, nays 78, as follows:<span className="note" id="Note46"><span className="noteLabel">13</span>The Journal also states that there were 78 negative votes, and adds Hammond and Holladay to those listed here.</span>
              </p>
              <p>
                NAYS—Messrs. Armstrong, Aston, Baldwin, A. M. Barbour, Berlin, Boggess, Brent, Brown, Burdett, Burley, Byrne, Cabell, Campbell, Carter, Chapman, Clemens, C. B. Conrad, R. Y. Conrad, Jas. H. Cox, Custis, Deskins, Dorman, Early, Echols, Forbes, French, Fugate, Garland, Gillespie, Gravely, Goggin, John Goode, Jr., Addison Hall, Ephraim B. Hall, Haymond, Hoge, Hubbard, Hughes, Hull,
                Jackson, Marmaduke Johnson, Peter C. Johnston, Kilby, Lewis, McGrew, McNeil, Macfarland, Jas. B. Mallory, Marshall, Marye, Sr., Maslin, Masters, Moffett, MOORE, Nelson, Orrick, Osburn, Parks, Pendleton, Porter, Preston, Price, Pugh, Rives, Robert E. Scott, Sharp, Sheffey, Sitlington, Slaughter, Sutherlin, Tayloe, F. P. Turner, White, Whitfield, Willey, Woods-78.
              </p>
              <p>EXPLANATION OF VOTES</p>
              <div className="speaker" id="sp1469"><span className="persName">Mr. TURNER</span>, of Jackson, when his name was called, said :</div>
              <p className="p-in-sp">While there are several propositions in the substitute offered by the gentleman from Wetzel [<span className="persName">Mr. HALL</span>] , which I would approve as bases of settlement, I shall still vote no.</p>
              <p><span className="persName">Mr. BROWN</span> stated that <span className="persName">Mr. CARLILE</span> was absent from illness.</p>
              <p><span className="persName">Mr. CHAPMAN J. STUART</span> stated that he had paired off with Mr. Moms, who left the Convention on account of illness; but, in order to secure a quorum, he would vote as he believed <span className="persName">Mr. MORRIS</span> would vote, if present. He voted no.</p>
              <div className="note" id="Note47">
                <span className="noteLabel">14</span>A correction (March 30) reads as follows:
                <div className="speaker"><span className="persName">Mr. CHAPMAN J. STUART</span> made the following explanation of his vote on the substitute of Mr. HALL of Wetzel:</div>
                <p className="p-in-sp">
                  Mr. President: I desire to correct the report of the proceedings of the Convention on the evening of the 26th instant, so far as it relates to my vote in Committee of the Whole, while the substitute of the gentleman from Wetzel was under consideration. During the many ballotings on the various motions, I paired off with <span className="persName">Mr. MORRIS</span>, who, from
                  indisposition, had to leave the Convention. I did not vote after that, until it became necessary for me to vote in order to make a quorum. Having necessarily to vote for the purpose indicated, I invariably explained that having paired off with <span className="persName">Mr. MORRIS</span>, honor required me, if I voted, to vote as I supposed
                  <span className="persName">Mr. MORRIS</span> would vote, if present, and so recorded my vote with the minority. This was true until the final adoption or rejection of the substitute was to be voted on. It then again became necessary for me to vote, in order to make a quorum. I then explained to the Committee that I did not know how <span className="persName">Mr. MORRIS</span> would vote
                  upon the substitute, but that I could not, under any circumstances, record my vote for the substitute, and I appealed to the Chair to know if the Committee could not excuse me from voting, and by so doing record my presence, thereby making a quorum. The Chair decided it could not be done. I then said that I voted my own sentiments without knowing what
                  <span className="persName">Mr. MORRIS</span>' were, and voted no.
                </p>
                <p>Although the form of the correction indicates that it was made in the Convention and therefore was a part of the actual Proceedings, the point at which the correction was offered has not been determined.</p>
              </div>
              <p><span className="persName">Mr. HOLLADAY</span>, from the same considerations, recorded his vote in the negative.</p>
              <div className="speaker" id="sp1470"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I now move that the Committee rise.</p>
              <p>The motion was agreed to.</p>
              <p>The Committee rose and reported progress, whereupon, on motion of <span className="persName">Mr. R. Y. CONRAD</span>, the Convention adjourned at quarter to 11 o'clock, PM.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v2.2.9">
            <PageNumber num={430} />
            <h2><span className="headingNumber">1.9. </span><span className="head">THIRTY-SIXTH DAY</span></h2>
            <div className="dateline">Wednesday, <span className="date">March 27</span></div>
            <p>Prayer by the Rev. Mr. Willis of the Baptist Church.</p>
            <div className="speaker" id="sp1471"><span className="persName">Mr. COX</span>, of Chesterfield—</div>
            <p className="p-in-sp">I ask the privilege of occupying the time of the Convention for a few moments to present some resolutions.</p>
            <div className="speaker" id="sp1472"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
            <p className="p-in-sp">Before any resolution or anything else can be acted on, I would say that I don't believe there is a quorum present, and I want to test the propriety of meeting at this early hour, by having a call of the house.</p>
            <p>A division was called for, and the motion for a call of the house was sustained by a vote of 45 to 17.</p>
            <p>The call of the house was then ordered. The following is the list of the absentees:</p>
            <p>
              Messrs. A. M. Barbour, J. Barbour, Blakey, Blow, Jr., Bouldin, Boyd, Branch, Bruce, Byrne, Caperton, Carter, Cecil, Chapman, Clemens, Couch, Critcher, Custis, Dent, Deskins, Dulany, Early, Flournoy, French, Graham, Grant, Gray, Hale, L. S. Hall, Hammond, Harvie, Haymond, Hoge, Holladay, Hubbard, Hun-ton, Kent, Kindred, Macfarland, Marshall, Marr, Marye, Sr., Miller, MOORE, Pendleton,
              Randolph, Saunders, Sr., W. C. Scott. Seawell, Staples, A. H. H. Stuart, Summers, Sutherlin, Tarr, Tredway, White, Wickham, Wilson, and Wysor.
            </p>
            <p>While the names of the absentees were being called,</p>
            <div className="speaker" id="sp1473"><span className="persName">Mr. TURNER</span> said:</div>
            <p className="p-in-sp">My object was simply to test the propriety of meeting at this hour. It was with a view to show the futility of meeting so early in the morning. I now move that the further call of the House be dispensed with.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp1474"><span className="persName">Mr. COX</span>, of Chesterfield, said—</div>
            <p className="p-in-sp">
              Mr. President, a meeting of a portion of my constituents was held at Chester, in Chesterfield county, on Saturday last, and passed the resolutions which I hold in my hand, with the request that they should be laid before this Convention. I take pleasure in complying with their request, and have only to say that I have heard the meeting was respectable in numbers. I know it was
              respectable in character and intelligence. None more so could be held in my county, and their opinions shall have as much weight with me, and are entitled to the favorable consideration of this Convention in as high a degree as the opinions of the like number of our fellow-citizens coming from any quarter of the State. The respectability, character and intelligence of
              <PageNumber num={431} /> the gentlemen who passed these resolutions, warrant and require that I shall ask that they be read at the clerk's desk and referred to the Committee on Federal Relations.
            </p>
            <p>The resolutions were read as follows:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>"At a meeting of the people of the county of Chesterfield, held at Chester, on Saturday, 23d of March, 1861, Mr. Charles W. Friend was called to the Chair, and J. L. Snead appointed Secretary. <span className="persName">Mr. E. O. Watkins</span> then introduced the following preamble and resolutions:</p>
                <p>
                  "Whereas, we, a portion of the people of Chesterfield, in a public meeting assembled, deeming it the duty of a free people at all times in their primary assemblies to express their opinions upon all grave and important questions, involving their interests and rights, and believing, from recent developments, that the time has now arrived when Virginia should resume all powers
                  heretofore delegated to the Federal Government, therefore,
                </p>
                <p>"Resolved, That in the opinion of this meeting, Virginia should, through the action of the Convention now sitting in Richmond, immediately pass an ordinance of secession from the Federal Union, and submit the same to the qualified voters of the State for ratification.</p>
                <p>"On motion of Mr. Henry A. Winfree,</p>
                <p>"Resolved, As the sense of the meeting, that the State of Virginia ought at once to become a member of the Southern Confederacy, and that our representative in the Virginia Convention be informed of this opinion.</p>
                <p>"On motion it was</p>
                <p>"Resolved, That a copy of the proceedings be forwarded to our representative in the Virginia Convention, with a request that he present the same before the Convention.</p>
                <p>C. W. FRIEND, Chairman.</p>
                <p>J. L. SNEAD, Sec'y.</p>
              </div>
            </div>
            <div className="section" id="vsc1965.v2.2.9.1">
              <h3><span className="headingNumber">1.9.1. </span><span className="head">THE TAXATION QUESTION</span></h3>
              <div className="speaker" id="sp1475"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The resolutions of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , are now in order, and the gentleman from Jackson is entitled to the floor.</p>
              <div className="speaker" id="sp1476"><span className="persName">Mr. TURNER</span>, of Jackson, resumed his remarks, as follows :</div>
              <p className="p-in-sp">
                Mr. President, in proceeding with the discussion of the question before the House, I shall depart from the rule which I laid down for my own action, and will confine myself to the question which I deem of vital importance to the people of my section of the State. I said before that this question should be disembarrassed of all other questions;
                <PageNumber num={432} /> and, after a full, free and fair discussion of the difficulties of our Federal relations, this Convention should proceed to investigate the subject of taxation, and render that justice to the people of the Western part of the State to which they are entitled, by equalizing the taxes of the State. I desire to call the attention
                of the members of this Convention, and of the people of the State, to the great inequality which exists in the apportionment of the taxes. I desire to meet the facts as they are, to deal with them frankly and fairly, and not to shirk any issue whatever. I desire that gentlemen will look at this question as it exists, and the consequences which would follow a refusal to act upon this
                matter.
              </p>
              <p className="p-in-sp">Sir, it is a fact, and a remarkable fact, that in order to obviate the necessity of taxing this particular species of property, every industrial pursuit of our inhabitants is taxed inordinately. The merchant is taxed and the auctioneer is taxed, to an enormous extent to meet the necessities and wants of this Commonwealth in supporting our Government.</p>
              <p className="p-in-sp">The class of the profession to which I belong (the legal profession), is</p>
              <p className="p-in-sp">
                not only taxed as a class, but is taxed in the revenue derived from the pursuit of the profession, for the purpose of meeting the same necessity and the same want. The income of the mechanic is taxed for the same purpose. Every branch of industry, every avenue of trade contributes to the support of the Government of the State; and especially does this burden fall upon Western
                Virginia, because every corporation which is now formed for the purpose of developing the mineral resources of that portion of the State, is to be taxed inordinately. These are facts that are irresistable, facts that must be met, facts that come home to the minds of the people, facts that they know and that they are determined to have investigated, in order that a remedy may be
                secured for the wrongs that are imposed upon them.
              </p>
              <p className="p-in-sp">
                And, sir, not only is it necessary that this investigation should proceed, not only has it been necessary on account of the increased burthens of the State for years, but it is essentially necessary at this particular time. Why? Propositions are now before this Convention proposing to put the State upon a complete war footing—upon a complete military posture of defence. Already the
                Legislature of our State has appropriated $1,000,000 for that purpose. This extra session of the Legislature, and this Convention itself, will still further increase the indebtedness of the State. In order to meet that indebtedness, we are informed by the Auditor of public accounts, in order to keep the government in motion during the incoming fiscal year, it is necessary to increase
                taxation fifty per cent. upon what it is now; making it <PageNumber num={433} /> necessary, instead of a tax of 40 cts. upon the $100, to impose a tax of 60 cts. upon the $100. Where is this increase of taxation to fall? What is the exigency that requires this increase of taxation at this time?
              </p>
              <p className="p-in-sp">
                It is an exigency presented for the protection of a species of property which does not contribute even for its own protection, and in the protection of which the section of the State from which I come have very little pecuniary interest. And, sir, I give those gentlemen in this Convention, representing the Western portion of the State, notice that they will be held to a strict
                accountability by their constituents and by that portion of Virginia, if they in any manner shirk this question or allow it to be passed by. They must stand up and meet the issue which is presented. They cannot get round it. I shall endeavor, by every means in my power, to secure some definite action upon the subject before any final adjournment, or before any adjournment of this
                body for any considerable length of time. In order that it may be investigated fully, I desire that it may be disembarrassed from all other questions, and that it may be presented fairly without any side issues to affect it.
              </p>
              <p className="p-in-sp">
                Sir, it is said that if the State remains in the Union in its present condition, or that if this increase of taxation is put upon slaves, a large portion of the population and wealth of the State will desert it. Mr. President, I do not know whether gentlemen appreciate the responsibility they owe to the State of Virginia or not. I cannot understand that they do, if, for any such
                cause, they are prepared to desert the old Commonwealth. We of the West are told what we have to expect if the State remains in the Union in its present condition. We are told that if the State remains with the Northern Confederacy, this portion of her population will desert her. If slave property is to be taxed, this population will desert us. Sir, the alternative is nothing to us.
                What I object to is, that we shall be compelled by Eastern gentlemen to remain in a Union upon terms of inequality with them, for the purpose of retaining within the limits of the Commonwealth property that does not contribute to its support. I ask simply that we shall be placed upon terms of equality with our Eastern brethren, and that our property may be placed upon terms
                approaching equality with the property we are taxed to protect.
              </p>
            </div>
            <div className="section" id="vsc1965.v2.2.9.2">
              <h3><span className="headingNumber">1.9.2. </span><span className="head">FEDERAL RELATIONS</span></h3>
              <p>The hour of half past ten having arrived, the Convention, in pursuance of its standing order, resolved itself into Committee of the <PageNumber num={434} /> Whole [<span className="persName">Mr. SOUTHALL</span> in the Chair] , and resumed the consideration of the report of the Committee on Federal Relations.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.9.3">
              <h3><span className="headingNumber">1.9.3. </span><span className="head">CORRECTION OF THE JOURNAL</span></h3>
              <div className="speaker" id="sp1477"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">
                Before proceeding to the business before the Committee, I ask for the reading of the first portion of the journal of this Committee of yesterday. A question was decided in Committee yesterday, affecting my rights as a member of this body—not to speak of courtesy—decided in a way which I think violated my rights, and I now only ask that the facts may be correctly stated upon the
                journal. I don't think the journal, as made up, states the facts as they occurred, and I ask that it may be corrected as to the point which was decided.
              </p>
              <p className="p-in-sp">Now, sir, the journal states that the Chairman spoke of a question before the Committee. The question which I heard the Chairman speak of was a possible contingency which might arise, and as to what course he would take if it did arise. Ergo, that it was a question before the Committee.</p>
              <p className="p-in-sp">
                Therefore, if the journal is allowed to stand as it now reads, it would appear that I was interrupted by the Chairman upon a matter which was germane to the subject before the Committee for its decision; whereas, he was only commenting upon a contingency which might possibly arise. No question was before the Committee upon which any such decision could then have been made; I ask,
                therefore, that the journal may be corrected by striking out so much of it as states that the Chair interrupted me upon a question which was before the Committee.
              </p>
              <div className="speaker" id="sp1478"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">That part of the journal alluded to by the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] , reads:</p>
              <p className="p-in-sp">"While <span className="persName">Mr. AMBLER</span> was occupying the floor having been recognized by the Chairman [<span className="persName">Mr. PRICE</span>] , he was interrupted by the Chairman, with an explanation of the question before the Committee, before concluding which, <span className="persName">Mr. NELSON</span> claimed the floor."</p>
              <div className="speaker" id="sp1479"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">I move to strike out from that portion of the journal just after the word explanation, the words "of the question before the Committee," so that the journal would read:</p>
              <p className="p-in-sp">"While <span className="persName">Mr. AMBLER</span> was occupying the floor, having been recognized by the Chairman [<span className="persName">Mr. PRICE</span>] , he was interrupted by the Chairman, with an explanation, before concluding which, <span className="persName">Mr. NELSON</span> claimed the floor," &amp;c.</p>
              <div className="speaker" id="sp1480"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">It is proper that I should make an explanation. I desire, if the Committee please, to state the facts, as I recollect them, so that if <PageNumber num={435} /> there is any injustice done to the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>]
                , the Committee may correct it. When the House went into Committee of the Whole yesterday morning, there was no competing proposition to the Committee's report. The Chairman stated that the question was upon the report of the Committee; that, in his opinion, the minority reports did not come up, as a matter of course, in competition with the Committee's report. The gentleman from
                Louisa rose and stated that the gentleman from Amelia
                [<span className="persName">Mr. HARVIE</span>] , was not in his seat, so as to offer his substitute, and that he supposed it would be done at some subsequent time. That is my recollection of what transpired. The Chairman then stated that the report would be taken up, acted upon by sections, and if a section were adopted, it would be competent for the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , to substitute his proposition for the entire report, including the amendments which might have been adopted by the Committee. The gentleman from Louisa [<span className="persName">Mr. AMBLER</span>]
                , seemed to be dissatisfied, as I thought, with that decision, and put further interrogatories to the Chair. The Committee will bear in mind that you, Mr. Chairman, had rendered such a decision, that when a section was adopted by this Committee it would stand as the act of the Committee. As I said, the gentleman from Louisa seemed to be dissatisfied with the decision I had made and
                he put farther questions to the Chair in reference to the course of proceeding, and the effect of the action of the Committee. The gentleman from Clarke
                [<span className="persName">Mr. NELSON</span>] , then, who had moved an adjournment the preceding evening, with a view of speaking, claimed the floor, and the Chairman accorded to him that right. If there is anything unjust in that course of proceeding to the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] , I hope that the Committee will correct it and place him right. The gentleman from Louisa sent to the Chair a statement made after the transaction which he desired entered upon the journal. The Chairman, whilst in the Chair during the progress of the debate, wrote out his recollection of the facts. If it be agreeable to the Committee, I will read that statement.</p>
              <p className="p-in-sp">
                "The Chairman of the Committee of the Whole upon taking the Chair, stated the question before the Committee to be the report of the Committee on Federal Relations, that the minority reports would not come up for consideration except upon motion. <span className="persName">Mr. AMBLER</span> thereupon arose and was recognized, and stated that the gentleman from Amelia was not in his seat,
                but he supposed that that gentleman could offer his substitute for the Committee's report, to which the Chairman replied that the report would be taken up by sections, and <PageNumber num={436} /> the vote was taken on each as they occurred, and when any section was adopted it could not afterwards be substituted by another proposition by the Committee,
                but would stand, as far as it went, as the action of the Committee. <span className="persName">Mr. AMBLER</span> questioned the correctness of this opinion, and asked for further explanation, whereupon <span className="persName">Mr. NELSON</span>, who had moved the session before that the Committee rise, indicating a wish to speak, here claimed the floor, and the Chairman accorded it to him,
                to the exclusion of <span className="persName">Mr. AMBLER</span>; from which opinion, <span className="persName">Mr. AMBLER</span> took an appeal, and the question being put to the Committee, the Committee sustained the opinion of the Chair."
              </p>
              <p className="p-in-sp">Now, that was just as fair a statement of what did transpire as I, at that time, could possibly give. I showed it to the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] . He was not satisfied with it, and the Assistant Clerk [<span className="persName">Mr. GRAEME</span>]
                , made out a statement of his own, with which I was satisfied; but the gentleman from Louisa was not. The Clerk then made out another statement which was modified by me so as to make it conform to my recollection of what did transpire. If there is injustice done to the gentleman from Louisa, I hope the Committee will correct it and do him full justice. I desire to say this: that I
                have had but little experience as a presiding officer and may have erred. If I have erred in any particular, let the error be corrected. With reference, however, to the facts that transpired, although inexperienced as a presiding officer, I have as distinct recollection as though I were more experienced.
              </p>
              <div className="speaker" id="sp1481"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">
                It is too late now to enter upon the question whether justice or injustice was done me. That question is decided. Now, what is due to me is that I should have on the journal such a statement as will place me fairly upon the journal. I submit to the Committee that it is evident that these words which I propose to strike out, and which say distinctly that there was a question before
                the committee and that the Chairman was speaking to that question, are not sustained by the statement made by the Chairman himself. It was a statement of what the Chairman would do in a contingency not then having arisen. Therefore, such a statement does me injustice in stating that I objected in any way, or threw any obstruction in the way of the Chairman expressing an opinion upon
                the question before the Committee. Now, Sir, I hope that it does not require any argument to show that this question was not before the committee. The proceedings themselves show that this question was not before the committee. It is doing injustice to me to state, not only by implication, but directly, that this question was before the committee, when it is acknowledged on all
                <PageNumber num={437} /> hands that such a question was not before the committee. Does it not place me in a false position to say that I was making objections when he was explaining a question not before the committee? There was no amendment offered. There was nothing in the proceedings in committee which required that the Chairman should express his
                opinions upon this question. Then if I am right in that—and, in my opinion, I am clearly right, and I think that the statement of the chairman himself shows that I am right—these words should be stricken out, as the merest act of justice to myself.
              </p>
              <p className="p-in-sp">There is another amendment to which I propose to ask the attention of the committee, when the present question is disposed of.</p>
              <div className="speaker" id="sp1482"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">Was not the whole report before the committee? Was it not proper for the Chairman to explain what is the effect of the action of the committee upon any part or the whole; and was it not perfectly legitimate, when it was remarked by the gentleman from Louisa that the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] was not in his seat to offer his substitute for the whole report, to say if any part of the report was adopted, that the substitute of the gentleman from Amelia could not be substituted for it, so far as the action of the committee went?</p>
              <div className="speaker" id="sp1483"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question now pending is upon the motion of the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] , to have the journal corrected in the manner indicated by him.</p>
              <p>The question was taken and the motion was agreed to.</p>
              <div className="speaker" id="sp1484"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">I also wish to have the fourth paragraph of the journal stricken out, and these words inserted:</p>
              <p className="p-in-sp">"<span className="persName">Mr. AMBLER</span> propounded an interrogatory to the Chairman, without answering which, the Chairman decided that <span className="persName">Mr. NELSON</span> was entitled to the floor."</p>
              <p className="p-in-sp">Now, sir, if there was any answer propounded by the Chair to the interrogatory at that time, I am not aware of it.</p>
              <div className="speaker" id="sp1485"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair will state the question. A proposition is made still further to correct the journal, which now reads as follows:</p>
              <p className="p-in-sp">"<span className="persName">Mr. AMBLER</span> propounded an interrogatory to the Chairman, and the Chairman having answered the same in part, decided that <span className="persName">Mr. NELSON</span> was entitled to the floor."</p>
              <p className="p-in-sp">The gentleman from Louisa moves to strike out that portion of the journal and insert the following in lieu thereof :</p>
              <p className="p-in-sp">"<span className="persName">Mr. AMBLER</span> propounded an interrogatory to the Chairman, without answering which, the Chairman decided <span className="persName">Mr. NELSON</span> was entitled to the floor."</p>
              <PageNumber num={438} />
              <p className="p-in-sp">The question is upon the proposed amendment.</p>
              <div className="speaker" id="sp1486"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">I wish to explain the position of the matter. I do not know that the record of the journal is wrong. I think the committee will certainly bear in mind that there was an interrogatory propounded and answered. In the first place, the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] , said that the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>]
                , was not in his place to offer a substitute, and the response was, that if the Committee adopted any of the sections, a substitute could not be introduced for the entire report. So far as the sections were concerned, they would stand as the action of the committee. And I am sure the committee will remember that I stated the opinion of <span className="persName">Mr. SOUTHALL</span> to be
                the same as mine. I admit that all the interrogatories which were propounded, were not answered, so that the journal is literally true.
              </p>
              <div className="speaker" id="sp1487"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I am satisfied that a large number of the members of this Convention have not heard one word of the correction that has been made. I am satisfied that very few of them heard the statement made by the gentleman from Greenbrier [<span className="persName">Mr. PRICE</span>] , and I do not think there were a dozen members voting on the question to correct the journal. I think that now a large number of the members do not understand the question, because they have not heard the statement made by the gentleman from Louisa and that made by the gentleman from Greenbrier. I desire that the corrections shall be stated.</p>
              <p>The CHAIRMAN repeated the question as previously stated by him.</p>
              <p>The question then came up on the motion to strike out and insert, when the committee refused to alter the journal.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.9.4">
              <h3><span className="headingNumber">1.9.4. </span><span className="head">FEDERAL RELATIONS</span></h3>
              <div className="speaker" id="sp1488"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">When the committee rose yesterday, it had under consideration the report of the Committee on Federal Relations. If no amendment is offered to that report, shall we now proceed to take action upon the several resolutions seriatim?</p>
              <div className="speaker" id="sp1489"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">Mr. Chairman, I offer the following as a substitute for the Report of the Committee on Federal Relations.</p>
              <p>The substitute was then read as follows:</p>
              <p>Seven States having withdrawn from the Federal Union and formed a new and distinct Government, it has become necessary for Virginia, if she remains in the present Federal Union, to obtain guarantees, by way of amendments to the Constitution of the United States upon the following points:</p>
              <p>1st. A recognition, that by virtue of the Constitution, African slavery does exist in all the territory of the United States, and must be protected by the Federal Government.</p>
              <PageNumber num={439} />
              <p>2d. Upon all questions relating to the acquisition of territory a concurrent majority of the Senators in Congress from the slaveholding and non-slaveholding States shall be required.</p>
              <p>
                3d. With a view to settle the vexed question of the territories it is agreed that in all the territory of the United States, now held or hereafter acquired, situate North of 36 degrees 30 minutes North latitude, slavery is prohibited, and in all the territory of the United States now held or hereafter acquired South of said line African slavery is recognized as existing, and shall
                receive its necessary protection as property from the various departments of the Government.
              </p>
              <p>4th. On all questions relating to laying taxes, duties, imposts and excises, or any other means necessary to raise revenue for the support of the General Government, a concurrent vote of a majority of the Senators in Congress, from the slaveholding and non-slaveholding States, shall be required.</p>
              <p>5th. The right of transit by the citizens of the several States with their property, slaves included, through the States and territories.</p>
              <p>6th. The rendition of fugitive slaves, and in case of their loss by violence or intimidation, remuneration by the General Government to the owner; and Congress shall provide for its reimbursement by laying and collecting a tax upon the State, city, or county in which said loss occurred.</p>
              <p>7th. That Congress shall not abolish or interfere with slavery, as it now exists, in the District of Columbia; nor shall it abolish or interfere with slavery in any of the States, by whose laws it is or may be allowed or permitted.</p>
              <p>8th. The withholding from persons who are in whole or in part of the African race, the elective franchise and the right to hold office, whether Federal, State, Territorial or Municipal.</p>
              <p>9th. Congress shall have no power to abolish slavery in places under the exclusive jurisdiction of the Federal Government, and situate within the limits of States that permit the holding of slaves.</p>
              <p>10th. That the importation of slaves from foreign countries into the United States shall be forever prohibited.</p>
              <p>11th. That Congress shall have no power to interfere with the slave trade between the States.</p>
              <p>12th. That the foregoing amendments shall not be subject to repeal</p>
              <p>or modification except with the consent of all the States of the Union.</p>
              <p>And in order that amendments to the Constitution of the United</p>
              <p>States, carrying into effect the foregoing suggestions shall be submitted</p>
              <PageNumber num={440} />
              <p>to the people of the several States now in the Federal Union for their approval or rejection, therefore:</p>
              <p>
                Resolved, That this Convention will appoint one Commissioner to each of the States of the Union, who shall be instructed to submit to the Legislatures of said States, if in session, and if not, to the Governors of said States, the foregoing amendments, with the request that the same may be acted on either by the Legislatures of said States, or by Conventions called by the
                Legislatures of said States, on or before the first Monday in October next.
              </p>
              <p>Resolved, That in case said amendments shall not be approved by the Legislatures or Conventions of said States on or before the first Monday in October next, that then an ordinance to the effect indicated in the following resolution, shall go into operation on the third Monday in October next.</p>
              <p>Resolved, That this Convention will adopt an ordinance resuming to the State of Virginia the powers heretofore delegated by her to the Federal Government, to take effect on the third Monday in October next, in case the amendments indicated in the foregoing suggestions are not approved by the several States of the Union on or before the first Monday in October next.</p>
              <p>Resolved, That the said amendments and ordinance be submitted to the people of this State at the next general election for their approval or rejection.</p>
              <p><span className="persName">Mr. CONRAD</span>, of Frederick, called for the ayes and noes, on the adoption of the above substitute, offered by the gentleman from Jackson [<span className="persName">Mr. TURNER</span>] .</p>
              <p>The Committee refused to adopt the substitute by a vote of 89 to 37, as follows:</p>
              <p>AYES—Messrs. Ambler, Blakey, Boisseau, Borst, Chambliss, Coffman, Conn, R. H. Cox, Fisher, Graham, Gregory, Jr., John Goode, Jr., T. F. Goode, Harvie, Holcombe, Hunton, Isbell, Kindred, Lawson, Leake, C. K. Mallory, J. B. Mallory, Montague, Morris, Morton, Neblett, Randolph, Richardson, Seawell, Strange, Thornton, R. H. Turner, F. P. Turner, Tyler, Williams, Wise, Woods —37.</p>
              <p>
                NOES—Messrs. Janney (President), Armstrong, Aston, Baldwin, Baylor, Berlin, Blow, Jr., Boggess, Boyd, Brent, Brown, Burdett, Burley, Byrne, Cabell, Campbell, Carlile, Chapman, Clemens, C. B. Conrad, R. Y. Conrad, Couch, J. H. Cox. Custis, Deskins, Dorman, Dulany, Early, Echols, Forbes, Fugate, Garland, Gillespie, Gravely, Gray, Goggin, A. Hall, C. Hall, E. B. Hall, Hammond, Haymond,
                Hoge, Holladay, Hubbard, Hughes, Hull, Jackson, M. Johnson, P. C. Johnston, Kilby, Lewis, McComas, McGrew, McNeil, Macfarland, Marshall, Marye, Sr., Maslin, Masters, Miller, Moffett, Nelson, Osburn, Parks, Patrick, Pendleton, Porter, Preston, Price, Pugh, Rives, R. E. Scott, Sharp,
              </p>
              <PageNumber num={441} />
              <p>Sheffey, Sitlington, Slaughter, Southall, Speed, Spurlock, Staples, C. J. Stuart, Summers, Sntherlin, Tarr, Tayloe, Waller, Whitfield, Willey, Wilson-89.</p>
              <p>During the calling of the roll, <span className="persName">Mr. MARYE</span>, of Spotsylvania, called for another reading of the substitute. The calling of the roll was suspended, and the substitute was read again by the Clerk. After the reading,</p>
              <div className="speaker" id="sp1490"><span className="persName">Mr. WISE</span> said :</div>
              <p className="p-in-sp">Mr. Chairman, is the question now upon the propositions just read as a substitute for the report of the Committee on Federal Relations?</p>
              <div className="speaker" id="sp1491"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Yes, sir, and the Committee is voting.</p>
              <div className="speaker" id="sp1492"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Have you begun the call of the House, sir.</p>
              <div className="speaker" id="sp1493"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Yes, Sir.</p>
              <div className="speaker" id="sp1494"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">It is not in order, I suppose, then, to make any alterations now.</p>
              <div className="speaker" id="sp1495"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">No, sir.</p>
              <div className="speaker" id="sp1496"><span className="persName">Mr. GREGORY</span>, of King William, pending the calling of the roll, said :</div>
              <p className="p-in-sp">Although there are some features of the propositions, offered by the gentleman from the county of Jackson [<span className="persName">Mr. TURNER</span>] , to which I object, yet, preferring it, as a whole, to the report of the Committee of Twenty-one, I vote aye.</p>
              <div className="speaker" id="sp1497"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
              <p className="p-in-sp">I ask to be excused from voting, as neither of the propositions suits my views, inasmuch as both look to delay.</p>
              <p>Objection was made, and <span className="persName">Mr. GOODE</span>, being compelled to indicate his preference, voted in the affirmative.</p>
              <div className="speaker" id="sp1498"><span className="persName">Mr. MONTAGUE</span>, of Matthews and Middlesex—</div>
              <p className="p-in-sp">There are some things in this substitute that I don't approve of, but as a whole I prefer it to the majority report of the Committee, and so I vote aye.</p>
              <div className="speaker" id="sp1499"><span className="persName">Mr. MORRIS</span>, of Caroline—</div>
              <p className="p-in-sp">In explanation of my vote, I would make a similar statement to that of the gentleman from Middlesex—that, although I object to some features of the substitute, still, preferring it to the report of the majority of the Committee, I vote aye.</p>
              <div className="speaker" id="sp1500"><span className="persName">Mr. SHEFFEY</span>, of Smyth—</div>
              <p className="p-in-sp">
                Mr. Chairman, I desire to state very briefly my reasons for the manner in which I shall cast my vote. Whilst there are propositions in this substitute that I would approve, I think the form in which it is presented does not come up to the necessities of the question. It does not at all propose guarantees and amendments in the form in which they are to be adopted. It merely suggests
                the points upon which those guarantees and amendments are to be made. Therefore, I am under the necessity of voting no.
              </p>
              <PageNumber num={442} />
              <div className="speaker" id="sp1501"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I enquired whether, if this substitute be adopted, it would be amendable or not?</p>
              <div className="speaker" id="sp1502"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">No, sir; not after it is adopted.</p>
              <div className="speaker" id="sp1503"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I mean, if it is adopted simply as an amendment, will it be subject to amendment before final action?</p>
              <div className="speaker" id="sp1504"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">No, sir, it was open to amendment before the house commenced voting.</p>
              <div className="speaker" id="sp1505"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                This is a proposition so nearly similar in some of its features and in some of its forms, to the substitute which I have reported, that I cannot vote against it; and yet if it is not amendable, and materially amendable, I should not like to vote for it. But considering it, with all its defects, superior to the report of the Committee, I vote aye, and take my chances.
              </p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp1506">After the vote had been announced, <span className="persName">Mr. HARVIE</span>, of Amelia, said :</div>
              <p className="p-in-sp">
                Mr. Chairman, I have been enquired of by a great many gentlemen, whether it was my purpose, and if so, when, to offer, in this Committee, the minority report that I had the honor to submit to the Convention, as a substitute to the majority report. In order to relieve myself from further enquiries of that sort, and in order also to satisfy the Committee of what my purpose is, I rise
                to give notice that I undoubtedly shall, at a proper time, at such time as I shall deem most expedient, offer the minority report which I had the honor to submit to the Convention, as a substitute for the majority report. I make this announcement now, not so much because of my personal desire to debate the question, but because I understood the Chair to decide that when any member
                indicated his desire to offer a substitute, he could enter into a discussion upon the merits of the subject.
              </p>
              <div className="speaker" id="sp1507"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair did not decide that.</p>
              <div className="speaker" id="sp1508"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I should like to hear what the decision of the Chair was.</p>
              <div className="speaker" id="sp1509"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The decision of the Chair was, that nothing is debatable except the propositions before the House.</p>
              <div className="speaker" id="sp1510"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I understood that the Chair decided that if a gentleman indicated that he would offer an amendment, the Chair would tolerate discussion upon it.</p>
              <div className="speaker" id="sp1511"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair now believes that the gentleman from Amelia is right.</p>
              <div className="speaker" id="sp1512"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">Then I shall, at a subsequent period, offer my report as a substitute for the whole.</p>
              <div className="speaker" id="sp1513"><span className="persName">Mr. GOGGIN</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, as the gentleman from Amelia has indicated what will be his course as to the amendment, and having <PageNumber num={443} /> given notice of his purpose to offer it, I ask the same indulgence; and I wish to say that whenever the gentleman from Amelia shall offer his report as a substitute for the report now before the Committee, I shall
                move, if it be in order, to substitute my propositions for the report of the gentleman from Amelia. I did not understand the Chair as deciding that any proposition which was offered as a substitute for the whole of the report of the Committee of Twenty-one was not subject to amendment.
              </p>
              <div className="speaker" id="sp1514"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It is susceptible to amendment in part, so as to perfect it before the final vote upon it is taken.</p>
              <div className="speaker" id="sp1515"><span className="persName">Mr. GOGGIN</span>—</div>
              <p className="p-in-sp">Do I understand the Chair as deciding that if an amendment is offered by the gentleman from Amelia, that amendment is not subject to amendment?</p>
              <div className="speaker" id="sp1516"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It is subject to partial amendment; but a motion would not be in order to strike out the whole of it, and insert another. The gentleman from Bedford can take the same course that the gentleman from Amelia has taken.</p>
              <div className="speaker" id="sp1517"><span className="persName">Mr. GOGGIN</span>—</div>
              <p className="p-in-sp">I merely wished to indicate that it was my purpose to bring my propositions in competition with the report of the gentleman from Amelia.</p>
              <div className="speaker" id="sp1518"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Clerk will proceed to read the first resolution in the report of the Committee on Federal Relations.</p>
              <p>The Clerk read the first resolution as follows :</p>
              <p>
                "1. Be it resolved and declared by the people of the State of Virginia in Convention assembled, That the States which composed the United States of America, when the Federal Constitution was formed, were independent sovereignties, and in adopting that instrument the people of each State agreed to associate with the people of the other States, upon a footing of exact equality. It is
                the duty, therefore, of the common Government to respect the rights of the States and the equality of the people thereof, and within the just limits of the Constitution, to protect with equal care, the great interests that spring from the institutions of each."
              </p>
              <div className="speaker" id="sp1519"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I move to amend that resolution. As it now stands it reads, "that the States which composed the United States of America, when the Federal Constitution was formed, were independent sovereignties," &amp;c. I move to amend by inserting after the word "were" the words "and still are," so that it will read "were and still are independent sovereignties."</p>
              <div className="speaker" id="sp1520"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">
                I trust that this amendment will not be adopted. While it is probable that there might be no difference of <PageNumber num={444} /> opinion between the gentleman and myself, yet I think, if he looks carefully to the phraseology of this resolution, he will find that it really does not present the question which he wished to raise before this Convention.
                He will see that it does not speak of the people of the States so as to raise any question whether the people of Virginia are or are not a sovereign and independent people. It speaks of the State in its corporate capacity. Well now, sir, I think the gentleman as a constitutional lawyer, would be prepared to admit that the State of Virginia, in its political capacity, as a State, is
                not properly speaking independent, because the people of the State of Virginia have made it, by the adoption of the Federal Constitution pro tanto dependent on and subordinate to the Federal Government. Unquestionably the Federal Constitution says that the Constitution and laws enacted under that Constitution shall be paramount to any laws enacted by the State of Virginia. We cannot,
                therefore, with any sort of propriety hold that the State in its political capacity remains after this adoption of that Constitution, independent of the Federal Constitution. It would be an affirmation in fact that the State Government is independent of the Federal Government.
              </p>
              <p className="p-in-sp">
                Now, while the people of Virginia as I admit, have the right at any moment to declare, by overthrow of the Federal authority, that they resume their independence in regard to that government, yet I cannot admit that after the adoption of the Federal Constitution and while the people of Virginia do not choose to sever their connection with the Federal Government, the State Government
                which they have created and to which they have given a part of their sovereign power, if the gentleman chooses to call it so, is entirely independent of the other government which they have also created, to wit, the Federal Government; and although this would, perhaps, be a very material alteration, yet so far as it is of any practical consequence, I cannot imagine that the
                Convention will adopt it. I certainly cannot consent to it, because it would involve us in a declaration of that which, as a lawyer, I am unable to affirm; and I think that upon reflection my friend himself will be unable to affirm.
              </p>
              <div className="speaker" id="sp1521"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I desire to say just one word in support of my amendment. I knew very well before I offered it, what was the view of the distinguished gentleman who has just taken his seat. I knew very well that he believed this Federal government at Washington was a consolidated central government, and that the States were merely corporations dependent upon it.</p>
              <div className="speaker" id="sp1522"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">
                The gentleman does not state my position <PageNumber num={445} />
              </p>
              <div className="speaker" id="sp1523"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">
                I am glad the gentleman has made that explanation. The gentleman, then does not believe that the government at Washington is a consolidated central government. Then it must be a government which is the mere creature of the States. And if it is a mere creature of the States and not a consolidated government, I ask how you can arrive at the conclusion that the States are not sovereign
                and independent? But the gentleman says the Constitution of the United States declares that all the laws, treaties, &amp;c., made in pursuance thereof, shall be the supreme law of the land, and that the States by assenting to that, have ignored their sovereignty so far as these questions are concerned. But he says that the State of Virginia can, if she thinks proper, break her
                connection with the Federal government, dissolve her relation to it and withdraw from the concern. I ask the gentleman and I ask the Convention, how Virginia can sever her connection with the general government, if she is not sovereign and independent? how can it be done? I ask the question and I call upon every member of this Convention to answer, whether the admission upon the part
                of the gentleman from Frederick, that the State of Virginia can do that, is not an admission that she is a sovereign and independent State?
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I had an object in view in offering this amendment, but I have no disposition to discuss my motives about anything. I suspect that a majority of the members of this Convention entertain the opinion that Virginia is not a sovereign State now. Her people have always entertained the opposite opinion, and I want the people of Virginia to see whether their representatives
                now will take back what they have contended for in this State for seventy-five years. That is why I have offered the amendment, and why I insist upon it. I call for the yeas and nays upon its adoption.
              </p>
              <p>The yeas and nays were ordered.</p>
              <div className="speaker" id="sp1524"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">It seems to me that the amendment of the gentleman from Middlesex involves rather a question of fact than a question of law. I understand the gentleman proposes the adoption of an amendment by which we shall be made to declare that the States which yet remain in this Union are now independent.</p>
              <div className="speaker" id="sp1525"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">Sovereign.</p>
              <div className="speaker" id="sp1526"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">
                Independent. If we were to declare that, Mr. Chairman, we should declare what every gentleman, upon a moment's consideration, must see to be untrue. If Virginia is a sovereign and independent State now she may unquestionably do whatever a <PageNumber num={446} /> sovereign and independent State may do. She may declare war; she may regulate her
                intercourse with other nations; she may regulate her commerce with other nations. Does the gentleman from Middlesex mean, that while Virginia is a member of this Confederacy she has the war power? Does he mean to say that she has the commercial power? Yet she must have these powers if it be true that she is now sovereign and independent. I cannot conceive how any gentleman can bring
                his mind to the conclusion that while the State of Virginia is a member of the Confederacy, while the most important powers that pertain to sovereignty have been parted with by us and the exercise of these powers ceded to a common government, that she is yet sovereign and independent. On the contrary, is it not true to a great extent that she is dependent upon the will of her
                associate States. She is dependent upon their will in respect to declarations of war. She is dependent upon their will in respect certainly to the regulation of her intercourse with other nations and so in respect to all those great powers which properly pertain to sovereignty and with which she has parted; for, alone she can exercise none of them, but is restricted, is dependent
                upon the will of her Confederate States for their exercise. I trust, therefore, that it will not be the pleasure of the Committee to engraft the amendment upon this resolution, which would make us speak so contrary to the fact.
              </p>
              <div className="speaker" id="sp1527"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                We are going over the old ground now that we went over, as is very well known, in the Committee of twenty-one. I rise to say directly in the teeth of the argument of the gentleman from Fauquier that the State of Virginia is more independent this day than she was when George III recognized her independence; more independent now after the formation of the Federal Union than she ever
                was before she formed that Union. And why? The Union was one intended and calculated only to strengthen her independence, and it is so declared on the face of the act and deed of Union. I mean what I say. I mean to answer the gentleman's question as to the attributes of sovereignty. I might ask him what are the attributes of sovereignty. He has not defined them, and he cannot, and I,
                to help him, cannot define them. The best writers on the law of nations never have been able to define the attributes of sovereignty. He asks me if Virginia has the power to declare war. I say yes, emphatically. How? Through her General Government. That is her power. Has she the power to regulate commerce? I say yes. How? Through the Federal Government. That is her power, and she
                never parted with the war making power. She never parted with the commercial power when she merely <PageNumber num={447} /> created an agent by whom to exercise the power. The compact of the Federal Government, in that respect, is nothing more than a compact with co-States to exercise their respective powers jointly, yet independently, more
                independently now than ever, because the independence thereby has been, I repeat, made stronger than ever. If the gentleman's rule be true, will he please tell me of a nation on the face of the earth that is independent? I will take a nation that is just formed and has no treaty with any other upon earth, if there be such an one that is recognized de facto and de jure; is that
                nation, if his rule of construction be true, independent? No, sir. Why not? Because there is a code, an international code, binding upon nations, pledging the nations in the great family of nations, which itself creates in one sense the dependency of the nations. All nations are dependent in the sense in which the gentleman says we are dependent.
              </p>
              <p className="p-in-sp">
                I go further, sir. If his mode of reasoning be correct, France is not independent, England is not independent. Why? She is not only dependent upon the law of nations, but upon special contracts and treaties, binding—musty from time, sanctioned by iteration oft repeated —governed by international laws, from which she cannot absolve herself. In that sense she is as materially dependent
                as you are upon any compact you have made with co-States. Yet France and England, I presume the gentleman will say, are independent sovereignties.
              </p>
              <p className="p-in-sp">
                I go farther still. Not only are the powers which you refer to, the power of war and commerce, not granted away from Virginia; not only were they never ceded—but they were in what is called the grant, expressly recognized, called, named, known as the powers of the United States—the powers of the States United—their independent powers, their co-powers, their Union powers, independent
                and separate, brought together by consent into the hands of a common agent. Did any man who ever created an agent by power of attorney, suppose that he parted with his rights of property or proprietorship, of control over the subject matter of the agency? No, sir! I go farther still, and, especially as to the war power, will show that the Constitution of the United States expressly
                reserves to Virginia, and to every other State in the Union, the very specific power called the war power. Whenever she, in her sovereign, independent judgment, shall declare that her safety requires the exercise of the war power, by and according to the Constitution of the United States, she has the power to engage in war. She has the power to make treaties, compacts or agreements
                with other States, or with foreign powers also; and I presume if she has the power to make compacts and engage in war, to make compacts and treaties with <PageNumber num={448} /> other States or with foreign powers, she will have the power to regulate commerce, or to do anything else. I knew, sir, that your report would bring up the fundamental
                principles that have guided us in this State, at least from 1800 down to this time. It is impossible to keep them down. The old strict construction of the States Rights principle meet an antagonism here. The federal principles of consolidated power could not be better and more strongly stated than they have been stated in the questions put to us by the gentleman from Fauquier
                [Mr. Scow] . This very amendment now moved, I tried to have inserted in the report of the committee. It failed there, and I supposed it would fail here.</p>
              <div className="speaker" id="sp1528"><span className="persName">Mr. BRUCE</span>—</div>
              <p className="p-in-sp">I beg to make a single observation. It seems to me that if the States were independent under the old articles of confederacy, they are independent under this Constitution. The argument of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
                would go to prove that this resolution itself is not true, because under the old articles of confederation the States had evidently parted with a large portion of what he calls their sovereignty. But the sovereignty of a State, we who belong to the school of States Rights hold cannot be alienated, and that a sovereign State cannot part from her sovereignty without an entire
                destruction. If you give way any amount of power; if a sovereign State parts with any amount of power and leaves but little, to that little clings the sovereignty of the State, and that little she has a right to protect. That is my idea of a sovereign State. It is the soul of a State. You may lop off the hand and the arms from the body; you may cut off the leg; you may mutilate the
                face—but if you leave the soul, that entity which we call the soul still exists. A nation may part with almost every power without parting with her sovereignty. The gentleman from Princess Anne
                [<span className="persName">Mr. WISE</span>] very truly remarks that every nation has compacts and treaties which are binding upon them, and which do, in fact, interfere with her sovereignty. We know that England cannot tax the wines of Portugal; nor can Portugal tax the woollens of England. But does that interfere with the sovereignty of either of these Governments?</p>
              <p><span className="persName">Mr. RIVES</span> addressed the Committee.</p>
              <div className="speaker" id="sp1529"><span className="persName">Mr. RIVES</span>—</div>
              <p className="p-in-sp">
                I propose to make a few remarks only. Being one of the Committee on Federal Relations, I deem it proper to do so, whilst I admit that there are other gentlemen, especially the distinguished gentleman who is chairman of that Committee, who are better prepared to discuss questions which will be presented to this Convention than I am. Yet, I cannot remain silent and hear doctrines
                avowed here, which, if they be true, will render us utterly powerless to do any act <PageNumber num={449} /> ourselves. We will never agree that any ground shall be assumed here that repudiates, denounces and renders null and void the action of the Convention, which was held in Virginia to ratify the Federal Constitution. If the doctrine be true, that
                these States are now independent States, above and beyond control, superior in themselves, not able to bind themselves, nor to bind anybody else—if that doctrine be true—there is but a very short step between us and omnipotence. What, sir, is the principle of government? It is that a State when it is admitted into the government surrenders a portion of its natural rights, in order to
                be secured in those which remain. Do we claim all the protection of the Government? You surrender, sir, a portion of your natural rights upon the condition that you are to be more strictly guarded by that society into which you enter. We are assembled here in Convention, and so were those who ratified the Federal Constitution—James Madison, Patrick Henry, James Monroe, and the whole
                host of departed statesmen. Yes, sir; statesmen and heroes were in the Convention which ratified the Federal Constitution. Did they have any power to speak for the people? Did they have any power delegated to them, in ratifying the Federal Constitution, in the name and on behalf of the people of Virginia? Have we anything delegated to us that we can do in the name and on behalf of
                the people of Virginia? If these men had the power to do anything which was calculated to bind us as a people—to establish for us a Government which now stands up as a beacon light to the nations of the earth; if they had any power to do anything, did they do it? Let us see what they did. Here is the last paragraph on the ratification of the Federal Constitution. I will read it:
              </p>
              <p className="p-in-sp">
                "We, the said delegates, in the name and in behalf of the people of Virginia, do by these presents assent to and ratify the Constitution recommended on the 17th day of September, one thousand seven hundred and eighty-seven, by the Federal Convention, for the government of the United States; hereby announcing to all those whom it may concern, that the said Constitution is binding upon
                the said people, according to an authentic copy hereto annexed."
              </p>
              <p className="p-in-sp">
                Binding upon whom? Upon the people of Virginia. Bound by whom? Those who are delegated to do the act. Now, sir, there is a modern set of repudiators who repudiate the act of the Convention of 1787, who repudiate the doings and actions of James Madison, the father of that Constitution. I tell you what they have got to do. Before they get out they must commit perjury, before they can
                get clear of their duty, because they stand committed to it through the whole line and letter of the history of the country.
              </p>
              <PageNumber num={450} />
              <p className="p-in-sp">Sovereignty is a thing that asks nothing. She stands and surveys the world, and says, I am your equal. No "pent up Utica" can contract her powers, and she can look and say, "the whole boundless continent is mine." Sovereignty wants nothing; begs nothing. She speaks but to command. But, what does the report, signed by the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>] , the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , and the gentleman from Shenandoah [<span className="persName">Mr. WILLIAMS</span>] , say —</p>
              <p className="p-in-sp">"Resolved, That the Committee on Federal Relations be instructed to report an ordinance resuming the powers delegated by Virginia to the Federal Government, and to make provision for submitting the same to the qualified voters of the Commonwealth for their adoption or rejection.</p>
              <p className="p-in-sp">"LEWIS E. HARVIE, "ROBERT L. MONTAGUE, "SAMUEL C. WILLIAMS."</p>
              <p className="p-in-sp">
                Yes, sir, their proposition is to resume the powers delegated to the General Government. The doctrine contained in their report would strike at doctrines that must not only destroy this Government, but every Government under God's Heaven which restricts its powers. Sovereignty! Virginia has her sovereignty now. Granted. But what says the second section of the sixth article of the
                Constitution of the United States? "This Constitution and the laws of the United States which shall be made in pursuance thereof, and all treaties made or which shall be made, under the authority of the United States, shall be the supreme law of the land; and the Judges in every State shall be bound thereby, anything in the Constitution or laws of any State to the contrary
                notwithstanding."
              </p>
              <p className="p-in-sp">Did you ever read this article of the Federal Constitution? Did my friends see there that this Constitution and the laws of the United States made in pursuance thereof, shall be the supreme "law of the land," the Constitution or laws of any State to the contrary notwithstanding. I say that if the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>]
                , had the same power conferred upon him that this article confers upon the General Government; if he had that power delegated to him, and there was another acting in a subordinate sphere, to be controlled and regulated by him, he would be the last man to allow any departure from the line and rule of action that he had laid down. He would permit no insubordination; he would hold the
                lines up straight, and he would make every one walk up to them; but I do not believe that line or chain would keep him in.
              </p>
              <div className="stage it">[Laughter.]</div>
              <PageNumber num={451} />
              <p className="p-in-sp">
                But, sir, take this article and look at it and say where the power is conferred; say, when a State law comes in contact with a law of the United States, which is to give way? Strike that out of the Federal Constitution and you will have no defence against Personal Liberty Bills. It is upon that section, that we must fight those Personal Liberty Bills. Look at the fifth amendment to
                the Constitution which relates to powers, the exercise of which is prohibited alike to the State, or the Federal Government. I tell my friend from Middlesex
                [<span className="persName">Mr. MONTAGUE</span>]
                , that no man's life, liberty or property, can be taken from him, except by due process of law. Who can take your life, sir? who can take your liberty? who can take your property in the face of the prohibition in the Constitution? If this independent State sovereignty without restriction or limitation be true, that restriction would only extend to the Federal Government, and Virginia
                might play her part unchecked in that circle in which some seem to be disposed to place her. If there be anything at all in the argument it will go a great deal farther than gentlemen wish to carry it. Sovereignty? Who has the power to declare war? The power to declare war, who has it, Virginia? No sir! Look at the eighteen delegated powers, and tell me whether the power to declare
                war is not conferred upon the Congress of the United States? Is that an attribute of sovereignty? Then the power to make treaties, is not that conveyed? Take the attributes of sovereignty. Look at every one of them, from the first to the eighteenth section of the delegated powers, and you will see every one of those attributes of sovereignty are given to the General Government for
                the purpose of establishing a Union for the preservation not only of all the States, but to make them all one colossal gigantic power to resist every attempt at home or abroad to strike down that Government—leaving to the States the exercise of all powers which they did not delegate.
              </p>
              <p className="p-in-sp">I wonder, if the argument is to be carried out, how this provision is to be carried out? I mean the fourth section of the fourth article of the Constitution of the United States, which reads as follows :</p>
              <p className="p-in-sp">"The United States shall guarantee to every State in the Union a Republican form of Government, and shall protect each of them against invasion; and on application of the Legislature, or of the Executive (when the Legislature cannot be convened) against domestic violence."</p>
              <p className="p-in-sp">Suppose we should accept the attributes of sovereignty for which the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>]
                , contends, we could establish a monarchy in Virginia for the government of the people. Could such a government be forced upon them? No, sir, this provision <PageNumber num={452} /> in the Constitution of the United States secures to them a Republican form of Government; and the Father of our Country, whose monument graces yon Capitol Square, and who
                presented that Constitution to us, would arise from his grave and tell the people of Virginia that the people of the other States had pledged themselves in that instrument that they should have a Republican form of government.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I had supposed that the especial friends of these Cotton States would have reserved all this fire which they have expended, so that they might have it to use whenever the question of secession is presented in a tangible form before this Convention. Have they forgotten what distinguishes the present position of Virginia from South Carolina? Look at her Declaration of
                Independence, if you please; look at her whole proceeding; look at her ordinance annulling and rescinding all connection with the Federal Government, and assuming to herself the powers which she had when she was an independent Colony, before she became connected with the Federal Government. Look at that, sir. Ah! all that is necessary to be done before you can take your stand among
                the powers of the earth. And Virginia, not having taken any such action, if we should pursue the course recommended in this minority report, which is signed by the gentleman from Middlesex
                [<span className="persName">Mr. MONTAGUE</span>]
                , we would be placing Virginia in a very false position. It would be placing her on the same platform with South Carolina, and Mississippi, and Alabama, and Louisiana, and the other States that have seceded from the Union. Those States had to annul the action that they took at the formation of the General Government, and I trust to God Virginia never will annul that action which made
                her a part of the Federal Union, until every Constitutional means is exhausted to redress her wrongs.
              </p>
              <p className="p-in-sp">
                When I look at the Constitution I find that every state is to have a Republican form of government, and is bound to be protected by the whole thirty-four States of this Union. All this is to be done, sir. The Constitution guaranties that we are to be guarded; and yet we are to lay down the doctrine in Virginia, that Vermont, for instance, if she wants to go out of the Union
                to-morrow, with a thousand negroes which she may have stolen from Virginia, all she has to do under this new-fangled doctrine, is to say "that I do not believe man can hold property in man, and I will secede." This is the practical working of the doctrine. Establish it and you lose the protection given by the Federal Constitution, and I warn my friend to "shake not his gory locks at
                me, he can not say I did it."
              </p>
              <p className="p-in-sp">
                I vow before God and man that I believe the relation between the <PageNumber num={453} /> Federal Government and the State of Virginia, the Constitution of each of which every officer takes an oath to support, is as binding as the marriage vow itself. When I made a vow at the Hymeneal altar, as to what I would do, I vowed before God, with all the
                solemnity and obligations of an oath; but I tell these gentlemen, that if their logic holds good, you have but one step farther to go, and that is to repudiate these solemn obligations, and you can get new wives as well as destroy your existing Constitution, just as often as you please.
                [Laughter.] The one is not any more simple than the other to a man who has any regard whatever for his oath.</p>
              <p className="p-in-sp">
                I have detained the Committee longer than I intended in the beginning, because I only wished to refer to the prominent features, and to call the attention of this body to what I consider—I will not say the absurdity of the ideas advanced—but to the folly and inconsistency of a State conferring on the Federal Government attributes of sovereignty, and imposing obligations on her
                citizens to support the Constitution of each, and yet, with the compact yet standing in all its binding force, to say such a State is entirely sovereign and independent.
              </p>
              <p className="p-in-sp">
                Further, sir—all the States, when the Federal Constitution was formed, were independent States. These States formed the Federal Constitution. South Carolina was one of the States which composed the United States of America when the Federal Constitution was formed. She was an independent State before she gave her consent to the compact. Is South Carolina one of the States of this
                Union now? Shall we state that South Carolina is an independent State in the Union? You undertake, by that amendment, to say that she is one of the States of this Union, and yet in the exercise of her independence she has declared herself out of it.
              </p>
              <p className="p-in-sp">I have but one more remark to make, and that is, that it was expressly declared that this condition should be binding upon the people of Virginia.</p>
              <p className="p-in-sp">
                Mr. Chairman, I must apologize to my friend, the Chairman of the Committee on Federal Relations, for taking the part that I have taken. The ability, the zeal, the thorough investigation which were displayed by him and others on that Committee, and I include my distinguished<span className="note" id="Note49"
                ><span className="noteLabel">2</span>Probably "friend" has been omitted after "distinguished."</span
                >
                from Richmond
                [<span className="persName">Mr. MACFARLAND</span>] and my equally distinguished friend from Fauquier [Mr. ScOTT] , entitled the Report to careful consideration. Every point was guarded, and every conciliatory measure taken that could be taken consistently, to bring about a satisfactory <PageNumber num={454} /> adjustment of our difficulties and protect the rights of the people of Virginia.</p>
              <p className="p-in-sp">
                By the adoption of conservative, constitutional means of redress, guarantees and amendments to the Constitution, such as those recommended by the Committee on Federal Relations, Virginia and the whole South will be secured in their rights under the Constitution, and the Union preserved. If this is denied us, and we are not allowed to separate in peace, let us take our stand like men,
                sword in hand, and not rely upon the miserable pretext of peaceable secession. Let the friends of the Union stand firm, and defend the report of the committee, that our country, the best government on earth, may be saved, and become an abode for the oppressed of all nations, to worship God in their own way, under their own vine and fig tree, without any to molest them or make them
                afraid.
              </p>
              <div className="stage it">[Applause.]</div>
              <div className="speaker" id="sp1530"><span className="persName">Mr. MONTAGUE</span> of Middlesex—</div>
              <p className="p-in-sp">Mr. Chairman : I wish to make a few remarks. I shall not attempt to reply to the gestures of the gentleman from Prince George [<span className="persName">Mr. RIVES</span>]
                . I mean no disrespect to that gentleman when I say that it is evident from the applause which followed the close of his remarks that his gestures constituted the most interesting part of his discussion. When I first knew him he stood upon the platform I stand on to-day, of State Sovereignty. Subsequently he went over, and denied the doctrine of State Rights. After being out in the
                sterile pastures of the opposition for a while he came back, and again assumed the position of State Rights and State Equality; and there he stood until now. Having deserted his first love, he is proclaiming with his unique, and peculiar, and inimitable gestures, that he is on the side of the Opposition.
              </p>
              <p className="p-in-sp">Sir, the proposition that I present here is, that the report of this Committee declares the fact, that these States, when we formed the Constitution, were sovereign. I say that they are sovereign now. I ask gentlemen to meet that proposition, not by gestures, but by sound argument and by historical facts.</p>
              <p className="p-in-sp">
                Sir, if I understood what fell from the gentleman who preceded me, he proclaimed a doctrine never before uttered in Virginia—a doctrine that is subversive of every principle of civil liberty and constitutional freedom. I have heard him, not amidst the plaudits, but amidst the smiles of this House, assert the doctrine that the great American nation is sovereign. Why is your American
                nation sovereign? Where are your people of the United States? Where are the people of the American nation? I lay down the proposition here—I do not mean to argue it now—that there are no such people known as the <PageNumber num={455} /> people of the United States. If there were, then the doctrine that that Government at Washington is a sovereign
                Government would be true, and then the States would be mere political corporations, and the will of a numerical majority would regulate and control us all. Mr. Chairman, do not the States stand now where they did when they went into the confederation? And did not the articles of confederation expressly declare upon their votes that each and every State was sovereign? Then, when were
                they divested of their sovereignty? I ask gentlemen here, I ask the gentleman from Frederick
                [<span className="persName">Mr. CONRAD</span>] , I ask the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
                , to point me to any word, to any syllable, one jot or title in this Federal Constitution, by which these States were deprived of their sovereignty. Where is it? Cannot sovereign powers, such as these States were, delegate to an agent the power to exercise their sovereign will to a restricted extent, without surrendering their own sovereignty? Cannot you delegate to your overseer,
                upon your farm, certain powers for the management and control of your estate; and cannot you, in the exercise of your sovereign will, divest that agent of the sovereignty with which you have invested him?—cannot you revoke the powers you have delegated? That is the question and the only question.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I do not propose, at this time, to go into any argument. I do mean at the proper time to present my views in extenso upon this question, for the purpose, it affords me infinite pleasure to say, of calling the attention of the people of Virginia to the fact that there is an attempt in this Convention to put forth to the world the dogma that there is such a thing as a
                sovereign nation in this land, and that the States are mere dependencies. If that is so, then farewell ts) your liberty. We will sit down under—not a monarchy, but under a despotism of mere numerical numbers.
              </p>
              <p className="p-in-sp">
                "But," says the gentleman, "if Virginia is sovereign, Virginia could form a monarchy." I ask you, Mr. Chairman, I ask the gentleman, I ask this whole Convention, if Virginia is not sovereign, why is this Convention here? How did you get here in any other way than in obedience to Virginia's sovereign will? Virginia's sovereign call? Sir, if the doctrine is sound, that the government
                at Washington is sovereign, then may Lincoln and Gen. Scott, at the head of the Army of the United States, come here and by force disperse this body and subjugate the Old Dominion to the will of the sovereign government at Washington. Is that the doctrine of the people of Virginia?
              </p>
              <p className="p-in-sp">
                But, Mr. Chairman, I do not desire to take up the time of this Committee by discussing these questions here and now. I intend to do <PageNumber num={456} /> it as fully as I can on some future day. I desire this Convention, by this vote, to put themselves upon the record—upon this amendment. You may be able, by a majority, to carry your point, against
                this little minority, with bold and defiant front; but I tell you that you can never get your masters, the people of Virginia, to entertain your doctrine of consolidation. Go before the people of Virginia upon the naked question, that this State has lost its sovereignty and independence, and when you go into the conflict, although the celebrated war horse of Prince George may be the
                leader in the charge, a little corporal's guard will be all that will be left, when the battle is over. The simple question is, are the States sovereign now? I hold that they are, and I have offered this amendment for the purpose of getting the sense of this House upon that question.
              </p>
              <div className="speaker" id="sp1531"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, I respectfully call the attention of the gentleman from Middlesex, and those who advocated his amendment, to what I shall read. And I will remark, that if he and those gentlemen who think with him are correct, the authors of the Declaration of Independence and the framers of the Federal Constitution, certainly did not understand their own work. On the 18th of January,
                1788, the Constitution of the United States being under consideration in the Legislature of South Carolina, General Pinckney, who was one of the framers of the Federal Constitution, and was a member of the Legislature at the time this question was considered, said :
              </p>
              <p className="p-in-sp">
                "The gentleman had mentioned the treaty of peace in a manner as if our independence had been granted us by the King of Great Britain. But that was not the case; we were independent before the treaty, which does not in fact grant, but acknowledges our independence. We ought to date that invaluable blessing from a much older charter than the treaty of peace—from a charter which our
                babes should be taught to lisp in their cradles; which our youth should learn as a carmen necessarium, or indispensable lesson; which our young men should regard as their compact of freedom; and which our old should repeat with ejaculations of gratitude for the bounties it is about to bestow on their posterity—I mean the Declaration of Independence, made in Congress the 4th of July,
                1776. This admirable manifesto, which, for importance of matter and elegance of composition, stands unrivalled—sufficiently confutes the honorable gentleman's doctrine of the individual sovereignty and independence of the several States. In that Declaration the several States are not even enumerated, but after reciting in nervous language, and with convincing arguments, our right to
                independence, and the tyranny which compelled us to <PageNumber num={457} /> assert it, the Declaration is made in the following words : 'We, therefore, the representatives of the United States of America in General Congress assembled, appealing to the Supreme Judge of the world for the rectitude of our intentions, do, in the name and by the authority
                of the good people of these Colonies, solemnly publish and declare, that these United Colonies are, and of right ought to be FREE AND INDEPENDENT STATES.' The separate independence and individual sovereignty of the several States were never thought of by the enlightened band of patriots who framed this Declaration; the several States are not even mentioned by name in any part of
                it—as if it was intended to impress this maxim on America, that our freedom and independence arose from our union, and that without it, we could neither be free nor independent. Let us then consider all attempts to weaken this Union by maintaining that each State is separately and individually independent, as a species of political heresy, which can never benefit us, but may bring on
                us the most serious distresses."
              </p>
              <p className="p-in-sp">I will now read from the speech made by Mr. Madison, in the Convention which framed the Constitution of the United States. While that instrument was under consideration, before that body, in reply to a gentleman who asserted the individual sovereignty of the States under the articles of Confederation, Mr. Madison said :</p>
              <p className="p-in-sp">
                "Some gentlemen are afraid that the plan is not sufficiently national, while others, that it is too much so. If this point of representation was once well fixed, we would come nearer to one another in sentiment. The necessity would then be discovered of circumscribing more effectually the State governments, and enlarging the bounds of the General Government. Some contend that States
                are sovereign, when in fact they are only political societies. There is a gradation of power in all societies, from the lowest corporation to the highest sovereign. The States never possessed the essential rights of sovereignty. These were always vested in Congress. Their voting as States, in Congress, is no evidence of sovereignty. The State of Maryland voted by counties. Did this
                make the counties sovereign? The States, at present, are only great corporations, having the power of making by-laws, and these are effectual only if they are not contradictory to the General Confederation."
              </p>
              <p className="p-in-sp">Now, sir, one more remark upon this point. If my friend from Middlesex [<span className="persName">Mr. MONTAGUE</span>]
                who I know is a great admirer of Calhoun, will turn to his speech on the Force Bill, in the Senate of the United States, which I have in my room, but which I have not with me, or I would read it—if he will read it, he will find that Mr. Calhoun <PageNumber num={458} /> expressly said there is no such thing in a State as sovereignty under this government
                of ours; that sovereignty resides in the people.
              </p>
              <div className="speaker" id="sp1532"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">The people of the States?</p>
              <div className="speaker" id="sp1533"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">Resides in the people, sir. I imagine there can be but little doubt in the minds of those who have referred to cotemporaneous history of the Constitution that it resides in the people and in the people alone.</p>
              <div className="speaker" id="sp1534"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">With the permission of the gentleman, I desire to ask him whether he holds to the proposition that sovereignty resides in the aggregate people of the nation or in the people of the States?</p>
              <div className="speaker" id="sp1535"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">Sovereignty resides in the people of the several States.</p>
              <div className="speaker" id="sp1536"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">Then you yield the whole question?</p>
              <div className="speaker" id="sp1537"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                No, sir; I do not yield the question. The people of the several States have agreed one with the other that certain sovereign powers shall be exercised for the benefit of all, and they have so far limited their sovereign power as to agree further that any amendment to the Constitution of the United States agreed upon as provided by three-fourths of the several States shall bind the
                other fourth, although they unanimously oppose it.
              </p>
              <div className="speaker" id="sp1538"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">
                I shall vote against the amendment of the gentleman from Middlesex, but not upon the issue which he presents. I deny his right to make any such issue for me at this time, under these circumstances, at this point in the consideration of the report of the Committee on Federal Relations. I shall not depart from my rule of silence at this time, so far as to go into any special argument
                in regard to the question, or questions, which may be raised upon this amendment, and which might occupy the time of this Convention from now until the anniversary of the Declaration of Independence. I will content myself with stating, upon the general doctrine of States rights, that there might be but small difference between him and myself. I believe that when Virginia united with
                the other twelve colonies, on the 4th of July, 1776, and declared with them that they were free and independent States, that she became as free and independent a State as she had been before a separate colony. I cannot agree that the independence thus declared was ever surrendered since by implication. And unless it has been surrendered by implication, it has never been surrendered
                at all. I say that she is as free and independent to-day, in the proper sense of these terms, as she was free and independent on the 4th day of July, 1776, and I will stand to that doctrine.
              </p>
              <p className="p-in-sp">But I do not say that I will, at this time, for the especial purpose of the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>]
                introduce words <PageNumber num={459} /> into this report that would bring up questions, the discussion on which would keep us here until the 4th of July next—to next Christmas—aye, sir, to the end of time—judging from the capacity of this body for debate. I do not say that is the purpose of the gentleman from Middlesex, but I do say, that the effect of
                his amendment is simply to embarrass the report of the Committee on Federal Relations, the consideration of which, as it is now before the Committee, cannot properly involve the question raised by the amendment. For that reason I shall take the liberty of voting against the proposition of the gentleman, but at the same time, I desire to place myself right upon the record. I desire to
                place myself there as declaring, that while I vote against it, I yield nothing to him in my devotion to States rights. I am in favor of no doctrine of consolidation or centralization, but I will not vote to suit the purpose of the gentleman from Middlesex to change the phraseology of the report before us, as he proposes.
              </p>
              <p className="p-in-sp">
                I will only say, in addition, that I trust that those who desire with me, that this report shall be considered with reference to arriving at a definite conclusion, will unite with me in voting down all amendments. I care not how proper they are, I care not though their propriety in themselves be as plain as holy writ—I care not if they be as true as the words of Scripture—I hope they
                will vote down every amendment, unless it shall have a proper bearing upon the precise point before us. This amendment has no such bearing, and was not introduced in reference to any such consideration, and I trust, therefore, that it will be voted down.
              </p>
              <div className="speaker" id="sp1539"><span className="persName">Mr. BAYLOR</span></div>
              <p className="p-in-sp">
                I have always regarded myself as a States rights man, and I still regard myself as such. I am a citizen of a State, but I am also a citizen of the United States; and while I hold that each State has certain rights, yet I am bound to hold that I owe some allegiance as a citizen of the United States. I will read the second section of the 6th article of the Constitution of the United
                States, in order that I may make myself perfectly intelligible.
              </p>
              <p className="p-in-sp">"This Constitution and the laws of the United States which shall be made in pursuance thereof, and all the treaties made or which shall be made under the authority of the United States, shall be the supreme law of the land, and the Judges in every State shall be bound thereby, anything in the Constitution or laws of any State to the contrary notwithstanding."</p>
              <p className="p-in-sp">
                Now, sir, I want to ask a question of this Convention, and particularly of my friend from Middlesex. If the Legislature of Virginia, or any authority in the State, has no right to make any law nullifying <PageNumber num={460} /> a law made in pursuance of the Constitution of the United States, how is Virginia sovereign and independent?
              </p>
              <p className="p-in-sp">
                That is the way to get at it. My idea is that the sovereignty cannot reside in two places. While I hold that the State of Virginia has certain rights, and that to the extent of these rights the General Government dare not infringe upon them, I hold also that the General Government has certain powers and rights delegated to it by the people of the several States, and that under the
                Constitution of the United States these rights, properly exercised, are the supreme law of the land. I hold that so long as that is the case, the State cannot be sovereign and independent.
              </p>
              <p className="p-in-sp">
                The gentleman says, that "if we decide to-day that the Federal Government at Washington is a supreme and independent sovereignty."<span className="note" id="Note50"><span className="noteLabel">3</span>The sentence seems to be incomplete.</span> We do not intend to decide any such thing; but I will tell you what I do intend to decide so far as my vote is concerned. I intend to decide that
                this Government is sovereign and independent, so far as the powers delegated to it, extend under the Constitution of the United States; but that Congress has no reserved power, has no power beyond that delegated under the Constitution. I want to read the 10th amendment of the Constitution, which is sometimes, I know, relied on by gentlemen to prove the Sovereignty of a State, but
                which I shall quote to prove that a State is not sovereign.
              </p>
              <p className="p-in-sp">"The powers not delegated to the United States, by the Constitution, nor prohibited by it to the States, are reserved to the States respectively or to the people."</p>
              <p className="p-in-sp">"The powers not delegated." It seems then that there were some powers delegated ; or else how did you come to get a constitution of the United States if you never delegated any powers? How did it ever become a compact if all the powers were to remain where they were? I should like to see how gentlemen get over that point.</p>
              <p className="p-in-sp">
                But, sir, I did not rise for the purpose of going into a debate of this proposition. I only wanted it to be understood why I shall vote against the amendment of the gentleman from Middlesex. I agree with the gentleman from Rockbridge, that even if we could dispose of the proposition of the gentleman from Middlesex at this point, isolated and alone, I would not consent to do
                it—because the question does not arise here and I do not want to throw any obstacles in the way of the report of this Committee; for I have no doubt that every opportunity will be embraced to throw every obstacle in the way that is possible. I shall vote against the amendment.
              </p>
              <div className="speaker" id="sp1540"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I desire to make a few remarks upon this subject, <PageNumber num={461} /> and especially do I desire to read some authority from Mr. Madison, inasmuch as my friend from Harrison [<span className="persName">Mr. CARLILE</span>]
                has given us what he declared were the opinions expressed by Mr. Madison in the Federal Convention. As to Mr. Pinckney, he was well known not to entertain opinions which sympathize with my own; and I shall, therefore, not undertake to make any reply to the remarks he is reported to have made in the South Carolina Legislature upon the adoption of the Federal Constitution.
              </p>
              <p className="p-in-sp">I am a little surprised that my friend from Rockbridge [<span className="persName">Mr. DORMAN</span>]
                should have expressed so strong an attachment for this report and the resolutions accompanying it, as that not even the work of inspiration itself could be interposed to induce him to reject the action of the Committee of twenty-one. I understand that one of the difficulties that now beset the minds of some gentlemen in reference to this matter, is the failure to recognize the manner
                in which our Federal Constitution was inaugurated.
              </p>
              <p className="p-in-sp">
                Why, sir, the argument of the gentleman from Harrison is as destructive of this report of the Committee of Twenty-one as it is of the position which the States Rights party upon this floor take in vindication of their position; because the report of this committee expressly declares that at the time of the adoption of the Federal Constitution, the thirteen States accepting that
                instrument were sovereign and independent; and the gentleman from Harrison has little understanding if, while sustaining that report, he denies that proposition.
              </p>
              <div className="speaker" id="sp1541"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I trust my friend from Northampton will not see the inconsistency after I have recorded my vote upon the report of the committee.</p>
              <div className="speaker" id="sp1542"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">
                I hope the gentleman will stand by me in voting against the report as it stands. Why, sir, Virginia inaugurated that Federal Constitution as a sovereign and independent State. She consented to this federal compact as a Union between States. In the Federal Convention, each proposition was voted on by States, the little State of Delaware having the same power and influence as the, at
                that time, great States of Virginia, New York and Pennsylvania. It was submitted for ratification to the separate and independent sovereignties, and each State, in its sovereign capacity, adopted and ratified the federal compact which was formed in the city of Philadelphia, by the gentlemen elected to represent separate and independent sovereignties. In all this there was the action
                of sovereign and independent parties, forming a compact as between themselves.
              </p>
              <p className="p-in-sp">
                But was there a surrender of power? Was there an abrogation of <PageNumber num={462} /> power? Was there in any sense an abandonment of the sovereign and independent powers which appertained to each separate State anterior to the adoption and ratification of the Federal compact? I respectfully submit that there was no abandonment. There was a delegation
                of the exercise of some powers which I admit were sovereign in their character; but is it true that a government cannot delegate the exercise of powers sovereign in their character to any functionary which it may select? Why, sir, when any one of the powers of the civilized world delegate a minister plenipotentiary to enter into a treaty, which disposes in any sense or in any way of
                any territory or thing that pertains to sovereignty, it is not an abandonment, it is not a surrender of the functions of sovereignty. As was well remarked by my friend from Middlesex, and also, the same idea expressed by my friend from Princess Anne, if an individual, by a regular power of attorney, authorize an agent to dispose of his patronage, he does not thereby dispose of the
                proprietorship of it. If an individual is the owner of a cotton plantation—and cotton, I am afraid, has but few friends upon this floor—in one of the Confederate States—himself residing in Virginia—appoints an agent to that State, who is authorized to appoint a manager, the individual who exercises the power to appoint a manager exercises the same power which would be exercised by
                the proprietor in person, if he were there, but in that delegation of power to an agent, he does not thereby surrender his right of proprietorship to his plantation in the South. Undoubtedly not.
              </p>
              <p className="p-in-sp">
                Now, sir, let us see—for a recurrence to fundamental principles is a great safeguard against innovations. In what language did Mr. Madison draft the 3d resolution which was presented by Mr. Taylor in the Virginia House of Delegates in 1798. It was in these words; and now let us see how this original draft of Mr. Madison comports with the argument of Mr. Madison in the Federal
                Convention, as quoted by the gentleman from Harrison. Hear what Mr. Madison said in 1798:
              </p>
              <p className="p-in-sp">
                "That this Assembly doth explicitly and peremptorily declare that it views the powers of the Federal Government as resulting from the compact to which the States alone are parties, as limited by the plain sense and intention of the instrument constituting that compact, as no further valid than they are authorized by the grants enumerated in that compact; and that in case of a
                deliberate, palpable and dangerous exercise of other powers not granted by the said compact, the States who are parties thereto have the right and are in duty bound to interpose for arresting the progress of the evil, and for maintaining within their <PageNumber num={463} /> respective limits the authorities, rights and liberties appertaining to them."
              </p>
              <p className="p-in-sp">From whence, if they be not sovereign, do they derive this right of judging? For, sir, I hold that coupled with the power of judging is the power of enforcing the judgment, and I enquire if anything less than sovereign power could enforce judgment which had thus been the result of the sovereign power?</p>
              <p className="p-in-sp">
                But, sir, not only did Mr. Madison maintain this doctrine in this resolution which he drafted, and which was offered by Mr. Taylor in 1798, but Jefferson maintained the same identical doctrine in his resolutions which were offered by Mr. Breckinridge in the Legislature of Virginia.<span className="note" id="Note51"><span className="noteLabel">4</span>This should be Kentucky.</span> From the
                first resolution offered on the 10th September, 1798 in the House of Representatives of the State of Kentucky, the draft of which is universally conceded to Mr. Jefferson, I read as follows:
              </p>
              <p className="p-in-sp">
                "Resolved, That the several States composing the United States of America are not united on the principle of unlimited submission to their General Government; but that by compact, under the style and title of a Constitution of the United States, and of amendments thereto, they constituted a general government for special purposes; delegated to that Government certain definite powers,
                reserving, each State to itself, the residuary mass of right to their own self government; and that whensoever the General Government assumes undelegated powers, its acts are unauthoritative, void and of no force. That to this compact each acceded as a State, and is an integral party, its co-States forming as to itself, the other party; that the government created by this compact was
                not made the exclusive or final judge of the extent of the powers delegated to itself, since that would have made its discretion, and not the Constitution, the measure of its powers; but that, as in all other cases of compact among parties having no common judge; each party has an equal right to judge for itself, as well of infractions as of the mode and measure of redress."
              </p>
              <p className="p-in-sp">That resolution received the sanction of the Legislature of Kentucky, in 1798. And the resolution, which I first read, was drafted by Mr. Madison, and received the sanction of the Legislature of the State of Virginia, when offered by John Taylor in the same year.</p>
              <p className="p-in-sp">
                From that hour to this, from the time that these resolutions were adopted by Kentucky and Virginia, both of these States have maintained the doctrine therein incorporated, and no party in this Commonwealth has ever triumphed politically—no party has ever obtained the control of the legislation of this State, that has not endorsed the
                <PageNumber num={464} /> truth of the doctrine embraced in these resolutions. It is strange, at this late day, when the Union is disrupted, that gentlemen on this floor should be levelling their darts at the doctrine upon which the action of this Convention must be necessarily based, if, indeed, this Convention is, according to my expectation, to
                vindicate the honor and protect the rights of the Commonwealth.
              </p>
              <p className="p-in-sp">I propose to read a portion of the report made by Mr. Madison in 1799, when he was a member of the Legislature, acting on his official oath, in vindication of his resolutions. I would not read it on this occasion, but I am anxious it should go out in the same paper that carries the quotations made by the gentleman from Harrison county [<span className="persName">Mr. CARLILE</span>] , from what Mr. Madison said in the Federal Convention; and I hope the extract will not be so unwelcome to this Convention that they will not bear with me whilst I read it. I will not read the whole of it, but only a short portion of it. The argument he makes here is based upon the third resolution of his report:</p>
              <p className="p-in-sp">
                "Clear as the position must seem, that the Federal powers are derived from the Constitution, and from that alone, the Committee are not unapprised of a late doctrine, which opens another source of Federal powers, not less extensive and important than it is new and unexpected. The examination of this doctrine will be most conveniently connected with a review of a succeeding
                resolution. The Committee satisfy themselves here with briefly remarking, that in all the cotemporary discussions and comments which the Constitution underwent, it was constantly justified and recommended, on the ground that the powers not given to the Government were withheld from it; and that, if any doubt could have existed on this subject, under the original text of the
                Constitution, it is removed, as far as words could remove it, by the 10th amendment, now a part of the Constitution, which expressly declares 'that the powers not delegated to the United States by the Constitution, nor prohibited by it to the States, are reserved to the States respectively, or to the people.' "
              </p>
              <p className="p-in-sp">
                The other position involved in this branch of the resolution, namely, "that the States are parties to the Constitution or compact" is, in the judgment of the Committee, equally free from objection. It is, indeed, true, that the term "States" is sometimes used in a vague sense, and sometimes in different senses, according to the subject to which it is applied. Thus, sometimes it means
                the separate sections of territory occupied by the political societies within each; sometimes the particular Governments established by those societies; sometimes those societies as organized into these particular Governments; and, lastly, it means <PageNumber num={465} /> the people composing those political societies, in their highest sovereign
                capacity. Although it might be wished that the perfection of language admitted less diversity in the signification of the same words, yet little inconvenience is produced by it, where the true sense can be collected with certainty, from the different applications. In the present instance, whatever different constructions of the terms "States," in the resolution, may have been
                entertained, all will at least concur in that last mentioned; because, in that sense the Constitution was submitted to the "States;" in that sense, the "States" ratified it; and, in that sense of the term "States," they are consequently parties to the compact, from which the powers of the Federal Government result.
              </p>
              <p className="p-in-sp">
                The next position is, that the General Assembly views the powers of the Federal Government, "as limited by the plain sense and intention of the instrument constituting that compact," and "as no farther valid than they are authorized by the grants therein enumerated." It does not seem possible, that any just objection can lie against either of these clauses. The first amounts to
                merely a declaration, that the compact ought to have the interpretation plainly intended by the parties to it; the other to a declaration, that it ought to have the execution and effect intended by them. If the powers granted be valid, it is solely because they are granted; and, if the granted powers are valid, because granted, all other power not granted, must not be valid.
              </p>
              <p className="p-in-sp">
                The resolution, having taken this view of the federal compact, proceeds to infer, that, in case of a deliberate, palpable and dangerous exercise of other powers, not granted by the said compact, the States, who are parties thereto, have the right and are in duty bound to interpose for arresting the progress of the evil, and for maintaining, within their respective limits, the
                authorities, rights and liberties appertaining to them.
              </p>
              <p className="p-in-sp">
                It appears to your committee to be a plain principle, founded on common sense, illustrated by common practice, and essential to the nature of compacts, that, where resort can be had to no tribunal, superior to the authority of the parties, the parties themselves must be the rightful judges in the last resort, whether the bargain made has been pursued or violated. The Constitution of
                the United States was formed by the sanction of the States, given by each in its sovereign capacity. It adds to the stability and dignity, as well as to the authority of the Constitution, that it rests on this legitimate and solid foundation. The States, then, being the parties to the constitutional compact, and in their sovereign capacity, it follows of necessity that there can be
                no tribunal above their authority, to decide, in the last resort, whether the compact <PageNumber num={466} /> made by them, be violated; and, consequently, that, as the parties to it, they must themselves decide, in the last resort, such questions as may be of sufficient magnitude to require their interposition.
              </p>
              <p className="p-in-sp">
                It does not follow, however, that because the States, as sovereign parties to their constitutional compact, must ultimately decide whether it has been violated, that such a decision ought to be interposed, either in a hasty manner or on doubtful and inferior occasions. Even in the case of ordinary conventions between different nations, where, by the strict rule of interpretation, a
                breach of a part may be deemed a breach of the whole, it is always laid down that the breach must be both wilful and material to justify an application of the rule. But, in the case of an intimate and constitutional Union, like that of the United States, it is evident that the interposition of the parties, in their sovereign capacity, can be called for by occasions only deeply and
                essentially affecting the vital principles of their political system.
              </p>
              <p className="p-in-sp">
                The resolution has accordingly guarded against any misapprehension of its object, by expressly requiring for such an interposition, the case of a deliberate, palpable and dangerous breach of the Constitution by the exercise of powers not granted by it. It must be a case, moreover, not obscure or doubtful in its construction, but plain and palpable. Lastly, it must be a case, not
                resulting from a partial consideration, or hasty determination; but a case stamped with a final consideration and deliberate adherence. It is not necessary, because the resolution does not require that the question should be discussed, how far the exercise of any particular power, ungranted by the Constitution, would justify the interposition of the parties to it. As cases might
                easily be stated which never would contain aught to fall within that description, cases, on the other hand, might, with equal ease, be stated, so flagrant and so fatal, as to unite every opinion in placing them within that description.
              </p>
              <p className="p-in-sp">
                Now, Mr. Chairman, if Mr. Madison in the argument he made to sustain the position assumed in the third resolution, has not vindicated the position that the States are now sovereign and independent, I should like to know how it may be maintained. I think that the argument is irrefragable. I regard the position which Mr. Madison and Mr. Jefferson have taken, that the States have the
                right to judge of the infractions of the compact and of the mode and measure of redress, necessarily implies the exercise of a sovereign power, a power independent of all other powers. But the gentleman from Prince George
                [<span className="persName">Mr. RIVES</span>]
                , would seem to suppose that we have a national sovereignty that covers this whole Union, in such a manner as if ours is a consolidated <PageNumber num={467} /> and not a Federal government. If this be so, although Virginia shall throw the panoply and shield of her sovereignty over her citizens, yet, if they obey her injunctions and mandates, they are
                traitors and rebels against this consolidated government at Washington and liable to be treated as such.
              </p>
              <p className="p-in-sp">
                This doctrine reduces our people to the condition of the serf of Russia and the slave of Virginia—the right to resist intolerable and unbearable oppression<span className="note" id="Note52"><span className="noteLabel">5</span>Some part of this parenthetical remark seems to be missing.</span>—to the condition in which if their revolution shall be successful, they may receive the plaudits and
                honors which usually attend successful revolutions, but if otherwise, may be compelled to fill the graves assigned to felons and traitors. It is to such extremities that these gentlemen are driven.
              </p>
              <p className="p-in-sp">
                But, Mr. Chairman, these doctrines find no sanction in the history of the formation of our Federal Government, or in the character of our institutions. They are at war with the great principles of human liberty upon which our governments rest, and are antagonistic, as it seems to me, to the impulses of every patriotic heart. According to the arguments of some gentlemen, the deduction
                is clear and indisputable: if we adopt an ordinance of secession, and Virginia withdraws from the present Black Republican Confederacy, and her citizens obey the ordinance, they will be traitors to, and rebels against the Federal Government.
              </p>
              <p className="p-in-sp">
                There is no gentleman on this floor either a traitor or a rebel ; for although I find gentlemen differing from me widely as to the best means of vindicating the honor and maintaining the rights of our own Commonwealth, I will do every one of them the justice to say that it is only a difference, as I humbly believe, as to the best means of accomplishing the same common object. I hope,
                for these reasons, which have been more strongly impressed upon this Committee by others than by myself, that the Committee will sustain the amendment offered by the gentleman from Middlesex
                [Mr. Montague] .</p>
              <div className="speaker" id="sp1543"><span className="persName">Mr. SLAUGHTER</span>—</div>
              <p className="p-in-sp">I agree, in general, with the doctrine laid down by my friend from Middlesex [<span className="persName">Mr. MONTAGUE</span>]
                , in regard to States Rights; but I think that the amendment he proposes is impolitic and unnecessary, for several reasons. In the first place the resolutions of the committee are so bound together, and the principles contained in them are so connected together, that if you begin to amend them in any respect, you will mar and destroy their symmetry. In the second place the words
                which he proposes to insert are incongruous with the proposition upon which he seeks to engraft them. The proposition of the committee speaks of the condition of different States at the time the Confederacy <PageNumber num={468} /> was formed and of their action at that time. It says they were then independent sovereignties, and in adopting the
                Constitution they then agreed to associate on terms of exact equality. The gentleman proposes to amend so as to make the resolution speak of their present condition. Moreover, when you come to examine the conclusion which the committee deduces from these premises, you find that the words proposed to be inserted are not in the least necessary to sustain the principle announced by the
                resolution, viz: the duty of the common Government to respect the rights of the States and the equality of the people thereof.
              </p>
              <p className="p-in-sp">
                I, therefore, contend that the amendment of the gentleman does seem to me to be out of place in this connection. Why the necessity of introducing any thing more? When this amendment was first proposed by the gentleman, I prepared an amendment which I proposed to offer, embracing my views of the subject. I proposed to change the section so as to make it read as follows : "In adopting
                that instrument, the States did not part with their sovereignty, but agreed to associate with each other upon a footing of exact equality."—but, upon examining the whole report, I think these views are so clearly enunciated by the whole taken together, that I have deemed it unnecessary to offer the amendment suggested by me.
              </p>
              <div className="speaker" id="sp1544"><span className="persName">Mr. HOLLADAY</span>—</div>
              <p className="p-in-sp">
                I have not risen to discuss the question as to the right of secession. On the contrary, I have risen expressly for the purpose of giving my reasons why I do not propose to discuss it and why I shall be constrained to vote against the amendment which has been proposed to be offered. I occupy, I presume, the same position occupied by every member upon this floor, that of having been
                supported by constituents who essentially differ in reference to the right which is sought to be admitted into this resolution. I take it for granted that the assertion of the principle sought to be maintained here is one to which the right of secession, as a right appertaining to a State sovereignty, necessarily connects and attaches itself.
              </p>
              <p className="p-in-sp">
                Now, sir, in my humble judgment, whatever may be the opinions of others similarly situated, as I believe all are so situated, I should unquestionably, by voting for that amendment, act in bad faith to my constituents or a portion of them, either upon the one hand or the other. My constituents, or that portion of them who elected me to a seat upon this floor, differ essentially upon
                this subject; and it cannot be believed for one moment that differing radically and essentially upon this subject they elected me here to settle this particular question which must be settled either against the one set or the other set of opinions <PageNumber num={469} /> of the individuals who supported me. If this be true in reference to the
                constituencies which we all represent, does not the same propriety attach to those who have united together and may unite together in the support of the resolutions as reported on behalf of the Committee on Federal Relations?
              </p>
              <p className="p-in-sp">
                Sir, it is sought to incorporate into that resolution the direct assertion of a disputed principle, or a disputed fact, I care not in which light it be regarded. What will be the object of this? I will not say that it has been designed by the mover of the amendment, but what is the necessary effect of the proposed amendment? I understand gentlemen have met here, not for the
                settlement of the political question which divides our own people, but for the settlement of the great questions which disturb the public mind of the country. We find upon the floor of this Convention two parties—one of which is in favor of withdrawing the State of Virginia from the Union, and the other in favor of constitutional guarantees, and of perpetuating the Union. Now, sir,
                does any gentleman understand and fully appreciate that the tendency of the amendment sought to be incorporated into these resolutions is, as the majority is composed of gentlemen differing in opinion upon this subject, to create division in the ranks of those who have met here for a very different purpose? For the reason that the effect of the amendment to those resolutions will be
                to disturb and divide those who wish to agree, and who ought to agree together upon more vital issues, I shall vote against the proposed amendment to the resolutions; and, for the reason that I hold that the whole discussion of this subject, upon the Union side of the house, is out of place, and after the first gushes should not have been taken up. I shall vote against the amendment
                to the resolutions of the Committee on Federal Relations.
              </p>
              <div className="speaker" id="sp1545"><span className="persName">Mr. MACFARLAND</span>, of Richmond—</div>
              <p className="p-in-sp">
                It is quite important, Mr. Chairman, that we should obtain distinct and defined ideas of the proposition before us, if we would avoid error and confusion. The proposition simply affirms, that the State of Virginia is now under all her obligations to the Union, and, whilst it is her pleasure to remain in it, independent and sovereign. That is the proposition. Now I put it to
                gentlemen, to say whether their knowledge of the relations existing between Virginia and the Federal Government, and the actual condition in which the State stands in respect to that Government, authorizes an affirmative response to the question? Can gentlemen conceive of a sovereign power which is not invested with the authority necessary to the maintenance of her independent
                existence? And that, sir, is simply the test.
              </p>
              <PageNumber num={470} />
              <p className="p-in-sp">
                Under the relations established by the Constitution between Virginia and the Federal Government, Virginia, for the time being, is denuded of the power to establish treaties with foreign empires, is deprived of the regulation of her own navigable streams, is subject to taxation, is liable to have her own citizens prosecuted under laws in the enactment of which she had had no agency
                whatever.
              </p>
              <p className="p-in-sp">
                Now, Mr. Chairman, whilst it may be true, but I do not mean to express my concurrence in it, that it is beyond the reach of clear and definite exposition to define what sovereignty is, I apprehend we can all say that it does not exist when we find it deprived of certain attributes. Well now, sir, I beg gentlemen to mark that the question is not whether Virginia may not resume the
                powers delegated to the Federal Government; the question is not whether she may not assert an independent and sovereign existence; in truth, the object of our assembling is to determine whether we will place Virginia on her reserved rights and resume the authority delegated to the Federal Government, But I apprehend until we have passed through that gradation it must be conceded that
                Virginia, for the time being, has relinquished, upon her part, attributes and powers indispensable to her sovereign existence.
              </p>
              <p className="p-in-sp">
                I refer you to the important attribute as to establishing treaties with independent Empires. Did you ever hear of a sovereign that had not the power to establish treaties? Did you ever hear of a sovereign power which could not levy an impost upon its own navigable waters for the purpose of its own improvement? Yet I beg leave to say that Judge Marshall decided against the exercise of
                that power. And so I might go on, referring to taxation, referring to criminal jurisdiction that may be asserted by the Federal Government against Virginia; nay, sir, to that appellate power which has been asserted on the part of the Supreme Court to take jurisdiction of State decisions.
              </p>
              <p className="p-in-sp">
                Now, do gentlemen mean to say that a State which is under the relations to which I have referred, of subordination in essentially the important particulars, is not properly described by being called a sovereignty? I beg gentlemen to mark, that all this does not, in the slightest degree, infringe upon the proposition of the absolute and the unconditional right, on the part of
                Virginia, to assert her separate existence, whenever it shall be her pleasure. But, I confess, sir, that I have heard now, for the first time, that in the actual subsisting relations between Virginia and the Federal Government, we are in a condition of absolute sovereignty.
              </p>
              <p className="p-in-sp">
                Why, gentlemen seem to suppose, that because these relations <PageNumber num={471} /> between Virginia and the General Government were established by the Convention of Virginia, that that qualifies the proposition and relieves Virginia from the condition of having parted with some of her powers, from being regarded as sovereign, as she would have been
                regarded if these powers had not been taken from her. As a logical proposition, is it true that a State may not reduce its condition below a sovereign power by its own Convention, by its own agreement? If a principality, theretofore, invested with supreme power for the sake of alliance and protection, or any other consideration, placed herself under the protection of another power,
                and consented for the time being, with the original attributes of a sovereign and independent State, would our vernacular be properly applied to the language, to be used in the ordinary usage, to call such a State sovereign?
              </p>
              <p className="p-in-sp">
                Sir, I insist that some regard be paid, in the deliberations of this body, and especially in the conclusions to which they may come, touching a legal fact, if I may so describe it, that they use words which are well defined, explicit, and not likely to mislead. Sir, if it were now announced that a State, heretofore unknown in the history of the world—and an example of such kind might
                be furnished by Sardinia in its present condition—was sovereign, would it not be understood that she was invested with all the attributes to establish foreign commerce, to regulate her intercourse with foreign powers; that within her limits she was not subject to taxation by any foreign power; that she had at least, within her own control, her own criminal and civil code? I
                apprehend, sir, that if the word "sovereign" be as I consider it is, definite and expressive, I must have the consent, at least, of the major part of this enlightened body to the proposition, that the term "sovereign" would apply to the case to which I have referred.
              </p>
              <p className="p-in-sp">
                Now, Mr. Chairman, I beg it may be understood that these views are not intended to have any effect upon all other and ulterior questions. I am not aware of any propositions in the public mind which incline it to the use of this term in the sense in which it is here applied. And when an effort is made upon this floor, to enlist the popular prejudice, and incite the popular clamor, I
                beg leave to recall the memory of the gentlemen to their classical expositions. I am not aware of any popular excitement or feeling whatever upon the specific subject we have now before us. I trust that I shall be found upon all proper occasions in giving to the popular voice the consideration and respect which it may so properly challenge; but in the absence of any indications of
                public opinion, when I know nothing about it, I have no other <PageNumber num={472} /> rule to ascertain the sentiment of the public mind than my best reflections to ascertain what is right; and when I have satisfied my own judgment upon that subject, in the absence of indications of popular opinion to the contrary, I shall find myself vindicating the
                popular privilege in following out the best conclusions of my own mind.
              </p>
              <p className="p-in-sp">
                But my whole purpose in rising, Mr. Chairman, was to call, if I could, the deliberate attention of this enlightened body to the application which is sought to be made of the word "sovereign." If Virginia, denuded for the time being, as I have said, of the powers to which I have referred, be sovereign, how is that condition to be described which retains this power, as that that
                Virginia has reserved?
              </p>
              <p className="p-in-sp">
                That there is difference between them, specific, organic, unmistakable, I apprehend can hardly furnish room for doubt or controversy. I then submit, Mr. Chairman, that in retaining the resolution in the form in which it was adopted by the Committee, I assert never was dangerous and heterodoxical.<span className="note" id="Note53"
                ><span className="noteLabel">6</span>There seems to be either some omission, or a change of construction in this sentence.</span
                >
                It was in the form, as I understood, and just the very language fit to describe the important matter in hand.
              </p>
              <div className="speaker" id="sp1546"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                As I am one of that Committee, I ask your indulgence for a few words of reply to another member of the Committee who has taken rank, strong Federal ground. I use this language in no opprobrious, in no demagogue sense whatever; I use it in its fair, legitimate sense. I say that another member of this Committee has taken the bull by the horns, and has distinctly, in your presence,
                claimed that the State of Virginia, in her present attitude and relation, is not an independent State, and is not a sovereign State. I ask the attention of the Committee while I reply to the last two gentlemen who have taken their seats.
              </p>
              <p className="p-in-sp">
                Does anybody say that the State of Virginia, or any power on earth, is absolutely sovereign? He says he has heard it asserted here to-day, for the first time, that the State of Virginia is endowed with absolute sovereignty. I have not heard that asserted here to-day, sir. Such a thing as absolute sovereignty is not known upon earth. Sovereign and independent are both relative terms,
                and not absolute terms; and the gentleman undertakes to say that it was asserted here to-day, not only that the State is a separate and independent sovereign, but that such sovereignty is absolute. I have not heard that opinion expressed, sir. The assertion made here to-day is that she is sovereign in the sense of international law, in the relative sense, in the sense of her
                connections and dependencies, which necessarily belong, to some extent, to every independent and sovereign power. No one has asserted that she is today absolutely separate, sovereign and independent; but every gentleman, <PageNumber num={473} /> who has advocated the adoption of this amendment, has avowed that she is undoubtedly, in the relative sense,
                sovereign and inde pendent. And one of the gentlemen has put it in this form. He says:
              </p>
              <p className="p-in-sp">"Does any one say that a State now sovereign may not by compact or agreement unite or fuse herself with another power, delegate her sovereignty and become subordinate?"</p>
              <div className="speaker" id="sp1547"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I intended that as an exact reply to the arguments of several gentlemen that Virginia ceded powers to the General Government. Hence the arguments, that if she ceded her powers the State was, therefore, deprived of her sovereignty.</p>
              <div className="speaker" id="sp1548"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I beg pardon. He has put the case expressly and has illustrated his meaning. If a power consents to unite with another power, and merge her sovereignty, may she not do it? I tell the gentleman we have got a case in our own history that will illustrate the meaning on both sides. But yesterday, Texas was a separate, independent and sovereign power. I say to the gentleman that Texas is
                no less a sovereign now, united with the United States, than she was when she was a separate, sovereign and independent Government. She is now a united sovereign, independent power. I need scarcely repeat to the Convention the argument that Virginia, whenever she shall declare herself in such imminent danger as will admit of no delay, has immediately, and by her separate act, the
                power of war, and she has the power of making agreements and treaties with other States, even with foreign powers, according to the express words of the Constitution, in the reserved powers and expressed limitation of powers to the Federal Government, put in by her act of sovereignty when she came into the Union. But the gentleman asks me whether she can levy imposts or not. I reply
                to him, as I did to the gentleman from Fauquier; she may declare war when she is not in imminent danger. How? Through the Federal Government. That is her power, precisely as though Texas alone should unite with Virginia, and all the other States should unite with both in a mere offensive and defensive treaty, to unite in war against a common enemy. Sir, they do this, now, whenever
                they declare war. How? By their own treaty-making power. By the treaty which each, severally, by Convention made.
              </p>
              <div className="speaker" id="sp1549"><span className="persName">Mr. MAcFARLAND</span>—</div>
              <p className="p-in-sp">Will my friend be so good as to answer me this question?—whether; if it should so happen that Virginia should be committed to a foreign war against her own remonstrances, against all her power to prevent it, that would be one of the instances in which she could be regarded as sovereign?</p>
              <div className="speaker" id="sp1550"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Yes, sir; yes, sir. Why? Her abiding and solemn compact <PageNumber num={474} /> and agreement in the Union under the Constitution must override a mere temporary and particular disagreement with her partners in that compact. And it is no less true of the German States than the American States. I beg the gentleman's attention. Does he not know there are
                certain confederacies in Europe? Is he not aware of a certain German confederacy as well as this North American confederacy? Will he not agree that Prussia is a sovereign and independent power, separate and yet united, too? Sir, Prussia is bound to the German confederacy; and may she not be forced against her consent into war? Yet who will deny that Prussia is a sovereign and an
                independent power? Will the gentleman answer that? Will he show me the difference between the case here and the case there? These are very great difficulties. Sir, I can inform the gentleman that I have had puzzles put to me by foreign ministers upon this very question, which, perhaps, might be difficult to solve. I remember meeting the Russian Minister, Lomonosoff. He always called
                himself Lom-monnosoff to prevent it sounding like blow-my-nose-off.
                [Laughter.]
                He always contended with me that, according to the European code of international law, the United States, the whole United States, had not the attribute of the war power, although it was known to him and to everybody else that we can declare war. Why? "Because," said he, "according to all the elementary writers, the war power is a sovereign power, and must be wielded by the unit
                power, to be sovereign; and therefore it has always been placed in the Executive department. Where have your republican Americans placed it? Instead of a united, executive war power, you have made it a legislative power, in organizing a municipal government under the Federal Constitution. I mean the government at Washington. It cannot pretend to sovereignty—it is a mere municipality.
                And you have put your war power in the legislative department. What then? One House of Congress can check another House of Congress, and after both Houses have agreed, the President may veto a law passed by both Houses. And there is another check upon your sovereign power. If the executive and legislative departments have united in declaring war, one of your legislative Houses may
                withhold the appropriation of money, and thus deny to war the very sinews of war. It is a very questionable sovereign power."
              </p>
              <p className="p-in-sp">
                I tried to convince him that these very checks and balances of our government were necessary to restrain the arrogance of war powers, that might crush our republican liberty if placed in the hands of the Executive. He could not comprehend it. But it seems my friend cannot <PageNumber num={475} /> comprehend the difference between a separate, independent
                sovereign and a united, independent sovereign; that is the only difference.
              </p>
              <p className="p-in-sp">Now, sir, I call attention to the remarks of my friend from Norfolk [<span className="persName">Mr. HOLLADAY</span>]
                . Permit me to say to him that I do him the credit of saying that he has put this matter upon its fair, bold, great, grave ground. He admits that it is to the sovereignty and the independence of the States that the doctrine of secession attaches. Admitting that, he admits the truth. That is the question which lies at the groundwork of our deliberations. Now, mark you, for I intend to
                hold my friend to his proposition, the doctrine of the right of secession attaches, as he tells us—and I concur with him—to the doctrine of the independence and sovereignty of the States. Secession is very odious, I mean immediate secession, he thinks; and I do not mean to reproach him at all; I merely state the fact as a premise from which to draw a conclusion. I would not, as a
                citizen of Virginia, drag her out of the Union without an effort to restore the Union. To this extent I concur with him. Perhaps I would not wait as long as he is willing to wait.
              </p>
              <p>The hour of two having arrived, the Committee took a recess until four o'clock.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.9.5">
              <h3><span className="headingNumber">1.9.5. </span><span className="head">EVENING SESSION</span></h3>
              <p>The Convention resumed its session at 4 o'clock, <span className="persName">Mr. WISE</span> being entitled to the floor.</p>
              <div className="speaker" id="sp1551"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I really was not aware when I commenced the few remarks I proposed to myself to make, how near it was to 2 o'clock, or perhaps I should not have taken the floor. It is the second time I have been cut off by "Shrewsbury clock," but not the first time by any means.</p>
              <p className="p-in-sp">
                When the Committee rose, I was addressing myself to my friend from Norfolk, who took, as I said, clear, distinct, sound ground of objection. I mean sound for him, sound for his position, logical for his position. He objected to this amendment, because secession attaches to the independence and sovereignty of the States. I called his particular attention to the results of the plan of
                objection he has taken to this amendment.
              </p>
              <div className="speaker" id="sp1552"><span className="persName">Mr. HOLLADAY</span>—</div>
              <p className="p-in-sp">
                I think the gentleman labors under a misapprehension as to what I did say. I think I declared no opinion upon the subject to which the gentleman refers, at all. I simply said, the amendment meant to assert a power, an abstract power, indirectly, which I did not deem we had been sent here to settle, and that, therefore, I should vote against the proposition. I think the gentleman
                misunderstood me, when he said that I opposed it because secession attached to the sovereignty of the States.
              </p>
              <PageNumber num={476} />
              <div className="speaker" id="sp1553"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">The gentleman said a great deal more than what he now says. I think he is mistaken in saying I misunderstood him. He certainly said that secession attached to these powers.</p>
              <div className="speaker" id="sp1554"><span className="persName">Mr. HOLLADAY</span>—</div>
              <p className="p-in-sp">I did sir; I do not deny it.</p>
              <div className="speaker" id="sp1555"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Then he did say all that I claimed he said—that secession attaches to the independence and sovereignty of States. But it is not upon that doctrine whether it does or does not attach that I wish to comment or to which my aim is to call my friend's attention. He is opposed to secession now. I do not understand him as opposed to it ever and under all circumstances. I hope I am right in
                understanding him in that, correctly. There is a time, there may be a time when he too will be, or may be compelled to resort to the remedy of secession. I presume that is his opinion, because I see him going along with the report of this committee. This Committee says that it will become necessary, under certain contingencies,—and the gentleman from Harrison,
                [<span className="persName">Mr. CARLILE</span>] , was right when he read the letter from his friend, and his friend was right in saying that the report of this committee itself, advocates secession. Now, mark you, my friend from Norfolk, means to support this report—he may be called upon to support secession. Am I wrong in that?</p>
              <p className="p-in-sp">On the sixth page of the partial report of the Committee, they lay down an American principle—and I have had to deal with American principles before to-day. What is it?</p>
              <p className="p-in-sp">
                "8. The people of Virginia recognize the American principle that government is founded in the consent of the governed, and they concede the right of the people of the several States of this Union, for just causes, to withdraw from their association under the Federal Government with the people of the other States, and to erect new governments for their better security, and they will
                never consent that the Federal power, which is in part their power, shall be exerted for the purpose of subjugating the people of such States to the Federal authority."
              </p>
              <p className="p-in-sp">
                Is not that secession, sooner or later? I will inform my friend from Norfolk that when it originally came before the Committee, it had before the word "right," the word "revolutionary." The Committee was not content with that word, and with the consent of the mover himself, it was stricken out; and the resolution now stands as asserting the great American principle. I ought to have
                called it the great Virginian principle. Why? Because Virginia was the mother of the first bill of rights of which there is any record, in the history of bills of rights. Old George Mason, of this State, was the first to assert this great principle, and to write it. All the United States of America <PageNumber num={477} /> adopted this principle, and
                the Committee have truly and correctly laid down this principle, of which Virginia was the founder and finisher.
              </p>
              <p className="p-in-sp">I say "founder," because she first declared it; "finisher," because in 1801 she sealed it by her resolutions, and by her standing army. That standing army has not yet been disbanded. It now stands, small as it is, in your arsenal on the banks of the James River, within the limits of this city; it now keeps guard over the capital of this State.</p>
              <p className="p-in-sp">
                Now, do the Committee mean to assert the State right of secession here, or do they mean to assert the mere revolutionary right of the individual people, which was the only right that George Mason declared? James Madison and Thomas Jefferson declared the right of a State to withdraw when the Federal compact should become intolerable —in 1798 and 1799—and the victory of that principle
                was consummated in 1801. But the principle declared by George Mason in 1776, was simply the revolutionary principle, the right of the individual people, or the people in their individual capacity to revolutionize their Government, to run the risk of the halter, to run the risk of the charge of treason, all the risks of civil war, all the risks of criminal prosecution, in order to
                overturn a Government which should pervert its end, aim and object, and which should destroy the happiness of the people.
              </p>
              <p className="p-in-sp">
                Now, sir, is it not a serious inquiry what this report means in this paragraph? Does it mean the mere revolutionary right, the right to seize arms? Yes, sir; that is the doctrine of my friend, the Chairman of the Committee. That is what the Committee mean—the mere revolutionary right, uncovered by the wgis and panoply of State sovereignty, of State independence, having the
                sovereignty and independent power to command citizens, willing or unwilling, to take up arms in defence of the rights and dignity of the States and their own rights of property.
              </p>
              <p className="p-in-sp">
                My friend from Norfolk will agree with the committee and will not insert the word that these States are now, and ever have been, independent and sovereign; and the majority of the Committee of twenty-one mean to assert that although under the old Confederation these States were sovereign, they ceased to be sovereign and independent when they adopted the federal constitution. Let it
                be understood then that the committee mean to imply a denial of the sovereignty, a denial of the independence of the States. Let it be understood then that the committee, and those who support their report, mean to declare by this eighth clause only the revolutionary right of the people to withdraw.
              </p>
              <div className="speaker" id="sp1556"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I will inquire of the gentleman whether he thinks there is no distinction between the position of a State which is bound <PageNumber num={478} /> only by her own consent and one bound by a majority of associated States?</p>
              <div className="speaker" id="sp1557"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I am coming to that directly. I will take you on your own report. I have not had time to write out my objections in full to this report. I must take it in limine as it comes up. I am now laying the inducing matter for the consideration of the very point which the gentleman suggests; and I do not want the point of my logic turned by anticipating its conclusions.</p>
              <p className="p-in-sp">
                The report denies the sovereignty and independence of the States at this time, and admits and means to admit only the right of revolution. Now, sir, is there any salvum in this report, to save us from this clamor about treason, if force should be employed to keep our people in the Union, when the time shall have arrived for them to declare themselves out of it? My friend from Norfolk
                and myself do not perhaps differ much about saving the Union or restoring the Union, except in point of time and the greater or less effort that may be made to accomplish the end which is as dear to me as to him.
              </p>
              <p className="p-in-sp">
                He may be more hopeful than I am. I am willing to go to what I think a reasonable length, and I will not make my opinions the standard for my friend from Norfolk. He is as much entitled to his opinions as I am to mine; and I know that he will act on the same high, patriotic, conservative, country-loving motives by which I myself profess to be actuated. If he cannot persuade me to go
                along with him a little further, in effort and time, I must stop.
              </p>
              <p className="p-in-sp">
                I may come, perhaps, to a point where I will stop short of his stopping; and he will go on and on. Loving the old Union as he does, it will not surprise me if he does go very far, and I will give him, and every other lover of the Union, a broad margin of time and effort. But this report contemplates a time at last—at last, when all reasonable efforts may have failed, and when we will
                have to count the time of the old Union as no more. What then? Are we to resort to revolution then, to the right to rise up and seize arms, without a shield over us, without a sovereignty to stand by us, without an independent power to say, "these are my citizens, and I have commanded them to obey their own sovereign"? Are we to be left only to the individual right of revolution, or
                are we not? If we are to be left to that naked right, if we are to be left bare, not only to the sword, but with necks bare to the halter, then I ask, in the name of the people, who is to protect your people and my people from the consequences of their action? What else is there but the independence and sovereignty of the State of Virginia? Nothing else under Heaven, except the red
                right arm of war.
              </p>
              <PageNumber num={479} />
              <p className="p-in-sp">Gentlemen contemplate that the time may come when they will be in the now condemned attitude of my friend from Amelia, or in my attitude; when they, too, shall be brought to the condition of secessionists. What is to shield them then, if they now ignore the independence of the State, which alone can shield them?</p>
              <p className="p-in-sp">Well, sir, they have got a panacea for that too. And now I come to the question of my friend from Richmond. The Committee itself answers that question, in the eleventh section of their report. The latter clause of that section, which was put in, I believe, on my motion, reads as follows :</p>
              <p className="p-in-sp">"And in the event that this Commonwealth fails to obtain satisfactory responses to her requests, from the non-slaveholding States, she will feel compelled to resume the powers granted by her under the Constitution of the United States, and to throw herself upon her reserved rights."</p>
              <p className="p-in-sp">
                This means something, a little more than the revolutionary right—does it not? The revolutionary right is put in the eighth clause; and if the eleventh clause means anything, if I meant anything by that amendment, it is that the people should have not only the revolutionary right, but that they should have over them, covering them like the Heavens, the shield of the sovereignty and
                independence—of what? Of the individual people? No! no! the independence of the Commonwealth—the most holy, the most ancient, the proudest, the grandest name that Virginia ever took for her sobriquet. Not "State." That is not commensurate with it. Not "community of people." That is not equal to it. A community of people may be a mere neighborhood, may be a city, may be a corporation.
                But a Commonwealth! What is a Commonwealth? In the view of any man who has read international law or political history, or a political vocabulary, does Commonwealth mean anything short of independence and sovereignty?
              </p>
              <p className="p-in-sp">Indeed, I am somewhat afraid to comment upon it in this way, for fear some of those gentlemen may strike it out.</p>
              <div className="speaker" id="sp1558"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I think the gentleman is now commenting on the section of the report which speaks of "resuming."</p>
              <div className="speaker" id="sp1559"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I have just come to that.</p>
              <div className="speaker" id="sp1560"><span className="persName">Mr. MAcFARLAND</span>—</div>
              <p className="p-in-sp">Will my friend allow me to ask him whether resumption does not imply a taking back, and whether, in the connection in which it is used, it does not imply a yielding theretofore of sovereign power?</p>
              <div className="speaker" id="sp1561"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Not at all. It does imply resumption—exactly what it expresses. But it does not imply that sovereign power ever was yielded <PageNumber num={480} /> up or in any manner surrendered. I may create my friend my agent, giving him any extent of delegated power, maintaining at the same time my own power of revocation at any time, and I have still the power to
                judge of the infraction of my rights and of the mode and measure of redress. I do not thereby deprive myself of any of my powers, and may resume them to my immediate exercise, by withdrawing them from the agent. The gentleman will not say as a lawyer, that I cannot thus take back those powers. Is the gentleman answered?
              </p>
              <p className="p-in-sp">
                And I will answer him further. I show him in the first place, ex vi termini, by the very use by the committee of the word "Commonwealth" in the eleventh section, that you mean to recognize Virginia as a Commonwealth now; and, secondly, although the gentleman has cut me short in some of my comments on the word "Commonwealth," I come to his point about "resumption," and I will ask him,
                in turn, one question. Can he conceive of a Commonwealth with the power of resuming at any moment sovereignty and independence, without attributing that Commonwealth with sovereignty and independence?
              </p>
              <p className="p-in-sp">Is it uncertain whether Virginia is sovereign or not? To prove that she is sovereign I cite to gentlemen the fact that she has power at any moment, by resuming the powers which she has granted, to make herself sovereign—and is not that, in itself, sovereignty?</p>
              <p className="p-in-sp">Why, sir, war power, peace power, land power, fisc power, are not, in the sum of them equal to this one great, God-like power—that of bringing her sovereignty, in full panoply, into being—whether resurrected or not—by the fiat of her word. And yet gentlemen are quibbling whether she is or is not, sovereign and independent now.</p>
              <p className="p-in-sp">
                If you grant me she is sovereign enough, and independent enough, whenever she dares to choose or chooses to dare, to resume all the powers that she has delegated, and to bring them back home to her own immediate exercise and unite them with the powers which she has reserved, then you admit all that I wish to demonstrate. Not absolute sovereignty, not absolute independence—for, as I
                said this morning, there is no such thing on earth as absolute independence or absolute sovereignty. In the absolute sense, England is dependent, France is dependent. The whole nation, with all the States combined, is dependent.
              </p>
              <p className="p-in-sp">
                We are very apt to tell each other that each one is a sovereign. I claim to be an independent, sovereign man in my individual capacity; and we all, on the stump, delight too much to tell our constituents that they are sovereign. Some drunk and some ragged are told that. But yet how dependent we are! For all that ministers to our pleasures,
                <PageNumber num={481} /> for all of enjoyment, how miserably dependent we are! We say we are sovereign, and yet how are we bound about and hedged around by every municipal statute and by sovereign power over municipal power, in all its varied forms of statute! So with States. Without a Confederacy, without a treaty even, States are dependent on the
                observation of international law. They are obliged to observe it. They are dependent for their existence on the countenance of other nations. They must enter into the obligations and duties of nations; for nations, as individuals, are dependent, each and all, one on the other. I therefore say, that there is no such thing hardly, as separate, much less absolute independence. The terms
                "sovereign" and "independent" are relative, not absolute. Gentlemen shall not drive me to the wall by claiming for Virginia more than I could claim for Great Britain herself—absolute independence.
              </p>
              <p className="p-in-sp">
                All I want for her, all I want for myself as a man, is merely relative independence. God save me from being made independent of the dependencies of life! I should not be happy without being dependent, partly on you, sir, to whom I am comparatively a stranger, for the pleasure of association and companionship—for the pleasure of knowing that there is such a man with me in the
                Commonwealth, and that you and I help to make up a mighty people, who combine to constitute a common Commonwealth. There is no word that imparts the dignity, the independence, and the sovereignty of a State, like that precious time-honored word, "Commonwealth." If Virginia be a Commonwealth now, if she has the power to shield her people and to say to the agent "you have maltreated my
                people, you have wronged them, and I will take their affairs into my own hands," that is all I want to make out for her. That is all the sovereignty and independence that is needed, and therefore, it is all that I will ask. Let her now, or in your own time, gentlemen, or at some time, when you are satisfied that she cannot obtain satisfactory responses to her request, say then, "I,
                the Commonwealth of Virginia resume my powers—the powers which I had delegated to an agent in trust for my people, and which have been abused. I now take them into my own hands." Let her say that, and her people are safe. Then, if any United States attorney in the North can be found to prosecute me for treason, I may put in my plea that I was commanded by my sovereign, who, when this
                federal compact was formed, reserved to herself all the sovereign powers in the very fact of her reserving to herself the right of resuming those she had delegated. Is not that so? Ought not the people of Virginia to be protected <PageNumber num={482} /> to that extent? Grant me this, and, if your people are oppressed, this protection is over them, and
                you might as well attempt to
              </p>
              <p className="p-in-sp">"Fetter the flame with flaxen band,</p>
              <p className="p-in-sp">To stop the ocean with the sand,"</p>
              <p className="p-in-sp">as to stop secession by all the powers of tyranny. I would ask my friend from Richmond [<span className="persName">Mr. MACFARLAND</span>]
                , how he would feel, if after revolution had commenced, he should be prosecuted for treason on the ground that the State was not sovereign and not independent; and if, when he should plead her sovereignty and independence, the United States attorney should cite the debates in this Hall and quote the gentleman himself for the doctrine that Virginia did give away her sovereignty? Why?
                Because if she did not give it away, she would not have to take it back.
              </p>
              <div className="speaker" id="sp1562"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">The gentleman will allow me to say that I regard the citizen as occupying a very different position, after a State has terminated her relation to the Union, from that which she occupied while she acknowledged her obligations to the Union.</p>
              <div className="speaker" id="sp1563"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Well, that is curious talking. The State is either bound or not bound. If she is bound, her failure to acknowledge the obligation will not release her from her bond.</p>
              <p className="p-in-sp">If she has been stripped of her sovereignty and is bound hand and foot, her denial of her obligations will not save her. The stronger party will enforce the obligations.</p>
              <p className="p-in-sp">
                We have had this doctrine of coercion preached last fall throughout the State. I have had to answer it before the people. I am glad that my friend does admit, that though there is now an obligation, one of the bound parties may for itself decide when the obligation ceases, and when it does not. That's a curious confusion of doctrine. Let the gentleman beware of his words now, for the
                time of trial may come.
              </p>
              <p className="p-in-sp">
                I feel too deeply, I feel too hotly, that I am now over the almost belching crater of the volcano of revolution. I would implore you to put me on safer ground than mere revolutionary ground. I would implore you to put me on States rights ground and under the cover of independent sovereignty; but I say to you that if you forsake the people, the people will not forsake themselves. If
                you will not cover them with the shield of the Commonwealth, they will go into battle under their own shield, and they will trust to God, as their fathers did, for their protection.
              </p>
              <div className="speaker" id="sp1564"><span className="persName">Mr. HOLLADAY</span>—</div>
              <p className="p-in-sp">The gentleman seems to draw a distinction between the sovereignty of the people and the sovereignty of a Commonwealth, <PageNumber num={483} /> and talks of the State throwing up her shield as a protection to the people. I do not comprehend exactly the idea, and I should be glad if the gentleman would explain it.</p>
              <div className="speaker" id="sp1565"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                It is very easily explained, Sir. I make no distinction between the sovereignty of the State and the sovereignty of the people. I spoke of the difference between the sovereignty of the State and that sovereignty of the individual to which the bill of rights applies—as between Virginia and her own people, for example. The gentleman understands that. The people of Virginia are the only
                sovereigns.
              </p>
              <div className="speaker" id="sp1566"><span className="persName">Mr. HOLLADAY</span>—</div>
              <p className="p-in-sp">Well, what shield does the Commonwealth hold up over the people of Virginia?</p>
              <div className="speaker" id="sp1567"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Sir, the sovereign people of Virginia have made what they call a body politic, a Commonwealth or State, which has certain political attributes known to the laws of nations, and which the individual people have not. You and I as men, have no corporate, no body-politic, powers; but an organized State has. A State is party to the Federal compact, but the people in their individual
                capacity are not. The State must judge for me of the infraction of its rights and of the mode and measure of redress; but all the people in their primary assemblages, without authority of law, cannot judge for themselves. Is not that so? Is not my friend from Norfolk
                [<span className="persName">Mr. HOLLADAY</span>]
                , one of that school of politicians that will agree to my doctrine, that the people cannot, by mere voluntary convention, upset the government—that they must have the authority of the law? That has been tried. Dorr tried that doctrine—holding that the people could upset even the charter of Charles II. So that you perceive there is a distinction even between the sovereign people and
                the sovereignty, constituted by the people and called a State; and though the people of Rhode Island should, by two to one, vote up free republican institutions over the charter of Charles II, yet they could not have them. Why? Because the State sovereignty, the State authority was invaded by mere popular meetings. Am I not right? Am I not on conservative ground now?
              </p>
              <p className="p-in-sp">
                I tell you, gentlemen, that if you throw the people on their revolutionary rights, you may have the war and the revolution much sooner than you expect. But if you tell the people to be patient, to wait; that there is a parent and protecting power over them—the power of a united, organized, independent sovereignty which presides over them, you may prevent war. Put your shield over
                them; guard them ; guide them. Throw away that shield, renounce that sovereignty, renounce that authority, leave them to themselves, and you may see a rush for revolution. As far as I am concerned, I mean to wait a reasonable time, <PageNumber num={484} /> and long. I mean to bring down pretentions and claims of rights as far as safety and honor permit
                me. I mean to go along with you, according to the Constitution, according to laws; according to State and Federal statutes, to guide, guard and regulate us all in our deliberations, and in our final action. But after a reasonable time shall have passed, or when our demands shall have been brought down so low that I could not even prefer them, with self-respect, then, if no other man
                in the Commonwealth shall raise the flag of revolution, I will raise it as high aloft as I can make it flaunt in Heaven's air. "If that be treason, make the most of it."
              </p>
              <div className="speaker" id="sp1568"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">
                I hope, Mr. Chairman, that I may be pardoned for claiming the attention of the Committee for a few moments, since the gentleman who has just taken his seat made my remarks of this morning the subject of some strictures. Now I beg it to be understood that on the question before us, I have no reference to ulterior questions. The gentleman and myself would probably not differ in the
                application of our general doctrine. But we are concerned just now with a simple, elementary proposition ; and as I know the gentleman
                [Mr. WisE]
                , does not desire to gain an advantage to which his logic does not entitle him, I request his attention for a moment, and I especially desire to know from him whether, while Virginia acknowledges her obligations to the Union, she can be out of it? whether, while she is in it, the powers and authority delegated to the Union are hers in fact. That is the whole question between us.
              </p>
              <div className="speaker" id="sp1569"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">There are two questions. I will answer, one one way and the other the other way.</p>
              <div className="speaker" id="sp1570"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">You can answer me after I shall have done. I take it, Mr. Chairman, that if there be anything which is a self-evident indisputable truism, it is this, that in a Confederation which deprives the States of many essential and important attributes, they are not sovereign. So the question simply is, whether certain attributes are not essential to sovereignty?</p>
              <p className="p-in-sp">
                That is the question, and the ingenuity of the gentleman will find it impossible to escape it. If, while we are within the Union, there be an obligation to respect those concessions of power which the State made to the Union, then the powers delegated were not retained. So that, whatever may be the right of the States to resume their powers, and assert their independence, such
                resumption must precede their restoration to sovereignty.
              </p>
              <p className="p-in-sp">
                Is there any doubt about that? Virginia conceded to the general government the right to impose duties on foreign commerce. Can we, <PageNumber num={485} /> so long as we remain in the Union, claim the power of imposing such duties? The power over our navigable rivers was yielded. Can we claim to dispute its exercise? An appeal from the decisions of our
                own courts, in certain cases, lies to the Supreme Court. Can we deny the appellate jurisdiction? Coinage alone is the privilege of the Federal Government, and the States cannot issue bills of credit.
              </p>
              <p className="p-in-sp">
                But, Mr. Chairman, after all, the inquiry runs into a mere criticism upon words. Are we, by the Constitution as it now exists, under obligations to the Federal Government? Does any one deny that we are? Does any one deny that we have delegated to the General Government the control of our external commerce, the treaty-making power, the enforcement of national laws within our own
                limits, the cognizance of a vast variety of subjects which concern our wealth and power?
              </p>
              <p className="p-in-sp">
                If any one denied this he would be answered by producing the Constitution, with its limitations on State authority. If, then, Virginia has, by her solemn agreement deprived herself of powers essential, indispensable to a separate national existence, it is a contradiction of terms to call her sovereign. What does the gentleman mean by sovereignty? Sovereignty may be a very indefinite
                term. All sovereigns are peers. Can that be a sovereignty which is under compulsion and restraint? Is that a sovereignty the line of whose conduct may be prescribed, and which is under the necessity of yielding to alien authority in many essential particulars? Every one must perceive that according to familiar and ordinary speech, he is no sovereign who must depend for his course,
                not on the sanctions of his own judgment, but on the will of other and different powers.
              </p>
              <p className="p-in-sp">
                Now, what is the relation which Virginia and the other States occupy to the Union, in regard to the question of peace or war? A controversy may arise between America and England or France. The sentiment of Virginia may be that there has been no provocation, and that it is an uncalled for controversy—that in point of fact, the States have no just and fair ground of quarrel with
                England or France, and yet, in despite of that solemn conviction, Virginia, if she acknowledged her obligations to the Union, would find herself committed to a war which she regarded as without sufficient motive, and as not being justified by prudence or discretion. Is she then sovereign when committed to a war which she condemns as unnecessary and unprovoked, and to which,
                nevertheless, she is forced to furnish her full contingent of men and money? If that be sovereignty, let the gentleman reconcile it with the common sense of the country.
              </p>
              <div className="speaker" id="sp1571"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Will the gentleman allow me to answer him?</p>
              <PageNumber num={486} />
              <div className="speaker" id="sp1572"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">Not till I shall have got through.</p>
              <p className="p-in-sp">
                We know that the Congress of the United States claims and exercises sovereign powers within the boundaries of the States. We are parties to this Union. Prosecutions grow out of these acts of Congress against our own people. Can Virginia, so long as she remains in the Union, defend her citizens against these prosecutions? Can she interpose any plea of sovereignty in their vindication
                or defence? Why, sir, the gentleman will not pretend that it is so. In all such cases Virginia is reduced to the necessity of disclaiming her connection with the Federal Government, or of submitting to the execution of the laws, as they may be interpreted by the Federal Judiciary. Is that denied? Will any gentleman upon this floor deny it? Will my friend from Princess Anne
                [<span className="persName">Mr. WISE</span>]
                , deny it? Without fatiguing this committee by going through the details, it is enough to say that the States, so long as they retain their position, are subject to the Federal Government, to the full extent of its constitutional authority. It may well happen, and it has happened, that laws of Congress have been in violation of the Constitution, and against such the only redress is
                through the Federal Judiciary. Would my friend say whilst Virginia remained in the Union, that he would muster a force to resist the execution of the federal laws? Is there one so insurgent, so turbulent here or elsewhere, that, whilst adhering to the Federal Government, he would be found in armed opposition to its laws ?
              </p>
              <p className="p-in-sp">
                In all such cases we must of necessity be reduced to the alternative of deciding between continuing in the Union and departing from it. I admit that any and all of the States have the privilege of retiring from the Union and thereby to escape its laws, when they find their grievances more intolerable than separation. That I concede in its fullest extent. But I am now engaged in
                insisting upon the proposition that the authority of the Government and its benefits cannot be claimed and acknowledged with the privilege at the same time of deciding what laws we shall obey. Is that the gentleman's opinion? Or does he mean to say—is there any member upon this floor who will say—that whilst claiming the benefits of the Union, whilst professing loyalty to it, we may
                determine which of its laws we will obey; and unless the gentleman will go to that extent he will find himself reduced to the alternative of disclaiming the jurisdiction of the Federal Government or of submitting to its requirements.
              </p>
              <p className="p-in-sp">
                A word or two more. All sovereigns are peers, and no State or empire can with any propriety be said to be sovereign which has not the privilege, without the sanction of any outside authority, of deciding <PageNumber num={487} /> upon what is due to its own dignity and rights. I put it to the gentleman, and I beg him to meet it. Can he conceive of a
                sovereignty, which, besides the sanction of its own wisdom, has to wait for some outside authority, to pursue the course recommended by its own judgment? Conceive a people in that condition. Are they not dependent, necessarily dependent? And is a dependent people, in respect to matters of great national concern, sovereign? Will the gentleman say that? Is the word sovereign alike
                fitted to describe an independent and a dependent power? Will he say that? Will he say that the term "sovereign" is equally appropriate and descriptive of a condition of a puny power, who seeks impunity by submission, and a power reposing upon the strength of its own arms and challenging contradiction? Will the gentleman say that? Be it remarked that this word "sovereign," has always
                imported indefinite comprehension and force. There is no term descriptive of power or of independence beyond it. Our Maker is sovereign in the universe, and no power upon the earth is sovereign from whose awards there lies an appeal.
              </p>
              <p className="p-in-sp">I confess, that with all the gentlemen of this Committee I admire the dexterity and fertility of my friend from Princess Anne [<span className="persName">Mr. WISE</span>]
                . He can tax his ingenuity as greatly and make as clever an escape from a difficulty as any gentleman in this hall—and if he will pardon me for saying so—I will intimate that he finds some pleasure and pride in it; but let me remark, Mr. Chairman, that it hardly contributes to just conclusions. If the gentleman means to assert that Virginia has the right for just cause to separate
                her connections with the Federal Government and to claim her original powers, he will find, I apprehend, an assent on the part of all the members of this Convention. But if he means to say that in the face of the concessions of power which have been made by the States to the General Government, by which the State is bound, so long as she is a party to it, that she still retained her
                sovereign powers, I apprehend that this Committee will, in response, say that he has drawn upon their credulity for something which all their respect for him can hardly accord. Why, sir, does the gentleman really believe, that at the foundation of our government, when her federal institutions were established, that their purpose was to constitute not only one but to provide for many
                sovereignties? Why I dare say that there are parts of the world in which the existence of Rhode Island is scarcely known. Did my friend ever hear that Rhode Island was pronounced a sovereign, denuded, as she is, of the privilege of making herself known abroad by receiving or accrediting ambassadors?
              </p>
              <p className="p-in-sp">
                I am afraid that I am protracting these remarks to a most unreasonable <PageNumber num={488} /> length; but I think it important to escape both an error in theory, and a mal application of terms. If gentlemen mean to say that Virginia has a quasi independence or an absolute independence, as she may choose to assert her reserved rights—agreed. But I
                protest that a State is not sovereign which has, by convention, surrendered the most significant attributes of an independent empire, so long as she is content to retain her disfranchised position.
              </p>
              <div className="speaker" id="sp1573"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Did you, Mr. Chairman, ever, in your life, hear such a catalogue of questions as that just iterated to this Committee? Why, my friend has not added one single question to those of this morning. Does he want us to be like the dogs that play in the yard, running around in a circle, chasing our own tails? I cannot reason in that way. I cannot reason in a circle. I must reason in a
                direct line, to a point.
              </p>
              <p className="p-in-sp">
                Now my friend has given me a homily. I am past majority. I have lived more than half a century in this world; and he tells me that I am very ingenious, very dexterous in getting over difficulties, and that I like to show ingenuity, perhaps, at the expense of ingenuousness in doing so. I can say to my friend that I do not take that as a compliment, I might, in turn, say to him, that
                Beau Brummell, in all his impudence before King George, never did exceed the bravery with which the old federalism pouts out of my friend from Richmond.
                [Laughter.]
                Why, sir, what do you suppose? He stands up before this assembly, and affects to treat the ideas and arguments which I had used to-day as a novelty, as something new. Why, sir, I cannot claim originality in a single thought I have uttered to-day. I am but repeating old truths, which for more than forty years have been repeated in the Senate of the United States by the prototype of
                that image
                [pointing to Calhoun's bust] , and he did not repeat them half as well as the Virginia fathers—the father of the Federal Constitution, and the father of the Declaration of Independence—repeated them to Virginia ever since 1776.</p>
              <p className="p-in-sp">
                What is the first question he asks you? If a State is in the Union can she be out of the Union? Is that question worth answering? It answers itself. He then asks me the question as if it was the same—can a State that has divested herself of sovereign power still claim to be sovereign? Well, sir, I can answer that question very easily. If she has divested herself and can divest
                herself of her sovereignty, then she is not sovereign. There is one thing that can divest her—and that is subjugation by war or otherwise; or it may be that she may merge herself in another people and not enjoy her separate sovereignty; but, <PageNumber num={489} /> without going into the question of potentiality, I come to the question of fact. In
                creating this Federal Government, in forming it as it is written before us in the Constitution which they did create—did they divest themselves of that sovereignty? Did Virginia give away her war making power? Did Virginia give away her commercial power? Did Virginia give away her power of eminent domain? She did not.
              </p>
              <p className="p-in-sp">
                She granted (she was one of the grantors), she delegated (she was one of the delegating powers), certain of her own powers to a mere agent of the States, to be exercised for her in trust, the powers still remaining her own. The agent is merely their administrator. I see no difficulty to get over there. The gentleman has not even placed a mole-hill before me, much less a mountain, to
                get around. Any man who has a hollow to his foot, could stand on this obstacle without touching its apex with the sole of his foot.
              </p>
              <p className="p-in-sp">
                The gentleman first assumes that the State has divested itself of all powers of war, of peace, of commerce, of land and of everything else, and then argues from this arbitrary premise that the State is not sovereign. This is an obvious petitio principii. Again, I say to the gentleman, that his definition of the word sovereign is not correct. It is not true in political law that
                nothing can be above a sovereignty; a sovereignty recognized de facto and de jure. All Europe knows the fact. The Papal States have been sovereign as long as the Pope has sat upon the throne. How many years was it during which the Papal States did not elect their sovereign themselves—had nothing to do with it—that the Emperor of Germany dictated who should be Pope? Yet were not the
                Papal States sovereign? How many grades of sovereignty are there in Europe? Are there not as many as five different gradations? Do you tell me that the King of Holland is sovereign as Louis Napoleon? Do you tell me that the Duke of Hesse Cassel is sovereign as the King of Holland?
              </p>
              <div className="speaker" id="sp1574"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">Will the gentleman tell me what connection there is between the sovereignty of those States—</p>
              <div className="speaker" id="sp1575"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I am talking not by a schoolboy's dictionary, but, as I ought to talk, by the vocabulary of political law. I am talking now by the laws of diplomacy, by the law of nations. Is not Turkey this moment sovereign? Who will deny that Turkey is sovereign? And yet is she not helpless, hopeless Turkey?</p>
              <p className="p-in-sp">Yes, sir, as weak as that bird among the fowls we call turkey [laughter] , and yet she is sovereign.</p>
              <p className="p-in-sp">
                Are there not States of Europe that cannot make war without the consent of other powers, and this under express treaty stipulations? Is <PageNumber num={490} /> not that the case with many of the German States? And yet, are they not recognized as sovereign, and treated as sovereign by all the powers of Europe? You have there Imperial sovereignty, and
                Royal sovereignty, and Ducal sovereignty—and I don't know how many other sovereignties—all more or less dependent, not by divesting themselves of power, but by simply delegating powers, and consenting to unite with powers, by compact, or agreement, or constitution, or convention—for there is almost every variety of name by which sovereign powers will consent to limit, not the power,
                not its own original character, but the mere exercise of the power. That is all that these States have done.
              </p>
              <p className="p-in-sp">
                Now, I want to ask a question; but before I do that I have a word to say which has just occurred to my mind. The gentleman asks me: if the Federal Government shall choose to go to war, however obnoxious it may be to Virginia, yet she is compelled to march to the field, whether she is sovereign or not? Why did the gentleman talk about Virginia? Why did he not refer to the instance
                which occurred in the State where his own school of politics claims its origin; in the State which is the mother of the elder Adams; that State that burned blue lights for the enemy, and that tried to overthrow Mr. Madison's administration because the General Government declared the war? She was made to fight because, by her own sovereign act, she had agreed not to exercise the power
                by herself, not to give up the power, but to exercise it in common with the other States. And the war was made for her defence, for the defence of free trade and sailors' rights, by the authority of her sovereign consent, and only against the consent of a cursed Hartford Convention, she was made to fight in that war.
              </p>
              <p className="p-in-sp">
                Now, if gentlemen affect to treat this subject as a novelty, and to compliment me over the left by saying that I am resorting to dexterity to escape difficulties, let me answer by these old historic musty facts. If he wants an answer whether a State in the Union on the other hand can remain in the Union and resist the powers of the Federal Government, and even raise an army to do it
                in the face of the Constitution itself that forbids her to keep troops in time of peace, let him go back to the time of the Alien and Sedition Laws; let him turn to those resolutions of Virginia that were read to-day; let him go to the Acts of our Legislature which created a standing army of the State Guard, organized to be the nucleus of an army intended to meet the forces of the
                Federal Government if necessary. Yet he affects that these things are novelties, and says that I am losing character by practicing upon the credulity of gentlemen. Now, after these two instances—Massachusetts resisting a war and Virginia raising an army <PageNumber num={491} /> without going out of the Union—I come to ask him a question. When the
                Constitution was formed there were thirteen States, each one of which was sovereign—so George III said, at all events. He acknowledged each and all of them as free, sovereign and independent States. Each of the thirteen had the war power, the commerce power, the land power— (oh, what a domain of land Virginia would have now, if she had only kept what was her own—if she had kept it, I
                mean, in her own hands and entrusted nobody with its administration!) —and each had all the other powers—that grand power of sending diplomats and of receiving foreign ambassadors included. Each and every one of those thirteen States had those powers. The gentleman says that they divested themselves of those powers. I say they did not divest themselves of those powers. They merely
                granted them in trust. They merely delegated them to be used not by another, but for the several States, as still sovereign, as still proprietors, as still sovereign proprietors of the power. But if they divest themselves of those sovereign powers, where did they go to? Will the gentleman answer me now, where did they go to?
              </p>
              <div className="speaker" id="sp1576"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">My friend will allow me to say, in answering his enquiry, that I suppose the subject of gifts obeys the intent of the giver. If they were delegated to the general government, of course the general government received them, and is entitled to exercise them until they are recalled.</p>
              <div className="speaker" id="sp1577"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Is the general government a sovereign government?</p>
              <div className="speaker" id="sp1578"><span className="persName">Mr. MAcFARLAND</span>—</div>
              <p className="p-in-sp">Agency, or whatever else you may call it; I speak now of receiving the delegation of the powers. You imply by your question that certain powers were given; I say they follow the grant. If they were given to a proper agent, that agent received them.</p>
              <div className="speaker" id="sp1579"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I ask the gentleman if they went to the Federal Government, did they constitute the Federal Government a sovereign Government?</p>
              <div className="speaker" id="sp1580"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">No, sir, by no means.</p>
              <div className="speaker" id="sp1581"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Why not?</p>
              <div className="speaker" id="sp1582"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">The gentleman must allow me to give the whole force of the answer. The gentleman will please mark that a gift may impoverish the donor without enriching the donee.</p>
              <div className="speaker" id="sp1583"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                And let me say to the gentleman that he might as well have told me that the moon was made of green cheese, for it has as much to do with the question. Sir, the donor in this case was no donor at all. There was no gift. There was a grant, I repeat, in trust, that deprived the grantor of nothing, and that did much benefit to the
                <PageNumber num={492} /> grantee, the delegate—if I may make a word—the Federal Government. The Government now has the power to declare war; it has the power to make peace; it has the power to regulate commerce; it has the power to regulate the disposition of the public domain; and yet, with all these powers, the gentleman tells us, that Government is
                not sovereign. But he didn't tell us why. Why, sir, with all these cardinal points, delegated to that Government, was it not made sovereign? Why? Because it was of a mere derivative character. It was not an original Government, and could not be made sovereign. It was not sovereign, was not intended to be sovereign, it was a mere derivative power, it was a Great Municipal Government.
                And you may invest it with all the power of making war, with all the power of making peace, with all the power of concluding treaties, with all the power or regulating commerce, with all the power of disposing of the public domain—five or six cardinal powers of sovereignty—and yet you cannot make it sovereign. Why? Because the powers which it wields belong to others. They belong to
                the several States.
              </p>
              <p className="p-in-sp">
                Now, does the gentleman tell me that this is false logic? Does the gentleman tell me that this is mere ingenuity? I ask is it not fair logic? Is it not a fair answer? Go to my agent and ask him, "Have you power to sell <span className="persName">Mr. WISE</span>'S live stock?" "Yes, sir, I have." "Have you power to sell this piece of land?" "Yes, sir, I have." "Have you power to sell
                that?" "No, sir, I cannot; I must go to my principal." "Well, sir, I will make a bargain for that which you have a right to sell; but there are terms, I find not within your power; I ask you whether you can undertake to assume the responsibility in his absence, to sell to me on terms that I would like—to add to your power?" He says, "No, sir, I cannot do that; I must go to my
                principal."
              </p>
              <p className="p-in-sp">
                Now, why is not he sovereign? Why can't he sell all the land? Because it is not in the power of attorney. Why can't he sell the land or stock that is in the power of attorney on other terms? It is because he is merely an agent. The powers are not his. They are mine. I have never divested myself of them, and if he exercises power he exercises my power, not his power.
              </p>
              <p className="p-in-sp">
                Is the gentleman answered? Is there any escape? Is there any false logic? Is there anything new in this? Is it not affectation to pretend to ask these questions and then to try to startle this House with the idea that I am trying to practice upon their credulity? Sir, I do not like that imputation. I have an honest earnestness in argument, and may go beyond the bounds sometimes, in
                pressing my argument. It is not because I am playing trickster or practising upon anybody's <PageNumber num={493} /> credulity; but it is because I am earnestly driving full tilt after the truth. I am like the swift greyhound in the chase, which sometimes runs beyond the game; but I am sure soon to check my speed and turn again in the pursuit as soon as
                possible.
                [Laughter.]
                If the gentleman's theory be correct, we have divested ourselves of the cardinal point of sovereignty. It has gone like soap bubbles somewhere into the thin air—it is now nowhere. This is the old Federal doctrine. This is the bluest of it. When a gentleman representing a States rights State, having the care of States rights, openly takes this doctrine and even scolds me for not
                taking it, I think it is pretty high time that we recur to fundamental principles; we had better go back to the old doctrines; we had better revive old Virginia as she once was. Once the plantation song of old Virginia was "Old Virginia never tire." It seems now that some gentlemen are tiring of old Virginia and want a new Virginia. Sir, I have had an antipathy to a black cravat all
                my life. They were never invented, except to hide dirt.
                [Laughter.] I believe, now that I think of it, I will go back to what I wore thirty years ago, I will take a white cravat, and "chaw" tobacco, in order to show one man, at least, who is willing to revive old Virginia. [Laughter.]
                The question has been asked among other things—the gentleman with much self gratulation asks, whether I would not admit that sovereigns were peers? No, sir, I have only to turn to the horn books of history to show that sovereigns themselves are very unequal. I say to the gentleman, in answer to his question, no, sovereigns are not peers. You may go to France, you may go to England,
                you may go to Belgium, you may go to Constantinople, you may go to St. Petersburg, you may go to Rome, you may go to Naples, you may go to Sardinia, and you will find that princes are not peers. Sir, the Emperor Napoleon of France looks down with as much contempt upon some of the sovereigns of Europe in point of grade as my Lord looks down upon his lackey. And there is no less
                variation of grade among sovereigns in their status of kingly or imperial power than there is among sovereignties of States in the grade of their relative rank. Do not understand me, however, as recognizing here any of these gradations, either among officers or ministers of powers of State or among the powers of the States themselves. I claim here that Virginia is now as sovereign
                not only as she ever was, but that the Constitution was intended to increase and strengthen that sovereignty; that, therefore, she has more sovereignty, inasmuch as she is stronger than she was when the Constitution was made, and that she is now as sovereign as it is <PageNumber num={494} /> possible for any State on earth to be. More than that—I claim
                that as little as Rhode Island is, as much contempt as the gentleman sneers out upon that little State, under the theory of that Constitution you profess to stand by, she is as sovereign as the mother of States, as sovereign as Virginia, as sovereign as New York is, which State is now called in these times the Empire State.
              </p>
              <div className="speaker" id="sp1584"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">If my friend will suffer me for a moment, I wish to say that I referred to Rhode Island with some little satisfaction. She has acquitted herself, recently, well, but she is so geographically small, and so little known in the intercourse of the world, that I hardly supposed she would be called sovereign.</p>
              <div className="speaker" id="sp1585"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Aye, sir, the gentleman alluded to Rhode Island very kindly, and I know he always means kindness to me, as I do to him; but he alluded to Rhode Island as he did to me. He paid her some compliments "over the left." [Laughter.]
                I say Rhode Island is as sovereign as Virginia or New York, and I say it upon the authority of the Constitution of the United States, which authorises her to have two Senators in the Senate of the United States, when New York has but two; which authorises Delaware to have two, for two from Virginia. Put New York and Virginia side by side with Delaware and Rhode Island, and tell me,
                after you have compared their dimensions and their powers, tell me, gentlemen, if you will, why it was they were made equal in the Senate? It was because the Constitution of the United States recognized all four, each and all, as sovereigns, and therefore made them equals.
              </p>
              <p className="p-in-sp">
                But my friend from Richmond has taken another position. He asks what is sovereign power, and then says it is the highest power of government. If that be true the Federal Government is sovereign, which he says is not sovereign, for it has the highest powers of government. What is the highest power of government under our system? Is it any power that the Federal Government has got? Is
                it the power of war; is it the power of peace; is it the power over the public domain; is it the power of commerce; is it the power of treaty; is it the power of diplomacy? No, sir. There is a power greater than all these. The gentleman said, and he is a good Christian, whom I honor and respect and who I know would not allude to the Deity irreverently—he said that God was the
                sovereign of the universe. I might have said in reply, that the great enemy of God and God's people is sovereign in the infernal regions—yet the devil is dependent after all.
                [Laughter.]
                I do not mean irreverence. But I know he is a very potent being with far the greater portion of mankind, and I am very sorry that I am yet <PageNumber num={495} /> under his dominion—yet he is dependent. But what D. D. will not tell us how sovereign he is over much of this world, and perhaps, a large part of the universe. Yet there is a higher sovereign
                in the universe. God can command him, and I hope he will command him, in my heart and in yours. I give you that instance, but I refer to it only because He is the Author and Creator. He can create and he destroy. But what power is there now in the United States that is God like—that can, like God, create and can destroy? Sir, it is the Constitution-making power. The States, each for
                themselves, created the Federal Government; and by your Constitution the States, and the States only, can destroy it. There is the test of power; there is the power that is the most sovereign of all sovereign powers. Is that false logic? Is that ingenuity that imposes upon credulity? Is that an enormity? Sir, I came here a beardless boy in 1833. You
                [<span className="persName">Mr. BRUCE</span>]
                did me the honor to allude to it the other day; and, by the bye, now is as good a time as any to settle accounts with my friend from Halifax. It just comes into my head. I came with a long address in manuscript, which it had given me the rheumatism in my right arm to write, upon a cold mahogany table. So absorbed had I been in my labor, that I had suffered the fire to go out, and
                myself to be chilled with cold. I came here with that sophomore address, and had it printed for a district which was not yet formed. When I had got to this city I met my friend from Frederick, not the Chairman of the Committee of Twenty-one, but my venerable friend from Frederick
                [<span className="persName">Mr. MARS HALL</span>]
                —I roomed with him—I had been somewhat under his tuition when a boy at the Winchester law school—I had been often taken by my venerable preceptor, the late lamented Henry St. George Tucker—to hear the arguments of that eminent lawyer upon the great and high questions then distracting the public mind—I came to this city with that first address acknowledging the doctrines of States
                rights; acknowledging that great truth put forth by Madison, but maintaining the doctrine of the Union against the doctrine of nullification of that day, but still opposed to the Force bill, and denouncing it with as much earnestness as my venerable friend, the Ex-President of the United States
                [<span className="persName">Mr. TYLER</span>]
                , who to his immortal honor, be it said, was the only Senator in his place voting against it. If his child will not put it upon his tomb I will put it there, and if I cannot pay the graver, I will scratch it there with a point of steel that shall be touched with blood. I was ready to baptize my faith in blood against that Force bill, though a Union man at that time. I came here to
                find Richmond at that moment, in January 1833, in a state of excitement such as I have never seen. There was Jones, of <PageNumber num={496} /> Elizabeth City, there was the lamented Gilmer, there was Bruce of Halifax—all Richmond was alive with excitement. Men deliberated with arms at their sides. Men debated with their hands upon their hilts;
                gentlemen were even walking armed in this city. When I arrived here the debate was waxing hot and strong. On the other side, there was MOORE, of Rockbridge, and Shell, of the south side—men standing up for the Union—there they stood, eye to eye, toe to toe in the conflict of debate. No man knew at that day at what moment a bloody conflict would be precipitated. I went to the printer,
                and after seeing him I found my friend from Frederick, and we roomed together. With his keen, scrutinizing and sifting logic, he searched me as a well stored, astute, experienced man would search and sift a school boy. He did it, and did it well, and when he was done with me, sir, I ached all over. He made me ashamed of my address. I was almost ready to go and take it out of the
                hands of the printer, but it was too far gone. Of that address I believe my friend from Northampton
                [<span className="persName">Mr. FISHER</span>] , has the only copy that is left.</p>
              <div className="speaker" id="sp1586"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I have one.</p>
              <div className="speaker" id="sp1587"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Have you? Well, sir, I hope you will keep it. [Laughter.]
                I am a little better prepared for my venerable friend now than I was then. I have gone through twenty-seven years exercises, but none more valuable than the exercises he gave me on that occasion. Then, for the first time, I met my friend from Halifax—for he said the other day that he desired to call me his friend, and I can tell him he did not desire it more than I do to reciprocate
                the friendship.
              </p>
              <p className="p-in-sp">
                He did not wear grey or pepper-and-salt then. He was strong, young, vigorous. Does it not make you sad sometimes, when you think what you were then? How hopeful, how jubilant we were, both of us then! I had not seen the gentleman from that day—now twenty-seven years ago—till I met him the other day, and was introduced to him by my other friend from Halifax—I mean my old opponent of
                1855
                [<span className="persName">Mr. FLOURNOY</span>]
                . I very brusquely said to the gentleman from Halifax—pulling down my spectacles—"I believe I have seen you before. I need no introduction to you. When I came here, twenty-seven years ago, you were a nullifier; I hope you are not a submissionist now." Said he, "No, sir, I am where I stood then." Down he sat. The gentleman says he has been buried for 25 years, and that he is dug up a
                fossil. That is his language, not mine. It was impossible to bury him. He is too good and useful a citizen. Certain it is, that if he was buried, they taught him the Bible well in the other world. The angels had something to do with him. But, sir, he was not gone—he was <PageNumber num={497} /> not buried. The only reason I had to suppose that my friend
                had perhaps forsaken his old States-right idea of 1833, was that, if he were gone, his ghost some years ago, was wandering about Richmond here and was found in company with Henry Winter Davis, and somebody else just as bad.
                [Laughter.]
                But such a man is always bound to be incorruptible. The grave itself could not corrupt him. His faith is fixed and firm, and, in the fullness of fidelity, he has maintained the faith. I grasp his hand through life. I was a Union man then, I am a Union man now, precisely in the same sense. I was a State rights man then, I am a State rights man now, in the same sense. I did not then
                think that a tariff bill was an excuse for dissolution or nullification. But as soon as we are satisfied, that if we cannot cure the disease I am for excision. I am driven to it against my will, I am obliged to take this ground. I have waited and will wait patiently and try and get what is reasonable. If that be rejected I will resume the powers granted, and, if not permitted to do
                that, I will seize the arms at hand, and if not covered with the aegis of the State, I will assume my own inalienable rights of self-preservation, and, live or die, sink or swim, I will fight for them to the death. You created this government—you, and you alone, can destroy it. This is your sovereign power, which you have never delegated. I ask you now to exercise it for yourselves,
                because your agent is faithless. The Government into whose hands you confided your powers, has already proved itself unworthy of the trust. If that were all, there would be no necessity for dissolving the Government; but that is not all. Your partners to the compact have themselves become faithless, untrustworthy, and worse than the agent. Let not the gentleman from Harrison iterate
                to me that the agent—the General Government—has not wronged us. Let him not reiterate to me that the agent is not the power which deprives me of my rights of property in slaves—that the agent has never wronged me.
              </p>
              <p className="p-in-sp">
                Grant it, for the argument's sake. Do not the partners wrong us? Do not the co-States wrong us? Suppose that I am an agent, and that I have the disbursement, for example, of the partnership funds to each. I deal out to three of the gentlemen in a partnership of four, their dividends, and I give to the other fourth none, telling him that all has been paid over to the other three. "How
                comes that?" "Why, the firm have so ordered, and it is not my fault, I cannot give you your share even pro tanto. These gentlemen, your co-partners, compel me to do what I am doing. They direct what sums are to be paid over to A., B. and C., and there is nothing left for you."
              </p>
              <p className="p-in-sp">
                Suppose he then says to me, "Sir, I cannot blame you. Whom am <PageNumber num={498} /> I to blame?" and I tell him, "Look to your partners." Now, even in that view of the case, because the agent is not to blame, has not the injured party a right to look to the co-partners? Has he not a right to say to them, "If you are robbing me in this way, I must
                break up the partnership"? And is not that the case here? Is it not worse than that? Remember who the agent is. The agent is constituted of the bone of their bone, and of the flesh of their flesh of the co-partners themselves. Your partners make the President. They make the majority in Congress, in both Houses; and very shortly they will make the tribunal of arbitrament and award.
                The agent then is part of the principal. The agent in this case is to blame, because consentingly he stands and sees Stephen stoned without raising a hand except to help to stone him.
              </p>
              <p className="p-in-sp">There is our position. These are the doctrines, this is the faith, this is the practice, this is the cause. And yet my friend gets up in the morning and asks a round of questions, hears the replies, comes back after dinner, and in the same form exactly asks again precisely the same questions, to be answered in the same way.</p>
              <p className="p-in-sp">Sir, I did not intend to speak fifteen minutes this morning, but my friend has given me a text out of which he has got a pretty long speech—and I expect it is the last I will make for some time, on account of my lungs.</p>
              <div className="speaker" id="sp1588"><span className="persName">Mr. MOORE</span>—</div>
              <p className="p-in-sp">Mr. Chairman, there is a rule on the journal which says that no member Shall speak more than twice on the same question, without leave, nor more than once till every other member, intending to speak, has spoken. I desire to know if that rule applies in Committee of the Whole?</p>
              <div className="speaker" id="sp1589"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">I apprehend it does not.</p>
              <p><span className="persName">Mr. W. C. SCOTT</span> obtained the floor.</p>
              <div className="speaker" id="sp1590"><span className="persName">Mr. SEAWELL</span>—</div>
              <p className="p-in-sp">Will the gentleman allow me? I desire to offer an amendment to the amendment of the gentleman from Middlesex, [<span className="persName">Mr. MONTAGUE</span>] .</p>
              <div className="speaker" id="sp1591"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman will state his amendment.</p>
              <div className="speaker" id="sp1592"><span className="persName">Mr. SEAWELL</span>—</div>
              <p className="p-in-sp">The first resolution now reads:</p>
              <p className="p-in-sp">
                "That the States which composed the United States of America, when the Federal Constitution was formed, were independent sovereignties." The gentleman from Middlesex, moved to insert the words, "and still are." I propose to amend it so that it will read, "That the States which composed the United States of America, when the Federal Constitution was formed, were independent
                sovereignties and still are sovereign."
              </p>
              <PageNumber num={499} />
              <div className="speaker" id="sp1593"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I have no objection to the amendment. I accept it.</p>
              <div className="speaker" id="sp1594"><span className="persName">Mr. SEAWELL</span>—</div>
              <p className="p-in-sp">I have offered that amendment in consequence, very much, of certain expressions of opinion which I have heard in this hall in regard to the phraseology of the resolution. The gentleman from Fauquier [<span className="persName">Mr. ROBERT E. SCOTT</span>]
                , directed his entire argument to this word "independent," ignoring in the course of his remarks, as far as I heard him, all discussion as to the word "sovereign." I think that my amendment, as accepted by the gentleman from Middlesex, obviates this difficulty and meets all the arguments which I recollect to have heard from the gentleman from Fauquier: but it does bring up the
                argument urged against this entire amendment by the gentleman from Richmond
                [<span className="persName">Mr. MACFARLAND</span>]
                . That gentleman did not seem to discriminate in his argument. It seemed as if it made no difference to him whether the word "independent" was used alone, or whether the word "sovereign" was used alone, or whether both words were used conjointly. His argument, so far as it had any application at all, was applicable to both terms. I simply desire now to say, with all deference to the
                gentlemen who have so ably discussed the question this evening—much more ably, than I could ever hope to do—that it seems to me the question is now resolved into a nutshell. I do not choose to go into the discussion now as to where the attributes of sovereignty are to be found under our present government.
              </p>
              <div className="speaker" id="sp1595"><span className="persName">Mr. W. C. SCOTT</span>—</div>
              <p className="p-in-sp">I did not yield to a speech, only to an amendment.</p>
              <div className="speaker" id="sp1596"><span className="persName">Mr. SEAWELL</span>—</div>
              <p className="p-in-sp">
                I will not detain the committee by going now into the argument as to where the attributes of sovereignty are to be found located in any government on this continent, but with all due deference to the gentlemen who have so ably discussed this question, it seems to me they have been discussing it on erroneous principles or rather have used erroneous terms. I take it that sovereignty,
                as applicable to nations on this continent, cannot be assimilated to sovereignty as belonging to the nationalities of Europe. Sovereignty in Europe, may, and in many instances does appertain to the Government; because, where the Government is sovereign the people are slaves. But here, my impression is, and it is one I have entertained through life, and if I am in error, it is a
                life-long error, my impression has always been here that according to the theory of government on this continent, sovereignty resides in the people alone; that sovereignty here, is only to be found in the people of the States, and not in the Government of this State of Virginia. Because, if it is so, if sovereignty is to be found here resident in the people of the Commonwealth of
                Virginia, then <PageNumber num={500} /> why are we here now? Is the argument which the gentleman from Richmond
                [<span className="persName">Mr. MACFARLAND</span>] ,—for whose talents and reputation I have the highest respect—has offered against the use of this term in the sense in which the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>]
                , used it, to be made applicable to this exposition of the term sovereignty? Why, sir, if I understood the drift of his argument, it was that you have delegated a portion of your powers, a portion of your sovereignty, to the government of the United States. If that be so, if any portion of the sovereignty of the people of Virginia has been delegated to the government at Washington,
                where is the other portion of it? If in forming a government for the purpose of carrying on our business and our national affairs, we thereby parted with a portion of our sovereignty invested in us, the other part of it is in Washington. If it be true that the sovereignty of Virginia is thus divided, if it be invested in two Governments, where is the power in Washington to move?
                Cannot a sovereignty move? Do you mean to hold by that doctrine that Virginia can never move, because she has but half sovereignty, unless she can move by the consent of the General Government? On the other hand, will you contend that the General Government cannot move because it possesses but half of the sovereignty of the State of Virginia—the Government of Virginia possessing the
                other half? I think this is the dilemma in which the argument of the gentlemen must lead them. We are now discussing the primary questions of principle. The resolutions which are now under consideration, are to go down to all time as the exposition of Virginia's first political principle. And, therefore, it is that I am desirous that they shall go down properly and fairly set forth,
                fully ascertained and maintaining our political principles of the past and the present.
              </p>
              <p className="p-in-sp">
                If you take the view that the sovereignty of Virginia resides in the people of Virginia, and that they accredit agents solely for the management of their interests and their affairs, then you have two agencies for the sovereignty of Virginia, one at Washington and one in the State of Virginia, either of which, if there is an attribute of sovereignty worth one cent in this world—that
                sovereign power which created them has the right and power to revoke and annul it at any moment it may please. If, I say, the sovereignty resides in the people, these governments of ours are but agencies; and although that sovereign power slumbers for a while, yet it is but the slumber of a giant, which may arise at any moment and tear down the fabric which it has erected. I submit
                the amendment for the purpose of obviating the objections <PageNumber num={501} /> that I think have been made, and with some force, to the first resolution.
              </p>
              <p><span className="persName">Mr. WM. C. SCOTT</span> obtained the floor.</p>
              <p><span className="persName">Mr. JOHNSON</span> moved that the Committee rise.</p>
              <p>The motion was agreed to.</p>
              <p>The Committee accordingly rose, and, the President having resumed the chair, the Chairman [<span className="persName">Mr. PRICE</span>] , reported that the Committee of the Whole had, according to order, had under consideration the report of the Committee on Federal Relations, and had come to no resolution thereon.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.9.6">
              <h3><span className="headingNumber">1.9.6. </span><span className="head">CLOSE OF DEBATE IN COMMITTEE OF THE WHOLE</span></h3>
              <div className="speaker" id="sp1597"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I move the adoption of the following resolution, and upon it I ask the previous question.</p>
              <p>The resolution was then read by the Clerk, as follows :</p>
              <p>"Resolved, That the debate in Committee of the Whole on the reports of the Committee on Federal Relations, shall be terminated on Monday next at 12 o'clock, M."</p>
              <div className="speaker" id="sp1598"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">Upon that resolution I demand the previous question.</p>
              <div className="speaker" id="sp1599"><span className="persName">Mr. STAPLES</span>—</div>
              <p className="p-in-sp">I move to lay it on the table.</p>
              <div className="speaker" id="sp1600"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I rise to a question of order before the resolution is reported. I wish to know whether a gentleman can make two motions in one breath. He moved first, the resolution which he offers, and then moved, before that resolution is reported, the previous question also.</p>
              <div className="speaker" id="sp1601"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair is of the opinion that the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] , sent his resolution up to the table, and that no motion was in order until it was reported to the Convention.</p>
              <div className="speaker" id="sp1602"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                I did not make any until after it was reported. After it had been reported I asked for the previous question upon it. I rose and addressed the Chair, and sent up a resolution which was reported by the Clerk, and when I sent it up I remarked that I had a resolution which I desired to be reported upon, and upon the adoption of which I should move the previous question. When it was
                reported, I moved its adoption and demanded the previous question, as I maintain that I had the right to do under the rules.
              </p>
              <div className="speaker" id="sp1603"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">According to the recollection of the Chair, when the gentleman sent up his resolution he called for the reading and stated that he should demand upon it the previous question. If the Chair is mistaken in that, he will change his opinion.</p>
              <div className="speaker" id="sp1604"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">The disorder was such that I never heard it reported, and, taken in that way, it was a snap judgment.</p>
              <PageNumber num={502} />
              <div className="speaker" id="sp1605"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Secretary will report the resolution if it is desired by any member.</p>
              <div className="speaker" id="sp1606"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I desire it.</p>
              <div className="speaker" id="sp1607"><span className="persName">Mr. JOHNSON</span>—</div>
              <p className="p-in-sp">I am no Parliamentarian—</p>
              <div className="speaker" id="sp1608"><span className="persName">A MEMBER</span>—</div>
              <p className="p-in-sp">I dislike very much to do so, but the order of this body must be preserved; and I maintain, pending the motion for the previous question, that no discussion, is in order.</p>
              <div className="speaker" id="sp1609"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair will state, that under the rules of this Convention, debate is allowed under a demand for the previous question, ten minutes to each member.</p>
              <div className="speaker" id="sp1610"><span className="persName">Mr. JOHNSON</span>—</div>
              <p className="p-in-sp">I do not propose to occupy ten minutes—but this I do mean to say—</p>
              <div className="speaker" id="sp1611"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I rise to a question of order, and it is one I am certain that the President will be compelled to sustain. The gentleman from Harrison [<span className="persName">Mr. CARLILE</span>]
                is right. I was standing near him. He offered his resolution and the Clerk reported it, and as soon as the Clerk finished the reporting of the resolution, he then called for the previous question. The Chair did not propound the question to the House and the point of order which I raise is, that there is no question before the House until it has been propounded by the Chair; and the
                gentleman from Harrison, in this case, demanded the previous question before the resolution was propounded to the House. I, therefore, say that the gentleman is out of order and I ask experienced gentlemen here to sustain me in that.
              </p>
              <div className="speaker" id="sp1612"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">I would ask if the Chair propounded the question, whether or not the previous question should be sustained?</p>
              <div className="speaker" id="sp1613"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair did propound the question upon the adoption of the resolution and was about to proceed to ask whether the call for the previous question should be sustained or not, when he was interrupted by the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] rising and asking to have the resolution reported. The question now is, shall the call for the previous question be sustained.</p>
              <div className="speaker" id="sp1614"><span className="persName">Mr. JOHNSON</span>—</div>
              <p className="p-in-sp">
                I desire to say but a few words on the motion made by the gentleman from Harrison. All will bear me testimony that thus far I have consumed very little time and have occupied very little of the attention of this Convention. I do not propose to occupy much more, if any more of it. But I think, sir, that fair play is a jewel. I am in favor of free, full liberty of legitimate
                discussion. I am not for any gag law; and I trust, assembled as we are here in the consideration of the most momentous questions that have ever been called to the attention of any of us, that it will not be the pleasure of this Convention <PageNumber num={503} /> to say, when some gentlemen have had the fullest opportunity to express their views fully
                and elaborately, that other gentlemen shall be cut off from a fair expression of their sentiments and the presentation of the views of their constituencies by any parliamentary diplomacy. I do not think that it is right, and I appeal to gentlemen, as a matter of plain and simple justice to say, that they will not cut off the fair and legitimate consideration of the important
                questions we have to determine. I shall vote against the resolution of the gentleman from Harrison, and I trust all here will.
              </p>
              <div className="speaker" id="sp1615"><span className="persName">Mr. PARKS</span>—</div>
              <p className="p-in-sp">
                I have not occupied one moment's time of the Convention since its commencement. I do know that I feel as deep an interest in the questions upon which we are now consulting, as any member upon this floor. I have not occupied any of the time of the Convention, from the fact, that I believe that the people of the country were already restless and weary, from the fact that this
                Convention were not acting, but instead thereof, making long speeches. I receive letters day after day from my constituents, saying, why don't you act; we have become tired of reading long labored arguments; there is a crisis upon the country; act and come home and say what Virginia intends doing; if you intend to go out of the Union and make common cause with the Southern
                Confederated States, or if you intend assuming your own rights say so, or if you intend holding on to the North say so; do what you will, but do it quickly and come home. Therefore, I say, that I am prepared to vote for the resolution, and I shall do so with the greatest pleasure.
              </p>
              <div className="speaker" id="sp1616"><span className="persName">Mr. WOODS</span>—</div>
              <p className="p-in-sp">
                It has not been my privilege nor my intention, nor is it my purpose now, to occupy very much of the time or attention of this Convention, being entirely without experience in parliamentary proceedings. I have listened with a great deal of interest, to the discussions that have taken place in this Hall, and to the speeches that have fallen from the lips of the various gentlemen who
                have taken occasion to address us. While I feel like complaining that we have been in session so long and done so little; while we have listened, and patiently listened, to so many long speeches; yet, I cannot conceal from my mind, and we cannot conceal it from the people of Virginia, that the most of these speeches have been occupying the time and attention of the Convention, while
                we were patiently waiting for the report of the Committee upon Federal Relations. We appointed a Committee of twenty-one, to whom we were to refer the various resolutions that were offered here. The most of them were referred, without debate, and, for lack of something better to do, I suppose, the various gentlemen who occupied
                <PageNumber num={504} /> the time and attention of the Committee, thought it was a good time to deliver the various addresses to which we have had the pleasure of listening. I think, then, since we have reached a legitimate subject for discussion, that we have not had, as yet, as much time as should be required for the consideration of so important
                questions as are here involved. I have been struck with the disposition manifested on the part of this Convention, to have their own way, as they are entitled to, and to make us feel that they will cut off debate on this question ; but I trust that the people of Virginia, whose ears have been patiently turned towards us for the last six weeks, in order to learn what we were saying,
                and to know, if possible, what we intended to do, will not be prevented from hearing this question discussed.
              </p>
              <p className="p-in-sp">
                It may look very well to some gentlemen's ideas of consistency, after they have discussed a question until they were tired, and I suppose until some of the rest of us were tired, to endeavor to cut off debate, to prevent the people of the Commonwealth of Virginia from knowing that there is but a single question for Virginia to determine, and that is whether she will remain in the
                Union or not. After you have canvassed the subject in all its various relations, it resolves itself into that simple question. The people realize that this is the issue, and it may be possible that it is to the interest of a portion of this Convention to prevent the people from fully comprehending the question. We meet here in the morning, and we sit here late at night in the
                consideration of the question. I am disposed to undergo all this labor, and as much more as may be necessary, but I am opposed to cutting off debate. The people have a right to have this subject fully discussed, as it is the most important subject in which they were ever interested. The course attempted to be pursued makes it appear to me as if we intended to prevent the people from
                understanding the issues, and I trust, unless a majority of the Convention has foreclosed the question, that it shall be the pleasure of the Convention not to do so. I do not intend to occupy much of your time, now or hereafter, but I desire that every man who may wish to submit his views shall have a reasonable opportunity to do it.
              </p>
              <div className="speaker" id="sp1617"><span className="persName">Mr. MALLORY</span>, of Brunswick—</div>
              <p className="p-in-sp">Mr. Chairman, I reckon, sir, I am the oldest man in the Convention, except one, and that is my venerable friend from Charles City [<span className="persName">Mr. TYLER</span>]
                . I don't know, I may or may not be older, but I am old enough to give advice, anyhow. Some gentlemen here have spoken two or three days, and I think they might let others have the poor privilege of occupying one day. I should despise myself if I would vote to sustain the resolution offered to cut off debate on Monday next. I am as anxious to go home as any one, and, to tell you
                <PageNumber num={505} /> the truth, I am sorry I ever came here at all.
                [Laughter.] But the people of Brunswick would send me here—it is no egotism in me to say so; yet I am sorry I made the bargain to come here. I wish I could go home to-morrow. But I cannot consent to cut off debate as early as Monday, and I appeal to gentlemen of all parties to vote down the proposition.</p>
              <div className="speaker" id="sp1618"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">This resolution only terminates debate in committee of the whole. You can discuss these propositions in Convention as long as you choose.</p>
              <div className="speaker" id="sp1619"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">Mr. Chairman, I shall feel constrained myself to vote for the resolution which is before the house, and in deference to the opinion of the gentleman from Brunswick [<span className="persName">Mr. MALLORY</span>]
                I beg leave to state that it is not with any view whatever of restraining debate in the Convention. We have, as I understand, in the Convention, a rule which, in the first place limits each member to one speech upon the same subject. In Committee of the Whole, there being no such rule, it is in the power of one member, provided he has physical strength to do so, to engross the whole
                debate and attention of the house. We have also in Convention the power of limiting the length of the speeches. I understand there is no such power in Committee of the Whole, and the consequence is that in the Committee of the Whole the house possesses no power to control in any way the debate. Now, sir, I should be very unwilling—and I will vote with that gentleman, with great
                pleasure against any resolution, to place any undue restriction upon debate in this house, or against preventing any member who has not had an opportunity to express his opinions and desires to do so, from having an opportunity to express these opinions. But, sir, if we go on without some such rule as that adopted by the Convention to restrain debate in Committee of the Whole, the
                debate may be, and to all appearances will be, interminable. It is impossible for any man to say when we will see the end of it. And whilst I would be far from undertaking to restrict debate upon this subject yet at the same time, sir, I have a strong sense of the importance of this Convention acting, within some reasonable time, upon the subject before it. For these reasons I shall
                be compelled to vote for the resolution as it is offered.
              </p>
              <div className="speaker" id="sp1620"><span className="persName">Mr. TAYLOE</span>, of Roanoke—</div>
              <p className="p-in-sp">I move to adjourn.</p>
              <div className="speaker" id="sp1621"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">Upon that question I call the ayes and noes.</p>
              <div className="speaker" id="sp1622"><span className="persName">SEVERAL MEMBERS</span>—</div>
              <p className="p-in-sp">O, no! withdraw the motion.</p>
              <div className="speaker" id="sp1623"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Does the Chair understand that the motion is withdrawn?</p>
              <div className="speaker" id="sp1624"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I withdraw it, sir.</p>
              <PageNumber num={506} />
              <div className="speaker" id="sp1625"><span className="persName">Mr. BARBOUR</span>, of Culpeper—</div>
              <p className="p-in-sp">Mr. Chairman, I was a little surprised at what fell from the lips of the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>]
                . He advocates a proposition that debate shall terminate in this Committee on Monday, but is willing that you may have as much in Convention as you please. Now, when you terminate the debate here you come to a vote in the Convention, and then it is proposed that there shall be full liberty to debate after the question is already settled! I am very free to say that I desire to have
                the privilege of debating this question, and if it is the pleasure of the Convention to hear me, I infinitely prefer to argue the subject before than after it is decided. I should have availed myself of the opportunity of speaking before this, but for the condition of my health, which induces me now to postpone it. I know that in the scramble that would follow if this resolution
                should be adopted, I would have no hope of getting the floor. I hope it will not be adopted.
              </p>
              <div className="speaker" id="sp1626"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                You will all testify, sir, I suppose, that I have spoken enough. I have had my share, sir, but I tell you now, I haven't begun. And, why? Why, sir, the preliminary questions, alone, required such hard work, and such hard discussion as you have heard to-day; and be it remembered, that you have just got to the first two lines of the first section of that report; and the Chairman of the
                Committee who reported it now asks that we shall come to a decision without any debate upon all of thirteen pages besides.
              </p>
              <p className="p-in-sp">Now, sir, there is my friend the Lieutenant Governor [<span className="persName">Mr. MONTAGUE</span>] , was on the Committee; there is my friend from Culpeper [<span className="persName">Mr. BARBOUR</span>] , there is the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , there is my friend from Shenandoah [<span className="persName">Mr. WILLIAMS</span>] , and the gentleman from Halifax [<span className="persName">Mr. BRUCE</span>] , and myself have been the only members who have been heard, and I have been heard on only a few points. I haven't been heard upon the thirteen pages for my text. [Laughter.]
                I am honest when I mean to say that this subject never shall be let off on no discussion; for really we have had but little in proportion to the number of members, or the points embraced in the report. The gentleman makes one objection to remaining in Committee of the Whole, and that is that gentlemen may speak twice. Sir, it is a rule in the Committee, that when a gentleman has
                spoken once, if another competes with him for the floor, the one who has not spoken is preferred, in the Committee as well as in the house; and I will always yield, as far as I am concerned, to any gentleman who has not spoken when I have spoken upon the subject. I say, sir, that it will be unjust to the minority of this Committee, and when you reflect that on that Committee
                <PageNumber num={507} /> of Twenty-one, there are fifteen or sixteen in the majority to five or six in the minority, after you have allowed the strong and able gentlemen, who have recommended this report to defend it, and but one of the minority to be heard upon it as yet. If you cut off debate now, when we have only discussed the first two lines of the
                first section of that report, I know of no case like it in all the history of legislation in my experience, except the case that occurred in the Congress of the United States, when my friend from Charles City
                [<span className="persName">Mr. TYLER</span>]
                , was President. I knew of one case like it, and that was when a bill to provide for a United States Bank was reported to Congress one day, and in an hour after the previous question was called, and it was passed, but was promptly vetoed, thank God, by the member who now sits in his seat. And if you pass this report in the same way, you will find a power greater than a President that
                will veto such work.
              </p>
              <div className="speaker" id="sp1627"><span className="persName">Mr. BLAKEY</span>, of Madison—</div>
              <p className="p-in-sp">
                Mr. Chairman, I have not heretofore occupied the attention of this body, except for the purpose of voting. The Chairman of the Committee upon Federal Relations, has surprised me exceedingly by giving his adhesion to the proposition before the Convention. Let it be borne in mind that the Chairman of the Committee, and those who acted with him took six weeks to make this report. They
                have had to debate this question among twenty-one members for six weeks; and then to come here and require 152 members to decide upon that action in a little more than two weeks, it seems to me is rather a strange idea. To have us adopt it, and then debate it afterwards, seems to me to be most extraordinary legislation. I have had no experience in legislative assemblies, but if this
                is a fair sample of legislation in this country, I trust I never shall have anything more to do with it. If the nerves of gentlemen have become unsettled in consequence of popular demonstrations, I think they had better try to quiet their nerves, and give others on this floor a fair chance to express their opinions.
              </p>
              <div className="speaker" id="sp1628"><span className="persName">Mr. HARVIE</span>, of Amelia—</div>
              <p className="p-in-sp">
                Mr. Chairman, I do not know, sir, that I shall make any speech at all in this Convention; I have not spoken much here, and I did not speak much in the committee. But I will say this to you, sir, and to the Convention, that there is not one solitary word, at least, not one line of this report that was not sifted, examined and discussed thoroughly. There is scarcely a section of this
                report that did not create division in that committee. There are many sections of this report that were passed by one vote in that committee. That committee kept this House waiting three weeks upon them, doing nothing; and for form's sake and shame's sake, the members of this Convention <PageNumber num={508} /> appeared to be doing something, because,
                otherwise, odium would have attached, and justly attached to them, for wasting the time of the people in this Convention.
              </p>
              <div className="speaker" id="sp1629"><span className="persName">Mr. JACKSON</span>, of Wood—</div>
              <p className="p-in-sp">I rise to a question of order.</p>
              <div className="speaker" id="sp1630"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I any entitled to the floor for ten minutes.</p>
              <div className="speaker" id="sp1631"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">My point of order is, that it is against parliamentary rule to refer to the proceedings of another committee. I want the decision of the Chair on that point.</p>
              <div className="speaker" id="sp1632"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The CHAIR is of the opinion that the gentleman is in order; he is referring simply to the time occupied in discussing the report and is explaining why the session of the Convention has been so long prolonged. The CHAIR decides that the gentleman is in order.</p>
              <div className="speaker" id="sp1633"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">Then I beg to call attention to the fact, that the gentleman himself gave the vote.</p>
              <div className="speaker" id="sp1634"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">If there is anything to be exposed here about that committee, if the gentleman knows anything, I do not.</p>
              <p className="p-in-sp">But, sir, I do mean to say what I am entitled to say about that Committee and about the proceedings of this House, and I intend to be heard in this House as to what I have to say. Gentlemen had, therefore, as well take it patiently and quietly for they cannot gag me or conceal from the public what I intend to say.</p>
              <p className="p-in-sp">
                Mr Chairman, I repeat, that this Committee—and I do not say it, and my colleague upon the Committee knows that I do not say it in censure—I say that it labored faithfully for twenty odd days before making this report; that every sentence, word and line of their report was examined thoroughly and voted upon by them; and I repeat, that they would have been false to their trust if they
                had discussed these questions less, and if they had not taken votes separately upon every one of these important questions. Various propositions were presented, and I hope I may be allowed to say that in some instances if there had been a full Committee present when the votes were taken; some of the propositions in the report would have been rejected, and other propositions which
                were rejected would have been inserted.
              </p>
              <p className="p-in-sp">
                Now, sir, I appeal to this House whether, upon questions involving so important interests in this Commonwealth, and which involve, I trust, the very integrity and existence of this Government, they will allow the gag to be applied, to prevent us who were upon that committee, and who have more information upon some of these questions than other members of the Convention, from
                imparting that information to the other members of this body, in order that they may vote understandingly upon all these questions. I ask if they will allow the <PageNumber num={509} /> gag to be placed upon us, when, as my friend from Princess Anne has told you, you have only got through one line and a half of this whole report—when you have not
                approached any portion of the report involving practical questions requiring the close examination of every member? I ask, and I repeat, whether, under all these circumstances, and particularly upon the appeal of a gentleman who has exhausted more time than any other member of this body, in presenting his views, sustained by a member of the Committee on Federal Relations, who has
                also presented his views while there are many other members of the committee who have never been allowed that opportunity, whether you will place the gag in our mouths, and require the Convention to vote upon the undigested work, now under consideration of the committee of the whole, that, if adopted, will place the Commonwealth of Virginia in a position which she ought not to
                occupy?
              </p>
              <p className="p-in-sp">It is hardly necessary, I presume, in presenting the facts, to enter into any discussion upon them. I hope upon their mere presentation that this body will vote down the resolution and permit a full and free discussion upon the questions before us.</p>
              <div className="speaker" id="sp1635"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">
                I have not been able to discover why there should be particular excitement upon this question more than upon any other connected with our deliberations. We are here in the consideration of an ordinary parliamentary proposition. We have been in session here six weeks. We were told when we came to this Convention, it was of the utmost importance that there should be speedy action, that
                our action should not only be prompt, but that the decision of Virginia should be made before the 4th of March. If I am correctly informed the 4th day of March has passed by and yet the action of Virginia has not been sent forth to the country, has not even been determined on by the Convention itself.
              </p>
              <p className="p-in-sp">Now, sir, as the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>]
                , has brought before the Convention some of the proceedings and actions of the Committee of Twenty-One, I desire to say in that connection, as a member of that Committee, that I and five members who acted with me, were not absent during its deliberations upon a single vote—although we were almost always in a minority—I will not tell you how often the gentleman from Amelia was absent.
              </p>
              <div className="speaker" id="sp1636"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">The gentleman from Amelia will tell you himself. The gentleman from Amelia was absent only from extreme illness in his family, and, when absent, by an arrangement the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , did not vote, so that although really absent for a portion of the time, I was practically present always.</p>
              <PageNumber num={510} />
              <div className="speaker" id="sp1637"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I understood the gentleman from Amelia, to some extent, to cast censure upon that Committee.</p>
              <div className="speaker" id="sp1638"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">In that the gentleman mistakes.</p>
              <div className="speaker" id="sp1639"><span className="persName">Mr. JACKSON—</span></div>
              <p className="p-in-sp">
                Not directly, but as a consequence of his argument. Now, sir, that Committee was in session some four or five weeks. The gentleman tells us that every sentence, every line, and every word was canvassed there, but he forgets to tell you that at the very time he speaks of some of these propositions passed by a single vote, when there was the bare number of twelve present out of its
                twenty-one members. And, sir, let me tell you, that the gentlemen with whom I act were there, and always there. And let me tell you that if all the members of that Committee had been as faithful in their attendance, and if all the members of this Convention had been as faithful, the business of this Convention would long since have been transacted, and the apprehensions of the people
                would have been quieted. We are told here, over and over again, that there is great excitement in the public mind, and that this Convention ought to have closed its labors long ago. Whose fault is it? I have not debated these propositions in Convention, and but very little in Committee. I am one of those who are considered as entertaining extreme opinions, but I have been anxious
                that those opinions, whatever they may be, should be expressed by my vote. I apprehend that no gentleman who now talks about having the gag applied believes that any single vote will be changed, that any single opinion will be changed; that any impression whatever will be made upon any member of this Convention, by any speech made in this body. I apprehend that no body entertains any
                such opinion. For what purpose then shall we continue the debate? Why shall we not act? Permit me to tell you that, in my parliamentary experience, small though it has been, scarcely ever has any subject been considered in Committee of the Whole at all, because debate in committee was known to be, as a general thing, a very unprofitable sort of debate. And I think our experience here
                will attest that fact. Every proposition before the Committee of the Whole can be more satisfactorily discussed and we shall arrive sooner at a satisfactory conclusion than we can possibly do by continuing the debate in committee.
              </p>
              <div className="speaker" id="sp1640"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">As I am here, as has been suggested, in the minority, I presume I may be allowed to give some advice.</p>
              <div className="speaker" id="sp1641"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">Oh! You are on the right side now under your instructions from Petersburg.</p>
              <div className="speaker" id="sp1642"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">
                Very well. I beg to inquire whether there has been any day suggested that will suit the purposes of gentlemen who are <PageNumber num={511} /> opposed to this resolution? Getting a little tired of hearing speeches in this body, I took the liberty last night of going home for a day's recreation. I do not know that you have lost anything by it and I have
                certainly been the gainer by my absence.
                [Laughter.]
                Now, sir, I have no idea of gagging any minority here, though I do desire, within some reasonable time, to finish the work of this Convention. I have no disposition to sit here until after corn-planting. I hope gentlemen will recollect that it is not the longest speeches that are always the most instructive. I am perfectly willing to extend the debate for any reasonable length of
                time, but I think some period should be fixed for its termination. Let it be extended to Wednesday, Thursday or Friday. If that will suit the wishes of gentlemen here, I will move to amend by inserting Friday of next week at 2 o'clock.
              </p>
              <div className="speaker" id="sp1643"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">No motion to amend is in order pending the demand for the previous question.</p>
              <div className="speaker" id="sp1644"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">If you will agree to adjourn the Convention sine die and let the Commonwealth get rid of you I will go with you.</p>
              <div className="speaker" id="sp1645"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">Perhaps I will, but I am not prepared to do so tonight.</p>
              <div className="speaker" id="sp1646"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I desire to propose an amendment before the previous question is sustained—for I understand that after the call has been sustained an amendment will not be in order.</p>
              <div className="speaker" id="sp1647"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">No amendment is in order after the call has been made.</p>
              <div className="speaker" id="sp1648"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I do not so understand it, but let it go.</p>
              <p>The previous question was seconded and the main question ordered to be put.</p>
              <p><span className="persName">Mr. CARLILE</span> called for the yeas and nays upon the adoption of the resolution.</p>
              <p>The yeas and nays were ordered.</p>
              <p>
                The question was taken and it was decided in the negative—yeas 66, nays 70—as follows : <span className="note" id="Note54"><span className="noteLabel">7</span>The Enquirer omits from the affirmative votes the name of Dorman, which is found in the Journal.</span>
              </p>
              <p>
                YEAS—Messrs. Armstrong, Aston, Baylor, Berlin, Boggess, Brown, Burdett, Burley, Byrne, Carlile, Carter, C. B. Conrad, R. Y. Conrad, Couch, J. H. Cox, Custis, Deskins, Dulany, Early, French, Fugate, Gillespie, Gray, A. Hall, E. B. Hall, Haymond, Hoge, Holladay, Hubbard, Hughes, Hull, Jackson, P. C. Johnston, Kilby, Lewis, McComas, McGrew, McNeil, Macfarland, Marshall, Marr, Maslin,
                Masters, Moffett, MOORE, Orrick, Osburn, Parks, Pendleton, Porter, Price, Pugh, Wm. C. Scott, Sharp, Sitlington, Spurlock, A. H. Stuart, C. J. Stuart, Summers, Tarr, Tayloe, Whitfield, Wickham, Willey, Wilson-66.
              </p>
              <p>NAYS—Messrs. Ambler, Baldwin, A. M. Barbour, J. Barbour, Blakey, Blow, Boisseau, Borst, Boyd, Branch, Brent, Cabell, Campbell, Chambliss, Chapman,</p>
              <PageNumber num={512} />
              <p>
                Coffman, Conn, R. H. Cox, Echols, Fisher, Flournoy, Forbes, Garland, Graham, Gravely, Gregory, Goggin, J. Goode, T. F. Goode, C. Hall, L. S. Hall, Hammond, Harvie, Holcombe, Hunton, Isbell, M. Johnson, Kent, Lawson, Leake, C. K. Mallory, J. B. Mallory, Marye, Miller, Montague, Morris, Morton, Neblett, Nelson, Preston, Randolph, Richardson, Rives, R. E. Scott, Seawell, Sheffey,
                Slaughter, Speed, Staples, Strange, Sutherlin, Thornton, Tredway, R. H. Turner, Tyler, Waller, Williams, Wise, Woods, Mr. President
                [Janney] -70.</p>
              <p>So the resolution was rejected.</p>
              <p>Before the vote was announced, <span className="persName">Mr. TURNER</span> stated that he had paired off with <span className="persName">Mr. PATRICK</span>; and then, on motion, the Convention adjourned.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v2.2.10">
            <PageNumber num={513} />
            <h2><span className="headingNumber">1.10. </span><span className="head">THIRTY-SEVENTH DAY</span></h2>
            <div className="dateline">Thursday, <span className="date">March 28</span></div>
            <p>The Convention met pursuant to adjournment at 10 o'clock, A. M. Prayer by the Rev. Dr. Willis, of the Methodist Church.</p>
            <div className="section" id="vsc1965.v2.2.10.1">
              <h3><span className="headingNumber">1.10.1. </span><span className="head">RESOLUTIONS OF NELSON COUNTY</span></h3>
              <div className="speaker" id="sp1649"><span className="persName">Mr. CABELL</span>—</div>
              <p className="p-in-sp">I present the proceedings of a public meeting of Nelson county, and ask that they be read, and that they take the usual reference.</p>
              <p>The proceedings were read as follows:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>PUBLIC MEETING IN NELSON COUNTY</p>
                  <p>
                    At a public meeting held at Lovingston, Nelson county, on Monday the 25th March (that being court day), after public proclamation was repeatedly made from various points, so as to insure a full knowledge of the meeting and its purposes, on motion of J. H. McCue, Esq., Wm. S. Dilliard was called to the chair, and N. H. Goodwin appointed secretary.<span className="note" id="Note55"
                    ><span className="noteLabel">1</span>The chairman's name is given below as Dillard.</span
                    >
                  </p>
                  <p>The Chair having stated the object of the meeting, <span className="persName">Mr. Mc</span>Cue addressed the assembly in favor of immediate secession, urging the adoption of decided action on the part of the representative of the State Convention from Nelson county. He was followed by Col. J. B. Hargrove in a strong appeal to the same purpose.</p>
                  <p>The following preamble and resolutions were then adopted without a dissenting voice from any party:</p>
                  <p>Virginia having done all that she can or ought to do, consistently with her honor, to preserve the Union—</p>
                  <p>Resolved, therefore, That we, the people of Nelson, do hereby express it as our solemn conviction that the State of Virginia should secede immediately from the United States Government.</p>
                  <p>Resolved, That the foregoing resolutions be forwarded to our delegate in the Convention to be presented by him to that body, and be regarded by him as instructions to vote for an ordinance of secession at once.</p>
                  <p>A motion was then made, and passed, that the proceedings of the meeting be published in the Richmond Whig, Enquirer, and Examiner, with a request that Lynchburg and Alexandria papers copy.</p>
                  <p>On motion the meeting then adjourned.</p>
                  <p>WM. S. DILLARD, Chairman.</p>
                  <p>N. H. GOODWIN, Secretary.</p>
                </div>
              </div>
              <PageNumber num={514} />
              <p>The resolution was referred to the Committee on Federal Relations.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.10.2">
              <h3><span className="headingNumber">1.10.2. </span><span className="head">CORRECTION OF THE JOURNAL</span></h3>
              <div className="speaker" id="sp1650"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I rise to make a correction of the report of the proceedings of Tuesday night, because that report places me in a false position. I find in the "Enquirer" of this morning this: "After a motion was made to adjourn and decided in the negative,</p>
              <p className="p-in-sp">"<span className="persName">Mr. EARLY</span>—Mr. President—</p>
              <p className="p-in-sp">"<span className="persName">Mr. HARVIE</span>—I rise to a point of order, and it is this: that the gentleman is bound to rise from his seat before he can be recognized by the President.</p>
              <p className="p-in-sp">"<span className="persName">Mr. EARLY</span>—I did rise from my seat.</p>
              <p className="p-in-sp">"<span className="persName">Mr. HARVIE</span>—The gentleman was standing before he addressed the Chair and did not rise for that purpose."</p>
              <p className="p-in-sp">
                As the gentleman from Amelia stated I did not rise from my seat, and the Chair so decided, but it is not true that I claimed to have risen from my seat. On the contrary I disputed the right of the gentleman from Amelia to call me to order, and asked if he rose from his seat. I suppose it was that remark which was misunderstood by the reporter. I do not want to be placed in the
                position of having stated what was not true, in the face of the statement of the gentleman from Amelia, and of the ruling of the then President. It is necessary to make this correction in order that I shall not appear to have been guilty of equivocation, in my desire to obtain the floor.
              </p>
            </div>
            <div className="section" id="vsc1965.v2.2.10.3">
              <h3><span className="headingNumber">1.10.3. </span><span className="head">BASIS OF TAXATION</span></h3>
              <p><span className="persName">The PRESIDENT</span> stated the business before the Convention to be the consideration of the resolutions offered by <span className="persName">Mr. WILLEY</span>, on which <span className="persName">Mr. TURNER</span>, of Jackson, was entitled to the floor.</p>
              <div className="speaker" id="sp1651"><span className="persName">Mr. TURNER</span>, of Jackson, resumed his remarks as follows:</div>
              <p className="p-in-sp">
                It is very apparent to my mind, Mr. President, from the course pursued by this body, that an adjourned session of this Convention will take place sometime during the fall or winter. I, therefore, desire to call the attention of gentlemen, and through them the attention of the people to the increased expenses that will be brought upon the State by the calling of a border State
                Conference, and to the necessity of this Convention taking into its consideration the propositions now before it. As I said yesterday, the increased expenditures of the State during the incoming fiscal year will increase the amount of taxation on the present sources of revenue to the extent of 50 per cent. There are other points of honor as near and as dear to the State of Virginia,
                <PageNumber num={515} /> as the question of her position in the Federal Union. Her State faith is a matter which must not be neglected or left unattended to. The only way in which it can be attended to satisfactorily to the people, is by finding new and legitimate sources of revenue. I apprehend from the remarks which I heard, that there is a mistake on
                the minds of gentlemen on the subject of slave taxation. They seem to suppose that those who are now favoring this change in our organic law, desire to fix the market value of the slave. Such is not my idea. The Eastern man, whose slave will be taxed, will fix the value of that slave. Eastern commissioners, slaveholders, will fix the value of slaves.
              </p>
              <p className="p-in-sp">He will value them after taking into consideration all the surrounding circumstances, just as in the case of any other property. I say this to disabuse the minds of gentlemen of the idea that Western men desire to fix the market value of the slave.</p>
              <p className="p-in-sp">But it is necessary to reach the new source of revenue in order that the credit and faith of the State may be maintained. It is humiliating to know that the bonds of the State are being hawked and peddled through the cities of the Union, and cannot be sold at any price.</p>
              <p className="p-in-sp">
                And why? Because it is apparent, from the reports of the financial officers of the State, that provision cannot be made to meet the interest due on the bonds, and also meet the liabilities of the State during the incoming year. The Treasury will be without its means of carrying on the State Government. And yet we are told that we must postpone action on this matter till an adjourned
                session of this Convention takes place; but it will then be found, perhaps, too late to get the Legislature, next winter, to act upon it. If this Convention ever meets again, and pursues the same course of policy which is being pursued by this Convention, who can tell when it will end?
              </p>
              <p className="p-in-sp">
                Sir, the people of Western Virginia are a conservative people. They are slow to come to conclusions, and equally as slow to depart from conclusions, once arrived at. They are not hasty in their action, and the best evidence of that fact is, their having submitted so long to the injurious course of taxation, pursued in this State. If gentlemen who are charging Northwestern Virginia
                with being abolitionized, desire to see that event brought about, the course of policy which they are now pursuing is the very best means they can use to bring about that result.
              </p>
              <p className="p-in-sp">
                When the people of North-western Virginia find that the appeals which may be made and which have been made by them, day after day, and year after year, are fruitless, and that their just rights are still refused, they will determine on a course which will most likely lead to the result that they desire, and will pursue it with that unfaltering
                <PageNumber num={516} /> step with which they pursue any course to which they dedicate themselves.
              </p>
              <p className="p-in-sp">
                Mr. President, I do not desire to occupy longer the attention of the Convention on this subject. I have done so longer than I designed when I commenced addressing it. It is not a pleasant position to occupy, to be compelled, on account of the time allotted to the consideration of the question, to be before the body for two or three days in succession. I have endeavored as briefly as
                possible to present my views, and thank the Convention for the patience with which it has listened to me.
              </p>
              <div className="speaker" id="sp1652"><span className="persName">Mr. WILLEY</span> addressed the Convention as follows:</div>
              <p className="p-in-sp">
                Before the vote is taken on this question, I desire to make a few observations. I am aware that under the rules allowing but a few minutes each morning for discussion of matters not before the Committee of the Whole, it is impossible to make an argument, and I myself shall not attempt it. I feel it due to myself, however, as proposing the resolutions before the Convention, that I
                should explain as briefly as I possibly can, the reasons which induced me to offer them.
              </p>
              <p className="p-in-sp">
                It has been alleged that the subject of taxation was not contemplated in the call of this Convention. To me that is a singular allegation. To refute any such assertion of opinion, we have only to refer to the Act passed by the Legislature convening this body. We have only to look into that Act to see that there is an express provision requiring any action that may be taken by this
                body, on the subject of amendments to the organic law of the State, to be referred back to the people of the State for rejection or ratification. It is a fact which cannot be disputed, that, at least in the Western portion of the State, amendments to the organic law, were the primary consideration in the minds of the people, when they appeared at the polls for the selection of
                delegates to this body. If, then, we are to ignore this subject, if we are to pass it by without consideration, we shall have failed to discharge one of the primary duties contemplated in the election of delegates, and shall have failed to answer the just expectation of a vast majority, as I humbly conceive, of the people of this Commonwealth.
              </p>
              <p className="p-in-sp">
                Now, sir, our organic law either wants amendment or it does not. The principle contemplated in the first resolution which I had the honor to offer, is either wrong or it is right. If it be wrong, if there be no necessity or propriety in amending the Constitution in this regard, let this Convention say so. Let them at once decide this question. If the principle of taxation, as now
                provided for in the organic <PageNumber num={517} /> law, be right, let the Convention say so. If it be wrong, why should we continue to live under the wrong? If it be oppressive, as many people think it is, why should the people of this Commonwealth be required to live longer under that oppression? We have it now in our power to remedy the evil, if it
                be an evil. We are assembled in Convention. The matter has been brought to the consideration of delegates here. What is the reason adduced by gentlemen, why we should not now consider it? It is alleged that in point of fact there was a virtual understanding at the last Convention, that amendments to the Constitution should not be considered until 1865. In the first place I remember
                no such understanding, and "it is not so nominated in the bond." We are here now. We have full power to redress the grievance, if it be a grievance. What do gentlemen contemplate by postponing this matter? Not only that we should suffer under this wrong for five years longer, but that the people of the Commonwealth shall be put to the trouble and expense of electing another
                Convention, to do what we have it in our power to do now, and which we may as well do now as we could in 1865. It is objected against the consideration of this proposition now, that this is an inopportune occasion, that it brings into the deliberations of this body, matters calculated to increase dissensions, and strifes, and heart burnings, already existing in the minds of the
                people of Virginia in regard to other matters. I say again, as I said a few days ago, that I had no such object in introducing this measure to the consideration of this body. I offered it as a peace measure. I believe, that if fairly and fully considered now, it will have a tranquilizing influence on the minds of the people. It will show to the people of the Commonwealth that there
                is a disposition to extend full justice to them. It will remove from the public mind a question which has been harassing and irritating it for the last ten years. It is a question which has been sowing the seeds of irritation deep in the public mind ever since Virginia had an organization as a Commonwealth. I will take it upon myself further to say: it is not a sectional question. I
                protest against gentlemen asserting that this measure is sectional in its character. It is a question between the non-slaveholding and the slaveholding portions of the people of Virginia. In the streets of Richmond I see a white man with his horse and dray. I enquire of him and he tells me he has a family and a small portion of the world's goods. I find by the statutes of Virginia
                that his dray and horse, his household furniture, and the head on his shoulders, are all taxed. I go into the county and see the white man laboring on his farm, with his stalwart boys earning an honest livelihood. His little <PageNumber num={518} /> stock, his plough and horses, and mules, and furniture, the head on his shoulders, and the heads on the
                shoulders of his sons, are all taxed. I look again in our cities and see the sturdy arm of the white laborer pushing the plane. His very tools, and all that he has, are taxed—taxed to their full value. There is no discrimination, no distinction. And this applies not alone to Western Virginia, not alone to the hardy sons of toil in the western boundaries of the State, but it applies
                equally in Richmond, Alexandria, Norfolk, and every county throughout the Commonwealth. Why should there be any such distinction?
              </p>
              <p className="p-in-sp">Why is it that the property, the entire property, of this class of our population should be taxed, while the man most able to pay taxes, whose property consists in slaves, should have a very considerable portion of his property wholly exempt from taxation.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.10.4">
              <h3><span className="headingNumber">1.10.4. </span><span className="head">COMMITTEE OF THE WHOLE</span></h3>
              <div className="speaker" id="sp1653"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The hour has arrived for the Convention to go into Committee of the Whole. The gentleman from Albemarle [<span className="persName">Mr. SOUTHALL</span>] , will take the Chair.</p>
              <div className="speaker" id="sp1654"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">I desire to call up the resolution which I offered the other day, in reference to changing the hour of meeting.</p>
              <div className="speaker" id="sp1655"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair cannot entertain a motion to take it up now.</p>
              <div className="speaker" id="sp1656"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">I move to suspend the order for the purpose of calling up that resolution.</p>
              <p>The motion was lost.</p>
              <p>The Convention then went into Committee of the Whole, <span className="persName">Mr. SOUTHALL</span> in the Chair.</p>
              <div className="speaker" id="sp1657"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">When the Committee rose yesterday the question under consideration was the first section of the report of the Committee on Federal Relations. A motion had been made by the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>] and the gentleman from Gloucester [<span className="persName">Mr. SEAWELL</span>] moved an amendment to the amendment, which the gentleman from Middlesex accepted. The amendment proposed to insert in the fourth line, after the word "were," the words "and still are," so that if the amendment was adopted, that portion of the section would read "when the Federal Constitution was formed were, and still are, independent sovereignties."</p>
              <p className="p-in-sp">
                During the discussion of that amendment, an amendment was suggested which was to add after the word "are" in the amendment, the word "sovereign," which was accepted by the mover of the first amendment, and which now constitutes the pending amendment to the resolution. <PageNumber num={519} /> Should the amendment be adopted that portion of the
                resolution will then read : "When the Federal Constitution was formed, were, and still are, sovereign and independent sovereignties."
              </p>
              <p className="p-in-sp">The question will be upon that amendment.</p>
              <p className="p-in-sp">
                The Chair has stated the question as it was understood by him. The Clerk, however, informs the Chair that, perhaps, there may be some misunderstanding this morning upon the question, and in order to have the question correctly put, the Chair would ask the gentleman who occupied the Chair last evening, to give his views of the state of the question. Will the gentleman be kind enough
                to state what the question is?
              </p>
              <div className="speaker" id="sp1658"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">I understood the Chairman to state the proposition as I understood it on yesterday evening. The Clerk furnished the Chairman with a copy of the report; I wrote down in that the additions to the amendment, as it was proposed to be made. The Clerk has the memorandum, and that will speak for itself.</p>
              <div className="speaker" id="sp1659"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair understood the proposition correctly. It will now re-state the question, to see whether the Chair understands correctly what the pending question is.</p>
              <p className="p-in-sp">These words, the Chair understands, are to be transposed, so as to cause that portion of the resolution to read—"when the Federal Constitution was formed, were, and still are, sovereign and independent sovereignties."</p>
              <div className="speaker" id="sp1660"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
              <p className="p-in-sp">Is it in order to offer an amendment to that amendment? I believe that amendment was accepted, and it is in order to amend it. I move, therefore, that the following amendment be added after the word "sovereignties."</p>
              <div className="speaker" id="sp1661"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It is in order.</p>
              <div className="speaker" id="sp1662"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">I beg leave, then, to offer it:</p>
              <p className="p-in-sp">"Over all powers not granted to the United States by the Constitution of the United States."</p>
              <div className="speaker" id="sp1663"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Does the gentleman propose to add that amendment to the amendment already pending?</p>
              <div className="speaker" id="sp1664"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">Yes, sir.</p>
              <div className="speaker" id="sp1665"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">
                If this amendment is adopted, that portion of the section will read, "when the Federal Constitution was formed, were, and still are, sovereign and independent States,<span className="note" id="Note56"><span className="noteLabel">2</span>This is evidently a mistake, and should read "sovereignties."</span> over all powers not granted to the United States by the Constitution of the United
                States."
              </p>
              <p className="p-in-sp">The question will be upon the amendment to the amendment.</p>
              <div className="speaker" id="sp1666"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">Is there any man in the United States that disputes the truth of that proposition?</p>
              <PageNumber num={520} />
              <div className="speaker" id="sp1667"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It is not for the Chair to answer.</p>
              <div className="speaker" id="sp1668"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I am merely referring to the fact, that the gentleman may see how unnecessary such a proposition is. If there be any gentleman who disputes the truth of that proposition, I never heard of him.</p>
              <p className="p-in-sp">There may be some Federalists in New England, who doubt its truth, but none others I imagine; and I do not see why we should be called upon to adopt that amendment. I submit, sir, that we ought not waste time upon this proposition, but proceed to take the question upon some amendment that has some tangibility in it.</p>
              <div className="speaker" id="sp1669"><span className="persName">Mr. R. Y. CONRAD—</span></div>
              <p className="p-in-sp">When I rose, yesterday, to object to the amendment proposed by the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>]
                , I contented myself with stating merely that the word "independence," as inserted there, was certainly incompatible with the real state of the relations between the State of Virginia and other States, and the Federal Government. I certainly had no expectation that that objection would lead to the debate which ensued upon it; and I did not refer, nor is it my purpose now to refer to
                this term, "sovereignty," which is used in that section. I am not to be led off in a debate upon any such Jack o' Lantern word as the word "sovereignty." As gentlemen seem to concede on all sides, it is a word which, has no precise or definite signification, and upon the application of which it is almost impossible to dispute. I was not disposed to question the fact that the people
                of the State of Virginia were, in some sense, a sovereign people, although I confess that I had in my mind an inclination that led me to the conclusion that the word is wholly inapplicable to any American institution. I cannot conceive of a sovereign without a subject. Now we call ourselves citizens.
              </p>
              <div className="speaker" id="sp1670"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Will the gentleman allow the Chair to state to him that the question now is upon the amendment to the amendment?</p>
              <div className="speaker" id="sp1671"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">I understand that. Sir, I suppose it is my right to discuss the whole amendment. I do not desire to trouble the Convention long; but I hope that in the few words which I wish to say, we may be enabled to come to a vote and to vote understandingly upon this proposition.</p>
              <p className="p-in-sp">As I was saying, sir, I mean not to follow in the lead of this discussion at all, because it leads to no definite or practical conclusion. I desire to call the attention of the Convention to one single consideration which has been stated by one or two gentlemen upon this floor, and which ought to be conclusive as to the vote upon this proposed amendment.</p>
              <p className="p-in-sp">
                If gentlemen will look at that section, they will see that the amendment <PageNumber num={521} /> proposed is a mere recital, leading to the conclusion which is drawn in the same section, and which is the only proposition intended to be affirmed, and that is the equality, the perfect equality of the States under our Federal Constitution.
              </p>
              <p className="p-in-sp">
                In order to lead to that conclusion, the resolution recites that when the Federal Constitution was formed these States were sovereign and independent States, and that was all which it was necessary to recite in order to deduce from it the conclusion, that having been sovereign and independent States and having agreed to form a common government for their mutual and joint benefit,
                they have still a right, under the operation of the Federal Constitution to insist that this perfect equality should be preserved.
              </p>
              <p className="p-in-sp">
                Now, sir, how is it proposed to amend that? To amend it by the insertion of the phrase that under the operation of the Federal Constitution the States are still sovereign and independent; or, as the amendment now makes it read, that they were independent sovereignties at the formation of the Federal Constitution, and that they still are sovereignties. Now, sir, I would ask this
                Convention where is the necessity—where is the propriety—where the relevancy of inserting this proposition in this section? Admitting that it is perfectly true, that it is unquestionable, why insert it any more than any truism? Does it not lead to the conclusion of this resolution? Is it necessary to demonstrate our right to perfect equality under the Federal Constitution, that we
                should insert here the mooted, or, if you please, the debated proposition that the States retain their sovereignties? What has it to do with the proposition in this section? And if nothing to do, if this section is not intended as a general declaration of rights, why, there would be the same propriety in inserting in toto the whole Bill of Rights of Virginia in this section as in
                inserting this declaration of the existing sovereignty of the State. And, if unnecessary I put it to this Convention—to all those who are voting in good faith, to carry out the views of the committee, and to carry out the views of the Convention and the views of the people of Virginia in order to make proper and definite demands upon the North—will they allow their proceedings to be
                embarrassed, their efforts to be frustrated and defeated by this game of inserting perfectly irrelevant and unnecessary matter by way of amendments in these resolutions? Now, sir, if gentlemen can show that there is any necessity or propriety why this Convention, in any way, should declare as to the existing sovereignty of the States of this Union under the Federal Constitution, let
                them offer it as a distinct proposition. It will not in any conceivable way affect the <PageNumber num={522} /> resolution which is now under the consideration of the Committee. I do not say that it was offered for the purpose merely of embarrassing the Convention in proceeding to pass upon the report of their Committee, but I will say that it can by no
                possibility have any effect than that. I trust, therefore, that this Committee, in voting upon this and upon any other amendments that are proposed, will look to the real character of the resolution to be amended, and see whether or no the proposed amendment really alters or amends in any way, the particular section proposed to be amended. If it does not, it cannot be moved for any
                direct or useful purpose. If there is any objection to any of these propositions—and I am free to say I have some objection myself to some of them and I expressed my dissent in Committee—why let the question be made upon it and directly. Let not this Committee lend itself to the attempt to defeat it by indirection.
              </p>
              <p className="p-in-sp">
                While I am up, Mr. Chairman, with the indulgence of the Committee, I would say one word in regard to another of these propositions which has been alluded to in the debate, and in which my proposition has been misconceived. I have never said, at any time ; I have never held in any way in approval of this disputed question of secession, the right of secession under the Federal
                Constitution or legal secession as it is sometimes called. Nor do I so understand the proposition of the Committee in their report here. It was an unnecessary declaration. There is nothing disaffirming it; there is nothing approving it. The affirmation is of the original right of any people; and, in the language of our original State paper, I hold the people of Virginia to be a free
                and independent people, except so far as they have voluntarily become dependent upon the Federal Government. I hold them to be free and independent, and, as such, having a right at any time to alter, reform or abolish their form of Government, whether State or Federal, subject to all their responsibilities to the people of the co-States, with whom they have made a compact of
                government. This is my position. And so the Committee will find that in this first section the position is simply that of equality of the States. There is nothing in that section whatsoever, as has been intimated by the gentleman from Princess Anne
                [<span className="persName">Mr. WISE</span>]
                , and other gentlemen upon this floor, that disaffirms State sovereignty. I desire that the mind of any gentleman who may have been misled upon this question shall be undeceived as to that. Let him look to that section. There is not one word or syllable in it which in any way conflicts with the opinion which may be held by him as to the entire and absolute sovereignty and
                independence of the States during the existence of the Federal Government. It does not contradict the <PageNumber num={523} /> opinion and it does not affirm the opinion. And the proposition now is to be put in that affirmation into the section unnecessarily, because it does not lead to the conclusion of the section, which is simply that the States have
                equal rights under the Federal Government.
              </p>
              <p className="p-in-sp">
                I desire not to abuse the patience of the Committee by making any other speech upon this subject, but I deem it important and necessary before the vote is taken upon it that the Committee should perfectly understand that this proposition has no connection with the proposition originally standing in the section which is under consideration, and that being the fact, whether it be true
                or false, it is unnecessary to insert it here by way of amendment.
              </p>
              <div className="speaker" id="sp1672"><span className="persName">Mr. WM. C. SCOTT</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, although I offered that amendment, and although I shall vote against the amendment as amended, because I am perfectly satisfied with the original resolutions, I do not think it is necessary that any amendment shall be made to the original resolutions, because I regard them as perfectly intelligible. Sir, the amendment offered by the gentleman from Gloucester was
                entirely unnecessary. It went on to state that the States were still sovereign. In some sense I admit that the States are still sovereign, and in some sense I deny that they are sovereign. They are sovereign over all powers retained. They are not sovereign, in my opinion, in regard to the powers granted to the Federal Government by the Constitution of the United States.
              </p>
              <p className="p-in-sp">Sir, what is the meaning of the word "sovereign"? My friend from Princess Anne [Mr. WISE] , yesterday said, he would not go to a schoolboy dictionary for the purpose of ascertaining the meaning of the word, but it seems that he and the gentleman from Richmond [<span className="persName">Mr. MACFARLAND</span>]
                differed widely in regard to its meaning. If gentlemen cannot agree in regard to the meaning of the word "sovereign," or "sovereignty," it is impossible that they could ever agree in opinion in regard to it. They might discuss it until doom's-day—they would be like the Scotchman who described metaphysics as two men talking together, one of whom did not know what he was talking about,
                neither did the other.
                [Laughter.] What is the meaning of the word "sovereign"? I understand it means supreme. What is the meaning of the word "sovereignty"? I understand it means supremacy. If these are not the definitions of the terms, I do not know what they are. Is the State of Virginia sovereign over the powers delegated to the General Government? I hold not.</p>
              <p className="p-in-sp">
                Mr. Chairman, I will not discuss the question whether the States were originally sovereign before they entered into articles of confederation. <PageNumber num={524} /> I grant that they were. The declaration of Independence of the United States declares that they were free, sovereign, and independent States. The King of England admitted that they were
                free, sovereign, and independent States. As such they entered into the articles of confederation. They did not deal directly with the people of the United States, nor with any of them. They dealt only with the States, as political communities. They had no power to lay taxes upon the people, under the pretence that they were executing any law. They had no power to try men for treason
                against it. They only dealt with States as political communities. But in the course of time it was found that a confederacy of this sort was not productive of any good to the country. The people of the United States, through their respective States, sent delegates to a National Convention, and agreed to form a compact with each other, by which, in my opinion, they agreed to become,
                one people for certain definite and specified purposes. They gave certain powers to the Federal Government, and in order to distinguish it from the old articles of confederation, they gave that Government power to act directly upon the people of the United States.
              </p>
              <p className="p-in-sp">
                The question with me is this: Did the Federal Government or the Constitution of the United States grant away certain powers to the Federal Government, and did it grant away those powers forever? If it granted away sovereign powers and granted those powers forever, I hold that then the States are not entirely sovereign. What power was granted away? The power of making war? Is not that
                a sovereign power? It is so stated by Vattel. The power of making peace; the power of making treaties; the power of making a citizen of the United States; the power of making a Navy; the power of maintaining an army? Are not these sovereign powers, sir? Were not these powers granted away by the States to the Federal Government? Has not the Federal Government the right to come into
                the State of Virginia and lay a tax upon you, upon me, and every one in this Convention, and has the State of Virginia the right to prevent it? Has the State of Virginia any more right to prevent the levying of taxes than the Federal Government has the right to prevent the State of Virginia from levying taxes upon the same people? They have not. Has not the Federal Government the
                right to come here and take one of your citizens and try him for an offence against the United States—take away his liberties if the case will justify it? There was a case of that sort, a short time ago, in my own county. A federal officer, without even consulting the State of Virginia in her political capacity, went into my county, took one of our citizens, brought him here to the
                city <PageNumber num={525} /> of Richmond, tried him before a federal tribunal without consulting the State in any way, and took away his liberty by convicting him of the offence alleged against him and sent him to the Penitentiary. If any of our citizens should be found in arms against the United States are they not guilty of treason and liable to be
                punished as such—and has the State of Virginia any more right to prevent the conviction of her citizens, if they be guilty of treason, than the United States would have a right to prevent the execution of an individual if he were tried and convicted by the State for treason against the State? The Federal Government had nothing to do with the States in their political capacity. They
                deal like State governments with individuals. The State acts upon them and the Federal Government acts upon them.
              </p>
              <p className="p-in-sp">
                But, Mr. Chairman, these powers have been granted. For how long and for what length of time have they been granted? Can they be resumed at pleasure? The preamble of the articles of the Confederation states that they were articles of confederation to perpetuate union among the States of New Hampshire, Virginia, &amp;c. Then, according to the preamble, it was not only declared to be a
                perpetual Union among the States, but in the last article it was so declared. Is this Union perpetual under the Constitution we now have? The preamble to this Constitution declares that "we, the people of the United States, in order to make a more perfect Union," &amp;c. If the old Union was perpetual, and this is a more perfect Union, is not this Union perpetual too?
              </p>
              <p className="p-in-sp">
                But I am told that the old articles of confederation were not perpetual, although they were declared to be such. It is true they were not; and so this government is not perpetual either, if it were altered in the manner indicated in the government itself. The old articles of the confederation were altered because the whole thirteen States agreed to the present Constitution of the
                United States. And this Constitution, this Union, can be altered, too, by three-fourths of the States. If, then, the powers granted to the Federal Government are to be regarded, as they are regarded by the law of nations, as sovereign powers, and if they be granted forever, then, sir, I hold that the State of Virginia, and other States, are not sovereign so far as regards the powers
                that are granted.
              </p>
              <p className="p-in-sp">
                Sir, I recollect that after the Constitution of the United States was adopted by some of the States, New York did not desire to come into the Union unless certain amendments were first made to the Constitution of the United States. Mr. Hamilton wrote to Mr. Madison to know if she could come in upon condition that if those amendments were not made, she should be at liberty to
                withdraw. Mr. Madison <PageNumber num={526} /> wrote back that she could not come in under any such condition; that if the State ratified the Constitution she must do it in toto and forever. So, I hold then, that the powers granted to the Federal Government were granted forever, and will not cease to be binding until they are altered by the power of the
                Constitution of the United States, which is the power specified in that Constitution.
              </p>
              <p className="p-in-sp">
                I have heard it said, Mr. Chairman, that there could be no divided sovereignty. I grant that, in one sense of the term, you cannot divide sovereignty, because there cannot be a supreme power over the States ; but, sir, is it at all singular or inconsistent that there should be a supreme power over certain classes of subjects? Is it inconsistent that the United States should have
                supreme power over one class of subjects, and that the States should have supreme power over another class? My friend from Princess Anne
                [<span className="persName">Mr. WISE</span>] , told us yesterday that Louis Napoleon looked down with as much contempt upon some of the sovereigns of Europe as a gentleman would look down upon his lackey. That may be true. I hold, in the language of the gentleman from the city of Richmond [<span className="persName">Mr. MACFARLAND</span>]
                , that powers, so far as authority over them is concerned, are sovereign. Queen Victoria is sovereign over the power to make war, but she is not sovereign over the power to appropriate money. Louis Napoleon is sovereign over the power to make war and appropriate money; but I hold that Queen Victoria is as much sovereign over the power to make war as Louis Napoleon is.
              </p>
              <p className="p-in-sp">Sir, we are told that a person cannot have a divided allegiance. I saw in a newspaper the other day that Senator Mason declared that he owed no allegience to the General Government of the United States, and my friend from Middlesex [<span className="persName">Mr. MONTAGUE</span>]
                , said the same thing here, I believe. I hold that every man in this Convention has two characters—he is a citizen of the State of Virginia and also a citizen of the United States. There are such persons as citizens of the United States. The Supreme Court in the Dred Scott decision declared that persons of African descent were not citizens of the United States, but there are some
                persons who are citizens of the United States, if they are not of African descent. The Constitution of the United States gives Congress the power to pass uniform laws of naturalization. A foreigner comes here and takes an oath of allegiance to the United States. By that oath he becomes a citizen of the United States. Is he any more a citizen of the United States than any one of us
                who has not taken that oath of allegiance? Are we not citizens without taking that oath? Sir, cannot we commit treason against the United States? Judge Marshall, in the case of Aaron Burr, decided that if two or more persons <PageNumber num={527} /> join together and by force of arms prevent the execution of the laws of the United States, those persons
                are guilty of treason. And yet, although my friend from Middlesex
                [<span className="persName">Mr. MONTAGUE</span>] , as I understand, said he did not owe allegiance to the United States; it is certain that he can commit treason against the United States. I hold, then, Mr. Chairman, that there is such a thing as being a citizen of the United States, and I hold again that a portion of the sovereign power of the State has been transferred to the United States.</p>
              <div className="speaker" id="sp1673"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">I will ask the gentleman from Powhatan if the Senators representing the thirty-four States were to withdraw from Congress, and those States refused to send others in their places, where would the sovereignty be of the Federal Government?</p>
              <div className="speaker" id="sp1674"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">
                O, sir, if all of them should withdraw and others were refused to be sent, why the sovereignty, I hold, is not in the Federal Government although it is said it may be in a Government. My notion, sir, is, that it is in three-fourths of the States that the power of the United States resides. If three-fourths of all the States unite in the manner prescribed by the Constitution of the
                United States they can alter or abolish that Constitution ; and, therefore, if all of them unite for that purpose, they can do so likewise.
              </p>
              <p className="p-in-sp">
                Sir, here I am reminded of that part of the preamble of the ratification of the Constitution of the United States by the State of Virginia, from which some gentlemen seem to derive the right of secession. Now, sir, I hold that that preamble only declares the right of revolution, just the same as the Declaration of Independence or the Bill of Rights of Virginia declares it. It
                declares, that when the Government of the United States shall fail to perform the duties imposed upon it and expected of it, that the people—not of a State—but that the people of the United States have a right to alter or abolish it. It is the right of revolution, sir, laid down in the Declaration of Independence and the Bill of Rights of Virginia.
              </p>
              <p className="p-in-sp">
                What next, Mr. Chairman? It is true, sir, that I deny the right of a State to secede. There is, I admit, a sovereign right to throw off allegiance to the Federal Government—a revolutionary remedy. Does that make any difference? I would as soon walk out of this Union, if I had cause to do so—although I think it is only a revolutionary remedy—as if the power were recognized in the
                Constitution of the United States. But if it were in the Constitution of the United States, a State ought not to withdraw except for sufficient cause. If it be a revolutionary remedy, she ought not to withdraw except for good cause; but she ought to withdraw if she has good cause to do so. And I say, sir, that <PageNumber num={528} /> whenever the State
                of Virginia shall think proper to withdraw from the Union, although, in my estimation, it is a revolutionary remedy, and although a person who raises arms against the United States would be considered guilty of treason, yet I am one of those who intend to be guilty of that treason. Treason is a crime or a glory, according to the notion of those who commit it. It was a crime in
                Catiline ; it was a glory in Washington; and I hold that every man who commits treason in this last sense, does it in obedience to his State and his people.
              </p>
              <div className="speaker" id="sp1675"><span className="persName">Mr. GOODE</span>, of Mecklenburg, said:</div>
              <p className="p-in-sp">
                Mr. Chairman, I have listened attentively, patiently, with much pleasure, and I trust with much profit, to the many able and distinguished gentlemen, who have preceded me in the discussion of the various questions now under the consideration of this Committee—questions deeply involving the interests and the feelings of those I represent upon this floor, and of the whole people of
                that great Commonwealth which we all venerate and love. I am aware, sir, that in entering myself upon that discussion, that I enter a field from which no laurels can be borne by me. And, sir, did I consult my own inclination, I should confine the expression of any opinion which I may entertain upon the various questions under the consideration of the Committee, to a simple response,
                upon the call of the ayes and noes. Feeling, however, that those whose representative I am, have a right to expect and to require of me the exercise of all my powers, feeble though they be, in the vindication of what I conceive to be their rights and honor, I shall not shrink from the task—nor shall I offer any apology, for trespassing upon the time of this body.
              </p>
              <p className="p-in-sp">Sir, the great question which is now uprooting this Government to its foundation—the great question which underlies all our deliberations here, is the question of African slavery. The distinguished gentleman from the county of Augusta [<span className="persName">Mr. BALDWIN</span>]
                , who addressed us a few days since, told us that upon this question of slavery, the action of the Federal Government had been either sanctioned at the time or subsequently ratified by the people of the State of Virginia, from the foundation of the Government to the election of Abraham Lincoln, and that we, of Virginia, were, therefore estopped to complain of the action of the
                Federal Government upon that subject.
              </p>
              <p className="p-in-sp">Sir, my Lord Coke says, that estoppels are odious; and if odious in a court of law, sitting on judgment upon petty questions of meum and tuum, and of dollars and cents, how odious must they be before the sovereignty of a State, deliberating upon its great and vital interests? Sir, the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>]
                , certainly displayed his skill as a lawyer, in resting his cause upon the plea of estoppel; for <PageNumber num={529} /> a party of the right to set forth the truth of his case.
              </p>
              <p className="p-in-sp">Sir, I shall demur to the plea of that gentleman. I shall require him to put in the plea of Not Guilty, and upon that, sir, I shall join issue with him, and upon that issue go with him before the people of the country.</p>
              <p className="p-in-sp">
                We are told that Virginia ratified the Missouri restriction of 1820; that she ratified the compromise of 1850, the one robbing her of all right to participate in the North-western Territory, north of 36 deg. 30 min.; the other robbing her of all right to participate in the great and wealthy domain of California; a domain acquired in part, at least, by her treasure and the valor of
                her sons. But, sir, did Virginia ratify those compromises as the full measure of her Constitutional rights? Did she ratify them as the full measure of justice? No sir! They were concessions extorted, rather from her patriotic devotion to the Union. Tell me then, sir, has she no right to complain of that want of regard for Constitutional compact? No right to complain of the want of
                that spirit of justice and fraternity which made those unholy and iniquitous demands upon her?—which presented to me, the alternative of submission to wrong, or the destruction, perchance, of the Government for which her sons had poured out their blood like water upon the battlefields of the country, and in the formation of which they had contributed so largely in council? Sir, this
                argument of estoppel, of the gentleman from Augusta, is, to my mind, the strongest argument that I have heard upon this floor for the prompt and decisive action of the people of Virginia. If each time we submit to a violation of our constitutional right, the duty is imposed upon us of yet further to submit—if each time we submit to aggression, the duty is imposed upon us of still
                further submission, then, sir, it is high time that every freeman in the Commonwealth—aye, that this great Commonwealth itself, should resolve that it will submit to violations of right and to aggressions no longer.
              </p>
              <p className="p-in-sp">
                But, sir, I would remind the gentleman from Augusta that it is not only from the Federal Government, as a Government, that we desire to escape, and with which we would dissolve our connexion, but that we would dissolve our connexion with that Government, as the common agent of a people a portion of whom have proven faithless to the covenants of the Constitution. Have we no cause to
                complain of the Northern people? Their statute books are covered over with Personal Liberty bills. Our State has been invaded, and the blood of our unoffending citizens has been shed by a sectional party organization.
              </p>
              <PageNumber num={530} />
              <p className="p-in-sp">Northern Governors have refused to surrender to the demands of Virginia justice, the perpetrators of that unexampled outrage against the sovereignty of a State.</p>
              <p className="p-in-sp">
                There is now a wide-spread conspiracy in many of the Northern States, not only against the peace and security of Virginia, but against the whole of the Southern States. Will the gentleman from Augusta tell us that we are estopped to complain of these things? Will he tell us that we have in any way participated in, sanctioned or ratified them? If then, sir, it were true that we had no
                cause to complain of the action of the Federal Government, what would that avail so long as the Northern States, as parties to the Governmental compact, are faithless to its highest and holiest obligations—so long as they systematically, persistently and by every means within their power, hinder and obstruct the enforcement of the Constitution and the laws of Congress passed in
                pursuance thereof? Sir, I submit, if the public sentiment at the North has not become stronger, more powerful than the Constitution? And if the Federal Government has not proven a failure? If it has not become powerless for the protection of our rights?
              </p>
              <p className="p-in-sp">
                That gentleman told us that the election of Abraham Lincoln to the Chief Magistracy of the United States constituted, in his opinion, no just cause for the withdrawal of the Cotton States from the Union; that it only imposed upon the Southern people the duty of standing by the defences of the Constitution. Sir, they have stood by the defences of the Constitution from the very
                foundation of the Government. They have stood upon the very battlements of the Constitution, and, time after time, have repelled the assaults of superior numbers. They stood around the great South Carolinian as in stern and defiant tones he warned our assailants against further aggression.
              </p>
              <p className="p-in-sp">
                They rallied around the great Kentuckian, as in thoughts that "breathed and words that burned," he plead the cause of his country, as he plead with Northern fanaticism and ambition, not to lay the destroying hand upon the Constitution of the country; they stood by that sacred instrument, until they saw elevated to place and power, a party having inscribed upon its revolutionary
                banner, the doctrine of the higher law, and then they turned ,away to take their destiny into their own hands. And do they deserve to, be reproached for this? As well may you reproach the sailors, who day after day, and night after night, had stuck to their gallant bark, who had done all that men could do to carry her safely into port, because, when going down amid the devouring
                element, they launched the life boat and put out to sea. Sir, the same feelings impelled our Southern brethren, when <PageNumber num={531} /> they found the ship of State going down amid the breakers of Republicanism and abolitionism. It was only when hope was gone—only when they must go down with it or leave it—that they left the defences of the
                Constitution—only then, sir, that they launched the political life boat to take the last chance for preservation. Sir, I thank God they have reached the land in safety. I thank God that from the Capitol at Montgomery, is heard the watchman's cry that "all is well."
              </p>
              <p className="p-in-sp">But, sir, I shall not interpose my opinion upon this subject in opposition to that of the distinguished gentleman from Augusta. I will, however, venture to interpose that of a great conservative statesman of the country, Millard Fillmore. I will trouble my friend from Monroe [<span className="persName">Mr. ECHOLS</span>] , to read from a speech made by Mr. Fillmore, at Albany, in 1856.</p>
              <div className="speaker" id="sp1676"><span className="persName">Mr. ECHOLS</span> read as follows:</div>
              <p className="p-in-sp">
                "But this is not all, sir. We see a political party presenting candidates for the Presidency and Vice Presidency for the first time from the free States alone, with the avowed purpose of electing these candidates by the suffrages of one part of the Union only, to rule over the whole United States. Can it be possible that those who are engaged in such a measure, can have seriously
                reflected upon the consequences, which must inevitably follow in case of success? can they have the madness or the folly, to believe that our Southern brethren will submit to be governed by such a Chief Magistrate? would he be required to follow the same rule prescribed by those who elected him, in making his appointments? If a man living south of Mason and Dixon's line be not worthy
                to be President or Vice-President, would it be proper to select one from the same quarter to be one of his Cabinet Council, or to represent the nation at a foreign court, or, indeed, collect the revenue, or administer the laws of the United States? If not, what new rule is the President to adopt, in selecting men for office, that the people themselves discarded in selecting him?
                These are serious but practical questions, and in order to appreciate them fully it is only necessary to turn the tables upon ourselves. Suppose the South, having a majority of the electoral votes, should declare that they would have slave holders for President and Vice-President, and elect such by their exclusive suffrages to rule over us at the North. Do you think we would submit
                to it. No not for a moment. And do you believe that your Southern brethren are less sensitive on this subject than we are? If you do, let me tell you, you are mistaken; and, therefore, you must see that if this sectional party succeed, it leads inevitably to the destruction of this beautiful <PageNumber num={532} /> fabric reared by our fathers, and
                bequeathed to us as a priceless inheritance."
              </p>
              <p className="p-in-sp">
                Thus, sir, we have the declaration of this great conservative statesman and patriot of the North; one who has himself presided over the destinies of the nation; one who has deserved well of his country, that the election of sectional candidates to the Presidency and Vice Presidency, by a strict sectional vote, is a sufficient cause for a severance of the bonds of the Union. We have
                his declaration, that the people of the North would not submit, for a moment, to be governed by Southern men thus elected. How much more powerful then, sir, the reason why the South should not submit to be governed by Northern men elected by a strictly sectional vote. The Government in the hands of Southern men, upon whatever principle elected, is powerless to invade, in any way, the
                social organization of the Northern States. It can never plant the foot of a slave on free soil. On the other hand, however, the power of the Government, in the hands of a Northern sectional party, may shake Southern society to its very foundations. The gentleman from Augusta told us, that the founders of our Government had built up five distinct barriers against encroachments upon
                the rights and liberties of the people—that if one or two failed, others would remain sufficient for the accomplishment of that end; and that, should all fail, he would then make his appeal from faithless servants to their masters, the people. To what people, sir, would he make his appeal, I respectfully ask? To those who had elevated to place and power those proclaiming the doctrine
                of the higher law, and a purpose to violate the sanctities of the Constitution and the rights of the South?
              </p>
              <p className="p-in-sp">
                Sir, the public men of the North are fair exponents of the popular sentiments of the North. If this be not so, if they are not, why is it that we see such men as Seward and Chase, and Sumner, Wilson and Hale, Wade and Lovejoy, and a host of kindred spirits, revilers and calumniators of Southern men and Southern institutions—scoffers at the compacts of the Constitution—retained in the
                public service year after year? Sir, the question needs no answer. No where in the history of popular governments have any people persistently re-elected those to places of trust and responsibility differing from them upon great and vital governmental principles.
              </p>
              <p className="p-in-sp">
                But, Mr. Chairman, the gentleman from Augusta regards the Executive as far the weakest department of the Government. To have heard that gentleman, one might have supposed that the President of the United States wielded scarcely the power and influence of a presiding justice of a county court; that it was an office entirely powerless for
                <PageNumber num={533} /> harm; yet in the next breath he enumerated, as one of the great checks and safeguards of the Government, the veto power.
              </p>
              <p className="p-in-sp">
                Sir, I would remind the gentleman that the power, which he correctly states may be so potent for good may be equally potent for evil. I would remind him that the veto power in the hands of a Republican President, might paralyze the action of both branches of the national legislature; that it might be made an efficient instrument to crush out and to destroy conservative sentiment and
                national legislation; that with the most conservative Congress it might stop the machinery of Government and break down the prosperity of the country. Sir, in 1840, the great statesman of Kentucky wrote a letter in which he laid down six propositions, and among them was one pronouncing the veto power, a power arbitrary and dangerous to the liberties of the people, and demanding the
                abolition of that power; and, if I mistake not, the gentleman from Augusta endorsed those views and opinions which were incorporated into the policy of the Whig party in 1844.
              </p>
              <div className="speaker" id="sp1677"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">If the gentleman will allow me, I desire to say that so far from acceding, I stood out uniformly, consistently in favor of the veto power as a great conservative element of our Government for the preservation of Southern rights.</p>
              <div className="speaker" id="sp1678"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
              <p className="p-in-sp">
                I stand corrected. It was nevertheless the opinion of Henry Clay, it was nevertheless engrafted upon the policy of the old Whig party, it was one of the great issues of the memorable canvass of 1844. In that canvass it was denounced by the Whig party, as a party, as one dangerous to the liberties of the people. It was one of the great issues of that canvass. But now we are to be
                told, that that power in the hands of a Republican President, is one of the checks and balances that Southern men are to look to, for the preservation of their Constitutional rights. I think I have shown that it may become destructive of Southern rights.
              </p>
              <p className="p-in-sp">
                But, sir, the gentleman from Augusta told us that the cotton States, instead of withdrawing from the Confederacy, might have stopped the wheels of Government, by stopping the appropriation of material supplies. I can tell the gentleman that the wheels of Government are not like the cog-wheels of grist-mills and saw-mills. They cannot be stopped and put in motion at pleasure. They
                cannot be stopped without results, wide-spread and disastrous. Stop the wheels of this Government for our protection!
              </p>
              <p className="p-in-sp">
                Let us suppose, sir, the Southern States to have adopted this plan, for the purpose of extorting from a Republican Administration a recognition of Southern rights; what would have been the result? Perhaps <PageNumber num={534} /> I may be told that it would have compelled the Administration to a concession of our just demands. Sir, they have seen the
                Government dissolved, and they have conceded nothing; they have seen it threatened with the horrors of civil war, and they have yielded nothing. They see our commerce declining, they see financial embarrassment almost without a parallel; they hear the complaints, the murmurs and cries of a distressed people, yet they stand indifferent and stolid upon the Chicago platform, refusing
                all compromise and all concession—and, I imagine, sir, that they would have conceded no more, had the plan suggested by the gentleman from Augusta been adopted. Had it been adopted, I repeat, what would have been the result? In a little while you would have seen your members of Congress dispersed for want of pay. It is said they do not work all for love. You would have seen your
                Federal Judiciary retire from the Courts—you would have seen your District Attorneys retire from their positions—you would have seen the whole mail intercourse of the country cut off—you would have seen your navy and army officers retire—you would have seen your public works everywhere stopped—you would have seen the public employees everywhere turned out to starve—you would have
                seen commerce destroyed—in short, sir, you would have seen national and individual bankruptcy and ruin—you would have seen a state of things under which no human power could have averted a revolution, more formidable and more fearful in its consequences than that which now surrounds us, and which we now contemplate. Sir, I have a profound respect for the distinguished gentleman from
                Augusta as a lawyer, but if this be his prescription for a diseased political body, I must say that I have but little respect for his pretensions as a doctor. I know of no principle upon which the gentleman suggested this remedy. Let the gentleman stop the wheels of this government when he will, and for what purpose he will, and he will never see them put in motion again. The wheels
                of this government once stopped, and they turn no more forever. Then, sir, the Southern States are told, what? That they should have remained in the government, and have pulled it down over their own heads, to crush out and destroy the prosperity and happiness of their own people. I submit, sir, if our gallant brethren of the cotton States did not adopt a wiser course? If they did
                not evince a wiser foresight, and a more prudential regard, not only for their own best interests, but for those of the whole country? But, sir, I would remind gentlemen that what the cotton States should or should not have done, is not the question for our consideration. It is said that Omnipotence cannot recall the past. Certain it is that we cannot recall
                <PageNumber num={535} /> the action of those States. We have to act, and must act in view of the circumstances which now surround us. We may denounce the cotton States, yet we are compelled to act, in view of the fact that those States no longer form a part of this government.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, the gentleman from Augusta, told us that we had invaded the Northern people, that we had invaded their schools of philosophy and theology, and that we had raised up friends for the South. He told us that the Republican party, in order to retain a respectable number, had had to back down from their abolition sentiment and proclivities. Sir, a brief review of the rise and
                progress of that party, will show the foundation for that declaration. The abolition party, as a political party, first made its appearance before the country, in 1840, with James G. Birney, of Michigan, as its candidate for the Presidency, and Francis J. Lemoyne, of Pennsylvania, as its candidate for the Vice-Presidency. They received 7,000 votes. In 1844, with Birney again as a
                candidate, that party polled 62,140 votes. In 1848, Martin Van Buren, was the candidate for President, of the Buffalo Convention, and Gerrit Smith the candidate of the more ultra abolition party, and they both polled 296,232 votes. In 1852, with John P. Hale as its candidate, it polled 157,296 votes. In 1856, the candidate of the Republican party, John C. Fremont, supported by the
                whole Republican party, received 1,341,812 votes. In 1860, Abraham Lincoln and Hannibal Hamlin, were elected President and Vice-President of the United States, by a strict abolition vote of 1,865,840 votes. If this be the result of Southern invasion of Northern schools of philosophy and theology, the sooner the South withdraws her invading army, the better. Yet the gentleman from
                Augusta, told us, that they had backed down from their abolition doctrines. Sir, let the declarations, fresh from the lips of the great leading men of the Republican party, answer the gentleman.
              </p>
              <p className="p-in-sp">In a pamphlet copy of Mr. Lincoln's speeches I find the following. I will trouble my friend from Monroe [Mr. ECHOLS] , to read for me. <span className="persName">Mr. ECHOLS</span> read as follows:</p>
              <p className="p-in-sp">"I did not say that I desired that slavery should be put in a course of ultimate extinction, I do say so now, however, so that there can be no longer any difficulty about that.</p>
              <p className="p-in-sp">"It may be written down in the great speech.</p>
              <p className="p-in-sp">
                "I have always hated slavery, I think, as much as any abolitionist. I have been an old line Whig. I have always hated it, but I have always been quiet about it until this new era of the introduction of the <PageNumber num={536} /> Nebraska bill began. I always believed that everybody was against it and that it was in a course of ultimate extinction.
              </p>
              <p className="p-in-sp">
                "We are now far into the fifth year since a policy was initiated with the avowed object and confident promise of putting an end to slavery agitation. Under the operation of that policy, that agitation has not only not ceased, but has constantly augmented. In my opinion, it will not cease until a crisis shall have been reached and passed. 'A house divided against itself cannot stand.'
                I believe this Government cannot endure permanently half slave and half free. I do not expect the Union to be dissolved. I do not expect the house to fall, but I do expect it will cease to be divided. It will become all one thing or all the other. Either the opponents of slavery will arrest the further spread of it and place it where the public mind shall rest in the belief that it
                is in the course of ultimate extinction or its advocates will push it forward till it shall become alike, lawful in all the States, old as well as new, North as well as South."
              </p>
              <p className="p-in-sp">Extract from a letter from Mr. Lincoln to the Republicans of Boston, dated April 6th, 1859:</p>
              <p className="p-in-sp">"This is a world of compensation, and he who would be no slave must consent to have no slave. Those who deny freedom to others deserve it not for themselves, and under a just God cannot long retain it."</p>
              <p className="p-in-sp">Extracts from the speeches of Wm. H. Seward :</p>
              <p className="p-in-sp">"Free labor has at last apprehended its rights and its destiny, and is organizing itself to assume the government of the Republic. It will henceforth meet you boldly and resolutely here [Washington] . It will meet you every where in the territories and out of them, wherever you may go to extend slavery. It has driven you back in California and in Kansas. It will invade you soon in Delaware, Maryland, Virginia, Missouri and Texas. It will meet you in Arizona, in Central America and even in Cuba."</p>
              <p className="p-in-sp">Not content with confining it to the territories, he adds :</p>
              <p className="p-in-sp">
                "You may indeed get a start under or near the tropics and seem safe for a time, but it will be only a short time. Even there you will find States only for free labor to maintain and occupy. The interest of the white race demands the ultimate emancipation of all men. Whether that conservatism shall be allowed to take effect with needful and wise precautions against sudden change and
                disaster, or be hurried on by violence, is all that remains for you to decide. The white man needs this continent to labor upon. His head is clear, his arm is strong and his necessities are fixed. It is for yourselves and not for us to decide how long and through what further modifications and disasters the contest <PageNumber num={537} /> shall be
                protracted, before freedom shall enjoy her already assured triumph. You may refuse to yield it now and for a short period, but your refusal will only animate the friends of freedom with the courage and the resolution, and produce the union among them which alone are necessary on their part to attain the position itself, simultaneously with the impending overthrow of the existing
                Federal Administration, and the constitution of a new and more independent Congress."
              </p>
              <p className="p-in-sp">
                In a speech made at Cleveland, in 1848, Mr. Seward said, "Wherein do the strength and security of slavery lie? You answer that they lie in the Constitution of the United States, and in the constitution and laws of the slaveholding States. Not at all. They lie in the erroneous sentiment of the American people. Constitutions and laws can no more rise above the will of the people than
                the limpid stream can climb above its native spring. Inculcate, then, the tone of freedom and the equal rights of man under the paternal roof. See to it, that they are taught in the schools and in the churches. Reform your own code; extend a cordial welcome to the fugitive who lays his weary limbs at your door, and defend him as you would your paternal gods. Correct your own
                error—that slavery has any constitutional guarantee which may not be released and ought not to be relinquished."
              </p>
              <p className="p-in-sp">And he says further on, "whenever the public mind shall will the abolition of slavery the way will open for it."</p>
              <p className="p-in-sp">Again he says:</p>
              <p className="p-in-sp">"Slavery can be limited to its present bounds. It can be ameliorated. It can and must be abolished, and you and I can and must do it."</p>
              <p className="p-in-sp">On another occasion, speaking of the abolition of slavery, he uses the following language :</p>
              <p className="p-in-sp">
                "The task is as simple and easy as its consummation will be beneficent and its rewards glorious. It requires to follow only this simple rule of action : To do everywhere and on every occasion, what we can, and not to neglect or refuse to do what we can, at any time, because at that precise time and on that particular occasion we cannot do more. Circumstances determine possibilities."
              </p>
              <p className="p-in-sp">Henry Wilson, now a United States Senator, from the State of Massachusetts, said in a public speech :</p>
              <p className="p-in-sp">"Let us remember that more than three millions of bondmen, groaning under nameless woes, demand that we shall cease to reprove each other, and that we labor for their deliverance."</p>
              <p className="p-in-sp">"I tell you here to-night, that the agitation of this question of human slavery will continue while the foot of a slave presses the soil of the American republic."</p>
              <PageNumber num={538} />
              <p className="p-in-sp">The Hon. Anson Burlingame, now Minister to Austria, by the appointment of Abraham Lincoln, says:</p>
              <p className="p-in-sp">
                "If asked to state specially what he would do, he would answer: First, repeal the Nebraska bill; second, repeal the fugitive slave law; third, abolish slavery in the District of Columbia; fourth, abolish the inter-State slave trade; next, he would declare that slavery should not spread to one inch of the territory of the Union; he would then put the government actually and
                perpetually on the side of freedom—by which he meant that a bright-eyed boy in Massachusetts should have as good a chance for promotion in the navy as a boy of one of the first families in Virginia. He would have our foreign consuls take side with the noble Kossuth, and against that butcher Bedini. He would have judges who believe in a higher law, and an anti-slavery Constitution, an
                anti-slavery Bible, an anti-slavery God! Having thus denationalized slavery, he would not menace it in the States where it exists; but would say to the States, it is your local institution—hug it to your bosom until it destroys you. But he would say, you must let our freedom alone. If you but touch the hem of the garment of freedom we will trample you to the earth. This is the only
                condition of repose, and it must come to this. He was encouraged by the recent election in the North, and he defended the 'new movement,' which he said was born of Puritan blood, and was against despotism of all kinds. This new party should be judged, like others, by its fruits. It had elected a champion of freedom to the United States Senate for four years, to fill the place of a
                man who was false to freedom, and not true to slavery. For himself, he could say that, so long as life dwelt in his bosom, so long would he fight for liberty and against slavery. In conclusion, he expressed the hope that soon the time might come when the sun should not rise on a master nor set on a slave."
              </p>
              <p className="p-in-sp">Then, sir [said Mr. GoonE]
                , we have here the declaration of the President elect that he desires the abolition of slavery. We have the declaration that he hates slavery as much as any Abolitionist. We have his declaration that he believes slavery to be in the progress of ultimate extinction, and that the slavery agitation would never be put to rest, until that institution was put in the course of ultimate
                extinction. We have the declaration of his Premier that free labor, in other words abolition fanaticism is to invade our institutions, wherever they may take root—wherever a slave shall tread. We have his declaration that it shall thus invade us upon the soil of Virginia. And, sir, while that prophecy was fresh upon the lips of Wm. H. Seward, fanaticism did invade the sovereignty of
                Virginia and strike down the peaceful citizens <PageNumber num={539} /> of the Commonwealth reposing under her constitution and her laws. The remainder of that prophecy is yet to be fulfilled, but will as surely be fulfilled as the sun now rolls above us.
              </p>
              <p className="p-in-sp">
                Wilson demands the agitation of the slavery question so long as the foot of a slave shall press the soil of the American Republic. Burlingame would have judges who believe in a higher law. He would have an anti-slavery Bible, and an anti-slavery God. He looks forward to the time when the sun shall not rise upon a master or set upon a slave. With these sentiments upon their lips and
                in their hearts, these men have been elevated to the high places of the nation.
              </p>
              <p className="p-in-sp">
                We have an Abolitionist at the head of the Government—surrounded by Abolitionists as advisors. We have Abolitionists sent as Ministers to foreign courts. We have Abolitionists at the head of all the important committees in the Senate of the United States. We have Abolitionists in all the subordinate Departments of the Government. And this the gentleman from Augusta thinks is a
                backing down of the Republican party from their abolition proclivities and sentiments. Sir, if that be so, God save us from any farther backing down of the Republican party!
              </p>
              <p className="p-in-sp">
                The gentleman from Augusta told us, and told us with an air of triumph, as though that concluded the question, that the Republican members of Congress were willing to incorporate into the Constitution a provision prohibiting the interference by the Federal Government with slavery in the States. What, I ask would that provision be worth if placed there? Would it be more sacred than
                the provision for the return of the fugitive slave to his master? No, sir; yet we have seen that provision violated—we have seen it spurned and trampled on by the Republican party. I tell the gentleman from Augusta, that men who do not regard one provision of the Constitution—who do not regard the whole as sacred, will regard none of its provisions or its parts.
              </p>
              <p className="p-in-sp">
                I will tell the gentlemen another thing, and I will quote from Mr. Seward to show, that in this Republican Government no Constitution can prove a barrier to a hostile public sentiment. Why has not the fugitive slave law been executed. Is it because it is not plainly engrafted upon the Constitution of the country? No, sir; but because the public sentiment of the North is opposed to
                it—because that public sentiment, is too strong for the Constitution. The Constitution has gone down before it. Sir, I call the attention of the House to what Mr. Seward has said upon this subject, in the Senate of the United States. Mr. Seward says, that "a Republican Government like this, notwithstanding all its constitutional checks, cannot long resist, or counteract the progress
                of society."
              </p>
              <PageNumber num={540} />
              <p className="p-in-sp">
                Sir, the Constitution, construed by the Republican party, can never afford a barrier, a protection to the South, against aggression—Mr. Seward was right. He uttered a great truth when he told us substantially, that no constitutional check, no constitutional barrier could long protect us against the encroachment of the North. And he told us in his Rochester speech, that for this
                reason all the compromises that had been attempted to be patched up between the South and the North, had proved ephemeral and delusive—that popular sentiment would override these constitutional checks and balances.
              </p>
              <p className="p-in-sp">
                With regard to the propositions of the Peace Conference, I have before declared my views in detail, and I have no desire or purpose to review them now. The gentleman from Augusta told us that the propositions of the Peace Conference covered over all the ills of which the South complained, as with a blanket. Sir, he arraigned the Virginia Senators for their refusal to vote for the
                submission of the propositions of the Peace Conference to the people. He told us that before we undertook to settle with Seward and Chase and those Northern men, we should settle with our Virginia Senators. And, strange to tell, the very next day, or perhaps two days after, when these Peace Conference propositions, which covered all our ills "as a blanket"—I think the gentleman
                said—were offered as a substitute for the report of the Committee of Twenty-one, that gentleman voted against the propositions of the Peace Conference.
              </p>
              <div className="speaker" id="sp1679"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                The gentleman will allow me to say that he is entirely under a misapprehension as to my voting against the propositions of the Peace Conference. If they were submitted now as independent propositions, as a satisfactory settlement of these difficulties as far as constitutional provisions can settle them, I would, to-day, unhesitatingly vote for them. The other day I voted for a plan
                which I think is an amendment to the Peace Conference propositions, and in approving that proposition, I did not resort to the expedient which was resorted to a day or two since by those with whom the gentleman acts upon this floor, who, when the constitution of the Southern Confederacy was before the House, retired outside the bar and did not vote at all.
              </p>
              <div className="speaker" id="sp1680"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
              <p className="p-in-sp">
                Mr. Chairman, if these Peace Conference propositions be the great panacea, the gentleman would have us believe, for all the ills that afflict the country; if it would leave us nothing to complain of, why is it that the Committee, with which the gentleman acted, did not report those propositions here, as their own propositions, to go forth to the people of Virginia? Sir, those
                gentlemen best know why. But the gentleman told us that they were better <PageNumber num={541} /> than the Crittenden propositions. If that be true, it is much to be regretted they were not submitted to the people, for, throughout the breadth and length of the State of Virginia, in the late canvass, I heard but one sentiment, and that was, that the
                Crittenden propositions would be acceptable and satisfactory to the people of Virginia. But, sir, it seems the Legislature of Virginia did not know what we wanted; that they did not know what were proper guarantees and guards of Southern rights. They sent to the Peace Conference demanding the adoption of the Crittenden propositions as a basis of settlement satisfactory to Virginia,
                but our generous friends, the Black Republicans, gave us, in that Peace Conference, propositions something better, something more than we demanded. Sir, to say the least of it, this is counter to the course which they have heretofore pursued towards us.
              </p>
              <p className="p-in-sp">
                Our Senators are arraigned. Yes, sir, while standing upon the battlements of the remnant of the Constitution of the country; while battling for Virginia's rights and Virginia's honor; while interposing their arms to stay the storm which threatens to burst upon us; while standing, as faithful sentinels, upon the watch-tower upon which they were placed by the people of Virginia,
                gentlemen of this Convention arraign them and demand a settlement. I am not surprised that the majority of this Convention should want a settlement with the Virginia Senators.
              </p>
              <p className="p-in-sp">
                I have no doubt that when the time for settlement comes, there will be many gentlemen here who will be very willing to step into the shoes of the Virginia Senators. It may be, for aught I know, that under the clamor which will go forth from this Convention, the Virginia Senators may go down; but, sir, they will go down, fighting the battles of the people of Virginia, and I would
                rather be one of those Senators going down than be the gentleman who rises on his ruins.
              </p>
              <p className="p-in-sp">Sir, our gallant Senators could fall upon no field more worthy of the patriot statesman; but stand or fall, there will be</p>
              <div className="lg"><div className="l">"The sweet rewards that decorate the brave"—</div></div>
              <p className="p-in-sp">rewards, not the less sacred to the statesman than to the soldier.</p>
              <p className="p-in-sp">But, sir, I believe that whatever may be the sentiment of the Convention, the great heart of the Commonwealth will, when the test comes, throb in approbation of the course of our Virginia Senators. If ever public men deserved to be greeted with a well done, good, and faithful servant, it is the Virginia Senators.</p>
              <p className="p-in-sp">I desire to say a very few words in regard to the majority report <PageNumber num={542} /> of the Committee of Twenty-one. I do not propose to examine that report in detail. I find in it two, to me, unsuperable objections.</p>
              <p className="p-in-sp">
                One is, that it looks to a conference of the border States; and the other, that it looks to yet further delay in severing the connection of Virginia with the broken and dishonored Confederacy. What do we of Virginia want with a conference of border States? Do we not know our own rights? Can we not maintain them, without consultation and without advisement? If we go into a conference
                of border States, and if those States choose to submit to wrong and aggression, which we would not submit to, and refuse to sustain our ultimatum, do gentlemen mean that they will yield the judgment of Virginia to that of the border States? If they do not mean that, then a border Conference is vain and idle.
              </p>
              <p className="p-in-sp">The Union is now dissolved—it has passed away-</p>
              <div className="lg">
                <div className="l">"Gone—glimmering through the dream of things that were—</div>
                <div className="l">A schoolboy's tale, the wonder of an hour."</div>
              </div>
              <p className="p-in-sp">
                The American Union now belongs but to history. It remains but for the historical pen to tell of its "decline and fall" to after times. Seven States of the Confederacy, carrying out the great cardinal principles of free government, as announced by the patriots and sages of the revolution, in severing their connection with the mother country, have taken their destinies in their own
                hands and with their own keeping. A Southern Confederacy is now a fixed fact. The flag of the Confederate States now floats in majestic beauty from the capitol, at Montgomery. I pray that ere long, as it floats out to the Southern breeze, it will envelope within its ample folds, this mother of States and statesmen. I hope the day is near when that flag will be your flag and my flag.
                But, sir, we are told that the Government may be re-constructed. Will these dissevered States ever be re-united? No, sir, "For never can true reconcilement grow where wounds of deadly hate have pierced so deep." Sir, had this government gone down and been overthrown from some defect in its system, we might hope that experience and wisdom might supply what was wanting. Had it gone
                down in some sudden, internal convulsion, we might hope that with the disappearance of the exciting cause, a fraternal spirit would be restored. In the one cause, as in the other, we might hope for reconstruction. But it has fallen from neither of those causes. It has gone down because its foundations were sapped by Northern hate of Southern men and for Southern institutions. In a
                word, sir, no <PageNumber num={543} /> human power can ever re-construct it. If that be so, there remains for the people of Virginia but one question, and that is, whether their fortunes and destinies shall be united in a glorious brotherhood, with kindred spirits of the South, or whether she will ingloriously remain in a Union with those who are aliens
                and enemies to her people and to their interests. To my mind there is a single view of this question, which is conclusive. It is this, that Virginia remaining in the present Confederacy, with the Northern States, not only can never again wield the powers of the government, but can never even participate in the exercise of those powers again. You have a sectional, northern, dominant
                party, whose leading, moving principle, is hostility to the social organization of the Southern States. The decree has gone forth, and has been registered in the high places that never again shall a Southern man hold the office of President or Vice-President of these United States. We of the South may indeed go to the polls. We may register our names. We may amuse ourselves, if we
                please, with the idea that we are participating in the administration of this government. But, sir, never again will the suffrage of a Southern freeman be heard at the capitol at Washington.
              </p>
              <div className="speaker" id="sp1681"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">That will be our capitol after awhile.</p>
              <div className="speaker" id="sp1682"><span className="persName">Mr. THOS. F. GOODE</span>—</div>
              <p className="p-in-sp">
                I hope so. Sir, we will be the mere serfs and bondsmen of a Northern government. We will be deprived of the right of self-government. We will be deprived of that right for which our fathers fought the battles of the revolution. I, for one, can never submit to it. I would rather see the standard of revolution roll through the land than submit to be controlled by a government in which
                I can have no voice. If we are to be deprived of self government, I had rather, so far as our rights and interests are concerned, be governed by the monarchy of England, or the autocrat of Russia. The people of Virginia will never submit to it.
              </p>
              <p className="p-in-sp">
                But, sir, we are asked, and with an air of triumph, what we are to gain by secession—by a withdrawal from the Government of the United States? Sir, we have much to gain. The anti-slavery party of the North is composed of two distinct elements. There are those who regard the institution of slavery as a rival power in the Government, and who war upon it for purposes of political
                ambition. Cut it loose from them, move it from the Government with them, and you put a stop to that incentive to war on the institution of slavery. The other element is the fanatical element. It is composed of those who believe slavery to be a great national sin—a sin for which they, as a part of the common government, are responsible. Therefore, they
                <PageNumber num={544} /> would lay a destroying hand upon it. Cut it loose from them and place it under another government, and they will no longer feel this responsibility resting on them. It will cease to war upon it; and thus, by secession, we will strike at the very root of the evil. We will remove the whole motive that impels Northern people to war
                on the institution of slavery. We will have no more abolition petitions presented to Congress. The country will not be flooded with debates in the national halls on this exciting question of slavery. These debates will not be spread broadcast over the land. We will remove the subject from the field of discussion. We will put it where it will have that repose, which alone can give it
                security and prosperity.
              </p>
              <p className="p-in-sp">
                But, sir, it has been the pleasure of gentlemen who have addressed us, on the other side of the House, to arraign the cotton States, and especially the State of South Carolina. If a stranger had entered this hall during the delivery of some of the addresses that we have heard, he would have supposed that we were sitting here, a high court in judgment on the cotton States, and
                especially on the State of South Carolina, for high crimes and misdemeanors against the national government. And what is the great cause of complaint? That those States acted precipitately. That they acted without conference with Virginia and with the other Southern States. Sir, if they acted without concert—if they acted without conference with Virginia and all the slaveholding
                States, the responsibility rests not at their doors. In view of the mutterings of the storm which threatened to burst upon the country, two of the Southern States, by the solemn acts of their State Legislatures, appointed and sent Commissioners to the Commonwealth of Virginia, asking her to meet them in council—asking her to take counsel with them on the great crisis that was
                approaching—and what was the response? Gentlemen who have been Union-savers for the last twenty-five or thirty years—who were so anxious to preserve the Union that they were afraid to take any steps looking to the preservation of our rights under the Union—folded their arms and said to South Carolina and Mississippi; "No, you want a Convention for purposes of disunion. We will not
                extend to you the right hand of fellowship. We will not confer with you." But, say gentlemen, that Convention was for the avowed purpose of disunion. Sir, I deny it, and I appeal to the address delivered before the Virginia Legislature by the Commissioner of South Carolina, and to the written communication of the Commissioner of the State of Mississippi to the Executive of the State
                of Virginia. These will show that the object of those States, in asking that Conference, was to perpetuate, and not to destroy the <PageNumber num={545} /> Union. It was to assert our constitutional guarantees in the Union. Mr. Memminger, a Commissioner from the State of South Carolina, in his address to the Virginia Legislature, uses this unmistakable
                language:
              </p>
              <p className="p-in-sp">
                "I would be wanting in the frankness and candor due to this august assemblage, if I did not plainly declare the opinions which we entertain in South Carolina. We have no confidence in any paper guarantees—neither do we believe that any measures of restriction or retaliation within the present Union will avail. But, with equal frankness we declare, that when we propose a conference,
                we do so with the full understanding that we are but one of the States in that conference, entitled like all the others to express our opinions, but willing to respect and abide by the united judgment of the whole."
              </p>
              <p className="p-in-sp">There, sir, is an unmistakable, unequivocal declaration, from the Commissioner of the State of South Carolina. Speaking for and in behalf of his State to the General Assembly of Virginia, he says that we will be but one in that Conference, we pledge ourselves to abide its judgment. And this is the avowed purpose of disunion which gentlemen have spoken of.</p>
              <p className="p-in-sp">I will now read an extract from the communication of the Commissioner from Mississippi, General Starke.</p>
              <div className="speaker" id="sp1683"><span className="persName">Mr. BALDWIN</span>, of Augusta—</div>
              <p className="p-in-sp">Will the gentleman allow me to interrupt him, that I may correct a statement in regard to a matter of fact?</p>
              <p className="p-in-sp">Does not the address of the Commissioner from South Carolina directly declare that South Carolina's desire, in going into that Conference was to bring about a dissolution of the Union, and that, although she intended to abide the action of the Conference, her own vote in the Conference would be a vote for disunion?</p>
              <div className="speaker" id="sp1684"><span className="persName">Mr. GOODE</span>—</div>
              <p className="p-in-sp">The gentleman could not have heard the first part of this extract. He will find there that the Commissioner says:</p>
              <p className="p-in-sp">"I would be wanting in the frankness and candor due to this august assemblage if I did not plainly declare the opinions which we entertain in South Carolina. We have no confidence in any paper guarantees, neither do we believe that any measures of restriction or retaliation within this Union will avail."</p>
              <p className="p-in-sp">And yet, sir, notwithstanding this avowal of the sentiments of South Carolina, she pledged herself, if, in conference with her sister States of the South, to abide the result of that conference.</p>
              <p className="p-in-sp">Now for the avowals of the Commissioner from Mississippi, Gen. Starke. He says:</p>
              <PageNumber num={546} />
              <p className="p-in-sp">
                "If, after what I have said, I am asked—conference for what? My answer is in a word—for protection to ourselves, for self-preservation, for defence of the Constitution of the United States, for the preservation of the Union, and the equal rights of all the States and of their people in the Confederacy. This is no question of party. It is one of patriotism. I am delegated here by
                party opponents—by those who would confer on me no honors of the partisan—but who have done me the higher honor of confiding to me the trust of the patriot. If others design disunion by commending conference, they have mistaken the means adapted to their end. So far from tending to disunion, a conference such as my State now tenders, and which I am instructed to urge upon the
                acceptance of the Commonwealth of Virginia, in the name and for the sake of the very bond itself of Union which ought to enable sister States to unite all the more readily, the more easily, the more confidingly in council and in conference—such a conference and that alone can effectually secure the co-operation to which the General Assembly of Virginia is now invited to save our
                equal rights, our honor, and our federal relations. Nothing, indeed, can more directly tend to disunion than a disinclination among the Southern States to consult with and confide in the counsels of each other. If it shall be found impossible to lend to moderate and peaceable action that effective force and impressive dignity which can result only from united counsels and concerted
                action—then and then only is it probable that the individual States will be obliged to resort to measures of redress which shall compensate in extreme severity the comparative weakness of individual effort."
              </p>
              <p className="p-in-sp">
                Thus, Mr. Chairman, the declaration of the Commissioner from Mississippi is unequivocal, that his State solicited that Conference with a view, not to the destruction, but to the perpetuation of this Union. And, sir, is not the course of the majority of this body now a full attestation, of the propriety and the necessity of the adoption of the suggestions of South Carolina and
                Mississippi, at that time? Does it not fully attest the propriety and the necessity of a Conference of the Southern States? We are told now that we should have consultation, that we should have conference. We are told that if we had a full conference of the Southern States, and if an ultimatum were laid down by them, that all would be well. But, sir, these gentlemen, who are so
                anxious for an ultimatum now, were not willing to unite with Southern States in laying down an ultimatum. If anything could have saved this Government—if anything could have stayed the storm of revolution that now rolls over this land—if anything could have stayed that storm <PageNumber num={547} /> which I fear is yet to come—it would have been a
                united South, in council, with one voice, demanding a guarantee for all the constitutional rights of the South; laying down their ultimatum; demanding of the North to accept it as a condition of their longer remaining in this Union. But, no, sir; Virginia refused to go into this Conference. Now, sir, the storm is upon us. Seven States have gone off. Virginia is left in the old ship
                of state with an abolition commander to pilot her through the breakers. But, oh! gentlemen now say, give us an ultimatum. Yes, the work of destruction is done; that which South Carolina and Mississippi told these Union-loving gentlemen would be the result of their refusal to go into conference with them, has come to pass. In default of a union of the Southern States, some of the
                Southern States have been forced to take State action; and now gentlemen have the grace to tell us that if they had had a Southern Conference all would have been well. Sir, if we are not to-day united—if we are not to-day standing side by side and shoulder to shoulder, manfully battling for every constitutional right—if we are not together on the political sea, making a common
                struggle for safety; if our efforts are not now united, upon you
                [the speaker here addressed himself to the Union side of the house]
                , gentlemen, rest the responsibility—and I believe the people of the country will place that responsibility upon you—you may sing hymns, and psalms, and paeans to this dead Union; tell of the past glories that cluster around it; you may tell us if you will of the once proud future that was before it; but, gentlemen, if it is destroyed, I believe upon your heads rests the
                responsibility of that destruction. You refused to go into a Conference, you refused to unite with the South in demanding our rights, and now we are left almost alone, to struggle with an abolitionized government.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, let us look around us. What, sir, can be the effect of any effort that is now being made in the country, to restore this disrupted government? Sir, while Southern State after Southern State is calling its Legislature together—while they are calling together the very sovereignty of the States—while their daily and nightly prayer to the Almighty has been, as long as there
                was a hope, for a re-construction and a restoration of fraternal feeling and harmony—look, sir, to the North. Has there been an effort made in a single Northern State? Has a single step been taken, a single move made to give us of the South an assurance that they will come up and reconstruct this government on constitutional grounds, recognizing and protecting our rights?
              </p>
              <PageNumber num={548} />
              <p className="p-in-sp">
                Is there one move on the part of the people about whom we hear so much here? Is there any evidence that they will give us the right hand of fellowship? No, sir, no. I believe in my heart, as I heard the distinguished and venerable gentleman and patriot from Charles city, remark in his speech to the people of this city, that the people of the North would not crack their finger to
                restore and to save the government.
              </p>
              <p className="p-in-sp">
                No, sir; if it is saved, we will have to save it without the aid of the Northern people. Sir, when you can regenerate the heart of man; when you can remove the prejudices that are instilled into the infant mind in the schoolhouse, and counteract the pernicious sentiments proclaimed from the pulpit and the press, then you may restore this Government, but not till then. But, oh! the
                abused, much denounced State of South Carolina—and I will pay gentlemen the compliment to say that South Carolina is a well abused State—Sir, I shall make no defence for the State of South Carolina. She requires it not at my hands. The brightest pages of our country's history are those which tell of the valiant deeds of Carolina's sons in the first great struggle for American
                liberty. And, sir, more than all, and prouder, yet, than all, the historic pen that portrays, for after times, the history of the present revolution, will tell that South Carolina led the van in this second great struggle for Constitutional American Liberty. I trust, sir, that Virginia may soon follow her footsteps. Sir, I hear much of the individual hazards of those who belong to
                the secession party in this body. I hear much of the account which we are to render to an indignant constituency. For myself, I desire but to say, that I have no wish to shrink from the fullest responsibility for every vote that I may give and for every word which I may utter upon this floor. I shall be prepared to answer, for each and all, to my own conscience, to my constituents,
                to my country, and to my God. In conclusion, Mr. Chairman, I make to you and to the members of this Convention my very grateful acknowledgements for the very kind attention which you have paid me.
              </p>
              <div className="speaker" id="sp1685"><span className="persName">Mr. SEAWELL</span>, of Gloucester—</div>
              <p className="p-in-sp">Mr. Chairman, I do not desire to take up the time of the Committee with anything like an argument on the question now pending, but I do desire that the position which gentlemen have assumed, in opposition to the amendment, shall be understood.</p>
              <div className="speaker" id="sp1686"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Will the gentleman suspend his remarks until the Committee comes to order?</p>
              <div className="speaker" id="sp1687"><span className="persName">Mr. SEAWELL</span>, after a brief interval, proceeded :</div>
              <p className="p-in-sp">I do desire to put right the question which is before the Committee <PageNumber num={549} /> on the amendment which was accepted by the gentleman from Madison, yesterday evening; and I desire to say, sir, in regard to the amendment to that amendment, which was offered this morning, by the gentleman from Powhatan [Mr. Scow] , that he has been frank enough to disclose the motives with which the amendment was offered, which is, that it may defeat the amendment to which he offers it.</p>
              <div className="speaker" id="sp1688"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
              <p className="p-in-sp">I did not say that my object was to defeat the amendment of the gentleman; but I did say that the amendment, if amended, would not be necessary, and when it came up I should vote against it.</p>
              <div className="speaker" id="sp1689"><span className="persName">Mr. SEAWELL</span>—</div>
              <p className="p-in-sp">
                That is what I understood the gentleman to say, and my understanding of the words, and that is what I believe they express in plain English—that he offered his amendment for the purpose of defeating the amendment to which it was offered. I do not desire to do the gentleman injustice, nor do I desire to detain the Committee with any reply to the arguments which the gentleman advanced
                in support of his propositions. The question has been so often discussed by abler men than I am, that it would be a waste of time for me to enter upon any discussion further than is necessary to meet the grounds assumed by the gentleman from Frederick
                [<span className="persName">Mr. CONRAD</span>] , in opposition to this amendment.</p>
              <p className="p-in-sp">I understood from him that he objected to this amendment on the ground that it is unnecessary. Although it may be true that Virginia is still sovereign, it is unnecessary to insert a truism in the connection which it will bear in this section of the report of the Committee on Federal Relations.</p>
              <p className="p-in-sp">
                Sir, he attempts to illustrate that by showing that the statement in that section which was incorporated by the Committee was only for the purpose of separating the conclusions which they came to, and that the States were in consequence of that promise, entitled to occupy a position in this Confederacy upon equal terms and entitled to the protection of the General Government. I
                desire to ask the gentleman this question by way of answer to his argument on that point, is Virginia any less entitled to equality in this Union now, and to the protection of her interests in this Union by reason of her being sovereign? Now, is she any less entitled to that which is claimed for her by that section of the Committee's report, by reason of the fact that she is now
                sovereign?
              </p>
              <div className="speaker" id="sp1690"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">Will the gentleman allow me to answer him? My answer is, that whilst she may not be the less entitled in consequence <PageNumber num={550} /> of the declaration of her sovereignty now, she is certainly no more entitled than she is upon the declaration of her sovereignty, when she united in form with government.</p>
              <div className="speaker" id="sp1691"><span className="persName">Mr. SEAWELL</span>—</div>
              <p className="p-in-sp">It may be that in the abstract she would be no more entitled; but practically, I think it will be considered that she ought to be more considered, if she is still sovereign. She has a higher claim to a recognition of her rights, if she is still sovereign, than if she had parted with it.</p>
              <p className="p-in-sp">
                But to go back to the train of argument I was pursuing: if the conclusion to which the committee seek to come in that section, to wit, that by reason of the fact that Virginia was sovereign and independent at the time she entered into this Confederation, she is now entitled to equality and protection, is she any less entitled to it by asserting the fact, and having it established as
                a fact that she is now sovereign?<span className="note" id="Note58"><span className="noteLabel"> 4</span>Part of Mr. Seawell's argument has apparently been left out.</span>
              </p>
              <p className="p-in-sp">
                But, said the gentleman from Frederick, there is no use in it, because the only reason for using those terms is to support the conclusion, and there is no necessity for adding these reasons. It is the first time I ever knew a gentleman, in my life, refusing two reasons for his conclusions when he had only taken one. I see no reason why any gentleman should object to having two good
                reasons for his conclusions, instead of one.
              </p>
              <p className="p-in-sp">
                But, said the gentleman from Frederick, it is incongruous; it has no business there. It may produce mischief, as I understood him. What mischief can be produced? I am anxious to hear a response to that interrogatory. What mischief can be produced there or anywhere else, in any connection or under any circumstances, if Virginia should declare to the world that she is sovereign? What
                sentence of any section of writing, can it mar the beauty of, or do injury to? It can do mischief, so far as I can understand, to nobody but that class of gentlemen who entertain strong doubts of the fact, or who deny it. There it may do mischief; there it may produce injury in some form, aye, indeed in many forms. With those gentlemen who do acknowledge, and will acknowledge, and
                maintain that Virginia still retains her sovereignty, I cannot, for my life, see why they should think it could do mischief, and the question was asked, why interpolate, if necessary, as it was assumed to be. If that question was pertinent before that proposition was made, it is no longer pertinent, because the truth, propriety and assertion of Virginia's sovereignty has been, and is
                denied. That, I think, is reason sufficient for making the assertion everywhere or under any circumstances. I therefore hope that it will be the pleasure of this Committee, by their votes, to sustain the <PageNumber num={551} /> amendment to that section of the report of the Committee on Federal Relations.
              </p>
              <div className="speaker" id="sp1692"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">
                I rise, this morning with diffidence, but yet with more of firmness than I usually do before this body. I trust that the Convention will bear with me patiently in the few crude remarks I may now make. It does not matter whether I have listeners or not, because my remarks will be published in the "Enquirer," and I know Mr. Tyler will not object, because he gets $800 a week for
                publishing our debates, and, of course does not care how long they are kept up.
              </p>
              <p className="p-in-sp">
                If I understand the question before us it is, what is the sovereignty of a State? I understand one party to contend for a perfect, and the other for a qualified sovereignty. I presume this is the position of the question. Now, I beg leave to enquire whether every man in this Convention has not made up his mind upon this question. Mine is. I consider myself one of the humblest members
                in this Convention, and if I can come to a conclusion from the arguments made yesterday and to day, surely the men who stand here as the Solons and Demosthenes of this body, ought to have come to a decision too; and permit me here to say, if I considered myself one of the leaders of this Convention, I should not fire my artillery upon this question. It would be a waste of time. I
                assume that every gentleman has made up his mind, and hence I ask, why the necessity of debating this amendment any longer?
              </p>
              <p className="p-in-sp">
                I voted last evening against closing the debate in Committee of the Whole next Monday at 12 o'clock M., and I proposed an amendment extending the time to the Friday following, which was not agreed to. I am not sure now that the time should be prolonged. One thing I have found out, that I have not patience enough to sit longer and quietly listen to gentlemen on the first two lines of
                the report, speeches too which may have been prepared years ago. I have been told that some gentlemen have delivered speeches that were made four years ago.
                [Laughter.]
                I have no doubt they were good ones. Perhaps if I had prepared speeches I too would have used them. Mr. Chairman, I stand in a predicament in which but few men here are placed—to carry out the wishes of my constituents, by their earnest "request"—and my people say that we must go out of this Union. I mean to carry out their wishes by my votes here, and not by speaking.
              </p>
              <p className="p-in-sp">Some days ago, about the 5th of March, my friend from Chesterfield [Mr. Cox] , introduced a resolution in the Convention, that the Committee on Federal Relations be instructed to make a report upon the subject of coercion, and also in regard to a Conference of the border <PageNumber num={552} /> States. The gentleman from Goochland [<span className="persName">Mr. LEAKE</span>] , saddled it at once with something like a secession ordinance. My friend from Amelia [<span className="persName">Mr. HARVIE</span>] , also offered a proposition, and hence they so clogged the report that we could not get any action upon it. I do not understand how things are managed, but it seems as if the secession question is forgotten.</p>
              <p className="p-in-sp">
                One thing I do know, that I, as well as other gentlemen in this Convention, were prevented from taking the action which we desired. My great aim now is to dispose of our business in the quickest possible way; and yet I would not intentionally be guilty of the least discourtesy to gentlemen who wish to present their views before this Convention. In olden times, when I was a boy, it
                was the practice, when unpleasant questions were under discussion, to have a limited number of speakers on each side, to whom the debate should be entirely confined. The plan worked well, and all the strong points on both sides of the question were thus presented, saving thereby endless repetition of the self-same ideas. I would suggest to each wing of the Committee the propriety of
                adopting the same policy here. It certainly would tend to expedite our business greatly and assist us materially in bringing our labors to a close. If you adopt that plan speedily, you may save me the necessity of being apparently discourteous in my votes, because I mean to apply the knife of excision, to avoid unnecessary waste of time. I would be much pleased if the Committee would
                take the course which I, in my temerity, have chosen to suggest, that of taking a few of your strong men on each side, and letting them debate the questions of importance coming before us daily. The rest of us could sit at their feet and learn wisdom, and the Convention would then despatch the business before them with that dignity and deliberation which should characterize the
                proceedings of this body. I have not been instructed here to-day by the speeches. I do not expect to be instructed by any speeches that may be made. I do not mean to cast any discredit upon the intellect, beauty and sentiment that have characterized the arguments on both sides of the question; but I certainly mean to say that in Committee here, we have too much latitude of
                discussion. The gentleman over the way, my valued friend from Mecklenburg
                [Mr. Gomm] , has elaborated subjects at great length, and with ability, too, but he has not touched the resolution under discussion. [Laughter.] He is a good deal like preachers I have heard, who will take an excellent text, but you never hear a word afterwards that could be legitimately deduced from it. I consider, when a man takes a text that he ought to preach to it, [laughter] ; and</p>
              <PageNumber num={553} />
              <p className="p-in-sp">that is the trouble with us here, in the discussion of the Committee of the Whole—we are wandering away from the subject before us. I believe that we are doing ourselves great injustice by this wide latitude of debate, and that our unnecessary delay causes much complaint ; besides that, we are spending in these discussions about a thousand dollars a day.</p>
              <p className="p-in-sp">
                I came from home yesterday, and the universal cry among my constituents was : what are you doing, and when are you going to act? We are a commercial city, and business languishes from your non-action. What could I tell them? All I could answer was, "I do not know, but I suppose we shall get through in about a fortnight." Now I am told that we cannot get through before the 1st of
                June. I give you fair notice, as a man intending to work and not talk, that I have no idea of sitting here until the 1st of May. So you may as well go to work; at least, I intend you shall, if my vote can accomplish such a result.
              </p>
              <p className="p-in-sp">
                I stand here in a peculiar condition, having received instructions from my constituents to act in a certain way. They say, we must go out of the Union, and, in accordance with such instructions, I intend to prepare and submit an ordinance of secession to this body to-morrow morning. I give notice to gentlemen, then, that they must take their choice, whether they will stay in or go
                out. Let us act, and thereby put an end to so much unnecessary and unprofitable discussion. I wish many of these gentlemen who expect to speak had followed the noble example of my venerable friend from Orange
                [<span className="persName">Mr. MORTON</span>] . He took time by the forelock, and elucidated all subjects from A to Z, some time ago, before the Committee made their report. [Laughter.] Gentlemen have unfortunately deferred their speeches until this time, some of whom may write them out, if they please, and the Committee will order their publication; some must be necessarily deprived of the opportunity of delivering them. I would be glad to accommodate all the gentlemen, but I cannot consent to hear every gentleman here. [Laughter.]
                And I, and a majority of the Committee, agree upon this point. I shall be criticised, perhaps, for venturing to make the remarks I have, but feeling that the line of policy I have indicated is the true one, I shall act up to what I have here stated. I will not follow the ways of tacticians nor parliamentarians, and as soon as I get through with this Convention I shall try and forget
                what I may have learned in that regard; for my political ambition will have been perfectly satiated.
                [Laughter.] I will not detain the Convention longer, but resume my seat, returning my thanks to the Committee for the</p>
              <PageNumber num={554} />
              <p className="p-in-sp">kind and patient attention with which they have listened to me.</p>
              <div className="speaker" id="sp1693"><span className="persName">Mr. BLAKEY</span>, of Madison—</div>
              <p className="p-in-sp">Mr. Chairman, the distinguished gentleman from Petersburg [<span className="persName">Mr. BRANCH</span>]
                , has furnished a very striking illustration of how much easier it is to preach than to practice. He tells us when we take a text we ought to stick to that text, and not run off into an exhortation. I think it is perfectly manifest that when he took his text he went off into an exhortation, and, to use his own phrase, travelled over Virginia and the rest of mankind.
              </p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp1694"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">And I have no doubt you'll follow me.</p>
              <div className="speaker" id="sp1695"><span className="persName">Mr. BLAKEY</span>—</div>
              <p className="p-in-sp">
                Well, I think Mr. Chairman, the gentleman has shown himself to be a bad preacher and I will prove him to be a false prophet. The gentleman is exceedingly desirous to cut off discussion, and he exhorts other gentlemen to be brief. Sir, I think that the gentleman ought to allow to others the same privileges that he himself has exercised. I think that he has occupied quite as much of
                the time of this Convention as several other gentlemen who have participated to a large extent in the debates, and a great deal more than many others who have been engaged in its deliberations. Now, sir, I am not one of the leaders of this Convention—I don't aspire to be one of the leaders of this Convention; and therefore I don't expect to be selected by him as one of those he would
                have to carry on the debate. I suppose it was only through modesty he announced that he himself was not a leader, because he thinks the leaders on each side ought to do all the speaking; and as he has done a good deal of talking on the other side, on the side of the majority, and now talks on behalf of the minority. According to the rule he has laid down he must think himself a
                double leader—not as a leader of one party or of the other, but as a leader of both parties.
                [Laughter.] But, sir, according to the advice of the gentleman, for his advice is better than his example, I will come to the text and endeavor to stick to it; and in doing so I will give the gentleman the mortification of finding himself a false prophet.</p>
              <p className="p-in-sp">
                The Committee upon Federal Relations, in their report, have deemed it necessary to set forth certain general principles. Now, sir, I maintain that when they undertake to set forth general principles, or when they state a general principle, they should state it correctly and fully. I doubt not that the chairman of that committee is under the impression that he has stated it correctly
                and fully. There are others, however, who differ from him in opinion. I do not think the gentleman ought to complain of those who differ from him, and who have to vote upon the propositions which he has submitted to this body, because <PageNumber num={555} /> they endeavor to correct them so as to agree with their views. He has brought these principles
                before us. His action requires that we shall vote upon them; and when I vote upon a principle, I desire to have that principle stated correctly, as I understand it. And I don't think that we of our side ought to be charged with desiring to throw any obstruction in the way of this committee, because we desire to perfect this statement of principles according to our opinions.
              </p>
              <p className="p-in-sp">I think, sir, it is perfectly manifest that if the statement which this committee has made is necessary to the conclusions which they draw, the amendment of the gentleman from Gloucester [<span className="persName">Mr. SEAWELL</span>]
                , is equally necessary—aye, more necessary—to justify these conclusions. The committee say "that the States which composed the United States of America, when the Federal Constitution was formed, were independent sovereignties;" and upon that premise they draw the conclusions: "that it is the duty of the Federal Government to respect the rights of the States and the equality of the
                people thereof, and within the just limits of the Constitution to protect with equal care the great interests that spring from the institutions of each." Because they were independent sovereign States at the time of the formation of the Constitution, therefore they are now entitled to equal rights, &amp;c.
              </p>
              <p className="p-in-sp">
                Now, sir, I don't think that is a necessary consequence at all. If they were independent and sovereign States at the time they formed the Constitution of the United States, they may have agreed that certain provisions should be engrafted in that Constitution which would deprive them of equality. Their conclusions do not by any means follow as necessary consequences. If sovereignty,
                at the time of the formation of the Constitution, gave equality, is it not much clearer that sovereignty at this time will entitle Virginia to it? It seems to me very clear, sir. I totally dissent from the sentiments advanced by the gentleman from Rockbridge
                [<span className="persName">Mr. DORMAN</span>] , and by the member from Norfolk city [<span className="persName">Mr. BLOW</span>] , to the effect that this Committee ought to vote for the report of the majority as it is, even though it should be proposed to amend it by enunciating a sound and true principle. I think, sir, that we ought to maintain the truth, even though it may be in conflict with the report of the Committee.</p>
              <p className="p-in-sp">
                But, sir, what is sovereignty? Where does the sovereign power lie? I know there is a great conflict of opinion in the world upon this subject; but I had understood, I had been induced to believe that the American doctrine is that sovereignty rests with the people; and when we speak of the seat of sovereign power, we refer to the people; we speak of the people as the fountain of it,
                as the retainers of it.
              </p>
              <PageNumber num={556} />
              <p className="p-in-sp">As I understand, its sovereign power is primitive, original power, and when it ceases to be primitive power and becomes delegated, derivative power, it ceases to be sovereign power.</p>
              <p className="p-in-sp">
                Why is the State of Virginia not a sovereign State? Why, sir, the State is the organism through which its sovereignty speaks, and by which it is made manifest, just as the body of the man is the organism through which we recognize the existence of the man, and through which he speaks. Virginia, organized as a State, is a sovereign body, and the sovereignty of the people speaks
                through that organism; and, therefore, she may be called a sovereign State; but when the State of Virginia delegates power to the Federal Government she cannot transfer to that General Government a power which, in the hands of that Government will be primitive original power. At the moment she deposits it in the hands of the Federal Government it becomes derivative power and ceases
                to be sovereign power.
              </p>
              <p className="p-in-sp">
                Sir, I regard the Federal Government in the nature of a political corporation formed by the consent and action of all the States, and charged by them with certain duties and powers. It is not a sovereign power, it is not a sovereign government, but it is a political corporation formed for the purpose of discharging, as the common agent for all the States, that which can be thus more
                conveniently performed by the States themselves.
              </p>
              <p className="p-in-sp">
                Gentlemen have argued to prove that the State of Virginia is not a sovereign power, because she has thus consented to the transfer, and has transferred, certain delegated powers to this agency, and that, because she has done this she ceases to be a sovereign State. Sir, sovereign power is not unlimited. If that be true, then there is no sovereignty, except. the great sovereignty of
                the Universe. It is admitted, on all hands, that the State of Virginia has a right to take back the powers she delegated to the Federal Government, and to the extent to which she helped to make it—to unmake that government. If she can thus make and unmake a government, she can exercise the highest powers of sovereignty—much higher than that of making treaties, declaring war and
                concluding peace, upon which gentlemen so much rely as necessary to sovereign existence.
              </p>
              <p className="p-in-sp">Now, sir, if these principles be correct, then the amendment of the gentleman from Powhatan [<span className="persName">Mr. SCOTT</span>]
                , is altogether unnecessary. He says that the States which composed the United States of America when the Constitution was formed, were independent sovereignties and are still sovereign, and he then adds, "over all powers not granted to the United States by the Constitution of the United States." This <PageNumber num={557} /> amendment is founded upon
                the idea that the power which is invested in the General Government, is sovereign whilst it remains there. That is the very position I combat. I say it ceased to be sovereign power the moment it became secondary power. For these reasons I shall vote for the proposition of the gentleman from Gloucester.
              </p>
              <div className="speaker" id="sp1696"><span className="persName">Mr. MOORE</span>, of Rockbridge—</div>
              <p className="p-in-sp">
                I hope, sir, that this discussion will shortly cease. It seems, to me, to be one of the most idle discussions I ever knew of. It is a mere dispute about words, nothing more. I think the difficulty with gentlemen arises, in part, from their not drawing a distinction between sovereignty and the capacity to maintain it. It seems even among the writers on the law of nations, that there
                is some inconsistency. They speak sometimes of the king as sovereign, and they talk of the sovereignty of subjects; but they all agree that the source of sovereignty is in the people.
              </p>
              <p className="p-in-sp">
                Now, sir, I apprehend that no one will contend that the State Government of Virginia is sovereign. And why not? Because the people never invested it with these sovereign powers—the power to declare war and conclude peace, to make treaties, &amp;c. Those powers were invested by them in another Government. But there is one point on which we all agree, and the report of the committee
                acknowledges it over and over: that Virginia has the source of sovereignty in herself, and that she can exercise it when she pleases. That principle is admitted, I think, in various instances in the report of the committee. Why then are we here day after day engaged in a dispute upon this, sir, when there is really no substantial difference between us? I hope, sir, we will take a
                vote upon the amendment.
              </p>
              <div className="speaker" id="sp1697"><span className="persName">Mr. TREDWAY</span> of Pittsylvania—</div>
              <p className="p-in-sp">Mr. Chairman, before the vote is taken, I desire to put myself right as to the vote I shall give. I agree with several gentlemen in the opinion that the amendment moved by the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>]
                , although it contains a proposition to which I will assent when it is proper to lay down that proposition; yet, believing, as I do, that the insertion of, or the attempt to insert that proposition, to wit: that the States are now sovereign, which I believe to be a truism, for I have been trained in that school of politics which has brought me to the same conclusion as the gentleman
                from Princess Anne
                [<span className="persName">Mr. WISE</span>]
                , and those gentlemen who have advocated the States Rights doctrine.<span className="note" id="Note59"><span className="noteLabel">5</span>The text is given thus in the Enquirer. Mr. Tredway seems to have lost the thread of his argument.</span> But, while I entertain that opinion, I think it has been demonstrated conclusively by the gentleman from Frederick
                [<span className="persName">Mr. CONRAD</span>] , that the insertion of this proposition, although I regard it as a truism, would only tend to <PageNumber num={558} /> produce distraction to others in this body, and that its insertion here is certainly unnecessary and improper.</p>
              <p className="p-in-sp">
                Mr Chairman, there are two schools of politicians in Virginia, as every one knows, and you cannot place such a proposition in this resolution, raising a question in regard to this doctrine, without placing those who belong to the States Rights party, as they have been denominated, in opposition to those who belong to the other school of politics, and who are sometimes denominated
                "Federalists," though I will not use, in this assembly, an epithet which is sometimes regarded as disagreeable. But extreme as are my own opinions upon this question, yet I know men with whom I am acting in this body, and among them some of the most eminent statesmen of Virginia, who differ with me upon this question on an article in the political creed; who differ with me honestly;
                and I am unwilling, until it is necessary, to involve those gentlemen with myself in a discussion, while we are beset with difficulties surrounding us certainly sufficient for this day. I therefore say, that I should regret the insertion of the amendment either of the gentleman from Middlesex, or that of the gentleman from Powhatan, as unnecessary, and as, in fact, improper to be
                incorporated in this connection in the report of the Committee. While, therefore, I, myself entertain the opinion that that proposition is true, and desire to put myself right as a States Rights man, I shall vote against it for the reasons I have assigned.
              </p>
              <div className="stage it">[Cries of "Question, question."]</div>
              <div className="speaker" id="sp1698"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I understand the question now to be upon the proposition of the gentleman from Powhatan [<span className="persName">Mr. SCOTT</span>] . What is that proposition?</p>
              <div className="speaker" id="sp1699"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>]
                , proposes to insert in the report of the Committee the words, "and still are." The gentleman from Powhatan proposes to add to that amendment the words, "over the power not granted to the United States by the Constitution of the United States." If both amendments be adopted, the clause will then read, "that the States which composed the United States of America when the Federal
                Constitution was formed, were independent sovereignties, and still are sovereign over the powers not granted to the United States by the Constitution of the United States."
              </p>
              <div className="speaker" id="sp1700"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I cannot understand that amendment.</p>
              <p>The Secretary again reported the amendment.</p>
              <div className="speaker" id="sp1701"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I do not catch it yet. Do I understand the gentleman to state that Virginia is independent, sovereign and free, except what <PageNumber num={559} /> belongs somewhere else? Does he mean to grant the sovereignty and then deny it in the same breath? Why, sir, I might as well say to one of the slaves on my plantation, you are free, except what belongs to
                me. It is a solecism in the statement of it. Do I understand my friend from Pittsylvania
                [<span className="persName">Mr. TREDWAY</span>]
                , to say that he admits the principle of States-rights, but he will not insert an assertion of the principle, because the assertion of it might breed distraction? Will he not assert a principle that he admits to be true, that he admits to be sound, when it is denied by implication in the resolution he proposes to adopt? If the assertion of the principle will breed distraction, let me
                ask him what will the denial of it breed?
              </p>
              <div className="speaker" id="sp1702"><span className="persName">Mr. TREDWAY</span>—</div>
              <p className="p-in-sp">With the consent of the gentleman I will say to him that I do not understand it to be denied in the section as it now stands. I understand it in fact to be admitted in the 8th section of that report to which the gentleman alluded yesterday; but I vote against the amendment, because I believe it to be unnecessary here, and in fact improper.</p>
              <div className="speaker" id="sp1703"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                The gentleman tells me he does not understand it to be denied, when the report expressly speaks in the past tense that they "were" sovereign, implying at least that the Committee ignore sovereignty in the present tense. He tells me that he does not understand it to be denied, when the gentleman from Fauquier and the gentleman from Richmond, both in the Committee which reported it,
                told him expressly that they do deny and meant to deny it. Will the gentleman fail to assert it, and leave the denial to be implied, because the report does not deny it in express words?
              </p>
              <div className="speaker" id="sp1704"><span className="persName">Mr. TREDWAY</span>—</div>
              <p className="p-in-sp">
                If the gentleman will permit me, I have not heard the gentleman from Richmond, nor the gentleman from Fauquier. I am governed in my own construction by the language of the section. To illustrate my position, I hold that the failure to assert that which is true, is not a denial of it as a truth. Some gentleman has said, that if we are to assert all truth, why not insert the Lord's
                Prayer which, according to the gentleman's theory, will be denied if we fail to insert it?
              </p>
              <div className="speaker" id="sp1705"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I think the Lord's Prayer should very properly be inserted for the benefit of gentlemen in this Convention who refuse to assert their own principles and their own propositions.</p>
              <p><span className="persName">Mr. MORRIS</span> called for the yeas and nays upon the adoption of the amendment to the amendment.</p>
              <p>The yeas and nays were ordered.</p>
              <div className="speaker" id="sp1706"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">
                The Chair wishes to intimate to the members of <PageNumber num={560} /> the Committee that if any gentleman desires to be excused from voting, or if any member is desirous to explain his vote, he should do so before the call of the roll has commenced. The Chair is aware that the practice has been allowed, to some extent, of permitting members to explain
                their votes during roll-call, and he therefore did not think proper, yesterday to interrupt gentlemen, but they will see that difficulties must result from allowing the practice to continue. If a gentleman rises to explain his vote, he has the right to give reasons, and he has the right to argue the question. In the meantime the call is suspended. Other gentlemen are entitled to the
                same privilege in giving excuses for not voting, and the Committee must see, that in a body as numerous as this, the time for taking a single vote might be extended to great length. The Chair, therefore, gives notice that he will not permit any interruption of the roll-call for purposes of explanation.
              </p>
              <div className="speaker" id="sp1707"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I rise to propose an amendment to the amendment of the gentleman from Powhatan, which I understand he is willing to accept. I move to strike out the word "over," and to insert the words "in regard to," so that it will read "sovereign in regard to all powers," &amp;c.</p>
              <div className="speaker" id="sp1708"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
              <p className="p-in-sp">I accept the amendment.</p>
              <p>The question was taken upon the amendment to the amendment, and it was decided in the negative—yeas 50, nays 75, as follows:</p>
              <p>YEAS—Messrs. Janney [President]
                , Aston, Baldwin, Baylor, Berlin, Boggess, Brown, Burdett, Burley, Campbell, Carter, R. Y. Conrad, Couch, Early, Fugate, Gravely, A. Hall, E. B. Hall, Hammond, Hoge, Holladay, Hubbard, Jackson, M. Johnson, P. C. Johnston, Lewis, McComas, McGrew, Macfarland, Maslin, Moffett, MOORE, Orrick, Osburn, Patrick, Pendleton, Porter, Price, Pugh, Rives, R. E. Scott, W. C. Scott, Sharp,
                Sitlington, A. H. H. Stuart, C. J. Stuart, Summers, Tarr, Tayloe, Willey-50.
              </p>
              <p>
                NAYS—Messrs. Ambler, Armstrong, J. Barbour, Blakey, Boisseau, Borst, Boyd, Branch, Brent, Bruce, Byrne, Cabe11, Chambliss, Chapman, Coffman, Conn, C. B. Conrad, J. H. Cox, R. H. Cox, Custis, Deskins, Dorman, Dulany, Echols, Fisher, Flournoy, Forbes, French, Garland, Gillespie, Graham, Gray, Gregory, Jr., Goggin, John Goode, Jr., T. F. Goode, C. Hall, L. S. Hall, Haymond, Holcombe,
                Hunton, Isbell, Kent, Kindred, Kilby, Lawson, Leake, McNeil, C. K. Mallory, J. B. Mallory, Marye, Sr., Miller, Montague, Morris, Morton, Neblett, Parks, Preston, Randolph, Richardson, Seawell, Sheffey, Slaughter, Southall, Speed, Strange, Thornton, Tredway, R. H. Turner, F. P. Turner, Waller, Whitfield, Williams, Wise, Woods-75.
              </p>
              <p>So the amendment to the amendment was rejected.</p>
              <p>The question then recurred upon the amendment offered by <span className="persName">Mr. MONTAGUE</span>, upon which the yeas and nays had been ordered.</p>
              <p>The CHAIRMAN stated the question to the Committee.</p>
              <PageNumber num={561} />
              <div className="speaker" id="sp1709"><span className="persName">Mr. WOODS</span>—</div>
              <p className="p-in-sp">I desire to submit some remarks upon that amendment.</p>
              <div className="speaker" id="sp1710"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It is not now in order. The Chair has stated the question to the Committee.</p>
              <div className="speaker" id="sp1711"><span className="persName">Mr. WOODS</span>—</div>
              <p className="p-in-sp">I supposed when the question had been taken and decided upon, the amendment to the amendment, the original amendment was open to amendment or discussion.</p>
              <div className="speaker" id="sp1712"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">So it was.</p>
              <div className="speaker" id="sp1713"><span className="persName">Mr. WOODS</span>—</div>
              <p className="p-in-sp">I have very little experience in these matters, but I supposed that it was always proper, before any amendment or proposition was open for discussion, for the Chair to state the question, and that it was then open for discussion—that discussion was, in fact, in order upon it at any time before the taking of the vote had actually commenced.</p>
              <div className="speaker" id="sp1714"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair has just decided that the original amendment was open to amendment or debate, when the question had been decided on the amendment to the amendment. But the Chair rose and propounded, not only what the question was to the Committee, but propounded the question, itself for the vote, and he decides that it is now too late.</p>
              <p>
                The question was then taken, and it was decided in the negative—yeas 47, nays 74, as follows :<span className="note" id="Note60"><span className="noteLabel">6</span>The Journal also gives the total of negative votes as 74, but lists the same 77 names given here.</span>
              </p>
              <p>
                YEAS—Messrs. Ambler, A. M. Barbour, Blakey, Boisseau, Borst, Bruce, Cabell, Chambliss, Chapman, Coffman, Conn, R. H. Cox, Fisher, Flournoy, Forbes, Garland, Graham, Gregory, John Goode, Jr., Thos. F. Goode, Cyrus Hall, L. S. Hall, Holcombe, Hunton, Isbell, Kent, Kindred, Lawson, Leake, C. K. Mallory, Marye, Sr., Miller, Montague, Morris, Morton, Neblett, Parks, Randolph, Richardson,
                Seawell, Sheffey, Thornton, R. H. Turner, F. P. Turner, Williams, Wise, Woods-47.
              </p>
              <p>
                NAYS—Messrs. Janney (President), Armstrong, Aston, Baldwin, Baylor, Berlin, Boggess, Boyd, Branch, Brent, Brown, Burdett, Burley, Byrne, Campbell, Carter, C. B. Conrad, R. Y. Conrad, Couch, J. H. Cox, Critcher, Custis, Deskins, Dorman, Dulany, Early, Echols, Fugate, Gillespie, Gravely, Gray, A. Hall, E. B. Hall, Hammond, Haymond, Hoge, Holladay, Hubbard, Jackson, Marmaduke Johnson,
                Peter C. Johnston, Kilby, Lewis, McComas, McGrew, McNeil, Macfarland, J. B. Mallory, Maslin, Moffett, MOORE, Orrick, Osburn, Patrick, Pendleton, Preston, Price, Pugh, Rives, Robert E. Scott, W. C. Scott, Sharp, Sitlington, Slaughter, Southall, Speed, Spurlock, A. H. H. Stuart, C. J. Stuart, Summers, Tarr, Tayloe, Tredway, Waller, Whitfield, Willey, Wilson-74.
              </p>
              <p>So the amendment was disagreed to.</p>
              <p>During the call of the roll,</p>
              <p><span className="persName">Mr. NELSON</span> stated that he had paired off with <span className="persName">Mr. HARVIE</span>.</p>
              <p><span className="persName">Mr. SPEED</span> stated that he desired to explain his vote and should do <PageNumber num={562} /> so but for the decision of the Chair, that no explanations should be allowed during the call of the roll.</p>
              <p>The hour of two having arrived, the Committee took a recess until 4 o'clock.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.10.5">
              <h3><span className="headingNumber">1.10.5. </span><span className="head">EVENING SESSION</span></h3>
              <p>The Convention re-assembled at 4 p.m., and resumed the consideration in Committee of the Whole of the report of the Committee on Federal Relations.</p>
              <div className="speaker" id="sp1715">
                <span className="persName">Mr. FISHER</span><span className="note" id="Note61"><span className="noteLabel">7</span>Mr. Fisher corrected these remarks in the Proceedings of April 2, pages 8-10.</span>—
              </div>
              <p className="p-in-sp">The Committee this morning passed on the amendment offered by the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>] , to the amendment offered by the gentleman from Powhatan [<span className="persName">Mr. WM. C</span>. Scow]
                , and I suppose that that first section is now open to amendment. I propose to amend it by striking out after the word "instrument" the words: "the people of each State agreed to associate with the people of the other States, upon a footing of exact equality;" and inserting, in lieu thereof, the following: "each State acceded as a State and as an integral party with its co-States,
                forming as to itself the other party;" so that it will read:
              </p>
              <p className="p-in-sp">"That the States which composed the United States of America, when the Federal Constitution was formed, were independent sovereignties, and in adopting that instrument, each State acceded," &amp;c.</p>
              <p className="p-in-sp">
                One of the objects, Mr. Chairman, that I have in offering that amendment, is to vindicate the truth of history. Another of my objects is that the true fact as to the formation of the Federal Constitution was, that it was not adopted by the people as an aggregate mass, a consolidated mass, but that each State, acting in its character as a State and in the exercise of its highest
                attribute of sovereignty, adopted it for itself, and became a party with its co-States in the formation of the present Federal Government.
              </p>
              <p className="p-in-sp">
                I know, Mr. Chairman, that there has been a large and very respectable, intelligent and intellectual class of people in the United States, who do not agree to the truth of the position embodied in the amendment which I have offered. I know that Mr. Daniel Webster, that Judge Story, that Chancellor Kent, never yielded to the truth of this position. But, on the other side, no statesman
                long in public life in Virginia has ever maintained another or different doctrine from that. That was the doctrine and language of Mr. Jefferson; that is the language almost precisely of Mr. Madison. It has been the language, in past days, of Mr. Giles, of Mr. Randolph, of Mr. Taylor, and of Mr. Wm. C. Rives. It has been the language and phraseology
                <PageNumber num={563} /> of all the statesmen of Virginia who claimed to belong to the State rights party of the State. But at this period in our history, when Virginia is in the very throes of revolution, a doctrine inconsistent with that which our fathers have maintained, must not be incorporated in a declaration of principles and of facts.
              </p>
              <p className="p-in-sp">
                If the Committee does not mean to indoctrinate the State of Virginia with new principles, why have they incorporated in this first section a principle which has been the subject of controversy from 1789 to the present day? It could scarcely be anticipated that those on this floor who have ever maintained the contrary doctrine, would allow it to pass sub silentio; and I think that my
                States Rights friends on this floor will scarcely be able to relieve themselves from the necessity of coming up to the mark, and facing the music boldly, as to this proposition.
              </p>
              <p className="p-in-sp">
                It never was true that the people of the States of this Confederacy associated with each other as people. Never. It was wholly impossible for them ever to have done so. The people of each State, as constituting a separate and distinct political community, acting for itself in its sovereign capacity through the medium of a Convention, could become parties to a compact like that which
                was formed and which is now known and understood as the Constitution of the United States.
              </p>
              <p className="p-in-sp">
                Do you not know, Mr. Chairman, and is there a gentleman on this floor who does not know it as a historical fact, that the people, as a people, never did adopt the Constitution of the United States. It was not submitted to the people for ratification. It was submitted by the Federal Convention for ratification, to each of the separate sovereign and distinct States. And if additional
                evidence were wanting of the fact, it is to be found in the Constitution of the United States itself, in another provision. That Constitution declares that, whenever it should be ratified by nine of the States it should, as to them, become their Constitution. Then, sir, it was not submitted to the people of the United States, but it was submitted for ratification to each State as a
                State.
              </p>
              <p className="p-in-sp">
                If it had been submitted to the people of the United States as one people, whenever a majority of the whole people of the United States, or of the sovereign States constituting the United States, had ratified that instrument, it would have become the Constitution of the whole thirteen States, although the other four States might have given a unanimous vote against it.
              </p>
              <p className="p-in-sp">
                But, furthermore: Do we not know the fact that Rhode Island was not represented in that Federal Convention? And do we not know <PageNumber num={564} /> the other fact that neither Rhode Island nor North Carolina adopted that Constitution or ratified it, until nearly two years after it had gone into operation as to the other eleven States? Then how is it
                true, and how can the declaration be made, that the States were sovereign and independent at the time of its adoption, and that the people of each State agreed to associate with the people of the other States? Did the people of Rhode Island, which had no representative there, agree to associate with the people of Virginia in any form? No, sir. Did the people of North Carolina,
                although their State was represented in the Federal Convention, agree, in adopting the Federal Constitution, to associate with the people of the other States? No, sir. The action of the several States took place at different times.
              </p>
              <p className="p-in-sp">
                Some were separated by months and some by years, in this act of adoption and ratification. I can conceive of a social association of individuals, but I cannot, for the life of me, comprehend how the people of the State of Georgia could have associated with the people of the State of Massachusetts in any other way than as a separate and distinct political community. I undertake to say
                that what ever may be the ingenuity of gentlemen, however gifted they may be, it is not in the power of any man to show how such an association could take place otherwise than in the manner which I have indicated. If this principle be true, as was remarked by a gentleman this morning
                [<span className="persName">Mr. W. C. SCOTT</span>]
                , then, indeed, we are this day traitors and rebels against the common government of the common people, because we are here to-day inaugurating a secession and dissolution of this government, which, it is said, we have instituted as one great consolidated mass of people. And, sir, I hold that if this proposition be true, we are subject and liable every hour, to be arrested by His
                Majesty, Abraham the First, if he can procure any officer to come into the Old Dominion for the purpose of arresting us. And I maintain, further, that if this doctrine be true, the 8th resolution in this partial report is unquestionably not true, because there are many gentlemen on this floor, of the highest intelligence, who look on that 8th section as maintaining the doctrine of
                the right of secession, and I should like to know if the people are to secede from themselves. Then, indeed, we will have to adopt the doctrine of my friend from Frederick
                [<span className="persName">Mr. MARSHALL</span>]
                ,<span className="note" id="Note62"><span className="noteLabel">8</span>Mr. Conrad was the delegate referred to.</span> as I understood him to assert this morning, that the doctrine maintained in the 8th resolution is nothing but the doctrine of the right of revolution, the right of the oppressed, the right of the down-trodden, the right equally of the slave and the serf. And, sir, if the
                people of the Confederate States, in 1787, or of the United States, by whatever <PageNumber num={565} /> name you choose to call them, was an association of people, then, in fact and in truth, there is no other right to resist the wrong and resent injury and throw off oppression, except that which the God of nature has implanted in each man's bosom, and
                given him the right to exercise.
              </p>
              <p className="p-in-sp">
                But, sir, is it true that the rights of the States of this Confederacy rest only upon this natural right declared in our Bill of Rights? Is it true, sir, in any sense? It seems to be forgotten, as it seems to me, with all due respect for gentlemen who entertain different opinions from me, when the States adopted the Federal Constitution, and ratified the same; they delegated the
                exercise of certain sovereign powers to the common agent; but they did not thereby surrender their sovereignty. They could not have surrendered it; and, sir, this right of secession is neither a legal nor a Constitutional right. It is not derived from the Constitution, but it is a right that the States constituting this Union have never surrendered to any earthly power. It is the
                same right, sir, which the sovereigns of the Empires of the globe have of refusing to abide by a treaty. As, for instance, this treaty now existing between England and France. Is it possible that a disregard of this treaty by England or France is a disruption of the right and sovereignty of either as an independent nation. What would be the result of a refusal of one or either to
                obey that treaty? It may bring about such a state of feeling between the two Governments as may involve them in war. Sir, I don't say that the States of Massachusetts, of New York, and Ohio, may not declare war upon the State of Virginia, if she secedes. I do not maintain that doctrine; but I do maintain the position that Virginia, in ratifying the Federal Constitution, never
                surrendered her right to secede—never surrendered her right to exercise her sovereign power in any way she might see fit. It is true, sir, that as a Christian nation, or as a Christian people, we ought not to break any compact which we have made, for light and trivial causes; but I maintain that Virginia is responsible to no power on earth for exercising a right and a power that she
                never delegated, and which are necessary for the maintenance of her sovereignty.
              </p>
              <p className="p-in-sp">
                I have but a few words more to say. This amendment will add to the beauty, the symmetry, and the proportion of the first section in the partial report of the Committee of Twenty-one. That Committee, in this report, have asserted a principle, the converse of which, to some extent at least, I maintain is true; and whether asserted in a resolution, in the form in which it is now, or in
                the language of Jefferson <PageNumber num={566} /> and Madison, or any of the distinguished statesmen to whom I have referred, does not in any wise impair the power of the sovereign. It will destroy nothing that has gone before; it will impair nothing that is to follow; but it will give vitality to the principle, as I humbly conceive, which is
                enunciated in the eighth resolution. And while some of my State rights friends will vote against the amendment offered by the gentleman from Middlesex
                [<span className="persName">Mr. MONTAGUE</span>] , because there is a mere implication of the sovereign right of secession, they will not find themselves in any manner embarrassed by adopting my amendment.</p>
              <div className="speaker" id="sp1716"><span className="persName">A MEMBER</span>, [from his seat]—</div>
              <p className="p-in-sp">Report the amendment.</p>
              <p>It was accordingly reported.</p>
              <div className="speaker" id="sp1717"><span className="persName">Mr. R. E. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">
                I little expected, Mr. Chairman, that the resolution now under consideration could have occupied the attention of this Committee for almost two entire days in its consideration. If I had desired to select from the series of resolutions one that I supposed would pass this body unchallenged; if I had set myself to the task to prepare a resolution upon this subject, couched in terms
                free from just criticism, I would have selected this resolution from the series, and would have prepared it in the form in which it is announced.
              </p>
              <p className="p-in-sp">The gentleman from Northampton [<span className="persName">Mr. FISHER</span>]
                proposes to amend it. Now, the purpose of an amendment ought to be to remove an ambiguity where one exists—to make that clear which otherwise would be obscure. The gentleman proposes to substitute for the phrase "people of the States," the word "States." Will the gentleman from Northampton tell me what he means by "States"? If I recollect aright, sir, Mr. Madison, in the famous
                resolutions of '98, so often referred to in this debate, stated that the States were parties to the Federal compact. The use of the word "States" in that connection gave rise to a discussion whether it did not cover the doctrine of nullification; in other words, whether it did not imply that the Constitution of the United States was adopted by the States through functionaries; and
                the party putting that construction upon these famous resolutions, deduced from it authority for this doctrine of nullification. Mr. Madison himself was applied to upon the subject, and he explained that by the word "States" he meant the people of the States. Mr. Madison knew too much of the Federal Constitution; knew too well how that instrument was formed, to have fallen into a
                blunder by stating that it was formed in any other way than by the people of the States. I repeat, that upon this question—upon the use of the word "States"—an ambiguity arose in the construction of these famous resolutions, which ambiguity Mr. <PageNumber num={567} /> Madison removed by explaining the sense in which he used that term —explaining that
                by "States" he meant "the people of the States."
              </p>
              <div className="speaker" id="sp1718"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">Will my friend allow me to read a short extract?</p>
              <div className="speaker" id="sp1719"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">I have a letter of Madison in my own possession. The gentleman can reply.</p>
              <p className="p-in-sp">But, sir, to come to higher authority than that of James Madison, let us appeal to the record and see in what manner Virginia became a party to the Federal compact; let us see by that record whether the terms in which this resolution announces the fact, correspond with the letter.</p>
              <p className="p-in-sp">I read, sir, from the Code of Virginia, page 28, where gentlemen will find the instrument by which the Convention of Virginia ratified and adopted the Constitution of the United States.</p>
              <p className="p-in-sp">
                "We, the said delegates, in the name and in behalf," not of the State of Virginia, in the name and in "behalf of the people of Virginia, do, by these presents, assent to and ratify the Constitution recommended on the 17th day of September, one thousand seven hundred and eighty-seven, by the Federal Convention for the Government of the United States, hereby announcing to all those
                whom it may concern, that the said Constitution is binding upon"—not the State, but upon "the people, according to an authentic copy hereto annexed."
              </p>
              <p className="p-in-sp">
                I take it then, sir, that in propounding the proposition in the terms of the resolution, the Committee have propounded it in the words of the ratification. Doubtless, if the gentleman from Northampton had been a member of the Convention, and been allowed the liberty of amending the proposition, he would have amended it in the manner in which he proposes now to amend the language of
                this section. He never would have consented to have it stated that the work was done for and "in behalf of the people." No; that would be Federalism The gentleman from Northampton
                [<span className="persName">Mr. FISHER</span>]
                , would have said in his constitutional law, "in behalf of the State of Virginia," and, in the concluding clause, the gentleman would have said that it was "binding," not "upon the people of the State," but "upon the State." The gentleman shakes his head. Why binding upon the people? Why say the ratification was by and in behalf of the people? Why not insist that the ratification
                should be by and in behalf of the State? Why the gentleman is objecting to the very terms in which the Constitution was ratified—quarrelling with language selected for the purpose of meeting squarely the terms of ratification—with language intended to make the resolution true according to the record of the fact. If he objects to the employment of the word "resolution,"
                <PageNumber num={568} /> I submit it is conclusive that his objection would extend to the employment of the word "people" in the ratification. The resolution proposes to explain the manner in which the Constitution was ratified. How was it ratified? We are to search for evidence to enlighten us. Where are we to find the evidence? In the record of
                ratification—for if it is to be found any where, it is to be found there—and when we go to that record, we learn that the Federal Constitution was adopted by the people of the several States, ratified by the people of the several States, and by their act made binding on the people of the several States.
              </p>
              <p className="p-in-sp">
                Now, sir, I am content—perhaps that makes me a blue-light Federalist—with the language of the ratification. Had I been a member of that Convention, I would not have moved the amendment. I should have been content to have let it pass. But whatever opinion I would have entertained, had I been a member of the Convention, however much opposed I should have been to the employment of the
                word "people," when in after times I should come to announce the manner in which the Constitution was adopted, I think the record would constrain me to say, it was adopted by the people, and by the adoption made binding on the people of the several States.
              </p>
              <p className="p-in-sp">I submit, therefore, to the Convention that this, like the previous one, is a case of hypercriticism, too obvious to escape the observation of this intelligent Committee.</p>
              <div className="speaker" id="sp1720"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I will detain the Committee but a few minutes. I want to read one short extract from Mr. Madison:</p>
              <p className="p-in-sp">"It was formed, not by the governments of the component States, as the Federal Government for which it was substituted was formed. Nor was it formed by a majority of the people of the United States as a single community, in the manner of a consolidated government.</p>
              <p className="p-in-sp">"It was formed by the States, that is, by the people in each of the States, acting in their highest sovereign capacity."</p>
              <p className="p-in-sp">
                In all the twenty-eight pages of the revised Code, what does my friend find? that the Federal Government, acting for and on behalf of their constituents, who are the people, ratified the Constitution of the United States, and declared that it shall be binding upon the people of the State. Now without undertaking to go into a definition of what a State is—I read what Mr. Madison said
                about this, yesterday —does the Convention of Virginia say there that we hereby associate with the people of the other States? for that is what is declared in this resolution. Nothing is there of that sort. The authority which the gentleman from Fauquier cites to confute the proposition I have made, <PageNumber num={569} /> it seems to me, so far from
                sustaining him, is direct authority in my favor; for it shows that the people of Virginia, in their highest sovereign capacity, as Mr. Madison says, separate and distinct from each and every people of the other States in this Confederacy, adopted, not associated with, and ratified the Federal Constitution. But if they had said, or had meant to declare, that they thereby associated
                with the people of the other States, by the ratification and adoption of the Federal Constitution, then, I conceive that my friend would have found an argument which I would have had difficulty in meeting.
              </p>
              <div className="speaker" id="sp1721"><span className="persName">Mr. BRUCE</span>—</div>
              <p className="p-in-sp">I have but one word to say. I see the impatience of the Committee and I am not disposed to tax it. I, like the gentleman from Northampton [<span className="persName">Mr. FISHER</span>]
                am exceedingly jealous upon all subjects of States' rights. As a member of the committee, I say that this resolution met with my hearty concurrence. The people of each State is the sovereign of each State, and in this resolution, that part of it particularly which is directed to this subject, there is a double truth conveyed—first, that the States are sovereign, and that the people
                of each State are sovereign and the sovereignty of each State resides in the people of each State; and, therefore, the people of each State agree to associate with the people of the other States upon a footing of exact equality. When the resolution was originally introduced, it contained the words "peoples of the other States," which would have met the gentleman's objection
                completely. If I am allowed to state what occurred in the committee, the gentleman from Princess Anne
                [<span className="persName">Mr. WISE</span>] said that the word "peoples" was a Kossuth word, and he would, therefore, have nothing to do with it.</p>
              <div className="speaker" id="sp1722"><span className="persName">THE CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair would remind the gentleman that it is not in order to refer to what has passed in the Committee.</p>
              <div className="speaker" id="sp1723"><span className="persName">Mr. BRUCE</span>—</div>
              <p className="p-in-sp">I beg pardon; my unfamiliarity with the rules of legislative bodies must be my apology. I would apologize to the Committee, the Convention, and my friend from Princess Anne [<span className="persName">Mr. WISE</span>] .</p>
              <div className="speaker" id="sp1724"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">My friend need not apologize, so far as I am concerned. I say now it is a Kossuth word, so that he may refer to it.</p>
              <div className="speaker" id="sp1725"><span className="persName">Mr. BRUCE</span>—</div>
              <p className="p-in-sp">
                There is another reason for using the word people. The rights of the State, and the equality of the people thereof within the territories, is protected by this resolution. It was perfectly proper that the word "people" should be used, in order to hold up and sustain the inference which was drawn from it. The language is precise. It is unobjectionable, and there can be no exception to
                it; and I would not alter it by saying "States," because the word "States" <PageNumber num={570} /> is of imperfect meaning. We do not all understand it alike.
              </p>
              <div className="speaker" id="sp1726"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Reference is again made to this word. I had a battle about it elsewhere; and the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
                , forces me into battle with him here again—lungs or no lungs. Now, sir, I say that in the very article of ratification which he read and commented upon—using the word "people" in the sense that it is there used—it is synonymous with State. Ask a child to bound the State of Virginia, and he will begin with Maryland on the North, the Atlantic ocean on the East, North Carolina on the
                South, and the Ohio river on the West. What does it bound there? The State in any political sense? No—mere territory. Ask the child for the population of the State, and in round numbers he will tell you that the State has some 1,600,000 inhabitants. Do that land and that 1,600,000 people, men, women, children, white, black, old, young, constitute the State in the political sense.
                Why, sir, all the population of the territory and all the territory united with the population do not constitute the State in the political sense. The Legislature and the Governor and the Judiciary of the State have done a particular act, and you say that a State, because she has acted by all her functionaries, has done a particular act. But is that correct, in a political sense? No,
                sir! Mere municipalities have performed the act. The State has done it only indirectly, by creating a municipality which has done it. Sovereignty don't reside there, sir. Your Governor, your Legislature and your Judges combined, are not the repositories of your sovereignty. Who are the repositories of your sovereignty? The people! What people? All the men, women, children, white and
                black, in this State? No! The gentleman from Fauquier cites you to Mr. Madison—and whenever a Federalist ventures to exhume old letters of Mr. Madison, he makes as great a mistake of his meaning as he does of the Constitution of the United States, when he attempts to handle its construction—and with a great deal of self-satisfaction, he tells us that Mr. Madison used the word
                people—that the people ratified the Constitution. The people of Virginia! What people? What does he mean by people? He asks the gentleman from Northampton
                [<span className="persName">Mr. FISHER</span>]
                , what he meant by State. Why, sir, in a political sense, State is synonymous with people, and people are synonymous with State. How? You do not mean the people en masse; you do not mean lunatics as well as the sane; you do not mean infants as well as adults. You mean the politically organized people. You do not mean what was once done in California, when citizens and aliens, Chinese
                and Chileans, Jew, Greek and Gentile, all of all sorts, were allowed, first through the <PageNumber num={571} /> act of a military commander, to usurp civil authority, and establish a territorial government; and then to proclaim from the camp a call for a Convention—a Convention composed of all the odds and ends of the earth. That was not a people in
                any political sense. That was the wrong done in that very case. It was an unorganized mass of human beings. A mere mass of human beings don't make a people in a political sense. What is it that makes a people? It is political organization, in the form of government or empire wielding government, that makes a people? Who are the people of Virginia? Her organized legal voters. They are
                the people in their highest sovereign capacity. They are the people meant by Mr. Madison. They are the people that made your Constitutions, State and Federal. They are the people in their highest sovereign capacity that constitute a State; and when you say that the people either create or destroy—the people acting in their highest sovereign capacity—and use the language of Mr.
                Madison, that they either create and destroy any thing, you express nothing more, nothing less than that the State, which they form, has done the one act or the other. Mr. Madison, himself, in the passage which was read here yesterday, is authority for these distinctions. The State is sometimes designated as the territory. The State is sometimes designated as a mass of inhabitants.
                The State is sometimes referred to as synonymous with her mere municipal government; but the State proper—the State in her highest sovereign capacity, is the State of an organized people, and an organized people is the State. When the gentleman from Fauquier
                [<span className="persName">Mr. SCOTT</span>] reads that clause of the ratification of the Constitution to me and says that the Constitution was ratified by the people, and asks if it was ratified by the State, I answer him "yes," because the word people, in that clause, is synonymous with State, and the word State is synonymous with the word people.</p>
              <p className="p-in-sp">
                But it is not worth while to put veils over our faces when we are looking at the facts and principles we are discussing. There is a party in this country that hold to the doctrine that these things were all done by the people en masse, consolidated—and, gentlemen, that belong to a party that never did look to the people, but to power concentrated and consolidated. When the word State
                means people, when the word people, in their highest sovereign capacity means State, it is useless to employ both. Why not say "State," meaning the people in their highest sovereign capacity, and not "people of the State?" If the people do it they do it in their highest sovereign capacity. Why do gentlemen persist in doing this? Why is it, except to keep up this idea that the
                separate State sovereignties have been fused into one <PageNumber num={572} /> sole and consolidated sovereignty, placed in the hands of one consolidated people? Why then, I say, do you want to use both phrases, when the word "State," itself, would be a comprehensive term which would embrace both? Why hold on to this word people, but to continue this
                idea of consolidation?
              </p>
              <div className="speaker" id="sp1727"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">
                The Chair wishes to correct an order issued yesterday under a misapprehension. When a division of the question was called for, and the Chair decided that it was not in order for the gentleman to call for such division, the Chair was under the impression that he was acting in accordance with parliamentary rule, and was governed by the Manual. Since then, the attention of the Chair has
                been drawn to a rule of the house, which says, "any member may call for a division of a question."
              </p>
              <p>The vote was then taken on the motion to strike out the first resolution of the Committee on Federal Relations, and insert the substitute of the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>] .</p>
              <p>
                The motion was lost—ayes 14, noes 113, as follows :<span className="note" id="Note63"><span className="noteLabel">9</span>Graham's name probably should be only among the affirmative votes, as it is in the Journal.</span>
              </p>
              <p>YEAS—Messrs. Blakey, Boisseau, Conn, R. H. Cox, Fisher, Graham, Gregory, John Goode, Isbell, Kent, Montague, Morris, Wise, Woods-14.</p>
              <p>NAYS—Messrs. Janney [President]
                , Ambler, Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Blow, Jr., Boggess, Borst, Boyd, Branch, Brent, Brown, Bruce, Burdett, Burley, Byrne, Cabell, Campbell, Caperton, Carlile, Carter, Chambliss, Chapman, Coffman, R. Y. Conrad, C. B. Conrad, Couch, J. H. Cox, Critcher, Custis, Deskins, Dorman, Dulany, Early, Echols, Flournoy, French, Fugate, Garland, Gillespie, Graham,
                Gravely, Gray, Goggin, T. F. Goode, Ephraim B. Hall, Hammond, Haymond, Hoge, Holladay, Hubbard, Hughes, Hull, Hunton, Jackson, Marmaduke Johnson, Peter C. Johnston, Kilby, Lawson, Lewis, McComas, McGrew, McNeil, Chas. K. Mallory, J. B. Mallory, Marshall, Marr, Marye, Sr., Maslin, Masters, Miller, Moffett, Morton, MOORE, Neblett, Orrick, Osburn, Parks, Patrick, Porter, Preston, Price,
                Pugh, Rives, Robt. E. Scott, Wm. C. Scott, Seawell, Sharp, Sheffey, Sitlington, Slaughter, Southall, Speed, Spurlock, Staples, A. H. H. Stuart, Chapman J. Stuart, Strange, Summers, Sutherlin, Tarr, Tayloe, Thornton, Tredway, R. H. Turner, F. P. Turner, Waller, Whitfield, Willey, Wilson, Wysor-113.
              </p>
              <p>The question then recurred on the adoption of the first resolution of the committee, which was then adopted, as follows:</p>
              <p>
                "1. Be it resolved and declared by the people of the State of Virginia in Convention assembled, That the States which composed the United States of America, when the Federal Constitution was formed, were independent sovereignties, and in adopting that instrument the people of each State agreed to associate with the people of the other States, upon a footing of exact equality. It is
                the duty therefore, of the common Government to respect the rights of the States and the
              </p>
              <PageNumber num={573} />
              <p>equality of the people thereof, and, within the just limits of the Constitution, to protect, with equal care, the great interests that spring from the institutions of each."</p>
              <p>The Clerk then proceeded to read the second resolution, as follows:</p>
              <p>
                "2. African slavery is a vital part of the social system of the States wherein it exists, and as that form of servitude existed when the Union was formed, and the jurisdiction of the several States over it within their respective limits, was recognized by the Constitution, any interference to its prejudice by the federal authority, or by the authorities of the other States, or by the
                people thereof, is in derogation from plain right, contrary to the Constitution, offensive and dangerous."
              </p>
              <div className="speaker" id="sp1728"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman—I move as an amendment that after the word "exists" in the second line, to insert "and of the political system of the United States," so that the proposition will then read "African slavery is a part of the social system of the States wherein it exists, and of the political system of the United States." I do not propose to strike out anything but simply to insert these
                words "and of the political system of the United States."
              </p>
              <p>The Chairman stated the question.</p>
              <div className="speaker" id="sp1729"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                My object in offering the amendment is this: I presume it will not interfere with the sense of the Committee in other respects. In fact, since the report was made, this amendment was suggested to my mind with a view of meeting one of the positions of Abolitionism or Black Republicanism. They assert that the Federal Constitution no where, in no respect, recognizes the institution of
                African slavery. They insist that the Constitution of the United States no where, in no respect, recognizes property in slaves, or the right to have that property recognized in the common territory, or places ceded, or the District of Columbia, out of the States where it exists. Now, undoubtedly, there can be no difference of opinion as to the fact that it is made a part of the
                political system of the United States. It is made so in no less than four distinct clauses of the Constitution of the United States. First, in the clause that recognizes the political relation of slavery to the Federal government in the apportionment of representation and direct taxation. There they are recognized as "other persons." Secondly, they are recognized in that clause which
                provides that capitation and other direct taxation shall be apportioned among the several States, according to the census; that census excluding two-fifths of certain persons because they are "persons held to service or labor." These two clauses recognize them in the sense of persons more than in the sense of property. But the next clause, the third clause to which I am
                <PageNumber num={574} /> referring, expressly recognizes the property in "persons held to service or labor." For, it is said, that "a person held to service or labor in one State, fleeing into another State, shall be delivered up according to the forms of law." It is founded on the fact that they are property, or, in other words, "held to service or
                labor." Thus, as persons, to be enumerated as persons, to be counted for representation as persons, to be counted according to the Constitution for direct taxes they are recognized; and as property, to be delivered up because they are held to service or labor. And they are also recognized in the Constitution of the United States, and we in passing this resolution ought not to omit
                this important point, when the point is expressly made by our adversary, that these persons as property, are not recognized by the Constitution of the United States.
              </p>
              <p className="p-in-sp">
                Then in avowing the fact that it is a part of the social system of the States wherein it exists, it seems to me not only important, but necessary that it should be claimed as a vital part of the political system of the United States. I say political as contra-distinguished from social, because these persons, whilst they do enter into the social system of the States, do not enter into
                the social system of the United States, but they enter into the political system of the United States. By policy, by Constitution, by compact, by statute, according to the requirements of the Constitution, they are made a part of the political system of the United States. This amendment, sir, I put in, submitting that clause as politic, as important, as necessary to be asserted in
                this declaration.
              </p>
              <div className="speaker" id="sp1730"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">Mr. Chairman the amendment now proposed by the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>]
                is obnoxious, and in a still higher degree obnoxious when, in addition to the objections to the amendments already rejected by the Committee, this has a single objection which could not have been applied to them. It proceeds, in the first place, from an utter misconstruction of the clause as it now stands; it is, in the second place, entirely unnecessary and incongruous to the
                proposition of the section; and in the third place it is untrue in point of fact. I said, sir, it proceeds from a misconstruction of the clause as it now stands. The gentleman assumes that this does not affirm the recognition by the Federal Government of the institution of slavery. The gentleman will find, by looking to the fourth and fifth lines, that it expressly asserts, "was
                recognized by the Constitution;" that is, that slavery was recognized by the Constitution. It, therefore, contains all that the gentleman claimed should be <PageNumber num={575} /> asserted in it, so far as the recognition of the institution of slavery is concerned.
              </p>
              <p className="p-in-sp">Again, sir, it is utterly unnecessary and incongruous, because the proposition of the second section is that "any interference to its prejudice by the Federal authority or by the authorities of the other States, or by the people thereof, is in derogation from plain right, contrary to the constitution, offensive and dangerous."</p>
              <p className="p-in-sp">
                Well, sir, what has that to do with this proposition to insert here that it is a part of the political system of the United States? When we assert that it belongs to the State and was recognized by the Federal Constitution and deduce from that the proposition that any interference with it is offensive, dangerous and contrary to our rights, why insert this or any declaration as to its
                connexion with the Federal Constitution or with the United States? Has it any thing to do—any pertinency whatever to the proposition in this resolution?
              </p>
              <p className="p-in-sp">
                And, again, I said it was untrue in point of fact. How would the section read if you insert the words that the gentleman proposes? "African slavery is a vital part of the social system of the States wherein it exists." And the gentleman would have us affirm that African slavery is a vital part of the Constitution of the United States? Why, sir, what does he mean by that? Suppose
                every State in this Union should abolish the institution of slavery, would the Federal Government die ipso facto? Is it a vital part in any sense of the Federal Constitution? Or is it any part? I deny that it is any part of the Federal system or of the United States Government. We have always maintained that it is an institution of the States. It has never been pretended before, if
                the gentleman means to pretend now, that it is a part of the political system of the Federal Government. It is recognized as existing in the States, and the States have, as I said before, representation based upon it. It is recognized in the clause providing for the reclamation of fugitive slaves, and in other clauses of the Constitution. All that is fully satisfied by the
                affirmation here that it is recognized by the Constitution of the United States. And, sir, I desire, without detaining the Committee at all, to repeat what I said at first, that this, amendment is unnecessary and incongruous, that everything that the gentleman has stated as necessary and proper to appear, does already appear in the section, and if his amendment is adopted it will
                make this Committee affirm that which, with all due respect to the gentleman, I say is an absurdity.
              </p>
              <div className="speaker" id="sp1731"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Will the gentleman please repeat so that I may clearly comprehend them—his three propositions—again.</p>
              <PageNumber num={576} />
              <div className="speaker" id="sp1732"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">I stated that the proposition of the gentleman in the first place, was based upon a misconstruction of the section he proposes to amend. Secondly, that it was unnecessary and incongruous; and thirdly, that it was not accurately true, if his amendment were inserted.</p>
              <div className="speaker" id="sp1733"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Now I understand the gentleman, and I will turn his argument, uttered with oracular precision, against himself. In the first place it is insisted that the amendment is founded upon misconstruction of the sentences contained in the report. Well, that means, if it means anything, what his second proposition means, and he need not have been so Dalgetty-like as to divide into three, when
                there need have been only two propositions.
              </p>
              <div className="speaker" id="sp1734"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">The gentleman does not understand me. I stated that it was a misconstruction of the section in supposing that it does not recognize the institution of slavery as recognized by the Constitution.</p>
              <div className="speaker" id="sp1735"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I did not misunderstand the gentleman at all. What does that mean? He means to say that I misconstrued the section in not supposing that it did already what the amendment proposes. He supposes that I think the section did not allege that slavery is recognized by the Constitution. And he cites me to the fifth line to show that the section does aver that slavery is recognized by the
                Federal Constitution. I am not content with that. I am not content to say that the Federal Government or the Constitution simply recognises slavery. I want to aver the fact whether it be a vital part or not, that it is, a part not merely recognized, not merely known to the Constitution, but that it is incorporated in it, and made an essential part at least of that instrument. I know
                the gentleman is somewhat inclined to mince terms with the North. I am not so inclined. When I have got it right in the teeth, in express terms, I am not going round outside to take it by implication. When I have got it as a substantial proposition in the Constitution, I am not going to take it as an incidental thing, merely touching the Constitution.
              </p>
              <p className="p-in-sp">
                Now the word "recognized" does not assert that it is a part. The United States recognizes England as a Government, but England is not a part of the United States. The word "recognized" is a very different thing from the expression "is a part of"—so that there was no misconstruction. I stated the phrase and averred that the section was content merely with an avowed recognition. I
                wanted something stronger, and therefore I proposed to assert a recognition of the fact <PageNumber num={577} /> that it is a part of the Government—a part of the political system of the United States.
              </p>
              <p className="p-in-sp">
                The second proposition of the gentleman is, that it is unnecessary and incongruous. Why did he say it was unnecessary? Because he says it asserts the same thing as already asserted in the section. Sir, if it asserts the same thing, it is not incongruous, for to be incongruous, it would have to assert something that did not belong to the section--something that was contradicted by, or
                inconsistent with the section. The gentleman's second proposition is, therefore, inconsistent with itself. The thing cannot be unnecessary, because it would be a mere pleonasm, merely asserting the same thing rather than incongruous to.
              </p>
              <p className="p-in-sp">
                Now, to assert that it is a part of the political system of the United States is to assert more than to assert that it is recognized by the Constitution. That answers the plea of its being unnecessary, and to assert that it is a part of the system of the United States is not incongruous with the assertion that the Constitution of the United States recognizes it. So much for the
                second objection.
              </p>
              <p className="p-in-sp">
                As to the third, the gentleman says—and mark you, oh, ye now assembled—oh, ye now called together to debate for the nation, to decide for the nation, its destinies, its life or its death—remember, that the chairman of its Committee of twenty-one on Federal Relations tells you that slavery is not a vital part of the whole United States system. When nothing else has so divided its very
                vitals, has so pierced its heart, has so actually torn it asunder as slavery, and that it is not vital! The gentleman says "suppose all the States in the Union were to abolish it to-morrow, would that kill the United States?" I ask him in turn, suppose the people of Virginia were to abolish it to-morrow, would Virginia die? If it is a vital part of the social system of Virginia,
                would she not die?
              </p>
              <div className="speaker" id="sp1736"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">Her social system would.</p>
              <div className="speaker" id="sp1737"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                And in attempting to abolish slavery in the United States the whole political system of the United States has been dissolved and is now as dead as the social system of Virginia would be, if she had abolished slavery. The gentleman has absolutely forgotten what he is about. If he had slept like Rip Van Winkle, for 20 years, and just woke up, he could not have made a worse mistake.
                Gentlemen, I now inform you all, that this chairman of the Committee of twenty-one, gives us, as his opinion, that slavery is not a vital part of the political system of the United States.
              </p>
              <p>The question being now upon the amendment offered by <span className="persName">Mr. WISE</span>, <span className="persName">Mr. WOODS</span> called for the yeas and nays.</p>
              <PageNumber num={578} />
              <p>The yeas and nays were ordered.</p>
              <p>
                The question was taken and it was decided in the negative—yeas 37, nays 91, as follows:<span className="note" id="Note64"><span className="noteLabel">10</span>The Journal refers to 37 affirmative votes, and adds Parks to the names here. The Journal refers to and lists 90 negative votes.</span>
              </p>
              <p>YEAS—Messrs. James Barbour, Blakey, Boisseau, Borst, Branch, Cabell, Chambliss, Chapman, Coffman, Conn, Richard H. Cox, Fisher, Forbes, Garland, Graham, Gregory, Jr., John Goode, Jr., Hunton, Isbell, Kent, Kindred, Lawson, Miller, Montague, Morris, Morton, Neblett, Richardson, Seawell, Sheffey, Speed, Strange, Franklin P. Turner, Whitfield, Wise, Woods-37.</p>
              <p>NAYS—Messrs. John Janney [President]
                , Armstrong, Aston, Baldwin, Alfred M. Barbour, Baylor, Berlin, Blow, Boggess, Boyd, Brent, Brown, Bruce, Burdett, Burley, Byrne, Campbell, Caperton, Carlile, Carter, C. B. Conrad, R. Y. Conrad, Couch, Jas. H. Cox, Critcher, Custis, Deskins, Dorman, Dulany, Early, Echols, Flournoy, French, Fugate, Gillespie, Gravely, Gray, Goggin, Ephraim B. Hall, Hammond, Haymond, Hoge, Holladay,
                Hubbard, Hughes, Hull, Jackson, Marmaduke Johnson, Peter C. Johnston, Kilby, Lewis, McComas, McGrew, McNeil, Charles K. Mallory, James B. Mallory, Marshall, Marr, Marye, Sr., Maslin, Masters, Moffett, MOORE, Orrick, Osburn, Patrick, Porter, Preston, Price, Pugh, Rives, Robert E. Scott, William C. Scott, Sharp, Sitlington, Slaughter, Southall, Spurlock, Staples, A. H. H. Stuart, C. J.
                Stuart, Summers, Sutherlin, Tarr, Tayloe, Thornton, Tredway, Waller, Willey, Wysor-91.
              </p>
              <p>So the amendment was disagreed to.</p>
              <p>The second resolution reported by the Committee on Federal Relations was then adopted.</p>
              <div className="speaker" id="sp1738"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">As the hour of six has arrived, and I think we have reached a point in our action at which it will be very well to pause, I move that the committee rise.</p>
              <p>The motion was agreed to. The Committee accordingly rose, and the President having resumed the Chair, the Chairman, <span className="persName">Mr. SOUTHALL</span>, reported progress.</p>
              <p>And then, on motion of <span className="persName">Mr. ECHOLS</span>, the Convention adjourned.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v2.2.11">
            <PageNumber num={579} />
            <h2><span className="headingNumber">1.11. </span><span className="head">THIRTY-EIGHTH DAY</span></h2>
            <div className="dateline">Friday, <span className="date">March 29</span></div>
            <p>The Convention met pursuant to adjournment at 10 o'clock, A. M. Prayer by Rev. Mr. Binford.</p>
            <div className="section" id="vsc1965.v2.2.11.1">
              <h3><span className="headingNumber">1.11.1. </span><span className="head">INSTRUCTION TO COMMITTEE ON FEDERAL RELATIONS</span></h3>
              <div className="speaker" id="sp1739"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">I offer the following resolution:</p>
              <p>Resolved, That the Committee on Federal Relations be requested to report as soon as practicable on the resolution submitted to them on the 20th instant, instructing inquiry into the expediency of reporting to the Convention two ordinances, &amp;c.</p>
              <div className="speaker" id="sp1740"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">I doubt the propriety of proceeding to any business till the Chair be satisfied that there is a quorum present. I doubt very much whether there is a quorum present. If the Chair be satisfied that there is, I will not move a call of the House.</p>
              <div className="speaker" id="sp1741"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair is inclined to think there is a quorum present, but cannot state it positively.</p>
              <p>The question was taken and the resolution was adopted.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.11.2">
              <h3><span className="headingNumber">1.11.2. </span><span className="head">PROCEEDINGS OF MEETING IN GRAYSON COUNTY</span></h3>
              <div className="speaker" id="sp1742"><span className="persName">Mr. PARKS</span>—</div>
              <p className="p-in-sp">With the consent of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , I present the proceedings of a meeting held in Grayson county, and after I shall have read them, I will submit a few remarks to the Convention.</p>
              <p><span className="persName">Mr. PARKS</span> read the proceedings as follows:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>Whereas, The Black Republican party, now holding the ascendancy in the non-slaveholding States, have rejected all overtures from Virginia, and from the South generally, looking to a peaceable solution of our national difficulties, and the re-construction of the Union in its original purity; and,</p>
                  <p>Whereas, The policy indicated in the Inaugural address of President Lincoln, if carried out, must result in bloodshed and ruin, and,</p>
                  <p>
                    Whereas, This fanatical spirit and policy has forced our sister States of the South to resume the powers delegated by them to the Federal Government, and establish an independent Southern Confederacy; and Virginia is now left to choose between a union with our enemies of the North, or our friends of the South; and believing, too, that we are identified in interest and feeling, as
                    we are in our institutions, with the Southern Confederacy; therefore,
                  </p>
                  <PageNumber num={580} />
                  <p>Resolved, That Virginia should immediately resume the powers delegated by her to the Federal Government, and unite her destiny with that of the seceded States of the South.</p>
                  <p>Resolved, That we are opposed to any measure calculated to procrastinate the prompt and efficient action of Virginia, and, therefore disapprove of the call of a Border States Convention before secession.</p>
                  <p>Resolved, That our delegate in the State Convention, now assembled in Richmond, be, and is hereby instructed, to use his efforts to procure the immediate passage of an ordinance of secession, and in the event of a failure in this, that he advocate an immediate adjournment, sine die, of that body.</p>
                  <p>
                    Resolved, That we are sternly and unalterably opposed to the introduction of any measure of local policy, calculated to disturb and distract the deliberations of our State Convention, now assembled; and viewing in that light the recent action of certain members of that body, in the introduction of the question of taxation, we hereby reprobate their action, and protest against all
                    such efforts, feeling, as we do, that the Convention was called to deliberate upon our national difficulties, and that its action should be rigidly confined to that subject, and that alone.
                  </p>
                  <p>
                    Resolved, That Governor John B. Floyd's triumphant vindication of his course, as an officer of the late Federal Government, in the alleged swindle of Russell, Majors &amp; Co., and his honorable acquittal by the Circuit Court of Washington city, affords us unfeigned pleasure, and we view with utter contempt and abhorrence this effort on the part of the Black Republican party to
                    place a stigma upon the character of a patriot, a faithful officer of the government, and one of Virginia's most gifted and distinguished sons.
                  </p>
                  <p>Resolved, That the Secretary of this meeting be required to forward</p>
                  <p>a copy of these proceedings to our delegate in the Convention. Resolved, That the proceedings of this meeting be published in the</p>
                  <p>Mountain News, and that the Richmond papers be requested to copy. On motion, the meeting adjourned.</p>
                  <p>JAMES DICKEY, Pres't.</p>
                  <p>M. J. JoNEs, Sec'y.</p>
                </div>
              </div>
              <div className="speaker" id="sp1743"><span className="persName">Mr. PARKS</span>—</div>
              <p className="p-in-sp">
                I also hold in my hand a petition signed by four hundred voters of the county of Grayson, asking for the passage of an ordinance of secession. I may remark, Mr. President, that the gentlemen who took part in that meeting are well known to me. Its presiding officer is a man well stricken in years. He has figured <PageNumber num={581} /> considerably in
                the political arena of the country, and has ever been a stern, steadfast Whig and a strong, devoted Union man. Believing, as he does, however, that the time has come when Virginia must act and act promptly, he has given his assent to the idea that Virginia should resume her original rights. The Secretary of the meeting I also know to be a gentleman of character and standing—the
                Editor of the "Mountain News." To show that it was not a party meeting, I may state that that gentleman belongs to the Douglas wing of the Democracy. Others who took part in the meeting are men of veracity and high standing. The people of my county called me from my home, where I was asking for no office at their hands, and without my canvassing for votes they elected me as their
                delegate to this Convention, knowing my sympathies and feelings to be in favor of the Union of the United States. But they knew that whenever the efforts inaugurated by our Legislature should be exhausted, and when it became evident that the policy of the Federal Government was not to yield one inch to Southern rights, they could then act; and now, sir, they have left their quiet
                homes, the farmer has left his farm and the boys are rushing from the mountain gorges to demand their rights. They feel aggrieved. They are anxious that this Convention should take prompt action. Ever since I have been here I have been one of those who have urgently contended for prompt action. I can only say that I respect the views of my constituents, and, so far as I can do so
                here, I will carry out what I believe to be their wishes. I hold the doctrine of the right of instruction. I believe the people have the right to instruct their representative and it is his duty to obey their instructions when fairly expressed. I know that this petition bears the signatures of those who are warm Union men, and who now make this demand. I will not trespass longer on
                the patience of the Convention or on the courtesy of my friend from Monongalia.
              </p>
              <p>The proceedings and petition were referred to the Committee on Federal Relations.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.11.3">
              <h3><span className="headingNumber">1.11.3. </span><span className="head">CLOSE OF DEBATE</span></h3>
              <div className="speaker" id="sp1744"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">With the consent of the gentleman from Monongalia, I propose, at the request of a number of gentlemen of this Convention, to offer the following resolutions:</p>
              <p>Resolved and ordered : First, That on Tuesday, the 2d day of April next, at 12 o'clock, noon, all debate in the Committee of the Whole on the report from the Committee on Federal Relations shall terminate, and the Committee shall at once proceed to vote on the</p>
              <PageNumber num={582} />
              <p>propositions before it; giving five minutes to the member offering any amendment, and the same time to one member opposing it, for explanation.</p>
              <p>Second, That hereafter no member of the Committee of the Whole shall be allowed to speak more than once on the same proposition. On that resolution I move the previous question.</p>
              <div className="speaker" id="sp1745"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I take it for granted the gentleman will withdraw that, as there is a thin House now.</p>
              <div className="speaker" id="sp1746"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">I decline to withdraw it.</p>
              <div className="speaker" id="sp1747"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">Is there any mode known to the Chair whereby the minority may place on record their protest against this attempt to gag the minority of this body, and the majority of the people of Virginia, in expressing their opinions here?</p>
              <div className="speaker" id="sp1748"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair can only administer the rules which the Convention has provided for its government, and does not know any mode by which the object of the gentleman can be attained.</p>
              <div className="speaker" id="sp1749"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">Under the rules adopted by the Convention, does not this resolution lie over for one day?</p>
              <div className="speaker" id="sp1750"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair is not aware of any such rule. The first thing to be done is to know whether the call for the previous question will be sustained.</p>
              <p>The call for the previous question was sustained, and the PRESIDENT stated that ten minutes debate was in order.</p>
              <div className="speaker" id="sp1751"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">Under what rule is it, that ten minutes debate is in order?</p>
              <div className="speaker" id="sp1752"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">A rule adopted by this Convention for its own government.</p>
              <div className="speaker" id="sp1753"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">Some days ago?</p>
              <div className="speaker" id="sp1754"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">No, in the last Convention, and re-adopted here.</p>
              <div className="speaker" id="sp1755"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">
                I shall probably not occupy the attention of the House for ten minutes. I rise to enter my solemn protest against this spirit of tyranny which a majority of this body is endeavoring to practise over the minority. The same spirit which induces me, as a Southern man, to resist the tyranny of a Northern majority, equally prompts me to resist this as outrageous tyranny, sought to be
                exercised in this body by a majority over the minority. This movement shows the want of confidence of the majority in the position they occupy. What have we witnessed? We have witnessed the deliberations of a Committee constituted by the Chair, on which the majority in this body are largely represented, and they discussed all these vital questions for weeks, with the doors of the
                Committee closed. Finally we have their report <PageNumber num={583} /> brought in here, and the gentleman from Kanawha
                [<span className="persName">Mr. SUMMERS</span>] , occupies two days in delivering his opinion; the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] , occupies three days; and the chairman of the Committee [<span className="persName">Mr. CONRAD</span>]
                , exhausted one day and part of another in unfolding his position. And now this majority asks to silence, not only the minority of the Committee, but the minority of the House. I say that such conduct ought to be held up to the indignant reprobation of every freeman in the Commonwealth. I do not, myself, seek to occupy much of the time of the House, but I know that there are members
                of the minority, distinguished members, from whom the Convention and the country want to hear. They should have that opportunity. Why should there be this hurry? Is it because the majority are afraid to hear the truth? Is it because they are afraid to hear the voice of the people. When members of the majority have been allowed several days to deliver their opinions, why is it that
                the Chairman of the Committee, which had been for nearly thirty days discussing the question, would shut out all answer here? With all respect to the chairman, I would say that there are members of this Convention who have not been heard, who from their position before the State and country, are entitled to be heard, and whose wisdom is as marked and acknowledged in this Commonwealth
                as that of even the chairman of the Committee on Federal Relations.
              </p>
              <p className="p-in-sp">
                Mr. President, it is not so much for my own rights here that I speak, as it is for the rights of my people and the rights of the minority. I should like to be heard on this report, if an opportunity is to be presented. But if the will of this dominant party is to be carried out, if they are determined to gag the minority and not permit the people to be heard, I shall have to bow to
                the omnipotence of a tyrannical majority—but I will appeal to the people.
              </p>
              <div className="speaker" id="sp1756"><span className="persName">Mr. STAPLES</span>—</div>
              <p className="p-in-sp">Mr. President, I wish to say one word on this proposition. I am a Union man; I have been a Union man; and I expect to add with what is known as the Union party in this Convention; but I must be permitted to say that I still must regard this movement as an outrage which no deliberative body representing a free people, ought for a moment to tolerate.</p>
              <p><span className="persName">Mr. WISE</span> obtained the floor.</p>
              <div className="speaker" id="sp1757"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The hour has arrived for the execution of the order of the day.</p>
              <div className="speaker" id="sp1758"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">I move to suspend the execution of the order of the day.</p>
              <div className="speaker" id="sp1759"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair is of opinion that that motion cannot <PageNumber num={584} /> be entertained without suspending the rule directing us to go into Committee.</p>
              <p>The Convention, therefore, resolved itself into Committee of the Whole [<span className="persName">Mr. PRICE</span> in the Chair] , and resumed the consideration of the report of the Committee on Federal Relations, the question being on the third resolution, as follows:</p>
              <p>
                "3d. The choice of functionaries of a common Government established for the common good, for the reason that they entertain opinions and avow purposes hostile to the institutions of some of the States, necessarily excludes the people of one section from participation in the administration of Government, subjects the weaker to the domination of the stronger section, leads to abuse,
                and is incompatible with the safety of those whose interests are imperilled; the formation, therefore, of geographical or sectional parties in respect to Federal politics is contrary to the principles on which our system rests, and tends to its overthrow."
              </p>
              <p><span className="persName">Mr. RIVES</span>, of Prince George and Surry, obtained the floor and addressed the Convention until the hour of 2 arrived, when the Committee took a recess until 4 o'clock P.M.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.11.4">
              <h3><span className="headingNumber">1.11.4. </span><span className="head">EVENING SESSION</span></h3>
              <p>The Committee again met at 4, when <span className="persName">Mr. RIVES</span> resumed and concluded his remarks.</p>
              <p>The speech will appear in full hereafter.</p>
              <div className="note" id="Note65">
                <span className="noteLabel">1</span>1. This speech by Mr. Rives has not been found in the Enquirer. The abstract below was printed in the Daily Dispatch of March 30:
                <p>
                  <span className="persName">Mr. RIVES</span>, of Prince George, said it was not his purpose to answer the arguments of the various speakers on this floor, but to point out what he conceived to be the true line of policy for Virginia to pursue. He took the position at the outset that the Union ought not to be dissolved. The politicians had for twenty-five or thirty years been endeavoring
                  to break up the Union, receiving the almost universal condemnation of the American people until the recent movement in the Cotton States. He proposed to show that the whole matter could be settled and the Union preserved. He then went on in his peculiar way to discuss the causes which led to the present state of affairs, and said it behooved the people to keep an eye upon the
                  Catilines and Caesars, North and South. Alluding to the settlement of agitating questions in the past, he said that the men composing the former councils were made of very different stuff from men of these days. Point an empty gun at secessionists now, they would dodge. They went down to Fortress Monroe, not to see if the guns were loaded, but to see if they were pointed towards
                  the land. Passing rapidly along in his argument, <span className="persName">Mr. RIVES</span> touched upon the tariff of 1828, and the nullification of South Carolina. The Union men said then as they said now—South Carolina, stand back; General Jackson, stand back! They thus acted as mediators, and saved the country. The point he made, as the reporter understood it, was that if wise
                  counsels could prevail, a similar result would follow the present efforts. The argument that the best way to reconstruct the Union was for Virginia to go out of it, was answered by supposing the case of a little girl, five years old, going into an "apothecary" store and buying a doll; her sister Mary, two years older, takes it and breaks off an arm; the first runs to her father,
                  and says "See here, papa, sister Mary has broken my doll!" The father replies, "Go away, child; break it all to pieces, and then bring it to me and I will mend it!"
                  [Laughter.] His [<span className="persName">Mr. RIVES</span>'s] idea was to mend the Union before any more of the limbs were broken off.</p>
                <p>
                  The course of William L. Yancey was commented on by the speaker with considerable severity, and an extract from his Slaughter letter produced to show that in 1858 his purpose was to dissolve the Union; while Andrew Johnson, of Tennessee, was quite as unequivocally complimented. Caleb Cushing came in for a share of denunciation, <span className="persName">Mr. RIVES</span> expressing a
                  doubt whether he would have supported Douglas for the Presidency if he had been nominated by a Convention over which Cushing presided. He preserved the line of argument and illustration with which all are familiar who have listened to his campaign speeches.
                </p>
                <p>
                  A reference, he said, had been made to his gestures. The mainspring of his action was Union, and it was the glory of the Union that gave force to his action here. When he saw a man stand off and carefully arrange a mass of papers before him, and proceed to bring forth a string of abstractions that would craze a philosopher to investigate, he thought that such a man was not the
                  statesman nor the lawyer for him. He proceeded then to argue the question of secession, reading evidence from speeches of Mr. Rhett and others in the Southern Convention, to show that Southern men had been endeavoring for years to bring about a dissolution of the Union. He would not say that they were pulling with the abolitionists, in couples, but that they were striving for the
                  same object. He then read from the Congressional reports of 1842, showing that a petition then came from Massachusetts, praying for separation. A resolution to censure Mr. Adams for offering the petition was introduced, and a large majority of the members from the now seceded States voted against laying it on the table. He alluded also to the course of Mr. Wise in battling for the
                  Union on that occasion, and he thought it would be well for the people of Virginia, with this record before them, to follow such suggestions and advice now. He would take up the weapons with which the member from Accomac fought disunion in 1842, and fight it to the very death. He predicted that the ballot-box would send forth a sound that would strike unmitigated sorrow to the
                  hearts of secessionists in Virginia, if it did not cover them with shame. <span className="persName">Mr. RIVES</span> indulged in some sharp thrusts at the secessionists in the Convention, which extorted a call of "order" from the Chair; but the gentleman's deafness prevented his hearing or heeding it. He went from this branch of his subject to the consideration of the grandeur of the
                  American Republic, and its position before the world, and, among other striking figures of speech, asked if they wanted the icy arms of death to encircle the fair form of the Goddess of Liberty. He quoted a considerable portion of the speech of Mr. Wise in Congress in 1842, upon the anti-slavery petition, and used it after the manner of seizing an enemy's guns and turning them
                  against himself. He then went on to speak of England's hatred of African slavery, and the probability of her pouncing down upon the slaveholding States, supposing they were to set up for themselves. To sustain this position he again brought up the speech of the member from Accomac, which, viewing the subject in a similar light, said that in the Union any such attempt would be
                  repelled by the united strength of the Republic—the sailor from New Bedford would stand by the sailor from Kentucky. <span className="persName">Mr. RIVES</span>' denunciations of the doctrine of secession were of the most emphatic kind, and we understood him to declare that if it was adopted here, he would never submit to it, if he had to stand alone, like the poor publican, saying
                  "God be merciful to me, a sinner."
                </p>
                <p>
                  He proceeded to speak of Texas, which, after coquetting with England, came into the U. States, having first achieved her independence with a smaller army than old John Brown had organized to invade Virginia. She came in, and received millions of dollars from the United States Government, and the protection due her as a member of the Confederacy; and now she had taken it upon
                  herself to withdraw from the Union. He asked if Virginia would sanction this act of rebellion on the part of Texas?
                </p>
                <p><span className="persName">Mr. RIVES</span> continued speaking until 2 o'clock, at which time the Committee took a recess until 4 o'clock P.M.</p>
                <p>The Committee was called to order by <span className="persName">Mr. SOUTHALL</span> at 4 o'clock P.M., and <span className="persName">Mr. RIVES</span> resumed his remarks.</p>
                <p>
                  He said it was his object to close his speech this evening, because he did not want it to be said that he consumed any more time than was necessary for an exposition of his views. He then proceeded to examine the existing causes of complaint against the North. With regard to the institution of African slavery, he said it was very easy for those who did not like it to get rid of
                  their slaves, while those who did like it sometimes found it very difficult to get them. He liked it, socially, morally, and politically, and he wished he could get a heap more of them. The election of Abraham Lincoln had been alluded to as a just cause of the dissolution of the Union. He averred that not one of those who supported Bell and Everett ever claimed that Lincoln's
                  election would justify such a proceeding. He went further, and said that of all the Breckinridge speakers whom he heard in the Presidential canvass, none occupied that position, and Mr. Hunter, in a speech in the city of Petersburg, said, in answer to a question, that he did not think the election of Lincoln would justify dissolution, and if any State went out, he would be among
                  the first to endeavor to bring her back again. The withdrawal of Southern Senators and Representatives from Congress was strongly condemned by <span className="persName">Mr. RIVES</span>, as giving up a power which the South possessed for preventing any objectionable appointments, and restraining any act of Administration hostile to her interests.
                </p>
                <p>
                  He was for relying upon the law which had confined all preceding Presidents to the sphere of their duty; but if it was not strong enough, make it stronger. The violations of the fugitive slave law, by Personal Liberty bills, he unequivocally condemned; but he wanted no better security for the enforcement of the fugitive slave law than was found in the Constitution. The President,
                  with the army and navy at his back, had full power to execute it, as President Fillmore did in the case of Burns at Boston. If it was contended that negroes were not caught at the North, it was equally true that they were not caught in the South, for the newspapers were constantly advertising runaway negroes. He thought a good many masters were to blame for dressing their slaves up
                  in fine clothes, and letting them drive about the country, instead of keeping them down in the cornfield, where they belonged. It looked too much like equality, and invested the mind of the negro with ideas that did not belong there.
                </p>
                <p>
                  The Territorial question next occupied his discourse. He took the ground that we did not want to take our slaves to the Territories. During the Kansas excitement there was a great furore in Petersburg, and a bonus of $50 was offered to all who would emigrate, and $100 to every one who carried a slave. It was only required that they should stay until after the October election,
                  when, if they thought proper, they could come back. Only twenty-five enlisted, and of these, not one was a slave-owner! A better illustration than this, he said, could be found in South Carolina.
                </p>
                <p>Not a single slaveholder will be fool enough to remove from his plantation there, to the finest fields that bloom in the great plains and valleys of the West.</p>
                <p>
                  After some further consideration of this point, he proceeded to urge upon the Committee the report on the subject of Federal Relations, which he thought ought to satisfy every one. Before his constituents he took the ground that Virginia should set forth a catalogue of her wrongs, and the mode and measure of redress, and then say to the North, "You know you have been guilty of
                  these things, and we ask you for this redress;" if they refused, then say to them, "Let us part in peace—you take the public property in the North, and we take the public property in the South; if we get more than you, we will pay you the difference, and if you get the most, pay us the difference." If they refused to accede to these terms, say to them, "Come on! We are ready' for
                  you!" He would not wander through a labyrinth of abstractions to get at the right of secession, but raise the flag of revolution, and fight for what could not be obtained in peace. If, however, the first mode proved effectual, we could say to the seceded States, we have got all we want, the North has agreed to put these things in the Constitution, and now we ask you to come back.
                  He believed it was wrong to endeavor to create the impression that such an effort would not succeed. The majority report, as he understood it, covered all the ground of which the South complained... It demanded everything that could be demanded, and yet gentlemen wanted something else. It reminded him of a cross and ill-natured child, dissatisfied with everything, and crying for
                  something else; and when the nurse took it to the looking-glass to exhibit to the child its own ugliness, it capped the climax by smashing the glass.
                </p>
                <p>
                  <span className="persName">Mr. RIVES</span> discussed the several sections of the report, expressing his concurrence therein. With regard to the objection against that portion providing for the remuneration of masters for slaves not recovered by the United States authorities, he made an arithmetical calculation, taking the number of slaveholders at 250,000, and the average value of the
                  runaway slaves at $1,000; and the General Government having paid this to the master, even should the tax fall upon slaveholders alone, it would come to only about three mills to each man. But falling upon 28,000,000 of people, what a subject for calculation would each one's portion be. Arguing at some length upon the question of reconstruction, he expressed his belief that the
                  Southern States would come back. He produced statistics to show that Virginia had not slaves enough, and urged a change in the agricultural system in order to retain them in Virginia.
                </p>
                <p>
                  Towards the close of his remarks, in order to disabuse the minds of those who thought his enthusiasm for the Union was accounted for by the fact that he was a United States officer, he said he had made a calculation of the emoluments of his office, and found that since the 31st of December last he had received, as Collector of the Port of Petersburg, the enormous sum of twenty-five
                  dollars and twenty-eight cents! He threw his keys upon the table before him, and said when any one wanted the keys of his office there they were. He had no favors to ask.
                </p>
                <p>He alluded to the pestilence that once raged in Portsmouth and Norfolk, and to the pecuniary assistance then rendered by the Northern States to their suffering brethren of Virginia—sending collectively over $124,000. Upon this manifestation of sympathy he dwelt with much earnestness, and with these considerations he closed his remarks, having spoken about seven hours.</p>
              </div>
              <p><span className="persName">Mr. FLOURNOY</span>, of Halifax, next obtained the floor, but gave way to <span className="persName">Mr. SLAUGHTER</span>, who moved the Committee rise.</p>
              <p>The motion was agreed to, and the Committee rose and reported progress.</p>
              <div className="speaker" id="sp1760"><span className="persName">Mr. PRICE</span>, of Greenbrier—</div>
              <p className="p-in-sp">
                Mr. President, I suppose the resolution which was offered by the Chairman of the Committee on Federal Relations, would come up as a matter of course. I don't see that gentleman now; whether he is present or not, I don't know; but I desire to make this remark, that the resolution will necessarily have to be changed. I desire exceedingly to come to some terms with the gentlemen on the
                other side, which would be entirely agreeable. I would not force the resolution upon them if I could. I would arrange the matter with any fair man who had no object but fair discussion. Now, if our friends on the other side are disposed to make an arrangement by which they shall secure to themselves all reasonable time for discussion, I tender them an overture. If, however, their
                object is to protract the session of the Convention, why, then, of course, I can give <PageNumber num={585} /> no terms. I want to give them fair play. I desire that the session shall be closed as soon as practicable, and I hope that the gentlemen on the other side may come to a consideration of the subject.
              </p>
              <p className="p-in-sp">I will refer to the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>] , who is a fair gentleman, and make an overture to him. I hope he will consult his friends, and that by to-morrow morning we will come to some understanding on this subject.</p>
              <div className="speaker" id="sp1761"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I would be always willing to undertake the office of peace-maker, but it is impossible for me to attend to the matter to which the gentleman refers, inasmuch as we have night sessions of the Senate, and I have a number of enrolled bills to sign. I would otherwise cheerfully accept the commission which the gentleman tenders.</p>
              <div className="speaker" id="sp1762"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">I will, then, select the gentleman from Lunenburg [<span className="persName">Mr. NEBLETT</span>] , and make a proposition to him, that he consult with his friends to see if we cannot come to some terms of adjustment. I make the overture to him, and trust that it will be accepted in the spirit in which it is tendered.</p>
              <div className="speaker" id="sp1763"><span className="persName">Mr. NEBLETT</span>—</div>
              <p className="p-in-sp">The gentleman from Lunenburg is too young to undertake to negotiate upon a matter of so much importance.</p>
              <div className="speaker" id="sp1764"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I wish to enquire whether a Committee of the Whole can order any printing to be done.</p>
              <div className="speaker" id="sp1765"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">I presume not.</p>
              <div className="speaker" id="sp1766"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                My impression was that they could not, and desiring to offer a series of amendments for the second part of the report of the Committee of Twenty-one, and to have these amendments, consisting of some nine or ten sections, in lieu of their eight or nine sections, I ask that the House will receive the amendments, order them to be printed for their information, and have them referred to
                the Committee of the Whole with the report, to be offered by me at the proper time. I ask this as a courtesy from the House for their own benefit as well as mine, that they may have an opportunity of seeing the competing propositions before they come before the Committee for consideration. I move, sir, that these amendments be received by the House, referred to the Committee of the
                Whole, and ordered to be printed.
              </p>
              <p>The motion was agreed to.</p>
              <p>Amendments were proposed by <span className="persName">Mr. WISE</span>, to propositions of the second part of the report of the Committee of Twenty-one on Federal Relations, so as to make the same read as follows:</p>
              <p>AMENDMENT 1sT—Amend by striking out the first and second sections of the report, and inserting:</p>
              <PageNumber num={586} />
              <p>
                SECTION 1. In all the present territory of the United States, involuntary servitude, as it now exists, shall remain and shall not be changed; nor shall any law be passed by Congress or the territorial legislatures to hinder or prevent the taking of persons held to service or labor, from any of the States of this Union to said territory; nor to impair the rights arising from said
                relation; nor shall said rights be in any manner affected by any pre-existing law of Mexico in the part acquired from her; but the same shall be protected as other rights, and be subject to judicial cognizance in the Federal Courts, according to existing laws, and to the remedies and practice of the common law, so far as they may be consistent with each other. And when any territory,
                within such boundary as Congress may prescribe, shall contain a population equal to that required for a member of Congress, it shall, if its form of government be republican, be admitted into the Union on an equal footing with the original States, with or without involuntary servitude, as such Constitution of the State may provide. In all territory which may hereafter be acquired by
                the United States, involuntar; servitude is prohibited, except for crime, north of thirty-six degrees thirty minutes; but shall not be prohibited by Congress or any territorial legislature south of that line.
              </p>
              <p>AMENDMENT 2.—Amend by striking out the 3rd section of the report and inserting:</p>
              <p>
                SECTION 2. Neither the Constitution, nor any amendment thereof, shall be construed to give Congress power to abolish involuntary servitude in any territory; nor in the District of Columbia; nor in the sites of forts, magazines, arsenals or other places ceded by the States to the Federal Government, within the limits of those States where involuntary servitude is established or
                recognized; nor within any forts, magazines, arsenals, or other places reserved within the limits of any territory for the uses of the Government of the United States; but Congress shall pass all laws necessary and proper to protect the property in persons held to service or labor, in said territory, District, or other places ceded or reserved to the United States. Nor shall any law
                be passed by Congress to hinder or prevent the taking of persons held to service or labor to or from the District of Columbia, or to hinder or prevent the retaining of the same within the limits thereof. Nor shall Congress have the power to prohibit the removal or transportation, by land or water, of persons held to service or labor in any State or Territory of the United States to
                any other State or Territory thereof, where it is established or recognized by law or usage; and the owner of property in persons held to service or labor, or his agent, <PageNumber num={587} /> shall have the right of transit through any State or Territory of the United States with such property and persons, to and from any State or territory
                recognizing said property by law or usage, and the right during transportation, by sea or river, of touching at ports, shores and landings, and of landing and sojourning with said property, in cases of need, temporarily, any law of any State or Territory to the contrary notwithstanding. And Congress shall not have the power to lay on persons held to service or labor in any of the
                States or territories of the United States any other tax than a capitation tax, to be apportioned as capitation or other direct taxes are directed to be apportioned throughout the United States according to the fourth clause of section nine of article first of the Constitution of the United States.
              </p>
              <p>AMENDMENT 3.—Amend by inserting:</p>
              <p>
                SECTION 3. In all cases where the property in persons held to service or labor in any State or Territory of the United States, or in the District of Columbia, has been or hereafter may be taken for public use, as in cases of impressment in war or otherwise, the owner thereof shall be justly compensated as in cases of other property so taken; and in all cases involving questions of
                property in said persons, the rights of property in them shall be recognized and protected by the United States and their authorities as the rights of other property are recognised and protected.
              </p>
              <p>AMENDMENT 4.—Amend section 4th of the report, by adding, after the word "due," the words "and it shall be the duty," et seq.—so that the 4th section, as amended, shall read:</p>
              <p>SECTION 4. The third paragraph of the second section of the fourth article of the Constitution shall not be construed to prevent any of the States, by appropriate legislation, and through the action of their judicial and ministerial officers, from enforcing the delivery of fugitives from labor to the person to whom such service or labor is due.</p>
              <p>
                And it shall be the duty of all the States, to pass all laws necessary and proper to aid, by their authorities, judicial and ministerial, in the execution of the laws passed by Congress for the delivery of fugitives from service or labor to the person to whom such service or labor is due. And in case the owner, or his agent, of the person held to service or labor, shall be unlawfully
                deprived of his property in such person by force or violence, by mobs or riotous assemblages, or by secret associations or conspiracies, in the limits of any State, such States shall make just compensation therefor, and it shall be the duty of Congress to provide by law for the enforcement of such compensation.
              </p>
              <p>AMENDMENT 5. Amend the 5th section of the report, by adding, <PageNumber num={588} /> after the word "prohibited," the words "Provided that" et seq.—so that the 5th section, as amended, shall read:</p>
              <p>
                SECTION 5. The importation of slaves, coolies or persons held to service or labor, into the United States, and the territories from places beyond the limits thereof is hereby forever prohibited. Provided, that nothing herein contained shall be deemed to apply to the Southern States wliich have declared, or may hereafter declare, their separation from the Cpnfederacy, in case their
                separate independence shall be acknowledged and continued.
              </p>
              <p>AMENDMENT 6. Amend by striking out the 6th section of the report.</p>
              <p>AMENDMENT 7. Amend by changing section 7th of the report to section 6th; and by adding, after the word "race," the words "and no person" et seq.—so that the section, as amended, shall read :</p>
              <p>SECTION 6. The elective franchise and the right to hold office, whether federal or territorial, shall not be exercised by persons who are of the African race.</p>
              <p>And no person of the African race shall be deemed and held entitled, under the Constitution of the United States, to the privileges and immunities of citizens in the several States. And the several States are prohibited from passing any laws establishing equality of the African with the white race within their limits.</p>
              <p>AMENDMENT 8th. Amend by inserting:</p>
              <p>
                SECTION 7. The second clause of the second section of the second article of the Constitution of the United States shall be so amended as to take from the President of the United States the power of nominating and appointing the judges of the Supreme and other Federal Courts of the United States, and their nomination and appointment shall be vested in the Senate of the United States
                alone; and three-fourths of the whole number of Senators shall be required to confirm the appointments.
              </p>
              <p>AMENDMENT 9th. Amend by inserting:</p>
              <p>
                SECTION 8. It shall be the duty of the several States, and of the Congress of the United States, within their respective jurisdictions, to pass all laws necessary and proper, to protect and preserve the domestic tranquility of the people of the several States, by suppressing all attempts of individual persons, or of assemblages, or associations to excite any portion of the people of
                the States to acts which will cause, or tend to cause, animosity or hostility between the various sections, or any invasions of any of the States or territories of the United States, or which will introduce or invite foreign influence to divide the Union, or which may tend to destroy the same.
              </p>
              <PageNumber num={589} />
              <p>AMENDMENT 10. Amend by changing section 8th of the report to section 9th, viz:</p>
              <p>SECTION 9. No one of these amendments, nor the third paragraph of the second section of the first article of the Constitution, nor the third paragraph of the second section of the fourth article thereof, shall be amended or abolished without the consent of all the States.</p>
              <p>
                And it is hereby ordained and declared by this Convention, That the foregoing propositions of adjustment shall be submitted to the people of this Commonwealth for their approval or disapproval, at their elections, to be held in May next. And that it shall also be submitted to them, at said elections to decide whether, when said propositions in case of their approval by the people of
                this Commonwealth, shall be made to the co-States, they will not accept the same, or will not respond thereto in a reasonable time or satisfactory manner, the people of this Commonwealth will or will not resume the powers granted by them under the Constitution of the United States. And to that end, it is further ordained that, it shall be the duty of the officers conducting the
                election to be held for members of the General Assembly, in May next, to prepare proper poll books, the form of which shall be prescribed, and distributed throughout the Commonwealth, by the Secretary thereof, and to see that a poll shall be opened at the several places of voting in every county; and the said poll books shall have four columns: one to be headed, "propositions
                approved ;" another to be headed "propositions disapproved ;" a third to be headed, "for resumption of powers;" and a fourth to be headed, "against resumption of powers." And it shall be the duty of said officers conducting the election, to cause the names of the qualified voters, voting at their respective precincts for members of the General Assembly, to be recorded for or against
                the said propositions of adjustment, and for or against the said alternatives of resuming or not resuming the powers granted by the people of this Commonwealth, under the Constitution of the United States, in case of the rejection of said propositions, or failure to answer them satisfactorily or at all, by the co-States, under the respective heading, according to the vote of each
                voter. And said poll books shall be certified and returned by said officers within five days after the election, to the Clerk of the county or corporation Court, as the case may be, where the election is held. And it shall be the duty of the respective Clerks to certify the result of the vote to the Governor of the Commonwealth, under their seals of office, within five days after the
                said poll books shall have been returned to them by the officers conducting the elections. And the Governor and Secretary of the Commonwealth shall <PageNumber num={590} /> compare and count the said polls and ascertain the result of the vote of the people of the Commonwealth, and the Governor shall make the same known by proclamation; and he shall
                communicate the result to the President of this Convention, who shall immediately assemble the members thereof, if not in session; and, if said propositions be approved by the people, or whether approved or not by the people, they shall adopt the alternative of resuming the powers granted by them under the Constitution, then this Convention shall proceed to act accordingly. And in
                case both the "propositions" and "resumption" are adopted by the people, this Convention shall await responses to the former, by the co-States, until the 1st day of October next; if the responses are satisfactory, they shall be accepted; if not, or insufficient responses or no responses at all are made, then the alternative of "resumption" shall be ordained by this Convention, and be
                made the supreme law of the Commonwealth; or, if the said propositions shall be rejected by the voters, and the alternative of resumption be adopted by them, then this Convention shall proceed immediately to ordain and procla'im that the people of this Commonwealth will resume the powers granted by them under the Constitution of the United States.
              </p>
              <div className="speaker" id="sp1767"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">The gentleman from Lunenburg regards himself as too young and inexperienced to undertake to make the arrangement. I will make the overture to the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] . I know he is a zealous man, but I always found him a fair man.</p>
              <div className="speaker" id="sp1768"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">
                I consider the proposition submitted by the gentleman as fair, just and liberal, and if I had it in my power to make an arrangement with him this moment, I would make it. I have no idea that the gentlemen who entertain opinions in common with me have any disposition to protract the session of this Convention. If there is any such disposition, I am utterly unacquainted with it. I
                think the Convention ought to come to a close at as early a day as it can be done. I now call upon my friends to meet at my room to consider what we ought to do, and, to-morrow, I expect I will be prepared to say what is to be done. I do not know what my friends will do, but I think they are disposed to bring this Convention to a close as soon as possible.
              </p>
              <div className="speaker" id="sp1769"><span className="persName">Mr. CAMPBELL</span>, of Washington county—</div>
              <p className="p-in-sp">
                I beg leave to present a petition from citizens of Washington county, embracing some 270 names, requesting this Convention to pass an ordinance of secession to be submitted to the people for ratification or rejection. The citizens signed to this petition embrace some of the most respectable in the county. It is entirely respectful in its terms, and it affords me pleasure
                <PageNumber num={591} /> to comply with its request, to lay it before the Convention. I move that it be referred to the Committee on Federal Relations.
              </p>
              <p className="p-in-sp">I have another petition on the other side of the question which appears to be a little heavier than the one just presented, I ask that this shall take the same course.</p>
              <p>Both were ordered to be referred to the Committee on Federal Relations.</p>
              <p>And then, on motion of <span className="persName">Mr. PRICE</span>, the Convention adjourned.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v2.2.12">
            <PageNumber num={592} />
            <h2><span className="headingNumber">1.12. </span><span className="head">THIRTY-NINTH DAY</span></h2>
            <div className="dateline">Saturday, <span className="date">March 30</span></div>
            <p>Prayer by Rev. <span className="persName">Mr. PETERKIN</span>.</p>
            <div className="section" id="vsc1965.v2.2.12.1">
              <h3><span className="headingNumber">1.12.1. </span><span className="head">PROCEEDINGS OF A PUBLIC MEETING IN BRUNSWICK COUNTY</span></h3>
              <div className="speaker" id="sp1770"><span className="persName">JAMES B. MALLORY</span>—</div>
              <p className="p-in-sp">I hold in my hands the resolutions adopted at Lawrenceville, Brunswick county, on Monday last. Although they are not specially directed to myself, yet the people of that section expected that I would present them to the Convention. The will of the people I represent is sufficient to command my obedience.</p>
              <p>The Secretary then read the proceedings as follows:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>We, a portion of the people of Brunswick, impressed with the necessity of prompt and decided action on the part of the Convention of Virginia now assembled, to vindicate her own honor, and to make known to the' world that she will favor no proposition which would keep her separated from her sisters of the Southern Confederacy, do</p>
                  <p>Resolve, That we are in favor of the "immediate and absolute secession of Virginia" from the present General Government.</p>
                  <p>Resolved, That we regard the propositions for the formation of a Border State Confederacy as even more odious than our connection with the Black Republican Government now existing, and as tending to ultimately and rapidly abolitionize Virginia.</p>
                  <p>Resolved, That the present Convention was called solely to consider the interests of this State as affected by the present political crisis of the country, and that we solemnly protest against any change in the organic law of this Commonwealth.</p>
                  <p>Resolved, That the Hon. Roger A. Pryor is entitled to the thanks of his constituents, for the fidelity and ability with which he has sustained their interests, in and out of Congress, and that we tender him our thanks for the eloquent and powerful address of this day.</p>
                  <p>Resolved, That we cordially endorse the action of our delegate in the General Assembly of Virginia, and believe that he has faithfully consulted the best interests of his constituents.</p>
                </div>
              </div>
              <p>PERSONAL EXPLANATION</p>
              <div className="speaker" id="sp1771"><span className="persName">Mr. CRITCHER</span>—</div>
              <p className="p-in-sp">
                I rise to make a personal explanation. I refer to a matter that touches my honor as a member of this body. It has been extensively circulated, by private persons and by the press, that I have withheld important political intelligence, which I had been requested <PageNumber num={593} /> by Dr. Wm. Wirt, of the county of Westmoreland, to communicate to
                the Convention. Sir, I did receive the intelligence, but it was accompanied by the request that it should not be published, and with a further request that I would consult with my conservative friends of this body as to the best use that could be made of the information. I strictly followed the wishes of my correspondent. I consulted with my friends in the Convention, and at their
                suggestion I communicated the letter to the then supposed head of the Republican Administration, at Washington, with the distinct assurance on our part, that if the policy there indicated, and attributed to Mr. Chase, were adopted and carried into practice, that they and I would sign an ordinance of secession within ten days after that policy should be inaugurated. That is the nature
                of my correspondence with Wm. H. Seward. I pursued the best course that could be pursued, except in the opinion of those who desire that policy to be adopted in order to enable them to dissolve the Union. I suppose when General Washington addressed a letter to the British commander, and forewarned him that the same punishment inflicted on American captives would be retaliated on
                British prisoners, he, too, was in correspondence with the enemy!
              </p>
              <p className="p-in-sp">I would disdain to notice such frivolous and malicious charges, if the public mind were more calm and composed.</p>
              <div className="speaker" id="sp1772"><span className="persName">Mr. WILLEY</span>—</div>
              <p className="p-in-sp">I suppose that the resolutions I had the honor to submit some time past are now in order.</p>
              <div className="speaker" id="sp1773"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The chair understood the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , to yield the floor to the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>] , to present his resolutions. These were under discussion yesterday when the Convention went into Committee of the Whole, and the chair is of opinion that they now have precedence. The gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] , is entitled to the floor, if he is in his place.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.12.2">
              <h3><span className="headingNumber">1.12.2. </span><span className="head">CLOSE OF DEBATE IN COMMITTEE OF THE WHOLE</span></h3>
              <div className="speaker" id="sp1774"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">
                I have but a word to say upon the subject of the resolutions introduced by me yesterday. If I could have thought that our friends upon the other side felt as much aggrieved as their words seemed to impart, I should feel, I confess, very much distressed myself. But I can hardly think that gentlemen can seriously entertain the idea that a motion merely to go out of the Committee of the
                Whole, which was in itself unnecessary machinery and only resorted to for convenience, could be that act of oppression as it has been characterized here. Nor do I think that even the time proposed for the final termination <PageNumber num={594} /> of this debate could be regarded as anything like oppression upon either party in this Convention. The
                subject now before the body has been argued from the commencement of the Convention to this time. It has been discussed and there is but one point in it and that was discussed before the report of the Committee was made, as it has been since.
              </p>
              <p className="p-in-sp">
                I, apprehend, sir, that let us terminate or attempt to terminate the sessions of this Convention, at any time, we should hear the same sort of Jeremiads upon this thing of outrage and gag. I admit that under these circumstances there should be some modifications of this resolution. As the whole day was occupied yesterday by one speech from a gentleman advocating the same opinions
                with myself, I should be perfectly willing now to extend the time to Thursday instead of Tuesday. My original preference was to fix upon Thursday next as the time for coming to a vote in the Committee of the Whole. I was overruled by the opinions of others; but even in the earlier day named I could not see that there was anything like oppression, nothing giving any just ground for
                complaint. I am willing, however, if it can be done by general consent in the present attitude of this question to modify -the resolution so as to change the time for closing the debate to Thursday next. If no objection be made, I will ask that the resolution may by general consent be so changed as to make it read Thursday instead of Tuesday.
              </p>
              <div className="speaker" id="sp1775"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I have no objection to modifying the resolution in any way the gentleman pleases. I do not rise to make any objection to that; but I am not willing that Thursday next should be fixed as the time. I stated to my friend from Greenbrier [Mr. RIVES]
                ,<span className="note" id="Note66"><span className="noteLabel">1</span>The delegate from Greenbrier was Mr. Price.</span> last night, that I would confer with gentlemen who agreed with me in opinion, as to the shortest time they thought would be necessary for them to present their views before this body. There was a very considerable number of these gentlemen who conferred with me upon the
                subject—some 25 or 30. My friend from Princess Anne
                [<span className="persName">Mr. WISE</span>]
                , was not present. He has announced his' intention to speak, but I cannot tell, how long he would desire to occupy the attention of the Convention. These gentlemen, with the sincere desire to shorten as much as practicable the session, conferred amongst themselves and came to the conclusion that it would take 20 hours for the—I don't know what to call them, secessionists, perhaps—
                [a VOICE: Southern rights men]
                —for the Southern rights party to present their views. If we should meet at 10 o'clock hereafter, adjourn at 2 1/2 P. M., then take a recess until 7 o'clock and sit until 9, we should thereby get a greater space of time between the <PageNumber num={595} /> two sessions. With the time and the arrangement I have here indicated, the gentlemen with whom I
                act would be content, but they cannot consent to any shorter time than 20 hours in which to present their views to this Convention, and through the Convention to the country.
              </p>
              <div className="speaker" id="sp1776"><span className="persName">Mr. STAPLES</span>—</div>
              <p className="p-in-sp">I indicated yesterday my opposition to the resolution introduced by the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>]
                . I.must say that, so far as I am concerned, I would not be willing to put off the voting upon these propositions longer than Thursday. I think that will give gentlemen on both sides time to debate the question fully. Thursday is as far as I can go. I do not wish by any vote of mine to check debate, or to gag any man. I have always been an advocate of free and full discussion upon
                all questions, but I think that in the course of the coming week, say by Thursday, gentlemen on both sides of the House would have ample time to discuss this question; and therefore I shall vote for the resolution as amended; as suggested by the gentleman from Frederick
                [<span className="persName">Mr. CONRAD</span>] .</p>
              <div className="speaker" id="sp1777"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">Having made an overture to the other side yesterday evening, it is proper that I should report the result. I was acting upon my own responsibility entirely, but I was satisfied at the time, if anything fair was agreed upon by myself with any gentlemen on the other side, that my friends would agree to it. The gentleman from Amelia [<span className="persName">Mr. HARVIE</span>]
                and myself had an interview this morning. He had a list of persons who desired to speak upon this subject, and the length of time they required. I distinctly objected to it, and insisted that the time should be fixed upon for closing the debate, and in the meantime, those gentlemen upon either side who were successful in getting the floor should be permitted to speak. I had no idea
                of picking out individuals to speak a certain given length of time to the exclusion of others who might desire to speak. I will say in all frankness that there was nothing unfair manifested on the part of the gentleman from Amelia
                [<span className="persName">Mr. HARVIE</span>]
                , and no purpose on his part, so far as I could see, of any desire to protract the session unnecessarily; nor was there on the part of the other gentleman who was associated with him in conversation. I said to those gentlemen "will you accept Wednesday instead of Thursday for closing the debate; one day has been already occupied by one of our friends; we will give you the benefit of
                that day, and name Thursday as the time for closing the debate." They replied that they could not safely acquiesce in this arrangement without consultation with their friends—and so no determination was arrived at, and the consultation was ended. I say, as a member of this Convention, that I am willing to extend the time for closing the debate to Thursday,
                <PageNumber num={596} /> and I will vote for the resolution as it now reads. Beyond that, I am not willing to go, because this debate must be closed.
              </p>
              <p className="p-in-sp">
                It is said that we are yet to have an ordinance of secession and various other propositions to be submitted to us. Have not these subjects been under consideration ever since the Convention was organized? Has not the question of secession been before the Convention from its organization to the present time, and undergone a full and thorough discussion? I am not prepared to
                accommodate every gentleman who may desire to speak. I would gladly do so if I could. My own constituents had expected to hear from me, and I had expected to address the Convention at length upon the very important subject before us, but I think I shall serve my constituents better by remaining silent and urging upon the Convention the necessity of bringing our labors to a close. I
                shall, therefore, forego the privilege of speaking myself, and I shall expect other gentlemen to act with the same disinterestedness as myself.
              </p>
              <div className="speaker" id="sp1778"><span className="persName">Mr. WOODS</span>—</div>
              <p className="p-in-sp">
                Ten minutes only, are allotted to each member of this Convention, to give his reasons, if any he has, why the debate in Committee of the Whole shall not be cut off, on the 4th April at 12 o'clock M. Admonished by necessity, I shall not waste words. The RIGHTS of the people of Virginia for forty years have been threatened by a sentiment hostile to her institutions. She has been
                invaded by MURDERERS. Midnight robbers and assassins have plundered and murdered her citizens at their own doors. They have been dragged from their beds by the hand of violence and shut up in prison, guarded by infuriated negroes, with pikes in their hands, and subjected to the vilest indignities, and when these murderers are tried, condemned and executed according to the laws of the
                State, so outraged, that sentiment has canonized them as saints and martyrs. Fugitives from justice escaped into Northern States, are protected and honored, because they have outraged all our rights of peace, security, honor and hospitality. Justly fearing similar insults and outrages, from persons holding the same sentiments, and fleeing in time from these, and the untold horrors of
                servile war, to be brought upon them by their brethren of the Northern States, seven Southern States have dissolved their connection with the Federal Union, endeared to them by all the glories of the past, and by all the hopes of the future, and have established in solemn manner, upon enduring foundations their new Confederacy.
              </p>
              <p className="p-in-sp">
                This party, thus hostile to the best interest of Virginia, has control of the Government of a once glorious, but now distracted, disintegrated country. Under these circumstances, while the great heart of the <PageNumber num={597} /> American nation was throbbing with the throes of approaching dissolution, Virginia called together this sovereign
                Convention, to consult upon the best mode of securing her rights in the Federal Union, or her independence out of it. We met; appointed a Committee of Twenty-one upon "Federal Relations," to which all propositions were to be referred, according to the first intention of its distinguished Chairman, "at once, without debate." After twenty-five days deliberation, that Committee made a
                "partial report," and after much additional deliberation a second report. In my opinion this report has disappointed, and vexed the great heart of the people. For seventy-five years Virginia has claimed, and in every struggle insisted, that she was an independent sovereignty.
              </p>
              <p className="p-in-sp">
                This Convention, in the Committee of the Whole, has deliberately abandoned and repudiated this great political principle, essential to the honor, security and freedom of the people of Virginia. It has been further declared, by members of the Convention, that Virginia never was an independent and sovereign State, and no member of this majority has repudiated it, as their sentiment.
              </p>
              <p className="p-in-sp">
                This Convention in Committee of the whole has, by its deliberate vote, declared that the institution of African slavery is not a vital part of the political system of the United States. Every odious Federal doctrine which the people of Virginia have repudiated from the foundation of the Government, has been galvanized into a spasmodic existence by the Report of the Committee on
                Federal Relations. Black Republicanism having found its ablest advocates, apologists and defenders in the majority of the Convention, there remains nothing that it can demand to make its defence complete, except a copy of the journal of this Convention. Its pages will present every sentiment that Virginia sages and patriots contended for, deliberately abandoned and betrayed, under
                the specious pretence of preserving a "Union" that every intelligent man knows, and must admit, no longer exists unbroken. Let it be declared to the people of Virginia, that in order that she may remain the miserable dependant of a Northern Confederacy, the majority of this Convention find it necessary to sacrifice and abandon the rights for which Virginia has hitherto contended;
                that the principles advocated by all the great and good men to whom she entrusted her political destinies, must be deliberately abandoned.
              </p>
              <p className="p-in-sp">
                This is the view that the proceedings of this Convention present to a plain, blunt man like myself, and which will present itself to the plain people of Virginia, at their homes, in their shops, in their fields, and by the wayside. To prevent the people of Virginia from comprehending <PageNumber num={598} /> the full force, effect, and danger of this
                abandonment of public right, this Convention now proposes to cut off discussion. The majority of the Convention have the power to do so; they have manifested the disposition to do so; and I say, if they desire it let them do it; cut off debate and let the people be betrayed! But "woe unto them by whom they are betrayed."
              </p>
              <p className="p-in-sp">
                Let the people know, that if they would preserve their liberties; if they would defend their fire sides from invasion, and the honor of their homes from fanatical desecration, let them arise in the power of their might, and by one voice of universal execration consign this majority to eternal political perdition. In the name of public liberty, in the name of the defenceless women and
                children, who are to be thus dishonored and betrayed, I protest against this effort to avoid the legitimate results of full and free discussion.
              </p>
              <div className="speaker" id="sp1779"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The hour of half past ten having arrived, the Convention in Committee of the Whole, will resume the consideration of the subject before them.</p>
              <div className="speaker" id="sp1780"><span className="persName">Mr. PATRICK</span>—</div>
              <p className="p-in-sp">I move that the order be suspended.</p>
              <div className="speaker" id="sp1781"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">This cannot be done after the hour fixed by the Convention for going into committee has arrived.</p>
              <div className="speaker" id="sp1782"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">
                I rise to a question of order. There are four ways in which the order of the day may be disposed of. It may be either dropped, discharged, postponed or executed. According to rules which he had examined upon the subject, the order might be postponed till 12 o'clock.<span className="note" id="Note67"
                ><span className="noteLabel">2</span>This sentence may be the reporter's summary of Mr. Dorman's remarks on the rules.</span
                >
              </p>
              <div className="speaker" id="sp1783"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair begs leave to state, that under the resolution of the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>]
                , offered some days ago, the Convention determined that it would, upon every day thereafter, until the business was completed, go into Committee of the Whole. The Chair examined this subject to some extent, because a motion was made yesterday to suspend the rules, and the Chair came to the conclusion, if a motion was made to suspend the execution of the order of the day, that it
                ought to be made in advance of the arrival of that order. Having doubts upon that subject, I consulted with gentlemen who are regarded as the most experienced Parliamentarians here, and they concurred with me in that opinion. The Chair for the present adheres to that opinion; and the Convention will now go into Committee of the Whole, unless the decision of the Chair is appealed
                from.
              </p>
              <div className="speaker" id="sp1784"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">I will not press the point at present, but I will take occasion to submit it privately to the Chair.</p>
              <PageNumber num={599} />
              <div className="speaker" id="sp1785"><span className="persName">Mr. PATRICK</span>—</div>
              <p className="p-in-sp">I understand the Chair to decide that each member has the right to address the House for ten minutes on the pending question.</p>
              <div className="speaker" id="sp1786"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">But not at this time.</p>
              <div className="speaker" id="sp1787"><span className="persName">Mr. PATRICK</span>—</div>
              <p className="p-in-sp">I want to give notice that I will offer a resolution rescinding the rule that gives the privilege of speaking after the previous question is sustained.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.12.3">
              <h3><span className="headingNumber">1.12.3. </span><span className="head">COMMITTEE OF THE WHOLE</span></h3>
              <p>The Convention then resolved itself into Committee of the Whole [<span className="persName">Mr. SOUTHALL</span> being in the Chair] , and resumed the consideration of the majority report of the Committee on Federal Relations, upon which <span className="persName">Mr. FLOURNOY</span> was entitled to the floor.</p>
              <div className="speaker" id="sp1788"><span className="persName">Mr. BRANCH</span>, of Petersburg—</div>
              <p className="p-in-sp">With the permission of the gentleman from Halifax [<span className="persName">Mr. FLOURNOY</span>]
                , I wish to say a few words for the purpose of explaining my position. Two days ago I promised to bring in an ordinance of secession for the action of this body. I believe my judgment was correct then, and I believe in the position I then took. After I had given that notice, gentlemen of high character, in whose judgment it seemed I ought to confide, have asked me to delay the action
                I proposed. It is, perhaps, proper to say that it was not expected that I would be a leader, but the delay of the Convention prompted me to determine upon the course I concluded to take. I am satisfied that my determination was correct, but I yield to the solicitations of gentlemen of large experience, whose large experience has induced them to make the suggestion, and influence me
                for the present to withhold my ordinance of secession. I have it ready, sir, but I will not offer it at this time.
              </p>
              <div className="speaker" id="sp1789"><span className="persName">SEVERAL VOICES</span>—</div>
              <p className="p-in-sp">Offer it, offer it.</p>
              <div className="speaker" id="sp1790"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Order, gentlemen, order.</p>
              <div className="speaker" id="sp1791"><span className="persName">Mr. PRICE</span>, of Greenbrier—</div>
              <p className="p-in-sp">I understand my friend from Petersburg [<span className="persName">Mr. BRANCH</span>]
                , to say he offered some resolutions some time ago. He subsequently offered the resolutions of a meeting held in the city of Petersburg. He said he regarded himself as instructed, and should pursure the course he was instructed to pursue, until the sentiments of his people should change. A day or two since he said he was having an ordinance of secession copied, and, intended to offer
                it on yesterday. He failed to do so, and he says now he has been instructed otherwise. I would like to know if the people of Petersburg have given him the last instructions?
              </p>
              <div className="stage it">[Laughter.]</div>
              <PageNumber num={600} />
              <div className="speaker" id="sp1792"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">I beg to say that I refer to my remarks for an explanation.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp1793"><span className="persName">Mr. FLOURNOY</span>, of Halifax—</div>
              <p className="p-in-sp">
                Mr. Chairman, nothing but a sense of duty to a constituency as largely and deeply interested in all the questions which have engaged our attention as any in the Commonwealth of Virginia could have induced me, after having witnessed the exceeding impatience of the Convention that this debate should be brought to a close, to throw myself upon the indulgence of this body. It is a duty I
                owe myself, and it is a duty I owe a constituency deeply interested as they are, that I shall express to this Convention, humble as I am, the views I entertain upon the questions which now absorb the attention of the country.
              </p>
              <p className="p-in-sp">
                In the outset, Mr. Chairman, let me say to this Committee I am no disunionist. From my earliest manhood I was taught to cherish a cordial, habitual and an immovable attachment to the Union. I was taught to look upon it as a main pillar in the edifice of our real independence, and I cannot, Mr. Chairman, except with feelings of pain, hear remarks which I frequently hear throughout the
                country, that it is an accursed Union. Nor shall I subscribe, sir, to the remarks which fell the other day from my worthy and excellent friend from Mecklenburg
                [Mr. GoonE]
                . He was glad, he said, that the bonds of the Union were broken, and he trusted in God they would never be reunited. Sir, I pray, in deep earnestness, that it may be restored in all its parts; that all its members may come again together, and be associated upon a footing of justice and equality of right, and that, under a wise administration of its affairs, millions yet unborn may
                enjoy its blessings, in generation after generation, down to the last syllable of recorded time. These are my feelings, Mr. Chairman, in regard to the Union. And I look upon the distraction and the division, and the feeling of distrust, jealousy and hate, which exist in our land, urging the permanent dismemberment of this great Government of ours, with feelings of the deepest sorrow.
                My heart is pained to hear it. Sir, where is the man who gives his mind and his heart to careful reflection, who looks upon this broad land, who remembers our glorious past, who knows what it was and sees what it is, that would not rejoice to have it what it was, and not to have it what it is?
              </p>
              <p className="p-in-sp">
                Mr. Chairman, the preservation of our Government, the restoration of it to what it was, with a proper, fair, just recognition of the rights of all, aye, all, from Maine to Texas, would be a consummation, in my humble judgment, devoutly to be wished by every patriot. Sir, in regard to the very institution, the interference with which has brought the
                <PageNumber num={601} /> mighty trouble upon the land, and has our country now in the throes and agonies of dissolution—that institution itself, upon such a restoration of our government, would be stronger, aye, stronger and more enduring than it has ever been in the history of the past.
              </p>
              <p className="p-in-sp">What, Mr. Chairman, would be the effect of proper guarantees and a proper constitutional recognition of our rights upon the question of slavery?</p>
              <p className="p-in-sp">My worthy and excellent friend from Orange [<span className="persName">Mr. MORTON</span>]
                , to whom I listened some time ago upon this floor, with close attention and deep interest, told you of the change that had been wrought in the course of 30 years in his own mind upon this subject. That change came not over him alone. Thousands and tens of thousands like him in the South thought as he did when his heart was moved to envy the position of Wilberforce, down whose cheeks
                he witnessed the trickling tears under the powerful appeals of McIntosh,<span className="note" id="Note68"><span className="noteLabel">3</span>The reference is perhaps to Sir James Mackintosh.</span> Denman, and Lord Brougham, upon the subject of African slavery. A portion of the Northern people, under the same influences and with the same feelings, began the agitation of this question. They
                commenced to disturb the peace and the repose of the country. It first took its formidable interference with the Christian communities of the land that were united, North and South. Many of the Northern fanatics began to preach a crusade against the Southern Christians who owned slaves, and said that he who was the master of a negro was not fit to be a subject of the master at whose
                table he deigned to sit to commemorate His dying love. This war was urged by Northern fanaticism upon Southern Christian men until the churches of the country began to be divided North and South, and it touched the consciences of Christian men, and it touched the consciences of my friend when he was told that he could not be a Christian man and a truthful follower of Him who is the
                Saviour of all, if he was the owner of a slave. It turned the Christian mind of the South to the investigation of that subject. They examined it carefully, they examined it prayerfully, they examined it earnestly in the lights of civilization and Christianity, and the result of it all has been that they, like my friend, have come to the conclusion that instead of Wilberforce upon
                that subject having been the leading philanthropist of his day, and one who ought to have had eulogies pronounced upon him, he was the mischiefmaker of his time.
              </p>
              <p className="p-in-sp">And where, now, Mr. Chairman, will you find, throughout all the Southern land, a man who will tell you that slavery is an evil morally, socially, politically? You will find him nowhere.</p>
              <p className="p-in-sp">
                Sir, I have listened to the debates of this Convention. I have taken <PageNumber num={602} /> but little part in the proceedings of this body. But I have listened to its debates, and I heard the member from the Pan Handle rise in his place and say that slavery was an institution recognized not only by our laws, but by the laws of God. I hung in rapt
                suspense upon the eloquent tones of the gentleman from Kanawha
                [Mr. SummERs] . I heard him say to this Convention that slavery was morally, socially, politically right. I heard the same declaration come from every part of this proud old Commonwealth. Look to the advance made since 1832, when many now in this Convention advocated the passage of an act in favor of gradual emancipation.</p>
              <p className="p-in-sp">
                The same change that has come over us is now beginning by small degrees its operation in the North. The continued interference of Black Republicanism with the institution of slavery, and the final triumph of that party in the elevation of our enemies to power—what effect has that produced? It has disturbed, it has deranged, it has disorganized our Government, until we stand to-day in
                such a position that in looking over this broad land we behold seven of our sister States of the South cutting loose from their former Government and organizing a Government of their own, because they say to us that the institution of slavery is not safe in connection with the North.
              </p>
              <p className="p-in-sp">We are debating here, Mr. Chairman, its propriety, and thousands of our people have already determined that we ought immediately to follow the example of our sister States of the South, and ought to take a stand with them outside of this Union, because the institution is not safe in association with the North.</p>
              <p className="p-in-sp">
                Now, let me look for a moment at the course of the Abolition party. It has only been within the last ten years that they have arisen in large proportions in the North. Their agitation has existed for more than thirty years, but the first development in the shape of a vote to any large proportion, was the vote given for John C. Fremont for President in 1856; and its triumph by a
                majority of the people of the North, but a decided minority of the people of the United States, occurred in 1860, in the elevation of Abraham Lincoln to the Presidency. Believe you, Mr. Chairman, that if the North could have foreseen, preceding the Presidential election, the division, the distraction, the discord, the ruin and bankruptcy that would have followed the elevation of a
                Black Republican to power that has marked his success, he could have been elevated to the Presidency? Is there a man in this assembly who believes that he would have been elected? None doubt but that the result would have been entirely different if the disastrous consequences which have followed could have been foreseen. Where is he now? How
                <PageNumber num={603} /> stands his power? Sir, with seven States of the South having resumed their sovereign powers delegated to the Government, and now forming a separate and independent Government, adopting its own principles, taking possession of the forts, going on peaceably to organize an independent Government and to form treaties and alliances,
                this Black Republican administration in Washington sits powerless to interfere. We hear, day after day, time after time, that if Virginia will adopt two propositions and send to the North her demand for adjustment, that the North are ready to accept it.
              </p>
              <p className="p-in-sp">
                Let us, then, do it. If the North shall respond, if the North shall accept, by the votes of her people, the amendments to the Constitution demanded by Virginia, I ask if that vote, by which the rights of the South shall be recognized, and the Constitution of the United States so amended, will not be the final and everlasting overthrow of this fanatical Black Republican party, that
                has obtained, upon a division of its opponents, a mere temporary possession of the government? Why, sir, the very arguments we have been using in the South, by which we have brought our minds to the conclusion that slavery is right, morally, socially and politically, will have to be used by the men of the North, who will be found urging upon their people the adoption of the
                amendments to the Constitution demanded by the people of Virginia. But when they shall have the propositions—when the propositions shall be made to the Northern people—not to the Northern politicians in Congress—they will also have recognized the justness of our conclusion that slavery is right.
              </p>
              <p className="p-in-sp">
                And I ask you now, is it not wiser to make an honest, earnest, decided effort to crush out and overthrow the party that is in power in Washington, than to leave that party to crush out our institutions? or for us to unite with the South, in producing the permanent separation of the States of this Union? Sir, I a thousand-fold prefer to make an attempt to crush out that fanatical
                abolition party in the North. Let my country rise above them, and set out afresh in its career of usefulness and glory.
              </p>
              <p className="p-in-sp">
                But while these are my general views, Mr. Chairman, we must look at the facts as they are; we must look at the condition of things as they present themselves. We must meet the responsibilities that are upon us. Our Government is dissolved. Say what you will, the Union is dissolved. The Government at Washington has not yet recognized its dissolution, but it is nevertheless dissolved.
                And what a part has Virginia now to perform to restore that disrupted Union? What is her duty? Sir, I occasionally hear a remark <PageNumber num={604} /> made in this body and elsewhere, that Virginia is a submission State; that she is about to surrender her honor, her interests, and her rights. When I hear such remarks as these they only excite a
                smile—I will not say either of pity or contempt—they excite a smile, and I regret that there is any son of Virginia so little capable of appreciating her position, her responsibility, the proud position which she now holds, as to look upon her as submitting to wrong, risking and hazarding her honor and her interest, because, forsooth, instead of immediately seceding, she has
                endeavored, and is still making efforts, honorable and patriotic efforts, to adjust all our difficulties, and save our great country from permanent dismemberment, if not ruin. Look, for a moment, Mr. Chairman, at her position. Her sisters, the cotton States of the South, are under a debt of gratitude to her that they will never be able to discharge. She has thrown herself between the
                government at Washington and the seceded States. She has said to the powers at Washington, "Lay not your hand upon our sisters at the South." She has asked in all kindness to her sisters at the South to do no act that should bring about a collision between them and the General Government. She has said to that Government that if that collision comes, if war ensues, if you endeavor to
                subjugate or coerce the South, Virginia is ready to make common cause with her Southern sisters, and, if need be, her sons will die in the last entrenchments of liberty, in their defence.
              </p>
              <p className="p-in-sp">
                What has been the effect of this conduct upon the part of Virginia, which certain gentlemen have pleased to call submission? It has been that the Government at Washington has stayed its hands; it has been that the Cabinet at Washington, with Mr. Lincoln at their head, have been consulting, day after day, hour after hour, and contriving and managing, not with manly, but weak and
                shifting policy, how to get their men away from the Southern posts, and away from the Southern States, because Virginia has thrown herself in the breach and warned them to commit no act of aggression upon the seceded States.
              </p>
              <p className="p-in-sp">
                But while Virginia has remained in the Union, and, in the estimation of some, been submitting to a Black Republican Administration, she has made that Black Republican Administration submit to see the seven Cotton States of the South quietly and peacefully throw off the Government of the Union, throw aside the Constitution of the United States, and form themselves peaceably and
                quietly into a confederacy of their own, and organize fully a Government of their own. All this has been done under the shadow and protection of Virginia, animated in her patriotic effort by the noble desire that the "sober second <PageNumber num={605} /> thought," North and South, would bring about in all its parts a reunion of the first nation of the
                earth.
              </p>
              <p className="p-in-sp">
                Look to the position of Virginia a little further. While she has thus afforded protection to the Cotton States of the South, and enabled them to progress with the formation of their Government; while she has done all this, what other responsibility has been imposed upon her by her sister slaveholding States of the Border? Missouri, Tennessee, Kentucky, Maryland and North Carolina,
                with a large population, including Virginia, of about 7,000,000 people, are now looking anxiously to her—to us in this Convention—and asking us, by wise, prudent, moderate but firm action, to save and restore the Union if it can be honorably saved and restored. Sir, Virginia, while she protects the Cotton States of the South from war, listens to the counsel of her sister States in
                the border, and says, by the course of this Convention, that while we intend to preserve the honor and interests and rights of Virginia and of the South, we will endeavor so to do it as to act in accordance with the feelings and judgment of those who have a common interest with us—with the whole South.
              </p>
              <p className="p-in-sp">
                And not only they, but a large body of the people of the North, are looking to us with the most intense interest in our proceedings and deliberations, and asking us in God's name not to deliver them over into the hands of a reckless, ruthless, merciless fanaticism, but, by our firmness, by our prudence and moderation, to save them from the rule of such intolerable proscription.
              </p>
              <p className="p-in-sp">
                That, sir, is the position of Virginia. Is there any submission in it? Does it show any want of firmness, any want of courage, any want of patriotism, any want of regard for the memories of the illustrious dead who lie buried in her bosom, and around whose graves her children delight to assemble and shed the filial tear? No, sir, she has shown the loftiness of her patriotism and her
                devotion to that liberty, the love of which is interwoven in every ligament of the hearts of her people. But, then, while she has performed this service to her sister States of the South, while she has been thus acting for her sister border States, and whilst she has been endeavoring to save our friends in the North from the fury of that fanaticism which now possesses the Government,
                yet she has a duty which she must perform—a duty rests upon her, which we, as her representatives, in a spirit of patriotism, of loyalty and of firmness that knows no fear—a firmness of consequences which she must perform.<span className="note" id="Note69"><span className="noteLabel">4</span>The verb of the first parenthetical relative clause is missing.</span> That duty is, Mr. Chairman, to
                agree upon what she believes to be proper amendments to the Constitution of the United States; and proper guaranties of rights for herself and for the South, <PageNumber num={606} /> and present them to the North as her final and last demand; and, in making that demand, let her ask nothing that is wrong and fail to ask nothing that is right. Let us with
                due deliberation conclude what it is proper to demand—what our interests require—and let Virginia send it to the North as her conclusion, from which she will under no circumstances depart.
              </p>
              <p className="p-in-sp">
                I know that with many of my friends the idea exists there should be a conference of the border slave States, in order that they may all agree upon what it is proper to present unitedly to the North as our ultimatum. Here I differ, and I am sorry to differ from those with whom it has been my pleasure to act upon every other question. Why, I ask, should we have further conference with
                the border slave States to determine upon the ultimatum which should be presented to the North? Some friend said that deference and courtesy to the border States required that we should pursue that course; that unless we invite Missouri, Kentucky, Tennessee, North Carolina, Maryland, and Arkansas into council, and ask them to unite with us in considering the propositions that are to
                be presented to the North as our final demand for the settlement of these questions, that the pride of Tennessee, Kentucky, Missouri, Maryland, North Carolina, and Arkansas would be wounded, and that they will not unite with Virginia in the position she may think proper to assume. I think, Mr. Chairman, there is no danger of this. When you look at the course Virginia has already
                pursued upon this subject, there can be no danger of offence by such a course. Sir, she has had her consultation with them all—little Delaware included. After South Carolina, Georgia, Florida, Alabama, Mississippi, Louisiana and Texas, one by one had left the Union, and not only left the Union, but were taking all the steps necessary to inaugurate a government of their own,
                Virginia—true to her ancient renown—true to the memories of the fathers—true to her love of that Union and Constitution of which she was the architect—remained. She called upon her sister border slave States, and not only upon them, but upon the Northern States, to meet her in council on the 4th of February, in the city of Washington, to consult upon such terms of guarantee and
                constitutional amendment as were necessary to protect her and her Southern sisters, to enable them all to come back into the Union, to restore it to what it was before fanaticism had driven us to the very brink of that gulf into which we are now day after day threatened to be plunged. They responded to her call. All the border slave States were in conference with Virginia in
                Washington city. But, remember, the Northern States were there, and if I understood our Commissioners <PageNumber num={607} /> aright, when they returned and made their report to us of the action of the Peace Conference in Washington, it was not that there was any disagreement between the border slave States in regard to the Crittenden Compromise; all
                agreed that the proposition of Virginia was right. Yes, sir, if it had been left to the border slave States alone to fix the terms upon which the Constitution should be amended and the Union preserved, there would have been no dissenting voice between the States of Delaware, Maryland, Virginia, North Carolina, Tennessee, Kentucky and Missouri. They all agreed. But Black Republican
                Governors and Black Republican Legislatures, sent Black Republican Commissioners to the city of Washington to meet our Commissioners of the border States in council. They desired to restore the Government to its unity; they desired also to maintain the supremacy of their party. Their triumph was secured; their President had not been inaugurated ; all their surroundings induced them
                to avoid every concession to Southern demand that could be safely refused, or entering upon negotiations in the flush of victory, and with the certainty of rendering that victory barren by conceding all our demands.
              </p>
              <p className="p-in-sp">
                They came up higher than it was to be expected that Black Republicans would come under such circumstances; and all the changes from the proposition of Virginia, made in that Peace Conference were made in concession and in a spirit of patriotic compromise, by the Commissioners from the border slave States. Then, sir, Virginia agreed in that Conference with her sisters of the border
                slave States. She knew very well—they knew very well, that time had become an important element in the controversy, that it was time to settle it, and settle it as speedily as possible. If time was an important element then, how much more important now. The material interests of our land are wasting. Our commerce is languishing. Our property is declining. Our people are restless. A
                spirit of revolution is getting abroad over the whole land, engendered by the mere desperation of circumstances, by the pecuniary distress which is bringing the laboring man, his wife and children daily and hourly to the risk of starvation. We know their difficulty: they know ours. Why, then, I ask, should we delay, and keep the country in still further suspense by referring the
                whole subject to a Convention of the border states, there to be discussed anew, in amendments, in alterations, in verbal criticisms, such as have occupied our Committee in this Convention during six long weeks; which have occupied this Convention for such a length of time, and which will, if the wishes of some men prevail, occupy us still longer, and even down, probably, to the first
                of May?
              </p>
              <PageNumber num={608} />
              <p className="p-in-sp">
                Why hang in suspense on the deliberation of the border Conference to settle and fix an ultimatum to be sent to the North, which, after it shall have been agreed upon, will have to be adopted and submitted by us to a vote of the people of Virginia, and approved by them, before we send it to the North to be there acted upon—before the peace of the country is restored—a procrastination
                of settlement beyond endurance? The indications of those border slave States are not in favor of a border conference. Let us look at their action. Missouri, it is true, held her Convention, and has endorsed the Crittenden Compromise. Kentucky has not even called a Convention. No action has been taken in that State in regard to a Convention. Tennessee has refused, by a large majority,
                to call a Convention, while at the same time she elected a large majority of Union men, thus saying to Virginia, "your Convention, and the principles on which your delegates were elected, represent my people and their opinions." North Carolina held her election, elected two-thirds of her delegates as conservative men, on the Crittenden proposition, and voted down a Convention, seeing
                that Virginia had called ours, and knowing that she concurred in opinion with Virginia. The only two Conventions held in the border slave States in this time of excitement, are the Conventions of Virginia and Missouri. If we ask for a border slave State Convention, how is it to be assembled? Who will send delegates to it from North Carolina, from Tennessee, from Kentucky, from
                Maryland, to meet us in council and to consult with us? The Governors, I presume, of those different States, whose opinions—for they were elected not in regard to the present question—are as various as the Governors themselves. I say, then, in view of the position of these border States, let Virginia lay down her ultimatum, let her fix her final propositions, let her make them
                reasonable, right, just, covering the whole question, and forever expelling from the councils of the country the agitation of this important question. Let her present them to the North and fix some reasonable time in the future when the Northern people—not the Congress of the United States—shall vote on these propositions, and say whether they will preserve this Union by doing
                justice to the South, or whether they will refuse our just and fair demands and thereby incur the sin, the damning sin, of destroying the very best government that God, in his Providence, ever vouchsafed to man.
              </p>
              <p className="p-in-sp">
                Sir, I would be delighted to see this Convention come up to this proposition at once—I would like to see the extreme Union men and the extreme secessionists come up to this proposition in the spirit of compromise and in the spirit of Virginians, and let Virginia, with one <PageNumber num={609} /> voice, present them to the North. Let the North act upon
                them under all the moral power and influence which they would carry with them by the unanimous voice of Virginia. When this single proposition of Virginia's ultimatum shall be presented, with no competing proposition for her people to endorse, you would have the people of the Commonwealth endorsing it with a unanimity which would make her power felt through this entire land.
              </p>
              <p className="p-in-sp">
                It will be felt in the South, it will be felt in the border, it will be felt in the North. When you adopt your ultimatum, send it by Commissioners to your sister border slave States, and tell them why Virginia adopted it without further Conference with them. Ask them in the spirit of fraternity and patriotism and justice to endorse this action of Virginia—to send it forth as their
                ultimatum too, and to let one voice reach the North, saying, in the name of the South, "here is our last proposition. Take it in the name of the Union, in the name of the Constitution, in the name of liberty. If you accept it all will be well; but if you reject it, the damning sin will rest upon you through time and through eternity, of having destroyed the Constitution of your land,
                and given a fatal stab to the liberties of the people. We of the South have determined upon that course. If you do not agree to it we will form our own government and take care of ourselves, as best we can, looking to the God of men and of nations to guide us in the course in which our fathers led us defence of their liberties;" which every Virginian loves so dearly that I think now
                I can hear echoed and reechoed from the seaboard to the mountains, and from the mountain tops to the beautiful Ohio, the language of the immortal Henry—"Give me liberty or give me death!"
              </p>
              <p className="p-in-sp">
                I am not one of those who doubt or distrust the loyalty of any son of Virginia. My own opinion is that no man can be a Virginian and be a submissionist. I know that there are with us, as with all other people, differences of opinion. Men from different parts of the Commonwealth come up and consult together. Of course it is natural that different views should prevail. But, sir, I
                cannot, I will not, until the evidence is so palpable that he who runs may read and understand, believe that in this assembly of ours any man will be found, from any part of this Commonwealth, untrue to the honor and the interests of Virginia. No, sir, you may differ with me in the details, you may differ with me as to the course to be pursued to protect her honor, and to protect her
                rights, but I yet believe, sir, and shall continue to believe, that a Virginian heart rests in every Virginian bosom, and when the hour of trial shall come, and she is driven to stand on her defence, let her enemies <PageNumber num={610} /> come. If they refuse to give us our rights, and we cannot separate in peace, but must meet in hostile array,
                through all her border you will find—
              </p>
              <p className="p-in-sp">"Their swords are a thousand : their bosoms are one."</p>
              <p className="p-in-sp">
                While I say to our Union friends that it is right and proper they should come up to the position, I also say to our friends who are for immediate secession: "Your remedy is unattainable." If it were a right remedy under present circumstances—which I do not believe—yet it is a remedy which cannot be carried out. Pass your ordinance of secession to-morrow, and submit the question to
                the people, and let the people see and know, as they will see and know, that your final proposition has not been presented to the North and rejected by the North, and they will vote it down by a larger majority than that by which they voted it should be referred back to them. In what position will you place the border slave States, your sisters, in whom you and I and all of us feel a
                deep and abiding interest? Pass your ordinance of immediate secession, and North Carolina will say to you : "I have been taken by surprise; I expected no such thing of Virginia. I saw that she had elected a large majority of conservative men. We, too, held an election and elected—three to one—Conservatives; but believing that Virginia would present an ultimatum to the North, we held
                no Convention. Virginia has disappointed us. She has not acted as we believed and expected she would act."
              </p>
              <p className="p-in-sp">
                North Carolina would pause and consider well before she takes the final step which you propose. Kentucky, the dark and bloody ground, with her gallant people standing by the grave of her Clay and her other distinguished and illustrious men, will say that Virginia has precipitated herself out of the Union, without any expectation on the part of Kentucky that she would have adopted so
                rash and precipitate a measure. Tennessee will answer you in the same way. She will say that you have disappointed her, for that, when she saw Virginia had placed herself on the Crittenden propositions, as a compromise to be demanded of the North, while she held an election and elected two-thirds of those who were in favor of compromise and adjustment, she held no Convention in
                reference to Virginia, who agreed with her and into whose hands she had, to a great extent, entrusted her interests. And she would pause long before she would take the final step. Missouri would be placed in the same position.
              </p>
              <p className="p-in-sp">
                But let Virginia present her ultimatum. Let her eschew immediate secession. Let her avoid the Border Conference, and explain her <PageNumber num={611} /> reasons why. Let her present her ultimatum to the North. Let it be endorsed by the border slave States. Let the North, with wild fanaticism, refuse to grant her fair, her just, her reasonable demands,
                and there will be but one voice all through the border, from Delaware to Missouri, that the time has come when we must take care of ourselves, when all the States of the South must join in one common Government, receiving as many of the far North-western States as will unite with us—all the States which lie in the draft of the Mississippi and its tributary waters; and we will form a
                Government of our own, which, in power and influence, may still take back, not the name of the Confederate States, but of "the United States," and the same flag, not with the Southern stars, but with the fanatical New England stars stricken from its folds, will float over us, a free and happy people. We will thus perpetuate the blessings bestowed upon us by a more glorious ancestry
                than ever formed a Government for any people in the tide of time.
              </p>
              <p className="p-in-sp">
                This result will follow from the prompt, from the decisive action of Virginia. The proposition which I have presented to-day is a fair offer of compromise between the extremes of this body, and to which every man may come without the least sacrifice of principle. This Convention will then present a united, firm, unbroken front to the people of the States, and will bring with it the
                endorsement of the border slave States; and that voice will go to the North with a force which, if any thing can, will awaken them, from the fanatical torpor which oppresses them, and will arouse them to do justice to the land, and restore this great republic, in all its parts, from the Aroostook in Maine, to the Rio Grande—a consummation most devotedly to be wished.
              </p>
              <p className="p-in-sp">
                But, as I remarked, if it awakes them not and they refuse to listen to the voice of reason and the demands of justice, we turn away from them. We will then shake hands with and part from them, if possible, in peace. I pray God that he may so order events that if we must part, we may separate in peace. We will then endeavor to take care of ourselves as best we may, and we will leave
                with a clear conscience, with no stain upon our escutcheon—we will stand in the presence of our country and the civilized world and before our God—we will then be able to say, and say truly, that Virginia, the Mother of Washington, stands acquitted of all guilt in destroying the glorious inheritance which he bequeathed to us. But, secede to-morrow, without making this last struggle
                to preserve that inheritance; drive Virginia out of the Union to-morrow, without making this effort, and admit that you succeed in working a majority of the people to sanction the act, there will still be found a large body in Virginia who will say to the last, Virginia has <PageNumber num={612} /> not done right, she ought to have presented her
                ultimatum to the North; she ought to have demanded a vote of the Northern people, and taken this extreme step only when these people refused to accept her ultimatum. But let her pursue the course I have indicated, and Virginia would thus be placed in an impregnable position, where no finger of scorn, reproach or contempt could be pointed at her. She would be elevated to a point where
                every freeman in the land would gaze upon her, and say she is still the worthy mother of the immortal Washington. And when she shall go out, if go she must, having made this last noble struggle to save the country, the free States of the border will unite with the South, and the powers of the territory, the interest of all the American people will be concentrated in this great
                republic, while the fanatical States of New England will be left to themselves, to suffer the effects of the dreadful recoil of fanaticism.
              </p>
              <p className="p-in-sp">
                Come up, I implore you, members of this Convention, from the Pan Handle to the Dismal Swamp; from Yorktown to Point Pleasant, and from the Potomac to the North Carolina border—come up as one man, send Virginia's voice to the North united for that that is just, for that that is right, for that that is honorable—plant yourself upon that ground, and say to the North, in no spirit of
                bravado, in no spirit of insult, but in the spirit of patriotism, of right and of justice, make this demand, and say, I have planted myself here, and I mean never to depart from it, because liberty requires me to take this stand, and, whatever liberty requires, I will die, if necessary, in accomplishing, and will yield not one jot or tittle of what I believe is necessary for its
                maintenance.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I have not discussed the report of the Committee of Twenty-one. The sections of that report will be examined by this Committee seriatim, and adopted or amended, as the wisdom of this body shall suggest. I trust that it will be made to embrace every just and proper demand that should be made upon the North, and that it will be completed as speedily as is consistent with
                due deliberation, and presented to the people that they may pass upon it at the election in May. I thank the Committee for its respectful attention.
              </p>
              <div className="speaker" id="sp1794"><span className="persName">Mr. JAMES BARBOUR</span>, of Culpeper—</div>
              <p className="p-in-sp">Mr. Chairman, I am somewhat, sir, in the situation of the gentleman who has just taken his seat [<span className="persName">Mr. FLOURNOY</span>]
                . I have, I feel, no occasion to make a declaration of my attachment to that Union that did exist last November. I can point him who dares to call in question my fidelity to the cause of the Union, as it was, to recorded acts which speak trumpet-tongued. It so happens that I have done that which few men as humble and as young as I
                <PageNumber num={613} /> am have ever done—I have risked, on more than one occasion all the political hope, all the political aspirations that I had, in giving an earnest, zealous, living testimony of attachment to the Union.
              </p>
              <p className="p-in-sp">
                Sir, certain action of the Virginia Legislature last winter has been repeatedly criticised by gentlemen on this floor, who, in my judgment, do not intend to act precisely upon the doctrine that they preach. But, sir, may I not, without indelicacy, say that every incident connected with my action, last winter, in the Legislature, in reference to the Conference question, meets my
                approval to-day. I believed then that that proposal for a Southern Conference was as idle and as void of any useful purpose as the gentleman from Halifax
                [<span className="persName">Mr. FLOURNOY</span>]
                , has just shown you a Middle State Conference to be. I believe it was intended to commit us to disunion! That was my opinion then, and I have never changed it. How did I stand then, sir, and what was my action? Every paper in this city connected with the party to which I belong assailed and denounced the opponents of the proposal to go into a Conference with South Carolina—every
                leading gentleman of the party in the State, high in position, publicly and openly implored the Legislature to go into the movement—your Senators, your Governor, your Ex-Governor, in letters and addresses earnestly and vigorously appealed to us to go into that Conference. To stand up in opposition to such influences was undoubtedly a hard position; but, sir, I did not shrink from it;
                and now here, when the action of the Legislature has been, over and over again, assailed upon this floor as one of the causes of the condition of things now upon us, I say now, here, I claim my full share of the responsibility as one who did his part upon that occasion to influence the course which the Legislature then pursued. I worked hard upon that occasion—I knew what I had to
                contend against, and I did not shrink from the contest. When I got down to the final combat, with all the odds against me, I stood with a majority of the Democratic members of the Legislature at my back.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, there was one more occasion when I put my political all at stake upon the cause of the Union. When, after six months of confinement to my bed—when I had not even seen the people of my county for six months, and had no opportunity of consulting with them, I came down here to the Virginia Assembly, and there vigorously aided in the movement which inaugurated this Peace
                Conference, I stood, in the recorded language of the Virginia Assembly, determined to make one final effort to preserve this Union, and to wait until that final effort was made. My personal and political friends at home poured letters down upon me, assuring me I was not reflecting the will of my <PageNumber num={614} /> constituents, and that there was
                not a baker's dozen in the county that would support me in thus opposing the secession movement.
              </p>
              <p className="p-in-sp">
                Sir, I listened, still—I had no reason to distrust, and I did not distrust, the sincerity of those friends who thus wrote me; for, sir, as my friends around me know, who know the people of that county, that there is not a more bold and determined constituency in this Commonwealth. I went back home in my enfeebled condition and stood before them. I told them my purpose to stand by
                that one effort and to await its result. I had to retire from the county to my seat in the House of Delegates and could not see them again, until the election day, while my opponent—one of the most worthy, able and eloquent men in Virginia—was traversing the county, speaking every day to the people. Yet I was sustained by nearly four-fifths of the voters. Now, sir, when I have thus,
                by positive act, testified, at the hazard of my own political existence on two occasions, my devotion to the Union, have I here to get up and sing psalms to the Union, to convince anybody that I have been its earnest devotee? I do not intend to condescend to that, sir.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I come now to a discussion of the question itself. Sir, the first object in every discussion is to find upon what points all parties in the discussion agree, and where the points of difference occur. We understand what we are arguing when we get that far. I do not mean to argue anything but the question in issue. This report of the majority of your Committee states, and
                states well, some of the points upon which we are all agreed. They declare that—
              </p>
              <p className="p-in-sp">"The representatives of the people of Virginia in Convention assembled, are profoundly sensible of the difficulty, delicacy and importance of the duty which, in obedience to the popular will, they have assumed to perform."</p>
              <p className="p-in-sp">Now, mark sir, "they feel that the controversy which unfortunately distracts and divides our country has brought a condition of public affairs for which history has no parallel, and the experience of government no precedent."</p>
              <p className="p-in-sp">There is one point where we all agree.</p>
              <p className="p-in-sp">But let us go on—and here is a point to which I invite the particular attention of the Committee—</p>
              <p className="p-in-sp">
                "The grievances for which several of the States have withdrawn from the Union, and overthrown the Federal Government within their limits, are such as have affected the people of Virginia to a greater extent than any of the seceded States, and it is their determined purpose to require such guarantees for the protection of the rights of the
                <PageNumber num={615} /> people of the slaveholding States, as, in the judgment of Virginia, will be sufficient for the accomplishment of that object."
              </p>
              <p className="p-in-sp">See here some important points:</p>
              <p className="p-in-sp">First, that this is an unprecedented condition of affairs. Second, that the grievances for which these seceded States have withdrawn, are more our grievances than theirs. One of the propositions necessarily involved in the paragraph, is what the distinguished gentleman who has just taken his seat [<span className="persName">Mr. FLOURNOY</span>] , in better terms, has stated that this Union is now dissolved. For this report says that they are determined to have a new Constitution or else they will secede. All agree upon that. Every body here is agreed that this government and Constitution do not suit Virginia.</p>
              <p className="p-in-sp">
                Is there a gentleman within the sound of my voice who is willing to let Virginia remain under the government and the Constitution as it is? I pause for a response. Then on all hands we are agreed that the property, the liberty and the rights of Virginia are unsafe under the government as it is; that you must have a new government, a new Constitution, a new Union for the protection of
                your people, and that it is your determined purpose to resume your sovereignty if you do not get it. He is indeed a bold man, who in this presence denies this?
              </p>
              <p className="p-in-sp">
                Mr. Chairman, the majority of this Committee, after having exhausted themselves in these investigations upon which they ascertained that this is the deplorable condition of the country, do what next? These wise patriots actually propose to adjourn over for six months! That is the grave proposition. They announce to their constituents that they have a Government utterly untrustworthy.
                The picked men of Virginia, sent here to find a remedy for the difficulties under which we are laboring, after they have spent six weeks in investigating this subject, now gravely come to the conclusion, that the best thing they can do is to sit, with folded arms in the midst of a great revolution, and see if something will not turn up! After sitting here for this great length of
                time, engaged gravely in discussing and considering the subject before them, they come to the conclusion that Virginia is living under a Government utterly unsafe. After this they come to the farther conclusion, that the best thing for us to do is to live on under it for six months more, and see if somebody will not come to our help in the meantime. If such be the conduct of this
                Convention, the future historian, in recording the events of this day, may well borrow the language of this Committee, and declare that it is a course of public action, for "which history has no parallel and the experience of government no precedent." You find your countrymen, with all their <PageNumber num={616} /> property in danger, under a system of
                government utterly untrustworthy; and yet this Committee propose that you shall adjourn for six months or so, until next October or some other indefinite time. Is that wise and patriotic? I have said that history has no parallel or experience of government no precedent for such conduct. But, if you will go into the realms of fancy, you may be furnished with a precedent. The genius of
                Shakespeare has created in the Constable Dogberry a fair model of the political character of this Committee. Dogberry charges his watchman, "if you meet with one of the Princes of the blood, bid him stand; if he won't stand call the watch together, and thank God the case is no worse, and go to bed."
                [Laughter.]
                Virginia announced her will through the voice of her representatives at that Capitol, and challenged the Black Republicans, Princes of the blood. She bid them stand; they won't stand, and Virginia's picked men come together, thank God the case is no worse, and go to bed for six months. That is the Dogberry policy now urged upon this Convention. Merciful Father—what a melancholy
                contrast will the record of history present between the Virginia of this day and the Virginia of 1776, if this Convention act as your Committee counsel you to act. Well may her sons bow their heads in deep humiliation at such a spectacle. In the meantime, what is our condition? You tell the people that they are without a Government; that the Government that presides over them is in
                the hands of a party hostile to them, and that two hundred millions of their property is rendered insecure and unsafe.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, it has been one of the most remarkable incidents of this remarkable revolution which is now going on, that the nonslaveholding, the laboring classes, have been the most excited and determined in favor of the secession movement. Any person who has been much among the people knows this to be the case. How is this to be accounted for? It is very easily accounted for. Every
                class of society in the slaveholding parts of the State is dependent upon the prosperity of the farming and slaveholding class for its own prosperity, because that is the great interest of the community. The lawyer, the physician, the mechanic and the day laborer all derive their support from the owner of lands and negroes; and when you depress the interest of the owner of lands and
                negroes, the interests of all other classes in the community are also correspondingly depressed. The slave owner can stand up against the depression for a while. He can repair his losses by sending a negro occasionally to the South for sale; but the mechanic and the laborer, in the interval of delay, sustains the most serious injury, because his employment stops, and he
                <PageNumber num={617} /> is deprived of the means of getting bread for himself and family. The slaveholder can stand it, but the other classes of society in the slave-holding communities cannot. Just look at what you propose to do with those classes. I most highly respect the members of the Committee on Federal Relations, and I disclaim allusions of an
                offensive character towards any gentleman of that Committee, in any remarks I may make with regard to the action of that Committee. Some of them are warm, personal friends of mine; one or two of them I respect as highly as any men in the universe, outside of my own family. But, sir. just mark the coincidence between the action proposed by these gentlemen and the action proposed by
                that arch-enemy of the institution of slavery, William H. Seward. What is his policy? He has announced it. It is "to wait one, two, three years for these eccentric movements of secession to have run their course," and then he will do something. What? Call a National Convention. Does not this report bear precisely the marks of the same manufacture? Wait one, two, three years, and then
                call a National Convention. These gentlemen are professing to protect and preserve the institution of slavery, and yet they walk in the same line of policy recommended by William H. Seward, and that is to wait, piddle and diddle away, one, two and three years, and then get a National Convention. You are to have a Border State Conference. That will take time; but still you are not
                done, for you finally wind up with a proposition in deference to the opinion of the present Administration, to create some new agency to deal with these questions.
              </p>
              <p className="p-in-sp">That is Mr. Seward's policy—to wait sometime; it may be, he says, one, two, or three years, until these eccentric movements at the South are over—that is, until the South is broken down or conquers; and after that time he proposes, like this Committee proposes, to call a National Convention to deal with these matters.</p>
              <p className="p-in-sp">
                Well, sir, suppose you send forth your ultimatum to the North, tell me, as a sensible man, whilst you are waiting for the response, where is it best to be? Where are you most likely to get a satisfactory response? Where must you stand during those one, two or three years? Under a government bitterly hostile to you; a government so hostile to you that your own fellow-citizens consider
                it a disgrace to see one of his neighbors going to the polls to vote for any one in that government? Will you stand under a government organized upon a principle of war, hostility, extermination of your interests, during this protracted period, when you are waiting for this response from the voters of the North? Why? You cannot say you are not willing to take any other
                <PageNumber num={618} /> course because you don't want to break up the Union. As all must agree the Union is broken up, the Union is dissolved to-day, and you don't render a dissolution of the Union any less a fact by declining to acknowledge it. You cannot change the fact that the Union is dissolved, by refusing to look at it. There it stands, as the
                distinguished gentleman from Halifax
                [<span className="persName">Mr. FLOURNOY</span>] , said a short time ago, it is a fact, we are bound to acknowledge it, the Union is now dissolved.</p>
              <p className="p-in-sp">
                All the powers on earth may recognize the existence of that government before you re-assemble here. Which is the stronger, which is the safer government for you to repose your fellow-citizens under? Yonder at Washington is a government, no body refuses to see. But it is a government enfeebled, may I not say broken down, in all its departments; broken down in every respect. No body
                stands blindfolded when you look at Washington; every body sees that government. Let me inform the gentlemen here assembled, that there is a government of the Confederate States of the South at Montgomery, which is an infinitely stronger government at this day and at this hour. Do you want to tell your fellow-citizens of Virginia that you will remain under this Washington government,
                when you see an efficient and friendly government imploring you to join them; a government that is infinitely stronger even at this time than the government at Washington? And it grows stronger every day, as time consolidates all new nationalities.
              </p>
              <p className="p-in-sp">Shall I prove that the government at Montgomery is stronger than the government at Washington. Does a body of Virginia statesmen absolutely require to be enlightened upon that point! Sir, one of the strongest evidences is the fact to which my friend from Halifax [<span className="persName">Mr. FLOURNOY</span>]
                , alluded—the fact that they are not collecting revenue, and are taking their men out of the way of being shot at, as fast as possible; withdrawing their forces from the Confederate States and bringing them here to the non-Confederate States. If my friend from Halifax and myself, agree about the fact, we have a different theory for explaining the fact. He says that it was because old
                Virginia requested them to do it; I say it was because the Confederate States told them that they must do it. They had the same reason—if I may be excused an allusion in the stump speaking view—they had the same reason for not coercing those Confederate States that Jack had for not eating his supper—he couldn't get it. That is the reason they don't coerce those Southern States—they
                could not do it.
              </p>
              <p className="p-in-sp">The Government at Washington, if it acts as the gentleman from Halifax represents, will do what Virginia statesmen tremble to do.</p>
              <PageNumber num={619} />
              <p className="p-in-sp">
                It practically acknowledges the independence of the government of the Confederated States of the South. The Federal Government will then say, "There stands one of the Powers of this earth." What did those same men say just before? "The first thing they must do was to find out if they had a Government." That is what they said on the floor of the United States Senate. What was the
                policy announced by men in the Cabinet controlling the Executive authorities of the United States. They could not do anything until they found out whether they had a government down in the Confederated States. Then they would consider what sort of policy to pursue. They said old Buchanan would not do anything, but when their man from Illinois got into his seat he would show them he
                had a government; and when they found out whether they had a government, they would talk about conciliation, but they scorned to do it before that. Well, after the fourth of March I don't know what they found out, but I know what they do, or have to do, in a short time, and that is to act upon the principle that they have not any Government in the Confederated States. They said that
                was the talk of old Buchanan; that to act as if there was no Government down there was a trick of this old imbecile Buchanan, but just let them get in and they would show whether there was a Government or not. Well, they got in and their acts have shown that upon due enquiry they have discovered that they haven't any Government there at all. That is a practical recognition of the
                Montgomery government; and yet we stand with averted eyes and consider it treason to talk about going with that Government of the Southern Confederacy.
              </p>
              <p className="p-in-sp">
                What is another fact, sir? Mr. Chairman, of all the tests of the strength of a Government, that which is most conclusive and most final is the test of its financial strength. You may get men to say the Government is strong, or it is not strong; but when you ask a man to put his money on an issue, you are apt to get at his heart and find out his true sentiments. If you will find men
                staking money upon the credit of a Government, you have the strongest evidence of the belief of the men in the stability and the strength of that Government.
              </p>
              <p className="p-in-sp">
                Now, let us, by this test, try the two Governments between which we are now situated. Abe Lincoln's Government is borrowing money and its stocks go off at ninety cents to the dollar. There is the measure of their Government. What is the condition of the other Government —this thing that you say is in its chrysalis state—so low down that if you poke a paper resolution at it you
                crumble it into ruins—what is it doing? Why, sir, I have now in my pocket a communication from the <PageNumber num={620} /> Secretary of the Treasury of that Government, in which he states that upon that loan of theirs of fifteen millions, which is to be exposed to public subscription on the 15th of April, they have already a large proportion of offers,
                at and above par, and in his judgment the whole of it would go off at or above par. Was ever such strength exhibited by a new Government in the first few weeks of its existence?
              </p>
              <p className="p-in-sp">
                Mr. Chairman, which Government would be the safest under which to live? The people who command the most money are able to equip the largest army, are the people of most military power, are they not? Well, sir, I have pointed out the financial condition of those Governments. The Government at Washington owes a hundred millions of dollars; its stocks have no par sales. When Virginia
                goes, certain as fate not only the other Border Slave States, but a large portion of the Border non-slaveholding States will go with her or go after her. And I say if the credit of the Federal Government were as high to-day as it was four years ago, under existing circumstances, the secession of Virginia would crumble that credit into atoms. They say, that this concern crumbling to
                pieces is going to war, and bid freemen to tremble at the prospect. Do you think, sir, that Virginians are going to hesitate? The Union is dissolved. If you affect not to see it, you do not change the stubborn fact which now passes into history. There is a Government at Montgomery as well as one at Washington. There is a Union of slaveholding States at the South as there is a Union
                Government by the exclusive appointees of the non-slaveholding States at Washington. The one begs you to come to it—take hold of it—control it for your own purposes. The other, to say the least, gives you the cold shoulder—is cavalier in its bearing towards you. "Under which King, Bezonian?" Where does Virginia go? Under the abolition Government, or under the slaveholding Government?
                We have that issue to decide. But I am told, that a Mr. Yancey said he was going to bring us to it—that Mr. Yancey foretold the things that have happened. Well, sir, I doubt very much whether Mr. Yancey ever said all the things that have been attributed to him, but if he did, because he foresaw and foretold what has happened, we should not fail to do our duty and make the best of it.
              </p>
              <p className="p-in-sp">Sir, nations view their own interests, and every nation acts upon its view of its own interests, not upon sentiment and nice points of honor, or a mere question of etiquette. I imagine that the people of Virginia are not going to forego their own interests for fear they will make a prophet of one Mr. Yancey, down yonder.</p>
              <p className="p-in-sp">In my opinion, Mr. Chairman, you might send out before the <PageNumber num={621} /> Virginia public all the powerful orators in this Hall; you might send out the gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>] , the gentleman from Halifax [<span className="persName">Mr. FLOURNOY</span>] , the gentleman from Montgomery [<span className="persName">Mr. BALLARD PRESTON</span>] , the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>]
                —all of these gentlemen from one side, and let them traverse this Commonwealth from now until next October, arguing to the people that they ought not to join the Southern Government, they would be rebuked by shouts and huzzas when some cross-roads'-man should get up and tell the people, "Yonder it is! There is a Government in the South, in successful operation!—where all the
                interests of the South are protected! which Virginia is invited to join!"
              </p>
              <div className="stage it">[At this point <span className="persName">Mr. BARBOUR</span>, who has just recovered from a severe attack of sickness, became exhausted, and postponed the further delivery of his speech.]</div>
              <div className="speaker" id="sp1795"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">The gentleman from Orange seems very much exhausted, and I hope he will be permitted to resume his remarks in the evening session.</p>
              <div className="speaker" id="sp1796"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair certainly hopes, if the gentleman is compelled to suspend his remarks on account of indisposition, that the Committee will give him that opportunity. The Chair, however, can make no arrangement upon the subject. If the gentleman yields the floor he does it unconditionally.</p>
              <div className="speaker" id="sp1797"><span className="persName">Mr. GOGGIN</span>—</div>
              <p className="p-in-sp">I desire to address the Committee, but I presume the gentleman will have abundant time to finish his remarks to-day, after I shall have concluded.</p>
              <div className="speaker" id="sp1798"><span className="persName">Mr. BARBOUR</span>—</div>
              <p className="p-in-sp">I do not want more than half an hour.</p>
              <div className="speaker" id="sp1799"><span className="persName">Mr. GOGGIN</span> spoke as follows:</div>
              <p className="p-in-sp">
                Mr. Chairman: I am not one of those who can see no difficulty at all attending any of the proposed plans for the adjustment of the questions which now agitate the public mind throughout the country. I am not one of those who can see no objection to the report of the Committee of Twenty-one; nor am I one of those who can see none in carrying out the purposes and the doctrines of some
                gentlemen in this Convention who go for immediate and unqualified secession. We are in the midst of troubles surrounding us on every hand, that should cause every patriot, every man in this Convention, every one in this Commonwealth, every citizen of the United States, if he be a patriot, to sacrifice for once all his party predilections and associations and when all should endeavor
                to come up as one people to rescue the country from the dangers which beset us.
              </p>
              <p className="p-in-sp">
                I have no apologies to make to any one for occupying the position <PageNumber num={622} /> which I do. While I have belonged all my life to a party with which it was my pride and my pleasure to act—a party I undertake to say, Mr. Chairman, as noble, as generous and as patriotic as any party that ever had an existence in this country; and while I shall
                be found to act with that party when the issues which divided the country come again before this people for settlement, yet I cannot permit my previous party associates in this emergency to carry me exactly in that direction which a majority of my political friends on this floor seem inclined to pursue.
              </p>
              <p className="p-in-sp">There are many things in this report of the Committee of twenty-one, that commend themselves to my approbation. I agree with the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] , I agree with my friend who is before me, the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>] , I agree with my friend from Halifax, who has just addressed the Convention [<span className="persName">Mr. FLOURNOY</span>]
                , and I agree with many others around me who have taken the view which they have presented to the people of Virginia, of the great advantages of a national Union. That is the ground, sir, upon which I placed myself when I first addressed this body at the beginning of its session. I stated then, as I say now, that I believe there is nothing but the peace and the quiet of the country,
                and the security of our property, that can compensate for the loss of the advantages we would enjoy in the Union as it was designed to be—that Union as it was established by our fathers—that Union as it was of old—the Union as I would now have it to be. I say, then, I listened with no ordinary pleasure to my friend before me
                [<span className="persName">Mr. PRESTON</span>]
                , when he portrayed, as he did a few days since, in such glowing colors, the blessings of the Union. I listened with equal satisfaction, to my friend from Augusta, and to my friend from Halifax, this morning. But, sir, it seemed to me that they, as well as others on this floor, had turned their eyes from the fact that this Union is now dissolved. They seem to some extent to have
                forgotten that we now occupy a peculiar position, and that new relations have been created; while there are those upon this floor, also, who seem to be desirous of conciliating the North, or to cement, rather, the bond of union between Virginia and the States of the North, my thoughts, my feelings and my interests, and those of the State, have directed me to seek for and to consider
                first some plan, some scheme that should restore some bond of union, of which the seceding States, as I trust, will yet become a part. Sir, I say here now on this occasion, as I said before, that I desire to reestablish the Union which has been dissolved, upon terms just to all sections. I would then glory in seeing that flag floating proudly over all the States as it once waved,
                with its full surface, in all its breadth; <PageNumber num={623} /> and yet, when it comes to the single issue between the North and the South—when you put to me the question whether I am to go with the seceded States, whether I am to unite myself with that portion of the Union yet remaining, with the North—I say here now, with all the responsibility
                attaching to such a declaration, that my destiny is there
                [pointing to the South]
                . I am ready to go with Virginia in forming a Union—in consolidating and binding together a government with those who, with herself have been assailed, whose rights have been prejudiced by the people of one section of this Union; because I believe there is upon us now that very state of things to which patriots in every part of this land have looked for many years, a crisis which was
                likely to arise and is now upon us. I do not believe, sir, in the doctrines of gentlemen before me and around me, the doctrine of the right of a State to secede, as a remedy under the Constitution, or as existing by compact; but I believe it to exist only by the action of the people in Convention, but only as a right which, for good cause, our fathers acted upon when they declared
                themselves independent of England—that mother country of which they had heretofore been a part. And when I arrive at the same point that those gentlemen do, I hope they will not quarrel with me because I do not travel the same road with them, or do not make so much haste in my journey. Our object, I trust, is a patriotic one on all sides. I should be the last man upon earth to
                suppose that my friends from Montgomery, from Augusta, from Halifax, and those who act with them upon this floor, were not actuated by the same equally high and honorable feelings that I claim for myself. I have known them long, and if there was anything that would cause me to believe that in this crisis I was not in a position that I ought to occupy, it would be that I differ from
                those whom it has heretofore been my pride to associate, men whom I honor, men whom I esteem, men whom I know are endeavoring to do as I am endeavoring to do—that which each believes is for the interest and honor of the State and for the good of the public. I am the last man upon this floor who will cast imputations upon them. But, sir, we are now, as I said, in a condition that
                demands at our hands not only prudence but discretion, and the casting aside of all party feelings and associations. We are, in my humble judgment, just where all have been when appeals have been made to them in terms much more beautiful and eloquent than I can express. "That there exist in our country, and have existed from the foundation of the Government, political parties
                entertaining different political opinions of the powers of the Federal Government, and the mode in which they have been, <PageNumber num={624} /> or should be, administered, is neither to be denied nor deplored. Party is the indispensable and beneficent attendant of free institutions; the unavoidable consequence of the diversity of human judgment freely
                and independently exerted. Free Governments owe the purity of their administration to honest and independent parties, engendered by freedom of investigation. To destroy all party spirit is alike impracticable and undesirable. But there are points of Union, matters of sentiment and judgment, common to all parties. The virtuous, intelligent and patriotic of all party sects concur in
                one common sentiment of devotion to public liberty and of reverence for the unsullied faith of our country. When these are endangered, patriotism and duty require that all minor differences of opinion should be silenced and forgotten. Party ceases to be the ally and becomes the betrayer of public liberty, if it aims to produce division at the time when the united exertions of all
                good citizens are required to maintain it."
              </p>
              <p className="p-in-sp">That, sir, is the language of the gentleman who sits before me, from the city of Richmond [<span className="persName">Mr. MACFARLAND</span>]
                . It is language to which my heart responded then, when he used it years ago, as it responds to it now. Then, sir, I say that there should be no party feeling, no remembrances of party association upon this floor. We are in a situation, Mr. Chairman—if you will permit me to say that such is my belief—such as our fathers were, in the year 1776. The State of Virginia now stands in her
                relation to this Northern Union as stood the Colony of Virginia when she was oppressed by the mother country; when the strong arm of British power was upon her, and when it became necessary that the people should unite in the defence of common rights, common privileges and common liberty; at the time that infant colony, as was the State of Virginia on the 6th May, 1776, in the city
                of Williamsburg, made known the purpose of the people no longer to submit to the grievances under which they labored, and against which they had petitioned the King and Parliament of Great Britain. They declared that they had no alternative left but an abject submission to the will of overbearing rulers, or a total separation from them. That appealing to the searcher of hearts for
                the sincerity of former declarations of an earnest desire to remain in connection with the mother country, yet they were driven from that inclination by the wicked councils of its rulers, and by the eternal laws of self preservation. They, therefore, unanimously resolved that a general Congress ought to declare the Colonies free and independent
                <PageNumber num={625} /> States, "absolved from all allegiance to or dependence upon the Crown or Parliament of Great Britain."
              </p>
              <p className="p-in-sp">That, sir, was the declaration made by our ancestors in the city of Williamsburg, preceding the Declaration of Independence near two months; in that noble old city where you and I [referring to <span className="persName">Mr. MONTAGUE</span>]
                have been, and where we enjoyed the hospitality of those who love the spot which is consecrated by so many glorious recollections of the past. Now, sir, is this our condition? I am not here to speak of the causes which have led to the dissolution of this Union. I am not here even to defend, to the extent to which other gentlemen have and will upon this floor, the action of South
                Carolina, of Georgia, and of the other seceded States. I am not here to say that they have taken their position properly without consultation with the border slave States of this Union. I am not here upon that question, but I stand here to point this Convention and the people of the State of Virginia, to the fact that whether it be with cause, or whether it be without cause, these
                States are no longer a part of this Union. I confess, then, to a fault, if it be one, to think that if even they have deserted the paternal home without all the causes which they allege, that it is our duty still to go in pursuit of the wanderers that we may reclaim them. I would go and endeavor to bring them back; I would not go and endeavor to teach our brothers their duty, but I
                would tell them here in this Union, this consecrated home of ours is the spot where the Father of his country placed all his children when he left them—that home where he would wish to behold them now could he look upon the loved and the lost. But, sir, they are gone, and I confess that I have a strong, a most earnest desire in the re-construction of this Union, not only to bring
                back those States, but to engraft upon any Constitution such provisions, such amendments as will forever put to rest all these distracting questions, so that even South Carolina may live as one of the sisters of the Union alongside of Virginia, and alongside of all of the other States that formed it.
              </p>
              <p className="p-in-sp">But, sir, I repeat, the Union is broken up. How was it? Not by the act of Virginia, but it was dissolved by the act of seven other States, who, as they conceived, for just cause, sundered the bonds that bound them to us. Then, I say, I want you all—all of us to reconstruct, as far as we can, the Union, so as to bring back these States into one common family.</p>
              <p className="p-in-sp">
                Now as to the causes which may have operated to produce this state of things upon the part of the North and the acts of the Southern government, I do not propose to dwell. Can we shut our eyes to the <PageNumber num={626} /> fact, however, that that very state of things has arisen here which our political associates upon this floor—aye, sir, the very
                men for whom we voted for the Presidency in 1856 and in 1860—have declared, whenever it arose, would be just cause for a dissolution of the Union. I stand here to acknowledge that I voted, in 1856, for Millard Fillmore as a candidate for the Presidency, after he had been defeated, too, for the nomination at Baltimore, four years previous. I stand here to declare that when he became
                President of the United States, by the death of General Taylor, I felt gratification in believing that when he went into the Presidential chair, from my knowledge of his previous character, and from his associations and his speeches upon the floor of the House of Representatives, he would pursue a course which would give peace and quiet to the whole country. I knew from the character
                of the man, that he might be trusted by the State of Virginia, and I repeat, that with pleasure I supported him upon that occasion, after the sentiments which I had so often heard uttered by him. In a speech made at Albany, in 1856, Mr. Fillmore fully and satisfactorily again expressed himself. The state of things to which he called the attention of the country has now arisen—he was
                then speaking of the probable election of Mr. Fremont to the Presidency, and, referring to the fact that he was a sectional candidate, and was supported wholly upon the anti-slavery issue, supported exclusively by a party at the North, he said then, what I believed he would say, and what he ought to have said—
              </p>
              <p className="p-in-sp">
                "We see a political party representing candidates for the Presidency and Vice-Presidency, selected, for the first time, from the free States alone, with the avowed purpose of electing those candidates by suffrages of one part of the Union only, to rule over the whole United States. Can it be possible that those who are engaged in such a measure can have seriously reflected upon the
                consequences which must inevitably follow in case of success? Can they have the madness or folly to believe that our Southern brethren will submit to be governed by such a Chief Magistrate?"
              </p>
              <p className="p-in-sp">
                He then asks his hearers whether the North would submit to be ruled over by a President elected exclusively by the votes of slave-holders, and expresses the opinion that they would not submit for a moment. He then proceeds: "And do you believe that your Southern brethren are less sensitive on this subject than you are, or less jealous of their rights? If you do, let me tell you that
                you are mistaken, and therefore you must see that if this sectional party succeeds, it leads inevitably to the destruction of this beautiful fabric reared by our <PageNumber num={627} /> forefathers." These are the words and warning of a patriot.
              </p>
              <p className="p-in-sp">That was the question which was put to the American people in regard to the election of Fremont, by Mr. Fillmore himself. Let me ask my countrymen of Virginia: is there anything in the character of Mr. Lincoln and his associates to entitle them to more respect? And was he not elected by a merely sectional party? has not that party triumphed?</p>
              <p className="p-in-sp">
                There was another distinguished man, for whom I also voted, whom in common with gentlemen around me of that party to which I have ever belonged, I have always sustained—a man who was sustained in the last canvass, earnestly and zealously, not only by my friends at the polls, but by friends who, with all the powers of eloquence and the force of argument, contributed to his election.
                But, I believe, he had entertained sentiments then, such as every man of every party in the State of Virginia might endorse—a man, permit me to say, who has been devoted in all his walks of life, public and private, to the promotion of the honor and happiness of his country. I allude to John Bell, of Tennessee. What did he say? Gentlemen seem to suppose that I am making a new issue
                for the first time; not so, it is an old issue, made by our own men, an issue which must be tried by the law as laid down in our own books. I want to tell you, as I want to tell the country, that I stand upon the platform with Millard Fillmore and John Bell.
              </p>
              <p className="p-in-sp">
                Mr. Bell, standing in the Senate of the United States in 1850, said: "The question is whether mutual trust and confidence shall continue to animate and encourage mutual efforts in promoting and multiplying common benefits, or whether mutual hate and distrust shall step in to check all progress, to distract and confound all joint endeavors for the common welfare; in fine, to entail
                upon the country all the evils of endless discord? That is the question. And when you present that issue to me, I say give me separation, give me disunion, give me anything, in preference to a Union sustained only by power, by constitutional and legal ties, without reciprocal trust and confidence."
              </p>
              <p className="p-in-sp">
                Mutual trust between the sections no longer exists—confidence between the States is destroyed, and discord has usurped the high places of law and of order. Parties have become sectional, not national; party issues are abandoned, and geographical discriminations mark the divisions now into Northern and Southern parties; no, sir, not parties, but into sections—sections between which
                there must be a, lasting separation, without forbearance on the part of both and security for all.
              </p>
              <p className="p-in-sp">
                Then, sir, I say, the language of John Bell was the announcement <PageNumber num={628} /> of the sentiment of a patriot. Now, Mr. Chairman, is not the present condition of the country, and the state of public affairs, just such as I may present to your minds from the picture which has been drawn with such skill by those who have already presented it?
                Does not that very state of things exist in the country to which Mr. Fillmore alluded, and to which Mr. Bell referred when one of them, a Northern man—not Northern man with Southern feelings, as another distinguished man
                [Van Buren]
                was said to be years ago—but a Northern man with the feelings of a patriot who loved the Union, and who told us that in such a crisis as this the Union would be dissolved—that Southern men would not stand by and submit to such humiliation? And yet, we find sons of the South, good, true and loyal as they are, I know, standing in this hall, telling us to wait, wait, wait a little
                longer before you take your position. They talk about the glories of this Union—its past history and its glorious deeds. Why, to all that I subscribe. They speak of its advantages; to all that I give my assent. They dwell upon its blessings; from these I would not detract. But yet, Mr. Chairman, the truth is before us, the crisis is upon us. Do we derive any consolation that Mr.
                Lincoln is President of the United States, instead of John C. Fremont? I know there is no man on this floor holding different sentiments from those I entertain. I know there is no one man within the sound of my voice, who holds any sentiment in regard to slavery as an institution in common with the President. I know there is no member of this body who would not spurn his sentiments;
                but, sir, I ask the gentleman here to remember that he is President of the United States, inaugurated, and now discharging the Executive functions upon almost a single, isolated principle in the platform upon which he was elected. He stands upon that principle of the Declaration of Independence which was to give liberty to every man in whatever condition he might be. But are you to
                derive any consolation from the fact that John P. Hale is one of his advisers—not a member of his Cabinet, but who stands in our councils at Washington a member of the Senate of the United States, in the majority as he now is—one of Mr. Lincoln's defenders, as able and efficient man as he is, supporting the doctrines of the President? I cannot answer gentlemen here who talk about the
                South having given this majority to the free soil party of the North. I have no reply to make, for the fact is so. I simply call your attention, Mr. Chairman, and gentlemen of the Convention, to this, however, that no matter what cause may have produced it, you are to look at the condition in which the people of Virginia find themselves as this moment. It is not worth
                <PageNumber num={629} /> while to tell us that a different state of things would have existed if Georgia and the other seceded States had remained in the Union. That is a self evident proposition. I tell you again, Mr. Chairman, that the fact being so, it is the duty of every Virginian in this body to look to the interests of those whom he represents,
                and to all the interests of this proud old Commonwealth in view of that very fact. Are we to derive any consolation from the fact that Mr. Seward is one of the constitutional advisers? He, too, is one of the strongest men of the great sectional party which now controls the destinies of the States and of the nation.
              </p>
              <p className="p-in-sp">
                I should suppose, Mr. Chairman, that there is no one here who would endorse the sentiments of Mr. Seward. I know there is not. But I should suppose that every member of this body who would reject his sentiments would also reject and repudiate the man as the leader of the Cabinet. A man of talents, a man of great abilities he is conceded to be; yet, representing as he does in that
                Cabinet the same sentiments upon which Mr. Lincoln was elected, he has talked not only about an "irrepressible conflict" between slavery and freedom, but he stands before you as one of the endorsers of one of the most infamous pamphlets that has ever emanated from the public press—a pamphlet which took the ground that slavery was a nuisance—and I have no doubt that he believes in the
                legal doctrine that, being a nuisance, it ought to be abated, and that in that abatement the forms of law need not be strictly observed.
              </p>
              <p className="p-in-sp">These are some of the cardinal doctrines of the party : "Slaveholders are a nuisance."</p>
              <p className="p-in-sp">"It is our imperative business to abate nuisances."</p>
              <p className="p-in-sp">"We propose to exterminate this catalogue from beginning to end." "We believe that thieves are, as a general rule, less amenable to the moral laws than slaveholders."</p>
              <p className="p-in-sp">"Slaveholders are more criminal than common murderers." "Slaveholders and slavetraders are, as a general thing, unfit to occupy any honorable station in life."</p>
              <p className="p-in-sp">"It is our honest conviction that all the pro-slavery slaveholders, who are alone responsible for the continuance of the baneful institution among us, deserve to be at once reduced to a parallel with the basest criminals that lie fettered within the cells of our public prisons."</p>
              <p className="p-in-sp">"Were it possible that the whole number (that is, of the slaveholders) could be gathered together, and transferred into four equal bands of licensed robbers, ruffians, thieves and murderers, society, we feel assured, would suffer less from their atrocities then than it does now."</p>
              <PageNumber num={630} />
              <p className="p-in-sp">
                "Inscribed on the banner which we herewith unfurl to the world, with the full and fixed determination to stand by it or die by it, unless one of more virtuous efficacy shall be presented, are the mottoes which, in substance, embody the principles, as we conceive, that should govern us in our patriotic warfare against the most subtle and insidious foe that ever menaced the inalienable
                rights and liberties and dearest interests of America.
              </p>
              <p className="p-in-sp">"1. Thorough organization and independent political action on the part of the non-slaveholding whites of the South.</p>
              <p className="p-in-sp">"2. Ineligibility of pro-slavery slaveholders ; never another vote to any one who advocates the retention and perpetuation of human slavery.</p>
              <p className="p-in-sp">"3. No co-operation with pro-slavery politicians; no fellowship with them in religion; no affiliation with them in society.</p>
              <p className="p-in-sp">"4. No patronage to pro-slavery merchants; no guestship in slave-waiting hotels; no fees to pro-slavery lawyers; no employment of pro-slavery physicians; no audience to pro-slavery parsons.</p>
              <p className="p-in-sp">"5. No more hiring of slaves by non-slaveholders.</p>
              <p className="p-in-sp">"6. Abrupt discontinuance of subscription to pro-slavery newspapers.</p>
              <p className="p-in-sp">"7. Immediate death to slavery, or, if not immediate, unqualified proscription of its advocates during the period of its existence.</p>
              <p className="p-in-sp">"A tax of sixty dollars on every slaveholder for each and every negro in his possession at the present time, or at any intermediate time between now and the 4th of July, 1863.</p>
              <p className="p-in-sp">"An additional tax of forty dollars per annum, to be levied annually on every slaveholder, for each and every negro found in his possession after the 4th of July, 1863.</p>
              <p className="p-in-sp">"This, then, is the outline of our scheme for the abolition of slavery in the Southern States. Let it be acted upon with due promptitude, and, as certain as truth is mightier than error, fifteen years will not elapse before every foot of territory, from the mouth of the Delaware to the emboguing of the Rio Grande, will glitter with the jewels of freedom."</p>
              <p className="p-in-sp">These are the sentiments of Mr. Lincoln's party, I will not say all of his party, for I believe there are many of the Republicans who would repudiate them. I am very sure, sir, they are not the sentiments of thousands of good, honest, conservative men at the North—they spurn them, and spurn the men who hold them.</p>
              <p className="p-in-sp">
                I have not time now, nor do I intend to pursue the doctrines of this pamphlet. I simply call attention to it from the fact that I was speaking <PageNumber num={631} /> of Mr. Seward, who tells you, however, he had read it, and gives an endorsement of the book, which is printed on the title-page, and is sent broadcast throughout the land. He says:
              </p>
              <p className="p-in-sp">"I have read the 'Impending Crisis of the South' with deep attention. It seems to me a work of great merit, rich, yet accurate, in statistical information, and logical in its analysis."</p>
              <p className="p-in-sp">
                Now, sir, I ask you, Mr. Chairman, if the South and the State of Virginia are to derive consolation from the fact that this man, the endorser of this infamous pamphlet, is one of the advisers of Mr. Lincoln? Are we to derive it from the fact that Mr. Chase, who entertains similar sentiments, is another? Are we to derive it from the fact that Joshua R. Giddings now stands at the mouth
                of the underground railroad tunnel, as Consul General of the United States, in Canada, there to carry out and execute the decrees of a party which tells us that slavery, being a nuisance, it must be abated? Do you derive it from the fact that David Wilmot, the author of the celebrated proviso, has been elevated to the Senate of the United States since the very Peace Conference
                measures, so often alluded to in this debate, were inaugurated? As if looking only to the interests of a particular section, and in order, as much as lies in their power, to do all that they can to excite the people of the South, they have placed<span className="note" id="Note70"><span className="noteLabel">5</span>The object is omitted here.</span> in a high position, as one of the advisers
                of the President of the United States, by virtue of his position as a Senator. Do you derive any consolation, Mr. Chairman, from the fact that Mr. Dayton has been sent to represent this country as Minister to France—a gentleman who ran for Vice President with Mr. Fremont, on the "irrepressible conflict" ticket? But I have many reasons to respect Mr. Dayton, who is a man of talents
                and of high social position; and if a Freesoiler were to be selected for the situation he fills, a more unexceptionable man could not be found in any Northern State.
              </p>
              <p className="p-in-sp">
                Is there a member of this Convention, sir, who can look down along the vista of time and say that there is anything to beckon him on in his hopes and aspirations for peace? Remember that Cassius M. Clay, entertaining the sentiments of Mr. Fremont and Mr. Lincoln, and other freesoilers of the North, has now become a representative of this government in the Court of Spain. Do you
                derive any comfort from that fact? Is it to be found in the fact that James Watson Webb has been selected to represent this government abroad? I think not, when his sentiments are so well known. There is another man, a German Red Republican, with an unpronounceable name—Schurz or Schwartz, I believe—who has got a foreign mission, though the worst
                <PageNumber num={632} /> sort of an abolitionist. Are you to derive comfort from the reflection that this country is to be represented by such a man as that? Do you derive it from the fact that the Ohio Legislature, pandering to that depraved spirit which has actuated many of the people of the North, has elected to the Senate of the United States, as
                another adviser of the President, Mr. Sherman, a man whom the House of Representatives itself repudiated, as unworthy the position of Speaker of that body, because he, too, was an endorser of this miserable pamphlet to which I have referred? Do you derive any satisfaction from the fact that but the other day the people of New Hampshire, by overwhelming majorities, installed Black
                Republicans in all her offices, so that, in sending Commissioners to the Peace Conference, she was but "keeping the word of promise to the ear and breaking it to the hope"? But, sir, I will not pursue the subject.
              </p>
              <p className="p-in-sp">One of the gentlemen who represents Marion county [<span className="persName">Mr. HAYMOND</span>]
                , asked, the other day, how I and others could advocate the particular line of policy we do, after having sustained that party whose platform was "The Constitution, the Union, and the Enforcement of the Laws"? We did stand upon that platform, and we stand upon it now. But the gentleman forgot altogether to note the fact that the Union has been taken away—one of our planks is gone.
                How can we maintain and preserve that which is lost? We cannot maintain it, but we may restore it, and there is no man in this assemblage who wills to do more or go farther than myself, if such a thing is possible to be accomplished. I trust it may be accomplished. My earnest efforts shall at all times be given to the restoration of the Union upon its true basis. I do not desire to
                mingle party politics in this discussion. I was, therefore, sorry that the gentleman from Marion, and other gentlemen, thought proper to allude to the position of the party with which I have been, and ever will be, associated upon the same issues, and I do not refer to it now in any spirit of unkindness. But when gentlemen speak of the Union party in the late election, I cannot but
                think of some of the positions occupied by those gentlemen themselves.
              </p>
              <p className="p-in-sp">The famous platform of the party of the gentleman from Marion, all will recollect, inaugurated this principle:</p>
              <p className="p-in-sp">"It is the duty of the Federal Government in all its departments to protect, when necessary, the rights of persons and property in the territories and wherever else its constitutional authority extends."</p>
              <p className="p-in-sp">This is the principle which received the sanction of the party of the gentleman. Well, sir, not long afterwards, in the Senate of the United <PageNumber num={633} /> States which has just closed its session, a resolution was offered in these words, by Mr. Brown, of Mississippi :</p>
              <p className="p-in-sp">
                "Resolved, That experience having already shown that the Constitution and the common law, unaided by statutory provision, do not afford adequate and sufficient protection to slave property, some of the Territories having failed, others having refused to pass such enactments, it has become the duty of Congress to interpose and pass such laws as will afford to slave property in the
                Territories, that protection which is given to other kinds of property."
              </p>
              <p className="p-in-sp">
                One would suppose that all those who stand upon the platform would have stood also by the resolution of Mr. Brown, as the one was adopted by their convention, and the other was offered by a Southern man, in the Senate of the United States. But, lo! when the vote was taken, three Southern Senators—Brown, of Mississippi, Johnson, of Arkansas, and Mallory, of Florida—alone voted for it;
                all the others voting against it, including our own Senators. Now, the principle involved was not, perhaps, that the party repudiated its platform, so much as it went to assert that the state of things to which the resolution referred, did not exist. So in regard to our platform, "the Union, the Constitution, and the enforcement of the Laws." The Union does not exist—the Constitution
                is surrendered, and the laws cannot be enforced—that is exactly my position.
              </p>
              <p className="p-in-sp">I say thus much to show the consistency of gentlemen who talk about the platform on which the Whig party stood—and on which all parties should stand while the Union is intact, while the Constitution is held sacred, or the laws made in pursuance of it can be enforced.</p>
              <p className="p-in-sp">
                But, sir, I said that the Union was not the Union of our fathers—that South Carolina, Georgia, Alabama, Mississippi, Florida, Louisiana and Texas had gone off and formed an association of States for themselves, and the question now to be presented to the consideration of the people of Virginia, is: "where shall Virginia go?" I care nothing at all about these immaterial issues; I care
                nothing about the platform of the Whig party, or the platform of the Democratic party. I have only referred to it by way of response to what the gentleman from Marion thought proper to say in regard to the party with which I have been associated. I did not wish to make any issue in regard to that. I want men of all parties to stand on the great conservative principle on which
                conservative men of every party should stand, when their dearest interests are assailed.
              </p>
              <PageNumber num={634} />
              <p className="p-in-sp">
                The question now presented to us here today is, whether those States having established an independent government and adopted a constitution of their own, we are to occupy a position with them, to occupy our present position in the Union, or to stand as a sovereign and independent State outside both these associations. I stand on the principle of the resolution which I offered in
                this Convention: that Virginia, for the present, should stand alone—not going either to the South or to the North. Virginia ought to occupy the position of a man who is appealed to in some personal quarrel among others, and who says: "I cannot decide this question here, I am a party, but I will be true to all. Give me time. Let me separate from the multitude and go where I can
                deliberate, and there I will decide the question of so much difficulty to all."
              </p>
              <p className="p-in-sp">
                I do not propose, Mr. Chairman, to place Virginia in a Southern Confederacy without knowing how we are to get there. I want gentlemen to tell me how, if Virginia goes out of this Union, with a view to immediate connection, how she is to get to the Capital of the Confederate States of the South? I asked, some time ago, how is she to pass through North Carolina? I see a smile on the
                face of one who is ready, I have no doubt, to respond, that my ordinance has no provision by which North Carolina and Tennessee may get to Washington, if Virginia secedes, and puts herself in the position I proposed—leaving them connected with the North.
              </p>
              <p className="p-in-sp">
                I must confess that at the first view of the subject I had some difficulty in regard to it, and I therefore determined to amend, as I have already done, the proposition which I had the honor to submit to this Convention, and in which I have provided that we shall secure for the States of North Carolina and Tennessee, so long as Virginia occupies an independent position at least, the
                right of transit across our territory to the capital at Washington, if she wishes to go there. But I want gentlemen to tell me, who advocate extreme secession doctrines, how it is that we are to connect ourselves with the Confederate States of the South while this tier of slaveholding States lies between us? No one yet, as I understand, has attempted to show how this difficulty is to
                be overcome. I listened to my friend from Orange
                [<span className="persName">Mr. MORTON</span>]
                , whom I see before me—I listened to him with great pleasure the other day—and he talked about tobacco, and various subjects, in reply to me. But, sir, I do not want to join these Confederate States without North Carolina and Tennessee; as thereby we might put my friend in that predicament in which he would fain place himself, when he said that if Virginia would go out, and
                <PageNumber num={635} /> North Carolina and Tennessee should not follow, he would present to me his head in a charger.
              </p>
              <p className="p-in-sp">
                While he told us this with much confidence in its occurrence, I want him to show us the other plan by which he hopes to carry out his idea. Will he refer to the action of North Carolina to sustain him in his mode of speculation? I should suppose not, for the people of that State have declared against a convention, as have the people of Tennessee. If this refusal on the part of the
                people of those States to call conventions, is any indication of public sentiment there, I say the very reverse of what the gentleman calculates upon is likely to be the fact. Then, sir, I am unwilling to take the position which my friend would have us take, as members of these Confederate States, until I know whether North Carolina and Tennessee are to co-operate with us in forming
                a new confederacy for the South. As to the resolutions that I have offered, and the ordinance accompanying them, I say now, I am ready to act with others on that subject, and to be advised by them.
              </p>
              <p className="p-in-sp">
                I have no sort of apology to make, however, to any body for the course that I believe it my conscientious duty to pursue. They may tell me here that my course has excited some interest and surprise among some of those with whom I have always acted ; but let me tell those friends, much as I respect them, that my private friendships can never be permitted to interfere with my public
                duty. However much I may have cause to regret being separated from those around me, yet I stand here occupying the very position which I took when I was before the people of my county. I have occupied that position, sir, which was in accordance with that held by Mr. Bell, Mr. Fillmore and other distinguished patriots of the Union Whig party, to whom I have already referred. This,
                sir, is the position which I occupy now, and, never can permit my party relations to influence me to any other course than that which I believe to be just and right and proper.
              </p>
              <p className="p-in-sp">
                I make complaint of no one upon this floor who entertains sentiments opposed to mine. And I say that there is not a man upon this floor who has more cause to be grateful to the people of this Commonwealth than I have; none has been made more to feel everywhere that he was a brother to each one of her sons and to all the daughters of this Commonwealth than I have. I say no man has
                better cause to be grateful that he has been called friend in every section—yes, sir, in yours and yours
                [referring to <span className="persName">Mr. HOLLADAY</span>, of Norfolk, and to <span className="persName">Mr. HAYMOND</span>, of Marion] , as well as in every other section of the State, than I have. I experienced this kindness in your Northwestern section, <PageNumber num={636} /> sir [referring to <span className="persName">Mr. WILLEY</span>, of Monongalia] , where I found the people as true upon this question of our peculiar institutions—all, all, whom, for the first time, I had the pleasure to meet—as in any part of Virginia.</p>
              <p className="p-in-sp">
                It has been said, sir, that there is, or rather would be, an alienation of feeling between the East and West. I am sure this cannot be so. I shall not discuss this question of taxation, which is said to be the cause of this alienation; it is not properly before this Committee; but I hope I may be pardoned for saying to some of these gentlemen near me, that whenever the question comes
                up, I shall deem it my duty to make an inquiry into the necessity of the proposed change in the Constitution, which it is desired should be made, and I shall vote for the resolution of inquiry.
              </p>
              <p className="p-in-sp">
                But, sir, it was the sentiments of Mr. Bell, alluded to in the extract read by me, to which I desired to call the attention of this Committee. While, as no man will deny, there is a large conservative element at the North—while there are men in Boston, that cradle of liberty, and the Athens of America, as she is called, who are conservative upon this subject, and just upon others;
                while there are men all over that noble old Commonwealth of Massachusetts, who sympathize with us, I desire to say, however, that no man can shut his eyes to the fact that there is an overwhelming majority in the North of those who endorse the sentiments of Mr. Lincoln and his Cabinet ministers, and United States Senators of that stamp. The feeling here at the South has become
                somewhat similar to that said to exist in times of old, when that son of Solomon who filled the throne of his father, and spoke to his people "after the counsel of the young men," he said to them—"my father made your yoke heavy, and I will add to your yoke; my father also chastised you with whips, but I will chastise you with scorpions." Then the venerable old men of Israel asked if
                this be so—if there be no end to our oppressions—if all the bonds of our Union are broken, "what portion have we in David? Now see to thine own house."
              </p>
              <p className="p-in-sp">
                So it might be asked now, Mr. Chairman, if this is the feeling of the North towards the South, what portion have we in this Union and Constitution? If Mr. Fillmore looked upon the election of sectional candidates even of the character of Mr. Fremont in 1856, as cause of disunion; now when the condition in which they are placed by the enumeration of these doctrines, now when such an
                extreme man as Mr. Lincoln is elected, and the Constitution furnishes no protection, we may ask, in the language of those old men, what portion have we in this Union? He has announced that he intended to administer <PageNumber num={637} /> this government upon the doctrines and platform upon which he was elected. And with this announcement, let me ask,
                must the earth quake before the people of Virginia will be wakened up to the condition in which they are placed by the enunciation of these doctrines?
              </p>
              <p className="p-in-sp">
                I have said on another occasion, as I have said to-day, that I am no advocate of the doctrine of secession. I believe that this government at Washington was once a proper government, but it has become less than a government of the Union. And, sir, if I am to be taken as a rebel against it, I shall be found engaged in that same cause in which WASHINGTON was found by the opponents of
                freedom to America, when he and our fathers dared the pride and power of the British lion. This is the sentiment and these are doctrines which I enunciated here weeks ago; and all the arguments of gentlemen about State sovereignty and the right of a State to secede and resume its reserved rights, have no power to change my opinions; and I say so with great respect. Let me again ask
                those gentlemen who advocate secession as a constitutional remedy, how much better chance have they to escape the consequences of treason than those who advocated the doctrine of revolution? Now, Mr. Chairman, what is treason against the government of the United States? If I am arrested here, sir, as a rebel against the government and tried for treason, I ask these gentlemen who
                advocate their doctrines, if they are regarded as traitors also? Why, sir, I should not like to see my friend from the county of Orange
                [<span className="persName">Mr. MORTON</span>] , who believes in that doctrine, come up and say I believe the law of treason in this regard was intercepted in its application to me by State sovereignty, and that I could not be tried as a rebel. But the judge would tell him, "you cannot construe the law —it is for me to say what it means, and not you who are amenable to it."</p>
              <p className="p-in-sp">Now, what is the doctrine of treason under the Constitution of the United States? "Treason against the United States shall consist only in levying war against them or in adhering to their enemies, and giving them aid and comfort."</p>
              <p className="p-in-sp">
                I don't propose to raise armies. I don't propose by any means to organize a party that is to march with pike and musket and bayonet to put down the government at Washington. I only propose that the State of Virginia shall stand where she stood in '76, upon the doctrine of revolutionary right, to renounce as the act of the whole people the authority of a government which can no longer
                protect them, and whose authority they cannot avoid unless they place themselves in the attitude of resistance to oppression. I don't know if those of the <PageNumber num={638} /> than
                the right to raise armies. I am sure, sir, they do not propose any such act. They propose to effect all this in somewhat the same manner that I do. We propose to do, in some respects, as the Committee of 21 has told us we should do. And we propose to tell the North, by the ordinance and resolutions which I have introduced, that we would regard any act by the General Government
                intended to coerce us into obedience or which was aggressive on the rights of the South, as just cause of complaint on the part of the people of Virginia, and that it would be resisted by our own arms in our own way. That, sir, is what we state in the ordinance which I have proposed, and to that extent the Committee and myself stand on common ground. I have engrafted upon the
                ordinance I proposed to this Convention, one of the very causes that is embraced in the report of the Committee of twenty-one; and I must say, sir, that I was struck, not only with the power of reasoning displayed in that report, from beginning to end, but with the great caution which the Committee have observed in refraining to demand anything that ought not to be demanded; and,
                also, so as not to give the slightest offence to those of whom the demands are made. For that, sir, I commend them, and I agree with that Committee in its earnest effort, as indicated by that report, to propose a plan by which the harmony of this Union may be restored. They think that we should remain in the Union in order to accomplish that which I maintain can best be accomplished
                by going out of the Union. And here permit me, while we are settling such great difficulties, to ask gentlemen of the Committee who have adopted the proposition of a border slave State conference, suppose the States of Kentucky, North Carolina and the other border slave States refuse to meet you in that conference, have you proposed any remedy for such a contingency? The plan
                proposed by myself does this. Gentlemen seem to have proceeded upon the idea that these border slave States would adopt the proposition which is embodied in this report. As I said a while ago, everything that has transpired in the border slave States recently, if any evidence can be deduced from their action heretofore, as to their purpose in the future, indicates a disposition on
                the part of these States not to unite in any conference with us. The States of North Carolina and Tennessee, at least, have already rejected a proposition to hold a Convention. Now, sir, if this conference of the border slave States fails to meet, or to act when it has met, I ask you where is Virginia to be? Why, I may be answered that if the people of the North accept the
                <PageNumber num={639} /> propositions which are made, and endorse them as amendments to the Constitution, that Virginia will then remain in the Union. I tell you frankly that that is not the position which I would have her occupy.
              </p>
              <p className="p-in-sp">
                If every State in the North should pass this compromise, should pass these amendments to the constitution, I still must say, even then, that I should wish to bring back those friends of ours that are at the South also. I should first wish to bring back those States that have seceded. I have, therefore, proposed that the State of Virginia shall stand apart from that Confederacy, apart
                from the North also, and act as may then be best; that she shall put herself in a position in which she may be just to herself and still true to others. Not with the purpose of making war upon the North, not with the view of uniting alone with the Confederated States of the South—alone, if she can carry her sisters with her—but with the view of uniting with those Confederated States
                in conjunction with the other border slave States. The proposition which I have submitted also invites the co-operation of the seceding States. It invites them to send Commissioners to Lexington, where I propose that the Conference shall be holden. It proposes that they shall send three Commissioners there, not for the purpose of taking part in the deliberations, not for the purpose
                of voting and controlling the action of that body, but for the purpose of presenting their views and counselling with us. I propose they shall come to that Conference as Commissioners of a like character were sent here but a few weeks ago, when by their talents, high character and their whole deportment, and their instructive arguments, they commanded, for several days, the undivided
                attention of this Convention. I propose, then, that these States shall send Commissioners to counsel with us, in like manner, and with others also. As one of the representatives here, I now say, that I do not propose to go into a union or association with the Confederated States of the South without first looking into their constitution, and without Virginia's having something to say
                in reference to its formation or amendment, so as to suit our condition as well as theirs, and so as fully to understand the principles upon which that government is to be administered. I claim here for Virginia that she has a right also to be consulted in this conference of the border slave States; and if the other slave States do not go with her, I will not indicate what her course
                ought to be. I will say here, however, as the proposition itself proves on its face, as I have presented it, that she will then belong neither to the North nor to the South. She will be free to go where she pleases, and if you determine to go North, you have nothing to do but to repeal your ordinance of secession, and you will be just where you were before.
              </p>
              <PageNumber num={640} />
              <p className="p-in-sp">That is one of the advantages of her position as it will be if she wishes to act the part of a friend to all and a mediator between all.</p>
              <p className="p-in-sp">Why, sir, the General Government does not acknowledge that the seceded States are even now out of the Union. I saw in a paper to-day, the appointment by Mr. Lincoln of a judge in one of the seceding States—the General Government still claiming that these States are a part of the Union.</p>
              <p>The hour of 2 having arrived, the Committee took a recess.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.12.4">
              <h3><span className="headingNumber">1.12.4. </span><span className="head">EVENING SESSION</span></h3>
              <p>The Committee resumed its session at four o'clock.</p>
              <div className="speaker" id="sp1800"><span className="persName">Mr. GOGGIN</span> resumed his remarks, as follows:</div>
              <p className="p-in-sp">
                Mr. Chairman, at the time of the recess, or just preceding it, I was endeavoring to call the attention of the Committee to some facts in connection with the report of the Committee of Twenty-one, as embodied in the preamble and resolutions which are appended to it and the objects which the Committee seemed to have in view by their action. I had already said, or intended to do so in
                some of the positions which I have assumed, that the State of Virginia occupied in this controversy a very peculiar relation to all other States of this Union, in view of the fact that seven States had seceded, and that she was now a border slave State, connected with the non-slaveholding portion of the Union. I find that the views which I entertain upon that subject are very
                forcibly referred to in the report of the Committee. The 6th clause of the preamble of the report has in it these words: "The grievances for which several of the States have withdrawn from the Union and overthrown the Federal Government within their limits, are such as have affected the people of Virginia to a greater extent than any of the seceded States."
              </p>
              <p className="p-in-sp">
                The ground of complaint assumed by the Committee, then, is this: that Virginia is affected by the dissolution of the Union to a greater extent than any of the seceding States were. I do not propose, as those who hear me now will bear me witness, I said on a former occasion I did not deem it necessary, to go into an examination of all the particular causes which induced the States of
                the South to secede and occupy the position which they do; but it seems that the Committee had in view the causes which did actuate them, and they have declared here before the people of Virginia, that the causes for dissolution operated upon the interests of the people of Virginia to a greater extent than upon the people of the seceded States. If the one had cause then, the other,
                now, has better cause; for they have made new causes.
              </p>
              <PageNumber num={641} />
              <p className="p-in-sp">
                I want to call the attention of gentlemen on the other side of the question—I mean the side of an exclusive slave State Government—to some other facts; because, as I said upon another occasion, I do not stand here to perform the part of a mere advocate. I do not stand here to present one view of these questions alone, for I am in search of the right view. I do not stand here to
                advocate doctrines of any particular character, that would lead only to my particular conclusions; I desire to find the best course for all. I have endeavored to present my views of this question in reference to facts as they are, not as I would have them to be. I want to know now, of some of those upon the other side of this question—those who advocate the doctrine of immediate and
                unqualified secession, and who propose to put Virginia outside of the Union, and at once into the Confederate Union—how her interests in relation to our peculiar property are to be protected on the Northern border by going into a Southern Confederacy for all time? I would like to have this question answered by gentlemen who take that ground, because it is one of the great
                difficulties for which we have to provide. It is a difficulty with me, I confess. I know it is proposed that a line of military posts should be established to protect us; but I showed, some weeks since, that such a plan for guarding the interests of Virginia would not be likely to be submitted to by the people. If gentlemen, then, have any other plan that will suit them, I should
                like very much to have them develop it in the course of this argument. I throw it out for the suggestion of gentlemen, and I ask again, as I have already asked, how are the rights of Virginia to be better protected in a Southern confederated Government upon this question of slavery, she being a border State? We shall have Joshua R. Giddings Consul General in Canada, and we shall have
                the operatives of that under-ground railroad, of which he will be the chief engineer, brought to your very border. It is true that we have a fugitive slave law, which ought to afford some protection; but it is a very inefficient law, from the fact that the people at the North oppose it now while we are in the Union; have already, as they do, a direct agency in endeavoring to defeat
                its operation within their borders. When Giddings is in authority anywhere, the South may well remember the doctrines of his famous letter, written but two years since, in which, speaking of this very fugitive slave law, he said in relation to a culprit who had resisted:
              </p>
              <p className="p-in-sp">
                "In disregarding the law, the prisoner did right. Their error consisted in sparing the lives of the slave catchers. Those pirates should have been delivered over to the colored men, and consigned to the <PageNumber num={642} /> doom of pirates. You are aware that this is the doctrine which I proclaimed in Congress. I adhere to it. Had the prisoners
                executed the slave catchers promptly, it would have taught the Administration a lesson not soon to be forgotten. We should have been no more troubled with that class of miscreants. They would have learned better than to show themselves among an intelligent people who know their rights, and dare maintain them."
              </p>
              <p className="p-in-sp">
                In reference to the position which I occupy here, and the resolution or the ordinance which I introduced, I will not argue that the course which I have indicated therein is the only one that can restore peace and quiet to the country, so as to satisfy the people of the State of Virginia. We have all come here, I trust, prepared to compare opinions, and to hear the opinions of
                gentlemen who entertain opposite views to those of our own. I have said what I thought ought to be our course—that Virginia, by reason of the dangers immediately before us, should declare her independence, and then set about the performance of the gravest duty of her existence as a State. You can never get the people of the Confederated States to co-operate with her while she remains
                in the Union, which they believe to be an oppressive Union, and which they have pronounced it to be. You can never get them to come back and co-operate even with us, and make propositions that may unite them with the North and with us, unless we are willing to carry our interests and our destiny where their interests and their destiny, have led them, upon a great cardinal principle,
                as they believe it. But if we were to transfer the State of Virginia into this Southern Confederacy, it should still be, as I have said, with the view which is always prominent in my mind, of restoring or making a new Union out of the elements of the old as it was, embracing every State, in that arrangement, as those States existed before the secession of any one of them from
                it—always providing for our security and that of our property. By placing yourselves outside of the Union, by placing yourselves in this position as a sovereign and independent State, you can then come before the Confederated States of the South as one who has no more interest than they have in the re-formation of that Union. In one sense you may be considered as a judge. I purpose
                to make the State of Virginia independent as a judge should be, having neither connection with the North nor with the South, but by her very position just to both sections, and that she shall occupy that place to which some of the Committee seem to suppose she was entitled. What do they say:
              </p>
              <p className="p-in-sp">"Virginia having initiated measures to obtain such guarantees, a proper self-respect impels her to demand of all the parties that they <PageNumber num={643} /> shall refrain, during the pendency of her efforts for amicable adjustment, from all action tending to produce a collision of forces."</p>
              <p className="p-in-sp">
                Now, this is a position in which she assumes to demand, of "all the parties," a certain line of policy, while she admits herself to be altogether among, or in the very midst of one of the parties and claims to be of that very party. It is, to my mind, rather a new mode of keeping the peace; and the South may well say "blessed are the peace makers," if they can keep the peace, and yet
                be on the side of those who disturb it. But Virginia, here, by the very argument which the Committee employs, proposes to place herself, to some extent, in the attitude that I would place her in, as a free, sovereign and independent State. She assumes, at least, the character of an arbiter. She assumes the attributes of a sovereign, and she does dictate to those States at the North
                the terms upon which they are to act. She dictates to Mr. Lincoln and the Cabinet, at Washington, the principle which is maintained in this report, that, in the event of a certain state of things, Virginia will regard it as an assault upon the Confederated States of the South. Tell me if that is not the exercise of sovereignty? But how can she exercise this power of her sovereignty,
                while you make her remain in this Union? What is she to do while she holds the stake, but to submit, if it be demanded by the crowd around her, in the midst of excitement, however honest, however patriotic her wishes and purposes? I propose, then, to place her in a condition that she may render effective that very doctrine which the Committee have taught us here in this report. Can
                she assume to herself that the laws of the United States shall not be executed? Can she claim to arrest the operation of those laws while she claims to be bound by them? This is practical nullification to which the Committee, I presume, never intended to give their assent. It seems to me that it necessarily follows that Virginia, then, must occupy the position in which I propose to
                place her as a sovereign and independent State—that she may do that very thing which the Committee says she will do in the contingency to which I have alluded.
              </p>
              <p className="p-in-sp">
                I desire, Mr. Chairman, to return my thanks to that Committee. I will here say that they have made a report which has been the result not only of the efforts of some of the best intellects in the State—some of the wisest and most patriotic men within our borders; and I feel every inclination to adopt, as far as I can, the action and the report of that Committee, unless something more
                acceptable is presented—I feel it due to myself to say that whenever the remedies to which I propose to resort have failed; whenever you fail to endorse such a plan as I have attempted to embody in the ordinance which I have introduced; <PageNumber num={644} /> if I cannot get something else which looks to the objects of the plan which I have indicated,
                I do not mean to stand back and say that I will take no plan at all. If I cannot carry out my own views, I stand here ready, as far as I can, to co-operate with the Committee or any others in this hall in endeavoring to bring before the people something which may commend itself, though I may not believe it to be the best, that can attain the objects which we have in view, in
                assembling here as we have in this trying emergency, and in this hour of peril, full of the brightest hopes of the future which may be crushed forever by one false move on our part. I have already said, in the remarks which I submitted upon another occasion, that I do not think that Virginia ought to go out of the Union and unite at once with the Confederate States of the South
                without knowing the terms upon which we were to go. I am ready, then, as I said before, to co-operate with the Committee, and I am ready to co-operate with my friend
                [<span className="persName">Mr. WISE</span>]
                , whom I see before me, and with whom I have co-operated upon other occa—- sions, if he can show me how I can fight in the Union and maintain the principles for which I contend—how I can guard these rights and be still a member of this Union. I am ready to stand by him and work with him as I did years ago, when fighting for the rights of the South and the interests of Virginia. I saw
                him fall gallantly in the strife—fall, sir, from mere physical exhaustion in the discharge of his duty. but to rise again in defence of the same rights resting on the same power of truth, and enforcing by his arguments, our rights and our demands. I stood by him then as I stood by him before when he and myself were barely men studying our profession, in the schools of our lamented
                friend
                [Judge TUCKER]
                , and though every way have since been estranged, politically for a time, I can but be pleased that we do stand together now upon the great questions before us, the question at least of resistance to oppression. Sir, when our State was invaded at Harper's Ferry, while he stood for us, I had the proud satisfaction of believing that we had one at the helm of the Government who would
                protect the rights and interests of this Commonwealth. He did it then, and I am ready to co-operate with him now, if he can present any scheme more acceptable than others.
              </p>
              <p className="p-in-sp">
                I am gratified, then, to say that I can bear this testimony not only to him, but to the Committee as I have done. I am ready to cooperate with either. I am ready to co-operate with any one who will propose a plan by which the State of Virginia can be protected, and all her rights secured in a Union as it ought to be. I do not think, however, for the reasons which I gave in the
                remarks which I submitted <PageNumber num={645} /> this morning, that it can be done in this Union, which now is dismembered and broken, and, therefore, that Virginia must go out of it for awhile, that she may restore it; that she must place herself upon her own platform, and then invite the slave States upon the border to co-operate with her altogether
                in the accomplishment of her objects.
              </p>
              <p className="p-in-sp">
                We have been told that probably the border States would not assemble in a Conference with Virginia if she goes out of the Union first. If that be true, it will furnish one of the very best evidences why we ought not to co-operate with them at all—that will prove that they wish to go North. If that should prove to be the case, I propose by the ordinance I have submitted, that a
                proclamation of that fact should be made to the people by the Governor of this Commonwealth, and then that this Convention should assemble within 15 days afterwards, to determine what shall be the future course of Virginia. I do not believe, however, that our destinies should be the same; and Virginia by taking such a position, would by no means deprive them of any advantage they now
                enjoy, nor would she desire to alienate herself from them. Her purpose would be to conciliate the South also. But I say to my friend from Princess Anne
                [<span className="persName">Mr. WISE</span>]
                , and to the Committee, that if they can show me any other plan by which we can protect our rights and interests, bring back the seceded States, and perpetuate this Union, I am perfectly willing and ready to co-operate with them in the Union without withdrawing from it, but as yet I am incredulous on that point, and I should wish the Committee to tell us what is to be done in the
                event the border States do not meet them in their proposed Conference—where, then, is Virginia to go in that event?
              </p>
              <p className="p-in-sp">But I said we have no Union now but as a dismembered one; and will she cling to that as her only hope? Will she remain where she is if the other border States say they are satisfied? I think she will not and ought not.</p>
              <p className="p-in-sp">
                Whatever may be my own views as an individual, whenever my destiny may lead me with Virginia outside of this Union, I should feel when I was taking that position and following that destiny, as one feels when he leaves the home of his childhood forever—as a man feels when he quits all those familiar faces and friends who are dear to him, and by whom he has been surrounded since his
                infancy. He removes to some far distant land, not upon the swift steam car, but in that slow mode of travel once so common in your county, sir, and in mine, years ago, when those from the lowlands took up their all and were seen wending their way through the mountain gorges; as they reached at <PageNumber num={646} /> last the summit of some commanding
                eminence, each one who had a Virginia heart, would look back toward the place of his birth, now hallowed in all his thoughts and feelings, and weep at the reflection that he was leaving it forever. That, sir, is the feeling I should have should I be compelled to leave this Union, once blest as it was, but now no longer, as it is. I should feel clinging around me all those affections
                and feelings that would fill the heart of the traveler on the mountain summit; such as I have no doubt have filled the hearts of many men on other occasions, and when they could wish that the circumstances were not such as to compel them to conform to them.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I have no dislike for this Union, but I have an ardent attachment to it, as I have read its history in the past. And if you can present it to me as it came from the hands of those who constructed it, I would be happy to stay in it, and be of it, now arid always. But now we have no Union—no Union, at least, that secures our rights. Let us look at it a moment. What does
                it to advance your prosperity as a people? Does it protect your commerce upon the ocean? Look at your ships without cargoes. Does it protect your commerce upon the navigable streams which traverse this country, from the Lakes to the Gulf of Mexico? See your steamers idle for the want of employment. No, sir, no. Then let me ask myself, ask yourself, sir, have we a Union that can
                protect our interests, as part of the nation; a Union that you and I and our children can be connected with in all time and under all circumstances? Have you a Government and a Union, Mr. Chairman, that can protect your revenues—your fiscal treasury? Why, sir, the present condition of the country and of our public affairs everywhere, shows that we have not. Let me ask, then, are we
                to continue in a Union which cannot do this? or, is it a Union at all? Let me ask, have we a Union that can enforce its laws? I have already referred to the fugitive slave law, and I suppose there is not a man upon this floor who would contend that we have a Government that has the power to enforce that law, or, if it had the power, that does enforce it, with personal liberty bills
                in the States to resist it. I admit that we have such a law in existence, but, yet, such is the force of the popular sentiment, the public opinion at the North, that the law is a dead letter upon the statute book. I ask you if you want to be part and parcel of such a Government as that?
              </p>
              <div className="speaker" id="sp1801"><span className="persName">Mr. CARLILE</span>, of Harrison—</div>
              <p className="p-in-sp">Will the gentleman allow me to ask him a question?</p>
              <div className="speaker" id="sp1802"><span className="persName">Mr. GOGGIN</span></div>
              <p className="p-in-sp">I am not much in the habit of being catechised in deliberative bodies, but the gentleman may proceed.</p>
              <PageNumber num={647} />
              <div className="speaker" id="sp1803"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I understand the gentleman from Bedford to say that the fugitive slave law is a dead letter, and cannot be enforced. I merely wish to call the attention of the gentleman to Mr. Buchanan's last message to Congress, where he says that he has enforced it in every instance that occurred during his administration.</p>
              <div className="speaker" id="sp1804"><span className="persName">Mr. GOGGIN</span>—</div>
              <p className="p-in-sp">
                Perhaps the gentleman from Harrison does not know that I occupy a position on this floor of not being a defender of Mr. Buchanan. There are many things, permit me to say to the gentleman from Harrison, that Mr. Buchanan has said to which I do not give my assent. I think Mr. Buchanan is reported to have said, on one occasion, that if he had a drop of Democratic blood in his veins he
                would let it out; but some gentlemen thought he had much of it—too much for them. I think he, said many inconsistent things, for which I do not stand here to defend him. The law may have been executed in some instances, but State interposition and mobs have rendered it wholly inoperative in others.
              </p>
              <p className="p-in-sp">
                But, sir, I was proceeding to speak of the Fugitive Slave law, when interrupted by the gentleman from Harrison, to whom I responded with cheerfulness; for however he may differ from me in opinion, I will do him the justice to say that in whatever part he has taken in the discussion in this Convention, he has borne himself with a courtesy of deportment that becomes a member of such a
                body as this.
              </p>
              <p className="p-in-sp">
                And here, sir, I take occasion to say, that I have been a member, not only of deliberative bodies under the laws and the Constitution elsewhere, but of many other bodies, assembled merely by private citizens, and that never before, in my humble career, and I believe never before in the history of this country, within the recollection of any man upon this floor, has there ever
                assembled a body of men more justly entitled to all respect than that which now constitutes the Convention representing the sovereignty of the people of Virginia. I feel a pleasure, sir, in bearing testimony to a fact which must have impressed every member of it, who is a man of any habits of observation at all, that a feeling of kindness, courtesy, politeness, and gentlemanly
                deportment has characterized all those around me, and who, for so great a length of time, have been in this body, engaged daily in the discussion of the most exciting subjects. There are other influences, sometimes, which manifest themselves in such assemblages as this (especially after dinner) , which I am happy to say no one has ever exhibited here. And, sir, I feel, whatever may
                be the fate of this State, or of the Union—whatever may be the action of this Convention, that there is not a man in it who will not feel at least some degree of satisfaction, hereafter, that <PageNumber num={648} /> his name was connected with it in its deliberations; and if the history of the private character of all who compose it could be written, I
                believe it would compare favorably with the history of the private character of any equal number of men who have ever assembled within the borders of this Commonwealth.
              </p>
              <p className="p-in-sp">
                But, sir, I was proceeding to remark upon the powers of the government to enforce its laws; and I now leave the fugitive slave law. Can it execute its laws in relation to the post office department? It does not do so; for it was but the other day I saw that, even in my own section of the State, there was an intimation given to one of Mr. Lincoln's officials of the mail service, that
                he should not be permitted to come into that quarter at all, in order to perform his duties as an agent upon one of the mail routes in the State. This may be wrong to the parties—wrong toward us; but you see here is a government inefficient in this respect. Can it protect its flag, and does that flag protect the people of the States who look to it as the beacon light of the nation?
                Let scenes which have been witnessed where that flag, which belongs to American freemen, has been hauled down and made to trail in the dust by those who oppose the operations of the government, answer the enquiry.
              </p>
              <p className="p-in-sp">
                But, Mr. Chairman, above all things, have we a Government now, constituted as this now is, that has punished, or does punish its own corruptions? Why, sir, we had it from some of the most distinguished men in Virginia, last fall, during the campaign of 1860, that the Federal Government was the most corrupt Government upon the face of the earth; and yet those gentlemen tell us that
                Virginia ought still to remain a part of that Government thus polluted. Does it contribute to the general welfare in its operations? It is organized upon a sectional platform, and most nearly all of the benefits to be derived from it are, by the existing state of affairs, secured to the people of that particular section. The people of the State of Virginia are virtually excluded from
                its benefits, at least to a very great extent. Not, sir, that they do not appoint men to office in Virginia, and even in Alabama, who claim to be out of the Union, and even in all the seceded States, but the very fact—and I do not admit it should be so—but the very fact that one has accepted an office under the present administration, is of itself, in the estimation of some,
                sufficient to render him unfit to be the associate of others. I do not say that it should be so, because men take office under Mr. Lincoln even in the State of Virginia, for I am sure I know none of the number who are not men who bear a high and honorable character. While we are in the Union, the offices should <PageNumber num={649} /> be filled, as we
                enjoy the benefits, and filled, indeed, by the best men. And here, Mr. Chairman, lest I forget it, as I desire to advert to it, I wish to say that I have upon this floor a very near friend, one with whom I have gone along in the way of life, upon many pleasant occasions; one whom I have known in the social circle and in the councils of the country; one whom I have known at his own
                hospitable fireside; one whom I have known but to love for his virtues, his patriotism and his whole character. I do not know that he is within the sound of my voice. I wish, therefore, to say that I saw it intimated, from certain quarters, that, when he was in Washington, where he had been sent by his State on a mission of peace, when he was endeavoring honestly, I have no doubt, to
                discharge the duty assigned him, there were men who could insinuate, though they would not charge, that he would be influenced in his course by a desire to receive a Cabinet appointment which would be tendered him from the hands of Mr. Lincoln. I had it at that very moment in my power to say, not only from my own knowledge of the man, that such was not the case, but I had it from
                himself. I had alluded to the insinuation to which I have referred on one occasion, in that freedom of intercourse and friendship which has existed between us for years, while, for a few days only, he was first in this Convention, and before the termination of the Peace Conference to which he afterwards returned. He then said, in reply to my own reference to the subject of the
                innuendo, that he would not accept such an appointment if tendered him. He seemed, indeed, surprised that it could have been supposed by any, and that he would accept, even if unsolicited, such an appointment had been placed at his disposal by any administration with which he was negotiating at Washington and endeavoring to bring about a settlement of those great difficulties with
                which the whole nation, as well as the State of Virginia, is now surrounded.<span className="note" id="Note72"><span className="noteLabel">7</span>The sentence sounds garbled.</span> I had it, therefore, in my power, as I did, directly to give the contradiction to any insinuation or charge of that sort against one whom, however much I may differ with him in reference to this question now,
                then at Washington, as I knew, and was laboring earnestly and faithfully, not for the promotion of a party, not for his own personal advancement, but for the honor and happiness of his country. That man, I need not announce, is my honorable friend from the county of Kanawha
                [<span className="persName">Mr. SUMMERS</span>] , who was a member of the Peace Conference Commission with the distinguished gentleman from Charles City [Ex-President TYLER] . I will also take occasion to say, that my friend [<span className="persName">Mr. SUMMERS</span>] , as I have reason to know, shared the respect of that gentleman, not only for his personal merit, but for <PageNumber num={650} /> his industry and untiring energy in endeavoring to bring about a settlement of those difficulties which encompass us. I listened with great pleasure to the speech in this body of my friend [<span className="persName">Mr. SUMMERS</span>] , and I said to others near me, that if there was any thing which would cause me to doubt the correctness of the position I occupied, it was that I differed in opinion with my friend from Kanawha.</p>
              <p className="p-in-sp">Sir, I am affected with general political feelings, in common with that friend, as I am, to some extent, with those of my friend from the county of Orange [<span className="persName">Mr. MORTON</span>] , whom I do not now see before me. But I never was imbued with the feeling which that gentleman said he had upon one occasion. I have had feelings in common with my distinguished friend in reference to the great political issues—my friend from the county of Kanawha [<span className="persName">Mr. SUMMERS</span>] , I mean—which have engaged the attention of the country, but in reference to that matter to which my friend from Orange [<span className="persName">Mr. MORTON</span>]
                , adverted, I cannot say that I could have sympathised with him, on the occasion to which he refers. He said, that when in London, years ago, he heard abolition orators in the meeting where Wilberforce presided, upon the great question of the abolition of slavery in the West Indies, and he sympathised with the sentiments, not only sympathized with the sentiments, but found himself
                shedding tears upon that occasion. I say, sir, I have not the necessity to stand here to disclaim any such position now, and I know I need not disclaim for my friend from Orange any sympathy now with Abolitionism in this State or out of it. And, sir, lest others might, perhaps, think that I had, in his absence, omitted to perform a duty to him also, I must do that friend, whom I have
                known long, the justice here to say, that there is not a truer man, or one who more earnestly desires to perform his duty as a good citizen, than the gentleman from the county of Orange.
              </p>
              <p className="p-in-sp">But, sir, I was about to refer for a moment to another subject, in reference to remarks which were made by the gentleman from Orange [<span className="persName">Mr. MORTON</span>]
                , to a certain agricultural production, which concerns that portion of the State in which he and I reside. I made an argument here, some weeks ago, in which I endeavored to show that Virginia was deeply interested in another great staple besides cotton—that of tobacco—and I cited the fact that so many hogsheads and so many pounds of tobacco were sent from the city of Richmond to the
                Northern ports. On a subsequent occasion, the gentleman from Richmond city
                [<span className="persName">Mr. RANDOLPH</span>] , with others who have alluded to the subject, agreed with me, I believe, in the great importance of that article as one of the sources of our wealth. The gentleman from Richmond [Mr. <PageNumber num={651} /> RANDOLPH] , made a speech upon the subjects of our commercial connections and relations, of very great ingenuity and ability; in which he took a view of this particular subject to which I desire for a moment to refer.</p>
              <p className="p-in-sp">
                Now, sir, I have before me—and I do not intend to be tedious—a publication, to which I call the attention of this body. It appears that in the year 1859, according to this report, which is an official document of the House of Representatives of the United States, on the tobacco trade, of the last Congress, that the export of that article during the year ending the 30th June, 1859,
                was of the value of $21,074,038, while that of cotton was $161,434,923, and the value of the exports of "agriculture" proper, other than tobacco and cotton, was $40,400,757; that of "the forest," such as lumber, tar, turpentine, &amp;c., was $14,489,406; and that of "the sea," such as fish, whale oil, whale bone, &amp;c., was $4,462,974. Of these articles or products, it appears that
                cotton, which is double the value of all the others, by virtue of our commercial regulations and the policy of foreign nations, is admitted by all the nations of Europe, or nearly so, free of all duty, or at a nominal rate of duty only. Cotton, then, the product of the seceded States, the product exclusively of slave labor, is protected to that extent. And how is it in regard to the
                next most valuable export—the products of agriculture, all kinds of grain, &amp;c.? This, too, is admitted at a free or merely nominal rate of duty, by all the nations of Europe, except the Germanic Confederation. By the same sort of commercial regulations, even the smallest class—the products of the sea—is protected by fishing bounties paid by our own Government. Yet, sir, tobacco
                has been made a sort of offering, a peace offering, a sacrifice for these advantages secured to all other productions.
              </p>
              <p className="p-in-sp">
                Let us see for a moment the operation: The whole production of tobacco in the European States, not referring to England and Spain, where its cultivation is prohibited by law, during the year to which I have referred, was two hundred and one millions, forty-nine thousand, four hundred and twenty-seven pounds (201,049,427), and the quantity imported into all the States, including
                England and Spain, was 254,004,527 pounds; from which it will appear that the quantity consumed ( deducting for the exports from the same countries, 58,206,734 pounds) , was 396,847,220 pounds, or an average of near two pounds per head of the people of those States. The total receipts of import duties imposed upon tobacco by the European States, and the duties arising from the
                monopolies in the trade by some of the States, were during the year to which I have referred $76,695,060.75, or 27 cents per <PageNumber num={652} /> head for each inhabitant. The foreign exportation of tobacco in the unmanufactured condition, that is in hogsheads, from the United States for the same period was 198,846 hogsheads, valued at $21,074,038,
                while the domestic consumption was 57,395 hogsheads, valued at $10,000,000. This, however, is far short, I have no doubt, of the actual amount consumed at home, as it is difficult to arrive at the quantity—but still those results show all I desired for the argument. Now it may be seen that this important product of slave labor is burdened to the very heaviest extent in this, that it
                is made to support the Governments of those States of Europe who derive from it near eighty millions of revenue. They have been appealed to again and again, from the time Mr. Jefferson was our Minister to France in 1785, down to the present day, to enter into some arrangement by treaty, or determine to lessen the burden upon the products of the slave labor of the Middle States, but
                they have met our demand simply by the declaration that the duties being for the supply of revenue could not be changed; that the Treasury at home must be looked to rather than the interests of those abroad. And, moreover, they have pointed us to the fact that cotton, the other great product of slave labor, is admitted free of duty. Let me ask, then, my friend from Halifax
                [<span className="persName">Mr. BRUCE</span>]
                , if, as he seemed to suppose would be the case, we can expect to be benefited in this regard, by going into an exclusive Southern, cotton-growing Confederacy? I do not think that Virginia can be more successful in making her appeals through an administration then, than she has been for three-quarters of a century, under all the administrations of the Government of the Union. She, in
                fact, will then be united with the very cotton growing districts whose products are deriving protection from her own labor bestowed upon tobacco. The looms of Manchester must be supplied, and they in turn must supply those who must be clothed—but the luxury of tobacco bears the tax of this operation, because not only the weavers, but all mankind want it, cost what it may—all,
                however, do not get it. Comparatively few can indulge in it to any extent. But remove the burden, as it is removed from the favorite competitor, cotton, and the consumption will be increased to an almost indefinite extent, and the planter will be remunerated for his labor, also.
              </p>
              <p className="p-in-sp">Now, Mr Chairman, in reply to my friends from Orange and the City of Richmond [<span className="persName">Mr. MORTON</span> and <span className="persName">Mr. RANDOLPH</span>] , I desire to say that I think they have wholly mistaken the course of trade in regard to manufactured tobacco also. They seemed to suppose, as did the gentleman from Mecklenburg [Mr. GooDE]
                , that Charleston, Savannah, and <PageNumber num={653} /> other Southern ports, were most valuable auxiliaries of the manufacture of tobacco in the City of Richmond. Those ports, no doubt, receive some tobacco from our ports, but much the largest portion consumed in the Southern States reaches the interior of the South from North Carolina, Tennessee,
                Kentucky and Missouri by inland transportation. My argument made on this subject some weeks since was directed to the fact that Northern ports were the points to which the article of manufactured tobacco was first sent—that the Northern people consumed much of it themselves—how much we cannot determine by any facts before us. But let us see how far Northern ports will compare with
                Southern ports as our allies in the shipments abroad of this article of manufactured tobacco. The duty upon this article I should, perhaps, first say, taking England as an example, is more than 2,000 per cent. ad valorem, while upon the raw material it is from 1,000 to 1,500 per cent. The gross duties collected upon tobacco amount to $25,000,000 imposed by the British, and those by
                the French government to the sum of $18,000,000, making more, altogether, than double the value of the leaf tobacco exported from the United States—the value of tobacco of all kinds exported from the United States being estimated, annually, at $21,074,038. With these facts, thus stated, I ask that others be now noticed with regard to the manufactured article, which gentlemen tell us
                goes South. It appears, sir, that the exports in one year, of this article—
              </p>
              <p className="p-in-sp">From Boston, is 3,054,940 lbs., of the value of $ 566,160</p>
              <p className="p-in-sp">From New York, 7,375,634 " " " 1,311,506</p>
              <p className="p-in-sp">From Philad'a, 220,387 " 220,387</p>
              <p className="p-in-sp">10,650,961 " ,, $2,098,053</p>
              <p className="p-in-sp">From Charleston, 433 lbs., of the value of $ 56</p>
              <p className="p-in-sp">From Savannah, 36 " ,, ,, 72</p>
              <p className="p-in-sp">From New Orleans, 25,441 " ,, 5,028</p>
              <p className="p-in-sp">25,910 " If II $5,156</p>
              <p className="p-in-sp">
                These data, sir, exhibit a most remarkable state of facts; I will content myself with these six ports, three North and three in the Confederate States; and I will dismiss the subject, as my wish is to create no prejudices or to excite more than that sort of inquiry which is always necessary in such matters to enable us to arrive at truth. I suppose, sir, that the 36 pounds they
                exported from Savannah, was a single box, at $2 per pound, sent to some friend, or taken by some gentleman <PageNumber num={654} /> going abroad. The document to which I have referred, is before me, and any member can have access to it at any moment.
              </p>
              <p className="p-in-sp">
                I tell you then, sir, and I frankly say it, that I can find no relief in a Southern Confederacy or from any such a quarter. I would be recreant to my constituents, if I did not stand up and announce these facts, as I do upon this floor. Indeed, upon this point I can very well see how they would be in a worse condition than they are now, or than they would be in the United States,
                because they now have the ports of Boston, New York, Philadelphia, Baltimore, and all the ports of the North open, where this article is received duty free. Cut them off, as I said before, make them aliens and strangers to us, and you will lose the advantages of that market, at least, for sales as well as shipping; and although we are told we shall be welcomed by the South, yet so
                far as the tobacco interest is concerned, they have already commenced by imposing a duty of ten per cent upon the manufactured article. I repeat, therefore, that so far as the tobacco interest is concerned, the people of Virginia have but little to hope for in either quarter, while the great European markets are almost closed to us by the heavy duties imposed upon our labor.
              </p>
              <p className="p-in-sp">I desire now, for a moment, to advert to the argument of my friend from Augusta [<span className="persName">Mr. BA</span>Lownv]
                , who said that we had an ample field for the employment of slave labor here in our own State. Why, sir, I think there were gentlemen in Virginia—I do not now refer to the gentleman from Augusta—who, but a year or two past, declared that slave labor never could be profitably employed in Kansas, and that it was useless to have a battle over it, so far as Kansas was concerned. I am
                rather inclined to believe that the argument was good, if no principle had been involved. But let me ask the gentleman from Augusta, if Western Virginia is adapted to the profitable employment of slave labor, why, in the period of one hundred years, has not slave labor gone there, to a much greater extent? Why has the tide of slave emigration been in the direction of the South almost
                exclusively, if it is not because there slave labor is most profitable? If you can make this description of labor profitable in attending to the pleasant, happy, profitable business of feeding cattle, gathering fodder, hay, corn, oats and wheat, and in attending to all those active interests that belong peculiarly to a farming and grazing community—if you can show me that any number
                of slaves can be employed profitably in such labor, I will abandon all the theories I have ever entertained upon this subject. Slavery will go where it can be employed with the most profit; and I tell you that section is where "cotton is king," in the extreme South. I ask my friend <PageNumber num={655} /> from Augusta, if Western Virginia, I speak of
                the West generally, is adapted for the profitable employment of slave labor, when the slaves of Eastern Virginia will seek the best market, why, with all the material resources of the people of that part of the State, have they not purchased the slaves in the market of Eastern Virginia? I have no doubt the brave and noble and true men you have in Augusta and in the counties round you
                in the whole West, have all the means, all the elements, to an almost unlimited extent for investment in that species of property, if they desired or found it for their interest to purchase—it is not because of any hostility to the institution they do not. The fact is, that they have never gone much into the purchase of slaves, because, with their fertile fields covered with grain
                and waving in grass, making the heart of the farmer rejoice and the heart of his children to be glad, their capital can be employed to better advantage than in the employment of slaves to any great extent, or the institution would have been extended into all parts of the West, for I am very sure there is little, if any, anti-slavery sentiment in Virginia, East or West. But while the
                institution does not exist there to the same extent it does in the Eastern portion of the State, I can say, as I have said before, that I believe the whole people of the West are ready to protect it as one of the institutions of the State.
              </p>
              <p className="p-in-sp">
                Gentlemen ask, will not the Southern States return? Well, Mr. Chairman, I should hardly suppose that a nation which has established its independence, will undo that act. You have said by your report here, that though it has not been recognized as an independent republic, you will not permit Mr. Lincoln and his Cabinet to lay the strong arm of power upon it. The Committee of 21 has
                thus far acknowledged the independence of the Confederate States of the South. With an area of 564,000 square miles, an area greater than England, Ireland, Scotland, France, Spain and Portugal combined, can we suppose that a people with that extent of territory—a people whose "wrongs have impelled them to cast off obedience to the Federal Government," as the Committee admit; a people
                who, acting on the spirit of their fathers, have declared their independence—do you believe that they intend to come back to a government whose people, in one section, have outraged their rights, insulted their feelings, taken their property and disregarded their interests? Never! I am sure, never, unless their rights are fully secured. I would to heaven I could say that I believe
                all would, even then. All my efforts, every energy of my heart shall be directed to the accomplishment of such a result. My proposition seeks the accomplishment of this object—I know not <PageNumber num={656} /> what others may desire; I am directed in my efforts to that object in all good faith—as I am sure all are.
              </p>
              <p className="p-in-sp">"I have done as you have done; that's what I can; induced as you have been; that's for my country."</p>
              <p className="p-in-sp">
                I want us, then, to unite together here in these border slave States, to prepare ourselves first to consult and arrange, and then to prepare a plan of Government, with which we shall be prepared to unite ourselves. When we have accomplished our objects then we would ask the people of the North to reconsider their purpose, to reconsider all their schemes, and not to raise the arm of
                power over the rights of people who would feel themselves disgraced if they submitted to it. Let us ask them to reconsider their views, and to adopt another Constitution which will guard the rights of the whole people of the border, and more, Southern States whose co-operation we want also.
              </p>
              <p className="p-in-sp">
                I would appeal to Massachusetts, to South Carolina, to all, to come back and be the sisters of Virginia. I verily believe, sir, that, although sometimes the men are wrong, the women, on these great questions of interest—the interest of their husbands, of their fathers, of their children, of their relatives, of their whole country—are in advance of the feelings which govern us.
              </p>
              <div className="lg">
                <div className="l">"That love which bid the patriot rise,</div>
                <div className="l">"To guard his country's rest;</div>
                <div className="l">"In mightier, holier, fulness thrills,</div>
                <div className="l">"In woman's gentle breast."</div>
              </div>
              <p className="p-in-sp">
                The people of the North have had enough of a national debt. They have had enough of the corruptions of government. They have had enough of a prodigal expenditure of public money. They have had, as we hope, enough of excitement on the subject of that great interest which we have determined to protect at all hazards, and to the last extremity. The Confederated States of the South have
                taxable property to an almost indefinite extent—the aggregate being $22,000,000,000. The aggregate debts of the Confederated States are but $18,000,000—an insignificant sum, as compared with our own State debt and the debts of the other States of the Union, amounting as they do, to the enormous sum of $174,000,000. The annual expenditure of the General Government averages from
                $60,000,000 to $75,000,000, while that of the Confederate States only amounts to the small sum of $1,468,190. I do not say that this is a lure which ought to induce us to go off, but I suppose we can have the benefit of the fact that their indebtedness is vastly less than that of the other States.
              </p>
              <p className="p-in-sp">
                There are many circumstances connected with the matter to which <PageNumber num={657} /> I may call attention. I may refer to the fact that about ten years ago, so anxious were the good men of this land to put a stop to this agitation, that many of the members of the Senate and House of Representatives made a solemn pledge, that under no circumstances
                would they thereafter vote for any man for President or Vice President, or member of Congress, or any office whatever, who did not stand pledged never to agitate this distracting subject of slavery. That paper was signed by one, to mention whose name is to call up feelings of love and veneration in the heart of every man, no matter what may be his party associations. I refer to Henry
                Clay. He, together with Howell Cobb, now one of the officers of the Confederate States of the South, I believe Alexander H. Stephens, Vice President, Robert Toombs, and some twenty-five or thirty others, North and South, gave the pledge, to which I have called attention. But, notwithstanding the high position of those men, and notwithstanding the efforts made to carry out the pledge,
                the subject has continued to be agitated time after time, running down to the present moment. It disturbs and distracts all the relations of life, and all the relations which this State has to the Union of which she is a part, and to all the other States. It seems that nothing can quell this incessant stream of excitement which rolls broadcast throughout the land. Virginia has, by
                the votes of large masses of her people, of both parties, given her honest efforts to quiet this distracting agitation, which can be traced back even to the beginning of the Government. As far back as 1790, attached to the debates on the adoption of the Federal Constitution, will be found a speech made by Mr. Smith, of South Carolina, referring to this very identical subject, which
                serves to illustrate the course that has been pursued by agitators at the North, who have pursued it with an appetite as keen as death, and a step as steady as time.
              </p>
              <p className="p-in-sp">
                There was a petition presented by a society in the State of Pennsylvania, virtually to repeal the Constitution permitting the importation of slaves, till the year 1808. Dr. Franklin was one of the petitioners. Mr. Smith said: "It was astonishing to see Dr. Franklin taking the lead in a business which looks so much like a persecution of the Southern inhabitants, when he recollected
                the parable he had written some time ago, with a view of showing the impropriety of one set of men persecuting others for a difference of opinion. The parable was to this effect: An old traveler, hungry and weary, applied to the patriarch Abraham for a night's lodging. In conversation, Abraham discovered that the stranger differed with him on religious points, and turned him out of
                doors. In the night his Maker appeared unto Abraham, and <PageNumber num={658} /> said: Where is the stranger? Abraham answered : I found that he did not worship the true God, and so I turned him out of doors. God said then unto him: Abraham, have I not borne with him three score and ten years? and couldst thou not bear with him one night?"
              </p>
              <p className="p-in-sp">
                I have referred to this to show you that this very identical subject was a cause of complaint as far back almost as the time of the adoption of the constitution of the United States. And it has come down to the present time, riding over and overwhelming everything else. It has now culminated to a point. It has reached that point at which Mr. Bell and Mr. Fillmore said the South would
                not be true to herself if she did not dissolve the connection existing between her and the North. I ask those who supported either, or both of those gentlemen, whether they did not endorse that sentiment then, and will they reject it now? Whether they did not feel that there was truth in it? Then let me ask them how, when the very crisis is upon us, we are to be told to wait till
                measures can be taken to bring back the people of the North to a sense of their duty? I trust that those who have presented this report of the Committee of Twenty-one may be able to accomplish such a result, if they bring back the. South also—the North to a proper sense of its duty —the South to the Union. I have an abiding faith in the men who compose this Committee, not only
                because of their high position, but because I know they have an earnest desire, such a desire as I have, to see the Union restored on the basis on which it was designed by our fathers to stand. I will never co-operate with a party that tell me that they have no desire to see the Union reconstructed. I will never co-operate with a party that tell me, that if the State goes out of the
                Union, they do not desire to see it re-united with the other States on such a basis as will secure the rights of the South. Some who were so ready but a few weeks ago, in the House of Delegates and in the Senate of Virginia, to endorse the plan of Mr. Crittenden, then said that they would be satisfied with that, but that if those proposed amendments to the Constitution were not made,
                a dissolution of the Union was inevitable. Those amendments, that plan, all have failed. But these gentlemen now get a better plan than that, and they are not satisfied. Times have changed. Lincoln has laid down his programme, distasteful as it is, to the South. Yet every one must admit that the report of the Committee of Twenty-one is a better plan than that of Mr. Crittenden, or
                than the Franklin proposition, so called as reported by the Peace Conference in Washington. All this goes to show that there is a growing discontent on the part of the Senate, which nothing can remove but a restoration of mutual respect and mutual desire for harmony. The North must <PageNumber num={659} /> abandon its pretensions to control our
                institutions any and every where.
              </p>
              <p className="p-in-sp">
                While I give credit to those who have endeavored to effect these objects, yet I must tell you in all candor, that I do not believe any thing will ever bring back the States of the South, unless we here hold out to them some token of our friendship and affinity with them. That is the proposition that I desire to stand upon; but, sir, as to endeavor to bring back the seceded States,
                without first recognizing their independence and nationality, I am free again to say, is not likly to be accomplished but by extending to them our own right hand, and Virginia's own strong arm, if need be. Whenever Virginia shall go, I would endeavor to carry with her the States of North Carolina, Tennessee and Kentucky, as well as the other border slave States. We should do all we
                can to secure their co-operation; but, I now say, if they refuse to go with us, and say they prefer to remain in this Union, I, for one, would be for placing the State of Virginia with the Confederate States of the South. And, sir, if I could not secure a transit route across North Carolina, if I could not get a route through Tennessee, I would go and take the chances, any how,
                rather than remain in our condition as one of the States, when the bond of Union is no longer the bond of peace.
              </p>
              <p className="p-in-sp">
                But, sir, I have already spent more time upon this subject than I designed to do. I shall conclude what I have to say, with expressing a hope that the plan of the majority report, the adoption of which, in my opinion, is a foregone conclusion, may meet the expectations of gentlemen here. I shall throw no obstacle in its way. Should my proposition be rejected I shall not feel
                constrained to vote for any Ordinance of Secession other than some such qualified ordinance as I have presented, looking to a reconstruction of the Union in some form, and looking always to the present condition of the seceded States also.
              </p>
              <p className="p-in-sp">
                Having occupied so much of the attention of this Convention, I shall not trespass further upon it than to say that I want to do equal justice to all gentlemen. I want that everybody in this Convention shall have an opportunity of presenting his views to this House. I will be the last man to vote for any proposition that is to stifle debate here, and prevent others from expressing
                their opinions as I have done.
              </p>
              <div className="speaker" id="sp1805"><span className="persName">Mr. A. M. BARBOUR</span>—</div>
              <p className="p-in-sp">
                I move that the committee now rise. I do so in order that I may be enabled to yield the floor, on Monday morning, to <span className="persName">Mr. BARBOUR</span> of Culpeper. That gentleman came here this evening to endeavor to complete his remarks, but his enfeeblement was so great that he had to retire to his room. I am sure that I can ask this indulgence
                <PageNumber num={660} /> for him, when I inform this Committee that he was for five months, last summer and autumn, confined to his bed with a severe illness. After but a few months of convalescence—because he has never had a month's health—he came here to the Legislature of Virginia, and has been confined to his business in that body until this
                Convention met. Since that time he has been laboring in both bodies until his health has come to such a condition that to have proceeded further this evening would have risked his life. He feels sure, however, that he will be able in a brief space on Monday morning to conclude the remarks which he commenced to-day.
              </p>
              <p>The motion that the Committee rise, was agreed to.</p>
              <p>The Committee accordingly rose and reported progress.</p>
              <div className="speaker" id="sp1806"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">I move that the Convention take up the resolutions upon the table which I offered yesterday.</p>
              <div className="speaker" id="sp1807"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">They are up. The pending question now is upon the resolutions offered by the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>] , and upon these resolutions the previous question has been called and seconded; and the question now is, shall the main question be put.</p>
              <div className="speaker" id="sp1808"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">I call for the ayes and noes upon that question.</p>
              <p>The call was sustained, whereupon the resolutions were reported as follows:</p>
              <p>
                Resolved and ordered, 1st. That on Thursday, the 4th day of April next, at 12 o'clock noon, all debate in the Committee of the Whole upon the reports from the Committee on Federal Relations shall terminate, and the committee shall at once proceed to vote upon the propositions before it, giving five minutes to the member offering any amendments, and the same time to one member
                opposing it, for explanation.
              </p>
              <p>2d. That hereafter no member in the Committee of the Whole shall be allowed to speak more than once upon the same proposition.</p>
              <div className="speaker" id="sp1809"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I don't know, sir, that it is not a resolution that would help me very much. My lungs and throat are in such a condition that I ought not to speak at all. That resolution, sir, is an effort to prevent speaking after the time indicated therein. But, sir, I call the attention of the Chairman of the Committee to the fact I have just sent to the printer a series of amendments to the
                second report of the Committee—a series of amendments which without going outside of the report of the Committee of Twenty-one, and the report which I had the privilege of offering—modify the two in such a way, as, I humbly think, to require time for their consideration. In order to afford members an opportunity of considering these proposed amendments, and modifications,
                <PageNumber num={661} /> and blending of the minority report with the majority report, I made a motion yesterday evening to have these amendments printed. Still explanation will be necessary, and I humbly submit that it will be impossible, in five minutes to explain the comparison of the substitute with the original in any one point. Now, the very first
                proposition that is made is to strike out the two first sections of the amendments proposed by the Committee and to insert one which I shall offer as a substitute; and I repeat that it will be impossible, within five minutes, to discuss any one point of comparison that may exist between these propositions. This is an unreasonable time for such a purpose. These are heavy subjects—they
                are of the most important consequence, and they ought to be understood. Reasonable time ought to be taken for comparison and judgment to operate. Now, this is too much railroad speed except for those who, like my friend
                [<span className="persName">Mr. CONRAD</span>]
                and myself, have had the opportunity already, in Committee, of comparing the two; and I submit to him, that these modifications we even have not compared. He has taken my proposition in Committee by itself. I have taken this proposition of the majority by itself and considered the preference between the two. I have endeavored to modify the one by the other and perhaps to make both
                acceptable. Now, this is a task where the Committee has been divided, and divided even more than it seems to be divided. This is a task for which some time ought to be allowed. I don't ask for that which is embraced in mere incidental debate; but I am pleading for the time that is to be taken in the clear work of the Committee. Surely, sir, so little measure of time will not be given
                to this House as to ask us to take the report of the Committee on mere trust. I know I may appeal to the reason of as reflective a chairman as the one who has presided over our Committee—submitting to his own reason whether five minutes is sufficient time to discuss even the first proposition of that second report. It is not; it is absurd to put that first section in the hands of a
                man that has not read it carefully or heard it well debated, and ask him in five minutes to adopt it. Then, as to the proposition to allow gentlemen to speak but once, it seems to be unreasonable. It is the collision of debate, sir, that strikes the fire of truth, and sometimes like the flint and steel, it must be oft repeated before the fire will spring from the collision or the
                spark catch the object that is to be ignited. We are here trying to strike out truth and not only to show the flame of truth, but to have it seize upon other minds here and elsewhere. This time is too short, and certainly were I to aver a fact and speak upon it, and lay down a principle and illustrate it, and my friend was to reply to me, I ought to have the
                <PageNumber num={662} /> opportunity of correcting, if, perchance, he made any mistake of fact or misapplication of principle.
              </p>
              <p className="p-in-sp">We ought to be all allowed to interchange in a reasonable space of time the aflirmant and reply, the proposition and answer, and the reasons. There is no other way of eliciting fair conclusions, but in fullness of time and opportunity.</p>
              <div className="speaker" id="sp1810"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">
                I feel the force of the appeal of the gentleman, which I know he has made in perfect sincerity. I am acting here not merely on my own judgment, but in behalf of others. But anxious for peace and harmony, and with a sincere desire to accommodate, as far as is consistent with what I regard to be the public interest, I would propose to the gentleman from Princess Anne
                [Mr. WtsE]
                , what I think is reasonable—for I know he has great powers of condensation—that the allowance of time be modified to ten minutes for the proposed amendments; and I would be perfectly willing, and desire that there should be ten minutes for a replication, without carrying the debate further. And I beg leave to add that I desire on my own part, and am sure that it is the desire of the
                Convention, that no rule that might be adopted here, shall prevent the Convention from having the benefit of his argument upon the whole subject. Whenever it shall be his pleasure to make it, I shall vote with great pleasure for suspending any rule which would be an impediment to his speaking.
              </p>
              <p className="p-in-sp">I propose now, sir, to modify the resolution by changing the time to ten minutes for any gentleman submitting a proposition and ten minutes for a replication.</p>
              <div className="speaker" id="sp1811"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I could not reply to the gentleman's remarks without cordially, sincerely and profoundly thanking him for the tender which he has made to me. It is one, sir, which, I would be false to myself as well as to him, if I did not acknowledge; but, whilst expressing that acknowledgement I hope he will not take it as meeting the tender in any inappropriate spirit, when I say that I could not
                accept such an offer for myself when I feel that justly the same tender belongs to all around me. I can never step forward from amidst my equals and my peers and ask any preference whatever, when the rule of equality is the just rule.
              </p>
              <p className="p-in-sp">
                Now, sir, had health permitted, I would have been heard before now upon the subject fully, though the subject in full has not yet been before us. Not yet. The propositions which I now desire to present to this body for their consideration have not yet been laid before them in a distinct and tangible form. They have gone to the printer and will be offered as amendments when the second
                report comes up.
              </p>
              <PageNumber num={663} />
              <p className="p-in-sp">
                The gentlemen of the Committee who have proposed immediate and direct secession have not yet laid their plan before the House, I want to have the whole subject before us, and I want to be in health when I do speak. If we are to be cut off on Thursday next, the probability is that I will be unable—I will try, if able, to perform the task—to be heard in Committee. True, I shall have
                the opportunity, if the previous question permits me, to be heard in the House. I must bide my time until then, perhaps, and if then the previous question cuts me off, why, sir, I have my own private affairs and responsibilities to look to, and I can go before my constituents and state, in words that I would have spoken had I been allowed. I am sorry that the persons who constrained
                my friend from being more liberal than even ten minutes, also through him and through their power, have the same effect in restraining me against my will. It may be that he is restrained with his consent; I am constrained against my consent.
              </p>
              <p>Here the hammer fell.</p>
              <div className="speaker" id="sp1812"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">There are the ten minutes.</p>
              <div className="speaker" id="sp1813"><span className="persName">Mr. SLAUGHTER</span>—</div>
              <p className="p-in-sp">
                Ten minutes certainly is too short a time in which to dispose of amendments to the Constitution of the United States, that are to be offered to the States for their consideration—the most important propositions that could possibly be discussed by any body whatever. To be required to act and vote upon these propositions in ten minutes after they shall have first been brought before
                this House, it appears to me, is a proposition which ought not to be and cannot be sustained. Propositions that the Convention may never have heard of before are brought up, five minutes allowed by way of explanation, and five minutes by way of replication, and then the Convention are to be called upon to say whether they desire such amendments or not. Besides, there is considerable
                ambiguity about this report, gentlemen differing as to the meaning of some of its provisions, and certainly more time is required than the brief period now proposed, in which to make needed and satisfactory explanations. I understand that there are distinguished gentlemen who desire to offer propositions in the nature of something like a dual executive, and I should not like to have
                discussion upon a proposition limiting the powers of the Federal Government in that way, limited to mere five minutes speeches. I think longer time should be given.
              </p>
              <div className="speaker" id="sp1814"><span className="persName">Mr. BLAKEY</span>—</div>
              <p className="p-in-sp">
                I endorse most heartily the statement made by the gentleman who has just taken his seat. The question under consideration is one of vast importance, involving no less than the destinies of the State of Virginia; and we are now called upon in the briefest, <PageNumber num={664} /> shortest examination to accept the work of this Committee, and to adopt it
                in committee of the Whole. And we are, furthermore, instructed when this committee rise, that it is designed to cut off debate. I believe the Chairman of the Committee on Federal Relations so informed us this morning when he was discussing this question. I presume it is the purpose of the committee to have a vote taken and the expression of the body given upon each proposition before
                the committee finally rise, so that we should have a vote upon every proposition and a plan will be then reported to the Convention as the plan of the Committee, to which each member of the Convention will be committed. I should like to know of what avail will discussion be after gentlemen have acted on the propositions?
              </p>
              <p className="p-in-sp">
                What strikes me as particularly singular about the whole matter, and I have thought it strange from the first, is that the chairman of the Committee of Twenty-One should father this gag operation. It will be remembered that this Committee have been some five or six weeks making up this report, and of course the various propositions presented to them have undergone a careful and
                labored investigation. The chairman, who may not need the benefit of discussion—perhaps he could have decided the propositions in the first instance without debate—now comes forward and tells us, after he has enjoyed all the advantages that may grow out of a discussion of questions, that we shall be cut off from the benefit that may result from such discussion. Why is this? The
                gentleman tells us that it was not necessary to have gone into the Committee of the Whole in the first instance. Why did he then move to go into Committee of the Whole? He is the author of that motion and now he comes forward and tells us deliberately that it was not necessary to have gone into Committee of the Whole in the first instance, and therefore that now we should not have
                any further discussion in Committee. Why is it, I ask? Is the Chairman of that Committee afraid to have his work thoroughly sifted, investigated and tested? I will not say that that is his purpose; I will not say that he intends any such thing, for I do not impute any thing improper to his motives, but it would be just as fair to impute that to his motives, as it was for him, this
                morning, to impute to us the deliberate purpose of delay, when we simply asked for the benefit of discussion.
              </p>
              <p className="p-in-sp">
                It is proposed, Mr. President, that this Convention shall decide the destinies of Virginia in this great crisis in as short a time as it took the State of Virginia to hang John Brown. Gentlemen say that there is a great cry in the country about the delay which characterizes the proceedings of this body, and that we are endeavoring to make capital
                <PageNumber num={665} /> out of it. When gentlemen say that, they mistake the point in the cry of delay. The cry about delay is not that you have taken too long a time to consider what you are to do, but that you are too long in getting Virginia out of this Northern Confederacy. That is the cause of complaint. When gentlemen say that they are charged
                with delay, and that the people find fault with them on account of their delay, it is that they are keeping Virginia in this fragment of a Northern Confederacy.
              </p>
              <p className="p-in-sp">But, sir, the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>]
                , has just brought in a proposition which the members of the Convention have not been permitted to see and examine for themselves. And yet he and all other gentlemen are to be cut off from discussion, whilst the doctrine is preached on the other side that no part of this report must be altered, because it will destroy its symmetry. I suppose, then, the plan is to cut off discussion,
                to exclude the light, and cram this report down our throats, whether we like it or not. I am in a minority here. I know that very well, and if gentlemen will force this report upon us without discussion, why we should have to submit, and to that extent we shall be submissionists. I earnestly contend that we ought to have an opportunity to discuss all the questions connected with this
                report, fully and thoroughly before we are required to act upon it.
              </p>
              <div className="speaker" id="sp1815"><span className="persName">Mr. CHAPMAN J. STUART</span>—</div>
              <p className="p-in-sp">
                Mr. President, it is action that Virginia wants, and not speaking. For myself I am completely worn out with this endless talking. I presume that every member of this Convention can claim to possess some degree of intelligence, and that we are all prepared to decide the questions presented for our consideration. The report of the Committee on Federal Relations has been before us now
                for the last three weeks. We had all examined it and considered its various provisions, I presume, carefully and thoroughly. The amendments which the gentleman from Princess Anne proposes to the report of the Committee are now in the hands of the printer, and will be laid upon our tables Monday morning, so that we will have three or four days to consider and reflect upon them before
                the time proposed for the closing of the debate shall arrive. Let me say to you, that, in my humble opinion, you may let the gentleman from Princess Anne
                [<span className="persName">Mr. WISE</span>]
                , or any other member of this Convention, speak from now till the first day of October, and he will not change the mind of a solitary man here; because we are all prepared to decide this question for ourselves. We are sent here by our people to pass upon and vote for the principles sustained by our constituents. We do not expect to be influenced by any mere talk in this Convention;
                we <PageNumber num={666} /> are all prepared, as I said before, to decide the questions that come before us for ourselves. It seems that gentlemen do not talk for the purpose of convincing the minds of the members of this Convention; but they want to have their speeches go home to their constituents; but let me say here that there has been more said and
                printed here than one out of every hundred citizens of Virginia will read between this and the sound of Gabriel's trump.
                [Laughter.]
                I will recollect that in the Convention of 1851, for the first three or four weeks, the people took great interest in the speeches made by the members of that Convention, but at last, after the whole ground had been traveled over, they became tired and disgusted with so much speech-making, so that, from that time on to the close of the Convention, there was scarcely a speech that was
                read by the people of Virginia. Let me say to you here that the subjects which we had under consideration have been thorougly discussed by their friends and opponents. As regards my friend from Princess Anne
                [<span className="persName">Mr. WISE</span>] , for whom I have fought many a battle, I hope that he may be permitted to take one or two days to explain his position; and I, for one, as a member of the Convention, will cheerfully accord him the permission. But, I pray you, gentlemen, not to talk us to death. Let us do what we are intending to do; and then go home and tell our constituents what we have done.</p>
              <div className="speaker" id="sp1816"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">
                So far as I am concerned I feel no disposition to protract the labors of this body beyond the reasonable and necessary time to perfect what this Convention is called upon to do. Sir, I am as anxious to get home as any man in this body, and I am certain that there is no man connected with this Convention who has more interests at stake than I have, rendering it necessary for me to get
                home at as early a day as possible. Whilst this is the fact, whilst I feel that my inclination and my interests would induce me to vote for the propositions of the gentleman from Frederick
                [<span className="persName">Mr. CONRAD</span>] , yet, I must acknowledge, so far as the questions upon which we are called to act, and which are so vitally and materially important, that there has been but one side presented and that has been through the Committee of twenty-one.</p>
              <p className="p-in-sp">
                I understand from a conversation between two distinguished members of the Committee on Federal Relations, that there are members of that Committee who signed the majority report who do not understand that report alike; that there is a radical, vital, important difference between the members of that Committee upon the meaning of certain portions of that report, although they all have
                signed it. Then, sir, I ask you, are you going to cut off debate when a full and <PageNumber num={667} /> fair discussion should be had, because members of that Committee differ upon vital and important questions connected with that report? If gentlemen desire the names those who engaged in that private conversation, they will be forthcoming. I want no
                trick about this matter; I want no double entendre; I want the people of the Commonwealth to know and to understand what is to be done and what does take place, and the construction which this Convention places upon that report. Don't let us go before the people of Virginia with a double construction of language. And, sir, permit me to make this remark — that we are sitting here in a
                very strange condition; and before we give the people this allopathic dose prepared by the Committee of twenty-one, I want to see what that pill is composed of, and I want to see if the doctors who got up this pill agree as to its component parts. I do not think, as far as I am concerned, that it will heal the nation.
              </p>
              <div className="speaker" id="sp1817"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">
                It is, sir, with great reluctance that I rise to say one word upon this subject, and nothing but a sense of duty which I do not feel at liberty to disregard, induces me to open my mouth in this discussion at all. I think I have a right to express the opinion—I most certainly entertain it—that the passage of this resolution would do a great injustice, and I think it must appear to
                gentlemen who will reflect upon the operation of it, that it would inevitably have that effect. For myself, sir, I claim no privilege upon this floor that I would not yield with alacrity to every other gentleman, but a due regard to the interests of those whom I represent, in part, demands that I should claim for myself what I am willing to yield to others.
              </p>
              <p className="p-in-sp">
                This Convention has been in session now for about six weeks. The discussions, thus far, have been confined to perhaps about twenty of its members, while one hundred and thirty-two members that remain, have not spoken upon questions discussed by this body, for reasons which are satisfactory to themselves. One reason why I have not spoken, and a leading one with me, has been an
                unwillingness to trespass upon the attention of the house in order to present my views upon the great subjects which have brought us together in this Convention. But, sir, I had presumed that when those who have occupied the attention of the house had spoken, some fair opportunity would have been afforded others who desired to have the pleasure of the performance of the same duty.
              </p>
              <p className="p-in-sp">
                If I may make a personal allusion, I may say that I occupy a peculiar position here. Some ten days ago, I offered a resolution, to be referred to the Committee of Twenty-one. It was a resolution <PageNumber num={668} /> peculiar in its character, and before it should be passed upon by the Convention, I preferred that it should go through the hands of
                that Committee. I was somewhat surprised to find that its reference was opposed by some forty-odd members of this body. What the reason for that objection was, I do not know, and it is not worth while for me to now stop and enquire. That resolution was referred to the Committee, but I am sorry to say they gave me no satisfaction about it. It has rested in the hands of that Committee
                from that day to this. I saw, or I thought I saw, that there was something of merit shadowed forth in that resolution; but whether there be or not, there has been no suitable opportunity offered to me, from that moment to the present, to say one word upon the subject; and now, if this resolution to cut off debate, prevails, I have yet to wait for the action of that Committee till
                either Monday or Tuesday. What their action is to be I know not precisely, but when it comes, then I am to take the chance with one hundred and thirty-odd members, to catch the Chairman's eye, and if I should succeed in securing it, then, in elucidation of the principles which I sought to carry out in that resolution, I am to be permitted to speak five minutes; and if I had the
                tongue of angels as well as of men, I could not begin to explain what occurred to me as the reasons which induced me to offer that resolution.
              </p>
              <div className="speaker" id="sp1818"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">I wish to say that I am inclined to think the gentleman misapprehends the idea of the resolution now before the Committee of the Whole. It does not apply to any future report or any future Committee of the Whole which may be determined on by the Convention to consider such report. It applies to the matter now before the Committee of the Whole.</p>
              <div className="speaker" id="sp1819"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">
                I venture to suggest a very reasonable conclusion, that if the majority get us out of Committee of the Whole now, even though I had the tongue of angels to persuade them, I would never be able to get them back into the Committee of the Whole for the purpose of considering any such proposition. In asking that, I should have to ask what would be very ridiculous, if I expected them to
                grant a favor reversing the whole course of policy agreed upon here, the reasons for which have not been so explained to me as to commend them to my approbation. So that I come back to the position in which I shall be pleased if this resolution is adopted—and if it were confined simply to myself personally, I would not care a snap of my finger about it—but I have constituents to
                whose interests I would be faithless if I did not seek to protect them to the extent of my ability; and by the operation of this rule I will have to vote either <PageNumber num={669} /> blindly for or blindly against this report. This proposition seems to have been agreed upon by a party who were determined to impose this limitation of time; and when
                the objector, in indignant tones remonstrated, and showed the injustice of it, the responsible organ of this party—and I beg to say, in parenthesis, that I do not wish to utter one word against him, for I merely state what has transpired before us all—undertakes to say, that in regard to this gentleman, the distinguished member from Princess Anne
                [<span className="persName">Mr. WISE</span>]
                , at any moment he wants to speak, he will give him full latitude; not only five minutes, not only ten or twenty minutes, but any length of time that may be necessary to carry out the purposes which he has in view. Sir, that gentleman could not have said anything complimentary to the distinguished gentleman from Princess Anne, that I would not endorse; I admired the reply which he
                [<span className="persName">Mr. WISE</span>]
                , made, and before he made that response I was ready to leap to my feet, to say I presumed the gentleman from Princess Anne would not accept any such proposition. I was glad to hear him decline it. Here we have the gentleman from this Convention first proposing five minutes, which is objected to; then ten minutes, and when objection is made to that, he goes so far as to say, that in
                regard to one member, there will be no limit placed upon his time. If full latitude is to be allowed him, I claim the same for myself, and my colleague, and other members here.
              </p>
              <p><span className="persName">Mr. MONTAGUE</span> asked for the reading of the resolution.</p>
              <p>The CLERK read it again.</p>
              <div className="speaker" id="sp1820"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">
                I shall not occupy ten minutes of your time. I think, sir, there is one thing that all of us should keep in view here, and that is that we should do nothing—should adopt no resolution—should take no action which would be calculated to endanger an angry feeling; and, sir, you know enough about human nature, and the members of this Convention know enough about human nature, to be
                satisfied that when you come to discuss the various propositions in that report, you will have an angry feeling generated that will disseminate itself throughout the State, and increase the now almost too much distracted feeling in the Commonwealth. But, sir, one thing has struck me in the course of this debate. The Chairman of the Committee upon Federal Relations, a high-toned,
                honorable gentleman gets up before a Virginia audience and confesses that he is the father of a resolution which does not meet his approbation, but which is offered in obedience to the behests of others.
              </p>
              <p className="p-in-sp">
                Sir, you have heard a great deal about King Cotton. You have heard a great deal about King one thing and King another. I say, as a <PageNumber num={670} /> free-born Virginian, I am not to be ruled by any king, but I had rather be ruled by any body than King Caucus. We have come here to deliberate gravely and calmly upon questions the like of which have
                never heretofore agitated the public mind of the country. The leading men upon the Union side of the question, as they call themselves, have occupied three quarters of the time in debate, and now, when the report comes in, when amendments are already in the hands of the printer—which I undertake to say, from a personal inspection of those amendments, will require as much
                consideration as the report itself—you want to bring us down to ten minutes each, thereby creating the strife and ill feeling to which I have referred, which can only distract the public mind, and prevent us arriving at some conclusion to save the Commonwealth and the country. I appeal to Virginia gentlemen here, and ask, is this mode of proceeding just? Is it right? Is it proper? Is
                it fair, even handed justice between man and man, between party and party? I appeal to gentlemen not to adopt this resolution. I have not the slightest doubt that if you do adopt it, instead of accomplishing the purpose you desire, it will provoke strife and angry feeling, which all of us will regret. I hope that the resolution will not be adopted.
              </p>
              <div className="speaker" id="sp1821"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">
                There is probably not a single member upon this floor who desires more anxiously than I do to bring this Convention to a close at the earliest possible moment. There is probably no gentleman here to whose advantage it would be to be able to leave this place within a few days, more than to mine. But, while that is a fact, I desire to enter my protest against the course of policy which
                is sought to be pursued by the majority of this Convention.
              </p>
              <p className="p-in-sp">
                Sir, if I had consulted my personal feelings alone, I should not have been here; but, coming here from a higher sense of duty than my own mere personal convenience, that alone shall not influence my leaving here; and I desire that the members of this Convention—a large part of whom have, within the last few days, shown their personal preferences for leaving this city as soon as
                possible—shall be actuated by higher motives than their own personal convenience; and that they shall give to every one of those important propositions looking to the welfare of the entire people of Virginia, a candid, rational, and deliberate consideration.
              </p>
              <p className="p-in-sp">
                I do not propose myself to occupy the attention of the Convention again during its session, and I have only occupied its attention so far at times when no one else desired to speak. At the same time I ask that questions which are presented to gentlemen for their consideration may be fully and freely discussed upon this floor in order that
                <PageNumber num={671} /> the people of the country may fully understand what they are required to pass upon hereafter. That, sir, being my wish, and knowing well that there are distinguished members of this body prepared to express their views upon the questions under consideration, I enter my protest against this resolution and shall vote against it.
                Gentlemen cannot induce me to vote for it from a mere threat as to the consequences in respect to the cost to the State upon the adoption or rejection of the resolution.
              </p>
              <p className="p-in-sp">
                I desire to call your attention for a moment, Mr. Chairman, to the policy that has been pursued by the party—for you may as well call it a party at once—which is pressing this resolution. Those who are now for precipitate action were a few days ago the most anxious for delay. The gentleman who presents this resolution, the distinguished Chairman of the Committee on Federal Relations,
                with those who acted with him on that Committee, kept us in session for several weeks before we could get their report before this body, and now, at the very outset of the consideration of that report, when we have but reached the third or fourth out of the fifteen or twenty distinct propositions, before we have even reached the outside crust that covers the kernel which is within
                that report, we are asked to stop discussion upon it, and to pass upon it, without having that full information which is necessary to act intelligently. Before I am asked to vote upon the adoption or rejection of these sections, I desire to know the premises upon which they are based. I desire a full and fair discussion on the part of gentlemen of the Committee who are able and
                willing to elucidate the subject. I repeat, that we are barely through the crust that covers the kernel, when we are brought to a stop upon the very threshold of the discussion. We have not yet come to the amendments which are to form the basis of the operations which this Convention seeks to inaugurate. We have not reached the propositions to amend the Constitution of the United
                States, which are to form the basis of the adjustment between the two sections for the consideration of the Conference which is to be inaugurated by this Convention. I trust that such a resolution as this will not be passed through this body, but that it will be the pleasure of gentlemen to listen patiently while gentlemen of the committee explain their report, and to such discussion
                as may arise upon it. After having indulged in the latitude of debate which has been indulged in the earlier part of our proceedings, I trust that it will not be cut short just as we have arrived at the threshold of the really important debate of the session. I for one shall certainly vote against the resolution.
              </p>
              <PageNumber num={672} />
              <p>The President stated the question to be on ordering the main question, upon which the yeas and nays had been ordered.</p>
              <div className="speaker" id="sp1822"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">I did not intend to call the yeas and nays upon ordering the main question, and if I was so understood by the Chair I will withdraw them.</p>
              <p>The main question was then ordered to be put.</p>
              <p><span className="persName">Mr. CONRAD</span>, of Frederick, called for the yeas and nays upon the adoption of the resolution.</p>
              <p>The yeas and nays were ordered.</p>
              <p>The question was taken, and it was decided in the affirmative—yeas 64, nays 38—as follows:</p>
              <p>
                AYES—Messrs. Janney (President), Armstrong, Aston, Baylor, Berlin, Blow, Jr., Boggess, Brent, Brown, Burdett, Burley, Byrne, Campbell, Carlile, Carter, Clemens, C. B. Conrad, R. Y. Conrad, Couch, Deskins, Dorman, Dulany, Early, French, Fugate, Gillespie, Gravely, Gray, Ephraim B. Hall, Hammond, Haymond, Hoge, Hubbard, Hughes, Hull, Jackson, Peter C. Johnston, Lewis, McComas, McGrew,
                McNeil, Marshall, Marr, Maslin, Masters, Moffett, MOORE, Osburn, Parks, Patrick, Pendleton, Porter, Price, Pugh, Wm. C. Scott, Sharp, Sitlington, Spurlock, C. J. Stuart, Tarr, Tayloe, Waller, Wickham, Willey-64.
              </p>
              <p>
                Noes—Messrs. A. M. Barbour, Borst, Boyd, Bruce, Coffman, Conn, Flournoy, Forbes, Garland, Graham, Gregory, Jr., Goggin, John Goode, Jr., Thomas F. Goode, L. S. Hall, Isbell, Marmaduke Johnson, Kent, Kindred, Lawson, Charles K. Mallory, Miller, Montague, Morton, Neblett, Richardson, Robt. E. Scott, Seawell, Sheffey, Slaughter, Strange, Thornton, Tredway, R. H. Turner, F. P. Turner,
                Wise, Woods, Wysor-38.
              </p>
              <p>So the resolution was adopted.</p>
              <p>During the call of the roll,</p>
              <p><span className="persName">Mr. BALDWIN</span> stated that he had paired off for the afternoon with <span className="persName">Mr. RANDOLPH</span> upon all questions, not anticipating, however, that this resolution would come up. If permitted, he should vote "aye."</p>
              <p><span className="persName">Mr. BLAKEY</span> and <span className="persName">Mr. BOISSEAU</span> both stated that they had paired off, but with whom the reporter did not understand.</p>
              <p><span className="persName">Mr. CRITCHER</span> said he had paired off with <span className="persName">Mr. SUTHERLIN</span>.</p>
              <p><span className="persName">Mr. CYRUS HALL</span> stated that he had paired off with <span className="persName">Mr. LEAKE</span>, otherwise he should vote "aye."</p>
              <p><span className="persName">Mr. SPEED</span> said he had paired off with <span className="persName">Mr. STUART</span> of Augusta, otherwise he should vote "nay."</p>
              <p><span className="persName">Mr. SUMMERS</span> stated that he had paired off with <span className="persName">Mr. HOLCOMBE</span>. The vote was then announced as above recorded.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.12.5">
              <PageNumber num={673} />
              <h3><span className="headingNumber">1.12.5. </span><span className="head">REPORT OF THE COMMITTEE ON FEDERAL RELATIONS</span></h3>
              <p><span className="persName">Mr. CONRAD</span>, of Frederick, from the Committee on Federal Relations, submitted the following report, which was read, laid on the table and ordered to be printed:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>
                    The Committee on Federal Relations have, according to order, had under consideration a resolution to them referred, directing them to enquire "into the expediency of reporting to the Convention two ordinances to be submitted to a vote of the people for their approval or rejection on the fourth Thursday in May next—one providing for a resumption by the State of the powers
                    heretofore delegated to the General Government; and the other, as an alternative proposition, the series of amendments to the Constitution of the United States, which may be agreed on by the Convention to be submitted to the Northern States as an ultimatum, on the acceptance of which this State will continue in the Federal Union, with the proviso that if not accepted by the day
                    of , the Convention shall have authority to pass an ordinance of secession without referring it back to the people for their ratification," and have come to the following resolution :
                  </p>
                  <p>Resolved, That it would be premature and inexpedient to act upon this subject at this time, but will be proper to consider and report upon the same after the Convention shall have decided upon the former report of the Committee.</p>
                </div>
              </div>
              <p><span className="persName">Mr. SCOTT</span>, of Fauquier, submitted an amendment of the fourteenth resolution of the report of the Committee on Federal Relations, which he asked to have printed, with the view of submitting it in Committee of the Whole.</p>
              <p>It was so ordered.</p>
              <p>And then, on motion of <span className="persName">Mr. HALL</span>, of Wetzel, at half-past six o'clock, the Convention adjourned until Monday next at 10 o'clock, A. M.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v2.2.13">
            <PageNumber num={674} />
            <h2><span className="headingNumber">1.13. </span><span className="head">FORTIETH DAY</span></h2>
            <div className="dateline">Monday, <span className="date">April 1</span></div>
            <p>The Convention met pursuant to adjournment at 10 o'clock A. M. Prayer by Rev. <span className="persName">Mr. PETTIGREW</span>, of the Disciples Church.</p>
            <div className="section" id="vsc1965.v2.2.13.1">
              <h3><span className="headingNumber">1.13.1. </span><span className="head">PERSONAL EXPLANATION</span></h3>
              <div className="speaker" id="sp1823"><span className="persName">Mr. SOUTHALL</span>—</div>
              <p className="p-in-sp">
                I ask the indulgence of the Convention to enable me to bring to its consideration a subject of a personal and privileged character, and at the same time to avail myself of the occasion of presenting, in connection therewith, the proceedings of a meeting held in the town of Scottsville, in the county of Albemarle. I received, a few days ago, a copy of those proceedings, and I confess
                that, at the moment, I doubted what course I should pursue. But a little reflection satisfied me that from their tone and character toward myself, here was the place and this the occasion where they should be presented, and where I should vindicate myself against the charges contained in the resolution adopted by that meeting. I should have done so immediately, but from my continued
                ill health and the inconvenience which I experience in arriving at the hall at the hour of the meeting of the Convention, so as to avail myself of some moment during the half hour session to which you limit yourself before going into Committee of the whole.
              </p>
              <p className="p-in-sp">
                Of what number this meeting was composed I do not know. If I were to credit rumor, and upon it to hazard a conjecture, I would say that, whatever may have been the individual respectability of the members of the meeting, it was small in number. While I concede the full and perfect right of any portion of the people, whether few or many, to assemble when, where, and how they please,
                for the purpose of deliberating on public affairs, and of indulging in criticism on the conduct of their public agent, it becomes those who undertake to impeach his course, to possess themselves of all the facts necessary and essential to bring them to a correct and proper result.
              </p>
              <p className="p-in-sp">In the close of those proceedings, the desire is expressed, that they shall be published in the various papers of my county and of the city of Richmond; and I desire, so far as I can, to further their object, by asking to have the proceedings read here, in order that I may send the antidote along with the bane.</p>
              <p className="p-in-sp">The proceedings are as follows:</p>
              <p className="p-in-sp">
                At a meeting of the citizens of Scottsville and vicinity, Albemarle <PageNumber num={675} /> county, Virginia, held in the Armory, on the 23d day of March, 1861, Joseph R. Beal, Esq., was called to the Chair, and C. G. Tompkins, Esq., appointed Secretary. The Chairman stated that the object of the meeting was for the instruction of
                <span className="persName">Mr. V. W</span>. Southall, one of our delegates in the Convention,
              </p>
              <p className="p-in-sp">Whereupon, B. F. Wade, Esq., offered the following resolution, which was almost unanimously adopted:</p>
              <p className="p-in-sp">Resolved, That we hereby express and make manifest our most decided disapprobation of the course of our delegate, <span className="persName">Mr. V. W</span>. Southall, in the Convention, and we would further urge him to cast his vote in accordance with the ideas of his canvass speech, made in this place, for immediate and direct secession.</p>
              <p className="p-in-sp">The above resolution was directed to be published in the Scottsville, Charlottesville and Richmond papers, and also copies to be sent to our delegates in the Convention.</p>
              <p className="p-in-sp">On motion, the meeting adjourned.</p>
              <p className="p-in-sp">JOSEPH R. BEAL, Chairman</p>
              <p className="p-in-sp">C. G. TOMPKINS, Secretary</p>
              <p className="p-in-sp">
                Without stopping, Mr. President, as my time is limited, to notice or characterize the language of this resolution, as I might do, for its discourteous and offensive character, I desire at once to approach the substantial matter of the resolution, and show how plain a tale will refute and dissipate it. I can but feel satisfied, sir, that those who composed that meeting must have known
                the position which I occupied in and through the late canvass, as well as I did myself. They had every opportunity to know it, and I will prove, by abundant evidence, that they must have fully known it when they cast their votes.
              </p>
              <p className="p-in-sp">
                It is true, as their resolution alleges, that in the month of January, during the canvass in that county, I addressed a large, intelligent, and highly respectable meeting, composed of the people of Scottsville and gentlemen of its vicinity. The next day, on arriving at my home, a distance of some 20 miles, I found that a rumor had actually preceded me and had reached the town of my
                residence, to the effect that in the speech delivered the day before in the town of Scottsville, I had taken the position, that if our difficulties with the North were not fully and satisfactorily adjusted, on or before the 4th of March, I was of opinion, and if returned as a delegate to this body, should be prepared,.at once, to sever the connection between Virginia and the Union.
              </p>
              <p className="p-in-sp">
                I confess, sir, that such a rumor, so utterly groundless, greatly surprised <PageNumber num={676} /> me. There was much excitement at that meeting. There were, for its size, as many influential persons at it, as I have ever seen at a meeting in that county. There were active, and honorable, and zealous individuals who differed with me in sentiment. I
                suppose I must have been interrupted—expressing a willingness to be—half a dozen times by various interrogatories propounded by those gentlemen. In order promptly to correct this misrepresentation coming from the town of Scottsville, whether made innocently or otherwise, as well as to arrest its further progress in the county, I forthwith sent to the press this card:
              </p>
              <p className="p-in-sp">
                "CARD-I understand it is reported, that in a speech made by me at Scottsville, I said, provided no satisfactory adjustment of the difficulties between the North and South, was accomplished by the fourth of March, I was opposed to a delay of the secession of Virginia, beyond that day. Anxious that no one shall misunderstand my position upon any and every question involved in the
                pending canvass, and as such representation of my speech at Scottsville is not in accordance with my views then, and now entertained, as to the time within which Virginia should act and should secede, I have deemed it proper to say, that on the occasion alluded to, I certainly intended to say, and now say, that if, before the final adjournment of the Convention it be ascertained,
                that no fair and honorable adjustment can be had, that our just demands and just claims are to be disregarded, and denied, whether this occurs before or after the 4th of March, the Convention, satisfied of this, should proclaim our union with the North as dissolved. V. W. SOUTHALL
              </p>
              <p className="p-in-sp">"JANUARY 21, 1861."</p>
              <p className="p-in-sp">
                Is there anything, sir, in that card like direct and immediate secession? Is there anything in it like an unqualified declaration that on or before the 4th of March, or on or before any other day, no matter what might be the condition of the country, or the prospect of a fair, honorable and satisfactory adjustment of our national difficulties, I pledge myself for "immediate and
                direct secession." Might I not stop here, Mr. President, in regard to this unkind and ungenerous resolution, and ask whether that single card does not, at once, whistle to the winds and utterly and conclusively refute it?
              </p>
              <p className="p-in-sp">
                But, sir, I shall not stop here. Before the delivery of the address at Scottsville, I had received a written communication from a portion of the people of my county, addressed jointly to Mr. Wm. C. Rives and myself, signed by some five or six hundred voters, requesting us <PageNumber num={677} /> to allow our names to be used as candidates for seats in
                this body; and I had prepared a reply to that communication. It was then in manuscript, and its immediate publication was delayed by circumstances beyond my control. Within a few days after my address at Scottsville, however, it was sent to the press, and was published in two papers of opposite party politics. It was also published in pamphlet form, and was circulated broadcast over
                the county. It was thus placed within the reach of every man who desired information touching my position. Allow me to detain you by referring to one or two portions of this address. I will not read the letter addressed to Mr. Rives and myself, but will read two or three extracts from my reply.
              </p>
              <p className="p-in-sp">After some preliminary remarks, in the address, the following paragraph in my reply occurs:</p>
              <p className="p-in-sp">
                "Ere the voters of the county shall proceed to select the persons, to be clothed with a trust transcending far any other which they can be called on to delegate, holding, as I do, a community of interest with them, and fated to share in whatever of good or evil the future may have in store for us and ours, I deem it as due to them to indicate my present views and opinions touching
                the terrible and formidable crisis impending over Virginia."
              </p>
              <p className="p-in-sp">I then proceed to refer to and incorporate in the address, resolutions adopted at a meeting of the people held at their Albemarle December Court. The resolutions then and there adopted, and which I approved in the meeting, I re-affirm and re-adopt. I will not read them, but pass on.</p>
              <p className="p-in-sp">In a subsequent portion of the address, I say :</p>
              <p className="p-in-sp">
                "Such is my trust in the good sense and patriotism of the American people, that I hope, if time is given to make an appeal directly to the American masses (not the politicians), they will rise in their might, and restore peace to a distracted country. And then the Union would go on in its noble march, transmitting, I trust, for ages to come, those countless blessings which we, in the
                past, enjoyed. I cannot but feel assured, that there is conservative feeling enough left in the North to accept the propositions which Mr. Crittenden has presented as the basis of an adjustment, rather than plunge over the fearful precipice, and encounter all the terrible consequences of disunion."
              </p>
              <p className="p-in-sp">In conclusion, I make this declaration :</p>
              <p className="p-in-sp">"If, during the contemplated Convention [that is, as stated in the card, already read, before its final adjournment]
                it be ascertained that our just and reasonable demands are to be disregarded, and our claims for all proper additional Constitutional safeguards are to be <PageNumber num={678} /> denied, then no alternative, I think, is left but for Virginia, through such Convention, to assume the powers which she heretofore delegated to the Federal Government."
              </p>
              <p className="p-in-sp">
                Now, Mr. President, with this proof, with this accumulated evidence, a meeting, composed of I know not whom, assembles, so far as we are informed, without notice, and adopts the resolution which I have read to you. Were they justified in doing so? Before indulging in such charges, it would have been infinitely better for them to have, at least, descended to specifications.
              </p>
              <p className="p-in-sp">But, sir, is this all?</p>
              <div className="speaker" id="sp1824"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The hour for going into Committee of the Whole has arrived.</p>
              <div className="speaker" id="sp1825"><span className="persName">Mr. SOUTHALL</span>—</div>
              <p className="p-in-sp">I am addressing the Convention upon a privileged question; and even if I were not, I should hope the Convention would yield to me the courtesy of proceeding a little longer.</p>
              <div className="speaker" id="sp1826"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The chair forgot that the gentleman was speaking upon a privileged question.</p>
              <div className="speaker" id="sp1827"><span className="persName">Mr. SOUTHALL</span>—</div>
              <p className="p-in-sp">I so announced, sir, when I arose to address you.</p>
              <p className="p-in-sp">
                Sir, I was about to remark, when interrupted, that this was not all. Evidence still comes to accumulate upon that which I have just read "piling Pelion upon Ossa," and Ossa upon Pelion. Allow me to say, if I have not before done so, that there is no portion of my county where there are more high-toned, honorable and intelligent people than are to be found in the town of Scottsville
                and its vicinity.
              </p>
              <p className="p-in-sp">
                In the progress of time the election took place. My honorable colleague, with another worthy and highly respectable gentleman, a resident in the vicinity of Scottsville, and myself, were the candidates. I have, sir, been in political life off and on for some thirty years, and I have never appealed to the people of Scottsville, that I have not invariably, as I am sure, received their
                cordial and warm support, and in the highest and most violent party times outrun, at, that precinct, my political opponents, and led those upon my own party ticket. But, sir, when the polls were closed at that place at the recent election, what result did they exhibit?
              </p>
              <p className="p-in-sp">
                The gentleman referred to as a candidate received a large majority over my colleague and myself, and I fell some forty or fifty votes behind the latter, though in the aggregate vote of the county, I was honored with the eldest return, by a few votes ahead of my colleague. Surely then, sir, the good people of Scottsville knew what they were doing; knew the principles that they were
                voting to sustain; knew the position and views upon which the candidates stood. So much, sir, <PageNumber num={679} /> then, for "immediate and direct secession," as avowed by me during the canvass in my county. The resolution of this meeting, assuming the form of an indictment, contains, however, two counts. I have disposed of the first. I approach now
                the second, and, on its trial, all I have to do is to invoke the testimony of each and every member on this floor. What is this second charge?
              </p>
              <p className="p-in-sp">"That we hereby express and manifest our most decided disapprobation of the course of Mr. Southall in the Convention."</p>
              <p className="p-in-sp">
                I see members around me smile at this most singular and groundless imputation. You, sir, did me the honor of placing me on the Committee on Federal Relations—that Committee, having the privilege to sit during the session of the House, were engaged in the matters referred to them for some twenty-five or thirty days before even their first partial report was made—sometime after their
                second and final report came in. I suppose these gentlemen hardly allude to anything that may have passed in the committee-room; and a short time after these reports were made, you, sir, assigned me the position of Chairman over the Committee of the Whole, where I have been since, and acted as such when not absent from ill-health. But, this apart, sir, I appeal, Mr. President, to
                your record; I appeal, sir, to every gentleman present, and I entreat to know, I urge any to speak, and say when there has been a single solitary vote taken since the organization of this body, the 13th of February, which goes to test or define the actual position of any gentleman here. I most confidently refer to your Journal, to show that there has not been from the day of your
                installation into that position, sir, to the present time any vote which tended to commit any gentleman as to his ulterior policy; and yet sir, these good people undertake, with a seeming hot haste, to censure their delegate in general terms for the omission or commission of something which they decline to specify.
              </p>
              <p className="p-in-sp">I say then, sir, in regard to this second charge, that there is not the shadow of a shade of proof to sustain it—that it is gratuitous and utterly groundless, and, to say the least, ought not to have been indulged in. I leave to its authors to reconcile it with the facts which you, and every member here, know full well to belong to the action of this body.</p>
              <p className="p-in-sp">
                Allow me, sir, in conclusion, as I am upon the subject of an account between the representative and a portion of his constituents, to indicate my opinion as to the relative rights and duties of each. In these exciting times, when every effort is being made for the purpose of manufacturing public sentiment through the wide extent of the
                <PageNumber num={680} /> Commonwealth, I desire to say, in reference to the right of instruction, however much and often abused, I have at all times entertained but one opinion upon the subject, and that is, that as between the constituent and representative, upon all questions of expediency not involving any moral or constitutional difficulties, it
                becomes the representative, when satisfied of the wishes and desires of the constituent body, to yield his own and to give effect to those entertained by his people.
              </p>
              <p className="p-in-sp">I know, sir, that able arguments have been urged contrary to this. Such as the celebrated address of Edmund Burke to the electors of Bristol; the speeches of Messrs. Brent and Giles in the Senate of the United States, when instructed, in years past, by your Legislature, touching the charter of the United States Bank, and others.</p>
              <p className="p-in-sp">
                The position, in part, of these latter gentlemen (for I've not seen either of their speeches for many years) , may have been as to the right of the Legislature to interfere with the duties of a Senator after his election. I have thought, sir, that the reasons given against the exercise of the right by a delegate's immediate constituents (one of which is that he represents the whole
                and not a part, though chosen by such part), as more plausible than sound.
              </p>
              <p className="p-in-sp">
                I believe, sir, that upon all questions of expediency, the feelings, wishes and opinions, when known to the representative—and I care not how the information may reach him—if he honestly believes that no rational doubt exists as to what his constituents desire, it is his duty to reflect such their will. I am inclined, Mr. President, from all I hear—for I have been absent from my
                county since the day before this Convention was organized, and therefore cannot speak from personal knowledge upon the subject—I am inclined to the belief that since the election of the delegates to this body, from all I learn, that those with whom I differed on the day of the election have increased in number, to what extent I am unable to say. Where the majority now is, I am unable
                to decide; though I will say, sir, from such information as I have received, there is uncertainty touching it. I have but to add, sir, whenever I can be satisfied that that majority is either the one way or the other, I will express and give effect to it. The question, sir, with the people, is one too transcendent, too momentous, involving all that is sacred and dear to them and
                posterity, to have their clear and manifest will, let it lead the one way or the other—arrested or defeated.
              </p>
              <p className="p-in-sp">But, Mr. President, so long as a doubt exists with me, as to the <PageNumber num={681} /> actual majority in my county, I shall adhere to the position I occupied, when the trust I hold was confided to me.</p>
              <p>The Convention then went into Committee of the whole, <span className="persName">Mr. SOUTHALL</span>, of Albemarle, in the Chair.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.13.2">
              <h3><span className="headingNumber">1.13.2. </span><span className="head">IN COMMITTEE OF THE WHOLE</span></h3>
              <div className="speaker" id="sp1828"><span className="persName">Mr. BARBOUR</span> resumed his remarks as follows:</div>
              <p className="p-in-sp">
                Mr. Chairman, I ask leave of the Committee for a moment to refer to a point from which I had passed the moment that the Committee allowed me to suspend my remarks. I had stated some facts, sir, to show that we were perfectly safe so far as the hostility of this Washington Government is concerned; that it had become so decrepid already that we had no cause to fear it. I have got hold
                of one of the official circulars of this government, to which I beg leave to ask the attention of the Committee. I have already said, sir, that the Washington Government is out of money, and that they in vain try to borrow it on such terms as you and I can borrow reasonable amounts. I tried to show that they were not able to maintain armies and invade States. Their own official
                declaration shows that they are utterly bankrupt.
              </p>
              <p className="p-in-sp">
                Here is one of the circulars that they are sending around now to the men who have performed service for them. They inform them respectfully that they are not able to pay, and that they should not submit to a shave just yet. It is the circular that the Superintendent of the Census Bureau at Washington is issuing to the Census takers throughout Virginia who have not yet been paid.
              </p>
              <p className="p-in-sp">"CENSUS OFFICE,</p>
              <p className="p-in-sp">DEPARTMENT OF THE INTERIOR,</p>
              <p className="p-in-sp">WASHINGTON, March 22nd, 1861.</p>
              <p className="p-in-sp">
                "Sir: You are informed that the suspension of the United States Treasury has for some time prevented the payment of Marshals and assistants engaged in taking the Census. The first payments will be completed as rapidly as possible, as soon as funds shall be provided; and final payments will be made at the earliest moment possible. That you will receive full compensation cannot be
                doubted, and you are advised not to dispose of your claim at a sacrifice.
              </p>
              <p className="p-in-sp">"Very respectfully,</p>
              <p className="p-in-sp">Your obedient servant,</p>
              <p className="p-in-sp">J. C. G. KENNEDY,</p>
              <p className="p-in-sp">Superintendent."</p>
              <PageNumber num={682} />
              <p className="p-in-sp">
                I need hardly repeat that the brave and high-minded people of this Old Dominion have no reason to fear a government thus deficient in the sinews of war. The Abolition Government at Washington actually have to send circulars around to men who have served them, begging them not to shave their own paper; but that the Government will do better some of these days. The Government at
                Washington finding itself with a treasury bankrupt, begs its own employees not to shave its own paper!
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I will now resume the line of argument I was pursuing Saturday evening, when I was addressing the Committee. I was endeavoring to show the condition of the Government of the Confederated States at Montgomery, that we were bound, as men looking to the facts that surround us, to deal with that Government as one of the national existences of the world. I find the speech of
                the Vice-President
                [STEPHENS] , briefly summing up the essential elements of its character. The following is an extract from <span className="persName">Mr. STEPHENS</span>' speech:</p>
              <p className="p-in-sp">
                "We have all the essential elements of a high national career. The idea has been given out at the North, and even in the Border States, that we are too small and too weak to maintain a separate nationality. This is a great mistake. In extent of territory we embrace 564,000 square miles and upwards. This is upwards of 200,000 square miles more than was included within the limits of
                the original thirteen States. It is an area of country more than double the territory of France or the Austrian Empire. France in round numbers has but 212,000 square miles. Austria in round numbers has 248,000 square miles. Ours is greater than both combined. It is greater than all France, Spain, Portugal and Great Britain, including England, Ireland and Scotland together. In
                population we have upwards of five millions, according to the census of 1860; this includes white and black. The entire population, including white and black, of the original thirteen States, was less than 4,000,000 in 1790, and still less in '76, when the independence of our fathers was achieved. If they, with a less population, dared to maintain their independence against the
                greatest power on earth, shall we have any apprehension of maintaining ours now?
              </p>
              <p className="p-in-sp">
                "In point of material wealth and resources, we are greatly in advance of them. The taxable property of the Confederate States cannot be less than $22,000,000,000. This, I think I venture but little in saying, may be considered as five times more than the Colonies possessed at the time they achieved their independence. Georgia alone
                <PageNumber num={683} /> possessed last year, according to the report of our Comptroller General, $672,000,000 of taxable property. The debts of the seven Confederate States sum up in the aggregate less than $18,000,000; while the existing debts of the other of the late United States sum up, in the aggregate, the enormous amount of $74,000,000. This is
                without taking into the account the heavy city debts, corporation debts and railroad debts, which press, and will continue to press, a heavy incubus upon the resources of those States. These debts, added to others, make a sum total not much under $500,000,000. With such an area of territory—with such an amount of population—with a climate and soil unsurpassed by any on the face of
                the earth—with such resources already at our command—with productions which control the commerce of the world—who can entertain any apprehensions as to our success, whether others join us or not?"
              </p>
              <p className="p-in-sp">
                Mr. Chairman, is a Virginia Assembly, after what we see and hear, going to sit with folded arms and decline to recognize a fact which is about to be acknowledged by all the powers of the earth? Look to the recent European news. Will not the powers of Europe do that which a Virginia Convention hesitates and pauses to do—recognize the fact that there is a national existence at
                Montgomery? Nations act on their interest, not on sentiments. England and France, because they do not like slavery, are not going to refuse to deal with and make money out of the most lucrative customer on the face of the globe. Tell me that they will do that in the future which is utterly inconsistent with every thing they have ever done in the past!
              </p>
              <p className="p-in-sp">
                In the recent news by the last mail from Europe, we find that the European powers are watching its progress, and when satisfied of its permanency, will recognize the existence of the Confederate government. I might detain this Convention by reading extracts from various organs of public communication in Europe. I choose not to detain you so, but I choose to go right home to the organ
                of that sentiment in England upon which gentlemen rely, to place England in opposition to this Southern republic. Here is what the London "News," the organ of Exeter Hall, the peculiar organ of the abolition sentiment of England says:
              </p>
              <p className="p-in-sp">
                "Mr. Gregory has given notice that on an early day he will call the attention of her Majesty's government to the expediency of a prompt recognition of the Southern Confederacy of America. There is no occasion for Mr. Gregory or any one else to be anxious to get our Government to acknowledge the so-called Southern Confederacy of American States. The practice of the British government
                in such cases is firmly <PageNumber num={684} /> established, and well understood—viz: to recognize all de facto governments, irrespective of opinions, origin, or any circumstance but the fact of being the actually established ruling power. If ever, and whenever, that happens with the Southern States, which now profess to be a Confederacy, there can be
                no doubt about their being recognized by all the European powers; and by England with the utmost certainty and distinctness—but the case has not yet reached this stage; and it is very far from reaching it."
              </p>
              <p className="p-in-sp">
                I merely read that, as being a forcible statement from a hostile quarter of what every gentleman knows is the established rule of national intercourse at this day, viz: that you do not look to the de jure, but to the de facto Government. All the powers of the earth, unless this Convention shall make the State of Virginia an exception, must recognize the Government at Montgomery as a
                de facto Government in actual existence, to be recognized as such, and dealt with as a national existence. We, who of all people on the face of the globe, are the most interested, and who should most quickly notice and avail ourselves of the fact, propose to adjourn over for six months, in the midst of the great events by which we are surrounded. You, gentlemen of this Convention,
                composed, as it is said to be, of the picked men of the State of Virginia, and invested with the powers of this Commonwealth, charged with the high responsibility of looking after the interests of Virginia, at this time, when all the Governments of Europe are watching, with eager eye, the progress of events here, propose to close your eyes, retire to your chambers and decline to have
                anything to do with the great events that may transpire for six months to come. Let us recognize this Government. I am making a respectful appeal to gentlemen here—and I ask them to calmly look around them and see what other people are doing. Let is recognize this Government as an existence and a de facto Government. Can not you, the representatives of the slaveholding State of
                Virginia, recognize it as a de facto Government, with all your interests bound up in it. Will you decline to deal with the Great Fact of this age, the existence of a Government at Montgomery? And will you decline to deal with it in consequence of your great, affectionate attachment to the Government at Washington? Dare you close your eyes to the abolition influences that control the
                Government at Washington? The Washington Government has not issued its pronunciamento and declared the independence of the Republic at Montgomery; but are not all of its acts based upon the recognition of that Government? Does it exercise now within those seven Confederated States of the South one of the powers <PageNumber num={685} /> of Government?
                Does it attempt to do it? It will be compelled to withdraw its forces from their borders and decline to collect the revenue there. It must withdraw the whole machinery of its power from within the limits of that Confederacy, and thus by this act make a practical de facto acknowledgment of the independence of that Republic at a moment when the Virginia Convention, tremulous in the
                presence of stirring events, will not see it, and are so uncertain whether it is there or not, that they must adjourn for six months and wait for further developments. In the midst of a great Revolution you sit here picking straws and whittling sticks. When we thus behold Virginia statesmanship will not your countrymen, wrung as by the agency of a private grief, exclaim, "How are the
                mighty fallen, fallen from their high estate!"
              </p>
              <p className="p-in-sp">
                It is a fact, Mr. Chairman, that there is a separate national existence at Montgomery; and are we performing our duties to the people of Virginia, when we decline to deal with that Government and direct our efforts to an alliance with the Government at Washington? Sir, we are bound to acknowledge the fact that here are two Governments. Which shall we join? Where is it safe to put the
                interest of this Commonwealth? Is not this the question? I think it is. Here are two Governments, one imploring you to take part with it, the other, to say the least, manifesting a considerable amount of indifference as to where you may go. Can we hesitate, sir? Let us see, in looking to the interest of our people, where ought we to look now?
              </p>
              <p className="p-in-sp">This thing of sentimental politics, this thing of arranging the affairs of nations upon sentiment, is a thing that no man, fit to touch the machinery of Government, ought ever to acknowledge as a controlling influence over his action. No great ruler of mankind ever yet conducted the affairs of his people by a mere sentimental attachment.</p>
              <p className="p-in-sp">
                Sir, it was the remark of a distinguished Frenchman, quoted with approbation by Edmund Burke, that "statesmanship is the science of circumstances;" "he who ruled over mankind," as Mr. Burke said, "has a different part to play from him who is a Professor in a College. The Professor may deal with abstract principles, the statesman has to look at the circumstances by which he is
                surrounded, and to endeavor to shape the action of his people in accordance with the circumstances that surround him." And, sir, what are our circumstances? Are you going to guide the action of this Commonwealth according to her interests under existing circumstances? Or, are you going to look in cold self-conceit to the cards which you sent out when you were candidates for seats in
                this Convention? Is each man to say, "I cannot do this <PageNumber num={686} /> until I see whether I said in my card that I was going to do it?" You would not conduct a Government long if you should conduct it according to the chart laid down in the cards of candidates; and I say that that man who stands here to-day, and says that the action of
                Virginia now should be what he would say it ought to have been on the first of January last, is a man who closes his eyes to the great duties rising up before him. You are living in the midst of a revolution, and you are bound to shape your action so as to promote the best interests of your constituents in the midst of this great revolution.
              </p>
              <p className="p-in-sp">
                And it is just at this point, I would say, Mr. Chairman, that, for one, when you come to ultimatums, I cannot touch any of them myself. I was willing, on the 15th of January, as one of the representatives of the people of Virginia, to place upon record the expression of the belief of the Virginia Legislature, that a prompt tender of the Crittenden propositions, would, in our
                judgment, be accepted by the people of the Commonwealth of Virginia. I thought so then; I believed so then. I have no doubt if they had tendered to us with promptitude the Crittenden resolutions, as amended by our Legislature, that they would have been accepted by the people of Virginia. At that time this Government of the Southern Confederacy down yonder, was in its chrysalis state,
                and we then heard the declarations of all their representatives that, rather than to tempt the trial of a new experiment in the formation of a new Government, those people would accept those Crittenden propositions.
              </p>
              <p className="p-in-sp">
                I am free to say, sir, that I won't stand on the Crittenden propositions to-day, simply because, viewing the public facts as I view them, my belief is so strong that I might almost say I do know—inferring the action of people from facts which ought to control them—that those Crittenden propositions would, at this moment, be utterly impotent to dissolve that Government at Montgomery,
                and bring those seceded States back; and I tell you I have no idea of remaining in this Government unless it will bring them back. Nor have my constituents if I know their purposes.
              </p>
              <p className="p-in-sp">
                Now, sir, those people, through their representatives at Washington, had announced, before they had formed their Government, before it had become consolidated, that the Crittenden propositions would be accepted by them. They asked for them, but they could not get them. They were compelled to make a trial of new modes and new forms of Government, and that experiment is now a splendid
                success.
              </p>
              <p className="p-in-sp">
                And, sir, do not you recognize it—does not every gentleman upon this floor recognize the fact, that if you bring those States back into <PageNumber num={687} /> this Government, you must do it by regular treaty and re-annexation? Do you think that by sticking into your Constitution some of these little paper amendments, that that thing from which they
                have been driven, is to become so seductive to them that they are going to throw off the habiliments of an independent Empire and come back to the oppression from which they successfully, manfully walked away?
              </p>
              <p className="p-in-sp">
                Mr Chairman, he who reasons in that way blinds himself to the motives which control the movements of men and of States. Why, sir, just imagine that the single State of Texas was out of this Union as she was in 1844. It was then a question of some difficulty amongst themselves, whether they would agree to annex themselves to these United States. Suppose at this moment, that after the
                development of the hostility of those who control this Government, to the institution of slavery, Texas had not been annexed, but stood out there, a little slave Empire to herself. Do you imagine they could get Texas to come into this Northern Confederacy? I think not, sir. How then, sir, can you expect to get those seven States, with all those elements of power stated by Vice
                President Stephens in the extract from his speech which you heard read a short time ago—in common sense how can you expect that Southern Republic to dissolve itself—strip itself of all the attributes of a separate existence, bow its head, come back here and subject itself to that oppression which it has just left? It is not fair to reason in that way. And what is to be the instrument
                of this great undertaking? "Their great attachment to us." That is the idea of those sentimental statesmen around me here, who expect to bring those States back. Wake yourselves up, gentlemen, and enquire if it is their interest to come back? Will they give up a Government wholly devoted to their own interest, and come to one from which they have walked away, because they were
                repelled when they asked nothing but what was just? "Give us the power to keep this Government from injuring us," is all that those seceded States have heretofore asked.
              </p>
              <p className="p-in-sp">
                Now that they have a Government exclusively devoted to their own interests, an existing nationality, will you tell me they are going to come back and give up all on the sentiment of attachment to old Virginia? Are we to stand here solitary and alone, fix up our resolution, go out to the public and expect the sentiment of attachment to this old Commonwealth to produce such a feeling
                that whatever old Virginia says everybody is going to do?
              </p>
              <p className="p-in-sp">Sir, if you will show me how it is the interest of any of those seceded States to do this, I will agree that they may do so. But when you tell me that they are going to act on a mere sentiment, I tell you we are <PageNumber num={688} /> working on a basis utterly untrustworthy, if the history of mankind is to be accredited.</p>
              <p className="p-in-sp">
                No, you must proceed by treaty with them. You are afraid to look at your Southern neighbors and friends, afraid to approach them. You are treating them as if they were the members of your family who had disgraced themselves, and contact with them would lower you in the eyes of your pure, virtuous, well beloved associates at the North. You dare not approach the North after having
                contaminated yourselves by any associations or conversations with these outcast sisters of the South? You cannot associate with them, you cannot communicate with them? 0, no! "That smacks of disunion. It would injure our standing amongst the noble populations of the North. Do not let them know we have any feeling in common with the slaveholding States. Stand here as if we disapproved
                of everything they are doing. We will stand here and do the best we can for them. We, the people of old Virginia, will get the best terms for our disgraced sisters and ourselves we can, out of the people of the North. We must not ask too much, we will get the best we can for our poor deluded sisters down yonder, and if we get it, we know they will come running back to us."
              </p>
              <p className="p-in-sp">
                That is not the kind of reasoning you get me to act upon. Sir, will not every gentleman here recognize the fact, that if there is to be a new alliance between those States of the South and those of the North, it is to be done by TREATY. Here now stand two national existences —the new one is now a national existence that may be recognized, by foreign powers, long before you can
                re-assemble here in October. Do not you recognize the fact that if you dissolve that Southern Government, you must dissolve it by treaty and by negotiations, through the constituted authorities of that government? Then be men and act on that fact.
              </p>
              <p className="p-in-sp">
                Well, sir, now mark where we stand. This majority report recognizes the great fact of this age that a Revolution by the means of secession of Southern States has occurred. It proposes not that this Convention should act for the interests of Virginia now, but that you should empower the hostile influences that control the Federal Government to act for you. Will my friend read for me?
              </p>
              <div className="speaker" id="sp1829"><span className="persName">Mr. MORTON</span> read the tenth section of the majority report of the Committee on Federal Relations as follows:</div>
              <p className="p-in-sp">
                "10. Without expressing an opinion as to the question of power, but in deference to the opinion of the Federal authorities, the people of Virginia hereby declare their desire to confer upon the Government of the United States the powers necessary to enable its proper authorities <PageNumber num={689} /> to deal peaceably with these questions, and, if it
                shall become necessary, to recognize the separate independence of the seceding States, and to make such treaties with them, and to pass such laws as the separation may make proper."
              </p>
              <div className="speaker" id="sp1830"><span className="persName">Mr. BARBOUR</span>—</div>
              <p className="p-in-sp">
                How is the fact Mr. Chairman. On the 9th of March, this Committee presented their report. They send out as an argument for Mr. Seward's National Convention, the declaration that we want to provide by Constitutional amendments that the government shall have the power to recognize the Southern Confederacy. For that reason you want a National Convention to determine whether a final
                separation is proper, and if so, to determine the terms on which the separation is to take place. Sir, how rapid is the march of events. How rapid is the march of this revolution. Before this Convention has gone beyond the threshold in the consideration of this report of its Committee, that government at Washington has de facto recognized the existence of the Southern Republic at
                Montgomery. It does what the Committee said it would not do; it assumes the power to deal peacefully with these questions. And I am rather inclined to think the Southern Confederacy are disposed to make the government at Washington deal peacefully with these questions. Col. Davis and the authorities at Montgomery are as good as a National Convention.
              </p>
              <p className="p-in-sp">
                But what is the grave proposition that is deliberately presented to us for our consideration? It is that recognizing the propriety of the government at Washington treating with the Southern Republic; you want a new authority to treat with them. Now will not that be a beautiful exhibition? Mind, the reason why you want a new authority constituted, is to get the power to treat with
                that government down there, to recognize its existence. Sir, look at the facts around you. How strange, Virginia's position. You propose that a peaceful negotiation shall be inaugurated. New powers are to be given to the government at Washington, to enable it to settle by treaty the grave controversy which now divides this Confederacy. The subject of the controversy is your property,
                and your rights. The grievances for which they have seceded are more yours than theirs. You recognize the necessity of treaty to settle the controversy between the sections. And yet when you come to treat for a final solution of this great struggle, on which side of the negotiation are you? You are on the side negotiating with South Carolina and the other seceding States, and
                contracting with them for the protection of your rights, against your associates in the treaty. Is not that a beautiful spectacle? Why, sir, the report of our Committee states that the grievances which are to be the subject of the <PageNumber num={690} /> negotiation, are grievances more nearly affecting the interests of the Commonwealth of Virginia
                than any of the seceding States. You get up new authority to negotiate for the protection of these assailed interests, and you are on the side of the Northern States, to negotiate for the protection of your own interests and your own rights which are assailed by the Northern States.
              </p>
              <p className="p-in-sp">
                That, sir, is a new phase of Virginia statesmanship. If we were a Yankee people, who were going into a negotiation upon such terms, I might expect something would come out of it; for it might, in Yankee lands, be made to work as a smart Yankee trick. If you were as smart as the Yankees, you might play it off and fool somebody. But when you ask me to go into a game of that sort, to be
                played with Yankees, I tell you I would rather not play it. That scene will indeed be strange, passing strange. The rights of Virginia slaveholders are invaded; the Virginia slaveholder at home is trembling under the apprehension that his rights of property will be sacrificed; and here we are, poor decrepit, impotent men, standing with folded arms, waiting for the result of a treaty
                between the slaveholding States that have seceded and the Northern States, to work out the protection of our own rights and interests! Mark how far the admission carries you. Mark how far the admission of the Committee of Twenty-one, in their report, carries them, and see where they propose to stop. You acknowledge that the Revolution has progressed so far that there has to be an
                agency empowered on the side of the States still recognizing the Government at Washington to treat with the Government at the South. That is an acknowledgment that this controversy has progressed so far that there has to be established an authority upon the part of the North to deal with the authorities upon the part of the South to establish new guaranties for the protection of the
                rights and interests of Southern men. You Virginians, you demand this negotiation. And when that negotiation commences, Virginia is to be on the Northern side.
              </p>
              <p className="p-in-sp">Sir, when you [addressing Mr. WISE]
                , when you went to Brazil, and entered upon the negotiation of a treaty between that Government and the Government of the United States, you always stood on the side of the United States, I imagine; you never told the representative of some other nation to come and represent your country in a negotiation with you and Brazil, for the protection of the interests of your country,
                against meditated encroachments by Brazil. It would have been a queer treaty for Brazil and the United States to negotiate with some nation for the protection of the interests of the United States against <PageNumber num={691} /> Brazil. Yet that is the position to which this Committee has come. They acknowledge that the controversy has arrived at a
                point where the existence of the Government at the South must be acknowledged—when they must be treated with—and yet you gravely stop while the negotiation is going on, and remain on the side from which concessions and guaranties are to be obtained. I ask you whether, having acknowledged the necessity for new instrumentalities, new negotiations, new compacts, whether, as honest men,
                having in charge the interests of your countrymen, living in this great Commonwealth, you had not better place yourselves on the side with the party holding interests in common with your own?
              </p>
              <p className="p-in-sp">
                This great controversy has reached its crisis. You recognize the necessity of regular negotiation and full and final settlement. If ever this Union is to be restored, it is to be after a new bargain between the sections, and under a new compact full of conditions for your security. The collision between the sections has become so violent—the struggle has become so vital—that, by
                general acknowledgement here, we have now to come to new terms of association, or separate forever. Then let the parties to the new compact stand honestly and fairly asunder. Let each stand for its own interest and give to its own side its full power and influence. The more powerful the party of our side the more favorable will be the terms which we obtain. If we have reached that
                point where we must have a new compact, as all now agree, let us take our natural, our honest side in the negotiation.
              </p>
              <p className="p-in-sp">
                Now, sir, that is the amount of it? What has Virginia to fear from going South under the existing circumstances? If we have to go there finally, where are we to go in the one, two or three years, which, upon the policy of this Committee of Twenty-one, it will require, to conclude the negotiations? Where will you stand in the interval? In the house of your enemies or your friends? You
                have told the people of Virginia in that report, that the House in which you now stand is utterly unfit to live in. Right across the street is one every way safe and comfortable into which you are cordially and earnestly invited. Yonder it is, with its doors all open to you. You are wanted there. Your strange reply is, I will not come until I have exhausted my appeals to the charity
                of my enemy to repair the house which I now occupy. When I find I can do no better, and not till then, will I accept your invitation. Your company is so distasteful to me I will not go to you until I am kicked out of my present dwelling. What are you afraid of? Oh it is your attachment to the Union, is it? You are afraid that something will be done that will dissolve that Union. Is
                that the principle upon <PageNumber num={692} /> which this grave assembly of Virginia statesmen is to act? Are you going to act upon that sentiment? My distinguished friend from Halifax
                [<span className="persName">Mr. FLOURNOY</span>]
                , exploded that theory the other day. He told you that it was not a question of the dissolution of the Union now—that the Union was already dissolved. That is a fact acknowledged now I believe everywhere in the world except in this Convention. I may almost allow there is not an authority on this continent, or in the world, except this able body of experienced Virginia statesmen now
                present, which does not recognize the fact that the Union has been dissolved. Yet we sit here holding tremblingly on, deeply deplore the present condition of things, and propose to retire to our chambers, mourning, sobbing, weeping like old women over this condition of things for six months, to see if, in the meantime, it will not get better.
              </p>
              <p className="p-in-sp">
                Sir, is there any danger in joining that Government down at Montgomery? I think there is danger in remaining in the other one up here at Washington. Let me ask you this one question. And here excuse for saying I only address those of you who are living men of this day and for a future day; who are young enough ever again to be candidates for public trust. The men who belong wholly to
                the past are unfit for the duties before us. It is a public misfortune that this element too strongly predominates. in this assembly and paralyzes it. But to my question. You must have money to carry on either of the two Governments under which you live. How are you going to get it? You must either get it by the direct taxation or by the indirect agency of a duty on imports. Which
                Government will be the most likely to relieve you from direct taxation by ample revenues, derived from duties on imports? Just look at the facts. I refer gentlemen, now to the cotton crop exported from the United States. According to the latest treasury return it is $161,000,000. The cotton crop used in the Northern States, according to the statistics of the Massachusetts Board of
                Trade, cannot be less than $40,000,000. They state themselves that the annual purchases of Massachusetts in cotton amount to $25,000,000, and that that is about, or nearly one-half, the entire amount manufactured in the United States; so that it is very fair to put the whole amount of consumption of cotton in the States at $40,000,000. There is not less than $200,000,000 worth of
                cotton exported from the Southern Confederacy. Your Auditor, at the last session of the General Assembly, and at this session again, has entered into calculations, founded upon such tax returns as are in his possession, showing what amount of goods are sold in the Commonwealth of Virginia, purchased from the North; for those returns do not embrace direct importation—upon which there
                is no <PageNumber num={693} /> license tax either directly or indirectly. It appears from the Auditor's Reports that the prime cost of such goods as the Virginia merchants purchased during the year before last is about $30,000,000. For the last year, according to the report upon my desk, it will probably amount to $35,000,000. You have then imported
                from the Northern States, during the last year, $35,000,000 worth of goods, at their cost where purchased, not at the retail price here. Now, sir, from an approximate estimate of the goods exported from the border States, including cotton, tobacco, and other cereal products, I have put down the amount at $100,000,000 making the entire exports from the slaveholding States not less
                than $300,000,000, a year. These are facts. Three hundred millions of exports return at least as much imports. An impost tax of ten per cent. would produce a revenue of $30,000,000, a year, would it not? Sir, living under such a confederated government you would be the lightest taxed people upon the face of the earth.
              </p>
              <p className="p-in-sp">
                That is your condition down there, sir; and that is the place to which you are afraid to go. How is it up North? They are not able to pay the men that took the census last year! Where have they the material for import taxes. Are not local factories scattered all through that country. Is not the local demand for manufactured articles supplied there by the local factories. Do you know
                how much is manufactured in Massachusetts, alone? One hundred and fifty millions is the annual value of the fabrics of Massachusetts alone; a large proporion of which are exported to the fifteen slaveholding States. Of course there is no fair expectation of revenue adequate to the wants of that Northern Government being raised by duties on imports. Their Government is bankrupt. They
                have no means of meeting their current expenses, and they have a debt of $100,000,000 besides. Do you suppose that Government will, or can, get on with an impost tax? No, sir, it cannot. It will have to resort to direct taxation to meet its own expenses. And you propose for this old Commonwealth of Virginia, now taxed 40 cts. upon every $100 and advised by the Auditor to increase
                that tax to sixty cents--I say you propose, in addition to this, to submit to a direct tax for the support of the Northern Confederacy. That is the position of gentlemen, who propose to keep Virginia in the Northern Union. Poor as we are, we must support our Yankee kin.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I merely refer to these things, I am not in a condition to elaborate them. In this connection, let me ask you was ever a people yet made the object of war by the commercial powers of the earth, while that people controlled $300,000,000 of exports? Why, sir, that very circumstance will keep the peace, not only with the Northern
                <PageNumber num={694} /> Government, but with all the commercial powers of the earth. It will not only keep the peace, but make cordial friends of them. The fact that this Southern Confederacy, when it shall embrace the border States, will have that amount of exports is worth more than all the treaties and compacts that human ingenuity can devise, to
                make peaceful friends of all nations.
              </p>
              <p className="p-in-sp">
                When a Commercial nation makes war upon such a Republic, it makes war upon its own interests. Do you think these Yankees are going to do it? Sir, I have not much faith in the sentiments of Yankees, any more than Sir Peter Teazle had in the sentiments of Joseph Surface. But I have a vast confidence in their appreciation of their own interests; and if their own interests do not make
                them the most peaceful, the most subservient and obedient friendly power upon the face of the earth, to this Southern Republic, I am vastly mistaken in estimating the influences that will control the men of the North.
              </p>
              <p className="p-in-sp">
                Now, Mr. Chairman, I am going to do what I am very reluctant to do, and what I would not now do if I were in a condition to elaborate these statistics, and to go over the ground which I went over on another occasion, in another body, in considering this subject. I hope the Committee will excuse me if I ask my friend here to read some facts collected by me on another occasion, in
                order to show to what an immeasurable extent we are able to wield power over these Northern people. As I said last winter to the Legislature, I say to you now, that we can hold them in more abject submission than you hold your own slaves at home.
              </p>
              <p className="p-in-sp">I invite your attention to some facts which I collected last winter, which exhibit our power over the industrial system of the North. I shall also repeat an illustration which I then drew from incidents in the history of our intercourse with Great Britain. Will my friend relieve me?</p>
              <div className="speaker" id="sp1831"><span className="persName">Mr. MORTON</span> then read from a speech delivered by <span className="persName">Mr. BARBOUR</span> in the House of Delegates, as follows :</div>
              <p className="p-in-sp">
                I do not mean to discuss the policy of the embargo and non-importation acts, which preceded our war with Great Britain in 1812. My only purpose now is to ascertain what was the effect of the non-importation act of the American Congress on the population of Great Britain. Here again I choose that the narrative should be made by an Englishman. Referring to the refusal of the British
                Government to ratify the adjustment made by Mr. Erskine with the American Government in 1809, Mr. Alison, in his history of Europe, says : The English ministry accordingly refused to ratify this arrangement; a resolution <PageNumber num={695} /> which, although justified in point of right by Napoleon's violence, and by Mr. Erskine's deviation from his
                instructions, may now well be characterized as one of the most unfortunate in point of expediency ever adopted by the British Government; for it at once led to the renewal of the non-intercourse act of the United States; put an entire stop for the next two years to all commerce with that country; reduced the exports of Great Britain fully a third during the most critical and
                important years of the war, and in its ultimate results contributed to produce that unhappy irritation between the two countries which has never yet subsided, notwithstanding the strong bonds of mutual interest by which they are connected.' If we take the action which I am now urging upon a Virginia assembly, some future historian of the Northern States may describe the action of the
                North towards us as the most unfortunate in point of expediency ever adopted by the Northern State Governments, and may depict in strong terms the misfortune to the North which was the result of their misconduct. But I am not yet done with Alison's narrative of the effects produced by the non-importation act of the American Congress. He attributes the general distress prevailing in
                the manufacturing districts of Great Britain, in the latter part of 1810 and 1811, mainly to the influence of the American Union protection act. He says, after stating some minor causes of distress: 'But above all, the natural irritation of the American Government at the unbounded vexations to which they were exposed by both the belligerent powers from the operations of the Berlin
                and Milan decrees, and the orders in council, had produced, on the part of the government of the United States, the non-intercourse act in February, 1811, whereby all commercial connection both with France and England was terminated; and the vast market of the United States, worth all other foreign markets put together, which took off British manufactures to the amount of above
                thirteen millions sterling, was entirely lost." The writer gives a detailed narrative of the overwhelming distress with which the British trading classes were thus afflicted. Parliament had to support them by a loan of $30,000,000. The operatives throughout the manufacturing districts were engaged in the most violent and alarming mobs and riots. They were banded together in
                systematic attacks upon the property of their employers. So great was the destruction of machines in the factories that Parliament passed an act to make it a capital offence to break a piece of machinery. Under this singular act seventeen persons were actually executed before the disturbances were arrested. Bear in mind, Mr. Speaker, that these sweeping results were produced by
                withdrawing <PageNumber num={696} /> from the English manufacturers a market which demanded only one-third of their exported fabrics, amounting to only $65,000,000 in value. I shall presently put in contrast the extent of our control over the market for the exported fabrics of manufactures in the Northern States.
              </p>
              <p className="p-in-sp">
                Let us next enquire, Mr. Speaker, into the analogy between the relations of Virginia and the Southern States with the Northern States, and the former relations of America with Great Britain. A large portion of the labor and capital of the North is wholly dependent upon Southern support. If we choose to use our privileges, we can control the Northern laborers. We hold them, sir, in
                bondage as complete as we hold our own slaves. I am not going to give any speculations of my own on this subject. I mean to let New England men describe to you the true condition of New England society. I direct your attention now to New England, because it was there that this anti-slavery agitation had its origin; from thence it now derives its strongest pabulum. New England is the
                great centre of the intellectual operations of the anti-slavery party. From that point the malign influences radiate to all points in the North. At that point the disease first appears. There is its seat. To that place the healing hand must apply its skill. If this House will heed the facts which I have to submit, they will soon know that they have controlling power over that
                population. I hold in my hand, Mr. Speaker, the fifth Annual Report of the government of the Boston Board of Trade. From this authentic source, I gather facts which strikingly exhibit the condition of society in Massachusetts : a fair specimen of all New England. I read an extract from this report:
              </p>
              <p className="p-in-sp">"Our soil and climate forbid either large or profitable investments in agriculture. In a word, we are impelled, by the very necessities of our position and condition, to continue to work cotton, wool and leather, and to build and employ ships. We cannot abandon these pursuits, because we cannot adopt any others."</p>
              <p className="p-in-sp">
                There it is. If manufactures cannot be profitably supported, the population of Massachusetts cannot be maintained. Strike down their factories and you force their laboring class to emigrate, or to go into the poor houses to sustain life. They must work cotton, wool and leather, or they must starve. If you will stop buying their fabrics of cotton, wool and leather, they will be
                compelled to stop working cotton, wool and leather.
              </p>
              <p className="p-in-sp">
                I learn, from reliable documents, that 96,000 male laborers are employed in the factories of Massachusetts, and 69,000 female laborers. The annual amount paid to these laborers is $39,700,000. The annual <PageNumber num={697} /> value of their fabrics is $151,000,000, almost the whole of which seeks its market within the United States. The whole export
                of manufactures of cotton, wool and leather, from the port of Boston, for the year 1858, was about $2,000,000. We may, then, assume that the manufacturers of New England are wholly dependent on the home market for the sale of their wares. Without going into a minute enquiry ( for which I do not possess the materials) , I can only refer to the general fact, that the local demand for
                fabrics in the Northern States is more or less supplied by local factories. The Southern demand, on the other hand, finding but little home supply, is mainly filled by Northern or foreign manufactures. I venture to say, sir, that the whole industrial system of New England would be prostrated by closing the Southern markets to their manufactures.
              </p>
              <p className="p-in-sp">
                By opening these markets to the manufactures of the North, we have built up the power which now assails us. The Southern consumer has multiplied the population of New England, enhanced its wealth, invigorated its material strength, and increased its political power. If you writhe from the sting of this serpent, you must reflect that you nursed it into life and strength. Will a
                Virginia Legislature learn anything from this statement of the Boston Board of Trade?
              </p>
              <p className="p-in-sp">"At the close of the last century, the value of real and personal estate in this city [Boston] was only seven millions of dollars; it was but forty-five millions as late as the purchase of the site of Lowell; but it is now two hundred and fifty-eight millions. Can any one doubt that the profits and incidental advantages of working cotton, wool and leather are among the prominent causes of this immense increase?"</p>
              <p className="p-in-sp">
                The value of real and personal property, thus mainly built up, in the city of Boston, by working cotton, wool and leather, is nearly equal to one-half of the assessed value of all the real and personal property of this Commonwealth. The sustenance of that growth has been mainly drawn from the profits of the Southern markets. But, sir, this report goes into a detailed account of these
                manufactures. The facts are so suggestive that I must venture to tax the kind attention which I am receiving from the House by another extract or two:
              </p>
              <p className="p-in-sp">"The boot and shoe trade to-day constitutes the largest manufacturing interest, not only of Massachusetts, but of the whole country. * * Since 1837, the trade has rapidly increased in Massachusetts, and at the present time amounts to more than fifty millions of dollars per annum."</p>
              <p className="p-in-sp">
                I have seen statements in the newspapers that one eighth of the <PageNumber num={698} /> whole population of Massachusetts is supported upon the boot and shoe trade. Its chief seats are at Natick and Lynn. Perhaps there are no such hot-beds of abolitionism in the whole North as Natick and Lynn. Why, sir, do you recollect that infamous resolution upon
                which a Senator of Massachusetts was recently arraigned for allowing it to be passed in his presence without rebuke? Shortly after the foray of John Brown, the operatives in the boot and shoe factories of Natick unanimously resolved that it is the right and duty of the Southern slaves to rebel, and it is the right and duty of the Northern people to encourage and support them in doing
                so. I am not sure, Mr. Speaker, that many of those who are now listening to me have not on their feet at this moment, boots or shoes made by these bloody propagandists of the Natick shoe factories. Do I make a vain appeal to a Virginia Legislature when I implore them to provide, by law, that no Virginia encouragement shall hereafter be extended to that nest of pestiferous vipers? I
                wish to provide, by act of Assembly, that these manufacturers and operatives shall never derive one dollar of profit from the people of Virginia. I wish to put the people of Natick and of Lynn in the condition of the trading interest of England in 1765. I wish to lay them "under the most dreadful anxiety they ever felt."
              </p>
              <p className="p-in-sp">
                This report of the Board of Trade, referring to the cotton manufacture of Massachusetts, says: "How different to-day the statistics of Massachusetts from those of New York, New Jersey, or Pennsylvania. Within this Commonwealth are two hundred and ninety-four factories, which employ an invested capital of thirty-two millions of dollars; which contain one and a half millions of
                spindles; which require an annual supply of one hundred and six millions of pounds of cotton; which weave three hundred and fifteen millions yards of cloth, and which put in market, fabrics, yarns and other manufactures, worth thirty-four millions of dollars yearly."
              </p>
              <p className="p-in-sp">
                Again, it says : "And the statistics of all New England, how clearly do they prove that supremacy in manufactures is naturally at the North. More than half of the cotton factories in this hemisphere are within her limits, and more than two-thirds of the invested capital belongs to her. Her annual purchase of cotton on an average of years, is five hundred thousand bales, for which she
                pays twenty-five millions of dollars when the price is ten cents the pound. Her mills turn out one-twelfth part of all the cotton goods that are made in the world." And then the report enters into an elaborate argument to show that the fashionable prevailing doctrine of the South, that they who control the cotton of the world, control the affairs of the world, is
                <PageNumber num={699} /> fallacious, and that it is the manufacturers of cotton fabrics who control the commerce of the world. That is what they say, sir.
              </p>
              <p className="p-in-sp">
                The report proceeds: "In the history of a nation, an hundred years is a brief space, for it barely marks the extreme limit of human life. But within a century the cotton manufacture in England was in its infancy. Nothing else, now, in the whole range of the industry of man, equals it in magnitude. England commercially considered, is preeminent among the nations of Europe,
                principally, because she moves twenty millions of cotton spindles; because she works eight hundred and fifty million pounds of the raw material in a year; because of the three hundred millions of dollars of fabrics and yarns annually made, and of one hundred and ninety millions annually exported. In point of value, she makes and vends one-half of all the cotton goods manufactured in
                the world, and hence wields a moiety of the power which cotton gives in the management of the world's affairs."
              </p>
              <p className="p-in-sp">
                They further refer to the influence of cotton by stating: "We have referred to Liverpool, and to Bremen and Hamburg, to illustrate the wealth to be acquired in the transport and factorage of cotton, and to several countries in Europe, and to England especially, to elucidate the wonderful, social and political influence which the making and vending of cotton confers upon nations."
              </p>
              <p className="p-in-sp">
                There is what these people say : That their present prosperity—their prospects in the future—are wholly dependent upon the success of their efforts to manufacture for as large a portion of the world as possible, cotton, wool and leather. And, at this very instant, whilst they are thus declaring their purpose to build up their power, to swell their importance, and increase their
                wealth, thus gaining greater power to strike at your interests—a power to be acquired by keeping open to the largest possible extent, the largest markets for their cotton, wool, and leather fabrics—I say, at this instant, while all this is seen, the people of Virginia are asked whether they will import these goods, or whether they will cripple that hostile power by proper and lawful
                means. They can hardly hesitate, in view of all the facts, to choose the latter alternative. They have as many white people as we have now. Let the present condition of things continue, and they will have twofold advantages; or, to use another expression, they strike two blows at your industrial interests. As their manufactures increase ours will diminish, because of the
                impossibility of our competing with them. They have already driven your mechanics from your midst. Let them go on, year after year, and soon we shall be left without a tradesman of any kind, and reduced to a condition of entire dependence on the <PageNumber num={700} /> North. They are going on, driving your white labor out, while applying themselves
                vigorously to the task of driving your black labor from your midst; so that, with powerful force, they are striking double blows at you.
              </p>
              <p className="p-in-sp">
                In view of this determined purpose to crush the South, is the Virginia Assembly to hesitate whether they will make the most stringent efforts to check this dangerous state of things, and to restore the old condition of peace? This can only be done by assuming a position of independence, and thus inspiring that regard which the opposite condition never begets. It is only by being
                independent that we can command the respect of a mercenary horde like those who oppose us.
              </p>
              <p className="p-in-sp">
                Sir, we are told that Virginia does not possess the power; that a Virginia market is a most insignificant and contemptible thing, not worth the efforts of a wise and statesman-like policy to strike at. Sir, you have an Auditor who, for the first time in my knowledge of your public documents has labored through the details of his office, and given us one of the most valuable
                statistical documents that I have seen. Particularly is it important at this time, when we are undertaking to reach out on this great design. Why, sir, our Auditor tells us, upon the basis of your tax returns, that your merchants annually sell, for consumption in the State, forty-two million dollars worth of goods. Recollect what I read to you from this book
                [Alison's History]
                , that the withdrawal of a market with a capacity of sixty-five millions of dollars worth of manufactured fabrics, prostrated the whole industrial interests of England. Sixty-five millions of dollars annually, was the capacity of the American market in 1811, and the withdrawal of that market from the consumption of British fabrics produced the degree of distress which I have already
                described to you.
              </p>
              <p className="p-in-sp">
                Now, sir, we sell forty-two millions of dollars worth of goods in this State annually. Where does that come from? Sir, this amount does not embrace the direct importations. Small as they are, they do not enter into the forty-two millions, because they are expressly exempted by your laws from taxation; so that the forty-two millions is the amount of goods not directly imported from
                abroad. The sales of all your merchants who sell more than $40,000 worth of goods annually, amount to twelve millions of dollars. Of course this includes the whole class of wholesale merchants, as no wholesale merchant sells less than $40,000 per annum. This, however, embraces all that they sell to merchants in North Carolina and Tennessee. These estimates are based upon the tax
                returns; and they are always under the mark by at least 25 per cent, inasmuch as the merchants themselves fix their <PageNumber num={701} /> own estimates, so that the excess which we will allow for that purpose, will equal the amount of double sales by wholesale; so that we can fairly assume that the amount of goods sold in this State annually is not
                less than forty millions of dollars. And if you allow 331/3 per cent profit, it will leave you about thirty millions of dollars as your annual purchase from other States for consumption in this State. There is your market, sir. I am putting it lower than the gentleman who presented the estimates upon which mine are, in a great measure, based. I am putting it ten millions under his.
                But, sir, I put it where it can safely be put, and assume that you annually expend beyond the limits of this Commonwealth not less than thirty millions of dollars in merchandize consumed in this Commonwealth.
              </p>
              <p className="p-in-sp">
                I now tell this House, that there is the capacity of your market $30,000,000—yes, $30,000,000 carried every year beyond your State. Your exports from the State are $35,000,000. The exports, according to all sound writers on political economy, are the measure of imports —and your exchanges and bank returns show you that there is over that amount of mercantile transactions between this
                and other States ; so that, I assume, you have not a market of less capacity than $30,000,000. And where does that go? Where ought it to go? It ought to remain here at home. Where does it go now? It goes to support the manufacturing and the commercial interests of the Northern States—it goes to support this Massachusetts policy—to support the New England manufacturers. There is where
                it goes! The Federal Government, in 1811, by its non-importation act, struck home a fatal blow at the manufacturing interests of England, with a market of only $65,000,000 capacity. Virginia, at this day, has a market that actually consumes $42,000,000 worth of goods. She actually opens to the States outside of her limits a market to the extent of $30,000,000 in hard cash. Yet
                gentlemen sneer sometimes at the thought of accomplishing anything practical by so insignificant an instrument as the Virginia market. They cannot move until the whole South agrees to the system. The South cannot adopt it except by the separate action of their several Legislatures. The works must commence somewhere. Where better than Virginia? If she leads the others must follow.
                Why, sir, if I could not do better, I would be glad to see one county, aye, single families and individuals, commencing the system.
              </p>
              <p className="p-in-sp">
                Have you undertaken to contemplate what a vast influence upon the industrial interests of this Commonwealth would be operated by expending for even one year among our own people thirty millions of dollars? Why, sir, we appropriated at the last session of the Legislature <PageNumber num={702} /> some three or four millions of dollars for works of
                internal improvement, and the incidental benefit resulting from the expenditure of that much money was beneficially felt throughout the Commonwealth. I know that the incidental advantages resulting from the expenditure of so much as went to the Alexandria improvements were seriously felt and acknowledged by the people. What then would be the effect on our internal interests to retain
                for home expenditure thirty millions annually. You would create employment for your white labor, which would restrain it from emigration. You would give your own mechanics a monopoly of your own market. The Yankee fabrics have driven your country mechanics from you. This bill will create a demand for the labor of white mechanics and artisans. What if it does raise the price of some
                of the fabrics generally consumed. The property holder can afford to pay the little increase of price when he obtains the advantages of protection to his interests which this measure secures. The property holder can afford encouragement to white labor when the white laborer is needed to aid in the protection of property. This State must gather all her energies, collect her resources
                and improve all the elements of her power. She needs population as an element of power in the federal government, as a means of defence under the State government. We must, at any sacrifice, therefore, retain our people at home. Give employment to labor even at the expense of a little burden to property. Protect your own mechanics and artisans, and your own merchants from Northern
                competition, and you enhance your own strength and power, just as fast as you reduce that of your adversary. Let us gather our imperial resources, and exhibit the imperial strength and vigor befitting this ancient Commonwealth in the great struggle in which we are involved, and from which we cannot retire. While resolutely protecting slave labor, let us also give the fullest
                encouragement to the labor of the mechanic and the merchant and the artisan in our midst. Let us have the fabrics which we necessarily consume, manufactured or imported by our own people. Let the thirty millions of dollars which we annually expend in merchandise be poured through Virginia channels. When we build up commerce, manufactures and trades, the accumulated wealth and
                population will be a source of strength and safety which will be cheaply purchased at the cost of any burdens which this system may temporarily occasion.
              </p>
              <p className="p-in-sp">
                The whole industrial system of Massachusetts and all New England depends upon the support of the South. I was in favor, last winter, inside the Union, of making them feel their commercial dependence <PageNumber num={703} /> upon us; but that is gone, and you can now only make them feel it by a Union of the whole South outside this Northern Confederacy.
              </p>
              <p className="p-in-sp">
                I say, that when you come to make them feel their dependence for subsistence upon their peaceful behavior towards the South, you have a sufficient guarantee of that good behavior. You have them, therefore, entirely dependent upon you. Now, what will you do with them? Wait till they treat with you? Will you hold off dilly-dallying, playing fast and loose, until they find whether they
                can crush out this Southern movement? If they do crush it out, you submit. If they do not crush it out, they, your Northern confederates, negotiate terms, not to suit you, but to suit them. Try the experiment for two or three years, and you will find they will then be sufficiently watchful to make terms with the Southern Confederacy to suit the Yankee, and leave Virginia out of
                consideration. They will be the party to make the final treaty, and not you. You can now control it. Omit this chance, and who gains? Wait, standing out, one, two or three years, and when the expectation of Southern ruin fails—if it does fail—not you, but Yankeedom walks in and makes its own terms, for its own benefit. Virginia loses the whole benefit, which a masterly course would
                now gain for her people. That is supposed wise Virginia statesmanship! That is our smart playing. Really, sir, the intelligence of this assembly will be exalted in history, if that is the part we are to play in this drama. You are to wait, and let Yankeedom treat with the Southern Confederacy; and when you reach the final termination of these great events, you are then to come in and
                take such terms as Yankeedom may permit.
              </p>
              <p className="p-in-sp">
                Now, sir, what is the question you have to settle? I do not care what course you may take now you are bound ultimately to go into that Southern republic. You may go now on your terms, otherwise the Yankees will carry you on theirs. Two hundred millions of export in the hands of that government is a commercial prize which is an inducement that will command success in treating with any
                nation in the civilized world. Yet you swing yourself to the tail of the Yankees and you will go there finally and go whenever the Yankees see it is their interest to carry you there on their and not your terms. Are you not bound to go? Do you suppose that these Yankees controlling the Northern Government are going to separate themselves, by war or by anything else, from the vast
                interest in the Southern Confederacy? Never, sir, never! It will end in their making terms with that republic, and terms that you will have no voice in dictating. Refuse to control your own destiny, and the Yankees will control it for you.
              </p>
              <PageNumber num={704} />
              <p className="p-in-sp">
                That is the condition now of that Southern Republic, and what will be the position of Virginia in it? At the ratio of apportionment they have fixed, you would have 21 representatives in their Congress, and they 68. Virginia would control almost one fourth of the entire vote in their lower House of Congress. Where would she stand in the Northern Republic? She stands now with 13
                Representatives. Under the new apportionment she will have about 11 in the Congress of a Northern Confederacy with 150 members. Will not that be a large representation in a Confederacy hostile to your interest? Every principle of that Government, every sentiment will be at war with your interests. And the entire border States, to which so much importance is here attached, stand in
                the next Congress with about 54 out of 150 representatives. There is your representation. All the border States put together will not hold the same relative power in the Northern Confederacy that the State of Virginia alone would hold in the Southern. There is a material fact for every Virginian, honestly seeking the truth. Where will you, under this state of facts, place your
                constituents? In a hostile confederacy in which your power will be but 11 out of 150, or in a friendly confederacy where it will be 21 out of 89? Under which Government are you going to trust the Old Dominion?
              </p>
              <p className="p-in-sp">
                Do you not think that the people of Virginia are ready for the question? I know the people intimately, and I tell you that if you think they are unprepared for the question, you make a great mistake. The people of the Commonwealth have as quick an appreciation of events as the members of this Convention have. Many here say that though they consider the State should secede, still the
                people do not look on it in that light. Many a man here says: "I am so much smarter than the people; I know that it is all right to take Virginia out of the Yankee Union, but the people—the uninformed people—poor ignorant creatures who sent me here, do not see it, and we must go through a good deal of foolery in order to satisfy them." The class, of all others, most interested in so
                acting as to promote the general prosperity, is that very laboring class which you wise statesmen are not willing to trust. If they are not quick enough to catch up with the events of the day, and to appreciate them truly, I am greatly mistaken about it. Put the proposition to them whether they will have their destinies placed where their interests will be the great object of the
                Government, State and Federal, or whether they will have their interests confided to a Government whose great object is to destroy them, and they will not be long in deciding that question. If that be <PageNumber num={705} /> the kind of constituency whom you represent, you ought to go to work immediately—especially those of you who think the Convention
                was brought here to propose organic amendments of the Constitution—and amend that part of the Constitution which regulates the right of suffrage, and place the Government in the hands of those who do understand their interests. If the people are as ignorant and as incompetent as you suppose them, they are wholly unfit to control their own Government.
              </p>
              <p className="p-in-sp">
                The people sent you here to examine into our national difficulties. They placed power in your hands. They sent you to stand here sentinels on the watch-tower to tell them what of the night. And yet you blindfold your eyes and propose to go back and tell them you are afraid to dissolve the Union until you know what the other border slave States think of it. What did the people send
                you here for? Was it that you might just stay here and draw your $4 a day? Was it that you might fold your arms, seeing and acknowledging what ought to be done, and yet refusing to do it because you are afraid the people have as much sense and knowledge as you? If that be so, they might as well have kept you at home. Men of energy, nerve and character will always do what they think
                to be right. I have had some little experience among the people; I know them and I know that the safe rule always is to do that thing which your conscience and your judgment approve —and the people will stand at your back as certain as fate. A representative of the people must take it for granted that the people have as much sense as he has and that what he sees other people will
                see.
              </p>
              <p className="p-in-sp">
                Now, what is the next proposition? Not to place your people under a Government that it will be safe for them to live under. Oh, no. Wise, astute, experienced statesmen, you discover that we are living under a system which it is utterly unsafe to trust; but at the same time you discover that you are utterly unfit to make a better one. And what do you propose? You tell your
                constituents that they are living under a Government which threatens to make effective war upon their interests. You say to them, Poor, unfortunate constituents, you are in a bad way. We deeply deplore it. But you made a most awful mistake when you thought we could propose any remedy. We found, when we got together, that we were unfit for the business which we were sent to perform,
                and so we thought we would appeal to other States for help. Old Virginia has gotten down so low that she cannot find a body of men within her limits to deal with great events. And, therefore, it is proposed to get up a Border Conference. Are not the <PageNumber num={706} /> destinies of Virginia safe in the hands of 150 of her picked men? Oh, no. We
                must send out to Missouri, and to Delaware, and to Maryland, and to Kentucky, and to North Carolina, and find material outside the limits of the old Commonwealth, to see what will promote the interest of the Commonwealth of Virginia. In a condition of public affairs without a parallel in the history of the world, without a precedent in the experience of the Government, all that you
                have been, thus far, able to discover, is that you are utterly unfit to discover a remedy for this grave emergency, utterly unworthy the high place you fill. And what do you propose to do?
              </p>
              <p className="p-in-sp">
                You cannot trust 150 picked Virginians. You know that they were selected by the people to act for the public interest, and yet when you get here you find yourselves unfit to act, and propose to send to other States to supply a deficiency in the intellectual material of the Commonwealth. The question is what we shall do to protect our slave interest in Virginia, and you ask Delaware
                to send somebody to advise with you as to what you shall do with your 500,000 slaves. What does Delaware know about it? There are not one-fourth as many slaves in the whole State of Delaware—which is to stand in the proposed Conference equal with the Commonwealth of Virginia—as there are in the single county which I represent on this floor. Delaware has 1,798 slaves, and her idea
                about taking care of the institution of slavery is to let Black Republican politicians attend to it. She has elected a Black Republican member of the next Congress, and her policy is a gradual extermination of slavery within her limits. Delaware consists of three counties, and nearly all her slaves, I am informed, are comprised in one county.
              </p>
              <p className="p-in-sp">
                And this is one of the States which you propose to ask to send somebody to tell you what you are to do with your 500,000 slaves. That is wise statesmanship, is it not? You know what a parade among the Virginians there was to pick the very best men they could find to send here—and I am sure we, each of us, consider they succeeded—and when the picked statesmen get here, we have to send
                to Delaware to get somebody to come and tell us what we ought to do. Why, I represent a people owning about four times as many slaves as there are in the whole State of Delaware. And yet, Delaware is not only to weigh down my county, but my whole State; and she elects to Congress a Black Republican.
              </p>
              <p className="p-in-sp">
                Well, what next? Maryland has 599,000 white people, and 87,000 slaves; that is, there are seven white men in Maryland to one slave. And we ask Maryland to send some one to advise us what to do to <PageNumber num={707} /> protect our half million of slaves. Well, how is it in that great State of Arkansas—a cotton State—whose hesitation about quitting
                this Union was handled most cheerily by gentlemen here? She has 300,000 white people, and 111,324 slaves. The whites there are three to one. Missouri, with a million of whites, has 114,000 slaves—the whites being as nine to one. And her slave population, as is suggested to me, is congregated in about twenty counties. So it is, too, with all the border slave States except North
                Carolina.<span className="note" id="Note73"><span className="noteLabel">1</span>The differences between statistics of population in the lines above, and those in the tabulation below, are not explained.</span>
              </p>
              <p className="p-in-sp">
                Mr. President, will this Convention close its eyes to the astounding fact that when we get into that border State Conference, Virginia and North Carolina will own one half of all the slaves in the States represented there? And yet they will hold but two out of the eight votes in that Conference. Virginia will have one third of all the slaves represented in that border Conference,
                while she will have only one of the eight votes. Are you willing to submit to that?
              </p>
              <p className="p-in-sp">
                Yes, Virginia with her five hundred thousand slaves would stand there with only as much power as little Delaware would have with her 1798 slaves. Is that the way in this great crisis that the wise men of Virginia propose to take care of the slave interests of the State? Sir, we are asked to turn our backs upon these seceded States with interests like ours. We are told they acted
                precipitately and without consultation with us. Let us see where we are to go in order to protect this property best.
              </p>
              <p className="p-in-sp">When we come to enquire, we find the large negro-owning Commonwealths all outside of the Union. In the seceded States there are a little over two and a half millions of whites and a little under two and half millions of slaves. I append a statement:</p>
              <p className="p-in-sp">BORDER STATES</p>
              <p className="p-in-sp">1860.</p>
              <p className="p-in-sp">States. Free. Slave. Total.</p>
              <p className="p-in-sp">Delaware, 110,420 1,798 112,218</p>
              <p className="p-in-sp">Maryland, 599,846 87,188 687,034</p>
              <p className="p-in-sp">Virginia, 1,105,196 490,887 1,596,063</p>
              <p className="p-in-sp">N. Carolina, 661,586 331,061 992,667</p>
              <p className="p-in-sp">Kentucky, 930,223 225,490 1,155,713</p>
              <p className="p-in-sp">Tennessee, 834,063 275,784 1,109,847</p>
              <p className="p-in-sp">Missouri, 1,058,352 114,965 1,173,317</p>
              <p className="p-in-sp">Arkansas, Total, 324,323 111,104 435,427</p>
              <p className="p-in-sp">5,624,009 1,638,277 7,262,286</p>
              <PageNumber num={708} />
              <p className="p-in-sp">SECEDED STATES.</p>
              <p className="p-in-sp">States. Free. 1860. Slave. Total.</p>
              <p className="p-in-sp">South Carolina, 301,271 402,541 703,812</p>
              <p className="p-in-sp">Georgia, 595,097 462,230 1,057,327</p>
              <p className="p-in-sp">Florida, 78,686 61,753 140,439</p>
              <p className="p-in-sp">Alabama, 529,164 435,132 964,296</p>
              <p className="p-in-sp">Mississippi, 354,699 436,696 791,395</p>
              <p className="p-in-sp">Louisiana, 376,913 332,520 709,433</p>
              <p className="p-in-sp">Texas, Total, 420,651 180,338 600,989</p>
              <p className="p-in-sp">2,656,481 2,311,210 4,967,691</p>
              <p className="p-in-sp">
                Are you going to the border States? Why, why, why, go there, sir? There is something beyond the surface in this matter, as you will find, if you look dispassionately into the subject. In the border States slaves to whites about 1 to 4, in seceded States about equal. The institution of slavery is assailed every where—all the world is making war upon it. Here are two classes of people
                on either side of you, and it is evident you must join one or the other. Which will you join? Those people down yonder holding an interest in the institution of slavery of more than the same proportion as your own people. You say in reference to them, we shall have nothing to do with them—they are degraded traitors; but you go here to the border States and express a readiness to
                unite with them. What is to come of that border State Conference. If the greatest Abolitionist in America would apply himself to the task of devising a plan to injure the institution of slavery, he could have hit upon no better plan than that of entering a wedge between the border States and the seceded States, such as must be the effect of the plan which the Committee on Federal
                Relations proposes. Divide and conquer—that is the great maxim of an enemy. The North can do nothing with these fifteen slave States united. Drive the wedge between them, separate them, organize them into separate Governments, and make them colliding and warring organizations, if you can; and then, I ask, what becomes of the slavery interests? It is, as I said, the very best device
                that could be hit upon, to effect their ultimate ruin; and yet this is the effect of the policy of the Virginia Convention. Are we going to do that because, as you say, you can't trust the people of the seceded States? You, the wise men of the Commonwealth, are going to inaugurate this policy of driving a wedge between the border slave States and the seceded States, because forsooth
                of some groundless <PageNumber num={709} /> distrust of your people to see and appreciate what you see and appreciate. You go into this Conference. In case you shall not get what you want, you will erect a new Government. Then you will invite the North and the South together to come and join you in it! Here in the South is a Government in full working
                order, strong, powerful, and efficient, which at this instant may be said to be one of the most successful governmental institutions in the world; and you tell me you cannot find safety there. Oh, no, you want to organize another Government. These people of the Old Union are so tractable, so amenable to the will of Virginia, that when you establish your Government, all will come into
                it, the North and South alike.
              </p>
              <p className="p-in-sp">I impute no hypocrisy to the gentlemen of that Committee, but I have a right to argue as to the effect of their conduct.</p>
              <p className="p-in-sp">
                I beg leave to call the attention of this assembly to the injurious effect of your action here. I tell you that if your purpose was to destroy the institution of slavery in this Commonwealth, you could pursue no policy better calculated to effect that object than that laid down in the report of the committee. One of the shrewdest and smartest men in this Union—I refer to Wm. H.
                Seward—has declared the best mode of accomplishing the final extinction of the institution of slavery to be this line of policy which we have now before us. How long is it going to take you to go through all this formula you propose? Who is going to appoint these conferees? I want my fair-minded friends to observe what I am about to state. Who is going to appoint these conferees?
                This sovereign assembly of Virginia assembled here to take care of the slave institution of the State appoint your men; and the Black Republican Governor of Maryland—Governor Hicks—makes appointments in behalf of that State. You, representing the sovereignty of Virginia, place the voice and power of Virginia there, and you call upon the Abolitionist Governor of Maryland, to come and
                balance, weigh down Virginia, by his own individual power. Virginia in the Conference counts one; Maryland's Governor, Hicks, counts one. A sovereign Convention of the people of Virginia counts one, and the Black Republican Governor of Maryland counts one. The abolition Governor Hicks of Maryland poises Virginia. Sir, it is all the world to nothing. That is your mode of rescuing your
                institution of slavery in Virginia from the great perils which now environ it.
              </p>
              <p className="p-in-sp">
                But, sir, who are the parties suddenly seized with this passion for Conferences? Precisely that class of gentlemen who most applauded me and my associates in the Legislature last winter, for defeating a similar conference. A conference with the slave States was horrible, <PageNumber num={710} /> but a conference with seven partially slave States, with a
                strong infusion of Black Republican influence, is admirable to these gentlemen. Last winter this class of gentlemen may have apprehended that a conference would unite the South. They opposed it. A conference such as is now proposed promises to divide the South. These gentlemen support it. I opposed it then, as I oppose it now, because these conferences are utterly impotent for good
                and can be only useful to accomplish mischievous purposes. These gentlemen who have condemned the action of the General Assembly last winter, on that conference subject, have wholly misapprehended that action. I thought I knew what I was about then, and I took care to record the object and the purpose of Virginia in the action then taken. I have the resolutions of the General
                Assembly of Virginia now before me. After expressing our "lively gratification" at "this renewed manifestation of that fraternal regard and affection which has heretofore characterized, and we trust will always continue to characterize, the relations of South Carolina and Mississippi," with Virginia, we "Resolved, That the General Assembly of Virginia recognizing in our present
                relations with the non-slaveholding States an imperative necessity for decisive measures, does not yet distrust the capacity of the Southern States, by a wise and firm exercise of their reserved powers, to protect the rights and liberties of the people and to preserve the Federal Union. For this purpose we earnestly desire the concurrent action of the Southern States. But the General
                Assembly respectfully submit, for the consideration of South Carolina and Mississippi and all our sister States of the South, that efficient co-operation will be more safely obtained by such direct legislative action of the several States as may be necessary and proper, than through the agency of an assemblage, which can exercise no legitimate power except to debate and advise."
              </p>
              <p className="p-in-sp">
                What is here announced by the Virginia voice? First, that we recognize in our relations with the Northern States an imperative necessity for decisive measures. The decisive measures to which we then referred was that system of commercial regulations over Northern trade which some of us in vain pressed on the General Assembly. Virginia then declared the imperative necessity for
                decisive measures, and afterwards shrunk from the adoption of decisive measures. In that abandonment of their duty, do I read the causes of the present deplorable state of our public affairs. We further declare the earnest desire of Virginia for the concurrent action of the Southern States. Do we still desire the "concurrent action of the Southern States"? Can
                <PageNumber num={711} /> you obtain it in this Northern Union? You do know that you cannot expect it. Seven of the heaviest slaveholding States in the Union have taken action—"concurrent action." If Virginia still "earnestly desires the concurrent action of the Southern States" she must go with them or falsify her public theories of last winter. You
                proclaimed the necessity for action—"concurrent action." You failed to act; the others have acted. You in the third place declared last winter that efficient co-operation of the South could be more safely obtained by the direct action of the several States, "than through the agency of an assemblage which can exercise no legitimate power except to debate and advise." Virginians then
                declared that a conference, such as then proposed and now proposed in this assembly, was wholly untrustworthy and unfit for use. It was an assemblage which could only debate and advise. In this great emergency of our public affairs she turns right around and gathers just such an assemblage now to "debate and advise" her people what to do in the midst of the great events which
                surround us. Sir, I stand where I stood last winter, when I aided in the Virginia declaration that such an instrumentality was too small for great purposes—while I discarded it because it was a mere machine for talking, not acting. There is the Virginia recorded judgment of the instrument which Virginia now proposes to use as her only safe agency in this great crisis. That conference
                will do to amuse the people. It is an old woman's device—possibly in full accord with the characters of this assembly.
              </p>
              <p className="p-in-sp">
                Sir, viewing the subject as I have presented it, if you wish to abolish slavery, you could establish no abolition aid society that could devise a more successful scheme than that suggested by the Committee. What do you propose to do? You propose to issue your declarations to your constituents, that they are under a hostile and untrustworthy Government, and then you tell them that
                they must wait in that situation one, two, or three years before they can look for a remedy. In the mean time, by way of reconciling them to this condition of things, and by way of amusing yourselves, for want of something to do, you propose to raise the taxes on negroes. The people sent you all here to do something for the protection of slave property in its present trials and
                perils; but instead of doing so, you propose to oppress them with taxation. Could you find a more efficient instrument in any abolition aid society than this Convention furnishes in the whole programme which it lays down here?
              </p>
              <p className="p-in-sp">Now let me say a word to my friends from the West.</p>
              <p className="p-in-sp">Mr. Chairman, I do not choose to state facts here except upon <PageNumber num={712} /> official authority, and I addressed a note to the Auditor to give me the facts which I shall now produce.</p>
              <p className="p-in-sp">There are in this Commonwealth 273,170 taxable slaves. They pay $327,000 into the State Treasury every year. Well, now, don't you think [addressing himself to <span className="persName">Mr. HALL</span>, of Wetzel]
                , that you would be doing an unkindness to your people of Wetzel, if you were to return home and say to them, fellow citizens, I went down there to Richmond, and I found a good many wise men in that Convention, but after staying there several weeks, I found the best thing I could do for you was to scare the negro owners so badly that they are taking their negroes away to some
                Southern region, and I think that in four or five years there will be few, if any negroes left in the Commonwealth. Sir, you will lose for your tax purposes not only the slaves which you drive away, but you will lose infinitely more. When you rid the Commonwealth of this species of property, you diminish the appreciable value of all other descriptions of property in the State. By the
                action of this Convention you drive off from your Western constituents a property from which they now derive $327,000 annually, and which must necessarily be made up from other sources.
              </p>
              <p className="p-in-sp">Sir [referring to <span className="persName">Mr. TURNER</span>, who intimated that he would prefer to keep the negroes]
                , you can well say, when you go back, fellow citizens, I would not let them part with this negro property, because I want to keep it there to pay taxes. I would not destroy the material interest of Eastern Virginia, for I found it to be your interest to preserve it. I found you had upon you a debt of $40,000,000 which had to be paid by taxation, and I thought the best thing I could
                do was to maintain the capacity of the people of Eastern Virginia to pay taxes and assist in discharging this heavy debt. The gentleman
                [<span className="persName">Mr. TURNER</span>] , wants the Eastern men to take the State out of the Union in order to make the slave property profitable to them, as a source of taxation.</p>
              <p className="p-in-sp">
                You are interested in enhancing the value of this kind of property as every good citizen of this Commonwealth is interested in enhancing the general prosperity of the Commonwealth. We are all citizens of one Commonwealth, and whatever injures one member of the family injures the whole. What is your condition and where do you stand? Are not your 500,000 slaves in danger? Where is
                there a man upon the other side who dare rise upon this floor and say that the property in these 500,000 slaves is not this day in danger? Who is willing to leave these slaves under the government of Mr. Lincoln as it is, without a new constitution? No man has yet lifted up his voice in this Convention <PageNumber num={713} /> and declared his
                willingness to do so. And yet, leaving them in danger, you propose to adjourn over for six months and then conclude what you propose to do. With this much of the property of Virginia—of Western Virginia as much as Eastern Virginia—in danger, not from foreign countries or outside foes, but from that government which, according to the opinions of these gentlemen, is instituted for the
                benefit of those over whom it rules—you propose this policy of delay and inaction. I shall not again occupy the time of the Convention, and I simply desire, in conclusion, now to ask the gentlemen assembled here whether they are prepared to leave the Commonwealth of Virginia without a government; whether they have the manliness and the nerve with their own hands to build up a
                government and provide security for a people who, as you have announced to the world, are utterly abandoned by their own existing institutions? You yourselves announced that the existing government is utterly worthless, and you yourselves announced that if proper guarantees were not granted you, you would resume your original rightful powers. Now, the only distinction between
                gentlemen here is, that one class go for secession, because they find their people oppressed; the other class go for secession, because the North will not give an authentic answer to unauthorized questions. When you go before the people, which will stand the best chance of success? They say, in the majority report, that, if the North do not answer these questions satisfactorily, that
                it is their determined purpose to resume their sovereignty. That is the only distinction between the two classes in this Hall. One says I am for secession, because it is for the interests of my constituents to be for secession. The other says we expect in a short time to be for secession, if we cannot get the North to give good answers to some very wise questions which we have
                propounded. They are going to secede, not because they are injured, but because the North will not answer the questions which they put to them. Your Legislature declared that this Peace Conference should be your final effort. That Peace Conference has adjourned and gone. Nothing has come out of it. I see one great fact staring me in the face : that the Peace Conference did not secure
                amendments to the Constitution. There is no man here who will deny that fact. Did that Peace Conference get any amendments to the Constitution? I do not care whether they wanted a good or bad one. What I say is, that they did not do it. There is one other fact: that, down yonder at Montgomery is the establishment of a prosperous, powerful and successful government. These are the two
                facts that stare me in the face. The Peace Conference <PageNumber num={714} /> at Washington did fail, and the government at the South did not fail. These are facts, and any gentleman who will investigate the subject as I have, will arrive at the same result that I have. I cannot close my eyes to these facts. The Peace Conference did fail to secure
                amendments to the Constitution of the United States, but the Southern Confederacy did not fail to establish one of the best governments on earth. Now you propose to discredit the declaration of the representatives of the people of Virginia? However feeble they may have been, however incompetent they may have been, if they were not sufficient for the crisis, whose fault was it? It was
                the fault of the people that they had not sent better representatives to the Legislature of Virginia. That Legislature, chosen by the people of Virginia, declared for the Commonwealth. "Where is Virginia's last final effort to restore this Union." Now comes this bran-new set of Virginia representatives, and the first thing you do is to declare that the representatives of Virginia
                don't express the voice and the will of the people; and therefore what you say must be listened to. You declare that the people of Virginia did a very foolish thing when they sent such a ragamuffin set over here to the Capitol, to make any such declaration for them; but here are the simon pures—here are the picked men—and yet we follow the example of the Legislature of Virginia, and
                declare that this is to be our very last. It is very much like the play-bills, when some favorite performer is engaged. They announce for one night his last appearance, the next night "positively his last appearance," and the third night comes the benefit. I suppose as old Virginia has performed her last appearance you want now a better set of men to make her appear on positively her
                last appearance, and after both of these are over, I suppose we will have a benefit night.
                [Laughter.]
                Who is going to believe a Virginia declaration about your determined purpose, when you discredit what two months ago Virginia proclaimed—that the Peace Conference was her final effort for the Union. You come forward now and declare that all you have got to do is to play a little longer. Every time the play bill comes out, you are going to play thunder the next time. Rolling up your
                sleeves and spitting on your hands, you are going to knock somebody down, bye and bye, and when the time comes you will postpone a little longer, and then, in place of a fight, wind up with a foot-race. For one, my judgment is, that the effect, whatever the design of the action recommended by your committee, is to bring discredit on this Commonwealth. I, therefore, give it my earnest
                opposition.
              </p>
              <div className="speaker" id="sp1832"><span className="persName">Mr. TREDWAY</span>, of Pittsylvania, addressed the Committee as follows :</div>
              <PageNumber num={715} />
              <p className="p-in-sp">
                It is with sincere reluctance and very great distrust that I presume to throw myself upon the attention of this Committee under the circumstances which surround me. I know, notwithstanding the clamor made here, and notwithstanding that a conflict has arisen upon this floor in regard to the length and freedom of debate, that the people of Virginia are tired and sick of debate. I know
                that my own people, while they desire that I should stand and counsel freely with the distinguished and wise men who have been sent up to this Convention, while they expect me to take a part in its deliberations yet, they would hold me inexcusable, if I was to consume too much of its time by speaking, and thereby, in some measure, prevent the body from coming to some conclusion.
              </p>
              <p className="p-in-sp">In the position which I shall take, Mr. Chairman, I shall agree with very much of what has been said by the gentleman from Culpeper [<span className="persName">Mr. BARBOUR</span>]
                , who has so ably and eloquently addressed the Committee. I shall have the misfortune to disagree with some with whom it has been my pride and fortune to associate politically in times gone by, and I shall have the misfortune to disagree with the other extreme wing in this body. While it would afford me great pleasure to agree with both, if I could, I dissent from them with great
                pain.
              </p>
              <p className="p-in-sp">
                The gentleman who has just taken his seat has told us that it becomes a statesman to survey all the circumstances surrounding him when he is called upon to act, and to determine his action by the circumstances which surround him, and adapt himself to them. It is true, sir; and one strong and controlling reason why I have not been able to agree with the gentlemen with whom I have
                formerly associated, and who have been denominated the precipitators from the inception of this revolution is, that I have felt the force of that sentiment, and I desired to accommodate my action not only to the circumstances which had transpired, but I felt that I ought to await the development of others. In my opinion the great fault with my friends on that side is that they have
                not adhered to that principle. They have wandered from that wise maxim which was reiterated by Edmund Burke, and which was actuated by my friend who has just taken his seat. If that principle had governed the South; if South Carolina, who commenced this revolution, had acted upon that principle, and those Southern States who have associated with her, we would not have been placed in
                the alarming condition in which we have found ourselves since the 20th of December last. I do not believe, sir, that they acted advisedly, and I do not believe that their course was justified by the circumstances.
              </p>
              <PageNumber num={716} />
              <p className="p-in-sp">
                Let me be understood. As a States Rights man, I hold to the doctrine of peaceable secession as a right which belongs to the people; but I hold that it is a right which ought to be exercised only under extreme circumstances, and that it cannot be justified unless it is done under circumstances stronger than any which have occurred yet. I ask, sir, why was it that South Carolina first
                resorted to this remedy? In view of all the tremendous consequences which have resulted, and of those untold and incalculable results which may yet come and which the wisest men cannot foresee, how is she to stand justified before a Christian world for the act which she has done? Take any and all of the grievances of which we now complain—take the Personal Liberty bills—those
                outrageous infractions of our rights which have been passed by some of the Northern States—take their continued interference, their unjustifiable warfare upon the institutions of the South, in every mode and every form to which they have resorted—take the invasion of Virginia in October, 1859—take all, and, even after all, by a resort to the constitutional, legal mode of resistance,
                without resorting to secession, we might have corrected the evil.
              </p>
              <p className="p-in-sp">
                I point, sir, to one fact: Every grievance under which South Carolina and the other States of the Southern Confederacy have suffered, and on account of which they justify that revolution to which they have resorted, has existed for a period of from twelve to fourteen years. We have lived under that Union—aye, more, sir, that Union had prospered—and the people of the Southern States,
                as well as of the Northern States, enjoyed a degree of tranquility and prosperity, personal and political, which no other people on the earth did enjoy. After every grievance had operated upon the States for years; after every wrong had been inflicted and borne for years; even with these operating upon us and grinding us, as gentlemen say they have ground us, to the dust, before the
                election of a President, in November last, every party of every political hue proclaimed, at least in Virginia, that they were not ready for secession, and that that crowning act, the only one which has transpired since all the wrongs to which I have alluded have existed—the election of a Black Republican President—did not justify secession on the part of the South. We were a unit,
                sir. As a Douglas man, as one of that wing of the Democratic party, I agreed with my Breckinridge Democratic friends in the last canvass. Nothing, sir, beyond that election has been alleged to justify this course on the part of the Southern States, which threatened to engulf not only all that was valuable and dear in Southern institutions, but all that was
                <PageNumber num={717} /> valuable, venerable and glorious in our Union, and to destroy the hopes of liberty throughout the world.
              </p>
              <p className="p-in-sp">But gentlemen tell us that secession, resorted to under such circumstances, which already has produced consequences most disastrous—they tell us now, and have told us for months past, that secession on the part of Virginia is to cure the mischiefs which have resulted entirely from secession on the part of the other States.</p>
              <p className="p-in-sp">
                Sir, up to the moment of the secession of those States, the prosperity of this country was great; all was calm and tranquil; there was no ripple on our political surface; no disturbance of our commercial or social systems; but as soon as secession was resorted to, a panic has spread over this broad land, which has not only addressed itself in fearful tones to the statesman who could
                stand in his position and view the causes, look to the results, and see the effects which certain causes have produced; not only to the capitalist, with his keen eye, who looks to political causes when he undertakes to invest his money; not only to the workshop, where the operations have been stopped; not only has enterprise everywhere been thwarted and brought to a stand, but panic
                has gone into the very dwellings of our citizens; it has sought the fireside of our household, and spread terror and consternation among the women and the children of the nation. Until secession occurred, there was no such evil known in this land.
              </p>
              <p className="p-in-sp">
                Now, sir, that was precipitate action, mark you. And those gentlemen who now justify that precipitate action, the results of which are not now matters of speculation, but are facts written in woeful characters upon the face of the country, these gentlemen tell us, and have been telling us for months, that the only remedy for these evils is immediate secession on the part of Virginia.
                Sir, when the election was progressing, I took little part in the canvass; but when I defined my position as one who was not willing to act precipitately—when I stood appalled in view of the fearful scene which was then spread out before me, and I could not speak advisedly to my people; for I told them I had not wisdom enough to do it—then I met with gentlemen who were wise enough to
                tell me that if Virginia would secede immediately, peace would be restored to the country, and prosperity within our borders. I could not see it then. I did not see the force of their position. I did not agree with them. I made up an issue with them. They said boldly and defiantly, and a panic was gotten up, "if you don't secede before the 4th of March; if you permit a Black
                Republican President to take his seat, and hold his sceptre over you, war is inevitable; you will surely bring desolation and ruin to all the interests of this country." I did <PageNumber num={718} /> not believe that, sir. I did regard the election of Abraham Lincoln as a most unfortunate occurrence, as a fearful thing, when I saw even the possibility
                of it, and I did really fear when I saw that he was elected.
              </p>
              <p className="p-in-sp">
                But I rested upon one strong arm of the government, which I knew had more power than he, and without whose concurrence, even the President did not have power to make war upon us, seriously to injure us. I looked to the fact that he had a minority of the votes cast in that election, that there were a majority of a million of the voters of the United States against him; I looked to the
                fact that the Congress of the United States elected up to that time, contained a majority against him, and he, the President, could do nothing without the aid of Congress. I mention these things as facts which I suppose will not be controverted.
              </p>
              <p className="p-in-sp">
                Now, sir, what has been the effect of secession up to this period? It is plain, it is incontrovertible, it is seen and felt in the deplorable condition of every department of enterprise; it is holding us here in deliberation—I regret to say, in divided council; it is holding the people of Virginia and the people of the other States in fearful, in awful suspense, as to what will be
                the final issue. Now, sir, in view of that fact, I ask, shall we listen, even for the very powerful reasons given by the gentleman who has just taken his seat
                [<span className="persName">Mr. BARBOUR</span>, of Culpeper] , to those who have wholly deceived themselves when they said, if we did not secede before the 4th of March, we would have bloodshed, and all the fearful evils attendant upon civil war?</p>
              <p className="p-in-sp">
                Mr. Chairman, this is the day of panics; the age is adapted to them. The excitable and inflammable material which now exists, ignites and rises in a terrific flame at the slightest touch from the hands of the panic-maker. The telegraphic wires have played an important part in extending this excitement. All will remember how difficult it was until recently, while Congress was in
                session, and about the time of the inauguration of the present President, to receive from Washington a dispatch, which did not either excite the precipitators or gratify the peace-makers. It is a remarkable fact, that whatever came from Washington upon the telegraphic wires, saying that war was probable, saying that Lincoln had taken up a position of hostility to the seceded
                States—whatever came which ministered to that spirit—I had like to have said, that wild spirit of fanaticism (but on account of my respect for gentlemen who labor under that infatuation, I will not say so) —whatever came here of that character, elated the hearts of the precipitators. Well, now, sir, I point to the calm and quiet now restored, in justification of my course in waiting
                to deliberate—in waiting, when <PageNumber num={719} /> we were warned by the gentleman from Bedford
                [<span className="persName">Mr. GOODE</span>] , who sits near me, who played so beautifully on the expression wait, but who, somehow or other, has changed his tune, and I believe is struggling now to wait, while the other side of the house are for bringing matters to a speedy termination.</p>
              <div className="speaker" id="sp1833"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
              <p className="p-in-sp">The 4th of March has come and gone.</p>
              <div className="speaker" id="sp1834"><span className="persName">Mr. TREDWAY</span>—</div>
              <p className="p-in-sp">
                Yes, sir, the 4th of March has come and gone, and I wonder that the gentleman alludes to the fact that it has come and gone, for as it has passed by it has proclaimed that my friend was no prophet. Now, sir, I maintain that while on the one hand the practical results of secession stand out in broad, in living and in fearful characters before the country, the very time we have taken
                to deliberate has relieved us of many difficulties, put us in a better position for adjustment, than we have ever been in before. You were told, sir, that the Peace Conference was a failure. I admit that it was a failure so far as it was designed to accomplish a settlement such as I would have been willing to have taken; but I will not admit that the Peace Conference was an entire
                failure. I ask, sir, as one important thing resulting from this Peace Conference, if we did not get in a proposition which, I understand, was supported by the Northern members of that Peace Conference, a proposition which they never did tolerate before at the North? In that article of the Peace Conference, a proposition which allows the General Government to pay the owners of a slave
                for his property which would be wrested from the marshal, I maintain there is a distinct recognition of the right of property in the slave-holder. Otherwise, how would they agree to pay the owner?
              </p>
              <p className="p-in-sp">
                I am not arguing, and I do not intend to argue, that the propositions of that Peace Conference, as a settlement, were sufficient to meet the demands we ought to make upon the North by any means. But I say that the agreement to that one article did, at least, settle that the portion of the North represented in that Peace Conference were willing to make that distinct admission, which
                was never before obtained from the North—nay, sir, which they had always refused.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, when you are settling a controversy with an adversary, when you have differed entirely at the commencement of the negotiation, and when afterwards you find that your adversary is willing to agree to a truce; when you find in him a disposition to settle with you, you have accomplished much. You may then have hope for an adjustment very far different from what you would
                have if he repels you and tells you he has no settlement to make. If you go further with that adversary, and find him, although declining to accede to your <PageNumber num={720} /> whole demand, vet willing to concede an important principle upon which you based your settlement, would not you conclude that in that concession you had made an important
                step in the progress towards the consummation of your settlement? I think so. Well, sir, by waiting we have not only accomplished that, but we have put the whole country, North and South, in the position of men who are deliberating and reflecting, and not disposed rashly and precipitately to act upon the great questions in issue. Their minds have been turned to the subject of a
                settlement, of an adjustment under which their tempers have cooled down. Instead of desiring to rush into a civil war—the most terrible calamity which can visit the earth, with all the fierceness which raged up to the 4th of March—he who reads the public mind, North and South, will find more of concession and less of anger; he will find that there is, to a greater extent, a spirit of
                adjustment, that temper has cooled down and reason, to some extent, resumed its proper office.
              </p>
              <p className="p-in-sp">
                I maintain, therefore, Mr. Chairman, that no man has the right to argue, looking to the results, that precipitate action and immediate secession is the remedy for the evils under which we labor. And I maintain, moreover, that the deliberation which has been exercised and the time which has been given even to those who believe in the right of secession, has worked well and done much
                good, for I believe it has preserved this country from the horrors of civil war. I have no doubt. from all the observation I have made, that if Virginia had immediately gone out of this Union and taken her position among the Southern States, that civil war would have at once commenced, and we should have seen consequences far more disastrous to this country than any which have yet
                resulted. As a lover of peace, therefore, I shall forever congratulate myself, whatever may be the course of events hereafter. that Virginia has taken the course which, in my judgment, has saved the country from the horrors of a civil war. While others have spoken of Virginia as being degraded, because she did not secede and make the wrongs under which she labored ground for taking a
                step that would have resulted in war; while others have pointed to her and pointed to me as a submissionist, I have felt erect and proud in my position. I have felt that the blessed old Commonwealth was on a higher and more glorious mission than ever she embarked in before. I have looked upon her as the oldest and one of the strongest members of this Confederacy; and when her dearest
                interests were imperilled to a greater extent than any of the States which have seceded, with calmness and self-possession she desisted from extreme measures, and <PageNumber num={721} /> she embarked under the benediction of Heaven on a mission of peace for the settlement of these unparalleled difficulties.
              </p>
              <p className="p-in-sp">
                Now, sir, I say that Virginia must not now look back. While I do not concur with my friends who are for precipitate action, I want time—I want deliberation. I desire to watch the developments that may occur and to govern my action by them; yet I am willing to forget the past. I see this great Union broken up. I see and acknowledge the fact that seven of the States of this Union have
                gone, and that they have established a government de facto, which ought, in my opinion, to be acknowledged and respected. I look, too, to the fact that these seven States which have gone possess institutions which give to them and to us identity of interests, which must be preserved, which are endangered and must at all hazards be protected—in fact that we must now meet the crisis
                and act upon the circumstances which surround us. Sir, when we had the old Constitution of this Union, the seven seceded States with us and the majority of Congress with us, although we had a Black Republican President, we might, as I remarked before, have resorted to legal and constitutional modes of protection, and we would have been safe at any rate for a longer period of time.
                But our condition is altered by the altered condition of this Union. We have not these seven States now to vote and to struggle along with us in Congress. We have lost them in the Senate, we have lost them in the House; and here we stand now, Virginia, one of the eight border slave States, in a Government with nineteen free States. And I will endeavor, so far as I can, to adapt
                myself to the great principle, which was stated by my friend from Culpeper
                [<span className="persName">Mr. BARBOUR</span>] . I would invoke every member of this Convention to let go the struggle as to the past; to look only to the present, to the circumstances which surround us and let us meet as statesmen, the issues which are presented, with the aid of every development that has been made in regard to our condition now.</p>
              <p className="p-in-sp">
                That, sir, which would satisfy me as a Virginian, with the seven Southern States we have lost united with us, will not satisfy me now, when we have but eight States to contend, so far as our interests are concerned, against nineteen States. But I am not ready now, to pass an ordinance of secession. I am not yet convinced, more than I was on the day of election, or on the 4th of
                March, that we ought to pass an ordinance of secession now. I am not yet satisfied, notwithstanding the very eloquent argument of my friend to persuade me that now is the time to go precipitately out of the Union; that we can gain nothing; that there is no prospect of accomplishing anything by waiting still <PageNumber num={722} /> longer. May we not
                heal divisions in our own State and procure the co-operation of the border slave States?
              </p>
              <p className="p-in-sp">
                The gentleman tells us here that Virginia, by waiting now, will be playing the part of Dogberry's watch. There are gentlemen here, who think she has been playing that part a long time. I wonder if, when those personal liberty bills were passed, which were infractions of the compact between the North and the South, which were nullifications of the law of Congress, a great, principal
                grievance, Virginia was not then playing the part of Dogberry's watch, because she agreed to wait. I wonder, if after all these wrongs had taken place, after John Brown's raid had taken place, after Virginia's soil had been invaded, why the gentleman did not tell us then that if she delayed to go out of the Union, she was playing the part of Dogberry's watch. And, now, Virginia
                having failed to take precipitate action, having failed to adopt immediate secession, and the prophecies of gentlemen as to the results of that failure having been falsified by the event, good having resulted from it, I insist, that as wise statesmen, we should look to each new development, and await the course of events. Why need we go now, precipitately, out of this Union? Did not
                the gentleman from Culpeper most truly depict to you that this General Government is now as harmless as an old woman; that she has neither credit nor money ; that she cannot get the sinews of war? Did he not represent her, and represent her most truly, as being in a condition in which she could do no harm to anybody; that we have nothing to fear from her? Why, then, I ask, shall we
                precipitate ourselves out of the Union now? I agree with the gentleman. I believe that the General Government is impotent to accomplish anything in the way of warfare. She has not the material in the administrative department of the Government to manage a crisis like this. She has not the men or the money to make war upon anybody. I believe the result will be that she will have to
                submit to such terms as the South may put upon her. Why, then, should we hurry out now?
              </p>
              <p className="p-in-sp">
                The gentleman tells us—and I was a little surprised at an argument of that kind from a gentleman to whom the public accords the claims of a statesman—that the Southern Confederacy had established a successful Government; that we were under a Government which had proved unsuccessful, demoralized and crippled; and that we should therefore now take our position with that successful
                Government established by the Confederate States. Why, sir, has that experiment proved yet successful? What man will stand up as a statesman before the world, and say that these States recently confederated—not all of which, I <PageNumber num={723} /> believe, have yet adopted their constitution, the first of which took her position only in December
                last, and the last of which took her position within a few weeks—I say what man could say that that experiment, when you consider the fleeting events of the passing moment, can base his action as a statesman upon it, and consider it a successful Government? I was hardly prepared to expect an opinion made up so precipitantly by the gentleman from Culpeper, or that he should advise so
                immediate action.
              </p>
              <div className="speaker" id="sp1835"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">I desire to ask the gentleman what amount it has been estimated Virginia contributes towards the Northern government? I will state that my understanding is that she contributes about $6.000,000 annually, or about $500,000 per month, and that she is now contributing at that rate. That is one reason why I desire immediate action.</p>
              <div className="speaker" id="sp1836"><span className="persName">Mr. TREDWAY</span>—</div>
              <p className="p-in-sp">I should like to know from what source the gentleman gets his information that Virginia contributes $500,000 a month for the support of this government?</p>
              <div className="speaker" id="sp1837"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">My opinion is based upon the amount of import duty, the pro rata share which Virginia contributes towards this import duty in her consumption, and which I understand amounts to $6,000,000 annually, or to about $500,000 per month.</p>
              <div className="speaker" id="sp1838"><span className="persName">Mr. TREDWAY</span>—</div>
              <p className="p-in-sp">
                Yes, sir. I supposed that that was the source of the gentleman's information. I tell him that he is acting unwisely in not accommodating himself to the present circumstances, instead of looking to the past. That may be the computation of what Virginia has paid under the former tariff ; but no one will say that under the present tariff, in competition with the Southern tariff,
                Virginia is paying anything like that amount to the General Government—anything that will compare with it; and all we may pay is voluntary.
              </p>
              <p className="p-in-sp">I must beg the gentleman [<span className="persName">Mr. HALL</span>]
                , not to act on what might have been facts 12 or 18 months ago, but to act on what are facts today. What are we told in regard to New York? Look at the extent to which the imports at that port have fallen off. I saw it stated the other day that goods had gone up the Mississippi River to St. Louis. I see the great fact staring me in the face that the centre of trade is broken up, and
                that there is a revolution in commercial affairs greater, perhaps, than there has been in political affairs.
              </p>
              <p className="p-in-sp">
                I shall accomplish much if I get my precipitate friends to look at present facts and not at the past. We are not paying, that I am aware of, anything to the General Government more than we choose to pay. There is no direct tax levied. Our merchants may go to Charleston <PageNumber num={724} /> and buy their goods there. I believe those from my section
                of the State come to Richmond for their stock. They do not expect to buy any goods from the North. In the exercise of our rights we expect to pay nothing by way of tribute to the North, and without immediate secession we are relieved from this burden.
              </p>
              <p className="p-in-sp">
                If our merchants should prefer to go to the North to buy their goods, while they can get them cheaper elsewhere, that is their own fault, and, I think, we ought not to base our important political action on such acts of our own. What harm do we sustain? We are not obliged to pay anything to the support of the General Government. Congress has passed no law that will grind us; and my
                friend from Culpeper
                [<span className="persName">Mr. BAR</span>BouR] , has demonstrated that the General Government cannot hurt us. Why, then, I ask, should we act precipitately and go out of the Union?</p>
              <p className="p-in-sp">
                My friend from Culpeper, gives as one reason, that Seward, that arch-enemy of the South, had advised waiting one, two or three years. But does not my friend remember that Giddings and Phillips and other ultra Abolitionists of the North are saying, as he does, "dissolve this accursed Union"? If the men who are for deliberation here find Seward agreeing with them, that would take him
                out of the category with ultra Abolitionists.
              </p>
              <p className="p-in-sp">I again ask, why should we act precipitately? What evil threatens us? Mr. Chairman, secession has become a poetical idea.</p>
              <p className="p-in-sp">
                It seems to me as if it had invested itself with all that is gorgeous and beautiful or fancy. It has captivated even the ladies. It has taken all the young men. It has even carried away, in the rush, many of the oldest and wisest men. The speeches made for secession all portray, in deep colors, the wrongs under which the South is supposed to suffer. They will not argue according to
                the existing state of facts. They will give you no remedy for the evil but that which their poetic fancy suggests—immediate secession. I see no charm in it, for I have felt, when I laid my head on my pillow at home, when secession was first broached by the South, all its terrors, and it has lost all its poetry for me.
              </p>
              <p className="p-in-sp">Is it not the part of wisdom to say that we will not act immediately? We may have to come to secession, and I tell you, as a States Rights man, that when my conscience and judgment tell me that we are justified in that extreme step, I will go for secession without any scruple whatever. But I think we can accomplish something yet within the Union.</p>
              <p className="p-in-sp">
                So long as that hope remains, and so long as there is no danger in waiting, I will prefer to wait. I stated a while ago, Mr. Chairman, <PageNumber num={725} /> that the delay which we had advocated, and which a majority of the people of Virginia advocated, had had a good effect; that it had brought the public mind to the consideration of the adjustment;
                that it had cooled down, to a very great degree, the angry feelings which had pervaded the public mind, while it had not brought upon us the evils which our friends had predicted.
              </p>
              <p className="p-in-sp">
                And, now, what is the position of Virginia to-day? Seven of her confederates have left this General Government—gone out, and established a government which the report of your Committee says ought to be acknowledged by Congress. But Virginia is not the only slave State now under the General Government. She is not in a position to act for herself alone. She has associates identified
                with her in feeling and in interest, and she ought not to take a step until they are consulted. North Carolina lies between us and the Southern Confederacy. Kentucky and Tennessee are on our West, Delaware and Maryland on our North.
              </p>
              <p className="p-in-sp">We are thus surrounded by slave States on the North, South and West. I ask, then, if it would not be more courteous and better, every way, that we should wait, when there is no danger in waiting, until these, our sister border slave States, can be heard from?</p>
              <p>The hour of 2 having arrived, the Committee took a recess till 4.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.13.3">
              <h3><span className="headingNumber">1.13.3. </span><span className="head">AFTERNOON SESSION</span></h3>
              <div className="speaker" id="sp1839">The Committee re-assembled at 4, and <span className="persName">Mr. TREDWAY</span> continued his remarks. He said :</div>
              <p className="p-in-sp">Mr. Chairman, before resuming the view which I was taking at the time the hour for the recess arrived, I must correct a misapprehension which I understand has arisen in regard to a remark that I made. I do not wish to have any personal explanation hereafter in regard to what I may say. I remarked, in reply to what had been said by the gentleman from Bedford [Mr. GOODE]
                , that the 4th of March had passed by, and that there had been no such disaster resulting from it as he had prophesied and anticipated. I was understood to say that no body had been injured, or in the language of Mr. Lincoln, that no one was hurt, by the events which had transpired. I did not mean to be so understood. Great disasters have resulted to the country. Panic, alarm, and
                derangement of commerce have ensued, and enterprises of all kinds, have been brought to a stand still. I did not say that nobody had been hurt, but I said that the 4th of March had not brought us to civil war, as the gentlemen who advocated precipitation had prophesied.
              </p>
              <p className="p-in-sp">
                And now, sir, I will go on to consider the proposition why Virginia <PageNumber num={726} /> should not now, even, precipitate herself out of this Union. One beneficial result of waiting still longer would be to ascertain the position of the border slave States. It is due to them, inasmuch as they are assimilated with us in interest, that they should
                not be precipitated out of the Union, while there was no danger.
              </p>
              <p className="p-in-sp">
                A statesman, looking to the mighty results involved, would not and ought not to be willing to take the experiment just initiated as an experiment that has been successfully consummated. It is still but an experiment. It was initiated but the other day; and even yet its Constitution has not been adopted by all the States in it. I may have glowing anticipations as to the future of that
                Confederacy. I may be willing to rely on its immense resources, yet still it is but an experiment, and should not be regarded as an ultimate success. A wise statesman—one who looks forward to the developements that must arise—would be unwilling to take that experiment as the basis of such action as is proposed to be taken by some gentlemen in this Convention. I say, therefore, wait
                till you see the still further operation of that experiment. You run no risk by doing so. My friend from Culpeper demonstrated that this weak, imbecile Government of which we are now a part, can do us no harm. We, therefore, by waiting, get all the advantages of deliberation, of concert with the border States, and with any other States that may consult and co-operate with us. We get
                the advantage of any developments which may yet arise; and then we can certainly act more advisedly than the wisest men could act now.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I am not among those who look upon the dissolution of a mighty confederacy as a light matter. I am not among those who regard the fact as demonstrated now, that two distinct Governments formed out of this Union will go on in a career of prosperity and success without difficulty. No, sir; I leave out of the question all that reverence and veneration which I have been
                taught from my youth to bear toward that Union under which we have prospered so signally.
              </p>
              <p className="p-in-sp">
                And I say that, in view of the foreign relations of this country, it is of immense moment that we should preserve this Union. The gentleman from Culpeper remarked that we in Virginia were unwilling to acknowledge the independence of that Government de facto which existed now in the South. I tell him that, as a Virginian, I will acknowledge it. I tell him that in the report which has
                come from the committee of 21, the ground is distinctly taken that it ought to be acknowledged. And he read from the London "News," a paper which was the index of the feeling in Exeter Hall; and that paper said, that they were willing and anxious to acknowledge the independence of the <PageNumber num={727} /> Sir, the worst enemy of republican
                institutions, the worst enemy of Southern institutions, would, in my opinion, advise the recognition of any government which would ensure the permanent destruction of this Union. I hail that indication from Exeter Hall as an admonition to be cautious not to take a position by which I shall consummate the disruption of the Government. The policy of the British Government would be to
                strike the very moment they found they could do it with impunity. They dare not assail us when we are one and united. The power of that Union, the strength of the national arm, when not divided, has been our guarantee, in times past, for peace and for a peaceful vindication of our rights when there was a controversy with foreign powers on any matter. If they want to make an attack
                upon Southern institutions, they could not, in my opinion, act more effectively than to produce an alienation between the North and South. Why, Mr. Chairman, I saw the other day in a newspaper some indications of movements by European powers, which are calculated to awaken watchfulness, if not apprehension. I saw it stated that France and England were already preparing a strong fleet
                to be sent to the Gulf of Mexico; and I saw, too, that Spain was about to send one to the Gulf. I know not what may be the occasion for these movements, unless it be that Spain is preparing to raise an issue in regard to Cuba, or unless she feels that she has been insulted by our propositions to buy that Island of her, or unless France and England suppose that they can dare to attack
                one portion of this Confederacy when it is struggling against another. They may think that when there is a war at home between sections, they may successfully strike at the institutions, North or South. I do not know any other cause why they should send strong fleets upon our neighboring waters. We have had controversies with Great Britain within a few years past; we were on the eve
                of a war with her in 1846, in regard to the boundary line between the United States and her possessions in North America. We had warm controversies about the line of 54-40, which was made a party platform, and which, at one time, it was believed, would involve the United States in war with Great Britain. It was attempted to be settled by negotiation. The negotiations which had pended
                for a long time failed. The question came before Congress and was discussed there, and it was doubtful, for a long time, what the result would be. After considerable efforts on the part of our most distinguished men to negotiate the matter, all of which had failed, the Senate of the United States took it up and it was settled. Patience and time wrought that
                <PageNumber num={728} /> result, not precipitancy and hurry. We have now a pending difficulty unadjusted with Great Britain about the San Juan Island. At one time an officer of our Navy took possession of it, and it was thought a collision would take place between Great Britain and ourselves. That question is yet in abeyance, and, as on the Oregon
                question, if we could only preserve this Union, if we could only preserve this great and invincible nation, to meet the foreign enemy, we would bring them to terms of fair settlement. But when you are divided, and in consequence of this division a foreign enemy can attack you successfully, have we not a right to apprehend that England would avail herself of our weakness and make an
                attack?
              </p>
              <p className="p-in-sp">My friend from Culpeper [<span className="persName">Mr. BARBOUR</span>]
                , told you of the prosperous and powerful Government which the Southern Confederacy have. I am glad to hear it; and I wish that Government success, in all sincerity, although I believe that the secession of the States composing that Confederacy was unquestionably wrong and unjustifiable. Since they have taken this position, I would not war with them—I would acknowledge their
                independence and bid them God speed.
              </p>
              <p className="p-in-sp">
                But how are you to preserve the credit of the nation? The credit of two nations cannot stand as high as they would if combined in one. The credit of the Union, sir, has stood high. The Government bonds have stood better than the bonds of Virginia, or the bonds of any one State, even with this national debt and its enormous extravagances. Yet the bonds of the United States, in the
                foreign market, have stood high; but, sir, this shock which has been brought on our commerce, has affected Government securities, and no capitalist from Europe or elsewhere will invest his funds in a Southern Confederacy, or the Government at Washington, as readily as before.
              </p>
              <p className="p-in-sp">
                You are told, sir, that the sinews of war were indispensable to a nation; that the fact of its commanding credit and obtaining money with facility was an evidence of a nation's success and strength. It is true the sinews of war are indispensable; without it no nation can successfully wage war. Now, sir, I happened to get hold, a day or two ago, of a letter containing the opinion of a
                very eminent London banker, who, by the bye, is a native American, and who concurs in the view I have taken, to wit: that in the event of a permanent disruption of this government, and the formation of two Confederacies, the credit of their bonds would not be as good as if the government continued united. I refer to Mr. George Peabody, the London Banker—a man of great foresight and
                shrewdness—one of that class of men who decide <PageNumber num={729} /> the question of war or peace in Europe, and who hold the very sinews of war in Europe. He says:
              </p>
              <p className="p-in-sp">"LONDON, March 8, 1861</p>
              <p className="p-in-sp">
                "My DEAR SIR: You call my attention to a letter written from London—extensively published in the large cities, and in the New York Evening 'Post,' among other papers, of which my name has been mentioned as the author. It states that, should concession be made to the South, and present difficulties be settled by compromise, our national credit abroad would be ruined, &amp;c. The
                writer is unknown to me. If I had written on the subject, my remarks would have been the reverse of those quoted. The anticipation of a bloody conflict between the North and the South has already destroyed confidence in the United States Government stocks and many of the States' securities, and millions have, within a few months, been sent home for a market in consequence. It is only
                by concession on the part of the Northern States, and a compromise which would secure the best feelings of the border States towards the North and West, that we can reinstate our credit abroad. I pray that the efforts making by the patriotic Crittenden, and the noble stand which old Virginia, Maryland and other sister border States are now making for conciliation and the Union may
                prove successful; but if, contrary to my hopes and expectations, it should prove otherwise, and those States join the South, even under amicable arrangements, the constant fear of war between the two sections would almost entirely destroy the credit of both the Northern and Southern Confederacies in Europe. What is still more important, the prestige which has heretofore emblazoned
                the arms of our Union, will have disappeared, and that pride of country, which has been a source of so much gratification to Americans in foreign society, will attend them no more. Spain, or any second-rate Power, may insult the stars and stripes (if any are left) with impunity. Napoleon III may establish despotic governments, and Lord Palmerston protectorates, or even monarchies in
                Mexico and South America, the 'Monroe doctrine' to the contrary notwithstanding. May Providence protect our country from such calamities and mortification.
              </p>
              <p className="p-in-sp">"Very truly yours, GEORGE PEABODY."</p>
              <p className="p-in-sp">
                I know, sir, upon money questions, no higher authority than this eminent English banker, whom I regard wiser than our statesmen, and who holds the purse strings upon the sovereigns of Europe. It is for causes such as these that I urge the patriotic people of Virginia to the <PageNumber num={730} /> proud position of peace maker, and not to heed the
                voice of those who are hurrying the State into an extremity from which she can never extricate herself, and from which she will not be able to bring back the seceded States and restore this Union. I do not mean to say that this Union can be saved. I have met, in the course of my intercourse and associations, but one class of men who could venture to tell me, with oracular precision,
                as to the result of this difficulty. I have seen but one class of men who could, upon the impulse of the moment, undertake to predict the result of the existing state of things. They are the precipitators. Sir, there is not a man living now in the country, with sufficient statesmanship and weight of character to manage affairs in a crisis like this. We have no such men nowadays as
                Jefferson, Madison and their compeers of that age; and among such as we have, none is to be found possessing sufficient wisdom and prescience to enable him to tell with even approximate accuracy what is to be the result of such a state of things, as we now witness. And when a man undertakes, with an air of confidence, to tell me that the events now passing are to produce a certain
                given result, I distrust the prudence of that man, though I may believe in his sincerity and honesty. To make such predictions is, to say the least, a rash adventure. No man can foresee what is to be the result of the present state of affairs. I only wish we had such a man who could foresee the results which are likely to follow. Sir, when we had difficulties in days gone by, when
                this Government was distracted and on the brink of ruin, we had men who took the helm, and with noble and majestic bearing, said to the troubled elements, be still; who with exalted patriotism and calmness stepped forward and calmed the troubled waters. But we have no such men now. We have political leaders—leaders of all parties, who can mount the hustings and speak to the people as
                if they had received inspiration from above. I fear it is these politicians, operating upon the credulity of the people, that have brought us to the condition in which we now are; and they are now incapable of controlling affairs or reconstructing a Government.
              </p>
              <p className="p-in-sp">
                John Randolph said that "a government, whether ready made to suit casual customers, or made per order, is the very last to operate as it is intended. Governments are like revolutions; you may put them in motion, but I defy you to control them after they are in motion." I tell the secessionists, you may make a government to order, but when you come to meet unforeseen occurrences, you
                will have much more to do than to dissolve a government. In the language of Mr. Burke, "They commit the whole to the mercy of untried speculation ; they <PageNumber num={731} /> abandon the dearest interests of the public to those loose theories to which none of them would choose to trust the slightest of his private concerns." It behooves every man in
                the South, if he can devise any means which may lead to the reconstruction of this Union, to apply himself to the use of those means. I did not understand your legislature to say that when a simple Peace Conference proposition, which was accepted by some of the States, should fail, then we should secede. I understood them to say that when all efforts had failed. But even if the
                legislature had said it, this body came from the people more recently than the legislature, and were elected more particularly in reference to this great question and the issues involved; and they are, I hold, better qualified to act in respect to these matters.
              </p>
              <p className="p-in-sp">No, sir; in view of the difficulties which must occur under the wisest administrations, you cannot foresee the result of a Southern Confederacy; and it behooves every patriotic statesman to exhaust every possible means to restore the Union as it was.</p>
              <p className="p-in-sp">
                I know not what the future may have in store for us. It is impossible to predict what will be the future destiny of this country; and yet I have some hope that all will be well when the angry collisions which have occurred since the 20th of December last, at a time when we expected every telegram would bring us news of blood spilled at Charleston, and of a war initiated there, shall
                have subsided, and when the Christian sentiments of this nation, both North and South, shall be brought to exert their healing and beneficent influences, and when all good men shall look to Him alone who can guide the destinies of a nation. It is my sincere hope that by wise and prudent counsels and by the exercise of kindness and forbearance on all sides, we may be able to come out
                of this conflict without ruin and desolation.
              </p>
              <p className="p-in-sp">
                Sir, you cannot repair the mischief already inflicted, at once. You have destroyed the centre of commerce and you have produced a derangement in trade and enterprise which cannot be restored immediately to its former condition of prosperity. Legislation cannot face it. We are obliged to suffer pecuniarily, despite of all the wisdom which may be exerted to alleviate our troubles. Time
                alone may work relief. We must hold on and bear the evils under which we are laboring, remembering that we are descended from an ancestry that endured still greater evils than any which we are called upon to bear. We must make the necessary sacrifice; hope on, and hope on, and we may escape, if not the results of a derangement of commerce, at least the awful consequences of a civil
                war. I admit that the future is dark. I cannot tell what the result will be. I wish I could. When I was called home a few <PageNumber num={732} /> weeks ago, in consequence of illness in my family, I was met by some of my constituents, who asked me anxiously for news—what we were doing here to relieve the present troubles? I told them that we had done
                nothing yet. "Why, you have been there some time, and you say you have done nothing; this certainly requires a large expenditure of money." I told them that "in my opinion, the only thing we could have done was to adopt an ordinance of secession, and in doing that we could have involved you in war; that would have been something, but I do not know what else we could have done; in my
                opinion we have preserved peace in refusing to do anything." They seemed to think, as every reasonable man, under the circumstances, will think, that if we could preserve this great nation from bloodshed, keep the sword in its sheath, and prevent a dissolution of the Union, we would accomplish a glorious work—a work that Virginia might well be proud of—prouder even than she might
                feel in the fact that she was a great leader in the formation of this Government. What are we to do? We are expected to do something. We have assembled here the wise men of our State—a body of men, who, for maturity of wisdom and prudence, I never saw excelled. So much am I impressed by the wisdom, intelligence and experience of gentlemen I see around me, that I feel great hesitation
                in obtruding my crude notions upon this body. We have a remarkably sober body too, not a set of men who came here intoxicated either with political excitement or ardent spirits. I told a friend of mine, who is as sober a man as there is in this body, that he was drunk and that I had not seen him sober since he was in Richmond. "Do you mean to say I am drunk," said he. "I do not mean
                to say you are drunk with liquor," I replied; "but you are drunk with excitement, with this idea of secession." Men can get drunk on something else besides whiskey. I have seen men drunk and deranged, who had not taken a drop of liquor; and I tell you there is more derangement now in the country than you are aware of. I believe that the people have run mad and deranged with wild
                ultraism and the wise men of the nation are tainted with it.
              </p>
              <p className="p-in-sp">
                Nothing can be more dangerous or mischievous than to undertake to tamper with governments either of States or churches. But there is a restless spirit abroad for changing every thing. Reforms in church and State are the order of the day; and it grows out of a restless and excited state of mind, which is not satisfied with that abundant success and prosperity which have marked our
                progress hitherto as a nation and conferred upon us such inestimable blessings. It is a dangerous time to trust men, North or South, to build up new governments. I have no <PageNumber num={733} /> particular line of policy to advocate here to which I feel myself bound to adhere, regardless of all other considerations; but I will listen most carefully
                and earnestly to all propositions that gentlemen may be disposed to make here, and if they will present to me any plan which will save our country and any mode of re-constructing this government—I care not from what section or party it may come—I pledge my honest efforts and every energy to co-operate with them. I know one thing: that party spirit is doing very great mischief; but I
                will not charge upon it all the mischief that has been done.
              </p>
              <p className="p-in-sp">My friend from Culpeper [<span className="persName">Mr. BARBOUR</span>]
                , spoke this morning of the intelligence of the people, and he said if you gave them facts that they would arrive at correct conclusions. I should like to know where the people are to get the facts, when the secession newspapers and the Union newspapers differ so widely in the accounts they give of current events. You will find in one paper telegrams reporting events in Washington
                city, while in another you have only a version of events transpiring in Montgomery. I never expect hereafter to mingle in the scenes of political life; and I want to warn them now, by all that is valuable and precious in their institutions, to beware of politicians and newspaper organs of politicians. Do not go to them for facts, for you will not get them. I have sometimes doubted,
                Mr. Chairman, not the capacity of the people for self government, but I have come to the conclusion that the people do not govern themselves. Why, sir, the people have been ridden to death by the politicians, who have completely humbugged and hoodwinked them. Our people are governed very much by the newspapers they read, and they credulously believe whatever their political leaders,
                under whom they have fought, tell them; and so it is a melancholy fact, that while the people may be capable of self government, they do not exercise self-government.
              </p>
              <p className="p-in-sp">
                But, Mr. Chairman, what are we to do in this great emergency? What can be done to save this great nation in its unity, its splendor, its power and its glory? The government of this Union is dissolved—a fact now established in the history of this nation. Seven beautiful stars, which once beamed in splendor upon the proud banner of our country, glitter there no more.
              </p>
              <p className="p-in-sp">
                As I have announced before, I have been brought up in that school of politics which teaches that secession as a peaceful, legitimate remedy, is proper and right, and belongs to the States. I have said moreover, that it was a right never to be exercised by any people, until they had exhausted every possible means for obtaining redress. Here is proud old Virginia standing now as one of
                the old United States Government— <PageNumber num={734} /> a slave State and identified in interest because of her peculiar institutions, with the States of the Southern Confederacy. The great advantage of her position heretofore has been, that remaining in the Union she has sent forth her voice for peace, and she has said to that government of which
                she is a part, you must not make war upon those Southern States that have seceded, as they had a right to do. Now she stands in a position to do more good yet. Although amid the storm and the billows of excitement, which have dashed madly around her majestic ship, and sometimes well nigh engulfed her; yet, so far, she has stood firm and unshaken; although she has been assailed by
                some of her own crew, she yet stands amid the storm of angry elements, proudly waving the olive branch in her hand, which she will never surrender until all hope is lost.
              </p>
              <p className="p-in-sp">No, sir, she must press on. And what shall she do? Well, sir, my opinion, submitted with very great distrust, is that she ought not now to do that which she would have been justified in doing when she had the seven States of the South united with her, to resist the aggressions of the North.</p>
              <p className="p-in-sp">We must now act upon the circumstances which have arisen since the secession of those States which has produced such disastrous results.</p>
              <p className="p-in-sp">
                Virginia ought now to present her ultimatum. She has, in a spirit of peace, initiated the Peace Conference, which has been referred to. She failed in that, but she will not cease her efforts because of a failure once; she will try again. She says to this Northern majority of nineteen States: "We have endeavored to preserve the peace; we are in a hopeless minority; have remained as a
                member of this Northern Confederacy in which are nineteen free States—we are yet determined to preserve the Union, if you will allow the second sober thought to return to you; do justice to the South, and adopt a government which will protect their rights in the future.
              </p>
              <p className="p-in-sp">We say to them when we send our ultimatum—"If you will accept it, we will reach out our hands to you of the Northern States, and we will, with all the earnestness of our heart, appeal to those Southern States which have seceded to come back; if you will only give us a Constitution under which the South can be safely protected."</p>
              <p className="p-in-sp">
                We have wasted enough of our energies in pacific measures to take our stand now upon an ultimatum; it is too late now, in my opinion, to await the action of a Conference of Border States. Adopt an ultimatum which will be safe for Virginia, under all circumstances, and safe to all of the minority States. We are told they will not do it. I do not know whether they will or not. I
                confess I have no very well- <PageNumber num={735} /> founded hope that they will; but if they refuse it, upon their heads be the blood which may be spilled and the consequences of a refusal. I warn them that Virginia will come out of this offer of conciliation, with her banner as pure and unsullied as it was when she went into it. We lose nothing by
                the offer; a political party may lose, but the country will not lose by the offer.
              </p>
              <p className="p-in-sp">
                What is to be that ultimatum? The great question which we have been struggling over, and which has been the cause of the disruption of this Union, has been the slavery question. That question must be settled now, definitely. In the present condition of things, the condition in which Virginia has placed herself, she ought to go further and demand something else than that, or she is
                not safe.
              </p>
              <p className="p-in-sp">I am willing to take the report of the Committee of Twenty-one, so far as it goes, upon the slavery question. There may be some alterations which I should like to make in it, as I have not examined it critically.</p>
              <p className="p-in-sp">
                But, Mr. Chairman, there is another great question in which Virginia is interested now, and which is connected with this slavery question, and in regard to which she ought to fix her eye with an unshaken gaze. It is the money question. It is that question which has more influence with parties and political movements than any other. Politicians of the North do not care so much about
                your slaves as they do about riding into power, on the strength of the agitation of the slavery question and clutching the spoils of office. How would Virginia stand if she could not get back those States of the Southern Confederacy, with nineteen Northern States on one hand and only eight border States on the other? Two of those eight States of the border, as has been demonstrated,
                are now passing from the condition of slave to free States. Delaware is no longer to be considered as a slave State; and the slave population of Maryland is decreasing rapidly. You run the risk of uniting with a Government with nineteen free States. Suppose they take your ultimatum; how would you stand?
              </p>
              <p className="p-in-sp">
                Look at the condition of this General Government. In the hands of utterly incompetent, corrupt men from the highest to the lowest, so stupid, so utterly blind to the true principle upon which a Government ought to be administered, and so hungry for the spoils, the first thing they have done is to pass that iniquitous Morrill Tariff Bill—a bill by which it was intended to plunder the
                South, but the operation of which under the existing circumstances has proved very disastrous to the Government and to the Northern people. Under it, and under such legislation as we may expect from a stupid, blind, fanatical and corrupt administration, we should be guarded at every point, against <PageNumber num={736} /> oppression, by the hungry
                majority—as this Government is bankrupt and without credit, unable to pay its employees. So it will be compelled hereafter to resort to direct taxation as one of the sources of revenue.
              </p>
              <p className="p-in-sp">
                How would Virginia or any other Southern slave State stand with such a majority? Nineteen States pecuniarily interested, and interested because of their anti-slavery opinions, to tax enormously your slaves with the large majority which they would have—I ask where would be the safety for the institutions of the South? None, sir. It would be the most disastrous scheme of emancipation
                which could be resorted to; more fearfully destructive than any direct legislation upon the subject of slavery. Why, sir, they would tax your slaves to extermination. Their need of money, their hatred of slavery as an institution, drives them at once to this prolific source of revenue. I am looking now to the possibility, the mere possibility, that the ultimatum which you may send to
                the North may be accepted by them. What would be your condition? Take your programme in regard to slavery. Suppose you have in it everything that the most ultra Southern man could wish, that slavery should not be interfered with anywhere—the hands of the Government bound up by Constitutional amendments, tied up—where would you be? I need not tell you that no man would feel safe, or
                would have a right to expect that he was safe in such a majority, moved by such opinions as govern them.
              </p>
              <p className="p-in-sp">
                I will look to wiser heads than mine to submit a suitable proposition here—that with the eight border slave States which might possibly go into such a Government, and 19 free States, interested in taxing your slaves to extermination, nothing short of an absolute, unqualified, self-protecting power ought to satisfy you. You ought to give to your Senators the right to prevent any
                legislation in regard to the taxing or appropriating power. I would not feel willing to consent to the possibility of association with the Northern States, unless we could get all the guarantees of safety that are necessary; I would secede before I would do that. I would not trust them. I submit it to the consideration of gentlemen from the East and the West. It has been demonstrated
                here, I think by the gentleman from Culpeper
                [<span className="persName">Mr. BARBOUR</span>] , that the Eastern portion of Virginia is as much concerned as the West in the preservation of slavery; they are as much interested in it, because it is a prolific source of taxation.</p>
              <div className="speaker" id="sp1840"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">
                Will the gentleman allow me to ask him a question? I ask the gentleman, if he is not satisfied with the clause in the report of the Committee on Federal Relations, which says : "Nor <PageNumber num={737} /> shall Congress have power to authorize any higher rate of taxation on persons held to labor or service than on land?" Does not that satisfy the
                gentleman?
              </p>
              <div className="speaker" id="sp1841"><span className="persName">Mr. TREDWAY</span>—</div>
              <p className="p-in-sp">
                I am not willing, sir, to trust Congress simply with a definition of power in the Constitution which they may or may not abuse. I want a check in the hands of the South beyond any peradventure or mistake. We know that this power of laying and collecting revenue by the General Government has been perverted, and I am not willing to rely on a mere injunction in the Constitution. I want
                a self-protecting power in my own hands, and nothing less will be satisfactory. Sir, it is a safe principle of Government, that those who pay the taxes ought to have the power to protect themselves. It is a principle which was prominently held by the patriarchs who formed our Constitution; it is a sound principle, and should be put in every Constitution.
              </p>
              <p className="p-in-sp">
                Well, sir, I may be told that the North will not accept that. I cannot tell, Mr. Chairman, how it may result. My business as a Virginian and as a Southern man is, to see that we are safe, to act in reference to the present altered condition of things, and to look forward to what may in any contingency be, so far as I can do so. Why, sir, a proposition was made here to take the
                Constitution of the Southern Confederacy, which, I believe, obtained very few votes. It is a very good Constitution for the Southern Confederacy, but I would not take it as a Constitution for the whole of the States.
              </p>
              <p className="p-in-sp">
                We are at sea now, Mr. Chairman. We have to re-construct this Union if re-construction is practicable. Virginia must seek her position either in a government re-constructed with the Southern and Northern States united, or she must seek her position with the Southern Confederacy or with the Northern States. I will not willingly put her in a position with either unless she is safe from
                oppression. Now, sir, the Constitution of the Southern Confederacy has a provision in regard to the appropriation of money, requiring, I believe, two-thirds of Congress; but that would not be safe for Virginia with 19 free States, because they might get two-thirds; they could override you and tax you to death. I want something better adapted to the present exigency—to the new
                circumstances and difficulties by which we are surrounded. It is but fair and right we should have it. I do not mean to consent to take anything short of it.
              </p>
              <p className="p-in-sp">
                I do not know whether it will be accepted. So far as my conduct here is concerned, I intend to ask nothing that is not right. I intend to ask for nothing that is not based upon a safe principle of government <PageNumber num={738} /> for all; nothing that can work injury to any section. When I have done that, the responsibility is upon them, and not upon
                me.
              </p>
              <div className="speaker" id="sp1842"><span className="persName">A MEMBER</span>—</div>
              <p className="p-in-sp">Mr. Greeley says they will not accept it.</p>
              <div className="speaker" id="sp1843"><span className="persName">Mr. TREDWAY</span>—</div>
              <p className="p-in-sp">I know Mr. Greeley says so, and Mr. Greeley has said a great many things that the Northern people have falsified. Mr. Greeley said the North would not accept the Compromise measures of 1830. Mr. Greeley is one of those ultra, dangerous fanatics of the North, whose declarations I would not heed.</p>
              <p className="p-in-sp">
                Well, sir, the question has been asked, if the North do not choose to accept those propositions, where is Virginia to go? I can answer that question. My mind has been made up upon that subject long ago. I am going to exhaust every means of saving this Union, that does not sacrifice or endanger the interests or honor of Virginia; but if they refuse to give us that which is right; if
                they will not give us such a platform as the Southern States which have gone out of the Union can stand upon; such a platform as we can go upon to reconstruct the Union, I say that Virginia ought to go, and must go, with the Southern Confederacy.
              </p>
              <p className="p-in-sp">
                I think there ought to be no difference of opinion in this body upon that subject. Whenever I find out that all our efforts shall have been in vain, and whenever I find that Virginia has discharged her entire duty; whenever it is ascertained that the high mission upon which she went out before the world as a mediator has failed; whenever she has exhausted all means consistent with
                her honor of reconstructing the Union, I have no scruples in declaring that I am ready to go promptly out of this Union.
              </p>
              <p className="p-in-sp">
                I am then ready to shake hands with my Southern States Rights friends and to join them in the Southern Confederacy. It is an experiment, I grant, but we are compelled to make experiments. Anything which you do now is an experiment. But, gentlemen, I have to exhort you, I conjure you to centre upon no experiment in which you may by any possibility be placed in a false position towards
                our Southern sister States. I want to be cautious. I do not believe that a reconstruction of this Union upon false principles or without safeguards and guarantees, such as will protect us under all circumstances and for all time, will be worth the trouble of the reconstruction. But, if you can get it reconstructed on a platform on which all the States can be united, if it can be
                restored upon a basis on which the rights of minorities shall be protected, then we shall have done a work worthy of the age, worthy of the proudest days of Virginia.
              </p>
              <p className="p-in-sp">
                I admit that the prospect looks dark and gloomy. The difficulties <PageNumber num={739} /> before us are great. We differ among ourselves as to what should be done. Our Western friends have been taunted with not being true upon this subject of slavery. I believe that great injustice has been done them—very great. So far as my intercourse with Western
                men, who are representative men, has gone—yes, sir, from North-Western Virginia—I would go to that section of the State to raise soldiers, true and gallant soldiers, to fight for the protection of the institutions of the South, with as much confidence as to any portion of Eastern Virginia, though differing from me upon the subject of taxation. And though differing from me in other
                matters—in the adjustment of the programme for the settlement of these national difficulties—I do not, therefore, infer that they are not as true to the State, in all its interests, as any man from Eastern Virginia. Sir, I make this statement in perfect confidence. I can trust to their patriotism. Waiving all minor considerations, I can appeal to them, and I believe that appeal will
                be met in candor and good faith. The charge of Black Republicanism upon them is, in my opinion, wholly unfounded.
              </p>
              <p className="p-in-sp">
                Now, Mr. Chairman, I have very imperfectly presented my views to this Convention. I have done it honestly, and without regard to party considerations, I do assure you. I have been in no caucus; I intend to go into no caucus. I have taken my position from the counsels of my own head, while I have listened to what has fallen from wiser heads and gathered from them what light I could
                upon these questions. I present my humble views as one commissioned here to take part in these proceedings.
              </p>
              <p className="p-in-sp">
                I assure you I do it with diffidence; I do it with humiliation, with distrust of my own judgment. I feel my weakness. I feel my inadequacy to propose any remedy that shall meet all the difficulties that surround us. I pledge myself if any gentleman can bring forward any plan that can restore this great and mighty government, and preserve the honor of Virginia, I will waive all the
                opinions which I entertain and most heartily and cordially unite with him in the patriotic and noble purpose. I am ready to sacrifice every thing to save the country and restore the Union.
              </p>
            </div>
            <div className="section" id="vsc1965.v2.2.13.4">
              <h3><span className="headingNumber">1.13.4. </span><span className="head">FEDERAL RELATIONS</span></h3>
              <div className="speaker" id="sp1844"><span className="persName">Mr. MONTAGUE</span>, of Mathews and Middlesex, spoke as follows :</div>
              <p className="p-in-sp">
                Mr. Chairman, I have some views which I desire to present to this Convention, but it will be utterly impossible for me to go fully into the questions which I propose to discuss without having before me some material which I have not now at hand. If the Committee will <PageNumber num={740} /> indulge me, I will proceed with some introductory remarks, and
                then ask the privilege of allowing me to complete what I have to say tomorrow. Will that course be acceptable to gentlemen?
                [Cries of "Agreed."] I congratulate my friend from Pittsylvania, who has just taken his seat [<span className="persName">Mr. TREDWAY</span>]
                , upon the progress he is making. I think when he gets over that derangement which he says is pervading the public mind of the country and shall settle down to calm, sober reason and reflection, he will not be very far from the point when he shall go with me South. But, sir, I call the attention of this Committee, and especially my friend from Pittsylvania, to the admissions which
                he, and those who have spoken before him, have made, and I wish gentlemen who follow me in this discussion to answer the questions I shall propound for the benefit of us here and the people of the country. My distinguished friend from Augusta
                [<span className="persName">Mr. BALDWIN</span>] , the distinguished Chairman of the Committee on Federal Relations [<span className="persName">Mr. R. Y. CONRAD</span>]
                , every man who has spoken here at all acknowledged that we have arrived at a crisis when there must be a settlement, and a permanent settlement, of the questions which have divided the country. The gentleman who has just taken his seat declares that there must be such a settlement as will assure the seceded States that their rights will be protected, and that they will be safe in
                returning to the Union. Then gentlemen you admit that the Government as it now stands, does not afford safety and protection to all the rights of the slaveholding States, yet you still declare that those States are guilty of treason and rebellion, in leaving a Government which you admit did not afford them safety and protection.
              </p>
              <div className="speaker" id="sp1845"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">With the permission of the gentleman, inasmuch as he has referred to me as one of those making the admission to which he refers, I desire to say that my position distinctly was, that we were safe, having the safeguards of the Constitution under our control, until they left us and involved upon us new dangers.</p>
              <div className="speaker" id="sp1846"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">
                Very well; then you have gentlemen upon the same side, one taking one position and another, another. And the Bible says that "when a house is divided against itself it cannot stand." But, Mr. Chairman, here is the point. Here is the fact to which I wish to bring the attention of this committee. You all admit—the gentleman from Augusta says he did not, but every other gentleman has
                made the admission—that a crisis is upon us in which we must settle these questions, and settle them forever. Now, I ask you, you gentlemen who are denouncing the seceded States, and yet who admit <PageNumber num={741} /> that your present Constitution and laws do not give such security and protection to the slaveholding States as will permit them to
                rest in safety and in peace for all time to come, if you now entertain that opinion, I want to know when did you ever before express it? What has brought about such a change in the public mind, which has made this opinion fix itself upon the heart and conscience of every man? What has done it? The exercise of the right of secession on the part of these much abused Southern States?
                And, when you admit that there must be a settlement for the purpose of giving security and satisfaction, you may wiggle and twist as much as you please, but you cannot avoid the necessary result of the admission that these States did right when they left the Union. You admit their safety required this.
              </p>
              <p className="p-in-sp">
                So much upon that point; and I do not propose to go further this evening than to give merely an introduction to the remarks I intend to submit to-morrow. When this confederation was formed, what were the great primary fundamental principles upon which it was based? You know, sir, that two great prominent ideas lay at the foundation of the whole system. There were a thousand little
                details, a thousand different minor points, but the great fundamental ideas lying at the bottom of the whole system were these : First, to form such a government as should be a common agent to combine the common forces of the country for the purpose of repelling foreign invasion, in other words, protection without; that was the first. What was the second? That there should be
                commercial, social, and political equality between the States; that the government should secure domestic tranquility and prevent any invasion upon the part of one State upon the rights of another.
              </p>
              <p className="p-in-sp">
                Now, sir, I undertake to say, that if any gentleman will investigate the history of the foundation of this Confederacy, he will agree that these two great principles are the foundation stones upon which the whole structure rests. Here, then, is the fact to which I wish to bring your attention; and mark, the social, commercial and political equality of the States is a fundamental
                principle in our system. How stands Virginia to-day, when you apply that principle to her condition? She has been invaded by citizens of her sister States. That invasion cost your State between $300,000 and $400,000, as you can ascertain by calling upon your auditor. Your Legislature, last winter, appropriated $500,000 for the construction of an armory to manufacture arms and
                munitions of war. Your Legislature, at the present session, has appropriated $1,000,000 for arming your State. Here, then, is a tax of $2,000,000 imposed upon a people already ground down by a debt of <PageNumber num={742} /> $40,000,000. And for what, I ask? For what? Why, sir, to put your State in a position to defend herself against the members of
                her own family; to defend her equality; to protect herself against her sister States, or against the Federal Government, which is the common agent of all the States. Can this state of things last?
              </p>
              <p className="p-in-sp">
                Sir, your Government rests in the affections of the people. Its abiding place must be in the honest hearts of the great masses of the people. And when those hearts become perverted, and when hate and malignity take the place of affection and regard, your whole system is a failure, and it becomes the part of wisdom and prudence to organize another that will cure these defects.
              </p>
              <p className="p-in-sp">And now, Mr. Chairman, in regard to what seemed to be the main leading point of the speech of my able and distinguished friend from Augusta [<span className="persName">Mr. BALDWIN</span>] , I will say a word or two.</p>
              <p className="p-in-sp">
                That gentleman declared here, with a triumphant air, and challenged the whole Commonwealth to refute him, that Virginia had ratified, and expressed her satisfaction, at every act of the Federal Government on the slavery question and of all others, up to the time of Abraham Lincoln's election. That declaration has gone forth before the country to have its effect. He said further—that
                the Federal Government has been administered, since its organization, by Virginia men on Virginia principles; that when not administered by Virginia men, it was administered on Virginia principles; and that, on the whole, its action and operation had been right and proper. I ask my friend how it happens that, with that declaration on his lips, he has been, all his life, warring
                against these just and proper principles? How does it happen that he, as a patriot, from the centre of Virginia, as he often declares—from that beautiful valley which can boast of its lofty mountains, its rivulets and green meadows—has been warring against and denouncing these principles all his life? I take issue with the gentleman. I say that the people of Virginia have not
                ratified and have not sanctioned every act of the Federal Government. The distinguished gentleman named a number of them. He ran through a catalogue. I have seen some venerable gentlemen here—not those grey-headed sinners, like my friend from Pittsylvania, who denounces politicians, while he has been one all his life—who recollect the period to which I am about to refer—I mean in
                1798. I have heard from venerable and intelligent men, that the excitement which rushed over the country at that period was nearly equal to the excitement that now reigns. Every man then was alarmed. The Government was but a few years old, and it was about to come to an <PageNumber num={743} /> end. And why? Because Congress had usurped its power in
                enacting the celebrated alien and sedition laws. Did Virginia ratify those laws? And yet the gentleman from Augusta gets up here and makes a broad declaration, and challenges the world to refute it, that Virginia has ratified every act of the Federal Government.
              </p>
              <div className="speaker" id="sp1847"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                The gentleman has certainly misconceived me strangely if he supposes for a moment that I meant to extend my remark to all acts of the general government. It was in special reference to the subject of slavery; and I have yet to learn that the alien and sedition laws had anything to do with that subject. With respect to the alien and sedition laws it may be proper to say that on that
                subject Virginia was not only triumphant, but it was a triumph of which her statesmen boasted more than of any other of the many triumphs that they achieved.
              </p>
              <div className="speaker" id="sp1848"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">
                I was about to come to that. I am very glad that the gentleman interrupted me. I wrote down his words at the time. Of course he has the right to explain and correct his remarks. If he does, his argument will not have the injurious effect in the country which I know it is having and has had. The gentleman distinctly said: "on every question as well as the slavery question." I wrote it
                down at the time. Whether or not he meant to say so, he certainly said it. But now he disclaims it, and it is not necessary for me to follow that branch of the subject, to show that Virginia had never ratified and sanctioned all Federal acts. I could show that Virginia never sanctioned or ratified the high protective tariff policy of the general government, and that she never
                sanctioned or ratified its internal improvement policy. But the gentleman says his remark had reference only to the slavery question. Let us see how that question stands.
              </p>
              <p className="p-in-sp">
                I intend to show, to-morrow, that this war on the institution of slavery did not, as seems to be the common opinion here and elsewhere, begin in 1835. I will show, to-morrow, from the records of the country, that it begun in the first Congress we ever had, and that it has raged with gradually increasing force from that time to the present. If you will take up the proceedings of
                Congress, you will see that in 1790 petitions were presented in favor of the abolition of slavery, and have been ever since ; and I ask the gentleman if Virginia or her politicians ever ratified or sanctioned that policy on the part of the Federal agents in Washington?
              </p>
              <p className="p-in-sp">
                My friend is a lawyer, and, I have no doubt, an able one; and his experience at the bar should have taught him better. When he examines a witness or refers to a matter in court, he ought to tell all <PageNumber num={744} /> about it, or his adversary will turn it against him. He refers to Virginia sanctioning and ratifying the Missouri Compromise line
                in 1820; but there is another fact connected with that which my friend omitted, and which is pregnant evidence to show that Virginia did not sanction and ratify it. That fact is, that in 1854, the first time Virginia got an opportunity to make a lick at it, both of her Senators, and all but one of her representatives, voted to repeal it, and did repeal it.
              </p>
              <p className="p-in-sp">
                Was that sanctioning or ratifying it? There it was on the record (and voted against when passed by 18 out of the 22 members of the House of Representatives from Virginia) , drawing a line of demarcation between the North and South. The South had been complaining of it for thirty years, as an encroachment on her Constitutional rights, and the first time she got a favorable opportunity
                Virginia went forward and assisted in blotting it from the statute books.
              </p>
              <p className="p-in-sp">Well, sir, I have shown you that Virginia never sanctioned this war of petitions against slavery carried on through Congress. If she has, I call upon the gentleman to show how, when and where, specifying it as he would in an indictment.</p>
              <p className="p-in-sp">
                But, says the gentleman, she sanctioned the compromise of 1850, that compromise, which, in my humble opinion, has done much to bring about the present condition of the country; by the unconstitutional, unjust and iniquitous admission of California, we were deprived of our equality in the United States Senate. The argument which the gentleman made to prove his assertion was an ad
                captandum one which I did not expect to hear from him, and I will meet it in the same style. He says that in 1852 both of the great political parties of Virginia met in convention and declared their satisfaction with it. I think, if my friend will look back to the record of that period, so far as these parties were concerned, he will see that they did not express satisfaction but
                simply acquiescence. There is a great difference between acquiescing in a thing which one cannot help, and expressing an honest bona fide satisfaction with it.
              </p>
              <p className="p-in-sp">
                The ad captandum fact which I bring up as an offset to the ad captandum argument of my friend, is this: In 1855, the distinguished gentleman from Princess Anne canvassed this whole State, for Governor. He was heard from the Atlantic's roar to the placid waters of the Ohio, on mountain tops and in valley. I know that in every speech he made, he denounced in unmeasured terms, the
                Compromise of 1850, especially the admission of California, the dismemberment of Texas, and the abolition of the slave trade in the District of Columbia. And with the full knowledge that these were his opinions, <PageNumber num={745} /> the people elected him by ten thousand majority. Was that a ratification of the Compromise of 1850?
              </p>
              <p className="p-in-sp">
                Another point which my friend made, struck me, coming from one who had just expressed his approbation of the whole system of governmental policy, as the most remarkable that I ever heard fall from the lips of a Virginia statesman. He declared that in his ultimatum which was to settle this question, and to give peace, and quiet, and tranquility to the country for ever, he would not
                sanction, by way of amendments to the Constitution, what was called guarantees of power, because that would admit the right of the minority, and not of the majority, to control the government, and that this was a government of majorities.
              </p>
              <div className="speaker" id="sp1849"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                My statement on that subject, was, that our government has its foundation in the virtue and intelligence of the people ; that the administrative force of the government must be lodged necessarily in the hands of a majority; that I could not see the propriety of undertaking to place the administration of the government in any other hands than those of a majority of the people; and
                that any attempt to build the administration of the Government on any narrower foundation than the virtue, and intelligence, and power of a majority of the people would lead to its overthrow.
              </p>
              <div className="speaker" id="sp1850"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">Exactly. I did not misunderstand my friend. He says that this is a government, the whole administrative authority of which is in the hands of a majority of the people. The administrative force. What is Lincoln but an administrative officer?</p>
              <p className="p-in-sp">But, sir, I utterly deny the proposition. It is entirely at war with the theory and practice of the government. Why, sir, what did the gentleman from Pittsylvania [<span className="persName">Mr. TREDWAY</span>]
                , and others, declare as a reason why we ought to be satisfied with and not resist the present administration of the Federal Government? Why, that although Mr. Lincoln was elected, a majority of a million of the votes of the people were cast against him. How could the gentleman say that this was the government of a majority, with that fact staring him in the face. The very aim, and
                end, and object of a government, is to protect the minority against the majority. A majority can always protect itself. If you give to the minority no guarantee of power to protect itself, against the aggressions of the majority, then your government is but a despotism of numbers, the worst of all despotisms—a government of strength and not a government of right. If my friend will
                turn to the Constitution, he will find that a minority of the people can break up the government whenever they please. It is a fact known to every intelligent man, that a majority of the Senate of the United States <PageNumber num={746} /> very frequently represents a large minority of the people of all the States. How can my friend say that this is a
                government of majorities? The Constitution says that Congress shall consist of a Senate and House of Representatives; that every State shall be entitled to two Senators, to be elected by the Legislatures thereof, and that each Senator shall have one vote. It says, further, that it shall require a majority of both Houses to constitute a quorum to do business.
              </p>
              <p className="p-in-sp">
                Now, just suppose, sir, that one half of the States should refuse to elect senators, there would be no quorum and the Government would crumble to pieces by the non-action of the States; and what power is there on earth to compel the States to elect Senators? None—none. Ah, but, sir, said Mr. Webster, the members of the Legislature have taken an oath to support the Constitution of the
                United States, and the Constitution says, they shall elect Senators. But suppose they refuse, what power is there to force them? Where is it in the Constitution? No, sir, it is not there.
              </p>
              <p className="p-in-sp">
                But, sir, take another view of the question. That Constitution declares that each State shall be represented in the Lower House by members elected by the people of the States. The people take no oath to support the Constitution, and suppose they refuse to elect members of the Lower House, suppose States containing a quorum refuse to elect; you can not compel them, and there is an end
                of the Government, and a minority of the States, the States—if you include the great States—New York, Pennsylvania, Ohio, and Virginia combining together, can do this, and thus break up the Government. As I have before said, the voters have not taken an oath to support the Constitution; and there is no power by which the Federal Government can come down to Virginia and compel the
                people to go to the polls and vote. If General Scott were to come among us with a force and go from one precinct to another to hunt up voters, and force them to the polls he would find his effort a vain and very fruitless thing. Thus, sir, I show a minority can administer and break up the government, if they see fit so to do. They have the power; and the fact that they have the power
                shows that our government is not one of majorities, as laid down by the gentleman from Augusta. This is all I shall say this evening.
              </p>
              <p><span className="persName">Mr. MONTAGUE</span> then moved that the Committee rise.</p>
              <p>The motion was agreed to.</p>
              <div className="speaker" id="sp1851"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
              <p className="p-in-sp">
                Mr. President—In obedience to the request of some of my constituents, I embrace this opportunity to present to the Convention the proceedings of two public meetings which have <PageNumber num={747} /> been recently held in the county of Bedford—one of them at the Cross Roads precinct, and the other at the Court House, on last Court day, after full
                notice, in the County newspapers. I am informed that the meeting at the Court House was a very large and enthusiastic one, and that the resolutions were adopted with but few dissenting voices. It is proper to state, also, that the officers and active participants in both of these meetings are recognized as among the most Highly respected and influential citizens of the county. I have
                only to add that it will afford me pleasure to conform my action upon this floor, in every particular, to the sentiments contained in these resolutions. I move, sir, that they be now read by the Secretary, laid on the table and printed.
              </p>
              <p>The motion was agreed to.</p>
              <p>The proceedings are as follows :</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>At a large and enthusiastic meeting of the people of Bedford county, on court-day, on motion, William G. Claytor was called to the Chair, and on motion of Wm. V. Jordan, Esq., John R. Thurman and L. A. Sale were appointed Secretaries of the meeting.</p>
                  <p>The following resolutions were then passed to the Chair, read and adopted with four dissenting votes:</p>
                  <p>
                    The people of Bedford county in General Meeting assembled, on the 25th March, in the year of our Lord one thousand eight hundred and sixty-one and of the Commonwealth the eighty-fifth, to consider of their rights and interests involved in the great struggle now pending between the people of the different sections of a once united but now broken Confederacy, after free conference
                    make known and declare :
                  </p>
                  <p>
                    1. That they recognize the fact, that seven out of the fifteen slave-holding States of our Confederacy for causes by them deemed just have resumed to themselves respectively all the powers previously delegated to the Federal Government, thereby effectually dissolving their political association with the other States, and, as separate, sovereign and independent communities, in the
                    exercise of the powers thus resumed, have adopted and established a new Federal Compact with the title of "Confederate States of America," "laying its foundation on such principles and organizing its powers in such form, as to them seemed most likely to effect their common safety and happiness."
                  </p>
                  <p>
                    2. That there is no power in the Government of the United States under the Constitution and laws made in pursuance thereof to make war upon the said Confederate States or upon any of them by reason of their separation from the other States, the establishment of a separate government for themselves, or their refusal to render obedience to the
                    <PageNumber num={748} /> Government of the United States; and any attempt on the part of the Government of the United States to make such war upon them or to enforce its laws within the limits and jurisdiction of the said Confederate States against their consent, would be flagrant usurpation of power, a direct invasion of the rights of the States,
                    and dangerous to the liberties of the American people.
                  </p>
                  <p>3. That the people of Virginia could never acquiesce in the exercise of such power for such purposes by the Government of the United States; but, impelled by a just and natural sympathy, and by a due regard to their honor, safety and plighted faith, they would unite with the Confederate States, and "with all the means in their power" make common resistance to a common wrong.</p>
                  <p>
                    4. That the State of Virginia, not disclaiming the right which she expressly reserved to herself when she "assented to and ratified" the Constitution of the United States to resume the powers granted thereunder whenever the same should be perverted to her injury and oppression, but being willing to postpone the exercise of the same until she had exhausted all reasonable means to
                    obtain for herself and her sister slaveholding States adequate constitutional guarantees against future "injury and oppression," for that purpose invited a Conference of all the States of the Union, expressly declaring to them and to the world, that it was "a final effort to restore the Constitution and the Union in the spirit in which they were established by the Fathers of the
                    Republic."
                  </p>
                  <p>
                    5. That this "final effort" having signally and notoriously failed in accomplishing the objects for which it was made, it becomes the duty as it is the right of the people of this State in Convention assembled to resume to themselves all the powers at any time heretofore delegated to the Government of the United States, and to concert proper measures to obtain from the other
                    States an equitable share of the common property.
                  </p>
                  <p>
                    6. That after such resumption of powers, proper efforts should be made to construct a Confederacy amongst all the slaveholding States upon such terms "as to them shall seem most likely to effect their common safety and happiness :" and if the said efforts should be successful, after the establishment of the said Confederacy, the concurrence of the parties to the compact being
                    first obtained for that purpose, such of the non-slaveholding States should be admitted into the Confederacy as may be willing to accept its terms.
                  </p>
                  <p>
                    7. That we earnestly remonstrate against any attempt in the Convention of the people of the State to disturb at this time the question <PageNumber num={749} /> of taxation and representation fixed by our State Constitution, as not contemplated in the call of the Convention, as hurtful if not fatal to the best interests of the Commonwealth,
                    calculated to produce and aggravate dissensions and divisions amongst our own people, impairing our own strength, inviting further aggression upon our rights from the Federal Government and the Northern States, and tending to excite and organize a revolutionary struggle between sections in the State.
                  </p>
                  <p>The meeting was addressed in eloquent Southern-Rights speeches by Edward C. Burks, and Wm. M. Burwell, Esq'rs., and much enthusiasm prevailed.</p>
                  <p>On motion,</p>
                  <p>Resolved, That the proceedings of this meeting be published in the newspapers of the county, and of Lynchburg, and a copy be transmitted to our delegates to be by them laid before the Convention.</p>
                  <p>On motion, the meeting adjourned.</p>
                  <p>WILLIAM G. CLAYTOR, Chairman</p>
                  <p>L. A. SALE, Secretaries</p>
                  <p>JNO. R. THURMAN,</p>
                </div>
              </div>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>PUBLIC MEETING IN BEDFORD</p>
                  <p>At a meeting of a portion of the citizens of Bedford, held at the Cross Roads, on the 23d of March, 1861, Grief R. Adams was called to the Chair and John Q. A. Wright appointed Secretary. The object of the meeting being explained, the following resolutions, offered by Dr. P. C. Sutphin, and duly explained by him, were unanimously passed.</p>
                  <p>
                    Whereas, the President of the United States in his inaugural address refused to recognize the secession of those States that have severed their connection with the Federal Government, declaring it his purpose, so far as it may lay in his power, to force said States to submit to Black Republican rule; and whereas Virginia stands honorably and legally pledged to resist
                    coercion—therefore, be it Resolved,
                  </p>
                  <p>1st. That as coercion is now the declared policy of the present administration, against which this State stands pledged, Virginia should at once be placed in an attitude to meet that policy, and this, in our opinion, can only be accomplished by resuming the powers which she delegated to the General Government.</p>
                  <p>2. Entertaining the belief, for the reasons just assigned, that we should at once withdraw from the Federal Government, we request our delegates in the Convention at Richmond, to use every honorable <PageNumber num={750} /> exertion to secure the passage of an ordinance declaring this State no longer a member of our present Confederacy.</p>
                  <p>
                    3d. That nothing has transpired proving that the people of the seceded States are other than friends to the people of this State, but on the contrary, the most abundant evidence exists exhibiting their utmost devotion and attachment to us, and in the event Virginia should secede therefore from the present Government, we feel no hesitation in declaring that it is both natural and
                    just, and in the strictest accordance both with honor and reason, that we should unite our destiny with theirs, rather than form another alliance with the people of the North—a people avowedly hostile to our institutions, and with whom, in spite of any present concessions adequate to the formation of a new government with them that they might grant us, we never really could
                    expect to harmonize upon the great and vital questions that now divide us as a nation.
                  </p>
                  <p>After the passage of these resolutions, Mr. Nathaniel Newsom offered the following, which passed nem con:</p>
                  <p>
                    Resolved, That a due regard to economy in the present embarrassed condition of the people of the State, as regards pecuniary matters, is an important consideration; and we would respectfully suggest to the Convention, therefore, the propriety of taking, at the earliest practicable moment, some definite and final action on the questions now before that body, in order that it may
                    adjourn, and thus relieve the Commonwealth of any further expense in this direction.
                  </p>
                  <p>On motion, it was ordered, that the proceedings of this meeting be published in our county papers and the Lynchburg Republican, and a copy be forwarded to our delegates in the Convention.</p>
                  <p>The meeting then adjourned.</p>
                  <p>GRIEF R. ADAMS, Chm'n.</p>
                  <p>JOHN Q. A. WRIGHT, Sec'y.</p>
                </div>
              </div>
              <div className="speaker" id="sp1852"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">
                I wish to present the proceedings of a public meeting recently held in the county of Amelia. The meeting was the largest I ever saw in the county, and with entire unanimity it adopted the following preamble and resolutions, which I ask to be read and referred to the Committee on Federal Relations. The resolutions advocate secession as the action of this Convention before it adjourns.
              </p>
              <p>The motion was agreed to.</p>
              <p>The proceedings are as follows:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>
                    At a meeting of the people of Amelia, held at the Court-house on the 28th March, 1861 (Court day), to take into consideration the present <PageNumber num={751} /> political condition of the country, and the want of definite action by the State Convention, now in session; on motion of John F. Wily, Esq., Dr. W. J. Cheatham<span
                      className="note"
                      id="Note74"
                    ><span className="noteLabel">2</span>The initials are given below as W. G.</span
                    >
                    was called to the Chair, and L. Masters appointed Secretary.
                  </p>
                  <p>The object of this meeting having been explained by Col. F. R. Farrar, the following resolutions were offered by Wm. Old, Jr., Esq.:</p>
                  <p>
                    Whereas, the former Union of the North American States has been practically dissolved, and the issue now presented to the people of Virginia is, whether they will unite with the States in which slave property is a leading interest, and by whom that property will be protected, or with States, a majority of which do not permit that property to exist within their limits, and are not
                    only hostile to it, but pledged not to protect it, therefore,
                  </p>
                  <p>Resolved, 1st. That the honor and interest of the people of Virginia demand that the present State Convention should place the State in a position to regulate its own destiny, and that, in our judgment, an immediate resumption of all sovereign powers, to which, as a free and independent Republic, this State is of right entitled, is the best mode of accomplishing this object.</p>
                  <p>2. Resolved, That the immediate secession of the State is necessary to free the people from the control of a government under which their property is endangered, and their freedom menaced.</p>
                  <p>
                    3. Resolved, That we consider any efforts, direct or indirect, which may be made by the Federal Government to treat the people of a seceding State as subject, in any manner, to its control, either by attempting to tax their persons or property, or to collect money from them by any device, or to hold by force, or to attempt to occupy forts or military positions by arms in such
                    States, are such acts of coercion and wrong as would require the people of Virginia to give their aid to the seceding States, and make armed resistance to the federal force.
                  </p>
                  <p>
                    4. Resolved, That the present State Convention was called by the people of Virginia for the specific purpose only of defining the relations of Virginia to the other States of the late Confederacy, and if it should adjourn or take a recess without any definite action intended finally to fix the relations of Virginia with the other States, it will have failed to perform the duties
                    imposed on it, and defeated, for the time, the wishes of the people of Virginia.
                  </p>
                  <p>Lewis E. Harvie, Esq., the member of the Convention from Amelia and Nottoway, being called upon addressed the meeting in relation to the policy of the State and the proceedings of the Convention. Upon <PageNumber num={752} /> the conclusion of Mr. Harvie's speech, the foregoing resolutions were unanimously adopted.</p>
                  <p>Wm. Old, Jr., Esq., then offered the following resolutions:</p>
                  <p>Resolved, That our late estimable member of the House of Delegates, Col. C. A. Crump, having declined a re-election, it affords us pleasure to offer him an expression of our high esteem and warm regard.</p>
                  <p>
                    Resolved, That in view of the existing crisis, we deem it of the utmost importance to be represented in the General Assembly, by a member who will reflect our views as expressed in the resolutions just adopted; and in order to secure such a member, we request the people of Nottoway to appoint delegates to meet delegates to be appointed by this meeting at the Junction, on such day
                    as may be designated by the people of Nottoway, for the purpose of selecting a candidate to represent Amelia and Nottoway in the next House of Delegates; and we intend to meet our fellow-citizens in that convention without reference to old party affinities (which we think should now be forgotten) , and with the sole view of uniting our efforts for the maintenance of the rights,
                    interest and honor of Virginia.
                  </p>
                  <p>Resolved, That the Chairman of this meeting appoint seven delegates from each magisterial district, to represent this county in the Convention proposed in the foregoing resolutions,</p>
                  <p>Which were unanimously adopted.</p>
                  <p>The following resolutions were offered by Dr. Joseph B. Anderson. and were unanimously adopted :</p>
                  <p>Resolved, That we tender our most cordial and sincere thanks to our late representative, the Hon. R. A. Pryor, for the eloquent address to which we have listened to-day, and for the faithful and able discharge of his duties in the last Congress of the United States.</p>
                  <p>Resolved, That we cordially and entirely approve of the course of our delegate in the State Convention, and we request him to present to that body the proceedings of this meeting.</p>
                  <p>On motion of G. C. Eggleston, Esq.,</p>
                  <p>Resolved, That the Richmond "Whig," "Enquirer" and "Examiner" be requested to publish the proceedings of this meeting.</p>
                  <p>The Chairman appointed the following delegates to the Convention to be held at the Junction:</p>
                  <p>District No. 1.—Dr. J. W. Tucker, F. R. Farrer, N. F. Wilson, Richard Johns, W. W. Fretwill, C. W. Farmer and J. M. Gill.</p>
                  <p>District No. 2.—Dr. J. B. Anderson, J. F. Wily, T. E. Whitworth, T. E. Barker, W. G. Haskins, Dr. M. F. F. Evans and J. Barns.</p>
                  <PageNumber num={753} />
                  <p>District No. 3.—W. H. Pollard, S. S. Weisiger, P. F. Boisseau, John</p>
                  <p>Wingo, J. G. Jefferson, E. R. Johnson and E. W. Eggleston.</p>
                  <p>District No. 4.—Dr. J. S. Jackson, J. S. Hardaway, T. W. Wealsh,</p>
                  <p>Jas. Eldridge, Jos. B. Dennis, Dr. W. C. Archer and R. B. Crowder. District No. 5.—R. F. Taylor, J. N. Coleman, John Harris, W. F. C.</p>
                  <p>Gregory, Richard A. Coleman, E. A. Featherston and J. B. Hamlin.</p>
                  <p>W. G. CHEATHAM, Chairman</p>
                  <p>L. MASTERS, Secretary</p>
                </div>
              </div>
              <p>PERSONAL EXPLANATION</p>
              <div className="speaker" id="sp1853"><span className="persName">Mr. HOLCOMBE</span>, of Albemarle—</div>
              <p className="p-in-sp">
                With the permission of the Convention I desire to make a statement in justice to my friend and colleague, <span className="persName">Mr. SOUTHALL</span>, and in reference to the proceedings of a meeting of our fellow-citizens held recently at Scottsville. Entertaining opinions somewhat different from those of <span className="persName">Mr. SOUTHALL</span> in our canvass, there is no
                gentleman in the county who took more special notice of every thing that fell from him in a public way—his written addresses to the people and also his oral communications—and it gives me pleasure to say here, that although I was not present at the meeting in Scottsville, I understood his general position during the canvass was precisely what he expressed it to be on the floor this
                morning. I understood, during the whole time, that it was not his intention to pledge himself to go out of the Union before the 4th of March or upon any definite day, but to declare generally that whenever he was satisfied, whether before or after the 4th of March, that such guarantees as he indicated in his address to the people, could not be obtained from the North, that then he
                would be prepared for an act of secession. I am sure he took every means to make known that position to the people of the county, and that the highly respectable gentlemen who composed this meeting at Scottsville were under a misapprehension—an innocent and honest one—but an entire misapprehension as to his views. I very much regret that the expression of those views and wishes of
                this late meeting (in which I most warmly sympathise) should have been made in any unkind way towards my colleague. I was, however, very much gratified to hear him say that he would respect the fairly ascertained and unquestionable sentiments of our constituents, however, their sentiments may be made known. Whatever difference of opinion may exist among us as to the general right of
                instruction, as to the duty of the representative under ordinary circumstances to obey instruction, the representative who is a member of a Legislature, with two houses, under oath to support a written constitution, and with a <PageNumber num={754} /> fixed and short term of office, and thus under the control of the people ; in a Convention like this
                whose members take no oath, whose session cannot be terminated by the people, and who are under no other responsibility than a moral one, the voice of the people distinctly proclaimed, ought to be irresistible.
              </p>
              <div className="speaker" id="sp1854"><span className="persName">Mr. BLAKEY</span>—</div>
              <p className="p-in-sp">I hold in my hand the proceedings of a meeting held in the county of Madison. I am told by gentlemen who were present that it was one of the largest meetings ever held in the county, and that the gentlemen who participated in it were of the highest respectability.</p>
              <p className="p-in-sp">
                My people have been as fondly devoted to the Union, and are now as devoted to it as any people within the limits of the Commonwealth. But, sir, the Union to which they were devoted was a Union maintained in the spirit in which our fathers made it. They are now devoted to such a Union, could it be maintained in accordance with that spirit. Its vitality, however, has fled, and since it
                has become worthless and failed to perform the functions for which it was instituted, they desire no longer to adhere to it. They have no attachment to a Black Republican Union, they have no desire to remain in a Union with the Northern Black Republicans. They are looking to another Union, they are looking earnestly and anxiously to the period when they shall be united with their
                sister States of the South, who have sustained the Constitution of our fathers and rescued it from the vandal grasp of the infidelity and agrarianism of the North, and who now hold it in its purity and perfection, and appeal to us and all the patriots of the land to rally around it and maintain it.
              </p>
              <p className="p-in-sp">It is for this that my people are struggling. It is for this that they mean to struggle, and with no less than this will they be satisfied.</p>
              <p className="p-in-sp">
                Sir, this Convention may send forth its paper bulletins to the North in the shape of ultimatums. They may be accepted by the North (I do not believe they will be), but, if accepted by the North, my people do not mean to submit to a Union with Black Republicans. But, sir, they have the utmost confidence in the patriotism of the people of Virginia, and they will appeal to that spirit
                of patriotism until the fires of liberty are kindled in every patriot heart, and the flaxen bands which bind us to this Union are severed, and severed forever. Then they will be for re-constructing a Union around the Confederate States of the South, in the spirit in which our fathers made the old Union which has passed away, by acting in unison with our Southern sisters.
              </p>
              <p className="p-in-sp">I will read the resolutions:</p>
              <PageNumber num={755} />
              <p className="p-in-sp">PUBLIC MEETING AT MADISON COURT HOUSE</p>
              <p className="p-in-sp">At a meeting of the people of the county of Madison, held at their Court House on Thursday, the 28th of March, 1861,</p>
              <p className="p-in-sp">On motion of Thomas P. Wallace, George Bouton was called to the Chair, and on motion of Dr. A. W. Graves, Thomas P. Wallace was appointed Secretary.</p>
              <p className="p-in-sp">
                The object of the meeting was explained by Genl. James L. Kemper, on whose motion the Chairman appointed a Committee consisting of Genl. James L. Kemper, Col. James W. Walker, Col. T. J. Twyman, Thos. J. Humphreys, and Dr. Asa W. Graves, to draft suitable resolutions for the meeting. The Committee retired, and in due time returned and reported the following resolutions, which were
                adopted after eloquent and able speeches by Col. Wm. H. Caruthers, of Bedford, and Gen. James L. Kemper.
              </p>
              <p className="p-in-sp">1st. Resolved, That the Union formed by the Constitution of the United States is dissolved.</p>
              <p className="p-in-sp">2d. Resolved, That we hold that Virginia stands relieved from all Federal obligations to any part or fragment of the late disrupted Federal Union, and in virtue of her reserved rights and by the irresistible force of revolution, she is of necessity thrown back upon her original rights of absolute sovereignty.</p>
              <p className="p-in-sp">
                3. Resolved, That the dissolution of the late Federal Union being an historical fact, Virginia stands alone without a Constitutional Federal head, and it would be not only unwise and inexpedient, by suicidal policy, on her part to elect a Congressional representative to the fragment of the Black Republican Government North! And the Southern rights people of the several counties of
                this Congressional District are hereby invited and earnestly requested to hold meetings and appoint delegates to a District Convention to be held at Charlottesville on Tuesday, the 7th day of May next, to consider and determine the action of the District at the ensuing general election ; and the Chairman of this meeting is authorized to appoint twenty-four Delegates to represent this
                county in said Convention.
              </p>
              <p className="p-in-sp">4th. Resolved, That this meeting utterly condemns the proposition for a border State Convention.</p>
              <p className="p-in-sp">5th. Resolved, That this meeting regards the project submitted by the late Peace Conference to Congress as a swindle and a fraud, and wholly insufficient to satisfy the demands of the slave States.</p>
              <p className="p-in-sp">6th. Resolved, That the true and proper association of Virginia is with the Confederate States of America.</p>
              <PageNumber num={756} />
              <p className="p-in-sp">7th. Resolved, That this meeting express their renewed confidence in the ability, fidelity and patriotism of Senators Mason and Hunter.</p>
              <p className="p-in-sp">8th. Resolved, That a copy of these resolutions be forwarded to the Delegate from this county to the Convention now in session in Richmond, with the request that he lay the same before that body.</p>
              <p className="p-in-sp">9th. Resolved, That the Southern Citizen and the papers published in Richmond, Alexandria and Charlottesville be requested to publish the proceedings of this meeting.</p>
              <p className="p-in-sp">In obedience to the third resolution the chairman appointed the following named gentlemen as delegates:</p>
              <p className="p-in-sp">
                Gen. James L. Kemper, Col. T. J. Twyman, Dr. Asa W. Graves, Thos. J. Humphreys, J. W. Fry, Col. J. W. Walker, R. D. Twyman. R. S. Thomas, W. 0. Fry, M. C. Stricker, Jno. J. Clore, N. W. Crisley, W. J. Cave, Gibbon S. Conway, John S. Kemper, General R. A. Banks, Dr. W. A. Hill, Acrey Berry, Hiram Yager, John P. Aylor, E. D. Fray, B. F. T. Conway, William L. Early, Thomas W. Chapman.
              </p>
              <p className="p-in-sp">On motion of Dr. A. W. Graves, the chairman and secretary were added to the list of delegates.</p>
              <p className="p-in-sp">Thomas P. Wallace then offered the following resolution which was unanimously adopted :</p>
              <p className="p-in-sp">Resolved, That we, the people of Madison county, having viewed with pride and pleasure the able and efficient manner in which our representative, Gen. Jas. L. Kemper, has discharged his duty as a representative in the General Assembly of Virginia, do hereby nominate him for re-election.</p>
              <p className="p-in-sp">On motion, the meeting adjourned.</p>
              <p className="p-in-sp">GEORGE BOUTON, Ch'n.</p>
              <p className="p-in-sp">THOS. P. WALLACE, Sec'y.</p>
              <p className="p-in-sp">I move that these proceedings be laid upon the table and printed.</p>
              <div className="speaker" id="sp1855"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">
                I don't understand these resolutions to contain any instructions or request to the delegate from Madison; but, as I heard them read, they contain a declaration that it would be degrading or improper—I don't recollect the precise language—for the people of Virginia to elect delegates to the next Congress, while they appoint delegates to a District Convention to be held at
                Charlottesville, for the purpose of taking that question into consideration.
              </p>
              <p className="p-in-sp">
                I want to know what this has to do with the proceedings of this body? I must enter my protest against having resolutions of this character printed at the expense of the Commonwealth. If they related <PageNumber num={757} /> to the proceedings of this body, or contained any request or instruction to the delegate from Madison, I should make no objection
                to their being printed; but inasmuch as they do not, I feel it my duty to vote against the motion to print.
              </p>
              <div className="speaker" id="sp1856"><span className="persName">Mr. BLAKEY</span>—</div>
              <p className="p-in-sp">
                I beg leave to remark that the resolutions do utter the sentiments of my people upon all the leading questions connected with this crisis. They did not send the resolutions here for the purpose of letting this Convention know what they were doing in their congressional district, the reference to that was merely incidental; but they sent them here to inform this Convention, and me as
                their representative, as to their views on the question of secession and of Union with the Confederate States, and of their condemnation of the border slave State Conference, and of the Peace Conference which assembled at Washington.
              </p>
              <div className="speaker" id="sp1857"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">
                I understand the resolutions to declare that this Union is already dissolved; that the propositions offered by the Peace Conference at Washington, were a swindle and a fraud, and for that reason the people of Virginia ought not to elect representatives to a Black Republican Congress, to assemble next winter. I do not understand the resolution to have any reference whatever to the
                action of this Convention. They neither advised, requested, or instructed their delegate as to the course he should pursue here. They contain a declaration that the Union is dissolved; that the Peace Conference has submitted propositions which are a swindle and a fraud, and that the people of Virginia ought not to elect members of Congress, to a Black Republican Congress, to assemble
                next winter. I want to know what right the people of Madison have to ask that we should put such resolutions as these upon our record. I should like to know if there is not a citizen of Madison, who is a candidate for a seat in this Black Republican Congress? I do enter my protest, and shall cast my vote against having these resolutions referred to the Committee on Federal Relations
                and printed at the expense of the State.
              </p>
              <div className="speaker" id="sp1858"><span className="persName">Mr. BLAKEY</span>—</div>
              <p className="p-in-sp">
                The gentleman seems to be very desirous of economising money for the State, and yet he has delivered a speech at the public expense which will amount to more than the cost of the publication of these proceedings. I apprehend there is something besides that which operates upon the mind of the gentleman. I presume he does not like to hear the expression of these patriotic sentiments
                from my people. The gentleman first votes to gag the representatives of the people, and now he is disposed to gag the people. I wish to know whether this Convention mean to give the resolutions which my <PageNumber num={758} /> people have sent here a different direction from that which is given to resolutions which have come up here from other portions
                of Virginia? My people speak clearly, distinctly and emphatically their opinions upon the leading questions of the day, and that is all their representative wants to know. He does not wait for them to instruct him in any particular form, and they do not see the necessity of complying with a particular form of instructions, as if they were writing out a deed to convey property or any
                thing else.
              </p>
              <div className="speaker" id="sp1859"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">
                If the people of Madison had sent any petition to this body in respectful terms; if they had sent any instructions to their delegate in respectful terms, I care not how much I may have disagreed with them, I never would have said one word against it, but I do protest against this mode of presenting resolutions which have no reference whatever to the action of this body. The gentleman
                talks about gagging representatives. Why the gentleman has had full and ample time to express his sentiments. If the people that he represents are satisfied with them, I am. God knows that I care very little what the sentiments of the gentleman from Madison are. He has a right to express his sentiments upon this floor, and he has undertaken to do so. When the people elected him, I
                take it for granted that they elected him knowing his sentiments. I care very little, after having elected him entertaining the sentiments that he does, that they shall reiterate their sentiments here; but I call the attention of the Convention to the fact that these resolutions do not have any relation whatever to the action of this body, and I do not see why we are to have entered
                upon the records of this Convention resolutions which only refer, so far as action is concerned, to the action of a District Convention, to be held at Charlottesville, in the county of Albemarle.
              </p>
              <div className="speaker" id="sp1860"><span className="persName">Mr. STAPLES</span>—</div>
              <p className="p-in-sp">If these proceedings, when ordered to be printed, are to be printed in the shape of a document, and not in the columns of the paper, then I am opposed to it. It is taking an extraordinary course, and one which I believe has not heretofore been permitted.</p>
              <div className="speaker" id="sp1861"><span className="persName">Mr. BLAKEY</span>—</div>
              <p className="p-in-sp">I only ask that they shall take the same direction that the proceedings of other meetings have taken. Such, for instance, as was given the proceedings of the meeting in the county of Bedford.</p>
              <div className="speaker" id="sp1862"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">If this motion prevails, these proceedings will be printed in the columns of the "Enquirer," as a part of the proceedings of the Convention.</p>
              <div className="speaker" id="sp1863"><span className="persName">Mr. BLAKEY</span>—</div>
              <p className="p-in-sp">I desire again to correct the gentleman from Franklin [<span className="persName">Mr. EARLY</span>]
                . These resolutions do speak to this Convention upon the question of the connection of Virginia with the Federal Government, <PageNumber num={759} /> and they indicate clearly and distinctly what the opinion of my people is as to what this Convention should do, both with reference to dissolving their connection with the present Black Republican
                Government at Washington, and also, as to what they should do in reference to their connection with the Confederated States of America. I am sorry to trespass upon the patience of the Convention so much.
              </p>
              <p className="p-in-sp">
                I will correct the gentleman in another respect. The gentleman says that there is a candidate from the county of Madison to represent that District in Congress. Now, sir, if that be so, the gentleman is better informed in that respect than I am. From information which I have the gentleman must certainly be mistaken about it. There is no candidate for Congress from the county of
                Madison that I know of.
              </p>
              <div className="speaker" id="sp1864"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">
                The disposition which the gentleman from Madison, asks that these resolutions may take is the same that the resolutions from the county of Orange, and every other county from which proceedings have been presented have taken. I can see no reason why the people of the county of Madison—than whom no truer and more patriotic people exist in the Commonwealth of Virginia—should not be
                entitled to the same respect that the constituents of the gentleman from Franklin should receive. That these resolutions are unacceptable to the gentleman from Franklin is a fact by no means remarkable, because if there is a gentleman upon this floor who would be a Union man under all circumstances, I look upon the gentleman from Franklin as that man; and although he represents, as I
                understand, one of the largest slaveholding constituencies east of the Blue Ridge, I regard him as even more determined to stick to this Northern Union than my distinguished friend from the county of Kanawha
                [<span className="persName">Mr. SUMMERS</span>] .</p>
              <p className="p-in-sp">
                These resolutions refer to the very questions which are upheaving this Union. They express the sentiments of a patriotic and indignant constituency. They express the opinion that Virginia and Virginians, should never rest satisfied with a Union with the North to the exclusion of the South, and although they say that they will never submit to whatever ultimatum may be presented to and
                accepted by the North, which detaches them from the cotton States of the South, they do not say they will enter into any revolution; but they say they will appeal to the hearts and patriotism of Virginia, until a fire is kindled in every bosom that will consume the bands that bind us to the North. If it be so very exceptionable to the gentleman from Franklin that a resolution, in
                reference to a Congressional election, should be embodied in these resolutions; if he is so economical of the <PageNumber num={760} /> monies of the Commonwealth, and is afraid of the expense that will be entailed upon it by the publication of these proceedings in the usual form, I presume it will not be a matter of very great moment to my friend from
                Madison, to have that resolution stricken out. I shall not, however, make the motion. I take it for granted that the constituents of the gentleman from Madison are entitled to the same respect as the constituents of any other gentleman upon this floor.
              </p>
              <div className="speaker" id="sp1865"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">
                I was not present when this question came up first. I hope the gentleman from Franklin, in his zeal to exclude any matter going before the people of Virginia involving those important questions discussed by the people of Madison, will not attempt to make objection, after voting, as I understand it, for Black Republican resolutions to be printed, which were presented by the gentleman
                from Marshall
                [<span className="persName">Mr. BURLEY</span>] .</p>
              <div className="speaker" id="sp1866"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I am not aware that any such resolutions were presented by any member of this body.</p>
              <div className="speaker" id="sp1867"><span className="persName">Mr. HALL</span>—</div>
              <p className="p-in-sp">If the gentleman will refer to the proceedings a few days since, he will find them. The gentleman from Marshall presented resolutions here, which were ordered to be printed, which declared unequivocally and emphatically, that Virginia desired no additional guarantees.</p>
              <div className="speaker" id="sp1868"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">
                I understood the gentleman from Madison as reading his resolutions in the course of his remarks. They would, as a matter of course, have been incorporated in his remarks and been published in the "Enquirer," and it, therefore, seems to be an unnecessary objection to the motion that these proceedings lie on the table and be printed. I hope all opposition to the motion, therefore, will
                be withdrawn and that we shall now adjourn.
              </p>
              <div className="speaker" id="sp1869"><span className="persName">Mr. BORST</span>—</div>
              <p className="p-in-sp">
                I wish to call the attention of the Convention to the fact that the gentleman from the neighboring town of Petersburg a short time ago stated that he was instructed as to what course he should pursue in this Convention, and I here tell the gentleman from Franklin that if he will read the resolutions adopted in that town and the resolutions there voted upon he will not find one word
                of instruction to the gentleman representing that district. Were not these resolutions brought here and presented for the consideration of this Conventi6n? And were they not ordered to be printed, as of right they should be? And shall we not print the resolutions presented from the county of Madison, which breathe the spirit of freedom and which express their views of our Federal
                Relations? And shall we discard the views of that noble, brave and generous people, who know their <PageNumber num={761} /> rights and who knowing them, I have no doubt will maintain them? I hope that these resolutions will take the same course that resolutions of a similar character have already taken and that this Convention will order them to be
                printed.
              </p>
              <p>The resolutions were then laid on the table and ordered to be printed.</p>
            </div>
            <div className="section" id="vsc1965.v2.2.13.5">
              <h3><span className="headingNumber">1.13.5. </span><span className="head">PERSONAL EXPLANATION</span></h3>
              <div className="speaker" id="sp1870"><span className="persName">Mr. NELSON</span>—</div>
              <p className="p-in-sp">I desire to make a correction of my remarks as they appear published in the "Enquirer." The reporters have not only made me use bad grammar in English—that I could have stood—but they have made me also to appear somewhat ignorant. They have made me say that I have read Adam Smith's essay. I spoke of Adam Smith, Say, and other works on political economy.</p>
              <p className="p-in-sp">But worse than this, they make me say:</p>
              <p className="p-in-sp">"Like Triptolemus Yellowlegs, in one of Scott's novels, the carts and the cart horses make it all, but the carts and the cart horses eat it all." [Laughter.] When it should read:</p>
              <p className="p-in-sp">"The caries and the cart havers make it all, but the caries and the cart havers eat it all."</p>
              <p className="p-in-sp">Again: They make me use bad Latin, in an appeal which I make to the ladies; and being a Master of Arts of the University of Virginia, independent of any personal feeling I may have in the matter, I have some regard, at least, for the reputation of my Alma Mater.</p>
              <p className="p-in-sp">In the quotation which I give from Virgil, they make it read:</p>
              <p className="p-in-sp">"Tantcene inx animus ccelestib," cutting off the "us." In connection with such celestial beings, I insist, as I always do, on claiming the "bus."</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp1871"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">At the request of one of the reporters, and as a matter of justice to them, I desire simply to remark that the speech of the gentleman was set from manuscript furnished by himself.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp1872"><span className="persName">Mr. NELSON</span>—</div>
              <p className="p-in-sp">I know that I write a very bad hand indeed, but I took good care to go to the printing office, and caution the printers to take care of the Latin and French. [Laughter.] On motion, the Convention then adjourned.</p>
            </div>
            <PageNumber num={762} />
          </div>
        </section>
      </div>
      <div className="tei_back" id="v2back">
        <section className="back" id="vsc1965.v2.3">
          <PageNumber num={764} />
          <header>
            <h1><span className="headingNumber">Appendix A </span><span className="head">NOTES</span></h1>
          </header>
          <div className="notes" id="vsc1965.v2.3.1">
            <PageNumber num={765} />
            <h2><span className="headingNumber">Appendix A.1 </span><span className="head">NOTES</span></h2>
            <p>The notes below are numbered 1 and upwards for each day of Convention Proceedings.</p>
            <p>Dates in parentheses are dates of issues of the Enquirer which contain editorial corrections for its reports of Proceedings.</p>
            <p>Page numbers in citations of Proceedings are numbers of pages in this edition.</p>
            <p>References to Documents, and to Journal or Convention Journal. are references to the printed official Documents of the Convention, and to the printed Journal of the Acts and Proceedings of a General Convention of the State of Virginia ... (Richmond : Wyatt M. Elliott, Printer, 1861) .</p>
            <p>TWENTY-EIGHTH DAY</p>
            <p>Monday, March 18</p>
            <p>1. A correction (March 21) of Mr. Early's sentence reads thus:</p>
            <p>"Why, sir, if he [the gentleman from Northampton] can succeed in making that bargain with the gentlemen from the North-west, what would become of my friends from Pittsylvania and Halifax, who came here Union men, representing the largest slaveholding counties in the State?"</p>
            <p>2. Mr. Branch's calculations at this point appear incomprehensible.</p>
            <p>3. This speech by Mr. Woods was printed in the Enquirer as if entirely contained in the Proceedings of March 19. However, the Daily Dispatch published, on March 19, an abstract of the first three paragraphs of the speech, and related proceedings, as part of the Proceedings of March 18.</p>
            <p>4. Mr. Randolph's entire speech is given in the Proceedings of March 16. See note 3 on those Proceedings.</p>
            <p>TWENTY-NINTH DAY</p>
            <p>Tuesday, March 19</p>
            <p>1. This version of the resolution differs from what Mr. Hall had read out when he proposed the resolution. Probably the version on page 32, which agrees with the version given in the Journal, is correct.</p>
            <p>2. A correction (March 21) notes that the resolution regarding a night session of the Convention was offered by Mr. George W. Hull, of Highland, and not by Mr. Hall.</p>
            <p>3. It appears that the first three paragraphs of Mr. Woods' speech were delivered on March 18. See note 3 on Proceedings of that day.</p>
            <p>4. Mr. Randolph's speech is given in the Proceedings of March 16. See note 3 on those Proceedings.</p>
            <PageNumber num={766} />
            <p>THIRTIETH DAY</p>
            <p>Wednesday, March 20</p>
            <p>1. The delivery of Mr. Holcombe's speech was begun on March 20 and concluded on March 21, but the speech was printed as a continuous text, with no indication of the point at which the break was made. The whole speech is printed here as part of the Proceedings of March 20.</p>
            <p>2. Perhaps the tag should be Contemptus famce, contemptus virtutis.</p>
            <p>3. The Journal has "Camp-Hill School House."</p>
            <p>4. Perhaps this last word ought to be "unacceptable" or "unallowable."</p>
            <p>5. The pronoun "it," or some noun, must have been left out here.</p>
            <p>THIRTY-FIRST DAY</p>
            <p>Thursday, March 21</p>
            <p>1. This word should perhaps be "array."</p>
            <p>2. Mr. Holcombe's entire speech is given in the Proceedings of March 20. See note 1 on those Proceedings.</p>
            <p>3. In the Proceedings of March 25, page 299, Mr. Carlile asked to have the words "citizens of" inserted before "the several states."</p>
            <p>4. The Chair referred to his remarks in the Proceedings of March 15, pages 700-701. As there seems to be no substantial difference between the two statements, the Chair may have been finding fault with a report in some other paper than the Enquirer.</p>
            <p>
              5. In the Proceedings of March 22, page 160, Mr. Carlile complained that his remarks of the day before were badly reported in the Enquirer. He added that he had written out a correction, which would be published in the Whig for March 23. In the Richmond Daily Whig for March 23, Mr. Carlile published a version of his remarks which combined what he said in this and subsequent paragraphs
              into an apparently continuous speech. The two versions show a considerable number of minor differences in wording and arrangement of sentences, but no substantial difference in meaning between the two versions can be discerned.
            </p>
            <p>THIRTY-SECOND DAY</p>
            <p>Friday, March 22</p>
            <p>1. The Journal gives the hour as 10:30 A.M.</p>
            <p>2. Mr. Carlile refers to his remarks in the Proceedings of March 21, pages 154159. See note 5 on those Proceedings.</p>
            <p>3. Mr. Hall corrected this statement in the Proceedings of March 25, page 249.</p>
            <p>4. Something seems to have been lost from the beginning of the sentence, such as "Send" or "Take"; or perhaps "go" was omitted after "diamond."</p>
            <p>THIRTY-THIRD DAY</p>
            <p>Saturday, March 23</p>
            <p>1. Mr. Hall corrected this statement in the Proceedings of March 25, page 249, and again in the Proceedings of March 26, pages 341-342.</p>
            <PageNumber num={767} />
            <p>2. See note 1, above.</p>
            <p>3. Mr. Boisseau corrected this statement in the Proceedings of March 25, pages 249-250.</p>
            <p>4. Below, the chairman's initials are T. L., and the secretary's are T. W.</p>
            <p>5. On March 26, the Enquirer published the following account of the compliment paid to the member of the Convention:</p>
            <p>More Northern Influence.</p>
            <p>Mr. Baldwin, of Augusta, concluded, on Saturday last, his three days' anti-Southern speech.</p>
            <p>Before the adjournment of the Convention, Mr. Price, of Greenbrier, announced that so soon as the Convention should adjourn, some of the "ladies of Richmond" desired the use of the hall for the purpose of "paying a compliment to a member of the Convention" and that members were requested to keep their seats, to witness the ceremony.</p>
            <p>
              Accordingly, so soon as the President declared the adjournment, Mr. Critcher, of Westmoreland, called upon Mr. Baldwin to stand forth. Mr. Baldwin stood forth. Mr. Critcher produced a large wreath of flowers, made a short speech complimentary to Mr. Baldwin, and read (or sang) some verses written by a lady. Mr. Critcher finally presented the wreath and the verses to Mr. Baldwin. A
              small portion of the audience feebly applauded. Mr. Baldwin replied with a short, and certainly a better speech than he had made in Committee of the Whole. A small portion of the audience cheered vociferously. After all which, the audience dispersed.
            </p>
            <p>So much for so much. The moral of the fable requires explanation. We are credibly informed that the contribution for the purchase of the wreath and the arrangement for its presentation were instigated and mainly achieved by Northern ladies, and the verses were written by a lady from New England.</p>
            <p>Mr. Baldwin's anti-Southern prose complimented by versification of New England manufacture! Decidedly appropriate!</p>
            <p>THIRTY-FOURTH DAY</p>
            <p>Monday, March 25</p>
            <p>1. The words "the treason" are in the Enquirer. The sense seems unclear, but the speaker may have been quoting something unidentified.</p>
            <p>THIRTY-FIFTH DAY</p>
            <p>Tuesday, March 26</p>
            <p>1. Mr. Preston's speech has not been found in the Enquirer. On March 27, the Daily Dispatch published this abstract of the proceedings covering Mr. Preston's speech:</p>
            <p><span className="persName">Mr. NELSON</span> then yielded the floor to <span className="persName">Mr. PRESTON</span>, of Montgomery, who proceeded to address the Committee.</p>
            <PageNumber num={768} />
            <p>
              After alluding to the great responsibility resting upon him as a member of the Convention he went on to consider the practical issue now before the country. For years there had been a regular course of sectional hostility, which excited the apprehensions of all of us. The election of last November resulted in a manner that shook the Commonwealth to its very centre, and the people took
              the necessary measures for meeting the crisis, and had sent a Convention here clothed with powers to make and unmake. The aggressions upon the South were no longer to be borne, and he for one was ready to repel them. Virginia, on the 4th of February, was not ready for disunion, but she took a prompt and decided stand against any measures of coercion or force. When we met here, the
              choice and purpose of Virginia was to have proper amendments to the Constitution, with a view to restore the Union upon a basis to secure permanent protection to her rights. It was further her purpose, in case no sufficient guarantees can be secured, to withdraw from the Union, and protect herself out of it. What was the proposition of the committee? If these permanent guarantees
              cannot be secured, then, thank God, we have a power to secure our own rights by withdrawing from the Union. He then alluded to the determination of the Commonwealth to stay all impediments by way of coercion on the one side or precipitation on the other. While the representatives of the people could deliberate with the calmness of peace, and if possible restore the Union. The
              Convention interposed its moral power—saying to the Federal Government, you must not use measures to coerce those who have seceded from the Union; you must not possess yourself of the forts or the commerce. She said to her sister, South Carolina, let there be no war; it would be hurtful to those who are deeply interested in your institutions, but are not so impulsive, and want time to
              reflect. They had paused. The Government had acknowledged its inefficiency, and we were not now in the danger that we had previously been. He could not say how long it would be so, though he hoped it might be permanent. But we were here to-day, with no external cause for alarm, and enabled to deliberate calmly upon all subjects of agitation.
            </p>
            <p>He could not agree with those who said this was the moment to secede. It was the very time when we ought to go to work to carry out the wishes of the people, as expressed at the polls on the 4th of February last.</p>
            <p>Proceeding to discuss the propositions of the committee, he said there must be thorough and efficient guarantees of equality in the Government, and of protection in regard to the institution of slavery. He asked his friend from Albemarle [<span className="persName">Mr. HOLCOMBE</span>]
              if, when those guarantees were secured, we should not have done all our constituents required? Guarantees of principle, and guarantees of power were the covenants to be considered. He believed that physical guarantees were not demanded. The guarantee of Magna Charta was at this moment stronger than ever before. Where was the guarantee, except by amendments of the Constitution, that had
              been for a moment thought of? He alluded to the exceptions of the dual Executive suggested by Mr. Calhoun, and to the dual Judiciary foreshadowed by Mr. Hunter, as checks to be interposed.
            </p>
            <PageNumber num={769} />
            <p>He asked how such checks were to be enforced on a party that recognized no influence of moral power? The false key of the higher law would still be used to unlock the casket and steal from it its treasures. Whenever any gentleman—the gentleman from Princess Anne or the gentleman from Albemarle—could show anything tangible, he would be ready to accept it.</p>
            <p><span className="persName">Mr. WISE</span> protested against being mingled with these dualities. He would say to the gentleman that a human head had been thinking of these guarantees of power, and at a proper time would show how they could be made practicable.</p>
            <p><span className="persName">Mr. PRESTON</span> said the gentleman from Princess Anne stood alone on his own platform. He was too slow in the enforcement of his guarantees of power.</p>
            <p><span className="persName">Mr. WISE</span> said he was obliged to be slow, for he had very heavy weights to drag after him. [Laughter.] <span className="persName">Mr. PRESTON</span> thought it would be better to press forward with his own burden, and leave others to take care of themselves. He [<span className="persName">Mr. PRESTON</span>]
              could see no efficiency in any guarantees, except by suitable amendments to the Constitution. The first section of the committee's report, he conceived, covered the question of exact equality, and the North must respond aye or no to it. If the response is "aye," you have a guarantee of faith for the full and ample protection of the institution in which we are vitally interested. The
              speaker continued to discuss in a general manner, and somewhat in detail, the propositions of the committee, taking ground similar to those who had preceded him in the advocacy of the report.
            </p>
            <p>
              After pointing out the efficiency of the remedies proposed, he said the cry of "inadequacy" still came up; we still heard of the deep-seated hatred and sectional hostility existing at the North. He asked if we were to get rid of this hostility by flying from it. Due weight, he thought, should be given to the conflicting passions in Congress, which had given an impulse to sectional
              hostility. The slave question, he argued, was at the bottom of it all, but if this cause of agitation could be cut off, much would be done towards restoring the friendly feelings of the two sections. Everything was favorable now to a submission to the demands we were to make of the North. It was not to be made in a crouching or an humble attitude; but we were to make, for the first
              time, an appeal to the Northern people—not to the politicians; and if they were not granted within a reasonable period, we separate from our brothers of the past, and separate forever. This was his idea of true chivalry—to make of them a firm demand, not in the language of bravado; but to say to them, if you accept, well; if you do not accept, well. Recurring again to the report, he
              read the demands therein contained. The test is thus proposed to the North to be voted upon. If they say "aye," said <span className="persName">Mr. PRESTON</span>, I will accept them as a repentant people. Everything, he thought, was auspicious for a full, clear, and unequivocal settlement. They have now an empire, but sit with a barren sceptre in their hand. Reason will regain its sway,
              and Virginia's demands will meet a favorable response. Dishonor, disgrace, and overthrow is now upon them. Their counsels are distracted
            </p>
            <PageNumber num={770} />
            <p>and their party divided. The Government is hesitating and tottering in its own weakness, under the certain doom which this Convention pronounces, unless those demands are submitted to which will restore the prosperity of the whole country.</p>
            <p>
              The propositions are to be sent to the North immediately upon the adjournment. The Conference at Frankfort was not to be entrusted with the settlement of the matter. This power belonged to the sovereign people of Virginia. He had come here for the purpose of carrying out their will. If it became necessary to divide, after a full and fair consultation with the border slave States, then
              we could go out peacefully and quietly. Although the propositions presented might not be so full as he could desire—if anything better came up, he would be ready to accept it—he still thought they were the best we could do. He came from the West, and in the name of his loyal people was prepared to make a demand for full security of their rights. He did not conceive it to be his duty to
              consult the wishes of the North in making demands, but the wishes of the people of Virginia. He urged gentlemen not to say, where Liberty is, there is my country; but say where my home is, there shall Liberty be.
              [Applause.]
              He closed with an appeal to all to stand by the Commonwealth, and to vindicate every right that belongs to her. His ancestors had fought and died in her defence, and with the help of God this should be his destiny. If, said he, I fall elsewhere, I ask to be brought back to the consecrated earth of his mother Virginia—to be buried in his own meadow. Then it may be said of Ballard
              Preston, "after life's fitful fever he sleeps well." He would go to the North or to the South, or to the judgment seat of God, in vindication of the rights of Virginia.
              [Applause.] <span className="persName">Mr. WISE</span>, of Princess Anne, next addressed the Convention. After an allusion to the eloquence of the gentleman who had preceded him, he said he did not feel as if he was standing alone. The gentleman stood near enough to call him his own, with the gentleman from Halifax [<span className="persName">Mr. BRUCE</span>] . He then went on to discuss the propositions, which he contended not only provided for dual, but triple guarantees of power.</p>
            <p><span className="persName">Mr. PRESTON</span> conceded this, but said the gentleman had shown the justness of his argument by pointing out the guarantees demanded by amendments to the Constitution. <span className="persName">Mr. PRESTON</span> reiterated the argument which he had made.</p>
            <p>
              <span className="persName">Mr. WISE</span> proposed to show that there were other guarantees of power, which the committee had not made, but which were perfectly practicable. He would say to the gentleman, in the friendliness of a brother, that if he could see no other tangible guarantee, he had reached an immedicable condition. Was the privilege of catching a runaway negro nothing? Was
              the privilege of going to the Territory of Kansas, with your property, nothing? They proposed to pay for fugitive slaves; but money was no compensation for the surrender of a principle. The Northern people were in league with the fanatics of Exeter Hall, in endeavoring to destroy our institutions. Was there no remedy for that?
            </p>
            <PageNumber num={771} />
            <p><span className="persName">Mr. PRESTON</span> re-stated his position. He had said that no guarantees had been proposed on the freedom of the press and freedom of speech; if they were proposed, he would be ready to accept them. He did not stand with the gentleman, but if he had such guarantees he would like to have them.</p>
            <p><span className="persName">Mr. WISE</span> had none to propose.</p>
            <p><span className="persName">Mr. PRESTON</span> said he thought he had them in his breeches pocket, and that he was about to be called on to accept them. He [<span className="persName">Mr. P</span>.] proposed to quiet agitation by removing it entirely from the halls of Congress.</p>
            <p>
              <span className="persName">Mr. WISE</span> said that the whole effort was then to be given up; that acknowledging they could not still the agitation, they could only stand by and cry "hush." He had, in his report, made fifteen specifications, eleven of which he believed were entirely practicable. He would make this appeal to the North, and if they did nothing, he would stand with the
              gentleman and fight and die upon our own soil. No host of Yankees could ever drive him from his hearthstone.
            </p>
            <p><span className="persName">Mr. PRESTON</span> made a brief rejoinder. The difference between the gentleman from Princess Anne and himself was that he [<span className="persName">Mr. W</span>.] had no hope, while he had hope from both sections, and would never give up until time and blood shall set a seal upon the efforts in behalf of the Union.</p>
            <p>2. Mr. Nelson's speech was printed as a continuous whole, with no indication of the point at which it was interrupted by the recess. The entire speech has been put in the Proceedings of the morning session.</p>
            <p>3. Mr. Nelson corrected this allusion in the Proceedings of April 1, page 751.</p>
            <p>4. Mr. Nelson corrected this reference in the Proceedings of April 1, page 751, and again in the Proceedings of April 3, page 56.</p>
            <p>5. Mr. Nelson corrected this quotation in the Proceedings of April 1, page 751, and again in the Proceedings of April 3, page 56.</p>
            <p>6. In this letter (March 30) Mr. Fisher offered several corrections to his remarks at this point:</p>
            <p>CONVENTION, March 28, 1861</p>
            <p>To the Editors of the Enquirer:</p>
            <p>
              I regret to be obliged to say that in the publication of the remarks which I made on Tuesday the 25th instant, in your paper of this morning, injustice, unintentionally doubtless, is done by the reporter of the sentiments I expressed. I stated, in commencing my remarks, that I had a great admiration for the constitution which has been adopted by the Confederated States, not
              "veneration." But this is a matter of small moment. I remarked that the commission which I bear did not authorise me to look now to a reconstruction of the Union, because I do not believe there is the remotest hope of that; yet, as I believe a majority of the Convention did look to that, we should demand such guaranties as will be satisfactory to the Cotton States, and as would restore
              them to the
            </p>
            <PageNumber num={772} />
            <p>
              Union, if granted. In another connection I affirmed that the States are as sovereign and independent, to-day, as they were on the 1st day of September, 1787, not 1777, meaning thereby to declare that they are as sovereign and independent now as they were under the old Articles of Confederation. And I never spoke of seventeen slave States, but I did of seven, as being associated now
              with nineteen free soil States. Nor did I ever speak of offering any proposition of any sort for a reconstruction of the Union. I believe that any man who entertains the opinion now that the Union can be reconstructed, and the Cotton States brought back into it, possesses a faith that can remove mountains.
            </p>
            <p>I have not attempted, nor shall I, to correct other errors, some of which are apparent, to be found in the report of my remarks.</p>
            <p>Very respectfully, MIERS W. FISHER</p>
            <p>7. Both Proceedings and Journal state that there were 76 negative votes. However, the Proceedings list only 73, while the Journal lists 74, adding Hammond to negative votes listed in the Proceedings.</p>
            <p>8. Both Proceedings and Journal state that there were 63 negative votes. However, the Proceedings list only 61, while the Journal lists 62, adding Carter to negative votes listed in the Proceedings.</p>
            <p>9. Both Proceedings and Journal state that there were 54 negative votes. The Proceedings list only 52, while the Journal lists 53, omitting Armstrong from, and adding Baldwin and Barbour to, the negative votes as listed in the Proceedings.</p>
            <p>10. Mr. Early corrected the account of this passage with Mr. Harvie in the Proceedings of March 28, page 504.</p>
            <p>11. The Journal states that there were 5 negative votes; it does not include Brent, but does include Wilson.</p>
            <p>12. A correction (March 30) notes that Dr. R. H. Cox voted with the nays, and not with the ayes. The Journal states that there were 74 affirmative votes, although it lists but 72: it omits R. H. Cox, and Critcher, but adds Forbes, Johnson and White to names given in the Proceedings.</p>
            <p>13. The Journal also states that there were 78 negative votes, and adds Hammond and Holladay to those listed here.</p>
            <p>14. A correction (March 30) reads as follows:</p>
            <p><span className="persName">Mr. CHAPMAN J. STUART</span> made the following explanation of his vote on the substitute of <span className="persName">Mr. HALL</span> of Wetzel:</p>
            <p>
              Mr. President: I desire to correct the report of the proceedings of the Convention on the evening of the 26th instant, so far as it relates to my vote in Committee of the Whole, while the substitute of the gentleman from Wetzel was under consideration. During the many ballotings on the various motions, I paired off with <span className="persName">Mr. MORRIS</span>, who, from indisposition,
              had to leave the Convention. I did not vote after that, until it became necessary for me to vote in order to make a quorum. Having necessarily to vote for the purpose indicated, I invariably explained that
            </p>
            <PageNumber num={773} />
            <p>
              having paired off with <span className="persName">Mr. MORRIS</span>, honor required me, if I voted, to vote as I supposed <span className="persName">Mr. MORRIS</span> would vote, if present, and so recorded my vote with the minority. This was true until the final adoption or rejection of the substitute was to be voted on. It then again became necessary for me to vote, in order to make a
              quorum. I then explained to the Committee that I did not know how <span className="persName">Mr. MORRIS</span> would vote upon the substitute, but that I could not, under any circumstances, record my vote for the substitute, and I appealed to the Chair to know if the Committee could not excuse me from voting, and by so doing record my presence, thereby making a quorum. The Chair decided it
              could not be done. I then said that I voted my own sentiments without knowing what <span className="persName">Mr. MORRIS</span>' were, and voted no.
            </p>
            <p>Although the form of the correction indicates that it was made in the Convention and therefore was a part of the actual Proceedings, the point at which the correction was offered has not been determined.</p>
            <p>THIRTY-SIXTH DAY</p>
            <p>Wednesday, March 27</p>
            <p>1. Probably "dependent" has been omitted after "corporations."</p>
            <p>2. Probably "friend" has been omitted after "distinguished."</p>
            <p>3. The sentence seems to be incomplete.</p>
            <p>4. This should be Kentucky.</p>
            <p>5. Some part of this parenthetical remark seems to be missing.</p>
            <p>6. There seems to be either some omission, or a change of construction in this sentence.</p>
            <p>7. The Enquirer omits from the affirmative votes the name of Dorman, which is found in the Journal.</p>
            <p>THIRTY-SEVENTH DAY</p>
            <p>Thursday, March 28</p>
            <p>1. The chairman's name is given below as Dillard.</p>
            <p>2. This is evidently a mistake, and should read "sovereignties."</p>
            <p>3. This word probably should be "deprives."</p>
            <p>4. Part of Mr. Seawell's argument has apparently been left out.</p>
            <p>5. The text is given thus in the Enquirer. Mr. Tredway seems to have lost the thread of his argument.</p>
            <p>6. The Journal also gives the total of negative votes as 74, but lists the same 77 names given here.</p>
            <p>7. Mr. Fisher corrected these remarks in the Proceedings of April 2, pages 8-10.</p>
            <p>8. Mr. Conrad was the delegate referred to.</p>
            <p>9. Graham's name probably should be only among the affirmative votes, as it is in the Journal.</p>
            <p>10. The Journal refers to 37 affirmative votes, and adds Parks to the names here. The Journal refers to and lists 90 negative votes.</p>
            <PageNumber num={774} />
            <p>THIRTY-EIGHTH DAY</p>
            <p>Friday, March 29</p>
            <p>1. This speech by Mr. Rives has not been found in the Enquirer. The abstract below was printed in the Daily Dispatch of March 30:</p>
            <p>
              <span className="persName">Mr. RIVES</span>, of Prince George, said it was not his purpose to answer the arguments of the various speakers on this floor, but to point out what he conceived to be the true line of policy for Virginia to pursue. He took the position at the outset that the Union ought not to be dissolved. The politicians had for twenty-five or thirty years been endeavoring to
              break up the Union, receiving the almost universal condemnation of the American people until the recent movement in the Cotton States. He proposed to show that the whole matter could be settled and the Union preserved. He then went on in his peculiar way to discuss the causes which led to the present state of affairs, and said it behooved the people to keep an eye upon the Catilines
              and Caesars, North and South. Alluding to the settlement of agitating questions in the past, he said that the men composing the former councils were made of very different stuff from men of these days. Point an empty gun at secessionists now, they would dodge. They went down to Fortress Monroe, not to see if the guns were loaded, but to see if they were pointed towards the land.
              Passing rapidly along in his argument, <span className="persName">Mr. RIVES</span> touched upon the tariff of 1828, and the nullification of South Carolina. The Union men said then as they said now—South Carolina, stand back; General Jackson, stand back! They thus acted as mediators, and saved the country. The point he made, as the reporter understood it, was that if wise counsels could
              prevail, a similar result would follow the present efforts. The argument that the best way to reconstruct the Union was for Virginia to go out of it, was answered by supposing the case of a little girl, five years old, going into an "apothecary" store and buying a doll; her sister Mary, two years older, takes it and breaks off an arm; the first runs to her father, and says "See here,
              papa, sister Mary has broken my doll!" The father replies, "Go away, child; break it all to pieces, and then bring it to me and I will mend it!"
              [Laughter.] His [<span className="persName">Mr. RIVES</span>'s] idea was to mend the Union before any more of the limbs were broken off.</p>
            <p>
              The course of William L. Yancey was commented on by the speaker with considerable severity, and an extract from his Slaughter letter produced to show that in 1858 his purpose was to dissolve the Union; while Andrew Johnson, of Tennessee, was quite as unequivocally complimented. Caleb Cushing came in for a share of denunciation, <span className="persName">Mr. RIVES</span> expressing a doubt
              whether he would have supported Douglas for the Presidency if he had been nominated by a Convention over which Cushing presided. He preserved the line of argument and illustration with which all are familiar who have listened to his campaign speeches.
            </p>
            <p>A reference, he said, had been made to his gestures. The mainspring of his action was Union, and it was the glory of the Union that gave force to his action here. When he saw a man stand off and carefully arrange a mass of papers before him, and proceed to bring forth a string of ab-</p>
            <PageNumber num={775} />
            <p>
              stractions that would craze a philosopher to investigate, he thought that such a man was not the statesman nor the lawyer for him. He proceeded then to argue the question of secession, reading evidence from speeches of Mr. Rhett and others in the Southern Convention, to show that Southern men had been endeavoring for years to bring about a dissolution of the Union. He would not say
              that they were pulling with the abolitionists, in couples, but that they were striving for the same object. He then read from the Congressional reports of 1842, showing that a petition then came from Massachusetts, praying for separation. A resolution to censure Mr. Adams for offering the petition was introduced, and a large majority of the members from the now seceded States voted
              against laying it on the table. He alluded also to the course of Mr. Wise in battling for the Union on that occasion, and he thought it would be well for the people of Virginia, with this record before them, to follow such suggestions and advice now. He would take up the weapons with which the member from Accomac fought disunion in 1842, and fight it to the very death. He predicted
              that the ballot-box would send forth a sound that would strike unmitigated sorrow to the hearts of secessionists in Virginia, if it did not cover them with shame. <span className="persName">Mr. RIVES</span> indulged in some sharp thrusts at the secessionists in the Convention, which extorted a call of "order" from the Chair; but the gentleman's deafness prevented his hearing or heeding it.
              He went from this branch of his subject to the consideration of the grandeur of the American Republic, and its position before the world, and, among other striking figures of speech, asked if they wanted the icy arms of death to encircle the fair form of the Goddess of Liberty. He quoted a considerable portion of the speech of Mr. Wise in Congress in 1842, upon the anti-slavery
              petition, and used it after the manner of seizing an enemy's guns and turning them against himself. He then went on to speak of England's hatred of African slavery, and the probability of her pouncing down upon the slaveholding States, supposing they were to set up for themselves. To sustain this position he again brought up the speech of the member from Accomac, which, viewing the
              subject in a similar light, said that in the Union any such attempt would be repelled by the united strength of the Republic—the sailor from New Bedford would stand by the sailor from Kentucky. <span className="persName">Mr. RIVES</span>' denunciations of the doctrine of secession were of the most emphatic kind, and we understood him to declare that if it was adopted here, he would never
              submit to it, if he had to stand alone, like the poor publican, saying "God be merciful to me, a sinner."
            </p>
            <p>
              He proceeded to speak of Texas, which, after coquetting with England, came into the U. States, having first achieved her independence with a smaller army than old John Brown had organized to invade Virginia. She came in, and received millions of dollars from the United States Government, and the protection due her as a member of the Confederacy; and now she had taken it upon herself to
              withdraw from the Union. He asked if Virginia would sanction this act of rebellion on the part of Texas?
            </p>
            <PageNumber num={776} />
            <p><span className="persName">Mr. RIVES</span> continued speaking until 2 o'clock, at which time the Committee took a recess until 4 o'clock P.M.</p>
            <p>The Committee was called to order by <span className="persName">Mr. SOUTHALL</span> at 4 o'clock P.M., and <span className="persName">Mr. RIVES</span> resumed his remarks.</p>
            <p>
              He said it was his object to close his speech this evening, because he did not want it to be said that he consumed any more time than was necessary for an exposition of his views. He then proceeded to examine the existing causes of complaint against the North. With regard to the institution of African slavery, he said it was very easy for those who did not like it to get rid of their
              slaves, while those who did like it sometimes found it very difficult to get them. He liked it, socially, morally, and politically, and he wished he could get a heap more of them. The election of Abraham Lincoln had been alluded to as a just cause of the dissolution of the Union. He averred that not one of those who supported Bell and Everett ever claimed that Lincoln's election would
              justify such a proceeding. He went further, and said that of all the Breckinridge speakers whom he heard in the Presidential canvass, none occupied that position, and Mr. Hunter, in a speech in the city of Petersburg, said, in answer to a question, that he did not think the election of Lincoln would justify dissolution, and if any State went out, he would be among the first to endeavor
              to bring her back again. The withdrawal of Southern Senators and Representatives from Congress was strongly condemned by <span className="persName">Mr. RIVES</span>, as giving up a power which the South possessed for preventing any objectionable appointments, and restraining any act of Administration hostile to her interests.
            </p>
            <p>
              He was for relying upon the law which had confined all preceding Presidents to the sphere of their duty; but if it was not strong enough, make it stronger. The violations of the fugitive slave law, by Personal Liberty bills, he unequivocally condemned; but he wanted no better security for the enforcement of the fugitive slave law than was found in the Constitution. The President, with
              the army and navy at his back, had full power to execute it, as President Fillmore did in the case of Burns at Boston. If it was contended that negroes were not caught at the North, it was equally true that they were not caught in the South, for the newspapers were constantly advertising runaway negroes. He thought a good many masters were to blame for dressing their slaves up in fine
              clothes, and letting them drive about the country, instead of keeping them down in the cornfield, where they belonged. It looked too much like equality, and invested the mind of the negro with ideas that did not belong there.
            </p>
            <p>
              The Territorial question next occupied his discourse. He took the ground that we did not want to take our slaves to the Territories. During the Kansas excitement there was a great furore in Petersburg, and a bonus of $50 was offered to all who would emigrate, and $100 to every one who carried a slave. It was only required that they should stay until after the October election, when, if
              they thought proper, they could come back. Only twenty-five enlisted, and of these, not one was a slave-owner! A better illustration than this, he said, could be found in South Carolina.
            </p>
            <PageNumber num={777} />
            <p>Not a single slaveholder will be fool enough to remove from his plantation there, to the finest fields that bloom in the great plains and valleys of the West.</p>
            <p>
              After some further consideration of this point, he proceeded to urge upon the Committee the report on the subject of Federal Relations, which he thought ought to satisfy every one. Before his constituents he took the ground that Virginia should set forth a catalogue of her wrongs, and the mode and measure of redress, and then say to the North, "You know you have been guilty of these
              things, and we ask you for this redress;" if they refused, then say to them, "Let us part in peace—you take the public property in the North, and we take the public property in the South; if we get more than you, we will pay you the difference, and if you get the most, pay us the difference." If they refused to accede to these terms, say to them, "Come on! We are ready' for you!" He
              would not wander through a labyrinth of abstractions to get at the right of secession, but raise the flag of revolution, and fight for what could not be obtained in peace. If, however, the first mode proved effectual, we could say to the seceded States, we have got all we want, the North has agreed to put these things in the Constitution, and now we ask you to come back. He believed it
              was wrong to endeavor to create the impression that such an effort would not succeed. The majority report, as he understood it, covered all the ground of which the South complained... It demanded everything that could be demanded, and yet gentlemen wanted something else. It reminded him of a cross and ill-natured child, dissatisfied with everything, and crying for something else; and
              when the nurse took it to the looking-glass to exhibit to the child its own ugliness, it capped the climax by smashing the glass.
            </p>
            <p>
              <span className="persName">Mr. RIVES</span> discussed the several sections of the report, expressing his concurrence therein. With regard to the objection against that portion providing for the remuneration of masters for slaves not recovered by the United States authorities, he made an arithmetical calculation, taking the number of slaveholders at 250,000, and the average value of the
              runaway slaves at $1,000; and the General Government having paid this to the master, even should the tax fall upon slaveholders alone, it would come to only about three mills to each man. But falling upon 28,000,000 of people, what a subject for calculation would each one's portion be. Arguing at some length upon the question of reconstruction, he expressed his belief that the Southern
              States would come back. He produced statistics to show that Virginia had not slaves enough, and urged a change in the agricultural system in order to retain them in Virginia.
            </p>
            <p>
              Towards the close of his remarks, in order to disabuse the minds of those who thought his enthusiasm for the Union was accounted for by the fact that he was a United States officer, he said he had made a calculation of the emoluments of his office, and found that since the 31st of December last he had received, as Collector of the Port of Petersburg, the enormous sum of twenty-five
              dollars and twenty-eight cents! He threw his keys upon the table before him, and said when any one wanted the keys of his office there they were. He had no favors to ask.
            </p>
            <PageNumber num={778} />
            <p>He alluded to the pestilence that once raged in Portsmouth and Norfolk, and to the pecuniary assistance then rendered by the Northern States to their suffering brethren of Virginia—sending collectively over $124,000. Upon this manifestation of sympathy he dwelt with much earnestness, and with these considerations he closed his remarks, having spoken about seven hours.</p>
            <p>THIRTY-NINTH DAY</p>
            <p>Saturday, March 30</p>
            <p>1. The delegate from Greenbrier was Mr. Price.</p>
            <p>2. This sentence may be the reporter's summary of Mr. Dorman's remarks on the rules.</p>
            <p>3. The reference is perhaps to Sir James Mackintosh.</p>
            <p>4. The verb of the first parenthetical relative clause is missing.</p>
            <p>5. The object is omitted here.</p>
            <p>6. There seems to be an omission here.</p>
            <p>7. The sentence sounds garbled.</p>
            <p>FORTIETH DAY</p>
            <p>Monday, April 1</p>
            <p>1. The differences between statistics of population in the lines above, and those in the tabulation below, are not explained.</p>
            <p>2. The initials are given below as W. G.</p>
          </div>
          <span className="pagebreak" id="index.xml-pb-w5937007aab1b2ab3">[Page]</span>
        </section>
      </div>
    </Styled.Volume>
  );
};

export default Transcription;
