import styled, { createGlobalStyle } from "styled-components";
import * as Constants from '../constants';
import { hexToRgb } from "../utilities";

const { colors, fonts, devices } = Constants;

export const Form = styled.form`
  display: grid;
  grid-template-columns: 100%;
  row-gap: 20px;
  column-gap: 20px;
  max-width: min(600px, 95vw);
  margin: 1rem auto;

  @media ${Constants.devices.desktop} {
    max-width: 600px;
  }

  * {
    font-family: ${Constants.fonts.sansSerif};
  }

  input {
    max-width: 100%;
  }

  input, textarea {
    padding: 0.5rem;
    background-color: rgba(${hexToRgb(Constants.colors.white)}, 0.5);
    border: 1px solid ${Constants.colors.grayMedium};
    outline: none;

    &:focus {
      color: ${Constants.colors.interactive};
      background-color: rgba(${hexToRgb(Constants.colors.white)}, 1);
      border-color: transparent;
      box-shadow: 0 0 8px 2px rgba(${hexToRgb(Constants.colors.interactiveLight)}, 0.6);

      ~ div {
        opacity: 1;

        svg {
          fill: ${Constants.colors.interactive};
        }
      }
    }
  }

  input[type=submit] {
    padding: 0.75rem;
    color: ${Constants.colors.white};
    font-weight: 600;
    background-color: ${Constants.colors.interactive};
    border: none;
    border-radius: 5px;
    cursor: pointer;
  
    &:hover {
      background-color: ${Constants.colors.interactiveDark};
    }
  }

  label {
    margin-bottom: -10px;
  }

  textarea {
    height: 200px;
  }
`;

export const SearchForm = styled(Form)`
  position: relative;
  display: flex;
  align-items: center;

  input {
    width: 100%;
    margin: 1rem 0;
    padding: 0.75rem 1rem 0.75rem 2rem;
    font-size: 1.1em;

    ~ a {
      margin-left: 0;
      color: ${Constants.colors.text};
      font-size: 0.9em;
      text-decoration: none;

      &:hover {
        color: ${Constants.colors.interactive};
      }
    }
  }
`;

export const SearchIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  transform: translate(0.5rem,-0.625rem);
  opacity: 0.5;

  svg {
    fill: currentColor;
  }
`;

export const Label = styled.label`
`;

export const Input = styled.input`
`;

export const TextArea = styled.textarea`
`;

export const Submit = styled(Input)`
`;

export const Header = styled.header`
  position: relative;
  height: 80px;
  width: calc(100% - 2rem);
  padding: 0 1rem;
  text-align: center;
  background: -moz-linear-gradientt(0deg, rgba(${hexToRgb(Constants.colors.white)}, 50%) 0%, rgba(${hexToRgb(Constants.colors.white)}, 0) 100%);
  background: -webkit-linear-gradientt(0deg, rgba(${hexToRgb(Constants.colors.white)}, 50%) 0%, rgba(${hexToRgb(Constants.colors.white)}, 0) 100%);
  background: linear-gradient(0deg, rgba(${hexToRgb(Constants.colors.white)}, 50%) 0%, rgba(${hexToRgb(Constants.colors.white)}, 0) 100%);
  // border-bottom: 1px solid ${Constants.colors.grayMedium};
  box-shadow: 0px 5px 5px -2px rgba(${hexToRgb(Constants.colors.black)}, 0.1);
  z-index: 999999999;

  h1 {
    margin: 0;
    font-size: clamp(1.1em, 3vw, 1.8em);
  }

  nav {

    a {
      position: relative;
      display: inline;
      margin: 0 10px;
      color: ${Constants.colors.text};
      text-align: center;
      text-decoration: none;
      line-height: 2.5em;
      cursor: pointer;

      &:hover {
        color: ${Constants.colors.interactive};
      }
      &:after,
      &.active:after {
        position: absolute;
        content: "";
        bottom: calc(50% - 0.75em);
        height: 3px;
        width: 0;
        left: 50%;
        background: ${Constants.colors.interactive};
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
      }

      &:hover:after {
        width: 100%;
        left: 0;
      }
    }
  }

  @media ${Constants.devices.tablet} {
    top: auto;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    nav {

      a {
        margin-right: 0;
        text-align: right;
      }
    }
  }
`;

export const Footer = styled.footer`
  height: calc(40px - 0.5rem);
  padding: 0.5rem 2.5% 0 2.5%;
  text-align: center;
  background-color: ${Constants.colors.grayDark};
  box-shadow: -1px 70px 20px -70px rgba(${hexToRgb(Constants.colors.black)}, 0.25) inset;

  * {
    color: ${Constants.colors.grayMedium};
  }

  a {
    padding: 0 12px;
    font-size: 0.8em;
    text-decoration: none;

    &:hover,
    &:active {
      color: ${Constants.colors.grayLight};
      text-decoration: underline;
    }

    + a {
      margin-top: 5px;
    }

    svg {
      height: 2.5em;
      width: 2.5em;
      fill: currentColor;
      stroke: none;
      transform: translateY(0.75em);
    }
  }
`;

export const ThreeStars = styled.div`
  position: relative;
  height: 10px;
  width: 100%;
  margin: 0 auto 2rem auto;
  text-align: center;

  svg,
  &::before,
  &::after {
    position: absolute;
    opacity: 0.5;
  }

  svg {
    top: 50%;
    fill: ${Constants.colors.text};
    height: 10px;
    width: 40px;
    transform: translate(-50%, -50%);
  }

  &::before,
  &::after {
    content: '';
    top: 50%;
    height: 1px;
    width: calc(50% - 25px);
    background: -moz-linear-gradient(90deg, rgba(${hexToRgb(Constants.colors.text)}, 0) 25%, rgba(${hexToRgb(Constants.colors.text)}, 1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(${hexToRgb(Constants.colors.text)}, 0) 25%, rgba(${hexToRgb(Constants.colors.text)}, 1) 100%);
    background: linear-gradient(90deg, rgba(${hexToRgb(Constants.colors.text)}, 0) 25%, rgba(${hexToRgb(Constants.colors.text)}, 1) 100%);
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
    transform: rotateY(180deg);
  }
`;

export const OriginalAbout = styled.div`
  background-color: white;
  padding: 2rem 1rem;

`;

export const GlobalStyle = createGlobalStyle`
  html {
    color: ${colors.text};
    font-family: ${fonts.sansSerif};
    background-color: ${colors.grayDark};
  }

  body {
    margin: 0;
    background-color: ${colors.grayLight};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: center;
    }

    h1,
    h2 {
      font-weight: bold;
    }
  }

  main {
    width: 90vw;
    max-width: 900px;
    margin: 10px auto;
    padding: 1rem 0 2rem 0;

    @media ${devices.tablet} {
      padding: 3rem 0 5rem 0;
    }

    p {
      max-width: 600px;
      margin: 10px auto;
      line-height: 1.7;
  
      &:last-of-type {
        margin-bottom: 1rem;
      }

      a {
        &:link,
        &:visited {
          color: ${colors.grayDark};
        }
        &:hover,
        &:active {
          text-decoration: none;
        }
      }
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  .App {
    position: relative;
  }

  .sitename {
    font-family: ${fonts.serif};
    font-weight: bold;
  }

  .tabs {
    position: relative;
    -webkit-box-shadow: 0px 5px 5px -2px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 5px 5px -2px rgba(0,0,0,0.1);
    box-shadow: 0px 5px 5px -2px rgba(0,0,0,0.1);
    overflow: hidden;
  
    .tab {
  
      label {
        background: white; 
        padding: 10px; 
        cursor: pointer;
        display: block;
      }
      input[type=radio] {
        display: none;   
      }
      input[type=radio]:checked ~ label {
        color: white;
        background: ${colors.interactive};
      }
    }
  
  }
`;