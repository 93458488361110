import styled from 'styled-components';
import * as Constants from '../../../constants';

export const Volume = styled.div`

  .stage {
    display: inline;
  }

  .headingNumber {
    display: none;
  }

  .dateline {
    text-align: center;
  }
`;

export const PageNumber = styled.span`

  .page-point {
    visibility: hidden;
    width: 1px;
    height: 1px;
  }

  .page-link {
    text-align: center;
    line-height: 1;
    text-decoration: none;
    color: ${Constants.colors.interactive};
    background-color: ${Constants.colors.grayLight};
  }

  .page-number {
    font-family: ${Constants.fonts.sansSerif};
    font-size: 16px;
    font-weight: 700;
    width: 20px;
    text-transform: uppercase;

    .label {
      display: block;
      font-size: 0.7em;
    }
  }
`;


export const Transcription = styled.div`
  line-height: 1.6;
  position: relative;
  overflow-x: visible;
  padding: 0 50px 0 1rem;

  .titlePage {
    text-align: center;

    .titlePart {
      font-size: clamp(1.1em, 3vw, 1.75em);
      font-weight: bold;
    }

    .docImprint {

      span:not(:first-of-type) {
        padding-left: 0.5em;
      }
    }

    div {
      margin-bottom: 2em;
    }
  }

  h3  {
    margin-top: 3em;
    
    + .synopsisDateLine {
      margin-top: -1em;
    }
  }

  // .head {
  //   text-align: center;
  // }

  .day {
    margin-top: 3rem;
  }

  .signed {
    text-align: right;
  }

  .note {
    position: relative;
    display: block;
    margin: 1.1rem 0;
    padding: 0 0 0 1.25em;
    font-size: 0.8em;

    + .note {
      margin-top: -1rem;
    }

    .noteLabel {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .prayer {
    text-align: center;
  }

  .ditto {
    font-size: 0;
    &:after {
      content: '"';
      visibility: visible;
      font-size: 16px;
    }
  }

  .tripledSize {
    font-size: 3em;
  }
  .tripledLineHeight {
    line-height: 4.8;
  }

  ol {
    list-style-position: inside;
  }

  ul {
    list-style: none;
  }

  aside {
    font-family: ${Constants.fonts.sansSerif};
    position: absolute;
    left: 15px;
    width: 70px;
    font-size: 0.8em;
    line-height: 1.1;
  }

  .page-point {
    position: absolute;
    right: 1px;
  }

  .page-link {
    position: absolute;
    right: 5px;
    height: 100%;
    min-width: 30px;

    .page-number {
      position: sticky;
      top: 20px;
    }
  }

  div.presentListTwoCols {
    display: flex;

    ul {
      margin-top: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 30px;
    }
  }

  div.presentListThreeCols {
    display: flex;

    ul {
      margin-top: 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 30px;
    }
  }

  .bottomToTop {
    transform: rotate(-90deg);
    transform-origin: 10% 100%;
    width: auto;
  }

  ol {
    padding-left: 0;
  }

  dl {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  header {
    display: block;
    border: 0;
    // text-align: right !important;
  }

  footer {
    background-color: transparent;
    height: auto;
    color: ${Constants.colors.text};
    box-shadow: none;

    div {
      &.signature {
        display: inline-block;
        max-width: 50%;
        margin: 10px 20px;
        vertical-align: top;
      }
    }

    &.rightStacked {
      div {
        &.signature {
          display: block;
          text-align: right;
          max-width: 100%;
        }
      }
    }
  }

  section {
    margin: 25px 0;
    padding: 25px 0;
    border-top: 1px dotted ${Constants.colors.grayMedium};
  }

  .leading {
    max-width: 40em;
    padding: 0;

    span:first-child {
      padding-right: 0.33em;
      padding-bottom: 5px;
    }

    span + span {
      float: right;
      padding-left: 0.33em;
    }
  }

  .leadingDots {
    max-width: 40em;
    padding: 0;
    overflow: hidden;

    background-image: radial-gradient(circle, currentcolor 1px, transparent 1.5px);
    background-position: bottom;
    background-size: 1ex 4.5px;
    background-repeat: space no-repeat;
    content: "";
    span:first-child {
      padding-right: 0.33em;
      padding-bottom: 5px;
      background: ${Constants.colors.grayLight};
    }

    span + span {
      float: right;
      padding-left: 0.33em;
      background: ${Constants.colors.grayLight};
    }
  }

  .amountRight {
    span.amount {
      float: right;
    }
  }

  td.numeric {
    text-align: right;
  }

  hr.short {
    text-align: center;
    width: 30%;
  }

  @media ${Constants.devices.tablet} {
    padding-left: 40px;

    aside {
      position: absolute;
      left: 15px;
      width: 70px;
      font-size: 0.8em;
      line-height: 1.1;
      text-indent: -9999px;

      &::before {
        content: "☞";
        position: absolute;
        color: ${Constants.colors.interactive};
        left: 0px;
        text-indent: 0;
        font-size: 1.5em;
        font-weight: 400;
      }
      &:hover {
        text-indent: 0px;
        background-color: ${Constants.colors.white};
        border-radius: 5px;
        padding: 2px 5px;
        border: 1px solid ${Constants.colors.grayMedium};
        overflow: hidden;
        z-index: 100;
        width: auto;
        max-width: 100px;

        &::before {
          content: "";
        }
      }
    }
  }
`;