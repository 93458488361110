import { NavLink } from 'react-router-dom';
import * as Styled from './styled';

const Header = () => {
  
  return (
    <Styled.Header>
      <h1 className='sitename'>Secession Convention Proceedings</h1>
      <nav>
        <NavLink to='/'>Proceedings</NavLink>
        <NavLink to='/map'>Voting Map</NavLink>
        <NavLink to='/timeline'>Timeline</NavLink>
        <NavLink to='/about'>About</NavLink>
      </nav>
    </Styled.Header>
  )
}

export default Header;