import styled from 'styled-components';

export const MapContainer = styled.figure`
width: 100%;
width: min(100%, 600px);
margin: 1em auto;
  svg {
    display: block;
    width: 100%;
    margin: 20px auto;
  }
  figcaption {
    text-align: center;
    font-size: 1.2rem;
    margin: 20px 0;
  }
`;