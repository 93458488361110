import React from "react";
import * as Styled from "./styled";
import ThreeStars from "./Icons/ThreeStars";

const About = () => {
  return (
    <main>
      <Styled.ThreeStars>
        <ThreeStars />
      </Styled.ThreeStars>
      <div>
        <h1>About the Project</h1>

        <p>
          The previous version of this digital collection, then titled <cite>Secession: Virginia and the Crisis of Union, 1861</cite>, was created in 2010 on the eve of the sesquicentennial of the Civil War. It was produced by a team of colleagues at the University of Richmond. The following is a verbatim reproduction of the original acknowledgements of the different individuals and teams that
          developed that project. (Please note that the original version included components like a couple maps showing which delegates voted for secession that are described below but not reproduced on this version. Also, most of the technical infrastructure of the original project described below are no longer reflective of this version.)
        </p>

        <Styled.OriginalAbout>
          <p>
            <cite>Secession: Virginia and the Crisis of Union, 1861</cite> is a collaborative project of the University of Richmond. It was built by an inter-departmental team of web designers, database experts, digital curators, digital humanists, and historians interested in bringing together the tools used to help run the university with the research and teaching at its core. The project
            explores a topic of broad scholarly and public interest as the sesquicentennial of the Civil War approaches: How did the decision to secede—and start the bloodiest conflict in US history—come about?
          </p>
          <p>
            <cite>Secession</cite> examines this question by bringing one important primary text, the <cite>Proceedings of the Convention of Virginia, 1861</cite> alongside a wealth of contextual information, including timelines, maps, political and demographic data, with tools to allow the user to link them all together. The Proceedings of the Convention were transcribed at the time by
            correspondents from the Richmond Enquirer. These accounts were published daily by the newspaper, then reprinted across the state. In preparation for the Centennial of the Civil War, researchers at the Library of Virginia collected and edited these documents into a four-volume set, along with three volumes of ancillary documents, the Journal and Papers of the Convention. The Library of
            Virginia generously donated a complete set of these volumes to be used for this project.
          </p>
          <p>
            The University of Richmond Library Digital Initiatives has encoded this text using TEI, an international XML standard for digitized and transcribed texts. This encoding allows those who read the electronic text easily to find speeches made by any member of the convention on any day. The speeches have been linked with demographic, political, and economic data on the places these
            delegates represented and the votes they took during the convention. By linking data to speeches, we broaden the readership for an important historical document, uncover patterns in the secession crisis that are otherwise invisible, and encourage historians and the public to ask new questions of an old text.
          </p>
          <p>This project required numerous skill sets and collaboration across departments at the University of Richmond.</p>
          <p>
            <strong>Boatwright Library:</strong> Crista LaPrade, Digital Production Services Supervisor, managed the process to image and extract text from the volumes provided by the Library of Virginia. Michael Weigle, Metadata Editor for Digital Initiatives and an undergraduate Computer Science student, created programs to encode the extracted text in TEI XML. Rick Neal, Library Systems
            Manager, built the server, installed applications, and currently maintains the operating system. Kory Mohr, intern with the School of Continuing Studies, provided essential support, investigation and testing during the software integration phase. Chris Kemp, Head of Digital Initiatives, oversaw data markup and enhanced the document encoding, designed formats for contextual and
            supplementary data, managed the eXist database and XQuery implementation, and handled consultant relationships with XQuery programmers Chris Wallace and Dan McCreary. Chris Kemp will sustain the project moving forward.
          </p>
          <p>
            <strong>Digital Scholarship Lab:</strong> Scott Nesbit, the Digital Scholarship Lab's associate director, gathered and formatted the project's contextual data, analyzed votes within the convention, worked with staff across the project to plan the vote visualizations, and situated the Convention within its historical context. Nathaniel Ayers, the Digital Scholarship Lab's
            Programmer/Analyst, and Rob Nelson, its director, provided valuable input and mock-ups during the project planning and visualization implementation stages.
          </p>
          <p>
            <strong>Systems and Networks:</strong> Clovis Khoury, Data Center Manager, allocated human and hardware resources to assist in building the project's server. Holly Caruso, Manager, IS Project Office, provided project management during the server allocation process. Consultation and technical support during the server deployment phase were provided by Systems Administrators Ting Chen,
            Bob Littlepage, and Sasko Stefanovski.
          </p>
          <p>
            <strong>University Communications:</strong> Matthew Levy, Web User Interface Manager, and Joedy Felts, Web Designer/Developer, teamed up to design the PHP, CSS, JavaScript and JQuery code required to translate between TEI and XHTML, create various maps and visualizations, and provided custom coding of the Proceedings' date browser. Phillip Gravely, Web and Editorial Strategy Director,
            consulted during the interface design and site content processes.
          </p>
          <p>
            <strong>Web Services:</strong> Instrumental consultation and collaboration during the query writing and text indexing phases of the project were provided by Alem Areki, Web Programmer. Eric Palmer, Director of Web Services, introduced the campus to the eXist-db XML database and is responsible for making initial decisions concerning the project's infrastructure. Eric contributed
            invaluable project management experience, brought in appropriate people and skill sets, and continuously asked the tough questions necessary to keep the project moving forward.
          </p>
        </Styled.OriginalAbout>

        <p>This new 2024 digital collection of the Secession Convention Proceedings was developed by Robert K. Nelson and Chad Devers. The TEI XML (available for <a href={`${process.env.PUBLIC_URL}/static/vsc1865TEI.zip`} download>download</a>) has been transformed into HTML. Search uses an instance of the Solr search platform instead of eXist. The application is written in TypeScript using the React Framework instead of a PHP web application.</p>
      </div>
    </main>
  );
};

export default About;
