import React from "react";
import * as Styled from './styled';

const ContactUs = () => {
  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const xhr = new XMLHttpRequest(),
      params = [
        encodeURIComponent('Form_ID') + '=' + encodeURIComponent('bot_contact_us'),
        encodeURIComponent('Owner_ID') + '=' + encodeURIComponent('rnelson2'),
        encodeURIComponent('send_submit') + '=' + encodeURIComponent('data'),
        encodeURIComponent('send_submit_to') + '=' + encodeURIComponent('rnelson2'),
        encodeURIComponent('project') + '=' + encodeURIComponent('bot_contact_form'),
        // @ts-ignore
        encodeURIComponent('name') + '=' + encodeURIComponent(evt.target.name.value),
        // @ts-ignore
        encodeURIComponent('email') + '=' + encodeURIComponent(evt.target.email.value),
        // @ts-ignore
        encodeURIComponent('message') + '=' + encodeURIComponent(evt.target.message.value)
      ].join('&');
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        (document.getElementById("contactUsForm") as HTMLDivElement).innerHTML = "<p>We have received your message. Thank you for contacting us.</p>";
      }
    };
    xhr.open("POST", 'https://webapps.richmond.edu/URPoster/URPoster.php');
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr.send(params);
    return false;
  }

  return (
    <main>
      <div id='contactUsForm'>
        <h2>Contact Us</h2>
          <Styled.Form name='bot_contact_us' onSubmit={(evt) => handleSubmit(evt)}>
            <Styled.Label htmlFor="name">Name</Styled.Label>
            <Styled.Input type="text" maxLength={50} name="name" />
            <Styled.Label htmlFor="email">Email</Styled.Label>
            <Styled.Input type="text" maxLength={50} name="email" />
            <Styled.Label htmlFor="message">Message</Styled.Label>
            <Styled.TextArea name="message" />
            <Styled.Submit type="submit" />
        </Styled.Form>
      </div>
    </main>
  );
}

export default ContactUs;

