import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as Styled from "../styled";
import * as StyledSelf from "./styled";
import SearchIcon from "../Icons/SearchIcon";
import ThreeStars from "../Icons/ThreeStars";

const Landing = () => {
  const searchRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  return (
    <main>
      <StyledSelf.Landing>
        <Styled.ThreeStars><ThreeStars /></Styled.ThreeStars>
        <p>
          On February 13, 1861, delegates representing all counties in Virginia met to decide how the state would respond to recent events, especially Abraham Lincoln's election and South Carolina's secession. They voted to remain in the Union and hoped that a compromise could be reached to defuse the situation. Two months later, the same men voted to secede from the United States, sparking a
          radically different war than might otherwise have taken place.
        </p>
        <p>
          This is a digital version of the four-volume <cite>Proceedings of the Virginia Convention of 1861</cite> published by the Library of Virginia in 1965.
        </p>

        <div>
          <h2>Search the Proceedings</h2>
          <Styled.SearchForm
            onSubmit={(e) => {
              e.preventDefault();
              if (searchRef.current && searchRef.current.value.length > 0) {
                navigate(`search/${searchRef.current.value}`);
              }
            }}
          >
            <Styled.Input type="text" ref={searchRef} />
            <Styled.SearchIcon><SearchIcon /></Styled.SearchIcon>
          </Styled.SearchForm>
        </div>

        <h2>Browse the Proceedings</h2>

        <StyledSelf.Volumes>
          <StyledSelf.VolumeLink to="v1/1">Volume 1<br />February 13-March 16</StyledSelf.VolumeLink>
          <StyledSelf.VolumeLink to="v2/1">Volume 2<br />March 18-April 1</StyledSelf.VolumeLink>
          <StyledSelf.VolumeLink to="v3/1">Volume 3<br />April 2-April 16</StyledSelf.VolumeLink>
          <StyledSelf.VolumeLink to="v4/1">Volume 4<br />April 16-May 1</StyledSelf.VolumeLink>
        </StyledSelf.Volumes>
      </StyledSelf.Landing>
    </main>
  );
};

export default Landing;
