import styled from 'styled-components';
import * as Constants from '../../../constants';
import { trackWidth } from '../constants';

export const TrackBase = styled.line`
  stroke: ${Constants.colors.interactive};
  stroke-linecap: round;
  stroke-width: ${trackWidth};
`;

export const Track = styled.line`
  stroke: ${Constants.colors.interactiveLight};
  stroke-linecap: round;
  stroke-width: ${trackWidth - 2};
`;

export const TrackBreak = styled.line`
  stroke: ${Constants.colors.grayLight};
  stroke-width: ${trackWidth};
`

export const TrackBreakExplanation = styled.text`
  font-size: 18px;
  fill: ${Constants.colors.grayMedium};
  text-anchor: middle;
`;

export const LincolnsElectionMarker = styled.circle`
  fill: ${Constants.colors.interactive};
`;

export const EventTick = styled.line`
  stroke: ${Constants.colors.interactive};
  stroke-width: ${trackWidth - 2};
`;

export const Date = styled.text`
  font-size: 12px;
  fill: white;
  text-anchor: middle;
`;