import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Nav from './Nav';
import * as Types from './types';
import * as Styled from "../styled";
import * as StyledSelf from "./styled";
import SearchIcon from "../Icons/SearchIcon";

const Search = () => {
  const { q, page: pageStr } = useParams();
  // the number or rows requested by the search
  const rows = 10;
  const page = (pageStr) ? parseInt(pageStr, 10) : 1;
  const start = rows * (page - 1);

  const ref = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [results, setResults] = useState<Types.Result[]>([]);
  const [numResults, setNumResults] = useState<number | undefined>(undefined);

  const runSearch = (e: React.SyntheticEvent | null) => {
    if (e) {
      e.preventDefault();
    }
    if (ref.current?.value && ref.current.value.length > 1) {
      navigate(`/search/${ref.current.value}`);
    }
  }

  console.log(q);

  // run the search
  useEffect(() => {
    if (q) {
      console.log(`https://digicol.richmond.edu:8983/solr/secession/select?start=${start}&rows=${rows}&hl.fl=text&hl=true&indent=true&q.op=OR&q=text%3A${q}`);
      axios.get<Types.SOLRData>(`https://digicol.richmond.edu:8983/solr/secession/select?start=${start}&rows=${rows}&hl.fl=text&hl=true&indent=true&q.op=OR&q=text%3A${q}`, {
        auth: {
          username: 'websites',
          password: 'qhsknQP!X2^P*It7'
        }
      })
        .then(response => {
          if (response.data.response.docs?.length > 0) {
            const results = response.data.response.docs.map(d => {
              console.log(response.data);
              const { id } = d;
              const texts = response.data.highlighting[id].text;
              return {
                volume: d.volume,
                page: d.page,
                text: texts || [],
              }
            });
            setResults(results);
          } else {
            setResults([])
          }
          setNumResults(response.data.response.numFound);
        });
    }
  }, [q, start]);

  return (
    <div>
      <Styled.SearchForm onSubmit={runSearch}>
        <input
          type='text'
          ref={ref}
          placeholder='Search'
          defaultValue={q}
        />
        <Styled.SearchIcon><SearchIcon /></Styled.SearchIcon>
        {(q) && (
          <Link
            to={`../../search/`}
            onClick={() => {
              if (ref.current) {
                ref.current.value = '';
                setResults([]);
                setNumResults(undefined);
              }
            }}
          >
            Clear
          </Link>
        )}
      </Styled.SearchForm>

      {(numResults === 0) && (
        <StyledSelf.NoResults>No matching search results</StyledSelf.NoResults>
      )}

      {!!(numResults && numResults > 0 && q) && (
        <Nav
          page={page}
          numResults={numResults}
          rows={rows}
          q={q}
        />
      )}
      {results.map(result => (
        <StyledSelf.Results key={`results for ${result.volume}-${result.page}`}>
          <StyledSelf.Title>
            <Link to={`../../v${result.volume}/${result.page}`}>Volume {result.volume} | Page {result.page}</Link>
          </StyledSelf.Title>
          <ul>
            {result.text && result.text.map(text => (
              <StyledSelf.Excerpt
                dangerouslySetInnerHTML={{ __html: text }}
                key={text}
              />
            ))}
          </ul>
        </StyledSelf.Results>
      ))}

      {!!(numResults && numResults > 0 && q) && (
        <Nav
          page={page}
          numResults={numResults}
          rows={rows}
          q={q}
        />
      )}
    </div>
  );
}

export default Search;