import React from 'react';
import * as d3 from 'd3';
import Events from "../../data/events.json";
import { height, trackWidth, eventOffset } from "./constants";


const lincolnsElection = new Date(1860, 10, 6);
const firstDate = new Date(lincolnsElection.getTime() - (24 * 60 * 60 * 1000) * 14);
const secessionSC = new Date(1860, 11, 20);
const manassas = new Date(1861, 6, 21);
const lastDate = new Date(manassas.getTime() + (24 * 60 * 60 * 1000) * 1);

// a d3 time scale function that maps a date to a position on the y-axis
const yScaleYMD = d3
  .scaleTime()
  .domain([firstDate, lincolnsElection, secessionSC, lastDate])
  .range([0, 50, 160, height - 50]);

// convenience function returning y but taking either a string YYYY-MM-DD, the year and month and day, or a Date object
const yScale = (dateOrDateStrOrYear: Date | number | string, month?: number, day?: number) => {
  if (typeof dateOrDateStrOrYear === 'number') {
    return yScaleYMD(new Date(dateOrDateStrOrYear, month as number, day as number));
  }
  if (typeof dateOrDateStrOrYear === 'string') {
    const [year, month, day] = dateOrDateStrOrYear.split("-").map((d) => parseInt(d, 10));
    return yScaleYMD(new Date(year, month - 1, day));
  }
  return yScaleYMD(new Date(dateOrDateStrOrYear.getFullYear(), dateOrDateStrOrYear.getMonth(), dateOrDateStrOrYear.getDate()));
};

// a list of all the dates between 11/1/1860 and 6/30/1861
const dates = d3.timeDays(secessionSC, lastDate);

// a list of dates starting with 11/6/1860 (Lincoln's election), 12/20/1860 (SC secession), and the first days of the month for January thorough July of 1861
const months = [
  d3.timeDay(lincolnsElection),
  d3.timeDay(secessionSC),
  ...dates.filter(d => d.getFullYear() === 1861 && d3.timeFormat("%e")(d).trim() === "1")
].map((date, idx, allDates) => {
  const height = (allDates[idx + 1]) ? yScale(allDates[idx + 1]) - yScale(date) : 30;
  return {
    date,
    height,
  }
});

// the slice removed Lincoln's election, which is dealt with separately
const events = Events.slice(1).sort((a, b) => {
  const [ayear, amonth, aday] = a.start.split("-").map((d) => parseInt(d, 10));
  const [byear, bmonth, bday] = b.start.split("-").map((d) => parseInt(d, 10));
  if (ayear !== byear) {
    return byear - ayear;
  }
  if (amonth !== bmonth) {
    return bmonth - amonth;
  }
  if (aday !== bday) {
    return bday - aday;
  }
  return 0;
});

const dayHeight = yScale(new Date(1861, 0, 2)) - yScale(new Date(1861, 0, 1));

export const TimelineContext = React.createContext({
  dimensions: {
    dayHeight: dayHeight,
    trackWidth,
    eventOffset,
    height,
    width: 1000, // a placeholder that is filled in in the hook
  },
  dates: {
    lincolnsElection,
    secessionSC,
    manassas,
    firstDate,
    lastDate,
    all: dates,
  },
  yScale,
  months,
  events,
});

