import React from 'react';
import { useParams, Link } from 'react-router-dom';

const PageNav = () => {
  const { volume, page } = useParams();
  const page_num = (page) ? parseInt(page, 10) : 1;

  const int2roman = (original: number): string => {
    if (original < 1 || original > 3999) {
      throw new Error('Error: Input integer limited to 1 through 3,999');
    }

    const numerals = [
      ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'], // 1-9
      ['X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC'], // 10-90
      ['C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM'], // 100-900
      ['M', 'MM', 'MMM']
    ]; // 1000 - 3000


    // TODO: Could expand to support fractions, simply rounding for now
    const digits = Math.round(original).toString().split('');
    let position = (digits.length - 1);

    return digits.reduce((roman, digit) => {
      if (digit !== '0') {
        roman += numerals[position][parseInt(digit) - 1];
      }

      position -= 1

      return roman
    }, '');
  }


  let pageLabel = page_num.toString();
  let maxPages = 0;
  if (volume === 'v1') {
    pageLabel = (page_num < 25) ? int2roman(page_num).toLowerCase() : (page_num - 24).toString();
    maxPages = 821;
  }
  if (volume === 'v2') {
    pageLabel = (page_num < 11) ? int2roman(page_num).toLowerCase() : (page_num - 10).toString();
    maxPages = 778;
  }
  if (volume === 'v3') {
    pageLabel = (page_num < 11) ? int2roman(page_num).toLowerCase() : (page_num - 10).toString();
    maxPages = 794;
  }
  if (volume === 'v4') {
    pageLabel = (page_num < 13) ? int2roman(page_num).toLowerCase() : (page_num - 12).toString();
    maxPages = 820;
  }

  return (
    <nav>

      {(page_num > 0) && (
        <Link to={`../${page_num - 1}`} relative='path'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.52 331.63">
            <path d="M165.82 331.28.35 165.82 165.82.35" />
          </svg>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </Link>
      )}

      page&nbsp;<strong>{pageLabel}</strong>&nbsp;/&nbsp;{maxPages}

      {(page_num < maxPages) && (
        <Link to={`../${page_num + 1}`} relative='path'>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.52 331.63">
            <path d="m.35 331.28 165.47-165.46L.35.35" />
          </svg>
        </Link>
      )}

    </nav>
  );
}

export default PageNav;